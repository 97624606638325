import { Outlet } from 'react-router-dom';

import { ErrorsFilterProvider } from '../errors-filter-context';
import { DashboardPage } from '/src/components/Page';

export function ErrorsProvider() {
  return (
    <DashboardPage>
      <ErrorsFilterProvider>
        <Outlet />
      </ErrorsFilterProvider>
    </DashboardPage>
  );
}
