import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useMetric, useAuthContext } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { WarningItem } from './WarningItem';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

const DEFAULT_INSTALL_PATH = '/install';

export function RenderWarnings({ warnings, finalUrl, installPath = DEFAULT_INSTALL_PATH }) {
  const trackMetric = useMetric();

  const { sessionInfo } = useAuthContext();
  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  if (!warnings || warnings?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="h-[30px]" />
      {warnings.find((warning) => warning.name === 'FULL_CHECK_NOT_COMPLETED') && (
        <WarningItem
          header="Partial Script Check Failed"
          description="The script is installed but we had a problem running the full check. If data is not being tracked, please contact support."
          ctaButton={
            <Button
              onClick={() => {
                window.open(SupportLinks.general.newSupportRequest, '_blank');
              }}
            >
              Contact support
            </Button>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'DUPLICATE_TRACKING_SCRIPT_INSTALLED') && (
        <WarningItem
          header="Duplicate Tracking Script Installed"
          description="It looks like you have multiple tracking scripts installed."
          howtoFix={
            <>
              Remove the duplicate tracking scripts. Then re-check its installation.
              <br />
              Visit this{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.scriptCheck.duplicateScript}
              >
                help article
              </a>{' '}
              to learn more.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'OUTDATED_TRACKING_SCRIPT_INSTALLED') && (
        <WarningItem
          header="Outdated Tracking Script Installed"
          description="The tracking script that you&#39;ve installed is outdated."
          howtoFix={
            <>
              <Link className="text-link" to={{ pathname: installPath }} state={{ requestUrl: finalUrl }}>
                Reinstall
              </Link>{' '}
              your Tracking Script. Then re-check its installation.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'CACHED_VERSION_OF_TRACKING_SCRIPT') && (
        <WarningItem
          header="Cached Version of Tracking Script"
          description="The Tracking Script version is incorrect, and may be due to a caching issue. Please ensure there are no
        tools caching the Tracking Script. A cached script will mean any changes you perform on Crazy Egg will not
        be reflected in your site."
          howtoFix={
            <>
              Visit this{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.scriptCheck.cacheScript}
              >
                help article
              </a>{' '}
              to learn more.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'TRACKING_SCRIPT_IN_NON_OPTIMAL_LOCATION') && (
        <WarningItem
          header="Tracking Script In Non-Optimal Location"
          description="While this won&#39;t necessarily affect tracking, you&#39;ll get better performance if you add the script to
        the &lt;head&gt; section of your site."
          howtoFix={
            <>
              <Link className="text-link" to={{ pathname: installPath }} state={{ requestUrl: finalUrl }}>
                Reinstall
              </Link>{' '}
              your Tracking Script. Then re-check its installation.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'TRACK_BY_NAME_CODE_DETECTED') && (
        <WarningItem
          header="Track by Name Code Detected"
          description="The Snapshot for this page does not use the Track by Name method, however a Track by Name code is installed
        on this page."
          howtoFix={
            <>
              Remove the Track by Name code from this page.
              <br />
              Visit this{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.scriptCheck.trackByName}
              >
                help article
              </a>{' '}
              to learn more.
            </>
          }
        />
      )}
      {isImpersonated && warnings.find((warning) => warning.name === 'NATIVE_FUNCTIONS_OVERRIDEN') && (
        <WarningItem
          header="Some browser native functions overriden"
          description="You have some code that overrides the following native browser objects: Array, URL, JSON, or XMLHttpRequest. This can cause compatibility issues with not just Crazy Egg but other tools you install on your website. We recommend you remove these overrides."
        />
      )}
      {warnings.find((warning) => warning.name === 'PROTOTYPE_JS_DEFINED') && (
        <WarningItem
          header="Prototype.js framework detected"
          description="You are using Prototype.js which overrides native browser functions. Prototype.js hasn't been updated since 2015 and is no longer necessary for modern browsers. It can cause compatibility issues with not just Crazy Egg but other tools you install on your website. We recommend you remove it."
        />
      )}
      {warnings.find((warning) => warning.name === 'MONTHLY_VISITS_QUOTA_REACHED') && (
        <WarningItem
          header="Monthly Visits Quota Reached"
          description="You've used up your monthly pageviews quota. Data is no longer being tracked for any running Snapshots."
          howtoFix={
            <>
              <Link
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                to={MANAGE_SUBSCRIPTION_PATH}
                onMouseDown={() => trackMetric('upgrade_cta_click', 'scriptcheck_monthly_visits_quota')}
              >
                Upgrade
              </Link>{' '}
              now to get more pageviews or wait until your quota resets on the first day of the next month.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'TOTAL_SNAPSHOT_QUOTA_REACHED') && (
        <WarningItem
          header="Total Snapshot Quota Reached"
          description="You have reached the total snapshot limit for your plan. Snapshots that are over the limit will not track
        data."
          howtoFix={
            <>
              <Link
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                to={MANAGE_SUBSCRIPTION_PATH}
                onMouseDown={() => trackMetric('upgrade_cta_click', 'scriptcheck_total_snapshots_quota')}
              >
                Upgrade
              </Link>{' '}
              your plan, or delete old snapshots.
            </>
          }
        />
      )}
      {warnings.find((warning) => warning.name === 'MONTHLY_RECORDINGS_QUOTA_REACHED') && (
        <WarningItem
          header="Monthly Recordings Quota Reached"
          description="You have used up your monthly Recordings quota, so no new visitor sessions will be recorded."
          howtoFix={
            <>
              <Link
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                to={MANAGE_SUBSCRIPTION_PATH}
                onMouseDown={() => trackMetric('upgrade_cta_click', 'scriptcheck_monthly_recordings_quota')}
              >
                Upgrade
              </Link>{' '}
              now to get more Recordings or wait until your quota resets on the first day of the next month.
            </>
          }
        />
      )}
    </>
  );
}
