import { Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

export function GroupBySelector({ groupBy, setGroupBy, className, values }) {
  const handleSelection = (value) => {
    setGroupBy(value);
  };

  return (
    <Popover className="relative">
      <Popover.Button
        type="button"
        className={`text-header-3 relative flex items-center pr-5 focus:outline-black ${className}`}
      >
        {values.find((x) => x.value === groupBy).text}
        <Indicator type="dropdown" className="absolute right-0" />
      </Popover.Button>
      <StyledPopoverPanel align="left" className="!translate-y-2.5">
        {({ close }) => (
          <>
            {values.map((x) => (
              <PopoverItem
                autoWidth
                key={x.value}
                onClick={() => {
                  handleSelection(x.value);
                  close();
                }}
              >
                {x.text}
              </PopoverItem>
            ))}
          </>
        )}
      </StyledPopoverPanel>
    </Popover>
  );
}
