import { useMemo, useId } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { capitalize, AIBox } from '@crazyegginc/hatch';

import { useAuthContext, useCrazyAI, useBulletPoints, useUserLanguage } from '/src/hooks';

import { TEXT_ENTRY_SUBTYPES, SENTIMENT_VALUES } from '/src/features/addons/constants';

import { DoughnutChart } from './DoughnutChart';

export const sentimentMeta = {
  [SENTIMENT_VALUES.POSITIVE]: { color: '#7FC2F2', mostlyEmoji: '👍', allEmoji: '🎉' },
  [SENTIMENT_VALUES.NEGATIVE]: { color: '#FF8FA7', mostlyEmoji: '🙁', allEmoji: '👎' },
  [SENTIMENT_VALUES.NEUTRAL]: { color: '#FFD155', mostlyEmoji: '😐', allEmoji: '😐' },
  [SENTIMENT_VALUES.MIXED]: { color: '#8F6BBA', mostlyEmoji: '🤷', allEmoji: '🤷' },
  [SENTIMENT_VALUES.UNKNOWN]: { color: '#E1E6EF', mostlyEmoji: '⏳', allEmoji: '' },
};

function getFinalSentiment(sentiments, total) {
  if (total === sentiments[0]?.count) {
    return { sentiment: sentiments[0].value, all: true };
  }
  if (sentiments[0]?.count === sentiments[1]?.count) {
    const sentimentValue = sentiments.reduce(
      (acc, curr) =>
        curr.value === SENTIMENT_VALUES.POSITIVE ? acc + 1 : curr.value === SENTIMENT_VALUES.NEGATIVE ? acc - 1 : acc,
      0,
    );
    const sentiment =
      sentimentValue > 0
        ? SENTIMENT_VALUES.POSITIVE
        : sentimentValue < 0
          ? SENTIMENT_VALUES.NEGATIVE
          : SENTIMENT_VALUES.MIXED;
    return { sentiment, all: false };
  }
  return { sentiment: sentiments[0].value, all: false };
}

export function TextEntry({ question, recentResponses }) {
  const lastResponses = question.lastResponses ?? [];
  const sentiments = question.results?.sort((a, b) => b.count - a.count) ?? [];
  const total = sentiments.reduce((acc, curr) => acc + curr.count, 0);

  if (!question.responseCount) {
    return <div className="text-body-5 px-9">No responses yet.</div>;
  }

  const isEmailQuestion = question.subtype === TEXT_ENTRY_SUBTYPES.EMAIL;

  const finalSentiment = getFinalSentiment(sentiments, total);

  const allUnknown = !!sentiments.find((s) => s.value === SENTIMENT_VALUES.UNKNOWN && s.count === total);

  return (
    <div className="space-y-5 px-9">
      {!isEmailQuestion && recentResponses && (
        <TextEntryAISummary question={question} recentResponses={recentResponses} />
      )}
      <div className="text-body-2 flex justify-center space-x-10">
        <div className="w-1/2 min-w-0">
          <div className="flex justify-between px-5 pb-2.5 text-2xs font-semibold">
            <span>LATEST RESPONSES</span>
          </div>
          <div
            className={classNames(
              'h-[336px] divide-y divide-dashed divide-mystic-500 overflow-y-auto rounded border border-mystic-500',
              'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded hover:scrollbar-thumb-lynch-500',
            )}
          >
            {lastResponses.map((resp, i) => (
              <div
                key={`${i}-${resp.textResponse}`}
                className={classNames('flex w-full items-center px-5 py-2 odd:bg-off-white-500', {
                  'min-h-[36px]': isEmailQuestion,
                  'min-h-[56px]': !isEmailQuestion,
                })}
              >
                {isEmailQuestion ? (
                  <a className="text-body-2 text-dodger-blue-500 hover:underline" href={`mailto:${resp.textResponse}`}>
                    {resp.textResponse}
                  </a>
                ) : (
                  resp.textResponse
                )}
              </div>
            ))}
          </div>
        </div>
        {!isEmailQuestion && (
          <div className="flex w-1/2 flex-col ">
            <div className="flex justify-center pb-2.5 text-2xs font-semibold">SENTIMENT</div>
            <div className="flex min-w-max grow items-center justify-center space-x-9 rounded bg-white-lilac-500 px-11">
              {allUnknown ? (
                <div className="text-center">
                  We could not determine the sentiment for this question. <br />
                  Most commonly this is due to the language being unsupported.
                </div>
              ) : (
                <>
                  <DoughnutChart
                    data={{
                      labels: sentiments.map((s) => capitalize(s.value)),
                      datasets: [
                        {
                          label: 'Sentiment',
                          data: sentiments.map((s) => s.count),
                          backgroundColor: sentiments.map((s) => sentimentMeta[s.value].color),
                          borderColor: sentiments.map((s) => sentimentMeta[s.value].color),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        doughnutlabel: {
                          labels: [
                            {
                              text: ' ',
                              font: {
                                size: 15,
                              },
                            },
                            {
                              text: `${finalSentiment.all ? 'All ' : 'Mostly '}${capitalize(finalSentiment.sentiment)}`,
                              color: '#0A1C2E',
                              font: {
                                size: 12,
                                weight: '600',
                              },
                            },
                            {
                              text: ' ',
                              font: {
                                size: 5,
                              },
                            },
                            {
                              text: sentimentMeta[finalSentiment.sentiment][
                                finalSentiment.all ? 'allEmoji' : 'mostlyEmoji'
                              ],
                              font: {
                                size: 18,
                              },
                            },
                          ],
                        },
                      },
                    }}
                  />
                  <div className="flex flex-col items-start">
                    {sentiments.map((s) => (
                      <div key={s.value} className="flex flex-col">
                        <div className="pl-5 text-base font-semibold">{Math.round((s.count / total) * 100)}%</div>
                        <div className="mb-3 flex items-center space-x-2.5">
                          <div
                            className="h-2.5 w-2.5 rounded-full"
                            style={{ background: sentimentMeta[s.value].color }}
                          />
                          <div className="text-body-5">
                            {capitalize(s.value)} ({s.count})
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function TextEntryAISummary({ question, recentResponses }) {
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const userPreferredLanguage = useUserLanguage();

  const xmlData = useMemo(() => {
    if (!recentResponses?.surveyResponseList?.responses) return null;
    const answers = recentResponses?.surveyResponseList.responses.reduce((acc, resp) => {
      const textResponse = resp.questions.find((q) => q.questionId === question.id)?.textResponse;
      if (textResponse) return acc + `<answer>${textResponse}</answer>`;
      else return acc;
    }, '');
    return `
    <data>
      <survey>
        <question>
          <title>${question.title}</title>
          <total_responses>${question.responseCount}</total_responses>
          <answers>${answers}</answers>
        </question>
      </survey>
    </data>`;
  }, [recentResponses, question]);

  const params = useMemo(
    () => ({
      data: xmlData,
      language: userPreferredLanguage,
    }),
    [xmlData, userPreferredLanguage],
  );

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
      userId: currentUser?.userId,
    }),
    [location.pathname, location.search, currentUser?.userId],
  );

  const { data, error, positiveFeedback, negativeFeedback, refetch } = useCrazyAI({
    type: 'summary_survey_responses',
    params,
    context,
  });

  const id = useId();
  useBulletPoints({ id, data: data?.text });

  return (
    <AIBox
      id={id}
      data={data}
      error={error}
      refetch={refetch}
      onPositive={positiveFeedback}
      onNegative={negativeFeedback}
      showChangeLanguage={true}
    />
  );
}
