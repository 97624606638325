import { AppConfig } from '../AppConfig';
import { isProduction, isDevelopment } from '/src/utils';
import Honeybadger from '@honeybadger-io/js';

export function sendMetric(metric, params) {
  if (isProduction() || isDevelopment()) {
    const search = params.url.split('?').slice(-1)[0];
    const queryParams = new URLSearchParams(search);

    if (window.METREX_DEBOUNCE === false) {
      send(metric, params);
      window.METREX_DEBOUNCE = false;
    }

    if (window.METREX_DEBOUNCE === true && !queryParams.has('s')) {
      send(metric, params);
      window.METREX_DEBOUNCE = false;
    }

    if (queryParams.has('s')) {
      window.METREX_DEBOUNCE = true;
    }
  }
}

function send(metric, params) {
  fetch(window.METREX_API_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      metric,
      params,
    }),
  });
}

export function hudMetric(category, event) {
  fetch(`${AppConfig.legacyCoreBaseURL()}/metrics/hud`, {
    method: 'POST',
    body: JSON.stringify({
      category,
      event,
    }),
  }).catch(function (error) {
    Honeybadger.notify('HUD Metric failed', {
      context: { error },
      name: 'HUD Metric',
    });
  });
}
