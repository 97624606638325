import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { SupportLinks } from '/src/support';

export function CE2BlockedModal({ onConfirm }) {
  const modal = useModal();

  return (
    <Modal dismissable={false} disableOutsideClick={true} dialogClassName="!w-[600px]">
      <Modal.Title>Uh-oh, it looks like your browser is blocking Crazy Egg</Modal.Title>

      <div className="text-body-2">
        To create or edit Surveys or CTAs we need to load the same Crazy Egg Javascript that you install on your
        website. <br />
        Your browser seems to be blocking this. You can fix this by:
        <ul className="ml-10 mt-2 list-outside list-disc">
          <li>Turning off any ad-blocking/privacy/tracking extensions</li>
          <li>Trying a different browser</li>
          <li>Trying again in an Incognito window</li>
        </ul>
        <p className="mt-2">
          If you still need help please don&apos;t hesitate to reach out to{' '}
          <a className="text-link " href={`mailto:${SupportLinks.general.email}`}>
            {SupportLinks.general.email}
          </a>
          .
        </p>
      </div>

      <Modal.Actions>
        <Button
          variant="primary"
          onClick={() => {
            modal.close();
            onConfirm?.();
          }}
        >
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
