import { Field } from 'formik';
import { Input } from '@crazyegginc/hatch';

export function ConditionTypeInput({
  disabled,
  name,
  placeholder,
  className = null,
  error = null,
  onDragStart = null,
  draggable = null,
}) {
  return (
    <Field name={name}>
      {({ field }) => (
        <div>
          <Input
            disabled={disabled}
            placeholder={placeholder ? placeholder : `Add a ${name}`}
            {...field}
            className={className}
            error={error}
            onDragStart={onDragStart}
            draggable={draggable}
          />
        </div>
      )}
    </Field>
  );
}
