/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import { useState, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';
import { useSelector } from '@xstate5/react';

import { useModal } from '/src/hooks';
import { clamp } from '/src/utils/math';
import { Slider, sliderWidth } from './Slider';

import { ReactComponent as ScrollIcon } from '@crazyegginc/hatch/dist/images/icon-scroll.svg';
import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-page-outline.svg';
import { ReactComponent as BackgroundedIcon } from '@crazyegginc/hatch/dist/images/icon-square-dashed.svg';
import { ReactComponent as LoadIcon } from '@crazyegginc/hatch/dist/images/icon-load-dots.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as EndedIcon } from '@crazyegginc/hatch/dist/images/icon-stop-circle-outline.svg';
import { ReactComponent as CommentIcon } from '@crazyegginc/hatch/dist/images/icon-comment-outline.svg';
import { ReactComponent as SwitchIcon } from '@crazyegginc/hatch/dist/images/icon-switch-outline.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as GoalConversionIcon } from '@crazyegginc/hatch/dist/images/icon-trophy-filled.svg';
import { ReactComponent as QuickbackIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-undo.svg';

import { TooltipClickEvent } from './events/ClickEvent';
import { TooltipFormEvent } from './events/FormEvent';
import { TooltipEcommerceEvent } from './events/EcommerceEvent';
import { PLAYER_EVENTS } from '/src/features/recordings/constants';

const {
  PAGE,
  MOUSEDOWN,
  SCROLL,
  BACKGROUNDED,
  PAGE_LOAD,
  RESIZE,
  END,
  ERROR_EVENT,
  TAB_SWITCH,
  FORM,
  ECOMMERCE,
  SURVEY_RESPONSE,
  GOAL_CONVERSION,
  QUICK_BACK,
  NAVIGATED_BACK,
} = PLAYER_EVENTS;

import { timeSeriesClusterize } from '/src/utils/math';
import { TooltipEvent } from './PlayerEvent';
import { ErrorDetailsModal } from './modals/ErrorDetailsModal';

function renderEvent({ event, onClick, modal, siteId }) {
  switch (event.type) {
    case PAGE:
      return (
        <TooltipEvent
          icon={PageIcon}
          title="Page"
          key={`EventTooltip:${PAGE}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case TAB_SWITCH:
      return (
        <TooltipEvent
          icon={SwitchIcon}
          title="Switch tab"
          key={`EventTooltip:${TAB_SWITCH}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case MOUSEDOWN:
      return (
        <TooltipClickEvent
          key={`EventTooltip:${MOUSEDOWN}:${event.rage ? 'rage' : event.dead ? 'dead' : ''}:${event.timestamp}`}
          onClick={onClick}
          {...event}
        />
      );
    case FORM:
      return (
        <TooltipFormEvent
          key={`EventTooltip:${FORM}:${event.sub_event_type}:${event.timestamp}`}
          onClick={onClick}
          event={event}
        />
      );
    case ECOMMERCE:
      return (
        <TooltipEcommerceEvent
          key={`EventTooltip:${ECOMMERCE}:${event.sub_event_type}:${event.timestamp}`}
          onClick={onClick}
          event={event}
        />
      );
    case SCROLL:
      return (
        <TooltipEvent
          icon={ScrollIcon}
          title="Scrolled"
          key={`EventTooltip:${SCROLL}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case BACKGROUNDED:
      return (
        <TooltipEvent
          icon={BackgroundedIcon}
          title="Backgrounded"
          key={`EventTooltip:${BACKGROUNDED}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case PAGE_LOAD:
      return (
        <TooltipEvent
          icon={LoadIcon}
          title="Page load"
          key={`EventTooltip:${PAGE_LOAD}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case ERROR_EVENT:
      return (
        <TooltipEvent
          icon={ErrorIcon}
          title="Error"
          key={`EventTooltip:${ERROR_EVENT}:${event.timestamp}`}
          event={event}
          onClick={(timestamp, opts) => {
            onClick?.(timestamp, opts);
            modal.show(<ErrorDetailsModal errorId={event.uuid} siteId={siteId} />);
          }}
        />
      );
    case SURVEY_RESPONSE:
      return (
        <TooltipEvent
          icon={SurveyIcon}
          title="Survey response"
          key={`EventTooltip:${SURVEY_RESPONSE}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case GOAL_CONVERSION:
      return (
        <TooltipEvent
          icon={GoalConversionIcon}
          title="Goal conversion"
          key={`EventTooltip:${GOAL_CONVERSION}:${event.trigger_id}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case QUICK_BACK:
      return (
        <TooltipEvent
          icon={QuickbackIcon}
          title="Quickback"
          key={`EventTooltip:${QUICK_BACK}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case NAVIGATED_BACK:
      return (
        <TooltipEvent
          icon={QuickbackIcon}
          title="Navigated back"
          key={`EventTooltip:${NAVIGATED_BACK}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    case END:
      return (
        <TooltipEvent
          icon={EndedIcon}
          title="Recording ended"
          key={`EventTooltip:${END}:${event.timestamp}`}
          event={event}
          onClick={onClick}
        />
      );
    default:
      return null;
  }
}

function EventLine({ children, left, width, count, active }) {
  return (
    <span
      className="group absolute bottom-0 z-10 block h-5 cursor-pointer transition duration-150 ease-in-out"
      style={{
        left,
        width,
      }}
    >
      <span
        className={classNames('absolute bottom-0 mx-px h-2 w-full bg-opacity-70', {
          'bg-white opacity-80 hover:opacity-100': !active && count >= 5,
          'bg-white opacity-50 hover:opacity-100': !active && count < 5,
          'bg-dodger-blue-500 opacity-80 hover:opacity-100': active,
        })}
      >
        {children}
      </span>
    </span>
  );
}

function ElapsedLine({ width }) {
  return (
    <span
      className="absolute left-0 z-10 mx-px block h-2 bg-dodger-blue-500 bg-opacity-50"
      style={{
        width,
      }}
    />
  );
}

function BackgroundedLine({ timestamp, left, width, isDragging, onClick }) {
  return (
    <span
      className="group absolute z-20 mx-px block h-2 cursor-pointer bg-lavender-500 transition duration-150 ease-in-out"
      style={{
        left,
        width,
      }}
    >
      {!isDragging ? (
        <Tooltip
          interactive={true}
          offset="15"
          style={{ maxWidth: '150px', padding: '0px' }}
          containerStyle={{ zIndex: 1000001 }}
          tooltipContent={
            <ul className="block min-w-max overflow-hidden rounded shadow-lg">
              <li
                className="flex w-full items-center justify-center rounded-b bg-woodsmoke-500 px-2.5 py-2 text-xs font-semibold text-white transition duration-150 ease-in-out hover:bg-dodger-blue-500"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  onClick(timestamp, { isTimestamp: true });
                }}
              >
                <BackgroundedIcon className="mr-1.5 h-3 w-3 fill-current text-cadet-blue-500" />
                Backgrounded
              </li>
            </ul>
          }
        >
          <div className="h-2 w-full" />
        </Tooltip>
      ) : null}
    </span>
  );
}

function TimeLineDot({ left, type, children }) {
  return (
    <span
      className={classNames(
        'absolute h-1 w-1 rounded-full',
        { 'bg-picasso-500': type === 'page' },
        { 'bg-carnation-500': type === 'error' },
      )}
      style={{ left: `${parseInt(left) - 2}px` }}
    >
      {children}
    </span>
  );
}

function TimeLineComment({ left, onClick }) {
  return (
    <div className="absolute h-2.5 w-2.5 -translate-y-0.5" style={{ left: `${parseInt(left) - 5}px` }}>
      <Tooltip
        containerStyle={{ zIndex: 1000001 }}
        tooltipContent={
          <div className="flex items-center">
            <CommentIcon className="mr-1 h-2.5 w-2.5 fill-current text-pink-salmon-500" />
            Comment
          </div>
        }
      >
        <button onClick={onClick} className="block">
          <CommentIcon className="h-2.5 w-2.5 fill-current text-pink-salmon-500" aria-label="Comment" />
        </button>
      </Tooltip>
    </div>
  );
}

// function ClusterPageVisit({ visit, onClick }) {
//   return (
//     <li className="flex w-full p-1 bg-mako-500 text-xs text-white font-semibold border-t border-mako-700" >
//       <button className="bg-woodsmoke-500 text-picasso-500 px-2 py-2 rounded-t-sm flex items-start transition duration-150 ease-in-out hover:bg-dodger-blue-500" onMouseDown={(e) => {
//       e.stopPropagation();
//       onClick?.(visit.timestamp, { isTimestamp: true });
//     }}>
//         <PageIcon className="fill-current text-picasso-500 w-3 h-4 mr-1.5 mt-px inline" />
//         <span className="flex-1 line-clamp-2 text-left">
//           {visit.title}
//         </span>
//       </button>
//     </li>
//   )
// }

function Cluster({ events, cluster, left, width, active, isDragging, onClick, siteId }) {
  const modal = useModal();
  if (events.length === 1 && cluster.tags.includes(BACKGROUNDED)) {
    if (events[0].backgrounded_time < 2000) {
      return null;
    }
    return (
      <BackgroundedLine
        left={left}
        width={width}
        active={active}
        isDragging={isDragging}
        visit={cluster.pageVisit}
        onClick={onClick}
        timestamp={cluster.minValue}
      />
    );
  }
  return (
    <EventLine left={left} width={width} count={cluster.indexes.length} active={active} onClick={onClick}>
      {!isDragging ? (
        <Tooltip
          interactive={true}
          offset="15"
          style={{ maxWidth: '160px', padding: '0px' }}
          containerStyle={{ zIndex: 1000001 }}
          tooltipContent={
            <ul className="relative overflow-hidden rounded">
              {events.map((event) => renderEvent({ event, onClick, modal, siteId }))}
              <span className="pointer-events-none absolute bottom-0 left-0 z-50 h-px w-full bg-woodsmoke-500"></span>
            </ul>
          }
        >
          <div className="h-2 w-full" />
        </Tooltip>
      ) : null}
    </EventLine>
  );
}

function getTimelineWidth(container) {
  const containerStyles = window.getComputedStyle(container);
  return (
    container.getBoundingClientRect().width -
    parseFloat(containerStyles.paddingLeft) -
    parseFloat(containerStyles.paddingRight)
  );
}

let isDragging = false;

export function PlayerTimeline({ actorRef, seekTime, onSeek = null, commentTimestamps, onDrag = null, onDrop = null }) {
  const events = useSelector(actorRef, (state) => state.context.recordedEvents);
  const playbackTime = useSelector(actorRef, (state) => state.context.duration);
  const elapsedTime = useSelector(actorRef, (state) => state.context.ticks);
  const recording = useSelector(actorRef, (state) => state.context.recording);

  const playerTimelineRef = useRef(null);
  const [state, setState] = useState(() => ({ meta: [], state: [], container: null, isDragging: false }));

  const { width: timelineWidth } = useResizeObserver({ ref: playerTimelineRef });

  const getRelativeX = useCallback(
    (e) => {
      const selectPosition = e.clientX - sliderWidth / 2;
      // calculate seek time
      return clamp(selectPosition, 0, timelineWidth) / timelineWidth;
    },
    [timelineWidth],
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const relativePosition = getRelativeX(e);
        onSeek?.(relativePosition);
      }
    },
    [getRelativeX, onSeek],
  );

  function handleMouseUp() {
    isDragging = false;
    document.body.removeEventListener('mousemove', handleMouseMove);
  }

  const memoizedEvents = useMemo(() => {
    if (!playbackTime || !events || !playerTimelineRef.current) return null;

    return events.map((ev) => ({ ...ev, left: (ev.timestamp / playbackTime) * timelineWidth }));
  }, [events, playbackTime, playerTimelineRef, timelineWidth]);

  const memoizedVisits = useMemo(() => {
    if (!memoizedEvents) return null;

    return memoizedEvents.filter((ev) => [PAGE, TAB_SWITCH].includes(ev.type));
  }, [memoizedEvents]);

  const memoizedErrors = useMemo(() => {
    if (!memoizedEvents) return null;

    return memoizedEvents.filter((ev) => ['error'].includes(ev.type));
  }, [memoizedEvents]);

  const memoizedClusters = useMemo(() => {
    if (!memoizedEvents || !playerTimelineRef.current) return null;

    return timeSeriesClusterize(memoizedEvents, {
      maxDistance: (playbackTime / timelineWidth) * 8, // max cluster size of 8px
      minTimeframe: 0,
      minRelevance: 0,
      timeField: 'timestamp',
      singleClusterTags: [BACKGROUNDED],
      tagField: 'type',
      tagValues: [BACKGROUNDED, PAGE],
    });
  }, [memoizedEvents, playerTimelineRef, playbackTime, timelineWidth]);

  // memoizedClustersWithData exists so that we only have to recalculate the
  // positions of each of the memoized clusters.
  const memoizedClustersWithData = useMemo(() => {
    if (!memoizedEvents || !memoizedClusters || !playerTimelineRef.current) return null;

    let page;

    return memoizedClusters.map((cluster) => {
      const clusterVisits = memoizedVisits.filter(
        (visit) => visit.timestamp < cluster.maxValue && visit.timestamp >= cluster.minValue,
      );
      // if there are page visits in the cluster. use the first page visit for the current cluster,
      // and the last one for the upcoming clusters.

      if (clusterVisits.length > 0) {
        // assign page for upcoming clusters
        page = { ...clusterVisits[clusterVisits.length - 1] };
      }

      return {
        ...cluster,
        left: `${(cluster.minValue / playbackTime) * timelineWidth}px`,
        // using Math.max here to enforce a min px width for clusters of 4px
        width: `${Math.max(
          cluster.indexes.length === 1 && cluster.tags.includes(BACKGROUNDED)
            ? (memoizedEvents[cluster.indexes[0]].backgrounded_time / playbackTime) * timelineWidth
            : ((cluster.maxValue - cluster.minValue) / playbackTime) * timelineWidth,
          4,
        )}px`,
        pageVisit: clusterVisits.length > 0 ? clusterVisits[0] : page,
      };
    });
  }, [memoizedClusters, memoizedEvents, memoizedVisits, playbackTime, timelineWidth]);

  // layout effect is used to mount the resize listeners
  useLayoutEffect(() => {
    const onResize = () => {
      const width = getTimelineWidth(playerTimelineRef.current);
      setState((state) => ({
        ...state,
        container: { width },
      }));
    };

    if (playerTimelineRef.current) {
      window.addEventListener(RESIZE, onResize);
    }

    return () => {
      window.removeEventListener(RESIZE, onResize);
    };
  }, [playerTimelineRef]);

  const timeline = useMemo(() => {
    if (!memoizedEvents || !memoizedClusters || !playbackTime || !playerTimelineRef.current) return null;

    return (
      <>
        {memoizedEvents &&
          memoizedClusters &&
          memoizedClustersWithData &&
          memoizedClustersWithData?.map((cluster, idx) => (
            <Cluster
              cluster={cluster}
              events={cluster.indexes.map((eventIdx) => memoizedEvents[eventIdx])}
              key={`Cluster:${cluster.minValue}:${idx}`}
              left={cluster.left}
              width={cluster.width}
              active={elapsedTime >= cluster.minValue}
              isDragging={state.isDragging}
              onClick={onSeek}
              siteId={recording.siteId}
            />
          ))}
        <div className="absolute -top-2.5 h-2 w-full">
          {memoizedVisits &&
            memoizedVisits
              .slice(1) // skip the first dot https://app.clubhouse.io/crazyegg/story/11228
              .map((visit, idx) => <TimeLineDot left={visit.left} key={`PageVisitDot:${idx}`} type="page" />)}
          {memoizedErrors &&
            memoizedErrors.map((error, idx) => <TimeLineDot left={error.left} key={`ErrorDot:${idx}`} type="error" />)}
          {[...new Set(commentTimestamps)].map((timestamp) => (
            <TimeLineComment
              key={`CommentIcon:${timestamp}`}
              left={((timestamp * 1000 + 500) / playbackTime) * timelineWidth}
              onClick={() => {
                actorRef.send({ type: 'PAUSE' });
                window.Mocky?.turnOn();
                onSeek?.(timestamp * 1000 + 500, { isTimestamp: true });
              }}
            />
          ))}
        </div>
      </>
    );
  }, [
    actorRef,
    memoizedClusters,
    memoizedEvents,
    elapsedTime,
    playbackTime,
    state.isDragging,
    memoizedClustersWithData,
    memoizedVisits,
    memoizedErrors,
    onSeek,
    commentTimestamps,
    recording.siteId,
    timelineWidth,
  ]);

  const isReady = !!(playbackTime && memoizedEvents && memoizedClusters && playerTimelineRef);

  return (
    <div
      className="relative z-0 mb-2 flex h-5 w-full items-center px-3"
      data-testid="timeline-bar"
      onMouseDown={(e) => {
        const startX = e.clientX;

        // logMouseButton(e);
        // only react to left mouse button clicks
        if (e.nativeEvent.which !== 1) return null;
        isDragging = true;
        onDrag?.();
        document.body.addEventListener('mousemove', handleMouseMove);
        document.body.addEventListener(
          'mouseup',
          (e) => {
            const endX = e.clientX;

            handleMouseUp(e);
            onDrop?.();

            if (startX === endX) {
              const relativePosition = getRelativeX(e);
              onSeek?.(relativePosition);
            }
          },
          { once: true },
        );
      }}
      ref={playerTimelineRef}
    >
      <span className="relative h-2 w-full bg-mako-500">
        {isReady ? (
          <>
            <ElapsedLine width={(elapsedTime / playbackTime) * timelineWidth} />
            {timeline}
            <Slider
              timelineWidth={timelineWidth}
              currentPosition={(seekTime ? seekTime : elapsedTime) / playbackTime}
            />
          </>
        ) : null}
      </span>
    </div>
  );
}
