import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';
import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as SnapshotsIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as SurveysIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as GoalsIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as TeamIcon } from '@crazyegginc/hatch/dist/images/icon-people-outline.svg';
import { ReactComponent as ABTestingIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';

export const externalLinkTasks = ['enable_recordings'];

export const tasksData = {
  install: {
    header: 'Connect',
    description: 'your site by installing our tracking script',
    url: '/install',
    referencedText: 'installed our tracking script',
    Icon: CodeIcon,
    status: 'COMPLETED',
  },
  enable_recordings: {
    header: 'Enable',
    description: 'Recordings to track visitor journeys',
    url: '/recordings/settings',
    referencedText: 'enabled Recordings',
    Icon: RecordingsIcon,
  },
  create_snapshot: {
    header: 'Create',
    description: 'Snapshots to monitor your pages',
    url: '/snapshots/new',
    referencedText: 'created a Snapshot',
    Icon: SnapshotsIcon,
    status: 'BLOCKED',
  },
  publish_survey: {
    header: 'Collect',
    description: 'user thoughts & feedback with Surveys',
    url: '/addons/templates?type=SURVEYS',
    referencedText: 'published a Survey',
    Icon: SurveysIcon,
  },
  create_goal: {
    header: 'Improve',
    description: 'your key conversions with Goals',
    url: '/goals/new',
    referencedText: '',
    Icon: GoalsIcon,
  },
  view_recording: {
    header: 'Watch',
    description: 'Recordings of visitor behaviour & issues',
    url: '/recordings',
    referencedText: '',
    Icon: RecordingsIcon,
  },
  view_snapshot: {
    header: 'Analyze',
    description: 'Snapshots to see how your pages perform',
    url: '/snapshots',
    referencedText: '',
    Icon: SnapshotsIcon,
  },
  view_survey: {
    header: 'Review',
    description: "Survey results to hear visitors' opinions",
    url: '/addons?type=SURVEYS',
    referencedText: '',
    Icon: SurveysIcon,
  },
  invite_team: {
    header: 'Invite',
    description: 'others to collaborate as a Team',
    url: '/account/team',
    referencedText: '',
    Icon: TeamIcon,
  },
  create_ab_test: {
    header: 'Compare',
    description: 'the success of different ideas with A/B tests',
    url: '/ab-tests/new',
    referencedText: '',
    Icon: ABTestingIcon,
  },
};
