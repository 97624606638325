import { useEffect } from 'react';
import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';

// eslint-disable-next-line no-restricted-syntax
export default function NewSurvey() {
  useEffect(() => {
    if (window.Metrics) {
      window.Metrics.used('Survey: Editor - New');
    }
  }, []);

  return <SurveyEditor />;
}
