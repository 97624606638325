import { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import useResizeObserver from 'use-resize-observer';
import classNames from 'classnames';
import { Input, Button, Tooltip, Spinner } from '@crazyegginc/hatch';

import { SharePreviewModal } from '/src/features/addons/common/modals/SharePreviewModal';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';
import { PreviewWarningModal } from '/src/features/addons/common/modals/PreviewWarningModal';
import { useEditorContext } from '../../editor-context';
import { hasAtLeastOneQuestion, validForPreview, showFeedbackButtonInPreview } from './editor-functions';
import { getExternalPreviewLink } from '../common-functions';
import { useModal, useAuthContext, useSelectedSite } from '/src/hooks';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { BEHAVIOR_TRIGGERS, SURVEY_DISPLAY_TYPES, ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as RestartIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';

export function PreviewOnSiteAndRestart({ saveSurvey }) {
  const { readonly, type, spelledType, id } = useEditorContext();
  const [, setDummy] = useState(0);
  const { values, isValid, errors } = useFormikContext();
  const modal = useModal();
  const [previewUrl, setPreviewUrl] = useState('');
  const [touched, setTouched] = useState(false);
  const [savingShare, setSavingShare] = useState(false);
  const [savingPreview, setSavingPreview] = useState(false);
  const previewWindow = useRef();
  const features = useRef();
  const isValidForPreview = validForPreview(errors);
  const isExternal = values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL;
  const { currentAccount } = useAuthContext();
  const { selectedSite } = useSelectedSite();

  const { height: surveyHeight } = useResizeObserver({
    ref:
      document.querySelector('crazyegg-survey')?.shadowRoot?.querySelector('#main')?.firstElementChild ??
      document.querySelector('crazyegg-feedback')?.shadowRoot?.querySelector('button'),
  });

  useEffect(() => {
    if (!touched) {
      setPreviewUrl((values.audience.pages?.[0] || `http://${values.audience.site?.name}`) ?? '');
    }
  }, [touched, values.audience.pages, values.audience.site]);

  useEffect(() => {
    // this is needed for surveyHeight to get a value after the survey transitions
    if (!surveyHeight) {
      setTimeout(() => setDummy((x) => x + 1), 700);
    }
  }, [surveyHeight, isValidForPreview]);

  if (!surveyHeight) return null;

  async function launchPreview() {
    setSavingPreview(true);
    const { previewToken } = await saveSurvey();
    setSavingPreview(false);

    if (isExternal) {
      open(getExternalPreviewLink(currentAccount.id, selectedSite.id, previewToken), 'previewWindow');
      return;
    }

    let newFeatures;
    if (values.audience.device.length === 1 && values.audience.device[0] === DEVICE_TYPES.MOBILE) {
      newFeatures = 'width=320,height=740';
    } else {
      newFeatures = '';
    }

    if (newFeatures !== features.current && previewWindow.current && !previewWindow.current.closed) {
      previewWindow.current.close();
    }
    features.current = newFeatures;
    previewWindow.current = open(previewUrl, id, features.current);
    modal.show(
      <PreviewLoadingModal target={features.current ? 'window' : 'tab'} type={type} spelledType={spelledType} />,
    );

    window.addEventListener('message', (event) => {
      if (event.data.status === 'initiated') {
        previewWindow.current.postMessage({ token: previewToken }, '*');
      } else if (event.data.status === 'scriptLoaded') {
        if (
          (values.autoPosition && !values.autoPositionFallbackToShow) ||
          values.behavior.show !== BEHAVIOR_TRIGGERS.IMMEDIATE
        ) {
          modal.show(
            <PreviewWarningModal
              autoPosition={values.autoPosition}
              autoPositionFallbackToShow={values.autoPositionFallbackToShow}
              behavior={values.behavior}
              spelledType={spelledType}
            />,
          );
        }
      } else if (event.data.status === 'launched') {
        modal.close();
      }
    });
  }

  const isValidForPreviewOnSite = isValid && hasAtLeastOneQuestion(values);

  return (
    <div className="flex w-full flex-col items-center !self-stretch py-4" style={{ marginTop: `${surveyHeight}px` }}>
      {isValidForPreview && (
        <button
          type="button"
          className="text-body-2 flex items-center font-semibold text-dodger-blue-500"
          onClick={() => {
            if (values.behavior.show === BEHAVIOR_TRIGGERS.FEEDBACK && !isExternal) {
              showFeedbackButtonInPreview(values.behavior.feedback, validForPreview(errors));
            } else {
              window.CE2.Survey?.update(1, { questionNumber: 0 });
            }
          }}
        >
          <RestartIcon className="mr-2.5 h-3 w-3 scale-x-[-1] fill-current" />
          Restart survey
        </button>
      )}
      {!readonly && (
        <>
          {!isExternal && (
            <h2
              className={classNames('text-body-2 mb-2 w-[310px]', {
                'mt-10': isValidForPreview,
                'mt-20': !isValidForPreview,
                'text-center': isExternal,
              })}
            >
              Preview directly on my site
            </h2>
          )}
          <Tooltip
            placement="bottom"
            show={!isValidForPreviewOnSite}
            tooltipContent={
              <div>
                {!isValid ? (
                  <>
                    Please fix any errors in the editor to be able
                    <br /> to {isExternal ? 'preview the survey' : 'preview the survey on your site'}.
                  </>
                ) : (
                  <>
                    You need to add at least one question to be able
                    <br /> to {isExternal ? 'preview this survey' : 'preview this survey on your site'}.
                  </>
                )}
              </div>
            }
          >
            <div
              className={classNames('flex w-[310px] items-center justify-center', {
                'mt-5': isExternal,
              })}
            >
              <div className={classNames('mr-2.5', { 'relative grow': !isExternal })}>
                {!isExternal && (
                  <Input
                    id="survey-preview-url"
                    value={previewUrl}
                    onChange={(e) => {
                      setPreviewUrl(e.target.value);
                      setTouched(true);
                    }}
                    className="!pr-24"
                    placeholder="Preview URL"
                    disabled={!isValidForPreviewOnSite || savingPreview || isExternal}
                  />
                )}
                <Button
                  variant="secondary"
                  className={classNames({
                    'absolute right-px top-px !h-[33px] !rounded-l-none hover:!border-transparent': !isExternal,
                  })}
                  disabled={!isValidForPreviewOnSite || savingPreview}
                  onClick={launchPreview}
                >
                  {savingPreview && <Spinner className="mr-2 h-4 w-4 text-cadet-blue-500" />}
                  Preview
                </Button>
              </div>

              <Tooltip tooltipContent="Share preview" show={isValidForPreviewOnSite}>
                <Button
                  variant="secondary"
                  disabled={!isValidForPreviewOnSite || savingShare}
                  onClick={async () => {
                    setSavingShare(true);
                    const { previewToken } = await saveSurvey();
                    setSavingShare(false);
                    modal.show(
                      <SharePreviewModal
                        url={previewUrl}
                        token={previewToken}
                        type={ADDON_TYPES.SURVEY}
                        external={isExternal}
                      />,
                    );
                  }}
                >
                  {savingShare ? (
                    <Spinner />
                  ) : (
                    <ShareIcon className="h-4 w-4 fill-current" aria-label="Share preview" />
                  )}
                </Button>
              </Tooltip>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
}
