import { PlayerWall } from '../RenderPlayerWall';
import { WebP } from '/src/components/WebP';

import YellowWarningPNG from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.png';
import YellowWarningWebP from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.webp';

function PlayerGeneralErrorImage() {
  return <WebP webp={YellowWarningWebP} fallback={YellowWarningPNG} width="204px" height="160px" />;
}

// eslint-disable-next-line no-restricted-syntax
export default function PlayerGeneralError() {
  return (
    <PlayerWall
      title="Hmm, we're having a little trouble here..."
      primaryText="Refresh page"
      primaryAction={() => {
        location.reload();
      }}
      image={PlayerGeneralErrorImage}
    >
      Please refresh the page and try again.
    </PlayerWall>
  );
}
