import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { authAtom } from '/src/machines/auth';

/*
  Treat this as a read-only hook for simple context access

  const { isSharing } = useAuthContext();
*/
export function useAuthContext() {
  const [state] = useAtom(authAtom);

  const context = useMemo(() => ({ ...state.context }), [state.context]);

  return context;
}
