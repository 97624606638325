import { useAuthContext } from '/src/hooks';
import { sendMetric } from '/src/utils/metrics';

export function useMetric() {
  const { currentUser } = useAuthContext();

  return (metric, e) => {
    const params = paramsForMetric(metric, e);

    sendMetric(metric, {
      ...params,
      user_id: currentUser?.userId,
      guest_id: currentUser?.guestId,
    });
  };
}

function paramsForMetric(metric, e) {
  if (metric === 'upgrade_cta_click') {
    return {
      url: location.pathname + (location.search ? location.search : ''),
      cta_clicked: e,
    };
  }
}
