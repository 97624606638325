import { MainTextRow, SubTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function CheckoutCompleteEvent({ liveEvent, identifyEvents }) {
  const { total, order_id } = liveEvent.attributes;

  // TODO add currency once added to event

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <span>
          completed checkout{' '}
          {total && (
            <>
              for <span className="text-body-1">{total}</span>
            </>
          )}
        </span>
      </MainTextRow>
      {order_id && <SubTextRow>Order ID #{order_id}</SubTextRow>}
    </div>
  );
}
