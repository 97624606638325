import { useState, useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import { usePopper } from 'react-popper';
import { Button, CloseButton, Popover, TextArea, Spinner, StyledPopoverPanel } from '@crazyegginc/hatch';

import { useMutation, useNotifications, useSelectedSite, usePermissions } from '/src/hooks';
import { updateErrorMutation } from '/src/features/errors/mutations';

import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as PencilIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function AddNote({ fingerprintMd5 }) {
  const buttonRef = useRef();
  const { selectedSite } = useSelectedSite();
  const notifications = useNotifications();
  const permissions = usePermissions();
  const canChangeStatus = permissions.can('edit', FEATURES.ERRORS_TRACKING).allowed;
  const [referenceElement, setReferenceElement] = useState(null);
  const [boxElement, setBoxElement] = useState(null);
  const doUpdateError = useMutation(updateErrorMutation);

  const { styles, attributes } = usePopper(referenceElement, boxElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, 8];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover.Group ref={setReferenceElement}>
      <Popover className="relative">
        {({ open }) => {
          return (
            <>
              <Popover.Button
                ref={buttonRef}
                as={Button}
                disabled={!canChangeStatus}
                variant="secondary"
                leftIcon={<PencilIcon className="mr-2 h-3 w-3 fill-current" />}
              >
                Add note
              </Popover.Button>
              {open && (
                <StyledPopoverPanel
                  ref={setBoxElement}
                  onMouseDown={(e) => e.stopPropagation()}
                  align="left"
                  className="w-[360px] !p-5"
                  style={{ ...styles.popper }}
                  {...attributes.popper}
                >
                  {({ close }) => {
                    return (
                      <div>
                        <CloseButton label="close" onClick={() => close()} />
                        <Formik
                          initialValues={{ note: '' }}
                          onSubmit={(values, actions) => {
                            doUpdateError.mutate(
                              {
                                siteId: selectedSite?.id,
                                note: values.note,
                                fingerprintMd5,
                              },
                              {
                                onError: (error) =>
                                  notifications.error({
                                    content: 'Failed to add note.',
                                    timeout: 3000,
                                    context: { error },
                                  }),
                                onSuccess: () => close(),
                                onSettled: () => actions.setSubmitting(false),
                              },
                            );
                          }}
                        >
                          {({ values, handleChange, handleBlur, isSubmitting }) => {
                            return (
                              <Form>
                                <TextArea
                                  name="note"
                                  id="error_note"
                                  placeholder="Type here..."
                                  value={values.note}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoFocus // eslint-disable-line
                                  rows={4}
                                  className="mb-5 mt-8"
                                />

                                <Button type="submit" disabled={isSubmitting || values.note.length < 1}>
                                  {isSubmitting ? (
                                    <div className="flex items-center">
                                      <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                                      Adding note...
                                    </div>
                                  ) : (
                                    `Add note`
                                  )}
                                </Button>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    );
                  }}
                </StyledPopoverPanel>
              )}
            </>
          );
        }}
      </Popover>
    </Popover.Group>
  );
}
