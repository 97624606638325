import { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { inflect } from '/src/utils/string';
import { useAuthContext, useSite } from '/src/hooks';

import { WebP } from '/src/components/WebP';
import SingleSnapshotPNG from '@crazyegginc/hatch/dist/images/illustration-snapshot-single.png';
import SingleSnapshotWebP from '@crazyegginc/hatch/dist/images/illustration-snapshot-single.webp';
import MultiSnapshotPNG from '@crazyegginc/hatch/dist/images/illustration-snapshots.png';
import MultiSnapshotWebP from '@crazyegginc/hatch/dist/images/illustration-snapshots.webp';
import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

function isValidSnapshotIds(input) {
  return input.every((part) => typeof part === 'number' && !Number.isNaN(part));
}

// eslint-disable-next-line no-restricted-syntax
export default function CreateSnapshotSuccess() {
  const { currentUser } = useAuthContext();

  const location = useLocation();
  const { sites, resetSite, persistSite } = useSite({ dontSelectSite: true });

  const scriptInstalled = currentUser.flags.includes('script_installed');
  const oldSitesIds = useRef(location.state?.oldSitesIds);
  const createdSnapshots = useRef(location.state?.snapshotIds ?? []);

  useEffect(() => {
    if (sites && oldSitesIds.current) {
      const newSite = sites.find((site) => !oldSitesIds.current.includes(site.id));
      if (newSite) {
        persistSite(newSite.name);
        resetSite();
      }
    }
  }, [persistSite, resetSite, sites]);

  if (!createdSnapshots.current.length || !isValidSnapshotIds(createdSnapshots.current)) {
    return <Navigate replace to="/snapshots/new" />;
  }

  const snapshotCount = createdSnapshots.current.length;

  return (
    <div className="flex min-h-screen w-screen items-center justify-center bg-white">
      <div className="w-[500px] px-6 py-11 text-center">
        <div className="flex w-full items-center justify-center">
          {snapshotCount === 1 ? (
            <WebP webp={SingleSnapshotWebP} fallback={SingleSnapshotPNG} width="178px" height="128px" alt="" />
          ) : (
            <WebP webp={MultiSnapshotWebP} fallback={MultiSnapshotPNG} width="187px" height="150px" alt="" />
          )}
        </div>
        <h1 className="text-header-0 mb-10">Success!</h1>
        <div className="text-header-4 flex items-center justify-center">
          <SuccessIcon className="mr-4 h-5 w-5 flex-shrink-0 fill-current text-lima-500" />
          {snapshotCount} {inflect('Snapshot', snapshotCount)} Created
        </div>
        <div className="text-body-2 mb-8 mt-10 px-8 text-base leading-normal">
          Your Snapshot will start to show data within a few minutes, if your tracking script is installed correctly.
          <br />
          <Link to="/snapshots/new" className="text-link text-base">
            Create more Snapshots
          </Link>{' '}
          while you wait.
        </div>
        <div className="flex items-center justify-center space-x-2.5">
          <Button component={Link} variant={scriptInstalled ? 'primary' : 'secondary'} to="/snapshots" replace>
            Return to Dashboard
          </Button>

          {!scriptInstalled && (
            <Button component={Link} variant="primary" to="/install" replace>
              Install Tracking Script
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
