import { Button } from '@crazyegginc/hatch';

import { inflect } from '/src/utils/string';
import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function DeleteRecordingsModal({ onConfirm, numToDelete }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Modal.Title>
        Delete {numToDelete} {inflect('recording', numToDelete)}?
      </Modal.Title>

      <div className="text-body-2">
        If you delete {numToDelete > 1 ? 'these recordings' : 'this recording'}, you will no longer have access to{' '}
        {numToDelete > 1 ? 'them' : 'it'}. This can’t be undone. <br />
        <br />
        Deleting your recordings won’t affect your quota.
      </div>

      <Modal.Actions>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Yes, delete {numToDelete > 1 ? 'these recordings' : 'this recording'}
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
