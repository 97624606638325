import { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { ReactTable, Button, BrowserIcon, Indicator } from '@crazyegginc/hatch';

import { useAuthContext, useModal, useQueryParams } from '/src/hooks';
import { NoMatchesWall } from '../../../_global/paywalls/NoMatchesWall';
import { useErrorsFilter } from '../../errors-filter-context';

import { PaginationControls } from '../../../../components/PaginationControls';
import { formatShortDateTime, getConvertedDateRange } from '/src/utils/date';
import { ErrorDetails } from './ErrorDetails';
import { generateErrorFilterUrl } from '/src/features/errors/utils';
import { LockedRecordingModal } from '/src/features/recordings/components/dashboard/modals/LockedRecordingModal';
import { PlayButton } from '/src/components/PlayButton';

import { errorListQuery, errorListTotalQuery } from '/src/features/errors/queries';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';

import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';

const MAX_ROW_PER_PAGE = 50;

export function ErrorList({ uniqueErrorId, site }) {
  const { isSharing } = useAuthContext();
  const modal = useModal();
  const { get: queryParamsGet, set: queryParamsSet, remove: queryParamsRemove } = useQueryParams();
  const initialOpen = useRef(queryParamsGet('openid'));
  const [page, setPage] = useState(parseInt(queryParamsGet('page'), 10) || 1);
  const { queryParams } = useErrorsFilter();
  const convertedDateRange = getConvertedDateRange(queryParams.dateRange);

  const selectError = useCallback(
    (id) => {
      queryParamsSet('openid', id);
    },
    [queryParamsSet],
  );

  const { data, isLoading } = useQuery({
    ...errorListQuery({
      fingerprintMd5: uniqueErrorId,
      limit: MAX_ROW_PER_PAGE,
      page,
      siteId: site.id,
      field: 'CREATED_AT',
      sort: SORT_ORDER_TYPES.DESC,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
    }),
    enabled: Boolean(uniqueErrorId),
  });

  const { data: dataTotal, isLoading: isLoadingTotal } = useQuery({
    ...errorListTotalQuery({
      fingerprintMd5: uniqueErrorId,
      siteId: site.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
    }),
    enabled: Boolean(uniqueErrorId),
  });

  const errorList = useMemo(() => data?.errorList?.errors ?? [], [data]);
  const onSetPage = useCallback(
    (page) => {
      queryParamsRemove('openid');
      queryParamsSet('page', page);
      setPage(page);
    },
    [queryParamsSet, queryParamsRemove],
  );

  // make sure page is not out of range
  useEffect(() => {
    if (!isLoadingTotal) {
      const newMaxPage = Math.max(Math.ceil(dataTotal?.errorListTotal ?? 0 / MAX_ROW_PER_PAGE), 1);
      if (page > newMaxPage) {
        onSetPage(newMaxPage);
      } else if (page <= 0) {
        onSetPage(1);
      }
    }
  }, [isLoadingTotal, page, onSetPage, dataTotal?.errorListTotal]);

  const columns = useMemo(() => {
    function handleDetailsClick(row, toggleAllRowsExpanded) {
      const isExpanded = row.getIsExpanded();
      if (row.original.permissions.canUpgradeToView) {
        modal.show(<LockedRecordingModal feature="error" />);
      } else {
        selectError(isExpanded ? null : row.original.id);
        toggleAllRowsExpanded(false);
        row.toggleExpanded(!isExpanded);
      }
    }

    const cols = [
      {
        header: 'Timestamp',
        accessorKey: 'createdAt',
        size: 50,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function TimestampCell({ row }) {
          return `${formatShortDateTime(row.original.createdAt)}`;
        },
      },
      {
        header: 'URL',
        accessorKey: 'url',
        size: 170,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function UrlCell({ row }) {
          return <div className="truncate">{row.original.url}</div>;
        },
      },
      {
        header: 'Error Message',
        accessorKey: 'errorMessage',
        size: 150,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function ErrorMessageCell({ row, table }) {
          return (
            <button
              type="button"
              className="inline-block truncate font-semibold text-dodger-blue-500 hover:underline"
              onClick={() => handleDetailsClick(row, table.toggleAllRowsExpanded)}
            >
              {row.original.errorMessage}
            </button>
          );
        },
      },
      {
        header: 'Browser',
        accessorKey: 'browser',
        size: 40,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function BrowserCell({ row }) {
          return row.original?.browser?.name ? <BrowserIcon browser={row.original.browser.name} /> : '';
        },
      },
      {
        header: 'App version',
        accessorKey: 'appVersion',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function AppversionCell({ row }) {
          return row.original.appVersion ?? '-';
        },
      },
      {
        id: 'details',
        size: 45,
        meta: {
          align: 'center',
        },
        cell: function DetailsCell({ row, table }) {
          return (
            <Button
              variant="secondary"
              size="sm"
              className="!w-[85px] shrink-0"
              onClick={() => handleDetailsClick(row, table.toggleAllRowsExpanded)}
            >
              Details
              {!row.original.permissions.canUpgradeToView ? (
                <Indicator type="expand" up={row.getIsExpanded()} className="ml-2.5" />
              ) : null}
            </Button>
          );
        },
      },
    ];

    if (!isSharing) {
      cols.unshift({
        header: 'Play',
        accessorKey: 'hashedId',
        size: 30,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function PlayCell({ row }) {
          const {
            valid,
            deletedAt,
            hashedId,
            sessionViewedAt,
            permissions: { canUpgradeToView },
          } = row.original;
          return (
            <PlayButton
              hashedId={hashedId}
              valid={valid}
              deletedAt={deletedAt}
              canUpgradeToView={canUpgradeToView}
              watched={!!sessionViewedAt}
            />
          );
        },
      });
    }

    return cols;
  }, [isSharing, modal, selectError]);
  const tableRef = useRef(null);

  const renderExpandedComponent = useCallback(
    ({ row }) => <ErrorDetails errorId={row.original.id} site={site} />,
    [site],
  );

  const initalExpanded = useMemo(() => ({ [initialOpen.current]: true }), []);

  if (!isLoading && !errorList.length) {
    return <NoMatchesWall item="errors" text="errors within that date range, try adjusting the date range" />;
  }

  return (
    <>
      <div className="mb-[15px] flex w-full items-end justify-between">
        <div className="text-header-3">Occurrences of this error</div>
        {!isLoading && !isSharing && (
          <Button
            component={Link}
            variant="secondary"
            size="lg"
            to={{
              pathname: '/recordings',
              search: `filters=${generateErrorFilterUrl(uniqueErrorId, convertedDateRange)}&site=${site.name}&groupBy=${
                GROUP_BY_TYPES.LIST_VIEW
              }`,
            }}
          >
            <RecordingIcon className="mr-2 h-4 w-4 fill-current" />
            View all recordings
          </Button>
        )}
      </div>
      <div className="mb-8 w-full pb-8">
        <ReactTable
          ref={{ tableRef }}
          fetching={isLoading}
          rowPadding={true}
          columns={columns}
          data={errorList}
          enableSorting={false}
          rowHeight={50}
          rowCustomBackground={(row) =>
            classNames({
              'bg-white-lilac-500 hover:bg-white': row && row.original.sessionViewedAt,
            })
          }
          rowCustomClassname={(row) =>
            classNames({
              'font-semibold': row && row.getIsExpanded(),
            })
          }
          renderExpandedComponent={renderExpandedComponent}
          initialExpanded={initalExpanded}
        />

        {!isLoadingTotal && Boolean(dataTotal?.errorListTotal) ? (
          <div className="mt-3 w-full">
            <PaginationControls
              currentPage={page}
              perPage={MAX_ROW_PER_PAGE}
              total={dataTotal.errorListTotal}
              setPage={(page) => onSetPage(page)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
