import { useState } from 'react';
import { Checkbox, Button, Spinner } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useNotifications } from '/src/hooks';
import { setUserNotificationsMutation } from '/src/features/account/mutations';
import { Card } from './Card';

export function CrazyEggEmails() {
  const { currentUser } = useAuthContext();
  const notifications = useNotifications();

  const [values, setValues] = useState(currentUser.userNotifications);
  const setUserNotifications = useMutation(setUserNotificationsMutation);

  function handleSave() {
    setUserNotifications.mutate(
      { ...values },
      {
        onError: (error) =>
          notifications.error({
            content: 'Failed to update email notification settings.',
            timeout: 3000,
            context: { error },
          }),
      },
    );
  }

  return (
    <Card heading="Email notifications" cardClassName="mb-[30px]">
      <Card.WrapperSection>
        <Card.LeftSection>
          <div className="flex flex-col gap-y-1.5 pl-1">
            <Checkbox
              onChange={() => setValues((x) => ({ ...x, weeklyUpdate: !x.weeklyUpdate }))}
              label="Send me a weekly summary of my activity"
              checked={values.weeklyUpdate}
              id="weeklyUpdate"
            />
            <Checkbox
              onChange={() => setValues((x) => ({ ...x, accountLimits: !x.accountLimits }))}
              label="Notify me when I'm reaching the limits of my plan"
              checked={values.accountLimits}
              id="accountLimits"
            />
            <Checkbox
              onChange={() => setValues((x) => ({ ...x, receipt: !x.receipt }))}
              label="Email me a receipt when my plan is renewed"
              checked={values.receipt}
              id="receipt"
            />
          </div>
        </Card.LeftSection>
        <Card.RightSection>
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={handleSave}
            disabled={setUserNotifications.isLoading}
          >
            {setUserNotifications.isLoading ? (
              <>
                <Spinner className="mr-2 h-3 w-3" />
                Saving...
              </>
            ) : (
              'Save changes'
            )}
          </Button>
        </Card.RightSection>
      </Card.WrapperSection>
    </Card>
  );
}
