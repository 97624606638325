import React from 'react';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export function AutoUpdateUrlInput({ redirectedTo, setDidRedirect }) {
  const { values, resetForm } = useFormikContext();
  useEffect(() => {
    if (redirectedTo && redirectedTo !== values.url) {
      resetForm({
        values: {
          ...values,
          url: redirectedTo,
        },
      });
      setDidRedirect(false);
    }
  }, [setDidRedirect, resetForm, values, redirectedTo]);

  return null;
}
