import { useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { Button, IconButton, Tooltip, Select, MultiSelect, Input } from '@crazyegginc/hatch';

import { returnURLTypeValue, urlTypeLabel } from '../../../common/common-functions';
import {
  getRatingValueOptions,
  getMultiChoiceValueOptions,
  getDefaultLogicStatement,
  getLogicStatementsForEveryChoice,
} from '../editor-functions';
import { useEditorContext } from '../../../editor-context';
import { normalizeUrl } from '/src/utils/url';

import {
  SURVEY_QUESTION_TYPES,
  LOGIC_COMPARISONS,
  MULTI_CHOICE_SUBTYPES,
  STATIC_DISPLAY_SUBTYPES,
  RATING_SUBTYPES,
  SHOW_QUESTION_OPTIONS,
  LOGIC_ACTIONS,
  ADDONS_CAPABILITIES,
  PREVIEW_READY,
} from '/src/features/addons/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-move-dots.svg';

export function SurveyLogic({ index }) {
  const { readonly } = useEditorContext();
  const { values } = useFormikContext();

  if (readonly && values.questions[index].logic.statements.length === 0) return null;

  return (
    <div className="text-body-2 !-mb-7 -ml-10 -mr-5 flex flex-col rounded-b-[5px] border-t border-mystic-500 bg-white-lilac-500 px-5 py-6">
      <FieldArray name={`questions[${index}].logic.statements`}>
        {(actions) => <SurveyLogicContent actions={actions} index={index} />}
      </FieldArray>
    </div>
  );
}

function SurveyLogicContent({ index, actions }) {
  const { readonly } = useEditorContext();
  const { values } = useFormikContext();

  const q = values.questions[index];
  const { move } = actions;

  useLayoutEffect(() => {
    // is-submitted must be on the last position
    const isSubmittedRowIndex = q.logic.statements.findIndex(
      (statement) => statement.conditional.conditions[0].comparison === LOGIC_COMPARISONS.IS_SUBMITTED,
    );
    const lastIndex = q.logic.statements.length - 1;
    if (isSubmittedRowIndex > -1 && isSubmittedRowIndex !== lastIndex) {
      move(isSubmittedRowIndex, lastIndex);
    }
  }, [move, q]);

  return (
    <>
      {q.logic.statements.length > 0 && (
        <div className="-ml-1.25 mb-5 space-y-2.5">
          {q.logic.statements.map((statement, i) => (
            <LogicStatementRow
              key={`statement-${statement.id}`}
              questionIndex={index}
              statementIndex={i}
              actions={actions}
              readonly={readonly}
            />
          ))}
        </div>
      )}

      {!readonly && <ActionButtonRow questionIndex={index} actions={actions} />}
    </>
  );
}

function LogicStatementRow({ questionIndex, statementIndex, actions, readonly }) {
  const { values } = useFormikContext();
  const ref = useRef(null);
  const dragRef = useRef(null);

  const q = values.questions[questionIndex];

  const canReorder =
    !readonly &&
    q.logic.statements[statementIndex].conditional.conditions[0].comparison !== LOGIC_COMPARISONS.IS_SUBMITTED;

  const [, drop] = useDrop({
    accept: 'logicStatement',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop: () => canReorder,
    hover(item, monitor) {
      if (!ref.current || !monitor.canDrop()) {
        return;
      }
      const dragIndex = item.statementIndex;
      const hoverIndex = statementIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      actions.move(dragIndex, hoverIndex);
      item.statementIndex = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'logicStatement',
    item: () => {
      return { id: q.id, statementIndex };
    },
    canDrag: () => canReorder,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  drag(dragRef);

  return (
    <div
      className={classNames('group relative flex items-start', { 'opacity-0': isDragging })}
      ref={ref}
      data-testid="logic-row"
    >
      <div className="flex h-10 items-center">
        <div
          className={classNames('invisible group-hover:visible', {
            'cursor-[grab] text-cadet-blue-500': canReorder,
            'cursor-not-allowed text-cadet-blue-500/50': !canReorder,
          })}
          ref={dragRef}
        >
          <MoveIcon className="h-4 w-4 fill-current" />
        </div>
        <div className="ml-2.5 w-4 font-mono text-cadet-blue-500">{statementIndex + 1}.</div>
        <div className="ml-2">If {q.type === SURVEY_QUESTION_TYPES.RATING ? 'rating' : 'answer'}: </div>
      </div>

      <div className="flex w-[286px] grow-0 items-start">
        <ComparisonSelector questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />
        <ValueSelector questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />
      </div>

      <div className="ml-2.5 flex h-10 items-center">then</div>

      <ActionSelector questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />

      <div className="ml-4 flex h-10 w-5 shrink-0 items-center">
        {!readonly && (
          <IconButton
            icon={<CrossIcon className="h-3 w-3 fill-current" />}
            className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
            onClick={() => {
              actions.remove(statementIndex);
            }}
            label="delete logic statement"
          />
        )}
      </div>
    </div>
  );
}

function ComparisonSelector({ questionIndex, statementIndex, readonly }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const q = values.questions[questionIndex];
  const moreThanOneStatement = q.logic.statements.length > 1;

  const alreadyHasAnyAnswer = [...q.logic.statements]
    .filter((_, i) => i !== statementIndex)
    .some((statement) => statement.conditional.conditions[0].comparison === LOGIC_COMPARISONS.IS_SUBMITTED);

  const options = useMemo(() => {
    let result = [{ label: 'is exactly', value: LOGIC_COMPARISONS.IS_EXACTLY }];

    if (!alreadyHasAnyAnswer) {
      result.push({
        label: moreThanOneStatement ? 'is any other answer' : 'is any answer',
        value: LOGIC_COMPARISONS.IS_SUBMITTED,
      });
    }
    if (q.optional) {
      result.push({ label: 'is skipped', value: LOGIC_COMPARISONS.IS_SKIPPED });
    }
    if (
      q.type === SURVEY_QUESTION_TYPES.TEXT_ENTRY ||
      (q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE && q.subtype === MULTI_CHOICE_SUBTYPES.MULTI)
    ) {
      result.push({ label: 'contains', value: LOGIC_COMPARISONS.CONTAINS });
    }
    if (q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE && q.subtype === MULTI_CHOICE_SUBTYPES.MULTI) {
      result.push({ label: 'includes at least one of', value: LOGIC_COMPARISONS.IS_ANY_OF });
    }
    return result;
  }, [q.type, q.subtype, q.optional, moreThanOneStatement, alreadyHasAnyAnswer]);

  const currentValue = q.logic.statements[statementIndex].conditional.conditions[0].comparison;

  useEffect(() => {
    if (!readonly && currentValue) {
      // if the current option is not available anymore (e.g. due to question type change), set it to undefined to trigger a validation error.
      if (options.every((option) => option.value !== currentValue)) {
        setFieldValue(
          `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].comparison`,
          undefined,
        );
      }
    }
  }, [options, currentValue, questionIndex, statementIndex, setFieldValue, readonly]);

  return (
    <div
      className={classNames('ml-2.5 grow', {
        'w-[102px] !grow-0':
          q.subtype === MULTI_CHOICE_SUBTYPES.MULTI && currentValue !== LOGIC_COMPARISONS.IS_SUBMITTED,
      })}
    >
      <Select
        size="lg"
        options={options}
        value={currentValue}
        aria-label="comparison selector"
        onChange={(value) => {
          if (value === LOGIC_COMPARISONS.IS_SKIPPED || value === LOGIC_COMPARISONS.IS_SUBMITTED) {
            setFieldValue(
              `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
              [],
            );
          }
          setFieldValue(
            `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].comparison`,
            value,
          );
        }}
        error={
          errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]
            ?.comparison ? (
            <div>
              {
                errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]
                  ?.comparison
              }
            </div>
          ) : null
        }
        disabled={readonly}
      />
    </div>
  );
}

function ValueSelector({ questionIndex, statementIndex, readonly }) {
  const { values } = useFormikContext();

  const q = values.questions[questionIndex];

  if (
    [LOGIC_COMPARISONS.IS_SUBMITTED, LOGIC_COMPARISONS.IS_SKIPPED].includes(
      q.logic.statements[statementIndex].conditional.conditions[0].comparison,
    )
  ) {
    return null;
  }

  if (q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE) {
    if (q.subtype === MULTI_CHOICE_SUBTYPES.MULTI) {
      return (
        <ValueSelectorMultiSelect questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />
      );
    } else if (q.subtype === MULTI_CHOICE_SUBTYPES.SINGLE) {
      return (
        <ValueSelectorSingleSelect questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />
      );
    }
  } else if (q.type === SURVEY_QUESTION_TYPES.TEXT_ENTRY) {
    return <ValueSelectorText questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />;
  } else if (q.type === SURVEY_QUESTION_TYPES.RATING) {
    return <ValueSelectorRating questionIndex={questionIndex} statementIndex={statementIndex} readonly={readonly} />;
  }
}

function ValueSelectorRating({ questionIndex, statementIndex, readonly }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const q = values.questions[questionIndex];

  const options = useMemo(
    () => getRatingValueOptions({ subtype: q.subtype, min: q.min.value, max: q.max.value }),
    [q.subtype, q.max.value, q.min.value],
  );

  const currentValue = q.logic.statements[statementIndex].conditional.conditions[0].values[0];

  useEffect(() => {
    if (!readonly && currentValue) {
      if (options.every((option) => option.value !== currentValue))
        setFieldValue(
          `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
          [],
        );
    }
  }, [options, currentValue, questionIndex, statementIndex, setFieldValue, readonly]);

  return (
    <div
      className={classNames('ml-2.5 shrink-0', {
        'w-16': q.subtype === RATING_SUBTYPES.NUMERICAL,
        'w-36': q.subtype === RATING_SUBTYPES.STARS || q.subtype === RATING_SUBTYPES.NPS,
        'w-[66px]': q.subtype === RATING_SUBTYPES.SMILEY,
      })}
    >
      <Select
        size="lg"
        placeholder="Select from choices"
        aria-label="value selector"
        options={options}
        value={currentValue}
        onChange={(value) => {
          setFieldValue(
            `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
            [value],
          );
        }}
        error={
          errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]?.values
        }
        disabled={readonly}
      />
    </div>
  );
}

function ValueSelectorText({ questionIndex, statementIndex, readonly }) {
  const { values, errors, handleChange, handleBlur } = useFormikContext();

  const q = values.questions[questionIndex];

  return (
    <div className="ml-2.5 w-[164px] shrink-0">
      <Input
        size="lg"
        name={`questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values[0]`}
        value={q.logic.statements[statementIndex].conditional.conditions[0].values[0]}
        error={
          errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]?.values
        }
        onChange={handleChange}
        onBlur={handleBlur}
        aria-label="value selector"
        disabled={readonly}
      />
    </div>
  );
}

function ValueSelectorSingleSelect({ questionIndex, statementIndex, readonly }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const q = values.questions[questionIndex];
  const options = useMemo(() => getMultiChoiceValueOptions(q.options), [q.options]);

  const currentValue = q.logic.statements[statementIndex].conditional.conditions[0].values[0];

  useEffect(() => {
    if (!readonly && currentValue) {
      if (options.every((option) => option.value !== currentValue))
        setFieldValue(
          `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
          [],
        );
    }
  }, [options, currentValue, questionIndex, statementIndex, setFieldValue, readonly]);

  return (
    <div className="ml-2.5 w-[164px] shrink-0">
      <Select
        size="lg"
        placeholder="Select from choices"
        aria-label="value selector"
        options={options}
        value={currentValue}
        onChange={(value) => {
          setFieldValue(
            `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
            [value],
          );
        }}
        error={
          errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]?.values
        }
        disabled={readonly}
      />
    </div>
  );
}

function ValueSelectorMultiSelect({ questionIndex, statementIndex, readonly }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const q = values.questions[questionIndex];
  const options = useMemo(() => getMultiChoiceValueOptions(q.options), [q.options]);

  const currentValues = q.logic.statements[statementIndex].conditional.conditions[0].values;

  useEffect(() => {
    if (!readonly && currentValues.length) {
      let validValues = currentValues.filter((x) => options.some((option) => option.value === x));
      if (validValues.length !== currentValues.length)
        setFieldValue(
          `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
          validValues,
        );
    }
  }, [options, currentValues, questionIndex, statementIndex, setFieldValue, readonly]);

  return (
    <div className="ml-2.5 w-[164px] shrink-0">
      <MultiSelect
        size="lg"
        placeholder="Select from choices"
        aria-label="value selector"
        options={options}
        value={currentValues}
        onChange={(value) => {
          setFieldValue(
            `questions[${questionIndex}].logic.statements[${statementIndex}].conditional.conditions[0].values`,
            value,
          );
        }}
        error={
          errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.conditional?.conditions?.[0]?.values
        }
        disabled={readonly}
      />
    </div>
  );
}

function ActionSelector({ questionIndex, statementIndex, readonly }) {
  const { values, errors, setFieldValue, handleBlur } = useFormikContext();
  const { addonsCapabilities } = useEditorContext();
  const isPreviewReady = addonsCapabilities[PREVIEW_READY];
  const haveURLTypes = addonsCapabilities[ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE];
  const q = values.questions[questionIndex];
  const currentValue = q.logic.statements[statementIndex].actions[0].editorAction;

  const options = useMemo(() => {
    let result = [
      { label: 'Show next question', value: SHOW_QUESTION_OPTIONS.NEXT },
      { label: 'Show first question', value: SHOW_QUESTION_OPTIONS.FIRST },
      { label: 'Show last question', value: SHOW_QUESTION_OPTIONS.LAST },
    ];

    result.push({ label: 'Close Survey', value: LOGIC_ACTIONS.CLOSE });

    result.push(
      { label: 'Open URL in same window', value: 'open-same' },
      {
        label: 'Open URL in new tab',
        value: 'open-tab',
      },
      { label: 'Open URL in new window', value: 'open-window' },
    );

    if (haveURLTypes) {
      result.push({ label: 'Send an email', value: 'open-mail' }, { label: 'Make a call', value: 'open-tel' });
    }

    values.questions.forEach((q, i) => {
      if (i !== questionIndex && q.subtype !== STATIC_DISPLAY_SUBTYPES.THANK_YOU) {
        result.push({
          label: `Show ${i + 1}. — ${q.title}`,
          value: `id-${q.id}`,
        });
      }
    });

    if (values.questions[values.questions.length - 1].subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU) {
      result.push({ label: 'Show thank you message', value: SHOW_QUESTION_OPTIONS.THANK_YOU });
    }

    return result;
  }, [questionIndex, values.questions, haveURLTypes]);

  useEffect(() => {
    // isPreviewReady is for haveURLTypes, since first render haveURLTypes will be false, const options will not have the value of editorAction for open-mail or open-tel and it will throw an error
    if (isPreviewReady) {
      if (!readonly && currentValue && !options.some((option) => option.value === currentValue)) {
        setFieldValue(
          `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].editorAction`,
          undefined,
        );
      }
    }
  }, [options, currentValue, questionIndex, statementIndex, setFieldValue, readonly, isPreviewReady]);

  return (
    <div className="ml-2.5 flex grow items-start">
      <div className="w-28 grow">
        <Select
          size="lg"
          placeholder="Select an action"
          aria-label="action selector"
          options={options}
          value={currentValue}
          error={
            errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.actions?.[0]?.action ??
            errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.actions?.[0]?.questionId
          }
          onChange={(value) => {
            setFieldValue(
              `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].editorAction`,
              value,
            );
            /^open-/.test(value)
              ? setFieldValue(
                  `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].url`,
                  normalizeUrl(q.logic.statements[statementIndex].actions[0].url, { appendSlash: false }),
                )
              : null;
          }}
          disabled={readonly}
        />
      </div>
      {(/^open-/.test(currentValue) || (haveURLTypes && /mailto-link|tel-link/.test(currentValue))) && (
        <>
          <div className="flex h-10 items-center">
            <Tooltip tooltipContent={<div className="max-w-xs">Opening a URL will also close the survey.</div>}>
              <InfoIcon
                aria-label="information on action"
                className="ml-0.5 h-4 w-4 fill-current text-dodger-blue-300"
              />
            </Tooltip>
          </div>
          <div className="ml-2.5 w-28 grow">
            <Input
              size="lg"
              aria-label="URL according to action"
              placeholder={`Enter ${urlTypeLabel(currentValue)}`}
              name={`questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].url`}
              value={q.logic.statements[statementIndex].actions[0].url}
              error={errors?.questions?.[questionIndex]?.logic?.statements?.[statementIndex]?.actions?.[0]?.url}
              onChange={(e) => {
                if (haveURLTypes) {
                  const config = {
                    selectedType: currentValue,
                    value: e.target.value,
                    fieldValueKeyType: `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].editorAction`,
                    fieldValueKeyURL: `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].url`,
                    setFieldValue,
                    isSurvey: true,
                  };
                  returnURLTypeValue(config);
                } else {
                  setFieldValue(
                    `questions[${questionIndex}].logic.statements[${statementIndex}].actions[0].url`,
                    normalizeUrl(e.target.value, { appendSlash: false }),
                  );
                }
              }}
              onBlur={handleBlur}
              disabled={readonly}
            />
          </div>
        </>
      )}
    </div>
  );
}

function ActionButtonRow({ questionIndex, actions }) {
  const { values, setFieldValue } = useFormikContext();

  const q = values.questions[questionIndex];

  return (
    <div className="flex items-center space-x-2.5">
      <Button
        variant="secondary"
        onClick={() => actions.push(getDefaultLogicStatement(values.questions, questionIndex))}
      >
        <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
        Add Logic
      </Button>
      {(q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE || q.type === SURVEY_QUESTION_TYPES.RATING) && (
        <Button
          variant="ghost-primary"
          onClick={() =>
            setFieldValue(
              `questions[${questionIndex}].logic.statements`,
              [...q.logic.statements, ...getLogicStatementsForEveryChoice(values.questions, questionIndex)],
              false,
            )
          }
        >
          Add logic for every choice
        </Button>
      )}
      <Tooltip
        tooltipContent={
          <div className="max-w-xs">
            Define what survey responders will see when they answer a certain way. The next question, a specific
            question, end the survey etc.
          </div>
        }
      >
        <InfoIcon aria-label="information on next button" className="h-4 w-4 fill-current text-dodger-blue-300" />
      </Tooltip>
    </div>
  );
}
