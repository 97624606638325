import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';
import { formatCurrency } from '/src/utils';

export function DisplayCurrency({
  value,
  precision = 10 ** 4,
  textOnly = false,
  appendCurrency = true,
  trimEmptyCents = false,
  className = '',
}) {
  if (!value || typeof value.displayWorth !== 'number') return null;

  // TODO: if value.displayCurrency !== value.originalCurrency
  return (
    <Tooltip
      show={value.isConverted}
      tooltipContent={
        <div className="flex flex-col items-center">
          <span>
            <strong>{formatCurrency(value.originalWorth / precision, value.originalCurrency)}</strong>{' '}
            <span className="text-caption text-mystic-500">{value.originalCurrency}</span>
          </span>
          <span className="text-caption text-mystic-500">Original currency</span>
        </div>
      }
    >
      <div className={classNames('flex', { 'flex-col': !textOnly, 'space-x-1': textOnly, [className]: true })}>
        <span>{formatCurrency(value.displayWorth / precision, value.displayCurrency, { trimEmptyCents })}</span>
        {appendCurrency ? (
          <span className={classNames({ 'text-caption': !textOnly })}>
            {value.displayCurrency}
            {value.isConverted ? '*' : ''}
          </span>
        ) : null}
      </div>
    </Tooltip>
  );
}
