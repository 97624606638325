import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Checkbox, Input } from '@crazyegginc/hatch';

import { useMetric, usePermissions } from '/src/hooks';
import { useEditorContext } from '../../editor-context';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';
import { MARKDOWN_TYPES, ADDONS_CAPABILITIES, ADDON_TYPES } from '/src/features/addons/constants';

import { MarkdownTooltip } from '/src/features/addons/common/editor/MarkdownTooltip';

export function RemoveBranding() {
  const { readonly, type, addonsCapabilities } = useEditorContext();
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } = useFormikContext();
  const [enableCustomBranding, setEnableCustomBranding] = useState(!!values.theme.customBrandingText);
  const permissions = usePermissions();
  const trackMetric = useMetric();

  const canRemoveBranding =
    type === ADDON_TYPES.CTA
      ? permissions.can('removeBranding', FEATURES.CTAS).allowed
      : permissions.can('removeBranding', FEATURES.SURVEYS).allowed;

  const canCustomizeBranding = addonsCapabilities[ADDONS_CAPABILITIES.CUSTOM_BRANDING];

  useEffect(() => {
    if (!readonly && !canRemoveBranding) {
      setFieldValue('theme.removeBranding', false);
      setFieldValue('theme.customBrandingText', '');
      setEnableCustomBranding(false);
    }
  }, [setFieldValue, canRemoveBranding, readonly]);

  return (
    <div>
      <div className="text-body-1 mb-px block">Branding</div>
      <div className="-ml-1.25 mb-0.5 mt-1.5 flex items-center">
        <div className="shrink-0">
          <Checkbox
            label="Remove Crazy Egg branding"
            id="removeCEBranding"
            name="theme.removeBranding"
            checked={values.theme.removeBranding}
            onChange={handleChange}
            disabled={readonly || !canRemoveBranding || (values.theme.removeBranding && enableCustomBranding)}
          />
        </div>
      </div>

      {canCustomizeBranding && values.theme.removeBranding && (
        <>
          <div className="-ml-1.25 mb-1 mt-1.5 flex items-center">
            <div className="shrink-0">
              <Checkbox
                label="Replace branding text"
                id="replaceBrandingText"
                name="replaceBrandingText"
                checked={enableCustomBranding}
                onChange={() => {
                  if (enableCustomBranding) {
                    setFieldValue('theme.customBrandingText', '');
                  }
                  setEnableCustomBranding(!enableCustomBranding);
                }}
                disabled={readonly || !canRemoveBranding}
              />
            </div>
          </div>
          <div className="ml-6">
            <Input
              id="theme.customBrandingText"
              name="theme.customBrandingText"
              placeholder="Your text here."
              value={values.theme.customBrandingText}
              error={
                errors.theme?.customBrandingText && touched.theme?.customBrandingText
                  ? errors.theme?.customBrandingText
                  : null
              }
              onChange={handleChange}
              onBlur={handleBlur}
              size="lg"
              disabled={readonly || !enableCustomBranding}
            />
            <div className="text-caption mb-[-8px] mt-1.25 flex h-3.5 items-center justify-end space-x-2.5">
              <MarkdownTooltip markdownType={MARKDOWN_TYPES.INLINE} />
            </div>
          </div>
        </>
      )}

      {!canRemoveBranding && (
        <div className="text-body-2 ml-[26px]">
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'addon_remove_branding')}
          >
            Upgrade to Plus plan
          </Link>{' '}
          or higher to remove branding
        </div>
      )}
    </div>
  );
}
