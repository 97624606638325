import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';

import { usePermissions } from '/src/hooks';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

import { FunnelDashboardContent } from '../components/FunnelDashboardContent';

// eslint-disable-next-line no-restricted-syntax
export default function FunnelDashboard() {
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.FUNNEL);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.FUNNEL} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.FUNNEL)} />
      <DashHeader title={getFeatureName(FEATURES.FUNNEL)} />
      <FunnelDashboardContent />
    </DashboardPage>
  );
}
