import { PlayerEvent } from '../PlayerEvent';
import { ReactComponent as BackgroundedIcon } from '@crazyegginc/hatch/dist/images/icon-square-dashed.svg';

export function BackgroundedEvent({ timestamp, active, duration, ...rest }) {
  return (
    <PlayerEvent
      icon={BackgroundedIcon}
      title="Background"
      text={duration && <time dateTime={`PT${duration}S`}>{duration}s</time>}
      timestamp={timestamp}
      active={active}
      {...rest}
    />
  );
}
