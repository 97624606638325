import { formatDistance, fromUnixTime } from 'date-fns';
import classNames from 'classnames';
import { capitalize } from '@crazyegginc/hatch';
import { Link } from 'react-router-dom';

import { Modal } from '/src/contexts/modal';
import { useModal } from '/src/hooks';
import { AdjustWeightsModal } from '/src/features/ab-testing/components/modals/AdjustWeights';
import { formatDate } from '/src/utils/date';
import { displayUrl, normalizeUrl } from '/src/utils/url';
import { ClipboardButton } from '/src/components/ClipboardButton';

import { AB_TEST_STATUSES, abTestTypes } from '/src/features/ab-testing/constants';

import { ReactComponent as PencilIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function AbTestDetailModal({ data }) {
  const modal = useModal();
  const isDraft = data.status === AB_TEST_STATUSES.DRAFT;

  function getDuration() {
    if (isDraft) return 'Not yet launched.';
    const start = data?.publishedAt
      ? fromUnixTime(data.publishedAt)
      : data?.createdAt
        ? fromUnixTime(data.createdAt)
        : null;
    if (!start) return 'No data collected yet.';
    const end = data?.stoppedAt ? fromUnixTime(data.stoppedAt) : Date.now();
    if (data?.stoppedAt) {
      return `${formatDistance(start, end)} (${formatDate(data?.publishedAt || data?.createdAt)} - ${formatDate(
        data?.stoppedAt,
      )})`;
    }
    if (data?.publishedAt) {
      return `${formatDistance(start, end)} (Published ${formatDate(data?.createdAt)})`;
    }
    return `${formatDistance(start, end)} (Created ${formatDate(data?.createdAt)})`;
  }

  const testId = data.id;
  const control = data.variants.find((v) => v.type === 'CONTROL');
  const variants = data.variants.filter((v) => v.type === 'VARIANT');

  const showSourceUrl = data?.type === abTestTypes.PATCH && data.matchingUrl !== data.pageUrl;

  return (
    <Modal dialogClassName="w-[640px]">
      <div className="text-header-3 mb-3.75">Test details</div>

      <div className="w-full rounded bg-white-lilac-500">
        <Row title="Test Type">{data?.type === abTestTypes.PATCH ? 'Page editor' : 'URL redirect'}</Row>
        <Row title="Traffic split">
          {data?.autoReweight ? 'Multi-arm bandit - Powered by AI' : 'Manual'}
          {data?.permissions.canAdjustWeight ? (
            <button
              type="button"
              className="text-link ml-4 flex items-center leading-none"
              onClick={() => modal.show(<AdjustWeightsModal abTest={data} />)}
            >
              <PencilIcon className="mr-1 h-3 w-3 fill-current text-dodger-blue-500" />
              Edit
            </button>
          ) : null}
        </Row>
        {!isDraft ? <Row title="Visitors">{(data?.totalVisitors ?? 0).toLocaleString()}</Row> : null}
        <Row
          title="Matching URL"
          classNames="flex items-center justify-between truncate w-full"
          canCopy={true}
          copyValue={normalizeUrl(data.matchingUrl, { appendSlash: false })}
        >
          <span className="truncate" title={normalizeUrl(data.matchingUrl, { appendSlash: false })}>
            {displayUrl(data.matchingUrl)}
          </span>
        </Row>
        {showSourceUrl ? (
          <Row
            title="Source URL"
            classNames="flex items-center justify-between truncate w-full"
            canCopy={true}
            copyValue={normalizeUrl(data.pageUrl, { appendSlash: false })}
          >
            <span className="truncate" title={normalizeUrl(data.pageUrl, { appendSlash: false })}>
              {displayUrl(data.pageUrl)}
            </span>
          </Row>
        ) : null}
        <Row title="Default Goal">
          <Link
            className="text-link"
            to={`/goals/${data.goal.id}`}
            onClick={() => {
              modal.close();
            }}
          >
            {data.goal.name}
          </Link>
        </Row>
        <Row title="Device" className="space-x-3">
          {humanizeDeviceString(data?.devices)}
        </Row>
        <Row title="Run time">{getDuration()}</Row>
      </div>

      <TestIdsGrid testId={testId} control={control} variants={variants} />
    </Modal>
  );
}

function humanizeDeviceString(devices) {
  const deviceMap = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    PHONE: 'mobile',
  };

  const deviceNames = devices.map((device, index) => (index === 0 ? capitalize(deviceMap[device]) : deviceMap[device]));

  if (deviceNames.length === 1) {
    return `${deviceNames[0]} visitors only`;
  } else {
    const lastDevice = deviceNames.pop();
    return `${deviceNames.join(', ')} and ${lastDevice} visitors`;
  }
}

function Row({ title, children, className, canCopy = false, copyValue = null }) {
  return (
    <div className="flex h-10 w-full items-center border-b border-dashed border-mystic-500 last:border-b-0">
      <div className="text-header-5 w-32 pl-4 flex-shrink-0">{title}</div>
      <div className={classNames('text-body-5 flex items-center text-black-pearl-500 w-full truncate', className)}>
        {children}
      </div>
      {canCopy ? (
        <div className="relative w-[36px] h-[35px] flex-shrink-0 flex items-center justify-center">
          <ClipboardButton value={copyValue ? copyValue : children} />
        </div>
      ) : null}
    </div>
  );
}

function GridItem({ title, children, skipBottomBorder = false, skipRightBorder = false, className }) {
  return (
    <div
      className={classNames('flex h-10 w-full items-center', {})}
      style={{
        borderBottom: skipBottomBorder ? '0' : '1px dashed rgb(225 230 239)',
        borderRight: skipRightBorder ? '0' : '1px solid rgb(225 230 239)',
      }}
    >
      <div className="text-header-5 w-32 pl-4">{title}</div>
      <div className={classNames('text-body-5 flex items-center text-black-pearl-500', className)}>{children}</div>
    </div>
  );
}

function getTestIdRowLength(input) {
  if (input <= 4) return 3;
  return Math.floor((input + 1) / 2) + 1;
}

function TestIdsGrid({ testId, control, variants }) {
  const rowLength = getTestIdRowLength(variants.length);
  const gridTemplateRows = `repeat(${rowLength}, minmax(0, 1fr))`;
  return (
    <div className="mt-5 w-full rounded bg-white-lilac-500">
      <div
        className={classNames('text-body-5 grid w-full grid-flow-col items-center', {
          'grid-cols-1': variants.length === 1,
          'grid-rows grid-cols-2': variants.length > 1,
        })}
        style={{ gridTemplateRows }}
      >
        <GridItem title="Test ID">{testId}</GridItem>
        <GridItem title="Control ID">{control.id}</GridItem>
        {ensureArrayLength(variants, variants.length > 1 ? rowLength * 2 - 2 : 1).map((v, idx) => {
          if (v) {
            return (
              <GridItem
                key={`grid-item:${idx}`}
                title={`Variant ${v.position} ID`}
                skipBottomBorder={idx + 3 === rowLength || idx + 3 === rowLength * 2}
                skipRightBorder={idx + 3 > rowLength}
              >
                {v.id}
              </GridItem>
            );
          }
          return (
            <GridItem
              key={`grid-item:${idx}`}
              title=""
              skipBottomBorder={idx + 3 === rowLength || idx + 3 === rowLength * 2}
              skipRightBorder={idx + 3 > rowLength}
            >
              &nbsp;
            </GridItem>
          );
        })}
      </div>
    </div>
  );
}
function ensureArrayLength(arr, minLength) {
  if (arr.length < minLength) {
    return [...arr, ...Array(minLength - arr.length).fill(null)];
  }
  return arr;
}
