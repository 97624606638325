import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

export function Tab({ children, ...props }) {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames('text-header-4 flex h-9 items-start space-x-6 px-6 leading-tight text-dodger-blue-500', {
          'border-b-2 border-dodger-blue-500': isActive,
        })
      }
    >
      {children}
    </NavLink>
  );
}

export function SlimTab({ active = false, children, ...props }) {
  return (
    <button
      {...props}
      className={classNames(
        'text-header-4 flex h-[53px] items-center space-x-6 border-b-2 px-6 leading-tight text-dodger-blue-500',
        {
          'border-dodger-blue-500': active,
          'border-transparent': !active,
        },
      )}
    >
      {children}
    </button>
  );
}

export function TabHeader({ title = '', children }) {
  return (
    <header className="mb-10 flex flex-col border-b border-mystic-500 bg-white px-10">
      <div className="flex items-center py-5">
        <h1 className="text-header-1 my-4 leading-none">{title}</h1>
      </div>
      <div className="flex h-[53px] min-w-[830px] items-end">{children}</div>
    </header>
  );
}

export function SlimTabHeader({ children }) {
  return (
    <header className="flex flex-col border-b border-mystic-500 bg-white">
      <div className="flex h-[53px]">{children}</div>
    </header>
  );
}
