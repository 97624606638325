import { gql } from '@urql/core';

import { whatsNewFeatureKeys } from './queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const viewChangelogEventsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ViewChangelogEvents($ids: [Int!]!) {
          viewChangelogEvents(ids: $ids)
        }
      `,
      { ids },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: whatsNewFeatureKeys.changelog._def,
    });
  },
});

export const changelogReactionMutation = ({ client, queryClient }) => ({
  mutationFn: ({ eventId, reaction }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ChangelogReaction($eventId: Int!, $reaction: String!) {
          changelogReaction(eventId: $eventId, reaction: $reaction) {
            id
            seenAt
            reaction
          }
        }
      `,
      {
        eventId,
        reaction,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: whatsNewFeatureKeys.changelog._def,
    });
  },
});
