import { MainTextRow, SubTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function GoalConversionEvent({ liveEvent, identifyEvents }) {
  const { goal_name, addon_name, unique } = liveEvent.attributes;

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <span className="whitespace-pre">
          {' '}
          completed <strong className="text-body-1">{goal_name}</strong> goal
        </span>
      </MainTextRow>
      <SubTextRow>
        {unique ? 'for the first time' : 'again'}
        {addon_name && (
          <span className="whitespace-pre">
            {' '}
            via <strong className="text-body-2">{addon_name}</strong>
          </span>
        )}
      </SubTextRow>
    </div>
  );
}
