import { gql } from '@urql/core';

import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const sendInstallInstructionsEmailMutation = ({ client }) => ({
  mutationFn: ({ email }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SendInstallInstructionsEmail($email: InstructionsEmailParams!) {
          sendInstallInstructionsEmail(email: $email) {
            success
          }
        }
      `,
      {
        email,
      },
    ),
});

export const sendUsFeedbackEmailMutation = ({ client }) => ({
  mutationFn: ({ message }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation FeedbackEmail($message: String!) {
          sendUsFeedbackEmail(message: $message) {
            success
          }
        }
      `,
      {
        message,
      },
    ),
});

export const initializeSegmentIntegrationMutation = ({ client }) => ({
  mutationFn: ({ returnUrl }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation InitializeSegmentIntegration($returnUrl: String!) {
          initializeSegmentIntegration(returnUrl: $returnUrl) {
            redirectUrl
          }
        }
      `,
      {
        returnUrl,
      },
    ),
});
