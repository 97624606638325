import { useEffect } from 'react';
import { runChecks } from '@crazyegginc/compatibility-checker';

import { useAuthContext } from '/src/hooks';
import { isDevelopment, isE2E } from '/src/utils';

export function useSpecificCompatibilityCheck({ features, enabled }) {
  const { currentUser } = useAuthContext();
  const isDev = isDevelopment();
  const isE2Etest = isE2E();

  useEffect(() => {
    async function checkCompatibility() {
      await runChecks({
        features,
        enableServerErrorNotification: true,
        enableClientErrorNotification: true,
        userData: { user_email: currentUser?.email, user_id: currentUser?.id },
      });
    }

    if (enabled && !isDev && !isE2Etest) {
      checkCompatibility();
    }
  }, [currentUser, features, enabled, isDev, isE2Etest]);
}
