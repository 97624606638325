import { useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { TemplateCard, TemplateSkeleton } from '/src/features/addons/common/templates/TemplateCard';
import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';
import { TemplateCTATypeSelector } from '/src/features/addons/common/templates/TemplateCTATypeSelector';
import { CreateTemplateButton } from '/src/features/addons/common/templates/CreateTemplateButton';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';

import { usePermissions, useQueryParams } from '/src/hooks';
import { ADDON_TYPES, CTA_TYPES } from '/src/features/addons/constants';
import { getSurveyQueryParams } from '/src/utils/url';
import { getInitialValue } from '/src/utils/location';
import { addonTemplatesListQuery } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

// eslint-disable-next-line no-restricted-syntax
export default function AddonTemplates() {
  const permissions = usePermissions();
  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;
  const allowedTypes = [];
  if (canCreateSurvey) {
    allowedTypes.push(FEATURES.SURVEYS);
  }
  if (canCreateCTA) {
    allowedTypes.push(FEATURES.CTAS);
  }

  const { set: queryParamsSet } = useQueryParams();
  const [type] = useState(() => getInitialValue('type', allowedTypes) ?? FEATURES.SURVEYS);
  const [CTAtype, setCTAType] = useState(() => getInitialValue('CTAtype', [...Object.values(CTA_TYPES)]));
  const { data, isFetching, isLoading } = useQuery({
    ...addonTemplatesListQuery(),
  });
  const canManageTemplates = permissions.can('manageTemplates', FEATURES.ADDONS).allowed;

  useEffect(() => {
    if (window.Metrics) {
      if (type === FEATURES.CTAS) {
        window.Metrics.used('CTA: Browsed Templates');
      } else if (type === FEATURES.SURVEYS) {
        window.Metrics.used('Survey: Browsed Templates');
      } else {
        window.Metrics.used('Add-on: Browsed Templates');
      }
    }
  }, [type]);

  const setCTATypeFilter = useCallback(
    (value) => {
      queryParamsSet('CTAtype', value);
      setCTAType(value);
    },
    [queryParamsSet],
  );

  const templates = useMemo(() => data?.addonTemplatesList?.list ?? [], [data]);

  const filteredTemplates = useMemo(
    () =>
      templates.filter((template) => {
        if (!type) return true;
        let displayType;
        try {
          displayType = JSON.parse(template.content)?.displayType;
        } catch {
          return false;
        }
        if (
          template.type ===
            type?.replace(FEATURES.CTAS, ADDON_TYPES.CTA).replace(FEATURES.SURVEYS, ADDON_TYPES.SURVEY) &&
          (type !== FEATURES.CTAS || displayType === CTAtype || !CTAtype)
        ) {
          return true;
        }
        return false;
      }) ?? [],
    [templates, type, CTAtype],
  );

  if ((type === FEATURES.SURVEYS && !canCreateSurvey) || (type === FEATURES.CTAS && !canCreateCTA)) {
    return <Navigate replace to={{ pathname: '/addons', search: getSurveyQueryParams({ type: type }) }} />;
  }

  return (
    <DashboardPage mainClass="!pb-0">
      <SEO title={`${getFeatureName(type)} Templates`} />
      <DashHeader
        titleComponent={
          <Breadcrumb>
            <BreadcrumbItem active={false} to={{ pathname: '/addons', search: getSurveyQueryParams({ type: type }) }}>
              {getFeatureName(type)}
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>Templates</BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex items-center space-x-2.5">
            {canManageTemplates && <CreateTemplateButton />}
            <NewAddonButton showTemplates={false} type={type} />
          </div>
        }
      />
      {!isFetching && templates.length === 0 ? (
        <div className="mt-10 flex w-full justify-center">No templates yet!</div>
      ) : (
        <>
          <div className="flex flex-col px-10 pb-[150px] pt-10">
            <div className="mb-4 flex space-x-2.5">
              {type === FEATURES.CTAS && (
                <TemplateCTATypeSelector disabled={isLoading} onSelect={setCTATypeFilter} selected={CTAtype} />
              )}
            </div>
            {filteredTemplates.length === 0 && !isFetching ? (
              <NoMatchesWall item="templates" text="templates, try adjusting the filters" />
            ) : (
              <div className="grid grid-cols-[repeat(auto-fill,_minmax(320px,_auto))] gap-x-3 gap-y-5">
                {isLoading ? (
                  <>
                    {[1, 2, 3, 4, 5].map((i) => (
                      <TemplateSkeleton key={i} />
                    ))}
                  </>
                ) : (
                  <>
                    {filteredTemplates.map((template, i) => (
                      <TemplateCard
                        key={template.name}
                        template={template}
                        active={false}
                        index={i}
                        onSelect={() => {}}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </DashboardPage>
  );
}
