import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions, useMetric } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';

import SnapshotsPNG from '@crazyegginc/hatch/dist/images/illustration-snapshots.png';
import SnapshotsWebP from '@crazyegginc/hatch/dist/images/illustration-snapshots.webp';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function SnapshotsUpgradePaywall({ mini }) {
  const { currentAccount } = useAuthContext();
  const trackMetric = useMetric();

  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={SnapshotsWebP}
        png={SnapshotsPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Understand your customer’s journey with Snapshots</Paywall.Title>

      <Paywall.Body>
        Snapshots are a great way to learn how people are interacting with your site, making it easy for you to learn
        what visitors want, without getting bogged down in the details.
        {mini ? null : (
          <div className="mt-6 flex justify-center">
            <div className="space-y-2">
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Heatmaps to easily identify your website’s most popular content.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Figure out why people are leaving your website.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Track and identify click patterns.
              </div>
            </div>
          </div>
        )}
        {!canManageBilling ? (
          <p className="mt-5">
            To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
            the plan.
          </p>
        ) : null}
      </Paywall.Body>

      {canManageBilling ? (
        <Button
          component={Link}
          to={MANAGE_SUBSCRIPTION_PATH}
          onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_upsell_paywall')}
          className="mb-5"
        >
          Get Snapshots
          <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
