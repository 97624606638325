import { useEffect } from 'react';

import { useWizard } from '/src/hooks';
import { CodeBox } from '/src/components/CodeBox';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { useScriptUrl } from '/src/features/installation/utils';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

import SquarespacePoster from '../assets/squarespace-instructions-poster.jpg';
import SquarespaceVideo from '../assets/squarespace-instructions.mp4';

export function SquarespaceInstructions() {
  const [code] = useScriptUrl();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/squarespace/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Squarespace',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Squarespace!</WizardHeader>
      <WizardSubHeader>Follow the instructions below for a completely code-free install.</WizardSubHeader>

      <div className="text-header-4 mb-2.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>
          Make sure you have access to{' '}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/115015517328"
            className="text-link mt-2.5"
            target="_blank"
            rel="noreferrer noopener"
          >
            Code injection which is a Squarespace Premium feature
          </a>
          .
        </WizardList.Item>
        <WizardList.Item>Log into your Squarespace account and open your admin panel.</WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Settings</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Under the <strong className="text-body-1">Website</strong> section, click on{' '}
          <strong className="text-body-1">Advanced &gt; Code Injection</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste the Crazy Egg tracking script below into the <strong className="text-body-1">Header</strong>{' '}
          text box.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Save</strong>.
        </WizardList.Item>
      </WizardList>

      <CodeBox loading={false} code={code} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={SquarespaceVideo} poster={SquarespacePoster} label="Squarespace installation video" />
      </div>

      <a href={SupportLinks.install.squarespace} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Squarespace
      </a>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Squarespace.
      </div>
    </div>
  );
}
