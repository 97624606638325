import { fromUnixTime, format } from 'date-fns';

const singleDay = 60 * 60 * 24;
export function getTimeUnit(dateRange) {
  if (dateRange.endDate - dateRange.startDate < 30 * singleDay) return 1;
  if (dateRange.endDate - dateRange.startDate < 180 * singleDay) return 7;
  return 28;
}

export function charDateLabel(date) {
  return format(fromUnixTime(date), 'MMM dd');
}
