import classNames from 'classnames';
import { Popover, StyledPopoverPanel, PopoverItem, Button, InfoBox, SkeletonLine, Indicator } from '@crazyegginc/hatch';

import { PLAYLIST_TYPES } from '/src/features/recordings/constants';

import { ReactComponent as PlaylistIcon } from '@crazyegginc/hatch/dist/images/icon-playlist-outline.svg';
import { ReactComponent as UserIcon } from '@crazyegginc/hatch/dist/images/icon-user-outline.svg';

export function PlaylistsSelector({ playlists, fetching, activePlaylist, selectPlaylist }) {
  const playlist = playlists?.find((x) => x.id === activePlaylist);

  const handleSelection = (playlist, close) => {
    selectPlaylist(playlist.id);
    close();
  };

  return (
    <Popover className="relative" data-testid="playlist-selector">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            size="lg"
            variant="secondary"
            className={classNames('relative !w-[175px]', { '!border-dodger-blue-500': open })}
          >
            <span className="w-[130px] truncate text-left">{playlist?.name ?? 'All playlists'}</span>
            <Indicator type="dropdown" className="absolute right-4" />
          </Popover.Button>
          <StyledPopoverPanel align="right" className="!max-h-[405px] !w-[250px] !overflow-y-auto !py-0 ">
            {({ close }) => (
              <>
                <section className="mt-4 w-full">
                  <SectionHeader icon={PlaylistIcon} text="Recommended playlists" />
                  {fetching ? (
                    <div className="space-y-2.5">
                      <SkeletonLine width="170px" />
                      <SkeletonLine width="120px" />
                      <SkeletonLine width="150px" />
                    </div>
                  ) : (
                    playlists?.map((playlist) =>
                      playlist.type === PLAYLIST_TYPES.RECOMMENDED ? (
                        <PopoverItem key={playlist.name} onClick={() => handleSelection(playlist, close)}>
                          {playlist.name}
                        </PopoverItem>
                      ) : null,
                    )
                  )}
                </section>

                <section className="mt-7 w-full">
                  <SectionHeader icon={UserIcon} text="Your playlists" />
                  {fetching ? (
                    <div className="space-y-2.5">
                      <SkeletonLine width="160px" />
                      <SkeletonLine width="180px" />
                    </div>
                  ) : (
                    playlists?.map((playlist) =>
                      playlist.type === PLAYLIST_TYPES.USER ? (
                        <PopoverItem key={playlist.name} onClick={() => handleSelection(playlist, close)}>
                          {playlist.name}
                        </PopoverItem>
                      ) : null,
                    )
                  )}
                </section>
                {!fetching && !playlists?.some((playlist) => playlist.type === PLAYLIST_TYPES.USER) && (
                  <InfoBox
                    header="Want to create a playlist?"
                    body="Filter your recordings then save as a playlist to see it here!"
                  />
                )}
                <div className="min-h-[15px]" />
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}

function SectionHeader({ icon: Icon, text }) {
  return (
    <div className="mb-2.5 flex items-center border-b border-mystic-500 pb-2.5">
      <Icon className="mx-2 h-[18px] w-[18px] fill-current text-dodger-blue-500" />
      <h3 className="text-body-2 m-0 leading-none">{text}</h3>
    </div>
  );
}
