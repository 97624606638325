import { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from '@xstate5/react';

import { useDebounce } from '/src/hooks';
import { PLAYER_EVENTS } from '/src/features/recordings/constants';

const {
  SCROLL,
  PAGE_LOAD,
  MOUSEDOWN,
  RESIZE,
  BACKGROUNDED,
  END,
  ERROR_EVENT,
  FORM,
  ECOMMERCE,
  SURVEY_RESPONSE,
  GOAL_CONVERSION,
  QUICK_BACK,
  NAVIGATED_BACK,
} = PLAYER_EVENTS;

import { VisitorPanel } from '/src/features/visitor-panel/components/VisitorPanel';

import { ReactComponent as ArrowRightIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';

import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as VirtualSessionIcon } from '@crazyegginc/hatch/dist/images/icon-block.svg';

import { PageVisit, PageVisitSkeleton } from './PageVisit';

import { LoadEvent } from './events/LoadEvent';
import { ErrorEvent } from './events/ErrorEvent';
import { ClickEvent } from './events/ClickEvent';
import { ResizeEvent } from './events/ResizeEvent';
import { ScrollEvent } from './events/ScrollEvent';
import { BackgroundedEvent } from './events/BackgroundedEvent';
import { FormEvent } from './events/FormEvent';
import { EcommerceEvent } from './events/EcommerceEvent';
import { SurveyResponseEvent } from './events/SurveyResponseEvent';
import { GoalConversionEvent } from './events/GoalConversionEvent';
import { QuickbackEvent } from './events/QuickbackEvent';
import { EndEvent } from './events/EndEvent';
import { SidebarVisitor } from './SidebarVisitor';
import { NavigatedbackEvent } from './events/NavigatedbackEvent';

function renderEvent(
  { type, timestamp = null, endTimestamp = null, ...rest },
  elapsedTime,
  onClick,
  searchFilter,
  siteId,
) {
  const active = elapsedTime && timestamp && endTimestamp && elapsedTime >= timestamp && elapsedTime < endTimestamp;

  switch (type) {
    case SCROLL:
      return (
        <ScrollEvent timestamp={timestamp} active={active} key={`Event:${SCROLL}:${timestamp}`} onClick={onClick} />
      );
    case PAGE_LOAD:
      return (
        <LoadEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${PAGE_LOAD}:${timestamp}`}
          duration={rest.load_time / 1000}
          onClick={onClick}
          event={rest}
        />
      );
    case MOUSEDOWN:
      return (
        <ClickEvent
          searchFilter={searchFilter}
          dead={rest?.dead ?? false}
          rage={rest?.rage ?? false}
          selector={rest?.selector}
          timestamp={timestamp}
          active={active}
          key={`Event:${MOUSEDOWN}:${rest.rage ? 'rage' : rest.dead ? 'dead' : ''}:${timestamp}`}
          onClick={onClick}
        />
      );
    case RESIZE:
      return (
        <ResizeEvent
          timestamp={timestamp}
          active={active}
          size={rest?.size}
          key={`Event:${RESIZE}:${timestamp}`}
          onClick={onClick}
        />
      );
    case BACKGROUNDED:
      return (
        <BackgroundedEvent
          timestamp={timestamp}
          active={active}
          duration={rest.backgrounded_time / 1000}
          key={`Event:${BACKGROUNDED}:${timestamp}`}
          onClick={onClick}
        />
      );
    case ERROR_EVENT:
      return (
        <ErrorEvent
          timestamp={timestamp}
          active={active}
          fingerprint={rest.fingerprint}
          errorId={rest.uuid}
          siteId={siteId}
          key={`Event:${ERROR_EVENT}:${timestamp}`}
          onClick={onClick}
        />
      );
    case FORM:
      return (
        <FormEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${FORM}:${rest.sub_event_type}:${timestamp}`}
          onClick={onClick}
          {...rest}
        />
      );
    case ECOMMERCE:
      return (
        <EcommerceEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${ECOMMERCE}:${rest.sub_event_type}:${timestamp}`}
          onClick={onClick}
          {...rest}
        />
      );
    case SURVEY_RESPONSE:
      return (
        <SurveyResponseEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${SURVEY_RESPONSE}:${timestamp}`}
          onClick={onClick}
          surveyId={rest.survey_id}
          {...rest}
        />
      );
    case GOAL_CONVERSION:
      return (
        <GoalConversionEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${GOAL_CONVERSION}:${rest.trigger_id}:${timestamp}`}
          onClick={onClick}
          {...rest}
        />
      );
    case QUICK_BACK:
      return (
        <QuickbackEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${QUICK_BACK}:${timestamp}`}
          onClick={onClick}
          {...rest}
        />
      );
    case NAVIGATED_BACK:
      return (
        <NavigatedbackEvent
          timestamp={timestamp}
          active={active}
          key={`Event:${NAVIGATED_BACK}:${timestamp}`}
          onClick={onClick}
          {...rest}
        />
      );
    case END:
      return (
        <EndEvent
          timestamp={timestamp}
          active={active}
          event={rest}
          key={`Event:${END}:${timestamp}`}
          onClick={onClick}
        />
      );
  }
}

function SidebarComponent({ actorRef, seekTime, onSeek = null, navigateToAnotherRecording = null }) {
  const fullScreen = useSelector(actorRef, (state) => state.context.fullScreen);
  const events = useSelector(actorRef, (state) => state.context.groupedEvents);
  const elapsedTime = useSelector(actorRef, (state) => state.context.ticks);
  const recording = useSelector(actorRef, (state) => state.context.recording);

  // const { height: bodyHeight } = useResizeObserver({ ref: document.querySelector(`body`) });

  //const { selectVisitor } = useVisitorId();
  const [searchOpened, setSearchOpened] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const searchInputRef = useRef(null);
  const scrollRef = useRef();

  const debouncedSearchFilter = useDebounce(searchFilter, 500);
  const filteredEvents = useMemo(() => {
    if (debouncedSearchFilter === '') return events;
    return events
      .map((page) => {
        return {
          ...page,
          events: page.events.filter((event) =>
            event.tags.some((tag) => tag.toLowerCase().indexOf(debouncedSearchFilter.toLowerCase()) >= 0),
          ),
        };
      })
      .filter((page) => {
        // filter out pages without events
        if (page?.tags.some((tag) => tag.toLowerCase().indexOf(debouncedSearchFilter.toLowerCase()) >= 0)) {
          return true;
        }
        return page.events.length > 0;
      });
  }, [events, debouncedSearchFilter]);

  const toggleSearchOpened = useCallback(() => {
    setSearchOpened((open) => {
      if (open) searchInputRef.current.value = '';
      return !open;
    });
  }, [setSearchOpened]);

  useEffect(() => {
    if (searchOpened) {
      searchInputRef.current?.focus?.();
    }
    setSearchFilter('');
  }, [searchOpened, searchInputRef]);

  // const onScroll = useCallback(
  //   (e) => {
  //     if (!metaScrollClosed) {
  //       const timelineHeight = scrollRef.current?.clientHeight ?? 100;
  //       setMetaOpened(
  //         (open) => (open && e.target.scrollTop < bodyHeight - timelineHeight) || (e.target.scrollTop === 0 && !open),
  //       );
  //     }
  //     return null;
  //   },
  //   [setMetaOpened, metaScrollClosed, bodyHeight],
  // );

  if (!recording) return null;

  return (
    <>
      <VisitorPanel currentRecordingHash={recording.hashedId} navigateToAnotherRecording={navigateToAnotherRecording} />
      <aside
        className={classNames(
          fullScreen ? 'padding-0 invisible w-0 translate-x-full overflow-hidden' : 'w-full p-2 sm:p-4 lg:w-100',
          'relative z-0 block h-2/5 flex-col overflow-y-auto bg-mako-500 transition duration-150 ease-out lg:flex lg:min-h-screen',
        )}
        aria-label="list of recording events"
        aria-hidden={fullScreen}
      >
        <SidebarVisitor actorRef={actorRef} />
        <div className="flex items-center justify-between pb-3.5">
          <h3 className="text-lg font-semibold text-white">Events</h3>
          <div
            className={classNames(
              'flex rounded border border-woodsmoke-500 bg-woodsmoke-500 bg-opacity-95 duration-150 ease-in-out',
              {
                'border border-mako-500 pl-2.5': searchOpened,
              },
            )}
          >
            <div
              className={classNames(
                searchOpened ? 'visible w-44' : 'hidden w-0',
                'flex items-center text-white duration-150 ease-in-out',
              )}
            >
              <span className="mr-2.5 flex h-6 w-6 items-center justify-center">
                <SearchIcon
                  aria-label="search event"
                  className="h-4 w-4 fill-current text-cadet-blue-500 duration-150 ease-in-out group-hover:text-white"
                />
              </span>

              <input
                ref={searchInputRef}
                className="focus:ring-blue-600 w-full border-0 bg-transparent text-sm text-white focus:ring-2"
                type="text"
                placeholder="Search events"
                onInput={(e) => setSearchFilter(e.target.value.trim())}
              />
            </div>
            <button
              className={classNames(
                'group ml-auto flex h-[46px] w-[46px] items-center justify-center rounded duration-150 ease-in-out active:bg-dodger-blue-500',
              )}
              onClick={toggleSearchOpened}
            >
              {searchOpened ? (
                <span className="flex h-6 w-6 items-center justify-center rounded bg-mako-500 duration-150 ease-in-out group-hover:bg-dodger-blue-500">
                  <CloseIcon
                    aria-label="close search"
                    className="h-3 w-3 fill-current text-cadet-blue-500 group-hover:text-white"
                  />
                </span>
              ) : (
                <span className="flex h-6 w-6 items-center justify-center">
                  <SearchIcon
                    aria-label="search event"
                    className="h-4 w-4 fill-current text-cadet-blue-500 group-hover:text-white"
                  />
                </span>
              )}
            </button>
          </div>
        </div>
        <section
          id="sidebar-events"
          ref={scrollRef}
          // onScroll={onScroll}
          className={classNames(
            'max-h-full !overflow-y-auto rounded scrollbar-thin scrollbar-track-transparent scrollbar-thumb-woodsmoke-500',
            { 'pr-1': scrollRef.current?.clientHeight < scrollRef.current?.scrollHeight },
          )}
        >
          <div className="flex flex-col rounded bg-woodsmoke-500 shadow-lg">
            {!!recording?.referrer?.url && (
              <h4 className="flex items-center border-b border-charade-500 bg-woodsmoke-500 px-5 py-3.5 text-sm font-normal text-cadet-blue-500">
                <ArrowRightIcon className="mr-2.5 h-5 w-5 fill-current text-cadet-blue-500" />
                <span className="flex-1 truncate">
                  <strong>Referrer:</strong> {recording.referrer.url}
                </span>
                <VirtualSessionIcon className="h-4 w-4 fill-current text-cadet-blue-500" />
              </h4>
            )}

            <ul>
              {filteredEvents.length === 0 && debouncedSearchFilter.length === 0 ? <PageVisitSkeleton /> : null}
              {filteredEvents.map((page, index) => (
                <PageVisit
                  previousPageUrl={filteredEvents?.[index - 1]?.url ?? null}
                  url={page.url}
                  key={`${page.timestamp}:${page.url}`}
                  onClick={() => onSeek(page.timestamp, { isTimestamp: true })}
                  active={(seekTime ? seekTime : elapsedTime) === page.timestamp}
                  type={page.type}
                  timestamp={page.timestamp}
                >
                  {page.events.map((event) =>
                    renderEvent(
                      event,
                      seekTime ? seekTime : elapsedTime,
                      () => {
                        onSeek(event.timestamp, { isTimestamp: true });
                      },
                      debouncedSearchFilter,
                      recording.siteId,
                    ),
                  )}
                </PageVisit>
              ))}
            </ul>
          </div>
        </section>
      </aside>
    </>
  );
}

export const Sidebar = memo(SidebarComponent);
