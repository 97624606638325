import { useEffect } from 'react';

import { useWizard, useAccountNumber } from '/src/hooks';
import { CodeBox } from '/src/components/CodeBox';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

import GtmPoster from '../assets/gtm-instructions-poster.jpg';
import GtmVideo from '../assets/gtm-instructions.mp4';

export function GtmInstructions() {
  const [accountNumber] = useAccountNumber();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/google-tag-manager/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Google Tag Manager',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Google Tag Manager!</WizardHeader>
      <WizardSubHeader>
        Follow the instructions below for a completely code-free install. Once you&#39;re prompted for your account
        number, enter <strong className="text-body-1">{accountNumber}</strong>.
      </WizardSubHeader>

      <div className="text-header-4 mb-2.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>Log into your Google Tag Manager account.</WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Add a New Tag</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on the <strong className="text-body-1">Tag Configuration</strong> or the pencil icon. Choose{' '}
          <strong className="text-body-1">Crazy Egg</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Enter your Crazy Egg account number, <strong className="text-body-1">{accountNumber}</strong> in the Account
          Number field.
        </WizardList.Item>
        <WizardList.Item>
          Click on the <strong className="text-body-1">Triggering</strong> area. Select{' '}
          <strong className="text-body-1">All Pages</strong> for the trigger.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Save</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Submit</strong> at the upper right-hand side of the page.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Publish</strong>.
        </WizardList.Item>
      </WizardList>

      <div className="mt-3" />

      <CodeBox loading={false} code={accountNumber} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={GtmVideo} poster={GtmPoster} label="Google Tag Manager installation video" />
      </div>

      <a href={SupportLinks.install.gtm} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Google Tag Manager
      </a>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Google Tag
        Manager.
      </div>
    </div>
  );
}
