import { useContext, useCallback } from 'react';

import { WizardContext, WIZARD_ACTIONS } from '/src/components/wizard/legacy/wizard-context';

const { SET, SET_DIRTY } = WIZARD_ACTIONS;

export function useWizard() {
  const context = useContext(WizardContext);

  const { wizard, wizardDispatch } = context;

  function setBackEnabled(backEnabled) {
    wizardDispatch({
      type: SET,
      payload: {
        backEnabled,
      },
    });
  }

  const setNextEnabled = useCallback(
    (nextEnabled) => {
      wizardDispatch({
        type: SET,
        payload: {
          nextEnabled,
        },
      });
    },
    [wizardDispatch],
  );

  // `back` can be a string, which will be interpreted as
  // an internal route, or a function which will execute
  // on click
  function setBack(back, backEnabled = false) {
    wizardDispatch({
      type: SET,
      payload: {
        back,
        backEnabled,
      },
    });
  }

  // `next` can be a string, which will be interpreted as
  // an internal route, or a function which will execute
  // on click
  function setNext(next, nextEnabled = false, nextText = 'Next') {
    wizardDispatch({
      type: SET,
      payload: {
        next,
        nextEnabled,
        nextText,
      },
    });
  }

  function setSteps(currentStep, totalSteps) {
    wizardDispatch({
      type: SET,
      payload: {
        currentStep,
        totalSteps,
      },
    });
  }

  function setTitle(title) {
    wizardDispatch({
      type: SET,
      payload: {
        title,
      },
    });
  }

  function setData(data) {
    // this data is any data that you would like to persist between screens inside the wizard
    wizardDispatch({
      type: SET,
      payload: {
        data: { ...wizard.data, ...data },
      },
    });
  }

  function showModal(component) {
    wizardDispatch({
      type: SET,
      payload: {
        modal: {
          component,
        },
      },
    });
  }

  function closeModal() {
    wizardDispatch({
      type: SET,
      payload: {
        modal: null,
      },
    });
  }

  const setCancelConfirm = useCallback(
    (enabled) => {
      wizardDispatch({
        type: SET,
        payload: {
          cancelConfirm: enabled,
        },
      });
    },
    [wizardDispatch],
  );

  const setReturnLocation = useCallback(
    (location) => {
      wizardDispatch({
        type: SET,
        payload: {
          returnLocation: location,
        },
      });
    },
    [wizardDispatch],
  );

  function setDirty() {
    wizardDispatch({
      type: SET_DIRTY,
      payload: true,
    });
  }

  /*
      Example usage:
      const wizard = useWizard();
  
      useEffect(() => {
        wizard.set({
          back: '/snapshots', backEnabled: true, next: '/snapshots/new', nextEnabled: true, currentPage: 1,
          totalPages: 3, title: 'New Snapshot'
        });
      }, []);
    */
  const set = useCallback(
    ({
      back = null,
      backEnabled = false,
      next = null,
      nextText = 'Next',
      nextEnabled = false,
      currentStep = 1,
      totalSteps = 1,
      title = null,
      celebrate = false,
      modal = null,
      ...rest
    }) => {
      wizardDispatch({
        type: SET,
        payload: {
          back,
          backEnabled,
          next,
          nextText,
          nextEnabled,
          currentStep,
          totalSteps,
          title,
          celebrate,
          modal,
          ...rest,
        },
      });
      if (!rest.data) {
        wizardDispatch({
          type: SET_DIRTY,
          payload: false,
        });
      }
    },
    [wizardDispatch],
  );

  if (!context) return;

  const { data, ...state } = wizard;

  return {
    state,
    data,
    setBackEnabled,
    setNextEnabled,
    setBack,
    setNext,
    setSteps,
    setTitle,
    set,
    setData,
    showModal,
    closeModal,
    setCancelConfirm,
    setReturnLocation,
    setDirty,
  };
}
