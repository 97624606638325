import { FEATURES, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

export const ERRORS_PERMISSIONS = {
  [FEATURES.ERRORS_TRACKING]: ({ capabilities, isSharing, sharedResource }) => {
    const sharedResourceIsError = sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.ERROR;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsError ? { allowed: true } : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.errors.permissions.nav,
          view: capabilities.errors.permissions.view,
          edit: capabilities.errors.permissions.edit,
        };
  },
};
