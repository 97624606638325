import { Field, useFormikContext } from 'formik';
import { Select } from '@crazyegginc/hatch';
import { roleOptions } from '../../pages/sso';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';

export function ProvisioningRoleSelect({ disabled = false, index }) {
  const { values, setFieldValue } = useFormikContext();
  const action = values.provisioningRules?.[index]?.action;

  const actionBase = `provisioningRules.[${index}].action`;

  return (
    <Field name={`${actionBase}.role`}>
      {() => (
        <div className="no-wrap-select-ul">
          <Select
            disabled={disabled}
            placeholder="Choose a role"
            options={roleOptions}
            value={roleOptions.find((option) => option.value === action.role)?.value}
            onChange={(value) => {
              setFieldValue(`${actionBase}.role`, value);
              // clear selected sites
              setFieldValue(`${actionBase}.specificSites`, value !== ACCOUNT_USER_ROLES.OWNER);
              setFieldValue(`${actionBase}.sites`, []);
            }}
          />
        </div>
      )}
    </Field>
  );
}
