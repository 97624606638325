export const MouseLine = {
  id: 'mouseline',
  afterEvent(chart) {
    const chartRef = chart;

    if (!chart.options.plugins.mouseline.enabled) {
      return;
    }

    chartRef.options.plugins.mouseline.x = false;
    chartRef.options.plugins.mouseline.x = false;

    if (chart._active.length) {
      chartRef.options.plugins.mouseline.x = chart._active[0].element.x;
    }
  },
  afterDatasetsDraw(chart) {
    if (!chart.options.plugins.mouseline) {
      return;
    }

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const x = chart.options.plugins.mouseline.x || false;

    if (x) {
      ctx.save();
      ctx.strokeStyle = chart.options.plugins.mouseline.color || 'rgb(153, 207, 245)';
      ctx.lineWidth = chart.options.plugins.mouseline.lineWidth || 2;
      ctx.setLineDash([4, 3]);
      ctx.beginPath();
      ctx.moveTo(chart.options.plugins.mouseline.x, chartArea.bottom);
      ctx.lineTo(chart.options.plugins.mouseline.x, chartArea.top);
      ctx.stroke();
      ctx.restore();
      chart.getActiveElements().forEach((el) => el.element.draw(ctx));
    }
  },
};
