import { format } from 'date-fns';

export function formatDate(input) {
  return format(input, `MMM dd, yyyy`).replace(/\./g, '');
}

export function externalizeLinks(parentElement) {
  const links = parentElement.querySelectorAll('a');
  links.forEach((link) => {
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
  });
}
