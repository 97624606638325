import { useState, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Popover, StyledPopoverPanel, Indicator, PopoverItem } from '@crazyegginc/hatch';
import { AppConfig } from '/src/AppConfig';

import { usePermissions, useModal, useMutation, useSite } from '/src/hooks';
import { ROOT_ELEMENT_ID, FEATURES } from '/src/features/_global/constants';
import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';
import { abTestRetireVariantMutation } from '/src/features/ab-testing/mutations';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { ABTEST } from '/src/features/ab-testing/constants';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';

// import { StopTestModal } from '/src/features/ab-testing/components/modals/StopTest';
import { VariantDescriptionModal } from '/src/features/ab-testing/components/modals/VariantDescriptionModal';

import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as PreviewIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as RetireIcon } from '@crazyegginc/hatch/dist/images/icon-unwatched-outline.svg';
import { ReactComponent as SnapshotIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as DescriptionIcon } from '@crazyegginc/hatch/dist/images/icon-editor.svg';

export function VariantActionButton({ abTest, entity, refetch = null, addNewVariant = null, editVariant = null }) {
  const { selectedSite } = useSite();
  const permissions = usePermissions();
  const modal = useModal();
  const navigate = useNavigate();
  const [duplicateStatus, setDuplicateStatus] = useState('idle');
  const [retireStatus, setRetireStatus] = useState('idle');

  const { mutateAsync: retireVariant } = useMutation(abTestRetireVariantMutation);

  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  const canEditTest = permissions.can('edit', FEATURES.AB_TESTING).allowed;

  const isEditableControl = useCallback(
    (variant) => {
      if (variant.type !== 'CONTROL') return false;
      return canEditTest;
    },
    [canEditTest],
  );

  const launchPreview = useCallback(
    ({ viewVariantUrl }) => {
      function handleMessage(e) {
        if (e.data.status === 'launched') {
          console.log('A/B Test Preview: Launched') // eslint-disable-line
          window.removeEventListener('message', handleMessage);
          modal.close();
        }
      }

      window.addEventListener('message', handleMessage);

      window.open(viewVariantUrl, `_preview_variant_${viewVariantUrl}`);
    },
    [modal],
  );

  if (!entity) return null;

  const isDraft = abTest.status === AB_TEST_STATUSES.DRAFT;
  const isRetired = entity.isRetired;

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              className={classNames(
                'flex h-[35px] w-[70px] items-center justify-center rounded px-4 text-dodger-blue-300',
                {
                  'border border-mystic-500 bg-white shadow-md': open,
                },
              )}
            >
              <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
              <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                ref={setTooltipElement}
                // onMouseDown={(e) => e.stopPropagation()}
                className="w-[220px]"
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {() => (
                  <>
                    <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />

                    {!isDraft && !isRetired ? (
                      <Item
                        onClick={() => {
                          if (entity.viewVariantUrl) {
                            modal.show(<PreviewLoadingModal type={ABTEST} target="tab" spelledType="A/B Test" />);
                            launchPreview(entity);
                          }
                        }}
                        icon={PreviewIcon}
                      >
                        Preview
                      </Item>
                    ) : null}

                    {entity.permissions.canEdit ? (
                      <Item
                        disabled={!entity.permissions.canEdit}
                        onClick={() => {
                          editVariant(abTest, entity);
                        }}
                        icon={EditIcon}
                      >
                        Edit
                      </Item>
                    ) : null}

                    {entity.permissions.canDuplicate ? (
                      <Item
                        disabled={duplicateStatus === 'pending'}
                        onClick={async () => {
                          addNewVariant(JSON.parse(entity.editorPatch));
                        }}
                        icon={DuplicateIcon}
                      >
                        Duplicate
                      </Item>
                    ) : null}

                    {entity.permissions.canRetire ? (
                      <Item
                        disabled={retireStatus === 'pending'}
                        onClick={async () => {
                          setRetireStatus('pending');
                          await retireVariant({ id: abTest.id, variantId: entity.id });
                          refetch?.();
                          setDuplicateStatus('idle');
                        }}
                        icon={RetireIcon}
                      >
                        {retireStatus === 'retiring' ? 'Retiring...' : 'Retire'}
                      </Item>
                    ) : null}

                    {entity.permissions.canViewSnapshot && entity.snapshotId ? (
                      <Item
                        onClick={() => {
                          window.open(`${AppConfig.legacyCoreBaseURL()}/snapshots/${entity.snapshotId}`, '_blank');
                        }}
                        icon={SnapshotIcon}
                      >
                        View Snapshot
                      </Item>
                    ) : null}

                    {entity.permissions.canViewRecordings ? (
                      <Item
                        onClick={() => {
                          const filters = encodeURIComponent(
                            JSON.stringify({
                              version: 2,
                              operator: 'AND',
                              conditions: [{ criteria: 'snapshot', values: [entity.snapshotId] }],
                            }),
                          );
                          navigate(
                            `/recordings?filters=${filters}&groupBy=${GROUP_BY_TYPES.LIST_VIEW}&site=${selectedSite?.name}`,
                          );
                        }}
                        icon={RecordingsIcon}
                      >
                        Watch Recordings
                      </Item>
                    ) : null}

                    {!isDraft ? (
                      <Item
                        onClick={() => {
                          close();
                          modal.show(
                            <VariantDescriptionModal
                              abTest={abTest}
                              variant={entity}
                              onSave={() => {
                                refetch?.();
                              }}
                            />,
                            { width: '335px' },
                          );
                        }}
                        icon={DescriptionIcon}
                      >
                        {entity.permissions.canEdit || isEditableControl(entity) ? `Edit` : `View`} description
                      </Item>
                    ) : null}
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </>
      )}
    </Popover>
  );
}

function Item({ children, icon: Icon, className, disabled, ...props }) {
  return (
    <PopoverItem
      className={classNames('!flex !select-none !items-center !py-2', className, {
        'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
        'group ': !disabled,
      })}
      disabled={disabled}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames('mr-5 h-4 w-4 fill-current group-hover:text-dodger-blue-500', {
            'text-cadet-blue-500': disabled,
            'text-dodger-blue-300': !disabled,
          })}
        />
      )}
      {children}
    </PopoverItem>
  );
}
