import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import useResizeObserver from 'use-resize-observer';
import Mark from 'mark.js';
import { Spinner } from '@crazyegginc/hatch';

import { useSubscription, useSelectedSite, useNavBarDimensions } from '/src/hooks';
import { useFilter } from '../live-filter-context';
import { LiveNoEventsYet } from './paywalls/LiveNoEventsYet';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { LIVE_EVENTS_SUBSCRIPTION } from '/src/features/live-activity/subscriptions';

import { SearchInput } from '/src/components/search-input';
import { isProduction } from '/src/utils';
import { PausePlayButton } from './PausePlayButton';
import { EventCard } from './EventCard';
import { LiveFilters } from './LiveFilters';
import { parseEvents, getAllEventNames } from '../live-functions';
import { MAX_EVENT_NUMBER } from '../constants';

export function LiveContent({ connected, setConnected, paused, setPaused }) {
  const { selectedSite } = useSelectedSite();
  const { search, filter, reset } = useFilter();
  const { ref: eventListRef, height: eventListHeight } = useResizeObserver();
  const { ref: filtersRef, height: filtersHeight } = useResizeObserver();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const [receivedEventReported, setReceivedEventReported] = useState(false);
  const { initialNavBarHeight } = useNavBarDimensions();
  const isProd = isProduction();

  const [pauseOnHover, setPauseOnHover] = useState(false);
  const displayedEvents = useRef([]);
  const visitEvents = useRef({});
  const identifyEvents = useRef({});
  const eventCounts = useRef({});
  const peopleCounts = useRef({});
  const pageCounts = useRef({});

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('live_dashboard_loading');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && connected) {
      try {
        window.CE2.timing.stop('live_dashboard_loading');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, connected, isProd]);

  const { isLoading, data } = useSubscription(
    {
      query: LIVE_EVENTS_SUBSCRIPTION,
      variables: useMemo(() => ({ siteId: selectedSite?.id }), [selectedSite?.id]),
      onNext: useCallback((acc = [], newEvents) => {
        if (!newEvents?.liveEvents) return acc;
        const res = [...newEvents.liveEvents.map((e) => ({ ...e, attributes: JSON.parse(e.attributes) })), ...acc];
        if (res.length > MAX_EVENT_NUMBER) res.splice(MAX_EVENT_NUMBER);
        return res;
      }, []),
    },
    {
      queryKey: useMemo(() => ['liveEvents'], []),
      enabled: Boolean(selectedSite?.id && selectedSite?.installed),
      staleTime: 0,
    },
  );

  const receivedEvents = useMemo(() => data ?? [], [data]);

  useEffect(() => {
    if (!connected && !isLoading && data) {
      setConnected(true);
    }
  }, [isLoading, data, connected, setConnected]);

  useEffect(() => {
    if (!receivedEventReported && receivedEvents.length) {
      window.Metrics?.used('Live Dashboard: Viewed Events');
      setReceivedEventReported(true);
    }
  }, [receivedEventReported, receivedEvents.length]);

  function highlightSearchResults(searchTerm) {
    if (document.querySelector('#eventlist')) {
      const mark = new Mark(document.querySelector('#eventlist'));
      mark.unmark({
        done: () => mark.mark(searchTerm, { acrossElements: true, separateWordSearch: false }),
      });
    }
  }

  useEffect(() => {
    highlightSearchResults(filter.search);
  }, [filter.search]);

  useEffect(() => {
    if (!paused && receivedEvents.length) highlightSearchResults(filter.search);
  }, [filter.search, paused, receivedEvents]);

  if (!connected) {
    return (
      <div className="mt-20 flex w-full flex-col items-center justify-center">
        <span className="text-header-2">Retrieving website visitor activity...</span>
        <Spinner className="mt-8 h-6 w-6 text-cadet-blue-500" />
      </div>
    );
  }

  let waitingToShow = 0;
  if (!paused) {
    const { newVisitEvents, newIdentifyEvents, newEventCounts, newPeopleCounts, newPageCounts, newDisplayedEvents } =
      parseEvents(receivedEvents, visitEvents.current);

    displayedEvents.current = newDisplayedEvents;
    visitEvents.current = Object.assign(visitEvents.current, newVisitEvents);
    identifyEvents.current = Object.assign(identifyEvents.current, newIdentifyEvents);
    eventCounts.current = newEventCounts;
    peopleCounts.current = newPeopleCounts;
    pageCounts.current = newPageCounts;
  } else if (displayedEvents.current.length) {
    const i = receivedEvents.findIndex((e) => e.eventId === displayedEvents.current[0].eventId);
    if (i === -1) {
      waitingToShow = receivedEvents.length;
    } else {
      waitingToShow = i - 1;
    }
  } else {
    waitingToShow = receivedEvents.length;
  }

  if (!receivedEvents?.length) {
    return <LiveNoEventsYet />;
  }

  const filterActive =
    filter.page || filter.search || filter.person || filter.events.length !== getAllEventNames().length;

  return (
    <>
      <div className="mb-5 flex h-10 w-full items-center justify-between">
        <span className="text-header-3">All activity</span>
        <SearchInput
          search={search}
          initialValue={filter.search}
          placeholder="Start typing to search"
          className="!bg-solitude-500 !placeholder-dodger-blue-500/50"
          wrapperClassName="!w-[310px]"
          ariaLabel="search"
        />
      </div>
      <div className="flex items-start space-x-8">
        <section
          style={{ height: `max(100vh - 246px, ${initialNavBarHeight - 246}px, ${filtersHeight ?? 0}px)` }}
          className="min-w-min grow !overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded"
        >
          <div id="eventlist" className="relative w-full pl-[52px] pr-3" ref={eventListRef}>
            <div className="absolute left-3.75 top-5 h-[calc(100%-55px)] w-1 bg-mystic-500" />
            {displayedEvents.current.map((e) => (
              <EventCard
                key={e.eventId}
                liveEvent={e}
                visitEvents={visitEvents.current}
                identifyEvents={identifyEvents.current}
                pageCounts={pageCounts.current}
                pauseOnHover={pauseOnHover}
                pause={() => setPaused(true)}
              />
            ))}
          </div>
          {!eventListHeight && filterActive ? (
            <NoMatchesWall reset={reset} text="matches, try clearing the filter" actionText="filter" />
          ) : (
            <div className="text-body-3 mb-10 mt-7 flex items-center justify-between pl-12 pr-3">
              {displayedEvents.current.length === MAX_EVENT_NUMBER && filterActive && (
                <span>Showing filtered results from the last {MAX_EVENT_NUMBER} events.</span>
              )}
              {displayedEvents.current.length === MAX_EVENT_NUMBER && !filterActive && (
                <span>Showing the last {MAX_EVENT_NUMBER} events.</span>
              )}
              {displayedEvents.current.length !== MAX_EVENT_NUMBER && (
                <span>You’ve reached the end of your live activity.</span>
              )}

              {filterActive && (
                <button
                  className="text-sm italic text-dodger-blue-500 focus-visible:outline-black"
                  type="button"
                  onClick={() => reset()}
                >
                  Clear filters
                </button>
              )}
            </div>
          )}
        </section>

        <section className="mb-10 w-[310px] grow-0" ref={filtersRef}>
          <PausePlayButton
            paused={paused}
            setPaused={setPaused}
            pauseOnHover={pauseOnHover}
            setPauseOnHover={setPauseOnHover}
            waitingToShow={waitingToShow}
          />
          <LiveFilters
            eventCounts={eventCounts.current}
            peopleCounts={peopleCounts.current}
            identifyEvents={identifyEvents.current}
            pageCounts={pageCounts.current}
            visitEvents={visitEvents.current}
          />
        </section>
      </div>
    </>
  );
}
