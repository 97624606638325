import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useQueryParams } from '/src/hooks';
import { getParam, deleteParam } from '/src/utils/location';
import { getAllEventNames } from './live-functions';

const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
};
const { SET, RESET } = FILTER_ACTIONS;

const FilterContext = createContext();

const getInitialValue = (name, possibleValues) => {
  const param = getParam(name);
  let parsed;
  try {
    parsed = JSON.parse(param);
  } catch {
    deleteParam(name);
    return null;
  }
  if (Array.isArray(parsed) && parsed.every((x) => possibleValues.includes(x))) {
    return parsed;
  } else {
    deleteParam(name);
    return null;
  }
};

const initialState = () => {
  const allEvents = getAllEventNames();
  return {
    events: getInitialValue('events', allEvents) || [...allEvents],
    page: getParam('page') || undefined,
    person: getParam('person') || undefined,
    search: getParam('search') || '',
  };
};

export function FilterProvider({ children }) {
  function filterReducer(state = initialState(), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case RESET:
        return {
          ...initialState(),
        };
      default:
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, initialState());
  const [filterState, setFilterState] = useState({ filter, filterDispatch });
  if (!isEqual(filter, filterState.filter)) {
    setFilterState({ filter, filterDispatch });
  }
  return <FilterContext.Provider value={filterState}>{children}</FilterContext.Provider>;
}

export function useFilter() {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filter, filterDispatch } = useContext(FilterContext);

  const setPersonFilter = useCallback(
    (person) => {
      queryParamsSet('person', person);
      filterDispatch({
        type: SET,
        payload: {
          person,
        },
      });
    },
    [filterDispatch, queryParamsSet],
  );

  const setEventFilter = useCallback(
    (events) => {
      const allEvents = getAllEventNames();
      if (allEvents.length !== events.length) {
        queryParamsSet('events', JSON.stringify(events));
      } else {
        queryParamsSet('events', '');
      }
      filterDispatch({
        type: SET,
        payload: {
          events,
        },
      });
    },
    [filterDispatch, queryParamsSet],
  );

  const setPageFilter = useCallback(
    (page) => {
      queryParamsSet('page', page);
      filterDispatch({
        type: SET,
        payload: {
          page,
        },
      });
    },
    [filterDispatch, queryParamsSet],
  );

  const search = useCallback(
    (value) => {
      queryParamsSet('search', value);
      filterDispatch({
        type: SET,
        payload: {
          search: value,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  function reset() {
    removeAll(['search', 'person', 'events', 'page']);
    filterDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  return {
    filter,
    setPersonFilter,
    setEventFilter,
    setPageFilter,
    reset,
    search,
  };
}
