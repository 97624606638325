import { useEffect } from 'react';
import { useActor } from '@xstate/react';
import { v4 as uuid } from 'uuid';

import { CodeBox } from '/src/components/CodeBox';
import { generateCodeGoalScript } from '/src/features/goals/utils';

export function CodeTrigger({ service, triggerIndex }) {
  const [state] = useActor(service);
  const { send } = service;
  // const [triggerField] = useField(`triggers.${triggerIndex}.trigger`);

  const values = state.context.payload.triggers[triggerIndex];

  useEffect(() => {
    if (!values.code) {
      send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'code', value: uuid() });
    }
  }, [send, triggerIndex, values.code]);

  if (!values.code) return null;

  return (
    <>
      <div>
        <h2 className="text-header-2 mb-5">Place this snippet into your website code:</h2>
        <CodeBox loading={false} code={generateCodeGoalScript(values.code)} canCopy={true} />

        <div className="text-body-2 my-10 ml-2 flex flex-col leading-6 text-black-pearl-500	">
          <div className="flex">
            <strong className="w-6">1.</strong>
            <span>Copy the code snippet</span>
          </div>
          <div className="flex">
            <strong className="w-6">2.</strong>
            <span>Place the code snippet on the page before the standard Crazy Egg tracking script.</span>
          </div>
        </div>

        <span className="text-body-2 text-lynch-500">
          <strong>Note:</strong> This is an advanced feature for developers. If you aren&#39;t sure how to implement
          this code snippet, we recommend using the other tracking options.
        </span>
      </div>
    </>
  );
}
