import { LineChart } from './LineChart';
import { inflect } from '/src/utils/string';
import { RATING_SUBTYPES } from '/src/features/addons/constants';
import { EmojiFaces } from '../EmojiFaces';

export function Rating({ question }) {
  const minValue = question?.min?.value ?? 1;
  const maxValue = question?.max?.value ?? 5;
  const totalCount = question.results?.reduce((acc, curr) => acc + curr.count, 0);

  const range = Array.apply(null, new Array(maxValue - minValue + 1)).map((_, i) => ({
    rating: i + minValue,
    count: 0,
  }));

  range.forEach((x) => {
    if (question.subtype === RATING_SUBTYPES.STARS) {
      x.value = `⭐️ `.repeat(x.rating);
    } else if (question.subtype === RATING_SUBTYPES.SMILEY) {
      x.value = EmojiFaces(x.rating);
    } else {
      x.value = x.rating;
    }

    if (x.rating === minValue && question?.min?.label) {
      x.label = ` — ${question.min.label}`;
    } else if (x.rating === maxValue && question?.max?.label) {
      x.label = ` — ${question.max.label}`;
    }
  });
  const reversedRange = range.reverse();

  for (const result of question.results) {
    reversedRange.find((item) => item.rating === +result.value).count = result.count;
  }

  return (
    <div className="flex flex-col space-y-5 px-3.75">
      {reversedRange.map(({ value, label, count }, index) => {
        return (
          <div className="flex flex-col" key={`${question.id}_${index}`}>
            <div className="text-body-4 mb-2.5 flex w-full items-center justify-between">
              <div className="flex items-center whitespace-pre text-black-pearl-500">
                {value} {label}
              </div>
              <span className="text-lynch-500">
                {count} {inflect('response', count)}
              </span>
            </div>
            <LineChart index={index} value={count} maxValue={totalCount} />
          </div>
        );
      })}
    </div>
  );
}
