import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Divider, Indicator } from '@crazyegginc/hatch';

import { useAuthContext, useWizard } from '/src/hooks';

import { DeviceTracking } from './settings/DeviceTracking';
import { StartSnapshot } from './settings/StartSnapshot';
import { EndSnapshot } from './settings/EndSnapshot';
import { BlockingElements } from './settings/BlockingElements';
import { SamplingRatio } from './settings/SamplingRatio';
import { CaptureTimer } from './settings/CaptureTimer';
import { TrackingOptions } from './settings/TrackingOptions';
import { RecurringSnapshot } from './settings/RecurringSnapshot';

import { SNAPSHOT_BLOCKING_POPUP_OPTIONS, SNAPSHOT_SAMPLING_RATIO_OPTIONS } from '/src/features/snapshots/constants';

export function SnapshotSettings() {
  const { sessionInfo } = useAuthContext();

  const { data: wizardData } = useWizard();
  const location = useLocation();
  const formik = useFormikContext();

  const isImpersonated = sessionInfo?.isImpersonated ?? false;
  const existingId = wizardData?.existingSnapshot?.id ?? null;
  const isEditing = !!existingId;
  const isPageCameraSnapshot = formik.values.pageCameraSnapshot;

  const configuredTrackingOptions =
    Object.keys(formik.values.trackingOptions).filter((option) => formik.values.trackingOptions[option] !== null)
      .length - 1;
  const blockingChanged = formik.values.blocking.option !== SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_POPUPS;
  const samplingRatioChanged = formik.values.samplingRatio.option !== SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT;
  const [showMore, setShowMore] = useState(configuredTrackingOptions > 0 || blockingChanged || samplingRatioChanged);

  const validationErrors = location.state?.validationErrors ?? [];

  return (
    <>
      <DeviceTracking isEditing={isEditing} />

      <div className="mt-10 flex w-full">
        <StartSnapshot isEditing={isEditing} validationErrors={validationErrors} />
        <EndSnapshot isEditing={isEditing} validationErrors={validationErrors} />
      </div>

      {!isPageCameraSnapshot && (
        <>
          <div className="mb-3 mt-10 flex w-full">
            <RecurringSnapshot isEditing={isEditing} />
          </div>

          <BlockingElements />
        </>
      )}

      <div className="mt-7 flex w-full items-center">
        <button
          type="button"
          className="text-body-1 flex items-center text-dodger-blue-500 focus-visible:outline-black"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? 'Show advanced settings' : 'Hide advanced settings'}
          <Indicator type="expand" up={showMore} className="ml-2.5" />
        </button>
        <Divider className="ml-4 w-auto flex-1" dashed />
      </div>

      {showMore && (
        <>
          <SamplingRatio validationErrors={validationErrors} />
          {isImpersonated && <CaptureTimer />}
          <TrackingOptions isEditing={isEditing} />
        </>
      )}
    </>
  );
}
