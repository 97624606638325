import { useEffect, useContext, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { recordingsCriteriaDefinitionQuery, playlistsListQuery } from '/src/features/recordings/queries';
import { useSelectedSite, usePrevious, useQueryParams, useAuthContext } from '/src/hooks';
import { PLAYLIST_TYPES, RECORDINGS_FILTER_ACTIONS } from '/src/features/recordings/constants';
import { FilterContext } from './recordings-filter-context';

function useRecordingsCriteriaDefinitionQuery() {
  const { isSharing } = useAuthContext();
  const { state, dispatch } = useContext(FilterContext);

  const {
    data: queryData,
    isFetching: fetching,
    error,
  } = useQuery({
    ...recordingsCriteriaDefinitionQuery({ version: state.filtersVersion }),
    enabled: Boolean(!isSharing && state.recordingsCriteriaDefinitionQuery?.data?.version !== state.filtersVersion),
    staleTime: 60 * 60 * 1000,
  });

  const data = queryData ? JSON.parse(queryData?.recordingsCriteriaDefinition ?? null) : null;

  useEffect(() => {
    if (data && state.recordingsCriteriaDefinitionQuery?.data?.version !== state.filtersVersion) {
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.SET_DEFINITIONS, payload: { data, fetching } });
    }
  }, [data, dispatch, fetching, state.filtersVersion, state.recordingsCriteriaDefinitionQuery?.data?.version]);

  useEffect(() => {
    // reset filtersVersion in case the override value was incorrect
    if (error) {
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.SET_FILTERS_VERSION, payload: null });
    }
    // set filtersVersion to the received version initially
    else if (data && !fetching && !state.filtersVersion) {
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.SET_FILTERS_VERSION, payload: data.version });
    }
  }, [dispatch, error, data, fetching, state.filtersVersion]);
}

function usePlaylistsListQuery() {
  const { isSharing } = useAuthContext();
  const { selectedSite } = useSelectedSite();
  const { state, dispatch } = useContext(FilterContext);

  const { data, isFetching: fetching } = useQuery({
    ...playlistsListQuery({ site: selectedSite?.id, version: state.filtersVersion }),
    enabled: Boolean(!isSharing && selectedSite?.id && state.filtersVersion),
  });

  // update filterVersion if a greater version found in the playlists
  useEffect(() => {
    if (!data) return;
    let max = 0;
    data.playlistsList.forEach((playlist) => {
      let filter;
      try {
        filter = JSON.parse(playlist.filter);
      } finally {
        if (filter) {
          max = Math.max(max, filter.version);
        }
      }
    });
    if (max > state.filtersVersion) {
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.SET_FILTERS_VERSION, payload: max });
    }
  }, [data, state.filtersVersion, dispatch, fetching]);

  return { data, fetching };
}

export function useFilter() {
  const { selectedSite } = useSelectedSite();
  const { get: queryParamsGet, set: queryParamsSet } = useQueryParams();
  const { state, dispatch } = useContext(FilterContext);

  const playlistsListQuery = usePlaylistsListQuery();
  useRecordingsCriteriaDefinitionQuery();

  // refresh definitions in state if version changes
  const prevDefVersion = usePrevious(state.recordingsCriteriaDefinitionQuery.data?.version);
  useEffect(() => {
    if (
      state.recordingsCriteriaDefinitionQuery.data &&
      prevDefVersion !== state.recordingsCriteriaDefinitionQuery.data.version
    ) {
      dispatch({
        type: RECORDINGS_FILTER_ACTIONS.REFRESH_DEFINITION,
        payload: state.recordingsCriteriaDefinitionQuery.data.definitions,
      });
    }
  }, [dispatch, state.recordingsCriteriaDefinitionQuery.data, prevDefVersion]);

  const setPage = useCallback(
    (page) => {
      queryParamsSet('page', page);
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.SET_PAGE, payload: page });
    },
    [queryParamsSet, dispatch],
  );

  const setFilters = useCallback(
    (filters, playlist, conditions) => {
      if (playlist) {
        if (playlist.type === PLAYLIST_TYPES.RECOMMENDED) {
          queryParamsSet('filters', JSON.stringify(playlist.name));
        } else {
          queryParamsSet('filters', playlist.id);
        }
      } else {
        queryParamsSet('filters', JSON.stringify(filters));
      }
      // ensure that a site is always present with a filter
      if (!queryParamsGet('site')) {
        queryParamsSet('site', selectedSite.name);
      }
      setPage(1);
      dispatch({ type: RECORDINGS_FILTER_ACTIONS.APPLY_FILTERS, payload: { conditions, filters } });
    },
    [queryParamsSet, queryParamsGet, dispatch, setPage, selectedSite?.name],
  );

  return {
    ...state,
    setFilters,
    playlistsListQuery,
  };
}
