import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

import ExpiredPNG from '@crazyegginc/hatch/dist/images/illustration-cc-expired.png';
import ExpiredWebP from '@crazyegginc/hatch/dist/images/illustration-cc-expired.webp';

export function TrialCCFailedPaywall() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image webp={ExpiredWebP} fallback={ExpiredPNG} width="143" />
      <Paywall.Title>Please validate your credit card details to continue your trial</Paywall.Title>
      {canManageBilling ? (
        <>
          <Paywall.Body>
            Don&apos;t worry! You won&apos;t be charged until the end of your trial, but there seems to be an issue with
            the information you provided. To continue your trial please re-confirm your billing details.
          </Paywall.Body>
          <Button component={Link} to={{ pathname: '/account/billing', search: 'billingModal=true' }}>
            Update credit card
          </Button>
        </>
      ) : (
        <>
          <Paywall.Body>
            To continue using Crazy Egg, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to update the
            billing information.
            <div className="mt-5">
              If you need help, email us at{' '}
              <a className="text-link" href={`mailto:${SupportLinks.general.email}`}>
                {SupportLinks.general.email}
              </a>
              .
            </div>
          </Paywall.Body>
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
            Contact account owner
          </Button>
        </>
      )}
    </Paywall>
  );
}
