import { Disclosure } from '@headlessui/react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Indicator, Divider, Panel, Checkbox, Button, Input, Select, capitalizeFirstLetter } from '@crazyegginc/hatch';

import { useFunnelContext } from '../funnel-context';
import { superLenientUrlRegex } from '/src/utils/regex';
import { ANY_VALUE, VISITOR_TYPES } from '../constants';

const validationSchema = yup.object().shape({
  checkboxes: yup.object().shape({
    landingPage: yup.boolean(),
    referrerUrl: yup.boolean(),
    utm: yup.boolean(),
    abTest: yup.boolean(),
  }),
  landingPage: yup.string().when('checkboxes', {
    is: (value) => value.landingPage === true,
    then: yup
      .string()
      .required("Please provide a URL or '*' for any value.")
      .test('is valid url', "Please provide a valid URL or '*' for any value.", (value) => {
        if (!value) return false;
        return value === ANY_VALUE || value.match(superLenientUrlRegex);
      }),
  }),
  referrerUrl: yup.string().when('checkboxes', {
    is: (value) => value.referrerUrl === true,
    then: yup.string().test('is valid url', "Please provide a valid URL or '*' for any value.", (value) => {
      if (!value) return true;
      return value === ANY_VALUE || value.match(superLenientUrlRegex);
    }),
  }),
  utmSource: yup.string(),
  utmCampaign: yup.string(),
  utmMedium: yup.string(),
  utmTerm: yup.string(),
  utmContent: yup.string(),
  abTestName: yup.string(),
  abTestVariationName: yup.string(),
});

export function ManageFilters() {
  const { filters, setFilterParamValue, resetFilters } = useFunnelContext();

  return (
    <Formik
      initialValues={{
        checkboxes: {
          landingPage: filters.landingPage !== ANY_VALUE,
          referrerUrl: filters.referrerUrl !== ANY_VALUE,
          utm:
            filters.utmSource !== ANY_VALUE ||
            filters.utmCampaign !== ANY_VALUE ||
            filters.utmMedium !== ANY_VALUE ||
            filters.utmTerm !== ANY_VALUE ||
            filters.utmContent !== ANY_VALUE,
          abTest: filters.abTestName !== ANY_VALUE || filters.abTestVariationName !== ANY_VALUE,
        },
        landingPage: filters.landingPage,
        referrerUrl: filters.referrerUrl,
        utmSource: filters.utmSource,
        utmCampaign: filters.utmCampaign,
        utmMedium: filters.utmMedium,
        utmTerm: filters.utmTerm,
        utmContent: filters.utmContent,
        abTestName: filters.abTestName,
        abTestVariationName: filters.abTestVariationName,
        visitor: filters.visitor,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setFilterParamValue({ landingPage: values.checkboxes.landingPage ? values.landingPage : ANY_VALUE });

        setFilterParamValue({ referrerUrl: values.checkboxes.referrerUrl ? values.referrerUrl : ANY_VALUE });
        setFilterParamValue({ utmSource: values.checkboxes.utm ? values.utmSource : ANY_VALUE });
        setFilterParamValue({ utmCampaign: values.checkboxes.utm ? values.utmCampaign : ANY_VALUE });
        setFilterParamValue({ utmMedium: values.checkboxes.utm ? values.utmMedium : ANY_VALUE });
        setFilterParamValue({ utmTerm: values.checkboxes.utm ? values.utmTerm : ANY_VALUE });
        setFilterParamValue({ utmContent: values.checkboxes.utm ? values.utmContent : ANY_VALUE });

        setFilterParamValue({ abTestName: values.checkboxes.abTest ? values.abTestName : ANY_VALUE });
        setFilterParamValue({ abTestVariationName: values.checkboxes.abTest ? values.abTestVariationName : ANY_VALUE });
        setFilterParamValue({ visitor: values.visitor });
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur, resetForm, setFieldValue }) => {
        return (
          <Disclosure as="div" className="mb-5">
            {({ open }) => (
              <>
                <Disclosure.Button className="text-header-4 flex w-full items-center text-dodger-blue-500 focus-visible:outline-black">
                  Manage filters <Indicator className="ml-2" type="expand" up={open} />
                  <Divider className="ml-4 w-auto flex-1" dashed />
                </Disclosure.Button>

                <Disclosure.Panel>
                  <Form>
                    <Panel>
                      <Checkbox
                        labelClass="!font-semibold"
                        label="Landing page"
                        id="landingPageCheckbox"
                        name="checkboxes.landingPage"
                        checked={values.checkboxes.landingPage}
                        onChange={handleChange}
                      />
                      {values.checkboxes.landingPage && (
                        <div className="mb-4 ml-7 w-full max-w-lg">
                          <Input
                            name="landingPage"
                            id="landingPage"
                            aria-label="Landing page"
                            value={values.landingPage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.landingPage ? errors.landingPage : null}
                          />
                        </div>
                      )}

                      <Checkbox
                        labelClass="!font-semibold"
                        label="Referrer"
                        id="referrerUrlCheckbox"
                        name="checkboxes.referrerUrl"
                        checked={values.checkboxes.referrerUrl}
                        onChange={handleChange}
                      />
                      {values.checkboxes.referrerUrl && (
                        <div className="mb-4 ml-7 w-full max-w-lg">
                          <Input
                            name="referrerUrl"
                            id="referrerUrl"
                            aria-label="Referrer"
                            value={values.referrerUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.referrerUrl ? errors.referrerUrl : null}
                          />
                        </div>
                      )}

                      <Checkbox
                        labelClass="!font-semibold"
                        label="Ad Campaign"
                        id="utmCheckbox"
                        name="checkboxes.utm"
                        checked={values.checkboxes.utm}
                        onChange={handleChange}
                      />
                      {values.checkboxes.utm && (
                        <div className="mb-4 flex w-full flex-col space-y-4">
                          <div className="ml-7 flex items-center space-x-5">
                            <Input
                              name="utmSource"
                              id="utmSource"
                              label="Campaign Source"
                              value={values.utmSource}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.utmSource ? errors.utmSource : null}
                            />
                            <Input
                              name="utmMedium"
                              id="utmMedium"
                              label="Campaign Medium"
                              value={values.utmMedium}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.utmMedium ? errors.utmMedium : null}
                            />
                          </div>
                          <div className="ml-7 flex items-center space-x-5">
                            <Input
                              name="utmCampaign"
                              id="utmCampaign"
                              label="Campaign Name"
                              value={values.utmCampaign}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.utmCampaign ? errors.utmCampaign : null}
                            />
                            <Input
                              name="utmTerm"
                              id="utmTerm"
                              label="Campaign Term"
                              value={values.utmTerm}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.utmTerm ? errors.utmTerm : null}
                            />
                          </div>
                          <div className="ml-7 flex items-center space-x-5">
                            <Input
                              name="utmContent"
                              id="utmContent"
                              label="Campaign Content"
                              value={values.utmContent}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.utmContent ? errors.utmContent : null}
                            />
                            <div className="w-full" />
                          </div>
                        </div>
                      )}

                      <Checkbox
                        labelClass="!font-semibold"
                        label="A/B Testing"
                        id="abTestCheckbox"
                        name="checkboxes.abTest"
                        checked={values.checkboxes.abTest}
                        onChange={handleChange}
                      />
                      {values.checkboxes.abTest && (
                        <div className="mb-4 flex w-full flex-col space-y-4">
                          <div className="ml-7 flex items-center space-x-5">
                            <Input
                              name="abTestName"
                              id="abTestName"
                              label="A/B Test Name"
                              value={values.abTestName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.abTestName ? errors.abTestName : null}
                            />
                            <Input
                              name="abTestVariationName"
                              id="abTestVariationName"
                              label="A/B Test Variation Name or ID"
                              value={values.abTestVariationName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.abTestVariationName ? errors.abTestVariationName : null}
                            />
                          </div>
                        </div>
                      )}
                      <div className="mt-5 w-40">
                        <Select
                          options={Object.values(VISITOR_TYPES).map((v) => ({
                            value: v,
                            label: capitalizeFirstLetter(v.toLowerCase()),
                          }))}
                          value={values.visitor}
                          label="Visitor type"
                          labelClassName="!font-semibold"
                          onChange={(value) => {
                            setFieldValue('visitor', value);
                          }}
                        />
                      </div>
                      <div className="mt-5 flex items-center space-x-2.5">
                        <Button type="submit">Apply</Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            resetFilters();
                            resetForm({
                              values: {
                                checkboxes: {
                                  landingPage: false,
                                  referrerUrl: false,
                                  utm: false,
                                  abTest: false,
                                },
                                landingPage: ANY_VALUE,
                                referrerUrl: ANY_VALUE,
                                utmSource: ANY_VALUE,
                                utmCampaign: ANY_VALUE,
                                utmMedium: ANY_VALUE,
                                utmTerm: ANY_VALUE,
                                utmContent: ANY_VALUE,
                                abTestName: ANY_VALUE,
                                abTestVariationName: ANY_VALUE,
                                visitor: VISITOR_TYPES.ALL,
                              },
                            });
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </Panel>
                  </Form>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      }}
    </Formik>
  );
}
