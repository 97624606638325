import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { produce } from 'immer';

import { useQueryParams } from '/src/hooks';
import { getParam, deleteParam } from '/src/utils/location';
import { ALL_RESPONSES_TABLE_ORDER } from '/src/features/addons/constants';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';

const FILTER_ACTIONS = {
  SET_ORDER_FIELD: 'SET_ORDER_FIELD',
  SET_ORDER_SORT: 'SET_ORDER_SORT',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
};

const FilterContext = createContext();

const getInitialValue = (name, possibleValues, parse) => {
  let param = getParam(name);
  if (!param) return null;

  if (parse) {
    try {
      param = JSON.parse(param);
    } catch {
      deleteParam(name);
      return null;
    }
  }

  if (!possibleValues || possibleValues.includes(param)) {
    return param;
  } else {
    deleteParam(name);
    return null;
  }
};

const initialState = () => ({
  order: {
    field:
      getInitialValue('order', [...Object.values(ALL_RESPONSES_TABLE_ORDER)]) || ALL_RESPONSES_TABLE_ORDER.CREATED_AT,
    questionId: getInitialValue('qid') || null,
    sort: getInitialValue('sort', [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || SORT_ORDER_TYPES.DESC,
  },
  initialColumnVisibility: getInitialValue('hidden_columns', null, true) ?? {},
  index: isNaN(getParam('response')) ? 1 : +getParam('response'),
});

export function SurveyResponseFilterProvider({ children }) {
  function reducer(state, action) {
    switch (action.type) {
      case FILTER_ACTIONS.SET_ORDER_FIELD:
        return produce(state, (draft) => {
          draft.order.field = action.payload.field;
          draft.order.questionId = action.payload.questionId;
        });

      case FILTER_ACTIONS.SET_ORDER_SORT:
        return produce(state, (draft) => {
          draft.order.sort = action.payload;
        });

      case FILTER_ACTIONS.SET:
        return produce(state, (draft) => {
          draft[action.payload.key] = action.payload.value;
        });
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState());

  const [contextValue, setContextValue] = useState({ state, dispatch });

  if (!isEqual(state, contextValue.state)) {
    setContextValue({ state, dispatch });
  }

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
}

export function useSurveyResponseFilter() {
  const { state, dispatch } = useContext(FilterContext);
  const { set: queryParamsSet, setAll: queryParamsSetAll } = useQueryParams();

  const setSort = useCallback(
    (sort) => {
      queryParamsSet('sort', sort);
      dispatch({ type: FILTER_ACTIONS.SET_ORDER_SORT, payload: sort });
    },
    [dispatch, queryParamsSet],
  );

  const setOrderField = useCallback(
    ({ field, questionId }) => {
      queryParamsSetAll({ order: field, qid: questionId });
      dispatch({ type: FILTER_ACTIONS.SET_ORDER_FIELD, payload: { field, questionId } });
    },
    [dispatch, queryParamsSetAll],
  );

  const setHiddenColumns = useCallback(
    (cols) => {
      const hiddenColumns = Object.keys(cols).reduce((acc, cur) => {
        if (cols[cur] === false) {
          acc[cur] = false;
        }
        return acc;
      }, {});
      const numOfHiddenColumns = Object.values(hiddenColumns).reduce(
        (acc, curr) => (curr === false ? acc + 1 : acc),
        0,
      );
      if (numOfHiddenColumns > 0) {
        queryParamsSet('hidden_columns', JSON.stringify(hiddenColumns));
      } else {
        queryParamsSet('hidden_columns', '');
      }
    },
    [queryParamsSet],
  );

  const updateIndex = useCallback(
    (i, total) => {
      let next = i;
      if (total) {
        if (next < 1) {
          next = total;
        }
        if (next > total) {
          next = 1;
        }
      } else {
        if (next < 1) {
          next = 1;
        }
      }
      dispatch({ type: FILTER_ACTIONS.SET, payload: { key: 'index', value: next } });
      queryParamsSet('response', next);
    },
    [queryParamsSet, dispatch],
  );

  return {
    ...state,
    setSort,
    setOrderField,
    setHiddenColumns,
    updateIndex,
  };
}
