import { DropdownEvent, DropdownRow } from '../PlayerEvent';
import { ReactComponent as EndedIcon } from '@crazyegginc/hatch/dist/images/icon-stop-circle-outline.svg';
import { ReactComponent as InactivityIcon } from '@crazyegginc/hatch/dist/images/icon-load-dots.svg';
import { ReactComponent as AbandonedIcon } from '@crazyegginc/hatch/dist/images/icon-login.svg';

import { PLAYER_END_EVENT_REASONS } from '/src/features/recordings/constants';

export function EndEvent({ timestamp, active, event, ...rest }) {
  return (
    <DropdownEvent icon={EndedIcon} title="Recording ended" timestamp={timestamp} active={active} {...rest}>
      {event.reason === PLAYER_END_EVENT_REASONS.INACTIVITY ? (
        <DropdownRow icon={InactivityIcon} title="Inactivity" />
      ) : null}
      {event.reason === PLAYER_END_EVENT_REASONS.CLOSED ? (
        <DropdownRow icon={AbandonedIcon} title="Abandoned page" />
      ) : null}
    </DropdownEvent>
  );
}
