import { Modal } from '/src/contexts/modal';
import { BEHAVIOR_TRIGGERS } from '/src/features/addons/constants';

export function PreviewWarningModal({ autoPosition, autoPositionFallbackToShow, behavior, spelledType }) {
  return (
    <Modal>
      <Modal.Title>Action required to preview {spelledType}</Modal.Title>
      <div className="text-body-2">
        {behavior.show === BEHAVIOR_TRIGGERS.TIME_DELAY && (
          <p>
            The {spelledType} is set to launch {behavior.timeDelaySeconds} seconds after the page loads. Please wait
            longer than {behavior.timeDelaySeconds} seconds to see the preview.
          </p>
        )}

        {behavior.show === BEHAVIOR_TRIGGERS.SCROLL_PAST_FOLD && (
          <p>
            The {spelledType} is set to launch after a visitor scrolls past the fold. Please scroll down the page to see
            the preview.
          </p>
        )}

        {behavior.show === BEHAVIOR_TRIGGERS.EXIT_INTENT && (
          <p>
            The {spelledType} is set to launch when a visitor is about to leave the page. Please move the mouse to the
            top of the window as if you were to close the tab, to see the preview.
          </p>
        )}

        {behavior.show === BEHAVIOR_TRIGGERS.NUM_PAGES && (
          <p>
            The {spelledType} is set to launch after a visitor navigates to {behavior.numPages} pages. However, the{' '}
            {spelledType} will launch immediately for the preview.
          </p>
        )}

        {autoPosition && !autoPositionFallbackToShow && (
          <p className="mb-5">
            The auto-positioning of the {spelledType} is set to avoid other elements on the page. If the preview does
            not show the {spelledType}, try adjusting the auto-position settings.
          </p>
        )}
      </div>
    </Modal>
  );
}
