import { FEATURES } from '/src/features/_global/constants';

export const ACCOUNT_PERMISSIONS = {
  [FEATURES.BILLING]: ({ capabilities }) => ({
    navigate: capabilities?.billing.permissions.nav,
    edit: capabilities?.billing.permissions.edit,
    navStatus: capabilities?.billing.permissions.navStatus,
  }),
  [FEATURES.CRAZY_EGG_EMAILS]: ({ capabilities }) => ({
    navigate: capabilities?.crazyEggEmailSettings.permissions.nav,
    edit: capabilities?.crazyEggEmailSettings.permissions.edit,
  }),
  ACCOUNT: () => ({}), // needed for What's new
};
