import { useState } from 'react';
import { Panel } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';

import { PlanDetails } from '../components/PlanDetails';
import { YourPlan } from '../components/YourPlan';
import { PlanAddons } from '../components/PlanAddons';

import { snapshotAddonListDetails } from '../utils';

import { SNAPSHOT_ADDON_50 } from '/src/features/account/constants';
import { PLAN_TYPES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function AccountPlan() {
  const [snapshotAddon, setSnapshotAddon] = useState(snapshotAddonListDetails(SNAPSHOT_ADDON_50));

  const { subscription } = useAuthContext();
  const isTrial = subscription.plan.type === PLAN_TYPES.TRIAL;
  let currentPlanTitle = subscription.plan.name;
  if (isTrial && !currentPlanTitle.match(/Trial/)) {
    currentPlanTitle += ' Trial';
  }

  return (
    <div className="min-w-[1200px] px-10">
      <Panel>
        <YourPlan snapshotAddon={snapshotAddon} currentPlanTitle={currentPlanTitle} />
        <PlanDetails />
        <PlanAddons snapshotAddon={snapshotAddon} setSnapshotAddon={setSnapshotAddon} />
      </Panel>
    </div>
  );
}
