import { useSite, usePermissions } from '/src/hooks';
import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';

import { SitePickerBar } from '/src/components/site-picker/index';
import { RecordingAlerts } from '/src/features/_global/components/RecordingAlerts';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { FEATURES } from '/src/features/_global/constants';

export function RecordingsDashboardContentHeader() {
  const permissions = usePermissions();
  const {
    sites: recordingsSites,
    selectedSite,
    selectSite,
  } = useSite({ sitesQuery: RECORDINGS_SITES_QUERY, dontSelectSite: true });
  const { sites } = useSite({ dontSelectSite: true });
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  return (
    <>
      <SitePickerBar
        sites={recordingsSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        showEnableOtherSites={sites.length > recordingsSites.length}
        pageDisplayName="Recordings dashboard"
        addSiteOption={canEditSites}
      />

      <>
        <RecordingAlerts />
        <InstallationBanner />
      </>
    </>
  );
}
