import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, endOfDay, addDays, getUnixTime, fromUnixTime, isBefore, min } from 'date-fns';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { DateRangePicker } from '@crazyegginc/hatch';

import { usePermissions, useSite, useSelectedSite, useQueryParams, usePrevious } from '/src/hooks';
import { getConvertedDateRange, getInitialDate } from '/src/utils/date';

import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { EnableRecordingsWall } from '../../recordings/components/dashboard/paywalls/EnableRecordingsWall';
import { SitePickerBar } from '/src/components/site-picker/index';
import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';
import { trafficAnalysisQuery } from '../queries';
import { TopTrafficSourcesTab } from './TopTrafficSourcesTab';
import { BuildYourOwnTab } from './BuildYourOwnTab';
import { TATable } from './TATable';

import { db } from '../db';
import { initialSelection } from '../utils';
import { QUERY_LIMIT, INITIAL_CRITERIA_FILTER_ORDER } from '../constants';
import { FEATURES, SPECIAL_DATE_RANGES } from '/src/features/_global/constants';

import { RecordingAlerts } from '/src/features/_global/components/RecordingAlerts';

export function TADashboardContent() {
  const {
    sites: recordingsSites,
    selectedSite,
    selectSite,
    loadingSites,
  } = useSite({ sitesQuery: RECORDINGS_SITES_QUERY });
  const { sites } = useSite({ dontSelectSite: true });
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  if (!loadingSites && recordingsSites.length === 0) {
    return (
      <div className="flex w-full items-center justify-center pt-5">
        <EnableRecordingsWall />
      </div>
    );
  }

  return (
    <>
      <SitePickerBar
        sites={recordingsSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        loading={loadingSites}
        showEnableOtherSites={sites.length > recordingsSites.length}
        addSiteOption={canEditSites}
        pageDisplayName="Traffic Analysis dashboard"
      />
      <RecordingAlerts />
      <InstallationBanner />
      <div className="flex flex-col p-10">
        <TrafficAnalysis />
      </div>
    </>
  );
}

function getInitialComparisons(queryParam) {
  if (queryParam) {
    let param;
    try {
      param = JSON.parse(queryParam);
    } catch {
      return null;
    }
    if (!Array.isArray(param)) {
      return null;
    }
    for (const comparison of param) {
      if (typeof comparison !== 'object') {
        return null;
      }
      if (
        Object.keys(comparison).some((key) => {
          if (!INITIAL_CRITERIA_FILTER_ORDER.includes(key) || typeof comparison[key] !== 'object') {
            return true;
          }
          if (comparison[key].value === null) {
            return true;
          }
          return false;
        })
      ) {
        return null;
      }
    }
    return param;
  }
  return null;
}

function TrafficAnalysis() {
  const { set: queryParamsSet, get: queryParamsGet } = useQueryParams();
  const [comparisons, setComparisons] = useState(() => getInitialComparisons(queryParamsGet('comparisons')) ?? []);
  const [criteriaFilterOrder, setCriteriaFilterOrder] = useState(INITIAL_CRITERIA_FILTER_ORDER);
  const [totalSessionsForParams, setTotalSessionsForParams] = useState({});
  const { selectedSite } = useSelectedSite();
  const [initDone, setInitDone] = useState(false);
  const initialTab = queryParamsGet('tab') ?? 0;

  const [dateRange, setDateRange] = useState(
    getInitialDate(queryParamsGet('date')) ?? { special: SPECIAL_DATE_RANGES.LAST_30_DAYS },
  );

  // limit date range: users should not be able to select a date before 2021-12-01
  useEffect(() => {
    let convertedRange = getConvertedDateRange(dateRange);
    if (isBefore(fromUnixTime(convertedRange.startDate), startOfDay(new Date('2021-12-01')))) {
      let start = startOfDay(new Date('2021-12-01'));
      let end = min([endOfDay(new Date()), addDays(start, 31)]);
      queryParamsSet(
        'date',
        JSON.stringify({
          start_date: getUnixTime(start),
          end_date: getUnixTime(end),
        }),
      );
      setDateRange({ start_date: start, end_date: end });
    }
  }, [dateRange, queryParamsSet]);

  const convertedDateRange = getConvertedDateRange(dateRange);

  const prevSelectedSiteId = usePrevious(selectedSite?.id);

  const addToComparison = useCallback((selection) => {
    const transformed = {};
    Object.keys(selection).forEach((key) => {
      if (selection[key].length > 0) {
        transformed[key] = [...selection[key]];
      }
    });
    setComparisons((state) => {
      //avoid duplicate columns
      const jsonTransformed = JSON.stringify(transformed);
      if (state.every((x) => JSON.stringify(x) !== jsonTransformed)) {
        return [...state, { ...transformed }];
      } else {
        return state;
      }
    });
  }, []);

  const removeFromComparison = useCallback((index) => {
    setComparisons((x) => {
      let result;
      result = [...x];
      result.splice(index, 1);
      return [...result];
    });
  }, []);

  const resetComparison = useCallback(() => {
    setComparisons([]);
  }, []);

  useEffect(() => {
    queryParamsSet('comparisons', JSON.stringify(comparisons));
  }, [comparisons, queryParamsSet]);

  useEffect(() => {
    // reset state when switching sites
    if (prevSelectedSiteId !== undefined && selectedSite?.id !== prevSelectedSiteId) {
      setComparisons([]);
    }
  }, [prevSelectedSiteId, selectedSite?.id]);

  const { data, isLoading } = useQuery({
    ...trafficAnalysisQuery({
      siteId: selectedSite?.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
      limit: QUERY_LIMIT,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  useEffect(() => {
    let cancelled = false;
    // (re-)populate db when data from backend received
    async function populateDB() {
      await db.traffic.clear();

      if (cancelled) return;
      if (data.trafficAnalysis?.length === 0) {
        setInitDone(true);
        return;
      }

      const keys = await db.traffic.bulkAdd(
        data.trafficAnalysis.map((x) => ({
          utmSource: x.utmSource ?? '',
          utmMedium: x.utmMedium ?? '',
          utmCampaign: x.utmCampaign ?? '',
          utmTerm: x.utmTerm ?? '',
          utmContent: x.utmContent ?? '',
          referrerUrl: x.referrerUrl ?? '',
          total: x.total,
        })),
        undefined,
        { allKeys: true },
      );

      const cardinalities = [];
      const totals = {};
      for (const criteria of Object.keys(initialSelection())) {
        const uniqueKeys = await db.traffic.orderBy(criteria).uniqueKeys();
        const cardinality = uniqueKeys.length;
        cardinalities.push({ name: criteria, cardinality });

        const total = {};
        for (const value of uniqueKeys) {
          total[value] = 0;
          await db.traffic
            .where(criteria)
            .equalsIgnoreCase(value)
            .each((x) => (total[value] += x.total));
        }
        totals[criteria] = total;
      }

      // need to clean up if this useEffect was cancelled while adding items to avoid duplicates
      if (cancelled) {
        await db.traffic.bulkDelete(keys);
      } else {
        setCriteriaFilterOrder(
          cardinalities
            .sort((a, b) => (a.cardinality > b.cardinality ? -1 : b.cardinality > a.cardinality ? 1 : 0))
            .map((x) => x.name),
        );
        setTotalSessionsForParams(totals);
        setInitDone(true);
      }
    }
    if (data) {
      populateDB();
    }
    return () => (cancelled = true);
  }, [data]);

  const setDateRangeWrapper = useCallback(
    (value) => {
      setDateRange(value);
      queryParamsSet(
        'date',
        JSON.stringify({
          start_date: getUnixTime(value.start_date),
          end_date: getUnixTime(value.end_date),
        }),
      );
    },
    [queryParamsSet],
  );

  if (!selectedSite?.id) return null;

  return (
    <>
      <Tab.Group
        defaultIndex={initialTab}
        onChange={(index) => {
          queryParamsSet('tab', index);
        }}
      >
        <>
          <div className="mb-10 flex w-full items-end">
            <Tab.List className="flex border-b border-mystic-500">
              {['Top 10 traffic sources', 'Build your own'].map((header) => (
                <Tab
                  key={header}
                  className={({ selected }) =>
                    classNames(
                      'text-header-4 border-b-2 px-5 pb-3 text-dodger-blue-500 focus:outline-none focus-visible:outline-black',
                      'flex items-center',
                      {
                        'border-dodger-blue-500': selected,
                        'border-transparent': !selected,
                      },
                    )
                  }
                >
                  {header}
                </Tab>
              ))}
            </Tab.List>
            <div className="flex-grow"></div>
            <DateRangePicker
              lowerBoundary={startOfDay(new Date('2021-12-01'))}
              upperBoundary={endOfDay(new Date())}
              startDate={dateRange?.start_date}
              endDate={dateRange?.end_date}
              special={dateRange?.special}
              setDateRange={setDateRangeWrapper}
              setCommonDateRange={(value) => {
                setDateRange(value);
                queryParamsSet('date', JSON.stringify(value));
              }}
              showCommonRanges={true}
              maxRange={31}
              size="lg"
            />
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <TopTrafficSourcesTab initDone={initDone} addToComparison={addToComparison} fetching={isLoading} />
            </Tab.Panel>
            <Tab.Panel>
              <BuildYourOwnTab
                comparisons={comparisons}
                fetching={isLoading}
                initDone={initDone}
                addToComparison={addToComparison}
                criteriaFilterOrder={criteriaFilterOrder}
                totalSessionsForParams={totalSessionsForParams}
              />
            </Tab.Panel>
          </Tab.Panels>
        </>
      </Tab.Group>

      <TATable
        comparisons={comparisons}
        removeFromComparison={removeFromComparison}
        convertedDateRange={convertedDateRange}
        selectedSite={selectedSite}
        resetComparison={resetComparison}
      />
    </>
  );
}
