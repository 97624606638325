import { WebP } from './WebP';

export function SelectLabel({ webp, png, text }) {
  return (
    <div className="flex items-center">
      <WebP webp={webp} fallback={png} className="mr-4" imageClassName="w-5 h-auto" />
      <span className="text-md font-normal">{text}</span>
    </div>
  );
}
