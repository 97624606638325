import { useEffect } from 'react';
import { ScrollToTop } from '@crazyegginc/hatch';

import { useSite, useSelectedSite, useSelection } from '/src/hooks';

import { VisitorPanel } from '/src/features/visitor-panel/components/VisitorPanel';
import { SitePickerBar } from '/src/components/site-picker/index';
import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';
import { NoRecordingsWall } from './paywalls/NoRecordingsWall';
import { EnableRecordingsWall } from './paywalls/EnableRecordingsWall';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { BULK_ACTIONABLE_ITEMS } from '/src/features/_global/constants';

import { useFilter } from '../../recordings-filter-context';
import { Filters } from '../recordings-filter/Filters';
import { Playlists } from '../recordings-filter/Playlists';
import { BulkAction } from '/src/components/bulk-action/BulkAction';

import { RecordingsGroups } from './RecordingsGroups';
import { RecordingsList } from './RecordingsList';
import { RecordingsDashboardContentHeader } from './RecordingsDashboardContentHeader';

export function RecordingsDashboardContent() {
  const { selectedSite, sites, loadingSites } = useSite({ sitesQuery: RECORDINGS_SITES_QUERY });
  const filtersHook = useFilter();
  const { clearSelection } = useSelection();
  const { initialLoadComplete, queryParams } = filtersHook;

  useEffect(() => {
    // clear selection if filters conditions or selectedSite change
    clearSelection();
  }, [queryParams.filters, queryParams.groupBy, selectedSite, clearSelection]);

  if (loadingSites)
    return <SitePickerBar loading={loadingSites} sites={sites} selectedSite={selectedSite} hidePageFeedback={true} />;

  const selectedSiteRecordingsCount =
    selectedSite?.id && sites.find((site) => site.id === selectedSite.id)?.recordingsCount;

  if (sites.length === 0) {
    return (
      <div className="mt-5 w-full items-center justify-center">
        <EnableRecordingsWall />
      </div>
    );
  }

  if (selectedSiteRecordingsCount === 0) {
    return (
      <>
        <RecordingsDashboardContentHeader />
        <div className="mt-5 w-full items-center justify-center">
          <NoRecordingsWall />
        </div>
      </>
    );
  }

  return (
    <>
      <VisitorPanel />
      <RecordingsDashboardContentHeader sites={sites} />
      <div className="flex min-w-[960px] flex-col p-10">
        <Playlists />
        <Filters />
        {initialLoadComplete && <Recordings filtersHook={filtersHook} />}
        <ScrollToTop />
      </div>
      <BulkAction feature={BULK_ACTIONABLE_ITEMS.RECORDINGS} queryParams={queryParams} />
    </>
  );
}

function Recordings({ filtersHook }) {
  const { selectedSite } = useSelectedSite();
  const { queryParams } = filtersHook;

  if (!selectedSite) return null;

  if (queryParams.groupBy === GROUP_BY_TYPES.LIST_VIEW) {
    return <RecordingsList filtersHook={filtersHook} site={selectedSite} />;
  } else {
    return <RecordingsGroups filtersHook={filtersHook} site={selectedSite} />;
  }
}
