import { useState, useEffect, useRef, useCallback, createRef, forwardRef } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { IconButton, Button, InfoBox, TextArea, Tooltip } from '@crazyegginc/hatch';

import { Input } from '../../../common/basic-ui';

import { DEFAULT_OPTION } from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';

import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as AddIcon } from '@crazyegginc/hatch/dist/images/icon-square-add.svg';
import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-move-dots.svg';

export const maxInputBeforeTextArea = 5;

function getAreaError(errors) {
  if (!Array.isArray(errors)) return null;

  const index = errors?.findIndex((x) => Boolean(x)) ?? -1;
  if (index !== -1) {
    return `Choice ${index + 1}: ${errors[index]}`;
  }
  return null;
}

export function MultiChoices({ index }) {
  const { readonly } = useEditorContext();
  const [showTextArea, setShowTextArea] = useState(false);
  const { values, errors, handleBlur, setFieldValue } = useFormikContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const [lineCountValue, setLineCountValue] = useState();
  const textAreaRef = useRef();
  const lineNumbersRef = useRef();

  // needs to be kept in sync with values.questions[index].options, used for drag-and-drop (needs the id)
  const [options, setOptions] = useState(values.questions[index].options.map((o) => ({ value: o, id: nanoid() })));

  const [inputRefs, setInputRefs] = useState([]);
  const [focusNext, setFocusNext] = useState(null);

  const optionsLength = values.questions[index].options.length;

  const textAreaValue = values.questions[index].options.join('\n');

  useEffect(() => {
    if (!showTextArea && optionsLength > maxInputBeforeTextArea) {
      setShowTextArea(true);
    }
  }, [optionsLength, showTextArea, setShowTextArea]);

  const updateLineCount = useCallback(() => {
    const areaValue = textAreaValue;
    const lineCount = areaValue.split('\n').length;
    const current = lineCountValue?.split('\n').length ?? 0;
    const outarr = new Array();
    if (current != lineCount) {
      for (let x = 0; x < lineCount; x++) {
        outarr[x] = x + 1 + '.';
      }
      setLineCountValue(outarr.join('\n'));
    }
  }, [lineCountValue, textAreaValue]);

  useEffect(() => {
    if (showTextArea && textAreaValue) {
      updateLineCount();
    }
  }, [showTextArea, textAreaValue, updateLineCount]);

  useEffect(() => {
    setInputRefs((prev) =>
      Array(optionsLength)
        .fill()
        .map((_, i) => prev[i] || createRef()),
    );
  }, [optionsLength]);

  useEffect(() => {
    if (focusNext) {
      setTimeout(() => {
        inputRefs[focusNext]?.current?.focus();
        setFocusNext(null);
      }, 1);
    }
  }, [focusNext, inputRefs]);

  const addOption = async (push) => {
    await push(`${DEFAULT_OPTION} ${optionsLength + 1}`);
    setOptions((x) => [...x, { value: `${DEFAULT_OPTION} ${optionsLength + 1}`, id: nanoid() }]);
    setFocusNext(optionsLength);
  };

  return (
    <div>
      <div className="text-body-1 mb-1">Choices</div>
      <div className="space-y-2.5">
        {showTextArea ? (
          <>
            <div className="relative">
              <Tooltip
                hover={false}
                show={showTooltip}
                onDismiss={() => setShowTooltip(false)}
                tooltipContent={
                  <div className="max-w-xs">
                    Please list each option on a separate line without leaving any blank lines. You can copy and paste
                    longer lists directly here.
                  </div>
                }
                skiddingPercent={35}
              >
                <TextArea
                  ref={textAreaRef}
                  aria-label="Choices"
                  rows={5}
                  id={`questions[${index}].options`}
                  name={`questions[${index}].options`}
                  value={textAreaValue}
                  error={getAreaError(errors.questions?.[index]?.options)}
                  onChange={(e) => {
                    setFieldValue(`questions[${index}].options`, e.target.value.split('\n'));
                  }}
                  onBlur={handleBlur}
                  className="!bg-transparent pl-12 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded"
                  wrap="off"
                  onScroll={() => {
                    lineNumbersRef.current.scrollTop = textAreaRef.current.scrollTop;
                  }}
                  disabled={readonly}
                />
              </Tooltip>
              <TextArea
                ref={lineNumbersRef}
                id="lineNumbers"
                rows={5}
                value={lineCountValue}
                className={classNames(
                  'absolute left-px top-px !w-10 !rounded-r-none !border-transparent !border-r-mystic-500 !bg-off-white-500 !px-0.5',
                  '!text-body-4 overflow-y-hidden text-right font-mono',
                )}
                wrap="off"
                style={{
                  height: `calc((5 * 14px * 1.5) + 20px)`,
                  lineHeight: `calc(14px * 1.5)`,
                }}
                readOnly
              />
            </div>
            {typeof errors.questions?.[index]?.options === 'string' ? (
              <div className="text-error">{errors.questions[index].options}</div>
            ) : null}
          </>
        ) : (
          <FieldArray name={`questions[${index}].options`}>
            {(actions) => (
              <>
                {options.map((choice, i) => (
                  <Choice
                    actions={actions}
                    questionIndex={index}
                    choiceIndex={i}
                    key={`choice-${choice.id}`}
                    addOption={addOption}
                    setFocusNext={setFocusNext}
                    setShowTextArea={setShowTextArea}
                    setShowTooltip={setShowTooltip}
                    setOptions={setOptions}
                    ref={inputRefs[i]}
                  />
                ))}

                {typeof errors.questions?.[index]?.options === 'string' ? (
                  <div className="text-error">{errors.questions[index].options}</div>
                ) : null}
                {!readonly && (
                  <div className="mr-9 flex items-start justify-between space-x-5">
                    <div className="flex flex-shrink-0 items-center space-x-2.5">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          addOption(actions.push);
                          if (optionsLength === maxInputBeforeTextArea) {
                            setShowTooltip(true);
                          }
                        }}
                      >
                        <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                        Add Choice
                      </Button>
                      <Button
                        variant="ghost-primary"
                        onClick={() => {
                          setShowTextArea(true);
                          setShowTooltip(true);
                        }}
                      >
                        <AddIcon className="mr-2 h-4 w-4 fill-current" />
                        Import multiple choices
                      </Button>
                    </div>
                    <div className="max-w-[340px]">
                      <InfoBox
                        body={
                          <>
                            Add as many choices as you want. If you have more than 5, we&apos;ll automatically turn it
                            into a dropdown menu.
                          </>
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </FieldArray>
        )}
      </div>
    </div>
  );
}

const Choice = forwardRef(
  (
    { questionIndex, choiceIndex, actions, addOption, setFocusNext, setShowTextArea, setShowTooltip, setOptions },
    ref,
  ) => {
    const { readonly } = useEditorContext();

    const { values, errors, handleChange, handleBlur, setFieldValue } = useFormikContext();
    const optionsLength = values.questions[questionIndex].options.length;

    const canReorder = !readonly;
    const choiceRef = useRef(null);
    const dragRef = useRef(null);

    const canDeleteOption = !readonly && optionsLength > 2;

    const [, drop] = useDrop({
      accept: 'choice',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      canDrop: () => canReorder,
      hover(item, monitor) {
        if (!choiceRef.current || !monitor.canDrop()) {
          return;
        }
        const dragIndex = item.choiceIndex;
        const hoverIndex = choiceIndex;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = choiceRef.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        actions.move(dragIndex, hoverIndex);
        setOptions((x) => {
          const y = [...x];
          [y[dragIndex], y[hoverIndex]] = [y[hoverIndex], y[dragIndex]];
          return y;
        });

        item.choiceIndex = hoverIndex;
      },
      drop: () => ref.current.focus(),
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'choice',
      item: () => {
        return { id: values.questions[questionIndex].id, choiceIndex };
      },
      canDrag: () => canReorder,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    preview(drop(choiceRef));
    drag(dragRef);

    return (
      <div
        className={classNames('group relative -ml-4 flex items-start', { 'opacity-0': isDragging })}
        data-testid={`choice row ${choiceIndex}`}
        ref={choiceRef}
      >
        <div
          className={classNames('invisible flex h-10 shrink-0 items-center group-hover:visible', {
            'cursor-[grab] text-cadet-blue-500': canReorder,
            'cursor-not-allowed text-cadet-blue-500/50': !canReorder,
          })}
          ref={dragRef}
        >
          <MoveIcon className="h-4 w-4 fill-current" />
        </div>
        <Input
          ref={ref}
          aria-label={`choice ${choiceIndex}`}
          id={`questions[${questionIndex}].options[${choiceIndex}]`}
          name={`questions[${questionIndex}].options[${choiceIndex}]`}
          value={values.questions[questionIndex].options[choiceIndex]}
          error={errors.questions?.[questionIndex]?.options?.[choiceIndex]}
          onChange={(e) => {
            handleChange(e);
            setOptions((x) => {
              const y = [...x];
              y[choiceIndex].value = e.target.value;
              return y;
            });
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              if (choiceIndex === optionsLength - 1) {
                await addOption(actions.push);
              } else {
                setFocusNext(choiceIndex + 1);
              }
            } else if (['Backspace', 'Clear', 'Delete'].includes(e.key)) {
              if (canDeleteOption && !values.questions[questionIndex].options[choiceIndex]) {
                setTimeout(async () => {
                  await actions.remove(choiceIndex);
                  setOptions((x) => {
                    const y = [...x];
                    y.splice(choiceIndex, 1);
                    return y;
                  });
                  setFocusNext(choiceIndex - 1);
                }, 1);
              }
            }
          }}
          onBlur={handleBlur}
          onFocus={(e) => {
            if (e.target.value === `${DEFAULT_OPTION} ${choiceIndex + 1}`) {
              e.target.select();
            }
          }}
          onPaste={(e) => {
            const paste = (e.clipboardData || window.clipboardData).getData('text');
            if (/[\r\n]+/.test(paste)) {
              const valueBefore = e.target.value;
              const selectionStart = e.target.selectionStart;
              const selectionEnd = e.target.selectionEnd;

              const valueWithPaste = [valueBefore.slice(0, selectionStart), paste, valueBefore.slice(selectionEnd)];

              const completeField = [
                ...values.questions[questionIndex].options.slice(0, choiceIndex),
                ...valueWithPaste,
                ...values.questions[questionIndex].options.slice(choiceIndex + 1),
              ]
                .filter(Boolean)
                .join('\n')
                .split('\n');

              if (completeField[0] === `${DEFAULT_OPTION} 1`) {
                completeField.splice(0, 1);
              }
              if (completeField[completeField.length - 1] === `${DEFAULT_OPTION} 2`) {
                completeField.splice(-1);
              }

              setFieldValue(`questions[${questionIndex}].options`, completeField);
              setShowTextArea(true);
              setShowTooltip(true);
            }
          }}
          disabled={readonly}
        />
        <div
          className={classNames('absolute left-4 top-0 h-10 w-1.25 rounded-l', {
            'bg-mystic-500/60': !errors.questions?.[questionIndex]?.options?.[choiceIndex],
            'bg-carnation-500': errors.questions?.[questionIndex]?.options?.[choiceIndex],
          })}
        />
        <div className="ml-4 flex h-10 w-5 flex-shrink-0 items-center">
          {canDeleteOption && (
            <IconButton
              icon={<CrossIcon className="h-3 w-3 fill-current" />}
              className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
              onClick={() => {
                actions.remove(choiceIndex);
                setOptions((x) => {
                  const y = [...x];
                  y.splice(choiceIndex, 1);
                  return y;
                });
              }}
              label="delete choice"
            />
          )}
        </div>
      </div>
    );
  },
);
