import { useState, useCallback, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  Input,
  SkeletonLine,
  Indicator,
  Tooltip,
  Popover,
  StyledPopoverPanel,
  PopoverItem,
  Divider,
  Spinner,
} from '@crazyegginc/hatch';

import { useOutsideClickNotify, useAuthContext, useModal, usePermissions } from '/src/hooks';
import { AddSnapshotNewSiteModal } from '/src/features/snapshots/components/dashboard/modals/AddSnapshotNewSiteModal';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';

import { ALL, FEATURES } from '/src/features/_global/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-filled.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as FeedbackIcon } from '@crazyegginc/hatch/dist/images/icon-feedback-outline.svg';

const SITE_FILTER_THRESHOLD = 8;

export function SitePicker({
  sites = [],
  selectedSite = null,
  selectSite = null,
  loading = false,
  onClose = null,
  enableAllSites = false,
  showEnableOtherSites = false,
  addSnapshotOption = false,
  addSiteOption = false,
}) {
  const modal = useModal();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);

  const [filterValue, setFilterValue] = useState('');

  const dropdownRef = useRef(null);

  useOutsideClickNotify(
    dropdownRef,
    useCallback(() => {
      setFilterValue('');
      onClose?.();
      setOpened(false);
    }, [onClose, setFilterValue]),
    opened,
  );

  const filteredSites = useMemo(
    () => sites.filter((site) => filterValue === '' || site.name.indexOf(filterValue) >= 0),
    [sites, filterValue],
  );

  const isSearching = filterValue.trim() !== '';

  const groupedSites = useMemo(() => {
    if (sites.length === 0) return { active: [], inactive: [] };

    return {
      active: sites.filter((site) => site.active),
      inactive: sites.filter((site) => !site.active),
    };
  }, [sites]);

  function togglePicker(e) {
    e.stopPropagation();
    setOpened(!opened);
  }

  function chooseSite(site) {
    setFilterValue('');
    selectSite(site, true);
    setOpened(false);
  }

  if (loading || !selectedSite) {
    return <SkeletonLine background="#8249c8" width="30%" />;
  }

  function keyUpHandler(e) {
    const currentSites = document.querySelectorAll('.site_search_result');
    for (const site of currentSites) {
      const currentSearch = e.target.value;
      const replacement = `<b>${currentSearch}</b>`;
      const newHtml = site.textContent.replace(currentSearch, replacement);
      site.innerHTML = newHtml;
    }
  }

  function renderSiteName() {
    if (selectedSite === ALL) return 'All sites';
    return selectedSite?.name ?? '';
  }

  const pickerClasses = classNames(
    'relative flex m-0 items-center text-header-2 select-none',
    'text-white focus-visible:outline-white',
  );

  const itemClasses =
    'h-7 flex-shrink-0 rounded-sm text-md text-lynch-500 font-bold text-left px-1.5 w-full truncate hover:text-black hover:bg-solitude-500 focus-visible:outline-black';

  const buttonClasses =
    'flex-shrink-0 rounded my-0.5 text-sm text-dodger-blue-500 text-left px-2.5 py-2 w-full truncate hover:text-black hover:bg-solitude-500 focus-visible:outline-black';

  const hasAllSites = enableAllSites && sites.length > 1;

  return (
    <div className="relative" ref={dropdownRef}>
      {sites.length <= 1 && !showEnableOtherSites && !addSnapshotOption && !addSiteOption ? (
        <h2 className={pickerClasses}>{renderSiteName()}</h2>
      ) : (
        <div className="relative">
          <button className={pickerClasses} onClick={togglePicker}>
            {renderSiteName()}
            <Indicator type="dropdown" className="ml-3.5 translate-y-px" />
          </button>
          {opened && (
            <div
              data-testid="sitepicker-dropdown"
              className="absolute top-full z-20 flex max-h-96 min-w-[231px] max-w-[440px] flex-col overflow-y-auto overflow-x-hidden rounded-md border border-mystic-500 bg-white pb-0.5 pl-1 pr-1 pt-1 shadow-xl"
            >
              {sites.length > SITE_FILTER_THRESHOLD && (
                <div className="my-0.5 px-0.5">
                  <Input
                    size="sm"
                    type="text"
                    placeholder="Search for site"
                    className="!rounded-sm !border-wedgewood-500 !px-2.5"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value.trim())}
                    onKeyUp={keyUpHandler}
                    autoFocus // eslint-disable-line
                  />
                </div>
              )}

              <div className="divide-y divide-mystic-500">
                {!isSearching ? (
                  <div className="space-y-1.5 !py-0">
                    <div className={classNames(`pl-1 pr-[3px] pt-2`, { 'pb-2.5': groupedSites.inactive.length === 0 })}>
                      {groupedSites.inactive.length !== 0 ? (
                        <h3 className="mb-0.5 ml-1.5 text-xs font-semibold text-lynch-500/70">Active sites</h3>
                      ) : null}
                      {groupedSites.active.length === 0 ? (
                        <span className="text-info-body pl-1.5">No active sites</span>
                      ) : (
                        <>
                          {groupedSites.active.map((site) => (
                            <Site
                              site={site}
                              onClick={() => chooseSite(site)}
                              key={`Site-${site.id}`}
                              className={`${itemClasses} !text-black-pearl-500`}
                            />
                          ))}
                        </>
                      )}
                    </div>
                    {groupedSites.inactive.length !== 0 ? (
                      <div className="bg-white-lilac-500 pb-1 pl-1 pr-[3px] pt-2">
                        <div className="mb-0.5 flex w-full items-center space-x-2">
                          <h3 className="ml-1.5 text-xs font-semibold text-lynch-500/70">Inactive sites</h3>
                          <Tooltip
                            tooltipContent={
                              <div className="w-[140px] text-left leading-none">
                                Inactive sites have not received any data over the last 28 days.
                              </div>
                            }
                            placement="right"
                          >
                            <InfoIcon
                              className="h-3 w-3 fill-current text-lynch-500"
                              aria-label="hover for more info"
                            />
                          </Tooltip>
                        </div>
                        {groupedSites.inactive.map((site) => (
                          <Site
                            site={site}
                            onClick={() => chooseSite(site)}
                            key={`Site-${site.id}`}
                            className={`${itemClasses} hover:!bg-mystic-500`}
                          />
                        ))}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="px-1">
                    {filteredSites.map((site) => (
                      <Site
                        site={site}
                        onClick={() => chooseSite(site)}
                        key={`Site-${site.id}`}
                        className={itemClasses}
                      />
                    ))}

                    {filteredSites.length === 0 && sites.length > 0 && (
                      <div className="text-info-body mb-1.5 mt-2 px-1.5 leading-none">No sites match.</div>
                    )}
                  </div>
                )}
                {hasAllSites && (
                  <div>
                    <button onClick={() => chooseSite(ALL)} className={buttonClasses}>
                      View all sites
                    </button>
                  </div>
                )}
                {showEnableOtherSites && (
                  <div>
                    <button
                      className={buttonClasses}
                      onClick={() => {
                        navigate({
                          pathname: `/recordings/settings/sites`,
                        });
                      }}
                    >
                      Enable other sites
                    </button>
                  </div>
                )}

                {addSnapshotOption && (
                  <div>
                    <button
                      className={`${buttonClasses} !w-[230px]`}
                      onClick={() => {
                        setOpened(false);
                        modal.show(<AddSnapshotNewSiteModal />);
                      }}
                    >
                      Add Snapshots for another site
                    </button>
                  </div>
                )}

                {addSiteOption && (
                  <div>
                    <Link to="/options/sites" className={`${buttonClasses} flex items-center`}>
                      Manage sites
                    </Link>
                  </div>
                )}
              </div>
              {/* <div className="min-h-[10px]" /> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function WizardSitePicker({
  sites = [],
  selectedSite = null,
  selectSite = null,
  loading = false,
  disabled = false,
}) {
  const modal = useModal();
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              'relative h-[50px] w-full cursor-default rounded border border-mystic-500 pl-3 pr-9 text-left',
              'text-body-2 leading-none',
              'focus:border-dodger-blue-500 focus:outline-none',
              {
                '!border-dodger-blue-500': open,
                'cursor-not-allowed bg-white-lilac-500 text-lynch-500': disabled,
                'bg-white text-black-pearl-500': !disabled,
              },
            )}
            disabled={disabled}
            aria-label="site selector"
          >
            {loading ? (
              <div className="flex items-center space-x-2.5">
                <Spinner />
                <span className="text-lynch-500">loading sites...</span>
              </div>
            ) : (
              <span>{selectedSite?.name ?? 'Select your site'}</span>
            )}
            {!disabled ? <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" /> : null}
          </Popover.Button>
          <StyledPopoverPanel align="right" className="!z-[90] !max-h-[400px] !w-full !overflow-y-auto">
            {({ close }) => (
              <>
                {sites.length ? (
                  sites.map((site) => (
                    <PopoverItem
                      key={site.id}
                      onClick={() => {
                        selectSite(site);
                        close();
                      }}
                    >
                      {site.name}
                    </PopoverItem>
                  ))
                ) : (
                  <div className="text-body-5 relative inline-block w-full max-w-full rounded px-2.5 py-1.25 text-left leading-[1.15]">
                    You don’t currently have any sites.
                  </div>
                )}
                {canEditSites ? (
                  <>
                    <Divider className="!-mx-4 !my-2 !w-auto" />
                    <PopoverItem
                      className="!font-semibold !text-dodger-blue-500"
                      onClick={() => {
                        modal.show(
                          <AddSiteModal
                            onExtraSteps={(newSite) => {
                              selectSite(newSite);
                              close();
                            }}
                          />,
                        );
                      }}
                    >
                      Add a new site
                    </PopoverItem>
                  </>
                ) : null}
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}

export function SitePickerBar({ pageDisplayName, hidePageFeedback, ...props }) {
  const { isSharing } = useAuthContext();

  return (
    <div className="flex h-[50px] w-full items-center justify-between px-11 gradient-purple" data-testid="sitepicker">
      <SitePicker {...props} />
      {props.sites.find((x) => x.id === props.selectedSite?.id)?.recordingsEnabled === false ? (
        <NotRecordingSiteWarning />
      ) : (
        <>{!hidePageFeedback && !isSharing ? <PageFeedback pageDisplayName={pageDisplayName} /> : null}</>
      )}
    </div>
  );
}

function NotRecordingSiteWarning() {
  return (
    <div className="flex items-center">
      <ErrorIcon className="mr-2.5 h-4 w-4 fill-current text-white" />
      <a href="/recordings/settings/sites" className="text-link text-white">
        Not recording this site
      </a>
    </div>
  );
}

function PageFeedback({ pageDisplayName }) {
  function handleClick() {
    window.CE2.SURVEY_VARIABLES = {
      pageDisplayName: pageDisplayName ? `the ${pageDisplayName}` : 'this page',
    };
    const surveyId = '9a2d7e2d-4699-4d64-ab57-53c8dd51c83b';
    window.CE2?.Survey?.stop?.(surveyId);
    window.CE2?.showSurvey?.(surveyId);
  }

  return (
    <button
      type="button"
      className={classNames(
        'flex h-[35px] items-center rounded px-[15px] text-sm text-white duration-75 hover:transition-all',
        'bg-transparent hover:bg-white/20 focus-visible:outline-white',
      )}
      onClick={handleClick}
    >
      <FeedbackIcon className="mr-2.5 h-4 w-4 fill-current" />
      Feedback about this page?
    </button>
  );
}

function Site({ site, onClick, className }) {
  return (
    <button key={`Site-${site.id}`} onClick={onClick} className={`${className} site_search_result`} title={site.name}>
      {site.name}
    </button>
  );
}
