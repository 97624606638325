import { useRef, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { CloseButton, Button, Tooltip, DateRangePicker, InfoBox } from '@crazyegginc/hatch';
import { subYears, fromUnixTime, getUnixTime } from 'date-fns';

import { FilterSelect, FilterMultiSelect, FilterTagsSelect, FilterGoalsSelect, FilterCTASelect } from './FilterSelect';
import { FilterCheckBoxes } from './FilterCheckBoxes';
import { FilterText } from './FilterText';
import { SupportLinks } from '/src/support';

export function FilterCondition({ condition, filtersHook }) {
  const { dismissDuplicateTooltip, changeComparison, changeValue, chainCondition, removeCondition, removeCriteria } =
    filtersHook;

  if (condition.criteria === 'date_range') return null; //sc20550

  return (
    <div className="group relative flex w-full border-b border-dashed border-mystic-500 py-1.25 pr-5">
      <FilterCriteria
        name={condition.definition.name}
        nameExtra={condition.definition.name_extra}
        tooltip={condition.definition.tooltip}
        showDuplicateTooltip={
          condition.showDuplicateTooltip &&
          (!condition.definition.multiple_values_type || condition.definition.multiple_values_type === 'native') &&
          !['country', 'tags', 'goal', 'cta'].includes(condition.definition.criteria)
        }
        onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
      />

      {condition.definition.allowed_comparisons && (
        <FilterSelect
          options={condition.definition.allowed_comparisons
            .filter((x) => condition.criteria !== 'landing_page' || (x !== 'is' && x !== 'is not')) //sc-22749
            .map((x) => ({ value: x }))}
          value={condition.comparison}
          containerStyle={{
            width: condition.criteria === 'goal' ? '105px' : undefined,
            marginRight: '10px',
            flexShrink: 0,
          }}
          label="comparison"
          onChange={(value) => changeComparison(condition.criteria, value)}
        />
      )}

      <div
        className={classNames('flex', {
          'flex-nowrap': condition.criteria === 'country',
          'flex-wrap': condition.criteria !== 'country',
        })}
      >
        {condition.values.map((fieldGroup, groupIndex) => {
          return (
            <Fragment key={groupIndex}>
              {fieldGroup.map((field, fieldIndex) => {
                const fieldDefinition = condition.definition.fields[fieldIndex];
                const key = groupIndex + '-' + fieldIndex;
                const target = { criteria: condition.criteria, fieldGroup: groupIndex, fieldIndex: fieldIndex };

                const removable =
                  condition.values.length > 1 &&
                  condition.definition.multiple_values_type &&
                  condition.definition.multiple_values_type !== 'native';

                if (fieldDefinition.show_only_for_specific_comparisons) {
                  if (!fieldDefinition.show_only_for_specific_comparisons_list.includes(condition.comparison))
                    return null;
                }

                switch (fieldDefinition.display_type) {
                  case 'checkboxes':
                    return (
                      <FilterCheckBoxes
                        key={key}
                        options={fieldDefinition.allowed_options}
                        value={field.value}
                        error={field.error}
                        onChange={(value) => changeValue(target, value)}
                      />
                    );
                  case 'multi_select':
                    return (
                      <FilterMultiSelect
                        key={key}
                        options={fieldDefinition.allowed_options}
                        placeholder={fieldDefinition.params?.placeholder}
                        value={field.value ? field.value.map((i) => ({ value: i })) : null}
                        onChange={(e) => changeValue(target, e?.map((i) => i.value) ?? '')}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                        tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                        onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                      />
                    );
                  case 'dropdown':
                    return (
                      <FilterSelect
                        key={key}
                        options={fieldDefinition.allowed_options}
                        placeholder={fieldDefinition.params?.placeholder}
                        value={field.value}
                        onChange={(value) => changeValue(target, value)}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                      />
                    );
                  case 'text':
                  case 'url':
                    return (
                      <FilterText
                        key={key}
                        value={field.value}
                        width={(fieldDefinition.params?.width ?? removable) ? '235px' : '200px'}
                        placeholder={fieldDefinition.params?.placeholder}
                        chainingText={fieldDefinition.params?.label}
                        onChange={(e) => changeValue(target, e.target.value)}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                        removable={removable}
                        onRemove={() => removeCondition(condition.criteria, groupIndex)}
                      />
                    );
                  case 'number':
                    return (
                      <FilterText
                        key={key}
                        value={field.value}
                        inputmode="numeric"
                        width={(fieldDefinition.params?.width ?? removable) ? '95px' : '60px'}
                        placeholder={fieldDefinition.params?.placeholder}
                        chainingText={fieldDefinition.params?.label}
                        onChange={(e) => changeValue(target, e.target.value)}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                        removable={removable}
                        onRemove={() => removeCondition(condition.criteria, groupIndex)}
                      />
                    );
                  case 'date_range':
                    return (
                      <div key={key} style={{ margin: '5px 0' }}>
                        <DateRangePicker
                          lowerBoundary={subYears(new Date(), 5)}
                          upperBoundary={new Date()}
                          startDate={field.value?.start_date && fromUnixTime(field.value.start_date)}
                          endDate={field.value?.end_date && fromUnixTime(field.value.end_date)}
                          special={field.value?.special}
                          setDateRange={(value) =>
                            changeValue(target, {
                              start_date: getUnixTime(value.start_date),
                              end_date: getUnixTime(value.end_date),
                            })
                          }
                          setCommonDateRange={(value) => changeValue(target, value)}
                          showCommonRanges={true}
                        />
                      </div>
                    );
                  case 'tags': {
                    const includeTags = field.value?.filter((tag) => !tag.match(/^NOT:/)) ?? [];
                    const excludeTags = field.value?.filter((tag) => tag.match(/^NOT:/)) ?? [];
                    return (
                      <Fragment key={key}>
                        <FilterTagsSelect
                          placeholder={fieldDefinition.params?.placeholder}
                          value={includeTags}
                          //onChange={(value) => changeValue(target, value)}
                          // hack for ch13385
                          onChange={(_, { action, removedValue, option }) => {
                            if (action === 'select-option' && option) {
                              changeValue(target, [...field.value, option.value]);
                            } else if (['remove-value', 'pop-value'].includes(action) && removedValue) {
                              changeValue(
                                target,
                                field.value.filter((tag) => tag !== removedValue),
                              );
                            }
                          }}
                          error={field.error}
                          label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                          tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                          onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                          containerStyle={{ minWidth: '230px' }}
                        />
                        {
                          // hack for ch13385
                          ['all of', 'any of'].includes(condition.comparison) && (
                            <div className="flex items-center">
                              <ChainingText>but none of (optional):</ChainingText>
                              <FilterTagsSelect
                                placeholder={fieldDefinition.params?.placeholder}
                                value={excludeTags.map((tag) => tag.replace('NOT:', ''))}
                                onChange={(_, { action, removedValue, option }) => {
                                  if (action === 'select-option' && option) {
                                    changeValue(target, [...field.value, `NOT:${option.value}`]);
                                  } else if (['remove-value', 'pop-value'].includes(action) && removedValue) {
                                    changeValue(
                                      target,
                                      field.value.filter((tag) => tag !== `NOT:${removedValue}`),
                                    );
                                  }
                                }}
                                label="exclude tags"
                                containerStyle={{ minWidth: '230px' }}
                              />
                            </div>
                          )
                        }
                      </Fragment>
                    );
                  }
                  case 'goals': {
                    return (
                      <FilterGoalsSelect
                        key={key}
                        value={field.value}
                        placeholder="Select a Goal"
                        tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                        onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                        onChange={(_, { action, removedValue, option }) => {
                          if (action === 'select-option' && option) {
                            changeValue(target, [...field.value, parseInt(option.value)]);
                          } else if (['remove-value', 'pop-value'].includes(action) && removedValue) {
                            changeValue(
                              target,
                              field.value.filter((goalId) => goalId !== parseInt(removedValue)),
                            );
                          }
                        }}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                      />
                    );
                  }
                  case 'ctas': {
                    return (
                      <FilterCTASelect
                        key={key}
                        value={field.value}
                        placeholder="Select a CTA"
                        tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                        onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                        onChange={(_, { action, removedValue, option }) => {
                          if (action === 'select-option' && option) {
                            changeValue(target, [...field.value, option.value]);
                          } else if (['remove-value', 'pop-value'].includes(action) && removedValue) {
                            changeValue(
                              target,
                              field.value.filter((id) => id !== removedValue),
                            );
                          }
                        }}
                        error={field.error}
                        label={fieldDefinition.name === 'default' ? condition.definition.name : fieldDefinition.name}
                      />
                    );
                  }
                }
              })}
              {/* Single chaining button for the last fieldGroup */}
              {groupIndex === condition.values.length - 1 &&
                condition.definition.multiple_values_type &&
                condition.definition.multiple_values_type !== 'native' &&
                !condition.definition.multiple_values_options && (
                  <FilterChainingButton
                    onClick={() => chainCondition(condition.criteria)}
                    onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                    buttonText={condition.definition.multiple_values_type}
                    showDuplicateTooltip={condition.showDuplicateTooltip}
                    tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                  />
                )}
              {/* Multi chaining button for the last fieldGroup */}
              {groupIndex === condition.values.length - 1 &&
                (condition.multiple_values ? (
                  /* Chaining type already chosen, can't be changed, so show only that */
                  <FilterChainingButton
                    onClick={() => chainCondition(condition.criteria)}
                    onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                    buttonText={condition.multiple_values}
                    tooltip={condition.showDuplicateTooltip && condition.definition.tooltip}
                  />
                ) : (
                  condition.definition.multiple_values_options?.map((opt, index) => (
                    /* Show all chaining options */
                    <FilterChainingButton
                      key={opt}
                      onClick={() => chainCondition(condition.criteria, opt)}
                      onTooltipDismiss={() => dismissDuplicateTooltip(condition.criteria)}
                      buttonText={opt}
                      tooltip={condition.showDuplicateTooltip && index === 0 && condition.definition.tooltip}
                      multi={true}
                    />
                  ))
                ))}
              {/* Chaining text for fieldGroup that is in the middle */}
              {groupIndex !== condition.values.length - 1 &&
                (condition.multiple_values ? (
                  <ChainingText>{condition.multiple_values}</ChainingText>
                ) : (
                  <ChainingText>{condition.definition.multiple_values_type}</ChainingText>
                ))}
            </Fragment>
          );
        })}
      </div>

      <div className="flex flex-1 items-start justify-end">
        <SpecialCases criteria={condition.criteria} />
      </div>
      <CloseButton
        onClick={() => removeCriteria(condition.criteria)}
        label="delete condition"
        iconClass="!text-dodger-blue-300"
        buttonClass="!right-0 !top-[22px]"
      />
      <div className="text-body-4 absolute bottom-0 left-1.5 translate-y-1/2 bg-white px-1 group-last:hidden">and</div>
    </div>
  );
}

function SpecialCases({ criteria }) {
  switch (criteria) {
    case 'visitor':
      return (
        <div className="w-[260px]">
          <InfoBox
            header="Want to search for specific visitors?"
            body={
              <>
                Just add our{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link text-2xs"
                  href={SupportLinks.sessions.visitorIdentifier}
                >
                  Visitor ID script
                </a>{' '}
                to your site!
              </>
            }
          />
        </div>
      );
    case 'tags':
      return (
        <div className="w-[160px]">
          <InfoBox
            body={
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link text-2xs"
                  href={SupportLinks.recordings.autoGeneratedTags}
                >
                  Learn more
                </a>{' '}
                about our auto generated tags.
              </div>
            }
          />
        </div>
      );
    case 'screen_width':
    case 'screen_height':
      return (
        <div className="w-[160px]">
          <InfoBox body={<div>The numeric value is in pixel units.</div>} />
        </div>
      );

    default:
      return null;
  }
}

function FilterChainingButton({ buttonText, onTooltipDismiss, onClick, multi, tooltip }) {
  const ref = useRef();

  useEffect(() => {
    if (tooltip) {
      ref.current.scrollIntoView({ block: 'center' });
    }
  }, [tooltip]);

  function renderButton() {
    return (
      <div ref={ref}>
        <Button className="!m-1.25 !ml-0 !px-2.5" variant="secondary" onClick={onClick}>
          {buttonText}...
        </Button>
      </div>
    );
  }

  return tooltip ? (
    <Tooltip
      hover={false}
      placement="bottom"
      arrowSkiddingPercent="20"
      skiddingPercent={multi ? '95' : '50'}
      offset="0"
      onDismiss={onTooltipDismiss}
      tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
    >
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
}

function FilterCriteria({ showDuplicateTooltip, name, nameExtra, onTooltipDismiss, tooltip }) {
  const ref = useRef();

  useEffect(() => {
    if (showDuplicateTooltip) {
      ref.current.scrollIntoView({ block: 'center' });
    }
  }, [showDuplicateTooltip]);

  function renderCriteria() {
    return (
      <div
        className="text-body-1 my-1.25 mr-2.5 flex h-[35px] w-[175px] flex-shrink-0 items-center rounded-sm bg-white-lilac-500 pl-2.5 text-xs"
        ref={ref}
        data-testid="filter_criteria"
      >
        {name}
        {nameExtra && <span className="text-2xs">&nbsp;{nameExtra}</span>}
      </div>
    );
  }

  return showDuplicateTooltip && tooltip ? (
    <Tooltip
      hover={false}
      placement="bottom"
      skiddingPercent="80"
      arrowSkiddingPercent="20"
      offset="0"
      onDismiss={onTooltipDismiss}
      tooltipContent={<TooltipContainer>{tooltip}</TooltipContainer>}
    >
      {renderCriteria()}
    </Tooltip>
  ) : (
    renderCriteria()
  );
}

export const TooltipContainer = ({ children }) => <div className="w-[130px] text-left">{children}</div>;

const ChainingText = ({ children }) => (
  <div className="text-body-4 m-1.25 mr-2.5 flex h-[35px] items-center justify-center">{children}</div>
);
