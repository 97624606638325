import { usePermissions } from './usePermissions';
import { CAPABILITY_REASONS } from '/src/features/_global/constants';

export function useNeedsUpgradeToAccessFeature(feature) {
  const permissions = usePermissions();

  const canView = permissions.can('view', feature);
  if (canView.allowed === false && canView.reason === CAPABILITY_REASONS.UPGRADE) {
    return true;
  } else if (canView.reason === CAPABILITY_REASONS.PERMISSION_UNDEFINED) {
    const canEdit = permissions.can('edit', feature);
    if (canEdit.allowed === false && canEdit.reason === CAPABILITY_REASONS.UPGRADE) {
      return true;
    }
  }
  return false;
}
