import { MetricCards } from '@crazyegginc/hatch';

import { STEP_COLORS } from '../constants';
import { round } from '/src/utils/math';

export function SummaryCards({ values, stepsData }) {
  return (
    <div className="mt-5">
      <MetricCards>
        {values.map((value, i) => (
          <MetricCards.Card
            key={`${stepsData[i].name}-${value}`}
            sequence={i + 1}
            fetching={false}
            title={stepsData[i].name}
            value={value}
            color={STEP_COLORS.slice(0 - values.length)[i].tw}
            className="!h-32 !w-auto grow"
            subtext={i === 0 ? null : `Rate${i === 1 ? '' : ` (step ${i} | step 1)`}:`}
            subvalue={
              i === 0
                ? null
                : `${round((value / values[i - 1]) * 100, 2)}%
                ${i === 1 ? '' : `| ${round((value / values[0]) * 100, 2)}%`}`
            }
          />
        ))}
      </MetricCards>
    </div>
  );
}
