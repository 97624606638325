import { useContext, useCallback, useMemo } from 'react';
import Honeybadger from '@honeybadger-io/js';
import { isE2E } from '/src/utils';

import { NotificationContext } from '/src/contexts/notifications';
import { NOTIFICATION_ACTIONS } from '/src/features/_global/constants';

const { ADD, REMOVE, REMOVE_ALL } = NOTIFICATION_ACTIONS;

const hardTimeout = isE2E() ? 1500 : null;

export function useNotifications() {
  const { notificationDispatch } = useContext(NotificationContext);

  const remove = useCallback(
    (id) => {
      notificationDispatch({
        type: REMOVE,
        payload: {
          id,
        },
      });
    },
    [notificationDispatch],
  );

  const add = useCallback(
    ({ title, content, type = 'success', timeout = null }) => {
      const id = +new Date();
      notificationDispatch({
        type: ADD,
        payload: {
          id,
          title,
          content,
          type,
        },
      });

      if (timeout) {
        setTimeout(() => {
          remove(id);
        }, hardTimeout || timeout);
      }

      return id;
    },
    [notificationDispatch, remove],
  );

  const success = useCallback(
    (params) => {
      const id = add({ ...params, type: 'success' });
      return id;
    },
    [add],
  );

  const error = useCallback(
    (params) => {
      const { context, skipHoneybadger, title, content } = params;
      if (!skipHoneybadger) {
        Honeybadger.notify(`${title ? `${title} - ` : ''}${content}`, {
          context,
          name: `${title ? `${title} - ` : ''}${content}`,
        });
      }
      const id = add({ ...params, type: 'error' });
      return id;
    },
    [add],
  );

  const info = useCallback(
    (params) => {
      const id = add({ ...params, type: 'info' });
      return id;
    },
    [add],
  );

  const removeAll = useCallback(() => {
    notificationDispatch({
      type: REMOVE_ALL,
    });
  }, [notificationDispatch]);

  return useMemo(
    () => ({ add, success, error, info, remove, removeAll }),
    [add, success, error, info, remove, removeAll],
  );
}
