import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function WarningModal({ onConfirm, title, body, button }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Modal.Title>{title}</Modal.Title>

      <div className="text-body-2">{body}</div>

      <Modal.Actions>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          {button}
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
