import { useEffect } from 'react';
import { Panel, Button } from '@crazyegginc/hatch';

import { useModal, useSearchParams, useAuthContext } from '/src/hooks';
import { CreditCardModal } from './modals/CreditCardModal';
import { CC_TYPES } from '../constants';
import { capitalizeFirstLetter } from '/src/utils/string';

import { ReactComponent as AmexIcon } from '@crazyegginc/hatch/dist/images/logo-amex.svg';
import { ReactComponent as DiscoverIcon } from '@crazyegginc/hatch/dist/images/logo-discover.svg';
import { ReactComponent as MastercardIcon } from '@crazyegginc/hatch/dist/images/logo-mastercard.svg';
import { ReactComponent as VisaIcon } from '@crazyegginc/hatch/dist/images/logo-visa.svg';
import { ReactComponent as OtherIcon } from '@crazyegginc/hatch/dist/images/illustration-creditcard-2.svg';
import { ReactComponent as ShopifyIcon } from '@crazyegginc/hatch/dist/images/logo-shopify-square.svg';

export function BillingPaymentInfo({ data, countries }) {
  const modal = useModal();
  const [searchParams] = useSearchParams();
  const openByDefault = searchParams.get('billingModal');
  const { currentAccount } = useAuthContext();

  useEffect(() => {
    if (openByDefault == 'true') {
      modal.show(<CreditCardModal data={data} countries={countries} />);
    }
  }, [data, countries, modal, openByDefault]);

  return (
    <>
      <h2 className="text-header-3 mb-2.5 flex items-center">Payment info</h2>
      <Panel className="!mb-10">
        {currentAccount.isShopifyUser ? (
          <div className="text-body-2 flex w-full items-center">
            <ShopifyIcon className="mr-5 h-10 w-10" />
            <div>
              <div>
                Your current payment method: <strong className="text-body-1">Shopify Payments</strong>
              </div>
              <div>Payment for Crazy Egg subscription will be included on your Shopify invoice.</div>
            </div>
          </div>
        ) : (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <CCImage type={data.ccType} />
              <CCNumber type={data.ccType} ccNumberSanitized={data.ccNumberSanitized} expiration={data.ccExpiration} />
            </div>
            <Button onClick={() => modal.show(<CreditCardModal data={data} countries={countries} />)}>
              {data.ccNumberSanitized ? 'Edit payment info' : 'Add a credit card'}
            </Button>
          </div>
        )}
      </Panel>
    </>
  );
}

function CCImage({ type }) {
  let Component = OtherIcon;
  switch (type) {
    case CC_TYPES.AMEX:
      Component = AmexIcon;
      break;
    case CC_TYPES.DISCOVER:
      Component = DiscoverIcon;
      break;
    case CC_TYPES.MASTERCARD:
      Component = MastercardIcon;
      break;
    case CC_TYPES.VISA:
      Component = VisaIcon;
      break;
  }

  return <Component className="mr-6 w-[60px]" aria-label="credit card" />;
}

function CCNumber({ type, ccNumberSanitized, expiration }) {
  if (!ccNumberSanitized) {
    return <div className="text-base">You do not have any payment information on file.</div>;
  }

  const last4digits = ccNumberSanitized.substring(ccNumberSanitized.length - 4);
  return (
    <div className="text-base">
      <div>{type ? `${capitalizeFirstLetter(type.toLowerCase())} ending in ${last4digits}` : ccNumberSanitized}</div>
      <div>{expiration}</div>
    </div>
  );
}
