import { Modal } from '/src/contexts/modal';
import { ReactComponent as BalloonIcon } from '@crazyegginc/hatch/dist/images/illustration-cancellation-balloon.svg';

import { SupportLinks } from '/src/support';

export function SadToSeeYouGoModal() {
  return (
    <Modal dialogClassName="!items-center !text-center !p-9 !w-[330px]" disableOutsideClick={true} dismissable={false}>
      <BalloonIcon className="h-[117px] w-[235px]" />
      <div className="text-header-3 mt-4">We&apos;re sad to see you go</div>
      <div className="text-body-2 mt-5 leading-normal">Thank you for being a valuable Crazy Egg customer.</div>
      <div className="text-body-2 mt-3 leading-normal">
        If you need any further assistance please contact{' '}
        <a href={`mailto:${SupportLinks.general.email}`} className="text-link">
          {SupportLinks.general.email}
        </a>
      </div>
    </Modal>
  );
}
