import { useEffect } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { capitalize, Divider } from '@crazyegginc/hatch';

import { usePermissions, useWizard } from '/src/hooks';
import { techRecommendationQuery } from '/src/features/installation/queries';
import { WebP } from '/src/components/WebP';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';
import { FEATURES } from '/src/features/_global/constants';

import WordPressIcon from '@crazyegginc/hatch/dist/images/logo-wordpress-square.svg';
import GoogleTagManagerIcon from '@crazyegginc/hatch/dist/images/logo-gtm-square.svg';
import ShopifyIcon from '@crazyegginc/hatch/dist/images/logo-shopify-square.svg';
import WixIcon from '@crazyegginc/hatch/dist/images/logo-wix-square.svg';
import SquarespaceIcon from '@crazyegginc/hatch/dist/images/logo-squarespace-square.svg';
import DrupalIcon from '@crazyegginc/hatch/dist/images/logo-drupal-square.svg';
import MagentoIcon from '@crazyegginc/hatch/dist/images/logo-magento-square.svg';
import JoomlaIcon from '@crazyegginc/hatch/dist/images/logo-joomla-square.svg';
import SegmentIcon from '@crazyegginc/hatch/dist/images/logo-segment-square.svg';

import HtmlPNG from '@crazyegginc/hatch/dist/images/illustration-html.png';
import HtmlWebP from '@crazyegginc/hatch/dist/images/illustration-html.webp';
import EmailPNG from '@crazyegginc/hatch/dist/images/illustration-email.png';
import EmailWebP from '@crazyegginc/hatch/dist/images/illustration-email.webp';

import CheckInstallPNG from '@crazyegginc/hatch/dist/images/illustration-check-install.png';
import CheckInstallWebP from '@crazyegginc/hatch/dist/images/illustration-check-install.webp';
import HelpPNG from '@crazyegginc/hatch/dist/images/illustration-help.png';
import HelpWebP from '@crazyegginc/hatch/dist/images/illustration-help.webp';

function useParams() {
  return new URLSearchParams(useLocation().search);
}

const allowedTechnologies = [
  'wordpress',
  'gtm',
  'shopify',
  'wix',
  'squarespace',
  'drupal',
  'magento',
  'joomla',
  'segment',
];

const imageMap = {
  wordpress: WordPressIcon,
  gtm: GoogleTagManagerIcon,
  shopify: ShopifyIcon,
  wix: WixIcon,
  squarespace: SquarespaceIcon,
  drupal: DrupalIcon,
  magento: MagentoIcon,
  joomla: JoomlaIcon,
  segment: SegmentIcon,
  zaraz: null,
};

const routeMapping = (value) => {
  if (value === 'gtm') {
    return 'google-tag-manager';
  }
  return value;
};

const humanizeTechnology = (tech) => {
  switch (tech.toLowerCase()) {
    case 'wordpress':
      return 'WordPress';
    case 'gtm':
      return 'Google Tag Manager';
    default:
      return capitalize(tech);
  }
};

const warningText = (tech) => {
  switch (tech.toLowerCase()) {
    case 'wix':
      return 'This feature is only available for sites with a connected domain.';
    case 'squarespace':
      return (
        <>
          You&#39;ll need access to <span className="text-body-1">Code Injection</span> which is a Squarespace Premium
          feature.
        </>
      );
    default:
      return null;
  }
};

function DisplayRecommendation() {
  const location = useLocation();

  const { data: recommendationData } = useQuery({
    ...techRecommendationQuery({ url: location?.state?.requestUrl ?? undefined }),
  });

  const queryParams = useParams();

  const recommend = queryParams.get('recommend') || (recommendationData?.techRecommendation?.method ?? null);

  if (!recommend) return null;
  if (!allowedTechnologies.includes(recommend)) return null;

  return (
    <>
      <div className="mb-2.5">Good news! We noticed you are using {humanizeTechnology(recommend)}.</div>

      <LinkBox
        target={`/install/${routeMapping(recommend)}`}
        image={<img src={imageMap[recommend]} alt={`${recommend} install`} className="h-[50px]" />}
        text={`Connect ${humanizeTechnology(recommend)} and Crazy Egg`}
        full
      />

      <div className="mb-2.5 mt-5">
        Don&#39;t want to use {humanizeTechnology(recommend)}? Select one of these other options:
      </div>
    </>
  );
}

export function ChooseMethodPage() {
  const { set: wizardSet } = useWizard();
  const permissions = usePermissions();
  const canViewZarazInstall = permissions.can('viewZaraz', FEATURES.INSTALLATION).allowed;

  useEffect(() => {
    if (canViewZarazInstall && !allowedTechnologies.includes('zaraz')) {
      allowedTechnologies.push('zaraz');
    }
  }, [canViewZarazInstall]);

  useEffect(() => {
    wizardSet({
      backEnabled: false,
      nextEnabled: false,
      title: 'Choose an installation method',
      currentStep: 1,
      totalSteps: 2,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col items-start">
      <WizardHeader className="!mb-5">Let’s install your Crazy Egg tracking script</WizardHeader>
      <WizardSubHeader>
        The tracking script lets Crazy Egg track your visitors. Without it, our report won&#39;t pick up any of your
        users&#39; clicks.
      </WizardSubHeader>

      <DisplayRecommendation />

      <div className="mb-8 flex w-full justify-between">
        <LinkBox
          target="/install/manually"
          image={<WebP webp={HtmlWebP} fallback={HtmlPNG} width="55px" alt="Manual install" />}
          text="I can install the tracking script myself"
        />

        <LinkBox
          target="/install/developer"
          image={<WebP webp={EmailWebP} fallback={EmailPNG} width="55px" alt="Developer install" />}
          text="I need my developer to add the script for me"
        />
      </div>

      <div className="mb-2.5">Or, you can integrate with a third-party app:</div>

      <div className="grid w-full grid-cols-4 gap-2">
        {allowedTechnologies.map((tech) => (
          <InstructionLinkBox name={tech} key={tech} />
        ))}
      </div>

      <Divider dashed className="mb-6 mt-2" />

      <div className="flex w-full justify-between">
        <LinkBox
          target="/install/check-script"
          image={<WebP webp={CheckInstallWebP} fallback={CheckInstallPNG} width="55px" alt="Check install" />}
          text="Already installed it, check my installation"
        />

        <LinkBox
          target="/install/help"
          image={<WebP webp={HelpWebP} fallback={HelpPNG} width="55px" alt="Help install" />}
          text="I&#39;m confused, I need more help"
        />
      </div>
    </div>
  );
}

function LinkBox({ target, image, text, full = false }) {
  return (
    <Link
      to={target}
      className={classNames(
        'relative flex overflow-hidden',
        'rounded border border-mystic-500',
        'transition hover:scale-[1.02] hover:shadow-md',
        {
          'h-[150px] w-[345px] flex-col items-center justify-center space-y-3.5 p-1.25': !full,
          'w-full items-center space-x-6 p-9': full,
        },
      )}
    >
      {image}
      <div className="text-body-2">{text}</div>
    </Link>
  );
}

function InstructionLinkBox({ name }) {
  const hoverText = warningText(name);
  return (
    <Link
      to={`/install/${routeMapping(name)}`}
      className={classNames(
        'group mb-4 h-[150px] w-[165px] px-4 text-center',
        'rounded border border-mystic-500',
        'transition hover:scale-[1.02] hover:shadow-md',
      )}
    >
      <div
        className={classNames('hidden h-full w-full flex-col items-center justify-center', {
          'group-hover:flex': hoverText,
        })}
      >
        <span className="text-body-5 mb-3">BEFORE YOU BEGIN</span>
        <div>{hoverText}</div>
      </div>

      <div
        className={classNames('flex h-full w-full flex-col items-center justify-center', {
          'group-hover:hidden': hoverText,
        })}
      >
        <img src={imageMap[name]} alt={`${name} install`} className="w-[50px]" />
        <span className="mt-3">I use {humanizeTechnology(name)}</span>
      </div>
    </Link>
  );
}
