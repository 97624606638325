import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Tooltip, SkeletonLine, Button, Divider, BrowserIcon, OSIcon, DeviceIcon } from '@crazyegginc/hatch';

import { useAuthContext, useModal, useVisitorId } from '/src/hooks';
import { CountryFlag } from '../../../../components/CountryFlag';
import { formatFullDateTime } from '/src/utils/date';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { LockedRecordingModal } from '/src/features/recordings/components/dashboard/modals/LockedRecordingModal';
import { TableTagList } from '/src/components/TableTagList';

import { errorQuery } from '/src/features/errors/queries';
import { SupportLinks } from '/src/support';

import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-circle-filled.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as ResizeIcon } from '@crazyegginc/hatch/dist/images/icon-resize.svg';

export const ErrorDetails = ({ errorId, site, standalone = false }) => {
  const { isSharing } = useAuthContext();
  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    ...errorQuery({
      id: errorId,
      siteId: site.id,
    }),
    enabled: Boolean(errorId),
  });

  const error = data?.error;

  if ((!isLoading && !error) || queryError) {
    return null;
  }
  if (standalone) {
    return (
      <>
        {!isLoading && (
          <Button
            component={Link}
            to={{
              pathname: `/errors/${error.fingerprintMd5}`,
              search: `site=${site.name}`,
            }}
            state={{
              reMount: true,
            }}
            className="!mb-3 !mt-10 !self-start"
          >
            Show list of occurrences of this error
          </Button>
        )}
        <div className="mb-5 flex w-full items-end justify-between">
          <div className="text-header-3">Error details</div>
          {!isLoading && !isSharing && (
            <div className="relative flex-shrink-0">
              <Tooltip
                show={!error.valid || error.deletedAt}
                tooltipContent={
                  <div className="w-40">
                    {error.deletedAt
                      ? 'The associated video was deleted.'
                      : "The visitor didn't perform any recordable actions, such as clicking, so there is no video to show."}
                  </div>
                }
              >
                <Button
                  component={Link}
                  variant="secondary"
                  disabled={!error.valid || error.deletedAt}
                  size="lg"
                  className={!error.valid || error.deletedAt ? 'pointer-events-none' : ''}
                  to={{
                    pathname: `/recordings/${error.hashedId}/player`,
                  }}
                  state={{
                    previousPath: location.pathname,
                    previousSearch: location.search,
                  }}
                >
                  <PlayIcon className="mr-2 h-5 w-5 fill-current" />
                  Play recording
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className="w-full min-w-min rounded border border-mystic-500 bg-white shadow-md"
          data-testid="error-details"
        >
          <ErrorDetailsContent error={error} fetching={isLoading} />
        </div>
      </>
    );
  }

  return (
    <div className="w-full min-w-min bg-white">
      <ErrorDetailsContent error={error} fetching={isLoading} />
      <Divider dashed />
    </div>
  );
};

const ErrorDetailsContent = ({ error, fetching }) => {
  const { isSharing } = useAuthContext();
  const modal = useModal();
  const { selectVisitor } = useVisitorId();

  useEffect(() => {
    if (error && error.permissions.canUpgradeToView) {
      modal.show(<LockedRecordingModal feature="error" />);
    }
  }, [modal, error]);

  if (error && error.permissions.canUpgradeToView) {
    return null;
  }

  return (
    <div data-testid="error-details">
      {fetching ? (
        <div className="flex h-14 flex-col justify-between px-6 py-2">
          <SkeletonLine width="25%" />
          <SkeletonLine width="75%" />
        </div>
      ) : (
        <>
          <div className="flex w-full items-start justify-between px-9 pb-5 pt-9">
            <div className="mr-5 w-2/3 rounded bg-white-lilac-500">
              <DetailsRow title="Timestamp" content={<>{formatFullDateTime(error.createdAt)}</>} />
              <DetailsRow
                title={
                  <>
                    Visitor{' '}
                    <Info
                      content={
                        <div className="w-28">
                          Identify your visitors by using our{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={SupportLinks.sessions.visitorIdentifier}
                            className="text-link"
                          >
                            Visitor ID script
                          </a>{' '}
                          on your site!
                        </div>
                      }
                      label="Hover for info on visitor id"
                    />
                  </>
                }
                content={
                  <button
                    disabled={isSharing}
                    className="text-link mr-2 truncate underline"
                    onClick={() => selectVisitor({ identifier: error.identifier, visitorId: error.visitorId })}
                  >
                    {getVisitorDisplayName(error.identifier, error.visitorId)}
                  </button>
                }
              />
              <DetailsRow
                title="URL"
                content={
                  <a className="hover:underline" href={error.url} target="_blank" rel="noopener noreferrer">
                    {error.url}
                  </a>
                }
                breakall
              />
              <DetailsRow
                title="Referrer"
                content={
                  <a className="hover:underline" href={error.referrer} target="_blank" rel="noopener noreferrer">
                    {error.referrer ?? '-'}
                  </a>
                }
              />
              <DetailsRow title="Error message" content={error.errorMessage} breakall />
              <DetailsRow
                title={
                  <>
                    App version{' '}
                    <Info
                      content={
                        <div className="w-28">
                          Track which version errors are happening in, by using our{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={SupportLinks.errors.appVersionTracking}
                            className="text-link"
                          >
                            App Version script
                          </a>{' '}
                          on your site!
                        </div>
                      }
                      label="Hover for info on app version"
                    />
                  </>
                }
                content={error.appVersion ?? '-'}
              />
              <DetailsRow
                title="Country"
                content={
                  <>
                    <CountryFlag country={error.country} /> <span className="ml-2">{error.country?.name ?? '-'}</span>
                  </>
                }
              />
              <DetailsRow title="Tags" content={error.tags ? <TableTagList tags={error.tags} /> : '-'} />
            </div>

            <div className="w-1/3 min-w-min rounded bg-white-lilac-500">
              <DetailsRow
                title="Browser"
                content={
                  error.browser?.name ? (
                    <>
                      <BrowserIcon browser={error.browser.name} printName={true} tooltip={false} />
                      <span className="ml-1.25">({error.browser.version ? error.browser.version : ''})</span>
                    </>
                  ) : (
                    '-'
                  )
                }
              />
              <DetailsRow
                title="Operating system"
                content={error.os ? <OSIcon os={error.os} tooltip={false} printName={true} /> : '-'}
              />
              <DetailsRow
                title="Device"
                content={error.device ? <DeviceIcon device={error.device} tooltip={false} printName={true} /> : '-'}
              />
              <DetailsRow
                title="Viewport"
                content={
                  error.screenWidth && error.screenHeight ? (
                    <>
                      <ResizeIcon className="mr-2 h-4 w-4 fill-current text-cadet-blue-500" />
                      {error.screenWidth + ' x ' + error.screenHeight}
                    </>
                  ) : (
                    '-'
                  )
                }
              />
              <DetailsRow title="Raw user agent" content={error.userAgent ?? '-'} />
            </div>
          </div>

          <div className="px-9 pb-9">
            <div className="w-full rounded bg-white-lilac-500">
              <div className="text-body-1 flex h-[50px] w-full items-center border-b border-dashed border-mystic-500 px-5">
                Raw stack trace
              </div>
              <div className="whitespace-pre-wrap break-all px-5 py-3 font-mono text-xs text-lynch-500">
                {error.stackTrace ? (
                  error.stackTrace.map((x) => <Stacktrace key={x} text={x} />)
                ) : (
                  <ExplainError fingerprint={error.fingerprint} />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function DetailsRow({ title, content, breakall = false }) {
  return (
    <div className="flex w-full items-start border-b border-dashed border-mystic-500 last:border-0">
      <div className="text-body-1 flex w-40 flex-none items-center justify-end px-2 py-2.5">{title}</div>
      <div
        className={classNames('text-body-5 flex items-center py-2.5 pl-2 pr-3.5', {
          'break-all': breakall,
        })}
      >
        {content}
      </div>
    </div>
  );
}

function ExplainError({ fingerprint }) {
  if (fingerprint === 'Unknown Resource Error') {
    return (
      <div className="italic">
        <div className="mb-3">This error type does not provide a stack trace.</div>
        This type of error event is fired on an{' '}
        <a
          className="text-link text-xs"
          rel="noopener noreferrer"
          target="_blank"
          href="https://developer.mozilla.org/en-US/docs/Web/API/Element"
        >
          Element
        </a>{' '}
        object when a resource failed to load, or can&apos;t be used. For example, if a script has an execution error or
        failed to load due to a network error or an image can&apos;t be found or is invalid. Check the error message to
        see which element triggered this error.
      </div>
    );
  }
  return (
    <div className="italic">
      <div className="mb-3">We don&apos;t have a stack trace for this error.</div>
      Although Crazy Egg makes every attempt to capture the stack trace some types of errors don&apos;t provide stack
      traces. Additionally some browsers{' '}
      <a
        className="text-link text-xs"
        rel="noopener noreferrer"
        target="_blank"
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack#browser_compatibility"
      >
        don&apos;t support stack traces
      </a>{' '}
      regardless of the error type. If this error is from a 3rd-party script you might need to do some{' '}
      <a
        className="text-link text-xs"
        rel="noopener noreferrer"
        target="_blank"
        href={SupportLinks.errors.crossBrowserTracking}
      >
        configuration on your end
      </a>{' '}
      so that Crazy Egg can capture it.
    </div>
  );
}

const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(:[\d]+:[\d]+)/i;
function Stacktrace({ text }) {
  let parts = text.split(urlRegex);
  for (let i = 1; i < parts.length; i += 3) {
    parts[i] = (
      <a key={i} href={parts[i]} className="text-link text-xs font-normal">
        {parts[i]}
      </a>
    );
  }

  return <div>{parts}</div>;
}

function Info({ content, label }) {
  return (
    <div className="ml-1.25">
      <Tooltip tooltipContent={content} interactive>
        <InfoIcon className="h-3.5 w-3.5 fill-current text-dodger-blue-300" aria-label={label} />
      </Tooltip>
    </div>
  );
}
