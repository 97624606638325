import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const optionsFeatureKeys = createQueryKeys('optionsFeature', {
  options: null,
  checkScript: ({ url, device }) => [{ url, device }],
  api: null,
});

export const optionsQuery = () => ({
  ...optionsFeatureKeys.options,
  meta: {
    query: gql`
      query Options {
        options {
          currentIp
          userBlockedIps
        }
      }
    `,
  },
});

export const checkScriptQuery = ({ url, device }) => ({
  ...optionsFeatureKeys.checkScript({ url, device }),
  useErrorBoundary: false,
  staleTime: 0,
  meta: {
    query: gql`
      query CheckScript($url: String!, $device: SnapshotDevice!) {
        checkScript(url: $url, device: $device) {
          finalUrl
          pageTitle
          didRedirect
          installed
          siteVisitedAt
          consoleErrors {
            type
            message
            url
          }
          error {
            name
          }
          warnings {
            name
          }
          infos {
            name
            ... on ScriptCheckResultPageRedirects {
              redirect
            }
            ... on ScriptCheckResultHasScheduledSnapshot {
              startsAt
            }
            ... on ScriptCheckResultDataBeingTrackedTo {
              snapshotId
              snapshotName
              snapshotDevice
              snapshotHasData
            }
            ... on ScriptCheckResultRecordingsDisabled {
              siteId
            }
          }
        }
      }
    `,
  },
});

export const apiQuery = () => ({
  ...optionsFeatureKeys.api,
  meta: {
    query: gql`
      query ApiKeys {
        api {
          key
          secret
          signature
        }
      }
    `,
  },
});
