import classNames from 'classnames';
import { useMemo, useEffect, useRef } from 'react';
import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-page-outline.svg';
import { ReactComponent as SwitchIcon } from '@crazyegginc/hatch/dist/images/icon-switch-outline.svg';
import { ReactComponent as VirtualSessionIcon } from '@crazyegginc/hatch/dist/images/icon-block.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-circle-filled.svg';
import { PLAYER_EVENTS } from '/src/features/recordings/constants';
import { Tooltip, SkeletonLine } from '@crazyegginc/hatch';

export function PageVisit({ previousPageUrl = null, url, onClick = null, children, active, type, timestamp }) {
  const pageRef = useRef();
  const isVirtualSession = timestamp < 0;

  let Icon;
  let title;
  if (type === PLAYER_EVENTS.TAB_SWITCH) {
    Icon = SwitchIcon;
    title = 'Switch tab';
  } else {
    Icon = PageIcon;
    title = 'Visit page';
  }

  useEffect(() => {
    if (
      pageRef?.current &&
      active &&
      !(document.querySelector('aside section#sidebar-events')?.matches?.(':hover') ?? null)
    ) {
      pageRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
    }
  }, [pageRef, active]);

  const tooltipURLText = (
    <a href={url} target="_blank" rel="noopener noreferrer" className="block break-all text-left  text-dodger-blue-500">
      {url}
    </a>
  );

  const tooltipVirtualSession = <span className="text-body-4">No video recorded for this event</span>;

  const displayUrl = useMemo(() => {
    if (!url) return null;
    if (!previousPageUrl) return url;

    const previousUrl = new URL(previousPageUrl);
    const currentUrl = new URL(url);

    if (previousUrl.hostname === currentUrl?.hostname) {
      return currentUrl.pathname;
    }

    return url;
  }, [previousPageUrl, url]);

  if (!url) return null;

  return (
    <li>
      {timestamp === 0 && (
        <span className="text-header-4 group flex w-full items-center border-b border-charade-500 bg-white bg-opacity-10 px-5 py-3.5 uppercase leading-none text-white">
          <PlayIcon className="mb-0.5 mr-2.5 h-5 w-5 fill-current text-white" />
          recording started
        </span>
      )}
      <Tooltip
        placement="left"
        style={{ maxWidth: isVirtualSession ? '150px' : '220px' }}
        tooltipContent={isVirtualSession ? tooltipVirtualSession : tooltipURLText}
        interactive={true}
        containerStyle={{ zIndex: 1000001 }}
      >
        <button
          className={classNames(
            'group flex w-full items-center bg-woodsmoke-500 px-5 py-3.5',
            'text-sm font-normal',
            'transition duration-150 ease-in-out',
            {
              'text-picasso-500 hover:bg-dodger-blue-500 hover:text-white': !isVirtualSession,
              'border-b border-charade-500 text-cadet-blue-500': isVirtualSession,
            },
          )}
          onClick={onClick}
          ref={pageRef}
          disabled={isVirtualSession}
        >
          <Icon
            className={classNames('mr-2.5 h-5 w-5 fill-current', {
              'text-picasso-500 group-hover:text-white': !isVirtualSession,
              'text-cadet-blue-500': isVirtualSession,
            })}
          />
          <span className="flex-1 truncate text-left">
            <strong>{title}:</strong> {displayUrl}
          </span>
          {isVirtualSession && <VirtualSessionIcon className="h-4 w-4 fill-current text-cadet-blue-500" />}
        </button>
      </Tooltip>
      {!isVirtualSession && (
        <ul className="relative border-b border-t border-charade-500 bg-woodsmoke-500">
          {children}
          <span className="absolute left-0 top-0 h-full w-full bg-charade-500 opacity-50" role="presentation"></span>
          <span className="pointer-events-none absolute bottom-0 left-0 z-50 h-px w-full bg-charade-500"></span>
        </ul>
      )}
    </li>
  );
}

export function PageVisitSkeleton() {
  return (
    <li>
      <div className="flex w-full items-center bg-woodsmoke-500 px-5 py-3.5">
        <div className="flex w-full items-center justify-around" data-testid="skeletonRow">
          <SkeletonLine width="8%" background="#3C424D" />
          <SkeletonLine width="28%" background="#3C424D" />
          <SkeletonLine width="38%" background="#3C424D" />
          <SkeletonLine width="18%" background="#3C424D" />
        </div>
      </div>
    </li>
  );
}
