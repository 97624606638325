import { useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useModal, useNotifications, usePermissions, useSite } from '/src/hooks';
import { superLenientUrlRegex } from '/src/utils/regex';
import { addSiteMutation } from '/src/features/options/mutations';
import { Modal } from '/src/contexts/modal';
import { FEATURES } from '/src/features/_global/constants';

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('You must provide a URL.')
    .matches(superLenientUrlRegex, 'Your URL appears to be invalid.'),
});

export function AddSiteModal({ customTitle = null, onExtraSteps = null, onCancel = null }) {
  const { currentAccount } = useAuthContext();

  const notifications = useNotifications();
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;
  const { sites } = useSite({ dontSelectSite: true });
  const oldSitesIdList = useRef();

  useEffect(() => {
    if (sites && !oldSitesIdList.current) {
      oldSitesIdList.current = sites.map((s) => s.id);
    }
  }, [sites]);

  const modal = useModal();
  let ref = useRef();

  const addSite = useMutation(addSiteMutation);

  if (!canEditSites)
    return (
      <Modal>
        <p className="text-body-2 mb-2.5">You are not authorized to add a new site.</p>
        <p className="text-body-2">
          Please{' '}
          <a className="text-link" href={`mailto:${currentAccount.ownerEmail}`}>
            contact your Account Owner
          </a>
          .
        </p>
        <Modal.Actions>
          <Modal.Cancel onClick={onCancel} />
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal data-testid="add-site-modal">
      <Modal.Title>{customTitle ? customTitle : 'Add a new site'}</Modal.Title>

      <Formik
        initialValues={{ url: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          addSite.mutate(
            {
              url: values.url,
            },
            {
              onError: (error) => {
                if (
                  error.graphQLErrors?.[0]?.originalError?.message?.match(
                    /is a subdomain of .* It can not be added separately. The data for the subdomain will be included with the main site./,
                  )
                ) {
                  notifications.error({
                    content: 'Subdomains are included with the main site.',
                    timeout: 3000,
                    context: { error },
                    skipHoneybadger: true,
                  });
                } else {
                  notifications.error({
                    content: 'Site creation failed.',
                    timeout: 3000,
                    context: { error },
                    skipHoneybadger:
                      error.graphQLErrors?.[0]?.originalError?.message?.match(/The site .* already exists/),
                  });
                }
              },
              onSuccess: (data) => {
                const newSite = data.addSite.find((site) => !oldSitesIdList.current.includes(site.id));
                notifications.success({ content: 'Site created successfully.', timeout: 3000 });
                modal.close();
                onExtraSteps?.(newSite);
              },
            },
          );
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form ref={ref}>
            <Input
              className="!mt-1.5"
              size="xl"
              label={'Enter your website URL (subdomains are included with the main site):'}
              name="url"
              id="newSiteInput"
              placeholder="e.g. http://example.com"
              onChange={handleChange}
              onBlur={(e) =>
                setTimeout(() => {
                  ref.current ? handleBlur(e) : null; // only update if component is still mounted
                }, 200)
              }
              value={values.url}
              error={touched.url && errors.url ? errors.url : null}
              autoFocus // eslint-disable-line
            />
            {addSite.error?.message && (
              <div className="text-error">{addSite.error.message.replace(/^\[.*\]\s/, '')}</div>
            )}

            <Modal.Actions>
              <Button type="submit" disabled={addSite.isLoading}>
                {addSite.isLoading ? 'Adding site...' : 'Add site'}
              </Button>
              <Modal.Cancel onClick={onCancel} disabled={addSite.isLoading} />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
