import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { replaceEmojiWithImage } from '../../addons/common/common-functions';

const availableReactions = [
  { emoji: ':slightly_frowning_face:', text: 'Disappointed' },
  { emoji: ':face_with_raised_eyebrow:', text: 'Confused' },
  { emoji: ':neutral_face:', text: 'Neutral' },
  { emoji: ':slightly_smiling_face:', text: 'Happy' },
  { emoji: ':heart_eyes:', text: 'Love it!' },
];

export function ReactionPicker({ reaction = null, onReaction = null }) {
  const [selection, setSelection] = useState(() => reaction);
  const sendReaction = useCallback(
    (selectedReaction) => {
      setSelection(selectedReaction);
      if (selectedReaction !== reaction) {
        onReaction?.(selectedReaction);
      }
    },
    [onReaction, reaction],
  );

  return (
    <div className="flex h-11 items-center space-x-2 rounded-[24px] bg-white-lilac-500 px-2.5">
      {availableReactions.map((currentReaction) => {
        const selected = selection && selection === currentReaction.emoji;
        return (
          <Tooltip tooltipContent={currentReaction.text} key={currentReaction.emoji} show={!selected}>
            <button
              onClick={() => sendReaction(currentReaction.emoji)}
              type="button"
              className={classNames(
                'flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-california-500 grayscale-0',
                {
                  '!grayscale': selection && !selected,
                  'emoji-selected pointer-events-none !w-auto': selection && selected,
                },
              )}
            >
              <span
                className={classNames('emoji-button flex items-center justify-center', {
                  'h-7 w-7': selection && selected,
                  'h-full w-full': !selection || !selected,
                })}
                dangerouslySetInnerHTML={{ __html: replaceEmojiWithImage(currentReaction.emoji) }}
              />
              {selected ? (
                <span className="min-w-[58px] self-center pl-1 pr-2 text-sm font-semibold text-charade-500">
                  {currentReaction.text}
                </span>
              ) : null}
            </button>
          </Tooltip>
        );
      })}
      {reaction &&
      selection === reaction &&
      !availableReactions.map((reaction) => reaction.emoji).includes(reaction) ? (
        <div
          className="emoji-button emoji-selected h-10 w-10"
          dangerouslySetInnerHTML={{ __html: replaceEmojiWithImage(reaction) }}
        />
      ) : null}
    </div>
  );
}
