import { useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { fromUnixTime, getUnixTime, isBefore, subHours } from 'date-fns';

import { useNotifications } from '/src/hooks';

const whiteListedPathsForReload = [
  '/live',
  '/goals',
  '/snapshots',
  '/recordings',
  '/errors',
  '/traffic',
  '/ab-tests',
  '/addons',
];

let interval;

export function OutdatedBundleHandler() {
  const { info } = useNotifications();
  const location = useLocation();
  const lastPathname = useRef(location.pathname);

  const versionCheck = useCallback(() => {
    if (window.version !== window.SHELL_VERSION) {
      clearInterval(interval);
      info({
        title: 'New version available',
        content: (
          <span className="flex items-center">
            Please
            <button onClick={() => window.location.reload()} className="text-link mx-1 !text-white !underline">
              reload the page
            </button>
            to get the latest changes.
          </span>
        ),
      });
    }
    const script = document.getElementById('version_script');
    script?.remove?.();
  }, [info]);

  useEffect(() => {
    window.addEventListener('core:version', versionCheck);
    interval = setInterval(async () => {
      try {
        const request = await fetch(`/v.js`);
        const blob = await request.blob();
        const objectURL = URL.createObjectURL(blob);
        const script = document.createElement('script');
        script.setAttribute('src', objectURL);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('id', 'version_script');
        document.head.appendChild(script);
      } catch {
        console.error('[VERSION] check failed');
      }
    }, 60_000);

    return () => {
      window.removeEventListener('core:version', versionCheck);
      clearInterval(interval);
    };
  }, [versionCheck]);

  useEffect(() => {
    versionCheck();
  }, [versionCheck]);

  // Force reload shell on whitelisted pages if version mismatch at max once every 24h
  useEffect(() => {
    const lastReload = window.localStorage.getItem('shell:lastReload');
    const canReload = !lastReload || isBefore(fromUnixTime(lastReload), subHours(new Date(), 24));

    if (lastPathname.current !== location.pathname) {
      lastPathname.current = location.pathname;
      if (
        window.version !== window.SHELL_VERSION &&
        whiteListedPathsForReload.includes(location.pathname) &&
        canReload
      ) {
        window.localStorage.setItem('shell:lastReload', getUnixTime(new Date()));
        window.location.reload();
      }
    }
  }, [location.pathname]);

  return null;
}
