import { useRef, useState, useEffect } from 'react';
import { copyToClipboard } from '/src/utils';
import { Button, Spinner } from '@crazyegginc/hatch';
import classNames from 'classnames';

// TODO: move to hatch
export function CodeBox({ loading = false, code = null, canCopy = true, fullWidth = true, height = null }) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    // this is purely for a visual effect
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const codeRef = useRef(null);

  const copyCode = () => {
    const codeText = codeRef?.current?.innerText;
    copyToClipboard(codeText);
    setCopied(true);
  };

  return (
    <div
      className={classNames(
        `text-caption relative my-2.5 ${
          height ? height : 'max-h-[150px]'
        } overflow-y-auto overflow-x-hidden whitespace-pre-wrap rounded border border-dodger-blue-500 bg-alice-blue-500 py-5 leading-6`,
        {
          'w-full': fullWidth,
          'px-[30px]': !canCopy,
          'pl-[30px] pr-[150px]': canCopy,
        },
      )}
    >
      {loading ? (
        <span className="flex items-center">
          <Spinner /> <span className="ml-2.5">Loading...</span>
        </span>
      ) : (
        <>
          {canCopy && (
            <div className="absolute right-5 top-4">
              <Button
                variant="secondary"
                size="xs"
                className={`!text-xs ${copied ? '!bg-lima-500 !text-white' : ''}`}
                onClick={() => copyCode()}
              >
                {copied ? 'Copied' : 'Copy to Clipboard'}
              </Button>
            </div>
          )}
          <code dangerouslySetInnerHTML={{ __html: code }} ref={codeRef} className="font-mono" />
        </>
      )}
    </div>
  );
}
