import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Spinner, Input } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';

const validationSchema = yup.object().shape({
  delete: yup.string().required('required').matches('DELETE', 'must match'),
});

export function DeleteModal({ goal, isDraft = false, onConfirm = null, onCancel = null, isDeleting = false }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[370px]">
      <Modal.Title>Delete this {isDraft ? 'draft ' : ''}Goal?</Modal.Title>
      <div className="mb-6 flex w-full justify-between">
        <p className="text-body-2 mt-0 flex flex-col space-y-2.5 text-sm leading-5">
          {goal.abTestsCountActive > 0 ? (
            <>
              <span>
                If you delete this Goal, you will also delete its associated{' '}
                <span className="text-body-1">
                  {goal.abTestsCountActive} A/B {inflect('Test', goal.abTestsCountActive)}
                </span>
                .
              </span>
              <div>
                <a href={`/goals/${goal.id}#ab-tests`} className="text-link" target="_blank" rel="noreferrer">
                  See the affected A/B {inflect('Test', goal.abTestsCountActive)} here.
                </a>
              </div>
            </>
          ) : (
            <span>Are you sure you want to delete this {isDraft ? 'draft' : 'saved'} Goal?</span>
          )}
          <span className="text-body-1">Deleting this Goal cannot be undone.</span>
        </p>
      </div>
      <Formik
        initialValues={{ delete: '' }}
        validationSchema={validationSchema}
        onSubmit={() => {
          onConfirm?.(goal.id);
          modal.close();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched }) => (
          <Form>
            <div className="text-body-2 leading-normal">
              <Input
                name="delete"
                id="delete-confirm-input"
                label="Type DELETE below to confirm:"
                placeholder="Type DELETE to continue"
                onFocus={() => setFieldTouched('delete', true, true)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.delete}
                autoComplete="off"
              />
              <span className="text-caption">case sensitive</span>
            </div>
            <Modal.Actions>
              <Button variant="warning" disabled={!!errors.delete || !touched.delete || isDeleting} type="submit">
                {isDeleting ? (
                  <>
                    <Spinner />
                    <span>Deleting...</span>
                  </>
                ) : (
                  `Delete ${isDraft ? 'draft' : 'Goal'}${
                    goal.abTestsCountActive > 0 ? ' & A/B ' + inflect('Test', goal.abTestsCountActive) : ''
                  }`
                )}
              </Button>
              <Button
                variant="cancel"
                disabled={isDeleting}
                onClick={() => {
                  onCancel?.();
                  modal.close();
                }}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
