import { Button, Tooltip } from '@crazyegginc/hatch';

import { AB_TEST_STATUSES } from '../constants';
import { AbTestActionButton } from './AbTestActionButton';
import { abTestDurationV2, abTestCreatedBy, renderBestVariant } from '../utils';
import { LinkWithParams } from '/src/components/LinkWithParams';
import { abbreviateNumber } from '/src/utils';
import { ListCard, ListCardMetric, ListCardDevices } from '/src/features/ab-testing/components/ListCardV2';
import { PreviewLink } from '/src/features/ab-testing/components/PreviewLink';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function AbTestListCard({ abTest }) {
  const testDurationField = abTestDurationV2(abTest);
  const testCreatedByField = abTestCreatedBy(abTest);

  const isDraft = abTest.status === AB_TEST_STATUSES.DRAFT;

  return (
    <ListCard
      abTest={abTest}
      key={abTest.id}
      counters={
        <>
          <ListCardDevices devices={abTest.devices} />
          {!isDraft ? (
            <ListCardMetric
              value={abbreviateNumber(abTest.totalVisitors)}
              title={`${abTest.totalVisitors} visitors`}
              unit="visitors"
              className="px-1"
            />
          ) : null}
          <ListCardMetric
            value={abTest.variantsCount}
            tooltipContent={
              <div className="flex flex-col items-start space-y-2.5 p-2 text-xs font-normal leading-4">
                {abTest.variants.map((variant) => {
                  return (
                    <PreviewLink variant={variant} key={`${abTest.id}_${variant.id}_previewLink`}>
                      <div className="flex items-center space-x-1.5 hover:underline">
                        <span>View {variant.variantName}</span>
                        <ExpandIcon className="h-2.5 w-2.5 rotate-90 fill-current" aria-label="follow link" />
                      </div>
                    </PreviewLink>
                  );
                })}
              </div>
            }
            interactive={true}
            unit="variants"
            className="border-b border-dashed px-1"
          />
        </>
      }
      actionButton={<AbTestActionButton entity={abTest} />}
      primaryButton={
        isDraft ? (
          <Button
            leftIcon={<EditIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            component={LinkWithParams}
            to={`/ab-tests/${abTest.id}/edit`}
            variant="secondary"
            className="shrink-0"
          >
            Edit Draft
          </Button>
        ) : (
          <Button component={LinkWithParams} to={`/ab-tests/${abTest.id}`} variant="secondary" className="shrink-0">
            View Results
          </Button>
        )
      }
    >
      <div className="flex min-w-0 flex-col space-y-2">
        {!isDraft ? (
          <div className="mb-0.5 flex min-w-0 space-x-5">
            <div className="text-body-5 w-24 shrink-0">Best variant</div>
            <div className="text-body-2">
              <span>{renderBestVariant(abTest)}</span>
            </div>
          </div>
        ) : null}

        <div className="mb-0.5 flex min-w-0 space-x-5">
          <div className="text-body-5 w-24 shrink-0">Traffic split</div>
          <Tooltip
            tooltipContent={
              <div className="grid grid-cols-2 space-x-4 text-xs font-semibold">
                {abTest.variants.map((variant) => (
                  <div className="contents" key={`weight:variant:${variant.id}`}>
                    <span className="text-left text-cadet-blue-500">{variant.variantName}</span>
                    {variant.weight ? (
                      <span className="text-right tabular-nums">{variant.weight}%</span>
                    ) : (
                      <span className="text-right font-thin italic">pending</span>
                    )}
                  </div>
                ))}
              </div>
            }
          >
            <div className="text-body-2 flex flex-col truncate border-b border-dashed border-b-cadet-blue-500">
              <span>{abTest.autoReweight ? 'Multi-arm bandit - Powered by AI' : 'Manual split'}</span>
            </div>
          </Tooltip>
        </div>

        <div className="mb-0.5 flex items-start">
          <div className="flex space-x-5">
            <div className="text-body-5 w-24 capitalize">{testCreatedByField.label}</div>
            <div className="text-body-2">{testCreatedByField.value}</div>
          </div>
        </div>

        <div className="mb-0.5 flex items-start">
          <div className="flex space-x-5">
            <div className="text-body-5 w-24">{testDurationField.label}</div>
            <div className="text-body-2">
              {testDurationField.value} {testDurationField?.description ? `(${testDurationField.description})` : null}
            </div>
          </div>
        </div>
      </div>
    </ListCard>
  );
}
