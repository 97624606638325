import { PlayerEvent, TooltipEvent } from '../PlayerEvent';
import { PLAYER_FORM_SUBEVENTS } from '/src/features/recordings/constants';

import { ReactComponent as LoginIcon } from '@crazyegginc/hatch/dist/images/icon-login.svg';
import { ReactComponent as SignupIcon } from '@crazyegginc/hatch/dist/images/icon-user-outline.svg';
import { ReactComponent as SubmitIcon } from '@crazyegginc/hatch/dist/images/icon-form-tick.svg';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';
import { ReactComponent as AbandonedFormIcon } from '@crazyegginc/hatch/dist/images/icon-form-abandoned.svg';
import { ReactComponent as ResubmitFormIcon } from '@crazyegginc/hatch/dist/images/icon-form-redo.svg';
import { ReactComponent as MailIcon } from '@crazyegginc/hatch/dist/images/icon-mail.svg';

const { FORM_SUBMIT, FORM_RESUBMIT, FORM_ABANDON, FORM_SIGNUP, FORM_LOGIN, FORM_EMAIL, FORM_SEARCH } =
  PLAYER_FORM_SUBEVENTS;

function getFormEventDisplayDetails(sub_event_type) {
  switch (sub_event_type) {
    case FORM_SUBMIT:
      return { title: 'Submit form', Icon: SubmitIcon };
    case FORM_RESUBMIT:
      return { title: 'Resubmit form', Icon: ResubmitFormIcon };
    case FORM_ABANDON:
      return { title: 'Abandon form', Icon: AbandonedFormIcon };
    case FORM_SIGNUP:
      return { title: 'Sign up', Icon: SignupIcon };
    case FORM_LOGIN:
      return { title: 'Log in', Icon: LoginIcon };
    case FORM_EMAIL:
      return { title: 'Enter email', Icon: MailIcon };
    case FORM_SEARCH:
      return { title: 'Search', Icon: SearchIcon };
  }
}

export function FormEvent({ timestamp, active, onClick, sub_event_type }) {
  const { title, Icon } = getFormEventDisplayDetails(sub_event_type);

  return <PlayerEvent icon={Icon} title={title} timestamp={timestamp} active={active} onClick={onClick} />;
}

export function TooltipFormEvent({ onClick, event }) {
  const { title, Icon } = getFormEventDisplayDetails(event.sub_event_type);

  return <TooltipEvent icon={Icon} title={title} event={event} onClick={onClick} />;
}
