export function MainTextRow({ children }) {
  return <div className="flex min-w-0 items-start">{children}</div>;
}

export function SubTextRow({ children }) {
  return <div className="text-body-5 mt-1 flex items-center">{children}</div>;
}

export function Bull() {
  return <span className="mx-2.5 h-1 w-1 rounded-full bg-cadet-blue-500" />;
}
