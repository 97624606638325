import { Link } from 'react-router-dom';

import { useSelectedSite } from '/src/hooks';
import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';
import { getPageName } from '../../live-functions';
import { MainTextRow, SubTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function ErrorEvent({ liveEvent, identifyEvents }) {
  const { url, fingerprint, fingerprint_md5 } = liveEvent.attributes;
  const { selectedSite } = useSelectedSite();

  const page = getPageName(liveEvent.attributes.url);

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <span>
          received error{' '}
          <Link
            className="text-link"
            to={{ pathname: `/errors/${fingerprint_md5}`, search: `site=${selectedSite?.name}` }}
          >
            {fingerprint}
          </Link>
        </span>
      </MainTextRow>
      {url && (
        <SubTextRow>
          <TruncateWithTooltip text={`on ${page}`} className="max-w-lg" />
        </SubTextRow>
      )}
    </div>
  );
}
