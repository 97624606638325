import { Button } from '@crazyegginc/hatch';

import { Paywall } from '/src/components/Paywall';

import SearchPNG from '@crazyegginc/hatch/dist/images/illustration-search-bg.png';
import SearchWebP from '@crazyegginc/hatch/dist/images/illustration-search-bg.webp';

function NoMatchesWall({ item = 'matches', reset, text, actionText }) {
  return (
    <Paywall>
      <Paywall.Image webp={SearchWebP} fallback={SearchPNG} width="176" alt="magnifying glass" />
      <Paywall.Title>No {item} found</Paywall.Title>
      <Paywall.Body>We couldn&#39;t find any {text}.</Paywall.Body>
      {reset && (
        <Button variant="secondary" onClick={reset}>
          Clear {actionText}
        </Button>
      )}
    </Paywall>
  );
}

NoMatchesWall.displayName = 'NoMatchesWall';

export { NoMatchesWall };
