import { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Popover, StyledPopoverPanel, Indicator, Toggle } from '@crazyegginc/hatch';

import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';

export function Settings({ sections, setSections }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [boxElement, setBoxElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, boxElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              'flex h-[35px] w-[70px] items-center justify-center rounded px-4 text-dodger-blue-300 focus-visible:outline-black',
              {
                'border border-mystic-500 bg-white shadow-md': open,
              },
            )}
          >
            <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
            <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
          </Popover.Button>
          {open ? (
            <StyledPopoverPanel
              ref={setBoxElement}
              align="center"
              static
              className="w-[260px] !pr-1"
              data-testid="actions-dropdown"
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />

              {sections.map((section, i) => (
                <div key={section.name} className="flex items-center justify-between space-x-2.5">
                  <Toggle
                    id={section.name}
                    enabled={section.enabled}
                    setEnabled={(newValue) =>
                      setSections((x) => {
                        const result = [...x];
                        result[i].enabled = newValue;
                        return result;
                      })
                    }
                    label={section.name}
                    displayLabel={true}
                    size="sm"
                  />
                </div>
              ))}
            </StyledPopoverPanel>
          ) : null}
        </>
      )}
    </Popover>
  );
}
