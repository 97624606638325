import { DashHeader } from '/src/components/headers/DashHeader';
import { VideoTutorial } from '/src/components/modals/VideoTutorial';

import { useModal } from '/src/hooks';

import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

import HowToVideo from '../assets/TA_howto.mp4';

import { ReactComponent as HelpIcon } from '@crazyegginc/hatch/dist/images/icon-help-circle-outline.svg';

export function TADashboardHeader() {
  const modal = useModal();

  return (
    <DashHeader
      title={
        <div className="flex items-center">
          {getFeatureName(FEATURES.TRAFFIC_ANALYSIS)}
          <button
            type="button"
            className="text-link ml-5 flex items-center leading-none"
            onClick={() =>
              modal.show(
                <VideoTutorial
                  title="Learn about Traffic Analysis"
                  description="Compare and analyze referring traffic, campaigns and landing pages."
                  videoSrc={HowToVideo}
                  videoLabel="Learn about Traffic Analysis video"
                />,
              )
            }
          >
            <HelpIcon className="mr-2 h-4 w-4 fill-current text-dodger-blue-500" />
            How to use
          </button>
        </div>
      }
      feature={FEATURES.TRAFFIC_ANALYSIS}
    />
  );
}
