import { useFormikContext } from 'formik';
import { TextArea, Button } from '@crazyegginc/hatch';

import { Input } from '../basic-ui';

export function TemplateFields() {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting } = useFormikContext();

  return (
    <div className="mb-5 ml-10 space-y-5">
      <TextArea
        label={<div className="text-body-1 mb-1"> Description</div>}
        id="description"
        name="description"
        rows={2}
        value={values.description}
        error={errors.description && touched.description ? errors.description : null}
        onChange={handleChange}
        onBlur={handleBlur}
        className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded"
      />
      <Input
        label="Category / Tags"
        id="category"
        name="category"
        value={values.category}
        error={errors.category && touched.category ? errors.category : null}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="flex items-center space-x-2.5">
        <Button
          variant="secondary"
          component="label"
          size="lg"
          className="cursor-pointer"
          htmlFor="upload"
          disabled={isSubmitting}
        >
          Choose image
        </Button>
        <span className="text-body-2">{values.thumbnail ? values.thumbnail.name : 'No file chosen'}</span>
        <input
          accept="image/*"
          id="upload"
          type="file"
          hidden
          onChange={(e) => {
            setFieldValue('thumbnail', e.currentTarget.files[0]);
          }}
        />
      </div>
    </div>
  );
}
