import { useState } from 'react';
import { Button, RadioGroup, Divider } from '@crazyegginc/hatch';

import { useWizard } from '/src/hooks';

const protocolOptions = [
  { value: false, label: 'Track both http and https protocols' },
  { value: true, label: 'Only track addresses with the same protocol as the URL I entered' },
];

const hostOptions = [
  { value: false, label: 'Track any sub-domain underneath the domain of the URL I entered' },
  { value: true, label: 'Only track the exact sub-domain for the URL I entered' },
];

const pathOptions = [
  { value: false, label: 'Track standard index paths and ignore trailing slashes' },
  { value: true, label: 'Only track addresses with the same exact path as the URL I entered' },
];

const queryOptions = [
  { value: false, label: 'Track any URL that has at least the same query string as I entered' },
  { value: true, label: 'Only track URLs with the exact query string as I entered' },
];

const hashOptions = [
  { value: false, label: 'Track all hashes for the URL I entered' },
  { value: true, label: 'Only track the exact URL with the hash as I entered' },
];

export function UrlTrackingModal({ onSuccess, formik }) {
  const wizard = useWizard();
  const urlTrackingValues = formik.values.trackingOptions.urlTracking;
  const [strictProtocol, setStrictProtocol] = useState(urlTrackingValues?.strictProtocol ?? protocolOptions[0].value);
  const [strictHost, setStrictHost] = useState(urlTrackingValues?.strictHost ?? hostOptions[0].value);
  const [strictPath, setStrictPath] = useState(urlTrackingValues?.strictPath ?? pathOptions[0].value);
  const [strictQuery, setStrictQuery] = useState(urlTrackingValues?.strictQuery ?? queryOptions[0].value);
  const [strictHash, setStrictHash] = useState(urlTrackingValues?.strictHash ?? hashOptions[0].value);

  function save() {
    formik.setFieldValue(
      'trackingOptions.urlTracking',
      {
        strictProtocol,
        strictHost,
        strictPath,
        strictQuery,
        strictHash,
      },
      true,
    );
    wizard.closeModal();
    onSuccess?.();
  }

  return (
    <>
      <h1 className="text-header-1 mb-8 mt-0">Customize URL Tracking Rules</h1>
      <Divider className="my-2.5" />

      <div className="text-body-2 mt-3.5">
        Default settings are selected and will work for the majority of sites. But, you can change the settings if you
        need more specific tracking.{' '}
      </div>

      <div className="mt-6">
        <RadioGroup value={strictProtocol} onChange={setStrictProtocol} label="Protocols" options={protocolOptions} />
        <div className="text-body-5 ml-[34px] leading-normal">
          {strictProtocol === false
            ? 'Track pages with either http or https addresses (http://crazyegg.com or https://crazyegg.com).'
            : 'Track pages only when the address matches the protocol (only http not https).'}
        </div>
      </div>

      <div className="mt-6">
        <RadioGroup value={strictHost} onChange={setStrictHost} label="Sub-Domains" options={hostOptions} />
        <div className="text-body-5 ml-[34px] leading-normal">
          {strictHost === false
            ? 'Track pages on any sub-domain (e.g. crazyegg.com, www.crazyegg.com, or foo.crazyegg.com).'
            : 'Track pages only when the host name is the exact domain specified (crazyegg.com).'}
        </div>
      </div>

      <div className="mt-6">
        <RadioGroup value={strictPath} onChange={setStrictPath} label="Paths" options={pathOptions} />
        <div className="text-body-5 ml-[34px] leading-normal">
          {strictPath === false
            ? 'Track any page with a standard index path and ignore trailing slashes (e.g. / or /index.html).'
            : 'Track pages with precisely the path I entered (/).'}
        </div>
      </div>

      <div className="mt-6">
        <RadioGroup value={strictQuery} onChange={setStrictQuery} label="Query Strings" options={queryOptions} />
        <div className="text-body-5 ml-[34px] leading-normal">
          {strictQuery === false
            ? 'Track any page with a query string that matches at least what I entered (e.g. / or ?page=1).'
            : 'Track only pages whose address matches the exact query string I entered (e.g. not ?page=1).'}
        </div>
      </div>

      <div className="mb-4 mt-6">
        <RadioGroup value={strictHash} onChange={setStrictHash} label="Hashes" options={hashOptions} />
        <div className="text-body-5 ml-[34px] leading-normal">
          {strictHash === false
            ? 'Track any address regardless of hash. (e.g. /, /#foo or /#bar).'
            : 'Do not track any address with a different hash (e.g. / not /#foo, not /#bar).'}
        </div>
      </div>

      <Divider className="my-2.5" />

      <div className="mt-7 flex space-x-2.5">
        <Button variant="secondary" onClick={save}>
          Save &amp; Continue
        </Button>
        <Button variant="cancel" onClick={wizard.closeModal}>
          Cancel
        </Button>
      </div>
    </>
  );
}
