import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';

import { isDevelopment } from '/src/utils';
import { CE2BlockedModal } from '/src/features/addons/common/modals/CE2BlockedModal';
import { useModal } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

export function useAddonScript(readyCallback, type) {
  const modal = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.CE2) {
      //Honeybadger.notify('window.CE2 is not available when trying to load addon script');
      modal.show(
        <CE2BlockedModal
          onConfirm={() => {
            navigate(type === 'cta' ? `/addons?type=${FEATURES.CTAS}` : `/addons?type=${FEATURES.SURVEYS}`);
          }}
        />,
      );
      return;
    }

    function loadScripts() {
      if (type === 'survey') {
        if (window.CE2.loadAddon) {
          window.CE2.loadAddon?.(type);
        } else {
          window.CE2.loadSurveyScripts?.();
        }
        if (window.CE2.Survey) {
          readyCallback?.();
        } else {
          window.addEventListener('surveyLoaded', () => {
            readyCallback?.();
          });
        }
      } else {
        window.CE2.loadAddon?.(type);
        if (window.CE2.CTA) {
          readyCallback?.();
        } else {
          window.addEventListener('ctaLoaded', () => {
            readyCallback?.();
          });
        }
      }
    }

    let src;
    if (isDevelopment()) {
      src = typeof window.ADDON_SCRIPT_URL === 'function' ? window.ADDON_SCRIPT_URL() : window.ADDON_SCRIPT_URL;
    } else {
      if (window.CE2.ADDON_SCRIPT_VERSION) {
        src = `https://script.crazyeggcdn.com/scripts/addons/${window.CE2.ADDON_SCRIPT_VERSION}/index.js`;
      } else {
        src = `https://script.crazyeggcdn.com/scripts/survey/${window.CE2.SURVEY_VERSION}/index.js`;
      }
    }

    if ((window.CE2.Survey && type === 'survey') || (window.CE2.CTA && type === 'cta')) {
      readyCallback?.();
      return;
    }
    const isScriptPresent = document.querySelector(`script[src="${src}"]`);

    if (isScriptPresent) {
      loadScripts();
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.onload = () => {
        loadScripts();
      };
      scriptTag.onerror = () => {
        Honeybadger.notify(`Failed to load addon script: ${src}`);
      };
      scriptTag.src = src;
      document.body.appendChild(scriptTag);
    }
  }, [readyCallback, type, modal, navigate]);
}
