import { DEVICE_TYPES } from '/src/features/_global/constants';

export const ABTEST = 'ABTEST';

export const abTestTypes = {
  SPLIT: 'SPLIT',
  PATCH: 'PATCH',
};

export const abTestVariantTypes = {
  CONTROL: 'CONTROL',
  VARIANT: 'VARIANT',
};

export const DEVICE_WIDTHS = {
  [DEVICE_TYPES.DESKTOP]: `${Math.max(window.innerWidth, 1366)}px`,
  [DEVICE_TYPES.TABLET]: '768px',
  [DEVICE_TYPES.PHONE]: '375px',
};

export const VARIANT_STATUSES = {
  STATIC: 'STATIC',
  DELETED: 'DELETED',
  PRISTINE: 'PRISTINE',
  DIRTY: 'DIRTY',
};

export const AB_TEST_STATUSES = {
  DELETED: 'DELETED',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
};

export const AB_TESTS_CREATION_LINK = '/ab-tests/new';

export const MOBILE_OPTIONS = [
  {
    id: 'apple',
    title: 'Apple',
    dimension: '',
    width: 0,
  },
  {
    id: 'apple15ProMax',
    title: 'iPhone 15 Pro Max',
    dimension: '430x932',
    width: 430,
  },
  {
    id: 'apple15Plus14ProMax',
    title: 'iPhone 15 Plus, 14 Pro Max',
    dimension: '430x932',
    width: 430,
  },
  {
    id: 'apple1514Pro',
    title: 'iPhone 15, 14 Pro',
    dimension: '393x852',
    width: 393,
  },
  {
    id: 'apple12ProMax13ProMax14Plus',
    title: 'iPhone 12 Pro Max, 13 Pro Max, 14 Plus',
    dimension: '428x926',
    width: 428,
  },
  {
    id: 'apple121314',
    title: 'iPhone 12, 13, 14',
    dimension: '390x844',
    width: 390,
  },
  {
    id: 'apple1111ProMax',
    title: 'iPhone 11, 11 Pro Max',
    dimension: '414x896',
    width: 414,
  },
  {
    id: 'appleXXS',
    title: 'iPhone X, XS',
    dimension: '375x812',
    width: 375,
  },
  {
    id: 'apple66s78se',
    title: 'iPhone 6, 6S, 7, 8, SE',
    dimension: '375x667',
    width: 375,
  },
  {
    id: 'google',
    title: 'Google',
    dimension: '',
    width: 0,
  },
  {
    id: 'google8Pro',
    title: 'Pixel 8 Pro',
    dimension: '448x998',
    width: 448,
  },
  {
    id: 'google6Pro7Pro',
    title: 'Pixel 6 Pro, 7 Pro',
    dimension: '412x892',
    width: 412,
  },
  {
    id: 'google5a66a77a8',
    title: 'Pixel 5a, 6, 6a, 7, 7a, 8',
    dimension: '412x915',
    width: 412,
  },
  {
    id: 'google4a5',
    title: 'Pixel 4a, 5',
    dimension: '393x851',
    width: 393,
  },
  {
    id: 'google4',
    title: 'Pixel 4',
    dimension: '393x830',
    width: 393,
  },
  {
    id: 'samsung',
    title: 'Samsung',
    dimension: '',
    width: 0,
  },
  {
    id: 'samsungS22Ultra',
    title: 'Galaxy S22 Ultra',
    dimension: '384x824',
    width: 384,
  },
  {
    id: 'samsungS22Plus',
    title: 'Galaxy S22+',
    dimension: '384x832',
    width: 384,
  },
  {
    id: 'samsungS22',
    title: 'Galaxy S22',
    dimension: '360x780',
    width: 360,
  },
  {
    id: 'samsungS21Ultra',
    title: 'Galaxy S21 Ultra',
    dimension: '412x915',
    width: 412,
  },
  {
    id: 'samsungS21Plus',
    title: 'Galaxy S21+',
    dimension: '384x854',
    width: 384,
  },
  {
    id: 'samsungS21',
    title: 'Galaxy S21',
    dimension: '360x800',
    width: 360,
  },
];
