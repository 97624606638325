import { useEffect, useCallback, useMemo, useRef } from 'react';
import { provideInteragentJWT, updateSelectors } from '/src/services/web-editor';
import { useDebounce, useAuthContext, useModal } from '/src/hooks';
import { EditorPopupsModal } from '/src/features/ab-testing/components/modals/EditorPopupsModal';

function safeEncodeUrlForPath(url) {
  /* Copied from web-editor Shell integration */

  // By encoding any % symbol before encoding the URL we avoid the
  // (incorrect) decoding the browser does when reading the URL on the
  // web-editor.
  //
  // Without this fix, the following flow happens
  //
  // 1. Shell (decoded)     : "/foo%20bar"
  // 2. Shell (encoded)     : "%2Ffoo%2520bar"
  // 3. WebEditor (encoded) : "%2Ffoo%20bar"
  // 4. WebEditor (decoded) : "/foo bar" Which is not what we want
  //
  // As you can see we loose the %25 character escape between step 2 and 3,
  // after the URL is placed on the iframe's src attribute as part of the
  // path.
  //
  // To avoid this we double encode the % symbol.
  //
  // 1. Shell (decoded)     : "/foo%20bar"
  // 2. Shell (escape %)     : "/foo%2520bar"
  // 3. Shell (encoded)     : "%2Ffoo%252520bar"
  // 4. WebEditor (encoded) : "%2Ffoo%2520bar"
  // 5. WebEditor (decoded) : "/foo%20bar"
  //
  // Which is the same as initial input.
  return encodeURIComponent(url.replace(/%/g, '%25'));
}

export function ElementSelector({
  url,
  service = null,
  selected = null,
  onSelect = null,
  onLoad = null,
  editorPath = 'click',
  device = 'desktop',
  isManual = false,
  ...props
}) {
  const modal = useModal();
  const { currentUser } = useAuthContext();

  const { explicitAgent } = currentUser.settings;
  const isInspect = editorPath === 'inspect';
  const debouncedSelector = useDebounce(selected || '', 500);
  // note we don't want the frameSrc to change when we select a new
  // element. The component is unmounted between device selections
  // so no risk of the component getting out of sync.
  const frameSrc = useMemo(
    () =>
      `${window.WEB_EDITOR_URL}/pages/${safeEncodeUrlForPath(url)}/${editorPath}?device=${device.toLowerCase()}${
        !isInspect && Boolean(selected) ? `&selector=${encodeURIComponent(selected)}` : ''
      }&name=element-selector&explicit_agent=${explicitAgent}`,
    [url, editorPath, device, isInspect, explicitAgent], // eslint-disable-line
  );

  useEffect(() => {
    if (isManual) {
      updateSelectors(debouncedSelector);
    }
  }, [isManual, debouncedSelector]);

  const showPopupsModal = useCallback(() => {
    modal.show(<EditorPopupsModal service={service} />);
  }, [modal, service]);

  const handleMessage = useCallback(
    async (msg) => {
      let data;
      try {
        data = JSON.parse(msg.data);
      } catch {
        // noop
      }

      if (data?.name === 'element-selector-events' && data?.query?.eventType === 'interagentJWT') {
        // get the interagent JWT
        return provideInteragentJWT('element-selector-frame', data);
      }

      if (service && data?.name === 'element-selector-events' && data?.query?.eventType === 'showPopupsModal') {
        showPopupsModal();
      }
    },
    [service, showPopupsModal],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  if (!window.WEB_EDITOR_URL) {
    console.warn('[web-editor] URL config not found');
    return null;
  }

  return (
    <ElementSelectorFrame
      key={`${editorPath}:${device}`}
      src={frameSrc}
      onSelect={onSelect}
      onLoad={() => {
        if (isManual && debouncedSelector && debouncedSelector.trim() !== '') {
          updateSelectors(debouncedSelector);
        }
        onLoad?.();
      }}
      {...props}
    />
  );
}

function ElementSelectorFrame({ src, onSelect, onLoad, ...props }) {
  const frameRef = useRef(null);

  const handleMessage = useCallback(
    (msg) => {
      let data;
      try {
        data = JSON.parse(msg.data);
      } catch {
        // noop
      }
      if (data?.name === props['event-name'] && data?.query) {
        onSelect?.(data.query);
      }
    },
    [onSelect, props],
  );

  useEffect(() => {
    if (frameRef.current) {
      window.addEventListener('message', handleMessage);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [frameRef, handleMessage]);

  return (
    <iframe
      id="element-selector-frame"
      title="Element Selector"
      src={src}
      ref={frameRef}
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      scrolling="no"
      frameBorder="0"
      onLoad={() => {
        onLoad?.();
      }}
      {...props}
    />
  );
}
