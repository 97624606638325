import { LIVE_EVENT_TYPES, BASIC_VISITOR } from './constants';
import { liveEvents } from './supported-events';

export function parseEvents(events, visitEvents) {
  const newVisitEvents = {};
  const newBasicVisitEvents = {};
  const newAdvancedVisitEvents = {};
  const newIdentifyEvents = {};
  const newEventCounts = {};
  const newPeopleCounts = {};
  const newPageCounts = {};
  const ignoredEventIds = {};

  [...events].reverse().forEach((e) => {
    const { name } = getLiveEvent(e.event, e.attributes?.sub_event_type);

    if (e.event === LIVE_EVENT_TYPES.IDENTIFY) {
      newIdentifyEvents[e.visitorId] = e.attributes.identifier;
    } else {
      if (!name) {
        // ignore unsupported event
        ignoredEventIds[e.eventId] = true;
      } else {
        let url;
        let visitorId = e.visitorId;
        let visitId = e.attributes.visit_id;
        if (visitId) {
          url = getPageName(newVisitEvents[visitId]?.attributes?.url ?? visitEvents[visitId]?.attributes?.url) ?? '';
        }
        if (e.event === LIVE_EVENT_TYPES.VISIT) {
          if (newBasicVisitEvents[e.visitorId]) {
            // there's already been basic visits with this visitorId, so remove those
            removeBasicEvents(newBasicVisitEvents[e.visitorId]);
            delete newBasicVisitEvents[e.visitorId];
          }
          visitId = e.eventId;
          url = getPageName(newVisitEvents[visitId]?.attributes?.url ?? visitEvents[visitId]?.attributes?.url) ?? '';
          newAdvancedVisitEvents[e.visitorId] = e;
          newVisitEvents[e.eventId] = e;
        } else if (e.event === LIVE_EVENT_TYPES.DAILY_VISIT) {
          if (newAdvancedVisitEvents[e.visitorId]) {
            // we've already seen an advanced visit with this visitorId, so ignore
            ignoredEventIds[e.eventId] = true;
          } else {
            visitId = e.eventId;
            visitorId = BASIC_VISITOR;
            url = getPageName(e.attributes?.path) ?? '';
            newBasicVisitEvents[e.visitorId] = [...(newBasicVisitEvents[e.visitorId] ?? []), e];
          }
        }
        if (!ignoredEventIds[e.eventId]) {
          if (visitId && url) {
            newPageCounts[url] = {
              count: (newPageCounts[url]?.count || 0) + 1,
              visitIds: [...(newPageCounts[url]?.visitIds ?? []), visitId],
            };
          }
          newPeopleCounts[visitorId] = (newPeopleCounts[visitorId] || 0) + 1;
        }
      }

      if (!ignoredEventIds[e.eventId]) {
        newEventCounts[name] = (newEventCounts[name] || 0) + 1;
      }
    }
  });

  function removeBasicEvents(events) {
    events.forEach((e) => {
      const { name } = getLiveEvent(e.event, e.attributes?.sub_event_type);
      const url = getPageName(e.attributes?.path) ?? '';
      const visitorId = BASIC_VISITOR;

      ignoredEventIds[e.eventId] = true;

      if (newPageCounts[url]) {
        newPageCounts[url].count -= 1;
      }
      if (newPeopleCounts[visitorId]) {
        newPeopleCounts[visitorId] -= 1;
        if (newPeopleCounts[visitorId] === 0) {
          delete newPeopleCounts[visitorId];
        }
      }
      if (newEventCounts[name]) {
        newEventCounts[name] -= 1;
        if (newEventCounts[name] === 0) {
          delete newEventCounts[name];
        }
      }
    });
  }

  const newDisplayedEvents = [...events].filter((e) => !ignoredEventIds[e.eventId]);

  return {
    newVisitEvents,
    newIdentifyEvents,
    newEventCounts,
    newPeopleCounts,
    newPageCounts,
    newDisplayedEvents,
  };
}

export function getLiveEventByName(name) {
  return (
    liveEvents.find((e) => {
      if (e.name === name) {
        return true;
      }
      return false;
    }) ?? {}
  );
}

export function getLiveEvent(type, sub_type) {
  return (
    liveEvents.find((e) => {
      if (e.type === type) {
        if (sub_type) {
          if (e.sub_type === sub_type) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }) ?? {}
  );
}

export function getAllEventNames() {
  return [...new Set(liveEvents.map((e) => e.name))];
}

export function getPageName(urlOrPath) {
  if (!urlOrPath) {
    return urlOrPath;
  }
  let page;
  if (urlOrPath.match(/^https?:/)) {
    page = new URL(urlOrPath).pathname;
  } else {
    page = decodeURIComponent(urlOrPath);
  }
  if (page === '/') {
    page = 'homepage';
  }

  return page;
}
