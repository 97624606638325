/* eslint-disable jsx-a11y/media-has-caption */

import { useCallback, useEffect, useRef, useState } from 'react';

import { formatDuration } from '/src/utils/date';
import { LazyImg } from '/src/components/LazyImg';
import IMGPlaceholder from '../assets/whatsnew_img_placeholder.jpg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';

export function Attachment({ attachment: { assetType, assetUrl, assetAlt } }) {
  if (assetType === 'video') {
    return <VideoPlayer url={assetUrl} />;
  }
  if (assetType === 'image') {
    return <LazyImg placeholder={IMGPlaceholder} src={assetUrl} alt={assetAlt} width="588px" height="279px" />;
  }
}

function VideoPlayer({ url }) {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [metadata, setMetadata] = useState(null);

  function startPlayback() {
    setPlaying(true);
    videoRef?.current?.play?.();
  }

  const togglePlayback = useCallback(() => {
    if (playing) {
      videoRef?.current?.pause?.();
    } else {
      videoRef?.current?.play?.();
    }
    setPlaying(!playing);
  }, [playing]);

  useEffect(() => {
    function handleKeyPresses(e) {
      e.preventDefault();

      if ((playing && e.code === 'Space') || (playing && e.code === 'KeyK')) {
        // give the player focus
        videoRef.current?.focus();
        togglePlayback();
      }
    }

    window.addEventListener('keypress', handleKeyPresses);

    return () => window.removeEventListener('keypress', handleKeyPresses);
  }, [playing, togglePlayback]);

  function videoType(videoUrl) {
    if (videoUrl.includes('.mp4')) {
      return 'video/mp4';
    } else if (videoUrl.includes('.webm')) {
      return 'video/webm';
    }
  }

  function setVideoMetadata(e) {
    setMetadata({
      videoHeight: e.target.videoHeight,
      videoWidth: e.target.videoWidth,
      duration: e.target.duration,
    });
  }

  if (!url) {
    throw new Error('Video component instantiated without URL');
  }

  return (
    <div className="relative">
      <video
        preload="metadata"
        controls={playing}
        className="h-auto w-full cursor-pointer rounded"
        ref={videoRef}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onLoadedMetadata={setVideoMetadata}
      >
        <source src={url} type={videoType(url)} />
      </video>
      {!playing ? (
        <button
          className="absolute left-1/2 top-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center border-0 bg-black bg-opacity-20 opacity-60 transition-opacity duration-200 ease-in-out hover:opacity-100"
          onClick={startPlayback}
        >
          <PlayIcon className="h-16 w-16 fill-current text-white" aria-label="play" />
        </button>
      ) : null}
      {!playing && metadata?.duration ? (
        <span className="absolute bottom-4 left-5 rounded bg-cadet-blue-500 px-1.25 py-0.5 text-2xs font-semibold leading-4 tracking-normal text-white">
          {formatDuration(metadata.duration, { units: 's' })}
        </span>
      ) : null}
    </div>
  );
}
