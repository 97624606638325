import { FEATURES, CAPABILITY_REASONS, PERMISSION_NOT_ALLOWED, ALLOWED } from '/src/features/_global/constants';

export const SNAPSHOTS_PERMISSIONS = {
  [FEATURES.SNAPSHOTS]: ({ capabilities }) => ({
    navigate: capabilities?.snapshots.permissions.nav,
    view: capabilities?.snapshots.permissions.view,
    edit: capabilities?.snapshots.permissions.edit,
    create:
      capabilities?.snapshots.permissions.create.allowed ||
      capabilities?.snapshots.permissions.create.reason === CAPABILITY_REASONS.QUOTA
        ? ALLOWED
        : capabilities?.snapshots.permissions.create,
    createMore: capabilities?.snapshots.permissions.create,
  }),
  Snapshot: ({ entity: snapshot = null }) => {
    return {
      duplicate: snapshot?.permissions.canDuplicate ? ALLOWED : PERMISSION_NOT_ALLOWED,
      edit: snapshot?.permissions.canEdit ? ALLOWED : PERMISSION_NOT_ALLOWED,
      editExpiration: snapshot?.permissions.canEditExpiration ? ALLOWED : PERMISSION_NOT_ALLOWED,
      editStartDate: snapshot?.permissions.canEditStartDate ? ALLOWED : PERMISSION_NOT_ALLOWED,
      export: snapshot?.permissions.canExport ? ALLOWED : PERMISSION_NOT_ALLOWED,
      pause: snapshot?.permissions.canPause ? ALLOWED : PERMISSION_NOT_ALLOWED,
      refresh: snapshot?.permissions.canRefresh ? ALLOWED : PERMISSION_NOT_ALLOWED,
      resume: snapshot?.permissions.canResume ? ALLOWED : PERMISSION_NOT_ALLOWED,
      setReoccurring: snapshot?.permissions.canSetReoccurring ? ALLOWED : PERMISSION_NOT_ALLOWED,
      unsetReoccurring: snapshot?.permissions.canUnsetReoccurring ? ALLOWED : PERMISSION_NOT_ALLOWED,
      view: snapshot?.permissions.canView ? ALLOWED : PERMISSION_NOT_ALLOWED,
    };
  },
};
