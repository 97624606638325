import { useNavigate } from 'react-router-dom';
import { Tooltip, Button, IconButton } from '@crazyegginc/hatch';

import { useMetric } from '/src/hooks';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import { ReactComponent as UpgradeIcon } from '@crazyegginc/hatch/dist/images/icon-rocket-outline.svg';

export function NavUpgradeButton({ expanded = true, textContent, isUpgrade = true, cta_id = 'navbar' }) {
  const navigate = useNavigate();
  const trackMetric = useMetric();

  return expanded ? (
    <Button
      variant="secondary"
      className="!h-10 w-[150px] justify-center"
      onMouseDown={() => {
        if (isUpgrade) {
          trackMetric('upgrade_cta_click', cta_id);
        }
      }}
      onClick={() => {
        navigate(MANAGE_SUBSCRIPTION_PATH);
      }}
      style={{ outlineColor: 'white' }}
    >
      {textContent}
    </Button>
  ) : (
    <Tooltip placement="right" tooltipContent={textContent}>
      <IconButton
        className="h-10 w-10 rounded border-solitude-500 bg-solitude-500 text-dodger-blue-500 hover:border-dodger-blue-500"
        icon={<UpgradeIcon className="h-5 w-5 fill-current " />}
        label={`${textContent} Button`}
        theme="dark"
        onMouseDown={() => {
          if (isUpgrade) {
            trackMetric('upgrade_cta_click', cta_id);
          }
        }}
        onClick={() => {
          navigate(MANAGE_SUBSCRIPTION_PATH);
        }}
      />
    </Tooltip>
  );
}
