import { Tooltip } from '@crazyegginc/hatch';
import { MARKDOWN_TYPES } from '/src/features/addons/constants';

export function MarkdownTooltip({ markdownType = MARKDOWN_TYPES.BLOCK }) {
  return (
    <>
      <Tooltip
        style={{ padding: 0 }}
        interactive={true}
        tooltipContent={<TooltipContent markdownType={markdownType} />}
      >
        <span className="cursor-pointer text-dodger-blue-500">more formatting</span>
      </Tooltip>
      <span>*italic*</span>
      <span>**bold**</span>
    </>
  );
}

function TooltipContent({ markdownType }) {
  return (
    <div className="flex w-80 flex-col divide-y divide-dashed divide-white/20 py-2 text-left">
      <TooltipRow>
        <TooltipRowItem>**Bold**</TooltipRowItem>
        <TooltipRowItem>
          <span className="font-bold">Bold</span>
        </TooltipRowItem>
      </TooltipRow>
      <TooltipRow>
        <TooltipRowItem>*Italic*</TooltipRowItem>
        <TooltipRowItem>
          <span className="italic">Italic</span>
        </TooltipRowItem>
      </TooltipRow>
      {markdownType !== MARKDOWN_TYPES.INLINE_NO_IMG && (
        <TooltipRow>
          <TooltipRowItem>[Link](http://foo.baz)</TooltipRowItem>
          <TooltipRowItem>
            <a href="http://foo.baz" className="pointer-events-none text-dodger-blue-500">
              Link
            </a>
          </TooltipRowItem>
        </TooltipRow>
      )}
      {markdownType === MARKDOWN_TYPES.BLOCK && (
        <>
          <TooltipRow>
            <TooltipRowItem>* Unordered list item</TooltipRowItem>
            <TooltipRowItem>
              <ul className="list-inside list-disc">
                <li>Unordered list item</li>
              </ul>
            </TooltipRowItem>
          </TooltipRow>
          <TooltipRow>
            <TooltipRowItem>
              1. Ordered list
              <br />
              2. Ordered list
            </TooltipRowItem>
            <TooltipRowItem>
              <ol className="list-inside list-decimal">
                <li>Ordered list</li>
                <li>Ordered list</li>
              </ol>
            </TooltipRowItem>
          </TooltipRow>
          <TooltipRow>
            <TooltipRowItem>`inline code`</TooltipRowItem>
            <TooltipRowItem>
              <code className="mx-0.5 rounded bg-[#46484a] p-0.5 text-2xs">inline code</code>
            </TooltipRowItem>
          </TooltipRow>
          <TooltipRow>
            <TooltipRowItem>```code block```</TooltipRowItem>
            <TooltipRowItem>
              <pre className="rounded bg-[#46484a] p-0.5 text-2xs">
                <code>code block</code>
              </pre>
            </TooltipRowItem>
          </TooltipRow>
          <TooltipRow>
            <TooltipRowItem>
              Add two backslashes
              <br />
              \\
              <br />
              if you want a blank line
            </TooltipRowItem>
            <TooltipRowItem>
              Add two backslashes
              <br />
              <br />
              if you want a blank line
            </TooltipRowItem>
          </TooltipRow>
        </>
      )}
    </div>
  );
}

function TooltipRow({ children }) {
  return <div className="flex items-center px-2.5 py-1.25">{children}</div>;
}

function TooltipRowItem({ children }) {
  return <div className="w-1/2 flex-shrink-0 flex-grow-0">{children}</div>;
}
