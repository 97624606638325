import { useContext, useMemo } from 'react';
import { useActor } from '@xstate/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { Spinner } from '@crazyegginc/hatch';

import { useMutation, useModal, useNotifications } from '/src/hooks';
// import { GoalsWizard } from '/src/features/goals/components/GoalsWizard';
import { GoalSelector } from '/src/features/goals/components/GoalSelector';

import { goalDeleteMutation } from '/src/features/goals/mutations';
import { goalListQuery } from '/src/features/goals/queries';

export const abTestGoalSchema = yup.object().shape({
  goalId: yup.number().required(),
});

export function AbTestGoalSelectPage({ context }) {
  const modal = useModal();
  const notifications = useNotifications();
  const { abTestService } = useContext(context);
  const [state] = useActor(abTestService);
  const { send } = abTestService;

  const {
    data: goalsData,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...goalListQuery({
      siteId: state.context.siteId,
    }),
    networkMode: 'always',
    enabled: !!state.context.siteId,
  });

  const { isLoading: deletingGoal, mutate: mutateDeleteGoal } = useMutation(goalDeleteMutation);

  const goals = useMemo(
    () => (goalsData?.pages ?? []).reduce((acc, page) => [...acc, ...(page?.goalList?.list ?? [])], []) ?? [],
    [goalsData],
  );

  // useEffect(() => {
  //   if (isFetchedAfterMount && goalsData && goals.length === 0 && !state.context.goal) {
  //     send({ type: 'FIRST_GOAL' });
  //   }
  // }, [isFetchedAfterMount, goals, goalsData, send, state.context.goal]);

  return (
    <div className="mx-auto flex w-[740px] flex-col space-y-10">
      <h1 className="text-header-0 m-0 mt-14 text-center">What&#39;s your goal for this test?</h1>

      {isFetching && !goals.length ? (
        <div className="mt-16 flex w-full items-center justify-center">
          <Spinner />
          <div className="ml-2.5">Loading...</div>
        </div>
      ) : (
        <GoalSelector
          draft={state.context.goal}
          fetching={isFetching}
          hasNextPage={hasNextPage}
          loadNextPage={fetchNextPage}
          selected={state.context.payload.goalId ?? null}
          onSelect={(value) => send({ type: 'SELECT', value })}
          goals={goals}
          onCreate={() => {
            send({ type: 'NEW' });
          }}
          onEdit={(goal) => {
            send({ type: 'EDIT', goal });
          }}
          deleting={deletingGoal}
          onDelete={(goalId) => {
            mutateDeleteGoal(
              {
                id: Number(goalId),
              },
              {
                onSuccess: () => {
                  notifications.success({ content: 'Goal deleted successfully.', timeout: 3000 });
                  modal.close();
                },
                onError: (error) => {
                  notifications.error({ content: 'Goal delete failed.', timeout: 3000, context: { error } });
                },
              },
            );
          }}
          onClearDraft={() => {
            send({ type: 'CLEAR_GOAL' });
          }}
        />
      )}
    </div>
  );
}
