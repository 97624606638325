/* Number of recording rows per page in a group */
export const RECORDINGS_PER_PAGE = 10;

/* GQL API version for recordings filter to use in tests and generated links*/
export const RECORDINGS_FILTER_VERSION = 2;

export const GROUP_BY_TYPES = {
  FIRST: 'FIRST',
  LAST: 'LAST',
  LIST_VIEW: 'LIST_VIEW',
};

export const PLAYLIST_TYPES = {
  RECOMMENDED: 'RECOMMENDED',
  USER: 'USER',
};

export const RECORDINGS_TABLE_ORDER = {
  BROWSER: 'BROWSER',
  COUNTRY: 'COUNTRY',
  DEVICE: 'DEVICE',
  DURATION: 'DURATION',
  FINAL_VALUE: 'FINAL_VALUE',
  FIRST_PAGE: 'FIRST_PAGE',
  LAST_PAGE: 'LAST_PAGE',
  MAX_VALUE: 'MAX_VALUE',
  RECORDED_AT: 'RECORDED_AT',
  VISITED_PAGES_COUNT: 'VISITED_PAGES_COUNT',
  VISITOR_ID: 'VISITOR_ID',
};

export const RECORDINGS_FILTER_ACTIONS = {
  ADD_CRITERIA: 'ADD_CRITERIA',
  REMOVE_CRITERIA: 'REMOVE_CRITERIA',
  CHANGE_VALUE: 'CHANGE_VALUE',
  CHANGE_COMPARISON: 'CHANGE_COMPARISON',
  CHAIN_CONDITION: 'CHAIN_CONDITION',
  REMOVE_CONDITION: 'REMOVE_CONDITION',
  EDIT_FILTERS: 'EDIT_FILTERS',
  APPLY_FILTERS: 'APPLY_FILTERS',
  CANCEL_EDIT: 'CANCEL_EDIT',
  SET_ERRORS: 'SET_ERRORS',
  DISMISS_DUPLICATE_TOOLTIP: 'DISMISS_DUPLICATE_TOOLTIP',
  APPLY_CLICKED_TAG: 'APPLY_CLICKED_TAG',
  SELECT_PLAYLIST: 'SELECT_PLAYLIST',
  INITIAL_LOAD_COMPLETE: 'INITIAL_LOAD_COMPLETE',
  REFRESH_DEFINITION: 'REFRESH_DEFINITION',
  SET_ORDER_FIELD: 'SET_ORDER_FIELD',
  SET_ORDER_SORT: 'SET_ORDER_SORT',
  SET_FILTERS_VERSION: 'SET_FILTERS_VERSION',
  SET_DEFINITIONS: 'SET_DEFINITIONS',
  SET_GROUP_BY: 'SET_GROUP_BY',
  SELECT_GROUP: 'SELECT_GROUP',
  SET_PAGE: 'SET_PAGE',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  RESET: 'RESET',
};

export const PLAYER_EVENTS = {
  PAGE: 'page',
  MOUSEDOWN: 'mousedown',
  SCROLL: 'scroll',
  BACKGROUNDED: 'backgrounded',
  PAGE_LOAD: 'page_load',
  RESIZE: 'resize',
  VISIT: 'visit',
  END: 'end',
  ERROR_EVENT: 'error',
  TAB_SWITCH: 'tab_switch',
  FORM: 'form',
  ECOMMERCE: 'ecommerce',
  SURVEY_RESPONSE: 'survey_response',
  GOAL_CONVERSION: 'goal_conversion',
  QUICK_BACK: 'quick_back',
  NAVIGATED_BACK: 'navigated_back',
};

export const PLAYER_END_EVENT_REASONS = {
  INACTIVITY: 'inactivity',
  CLOSED: 'closed',
};

export const PLAYER_FORM_SUBEVENTS = {
  FORM_SUBMIT: 'submit',
  FORM_RESUBMIT: 'resubmit',
  FORM_ABANDON: 'abandon',
  FORM_SIGNUP: 'signup',
  FORM_LOGIN: 'login',
  FORM_EMAIL: 'email',
  FORM_SEARCH: 'search',
};

export const PLAYER_ECOMMERCE_SUBEVENTS = {
  ECOMMERCE_ADD_TO_CART: 'add_to_cart',
  ECOMMERCE_CHECKOUT_START: 'checkout_start',
  ECOMMERCE_CHECKOUT_COMPLETE: 'checkout_complete',
};
