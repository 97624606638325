import { formatDistanceStrict, fromUnixTime } from 'date-fns';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { formatDate } from '/src/utils/date';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import {
  abTestTypes,
  abTestVariantTypes,
  AB_TEST_STATUSES,
  VARIANT_STATUSES,
  MOBILE_OPTIONS,
  DEVICE_WIDTHS,
} from '/src/features/ab-testing/constants';
import { convertKeysToCamelCase } from '/src/utils/string';
import { createEmptyVariant, parseExistingVariant } from './machines/variant-editor';
import { GOAL_PURPOSES } from '/src/features/goals/constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

import { isNumber } from '/src/utils';

export function generateVariantEditorPatches({ variants, autoReweight = true }) {
  return variants
    .map((variant) => {
      if (variant.type === 'control') {
        return {
          id: isNumber(variant.id) ? variant.id : undefined,
          type: abTestVariantTypes.CONTROL,
          editorPatch: null,
          weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
          status: undefined,
        };
      }
      return {
        id: isNumber(variant.id) ? variant.id : undefined,
        type: abTestVariantTypes.VARIANT,
        editorPatch: JSON.stringify(variant.editorPatch),
        weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
        status: undefined,
      };
    })
    .filter(Boolean);
}

export function generateSplitVariantPayload({ variants, autoReweight = true }) {
  return variants
    .map((variant) => {
      if (variant.type === 'control') {
        return {
          id: isNaN(variant.id) ? undefined : variant.id,
          type: abTestVariantTypes.CONTROL,
          redirectUrl: null,
          weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
          status: undefined,
        };
      }
      return {
        id: isNaN(variant.id) ? undefined : variant.id,
        type: abTestVariantTypes.VARIANT,
        redirectUrl: variant.redirectUrl,
        weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
        status: undefined,
      };
    })
    .filter(Boolean);
}

export function generateVariantPayload({ variants, type, autoReweight = true }) {
  const nonPristineVariants = variants.filter((v) => !['DELETED', 'PRISTINE'].includes(v.status));
  const activeVariants = variants.filter((v) => v.status !== 'DELETED');
  const processedType = type.toUpperCase();
  if (processedType === abTestTypes.SPLIT) {
    return generateSplitVariantPayload({ variants: activeVariants, autoReweight });
  } else if (processedType === abTestTypes.PATCH) {
    return generateVariantEditorPatches({ variants: nonPristineVariants, autoReweight });
  }
  return null;
}

export function abTestDuration(abTest) {
  if (!abTest) return null;

  if (abTest.status === AB_TEST_STATUSES.DRAFT) {
    return {
      value: 'Not yet launched.',
      label: 'Duration',
    };
  }

  if (abTest.duration) {
    return {
      value: formatDistanceStrict(0, fromUnixTime(abTest.duration)),
      label: 'Duration',
    };
  }

  if (abTest.publishedAt) {
    return {
      value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.publishedAt)),
      label: 'Duration',
    };
  }

  return {
    value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.createdAt)),
    label: 'Duration',
  };
}

export function abTestDurationV2(abTest) {
  if (!abTest) return null;

  if (abTest.status === AB_TEST_STATUSES.DRAFT) {
    return {
      value: 'Not yet launched.',
      label: 'Run time',
    };
  }

  if (abTest.duration) {
    return {
      value: formatDistanceStrict(0, fromUnixTime(abTest.duration)),
      label: 'Run time',
    };
  }

  if (abTest.publishedAt) {
    return {
      value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.publishedAt)),
      label: 'Run time',
    };
  }

  return {
    value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.createdAt)),
    label: 'Run time',
  };
}

export function abTestCreatedBy(abTest) {
  if (!abTest) return null;

  if (abTest.createdAt) {
    return {
      label: 'created',
      value: formatDate(abTest.createdAt),
      author: abTest.createdByUserName,
    };
  }
}

export function calcTotalWeight(variants) {
  return variants.reduce(
    (acc, variant) => acc + parseFloat(variant.weight && variant.weight !== '' ? variant.weight : 0),
    0,
  );
}

export function balanceWeights(variants, force) {
  const activeVariants = variants.filter(
    (variant) => variant.status !== VARIANT_STATUSES.DELETED && !variant.isRetired,
  );

  const sum = calcTotalWeight(activeVariants);

  const variantWeights = activeVariants.map((variant) => parseFloat(variant?.weight ?? 0));

  if (Math.abs(sum - 100) < 1 || force) {
    const diff = 100 - sum;

    const adjustment = diff / activeVariants.length;

    const adjustedWeights = variantWeights.map((weight) => parseFloat((weight + adjustment).toFixed(1)));

    const adjustedSum = adjustedWeights.reduce((acc, weight) => acc + weight, 0);

    if (adjustedSum !== 100) {
      const lastIndex = adjustedWeights.length - 1;
      adjustedWeights[lastIndex] = parseFloat((adjustedWeights[lastIndex] + (100 - adjustedSum)).toFixed(1));

      return {
        total: 100,
        weights: adjustedWeights,
        variants: activeVariants.map((v, i) => ({ ...v, weight: adjustedWeights[i] })),
      };
    }

    return {
      total: adjustedSum,
      weights: adjustedWeights,
      variants: activeVariants.map((v, i) => ({ ...v, weight: adjustedWeights[i] })),
    };
  } else {
    return { total: sum, weights: variantWeights, variants: activeVariants };
  }
}

export function convertDraftToPayload(id, draft, storedVariants) {
  const payload = convertKeysToCamelCase(JSON.parse(draft));
  const type = payload.type.toUpperCase();

  return {
    ...payload,
    id,
    devices: payload.devices.map((d) => d.toUpperCase()),
    type,
    variants: storedVariants.map((v) => parseExistingVariant(v, type)),
    goal: payload.goal
      ? {
          ...payload.goal,
          purpose: payload.goal?.purpose?.toUpperCase?.() ?? GOAL_PURPOSES.MORE_PAGE_VIEWS,
          triggers: payload.goal.triggers.map((t) => ({
            ...t,
            uiMode: t.uiMode.toUpperCase(),
          })),
        }
      : undefined,
  };
}

export function getInitialVariantStatus(variant) {
  if (variant.type.toUpperCase() === 'CONTROL') {
    return 'STATIC';
  }
  if (variant.status) {
    return variant.status;
  }
  if (isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0) {
    return 'DIRTY'; // it is a draft or was before
  }
  return 'PRISTINE';
}

export function prepareVariantsForEdit(variants, { createBlank = true, nextPosition = null } = {}) {
  if (!variants) {
    return null;
  }
  const variantsOnly = variants.filter((v) => v.type === 'variant');
  const payloadVariants = variants.map((v) => {
    let parsedPatch;
    try {
      if (typeof v.editorPatch === 'string') {
        parsedPatch = v.editorPatch ? JSON.parse(v.editorPatch) : null;
      } else if (typeof v.editorPatch === 'object') {
        parsedPatch = v.editorPatch;
      }
    } catch {
      parsedPatch = {};
    }
    return {
      ...v,
      editorPatch: parsedPatch,
      status: getInitialVariantStatus(v),
    };
  });
  const newVariant = !createBlank || variantsOnly.length ? null : createEmptyVariant(nextPosition || variants.length);
  return [...payloadVariants, newVariant].filter(Boolean);
}

function goalToArgs(goal) {
  return {
    ...goal,
    purpose: goal.purpose.toUpperCase(),
    triggers: goal.triggers.map((t) => ({
      ...t,
      elementSelectorType: t.elementSelectorType?.toUpperCase?.(),
      trackingOption: t.trackingOption?.toUpperCase?.(),
      uiMode: t.uiMode.toUpperCase(),
    })),
  };
}

export function contextToArgs(context) {
  const { id, goal, variants, ...rest } = context.payload; // eslint-disable-line

  return {
    ...rest,
    siteId: context.siteId || context.payload.siteId,
    variants: generateVariantPayload({
      variants: context.variants || variants,
      type: context.payload.type,
      autoReweight: context.payload.autoReweight,
    }),
    goalId: isNumber(context.payload.goalId) ? context.payload.goalId : null,
    goal: isNumber(context.payload.goalId) ? undefined : context.goal ? goalToArgs(context.goal) : undefined,
  };
}

export function payloadToArgs(payload) {
  const { id, autoReweight, goalId, siteId, type, goal, variants, ...rest } = payload; // eslint-disable-line

  return {
    ...rest,
    siteId: payload.siteId,
    variants: generateVariantPayload({
      variants,
      type,
      autoReweight,
    }),
    goalId: goalId !== 'draft' ? goalId : null,
    goal: undefined,
  };
}

export async function syncVariants(
  id,
  context,
  { mutateAddVariants, mutateUpdateDraft, mutateDeleteVariant, mutateUpdateVariants },
) {
  const variants = context.variants || context.payload.variants;
  if (!variants) return null;

  const operations = {
    create: variants.filter((v) => isNaN(v.id) && v.status === 'DIRTY'),
    update: variants.filter((v) => isNumber(v.id) && v.status === 'DIRTY'),
    delete: variants.filter((v) => isNumber(v.id) && v.status === 'DELETED'),
  };

  const payload = context.payload;

  await Promise.all(
    [
      operations.create.length
        ? mutateAddVariants({
            id,
            variants: generateVariantPayload({
              variants: operations.create,
              type: payload.type,
              autoReweight: payload.autoReweight,
            }),
          })
        : null,
      operations.update.length
        ? mutateUpdateVariants({
            id,
            variants: generateVariantPayload({
              variants: operations.update,
              type: payload.type,
              autoReweight: payload.autoReweight,
            }),
          })
        : null,
      operations.delete.length
        ? operations.delete.map((v) => {
            return mutateDeleteVariant({
              id,
              variantId: v.id,
            });
          })
        : null,
      mutateUpdateDraft
        ? mutateUpdateDraft({
            id,
            args: contextToArgs(context),
          })
        : null,
    ]
      .filter(Boolean)
      .flat(),
  );
}

export const findDeviceWidth = (type) => {
  const isMobile = type === DEVICE_TYPES.PHONE;
  let deviceWidth;

  if (isMobile) {
    const deviceOption = MOBILE_OPTIONS.find((device) => device.id === 'appleXXS');
    deviceWidth = deviceOption.width;
  } else {
    deviceWidth = DEVICE_WIDTHS[type];
  }

  return parseInt(deviceWidth, 10);
};

export const findDeviceID = (type) => {
  switch (type) {
    case DEVICE_TYPES.PHONE:
      return 'appleXXS';
    default:
      return type;
  }
};

export function renderSelectedDevice(editor) {
  const isMobile = editor.device === DEVICE_TYPES.PHONE;

  if (isMobile) {
    const device = MOBILE_OPTIONS.find((device) => device.id === editor.deviceId);
    return device?.title ?? `${editor.screenWidth}px`;
  } else {
    return `${editor.screenWidth}px`;
  }
}

function conversionRateToHumanText(rate) {
  if (parseFloat(rate) > 0) {
    return <span className="text-lima-500">{rate}% better</span>;
  }
  return <span className="text-carnation-500">{rate}% worse</span>;
}

export function renderBestVariant(abTest) {
  if (!abTest.bestVariant) {
    return <span className="text-body-5">Not enough data</span>;
  }

  const { bestVariant } = abTest;
  const { results } = bestVariant;

  const significant = !!bestVariant.significance && bestVariant.significance >= 95;

  return (
    <span className="text-body-2 flex items-center space-x-2">
      <span>{bestVariant.variantName}</span>
      <Dot />
      <span className="font-semibold">{results.conversionRate}%</span>
      <RateDelta value={results.improvementFromControl} />
      <Dot />
      {!significant ? (
        <Tooltip
          tooltipContent={
            <div className="flex max-w-[250px] flex-col space-y-1 text-left">
              <span>
                {bestVariant.variantName.replace('#', '')} converted{' '}
                {conversionRateToHumanText((results.improvementFromControl || results.conversionRate).toFixed(1))} than
                the {bestVariant.type.toUpperCase() === 'CONTROL' ? 'variants' : 'control'}.
                {bestVariant.significance && (
                  <>
                    {' '}
                    We are {bestVariant.significance.toFixed(1)}% certain that the{' '}
                    {bestVariant.type.toUpperCase() === 'CONTROL' ? (
                      <>control has the best conversion rate.</>
                    ) : (
                      <>changes in {bestVariant.variantName.replace('#', '')} will improve your conversion rate.</>
                    )}
                  </>
                )}
              </span>
              <span>This test is not yet statistically significant.</span>
            </div>
          }
        >
          <div className="text-body-5 flex flex-col truncate border-b border-dashed border-b-cadet-blue-500">
            <span>Not yet significant</span>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          tooltipContent={
            <div className="max-w-[250px] text-left">
              {bestVariant.variantName.replace('#', '')} converted{' '}
              {conversionRateToHumanText((results.improvementFromControl || results.conversionRate).toFixed(1))} than
              the {bestVariant.type.toUpperCase() === 'CONTROL' ? 'variants' : 'control'}. We are{' '}
              <span className="text-lima-500">100%</span> certain that the{' '}
              {bestVariant.type.toUpperCase() === 'CONTROL' ? (
                <>control has the best conversion rate.</>
              ) : (
                <>changes in {bestVariant.variantName.replace('#', '')} will improve your conversion rate.</>
              )}
            </div>
          }
        >
          <div className="text-body-5 flex flex-col truncate border-b border-dashed border-b-cadet-blue-500">
            <span className="text-body-2 !font-semibold !text-lima-500">
              Significance reached{bestVariant.type !== 'CONTROL' ? '! 🎉' : '.'}
            </span>
          </div>
        </Tooltip>
      )}
    </span>
  );
}

function Dot() {
  return <span className="mx-2.5 inline-block h-1 w-1 rounded-full bg-dodger-blue-300" />;
}

export function RateDelta({ value }) {
  const isDown = value < 0;
  const isUp = value > 0;
  const isConstant = value === 0 || !value;

  if (isConstant) {
    return null;
  }

  const absValue = Math.abs(value);

  return (
    <span
      className={classNames('flex items-center space-x-[3px] text-carnation-500', {
        'text-carnation-500': isDown,
        'text-lima-500': isUp,
      })}
    >
      <ArrowIcon
        className={classNames('w-[9px] fill-current', {
          'rotate-90': isDown,
          '-rotate-90': isUp,
        })}
      />
      <span className="font-semibold">{absValue}%</span>
    </span>
  );
}
