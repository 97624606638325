import classNames from 'classnames';
import { SkeletonLine } from '@crazyegginc/hatch';

import { WebP } from '/src/components/WebP';
import { GoalTriggerSummary } from '/src/features/goals/utils';
import { GOAL_TRIGGERS_METADATA } from '/src/features/goals/constants';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

function goalType(goal) {
  if (!goal) return 'OTHER';
  if (goal.icon) {
    return goal.icon.toUpperCase();
  }
  if (goal.triggers && goal.triggers.length) {
    const firstTrigger = goal.triggers.at(0).trigger;

    if (firstTrigger.trim().length === 0) {
      return 'OTHER';
    }

    return goal.triggers.at(0).trigger;
  }
  return 'OTHER';
}

export function GoalCard({
  goal,
  pending = false,
  selected = false,
  onSelect = null,
  onEdit = null,
  onDelete = null,
  isDraft = false,
}) {
  return (
    <div
      role="button"
      aria-label={goal.name}
      tabIndex={0}
      className={classNames(
        'group relative flex w-full min-w-0 cursor-pointer rounded border bg-white px-4 py-5 transition-transform duration-200 ease-in-out',
        {
          'hover:scale-105 hover:shadow-md': !!onSelect,
          'scale-105 border-dodger-blue-500 shadow-md': selected,
          'border-mystic-500': !selected,
        },
      )}
      onClick={() => {
        if (!selected) {
          onSelect?.(goal);
        } else {
          onSelect?.(null);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect?.(goal);
        }
      }}
    >
      <div className="mr-5 flex w-[35px] items-center">
        <WebP
          className="w-[35px]"
          webp={GOAL_TRIGGERS_METADATA[goalType(goal)]?.webp}
          fallback={GOAL_TRIGGERS_METADATA[goalType(goal)]?.png}
          width="35"
          height="35"
          alt="goal"
        />
      </div>
      <div className="flex-1 flex-col items-start overflow-hidden text-left">
        <div className="mb-1 text-sm font-semibold">{goal.name.length ? goal.name : '[Unnamed goal]'}</div>

        <GoalTriggerSummary goal={goal} textOnly={!!onSelect} expandBehavior={onSelect ? 'info' : 'click'} />
      </div>
      <div className="w-[175px] self-end text-2xs text-lynch-500">
        <div className="flex justify-end">
          {!isDraft ? (
            <div className="self-end truncate leading-none">
              Used in <strong>{goal.abTestsCountActive || 0}</strong> tests
            </div>
          ) : null}
          <div
            className={classNames('invisible w-0 opacity-0 transition duration-200 ease-in-out', {
              'hover:transform group-hover:visible group-hover:flex group-hover:w-auto group-hover:opacity-100':
                pending || !!onSelect,
            })}
          >
            <div className="relative z-0 ml-4 self-end leading-none">
              {!isDraft ? (
                <div className="absolute -left-2 bottom-0 top-[2px] m-auto h-[2px] w-[2px] rounded-full bg-lynch-500"></div>
              ) : null}
              <button type="button" className="text-2xs text-dodger-blue-500 hover:underline" onClick={onEdit}>
                Edit
              </button>
            </div>
            <div className="relative z-0 ml-4 self-end leading-none">
              <div className="absolute -left-2 bottom-0 top-[2px] m-auto h-[2px] w-[2px] rounded-full bg-lynch-500"></div>
              {isDraft || pending || (goal?.permissions?.canDelete ?? false) ? (
                <button type="button" className="text-2xs text-carnation-500 hover:underline" onClick={onDelete}>
                  Delete
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {selected ? (
        <TickIcon
          className="absolute right-2.5 top-2.5 h-5 w-5 fill-current text-dodger-blue-500"
          aria-label="selected"
        />
      ) : null}
    </div>
  );
}

export function GoalRequired({ onClick = null }) {
  return (
    <div
      role="button"
      aria-label="Goal required"
      tabIndex={0}
      className="group relative flex w-full min-w-0 cursor-pointer rounded border border-carnation-500 bg-white px-4 py-5 transition-transform duration-200 ease-in-out"
      onClick={() => {
        onClick?.();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick?.();
        }
      }}
    >
      <div className="mr-5 flex w-[35px] items-center">
        <WebP
          className="w-[35px]"
          webp={GOAL_TRIGGERS_METADATA[goalType(null)]?.webp}
          fallback={GOAL_TRIGGERS_METADATA[goalType(null)]?.png}
          width="35"
          height="35"
          alt="goal"
        />
      </div>
      <div className="flex-1 flex-col items-start overflow-hidden text-left">
        <div className="mb-1 text-sm font-semibold text-carnation-500">No goal selected</div>
        <p className="text-body-4">Click to select a new Goal</p>
      </div>
    </div>
  );
}

export function SkeletonCard() {
  return (
    <div className="group relative flex w-full min-w-0 cursor-pointer rounded border border-mystic-500 bg-white px-4 py-5 transition-transform duration-200 ease-in-out">
      <div className="mr-5 flex w-[35px] items-start">
        <SkeletonLine className="!h-[35px] !rounded" width="100%" />
      </div>
      <div className="flex-1 flex-col items-start space-y-2 overflow-hidden text-left">
        <div className="mb-1 text-sm font-semibold">
          <SkeletonLine />
        </div>
        <div className="flex flex-col space-y-2">
          <SkeletonLine width="40%" />
          <SkeletonLine width="40%" />
        </div>
      </div>
      <div className="w-[175px] self-end text-2xs text-lynch-500">
        <div className="flex justify-end">
          <div className="self-end truncate leading-none">
            <SkeletonLine width="100px" />
          </div>
        </div>
      </div>
    </div>
  );
}
