import { useState } from 'react';
import { Button, TextArea } from '@crazyegginc/hatch';
import { Modal } from '/src/contexts/modal';

import { useModal, useMutation } from '/src/hooks';

import { abTestUpdateNotesMutation } from '/src/features/ab-testing/mutations';

export function NotesModal({ abTest, onSave = null }) {
  const modal = useModal();
  const [status, setStatus] = useState('idle');
  const [notes, setNotes] = useState(abTest.notes || '');

  const { mutateAsync: updateNotes } = useMutation(abTestUpdateNotesMutation);

  return (
    <Modal dialogClassName="w-[640px]">
      <Modal.Title className="mb-[20px]">Test Notes</Modal.Title>

      <TextArea
        rows={8}
        placeholder="Enter any notes you have about this test here..."
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />

      <Modal.Actions className="mt-[20px]">
        <Button
          onClick={async () => {
            try {
              setStatus('saving');
              await updateNotes({ id: abTest.id, notes });
              onSave?.();
              modal.close();
            } finally {
              setStatus('idle');
            }
          }}
          disabled={status === 'saving' || !notes || abTest.notes === notes}
        >
          Save notes
        </Button>
        <Button variant="cancel" onClick={() => modal.close()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
