import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { PlayButton } from '/src/components/PlayButton';
import { round } from '/src/utils/math';
import { AIBox } from '@crazyegginc/hatch';

import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';

export function RecordingsSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={RecordingsIcon} color="bg-wisteria-500" to="/recordings">
        <span>Recordings</span>
      </Section.Header>
      <Section.Body>
        <div className="mb-5 flex w-full items-stretch justify-center">
          <TrendMetric
            text="Watched"
            extraText={
              <div className="text-center text-base">{`(${
                data?.recordings.total ? round((data.recordings.watched / data.recordings.total) * 100, 1) : ''
              }% of total)`}</div>
            }
            value={data?.recordings.watched}
            className="max-w-[200px]"
            below={false}
            loading={loading}
          />
        </div>
        <AIBox
          id="recai"
          data={{
            text: (
              <>
                <div className="text-body-1 mb-2 flex items-center">We think you should definitely watch these:</div>
                <div className="mb-2 flex">
                  <PlayButton hashedId="abc" valid={true} sizeClass="h-6 w-6" />
                  <span className="ml-3 leading-tight">
                    This session has a high number of pages visited and events in a short duration, indicating the user
                    was quickly clicking/scrolling around but encountered issues. The tags confirm issues like
                    abandonment and rage clicks.
                  </span>
                </div>
                <div className="mb-2 flex ">
                  <PlayButton hashedId="abc" valid={true} sizeClass="h-6 w-6" />
                  <span className="ml-3">
                    This session has a very long duration and high number of clicks/scrolls, indicating the user spent a
                    lot of time interacting with the site. The tags show they encountered issues like abandonment and
                    rage clicks.
                  </span>
                </div>
                <div className="mb-2 flex ">
                  <PlayButton hashedId="abc" valid={true} sizeClass="h-6 w-6" />
                  <span className="ml-3">
                    This session has a high number of visits and events for only 2 pages visited. The tags show form
                    issues and many clicks/scrolls, indicating trouble with a specific form.
                  </span>
                </div>
              </>
            ),
          }}
        />
        <Section.SeeMore to="/recordings" />
      </Section.Body>
    </Section>
  );
}
