import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';
import { useSearchParams } from '/src/hooks';

import { ReactComponent as SortIcon } from '@crazyegginc/hatch/dist/images/icon-sort.svg';

export function AbTestSortOrder({ onSet }) {
  const [searchParams, setSearchParams] = useSearchParams();

  function getHumanizedFieldName(fieldName) {
    switch (fieldName) {
      case 'CREATED_AT':
        return 'created date';
      default:
        return fieldName.toLowerCase();
    }
  }

  return (
    <div className="flex items-center">
      <span className="mr-2.5 text-body-2">Sort by:</span>

      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as={Button}
              variant="secondary"
              size="lg"
              className={classNames('capitalize', { '!border-dodger-blue-500': open })}
            >
              {getHumanizedFieldName(searchParams.get('order') || 'CREATED_AT')}
              <Indicator type="dropdown" className=" ml-2.5" />
            </Popover.Button>

            <StyledPopoverPanel align="center">
              {({ close }) => (
                <>
                  <PopoverItem
                    autoWidth
                    onClick={() => {
                      const newValue = { order: 'CREATED_AT', sort: searchParams.get('sort') || 'ASC' };
                      setSearchParams(newValue);
                      onSet(newValue);
                      close();
                    }}
                  >
                    Created Date
                  </PopoverItem>
                  <PopoverItem
                    autoWidth
                    onClick={() => {
                      const newValue = { order: 'NAME', sort: searchParams.get('sort') || 'ASC' };
                      setSearchParams(newValue);
                      onSet(newValue);
                      close();
                    }}
                  >
                    Name
                  </PopoverItem>
                  <PopoverItem
                    autoWidth
                    onClick={() => {
                      const newValue = { order: 'VISITORS', sort: searchParams.get('sort') || 'ASC' };
                      setSearchParams(newValue);
                      onSet(newValue);
                      close();
                    }}
                  >
                    Visitors
                  </PopoverItem>
                </>
              )}
            </StyledPopoverPanel>
          </>
        )}
      </Popover>

      <Button
        variant="secondary"
        className="ml-[5px] w-[46px] !h-10"
        onClick={() => {
          const newValue = {
            sort: (searchParams.get('sort') || 'DESC') === 'DESC' ? 'ASC' : 'DESC',
            order: searchParams.get('order') || 'CREATED_AT',
          };
          setSearchParams(newValue);
          onSet(newValue);
        }}
      >
        <SortIcon
          className={classNames('h-4 w-4 fill-current', {
            'scale-y-[-1]': (searchParams.get('sort') || 'DESC') === 'DESC',
          })}
        />
      </Button>
    </div>
  );
}
