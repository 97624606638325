import { useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { useSelector } from '@xstate5/react';
import {
  Tooltip,
  Button,
  Popover,
  StyledPopoverPanel,
  PopoverItem,
  DeviceIcon,
  CloseButton,
  LoaderBalloon,
} from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { ConfirmLeaveWizardModal } from '/src/components/wizard/ConfirmLeaveWizardModal';
import { ProvideEditorPageModal } from '../components/modals/ProvideEditorPageModal';

import { ReactComponent as TriangleIcon } from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as InteractiveIcon } from '@crazyegginc/hatch/dist/images/icon-interactive-outline.svg';
import { ReactComponent as UndoIcon } from '@crazyegginc/hatch/dist/images/icon-undo.svg';
import { ReactComponent as RedoIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as PreviewIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-move-outline.svg';
import { ReactComponent as PopupIcon } from '@crazyegginc/hatch/dist/images/icon-popup.svg';
import { ReactComponent as HidePopupIcon } from '@crazyegginc/hatch/dist/images/icon-popup-hide.svg';

import { normalizeWithHttps } from '/src/utils/url';
import { getVariantColor, getMutations, openPreview, changeScreenWidth } from '/src/services/web-editor';
import { VariantEditor } from '/src/features/ab-testing/components/VariantEditor';
import { renderSelectedDevice } from '/src/features/ab-testing/utils';

import { DEVICE_TYPES } from '/src/features/_global/constants';
import { VARIANT_STATUSES, MOBILE_OPTIONS } from '/src/features/ab-testing/constants';

const SCREEN_WIDTHS = {
  [DEVICE_TYPES.DESKTOP]: [1280, 1366, 1440, 1600, 1920, 2560],
  [DEVICE_TYPES.TABLET]: [601, 768, 820, 962, 1024],
  [DEVICE_TYPES.PHONE]: [360, 375, 390, 414, 428],
};

const moveSettings = {
  0: { text: 'Reorder', value: 'reorder', index: 0 },
  1: { text: 'Move anywhere', value: 'move', index: 1 },
  2: { text: 'Auto', value: 'auto', index: 2 },
};

export function VariantEditorWizard({
  actorRef,
  backEnabled = true,
  nextText = 'Choose a goal',
  isEditingVariant = false,
  enableDraft = true,
}) {
  const state = useSelector(actorRef, (state) => state);
  const variants = useSelector(actorRef, (state) => state.context.variants);

  const isNextEnabled = state.can({ type: 'NEXT' });
  const isSaving = state.matches('saving');

  const { selectedVariant } = state.context;
  const isEditorMobile = state.context.editor.device === DEVICE_TYPES.PHONE;

  const modal = useModal();
  const variantSelectorRef = useRef(null);
  const interactiveModeRef = useRef(null);
  const nextButtonRef = useRef(null);

  const previewMutations = useCallback(async () => {
    const mutations = await getMutations(selectedVariant.id);
    openPreview(state.context.url, state.context.device.toLowerCase(), mutations, {
      editionId: selectedVariant.id,
      editorColor: getVariantColor(selectedVariant),
      screenWidth: state.context.editor.screenWidth,
    });
  }, [selectedVariant, state.context.device, state.context.url, state.context.editor.screenWidth]);

  const showConfirmModal = useCallback(
    ({ onConfirm = null }) => {
      modal.show(
        <ConfirmLeaveWizardModal
          onConfirm={() => {
            onConfirm?.();
            modal.close();
          }}
        />,
      );
    },
    [modal],
  );

  if (!state.context.editor.device) return null;

  return (
    <div className="flex h-full w-full flex-col">
      {state.context.matchingUrl && state.context.url && state.context.url.indexOf('*') < 0 ? (
        <VariantEditor
          service={actorRef}
          variant={selectedVariant}
          url={normalizeWithHttps(state.context.url)}
          event-name="page-interaction"
          className="w-full flex-1"
          device={state.context.editor.device.toLowerCase()}
          onChange={(editorPatch) => {
            actorRef.send({ type: 'SYNC_MUTATIONS', editorPatch });
          }}
          onUndoRedoChange={(newState) => {
            const key = Object.keys(newState)[0];
            actorRef.send({ type: 'SET_EDITOR', key, value: newState[key] });
          }}
          onLoaded={() => {
            actorRef.send({ type: 'LOAD_VARIANT' });
          }}
        />
      ) : (
        <>
          <div className="w-full flex-1" />
          <ProvideEditorPageModal
            onDismiss={() => actorRef.send({ type: 'BACK' })}
            onConfirm={(url) => actorRef.send({ type: 'SET_URL', url })}
          />
        </>
      )}

      <footer
        className="grid h-[62px] w-full grid-cols-5 items-center border-t-2 bg-white px-8"
        style={{ borderColor: getVariantColor(selectedVariant) }}
      >
        <div className="flex items-center space-x-2.5">
          {backEnabled ? (
            <Button variant="secondary" onClick={() => actorRef.send({ type: 'BACK' })}>
              <ArrowIcon className="mr-2.5 h-2.5 w-2.5 -rotate-90 fill-current" />
              Back
            </Button>
          ) : null}
          <Button
            className="underline"
            variant="cancel"
            onClick={() => {
              if (!enableDraft || !state.context.draft) {
                showConfirmModal({
                  onConfirm: () => {
                    actorRef.send({ type: 'CANCEL' });
                  },
                });
              } else {
                actorRef.send({ type: 'CANCEL' });
              }
            }}
          >
            {enableDraft && state.context.draft ? 'Save draft & close' : 'Cancel'}
          </Button>
        </div>

        <div className="col-span-3 flex items-center justify-center space-x-3">
          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  ref={variantSelectorRef}
                  as={Button}
                  variant="cancel"
                  size="lg"
                  style={{
                    color: getVariantColor(selectedVariant),
                    background: open && getVariantColor(selectedVariant),
                  }}
                  className={classNames('group !no-underline', {
                    'hover:bg-current': !open,
                  })}
                  data-testid="variant_selector_btn"
                >
                  <span
                    className={classNames('flex items-center justify-between', {
                      'text-white': open,
                    })}
                  >
                    {selectedVariant?.type?.toUpperCase?.() === 'CONTROL'
                      ? 'Control'
                      : selectedVariant?.position
                        ? `Variant ${selectedVariant?.position}`
                        : 'Control'}

                    <TriangleIcon
                      className={classNames('ml-3.5 h-2 w-2 fill-current', {
                        'rotate-180': !open,
                      })}
                    />
                  </span>
                </Popover.Button>

                <StyledPopoverPanel align="center" className="!bottom-full" data-testid="variants_popover">
                  <div style={{ maxHeight: '241px', overflowY: 'scroll' }}>
                    {variants
                      .map((variant, index) => {
                        if (variant.status === VARIANT_STATUSES.DELETED) {
                          return null;
                        }
                        if (variant.type.toUpperCase() === 'CONTROL') {
                          return (
                            <PopoverItem
                              className="whitespace-nowrap font-semibold"
                              onClick={() => {
                                actorRef.send({ type: 'SELECT_VARIANT', variant });
                                close();
                              }}
                              key={`Variant-Control`}
                            >
                              Control
                            </PopoverItem>
                          );
                        }
                        if (variant.isRetired) {
                          return null;
                        }
                        return (
                          <PopoverItem
                            className="group flex items-center whitespace-nowrap"
                            onClick={() => {
                              actorRef.send({ type: 'SELECT_VARIANT', variant });
                              close();
                            }}
                            key={`Variant-${index}`}
                          >
                            <span
                              className="flex-1 font-semibold"
                              style={{
                                color: getVariantColor(variant),
                              }}
                            >
                              Variant {variant.position}
                            </span>
                            {!isEditingVariant && variant.status !== 'STATIC' ? (
                              <div
                                tabIndex={0}
                                role="button"
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter') {
                                    actorRef.send({ type: 'REMOVE_VARIANT', value: variant.id });
                                    e.stopPropagation();
                                    close();
                                  }
                                }}
                                type="button"
                                onClick={(e) => {
                                  actorRef.send({ type: 'REMOVE_VARIANT', value: variant.id });
                                  e.stopPropagation();
                                  close();
                                }}
                                className="ml-0.5 hidden flex-shrink-0 px-0.5 text-cadet-blue-500 opacity-70 hover:text-carnation-500 hover:opacity-100 group-hover:block"
                              >
                                <TrashIcon className="h-3 w-3 fill-current" aria-label="delete this variant" />
                              </div>
                            ) : null}
                          </PopoverItem>
                        );
                      })
                      .filter(Boolean)}
                  </div>
                  {!isEditingVariant ? (
                    <PopoverItem
                      className="whitespace-nowrap"
                      onClick={() => {
                        actorRef.send({ type: 'ADD_VARIANT' });
                        close();
                      }}
                    >
                      Create new variant
                    </PopoverItem>
                  ) : null}
                </StyledPopoverPanel>
              </>
            )}
          </Popover>

          <span className="mx-5 h-[23px] w-px bg-mystic-500" />

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  as={Button}
                  variant="cancel"
                  size="lg"
                  className={open ? '!border-dodger-blue-500' : ''}
                >
                  <DeviceIcon device={state.context.editor.device} tooltip={false} />
                </Popover.Button>

                <StyledPopoverPanel align="center" className="!bottom-full">
                  <PopoverItem
                    // disabled={!checkUrlData?.abTestCheckUrl?.devices?.includes(DESKTOP)}
                    className="flex items-center space-x-2 whitespace-nowrap"
                    onClick={() => {
                      // setFieldValue('device', DESKTOP);
                      actorRef.send({ type: 'SET_DEVICE', value: DEVICE_TYPES.DESKTOP });
                      close();
                    }}
                  >
                    <DeviceIcon device={DEVICE_TYPES.DESKTOP} tooltip={false} />
                    <span>Desktop</span>
                  </PopoverItem>
                  <PopoverItem
                    // disabled={!checkUrlData?.abTestCheckUrl?.devices?.includes(TABLET)}
                    className="flex items-center space-x-2 whitespace-nowrap"
                    onClick={() => {
                      // setFieldValue('device', TABLET);
                      actorRef.send({ type: 'SET_DEVICE', value: DEVICE_TYPES.TABLET });
                      close();
                    }}
                  >
                    <DeviceIcon device={DEVICE_TYPES.TABLET} tooltip={false} />
                    <span>Tablet</span>
                  </PopoverItem>
                  <PopoverItem
                    // disabled={!checkUrlData?.abTestCheckUrl?.devices?.includes(PHONE)}
                    className="flex items-center space-x-2 whitespace-nowrap"
                    onClick={() => {
                      // setFieldValue('device', PHONE);
                      actorRef.send({ type: 'SET_DEVICE', value: DEVICE_TYPES.PHONE });
                      close();
                    }}
                  >
                    <DeviceIcon device={DEVICE_TYPES.PHONE} tooltip={false} />
                    <span>Mobile</span>
                  </PopoverItem>
                </StyledPopoverPanel>
              </>
            )}
          </Popover>

          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Tooltip tooltipContent="You can check how your edits look on different resolution by changing the width here.">
                  <Popover.Button
                    as={Button}
                    variant="cancel"
                    size="lg"
                    className={open ? '!border-dodger-blue-500' : ''}
                  >
                    {renderSelectedDevice(state.context.editor)}
                  </Popover.Button>
                </Tooltip>

                <StyledPopoverPanel
                  align="center"
                  className={classNames('!bottom-full', { '!w-[380px]': isEditorMobile })}
                >
                  {isEditorMobile
                    ? MOBILE_OPTIONS.map((device) => {
                        const { id, title, dimension, width } = device;
                        const isTitle = dimension === '';
                        const isSelected = id === state.context.editor.deviceId;

                        return (
                          <PopoverItem
                            // disabled={!checkUrlData?.abTestCheckUrl?.devices?.includes(DESKTOP)}
                            className={classNames('flex items-center space-x-2 whitespace-nowrap', {
                              'hover:!text-body-5 !cursor-not-allowed !font-bold hover:!bg-white': isTitle,
                              'hover:!font-medium': !isTitle,
                              '!cursor-not-allowed !bg-solitude-500 !font-medium !text-black-pearl-500': isSelected,
                            })}
                            key={id}
                            disabled={isTitle}
                            onClick={() => {
                              // setFieldValue('device', DESKTOP);
                              actorRef.send({ type: 'SET_SCREEN_WIDTH', value: width, id });
                              changeScreenWidth(width);
                              close();
                            }}
                          >
                            <div className="flex w-full justify-between">
                              <span>{title}</span>
                              <span>{dimension}</span>
                            </div>
                          </PopoverItem>
                        );
                      })
                    : SCREEN_WIDTHS[state.context.editor.device].map((width) => {
                        return (
                          <PopoverItem
                            // disabled={!checkUrlData?.abTestCheckUrl?.devices?.includes(DESKTOP)}
                            className="flex items-center space-x-2 whitespace-nowrap"
                            key={width}
                            onClick={() => {
                              // setFieldValue('device', DESKTOP);
                              actorRef.send({
                                type: 'SET_SCREEN_WIDTH',
                                value: width,
                                id: state.context.editor.device,
                              });
                              changeScreenWidth(width);
                              close();
                            }}
                          >
                            <span>{width}px</span>
                          </PopoverItem>
                        );
                      })}
                </StyledPopoverPanel>
              </>
            )}
          </Popover>

          <span className="mx-5 h-[23px] w-px bg-mystic-500" />

          <Tooltip tooltipContent="Undo last change">
            <Button
              onClick={() => actorRef.send({ type: 'UNDO_CHANGE' })}
              className="group !flex !w-[35px] !items-center !justify-center !p-0"
              disabled={selectedVariant.type !== 'variant' || !state.context.editor.undoEnabled}
              variant="cancel"
            >
              <UndoIcon className="h-4 w-4 fill-current text-dodger-blue-500  group-disabled:text-white" />
            </Button>
          </Tooltip>

          <Tooltip tooltipContent="Redo last change">
            <Button
              onClick={() => actorRef.send({ type: 'REDO_CHANGE' })}
              className="group !flex !w-[35px] !items-center !justify-center !p-0"
              disabled={selectedVariant.type !== 'variant' || !state.context.editor.redoEnabled}
              variant="cancel"
            >
              <RedoIcon className="h-4 w-4 fill-current text-dodger-blue-500  group-disabled:text-white" />
            </Button>
          </Tooltip>

          <Tooltip tooltipContent="Preview changes">
            <Button
              onClick={() => previewMutations()}
              className="group !flex !w-[35px] !items-center !justify-center !p-0"
              variant="cancel"
              disabled={selectedVariant.type === 'control'}
            >
              <PreviewIcon className="h-4 w-4 fill-current text-dodger-blue-500  group-disabled:text-white" />
            </Button>
          </Tooltip>

          <Tooltip tooltipContent="Interactive mode">
            <Button
              ref={interactiveModeRef}
              onClick={() => {
                actorRef.send({ type: 'TOGGLE_INTERACTIVE' });
              }}
              className="group !flex !w-[35px] !items-center !justify-center !p-0"
              variant="cancel"
              disabled={selectedVariant.type === 'control'}
            >
              <InteractiveIcon
                className={classNames('h-4 w-4 fill-current group-disabled:text-white', {
                  'text-dodger-blue-500': state.context.editor.interactive,
                })}
              />
            </Button>
          </Tooltip>

          <Tooltip tooltipContent="Move settings">
            <Popover className="relative">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    as={Button}
                    variant="cancel"
                    size="lg"
                    className="group !flex !h-[35px] !w-[35px] !items-center !justify-center !p-0"
                    disabled={selectedVariant.type === 'control'}
                  >
                    <MoveIcon
                      className={classNames('h-4 w-4 fill-current group-disabled:text-white', {
                        'text-dodger-blue-500': open,
                      })}
                      aria-label="move settings"
                    />
                  </Popover.Button>

                  <StyledPopoverPanel
                    align="center"
                    className="!bottom-full !min-w-[265px] !px-0 !py-0"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div className="flex w-full items-center justify-between bg-[#78ab00] px-4 py-2.5 text-white">
                      <span>Move settings</span>
                      <CloseButton
                        buttonClass="!relative !top-auto !right-auto !my-0 !mr-0"
                        iconClass="!text-white"
                        onClick={close}
                      />
                    </div>

                    <Tab.Group
                      defaultIndex={
                        Object.values(moveSettings).find(
                          (setting) => setting.value === state.context.editor.moveSetting,
                        ).index
                      }
                      onChange={(index) => {
                        actorRef.send({ type: 'SET_MOVE_SETTING', value: moveSettings[index].value });
                      }}
                    >
                      <div className="border-b border-dashed border-white-lilac-500 px-2.5 py-3.75">
                        <Tab.List className="flex">
                          {Object.values(moveSettings).map((setting) => (
                            <Tab
                              key={`setting:${setting.value}`}
                              className={({ selected }) =>
                                classNames(
                                  'text-header-link h-8 whitespace-nowrap border px-2.5 first:rounded-l last:rounded-r',
                                  {
                                    'border-dodger-blue-500 bg-solitude-500': selected,
                                    'border-solitude-500 hover:border-dodger-blue-500': !selected,
                                  },
                                )
                              }
                            >
                              {setting.text}
                            </Tab>
                          ))}
                        </Tab.List>
                      </div>
                      <Tab.Panels>
                        <div className="px-3.75 pb-2.5">
                          <Tab.Panel>
                            <div className="text-body-5">
                              <p className="py-1.25">Need to move elements around on the page?</p>
                              <p className="py-1.25">
                                With <span className="italic">Move anywhere</span>, you can move any element to test
                                different placements.
                              </p>
                              <p className="py-1.25">
                                <strong>How to move an element</strong>
                              </p>
                              <p className="py-1.25">Click and drag an element to move it around the page.</p>
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="text-body-5">
                              <p className="py-1.25">
                                The Editor allows you to reorder an element relative to other elements.
                              </p>
                              <p className="py-1.25">
                                For example, you can place the <span className="italic">About</span> tab to go before
                                the <span className="italic">Contact</span> tab.
                              </p>
                              <p className="py-1.25">
                                <strong>How to reorder elements</strong>
                              </p>
                              <p className="py-1.25">Click and drag the element to where you&apos;d like it to go.</p>
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="text-body-5">
                              <p className="py-1.25">
                                This <span className="italic">Auto</span> option is a combination of{' '}
                                <span className="italic">Move anywhere</span> and{' '}
                                <span className="italic">Reorder</span>.
                              </p>
                              <p className="py-1.25">It allows you to move any element around on your site.</p>
                              <p className="py-1.25">
                                If you select an element within an ordered list, The Editor will reorder it relative to
                                other elements.
                              </p>
                            </div>
                          </Tab.Panel>
                        </div>
                      </Tab.Panels>
                    </Tab.Group>
                  </StyledPopoverPanel>
                </>
              )}
            </Popover>
          </Tooltip>

          <Tooltip tooltipContent={`${state.context.editor.hidePopups ? 'Show' : 'Hide'} pop-ups`}>
            <Button
              onClick={() => {
                actorRef.send({ type: 'CHANGE_POPUPS_STATE' });
              }}
              className="group !flex !w-[35px] !items-center !justify-center !p-0"
              variant="cancel"
            >
              {state.context.editor.hidePopups ? (
                <PopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" />
              ) : (
                <HidePopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" />
              )}
            </Button>
          </Tooltip>
        </div>

        <div className="flex items-center justify-end space-x-3">
          <Button
            ref={nextButtonRef}
            onClick={() => {
              actorRef.send({ type: 'NEXT' });
            }}
            disabled={!isNextEnabled}
          >
            {isSaving ? 'Creating...' : nextText}
            <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
          </Button>
        </div>
      </footer>
    </div>
  );
}

export function NullEditorWizard() {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex h-full w-full items-center justify-center">
        <LoaderBalloon text="Saving..." />
      </div>
      <footer className="grid h-[62px] w-full grid-cols-2 items-center border-t-2 border-mystic-500 bg-white px-8">
        <div className="flex items-center space-x-2.5">
          <Button disabled className="underline" variant="cancel">
            Cancel
          </Button>
        </div>

        <div className="flex items-center justify-end space-x-3">
          <Button disabled>Creating...</Button>
        </div>
      </footer>
    </div>
  );
}
