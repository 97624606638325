import { Button, Checkbox, Input, Tooltip } from '@crazyegginc/hatch';
import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useActor } from '@xstate/react';

import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as TabletIcon } from '@crazyegginc/hatch/dist/images/icon-tablet-outline.svg';
import { ReactComponent as MobileIcon } from '@crazyegginc/hatch/dist/images/icon-mobile-outline.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as InteractiveIcon } from '@crazyegginc/hatch/dist/images/icon-interactive-outline.svg';
import { ReactComponent as ReloadIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as PopupIcon } from '@crazyegginc/hatch/dist/images/icon-popup.svg';
import { ReactComponent as HidePopupIcon } from '@crazyegginc/hatch/dist/images/icon-popup-hide.svg';

import { ElementSelector } from '/src/components/ElementSelector';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { normalizeUrl } from '/src/utils/url';

const { DESKTOP, TABLET, PHONE } = DEVICE_TYPES;

export function TriggerElementSelector({
  service,
  url,
  trigger,
  triggerIndex,
  mode = 'click',
  device = DESKTOP,
  isManual = false,
  errors = null,
  placeholder = 'button#cta.primary',
}) {
  const [state] = useActor(service);
  const [isInteractive, setIsInteractive] = useState(false);
  const [selectedDevice, selectDevice] = useState(() => device);
  const [count, setCount] = useState(0);
  const { send } = service;

  useEffect(() => {
    send({
      type: 'SET_INTERACTIVE',
      value: isInteractive,
    });
  }, [isInteractive, send]);

  const normalizedUrl = useMemo(() => normalizeUrl(url, { appendSlash: false }), [url]);
  const currentSelector = `${selectedDevice.toLowerCase()}Selector`;
  const selectedElement = trigger?.[currentSelector] ?? null;

  const isPhoneSelected = trigger?.phoneSelector && trigger.phoneSelector.trim() !== '';
  const isTabletSelected = trigger?.tabletSelector && trigger.tabletSelector.trim() !== '';
  const isDesktopSelected = trigger?.desktopSelector && trigger.desktopSelector.trim() !== '';

  const currentSelectorError =
    errors?.[currentSelector] && errors[currentSelector] !== 'required' ? errors[currentSelector] : null;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col rounded border border-mystic-500">
        <div className="border-b border-mystic-500 px-5 py-2.5">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-1">
              <span className="text-body-4 mr-[6px]">Device view:</span>
              <div className="flex items-center space-x-0.5">
                <Button
                  className={classNames('relative flex w-10 justify-center !rounded-br-none !rounded-tr-none !px-0', {
                    '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                      selectedDevice === DESKTOP,
                  })}
                  onClick={() => selectDevice(DESKTOP)}
                  variant="secondary"
                  disabled={selectedDevice === DESKTOP}
                >
                  <DesktopIcon className="h-4 w-4 fill-current" aria-label="desktop" />
                  {!isDesktopSelected ? <WarningSign /> : null}
                </Button>
                <Button
                  className={classNames('relative flex w-10 justify-center !rounded-none !px-0', {
                    '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                      selectedDevice === TABLET,
                  })}
                  onClick={() => selectDevice(TABLET)}
                  variant="secondary"
                  disabled={selectedDevice === TABLET}
                >
                  <TabletIcon className="h-4 w-4 fill-current" aria-label="tablet" />
                  {!isTabletSelected ? <WarningSign /> : null}
                </Button>
                <Button
                  className={classNames('relative flex w-10 justify-center !rounded-bl-none !rounded-tl-none !px-0', {
                    '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                      selectedDevice === PHONE,
                  })}
                  onClick={() => selectDevice(PHONE)}
                  variant="secondary"
                  disabled={selectedDevice === PHONE}
                >
                  <MobileIcon className="h-4 w-4 fill-current" aria-label="mobile" />
                  {!isPhoneSelected ? <WarningSign /> : null}
                </Button>
              </div>
              <div className="ml-2">
                <Checkbox
                  checked={trigger.syncSelectors}
                  id={`trigger_${triggerIndex}_sync_selectors`}
                  label={<span className="text-body-3">Apply selector to all devices</span>}
                  onChange={(e) => {
                    send({
                      type: 'SET_SYNC_SELECTORS',
                      index: triggerIndex,
                      value: e.target.checked,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex items-center space-x-1">
              <Tooltip tooltipContent="Interactive mode">
                <Button
                  onClick={() => {
                    setIsInteractive(!isInteractive);
                  }}
                  className={classNames('relative flex w-10 justify-center !px-0', {
                    '!border !border-dodger-blue-500 !bg-dodger-blue-500 text-white': isInteractive,
                  })}
                  variant="secondary"
                >
                  <InteractiveIcon className="h-4 w-4 fill-current" />
                </Button>
              </Tooltip>
              <Tooltip tooltipContent="Reload the page">
                <Button
                  onClick={() => {
                    setCount((count) => count + 1);
                  }}
                  className="relative flex w-10 justify-center !px-0"
                  variant="secondary"
                >
                  <ReloadIcon className="h-3 w-3 fill-current" />
                </Button>
              </Tooltip>
              <Tooltip tooltipContent={`${state.context.hidePopups ? 'Show' : 'Hide'} pop-ups`}>
                <Button
                  onClick={() => {
                    send({ type: 'CHANGE_POPUPS_STATE' });
                  }}
                  className="group !flex !w-[35px] !items-center !justify-center !p-0"
                  variant="cancel"
                >
                  {state.context.hidePopups ? (
                    <PopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" />
                  ) : (
                    <HidePopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" />
                  )}
                </Button>
              </Tooltip>
            </div>
          </div>
          {isManual ? (
            <div className="mt-2.5">
              <Input
                value={trigger[currentSelector] ?? ''}
                error={currentSelectorError}
                maxLength={10000}
                placeholder={`Enter CSS selector here (e.g.: ${placeholder})`}
                onChange={(e) =>
                  send({
                    type: 'SET_SELECTOR',
                    index: triggerIndex,
                    value: e.target.value,
                    device: selectedDevice,
                  })
                }
              />
            </div>
          ) : null}
        </div>
        <ElementSelector
          key={`${normalizedUrl}:${selectedDevice}:${count}`}
          service={service}
          url={normalizedUrl}
          device={selectedDevice.toLowerCase()}
          editorPath={isManual ? 'inspect' : mode}
          event-name="page-interaction"
          selected={selectedElement}
          isManual={isManual}
          onSelect={({ eventType, payload }) => {
            if (['clickSelected', 'formSelected'].includes(eventType)) {
              send({
                type: 'SET_SELECTOR',
                index: triggerIndex,
                value: payload,
                device: selectedDevice,
              });
            }
          }}
          onLoad={() => {
            setIsInteractive(false);
          }}
          className="aspect-[4/3]"
        />
      </div>
    </div>
  );
}

function WarningSign() {
  return (
    <div className="absolute right-[3px] top-[3px] flex h-[10px] w-[11px] items-center justify-center rounded-full">
      <div className="absolute left-px top-px h-2 w-[9px] rounded-full bg-white" />
      <WarningIcon className="z-10 h-[10px] w-[11px] fill-current text-radical-red-500" aria-label="contains error" />
    </div>
  );
}
