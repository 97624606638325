import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import { Tooltip, Button, Input } from '@crazyegginc/hatch';

import { usePermissions, useMetric, useAuthContext } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { SNAPSHOT_ACTIONS } from '/src/features/snapshots/constants.js';
import { MANAGE_SUBSCRIPTION_PATH, FEATURES } from '/src/features/_global/constants';

function AfterActionTooltip({ onDismiss = null, children, tooltipContent, timeout = null, skiddingPercent = '50' }) {
  return (
    <Tooltip
      onDismiss={onDismiss}
      placement="bottom"
      skiddingPercent={skiddingPercent}
      arrowSkiddingPercent="10"
      hover={false}
      tooltipContent={<div className="w-[260px] text-left">{tooltipContent}</div>}
      timeout={timeout}
    >
      {children}
    </Tooltip>
  );
}

const validationSchema = yup.object().shape({
  currentName: yup.string(),
  uniqueName: yup
    .string()
    .required('Please provide a name.')
    .when('currentName', (currentName, schema) =>
      schema.test('changed', 'Please change the name to be able to update it.', (value) => {
        return value !== currentName;
      }),
    ),
});

export function DuplicatedTooltip({ children, snapshot, fetching = false, onAction = null, onDismiss = null }) {
  return (
    <AfterActionTooltip
      onDismiss={onDismiss}
      skiddingPercent="15"
      tooltipContent={
        <div className="pl-2">
          <div className="text-header-5 mb-5 text-white">
            Success!
            <br /> Your Snapshot has been duplicated.
          </div>
          <div className="font-normal">
            <Formik
              initialValues={{ currentName: snapshot.name, uniqueName: snapshot.name }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onAction?.(values.uniqueName.trim());
              }}
            >
              {({ values, errors, touched, dirty, handleChange, handleBlur }) => (
                <Form>
                  <Input
                    name="uniqueName"
                    id="uniqueName"
                    label="Update Snapshot Name:"
                    value={values.uniqueName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.uniqueName ? errors.uniqueName : null}
                    disabled={fetching}
                    theme="dark"
                  />
                  <div className="mt-5 flex space-x-1.25">
                    <Button disabled={fetching || !dirty} theme="dark" type="submit">
                      {fetching ? 'Updating...' : 'Update'}
                    </Button>
                    <Button theme="dark" variant="cancel" component="a" href={`/snapshots/edit/${snapshot.id}`}>
                      Edit Settings
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    >
      {children}
    </AfterActionTooltip>
  );
}

export function RefreshedTooltip({ children, onDismiss = null }) {
  return (
    <AfterActionTooltip
      onDismiss={onDismiss}
      timeout={5000}
      skiddingPercent="15"
      tooltipContent={
        <>
          <div className="text-header-5 mb-5 text-white">Your image is updating</div>
          <div className="font-normal">We&apos;re in the process of updating your snapshot image.</div>
        </>
      }
    >
      {children}
    </AfterActionTooltip>
  );
}

export function StatusTooltips({ children, action, onDismiss = null }) {
  return (
    <>
      {action === SNAPSHOT_ACTIONS.PAUSED && (
        <AfterActionTooltip
          onDismiss={onDismiss}
          timeout={5000}
          tooltipContent={
            <>
              <div className="text-header-5 mb-5 text-white">Your Snapshot is now stopped</div>
              <div className="font-normal">Your Snapshot is stopped and you can resume tracking again at any time.</div>
            </>
          }
        >
          {children}
        </AfterActionTooltip>
      )}
      {action === SNAPSHOT_ACTIONS.RESUMED && (
        <AfterActionTooltip
          onDismiss={onDismiss}
          timeout={5000}
          tooltipContent={
            <>
              <div className="text-header-5 mb-5 text-white">Your Snapshot will resume tracking</div>
              <div className="font-normal">We will start tracking data for this Snapshot ASAP.</div>
            </>
          }
        >
          {children}
        </AfterActionTooltip>
      )}
    </>
  );
}

export function LimitWarningTooltip({ onDismiss = null, show, children }) {
  const { currentAccount, capabilities } = useAuthContext();
  const trackMetric = useMetric();

  const { current, limit } = capabilities.snapshots.quotas.totalSnapshots;
  const usage = Math.min(Math.ceil((current / limit) * 100), 100);
  const permissions = usePermissions();
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Tooltip
      onDismiss={onDismiss}
      hover={false}
      placement="bottom"
      arrowSkiddingPercent="80"
      skiddingPercent="70"
      interactive
      show={show}
      tooltipContent={
        <div className="mb-2.5 ml-2 mt-1 w-[250px] text-left">
          <div className="mb-5 text-md font-semibold">{usage}% of Snapshots used</div>
          <div className="text-xs font-normal leading-tight">
            You can&apos;t add more Snapshots right now. You&apos;ve used up all Snapshots available in your plan. We
            recommend adding more Snapshots to your account.
            {canManageSubscription ? (
              <div className="mt-5 flex">
                <Button
                  size="xs"
                  theme="dark"
                  component={Link}
                  onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_limit_warning_tooltip')}
                  to={MANAGE_SUBSCRIPTION_PATH}
                >
                  Upgrade Now
                </Button>
              </div>
            ) : (
              <>
                {' '}
                Please contact the account owner{' '}
                <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link !text-white !underline">
                  {currentAccount.ownerEmail}
                </a>
              </>
            )}
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}

export function ErrorTooltip({ children, snapshot, onClose, canHover = false }) {
  const [page, setPage] = useState(1);

  return (
    <Tooltip
      onDismiss={!canHover ? () => onClose() : null}
      placement="bottom"
      hover={canHover}
      interactive={true}
      tooltipContent={
        <div className={classNames('w-[360px] text-left', { 'pt-3.5': canHover })}>
          <h4 className="text-header-3 pb-5 text-center text-white">
            {page === 1 && 'Snapshot capture error'}
            {page === 2 && 'Option 1 — Check the page URL'}
            {page === 3 && 'Option 2 — Recapture the image'}
            {page === 4 && 'Option 3 — Contact Customer Support'}
          </h4>
          <div className="px-[15px]">
            <div className="h-[80px] text-center text-sm">
              {page === 1 && (
                <p className="h-full overflow-auto break-all px-3">
                  There was an error while capturing the image of this webpage: {snapshot.sourceUrl}
                  <br />
                  The page would not load for us.
                </p>
              )}
              {page === 2 && (
                <p>
                  Double-check that the page URL you entered is correct. If it is not, delete the snapshot and re-create
                  it.
                </p>
              )}
              {page === 3 && (
                <p>
                  Click on the gear symbol to the far right and choose “Refresh this Snapshot.” Wait 15 minutes for the
                  new image to appear.
                </p>
              )}
              {page === 4 && (
                <p>
                  <a href={SupportLinks.snapshots.error} className="text-link" target="_blank" rel="noreferrer">
                    Reach out to us
                  </a>{' '}
                  and provide us with this snapshot ID. We will help figure out what has happened.
                </p>
              )}
            </div>
            <div className="mt-5 flex h-[30px] items-center justify-between border-t border-mystic-500 py-2 text-sm">
              <div>{page} of 4</div>
              <div className="flex space-x-2.5">
                {page > 1 && (
                  <div>
                    <button
                      className="font-semibold text-off-white-500 hover:underline focus-visible:outline-white"
                      type="button"
                      onClick={() => setPage(page - 1)}
                    >
                      previous
                    </button>
                  </div>
                )}
                {page > 1 && page < 4 && <div>{' | '}</div>}
                {page < 4 && (
                  <div>
                    <button
                      type="button"
                      className="text-link focus-visible:outline-white"
                      onClick={() => setPage(page + 1)}
                    >
                      {page === 1 ? 'how do I fix this?' : 'what else can I try?'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
