import { useMemo, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { Tooltip, ReactTable, Button } from '@crazyegginc/hatch';

import { useHasFeatureFlag } from '/src/hooks';
import { uniqueErrorListQuery } from '/src/features/errors/queries';
import { useErrorsFilter } from '../../errors-filter-context';

import { getErrorsQueryParams } from '/src/utils/url';
import { NoErrorsFoundWall } from '../paywalls/NoErrorsFoundWall';
import { formatFullDateTime, getConvertedDateRange } from '/src/utils/date';
import { camelToSnake, snakeToCamel } from '/src/utils/string';

import { SORT_ORDER_TYPES, SPECIAL_DATE_RANGES } from '/src/features/_global/constants';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { generateErrorFilterUrl } from '../../utils';

import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function ErrorsList({ site, setPartsLoaded }) {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { queryParams } = useErrorsFilter();

  const convertedDateRange = getConvertedDateRange(
    isHighVolume
      ? {
          special: SPECIAL_DATE_RANGES.LAST_30_DAYS,
        }
      : queryParams.dateRange,
  );

  const { data, isFetching, hasNextPage, fetchNextPage, isInitialLoading } = useInfiniteQuery({
    ...uniqueErrorListQuery({
      siteId: site.id,
      limit: 50,
      field: queryParams.order.field,
      sort: queryParams.order.sort,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
      status: queryParams.status,
      search: isHighVolume ? undefined : queryParams.search,
    }),
  });

  useEffect(() => {
    if (data) {
      setPartsLoaded((x) => (x.list === false ? { ...x, list: true } : x));
    }
  }, [data, setPartsLoaded]);

  const errors = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.uniqueErrorList?.uniqueErrors ?? [])], []) ?? [],
    [data],
  );

  if (!isFetching && errors.length === 0) {
    return (
      <div className="mt-5 flex w-full items-center justify-center">
        <NoErrorsFoundWall status={queryParams.status} convertedDateRange={convertedDateRange} />
      </div>
    );
  }

  return (
    <>
      <div className="text-header-3 mb-3.75">Error list</div>
      <ErrorsTable
        errors={errors}
        fetching={isInitialLoading}
        hasNextPage={hasNextPage}
        loadNextPage={fetchNextPage}
        site={site}
      />
    </>
  );
}

function ErrorsTable({ errors, fetching, hasNextPage, loadNextPage, site }) {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { queryParams, setOrder, setSort } = useErrorsFilter();

  const columns = useMemo(() => {
    const convertedDateRange = getConvertedDateRange(queryParams.dateRange);
    return [
      {
        header: 'Error',
        accessorKey: 'fingerprint',
        size: 150,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function FingerprintCell({ row }) {
          return (
            <div className="flex min-w-0 flex-col">
              <Link
                to={{
                  pathname: `/errors/${row.original.fingerprintMd5}`,
                  search: `${getErrorsQueryParams({ site: site.name })}`,
                }}
                className="text-link truncate leading-5 underline"
              >
                {row.original.fingerprint}
              </Link>
              <div className="truncate">{row.original.lastErrorMessage}</div>
            </div>
          );
        },
      },
      {
        header: function OccurrencesHeader() {
          return (
            <div className="flex items-center text-center">
              Occurrences
              <div className="ml-1 inline-block">
                <Tooltip
                  tooltipContent={<div className="w-28">Errors are tracked for recorded visitor sessions only.</div>}
                >
                  <InfoIcon
                    className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                    aria-label="Info on occurrences"
                  />
                </Tooltip>
              </div>
            </div>
          );
        },
        accessorKey: 'count',
        size: 60,
        meta: {
          align: 'center',
        },
        cell: function OccurrencesCell({ row }) {
          return row.original.count.toLocaleString();
        },
      },
      {
        header: 'Last seen',
        accessorKey: 'lastSeen',
        size: 40,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function LastSeenCell({ row }) {
          return (
            <Tooltip tooltipContent={formatFullDateTime(row.original.lastSeen)}>
              {formatDistanceToNow(fromUnixTime(row.original.lastSeen), { addSuffix: true })}
            </Tooltip>
          );
        },
      },
      !isHighVolume
        ? {
            header: () => (
              <div className="text-center leading-[14px]">
                Pages <br />
                Affected
                <div className="relative top-0.5 ml-1 inline-block">
                  <Tooltip
                    tooltipContent={<div className="w-36">Unique number of pages the error was triggered on.</div>}
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                      aria-label="Info on pages affected"
                    />
                  </Tooltip>
                </div>
              </div>
            ),
            accessorKey: 'pagesAffected',
            size: 40,
            meta: {
              align: 'center',
              justify: 'center',
            },
            cell: function PagesAffectedCell({ row }) {
              return row.original.pagesAffected.toLocaleString();
            },
          }
        : null,
      !isHighVolume
        ? {
            header: () => (
              <div className="text-center leading-[14px]">
                Visitors <br />
                Affected
                <div className="relative top-0.5 ml-1 inline-block">
                  <Tooltip
                    tooltipContent={
                      <div className="w-36">% of visitors who triggered this error compared to total visitors.</div>
                    }
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                      aria-label="Info on visitors affected"
                    />
                  </Tooltip>
                </div>
              </div>
            ),
            accessorKey: 'visitorsAffectedRatio',
            size: 40,
            meta: {
              align: 'center',
              justify: 'center',
            },
            cell: function VisitorsAffectedCell({ row }) {
              const ratio = row.original.visitorsAffectedRatio;
              if (ratio > 0 && ratio < 0.01) return '< 1%';
              return `${Math.round(ratio * 100)}%`;
            },
          }
        : null,
      !isHighVolume
        ? {
            header: () => (
              <div className="text-center leading-[14px]">
                Bounce rate <br />
                ratio
                <div className="relative top-0.5 ml-1 inline-block">
                  <Tooltip
                    tooltipContent={<div className="w-36">% of visitors who bounce after triggering error.</div>}
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                      aria-label="Info on bounce rate"
                    />
                  </Tooltip>
                </div>
              </div>
            ),
            accessorKey: 'bounceRatio',
            size: 40,
            meta: {
              align: 'center',
              justify: 'center',
            },
            cell: function BounceRatioCell({ row }) {
              const ratio = row.original.bounceRatio;
              if (ratio > 0 && ratio < 0.01) return '< 1%';
              return `${Math.round(ratio * 100)}%`;
            },
          }
        : null,
      !isHighVolume
        ? {
            header: () => (
              <div className="text-center leading-[14px]">
                Rage click <br />
                ratio
                <div className="relative top-0.5 ml-1 inline-block">
                  <Tooltip
                    tooltipContent={<div className="w-36">% of visitors who rage click after triggering error.</div>}
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                      aria-label="Info on rage click"
                    />
                  </Tooltip>
                </div>
              </div>
            ),
            accessorKey: 'rageClickRatio',
            size: 40,
            meta: {
              align: 'center',
              justify: 'center',
            },
            cell: function RageClickRatioCell({ row }) {
              const ratio = row.original.rageClickRatio;
              if (ratio > 0 && ratio < 0.01) return '< 1%';
              return `${Math.round(ratio * 100)}%`;
            },
          }
        : null,
      {
        header: "Rec's",
        size: 30,
        meta: {
          align: 'center',
          justify: 'center',
        },
        enableSorting: false,
        cell: function RecordingsCell({ row }) {
          return (
            <Link
              to={{
                pathname: '/recordings',
                search: `filters=${generateErrorFilterUrl(row.original.fingerprintMd5, convertedDateRange)}&site=${
                  site.name
                }&groupBy=${GROUP_BY_TYPES.LIST_VIEW}`,
              }}
              className="flex items-center text-malibu-500"
            >
              <RecordingIcon className="h-5 w-5 fill-current" aria-label="link to recordings with this error" />
            </Link>
          );
        },
      },
      {
        header: '',
        id: 'view',
        size: 25,
        meta: {
          align: 'center',
        },
        enableSorting: false,
        cell: function ViewCell({ row }) {
          return (
            <Button
              component={Link}
              variant="secondary"
              size="sm"
              className="w-[62px] justify-center"
              to={{
                pathname: `/errors/${row.original.fingerprintMd5}`,
                search: `${getErrorsQueryParams({ site: site.name })}`,
              }}
            >
              View
            </Button>
          );
        },
      },
    ].filter(Boolean);
  }, [site, queryParams.dateRange, isHighVolume]);

  const tableRef = useRef(null);

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(queryParams.order.field),
      desc: queryParams.order.sort === SORT_ORDER_TYPES.DESC,
    };
  }, [queryParams.order.field, queryParams.order.sort]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setOrder(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      fetching={fetching}
      ref={{ tableRef }}
      rowPadding={true}
      columns={columns}
      data={errors}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      useVirtualization={true}
      hasNextPage={hasNextPage}
      isNextPageLoading={fetching}
      loadNextPage={loadNextPage}
    />
  );
}
