import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';
import { AB_TESTS_CREATION_LINK } from '/src/features/ab-testing/constants';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function CreateAbTestButton() {
  const permissions = usePermissions();
  const canCreate = permissions.can('create', FEATURES.AB_TESTING).allowed;

  if (!canCreate) return null;

  return (
    <Button component={Link} size="xl" to={AB_TESTS_CREATION_LINK}>
      <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" /> Create New A/B Test
    </Button>
  );
}
