import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { LIVE_EVENT_NAMES } from '../constants';

import { ReactComponent as CartAddIcon } from '@crazyegginc/hatch/dist/images/icon-cart-add-filled.svg';
import { ReactComponent as CartCompleteIcon } from '@crazyegginc/hatch/dist/images/icon-cart-complete.svg';
import { ReactComponent as LoginIcon } from '@crazyegginc/hatch/dist/images/icon-login.svg';
import { ReactComponent as FormSubmitIcon } from '@crazyegginc/hatch/dist/images/icon-form-tick.svg';
import { ReactComponent as FormRedoIcon } from '@crazyegginc/hatch/dist/images/icon-form-redo.svg';
import { ReactComponent as FormAbandonIcon } from '@crazyegginc/hatch/dist/images/icon-form-abandoned.svg';
import { ReactComponent as UserAddIcon } from '@crazyegginc/hatch/dist/images/icon-user-add-outline.svg';
import { ReactComponent as ViewedPageIcon } from '@crazyegginc/hatch/dist/images/icon-viewed-page-outline.svg';
import { ReactComponent as UnknownUserIcon } from '@crazyegginc/hatch/dist/images/icon-user-unknown-outline.svg';
import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as RageIcon } from '@crazyegginc/hatch/dist/images/icon-face-rage-outline.svg';
import { ReactComponent as DeadIcon } from '@crazyegginc/hatch/dist/images/icon-block.svg';
import { ReactComponent as GoalIcon } from '@crazyegginc/hatch/dist/images/icon-trophy-filled.svg';

export function LiveEventIcon({ name, subType, tooltip }) {
  let Icon, color;
  switch (name) {
    case LIVE_EVENT_NAMES.ECOMMERCE_ADD_TO_CART:
      Icon = CartAddIcon;
      color = 'bg-[#28A314]';
      break;
    case LIVE_EVENT_NAMES.ECOMMERCE_CHECKOUT_COMPLETE:
      Icon = CartCompleteIcon;
      color = 'bg-[#28A314]';
      break;
    case LIVE_EVENT_NAMES.FORM_LOGIN:
      Icon = LoginIcon;
      color = 'bg-[#E32E9A]';
      break;
    case LIVE_EVENT_NAMES.FORM_SUBMIT:
      Icon = FormSubmitIcon;
      color = 'bg-[#E32E9A]';
      break;
    case LIVE_EVENT_NAMES.FORM_RESUBMIT:
      Icon = FormRedoIcon;
      color = 'bg-[#E32E9A]';
      break;
    case LIVE_EVENT_NAMES.FORM_ABANDON:
      Icon = FormAbandonIcon;
      color = 'bg-[#E32E9A]';
      break;
    case LIVE_EVENT_NAMES.FORM_SIGNUP:
      Icon = UserAddIcon;
      color = 'bg-[#E32E9A]';
      break;
    case LIVE_EVENT_NAMES.VISIT:
      Icon = ViewedPageIcon;
      color = 'bg-[#6565E0]';
      break;
    case LIVE_EVENT_NAMES.BASIC_VISIT:
      Icon = UnknownUserIcon;
      color = 'bg-cadet-blue-500';
      break;
    case LIVE_EVENT_NAMES.CLICK:
      if (subType === 'dead') {
        Icon = DeadIcon;
        color = 'bg-carnation-500';
      } else if (subType === 'rage') {
        Icon = RageIcon;
        color = 'bg-carnation-500';
      } else {
        Icon = ClickIcon;
        color = 'bg-dodger-blue-300';
      }
      break;
    case LIVE_EVENT_NAMES.ERROR:
      Icon = WarningIcon;
      color = 'bg-carnation-500';

      break;
    case LIVE_EVENT_NAMES.SURVEY_RESPONSE:
      Icon = SurveyIcon;
      color = 'bg-[#975CDE]';
      break;
    case LIVE_EVENT_NAMES.TAG:
      Icon = TagIcon;
      color = 'bg-[#1698A9]';
      break;
    case LIVE_EVENT_NAMES.GOAL_CONVERSION:
      Icon = GoalIcon;
      color = 'bg-dandelion-500';
      break;
  }

  return (
    <div className="absolute left-[-52px] top-1.5 flex h-[32px] w-[32px] items-center justify-center rounded-full bg-off-white-500">
      <Tooltip arrowSkiddingPercent={15} show={!!tooltip} tooltipContent={<div className="max-w-md">{tooltip}</div>}>
        <div className={classNames('flex h-7 w-7 items-center justify-center rounded-full', color)}>
          <Icon className="h-4 w-4 fill-current text-white" />
        </div>
      </Tooltip>
    </div>
  );
}
