import { Link } from 'react-router-dom';

import { MainTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function SurveyResponseEvent({ liveEvent, identifyEvents }) {
  const { surveyId } = liveEvent.attributes;

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <span className="whitespace-pre">responded to </span>
        <Link className="text-link" to={`/addons/${surveyId}`}>
          survey
        </Link>
      </MainTextRow>
    </div>
  );
}
