import { useLayoutEffect, useMemo, useCallback } from 'react';
import { useAtom, atom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { getOperationAST } from 'graphql';

import { useQueryParams } from '/src/hooks';
import { SITES_QUERY } from '/src/features/_global/queries';
import { ALL } from '/src/features/_global/constants';

export const selectedSiteAtom = atom(null);

export const SITE_FETCHING_STATES = {
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INIT: 'INIT',
};

export function useSite({
  persist = true,
  enableAllSites = false,
  onlySelectFromQueryParam = false,
  sitesQuery = SITES_QUERY,
  sitesQueryProps = {},
  dontSelectSite = false,
  onSelect = null,
} = {}) {
  const siteQueryParam = 'site';
  const allSitesKey = 'shell:allsites';
  const { set: setQueryParams, get: getQueryParams, remove: removeQueryParams } = useQueryParams();
  const [selectedSite, selectSiteAtom] = useAtom(selectedSiteAtom);

  const persistSite = useCallback((name) => {
    if (!name) return;
    window.localStorage.setItem('shell:site', name);
  }, []);

  const persistAllSitesSelection = useCallback((set) => {
    if (set) {
      window.localStorage.setItem(allSitesKey, 'true');
    } else {
      window.localStorage.removeItem(allSitesKey);
    }
  }, []);

  const hydrateSiteSelection = useCallback(() => {
    return window.localStorage.getItem('shell:site');
  }, []);

  const resetSite = useCallback(() => {
    removeQueryParams(siteQueryParam);
    selectSiteAtom(null);
  }, [removeQueryParams, selectSiteAtom]);

  const { variables, ...rest } = sitesQueryProps;
  const {
    isFetching,
    isLoading,
    data,
    error,
    refetch: reloadSites,
  } = useQuery({
    queryKey: ['sites', getOperationAST(sitesQuery).name.value, variables ? { ...variables } : null],
    meta: { query: sitesQuery },
    useErrorBoundary: false,
    ...rest,
  });

  const sitesState = useMemo(() => {
    if (isFetching === true) {
      return SITE_FETCHING_STATES.FETCHING;
    }
    if (data) {
      return SITE_FETCHING_STATES.SUCCESS;
    }
    if (error) {
      return SITE_FETCHING_STATES.ERROR;
    }
    return SITE_FETCHING_STATES.INIT;
  }, [data, error, isFetching]);

  const sites = useMemo(() => {
    if (data?.site) {
      return [{ ...data?.site }];
    }
    return structuredClone(
      (data?.sites || data?.sitesForRecordings || data?.sitesForSnapshots || data?.sitesForAddons) ?? [],
    );
  }, [data]);

  const selectSite = useCallback(
    (selected, userClick = false) => {
      let persistedSite;
      if (selected === ALL) {
        setQueryParams(siteQueryParam, ALL);
        if (persist) persistSite(ALL);
        persistAllSitesSelection(true);
      } else if (typeof selected === 'number') {
        const site = sites.find((s) => s.id === selected);
        if (site) {
          persistedSite = site;
          setQueryParams(siteQueryParam, site.name);
          if (persist) persistSite(site.name);
          if (userClick) persistAllSitesSelection(false);
        }
      } else if (typeof selected === 'object' && selected?.name) {
        setQueryParams(siteQueryParam, selected.name);
        if (persist) persistSite(selected.name);
        if (userClick) persistAllSitesSelection(false);
      } else if (typeof selected === 'string') {
        const site = sites.find((s) => s.name === selected);
        if (site) {
          persistedSite = site;
          setQueryParams(siteQueryParam, site.name);
          if (persist) persistSite(site.name);
          if (userClick) persistAllSitesSelection(false);
        }
      } else {
        removeQueryParams(siteQueryParam);
      }
      selectSiteAtom(persistedSite || selected);
      onSelect?.(persistedSite || selected);
    },
    [
      selectSiteAtom,
      setQueryParams,
      removeQueryParams,
      persistSite,
      persist,
      sites,
      persistAllSitesSelection,
      onSelect,
    ],
  );

  const groupedSites = useMemo(() => {
    if (!sites?.length) return { activeSites: [], inactiveSites: [] };

    return {
      activeSites: sites.filter((site) => site.active),
      inactiveSites: sites.filter((site) => !site.active),
    };
  }, [sites]);

  useLayoutEffect(() => {
    if (isFetching || dontSelectSite) return;

    const siteNameInQueryParam = getQueryParams(siteQueryParam);
    const allSitesPersisted = window.localStorage.getItem(allSitesKey);

    if (
      (sites.length && !selectedSite) ||
      (allSitesPersisted && enableAllSites && selectedSite !== ALL) ||
      (!sites.some((site) => site.name === selectedSite.name) &&
        ((selectedSite !== ALL && enableAllSites) || (selectedSite === ALL && (!enableAllSites || sites.length <= 1))))
    ) {
      const previousSelectedSiteName = siteNameInQueryParam || hydrateSiteSelection();
      if (onlySelectFromQueryParam) {
        const siteInQueryParam = siteNameInQueryParam && sites.find((site) => site.name === siteNameInQueryParam);
        if (siteNameInQueryParam === ALL && enableAllSites) {
          selectSite(ALL);
        } else if (siteInQueryParam) {
          selectSite(siteInQueryParam);
        }
      } else {
        const previousSelectedSite =
          previousSelectedSiteName && sites.find((site) => site.name === previousSelectedSiteName);
        if (sites.length === 1) {
          selectSite(sites[0]);
        } else if (enableAllSites && allSitesPersisted && selectedSite !== ALL) {
          selectSite(ALL);
        } else if (previousSelectedSite) {
          selectSite(previousSelectedSite);
        } else if (enableAllSites) {
          selectSite(ALL);
        } else if (groupedSites.activeSites.length > 0) {
          selectSite(groupedSites.activeSites[0]);
        } else {
          selectSite(sites?.[0]);
        }
      }
    } else {
      if (selectedSite === ALL) {
        setQueryParams(siteQueryParam, ALL);
      } else if (selectedSite?.name) {
        const site = sites.find((s) => s.name === selectedSite?.name);
        if (site && site.__typename !== selectedSite.__typename) {
          // re-select site if selectedSite came from a different query, as it might have different fields
          selectSite(site);
        } else {
          setQueryParams(siteQueryParam, selectedSite.name);
        }
      } else {
        removeQueryParams(siteQueryParam);
      }
    }
  }, [
    sites,
    groupedSites,
    dontSelectSite,
    isFetching,
    selectSite,
    selectedSite,
    enableAllSites,
    setQueryParams,
    getQueryParams,
    removeQueryParams,
    hydrateSiteSelection,
    onlySelectFromQueryParam,
  ]);

  return {
    sites,
    ...groupedSites,
    loadingSites: isLoading,
    sitesState,
    selectedSite,
    reloadSites,
    persistSite,
    selectSite,
    resetSite,
  };
}
