import { useEffect, useState } from 'react';
import { Button, TextArea } from '@crazyegginc/hatch';

import { useMutation, useWizard } from '/src/hooks';
import { sendUsFeedbackEmailMutation } from '/src/features/installation/mutations';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

const maxChars = 2000;

export function InstallHelpPage() {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const valid = message.length > 0;

  const { set: wizardSet, setNext: wizardSetNext, setNextEnabled: wizardSetNextEnabled } = useWizard();
  const sendEmail = useMutation(sendUsFeedbackEmailMutation);

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      nextEnabled: false,
      title: 'Contact support for help',
      currentStep: 2,
      totalSteps: 2,
    });
  }, [wizardSet]);

  useEffect(() => {
    wizardSetNextEnabled(false);
  }, [wizardSetNextEnabled]);

  function sendMessage() {
    if (valid) {
      sendEmail.mutate(
        {
          message,
        },
        {
          onSuccess: (data) => {
            if (data.sendUsFeedbackEmail?.success === true) {
              wizardSetNext('/', true);
              setSuccess(true);
            }
          },
        },
      );
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxChars) {
      setMessage(value);
    }
  };

  return (
    <div className="text-body-2 flex flex-col">
      {success ? (
        <>
          <WizardHeader className="!mb-5">Email sent!</WizardHeader>
          <WizardSubHeader>Hang tight, we&#39;ll email you back as soon as we can.</WizardSubHeader>

          <div className="relative flex w-full flex-col items-center rounded border border-mystic-500 p-6">
            <TickIcon className="h-16 w-16 fill-current text-lima-500" />
            <div className="text-header-4 mt-2 text-lima-500">Email sent!</div>
            <div className="mt-5">
              Click <strong className="text-body-1">Next</strong> to head to your dashboard.
            </div>
          </div>
        </>
      ) : (
        <>
          <WizardHeader className="!mb-5">Don&#39;t worry - we&#39;re here to help you!</WizardHeader>
          <WizardSubHeader>
            Please tell us what the problem is with specific details, and we will get back to you as soon as possible.
          </WizardSubHeader>

          <div className="relative mb-2.5 w-full">
            <TextArea
              rows={10}
              disabled={sendEmail.isLoading}
              placeholder="Type your message here"
              value={message}
              onChange={handleChange}
            />
            <div className="text-caption flex justify-end font-semibold">{maxChars - message.length}</div>
          </div>

          <div className="flex items-center">
            <Button variant="secondary" disabled={sendEmail.isLoading || !valid} onClick={sendMessage}>
              {sendEmail.isLoading ? 'Sending...' : 'Send'}
            </Button>
            {sendEmail.error && (
              <span className="text-error ml-[30px]">Failed to send your message. Please try again later.</span>
            )}
          </div>
        </>
      )}
    </div>
  );
}
