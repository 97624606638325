import { getIn, useFormikContext } from 'formik';
import classNames from 'classnames';
import { Checkbox } from '@crazyegginc/hatch';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

import { Panel } from './SsoUi';

export function SsoEnabled({ settings }) {
  const { values, errors, setFieldValue, handleChange } = useFormikContext();

  if (!settings) return null;

  const enabledError = getIn(errors, 'enabled');
  const requiredError = getIn(errors, 'required');

  return (
    <>
      <h2 className="text-header-3 mb-[15px]">SSO Enabled</h2>
      <Panel>
        <div className="mb-[15px] flex flex-col gap-y-2">
          <Checkbox
            label="SSO enabled"
            id="enabled"
            name="enabled"
            checked={values.enabled}
            onChange={(e) => {
              setFieldValue('enabled', e.target.checked);
              if (e.target.checked === false) {
                setFieldValue('required', false);
              }
            }}
            labelClass={classNames('!font-semibold', { '!text-lynch-500': !values.enabled })}
            error={enabledError}
          />
          <Checkbox
            label="SSO required for login"
            id="required"
            name="required"
            disabled={!values.enabled}
            checked={values.required}
            onChange={handleChange}
            labelClass={classNames('!font-semibold', { '!text-lynch-500': !values.enabled })}
            error={requiredError}
          />
          <p className="text-info-body ml-[30px] flex max-w-[664px] rounded bg-white-lilac-500 px-3.5 py-2.5">
            <InfoIcon className="mr-2.5 h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
            We recommend you do not enable this setting until you have successfully logged in via SSO for your account.
          </p>
        </div>
      </Panel>
    </>
  );
}
