import { Link } from 'react-router-dom';
import { InfoBox } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';

import { FEATURES } from '/src/features/_global/constants';

export function HeaderInfoBox({ feature }) {
  const permissions = usePermissions();
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;
  const yourPlanLink = (
    <Link className="text-dodger-blue-500" to={'/account/plan'}>
      your plan
    </Link>
  );

  const infoBoxText = (feature) => {
    switch (feature) {
      case FEATURES.ERRORS_TRACKING:
        return (
          <>
            Errors are tracked for recorded visits only. If {canManageSubscription ? yourPlanLink : 'your plan'} has a
            low recording quota you may be missing vital errors.
          </>
        );
      case FEATURES.TRAFFIC_ANALYSIS:
        return (
          <>
            Traffic Analysis is based on recorded visits only. If {canManageSubscription ? yourPlanLink : 'your plan'}{' '}
            has a low recording quota you may be missing vital traffic data.
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-[350px]">
      <InfoBox body={infoBoxText(feature)} />
    </div>
  );
}
