import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function DeleteQuestionModal({ onConfirm, questionNumber }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Modal.Title>Delete this question?</Modal.Title>

      <div className="text-body-2">
        Are you sure you want to delete <span className="text-body-1">question {questionNumber}</span>?
      </div>

      <Modal.Actions>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Yes, delete question
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
