export const MAX_COMPARISONS = 3;
export const QUERY_LIMIT = 1000;

export const INITIAL_CRITERIA_FILTER_ORDER = [
  'utmTerm',
  'utmContent',
  'referrerUrl',
  'utmCampaign',
  'utmSource',
  'utmMedium',
];
