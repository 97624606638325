import { useState } from 'react';
import { Button, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';

export function RegenerateUrlModal({ onRegenerate = null, numToGenerate = 1 }) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal dialogClassName="!w-[360px]">
      <Modal.Title>{`Generate ${numToGenerate > 1 ? numToGenerate : ''} new ${inflect(
        'URL',
        numToGenerate,
      )}?`}</Modal.Title>
      <p className="text-body-5 leading-5">
        Generating a new URL will disable the old URL. Anyone with the old URL will no longer be able to view the page.
      </p>
      <Modal.Actions>
        <Button
          disabled={submitting}
          leftIcon={submitting ? <Spinner className="mr-1 h-3 w-3" /> : null}
          onClick={() => {
            setSubmitting(true);
            onRegenerate?.();
          }}
        >
          {submitting ? 'Regenerating...' : `Generate new ${inflect('URL', numToGenerate)}`}
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
