import { createContext, useReducer, useState } from 'react';
import isEqual from 'react-fast-compare';

export const VisitorIdContext = createContext();

const initialState = {
  selectedVisitor: null,
  panelOpen: false,
};

const SELECT_VISITOR = 'SELECT_VISITOR';
const UNSELECT_VISITOR = 'UNSELECT_VISITOR';

function visitorReducer(state, action) {
  switch (action.type) {
    case SELECT_VISITOR:
      return {
        ...state,
        panelOpen: true,
        selectedVisitor: action.payload,
      };

    case UNSELECT_VISITOR:
      return initialState;

    default:
      return state;
  }
}

export function VisitorIdProvider({ children }) {
  const [visitor, visitorDispatch] = useReducer(visitorReducer, initialState);
  const [visitorState, setVisitorState] = useState({
    visitor,
    visitorDispatch,
  });

  if (!isEqual(visitor, visitorState.visitor)) {
    setVisitorState({ visitor, visitorDispatch });
  }

  return <VisitorIdContext.Provider value={visitorState}>{children}</VisitorIdContext.Provider>;
}
