import { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import useResizeObserver from 'use-resize-observer';
import classNames from 'classnames';
import { Input, Button, Tooltip, Spinner } from '@crazyegginc/hatch';

import { SharePreviewModal } from '/src/features/addons/common/modals/SharePreviewModal';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';
import { PreviewWarningModal } from '/src/features/addons/common/modals/PreviewWarningModal';
import { useEditorContext } from '../../editor-context';
import { useModal } from '/src/hooks';

import { DEVICE_TYPES } from '/src/features/_global/constants';

import { BEHAVIOR_TRIGGERS, CTA_TYPES, ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';

export function PreviewOnSite({ saveCTA, isValidForPreview }) {
  const { readonly, type, spelledType, id } = useEditorContext();
  const { values, isValid } = useFormikContext();
  const modal = useModal();
  const [previewUrl, setPreviewUrl] = useState('');
  const [touched, setTouched] = useState(false);
  const [savingShare, setSavingShare] = useState(false);
  const [savingPreview, setSavingPreview] = useState(false);
  const previewWindow = useRef();
  const features = useRef();
  const isBar = values.displayType === CTA_TYPES.BAR;
  const isButton = values.displayType === CTA_TYPES.BUTTON;

  const { height: ctaHeight } = useResizeObserver({
    ref: document.querySelector('crazyegg-cta')?.shadowRoot?.querySelector('#main')?.firstElementChild,
  });

  useEffect(() => {
    if (!touched) {
      setPreviewUrl((values.audience.pages?.[0] || `http://${values.audience.site?.name}`) ?? '');
    }
  }, [touched, values.audience.pages, values.audience.site]);

  if (!ctaHeight || !isValidForPreview) return null;

  async function launchPreview() {
    setSavingPreview(true);
    const { previewToken } = await saveCTA();
    setSavingPreview(false);

    let newFeatures;
    if (values.audience.device.length === 1 && values.audience.device[0] === DEVICE_TYPES.MOBILE) {
      newFeatures = 'width=320,height=740';
    } else {
      newFeatures = '';
    }

    if (newFeatures !== features.current && previewWindow.current && !previewWindow.current.closed) {
      previewWindow.current.close();
    }
    features.current = newFeatures;
    previewWindow.current = open(previewUrl, id, features.current);
    modal.show(
      <PreviewLoadingModal target={features.current ? 'window' : 'tab'} type={type} spelledType={spelledType} />,
    );

    window.addEventListener('message', (event) => {
      if (event.data.status === 'initiated') {
        previewWindow.current.postMessage({ token: previewToken }, '*');
      } else if (event.data.status === 'scriptLoaded') {
        if (
          (values.autoPosition && !values.autoPositionFallbackToShow) ||
          values.behavior.show !== BEHAVIOR_TRIGGERS.IMMEDIATE
        ) {
          modal.show(
            <PreviewWarningModal
              autoPosition={values.autoPosition}
              autoPositionFallbackToShow={values.autoPositionFallbackToShow}
              behavior={values.behavior}
              spelledType={spelledType}
            />,
          );
        }
      } else if (event.data.status === 'launched') {
        modal.close();
      }
    });
  }

  const isValidForPreviewOnSite = isValid;

  return (
    <div
      className={classNames('flex flex-col !self-stretch', {
        'w-full items-center py-4': !isBar,
        'items-end': isBar,
      })}
      style={{ marginTop: `${isBar || isButton ? 0 : ctaHeight}px` }}
    >
      {!readonly && (
        <div
          className={classNames('', {
            'flex items-center space-x-2.5': isBar,
          })}
        >
          <h2
            className={classNames('text-body-2', {
              'mt-10': isValidForPreview && !isBar,
              'mt-20': !isValidForPreview && !isBar,
              'mb-2 w-[310px]': !isBar,
            })}
          >
            Preview full-size directly on my site
          </h2>
          <Tooltip
            placement="bottom"
            show={!isValidForPreviewOnSite}
            tooltipContent={
              <div>
                Please fix any errors in the editor to be able
                <br /> to preview the CTA on your site.
              </div>
            }
          >
            <div className={classNames('flex w-[310px] items-center justify-center')}>
              <div className={classNames('relative mr-2.5 grow')}>
                <Input
                  id="survey-preview-url"
                  value={previewUrl}
                  onChange={(e) => {
                    setPreviewUrl(e.target.value);
                    setTouched(true);
                  }}
                  className="!pr-24"
                  placeholder="Preview URL"
                  disabled={!isValidForPreviewOnSite || savingPreview}
                />
                <Button
                  variant="secondary"
                  className={classNames('absolute right-px top-px !h-[33px] !rounded-l-none hover:!border-transparent')}
                  disabled={!isValidForPreviewOnSite || savingPreview}
                  onClick={launchPreview}
                >
                  {savingPreview && <Spinner className="mr-2 h-4 w-4 text-cadet-blue-500" />}
                  Preview
                </Button>
              </div>

              <Tooltip tooltipContent="Share preview" show={isValidForPreviewOnSite}>
                <Button
                  variant="secondary"
                  disabled={!isValidForPreviewOnSite || savingShare}
                  onClick={async () => {
                    setSavingShare(true);
                    const { previewToken } = await saveCTA();
                    setSavingShare(false);
                    modal.show(<SharePreviewModal url={previewUrl} token={previewToken} type={ADDON_TYPES.CTA} />);
                  }}
                >
                  {savingShare ? (
                    <Spinner />
                  ) : (
                    <ShareIcon className="h-4 w-4 fill-current" aria-label="Share preview" />
                  )}
                </Button>
              </Tooltip>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
