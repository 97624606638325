import { Divider } from '@crazyegginc/hatch';

// eslint-disable-next-line no-restricted-syntax
export default function RegexSyntaxModal() {
  return (
    <div className="text-body-2 flex w-full flex-col p-7">
      <h1 className="text-header-2 m-0 mt-1.25">Regular Expression Syntax</h1>

      <Divider />

      <p className="mb-7 mt-2.5">
        This is just a quick summary. Complete references can be found{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-link"
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp"
        >
          here
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-link"
          href="https://docs.microsoft.com/en-us/previous-versions/1400241x(v=vs.85)"
        >
          here
        </a>
        .
      </p>

      <span className="py-1.25">
        <Code>[a-z]</Code> denotes a character set, which matches any characters or ranges inside the brackets.
      </span>
      <span className="py-1.25">
        <Code>.</Code> matches any character (except line-breaking characters).
      </span>
      <span className="py-1.25">
        <Code>*</Code> matches the previous character, set or group zero or more times.
      </span>
      <span className="py-1.25">
        <Code>+</Code> matches the previous character, set or group one or more times.
      </span>
      <span className="py-1.25">
        <Code>?</Code> matches the previous character, set or group one time or zero times.
      </span>
      <span className="py-1.25">
        <Code>^</Code> the beginning of the URL.
      </span>
      <span className="py-1.25">
        <Code>$</Code> the end of the URL.
      </span>
      <span className="py-1.25">
        <Code>[^a-z]</Code> denotes a negated character set, which matches any characters not in the set.
      </span>
      <span className="py-1.25">
        <Code>\d</Code> equivalent to <code>[0-9]</code>.
      </span>
      <span className="py-1.25">
        <Code>\D</Code> equivalent to <code>[^0-9]</code>.
      </span>
      <span className="py-1.25">
        <Code>\w</Code> equivalent to <code>[a-z0-9_]</code>.
      </span>
      <span className="py-1.25">
        <Code>\W</Code> equivalent to <code>[^a-z0-9_]</code>.
      </span>
      <span>
        <Code>\s</Code> matches any white space character.
      </span>
      <span className="py-1.25">
        <Code>\S</Code> matches anything except a white space character.
      </span>
      <span className="py-1.25">
        <Code>\b</Code> matches the beginning or end of a word.
      </span>
      <span className="py-1.25">
        <Code>{`{n,m}`}</Code> matches the previous character, set or group between <em>n</em> and <em>m</em> times.
      </span>
      <span className="py-1.25">
        <Code>{`{n,}`}</Code> matches the previous character, set or group at least <em>n</em> times.
      </span>
      <span className="py-1.25">
        <Code>{`(abc)`}</Code> denotes a <em>group</em> of characters and/or sets.
      </span>
    </div>
  );
}

function Code({ children }) {
  return <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">{children}</code>;
}
