import { Children } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export function Breadcrumb({ children }) {
  const breadcrumbLength = Children.toArray(children).length;

  return (
    <div className="flex min-w-0 items-center">
      {Children.map(children, (child, childIndex) => (
        <>
          {child}
          {childIndex + 1 <= breadcrumbLength - 1 ? <DotSeparator /> : null}{' '}
        </>
      ))}
    </div>
  );
}

export function BreadcrumbItem({ children, to = null, href = null, active = false }) {
  if (to) {
    return (
      <Link
        to={to}
        className={classNames('min-w-0 shrink-0 hover:underline', {
          'opacity-50': !active,
        })}
      >
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a
        href={href}
        className={classNames('min-w-0 shrink-0 hover:underline', {
          'opacity-50': !active,
        })}
      >
        {children}
      </a>
    );
  }

  return <span className="min-w-0">{children}</span>;
}

function DotSeparator() {
  return (
    <div className="relative mx-2.5 h-1 w-1">
      <span className="absolute block h-1 w-1 rounded-full bg-black-pearl-500 opacity-50" />
    </div>
  );
}
