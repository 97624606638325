import { Button } from '@crazyegginc/hatch';
import { Panel } from '../SsoUi';
import { SupportLinks } from '/src/support';

export function GeneralErrorMessage() {
  return (
    <Panel>
      <p className="text-body-2 mb-7">
        An error prevented the Single Sign-On configuration from loading. Please check your connection and try again
        later. If the issue persists, please contact support.
      </p>
      <div className="flex gap-x-2.5">
        <Button onClick={() => window.open(SupportLinks.general.newSupportRequest, '_blank')}>Contact Support</Button>
      </div>
    </Panel>
  );
}
