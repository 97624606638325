import { useMemo } from 'react';
import { clamp } from '/src/utils/math';
import { getParam } from '/src/utils/location';

// function convertRemToPixels(rem) {
//   return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
// }

export const sliderWidth = 21;

export function Slider({ currentPosition = 0, timelineWidth = 0 }) {
  const debugMode = getParam('debug', false);

  const memoizedPosition = useMemo(() => {
    return clamp(
      currentPosition * timelineWidth - sliderWidth / 2,
      0 - sliderWidth / 2,
      timelineWidth - sliderWidth / 2,
    );
  }, [currentPosition, timelineWidth]);

  if (!timelineWidth) return null;

  if (debugMode) {
    return (
      <div
        className="bg-red-600 drag-handle absolute block h-6 w-0.5 cursor-pointer"
        style={{ left: `${memoizedPosition}px`, zIndex: 150 }}
      />
    );
  }

  return (
    <div
      className="drag-handle duration-110 absolute bottom-0 top-0 z-30 m-auto flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-white shadow-xl outline-none transition ease-in-out hover:scale-125"
      style={{ left: `${memoizedPosition}px`, zIndex: 150 }}
    >
      <span className="block h-3 w-3 select-none rounded-full bg-dodger-blue-500"></span>
    </div>
  );
}
