import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';

import { useMutation, useNotifications, useDebounce, usePrevious } from '/src/hooks';
import { setRecordingSettings } from '/src/features/options/mutations';

import { Select, Input, Tooltip } from '@crazyegginc/hatch';

import {
  calculateRecordingRatio,
  numberToPercentage,
  percentageToNumber,
  checkSamplingType,
} from '/src/features/options/utils';
import { SiteSettingsColumnSection } from '/src/features/options/components/SiteSettingsColumnSection';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

import { SAMPLING_TYPE, NUMBER_TYPE } from '/src/features/options/constants';

function AutoSave({ func }) {
  const formik = useFormikContext();
  const { values, isValid, dirty, isSubmitting } = formik;

  const debounceValue = useDebounce(values.value, 1250);
  const prevDebouncedValue = usePrevious(debounceValue);

  useEffect(() => {
    if (isValid && dirty && !isSubmitting && prevDebouncedValue !== debounceValue) {
      func();
    }
  }, [func, formik, debounceValue, dirty, isSubmitting, isValid, prevDebouncedValue]);

  return null;
}

function ErrorComponent({
  error,
  isSamplingAutomatic,
  isNumberTypePercentage,
  samplingRecordingRatio,
  samplingEstimated,
  value,
}) {
  let text = '';
  let isErrorFromDB = false;

  if (!error) {
    if (isSamplingAutomatic) {
      text = `${Math.round(
        percentageToNumber(calculateRecordingRatio(samplingRecordingRatio), samplingEstimated),
      ).toLocaleString()} recordings`;
    } else {
      if (isNumberTypePercentage) {
        if (value > 100) {
          text = `This is more than your monthly visitors. Please use 100% instead.`;
          isErrorFromDB = true;
        } else {
          text = `${Math.round(percentageToNumber(value, samplingEstimated)).toLocaleString()} recordings`;
        }
      } else {
        if (numberToPercentage(value, samplingEstimated) > 100) {
          text = `This is more than your monthly visitors. Please use 100% instead.`;
          isErrorFromDB = true;
        } else {
          text = `${numberToPercentage(value, samplingEstimated).toLocaleString()}% sampling rate`;
        }
      }
    }
  } else {
    text = error;
  }

  return (
    <span
      className={classNames('text-caption', {
        'absolute top-0 left-0 leading-none text-carnation-500 pt-0.5': error || isErrorFromDB,
      })}
    >
      {text}{' '}
    </span>
  );
}

function checkWarning(
  type,
  endSessionOnBlockedUrl,
  pageTargetingEnabled,
  samplingEstimated,
  num,
  samplingRecordingRatio,
) {
  const isCustomPageEnabled = endSessionOnBlockedUrl || pageTargetingEnabled;
  const fullSampling = 100;

  const isMoreThanFull = (type) => {
    switch (type) {
      case NUMBER_TYPE.RATE:
        return num;
      case NUMBER_TYPE.STATIC:
        return numberToPercentage(num, samplingEstimated);
      default:
        return calculateRecordingRatio(samplingRecordingRatio);
    }
  };

  return isCustomPageEnabled && isMoreThanFull(type) >= fullSampling;
}

export function SiteSettingsSamplingInputs({
  siteId,
  samplingType,
  samplingValue,
  samplingRecordingRatio,
  samplingEstimatedMaxRecordingsDay,
  samplingEstimatedVisitsDay,
  name,
  endSessionOnBlockedUrl,
  pageTargetingEnabled,
}) {
  /*
    NOTE:
      For initial data
     - AUTO: check the recordingRatio
     - RATE or STATIC: check the samplingValue
  */

  const returnSamplingType = checkSamplingType(samplingType);
  const returnEstimatedVisit =
    samplingEstimatedMaxRecordingsDay && samplingEstimatedMaxRecordingsDay > 0
      ? samplingEstimatedMaxRecordingsDay
      : samplingEstimatedVisitsDay;
  const isSamplingAutomatic = returnSamplingType === SAMPLING_TYPE.AUTO;
  const isNumberTypePercentage = returnSamplingType === NUMBER_TYPE.RATE;
  const initialValuesValue =
    returnSamplingType === SAMPLING_TYPE.AUTO ? calculateRecordingRatio(samplingRecordingRatio) : (samplingValue ?? 0);

  const initialValue = {
    type: returnSamplingType,
    value: initialValuesValue,
  };

  const validationSchema = yup.object().shape({
    type: yup.string().oneOf([...Object.values(SAMPLING_TYPE), ...Object.values(NUMBER_TYPE)]),
    value: yup
      .number()
      .when('type', {
        is: (value) => value === NUMBER_TYPE.RATE,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .max(100, 'Should not exceed 100.')
          .test('is-decimal', 'The maximum decimal is two digits after comma.', (value) => {
            if (value != undefined) {
              return /^\d+(\.\d{0,2})?$/.test(value);
            }
            return true;
          }),
      })
      .when('type', {
        is: (value) => value === NUMBER_TYPE.STATIC,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .integer('Please provide a valid number.')
          .positive('Please provide a valid number.')
          .test(
            'not-more-than-allocated',
            "That's more than your monthly visitors. Please use 100% instead.",
            (value) => {
              const isBigger = value > Math.round(percentageToNumber(100, returnEstimatedVisit));
              return !isBigger;
            },
          ),
      }),
  });

  const updateRecordingSettings = useMutation(setRecordingSettings);
  const notifications = useNotifications();

  const { mutate } = updateRecordingSettings;

  // isWarning is Temporary
  const isWarning = checkWarning(
    initialValue.type,
    endSessionOnBlockedUrl,
    pageTargetingEnabled,
    returnEstimatedVisit,
    samplingValue,
    samplingRecordingRatio,
  );

  const options = [
    {
      value: SAMPLING_TYPE.AUTO,
      label: 'Automatic',
    },
    {
      value: SAMPLING_TYPE.MANUAL,
      label: 'Manual',
    },
  ];

  const numTypeOption = [
    {
      value: NUMBER_TYPE.RATE,
      label: '%',
    },
    {
      value: NUMBER_TYPE.STATIC,
      label: '#',
    },
  ];

  const handleSamplingUpdate = useCallback(
    (type, value) => {
      mutate(
        {
          siteId,
          samplingType: type,
          samplingValue: type === NUMBER_TYPE.RATE ? parseFloat(value) : parseInt(value),
        },
        {
          onError: (error) => {
            notifications.error({
              content: `Sampling update failed for ${name}.`,
              timeout: 3000,
              context: { error },
            });
          },
        },
      );
    },
    [notifications, siteId, mutate, name],
  );

  function returnValueToBeSend(type, num) {
    switch (type) {
      case NUMBER_TYPE.RATE:
        return numberToPercentage(num, returnEstimatedVisit);
      case NUMBER_TYPE.STATIC:
        return percentageToNumber(num, returnEstimatedVisit);
      default:
        return num;
    }
  }

  return (
    <Formik initialValues={initialValue} validationSchema={validationSchema}>
      {({ values, errors, setFieldValue, handleChange, resetForm }) => {
        const samplingNumber = values?.value ?? 0;
        const isValueTypeAuto = values.type === SAMPLING_TYPE.AUTO;

        return (
          <Form className="relative flex w-full">
            <SiteSettingsColumnSection placement="left">
              <div className="flex flex-col">
                <Select
                  aria-label={values.type}
                  name="samplingType"
                  value={
                    values.type === NUMBER_TYPE.RATE || values.type === NUMBER_TYPE.STATIC
                      ? SAMPLING_TYPE.MANUAL
                      : SAMPLING_TYPE.AUTO
                  }
                  options={options}
                  disabled={!!errors.value}
                  onChange={(type) => {
                    const sendValue =
                      values.type === NUMBER_TYPE.STATIC
                        ? numberToPercentage(values.value, returnEstimatedVisit)
                        : samplingNumber;

                    mutate(
                      {
                        siteId,
                        samplingType: type === SAMPLING_TYPE.MANUAL ? NUMBER_TYPE.RATE : SAMPLING_TYPE.AUTO,
                        samplingValue: parseFloat(sendValue),
                      },
                      {
                        onError: (error) => {
                          notifications.error({
                            content: `Sampling update failed for ${name}.`,
                            timeout: 3000,
                            context: { error },
                          });

                          resetForm();
                        },
                      },
                    );
                  }}
                />
              </div>
            </SiteSettingsColumnSection>
            <SiteSettingsColumnSection placement="right">
              <div className="relative flex">
                <div className="flex max-w-[200px] flex-1">
                  <div className="flex-1">
                    <Input
                      aria-label={`number ${name}`}
                      name={`number ${siteId}`}
                      id={`number ${siteId}`}
                      value={
                        values.type === NUMBER_TYPE.STATIC
                          ? Math.round(samplingNumber).toLocaleString()
                          : samplingNumber
                      }
                      onChange={(e) => {
                        let newValue;
                        if (e.target.value) {
                          newValue =
                            values.type === NUMBER_TYPE.STATIC
                              ? parseInt(Math.round(e.target.value.replace(',', '')))
                              : parseFloat(e.target.value);
                        } else {
                          newValue = '';
                        }

                        handleChange(e);
                        setFieldValue('value', newValue);
                      }}
                      type="number"
                      inputMode="numeric"
                      step="any"
                      disabled={isSamplingAutomatic || isValueTypeAuto}
                      className={classNames(
                        'rounded-br-none rounded-tr-none !pr-1 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
                        {
                          '!border-carnation-500': errors.value,
                        },
                      )}
                    />
                  </div>
                  <Select
                    aria-label={`type ${values.type}`}
                    name="numberType"
                    value={
                      isValueTypeAuto || values.type === NUMBER_TYPE.RATE || values.type === SAMPLING_TYPE.MANUAL
                        ? NUMBER_TYPE.RATE
                        : NUMBER_TYPE.STATIC
                    }
                    options={numTypeOption}
                    onChange={(type) => {
                      if (type !== values.type) {
                        const numberValue = returnValueToBeSend(type, values.value).toFixed(2);

                        mutate(
                          {
                            siteId,
                            samplingType: type,
                            samplingValue: parseFloat(numberValue),
                          },
                          {
                            onError: (error) => {
                              notifications.error({
                                content: `Sampling update failed for ${name}.`,
                                timeout: 3000,
                                context: { error },
                              });

                              resetForm();
                            },
                          },
                        );
                      }
                    }}
                    style={{
                      background: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? '#e1e6ef' : '#e5f2fc'}`,
                      borderColor: '#e1e6ef',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderLeft: '0px',
                      cursor: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? 'default' : 'pointer'}`,
                      color: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? '#69768d' : '#0086e6'}`,
                      fontWeight: 'bold',
                      width: '60px',
                    }}
                    disabled={isSamplingAutomatic || isValueTypeAuto || !!errors.value}
                  />
                </div>

                {(isNumberTypePercentage || isSamplingAutomatic) && (
                  <div className="text-caption ml-2.5 flex w-[60px] items-center leading-none">
                    of visitors to this site
                  </div>
                )}

                {isWarning && (
                  <div className="ml-1 flex items-center">
                    <Tooltip
                      tooltipContent={
                        <div className="w-[300px] p-0.5 leading-tight">
                          <p>
                            There are other active Recordings settings that could restrict what gets recorded. View
                            settings
                          </p>
                        </div>
                      }
                      placement="bottom"
                    >
                      <WarningIcon className="-mt-[2px] h-4 w-4 fill-current text-dandelion-500" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="absolute -bottom-[3px] w-full h-[20px]">
                <ErrorComponent
                  error={errors.value}
                  isSamplingAutomatic={isSamplingAutomatic}
                  isNumberTypePercentage={isNumberTypePercentage}
                  samplingRecordingRatio={samplingRecordingRatio}
                  samplingEstimated={returnEstimatedVisit}
                  value={values.value}
                />
              </div>
            </SiteSettingsColumnSection>
            <AutoSave func={() => handleSamplingUpdate(values.type, values.value)} />
          </Form>
        );
      }}
    </Formik>
  );
}
