import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { SNAPSHOT_FILTER_STATUSES } from '/src/features/snapshots/constants';

const { ALL, COMPLETED, NOT_COMPLETED } = SNAPSHOT_FILTER_STATUSES;

const humanizeSelection = {
  [ALL]: 'Show All',
  [COMPLETED]: 'Completed',
  [NOT_COMPLETED]: 'Running',
};

export function SnapshotStatusDropdown({ counts, onSelect, selected }) {
  const running = counts?.running ?? null;
  const completed = counts?.completed ?? null;
  const total = counts?.total ?? null;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as={Button} variant="secondary" size="lg" className={open ? '!border-dodger-blue-500' : ''}>
            {(selected && humanizeSelection[selected]) || 'Show All'}
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="center">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(ALL);
                    close();
                  }}
                >
                  All Snapshots{total > 0 && ` (${total})`}
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(NOT_COMPLETED);
                    close();
                  }}
                >
                  Running{running > 0 && ` (${running})`}
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(COMPLETED);
                    close();
                  }}
                >
                  Completed{completed > 0 && ` (${completed})`}
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
