import classNames from 'classnames';

export function Card({ heading, cardClassName, children }) {
  return (
    <div className={classNames(cardClassName)}>
      <h3 className="text-header-3 mb-3.75">{heading}</h3>
      <div className="flex flex-col rounded-md border-2 border-mystic-500 p-[25px]">{children}</div>
    </div>
  );
}

function WrapperSection({ cardWrapperClassName, children }) {
  return <div className={classNames('flex w-full', cardWrapperClassName)}>{children}</div>;
}

function LeftSection({ children }) {
  return <div className="w-full max-w-[550px]">{children}</div>;
}

function RightSection({ contentBottom = false, errors = false, children }) {
  return (
    <div
      className={classNames('ml-[46px] flex flex-1', {
        'items-center': !contentBottom && !errors,
        'items-center pt-1': errors,
        'items-end': contentBottom && !errors,
      })}
    >
      {children}
    </div>
  );
}

Card.WrapperSection = WrapperSection;
Card.LeftSection = LeftSection;
Card.RightSection = RightSection;
