import classNames from 'classnames';

import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-circle-filled.svg';
import { ReactComponent as LockIcon } from '@crazyegginc/hatch/dist/images/icon-lock-filled.svg';

export function PlayButton({
  isWatching = false,
  watched = false,
  isTooOld = false,
  onClick = null,
  size = 'base',
  flipColorOnHover,
}) {
  const testId = watched ? 'play_watched' : 'play';

  let Icon = isTooOld ? LockIcon : PlayIcon;

  return (
    <button
      className={classNames('relative flex justify-center border-none bg-transparent p-0 outline-none', {
        'h-[25px] w-[25px]': size === 'base',
        'h-[35px] w-[35px]': size === 'lg',
      })}
      onClick={onClick}
    >
      <Icon
        className={classNames('relative z-10 border-none fill-current outline-none', {
          'h-[25px] w-[25px]': size === 'base',
          'h-[35px] w-[35px]': size === 'lg',
          'text-dodger-blue-500 hover:text-dodger-blue-700': !watched && !isWatching && !isTooOld,
          'text-cadet-blue-500 hover:text-lynch-500': watched && !isWatching && !isTooOld,
          'text-white': isWatching,
          'text-cadet-blue-500': isTooOld,
          'group-hover:text-white': flipColorOnHover,
        })}
        aria-label="play"
        data-testid={testId}
      />

      <div
        className={classNames('absolute left-1/2 top-1/2 z-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rounded-full', {
          'bg-white': !isWatching,
          'bg-dodger-blue-500': isWatching,
          'group-hover:bg-dodger-blue-500': flipColorOnHover,
        })}
      />
    </button>
  );
}
