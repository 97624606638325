import { useRef, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';

import { useFilter } from '../live-filter-context';

import { formatShortDateTime } from '/src/utils/date';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { escapeRegexSpecialChars } from '/src/utils/regex';
import { getLiveEvent } from '../live-functions';
import { LiveEventIcon } from './LiveEventIcon';

import { LIVE_EVENT_NAMES, BASIC_VISITOR } from '../constants';

export function EventCard({ liveEvent, visitEvents, identifyEvents, pageCounts, pauseOnHover, pause }) {
  const { filter } = useFilter();
  const [doesNotMatchSearch, setDoesNotMatchSearch] = useState(false);
  const compRef = useRef();

  const { name, CardComponent, tooltip } = getLiveEvent(liveEvent.event, liveEvent.attributes.sub_event_type);

  let iconSubType;
  if (name === LIVE_EVENT_NAMES.CLICK) {
    if (liveEvent.attributes.dead) iconSubType = 'dead';
    if (liveEvent.attributes.rage) iconSubType = 'rage';
  }

  useLayoutEffect(() => {
    if (filter.search) {
      if (compRef?.current) {
        const regexp = new RegExp(escapeRegexSpecialChars(filter.search), 'i');
        if (!compRef.current.innerText.replace(/\s+/g, ' ').match(regexp)) {
          setDoesNotMatchSearch(true);
        } else {
          setDoesNotMatchSearch(false);
        }
      }
    } else {
      setDoesNotMatchSearch(false);
    }
  }, [filter.search]);

  if (
    !name ||
    !filter.events.includes(name) ||
    (filter.person &&
      ((liveEvent.visitorId && liveEvent.visitorId !== filter.person) ||
        (!liveEvent.visitorId && filter.person !== BASIC_VISITOR))) ||
    (filter.page &&
      pageCounts[filter.page] &&
      !pageCounts[filter.page].visitIds.includes(liveEvent.attributes?.visit_id) &&
      !pageCounts[filter.page].visitIds.includes(liveEvent.eventId))
  )
    return null;

  const date = formatShortDateTime(Math.round(liveEvent.timestamp / 1000)).split(',');

  return (
    <div
      className={classNames(
        'text-body-2 relative mb-3.75 min-w-max rounded-[10px] border border-mystic-500 bg-white py-3.75 pl-3.75 pr-5 hover:shadow',
        {
          'hidden ': doesNotMatchSearch,
        },
      )}
      onPointerEnter={() => {
        pauseOnHover && pause?.();
      }}
      ref={compRef}
    >
      <LiveEventIcon name={name} subType={iconSubType} tooltip={tooltip} />
      <div className="flex w-full items-start justify-between">
        {CardComponent && (
          <CardComponent liveEvent={liveEvent} visitEvents={visitEvents} identifyEvents={identifyEvents} />
        )}
        <div className="text-body-5 shrink-0">
          <div className="flex flex-col items-center">
            <div className="text-body-4">{date[0].trim()}</div>
            <div>{date[1].trim()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Identity({ id, identifyEvents }) {
  const { setPersonFilter, filter } = useFilter();
  const visitorId = id;
  const identifier = identifyEvents[visitorId];

  const displayName = getVisitorDisplayName(identifier, visitorId);

  return (
    <button
      type="button"
      onClick={() => {
        if (filter.person === visitorId) {
          setPersonFilter(undefined);
        } else {
          setPersonFilter(visitorId);
        }
      }}
      className={classNames('mr-1.25 shrink-0 font-semibold text-dodger-blue-500', {
        'w-32 truncate': !identifier,
      })}
    >
      {displayName}
    </button>
  );
}
