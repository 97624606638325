import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppConfig } from '../../../AppConfig';

export function NotImplementedPage({ path = null }) {
  const location = useLocation();

  useEffect(() => {
    const legacyBaseUrl = AppConfig.legacyCoreBaseURL();
    const url = `${path ? path : location.pathname}${location.search}${location.hash}`;
    if (legacyBaseUrl && url) {
      window.location.replace(`${legacyBaseUrl}${url}`);
    }
  }, [path, location.hash, location.pathname, location.search]);

  return null;
}
