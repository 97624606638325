import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isFuture, fromUnixTime, format } from 'date-fns';

import { usePermissions, useAuthContext, useMetric } from '/src/hooks';
import { inflect } from '/src/utils/string';
import { featureUsage } from '/src/utils/features';
import { getDaysRemainingInMonthServerTime } from '/src/utils/date';
import { DashboardBanner } from '/src/components/DashboardBanner';
import { FEATURES, MANAGE_SUBSCRIPTION_PATH } from '../constants';

export function SnapshotAlerts({ page = FEATURES.SNAPSHOTS }) {
  const { currentAccount, currentUser, capabilities } = useAuthContext();
  const trackMetric = useMetric();

  const [hasDismissedAlerts, setHasDismissedAlerts] = useState(
    Boolean(window.sessionStorage.getItem('ce.dashboards.dismissedAlerts') ?? false),
  );

  const permissions = usePermissions();
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;
  const canEditSnapshots = permissions.can('edit', FEATURES.SNAPSHOTS).allowed;

  const expiredOn = currentUser.subscriptions.snapshots.expiredOn;
  const dontShowPaymentAlert = [181, 197].includes(currentUser.subscriptions.snapshots.paymentPlan.id); // ch18817,sc24366
  const isInExpiredGrace = isFuture(fromUnixTime(expiredOn));
  const isSnapshotPage = page === FEATURES.SNAPSHOTS;

  function dismissAlerts() {
    window.sessionStorage.setItem('ce.dashboards.dismissedAlerts', 'true');
    setHasDismissedAlerts(true);
  }

  if (!canEditSnapshots) {
    return null;
  }

  const { monthlyPageViews } = capabilities.account.quotas;
  const canVisitMore = monthlyPageViews.current < monthlyPageViews.limit;
  const snapshotsVisits = featureUsage(monthlyPageViews, 0);
  const daysRemaining = getDaysRemainingInMonthServerTime();

  const canVisitMoreSubMessage = (page, daysRemaining) => {
    switch (page) {
      case FEATURES.SNAPSHOTS:
        return `or wait ${daysRemaining} ${inflect(
          'day',
          daysRemaining,
        )} (until 1st of the month) to continue tracking data.`;
      case FEATURES.AB_TESTING:
        return `or wait ${daysRemaining} ${inflect(
          'day',
          daysRemaining,
        )} (until 1st of the month) to continue running a/b tests.`;
      default:
        return '';
    }
  };

  if (isInExpiredGrace && !dontShowPaymentAlert && canManageSubscription) {
    if (hasDismissedAlerts) return null;

    return (
      <DashboardBanner title="There’s a payment issue!" data-testid="alert-banner" onClose={dismissAlerts}>
        Your Account will be deactivated on {format(fromUnixTime(expiredOn), 'MMM dd, yyyy')} due to a problem with
        payment.{' '}
        <Link to="/account/billing?billingModal=true" className="text-link !text-white !underline">
          Please update your payment info
        </Link>
        .
      </DashboardBanner>
    );
  }

  if (!canVisitMore) {
    return (
      <DashboardBanner title="Visit Limit Reached!" data-testid="alert-banner">
        You’ve used up 100% of your pageview quota this month.{' '}
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link !text-white !underline">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}{' '}
        {canVisitMoreSubMessage(page, daysRemaining)}
      </DashboardBanner>
    );
  }

  if (snapshotsVisits > 75) {
    if (hasDismissedAlerts) return null;
    return (
      <DashboardBanner data-testid="alert-banner" onClose={dismissAlerts}>
        You’ve reached {snapshotsVisits}% of your pageview quota this month. When you reach 100%, data will stop
        tracking.{' '}
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link !text-white !underline">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}
        .
      </DashboardBanner>
    );
  }

  const { totalSnapshots } = capabilities.snapshots.quotas;
  const remainingSnapshots = totalSnapshots.limit - totalSnapshots.current;
  const snapshotsUsage = featureUsage(totalSnapshots);

  if (remainingSnapshots <= 0 && isSnapshotPage) {
    return (
      <DashboardBanner title="Snapshot Limit Reached!" data-testid="alert-banner">
        You’ve used up all of your Snapshots quota. To create new Snapshots,{' '}
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link !text-white !underline">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}{' '}
        or delete a few.
        <br />
        Any snapshots over the quota in a running status will not track data.
      </DashboardBanner>
    );
  }

  if (snapshotsUsage > 75 && remainingSnapshots > 0 && isSnapshotPage) {
    if (hasDismissedAlerts) return null;
    return (
      <DashboardBanner data-testid="alert-banner" onClose={dismissAlerts}>
        You’ve reached {snapshotsUsage}% of your Snapshots limit, which means you can only create {remainingSnapshots}{' '}
        more {inflect('Snapshot', remainingSnapshots)}.{' '}
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'snapshots_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link !text-white !underline">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}{' '}
        to increase your Snapshot limit.
      </DashboardBanner>
    );
  }

  return null;
}
