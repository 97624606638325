import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup, Input } from '@crazyegginc/hatch';

import { SNAPSHOT_SAMPLING_RATIO_OPTIONS } from '/src/features/snapshots/constants';

export function SamplingRatio({ validationErrors }) {
  const formik = useFormikContext();

  const { setFieldError, setFieldTouched } = formik;

  useEffect(() => {
    if (validationErrors?.findIndex((error) => error.field === 'samplingRatio' && error.message === 'at_least') > -1) {
      const value = JSON.parse(
        validationErrors?.find((error) => error.field === 'samplingRatio' && error.message === 'at_least').args,
      ).number;
      setFieldError('samplingRatio.ratio', `Your sampling ratio must be at least ${value}.`);
      setFieldTouched('samplingRatio.ratio', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'samplingRatio' && error.message === 'at_most') > -1
    ) {
      const value = parseInt(
        JSON.parse(
          validationErrors?.find((error) => error.field === 'samplingRatio' && error.message === 'at_most').args,
        ).number,
      ).toLocaleString();
      setFieldError('samplingRatio.ratio', `Your sampling ratio cannot exceed ${value}.`);
      setFieldTouched('samplingRatio.ratio', true, false);
    }
  }, [validationErrors, setFieldError, setFieldTouched]);

  return (
    <section className="mb-0.5 mt-10">
      <h2 className="text-header-2 mb-4">Sampling Ratio</h2>
      <RadioGroup
        options={[
          { value: SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT, label: 'Track every visit' },
          {
            value: SNAPSHOT_SAMPLING_RATIO_OPTIONS.CUSTOM,
            label: (
              <div className="flex items-center">
                Track 1 in
                <div className="mx-1.25 w-14">
                  <Input
                    name={'samplingRatio.ratio'}
                    size="xs"
                    value={
                      isNaN(formik.values.samplingRatio.ratio) || formik.values.samplingRatio.ratio === ''
                        ? formik.values.samplingRatio.ratio
                        : (+formik.values.samplingRatio.ratio)?.toLocaleString('en-US')
                    }
                    onChange={(e) => formik.setFieldValue('samplingRatio.ratio', e.target.value.replace(',', ''), true)}
                    onBlur={formik.handleBlur}
                    className="!px-2"
                  />
                </div>
                visits
              </div>
            ),
          },
        ]}
        value={formik.values.samplingRatio.option}
        onChange={(newValue) => formik.setFieldValue('samplingRatio.option', newValue)}
        optionWrapperClass="mb-2.5"
      />
      {formik.touched?.samplingRatio?.ratio && formik.errors?.samplingRatio?.ratio && (
        <div className="relative h-5">
          <div className="text-body-4 absolute translate-y-0.5 whitespace-nowrap text-carnation-500">
            {formik.errors.samplingRatio.ratio}
          </div>
        </div>
      )}
    </section>
  );
}
