import { Navigate } from 'react-router-dom';
import { useSelector } from '@xstate5/react';

import Wizard from '/src/components/wizard/Wizard';

import { AbTestWeightsPage } from './new/weights-v2';
import AbTestVariantEditorPage from './new/variants-v2';

export function AbTestWizard({ actorRef, defaultPath }) {
  const state = useSelector(actorRef, (state) => state);

  const isNextEnabled = state.can({ type: 'NEXT' });
  const canSkip = state.can({ type: 'SKIP' });

  if (state.matches('cancelled')) {
    return <Navigate to={defaultPath} replace />;
  }

  if (
    state.matches('variants') ||
    state.matches('createVariants') ||
    (state.matches('loading') && state.context.nextState === 'weights')
  ) {
    return (
      <div className="flex h-full w-full flex-col">
        <AbTestVariantEditorPage actorRef={actorRef} />
      </div>
    );
  }

  const nextText = (defaultValue) => {
    if (state.matches('syncWeights')) {
      return 'Saving...';
    }
    return defaultValue;
  };

  const isSaving = state.matches('createVariants') || state.matches('syncWeights');

  return (
    <div className="flex h-full w-full flex-col">
      <Wizard.Header>
        <Wizard.CloseButton
          onClick={() => {
            actorRef.send('CANCEL');
          }}
          defaultPath={state.context.previousPath || '/ab-tests'}
        />
      </Wizard.Header>

      <Wizard.Content dontMask={true}>
        {state.matches('variants') || state.matches('createVariants') ? null : null}
        {state.matches('weights') || state.matches('syncWeights') ? <AbTestWeightsPage actorRef={actorRef} /> : null}
      </Wizard.Content>

      <Wizard.SimpleFooter
        confirmCancel={false}
        currentStep={2}
        totalSteps={2}
        showMeta={true}
        title={state.context.title}
        onBack={!state.matches('variants') ? () => actorRef.send({ type: 'BACK' }) : undefined}
        cancelText="Cancel"
        hideNextArrow={isSaving}
        nextText={nextText(state.context.nextText || 'Next')}
        nextEnabled={isNextEnabled}
        onNext={() => actorRef.send({ type: 'NEXT' })}
        onCancel={() => {
          actorRef.send({ type: 'CANCEL' });
        }}
        onSkip={canSkip ? () => actorRef.send({ type: 'SKIP' }) : null}
      />
    </div>
  );
}
