import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAccountNumber } from '/src/hooks';
import { SEO } from '/src/components/SEO';
import { CodeBox } from '/src/components/CodeBox';
import { WizardLogo, WizardList, WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

// eslint-disable-next-line no-restricted-syntax
export default function ZarazInstallPage() {
  const [accountNumber] = useAccountNumber();

  return (
    <div className="flex min-h-screen min-w-full flex-col">
      <SEO title="Install Script" />
      <WizardLogo to="/" />

      <main className="mx-auto mb-[200px] w-[780px] bg-white px-[30px] pb-[100px] pt-14">
        <div className="text-body-2 flex flex-col">
          <WizardHeader className="!mb-5">Zaraz Installation Instructions</WizardHeader>
          <WizardSubHeader>To start collecting data, follow the directions below.</WizardSubHeader>

          <div className="text-header-4 mb-2.5">Instructions:</div>
          <WizardList>
            <WizardList.Item>Log into your CloudFlare account.</WizardList.Item>
            <WizardList.Item>
              Click on <strong className="text-body-1">Zaraz</strong>.
            </WizardList.Item>
            <WizardList.Item>
              Click on <strong className="text-body-1">Add a New Integration</strong>.
            </WizardList.Item>
            <WizardList.Item>
              Copy and paste the Crazy Egg account number below, into the Account Number field.
              <div className="mt-3" />
              <CodeBox loading={false} code={accountNumber} />
            </WizardList.Item>
            <WizardList.Item>
              Click on <strong className="text-body-1">Save</strong>.
            </WizardList.Item>
          </WizardList>
        </div>
        <div className="mx-auto mt-10 w-1/3 text-center">
          <Button component={Link} to="/" className="justify-center">
            Back to dashboard
          </Button>
        </div>
      </main>
    </div>
  );
}
