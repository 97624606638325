import { assign, actions, createMachine } from 'xstate';
import * as yup from 'yup';
import { capitalize } from '@crazyegginc/hatch';
import { v4 as uuid } from 'uuid';
import { megaSuperLenientUrlRegex } from '/src/utils/regex';
import { formattedCurrentDate } from '/src/utils/date';
import { yupToFormErrors } from 'formik';
import { deepEqual } from 'fast-equals';

import { balanceWeights, prepareVariantsForEdit, findDeviceID, findDeviceWidth } from '../utils';
import { ensureUrlHasProtocol, normalizeUrl, hasWildcard, isValidUrl } from '/src/utils/url';

import { newGoalMachine, editGoalMachine, initialGoalContext } from '/src/features/goals/machines/wizard';
import { variantMachine, initialVariantContext, controlVariant, variantsSchema } from './variant-editor';

import { DEVICE_TYPES } from '/src/features/_global/constants';
import { abTestTypes, DEVICE_WIDTHS, VARIANT_STATUSES } from '/src/features/ab-testing/constants';

const { SPLIT, PATCH } = abTestTypes;

const AB_TEST_DRAFT_VERSION = 1;

export function isFormattedTestName(name) {
  return /^https?:\/\/\S+ test \d{2}-\d{2}-\d{4}( \[\w+\])?( duplicate)?$/.test(name);
}

function generateTestName(payload) {
  if (payload.name && !isFormattedTestName(payload.name)) {
    return payload.name;
  } else if (/duplicate$/.test(payload.name)) {
    return payload.name;
  }

  const deviceString =
    payload.devices.length === 1
      ? ` [${capitalize(payload.devices[0].replace(DEVICE_TYPES.PHONE, DEVICE_TYPES.MOBILE).toLowerCase())}]`
      : '';

  return `${payload.matchingUrl || payload.url} test ${formattedCurrentDate()}${deviceString}`;
}

function createSplitVariant(position, weight) {
  return {
    id: uuid(),
    type: 'variant',
    position,
    redirectUrl: '',
    weight,
  };
}

const pageValidations = {
  url: yup.string(),
  matchingUrl: yup
    .string()
    .required('Please provide a URL.')
    .matches(
      megaSuperLenientUrlRegex,
      'Your URL appears to be invalid. A full URL is required. (e.g. https://www.example.com)',
    )
    .test('no_csv', 'The URL cannot contain a list of values, only a single URL is allowed.', (value) => {
      try {
        const normalizedValue = normalizeUrl(value);
        const parsedUrl = new URL(normalizedValue);
        if (parsedUrl.host.includes(',')) {
          return false;
        }
        if (parsedUrl.pathname.includes(',')) {
          return false;
        }
        return true;
      } catch {
        return false;
      }
    }),

  type: yup.string().oneOf([PATCH, SPLIT]).required(),
  devices: yup
    .array()
    .of(yup.string().oneOf([DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE]))
    .required('Please add at least one device type.')
    .min(1, 'Please add at least one device type.'),
};

export const splitVariantsValidations = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string().required(),
      type: yup.string().oneOf(['control', 'variant']),
      position: yup.number().required(),
      redirectUrl: yup.string().when(['type', 'status'], (type, status, schema) => {
        if (status === 'DELETED') {
          return schema.nullable(true);
        }
        if (type === 'variant') {
          return schema
            .required('Please provide a URL.')
            .matches(
              megaSuperLenientUrlRegex,
              'Your URL appears to be invalid. A full URL is required. (e.g. https://www.example.com)',
            )
            .test(
              'wildcards',
              'The variant URL can only contain up to the same number of wildcards as the control URL.',
              (value, context) => {
                const matchingUrl = context.from[1].value.matchingUrl;
                if (!matchingUrl || !value) {
                  return true;
                }

                const maxNumOfWildcards = matchingUrl.split('*').length - 1;
                const currentNumOfWildcards = value.split('*').length - 1;

                if (currentNumOfWildcards > maxNumOfWildcards) return false;

                return true;
              },
            )
            .test('no_csv', 'The URL cannot contain a list of values, only a single URL is allowed.', (value) => {
              try {
                const normalizedValue = normalizeUrl(value);
                const parsedUrl = new URL(normalizedValue);
                if (parsedUrl.host.includes(',')) {
                  return false;
                }
                if (parsedUrl.pathname.includes(',')) {
                  return false;
                }
                return true;
              } catch {
                return false;
              }
            });
        } else {
          return schema.nullable(true);
        }
      }),
    }),
  )
  .min(2)
  .required()
  .test('unique', 'URL redirect variant URLs must be unique.', (value, context) => {
    if (!context.parent.matchingUrl || !value || value.length === 0) {
      return true;
    }

    const controlUrl = ensureUrlHasProtocol(context.parent.matchingUrl.replace(/\/$/, ''));
    const variantUrls = value
      .filter(
        (variant) =>
          !!variant.redirectUrl &&
          variant.type.toUpperCase() === 'VARIANT' &&
          variant.status !== VARIANT_STATUSES.DELETED,
      )
      .map((variant) => ensureUrlHasProtocol(variant.redirectUrl.replace(/\/$/, '')));

    // check that variantUrls are unique and not equal to controlUrl
    const uniqueVariantUrls = [...new Set(variantUrls)];

    if (uniqueVariantUrls.length !== variantUrls.length) {
      return false;
    }

    if (uniqueVariantUrls.includes(controlUrl)) {
      return false;
    }

    return true;
  })
  .test('at-least-one-non-deleted-variant', 'You must have at least one variant.', (value) => {
    return value.findIndex((v) => v.type === 'variant' && v.status !== VARIANT_STATUSES.DELETED) >= 0;
  });

const variantsValidations = {
  variants: yup.array().when('type', {
    is: SPLIT,
    then: splitVariantsValidations,
    otherwise: variantsSchema,
  }),
};

const variantsWithWeightValidations = {
  variants: yup
    .array()
    .when('type', {
      is: SPLIT,
      then: splitVariantsValidations,
      otherwise: variantsSchema,
    })
    .test('weight', 'Weights must equal to 100%.', (value, context) => {
      if (context.parent.autoReweight !== false) {
        return true;
      }
      const totalWeight = value.map((variant) => parseFloat(variant.weight || 0)).reduce((acc, curr) => acc + curr, 0);
      return totalWeight === 100;
    }),
};

const goalsValidations = {
  goalId: yup.mixed().required(),
};

const minimumDraftSchema = yup.object().shape({
  ...pageValidations,
});

const setupSchema = yup.object().shape({
  ...pageValidations,
  variants: yup.array().when('type', {
    is: SPLIT,
    then: splitVariantsValidations,
  }),
});

const goalsSchema = yup.object().shape({
  ...pageValidations,
  ...variantsValidations,
  ...goalsValidations,
});

const weightsSchema = yup.object().shape({
  ...pageValidations,
  ...goalsValidations,
  ...variantsWithWeightValidations,
});

const reviewSchema = yup.object().shape({
  ...pageValidations,
  ...variantsWithWeightValidations,
  ...goalsValidations,
  name: yup.string().required('Please provide a name for this test.'),
  createSnapshots: yup.bool().required(),
});

// const emptyTrigger = {
//   name: '',
//   trigger: '',
//   targetUrl: '',
//   devices: [],
//   trackingOption: GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE,
//   elementSelectorType: ELEMENT_SELECTOR_TYPES.SPECIFIC,
//   expanded: true,
// };

const emptyErrors = {};

function getUrlError(payload) {
  if (payload.includes('MALFORMED_URL')) {
    return `We couldn't find any data for that webpage. Check the URL and try again.`;
  } else if (payload.includes('NO_PERMISSION')) {
    return `You don't have permission to create tests for this site.`;
  } else if (payload.includes('SITE_CREATION_FAILED')) {
    return '';
  }
  return '';
}

export function createGoalsStates(isEditing = false) {
  return {
    id: 'goals',
    initial: 'chooseGoal',
    states: {
      chooseGoal: {
        entry: [
          assign({
            step: 2,
            title: 'Set your goal',
            nextText: undefined,
            payload: (ctx) => ({
              ...ctx.payload,
              goalId: !!ctx.goal && !ctx.payload.goalId ? 'draft' : ctx.payload.goalId,
            }),
          }),
        ],
        on: {
          BACK: !isEditing
            ? [
                {
                  target: '#variants',
                  cond: (ctx) => ctx.payload.type === PATCH,
                },
                {
                  target: '#setup.selectDevice',
                  cond: (ctx) => ctx.payload.type === SPLIT,
                },
              ]
            : {
                target: '#weights',
              },
          NEXT: [
            {
              target: 'createGoal',
              cond: 'goalsValidDraft',
            },
            {
              target: '#weights',
              cond: 'goalsValid',
              actions: assign({
                goal: undefined,
              }),
            },
          ],
          SELECT: {
            target: 'chooseGoal',
            actions: [
              assign({
                payload: (context, e) => {
                  return {
                    ...context.payload,
                    goalId: e.value ? e.value.id || 'draft' : null,
                  };
                },
              }),
            ],
          },
          // FIRST_GOAL: {
          //   target: 'createGoal',
          //   actions: assign({
          //     firstGoal: true,
          //     goal: {
          //       name: '',
          //       triggers: [{ ...emptyTrigger }],
          //     },
          //   }),
          // },
          CLEAR_GOAL: {
            actions: assign({
              goal: null,
            }),
          },
          NEW: {
            target: 'createGoal',
            actions: assign({
              payload: (ctx) => ({
                ...ctx.payload,
                goalId: null,
              }),
            }),
            // actions: assign({
            //   goal: {
            //     name: '',
            //     triggers: [{ ...emptyTrigger }],
            //   },
            // }),
          },
          EDIT: {
            target: 'editGoal',
            actions: [
              assign({
                goal: (ctx, e) => ({ ...e.goal }),
                payload: (ctx) => ({
                  ...ctx.payload,
                  goalId: null,
                }),
              }),
            ],
          },
        },
      },
      createGoal: {
        // invoke a child machine for the goal creation
        // flow.
        invoke: {
          id: 'newGoal',
          src: newGoalMachine,
          data: {
            ...initialGoalContext,
            step: 2,
            title: 'Set your goal',
            nextText: 'Save new goal & continue',
            showMeta: true,
            siteId: (ctx) => ctx.siteId,
            previousPath: (ctx) => ctx.previousPath,
            payload: (ctx) => (ctx.goal ? ctx.goal : initialGoalContext.payload),
            draft: (ctx) => ctx.draft || ctx.payload,
          },
          onDone: [
            {
              target: '#weights',
              cond: (ctx, e) => !!e?.data?.goalId,
              actions: [
                assign({
                  firstGoal: undefined,
                  payload: (context, event) => ({
                    ...context.payload,
                    goalId: event.data.goalId,
                    goal: undefined,
                  }),
                  goal: undefined,
                }),
              ],
            },
            {
              target: 'chooseGoal',
              cond: (ctx, e) => e.data.reason === 'BACK' && isEditing,
              actions: assign({
                goal: (ctx, e) => {
                  return e.data.goal;
                },
              }),
            },
            {
              target: '#variants',
              cond: (ctx, e) => e.data.reason === 'BACK' && ctx.firstGoal && ctx.payload.type === PATCH,
              actions: assign({
                firstGoal: undefined,
                goal: (ctx, e) => {
                  return e.data.goal;
                },
              }),
            },
            {
              target: '#setup.selectDevice',
              cond: (ctx, e) => e.data.reason === 'BACK' && ctx.firstGoal && ctx.payload.type === SPLIT,
              actions: assign({
                firstGoal: undefined,
                goal: (ctx, e) => {
                  return e.data.goal;
                },
              }),
            },
            {
              target: 'chooseGoal',
              cond: (ctx, e) => e.data.reason === 'BACK',
              actions: assign({
                goal: (ctx, e) => {
                  return e.data.goal;
                },
              }),
            },
            {
              target: '#savingDraft',
              cond: (ctx, e) => !!e.data.goal,
              actions: assign({
                firstGoal: undefined,
                goal: (ctx, e) => {
                  return e.data.goal;
                },
              }),
            },
            {
              target: '#cancelled',
            },
          ],
        },
      },
      editGoal: {
        // invoke a child machine for editing a goal
        invoke: {
          id: 'editGoal',
          src: editGoalMachine,
          data: (ctx) => ({
            ...initialGoalContext,
            nextText: 'Save goal',
            step: 2,
            title: 'Set your goal',
            goalId: ctx.goal.id,
            previousPath: ctx.previousPath,
            // nextPage: e.goal && !e.goal ? 'chooseGoal' : undefined,
            nextPage: undefined,
          }),
          onDone: [
            {
              target: 'chooseGoal',
              cond: (ctx, e) => {
                return !!e.data && e.data.reason !== 'CANCEL';
              },
              actions: assign({
                goal: undefined,
                payload: (context, event) => ({
                  ...context.payload,
                  goal: undefined,
                  goalId: event.data.goalId,
                }),
              }),
            },
            {
              target: '#cancelled',
            },
          ],
        },
      },
    },
  };
}

// function getControlVariant(variants) {
//   return variants.find((v) => v.type === 'control');
// }

export const initialPayload = {
  matchingUrl: '',
  url: undefined,
  devices: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE],
  variants: [controlVariant],
  goalId: null, // for existing goals
  createSnapshots: true,
  type: null,
  autoReweight: null,
  patchStrategy: 'async',
};

const emptyPayload = {
  matchingUrl: '',
  url: undefined,
  devices: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE].sort(),
  variants: [controlVariant],
  goalId: null, // for existing goals
  createSnapshots: true,
  type: null,
  autoReweight: null,
  patchStrategy: 'async',
  ...initialPayload,
};

const draftToPayload = {
  id: (ctx, e) => e.payload.id,
  siteId: (ctx, e) => e.payload.siteId,
  draft: (ctx, e) => e.payload,
  payload: (ctx, e) => e.payload,
  goal: (ctx, e) => e.payload.goal,
};

export const abTestWizardMachine = ({ initial = 'setup' }) =>
  createMachine(
    {
      id: 'abTestsWizard',
      initial,
      predictableActionArguments: true,
      context: {
        step: 1,
        siteId: null,
        previousPath: '/ab-tests',
        payload: {
          ...emptyPayload,
        },
        errors: emptyErrors,
        selectedTrigger: null,
        draftVersion: AB_TEST_DRAFT_VERSION,
      },
      states: {
        syncDraft: {
          id: 'syncDraft',
          on: {
            DONE: [
              {
                target: '#variants',
                actions: assign({ nextState: undefined }),
                cond: (ctx) => ctx.nextState === '#variants',
              },
              {
                target: '#goals',
                actions: assign({ nextState: undefined }),
                cond: (ctx) => ctx.nextState === '#goals',
              },
              {
                target: '#weights',
                actions: assign({ nextState: undefined }),
                cond: (ctx) => ctx.nextState === '#weights',
              },
            ],
          },
        },
        waitingForDraft: {
          on: {
            SET_PAYLOAD: [
              {
                target: '#setup.selectDevice',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return (
                    e.payload.type === SPLIT &&
                    e.payload.variants.some(
                      (variant) => variant.type.toUpperCase() === 'VARIANT' && !isValidUrl(variant.redirectUrl),
                    )
                  );
                },
              },
              {
                target: '#setup.selectDevice',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return e.original.originalId !== null && e.original.type === SPLIT;
                },
              },
              {
                target: '#variants',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return e.original.originalId !== null;
                },
              },
              {
                target: '#weights',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return (
                    typeof e.payload.autoReweight !== 'undefined' &&
                    e.payload.autoReweight !== null &&
                    (!!e.original.goal || !!e.original.goalId)
                  );
                },
              },
              {
                target: '#goals',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return !!e.original.goal || !!e.original.goalId || !!e.payload.goalId || !!e.payload.goal;
                },
              },
              {
                target: '#variants',
                actions: [assign(draftToPayload)],
                cond: (ctx, e) => {
                  return e.payload.type === abTestTypes.PATCH && e.payload.variants.length > 1;
                },
              },
              {
                target: '#setup.selectDevice',
                actions: [assign(draftToPayload)],
              },
            ],
          },
        },
        setup: {
          id: 'setup',
          initial: 'selectType',
          exit: [
            actions.pure((ctx) => {
              // normalize URLs when exiting the setup screen
              const matchingUrl = normalizeUrl(ctx.payload.matchingUrl, { appendSlash: false });
              const url = hasWildcard(matchingUrl) ? undefined : matchingUrl;
              const variants = ctx.payload.variants.map((v) => {
                if (!v.redirectUrl) return v;
                return {
                  ...v,
                  redirectUrl: normalizeUrl(v.redirectUrl, { appendSlash: false }),
                };
              });
              return assign({
                payload: (ctx) => ({
                  ...ctx.payload,
                  matchingUrl,
                  url,
                  variants,
                }),
              });
            }),
          ],
          states: {
            selectType: {
              always: [
                {
                  target: 'enterPage',
                  cond: (ctx) => !!ctx.payload.type,
                },
              ],
              entry: [
                assign({
                  title: 'Page details',
                  step: 1,
                  nextText: undefined,
                }),
              ],
              on: {
                SET_TYPE: [
                  {
                    target: 'enterPage',
                    cond: (ctx, e) => [PATCH, SPLIT].includes(e.value),
                    actions: [
                      assign({
                        payload: (ctx, e) => ({
                          ...ctx.payload,
                          type: e.value,
                          variants: [{ ...controlVariant }, e.value === SPLIT ? createSplitVariant(1) : null].filter(
                            Boolean,
                          ),
                        }),
                      }),
                    ],
                  },
                ],
              },
            },
            enterPage: {
              entry: [
                assign({
                  title: 'Page details',
                  step: 1,
                  nextText: undefined,
                }),
              ],
              on: {
                CHANGE: [
                  {
                    target: 'queueVerifyPage',
                    cond: (ctx, e) => e.value.length > 0,
                    actions: [
                      assign({
                        payload: (ctx, e) => ({
                          ...ctx.payload,
                          [e.key]: e.value,
                        }),
                      }),
                    ],
                  },
                  {
                    target: 'enterPage',
                    actions: assign({
                      payload: (ctx, e) => ({
                        ...ctx.payload,
                        [e.key]: e.value,
                      }),
                      errors: emptyErrors,
                    }),
                  },
                ],
              },
            },
            queueVerifyPage: {
              entry: [
                assign({
                  errors: emptyErrors,
                }),
              ],
              after: {
                1000: {
                  target: 'verifyPage',
                },
              },
            },
            verifyPage: {
              always: {
                target: 'enterPage',
                cond: (ctx) => ctx.payload.matchingUrl === '',
              },
              entry: [
                assign((ctx) => {
                  const errors = getValidationErrors(setupSchema, ctx.payload);
                  return {
                    errors,
                    title: 'Verifying URL...',
                    step: 1,
                    nextText: undefined,
                  };
                }),
              ],
              on: {
                PAGE_DATA_RECEIVED: [
                  {
                    target: 'enterPage',
                    actions: [
                      assign({
                        errors: (ctx, e) => ({
                          ...ctx.errors,
                          matchingUrl: ctx.errors?.matchingUrl || getUrlError(e.payload),
                        }),
                      }),
                    ],
                    cond: (ctx) => ctx.errors?.matchingUrl,
                  },
                  {
                    target: 'selectDevice',
                    actions: [
                      assign({
                        siteId: (ctx, e) => e.payload.siteId,
                      }),
                    ],
                  },
                ],
                PAGE_DATA_FAILED: {
                  target: 'enterPage',
                  actions: [
                    assign({
                      errors: (ctx, e) => ({
                        ...ctx.errors,
                        matchingUrl: ctx.errors?.matchingUrl || getUrlError(e.payload),
                      }),
                    }),
                  ],
                },
              },
            },
            selectDevice: {
              entry: [
                assign({
                  title: 'Page details',
                  step: 1,
                  nextText: undefined,
                  payload: (ctx) => ({
                    ...ctx.payload,
                    name: generateTestName(ctx.payload),
                  }),
                }),
              ],
              on: {
                SELECT: {
                  target: 'selectDevice',
                  actions: [
                    assign((ctx, e) => {
                      const newPayload = {
                        ...ctx.payload,
                        devices: ctx.payload.devices.includes(e.value)
                          ? [...ctx.payload.devices.filter((device) => device !== e.value)]
                          : [...ctx.payload.devices, e.value].sort(),
                      };
                      const errors = getValidationErrors(setupSchema, newPayload);
                      return {
                        payload: newPayload,
                        errors,
                      };
                    }),
                    assign({
                      payload: (ctx) => ({
                        ...ctx.payload,
                        name: generateTestName(ctx.payload),
                      }),
                    }),
                  ],
                },
                NEXT: {
                  target: 'confirmSite',
                  cond: 'setupValid',
                },
              },
            },
            confirmSite: {
              entry: [
                assign({
                  nextText: `Verifying...`,
                }),
              ],
              on: {
                VALID: [
                  {
                    target: '#variants',
                    cond: (ctx) => {
                      return ctx.payload.type === PATCH;
                    },
                    actions: assign({
                      siteId: (ctx, e) => {
                        return e.payload.siteId;
                      },
                    }),
                  },
                  {
                    target: '#goals',
                    cond: (ctx) => {
                      return ctx.payload.type === SPLIT;
                    },
                    actions: assign({
                      siteId: (ctx, e) => {
                        return e.payload.siteId;
                      },
                      payload: (ctx) => ({
                        ...ctx.payload,
                        variants: ctx.payload.variants.filter((v) => {
                          if (v.type.toUpperCase() === 'VARIANT' && v.redirectUrl.trim() === '') {
                            return false;
                          }
                          return true;
                        }),
                      }),
                    }),
                  },
                ],
                ERROR: {
                  target: 'selectDevice',
                },
              },
            },
            createDraft: {
              entry: [
                assign({
                  nextText: 'Saving...',
                }),
              ],
              on: {
                SUCCESS: [
                  {
                    target: '#variants',
                    cond: (ctx) => {
                      return ctx.payload.type === PATCH;
                    },
                    actions: assign({
                      id: (ctx, e) => e.data.id,
                    }),
                  },
                  {
                    target: '#goals',
                    cond: (ctx) => {
                      return ctx.payload.type === SPLIT;
                    },
                    actions: assign({
                      id: (ctx, e) => e.data.id,
                      payload: (ctx) => ({
                        ...ctx.payload,
                        variants: ctx.payload.variants.filter((v) => {
                          if (v.type.toUpperCase() === 'VARIANT' && v.redirectUrl.trim() === '') {
                            return false;
                          }
                          return true;
                        }),
                      }),
                    }),
                  },
                  {
                    target: 'selectDevice',
                  },
                ],
                ERROR: {
                  target: 'selectDevice',
                },
              },
            },
          },
          on: {
            // global actions for #setup
            SET_TYPE: [
              {
                target: '#setup.queueVerifyPage',
                cond: (ctx, e) => [PATCH, SPLIT].includes(e.value),
                actions: [
                  assign({
                    payload: (ctx, e) => ({
                      ...ctx.payload,
                      type: e.value,
                      variants: [{ ...controlVariant }, e.value === SPLIT ? createSplitVariant(1) : null].filter(
                        Boolean,
                      ),
                    }),
                  }),
                ],
              },
            ],
            CHANGE: {
              target: '#setup.queueVerifyPage',
              actions: [
                assign({
                  payload: (ctx, e) => ({
                    ...ctx.payload,
                    [e.key]: e.value,
                  }),
                }),
              ],
            },
            ADD_SPLIT_VARIANT: {
              actions: [
                assign({
                  payload: (ctx) => {
                    return {
                      ...ctx.payload,
                      variants: [
                        ...ctx.payload.variants.map((variant) => ({ ...variant })),
                        createSplitVariant(ctx.payload.variants.length),
                      ],
                    };
                  },
                }),
              ],
            },
            CHANGE_SPLIT_VARIANT: {
              actions: [
                assign((ctx, e) => {
                  const newPayload = {
                    ...ctx.payload,
                    variants: [
                      ...ctx.payload.variants.map((v, i) => {
                        if (i === e.index) {
                          return {
                            ...v,
                            redirectUrl: e.value,
                            status: VARIANT_STATUSES.DIRTY,
                          };
                        }
                        return v;
                      }),
                    ],
                  };
                  const errors = getValidationErrors(setupSchema, newPayload);
                  return {
                    payload: newPayload,
                    errors,
                  };
                }),
              ],
            },
            DELETE_SPLIT_VARIANT: {
              actions: [
                assign((ctx, e) => {
                  const affectedVariantIdx = ctx.payload.variants.findIndex((v) => v.position === e.position);
                  const affectedVariant = { ...ctx.payload.variants.at(affectedVariantIdx) };
                  const newPayload = {
                    ...ctx.payload,
                    variants: [
                      ...ctx.payload.variants.slice(0, affectedVariantIdx),
                      {
                        ...affectedVariant,
                        status: VARIANT_STATUSES.DELETED,
                      },
                      ...ctx.payload.variants.slice(affectedVariantIdx + 1),
                    ],
                  };
                  const errors = getValidationErrors(setupSchema, newPayload);
                  return {
                    payload: newPayload,
                    errors,
                  };
                }),
              ],
            },
          },
        },
        variants: {
          id: 'variants',
          invoke: {
            id: 'variantEditor',
            src: variantMachine,
            onDone: [
              {
                target: '#goals',
                cond: (ctx, e) => e.data.reason === 'NEXT',
                actions: assign({
                  payload: (context, event) => ({
                    ...context.payload,
                    variants: [...event.data.variants],
                    url: event.data.url,
                  }),
                  variants: undefined, // clear variants if they are valid
                }),
              },
              {
                target: '#setup.selectDevice',
                cond: (ctx, e) => e.data.reason === 'BACK',
                actions: assign({
                  variants: (ctx, e) => {
                    if (!deepEqual(e.data.variants, ctx.payload.variants)) {
                      return e.data.variants;
                    }
                    return ctx.variants;
                  },
                }),
              },
              {
                target: '#savingDraft',
                cond: (ctx, e) => e.data.reason === 'CANCEL',
                actions: assign({
                  variants: (ctx, e) => {
                    return e.data.variants;
                  },
                }),
              },
              {
                target: 'cancelled',
              },
            ],
            data: (ctx) => {
              const device = ctx.payload.devices.sort(
                (a, b) => parseInt(DEVICE_WIDTHS[b]) - parseInt(DEVICE_WIDTHS[a]),
              )[0];
              const payload = ctx.variants
                ? prepareVariantsForEdit(ctx.variants, { createBlank: false })
                : prepareVariantsForEdit(ctx.payload.variants);
              const selectedVariant = payload.at(-1);
              return {
                ...initialVariantContext,
                payload,
                selectedVariant,
                step: 2,
                nextText: undefined,
                device,
                matchingUrl: ctx.payload.matchingUrl,
                url: ctx.payload.url || ctx.payload.matchingUrl,
                editor: {
                  ...initialVariantContext.editor,
                  device,
                  deviceId: findDeviceID(device),
                  screenWidth: findDeviceWidth(device),
                },
                variants: ctx.variants,
                draft: ctx.draft || ctx.payload,
              };
            },
          },
        },
        goals: createGoalsStates(),
        weights: {
          id: 'weights',
          entry: [
            assign({
              title: 'Split your traffic',
              step: 3,
              nextText: 'Next',
            }),
          ],
          on: {
            BACK: '#goals',
            NEXT: {
              target: '#review',
              cond: 'weightsValid',
            },
            SET_REWEIGHT: {
              actions: assign({
                payload: (ctx, e) => ({
                  ...ctx.payload,
                  autoReweight: e.value,
                  // clear variants.weight if value is true
                  variants: e.value
                    ? ctx.payload.variants.map((v) => ({
                        ...v,
                        weight: undefined,
                      }))
                    : ctx.payload.variants,
                }),
              }),
            },
            ADJUST_WEIGHTS: {
              actions: actions.pure((ctx, e) => {
                const newVariants = balanceWeights(e.value);

                return assign({
                  payload: () => ({
                    ...ctx.payload,
                    variants: newVariants.variants.map((v) => ({ ...v, status: VARIANT_STATUSES.DIRTY })),
                  }),
                });
              }),
            },
          },
        },
        review: {
          id: 'review',
          entry: [
            assign({
              title: 'Review your A/B Test',
              step: 4,
              nextText: 'Publish A/B test',
            }),
          ],
          on: {
            BACK: '#weights',
            CHANGE: {
              actions: [
                assign((ctx, e) => {
                  const newPayload = {
                    ...ctx.payload,
                    [e.key]: e.value,
                  };
                  const errors = getValidationErrors(reviewSchema, newPayload);
                  return {
                    payload: newPayload,
                    errors,
                  };
                }),
              ],
            },
            RESET_GOAL: {
              actions: assign({
                payload: (ctx) => ({
                  ...ctx.payload,
                  goalId: null,
                }),
              }),
            },
            CHANGE_GOAL: {
              target: '#goals',
            },
            NEXT: [
              {
                target: '#publishTest',
                cond: 'reviewPublishValid',
              },
              {
                target: 'createTest',
                cond: 'reviewValid',
              },
            ],
          },
        },
        publishTest: {
          id: 'publishTest',
          entry: [
            assign({
              title: 'Publishing your A/B Test...',
              nextText: 'Publishing...',
            }),
          ],
          on: {
            SUCCESS: {
              target: '#success',
              actions: assign({
                result: (ctx, e) => e.value,
              }),
            },
            ERROR: {
              target: '#review',
              actions: assign({
                errors: (ctx, e) => ({
                  ...ctx.errors,
                  abTest: e.error,
                }),
              }),
            },
          },
          after: {
            10000: {
              target: '#review',
              actions: assign({
                errors: (ctx) => ({
                  ...ctx.errors,
                  abTest: 'TIMEOUT',
                }),
              }),
            },
          },
        },
        success: {
          id: 'success',
          type: 'final',
        },
        cancelled: {
          id: 'cancelled',
          type: 'final',
        },
        savingDraft: {
          id: 'savingDraft',
          entry: [
            assign({
              title: 'Saving your A/B Test...',
              nextText: 'Saving...',
            }),
          ],
          on: {
            CREATE_SUCCEEDED: '#cancelled',
            SAVE_SUCCESS: '#cancelled',
            // TODO: on error states go back to last state
          },
        },
        createTest: {
          id: 'createTest',
          entry: [
            assign({
              title: 'Creating your A/B Test...',
              nextText: 'Creating...',
            }),
          ],
          on: {
            CREATE_SUCCEEDED: {
              actions: assign({
                id: (ctx, e) => e.value.id,
              }),
              target: '#publishTest',
            },
            CREATE_FAILED: '#review',
          },
        },
      },
      on: {
        NAVIGATE: [
          {
            target: 'setup.selectDevice',
            cond: (ctx, e) => e.value === 'variants' && ctx.payload.type === abTestTypes.SPLIT,
          },
          { target: 'variants', cond: (ctx, e) => e.value === 'variants' && ctx.payload.type === abTestTypes.PATCH },
        ],
        CANCEL: [{ target: '#savingDraft', cond: 'isDraftable' }, { target: '#cancelled' }],
        DRAFT: [{ target: '#savingDraft', cond: 'isDraftable' }],
      },
    },
    {
      guards: {
        isDraftable: (context) => {
          if (!context.siteId) {
            return false;
          }
          try {
            minimumDraftSchema.validateSync(context.payload);
            if (deepEqual(context.payload, context.draft)) {
              // only make it draftable if the payload is dirty and site id is available
              return false;
            }
            return true;
          } catch {
            return false;
          }
        },
        setupValid: (context) => {
          try {
            setupSchema.validateSync(context.payload);
            return true;
          } catch {
            return false;
          }
        },
        // variantsValid: (context) => {
        //   try {
        //     variantsSchema.validateSync(context.payload);
        //     return true;
        //   } catch {
        //     return false;
        //   }
        // },
        goalsValid: (context) => {
          try {
            goalsSchema.validateSync(context.payload);
            return true;
          } catch (e) {
            window.e = e;
            return false;
          }
        },
        goalsValidDraft: (context) => {
          try {
            return context.payload.goalId === 'draft';
          } catch {
            return false;
          }
        },
        weightsValid: (context) => {
          try {
            weightsSchema.validateSync(context.payload);
            return true;
          } catch {
            return false;
          }
        },
        reviewValid: (context) => {
          try {
            reviewSchema.validateSync(context.payload);
            return true;
          } catch {
            return false;
          }
        },
        reviewPublishValid: (context) => {
          try {
            reviewSchema.validateSync(context.payload);
            return Boolean(context.id);
          } catch {
            return false;
          }
        },
      },
    },
  );

/* Note: To check if the NEXT guard is passing, use the pure function supplied by the service as the third value in the atom */
/**
 * const [state, send, service] = useAtom(wizardMachineAtom);
 *
 * const canProceed = service.nextState({ type: NEXT }).changed;
 */
/* ^ `nextState` is a pure function so the interpreted function will remain unaffected */

function getValidationErrors(schema, value) {
  let errors;
  try {
    schema.validateSync(value, { abortEarly: false });
  } catch (yupErrors) {
    errors = yupToFormErrors(yupErrors);
  }
  return errors;
}
