import { Link } from 'react-router-dom';
import classNames from 'classnames';

//import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function Section({ children, className, ...props }) {
  return (
    <section className={classNames('flex w-full flex-col', className)} {...props}>
      {children}
    </section>
  );
}

function SectionHeader({ children, className, icon: Icon, color, to }) {
  return (
    <Link
      to={to}
      className={classNames('text-header-3 mb-2 flex items-center space-x-2 self-start hover:underline', className)}
    >
      {Icon && (
        <div className={classNames('flex h-8 w-8 shrink-0 items-center justify-center rounded-full', color)}>
          <Icon className="h-5 w-5 fill-current text-white" />
        </div>
      )}
      {children}
    </Link>
  );
}

function SectionBody({ children, className }) {
  return (
    <div
      className={classNames(
        'text-body-2 flex grow flex-col rounded border border-mystic-500 bg-white px-4 py-4 shadow-md',
        className,
      )}
    >
      {children}
    </div>
  );
}

function SeeMore(/*{  ...props}*/) {
  return null;
  /*return (
    <div className="flex w-full grow items-end justify-end">
      <Link className="text-link flex items-center" {...props}>
        More...
        <ArrowIcon className="ml-1 h-2.5 w-2.5 rotate-90 fill-current" />
      </Link>
    </div>
  );*/
}

Section.Header = SectionHeader;
Section.Body = SectionBody;
Section.SeeMore = SeeMore;
