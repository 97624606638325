import { ReactComponent as LoadIcon } from '@crazyegginc/hatch/dist/images/icon-load-dots.svg';
import { DropdownEvent, DropdownRow } from '../PlayerEvent';

export function LoadEvent({ timestamp, active, duration = null, event, ...rest }) {
  const formattedDuration = parseFloat(duration).toFixed(2);
  return (
    <DropdownEvent
      icon={LoadIcon}
      title="Page load"
      text={duration && <time dateTime={`PT${formattedDuration}S`}>{formattedDuration}s</time>}
      timestamp={timestamp}
      active={active}
      {...rest}
    >
      <DropdownRow
        title="Time to interactive"
        tooltipText="Content is loaded and interactive."
        duration={event.dom_interactive / 1000}
      />
      <DropdownRow
        title="DOM content loaded"
        tooltipText="HTML document is loaded, often before CSS, JS and images."
        duration={event.dom_content_load / 1000}
      />
      <DropdownRow
        title="Content page loaded"
        tooltipText="All resources have finished loading."
        duration={event.load_time / 1000}
      />
    </DropdownEvent>
  );
}
