import { useState, useCallback, useMemo, useRef } from 'react';
import { isBefore, subHours, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Button, ReactTable, Tooltip, IconButton } from '@crazyegginc/hatch';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';

import { SupportLinks } from '/src/support';
import { usePermissions, useNotifications, useModal, useMutation, useAuthContext } from '/src/hooks';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';
import { SiteSettingsColumnSection } from '/src/features/options/components/SiteSettingsColumnSection';
import { SiteSettingsSamplingInputs } from '/src/features/options/components/SiteSettingsSamplingInputs';
import { SiteSettingsModal } from '/src/features/options/components/modals/SiteSettingsModal';
import { deleteSiteAndAssociatedDataMutation } from '/src/features/options/mutations';
import { calculateRecordingRatio, percentageToNumber, checkSamplingType } from '/src/features/options/utils';
import { FEATURES } from '/src/features/_global/constants';
import { SAMPLING_TYPE, NUMBER_TYPE } from '/src/features/options/constants';

import { ReactComponent as HelpIcon } from '@crazyegginc/hatch/dist/images/icon-help-circle-outline.svg';
import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as SettingsIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as InstalledIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

export function Status({ active, isMoreThanADay, installed }) {
  /* NOTE:
    [Installed]
      installed: true,
      installed_at: some date,
      active: true,
      last_received_data_at: some date less than 24hr in the past
    [Not installed]
      installed: false,
      installed_at: null,
      active: false,
      last_received_data_at: null
    [No data] 
      installed: true,
      installed_at: some date,
      active: true,
      last_received_data: some date more than 24hr in the past
    [Inactive] 
      installed: true,
      installed_at: some date,
      active: false,
      last_received_data_at: null
  */

  let isTypeInstalled = false;
  let Icon, text, color, link, linkText;

  if (installed) {
    if (active) {
      if (isMoreThanADay) {
        Icon = WarningIcon;
        text = 'No data received in the last 24 hours';
        color = 'text-dandelion-500';
        link = '/install/check-script';
        linkText = 'Check Installation';
      } else {
        isTypeInstalled = true;
        Icon = InstalledIcon;
        text = 'Installed';
        color = 'text-lima-500';
      }
    } else {
      Icon = WarningIcon;
      text = 'Inactive';
      color = 'text-cadet-blue-500';
      link = '/install/check-script';
      linkText = 'Check Installation';
    }
  } else {
    Icon = WarningIcon;
    text = 'Not installed';
    color = 'text-carnation-500';
    link = '/install/choose-method';
    linkText = 'Install Crazy Egg';
  }

  return (
    <div className={classNames({ 'pt-2': isTypeInstalled })}>
      <div className="flex pr-0.5">
        <Icon className={`mr-2.5 h-4 w-4 fill-current ${color}`} />
        <p className="flex-1 leading-tight">{text}</p>
      </div>
      {link && (
        <Link className="text-link mt-[3px] block" to={link}>
          {linkText}
        </Link>
      )}
    </div>
  );
}

function Allowance({ allocated, used }) {
  const isOver = used > allocated;
  const allowancePercentage = isOver ? Math.floor(((used - allocated) / allocated) * 100) : 0;

  return (
    <div
      className={classNames('text-header-5 flex items-center', {
        'text-carnation-500': allowancePercentage > 30,
      })}
    >
      <span className="mr-2.5">{used.toLocaleString()}</span>
      {allowancePercentage > 15 && (
        <Tooltip
          tooltipContent={
            <div className="w-[300px] p-0.5 leading-tight">
              <p>
                If you continue with these settings, you could go over your monthly plan allowance by {used - allocated}{' '}
                recordings. Recordings will stop when you reach your monthly plan allowance.
              </p>
            </div>
          }
          placement="bottom"
        >
          <WarningIcon
            className={classNames('-mt-[2px] h-4 w-4 fill-current', {
              'text-dandelion-500': allowancePercentage > 15 && allowancePercentage <= 30,
              'text-carnation-500': allowancePercentage > 30,
            })}
          />
        </Tooltip>
      )}
    </div>
  );
}

function SamplingInputs({
  isInstalled,
  siteId,
  siteRecordingRatio,
  samplingEstimatedVisitsDay,
  samplingEstimatedMaxRecordingsDay,
  samplingType,
  samplingValue,
  siteName,
  endSessionOnBlockedUrl,
  pageTargetingEnabled,
}) {
  if (isInstalled) {
    const haveEstimated = samplingEstimatedMaxRecordingsDay || samplingEstimatedVisitsDay;

    if (siteRecordingRatio !== null && haveEstimated) {
      return (
        <SiteSettingsSamplingInputs
          siteId={siteId}
          samplingRecordingRatio={siteRecordingRatio}
          samplingEstimatedMaxRecordingsDay={samplingEstimatedMaxRecordingsDay}
          samplingEstimatedVisitsDay={samplingEstimatedVisitsDay}
          samplingType={samplingType}
          samplingValue={samplingValue}
          name={siteName}
          endSessionOnBlockedUrl={endSessionOnBlockedUrl}
          pageTargetingEnabled={pageTargetingEnabled}
        />
      );
    } else {
      return (
        <SiteSettingsColumnSection>
          <div className="text-lynch-500">Not enough data to adjust sampling rate</div>
        </SiteSettingsColumnSection>
      );
    }
  } else {
    return (
      <SiteSettingsColumnSection>
        <div className="text-lynch-500">
          <Link className="text-link mt-1" to="/install/choose-method">
            Install Crazy Egg
          </Link>{' '}
          to adjust sampling rate
        </div>
      </SiteSettingsColumnSection>
    );
  }
}

function TopTable({ canEditSites, topTableExternalWidth }) {
  const modal = useModal();

  return (
    <div className="flex">
      <div className="flex flex-1 items-center">
        {canEditSites && (
          <Button
            variant="secondary"
            onClick={() =>
              modal.show(
                // do not need addRecordingSettings, in production by default it will create
                <AddSiteModal />,
              )
            }
          >
            Add New Site
          </Button>
        )}
      </div>
      <div
        className="text-table-headers relative top-1 z-0 flex items-center rounded-t-[5px] border-x border-t border-mystic-500 bg-off-white-500 px-[30px] py-[26px] uppercase shadow-lg"
        style={{ width: topTableExternalWidth }}
      >
        <span className="mr-2.5">Monthly Recordings Sampling</span>
        <Tooltip
          tooltipContent={
            <div className="w-[340px] p-0.5 leading-tight">
              <p className="mb-1">
                Adjust the sampling rate of recordings for each website. Choose between automatic or manual control.
              </p>
              <a
                className="text-link cursor-pointer"
                href={SupportLinks.recordings.monthlyRecordingsSampling}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about sampling
              </a>
            </div>
          }
          interactive={true}
          arrowSkiddingPercent={92}
          placement="bottom"
        >
          <HelpIcon className="mb-1 h-4 w-4 fill-current text-dodger-blue-300" aria-label="hover for more info" />
        </Tooltip>
      </div>
    </div>
  );
}

export function SiteSettingsTable({ sites, loadingSites, selectedSite, resetSite }) {
  const [topTableExternalWidth, setTopTableExternalWidth] = useState('32.95%');

  const permissions = usePermissions();
  const tableRef = useRef(null);
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;
  const { capabilities } = useAuthContext();

  const modal = useModal();
  const notifications = useNotifications();
  const { mutateAsync: deleteSiteMutateAsync } = useMutation(deleteSiteAndAssociatedDataMutation);

  const removeSite = useCallback(
    (site) => {
      if (!canEditSites) return;

      modal.show(
        <DeleteConfirmationModal
          text={
            <>
              If you delete <strong className="text-body-1">{site.name}</strong>, all your data will be gone forever.
            </>
          }
          entity="site"
          onDelete={() => {
            try {
              return deleteSiteMutateAsync(
                {
                  siteId: site.id,
                },
                {
                  onError: (error) =>
                    notifications.error({ content: 'Deleting site failed.', timeout: 3000, context: { error } }),
                  onSuccess: () => {
                    notifications.success({ content: 'Site deleted successfully.', timeout: 3000 });
                    if (selectedSite?.id === site.id) {
                      resetSite();
                    }
                    modal.close();
                  },
                },
              );
            } catch {
              //noop
            }
          }}
        />,
      );
    },
    [canEditSites, modal, notifications, deleteSiteMutateAsync, resetSite, selectedSite],
  );

  const headTableLastColRef = useCallback((node) => {
    if (node !== null) {
      setTopTableExternalWidth(`${node.offsetWidth + 32}px`);
      window.addEventListener('resize', () => {
        setTopTableExternalWidth(`${node.offsetWidth + 32}px`);
      });
    } else {
      window.removeEventListener('resize', () => {
        setTopTableExternalWidth('32.95%');
      });
    }
  }, []);

  const updateEstimate = useCallback((type, number, day) => {
    switch (type) {
      case SAMPLING_TYPE.AUTO:
        return Math.round(percentageToNumber(calculateRecordingRatio(number), day));
      case NUMBER_TYPE.RATE:
        return Math.round(percentageToNumber(number, day));
      case NUMBER_TYPE.STATIC:
        return Math.round(number);
      default:
        return 0;
    }
  }, []);

  function isMoreThanADay(time) {
    return time ? isBefore(fromUnixTime(time), subHours(new Date(), 24)) : false;
  }

  const groupedSites = useMemo(() => {
    const activeSites = [];
    const inactiveSites = [];

    if (!sites?.length) return { activeSites, inactiveSites };

    sites.forEach((site) => {
      if (!site.active && site.installed) {
        inactiveSites.push(site);
      } else {
        activeSites.push(site);
      }
    });

    return {
      activeSites,
      inactiveSites,
    };
  }, [sites]);

  const totalEstimate = useCallback(() => {
    return sites
      .filter((site) => site.installed && site.sessionRecording)
      .reduce((total, site) => {
        let estimate;
        const { recordingRatio, estimatedMaxRecordingsDay, estimatedVisitsDay } = site;
        const haveEstimated = estimatedMaxRecordingsDay || estimatedVisitsDay;
        if (recordingRatio !== null && haveEstimated) {
          const typeofSampling = checkSamplingType(site?.recordingSettings?.samplingType);
          const returnEstimated =
            estimatedMaxRecordingsDay && estimatedMaxRecordingsDay > 0 ? estimatedMaxRecordingsDay : estimatedVisitsDay;
          estimate = updateEstimate(
            typeofSampling,
            typeofSampling === SAMPLING_TYPE.AUTO ? recordingRatio : site?.recordingSettings?.samplingValue,
            returnEstimated,
          );
        } else {
          estimate = 0;
        }

        return total + estimate;
      }, 0);
  }, [sites, updateEstimate]);

  const columns = useMemo(
    () => [
      {
        header: 'SITE',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-col">
            <a className="text-link" target="_blank" rel="noopener noreferrer" href={`http://${row.original.name}`}>
              {row.original.name}
            </a>
          </div>
        ),
        size: 100,
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: '',
        id: 'status',
        size: 80,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => {
          return (
            <Status
              type="installed"
              active={row.original.active}
              isMoreThanADay={isMoreThanADay(row.original.lastData)}
              installed={row.original.installed}
            />
          );
        },
      },
      {
        header: '',
        id: 'actions',
        size: 100,
        meta: {
          align: 'right',
          justify: 'right',
        },
        cell: ({ row }) => (
          <div className="flex flex-col">
            <div className="mr-5 flex space-x-5">
              <Button
                leftIcon={<SettingsIcon className="mr-1 h-3 w-3 fill-current" />}
                onClick={() => modal.show(<SiteSettingsModal site={row.original} />)}
                variant="secondary"
                disabled={!canEditSites}
              >
                Settings
              </Button>
              <Tooltip tooltipContent={canEditSites ? 'Delete site' : "You don't have permission for this action."}>
                <IconButton
                  icon={<RemoveIcon className="h-4 w-4 fill-current" aria-label="delete" />}
                  onClick={() => removeSite(row.original)}
                  className={classNames('h-[35px] w-[35px] rounded-[3px]', {
                    'cursor-not-allowed bg-mystic-500 text-lynch-500': !canEditSites,
                    'text-dodger-blue-500 hover:bg-solitude-500': canEditSites,
                  })}
                  disabled={!canEditSites}
                  theme="light"
                />
              </Tooltip>
            </div>
          </div>
        ),
      },
      {
        header: () => {
          const { limit } = capabilities.recordings.quotas.monthlyRecordings;

          return (
            <div ref={headTableLastColRef} className="flex h-full w-full pb-2.5 pl-[25px] pr-[5px] pt-2">
              <SiteSettingsColumnSection placement="left">
                <span className="text-body-4 leading-tight"></span>
                <SiteSettingsColumnSection.Subtitle>Plan allowance</SiteSettingsColumnSection.Subtitle>
                <span className="text-header-5">{limit?.toLocaleString() || 0}</span>
              </SiteSettingsColumnSection>
              <SiteSettingsColumnSection placement="right">
                <div className="flex items-start">
                  <Tooltip
                    tooltipContent={
                      <div className="w-[300px] p-0.5 leading-tight">
                        <p>The sampling rate is automatically adjusted to meet the quota.</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <SiteSettingsColumnSection.Subtitle withToolTip={true}>
                      Est. monthly recordings
                    </SiteSettingsColumnSection.Subtitle>
                  </Tooltip>
                </div>
                <Allowance allocated={limit} used={totalEstimate()} />
              </SiteSettingsColumnSection>
            </div>
          );
        },
        id: 'inputs',
        size: 180,
        enableResizing: false,
        meta: {
          headerCellClassnames: 'border-l border-mystic-500',
          rowCellClassnames: 'border-l border-mystic-500',
        },
        cell: ({ row }) => {
          return (
            <div className="flex h-full w-full pb-2.5 pl-[25px] pr-[5px] pt-2">
              {row.original.sessionRecording ? (
                <SamplingInputs
                  isInstalled={row.original.installed}
                  siteId={row.original.id}
                  siteRecordingRatio={row.original.recordingRatio}
                  samplingEstimatedVisitsDay={row.original.estimatedVisitsDay}
                  samplingEstimatedMaxRecordingsDay={row.original.estimatedMaxRecordingsDay}
                  samplingType={row.original?.recordingSettings?.samplingType}
                  samplingValue={row.original?.recordingSettings?.samplingValue}
                  endSessionOnBlockedUrl={row.original?.recordingSettings?.endSessionOnBlockedUrl}
                  pageTargetingEnabled={row.original?.recordingSettings?.pageTargetingEnabled}
                  siteName={row.original.name}
                />
              ) : (
                <SiteSettingsColumnSection>
                  <div className="text-lynch-500">
                    Recordings are turned off for this site.{' '}
                    {canEditSites && (
                      <>
                        Click{' '}
                        <button
                          onClick={() => modal.show(<SiteSettingsModal site={row.original} />)}
                          className="text-link"
                        >
                          Settings
                        </button>{' '}
                        to enable Recordings.
                      </>
                    )}
                  </div>
                </SiteSettingsColumnSection>
              )}
            </div>
          );
        },
      },
    ],
    [
      removeSite,
      canEditSites,
      modal,
      capabilities.recordings.quotas.monthlyRecordings,
      totalEstimate,
      headTableLastColRef,
    ],
  );

  return (
    <>
      <TopTable canEditSites={canEditSites} topTableExternalWidth={topTableExternalWidth} />
      <div className="relative z-10">
        <ReactTable
          fetching={loadingSites}
          ref={{ tableRef }}
          enableSorting={false}
          columns={columns}
          data={[...groupedSites.activeSites, ...groupedSites.inactiveSites]}
          rowPadding={true}
          rowHeight={90}
          rowCustomClassname={(row) =>
            classNames('min-h-[90px]', {
              '!border-mystic-500 !border-b after:border-none':
                groupedSites.inactiveSites.length > 0
                  ? row?.original?.id === groupedSites.activeSites.at(-1)?.id
                  : false,
            })
          }
          dynamicRowHeight={true}
        />
      </div>
    </>
  );
}
