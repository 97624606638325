import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { EditorView, basicSetup } from 'codemirror';
import { javascript, esLint } from '@codemirror/lang-javascript';
import { linter, lintGutter } from '@codemirror/lint';
import * as eslint from 'eslint-linter-browserify';

function useCodeMirror(extensions, initialValue) {
  const [element, setElement] = useState();
  const [view, setView] = useState();

  const ref = useCallback((node) => {
    if (!node) return;

    setElement(node);
  }, []);

  useEffect(() => {
    if (!element) return;

    const view = new EditorView({
      extensions: [...extensions],
      parent: element,
      doc: initialValue,
    });
    setView(view);

    return () => {
      view?.destroy();
      setView(undefined);
    };
  }, [element, extensions, initialValue]);

  return { ref, view };
}

function onUpdate(onChange) {
  return EditorView.updateListener.of((viewUpdate) => {
    if (viewUpdate.docChanged) {
      const doc = viewUpdate.state.doc;
      const value = doc.toString();
      onChange(value, viewUpdate);
    }
  });
}

export function JSCodeEditor({ value, onChange, extensions, theme, ...props }) {
  const initialValue = useRef(value);

  const ext = useMemo(() => {
    const JSEditorTheme = EditorView.theme({
      '&': {
        height: '100%',
        maxHeight: '100%',
        border: '1px solid #e1e6ef',
        borderRadius: '4px',
        overflow: 'hidden',
      },
      '&.cm-focused': {
        outline: 'none !important',
        border: '1px solid #0086e6',
      },
      ...(theme ? theme : {}),
    });

    return [
      basicSetup,
      JSEditorTheme,
      javascript({ jsx: false, typescript: false }),
      lintGutter(),
      linter(esLint(new eslint.Linter())),
      onUpdate(onChange),
      ...(extensions ? extensions : []),
    ];
  }, [onChange, extensions, theme]);

  const { ref } = useCodeMirror(ext, initialValue.current);

  return <div ref={ref} {...props} />;
}
