import { useMutation as useReactQueryMutation, useQueryClient } from '@tanstack/react-query';
import { useUrqlClient } from '/src/hooks';

export function useMutation(mutationFactory) {
  const queryClient = useQueryClient();
  const { client } = useUrqlClient();

  return useReactQueryMutation({
    ...mutationFactory({ client, queryClient }),
  });
}
