import { useEffect } from 'react';
import classNames from 'classnames';

import { ANNOUNCEMENT_TYPES } from '/src/features/_global/constants';
import { DARK_PATHS } from '/src/features/_global/constants';

const isDarkPath = DARK_PATHS.some((route) => window.location.pathname.match(route));

export function AnnouncementBanner({ severity, content }) {
  useEffect(() => {
    const links = document.querySelectorAll('.content-links a');
    links.forEach((link) => {
      if (!link.classList.contains('underline')) {
        link.classList.add('underline');
      }
      if (!link.hasAttribute('target')) {
        link.setAttribute('target', '_blank');
      }
      if (!link.hasAttribute('rel')) {
        link.setAttribute('rel', 'noopener noreferrer');
      }
      if (!link.getAttribute('href').match(/https?:\/\//)) {
        link.setAttribute('href', `http://${link.getAttribute('href')}`);
      }
    });
  }, [content]);

  if (isDarkPath) return null;

  return (
    <div
      className={classNames(
        'text-button content-links flex min-h-[30px] w-full items-center justify-center whitespace-pre px-3 py-1 text-center',
        {
          'bg-carnation-500': severity === ANNOUNCEMENT_TYPES.ALERT,
          'bg-california-500': severity === ANNOUNCEMENT_TYPES.WARNING,
          'bg-dodger-blue-500': severity === ANNOUNCEMENT_TYPES.INFO,
          'bg-lima-500': severity === ANNOUNCEMENT_TYPES.SUCCESS,
        },
      )}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
