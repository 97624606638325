import { Input, Button } from '@crazyegginc/hatch';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { useModal, useMutation, useNotifications } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

import { splitVariantsValidations } from '/src/features/ab-testing/machines/wizard';

import { abTestUpdateVariantsMutation } from '/src/features/ab-testing/mutations';

function variantsToPayload(variants) {
  return variants.map((v) => ({
    id: v.id,
    position: v.position,
    type: v.type.toLowerCase(),
    redirectUrl: v.redirectUrl,
  }));
}

const editVariantSchema = yup.object().shape({
  matchingUrl: yup.string().required(),
  variants: splitVariantsValidations,
});

export function EditSplitVariant({ abTest, variant }) {
  const index = abTest.variants.findIndex((v) => v.id === variant.id);

  const modal = useModal();
  const notifications = useNotifications();

  const { mutate, isLoading } = useMutation(abTestUpdateVariantsMutation);

  return (
    <Modal dialogClassName="!p-[30px] flex-col">
      <Modal.Title>Edit variant URL</Modal.Title>

      <Formik
        initialValues={{ matchingUrl: abTest.matchingUrl, variants: variantsToPayload(abTest.variants) }}
        validationSchema={editVariantSchema}
        onSubmit={(values) => {
          const payload = {
            id: abTest.id,
            variants: [
              { ...values.variants[index], type: values.variants[index].type.toUpperCase(), position: undefined },
            ],
          };
          mutate(payload, {
            onSuccess() {
              notifications.success({ content: `Variant URL update was successful.`, timeout: 3000 });
              modal.close();
            },
            onError() {
              notifications.error({ content: `Variant URL update failed.`, timeout: 3000 });
            },
          });
        }}
      >
        {({ errors, values, handleChange, handleBlur, isSubmitting, isValid }) => (
          <Form>
            <Input
              value={values.variants[index].redirectUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              name={`variants.${index}.redirectUrl`}
              error={errors?.variants?.[index]?.redirectUrl ?? errors?.variants}
            />

            <Modal.Actions>
              <Button disabled={isSubmitting || !isValid} type="submit">
                {isLoading ? 'Updating...' : 'Update'}
              </Button>
              <Button onClick={() => modal.close()} type="button" variant="cancel">
                Cancel
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
