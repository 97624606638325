import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const recordingsFeatureKeys = createQueryKeys('recordingsFeature', {
  recordingsGroups: ({ filters, groupBy, includeFirst, limit, site }) => [
    {
      filters,
      groupBy,
      includeFirst,
      limit,
      site,
    },
  ],
  recordingsInGroup: ({ filters, group, groupBy, field, sort, page, site }) => [
    {
      filters,
      group,
      groupBy,
      limit: 10,
      order: {
        field,
        sort,
      },
      page,
      site,
    },
  ],
  recordingsList: ({ filters, site, field, sort, limit }) => [
    {
      filters,
      site,
      order: {
        field,
        sort,
      },
      limit,
    },
  ],
  recordingsCriteriaDefinition: ({ version }) => [{ version }],
  recordingsCount: ({ filters, site }) => [{ filters, site }],
  recordingsTags: ({ fragment, site, limit }) => [{ fragment, site, limit }],
  recording: ({ hashedId }) => [{ hashedId }],
  playlistsList: ({ version, site }) => [{ site, version }],
  playlistsMostRecent: ({ site, limit }) => [{ site, limit }],
});

export const RECORDINGS_SITES_QUERY = gql`
  query SitesForRecordings {
    sitesForRecordings {
      __typename
      id
      installed
      lastData
      name
      recordingsCount
      recordingsEnabled
      lastData
      active
      metadata
    }
  }
`;

export const recordingFragment = gql`
  fragment Recording_recording on Recording {
    __typename
    id
    browser
    country {
      name
      code
    }
    os
    device
    duration
    finalValue {
      displayCurrency
      displayWorth
      originalCurrency
      originalWorth
      isConverted
    }
    firstPageName
    firstPageUrl
    hashedId
    identifier
    inProgress
    lastEventAt
    lastPageName
    lastPageUrl
    maxValue {
      displayCurrency
      displayWorth
      originalCurrency
      originalWorth
      isConverted
    }
    permissions {
      canUpgradeToView
    }
    recordedAt
    sessionId
    tags
    viewedAt
    visitedPagesCount
    visitorId
    screenHeight
    screenWidth
    referrer {
      url
    }
    siteId
    version
  }
`;

export const RECORDINGS_IDS_IN_GROUP_QUERY = gql`
  query RecordingsIdsInGroup($filters: String!, $group: String!, $groupBy: RecordingGroupBy!, $site: Int!) {
    recordingsIdsInGroup(filters: $filters, group: $group, groupBy: $groupBy, site: $site) {
      id
      viewedAt
      permissions {
        canUpgradeToView
      }
    }
  }
`;

export const REFRESH_FILTER_VERSION_QUERY = gql`
  query RefreshFilterVersion($filters: String!, $version: Int) {
    refreshFilterVersion(filters: $filters, version: $version)
  }
`;

export const recordingsGroupsQuery = ({ filters, groupBy, includeFirst, limit, site }) => ({
  ...recordingsFeatureKeys.recordingsGroups({ filters, groupBy, includeFirst, limit, site }),
  getNextPageParam: (lastPage) =>
    lastPage.recordingsGroups.hasNextPage ? lastPage.recordingsGroups.nextPageCursor : undefined,
  meta: {
    query: gql`
      query RecordingsGroups(
        $cursor: String
        $filters: String!
        $groupBy: RecordingGroupBy!
        $includeFirst: String
        $limit: Int
        $site: Int!
      ) {
        recordingsGroups(
          cursor: $cursor
          filters: $filters
          groupBy: $groupBy
          includeFirst: $includeFirst
          limit: $limit
          site: $site
        ) {
          hasNextPage
          nextPageCursor
          groups {
            id
            latestRecording
            recordingsCount
            thumbnail
            unwatchedCount
            url
          }
        }
      }
    `,
  },
});

export const recordingsInGroupQuery = ({ filters, group, groupBy, field, sort, page, site }) => ({
  ...recordingsFeatureKeys.recordingsInGroup({ filters, group, groupBy, field, sort, page, site }),
  meta: {
    query: gql`
      query RecordingsInGroup(
        $filters: String!
        $group: String!
        $groupBy: RecordingGroupBy!
        $limit: Int
        $order: RecordingsListOrder!
        $page: Int
        $site: Int!
      ) {
        recordingsInGroup(
          filters: $filters
          group: $group
          groupBy: $groupBy
          limit: $limit
          order: $order
          page: $page
          site: $site
        ) {
          page
          recordings {
            ...Recording_recording
          }
        }
      }
      ${recordingFragment}
    `,
  },
});

export const recordingsListQuery = ({ filters, site, field, sort, limit }) => ({
  ...recordingsFeatureKeys.recordingsList({ filters, site, field, sort, limit }),
  getNextPageParam: (lastPage) =>
    lastPage.recordingsList.hasNextPage ? lastPage.recordingsList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query RecordingsListQuery(
        $cursor: String
        $filters: String!
        $order: RecordingsListOrder!
        $site: Int!
        $limit: Int
      ) {
        recordingsList(cursor: $cursor, filters: $filters, order: $order, site: $site, limit: $limit) {
          hasNextPage
          nextPageCursor
          recordings {
            ...Recording_recording
          }
        }
      }
      ${recordingFragment}
    `,
  },
});

export const recordingsCriteriaDefinitionQuery = ({ version }) => ({
  ...recordingsFeatureKeys.recordingsCriteriaDefinition({ version }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query RecordingsCriteriaDefinition($version: Int) {
        recordingsCriteriaDefinition(version: $version)
      }
    `,
  },
});

export const recordingsCountQuery = ({ filters, site }) => ({
  ...recordingsFeatureKeys.recordingsCount({ filters, site }),
  meta: {
    query: gql`
      query RecordingsCount($filters: String!, $site: Int!) {
        recordingsCount(filters: $filters, site: $site)
      }
    `,
  },
});

export const recordingsTagsQuery = ({ fragment, site, limit }) => ({
  ...recordingsFeatureKeys.recordingsTags({ fragment, site, limit }),
  meta: {
    query: gql`
      query RecordingsTagsQuery($fragment: String!, $site: Int!, $limit: Int) {
        recordingsTags(fragment: $fragment, site: $site, limit: $limit)
      }
    `,
  },
});

export const recordingQuery = ({ hashedId }) => ({
  ...recordingsFeatureKeys.recording({ hashedId }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query RecordingQuery($hashedId: String!) {
        recording(hashedId: $hashedId) {
          skipRecordingCursor
          recording {
            ...Recording_recording
            sessionToken
            userId
          }
          userId
        }
      }
      ${recordingFragment}
    `,
  },
});

export const playlistsListQuery = ({ version, site }) => ({
  ...recordingsFeatureKeys.playlistsList({ version, site }),
  meta: {
    query: gql`
      query PlaylistsList($site: Int!, $version: Int) {
        playlistsList(site: $site, version: $version) {
          id
          type
          name
          filter
        }
      }
    `,
  },
});

export const playlistsMostRecentQuery = ({ site, limit }) => ({
  ...recordingsFeatureKeys.playlistsMostRecent({ site, limit }),
  meta: {
    query: gql`
      query PlaylistsMostRecent($site: Int!, $limit: Int) {
        playlistsMostRecent(site: $site, limit: $limit) {
          id
          name
        }
      }
    `,
  },
});
