import { useState, useMemo, Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Combobox, Transition } from '@headlessui/react';
import { Indicator, Spinner, Divider } from '@crazyegginc/hatch';

import { useSelectedSite } from '/src/hooks';
import { goalListQuery } from '/src/features/goals/queries';
import { WebP } from './WebP';
import { GOAL_TRIGGERS_METADATA } from '/src/features/goals/constants';
import { GoalTriggerSummary } from '/src/features/goals/utils';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import BlockPNG from '@crazyegginc/hatch/dist/images/illustration-block.png';
import BlockWebP from '@crazyegginc/hatch/dist/images/illustration-block.webp';

export function SearchableGoalSelector({
  error,
  value,
  onSelect,
  disabled,
  filterIds = [],
  showNoGoalOption = true,
  displayIfNoGoals,
}) {
  const { selectedSite } = useSelectedSite();
  const [query, setQuery] = useState('');

  const {
    data: goalsData,
    isFetching: fetchingGoals,
    isInitialLoading,
  } = useQuery({
    ...goalListQuery({ siteId: selectedSite?.id, limit: -1 }),
    enabled: Boolean(selectedSite?.id),
  });

  const goals = useMemo(
    () =>
      goalsData?.goalList?.list
        .filter((x) => !filterIds.includes(x.id))
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)) ?? [],
    [goalsData, filterIds],
  );

  const selectedGoal = goals.find((g) => g.id === value) ?? null;

  const filteredGoals =
    query === ''
      ? goals
      : goals.filter((goal) =>
          goal.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  useEffect(() => {
    if (value && !fetchingGoals && !disabled && !selectedGoal) {
      onSelect?.(null);
    }
  }, [onSelect, disabled, selectedGoal, fetchingGoals, value]);

  if (!goals.length && !value && !fetchingGoals) {
    if (displayIfNoGoals == null) return displayIfNoGoals;
  }

  const displayValue = (goal) => {
    if (value != null && !selectedGoal) return 'The goal has been deleted';
    if (!fetchingGoals && !goals.length) {
      return displayIfNoGoals;
    }
    if (goal) return goal.name;
    if (showNoGoalOption) return 'No Goal';
    return 'Select a Goal';
  };

  return (
    <div className="text-body-2 w-full">
      <Combobox
        value={selectedGoal}
        onChange={onSelect}
        nullable
        by="id"
        disabled={disabled || (!goals.length && !fetchingGoals)}
      >
        {({ open, value }) => (
          <div className="relative">
            <div
              className={classNames(
                'text-body-4 relative h-10 w-full cursor-default rounded border text-left focus:outline-none',
                {
                  'focus:border-dodger-blue-500': !error,
                  'border-dodger-blue-500': open && !error,
                  'border-mystic-500': !open && !error,
                  'bg-white text-black-pearl-500': !disabled,
                  'bg-white-lilac-500 text-lynch-500': disabled,
                  'text-lynch-500': value === null,
                  'border-carnation-500 focus:border-carnation-500': !!error,
                },
              )}
            >
              {isInitialLoading ? (
                <div className="flex h-full w-full items-center justify-center space-x-2.5">
                  <Spinner />
                  <span className="text-lynch-500">loading goals...</span>
                </div>
              ) : (
                <>
                  <Combobox.Input
                    className="w-full rounded border-none py-2 pl-3 pr-9 text-sm leading-5 focus:ring-0"
                    displayValue={displayValue}
                    onChange={(event) => setQuery(event.target.value)}
                    onFocus={(e) => e.target.select()}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <Indicator type="dropdown" />
                  </Combobox.Button>
                </>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredGoals.length === 0 && query !== '' ? (
                  <div className="text-body-3 relative flex w-full cursor-default select-none justify-center px-4 py-2">
                    Nothing found.
                  </div>
                ) : (
                  <>
                    {!!showNoGoalOption && query === '' && (
                      <>
                        <Combobox.Option
                          className={({ active, disabled }) =>
                            classNames('relative cursor-default select-none py-2 pl-10 pr-4', {
                              'bg-solitude-500': active,
                              'bg-white-lilac-500 text-lynch-500': disabled,
                            })
                          }
                          value={null}
                        >
                          {({ selected }) => (
                            <>
                              <GoalTitle webp={BlockWebP} png={BlockPNG} name="No Goal" />
                              {selected ? (
                                <span className={`absolute inset-y-0 left-0 z-20 flex items-center pl-3`}>
                                  <TickIcon className="h-2.5 w-2.5 fill-current text-dodger-blue-500" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                        <Divider dashed className="!w-auto last:hidden" />
                      </>
                    )}
                    {filteredGoals.map((goal) => (
                      <Fragment key={goal.id}>
                        <Combobox.Option
                          className={({ active, disabled }) =>
                            classNames('relative cursor-default select-none py-2 pl-10 pr-4', {
                              'bg-solitude-500': active,
                              'bg-white-lilac-500 text-lynch-500': disabled,
                            })
                          }
                          value={goal}
                        >
                          {({ selected }) => (
                            <>
                              <GoalTitle
                                webp={
                                  GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.webp ??
                                  GOAL_TRIGGERS_METADATA.OTHER.webp
                                }
                                png={
                                  GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.png ??
                                  GOAL_TRIGGERS_METADATA.OTHER.png
                                }
                                name={goal.name}
                              />
                              <div className="ml-[55px]">
                                <GoalTriggerSummary
                                  goal={goal}
                                  textOnly={true}
                                  showMaxTriggers={1}
                                  expandBehavior="info"
                                />
                              </div>
                              {selected ? (
                                <span className={`absolute inset-y-0 left-0 z-20 flex items-center pl-3`}>
                                  <TickIcon className="h-2.5 w-2.5 fill-current text-dodger-blue-500" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                        <Divider dashed className="!w-auto last:hidden" />
                      </Fragment>
                    ))}
                  </>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
      {!!error && <div className="text-error absolute">{error}</div>}
    </div>
  );
}

function GoalTitle({ webp, png, name, clamp }) {
  return (
    <div className="flex items-center">
      <WebP className="w-[40px]" webp={webp} fallback={png} width="40" alt="goal type" />
      <span
        className={classNames('text-body-1 ml-3.75', {
          'line-clamp-2': clamp,
        })}
      >
        {name}
      </span>
    </div>
  );
}
