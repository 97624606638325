import { useRef, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { ReactTable, Checkbox, Tooltip, IconButton, capitalize } from '@crazyegginc/hatch';

import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { formatDate, dateDistance } from '/src/utils/date';

import { useFilterSharing } from '../../account-sharing-filter-context';

import { useMutation, useSelection, useNotifications, useModal } from '/src/hooks';
import { CopyURLModal } from './modals/CopyURLModal';
import { RegenerateUrlModal } from './modals/RegenerateUrlModal';
import { UpdateUrlStatusModal } from './modals/UpdateUrlStatusModal';

import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { START_URLS_SHARING_TIMESTAMP, SHARABLE_RESOURCE_TYPES, SHARED_URL_STATUSES } from '../../constants';

import { regenerateSharedUrlListMutation, updateSharedUrlListMutation } from '/src/features/team-and-sharing/mutations';

import { ReactComponent as SnapshotsIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as ABTestIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as CTAIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as GoalIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as PausedIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as GenerateNewUrlIcon } from '@crazyegginc/hatch/dist/images/icon-switch-outline.svg';

export function AccountUserShareTable({ sharedUrls, fetching, shareStatus }) {
  const tableRef = useRef(null);
  const filters = useFilterSharing(shareStatus);
  const notifications = useNotifications();
  const modal = useModal();

  const { mutate: regenerateSharedUrlListMutate } = useMutation(regenerateSharedUrlListMutation);
  const { mutate: updateSharedUrlListMutate } = useMutation(updateSharedUrlListMutation);

  const { currentSelection, addToSelection, toggleSelection, removeFromSelection } = useSelection();
  const filteredCurrentSelection = currentSelection.filter((selection) => selection.status === shareStatus);

  // MUTATIONS:START
  const handleRegenerateUrl = useCallback(
    (url) => {
      regenerateSharedUrlListMutate(
        {
          ids: [url.id],
        },
        {
          onError: (error) =>
            notifications.error({ content: 'Failed to regenerate url.', timeout: 3000, context: { error } }),
          onSuccess: (data) => modal.show(<CopyURLModal newShareUrl={data.regenerateSharedUrlList[0].fullPath} />),
        },
      );
    },
    [regenerateSharedUrlListMutate, notifications, modal],
  );

  const handleStatusUpdate = useCallback(
    (sharedUrlData, shareStatus) => {
      const changeCurrentStatus =
        shareStatus === SHARED_URL_STATUSES.ENABLED ? SHARED_URL_STATUSES.DISABLED : SHARED_URL_STATUSES.ENABLED;
      const notificationText = shareStatus === SHARED_URL_STATUSES.ENABLED ? 'paused' : 'resumed';

      updateSharedUrlListMutate(
        {
          sharedUrls: [{ id: sharedUrlData.id, status: changeCurrentStatus, note: sharedUrlData.note }],
        },
        {
          onError: (error) =>
            notifications.error({
              content: `Failed to ${notificationText} share link.`,
              timeout: 3000,
              context: { error },
            }),
          onSuccess: () =>
            notifications.success({ content: `Sharing link was ${notificationText} successfully.`, timeout: 3000 }),
          onSettled: () => modal.close(),
        },
      );
    },
    [modal, notifications, updateSharedUrlListMutate],
  );
  // MUTATIONS:END

  const columns = useMemo(
    () => [
      {
        header: () => (
          <div>
            <Checkbox
              checked={
                filteredCurrentSelection.length && filteredCurrentSelection.length === sharedUrls.length ? true : false
              }
              onChange={() => {
                filteredCurrentSelection.length === sharedUrls.length
                  ? removeFromSelection([...sharedUrls])
                  : sharedUrls.every((x) => filteredCurrentSelection.find((y) => x.id === y.id))
                    ? removeFromSelection([...sharedUrls])
                    : addToSelection([...sharedUrls]);
              }}
              indeterminate={filteredCurrentSelection.length > 0 && filteredCurrentSelection.length < sharedUrls.length}
              title="Toggle all rows selected"
              size="lg"
            />
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div>
              <Checkbox
                checked={currentSelection.findIndex((x) => x.id === row.original.id) > -1}
                onChange={() => toggleSelection(row.original)}
                size="lg"
              />
            </div>
          );
        },
        id: 'selection',
        minSize: 30,
        maxSize: 30,
        meta: {
          align: 'center',
        },
        enableSorting: false,
      },
      {
        header: 'page / link shared',
        accessorKey: 'title',
        size: 250,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function titleCell({ row }) {
          const urlFullPath = row?.original?.fullPath?.toLowerCase() ?? '';
          return (
            <Tooltip
              interactive={true}
              tooltipContent={
                <div className="max-w-[250px] break-words text-left">
                  <div className="text-md">{row.original.title}</div>
                  <div className="text-2xs text-mystic-500">{urlFullPath}</div>
                </div>
              }
            >
              <div className="flex min-w-0 flex-col pr-1">
                <span
                  className={classNames('text-link mb-0.5 flex items-center truncate !underline', {
                    '!text-black-pearl-500': shareStatus !== SHARED_URL_STATUSES.ENABLED,
                  })}
                >
                  {shareStatus === SHARED_URL_STATUSES.ENABLED ? (
                    <a href={urlFullPath} className="text-link" target="_blank" rel="noopener noreferrer">
                      {row.original.title}
                    </a>
                  ) : (
                    <span className="cursor-default">{row.original.title}</span>
                  )}
                </span>
                <span className="text-body-4 truncate">{urlFullPath}</span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'itemType',
        size: 80,
        meta: {
          align: 'center',
        },
        cell: function TypeCell({ row }) {
          return <ItemStatusIcon type={row.original.itemType} />;
        },
      },
      {
        header: 'Link Created',
        accessorKey: 'createdAt',
        size: 125,
        meta: {
          align: 'center',
        },
        cell: function CreatedAtCell({ row }) {
          return formatDate(row.original.createdAt);
        },
      },
      {
        header: 'Created by',
        accessorKey: 'sharedByUserName',
        size: 150,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function CreatedByCell({ row }) {
          return (
            <div className="flex min-w-0 flex-col pr-1">
              <span className="mb-0.5 truncate">{row.original.sharedByUserName}</span>
            </div>
          );
        },
      },
      {
        header: 'Link Views',
        accessorKey: 'totalViews',
        size: 80,
        meta: {
          align: 'center',
        },
        enableSorting: false,
        cell: function ViewsCell({ row }) {
          if (row.original.createdAt < START_URLS_SHARING_TIMESTAMP) {
            return (
              <Tooltip
                tooltipContent={
                  <div className="max-w-[124px]">
                    No Link view data collected before
                    <br /> {formatDate(START_URLS_SHARING_TIMESTAMP)}
                  </div>
                }
              >
                <span className="cursor-default">{row.original.totalViews || 0}*</span>
              </Tooltip>
            );
          } else {
            return row.original.totalViews || 0;
          }
        },
      },
      {
        header: 'Last Viewed',
        accessorKey: 'lastViewedAt',
        size: 125,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function LastViewedCell({ row }) {
          return row.original.lastViewedAt ? dateDistance(row.original.lastViewedAt) : null;
        },
      },
      {
        id: 'Actions',
        size: 80,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function AccountUserActionsCell({ row }) {
          return (
            <AccountUserShareActions
              isActive={shareStatus}
              onRegenerate={() => handleRegenerateUrl(row.original)}
              onStatusUpdate={() => handleStatusUpdate(row.original, shareStatus)}
            />
          );
        },
      },
    ],
    [
      addToSelection,
      sharedUrls,
      currentSelection,
      toggleSelection,
      shareStatus,
      removeFromSelection,
      handleRegenerateUrl,
      handleStatusUpdate,
      filteredCurrentSelection,
    ],
  );

  const tableSortData = useMemo(
    () => ({
      id: snakeToCamel(filters.order.field),
      desc: filters.order.sort === SORT_ORDER_TYPES.DESC,
    }),
    [filters.order.field, filters.order.sort],
  );

  const { setOrder, setSort } = filters;

  const onFetchData = useCallback(
    ({ sorting = null }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setOrder(nextField);

        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      columns={columns}
      data={sharedUrls}
      ref={{ tableRef }}
      rowPadding={true}
      rowHeight={65}
      fetching={fetching}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
    />
  );
}

function AccountUserShareActions({ isActive, onRegenerate, onStatusUpdate }) {
  const modal = useModal();

  return (
    <div
      className={classNames(
        'flex w-full max-w-[80px] items-stretch justify-between px-1',
        'invisible group-hover:visible',
      )}
    >
      <Tooltip style={{ maxWidth: '75px' }} tooltipContent="Generate new url">
        <IconButton
          icon={<GenerateNewUrlIcon className="h-4 w-4 fill-current" />}
          className="h-full text-malibu-500 hover:text-dodger-blue-500"
          label="Generate new url"
          onClick={() => modal.show(<RegenerateUrlModal onRegenerate={onRegenerate} />)}
        />
      </Tooltip>
      {isActive === SHARED_URL_STATUSES.ENABLED ? (
        <Tooltip style={{ maxWidth: '85px' }} tooltipContent="Pause link sharing">
          <IconButton
            icon={<PausedIcon className="h-4 w-4 fill-current" />}
            className="h-full text-malibu-500 hover:text-dodger-blue-500"
            onClick={() => modal.show(<UpdateUrlStatusModal status={isActive} onStatusUpdate={onStatusUpdate} />)}
            label="Pause link sharing"
          />
        </Tooltip>
      ) : (
        <Tooltip style={{ maxWidth: '85px' }} tooltipContent="Resume link sharing">
          <IconButton
            icon={<PlayIcon className="h-4 w-4 fill-current" />}
            className="h-full text-malibu-500 hover:text-dodger-blue-500"
            onClick={() => modal.show(<UpdateUrlStatusModal status={isActive} onStatusUpdate={onStatusUpdate} />)}
            label="Resume link sharing"
          />
        </Tooltip>
      )}
    </div>
  );
}

const { SNAPSHOT, RECORDING, ABTEST, ERROR, SURVEY, CTA, GOAL } = SHARABLE_RESOURCE_TYPES;
function ItemStatusIcon({ type }) {
  let Icon;
  let text = capitalize(type);
  switch (type) {
    case SNAPSHOT:
      Icon = SnapshotsIcon;
      break;
    case RECORDING:
      Icon = RecordingsIcon;
      break;
    case ABTEST:
      Icon = ABTestIcon;
      text = 'A/B Test';
      break;
    case ERROR:
      Icon = ErrorIcon;
      break;
    case SURVEY:
      Icon = SurveyIcon;
      break;
    case CTA:
      Icon = CTAIcon;
      text = 'CTA';
      break;
    case GOAL:
      Icon = GoalIcon;
      break;
    default:
      return null;
  }
  return (
    <Tooltip tooltipContent={text}>
      <Icon className="h-4 w-4 fill-current text-cadet-blue-500" />
    </Tooltip>
  );
}
