import { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Popover, StyledPopoverPanel, Button, PopoverItem, Spinner, Indicator } from '@crazyegginc/hatch';

import { useMutation, useNotifications, useAuthContext } from '/src/hooks';

import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';
import { updateAccountUsersMutation } from '/src/features/team-and-sharing/mutations';
import { isAccountOwner, userRoleTextAndClassName } from '../../utils';

export function AccessLevelDropdown(user, siteList) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const updateAccountUsers = useMutation(updateAccountUsersMutation);
  const notifications = useNotifications();
  const { currentUser } = useAuthContext();

  const handleUpdateAccountUser = (userId, prevUserRole, prevSpecificsSites, selectedRole) => {
    const isSpecificSites =
      user.specificSites == false ? false : selectedRole.toUpperCase() !== ACCOUNT_USER_ROLES.OWNER;
    const isPrevRoleOwner = prevUserRole.toUpperCase() === ACCOUNT_USER_ROLES.OWNER;
    if (prevUserRole.toUpperCase() === selectedRole.toUpperCase()) {
      return null;
    }
    updateAccountUsers.mutate(
      {
        ids: [userId],
        role: selectedRole,
        sites: !isPrevRoleOwner && isSpecificSites ? user.sites : siteList,
        specificSites: !isPrevRoleOwner && isSpecificSites && prevSpecificsSites,
      },
      {
        onError: (error) =>
          notifications.error({ content: `Failed to update member.`, timeout: 3000, context: { error } }),
        onSuccess: () => notifications.success({ content: `Member updated.`, timeout: 3000 }),
      },
    );
  };

  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [popper.width / 2 - reference.width / 2, 10];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover className="relative w-full">
      {({ open }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              as={Button}
              disabled={
                isAccountOwner(user.role) ||
                currentUser.email.toLowerCase() === user.email.toLowerCase() ||
                updateAccountUsers.isLoading
              }
              variant="secondary"
              size="lg"
              className={classNames('!w-[130px] justify-between capitalize', {
                '!border-dodger-blue-500': open,
              })}
            >
              {updateAccountUsers.isLoading ? (
                <div className="flex items-center">
                  <Spinner className="mr-1.5 h-3 w-3" />
                  Updating...
                </div>
              ) : (
                <>
                  {userRoleTextAndClassName(user.role)[0]}
                  {isAccountOwner(user.role) || currentUser.email.toLowerCase() === user.email.toLowerCase() ? null : (
                    <Indicator type="expand" className="ml-auto" />
                  )}
                </>
              )}
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                onMouseDown={(e) => e.stopPropagation()}
                align="left"
                className="w-[170px]"
                ref={setTooltipElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {({ close }) => (
                  <>
                    <PopoverItem
                      autoWidth
                      onClick={() => {
                        handleUpdateAccountUser(user.id, user.role, user.specificSites, ACCOUNT_USER_ROLES.OWNER);
                        close();
                      }}
                    >
                      Admin
                    </PopoverItem>
                    <PopoverItem
                      autoWidth
                      onClick={() => {
                        handleUpdateAccountUser(user.id, user.role, user.specificSites, ACCOUNT_USER_ROLES.MANAGER);
                        close();
                      }}
                    >
                      Member
                    </PopoverItem>
                    <PopoverItem
                      autoWidth
                      onClick={() => {
                        handleUpdateAccountUser(user.id, user.role, user.specificSites, ACCOUNT_USER_ROLES.READ_ONLY);
                        close();
                      }}
                    >
                      Read only
                    </PopoverItem>
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </>
      )}
    </Popover>
  );
}
