import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { DashboardPage } from '/src/components/Page';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SEO } from '/src/components/SEO';
import { SitePickerBar } from '/src/components/site-picker';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { FilterProvider } from '../live-filter-context';
import { LiveContent } from '/src/features/live-activity/components/LiveContent';
import { LiveNotInstalled } from '../components/paywalls/LiveNotInstalled';
import { SessionTimeCounter } from '/src/features/live-activity/components/SessionTimeCounter';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

import { useSite, usePermissions } from '/src/hooks';

// eslint-disable-next-line no-restricted-syntax
export default function LiveDashboard() {
  const [connected, setConnected] = useState(false);
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.LIVE_ACTIVITY);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.LIVE_ACTIVITY} reason={reason} />;
  }

  return (
    <DashboardPage mainClass="!pb-0">
      <SEO title={getFeatureName(FEATURES.LIVE_ACTIVITY)} />
      <DashHeader
        title={getFeatureName(FEATURES.LIVE_ACTIVITY)}
        actionButton={<SessionTimeCounter start={connected} />}
      />
      <LiveDashboardContent connected={connected} setConnected={setConnected} />
    </DashboardPage>
  );
}

function LiveDashboardContent({ connected, setConnected }) {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const [paused, setPaused] = useState(false);
  const { sites, loadingSites, selectedSite, selectSite, reloadSites } = useSite();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  return (
    <>
      <SitePickerBar
        loading={loadingSites}
        sites={sites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        addSiteOption={canEditSites}
        pageDisplayName={getFeatureName(FEATURES.LIVE_ACTIVITY)}
      />
      {!sites.length && !loadingSites && (
        <AddSiteModal
          customTitle="Add a site"
          onExtraSteps={() => {
            reloadSites();
          }}
          onCancel={() => navigate('/snapshots', { replace: true })}
        />
      )}
      {selectedSite && !loadingSites && (
        <div
          className={classNames('flex flex-col border-[10px] px-[30px] pt-[30px]', {
            'border-regal-blue-500': paused,
            'border-transparent': !paused,
          })}
        >
          {selectedSite.installed ? (
            <FilterProvider>
              <LiveContent connected={connected} setConnected={setConnected} paused={paused} setPaused={setPaused} />
            </FilterProvider>
          ) : (
            <LiveNotInstalled />
          )}
        </div>
      )}
    </>
  );
}
