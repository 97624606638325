import { useFormikContext } from 'formik';
import { Popover, Indicator } from '@crazyegginc/hatch';

import { QuestionSelector } from '../dropdowns/QuestionSelector';
import { generateInitialQuestionSchema } from '../SurveyEditor';
import { STATIC_DISPLAY_SUBTYPES } from '/src/features/addons/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function AddQuestionButton({ questionActions }) {
  const { values } = useFormikContext();

  return (
    <Popover className="relative ml-10 flex flex-col">
      <>
        <Popover.Button className="group mt-5 flex h-11 items-center rounded-[5px] border border-mystic-500 bg-white-lilac-500 px-5 focus-visible:outline-black">
          <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
          Add Question
          <Indicator type="dropdown" className="ml-2.5 text-cadet-blue-500 group-hover:text-lynch-500" />
        </Popover.Button>
        <QuestionSelector
          onSelection={(type, subtype) => {
            if (values.questions[values.questions.length - 1]?.subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU) {
              // if has thank you, then insert before it
              questionActions.insert(values.questions.length - 1, generateInitialQuestionSchema(type, subtype));
            } else {
              // otherwise add to the end
              questionActions.push(generateInitialQuestionSchema(type, subtype));
            }
          }}
        />
      </>
    </Popover>
  );
}
