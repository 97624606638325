import { Button, Spinner } from '@crazyegginc/hatch';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@xstate5/react';

import { useMutation, usePermissions, useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { deleteRecordingsMutation } from '/src/features/recordings/mutations';
import { getPlayerPlaylistQueryParams } from '/src/utils/url';

import { FEATURES } from '/src/features/_global/constants';

export function PlayerSessionError({ actorRef }) {
  const permissions = usePermissions();
  const modal = useModal();
  const navigate = useNavigate();
  const deleteRecordings = useMutation(deleteRecordingsMutation);

  const nextRecording = useSelector(actorRef, (state) => state.context.nextRecording);
  const recording = useSelector(actorRef, (state) => state.context.recording);

  const canDelete = recording && permissions.can('delete', recording).allowed;

  return (
    <Modal dialogClassName="!p-6 !max-w-[400px]" dark>
      <Modal.Title>Whoops!</Modal.Title>

      <p className="text-body-2 leading-5 text-white">It looks like there was an issue recording this session.</p>

      {deleteRecordings.isError ? (
        <div className="mt-5">
          <p className="text-error leading-5">We could not delete your recording.</p>
          <p className="text-error leading-5">Please check your network connection and try again.</p>
        </div>
      ) : null}

      <Modal.Actions>
        {canDelete ? (
          <Button
            variant="warning"
            theme="dark"
            onClick={async () => {
              deleteRecordings.mutate(
                {
                  recordingIds: [recording.id],
                  site: recording.siteId,
                },
                {
                  onSuccess: () => {
                    if (nextRecording) {
                      window.location.replace(
                        `/recordings/${nextRecording.hashedId}/player?${getPlayerPlaylistQueryParams()}`,
                      );
                      modal.close();
                    } else {
                      navigate(`/recordings?${getPlayerPlaylistQueryParams()}`, { replace: true });
                      modal.close();
                    }
                  },
                },
              );
            }}
            disabled={deleteRecordings.isLoading}
            className="!flex !items-center !justify-center !space-x-2.5"
          >
            {deleteRecordings.isLoading ? (
              <>
                <Spinner />
                <span>Deleting...</span>
              </>
            ) : (
              'Delete this recording'
            )}
          </Button>
        ) : (
          <GoToDashButton />
        )}
        {deleteRecordings.isError && !!nextRecording ? <GoToDashButton /> : null}
        {deleteRecordings.isError && !!nextRecording ? <PlayNextRecordingButton nextRecording={nextRecording} /> : null}
      </Modal.Actions>
    </Modal>
  );
}

function GoToDashButton() {
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.RECORDINGS).allowed;
  const navigate = useNavigate();
  const modal = useModal();

  if (!canVisitDashboard) return null;

  return (
    <Button
      theme="dark"
      onClick={() => {
        navigate(
          {
            pathname: `/recordings`,
            search: getPlayerPlaylistQueryParams(),
          },
          { replace: true },
        );
        modal.close();
      }}
    >
      Go to dashboard
    </Button>
  );
}

function PlayNextRecordingButton({ nextRecording }) {
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <Button
      theme="dark"
      onClick={() => {
        navigate(
          {
            pathname: `/recordings/${nextRecording.hashedId}/player`,
            search: getPlayerPlaylistQueryParams(),
          },
          { replace: true },
        );
        modal.close();
      }}
    >
      Play next recording
    </Button>
  );
}
