import { Paywall } from '/src/components/Paywall';

import NoSharedLinkPNG from '@crazyegginc/hatch/dist/images/illustration-envelope-share-bg.png';
import NoSharedLinkWebP from '@crazyegginc/hatch/dist/images/illustration-envelope-share-bg.webp';

export function NoSharedUrls() {
  return (
    <Paywall>
      <Paywall.Image webp={NoSharedLinkWebP} fallback={NoSharedLinkPNG} width="222" />
      <Paywall.Title>You haven&apos;t shared anything yet.</Paywall.Title>
      <Paywall.Body>
        <div className="w-[295px]">
          Any links your team shares will appear here, so you can manage them all in one place.
        </div>
      </Paywall.Body>
    </Paywall>
  );
}
