import { useEffect } from 'react';
import { useActor } from '@xstate/react';
import { useQuery } from '@tanstack/react-query';
import { Spinner, Button } from '@crazyegginc/hatch';

import { useNotifications, useMutation, useSite } from '/src/hooks';

import Wizard from '/src/components/wizard/Wizard';

import { goalDetailQuery } from '/src/features/goals/queries';
import { goalCreateMutation, goalEditMutation } from '/src/features/goals/mutations';
import { abTestUpdateMutation } from '/src/features/ab-testing/mutations';

import { GoalValueWizard } from './GoalValueWizard';
import { GoalTriggerWizard } from './GoalTriggerWizard';

// import ShoppingBagsPNG from '@crazyegginc/hatch/dist/images/illustration-shopping-bags.png';
// import ShoppingBagsWebP from '@crazyegginc/hatch/dist/images/illustration-shopping-bags.png';
// import HandshakePNG from '@crazyegginc/hatch/dist/images/illustration-handshake2.png';
// import HandshakeWebP from '@crazyegginc/hatch/dist/images/illustration-handshake2.webp';
// import PageviewsPNG from '@crazyegginc/hatch/dist/images/illustration-pageviews.png';
// import PageviewsWebP from '@crazyegginc/hatch/dist/images/illustration-pageviews.webp';
// import TargetPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
// import TargetWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';

export const emptyTrigger = {
  name: '',
  trigger: '',
  targetUrl: '',
  devices: [],
  trackingOption: null,
  elementSelectorType: null,
};

function goalPayloadToParams(payload, { includeValues = false } = {}) {
  const triggerValue = (index) => {
    if (!includeValues) return {};

    const syncValues = payload?.syncValues ?? true;

    const trigger = syncValues ? payload?.triggers?.[0] : payload?.triggers?.[index];

    if (!trigger) return {};

    return {
      valueType: trigger.valueType,
      fixedValue: trigger.fixedValue,
      elementValue: trigger.elementValue,
    };
  };

  return {
    ...payload,
    autoName: payload.name,
    syncValues: payload?.syncValues,
    triggers: payload.triggers.map((trigger, index) => {
      return {
        autoName: `Trigger ${index + 1}`,
        name: trigger.name,
        filter: trigger.filter,
        uiMode: trigger.uiMode,
        ...triggerValue(index),
      };
    }),
  };
}

// if the `goalId` is not null, we assume editing.
export function GoalsWizard({ goalId = null, service, skipNotifications = false }) {
  const { selectSite } = useSite({ dontSelectSite: true });
  const notifications = useNotifications();
  const [state] = useActor(service);
  const { send } = service;

  const { mutate: mutateGoalCreate } = useMutation(goalCreateMutation);
  const { mutate: mutateGoalEdit } = useMutation(goalEditMutation);
  const { mutate: mutateUpdateTest } = useMutation(abTestUpdateMutation);

  const isEditing = !!goalId || !!state.context.goalId;

  const { data } = useQuery({
    ...goalDetailQuery({ id: state.context.goalId }),
    enabled: state.matches('initializeEdit') && isEditing,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isEditing && data?.goalDetail?.siteId) {
      selectSite(data.goalDetail.siteId);
    }
  }, [data, selectSite, isEditing]);

  useEffect(() => {
    if (state.matches('initializeEdit') && data) {
      send({ type: 'GOAL_LOADED', value: data.goalDetail });
    }
  }, [data, state, send]);

  useEffect(() => {
    if (state.matches('savingGoal') && !isEditing) {
      mutateGoalCreate(
        {
          params: {
            ...goalPayloadToParams(state.context.payload, { includeValues: true }),
          },
        },
        {
          onSuccess: (data) => {
            !skipNotifications && notifications.success({ content: 'Goal created successfully.', timeout: 3000 });
            const goalId = data.goalCreate.id;
            service.send({ type: 'GOAL_SUCCESS', goalId });
          },
          onError: (error) => {
            !skipNotifications &&
              notifications.error({ content: 'Goal creation failed.', timeout: 3000, context: { error } });
            service.send('GOAL_ERROR');
          },
        },
      );
    }
  }, [mutateGoalCreate, isEditing, notifications, service, skipNotifications, state, mutateUpdateTest]);

  useEffect(() => {
    if (state.matches('savingGoal') && isEditing) {
      const { autoName, name, triggers, purpose = 'MORE_PAGE_VIEWS' } = state.context.payload;
      const { goalId } = state.context;
      mutateGoalEdit(
        {
          params: {
            id: goalId,
            name,
            autoName: autoName || name,
            purpose,
            triggers: triggers.map((trigger, index) => {
              return {
                autoName: `Trigger ${index + 1}`,
                name: trigger.name,
                filter: trigger.filter,
                uiMode: trigger.uiMode,
              };
            }),
          },
        },
        {
          onSuccess: (data) => {
            notifications.success({ content: 'Goal updated successfully', timeout: 3000 });
            service.send({ type: 'GOAL_SUCCESS', goalId: data.goalEdit.id });
          },
          onError: (error) => {
            notifications.error({ content: 'Goal update failed.', timeout: 3000, context: { error } });
            service.send('GOAL_ERROR');
          },
        },
      );
    }
  }, [mutateGoalEdit, isEditing, notifications, service, skipNotifications, state]);

  if (state.matches('initialize') || state.matches('initializeEdit') || state.matches('initializeValue')) {
    return (
      <Wizard>
        <Wizard.Header />
        <Wizard.Content>
          <div className="mt-16 flex w-full items-center justify-center">
            <Spinner />
            <div className="ml-2.5">Loading...</div>
          </div>
        </Wizard.Content>
      </Wizard>
    );
  }

  if (state.matches('initializeError')) {
    return (
      <Wizard>
        <Wizard.Header />
        <Wizard.Content>
          <div className="mt-16 flex w-full flex-col items-center justify-center">
            <div className="mb-2.5">Data loading failed. Check your connection and try again.</div>
            <div className="flex space-x-2.5">
              <Button onClick={() => send('RETRY')}>Try again</Button>
            </div>
          </div>
        </Wizard.Content>
      </Wizard>
    );
  }

  if (state.matches('manageValue') || state.matches('savingValue')) {
    return <GoalValueWizard service={service} />;
  }

  return <GoalTriggerWizard service={service} />;
}
