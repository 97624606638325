export function getStorageKey(key, keyPersistPrefix) {
  return keyPersistPrefix ? `shell:${keyPersistPrefix}:${key}` : `shell:${key}`;
}

const replaceUrl = (params) => {
  let newString = params.toString();
  if (window.location.hash !== '') {
    newString += window.location.hash;
  }
  if (newString.length > 0) {
    window.history.replaceState(null, document.title, `?${newString}`);
  } else {
    const newPath = window.location.href.split('?')[0];
    window.history.replaceState(null, document.title, newPath);
  }
};

export function getParam(key, fallback, search, keyPersistPrefix) {
  const params = new URLSearchParams(search || window.location.search.slice(1));
  const value = params.get(key);

  const storedValue = window.localStorage.getItem(getStorageKey(key, keyPersistPrefix));

  return value || storedValue || fallback;
}

export function setParam(key, value, persist = false, keyPersistPrefix) {
  const params = new URLSearchParams(window.location.search.slice(1));
  params.set(key, value);
  if (!value) {
    params.delete(key);
  }

  if (persist && value) {
    window.localStorage.setItem(getStorageKey(key, keyPersistPrefix), value);
  } else if (persist && !value) {
    window.localStorage.removeItem(getStorageKey(key, keyPersistPrefix));
  }
  replaceUrl(params);
}

export function deleteParam(key, persist = false, keyPersistPrefix) {
  const params = new URLSearchParams(window.location.search.slice(1));

  if (persist) {
    window.localStorage.removeItem(getStorageKey(key, keyPersistPrefix));
  }

  if (params.has(key)) {
    params.delete(key);
    replaceUrl(params);
  }
}

export const getInitialValue = (name, possibleValues) => {
  const param = getParam(name);
  if (possibleValues.includes(param)) {
    return param;
  } else {
    deleteParam(name);
    return null;
  }
};
