import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function DeleteModal({ onConfirm = null, onCancel = null }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[80%] !max-w-[350px]" dark>
      <Modal.Title>Delete this recording?</Modal.Title>

      <p className="mt-0 text-xs leading-5 text-cadet-blue-500">
        If you delete this recording, you will no longer have access to it. This can&#39;t be undone.
      </p>

      <Modal.Actions>
        <Button
          variant="warning"
          theme="dark"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Yes, delete this recording
        </Button>
        <Button
          variant="cancel"
          theme="dark"
          onClick={() => {
            onCancel?.();
            modal.close();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
