import classNames from 'classnames';

export function DashboardPage({ mainClass, children }) {
  return <main className={classNames('min-w-0 flex-1 bg-white-lilac-500 pb-[150px]', mainClass)}>{children}</main>;
}

export function WizardPage({ children }) {
  return (
    <div className="flex min-h-screen min-w-full flex-col">
      <main className="mx-auto mb-[200px] w-[780px] bg-white px-[30px] pb-[100px] pt-14">{children}</main>
    </div>
  );
}

export function PlayerPage({ children }) {
  return <main>{children}</main>;
}
