import { Button, Tooltip } from '@crazyegginc/hatch';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function DeleteRowButton({ disabled, onRemove = null }) {
  return (
    <Tooltip tooltipContent="Remove this condition" placement="right">
      <Button
        disabled={disabled}
        className="group border border-mystic-500 !bg-white !px-2.5 !text-white hover:border-carnation-500 hover:!bg-carnation-500"
        onClick={() => onRemove?.()}
      >
        <DeleteIcon
          aria-label="remove this condition"
          className="h-3 w-3 fill-current !text-carnation-500 group-hover:!text-white"
        />
      </Button>
    </Tooltip>
  );
}
