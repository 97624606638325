import classNames from 'classnames';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Select, Checkbox } from '@crazyegginc/hatch';

import { Toggle, Input, SectionHeader, SectionContent } from '../../../common/basic-ui';
import { QuestionPanel } from './Questions';
import { QuestionTitle } from './QuestionTitle';
import {
  SURVEY_QUESTION_TYPES,
  STATIC_DISPLAY_SUBTYPES,
  URL_TYPES,
  ADDONS_CAPABILITIES,
} from '/src/features/addons/constants';
import { generateInitialQuestionSchema, DEFAULT_LINK_BUTTON_TITLE, DEFAULT_LINK_BUTTON_URL } from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';
import { normalizeUrl } from '/src/utils/url';

import { ReactComponent as ThanksIcon } from '@crazyegginc/hatch/dist/images/icon-visitorface-1.svg';

export function ThankYouMessage({ questionActions }) {
  const { readonly, addonsCapabilities } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext();
  const [savedValues, setSavedValues] = useState(
    generateInitialQuestionSchema(SURVEY_QUESTION_TYPES.STATIC_DISPLAY, STATIC_DISPLAY_SUBTYPES.THANK_YOU),
  );

  const haveURLTypes = addonsCapabilities[ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE];

  let index = values.questions?.length - 1;
  const enabled = values.questions?.[index]?.subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU;

  const getURLType = (value) => {
    if (value.match(/^mailto:/)) {
      return URL_TYPES.MAILTO;
    }

    if (value.match(/^tel:/)) {
      return URL_TYPES.PHONE;
    }

    return URL_TYPES.URL;
  };

  const removeURLType = (value) => {
    const type = getURLType(value);

    if (type === URL_TYPES.MAILTO || type === URL_TYPES.PHONE) {
      return value.replace('mailto:', '').replace('tel:', '');
    }

    return normalizeUrl(value, { appendSlash: false });
  };

  const returnURLTYPE = (type, value, fieldValue, setFieldValue) => {
    let result = '';
    let currentValue = value;

    if (value.match(/(mailto:|tel:)/)) {
      currentValue = value.replaceAll('mailto:', '').replaceAll('tel:', '');
    }

    switch (type) {
      case URL_TYPES.MAILTO:
        result = 'mailto:';
        break;
      case URL_TYPES.PHONE:
        result = 'tel:';
        break;
      default:
        result = currentValue.replaceAll('mailto:', '').replaceAll('tel:', '');
        break;
    }

    setFieldValue(
      fieldValue,
      `${type === URL_TYPES.URL ? normalizeUrl(result, { appendSlash: false }) : `${result}${currentValue}`}`,
    );
  };

  const returnInputURL = (prevValue, value, fieldValue, setFieldValue) => {
    const prevType = getURLType(prevValue);
    let result = '';

    if (
      value.match(/^mailto:/) ||
      (prevType === URL_TYPES.PHONE && value.match(/^mailto:/)) ||
      (prevType === URL_TYPES.MAILTO && !value.match(/^tel:/))
    ) {
      result = 'mailto:';
    }

    if (
      value.match(/^tel:/) ||
      (prevType === URL_TYPES.PHONE && !value.match(/^mailto:/)) ||
      (prevType === URL_TYPES.MAILTO && value.match(/^tel:/))
    ) {
      result = 'tel:';
    }

    setFieldValue(fieldValue, `${result}${value.replaceAll('mailto:', '').replaceAll('tel:', '')}`);
  };

  return (
    <div className="mt-10">
      <QuestionPanel index={index}>
        <SectionHeader className={classNames({ '!rounded-[5px]': !enabled })}>
          <div className="flex items-center">
            <ThanksIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
            Thank You Message
          </div>
          <div className="ml-5">
            <Toggle
              label="Enabled"
              setEnabled={() => {
                if (enabled) {
                  setSavedValues(values.questions[index]);
                  questionActions.remove(index);
                } else {
                  questionActions.push(savedValues);
                }
              }}
              enabled={enabled}
              disabled={readonly}
            />
          </div>
        </SectionHeader>
        {enabled ? (
          <SectionContent>
            <QuestionTitle index={index} titleLabel="Heading" autofocus={false} />
            <Checkbox
              label="Add custom button with link"
              id={`questions[${index}].linkButton.enabled`}
              name={`questions[${index}].linkButton.enabled`}
              checked={values.questions[index].linkButton?.enabled ?? false}
              onChange={handleChange}
              disabled={readonly}
            />
            {values.questions[index].linkButton?.enabled ? (
              <div className="flex items-start">
                <div className="mr-2.5 w-1 flex-grow">
                  <Input
                    label="Custom button text"
                    id={`questions[${index}].linkButton.title`}
                    name={`questions[${index}].linkButton.title`}
                    value={values.questions[index].linkButton.title}
                    error={
                      errors.questions?.[index]?.linkButton?.title && touched.questions?.[index]?.linkButton?.title
                        ? errors.questions[index].linkButton.title
                        : null
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={(e) => {
                      if (e.target.value === DEFAULT_LINK_BUTTON_TITLE) {
                        e.target.select();
                      }
                    }}
                    disabled={readonly}
                  />
                </div>
                <div className="ml-2.5 w-1/4 flex-grow">
                  <h5 className="text-body-2 mb-1 block font-semibold">Custom button URL</h5>
                  <div className="flex">
                    {haveURLTypes && (
                      <div className="mr-2 w-36">
                        <Select
                          aria-label="URL type selection"
                          options={[
                            { label: 'URL', value: URL_TYPES.URL },
                            { label: 'Email', value: URL_TYPES.MAILTO },
                            { label: 'Phone', value: URL_TYPES.PHONE },
                          ]}
                          value={getURLType(values.questions[index].linkButton.url)}
                          onChange={(value) =>
                            returnURLTYPE(
                              value,
                              values.questions[index].linkButton.url,
                              `questions[${index}].linkButton.url`,
                              setFieldValue,
                            )
                          }
                          disabled={readonly}
                          size="lg"
                        />
                      </div>
                    )}

                    <Input
                      aria-label="Custom button URL"
                      id={`questions[${index}].linkButton.url`}
                      name={`questions[${index}].linkButton.url`}
                      value={
                        haveURLTypes
                          ? removeURLType(values.questions[index].linkButton.url)
                          : values.questions[index].linkButton.url
                      }
                      error={errors.questions?.[index]?.linkButton?.url}
                      onChange={(e) => {
                        haveURLTypes
                          ? returnInputURL(
                              values.questions[index].linkButton.url,
                              e.target.value,
                              `questions[${index}].linkButton.url`,
                              setFieldValue,
                            )
                          : setFieldValue(
                              `questions[${index}].linkButton.url`,
                              normalizeUrl(e.target.value, { appendSlash: false }),
                            );
                      }}
                      onBlur={handleBlur}
                      onFocus={(e) => {
                        if (e.target.value === DEFAULT_LINK_BUTTON_URL) {
                          e.target.select();
                        }
                      }}
                      disabled={readonly}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </SectionContent>
        ) : null}
      </QuestionPanel>
    </div>
  );
}
