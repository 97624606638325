import { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Panel, Tooltip, Button, Input, Spinner, Autocomplete } from '@crazyegginc/hatch';

import { useMutation, useNotifications } from '/src/hooks';
import { updateCompanyInfoMutation } from '../mutations';
import { NO_COUNTRY } from '../constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

const validationSchema = yup.object().shape({
  companyName: yup.string().max(64, 'Please provide a shorter name, maximum ${max} characters.').nullable(),
  companyId: yup.string().max(64, 'Please provide a shorter id, maximum ${max} characters.').nullable(),
  companyVat: yup.string().max(64, 'Please provide a shorter value, maximum ${max} characters.').nullable(),
  companyAddress: yup.object().shape(
    {
      address: yup
        .string()
        .nullable(true)
        .when(
          ['city', 'stateOrProvince', 'zipOrPostalCode', 'country'],
          (city, stateOrProvince, zipOrPostalCode, country, schema) => {
            if (!city && !stateOrProvince && !zipOrPostalCode && (!country || country === NO_COUNTRY)) {
              return schema;
            } else {
              return schema
                .required('Please provide an address.')
                .max(60, 'Address cannot exceed ${max} characters.')
                .matches(/[a-zA-Z]/, 'Please provide a valid address.');
            }
          },
        ),
      city: yup
        .string()
        .nullable(true)
        .when(
          ['address', 'stateOrProvince', 'zipOrPostalCode', 'country'],
          (address, stateOrProvince, zipOrPostalCode, country, schema) => {
            if (!address && !stateOrProvince && !zipOrPostalCode && (!country || country === NO_COUNTRY)) {
              return schema;
            } else {
              return schema.required('Please provide a city.').max(50, 'City name cannot exceed ${max} characters.');
            }
          },
        ),
      stateOrProvince: yup
        .string()
        .nullable(true)
        .max(50, 'State or province cannot exceed ${max} characters.')
        .when(['address', 'city', 'zipOrPostalCode', 'country'], (address, city, zipOrPostalCode, country, schema) => {
          if ((!address && !city && !zipOrPostalCode) || !['United States', 'Canada', 'Australia'].includes(country)) {
            return schema;
          } else {
            return schema.required('Please provide a state.');
          }
        }),
      zipOrPostalCode: yup
        .string()
        .nullable(true)
        .max(50, 'ZIP / Postal code cannot exceed ${max} characters.')
        .when(['address', 'city', 'stateOrProvince', 'country'], (address, city, stateOrProvince, country, schema) => {
          if ((!address && !stateOrProvince && !city) || !['United States', 'Canada', 'Australia'].includes(country)) {
            return schema;
          } else {
            return schema.required('Please provide a ZIP / Postal code.');
          }
        }),
      country: yup
        .string()
        .nullable(true)
        .when(
          ['address', 'city', 'stateOrProvince', 'zipOrPostalCode'],
          (address, city, stateOrProvince, zipOrPostalCode, schema) => {
            if (!address && !stateOrProvince && !city && !zipOrPostalCode) {
              return schema;
            } else {
              return schema.required('Please select a country.').notOneOf([NO_COUNTRY], 'Please select a country.');
            }
          },
        ),
    },
    [
      ['address', 'stateOrProvince'],
      ['address', 'city'],
      ['address', 'zipOrPostalCode'],
      ['city', 'stateOrProvince'],
      ['city', 'zipOrPostalCode'],
      ['stateOrProvince', 'zipOrPostalCode'],
      ['stateOrProvince', 'country'],
      ['zipOrPostalCode', 'country'],
      ['address', 'country'],
      ['city', 'country'],
    ],
  ),
});

export function BillingCompanyInfo({ data, countries }) {
  const countryOptions = useMemo(() => [{ value: NO_COUNTRY }, ...countries.map((c) => ({ value: c }))], [countries]);
  const { mutate: mutateCompanyInfo, error } = useMutation(updateCompanyInfoMutation);
  const notifications = useNotifications();

  const stateOrProvinceError = error?.graphQLErrors?.some((e) =>
    e.originalError?.message?.match(/state_or_province:.*must be a valid state or province/),
  )
    ? 'Please provide a valid state or province.'
    : null;
  const zipOrPostalCodeError = error?.graphQLErrors?.some((e) =>
    e.originalError?.message?.match(/zip_or_postal_code:.*must be a valid zip or postal code/),
  )
    ? 'Please provide a valid ZIP or Postal code.'
    : null;

  return (
    <>
      <h2 className="text-header-3 mb-2.5 flex items-center space-x-2.5">
        <span>Company info</span>
        <Tooltip
          tooltipContent={
            <div className="max-w-[190px]">The information in this section will be added to your receipt.</div>
          }
        >
          <InfoIcon aria-label="company info" className="h-4 w-4 fill-current text-dodger-blue-300" />
        </Tooltip>
      </h2>
      <Panel className="!mb-10">
        <Formik
          initialValues={{
            companyName: data.companyName ?? '',
            companyId: data.companyId ?? '',
            companyVat: data.companyVat ?? '',
            companyAddress: {
              address: data.companyAddress.address ?? '',
              city: data.companyAddress.city ?? '',
              stateOrProvince: data.companyAddress.stateOrProvince ?? '',
              zipOrPostalCode: data.companyAddress.zipOrPostalCode ?? '',
              country: data.companyAddress.country ?? NO_COUNTRY,
            },
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            mutateCompanyInfo(
              {
                companyName: values.companyName ?? null,
                companyId: values.companyId ?? null,
                companyVat: values.companyVat ?? null,
                address: values.companyAddress.address || null,
                city: values.companyAddress.city || null,
                stateOrProvince: values.companyAddress.stateOrProvince || null,
                zipOrPostalCode: values.companyAddress.zipOrPostalCode || null,
                country: values.companyAddress.country === NO_COUNTRY ? null : values.companyAddress.country,
              },
              {
                onError: () => {
                  notifications.error({
                    title: 'Failed to update company info',
                    content: 'Please fix the errors and try again.',
                    timeout: 4000,
                    skipHoneybadger: true,
                  });
                },
                onSuccess: () => {
                  notifications.success({ content: 'Company info updated successfully.', timeout: 3000 });
                },
                onSettled: () => {
                  actions.setSubmitting(false);
                },
              },
            );
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
            return (
              <Form className="w-full max-w-3xl">
                <div className="flex w-full space-x-10">
                  <div className="w-1/2 space-y-3.75">
                    <Input
                      name="companyName"
                      id="companyName"
                      label="Company Name"
                      size="lg"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.companyName && errors.companyName ? errors.companyName : null}
                    />
                    <Input
                      name="companyId"
                      id="companyId"
                      label="Company Number"
                      size="lg"
                      value={values.companyId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.companyId && errors.companyId ? errors.companyId : null}
                    />
                    <Input
                      name="companyVat"
                      id="companyVat"
                      label="VAT"
                      size="lg"
                      value={values.companyVat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.companyVat && errors.companyVat ? errors.companyVat : null}
                    />
                  </div>
                  <div className="w-1/2 space-y-2.5">
                    <Input
                      name="companyAddress.address"
                      id="address"
                      label="Address"
                      size="lg"
                      value={values.companyAddress.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.companyAddress?.address && errors.companyAddress?.address
                          ? errors.companyAddress?.address
                          : null
                      }
                      placeholder="20/123 Market Street"
                    />
                    <Input
                      name="companyAddress.city"
                      size="lg"
                      value={values.companyAddress.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.companyAddress?.city && errors.companyAddress?.city ? errors.companyAddress?.city : null
                      }
                      placeholder="City"
                    />
                    <Input
                      name="companyAddress.stateOrProvince"
                      size="lg"
                      value={values.companyAddress.stateOrProvince}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched.companyAddress?.stateOrProvince && errors.companyAddress?.stateOrProvince
                          ? errors.companyAddress?.stateOrProvince
                          : null) ?? stateOrProvinceError
                      }
                      placeholder="State / Province"
                    />
                    <Input
                      name="companyAddress.zipOrPostalCode"
                      size="lg"
                      value={values.companyAddress.zipOrPostalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched.companyAddress?.zipOrPostalCode && errors.companyAddress?.zipOrPostalCode
                          ? errors.companyAddress?.zipOrPostalCode
                          : null) ?? zipOrPostalCodeError
                      }
                      placeholder="ZIP / Postal Code"
                    />
                    <Autocomplete
                      options={countryOptions}
                      value={values.companyAddress.country}
                      onChange={(value) => setFieldValue('companyAddress.country', value)}
                      error={
                        touched.companyAddress?.country && errors.companyAddress?.country
                          ? errors.companyAddress?.country
                          : null
                      }
                      aria-label="Country"
                    />
                  </div>
                </div>
                <Button className="!mt-[35px]" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                      Saving...
                    </div>
                  ) : (
                    'Save changes'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Panel>
    </>
  );
}
