import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';

import { usePermissions } from '/src/hooks';
import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { updateQuestionIds } from '/src/features/addons/surveys/common-functions';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { addonSuggestionDetailQuery } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function UtilizeSurveySuggestion() {
  const { id } = useParams();
  const permissions = usePermissions();
  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;

  const { data, isFetching } = useQuery({
    ...addonSuggestionDetailQuery({ id: parseInt(id) }),
    enabled: Boolean(id && canCreateSurvey),
  });

  const suggestion = data?.addonSuggestionDetail;

  useEffect(() => {
    if (window.Metrics && suggestion && suggestion.type === ADDON_TYPES.SURVEY) {
      window.Metrics.used('Survey: Editor - Suggestion used');
    }
  }, [suggestion]);

  if (!suggestion && isFetching) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }

  if (
    !id ||
    !canCreateSurvey ||
    (!isFetching && !suggestion) ||
    (suggestion && suggestion.type !== ADDON_TYPES.SURVEY)
  ) {
    return <Navigate replace to="/addons" />;
  }

  const surveyData = updateQuestionIds(
    generateSurveyEditorFormat({ content: suggestion.data, suggestionId: suggestion.id }),
  );
  surveyData.name = suggestion.title;

  return <SurveyEditor initData={surveyData} />;
}
