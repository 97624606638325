import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

export function CTAsPaywallText({ children }) {
  return children;
}

function CTAsPaywallTitle() {
  return 'Quick CTAs to drive more user responses and conversions';
}

function CTAsPaywallBody() {
  return (
    <>
      <p className="leading-tight">Deploy customized banners or popup buttons to users on specific pages.</p>
    </>
  );
}

function CTAsPaywallList() {
  return (
    <div className="mt-2 flex flex-col justify-center">
      <p className="mb-4">Use these to test new calls-to-action without changing your page design:</p>
      <ul className="space-y-2">
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>Highlight discounts and promotions</p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>Announce a new feature, content, or event</p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>Survey users</p>
        </li>
      </ul>
    </div>
  );
}

CTAsPaywallText.Title = CTAsPaywallTitle;
CTAsPaywallText.Body = CTAsPaywallBody;
CTAsPaywallText.List = CTAsPaywallList;
