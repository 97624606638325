import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { useAuthContext, useDebounce } from '/src/hooks';
import { PreviewOnSiteAndRestart } from './PreviewOnSiteAndRestart';
import { useEditorContext } from '../../editor-context';
import { generateQuestionSurveyFormat, validForPreview, removeFeedbackButtonInPreview } from './editor-functions';
import { replaceEmojiWithImage } from '../../common/common-functions';
import {
  ADDON_PREVIEW_ID,
  BEHAVIOR_TRIGGERS,
  SURVEY_DISPLAY_TYPES,
  ADDONS_CAPABILITIES,
  PREVIEW_READY,
} from '/src/features/addons/constants';

export function Preview({ saveSurvey }) {
  const { readonly, handleAddOnsCapabilities } = useEditorContext();
  const { values, errors } = useFormikContext();
  const questions = useDebounce(values.questions, 200);
  const [surveyCreated, setSurveyCreated] = useState(false);
  const [surveyAvailable, setSurveyAvailable] = useState(false);

  const { currentAccount, currentUser } = useAuthContext();
  const { scriptVersion } = currentAccount;
  const { features: featureFlags } = currentUser;

  // We validate only the part here that the survey script cares about for preview
  const isValidForPreview = validForPreview(errors);

  const isExternal = values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL;

  useEffect(() => {
    if (!surveyCreated) {
      window.CE2.Survey?.createSurvey(1, {
        target: `#${ADDON_PREVIEW_ID}`,
        data: {
          questions: generateQuestionSurveyFormat(questions),
          theme: values.theme,
          external: isExternal,
          closable: values.closable,
        },
        preview: true,
        questionNumber: values.behavior.show === BEHAVIOR_TRIGGERS.FEEDBACK ? -1 : 0,
        userData: { scriptVersion, featureFlags },
      });
      setSurveyCreated(true);
      setTimeout(() => setSurveyAvailable(true), 200);
    }
  }, [
    surveyCreated,
    questions,
    values.theme,
    values.behavior.show,
    isExternal,
    scriptVersion,
    featureFlags,
    values.closable,
  ]);

  useEffect(() => {
    if (surveyAvailable) {
      const capabilities = {
        [PREVIEW_READY]: true,
        [ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE]: window.CE2.Survey?.hasCapability(
          1,
          ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE,
        ),
        [ADDONS_CAPABILITIES.LABEL_MULTIPLE_CHOICE]: window.CE2.Survey?.hasCapability(
          1,
          ADDONS_CAPABILITIES.LABEL_MULTIPLE_CHOICE,
        ),
        [ADDONS_CAPABILITIES.CLOSABLE]: window.CE2.Survey?.hasCapability(1, ADDONS_CAPABILITIES.CLOSABLE),
        [ADDONS_CAPABILITIES.SAMPLING]: window.CE2.Survey?.hasCapability(1, ADDONS_CAPABILITIES.SAMPLING),
        [ADDONS_CAPABILITIES.CLOSABLE_OPTIONS]: window.CE2.Survey?.hasCapability(
          1,
          ADDONS_CAPABILITIES.CLOSABLE_OPTIONS,
        ),
        [ADDONS_CAPABILITIES.POPUP_IMAGE]: window.CE2.Survey?.hasCapability(1, ADDONS_CAPABILITIES.POPUP_IMAGE),
        [ADDONS_CAPABILITIES.CUSTOM_BRANDING]: window.CE2.Survey?.hasCapability(1, ADDONS_CAPABILITIES.CUSTOM_BRANDING),
        [ADDONS_CAPABILITIES.COLOR_ACCESSIBILITY]: window.CE2.Survey?.hasCapability(
          1,
          ADDONS_CAPABILITIES.COLOR_ACCESSIBILITY,
        ),
      };

      handleAddOnsCapabilities(capabilities);
    }
  }, [surveyAvailable, handleAddOnsCapabilities]);

  // Update parts of data independently
  useEffect(() => {
    if (surveyAvailable) {
      window.CE2.Survey?.update(1, { data: { questions: generateQuestionSurveyFormat(questions) } });
    }
  }, [questions, surveyAvailable]);

  useEffect(() => {
    if (surveyAvailable) {
      window.CE2.Survey?.update(1, { data: { theme: values.theme, closable: values.closable } });
    }
  }, [values.theme, values.closable, surveyAvailable]);

  useEffect(() => {
    if (surveyAvailable) {
      window.CE2.Survey?.update(1, { data: { external: isExternal } });
    }
  }, [isExternal, surveyAvailable]);

  useEffect(() => {
    let latest = true;
    const trigger = () => {
      const buttonExists = document.querySelector(`#${ADDON_PREVIEW_ID} > crazyegg-feedback`);
      const surveyExists = document
        .querySelector(`#${ADDON_PREVIEW_ID} > crazyegg-survey`)
        ?.shadowRoot?.querySelector('#main')?.firstElementChild;

      window.CE2.removeFeedbackButton?.();
      window.CE2.Survey?.update(1, { questionNumber: -1 });

      const show = () => {
        if (latest) {
          window.CE2.showFeedbackButton?.({
            id: 1,
            preview: true,
            target: `#${ADDON_PREVIEW_ID}`,
            data: {
              behavior: {
                feedback: { ...values.behavior.feedback, text: replaceEmojiWithImage(values.behavior.feedback.text) },
              },
              questions: [],
            },
          });
        }
      };

      if (values.behavior.feedback && !isExternal && values.behavior.feedback.cta_bg && isValidForPreview) {
        if (buttonExists || !surveyExists) {
          show();
        } else {
          setTimeout(() => {
            show();
          }, 700);
        }
      }
    };

    if (surveyAvailable) {
      if (values.behavior.show === BEHAVIOR_TRIGGERS.FEEDBACK && !isExternal) {
        trigger();
      } else if (values.behavior.show !== BEHAVIOR_TRIGGERS.FEEDBACK || isExternal) {
        removeFeedbackButtonInPreview();
      }
    }

    return () => {
      latest = false;
    };
  }, [
    values.behavior.feedback,
    values.behavior.show,
    isExternal,
    readonly,
    errors,
    surveyAvailable,
    isValidForPreview,
  ]);

  return (
    <section
      className="relative flex w-[400px] shrink-0 flex-col items-center justify-start pt-8"
      style={{
        backgroundColor: '#F9FAFD',
        backgroundImage: 'radial-gradient(rgb(239, 242, 247) 2px, rgb(249, 250, 253) 2px)',
        backgroundSize: '20px 20px',
      }}
    >
      <h2 className="text-header-3 ml-10 mt-8 !self-start">Preview</h2>
      <div id={ADDON_PREVIEW_ID} className="mt-3">
        {!isValidForPreview ? (
          <div className="text-error absolute w-[250px] -translate-x-1/2 rounded border border-mystic-500 bg-white px-5 py-5 text-center text-sm">
            Please fix any errors in the editor and then you&apos;ll be able to see the preview here.
          </div>
        ) : null}
      </div>
      {<PreviewOnSiteAndRestart saveSurvey={saveSurvey} />}
    </section>
  );
}
