import { useEffect } from 'react';
import { createClient } from 'graphql-ws';
import { useQueryClient, useQuery } from '@tanstack/react-query';

import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';

const wsClient = createClient({
  url() {
    return `${window.CORE_API_URL}/socket-ws`.replace(/^http/, 'ws');
  },
  connectionParams() {
    const token = window.localStorage.getItem(AUTH_TOKEN_KEY);
    return {
      authorization: `Bearer ${token}`,
    };
  },
});

export function useSubscription({ query, variables, onNext }, { queryKey, enabled = true, ...rest }) {
  const queryClient = useQueryClient();

  useEffect(() => {
    let unsubscribe;
    if (enabled) {
      unsubscribe = wsClient.subscribe(
        { query: query.loc.source.body, variables },
        {
          next: (newData) => {
            const currentData = queryClient.getQueryData(queryKey);
            const handledData = onNext(currentData, newData.data);
            queryClient.setQueryData(queryKey, handledData);
          },
          error: (e) => {
            throw new Error(e);
          },
          complete: () => {},
        },
      );
    }
    return () => {
      unsubscribe?.();
      queryClient.removeQueries({ queryKey });
    };
  }, [query, variables, onNext, queryKey, queryClient, enabled]);

  return useQuery({ queryKey, queryFn: () => new Promise(() => {}), ...rest });
}
