import { useState, useMemo, useEffect, useCallback, useId } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import Honeybadger from '@honeybadger-io/js';
import { Spinner, MetricCards, AIBox, Tooltip } from '@crazyegginc/hatch';

import { useAuthContext, useCrazyAI, useBulletPoints, useAddonScript, useUserLanguage } from '/src/hooks';

import { surveyResultSummaryQuery, surveyResponseListQuery } from '/src/features/addons/queries';
import {
  SURVEY_QUESTION_TYPES,
  RATING_SUBTYPES,
  TEXT_ENTRY_SUBTYPES,
  MULTI_CHOICE_SUBTYPES,
} from '/src/features/addons/constants';
import { NoMatchesWall } from '/src/features/_global/paywalls/NoMatchesWall';
import { SectionPanel, SectionHeader, SectionContent } from '../../../common/basic-ui';
import { processUnsafeText } from '../../../common/common-functions';
import { CommonActions } from '../CommonActions';
import { NPS } from './NPS';
import { Rating } from './Rating';
import { MultiChoice } from './MultiChoice';
import { TextEntry } from './TextEntry';
import { MetaRow } from '../MetaRow';
import { isProduction } from '/src/utils';
import { htmlDecode } from '/src/utils/string';
import { ADDONS_CAPABILITIES, ALL_RESPONSES_TABLE_ORDER } from '/src/features/addons/constants';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { SupportLinks } from '/src/support';

import { ReactComponent as SmileyIcon } from '@crazyegginc/hatch/dist/images/icon-emoji-outline.svg';
import { ReactComponent as ViewIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function SummaryTab({ survey, refetchDetails, convertedDateRange, resetDateRange }) {
  const isProd = isProduction();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);

  const { currentAccount, currentUser } = useAuthContext();
  const scriptVersion = currentAccount?.scriptVersion;
  const featureFlags = currentUser?.features;
  const [capabilities, setCapabilities] = useState({});

  useAddonScript(
    useCallback(
      () => setCapabilities(window.CE2.Survey.getAllCapabilities({ scriptVersion, featureFlags })),
      [scriptVersion, featureFlags],
    ),
    'survey',
  );

  const {
    data,
    isFetching,
    error,
    refetch: refetchSummary,
  } = useQuery({
    ...surveyResultSummaryQuery({
      id: survey.id,
      startDate: convertedDateRange.startDate,
      endDate: convertedDateRange.endDate,
    }),
  });

  // This is for the AI summary
  const { data: recentResponses, refetch: refetchRecentResponses } = useQuery({
    ...surveyResponseListQuery({
      surveyId: survey.id,
      order: {
        field: ALL_RESPONSES_TABLE_ORDER.CREATED_AT,
        questionId: null,
        sort: SORT_ORDER_TYPES.DESC,
      },
      limit: 100,
      startDate: convertedDateRange.startDate,
      endDate: convertedDateRange.endDate,
    }),
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('survey_results_summary');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && (data || error) && !isFetching) {
      try {
        window.CE2.timing.stop('survey_results_summary');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, error, isProd, isFetching]);

  const parsedSurveyContent = useMemo(() => {
    let parsedContent = {};
    try {
      parsedContent = JSON.parse(survey.content);
    } catch (e) {
      Honeybadger.notify('Failed to parse survey content', {
        context: { error: e, content: survey.content },
        name: 'Survey result error',
      });
    }
    return parsedContent;
  }, [survey]);

  const questionsWithResults = useMemo(
    () =>
      (parsedSurveyContent?.questions ?? [])
        .filter((question) => question.type !== 'static-display')
        .map((question) => {
          const questionData = (data?.surveyResultSummary?.questions ?? []).find((q) => q.id === question.id);
          return {
            ...questionData,
            ...question,
          };
        })
        .sort((a, b) => a.position - b.position),
    [data, parsedSurveyContent],
  );

  if (isFetching) {
    return (
      <div className="mt-6 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );
  }

  if (survey.responseCount > 0 && data?.surveyResultSummary.responseCount === 0) {
    return <NoMatchesWall reset={resetDateRange} text="matches for this date range" actionText="date range" />;
  }

  const showAISummary =
    recentResponses?.surveyResponseList?.responses?.length > 0 &&
    (questionsWithResults.length > 1 || questionsWithResults[0].type !== SURVEY_QUESTION_TYPES.TEXT_ENTRY);

  return (
    <>
      <div className="flex items-center">
        <CommonActions
          survey={survey}
          refresh={() => {
            refetchSummary();
            refetchDetails();
            refetchRecentResponses();
          }}
          className="mb-3.75"
        />
        {capabilities[ADDONS_CAPABILITIES.SAMPLING] && survey.responseCount >= 5000 ? (
          <div className="text-body-3 mb-3.75 ml-7 flex items-center">
            <InfoIcon className="mr-2.5 h-3.5 w-3.5 fill-current" />
            <p>
              Whoa, you&apos;ve got a ton of responses. Great job! To prevent fatiguing your visitors you might consider{' '}
              <Link to={`/addons/${survey.id}/edit`} className="text-link" state={{ goToSampling: true }}>
                enabling sampling
              </Link>{' '}
              for this survey.
            </p>
          </div>
        ) : null}
      </div>

      <SummaryTiles
        totalViews={data.surveyResultSummary.totalViews}
        totalResponses={data.surveyResultSummary.responseCount}
      />

      <div className="flex min-w-[900px] max-w-[1500px] flex-col space-y-10">
        {showAISummary ? (
          <SuveyAISummary
            recentResponses={recentResponses}
            questionsWithResults={questionsWithResults}
            totalResponses={data.surveyResultSummary.responseCount}
          />
        ) : null}

        {questionsWithResults.map((question, index) => {
          const safeHtmlTitle = processUnsafeText(question.title);

          return (
            <SectionPanel key={index} className="!ml-0 !bg-white first-of-type:!mt-0">
              <SectionHeader className="!h-auto !min-h-[44px] !px-3.75">
                <span className="flex items-center space-x-4">
                  <div
                    className={classNames(
                      'flex h-[30px] w-[30px] shrink-0 items-center justify-center rounded-full border border-mystic-500 bg-white pr-px pt-px',
                      'text-md font-semibold leading-none text-lynch-500',
                    )}
                    data-testid="question-number"
                  >
                    {index + 1}
                  </div>
                  <div
                    className="flex"
                    dangerouslySetInnerHTML={{ __html: safeHtmlTitle }}
                    data-testid="question-title"
                  />
                </span>
              </SectionHeader>
              <SectionContent className="relative !mb-9">
                <MetaRow
                  totalViews={question.totalViews}
                  responseCount={question.responseCount}
                  optional={question.optional}
                  showAnswerRate={survey.createdAt > 1658268000 && question.responseCount > 0}
                />
                <div className="!mt-[30px]">
                  <RenderQuestionContent question={question} recentResponses={recentResponses} />
                </div>
              </SectionContent>
            </SectionPanel>
          );
        })}
      </div>
    </>
  );
}

function SummaryTiles({ totalViews, totalResponses }) {
  return (
    <MetricCards className="!justify-start">
      <MetricCards.Card
        className="!w-1/3 min-w-[240px] max-w-[350px]"
        icon={ViewIcon}
        title={
          totalViews === 0 ? (
            <div className="flex items-center">
              <Tooltip
                placement="bottom"
                arrowSkiddingPercent={15}
                interactive={true}
                tooltipContent={
                  <div className="px-2 py-2.5 text-left">
                    <h5 className="mb-2.5 text-xs font-bold text-white">Why are my &apos;total views&apos; 0?</h5>
                    <p className="text-xs font-light leading-tight tracking-wide text-white">
                      Read about possible reasons{' '}
                      <a
                        href={SupportLinks.survey.zeroViews}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-link"
                      >
                        here.
                      </a>
                    </p>
                  </div>
                }
              >
                <InfoIcon className="h-3.5 w-3.5 fill-current text-dodger-blue-300" />
              </Tooltip>
              <span className="ml-2.5">Total views</span>
            </div>
          ) : (
            'Total views'
          )
        }
        value={totalViews}
        color="bg-dodger-blue-300"
        data-testid="total-views"
      />
      <MetricCards.Card
        className="!w-1/3 min-w-[240px] max-w-[350px]"
        icon={SmileyIcon}
        title="Total responses"
        value={totalResponses}
        color="bg-dodger-blue-300"
        data-testid="total-responses"
      />
    </MetricCards>
  );
}

function RenderQuestionContent({ question, recentResponses }) {
  switch (question.type) {
    case SURVEY_QUESTION_TYPES.RATING:
      if (question.subtype === RATING_SUBTYPES.NPS) {
        return <NPS question={question} />;
      }
      return <Rating question={question} />;
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY:
      return <TextEntry question={question} recentResponses={recentResponses} />;
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      return <MultiChoice question={question} />;
    default:
      return null;
  }
}

function SuveyAISummary({ recentResponses, questionsWithResults, totalResponses }) {
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const userPreferredLanguage = useUserLanguage();

  const xmlData = useMemo(() => {
    if (!recentResponses?.surveyResponseList?.responses || !questionsWithResults) return null;

    const getType = ({ type, subtype }) => {
      if (subtype === RATING_SUBTYPES.NPS) return 'net promoter score';
      if (type === SURVEY_QUESTION_TYPES.RATING) return 'rating';
      if (type === SURVEY_QUESTION_TYPES.TEXT_ENTRY) return 'open text';
      if (type === SURVEY_QUESTION_TYPES.MULTI_CHOICE) {
        if (subtype === MULTI_CHOICE_SUBTYPES.MULTI) return 'multiple choice multiple select';
        return 'multiple choice single select';
      }
    };

    const answers = ({ id, type, subtype }) => {
      if (type === SURVEY_QUESTION_TYPES.RATING) {
        const question = questionsWithResults.find((q) => q.id === id);
        if (subtype === RATING_SUBTYPES.NPS) {
          return `<answers>
          ${['promoters', 'passives', 'detractors'].map(
            (v) =>
              `<answer>
                <value>${v}</value>
                <responses>${question.results?.find((r) => r.value === v)?.count ?? 0}</responses>
              </answer>`,
          )}</answers>`;
        }
        const totalCount = question.results?.reduce((acc, curr) => acc + curr.count, 0);
        const average = question.results?.reduce((acc, curr) => acc + curr.count * curr.value, 0) / totalCount;
        return `
        <minimum>${question?.min?.value ?? 1}</minimum>
        <maximum>${question?.max?.value ?? 5}</maximum>
        <average>${average}</average>
        `;
      }

      if (type === SURVEY_QUESTION_TYPES.TEXT_ENTRY) {
        return `<answers>${recentResponses?.surveyResponseList.responses.reduce((acc, resp) => {
          const textResponse = resp.questions.find((q) => q.questionId === id)?.textResponse;
          if (textResponse) return acc + `<answer>${textResponse}</answer>`;
          else return acc;
        }, '')}</answers>`;
      }

      if (type === SURVEY_QUESTION_TYPES.MULTI_CHOICE) {
        const question = questionsWithResults.find((q) => q.id === id);
        const allOptions = [...new Set([...question.options, ...question.results.map((o) => htmlDecode(o.value))])].map(
          (option) => ({
            count: question.results.find((r) => htmlDecode(r.value) === option)?.count ?? 0,
            option,
          }),
        );
        return `<answers>${allOptions.map(
          (o) => `<answer><value>${o.option}</value><responses>${o.count}</responses></answer>`,
        )}</answers>`;
      }
    };

    const questions = questionsWithResults.reduce((acc, question) => {
      if (question.type === SURVEY_QUESTION_TYPES.STATIC_DISPLAY || question.subtype === TEXT_ENTRY_SUBTYPES.EMAIL) {
        return acc;
      }
      return (
        acc +
        `
        <question>
          <title>${question.title}</title>
          <type>${getType({ type: question.type, subtype: question.subtype })}</type>
          ${answers({ id: question.id, type: question.type, subtype: question.subtype })}
        </question>`
      );
    }, '');

    return `
    <data>
      <survey>
        <total_responses>${totalResponses}</total_responses>
        <questions>
          ${questions}
        </questions>
      </survey>
    </data>`;
  }, [recentResponses, questionsWithResults, totalResponses]);

  const params = useMemo(
    () => ({
      data: xmlData,
      language: userPreferredLanguage,
    }),
    [xmlData, userPreferredLanguage],
  );

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
      userId: currentUser?.userId,
    }),
    [location.pathname, location.search, currentUser?.userId],
  );

  const { data, error, refetch, positiveFeedback, negativeFeedback } = useCrazyAI({
    type: 'summary_survey',
    params,
    context,
  });

  const id = useId();
  useBulletPoints({ id, data: data?.text });

  return (
    <AIBox
      className="mb-10"
      size="lg"
      border={true}
      id={id}
      data={data}
      error={error}
      refetch={refetch}
      onPositive={positiveFeedback}
      onNegative={negativeFeedback}
      showChangeLanguage={true}
    />
  );
}
