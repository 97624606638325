import { useFormikContext } from 'formik';

import { Input } from '../../common/basic-ui';
import { DEFAULT_HEADING } from './CTAEditor';
import { useEditorContext } from '../../editor-context';

export function Heading() {
  const { readonly } = useEditorContext();
  const { values, errors, handleChange, handleBlur } = useFormikContext();

  function selectTextOnFocus(e) {
    if (e.target.value === DEFAULT_HEADING) {
      e.target.select();
    }
  }

  return (
    <Input
      label="Heading"
      id="heading"
      name="heading"
      value={values.heading}
      error={errors.heading}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={selectTextOnFocus}
      disabled={readonly}
    />
  );
}
