import { Spinner } from '@crazyegginc/hatch';

import { useSelectedSite } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';

import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';

export function LiveNoEventsYet() {
  const { selectedSite } = useSelectedSite();

  return (
    <Paywall>
      <div className="mt-12 flex flex-col items-center">
        <Paywall.Title>Waiting for events...</Paywall.Title>

        <Paywall.Body>
          <div className="flex flex-col items-center space-y-4">
            <span>Events occur when someone interacts with your website.</span>
            <Spinner className="h-8 w-8 text-cadet-blue-500" />
          </div>
          <div className="mt-10 flex items-center rounded border-2 border-mystic-500 px-6 py-5">
            <div className="mr-3.75 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-solitude-500">
              <ClickIcon className="h-5 w-5 fill-current text-dodger-blue-500" />
            </div>
            <div>
              <span className="text-body-1">While you wait — </span>Visit your website and click around to see your own
              click events appearing here! Visit{' '}
              <a className="text-link" href={`http://${selectedSite.name}`} target="_blank" rel="noreferrer">
                {selectedSite.name}
              </a>{' '}
              to test it out.
            </div>
          </div>
        </Paywall.Body>
      </div>
    </Paywall>
  );
}
