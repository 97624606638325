import { useState } from 'react';
import { Button, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';
import { SHARED_URL_STATUSES } from '/src/features/team-and-sharing/constants';

export function UpdateUrlStatusModal({ onStatusUpdate = null, status, numToUpdate = 1 }) {
  const [submitting, setSubmitting] = useState(false);
  const isStatusEnabled = status === SHARED_URL_STATUSES.ENABLED;
  const textLinkSharing = numToUpdate > 1 ? `${numToUpdate} share links` : 'share link';
  const theseThis = numToUpdate > 1 ? 'these' : 'this';
  const textButton = isStatusEnabled ? `Pause link sharing` : `Resume link sharing`;

  return (
    <Modal dialogClassName="!w-[360px]">
      <Modal.Title>{isStatusEnabled ? `Pause ${textLinkSharing} ?` : `Resume ${textLinkSharing} ?`}</Modal.Title>
      <p className="text-body-5 leading-5">
        {`Are you sure you want to ${
          isStatusEnabled ? 'deactivate' : 'activate'
        } ${textLinkSharing} for ${theseThis} ${inflect(
          'page',
          numToUpdate,
        )}? Anyone with access to ${theseThis} ${inflect('URL', numToUpdate)} will ${
          isStatusEnabled ? 'will no longer' : 'will'
        } able to view the ${inflect('page', numToUpdate)}.`}
      </p>
      <Modal.Actions>
        <Button
          variant={isStatusEnabled ? 'warning' : 'primary'}
          disabled={submitting}
          leftIcon={submitting ? <Spinner className="mr-1 h-3 w-3" /> : null}
          onClick={() => {
            setSubmitting(true);
            onStatusUpdate?.();
          }}
        >
          {submitting ? 'Updating...' : textButton}
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
