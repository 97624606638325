import { useAuthContext } from '/src/hooks';

export function useHasFeatureFlag(flags) {
  const { currentUser } = useAuthContext();

  const hasFlag = (flag) => currentUser?.features?.includes?.(flag) ?? false;

  if (Array.isArray(flags)) {
    return flags.every(hasFlag);
  } else {
    return hasFlag(flags);
  }
}
