import { useState } from 'react';
import { useActor } from '@xstate/react';
import { Input } from '@crazyegginc/hatch';

import { normalizeUrl } from '/src/utils/url';
import { useDebounce } from '/src/hooks';

export function UrlVisitTrigger({ service, triggerIndex }) {
  const [state] = useActor(service);
  const isEditing = window.location.pathname.includes('edit');
  const { send } = service;
  const errors = state.context.errors?.triggers?.[triggerIndex];
  const values = state.context.payload.triggers[triggerIndex];
  const [touched, setTouched] = useState({ targetUrl: isEditing && !!values.targetUrl });
  const debouncedTargetUrl = useDebounce(values.targetUrl);
  const debouncedTargetUrlError = useDebounce(touched.targetUrl ? errors?.targetUrl : null, 500, [debouncedTargetUrl]);

  return (
    <>
      <h2 className="text-header-2 mb-5 mt-10">Your goal is on this page:</h2>

      <div>
        <Input
          placeholder="https://www.example.com or https://www.example.com/*"
          value={values.targetUrl}
          onChange={(e) => {
            setTouched((x) => ({ ...x, targetUrl: true }));
            send({
              type: 'SET_TRIGGER',
              index: triggerIndex,
              key: 'targetUrl',
              value: normalizeUrl(e.target.value.trim(), { appendSlash: false }),
            });
          }}
          error={touched.targetUrl ? debouncedTargetUrlError : null}
        />
        <span className="text-body-4">Example: You can track if someone has reached the confirmation page.</span>
      </div>
    </>
  );
}
