import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useQueryParams } from '/src/hooks';
import { getInitialValue } from '/src/utils/location';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { ACCOUNT_USERS_ORDER } from '/src/features/team-and-sharing/constants';

const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
};
const { SET, RESET } = FILTER_ACTIONS;

const FilterContext = createContext();

// keep this as a function to delay evaluation while the script loads
const initialState = () => {
  return {
    order: getInitialValue('order', [...Object.values(ACCOUNT_USERS_ORDER)]) || ACCOUNT_USERS_ORDER.EMAIL,
    sort: getInitialValue('sort', [SORT_ORDER_TYPES.DESC, SORT_ORDER_TYPES.ASC]) || SORT_ORDER_TYPES.ASC,
  };
};

export function AccountUsersProvider({ children }) {
  function filterReducer(state = initialState(), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case RESET:
        return {
          ...initialState(),
        };
      default:
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, initialState());
  const [filterState, setFilterState] = useState({ filter, filterDispatch });
  if (!isEqual(filter, filterState.filter)) {
    setFilterState({ filter, filterDispatch });
  }
  return <FilterContext.Provider value={filterState}>{children}</FilterContext.Provider>;
}

export function useFilter() {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filter, filterDispatch } = useContext(FilterContext);

  const setSort = useCallback(
    (sort) => {
      queryParamsSet('sort', sort);
      filterDispatch({
        type: SET,
        payload: {
          sort,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  const setOrder = useCallback(
    (order) => {
      queryParamsSet('order', order);
      filterDispatch({
        type: SET,
        payload: {
          order,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  function reset() {
    removeAll(['order', 'sort', 'page']);
    setSort(initialState().sort);
    setOrder(initialState().order);
    filterDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  const { order, sort, ...filterObj } = filter;

  return {
    filter: filterObj,
    order: { field: order, sort },
    setSort,
    setOrder,
    reset,
  };
}
