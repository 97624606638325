import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Panel, Spinner } from '@crazyegginc/hatch';

import { paymentTransactionQuery } from '../queries';
import { useAuthContext } from '/src/hooks';
import { formatDate } from '/src/utils/date';
import { round } from '/src/utils/math';
import { SupportLinks } from '/src/support';
import { PAYMENT_TYPES } from '../constants';

import { ReactComponent as CeLogoIcon } from '@crazyegginc/hatch/dist/images/logo-crazy-egg-color.svg';
import HelpDeskPNG from '@crazyegginc/hatch/dist/images/illustration-helpdesk.png';

/* eslint-disable-next-line no-restricted-syntax */
export default function Receipt() {
  const { id } = useParams();
  const { data, isFetching } = useQuery(paymentTransactionQuery({ id: Number(id) }));
  const { currentAccount } = useAuthContext();

  if (!data && isFetching) {
    return (
      <div className="flex w-full flex-col p-10">
        <div className="mt-6 flex w-full items-center justify-center">
          <Spinner />
          <div className="ml-2.5">Loading...</div>
        </div>
      </div>
    );
  }

  const receipt = data.paymentTransaction;
  const billedTo =
    receipt.companyName ||
    (receipt.firstName && receipt.lastName ? `${receipt.firstName} ${receipt.lastName}` : currentAccount.ownerEmail);
  const invoiceId = `${currentAccount.id}-${String(receipt.id).padStart(7, '0')}`;
  const address = receipt.companyAddress?.address ? receipt.companyAddress : receipt.address;
  const paymentMethod = receipt.paypalEmail || receipt.cardNumber;
  const amount = `$${round(receipt.amount, 2).toFixed(2)}`.replace(/^\$-/, '-$');
  const isRefund = receipt.paymentType === PAYMENT_TYPES.REFUND;

  return (
    <div className="flex w-full justify-center bg-off-white-500 p-10">
      <Panel className="flex !w-[860px] flex-col !p-[30px]">
        <div className="flex w-full items-end justify-between px-2.5">
          <CeLogoIcon className="w-[190px]" aria-label="crazy egg logo" />
          <h1 className="text-header-0 font-bold leading-none ">{isRefund ? 'Refund Receipt' : 'Receipt'}</h1>
        </div>
        <div className="mt-10 flex w-full justify-between px-2.5">
          <div>
            <div className="text-lynch-500">Billed to</div>
            <div>{billedTo}</div>
            {receipt.companyId ? <div>{receipt.companyId}</div> : null}
            {receipt.companyVat ? <div>{receipt.companyVat}</div> : null}
            {address?.address ? (
              <>
                <div>{address.address}</div>
                <div>{`${address.city}${address.stateOrProvince ? `, ${address.stateOrProvince}` : ''} ${
                  address.zipOrPostalCode ?? ''
                }`}</div>
                <div>{address.country}</div>
              </>
            ) : null}
          </div>
          <div className="flex flex-col items-end">
            <div className="text-lynch-500">Invoice ID</div>
            <div>{invoiceId}</div>
            <div className="mt-5 self-end text-lynch-500">Payment method</div>
            <div>{paymentMethod}</div>
          </div>
        </div>
        <div className="mt-10 pl-1.25 text-lg font-bold text-lima-500">Receipt Summary</div>
        <table className="mt-5 w-full">
          <thead>
            <tr className="h-9 bg-white-lilac-500">
              <th className="w-44 pl-1.25 text-left">Date</th>
              <th className="text-left">Description</th>
              <th className="w-44 pr-1.25 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-9 border-b border-mystic-500">
              <td className="pl-1.25">{formatDate(receipt.createdAt)}</td>
              <td className="font-semibold">
                {receipt.planName} {receipt.description ? `- ${receipt.description}` : ''} {isRefund ? ' Refund' : ''}
              </td>
              <td className="pr-1.25 text-right">{amount}</td>
            </tr>
            <tr className="h-9 border-b border-mystic-500">
              <td></td>
              <td></td>
              <td className="pr-1.25 text-right">
                <span className="mr-1.25 text-lynch-500">Subtotal:</span>
                {amount}
              </td>
            </tr>
            <tr className="h-9 border-b-2 border-mystic-500">
              <td></td>
              <td></td>
              <td className="pr-1.25 text-right">
                <span className="mr-1.25 text-lynch-500">Paid:</span>
                {amount}
              </td>
            </tr>
            <tr className="h-9">
              <td></td>
              <td></td>
              <td className="pr-1.25 text-right">
                <span className="mr-1.25 text-lynch-500">Amount Due:</span>
                <span className="font-semibold">$0.00</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-10 flex w-full rounded bg-white-lilac-500 p-5">
          <div className="w-1/2">
            <div className="mt-3 text-lg font-bold text-lima-500">We appreciate your business!</div>
            <div className="mt-4 text-justify leading-snug">
              Thanks for being our customer. If you ever need something, we&apos;re happy to help. Email us at:{' '}
              <a className="text-link text-base" href={`mailto:${SupportLinks.general.email}`}>
                {SupportLinks.general.email}
              </a>{' '}
              or visit one of our other{' '}
              <a
                className="text-link text-base"
                href={SupportLinks.general.helpCenter}
                target="_blank"
                rel="noreferrer"
              >
                support channels
              </a>
              .
            </div>
          </div>
          <div className="flex w-1/2 items-center justify-center">
            <img src={HelpDeskPNG} alt="help desk illustration" width="240px" />
          </div>
        </div>
        <div className="grow" />
        <div className="mt-7">
          <strong className="font-semibold">Crazy Egg, Inc.</strong> &#183; 16220 Ridgeview Lane, La Mirada, CA 90638
          &#183; EIN: 20-4628383 &#183;{' '}
          <a href="https://www.crazyegg.com/" target="_blank" rel="noreferrer" className="text-link text-base">
            crazyegg.com
          </a>
        </div>
      </Panel>
    </div>
  );
}
