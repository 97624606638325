import { useFormikContext } from 'formik';
import { Checkbox } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { usePermissions, useWizard } from '/src/hooks';

export function RecurringSnapshot({ isEditing }) {
  const { values, handleChange } = useFormikContext();
  const { data: wizardData } = useWizard();
  const permissions = usePermissions();

  const disableRecurring =
    isEditing &&
    ((!values.recurringSnapshot && !permissions.can('setReoccurring', wizardData.existingSnapshot).allowed) ||
      (values.recurringSnapshot && !permissions.can('unsetReoccurring', wizardData.existingSnapshot).allowed));

  return (
    <div className="flex flex-wrap">
      <div className="text-header-2 mr-6">Recurring Snapshot</div>
      <div className="flex w-full">
        <Checkbox
          label="Enable recurring snapshot"
          id="reoccuring-checkbox"
          name="recurringSnapshot"
          checked={values.recurringSnapshot}
          onChange={handleChange}
          disabled={disableRecurring}
        />
      </div>
      <div className="text-body-5 mb-3 mt-2.5 leading-normal">
        Snapshots will auto-renew immediately with the same settings as soon as it ends.
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={SupportLinks.snapshots.recurringSnapshot}
        className="text-link cursor-pointer"
      >
        Learn more about recurring snapshots
      </a>
    </div>
  );
}
