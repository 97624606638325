import { Helmet } from 'react-helmet';

export function SEO({ lang = 'en', title, description = null, meta = {} }) {
  const metaDescription =
    description ||
    'Use Crazy Egg to start converting your visitors into customers. You can get a new heat map up and running in minutes.';
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`Crazy Egg - %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}
