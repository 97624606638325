import { Button } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { Panel } from '../SsoUi';
import { SupportLinks } from '/src/support';

export function NotAllowedMessage() {
  const { currentAccount } = useAuthContext();

  return (
    <Panel>
      <p className="text-body-2 mb-7">
        You are not authorized to view the Single Sign-On settings for this account. If you believe this is a mistake,
        please contact your account owner or support for further assistance.
      </p>
      <div className="flex gap-x-2.5">
        <Button onClick={() => window.open(`mailto:${currentAccount.ownerEmail}`, '_blank')}>
          Contact Account Owner
        </Button>
        <Button onClick={() => window.open(SupportLinks.general.newSupportRequest, '_blank')}>Contact Support</Button>
      </div>
    </Panel>
  );
}
