import { useEffect } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { useWizard } from '/src/hooks';
import { CodeBox } from '/src/components/CodeBox';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { useScriptUrl } from '/src/features/installation/utils';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

import Magento2Poster from '../assets/magento2-instructions-poster.jpg';
import Magento2Video from '../assets/magento2-instructions.mp4';

function Magento1({ code }) {
  return (
    <>
      <div className="text-header-4 mb-2.5">Instructions for Magento 1:</div>
      <WizardList>
        <WizardList.Item>Log in to your Magento account, and open your admin panel.</WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">System &gt; Configuration</strong>.
        </WizardList.Item>
        <WizardList.Item>
          In the panel on the left, under General, click on <strong className="text-body-1">Design</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click to expand the <strong className="text-body-1">HTML Head</strong> section.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste the Crazy Egg tracking script below into the{' '}
          <strong className="text-body-1">Miscellaneous Scripts</strong> text box.
        </WizardList.Item>
        <WizardList.Item>
          Click on the <strong className="text-body-1">Save Config</strong> button.
        </WizardList.Item>
      </WizardList>

      <div className="my-5 rounded bg-white-lilac-500 px-6 py-5">
        <strong className="text-body-1">Note:</strong> If you are using a cache plugin on your site, you will need to
        clear it after you have installed your Crazy Egg code.
      </div>

      <CodeBox loading={false} code={code} />

      <a href={SupportLinks.install.magento1} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Magento 1
      </a>
    </>
  );
}

function Magento2({ code }) {
  return (
    <>
      <div className="text-header-4 mb-2.5">Instructions for Magento 2:</div>
      <WizardList>
        <WizardList.Item>Log in to your Magento account, and open your admin panel.</WizardList.Item>
        <WizardList.Item>
          In the panel on the left, click on <strong className="text-body-1">Content &gt; Configuration</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Find the store view that you want to configure. Then, in the Action column, click{' '}
          <strong className="text-body-1">Edit</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click to expand the <strong className="text-body-1">HTML Head</strong> section.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste the Crazy Egg tracking script below into the{' '}
          <strong className="text-body-1">Scripts and Style Sheets</strong> text box.
        </WizardList.Item>
        <WizardList.Item>
          Click on the <strong className="text-body-1">Save Configuration</strong> button.
        </WizardList.Item>
      </WizardList>

      <div className="my-5 rounded bg-white-lilac-500 px-6 py-5">
        <strong className="text-body-1">Note:</strong> If you are using a cache plugin on your site, you will need to
        clear it after you have installed your Crazy Egg code.
      </div>

      <CodeBox loading={false} code={code} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:{' '}
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={Magento2Video} poster={Magento2Poster} label="Magento 2 installation video" />
      </div>

      <a href={SupportLinks.install.magento2} className="text-link mt-2.5 block" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Magento 2
      </a>
    </>
  );
}

export function MagentoInstructions() {
  const [code] = useScriptUrl();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/magento/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Magento',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Magento!</WizardHeader>
      <WizardSubHeader>Follow the instructions below for a completely code-free install.</WizardSubHeader>

      <Tab.Group>
        <Tab.List className="mb-6 flex border-b border-mystic-500">
          {['Magento 1', 'Magento 2'].map((header) => (
            <Tab
              key={header}
              className={({ selected }) =>
                classNames('text-header-5 border-b-2 px-5 pb-3 focus:outline-none focus-visible:outline-black', {
                  'border-dodger-blue-500': selected,
                  'border-transparent': !selected,
                })
              }
            >
              {header}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Magento1 code={code} />
          </Tab.Panel>

          <Tab.Panel>
            <Magento2 code={code} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Magento.
      </div>
    </div>
  );
}
