import { useEffect } from 'react';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';

// eslint-disable-next-line no-restricted-syntax
export default function NewCTA() {
  useEffect(() => {
    if (window.Metrics) {
      window.Metrics.used('CTA: Editor - New');
    }
  }, []);

  return <CTAEditor />;
}
