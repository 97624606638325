import { DEVICE_TYPES } from '/src/features/_global/constants';
import { REFERRER_VALUES, REPEAT_VALUES, BEHAVIOR_TRIGGERS } from '/src/features/addons/constants';

import { replaceEmojiWithImage, replaceImageWithColons } from '../common-functions';
import { LOGICALS } from '/src/features/_global/constants';

export function generateAudienceAddonFormat(audience) {
  const result = {
    version: 1,
    operator: LOGICALS.AND,
    conditions: [
      {
        criteria: 'device',
        values: audience.device.map((x) => x.replace(DEVICE_TYPES.MOBILE, DEVICE_TYPES.PHONE).toLowerCase()),
        comparison: 'is',
      },
    ],
  };

  if (!audience.allPages) {
    result.conditions.push({
      criteria: 'visited_page',
      multiple_values: 'or',
      comparison: 'matches',
      values: [...audience.pages],
    });
  }

  if (audience.referrerOption !== REFERRER_VALUES.EVERYONE) {
    result.conditions.push({
      criteria: 'referrer',
      multiple_values: 'or',
      comparison: 'matches',
      values: audience.referrerOption === REFERRER_VALUES.DIRECT_TRAFFIC ? [''] : [...audience.referrers],
    });
  }

  return result;
}

export function generateAudienceEditorFormat(audience, site) {
  const result = {};

  const devices = audience.conditions?.find((x) => x.criteria === 'device')?.values;
  if (devices?.length > 0) {
    result.device = devices.map((y) => y.toUpperCase().replace(DEVICE_TYPES.PHONE, DEVICE_TYPES.MOBILE));
  }

  const visited_pages = audience.conditions?.find((x) => x.criteria === 'visited_page')?.values;
  if (visited_pages?.length > 0) {
    result.allPages = false;
    result.pages = [...visited_pages];
  } else {
    result.allPages = true;
  }

  const referrers = audience.conditions?.find((x) => x.criteria === 'referrer')?.values;
  if (!referrers) {
    result.referrerOption = REFERRER_VALUES.EVERYONE;
  } else if (referrers.length === 1 && referrers[0] === '') {
    result.referrerOption = REFERRER_VALUES.DIRECT_TRAFFIC;
  } else {
    result.referrerOption = REFERRER_VALUES.REFERRER;
    result.referrers = [...referrers];
  }

  if (site) {
    result.site = structuredClone(site);
  }

  return result;
}

export function generateBehaviorAddonFormat(behavior) {
  const result = {
    show: behavior.show,
    repeat: behavior.repeat,
  };

  switch (behavior.show) {
    case BEHAVIOR_TRIGGERS.TIME_DELAY:
      result.timeDelaySeconds = behavior.timeDelaySeconds;
      break;
    case BEHAVIOR_TRIGGERS.NUM_PAGES:
      result.numPages = behavior.numPages;
      break;
    case BEHAVIOR_TRIGGERS.FEEDBACK:
      result.feedback = { ...behavior.feedback };
      result.feedback.text = replaceEmojiWithImage(behavior.feedback.text);
      break;
  }

  switch (behavior.repeat) {
    case REPEAT_VALUES.NUMBER:
      result.repeatCount = behavior.repeatCount;
      break;
  }

  return result;
}

export function generateBehaviorEditorFormat(behavior) {
  const result = { ...behavior };
  if (behavior.feedback) {
    result.feedback.text = replaceImageWithColons(behavior.feedback.text);
  }
  return result;
}
