import { useFormikContext } from 'formik';

import { Input } from '../../common/basic-ui';
import { DEFAULT_BUTTON_TITLE } from './CTAEditor';
import { useEditorContext } from '../../editor-context';

export function ButtonText() {
  const { readonly } = useEditorContext();
  const { values, errors, handleChange, handleBlur } = useFormikContext();

  function selectTextOnFocus(e) {
    if (e.target.value === DEFAULT_BUTTON_TITLE) {
      e.target.select();
    }
  }

  return (
    <Input
      label="Button text"
      id="buttonTitle"
      name="buttonTitle"
      value={values.buttonTitle}
      error={errors.buttonTitle}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={selectTextOnFocus}
      disabled={readonly}
    />
  );
}
