import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions, useMetric } from '/src/hooks';
import { Link } from 'react-router-dom';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';
import { FEATURES, MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import ABTestingPNG from '@crazyegginc/hatch/dist/images/illustration-ab-test.png';
import ABTestingWebP from '@crazyegginc/hatch/dist/images/illustration-ab-test.webp';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function ABTestingUpgradeModal() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const trackMetric = useMetric();

  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Modal dialogClassName="!w-[640px]">
      <div className="text-body-2 flex flex-col items-center py-5 text-center">
        <WebP webp={ABTestingWebP} fallback={ABTestingPNG} width="245px" height="150px" />
        <div className="text-header-2 mt-5">Learn what works with A/B Testing</div>
        <div className="mb-6 mt-4 px-10 text-center">
          Test new ideas for your goal and quickly see which one converts the best!
          {!canManageBilling ? (
            <p className="mt-5">
              To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
              the plan.
            </p>
          ) : null}
        </div>

        {canManageBilling ? (
          <Button
            component={Link}
            to={MANAGE_SUBSCRIPTION_PATH}
            onMouseDown={() => trackMetric('upgrade_cta_click', 'ab_testing_upsell_modal')}
          >
            Get A/B Testing
            <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
          </Button>
        ) : (
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
            Contact account owner
          </Button>
        )}
      </div>
    </Modal>
  );
}
