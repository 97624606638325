import { useRef, useState } from 'react';
import classNames from 'classnames';
import { CloseButton, Button, Spinner } from '@crazyegginc/hatch';

import { useMutation, useOutsideClickNotify, useSelectedSite } from '/src/hooks';
import { deletePlaylistMutation } from '/src/features/recordings/mutations';

export function PlaylistDeleteDialog({ open, onClose, buttonRef, filtersHook }) {
  const { selectedSite } = useSelectedSite();
  const dialogRef = useRef();
  const [deleting, setDeleting] = useState(false);

  const { playlists, playlistsListQuery, clearFilters } = filtersHook;

  const playlist = playlistsListQuery.data?.playlistsList.find((x) => x.id === playlists.active);

  useOutsideClickNotify([dialogRef, buttonRef], () => onClose(), open);

  const deletePlaylist = useMutation(deletePlaylistMutation);

  if (!open) return null;

  const handleDelete = () => {
    if (deleting) return;
    setDeleting(true);
    const start = performance.now();
    deletePlaylist.mutate(
      { site: selectedSite?.id, playlist: playlist.id },
      {
        onSettled: () => {
          const end = performance.now();
          setTimeout(
            () => {
              setDeleting(false);
              clearFilters();
              onClose();
            },
            Math.max(1200 - (end - start), 0),
          );
        },
      },
    );
  };

  return (
    <>
      <span
        className="fixed inset-0 z-[9998] block"
        style={{
          background: 'rgba(6,20,33,0.1)',
        }}
      />
      <div
        className={classNames(
          'flex w-[350px] animate-scalein flex-col rounded-md border border-mystic-500 bg-white p-6 shadow-md',
          'absolute right-0 top-[35px] z-[9999]',
        )}
        ref={dialogRef}
      >
        <CloseButton onClick={onClose} label="close delete playlist dialog" />
        <div className="text-header-3 mb-6">Delete this playlist?</div>
        <div className="text-body-2 leading-normal">
          Are you sure you want to delete this playlist? <br />
          This can’t be undone.
        </div>
        <div className="mt-[30px] flex">
          <Button variant="warning" onClick={handleDelete} disabled={deleting}>
            {deleting && (
              <div className="mr-2.5">
                <Spinner />
              </div>
            )}
            {deleting ? 'Deleting playlist...' : 'Yes, delete this playlist'}
          </Button>
          <Button variant="cancel" className="!ml-2.5" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}
