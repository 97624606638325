import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useSearchParams } from '/src/hooks';
import { authAtom, SHARED_RESOURCE_NOT_FOUND, PRESENT_SHARED_RESOURCE } from '../machines/auth';
import { getQueryParams } from '/src/utils/url';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

export function SharedSessionHandler() {
  const [state] = useAtom(authAtom);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (state.value === PRESENT_SHARED_RESOURCE && state?.context?.sharedResource) {
      const { resource } = state.context.sharedResource;
      const path = getPathForResource(resource.resourceType, resource.resourceId, {
        ...Object.fromEntries(searchParams),
        site: resource.siteName,
        code: state.context.sharingCode,
      });
      navigate(path, { replace: true });
    }
  }, [
    navigate,
    state.context.sharedResource,
    state.context.sharingCode,
    state.context.token,
    state.value,
    searchParams,
  ]);

  useEffect(() => {
    if (state.value === SHARED_RESOURCE_NOT_FOUND) {
      navigate('/not-found');
    }
  }, [navigate, state.value]);

  return <LoaderBalloonScreen />;
}

const resourcePaths = {
  [SHARABLE_RESOURCE_TYPES.ERROR]: '/errors/:id',
  [SHARABLE_RESOURCE_TYPES.RECORDING]: '/recordings/:id/player',
  [SHARABLE_RESOURCE_TYPES.SURVEY]: '/addons/:id',
  [SHARABLE_RESOURCE_TYPES.CTA]: '/addons/:id',
  [SHARABLE_RESOURCE_TYPES.ABTEST]: '/ab-tests/:id',
  [SHARABLE_RESOURCE_TYPES.GOAL]: '/goals/:id',
};

function getPathForResource(type, id, params = {}) {
  const search = getQueryParams(params);
  return `${resourcePaths[type].replace(':id', id)}?${search}`;
}
