import { useEffect, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Toggle, TextArea, Panel, Divider } from '@crazyegginc/hatch';

import { useMutation, useMetric, useAuthContext, usePermissions, useNotifications, useModal } from '/src/hooks';
import { optionsQuery } from '/src/features/options/queries';
import { updateBlockedIpsMutation, updateUserSettingsMutation } from '/src/features/options/mutations';
import { OptionsHeader } from '../components/OptionsHeader';
import { CurrencySelector } from '/src/components/CurrencySelector';
import { EmailTrackModal } from '/src/features/options/components/modals/EmailTrackModal';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES, CAPABILITY_REASONS, CURRENCIES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsAdvancedTrackingScreen() {
  const permissions = usePermissions();
  const notifications = useNotifications();
  const { currentUser } = useAuthContext();
  const modal = useModal();

  const trackMetric = useMetric();

  const updateUserSettings = useMutation(updateUserSettingsMutation);
  const { mutateAsync: asyncUpdateUserSettings } = updateUserSettings;

  const { dnt, autoIdentity, defaultCurrency, explicitAgent, useSecureCookies } = currentUser.settings;
  const { allowed: canEdit, reason } = permissions.can('edit', FEATURES.TRACKING_OPTIONS);

  const { data } = useQuery({
    ...optionsQuery(),
    enabled: Boolean(canEdit),
  });

  const { data: mutationResult, isLoading: updating, mutate: updateIpList } = useMutation(updateBlockedIpsMutation);
  const [dataInitialized, setDataInitialized] = useState(false);
  const [ipList, setIpList] = useState('loading...');
  const [currency, setCurrency] = useState(() => defaultCurrency);

  useEffect(() => {
    if (data && !dataInitialized) {
      setDataInitialized(true);
      setIpList(data?.options?.userBlockedIps ?? '');
    }
  }, [data, dataInitialized]);

  const currentIp = data?.options?.currentIp ?? null;

  function updateIps() {
    if (!canEdit) return;

    updateIpList(
      {
        ipList,
      },
      {
        onError: (error) =>
          notifications.error({
            content: `Changes failed to update`,
            timeout: 5000,
            context: { error },
          }),
        onSuccess: (data) => {
          if (data.updateBlockedIps.errors !== null) {
            notifications.error({
              content: `Changes failed to update`,
              timeout: 3000,
              skipHoneybadger: true,
            });
          } else {
            notifications.success({
              content: `Changes saved successfully!`,
              timeout: 3000,
            });
          }
          // set the resultant text from the mutation as
          // the new textarea value
          setIpList(data.updateBlockedIps.text);
        },
      },
    );
  }

  function addMyIp() {
    if (!canEdit) return;

    let modifiedList;
    if (ipList.trim().length > 0) {
      modifiedList = `${ipList}${ipList.endsWith('\n') ? '' : '\n'}${data?.options?.currentIp}\n`;
    } else {
      modifiedList = `${data?.options?.currentIp}\n`;
    }
    setIpList(modifiedList);
    updateIpList(
      {
        ipList: modifiedList,
      },
      {
        onError: (error) =>
          notifications.error({
            content: `Changes failed to update`,
            timeout: 5000,
            context: { error },
          }),
        onSuccess: (data) => {
          setIpList(data.updateBlockedIps.text);
        },
      },
    );
  }

  const trackEmails = useCallback(() => {
    modal.show(
      <EmailTrackModal
        onTrack={() => {
          return asyncUpdateUserSettings(
            {
              autoIdentity: !autoIdentity,
            },
            {
              onError: (error) =>
                notifications.error({ content: 'Updating email tracking failed.', timeout: 3000, context: { error } }),
              onSuccess: () => {
                modal.close();
              },
            },
          );
        }}
      />,
    );
  }, [modal, notifications, asyncUpdateUserSettings, autoIdentity]);

  if (!canEdit && reason === CAPABILITY_REASONS.UPGRADE) {
    return (
      <>
        <OptionsHeader>Block specific IPs from being tracked</OptionsHeader>
        <Panel className="py-[30px]">
          <div className="text-body-2 mb-7">
            Upgrade your plan to exclude your visits or your team&apos;s visits from being tracked.
          </div>
          <Button
            component={Link}
            onMouseDown={() => trackMetric('upgrade_cta_click', 'advanced_tracking_upsell')}
            to={MANAGE_SUBSCRIPTION_PATH}
            className="self-start"
          >
            Upgrade now
          </Button>
        </Panel>
        <OptionsHeader className="mt-5">Email Identity Tracking</OptionsHeader>
        <Panel className="py-[30px]">
          <div className="text-body-2 mb-7">
            Upgrade your plan to automatically associate emails provided in survey responses as the visitor ID.
          </div>
          <Button
            component={Link}
            onMouseDown={() => trackMetric('upgrade_cta_click', 'advanced_tracking_upsell')}
            to={MANAGE_SUBSCRIPTION_PATH}
            className="self-start"
          >
            Upgrade now
          </Button>
        </Panel>
        <OptionsHeader className="mt-5">HTTPS-Only Cookies</OptionsHeader>
        <Panel className="py-[30px]">
          <div className="text-body-2 mb-7">Upgrade your plan to set the secure attribute on any cookies.</div>
          <Button
            component={Link}
            onMouseDown={() => trackMetric('upgrade_cta_click', 'advanced_tracking_upsell')}
            to={MANAGE_SUBSCRIPTION_PATH}
            className="self-start"
          >
            Upgrade now
          </Button>
        </Panel>
      </>
    );
  }

  if (!canEdit) {
    return <Navigate replace to="/options" />;
  }

  return (
    <>
      <OptionsHeader>Do Not Track Setting</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="mb-5">
          <Toggle
            enabled={dnt}
            label="Ignore Do Not Track (DNT)"
            displayLabel={true}
            setEnabled={() => updateUserSettings.mutate({ dnt: !dnt })}
            labelClasses="!font-semibold"
          />
        </div>
        <div className="text-body-2 leading-snug">
          Crazy Egg <strong>WILL {!dnt && 'NOT'}</strong> track your website and visitors
          {dnt
            ? `, even if the visitor's browser setting has Do Not Track enabled. Your Crazy Egg data will be comparable to Google
          Analytics data.`
            : ` when the visitor's browser setting has Do Not Track enabled. Your Crazy Egg data will be different compared to Google Analytics data.`}
        </div>
      </Panel>

      <OptionsHeader>Email Identity Tracking</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="mb-5">
          <Toggle
            enabled={autoIdentity}
            label="Automatically Track Email Addresses"
            displayLabel={true}
            setEnabled={() =>
              autoIdentity ? updateUserSettings.mutate({ autoIdentity: !autoIdentity }) : trackEmails()
            }
            labelClasses="!font-semibold"
          />
        </div>
        <div className="text-body-2 leading-snug">
          When Crazy Egg detects an email address entered in a form field or survey response, Crazy Egg{' '}
          <strong>WILL {!autoIdentity && 'NOT'}</strong> associate that email address as the visitor profile ID
          {autoIdentity
            ? ' (instead of a random anonymous ID).'
            : '. The visitor profile ID will show a random anonymous ID.'}
        </div>
      </Panel>

      <OptionsHeader>HTTPS-Only Cookies</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="mb-5">
          <Toggle
            enabled={useSecureCookies}
            label="Only send cookies over HTTPS"
            displayLabel={true}
            setEnabled={() => updateUserSettings.mutate({ useSecureCookies: !useSecureCookies })}
            labelClasses="!font-semibold"
          />
        </div>
        <div className="text-body-2 leading-snug">
          Crazy Egg <strong>WILL {!useSecureCookies && 'NOT'}</strong> set the Secure attribute on any cookies it sets.{' '}
          {useSecureCookies
            ? `This will mean that Crazy Egg will only work properly on SSL-enabled pages that start with https://.`
            : 'This will allow Crazy Egg to work on both https:// and http:// pages.'}
        </div>
      </Panel>

      <OptionsHeader className="mt-5">Crazy Egg Bot User Agent</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="mb-5">
          <Toggle
            enabled={explicitAgent}
            label="Crazy Egg Bot User Agent"
            displayLabel={true}
            setEnabled={() => updateUserSettings.mutate({ explicitAgent: !explicitAgent })}
            labelClasses="!font-semibold"
          />
        </div>
        <div className="text-body-2 leading-snug">
          {explicitAgent ? (
            <>
              When enabled Crazy Egg will use the following User Agents when accessing your site:{' '}
              <span className="rounded-sm bg-[#ededeb] px-2 py-0.5 text-xs text-[#eb5252]">Crazy Egg Bot</span> or{' '}
              <span className="rounded-sm bg-[#ededeb] px-2 py-0.5 text-xs text-[#eb5252]">Crazy Egg Bot (Mobile)</span>
              . If the default User Agent is being blocked by your site, enabling this will allow you to whitelist just
              the Crazy Egg bot so we can capture your pages successfully. Crazy Egg&apos;s bot is on Cloudflare&apos;s
              Verified Good Bot list. Enabling this option allows Cloudflare to correctly identify and allow Crazy Egg
              to access your site.
            </>
          ) : (
            <>
              When disabled Crazy Egg will use a common Chrome User Agent for a desktop, mobile, or tablet device by
              default. This is less likely to get blocked by automated website software, but it&apos;s not recommended
              if you use Cloudflare. If you use Cloudflare you should enable this option to take advantage of
              Cloudflare&apos;s Verified Good Bot list.
            </>
          )}
        </div>
      </Panel>

      <OptionsHeader>Block specific IPs from being tracked</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="text-body-2 mb-5 pr-[30%] leading-snug">
          All Snapshots, Recordings, A/B Tests, and Surveys will ignore all visits and clicks from any of these
          addresses listed here. This means that Crazy Egg will exclude these IP addresses when collecting Snapshots,
          Recordings, & A/B Testing data, so you can omit traffic generated by your internal team. A/B Tests and Surveys
          will not be displayed to any visitors from these IPs. Add the IP addresses below.
        </div>

        <div className="text-header-4 mb-2.5">Examples:</div>
        <ul className="text-body-2 mb-5 space-y-2">
          <li>127.0.0.1</li>
          <li>127.0.0.*</li>
          <li>127.0.0.0-127.0.2.255</li>
        </ul>

        <div className="w-[470px]">
          <TextArea
            resizeable="none"
            rows={10}
            value={ipList}
            onChange={(e) => setIpList(e.target.value)}
            disabled={!dataInitialized}
          />
        </div>

        <div className="flex flex-col">
          {mutationResult?.updateBlockedIps?.errors?.map((error, idx) => {
            if (error?.line) {
              return (
                <div className="text-error my-1.25" key={`BlockedIpError${idx}`}>
                  <strong>Line {error.line}:</strong> {error.message}
                </div>
              );
            }
            return (
              <div className="text-error my-1.25" key={`BlockedIpError${idx}`}>
                {error.message}
              </div>
            );
          })}
        </div>

        <Button
          disabled={updating || ipList === data?.options?.userBlockedIps}
          onClick={updateIps}
          className="!mt-3.5"
          variant="secondary"
        >
          Update Settings
        </Button>

        <Divider className="my-[30px]" />

        <div className="text-body-2 mb-5">
          {ipList.indexOf(currentIp) > -1 ? (
            <>
              Current IP address <strong className="text-body-1">{currentIp}</strong> is on the block list
            </>
          ) : (
            <>
              Add my current IP address <strong className="text-body-1">{currentIp}</strong> to the block list
            </>
          )}
        </div>

        <Button variant="secondary" onClick={addMyIp} disabled={updating || ipList.indexOf(currentIp) > -1}>
          Add My IP
        </Button>
      </Panel>

      <OptionsHeader>Currency settings</OptionsHeader>
      <Panel className="mb-[50px]">
        <div className="text-body-2 pr-[30%] leading-snug">
          <p className="mb-2.5">
            Set the default currency for your user sessions. This will only effect sessions where a currency is
            unspecified. For example, if you use Shopify, your sessions will have a currency specified by your Shopify
            store.
          </p>
          <div className="w-1/2">
            <CurrencySelector
              value={currency || CURRENCIES.USD}
              onSelect={(defaultCurrency) => {
                setCurrency(defaultCurrency);
                updateUserSettings.mutate({ defaultCurrency });
              }}
            />
          </div>
        </div>
      </Panel>
    </>
  );
}
