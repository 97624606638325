import { memo, useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { DeviceIcon, Indicator } from '@crazyegginc/hatch';
import { useSelector } from '@xstate5/react';

import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as CalendarIcon } from '@crazyegginc/hatch/dist/images/icon-calendar-outline.svg';
import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as PinIcon } from '@crazyegginc/hatch/dist/images/icon-pin-filled.svg';
import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as CurrencyIcon } from '@crazyegginc/hatch/dist/images/icon-currency-outline.svg';

import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';
import { useMutation, useAuthContext, usePermissions, useVisitorId } from '/src/hooks';

import { formatFullDateTime } from '/src/utils/date';
import { inflect } from '/src/utils/string';
import { Gravatar } from '/src/components/gravatar';
import { FilterTagsSelect } from '../recordings-filter/FilterSelect';
import { DeviceDescription } from '/src/components/DeviceDescription';

import { addTagsToRecordingsMutation, removeTagsFromRecordingsMutation } from '/src/features/recordings/mutations';

function SidebarVisitorComponent({ actorRef }) {
  const opened = useSelector(actorRef, (state) => state.context.showVisitorSidebar);
  const recording = useSelector(actorRef, (state) => state.context.recording);

  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(recording.tags);
  }, [recording.tags, setTags]);

  const { isSharing } = useAuthContext();

  const { selectVisitor } = useVisitorId();

  const permissions = usePermissions();
  const canTag = recording && permissions.can('tag', recording).allowed;
  const addTagsToRecordings = useMutation(addTagsToRecordingsMutation);
  const removeTagsFromRecordings = useMutation(removeTagsFromRecordingsMutation);

  const hideTags = !canTag && (recording?.tags?.length ?? 0) === 0;

  const identifier = recording?.identifier ?? recording?.visitorId ?? null;
  const recordingText = recording?.identifier ? identifier : `Visitor #${identifier}`;

  const hasSessionWorth =
    (recording?.maxValue?.originalWorth ?? 0 > 0) || (recording?.finalValue?.originalWorth ?? 0 > 0);

  const openVisitorPanel = useCallback(
    (visitor) => {
      selectVisitor(visitor);
    },
    [selectVisitor],
  );

  const screenDimensions =
    recording?.screenWidth && recording?.screenHeight && `${recording.screenWidth}x${recording.screenHeight}`;

  const handleTagChange = (tags, { action, removedValue, option }) => {
    if (canTag) {
      if (action === 'select-option' || action === 'create-option') {
        addTagsToRecordings.mutate(
          { recordingIds: [recording.id], tags: tags.map((t) => t.value ?? t) },
          {
            onSuccess() {
              setTags((current) => [...current, option.value]);
            },
          },
        );
      } else if ((action === 'remove-value' || action === 'pop-value') && removedValue) {
        removeTagsFromRecordings.mutate(
          { recordingIds: [recording.id], tags: [removedValue] },
          {
            onSuccess() {
              setTags((current) => current.filter((t) => t !== removedValue));
            },
          },
        );
      }
    }
  };

  function toggleOpened() {
    actorRef.send({ type: 'TOGGLE_VISITOR_SIDEBAR' });
  }

  return (
    <header className="z-10 mb-7 shadow-lg">
      <div
        className={classNames('flex w-full items-center bg-woodsmoke-500 px-3 py-2 outline-none sm:px-3.5', {
          'rounded-t': opened,
          rounded: !opened,
        })}
      >
        <div className="relative flex flex-1 items-center overflow-hidden">
          <div className="mr-3.5 flex h-11 w-11 items-center justify-center rounded-full bg-white" role="presentation">
            <Gravatar email={identifier} />
          </div>
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              <button onClick={toggleOpened} type="button" className="truncate text-left font-semibold text-white">
                {recordingText}
              </button>
              <button className="flex h-3 w-12 items-center justify-center p-2 text-white" onClick={toggleOpened}>
                <Indicator type="expand" up={opened} className="!h-3 !w-3" aria-label="Expand recording meta info" />
              </button>
            </div>
            {!isSharing ? (
              <button
                type="button"
                className="flex items-center text-xs text-dodger-blue-500 hover:underline focus:outline-none"
                onClick={() => openVisitorPanel({ identifier: recording.identifier, visitorId: recording.visitorId })}
              >
                View profile <ExpandIcon className="ml-1 h-2 w-2 rotate-90 fill-current" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {opened ? (
        <ul className="flex flex-wrap rounded-b bg-charade-500 p-5 text-sm font-semibold text-cadet-blue-500 opacity-90">
          {recording?.recordedAt ? (
            <li className="flex items-center pb-3 md:w-1/2 md:pr-3 lg:w-full lg:pr-0">
              <CalendarIcon className="mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
              <span className="flex-1 truncate">{formatFullDateTime(recording.recordedAt)}</span>
            </li>
          ) : null}

          {recording?.device || recording?.browser || recording?.os || screenDimensions ? (
            <li className="flex items-center pb-3 md:w-1/2 lg:w-full">
              <DeviceIcon device={recording?.device} tooltip={false} className="mr-2.5" />
              <DeviceDescription browser={recording.browser} os={recording.os} screenDimensions={screenDimensions} />
            </li>
          ) : null}

          <li className="flex items-center pb-3 md:w-1/2 lg:w-full">
            <PageIcon className="mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
            <span className="flex-1 truncate">
              {recording.visitedPagesCount} {inflect('page', recording.visitedPagesCount)}
            </span>
          </li>

          {recording?.country?.name ? (
            <li className="flex items-center pb-3 md:w-1/2 lg:w-full">
              <PinIcon className="mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
              <span className="flex-1 truncate">{recording.country.name}</span>
            </li>
          ) : null}

          {hasSessionWorth ? (
            <li className="flex items-center pb-3 md:w-1/2 lg:w-full">
              <CurrencyIcon className="mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
              <div className="flex flex-1 items-center space-x-1.5 truncate">
                <span className="flex space-x-1">
                  <span>Max:</span>
                  <DisplayCurrency value={recording.maxValue} textOnly={true} />
                </span>
                <span>&bull;</span>
                <span className="flex space-x-1">
                  <span>Final:</span>
                  <DisplayCurrency value={recording.finalValue} textOnly={true} />
                </span>
              </div>
            </li>
          ) : null}

          {!hideTags ? (
            <li className="flex w-full items-center">
              <TagIcon className="mr-2.5 h-4 w-4 fill-current text-cadet-blue-500" />
              <div className="flex-1">
                <FilterTagsSelect
                  placeholder="Add tags to this recording"
                  value={tags}
                  onChange={handleTagChange}
                  label="tags"
                  creatable={true}
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  containerStyle={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}
                  playerStyle={true}
                  inlineStyle={true}
                  isDisabled={!canTag}
                />
              </div>
            </li>
          ) : null}
        </ul>
      ) : null}
    </header>
  );
}

export const SidebarVisitor = memo(SidebarVisitorComponent);
