import { useNavigate, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Spinner } from '@crazyegginc/hatch';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

import { useModal, useWizard } from '/src/hooks';

import { ConfirmLeaveWizardModal } from '../ConfirmLeaveWizardModal';

export function WizardFooter() {
  const wizard = useWizard();
  const modal = useModal();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const {
    currentStep,
    totalSteps,
    title,
    back,
    backEnabled,
    next,
    nextText,
    nextEnabled,
    cancelConfirm = null,
    loading = false,
    returnLocation,
  } = wizard.state;

  const isNextEnabled = typeof nextEnabled === 'function' ? nextEnabled() : nextEnabled;
  const isBackEnabled = typeof backEnabled === 'function' ? backEnabled() : backEnabled;

  const backToDashboard = () => {
    modal.close();
    const path = returnLocation ?? '/';
    navigate(path, { replace: true });
  };

  const showConfirmModal = () => {
    modal.show(<ConfirmLeaveWizardModal onConfirm={backToDashboard} />);
  };

  if (!title) {
    return null;
  }

  function shouldConfirmNavigate() {
    if (typeof cancelConfirm === 'function') return cancelConfirm();
    return cancelConfirm || wizard.data.dirty;
  }

  return (
    <div
      className="fixed inset-x-0 bottom-0 z-[89] grid h-[90px] grid-cols-3 grid-rows-1 border-t border-mystic-500 bg-white px-8"
      style={{ boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="flex items-center">
        <div className="mr-2.5 flex">
          {currentStep > 1 && (
            <Button
              variant="secondary"
              onClick={() => {
                if (back && typeof previousRoute === 'function') {
                  navigate(back(params));
                } else if (back) {
                  navigate(back);
                }
              }}
              disabled={!isBackEnabled}
            >
              <ArrowIcon className="mr-2.5 h-2.5 w-2.5 -rotate-90 fill-current" />
              Back
            </Button>
          )}
        </div>
        <Button
          variant="cancel"
          onClick={() => {
            if (shouldConfirmNavigate()) return showConfirmModal();
            backToDashboard();
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          {[...Array(totalSteps).keys()].map((_, idx, arr) => (
            <div key={`WizardStep-${idx}`} className="relative mr-[45px] last:mr-0">
              {idx <= currentStep - 1 ? (
                <TickIcon aria-label="check" className="h-[25px] w-[25px] fill-current text-dodger-blue-500" />
              ) : (
                <div
                  className={classNames(
                    'flex h-[25px] w-[25px] items-center justify-center rounded-full border-[3px] border-cadet-blue-500 bg-white',
                  )}
                />
              )}
              {idx !== arr.length - 1 && (
                <div
                  className={classNames('absolute left-[30px] top-1/2 h-[3px] w-[35px] -translate-y-1/2', {
                    'bg-cadet-blue-500': idx >= currentStep - 1,
                    'bg-dodger-blue-500': idx < currentStep - 1,
                  })}
                />
              )}
            </div>
          ))}
        </div>
        <div aria-label="step title" className="mt-2 whitespace-nowrap text-md text-lynch-500">
          {title || 'nothing found'}
        </div>
      </div>
      <div className="relative flex items-center justify-end">
        <Button
          disabled={!isNextEnabled}
          onClick={() => {
            if (next && typeof next === 'function') {
              const result = next(params);
              if (result && typeof result === 'string') {
                navigate(result, { state: { previousRoute: `${location.pathname}${location.search}` } });
              }
            } else if (next) {
              navigate(next, { state: { previousRoute: `${location.pathname}${location.search}` } });
            }
          }}
        >
          {loading && (
            <span className="mr-2.5">
              <Spinner />
            </span>
          )}
          {nextText}
          {!loading && <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />}
        </Button>
      </div>
    </div>
  );
}
