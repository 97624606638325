import { PlayerEvent } from '../PlayerEvent';
import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as RageIcon } from '@crazyegginc/hatch/dist/images/icon-face-rage-outline.svg';
import { ReactComponent as DeadClickIcon } from '@crazyegginc/hatch/dist/images/icon-block.svg';

import { TooltipEvent } from '../PlayerEvent';
import { escapeRegexSpecialChars } from '/src/utils/regex';

export function ClickEvent({ searchFilter, timestamp, active, selector, dead = false, rage = false, ...rest }) {
  const searchRegexp = new RegExp(escapeRegexSpecialChars(searchFilter), 'i');
  return (
    <>
      {rage && searchRegexp.test('rage click') ? (
        <PlayerEvent icon={RageIcon} title="Rage click" timestamp={timestamp} active={active} {...rest} />
      ) : null}
      {dead && searchRegexp.test('dead click') ? (
        <PlayerEvent icon={DeadClickIcon} title="Dead click" timestamp={timestamp} active={active} {...rest} />
      ) : null}
      {!dead && !rage ? (
        <PlayerEvent icon={ClickIcon} title="Click" text={selector} timestamp={timestamp} active={active} {...rest} />
      ) : null}
    </>
  );
}

export function TooltipClickEvent({ rage = false, dead = false, onClick, ...event }) {
  return (
    <>
      {rage ? <TooltipEvent icon={RageIcon} title="Rage click" event={event} onClick={onClick} /> : null}
      {dead ? <TooltipEvent icon={DeadClickIcon} title="Dead click" event={event} onClick={onClick} /> : null}
      {!dead && !rage ? <TooltipEvent icon={ClickIcon} title="Click" event={event} onClick={onClick} /> : null}
    </>
  );
}
