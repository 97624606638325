import classNames from 'classnames';

export function SectionHeader({ children, className }) {
  return <h2 className={classNames('text-header-3 mb-3.75 flex items-center', className)}>{children}</h2>;
}

export function Price({ amount, period }) {
  return (
    <div className="flex items-start">
      <span className="mt-0.5 text-md">$</span>
      <div className="flex items-end">
        <span className="mx-0.5 text-4xl leading-10">{amount}</span>
        <span className="text-body-5">/{period}</span>
      </div>
    </div>
  );
}
