import { useFormikContext } from 'formik';
import { RadioGroup, Input, Tooltip } from '@crazyegginc/hatch';

import { SNAPSHOT_CAPTURE_TIMER_OPTIONS } from '/src/features/snapshots/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function CaptureTimer() {
  const formik = useFormikContext();

  return (
    <section className="mb-0.5 mt-7">
      <div className="mb-4 flex items-center">
        <h2 className="text-header-2">Snapshot Capture Timer</h2>

        <Tooltip
          placement="right"
          interactive
          style={{ borderRadius: '6px', padding: '0' }}
          tooltipContent={
            <div className="w-[250px] p-3 text-left">
              This timer is only used once when the Snapshot is initially created and our server captures the image of
              the page.
              <br />
              <br />
              The page image is captured to use for the background image of your Snapshot Report. This is not related to
              the actual data tracking of your website visitors.
            </div>
          }
        >
          <InfoIcon className="ml-3.5 h-3.5 w-3.5 cursor-pointer fill-current text-dodger-blue-300" aria-label="info" />
        </Tooltip>
      </div>

      <RadioGroup
        options={[
          { value: SNAPSHOT_CAPTURE_TIMER_OPTIONS.DEFAULT, label: 'Capture Snapshot 20 seconds after the page loads' },
          {
            value: SNAPSHOT_CAPTURE_TIMER_OPTIONS.CUSTOM,
            label: (
              <div className="flex items-center">
                Wait
                <div className="mx-1.25 w-10">
                  <Input
                    name={'captureTimer.seconds'}
                    size="xs"
                    value={formik.values.captureTimer.seconds}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="!px-2"
                  />
                </div>
                seconds after the page loads
              </div>
            ),
          },
        ]}
        value={formik.values.captureTimer.option}
        onChange={(newValue) => formik.setFieldValue('captureTimer.option', newValue)}
        optionWrapperClass="mb-2.5"
      />
      {formik.touched?.captureTimer?.seconds && formik.errors?.captureTimer?.seconds && (
        <div className="relative h-5">
          <div className="text-body-4 absolute translate-y-0.5 whitespace-nowrap text-carnation-500">
            {formik.errors.captureTimer.seconds}
          </div>
        </div>
      )}

      {formik.values.captureTimer.option === SNAPSHOT_CAPTURE_TIMER_OPTIONS.CUSTOM && (
        <div className="text-body-2 mt-3 max-w-lg text-lynch-500">
          Set a time delay to specify when Crazy Egg should capture a Snapshot. For example, if you want to collect data
          on a form that displays 30 seconds after the page loads, enter in 31 seconds.
        </div>
      )}
    </section>
  );
}
