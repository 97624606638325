import { PROVISIONING_CONDITION_TYPES } from '/src/features/sso/constants';
import { EmailConditionTypeRow } from './EmailConditionTypeRow';
import { AttributeConditionTypeRow } from './AttributeConditionTypeRow';
import { ConditionTypeSelect } from '../selects/ConditionTypeSelect';
import { DeleteRowButton } from './DeleteRowButton';

export function RenderConditionRow({ ...props }) {
  switch (props.condition.type) {
    case PROVISIONING_CONDITION_TYPES.ANYONE:
      return null;
    case PROVISIONING_CONDITION_TYPES.EMAIL:
      return <EmailConditionTypeRow {...props} />;
    case PROVISIONING_CONDITION_TYPES.ATTRIBUTE:
      return <AttributeConditionTypeRow {...props} />;
    default:
      return (
        <div className="mb-4 flex items-center border-b border-dashed border-mystic-500 pb-4">
          <div className="flex flex-1 items-start justify-between">
            <ConditionTypeSelect {...props} />
            {!props.disabled ? <DeleteRowButton onRemove={props.onRemove} /> : null}
          </div>
        </div>
      );
  }
}
