import { useQuery } from '@tanstack/react-query';
import { MetricCards } from '@crazyegginc/hatch';

import { useErrorsFilter } from '../../errors-filter-context';
import { getConvertedDateRange } from '/src/utils/date';
import { inflect } from '/src/utils/string';

import { uniqueErrorStatsQuery } from '/src/features/errors/queries';

import { useHasFeatureFlag } from '/src/hooks';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-basic.svg';
import { ReactComponent as GeneralBrowserIcon } from '@crazyegginc/hatch/dist/images/icon-browser.svg';
import { ReactComponent as UserIcon } from '@crazyegginc/hatch/dist/images/icon-visitorface-5.svg';
import { ReactComponent as PagesIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';

export function SummaryCards({ uniqueErrorId, site }) {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { queryParams } = useErrorsFilter();
  const convertedDateRange = getConvertedDateRange(queryParams.dateRange);

  const { data, isLoading, error } = useQuery({
    ...uniqueErrorStatsQuery({
      fingerprintMd5: uniqueErrorId,
      siteId: site.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
    }),
    enabled: !isHighVolume && Boolean(uniqueErrorId),
  });
  const stats = data?.uniqueErrorStats;

  if (isHighVolume) {
    return null;
  }

  if ((!stats && !isLoading) || error) {
    return null;
  }

  return (
    <MetricCards>
      <MetricCards.Card
        fetching={isLoading}
        title={`Total ${inflect('occurrence', stats?.count)}`}
        value={stats?.count}
        icon={ErrorIcon}
        color="bg-carnation-500"
        className="!h-[100px] !w-auto grow"
      />
      <MetricCards.Card
        fetching={isLoading}
        title={`${inflect('Visitor', stats?.usersAffected)} affected`}
        value={stats?.usersAffected}
        icon={UserIcon}
        color="bg-lavender-500"
        className="!h-[100px] !w-auto grow"
      />
      <MetricCards.Card
        fetching={isLoading}
        title={`${inflect('Browser', stats?.browsersAffected)} affected`}
        value={stats?.browsersAffected}
        icon={GeneralBrowserIcon}
        color="bg-dodger-blue-300"
        className="!h-[100px] !w-auto grow"
      />
      <MetricCards.Card
        fetching={isLoading}
        title={`${inflect('Page', stats?.pagesAffected)} affected`}
        value={stats?.pagesAffected}
        icon={PagesIcon}
        color="bg-riptide-500"
        className="!h-[100px] !w-auto grow"
      />
    </MetricCards>
  );
}
