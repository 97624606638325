import React from 'react';
import { Tooltip } from '@crazyegginc/hatch';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-filled.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';

export function IconWithTooltip({ type }) {
  let Icon, text, color;
  switch (type) {
    case 'info':
      Icon = InfoIcon;
      text = 'Useful information about tracking on this page.';
      color = 'text-dodger-blue-500';
      break;
    case 'warning':
      Icon = WarningIcon;
      text =
        'This warning indicates a reason why tracking might not be working as expected even with the tracking script installed.';
      color = 'text-dandelion-500';
      break;
    case 'console':
      Icon = CodeIcon;
      text = 'These following console errors were detected.';
      color = 'text-black-pearl-500';
      break;
  }

  return (
    <div className="absolute left-[-57px] top-px z-10">
      <Tooltip tooltipContent={<div className="max-w-xs">{text}</div>} arrowSkiddingPercent="20">
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-alice-blue-500">
          <Icon className={`h-5 w-5 fill-current ${color}`} />
        </div>
      </Tooltip>
    </div>
  );
}
