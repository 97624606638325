import classNames from 'classnames';
import { SkeletonLine, Indicator, Popover, StyledPopoverPanel, PopoverItem } from '@crazyegginc/hatch';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';

export function SummaryRow({ children }) {
  return (
    <div className="flex h-20 w-full divide-x divide-mystic-500 rounded-t-md border !border-b-0 border-mystic-500 bg-white">
      {children}
    </div>
  );
}

export function SummaryCard({ title, onClick, value = 0, unit = null, selected = false }) {
  return (
    <button
      onClick={onClick}
      className={classNames('box-border flex h-[82px] flex-1 flex-col border-b-2 px-[20px] py-[14px] transition', {
        '!border-b-mystic-500 hover:bg-white-lilac-500': !selected,
        '!border-b-dodger-blue-500': selected,
      })}
    >
      <span className="text-header-5 text-lynch-500">{title}</span>
      <span className="flex items-end space-x-1">
        <span className="text-header-1">{value}</span>
        {unit ? <span className="text-body-2 pb-1.5">{unit}</span> : null}
      </span>
    </button>
  );
}

export function SummaryGoalSelector({ onClick, selected, goals = [], loading = false, disabled = false }) {
  return (
    <Popover className="relative w-[220px]">
      {() => (
        <>
          <Popover.Button
            className="box-border flex h-[82px] w-full flex-1 flex-col border-b-2 border-mystic-500 px-[20px] py-[14px] transition hover:bg-white-lilac-500"
            disabled={loading || disabled}
          >
            <span className="text-header-5 text-lynch-500">Goal</span>
            {loading ? (
              <div className="mt-3.5 w-full">
                <SkeletonLine width="75%" />
              </div>
            ) : (
              <div className="block w-full truncate mt-1 text-left">
                <span className="text-body-2 mt-1.5 flex items-center">
                  {selected?.name ?? ''} {!disabled ? <Indicator type="dropdown" className="flex ml-4" /> : null}
                </span>
              </div>
            )}
          </Popover.Button>
          <StyledPopoverPanel
            align="right"
            className="!z-[90] !max-h-[400px] !w-[216px] !overflow-y-auto !overflow-x-hidden !px-0"
          >
            {({ close }) => (
              <>
                {goals.length ? (
                  goals.map((goal) => (
                    <div className="flex" key={goal.id}>
                      <PopoverItem
                        className="truncate"
                        onClick={() => {
                          onClick?.(goal);
                          close();
                        }}
                      >
                        <div className="flex w-full items-center">
                          {goal.id === selected.id ? (
                            <TickIcon className="w-3 h-3 fill-current text-dodger-blue-500 mr-[5px] flex-shrink-0" />
                          ) : (
                            <span className="w-3 h-3 mr-[5px] flex-shrink-0">&nbsp;</span>
                          )}
                          <span>{goal.name}</span>
                        </div>
                      </PopoverItem>
                    </div>
                  ))
                ) : (
                  <div className="text-body-5 relative inline-block w-full max-w-full rounded px-2.5 py-1.25 text-left leading-[1.15]">
                    This A/B test doesn&apos;t have any Goals.
                  </div>
                )}
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
