import { Button } from '@crazyegginc/hatch';

import { usePermissions, useAuthContext, useMetric } from '/src/hooks';
import { Link } from 'react-router-dom';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';
import { FEATURES, MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import RecordingsPNG from '@crazyegginc/hatch/dist/images/illustration-recordings-1.png';
import RecordingsWebP from '@crazyegginc/hatch/dist/images/illustration-recordings-1.webp';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function RecordingsUpgradeModal() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const trackMetric = useMetric();

  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Modal dialogClassName="!w-[640px]">
      <div className="text-body-2 flex flex-col items-center py-5 text-center">
        <WebP webp={RecordingsWebP} fallback={RecordingsPNG} width="278px" height="150px" />
        <div className="text-header-2 mt-5">See clearly with Recordings</div>
        <div className="mb-6 mt-4 px-10 text-center">
          Watch recordings of actual visitors accomplishing your goal to see how easy it was to complete and if there
          were any blockers or frustrations.
          {!canManageBilling ? (
            <p className="mt-5">
              To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
              the plan.
            </p>
          ) : null}
        </div>

        {canManageBilling ? (
          <Button
            component={Link}
            to={MANAGE_SUBSCRIPTION_PATH}
            onMouseDown={() => trackMetric('upgrade_cta_click', 'recordings_upsell_modal')}
          >
            Get Recordings
            <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
          </Button>
        ) : (
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
            Contact account owner
          </Button>
        )}
      </div>
    </Modal>
  );
}
