import { useState, useCallback, useRef, useEffect } from 'react';
import { Formik, Form, validateYupSchema, yupToFormErrors, useFormikContext } from 'formik';
import { format } from 'date-fns';
import * as yup from 'yup';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { Button } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { BackToDashboardButton } from '../../common/BackToDashboardButton';
import { AddonLoading } from '../../common/AddonLoading';
import { AddonEditorContext } from '../../editor-context';
import { AddonName } from '../../common/editor/AddonName';
import { TabList } from '../../common/editor/TabList';
import { TypeSelector } from './TypeSelector';
import { Content } from './Content';
import { Action } from './Action';
import { Audience } from '../../common/editor/Audience';
import { Behavior } from '../../common/editor/Behavior';
import { Appearance } from './Appearance';
import { Preview } from './Preview';
import { SaveAndPublish } from '../../common/editor/SaveAndPublish';
import { BAR_PREVIEW_HEIGHT } from './Preview';
import { TemplateFields } from '../../common/editor/TemplateFields';
import { SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { DeletedAddonModal } from '../../common/modals/DeletedAddonModal';

import { useSite, useSelectedSite, useNavBarDimensions, useModal, useAddonScript } from '/src/hooks';
import { getSurveyQueryParams } from '/src/utils/url';
import { mergeDeep, numberOfKeys } from '/src/utils/object';
import { getDocumentCoordinates } from '/src/utils';
import { audienceSchema, templateSchema } from '../../common/editor/validation';
import { generateCTADataFormat } from './editor-functions';
import { useAddonMutations } from '../../common/mutation-functions';

import { DEVICE_TYPES, FEATURES } from '/src/features/_global/constants';
import {
  CTA_TYPES,
  REFERRER_VALUES,
  REPEAT_VALUES,
  TARGET_OPTIONS,
  AUDIENCE_MODES,
  ADDON_TYPES,
  BEHAVIOR_TRIGGERS,
} from '/src/features/addons/constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

export const DEFAULT_HEADING = 'Want 30% off?';
export const DEFAULT_ADDITIONAL_INFO =
  'Try us for 14 days free and then get 30% off your first purchase. This offer expires soon.';
export const DEFAULT_BUTTON_TITLE = 'Save 30%';

const initialData = (isTemplate, site) => {
  const data = {
    name: 'New CTA ' + format(new Date(), 'yyyy-MM-dd HH:mm'),
    theme: {
      dark: false,
      accent: '#34D495',
      bar: '#34D495',
      button: '#225B3F',
      removeBranding: false,
      customBrandingText: '',
      adjustColors: true,
    },
    displayType: CTA_TYPES.BUTTON,
    heading: DEFAULT_HEADING,
    buttonTitle: DEFAULT_BUTTON_TITLE,
    subtext: DEFAULT_ADDITIONAL_INFO,
    bar: {
      position: 'top',
      padding: true,
      fixed: 'partial',
      showButton: true,
    },
    button: {
      vertical: true,
      position: 'right-center',
    },
    popup: {
      position: 'bottomRight',
    },
    action: {
      action: TARGET_OPTIONS.NEW_TAB,
      url: `http://${site.name}/sale`,
      code: `function onClick(cta) {  // eslint-disable-line
  //  === Add your code below ===
  
  console.log('CTA clicked!');

  // You can remove the CTA with the following code:
  // cta.remove();

  // === Add your code above ===
}`,
    },
    closable: true,
    autoPosition: true,
    autoPositionFallbackToShow: true,
    mode: AUDIENCE_MODES.SIMPLE,
    goalId: null,
    audience: {
      device: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.MOBILE],
      allPages: true,
      referrerOption: REFERRER_VALUES.EVERYONE,
      site: structuredClone(site),
    },
    behavior: {
      show: BEHAVIOR_TRIGGERS.IMMEDIATE,
      timeDelaySeconds: 30,
      numPages: 3,
      repeat: REPEAT_VALUES.SUBMIT,
      repeatCount: 1,
    },
  };

  if (isTemplate) {
    data.name = 'New Template ' + format(new Date(), 'yyyy-MM-dd HH:mm');
    data.description = '';
    data.category = '';
    data.icon = 'star';
  }

  return data;
};

const CTASchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide a name for the CTA.')
    .matches(/^(?!\s+$).*/, 'The name cannot contain only spaces.'),
  mode: yup.string().oneOf([...Object.values(AUDIENCE_MODES)], 'Please select a mode.'),
  audience: yup.object().when('mode', {
    is: (value) => value === AUDIENCE_MODES.SIMPLE,
    then: yup.object().shape({}).concat(audienceSchema),
    otherwise: yup.object().shape({}),
  }),
  behavior: yup.object().when(['mode'], (mode, schema) => {
    let launch = yup.object().shape({});
    if (mode === AUDIENCE_MODES.SIMPLE) {
      launch = yup.object().shape({
        show: yup
          .string()
          .oneOf([
            BEHAVIOR_TRIGGERS.IMMEDIATE,
            BEHAVIOR_TRIGGERS.TIME_DELAY,
            BEHAVIOR_TRIGGERS.SCROLL_PAST_FOLD,
            BEHAVIOR_TRIGGERS.EXIT_INTENT,
            BEHAVIOR_TRIGGERS.NUM_PAGES,
          ]),
        timeDelaySeconds: yup.number().when('show', {
          is: (value) => value === BEHAVIOR_TRIGGERS.TIME_DELAY,
          then: yup
            .number()
            .required('Please provide a valid number for seconds.')
            .typeError('Please provide a valid number for seconds.')
            .integer('Please provide a valid number for seconds.')
            .positive('Please provide a valid number for seconds.')
            .min(1, 'Seconds should be at least ${min}. Please provide a larger number.')
            .max(600, 'Seconds cannot exceed ${max}. Please provide a lower number.'),
        }),
        numPages: yup.number().when('show', {
          is: (value) => value === BEHAVIOR_TRIGGERS.NUM_PAGES,
          then: yup
            .number()
            .required('Please provide a valid number for pages.')
            .typeError('Please provide a valid number for pages.')
            .integer('Please provide a valid number for pages.')
            .positive('Please provide a valid number for pages.')
            .min(1, 'Pages should be at least ${min}. Please provide a larger number.'),
        }),
      });
    }
    return schema
      .shape({
        repeat: yup.string().oneOf([...Object.values(REPEAT_VALUES)]),
        repeatCount: yup.number().when('repeat', {
          is: (value) => value === REPEAT_VALUES.NUMBER,
          then: yup
            .number()
            .required('Please provide a number for the repeat count.')
            .typeError('Please provide a valid number for the repeat count.')
            .integer('Please provide a valid number for the repeat count.')
            .positive('Please provide a valid number for the repeat count.')
            .min(1, 'Repeat count should be at least ${min}. Please provide a larger number.'),
        }),
      })
      .concat(launch);
  }),
});

export function CTAEditor({ id, initData, isTemplate = false, readonly = false, publishedEdit = false }) {
  const stableInitData = useRef(initData);
  const [ctaReady, setCtaReady] = useState(false);
  useAddonScript(
    useCallback(() => setCtaReady(true), []),
    'cta',
  );
  const [yCoord, setYCoord] = useState(null);
  const { initialNavBarHeight } = useNavBarDimensions();
  const saveAndPublishComp = useRef();
  const modal = useModal();

  const handleAddOnsCapabilities = useCallback((addonsCapabilities) => {
    setEditorState((state) => ({ ...state, addonsCapabilities }));
  }, []);

  const [editorState, setEditorState] = useState({
    id,
    isTemplate,
    readonly,
    publishedEdit,
    type: ADDON_TYPES.CTA,
    spelledType: 'CTA',
    addonsCapabilities: {},
    handleAddOnsCapabilities,
  });
  const { createAddon, editAddon, createTemplate, editTemplate } = useAddonMutations();

  const { selectedSite, selectSite } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: !!stableInitData.current?.audience?.site?.id, //we'll select the site from initData if it's set
  });
  const editorRef = useRef();

  // this is used for saving, auto-saving and as a first step to pubishing
  async function handleFormSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    let returnValue = false;

    const cta = generateCTADataFormat({ values, isTemplate, id: editorState.id, publishedEdit });

    if (isTemplate) {
      if (editorState.id) {
        returnValue = await editTemplate(cta, values.thumbnail);
      } else {
        const result = await createTemplate(cta, values.thumbnail);
        if (result) {
          setEditorState((state) => ({ ...state, id: result }));
          returnValue = result;
        }
      }
    } else {
      if (editorState.id) {
        returnValue = await editAddon(cta);
        if (returnValue.status === 'DELETED') {
          await new Promise((resolve) => {
            modal.show(
              <DeletedAddonModal
                type={editorState.spelledType}
                onConfirm={async () => {
                  const cta = generateCTADataFormat({ values, isTemplate });
                  const result = await createAddon(cta, values.audience.site.id);
                  if (result) {
                    const { id, previewToken } = result;
                    setEditorState((state) => ({ ...state, id }));
                    returnValue = { id, previewToken };
                    resolve();
                  }
                }}
              />,
            );
          });
        }
      } else {
        const result = await createAddon(cta, values.audience.site.id);
        if (result) {
          const { id, previewToken } = result;
          setEditorState((state) => ({ ...state, id }));
          returnValue = { id, previewToken };
        }
      }
    }
    setSubmitting(false);
    return returnValue;
  }

  const getCoordiantes = useCallback((node) => {
    if (node !== null) {
      setYCoord(getDocumentCoordinates(node).top);
    }
  }, []);

  // if initdata has site already, select that initially
  useEffect(() => {
    if (!selectedSite?.id && stableInitData.current?.audience?.site?.id) {
      selectSite(stableInitData.current.audience.site);
    }
  }, [selectedSite, selectSite]);

  if (!initialNavBarHeight || !ctaReady || !selectedSite?.id) {
    return <AddonLoading type={FEATURES.CTAS} />;
  }

  let validationSchema = CTASchema.concat(window.CE2.CTA.getValidationSchema());
  if (isTemplate) {
    validationSchema = validationSchema.concat(templateSchema);
  }

  return (
    <AddonEditorContext.Provider value={editorState}>
      <Formik
        initialValues={mergeDeep(initialData(isTemplate, selectedSite), stableInitData.current)}
        validate={async (values) => {
          return validateYupSchema({ ...values }, validationSchema).then(
            () => ({}),
            (err) => yupToFormErrors(err),
          );
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => (
          <DashboardPage mainClass="!pb-0">
            <SEO title="CTAs" />
            <Form>
              <DashHeader
                titleComponent={
                  <Breadcrumb>
                    <BreadcrumbItem
                      active={false}
                      to={{
                        pathname: `/addons`,
                        search: `type=${FEATURES.CTAS}${getSurveyQueryParams() ? `&${getSurveyQueryParams()}` : ''}`,
                      }}
                    >
                      CTAs
                    </BreadcrumbItem>
                    <BreadcrumbItem active={true}>{values.name}</BreadcrumbItem>
                  </Breadcrumb>
                }
                actionButton={
                  <div className="flex items-center space-x-2.5">
                    <BackToDashboardButton type={FEATURES.CTAS} />
                    {!readonly && <SaveAndPublish ref={saveAndPublishComp} />}
                  </div>
                }
              />
              <div className="flex" ref={getCoordiantes}>
                <section
                  style={{
                    height: `calc(max(100vh, ${initialNavBarHeight}px) - ${
                      yCoord + (values.displayType === CTA_TYPES.BAR ? BAR_PREVIEW_HEIGHT : 0)
                    }px)`,
                    marginBottom: values.displayType === CTA_TYPES.BAR ? `${BAR_PREVIEW_HEIGHT}px` : 0,
                  }}
                  className={classNames(
                    'min-w-min flex-grow pb-20 pr-5 pt-8',
                    'border-r border-mystic-500 bg-white',
                    '!overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
                    {
                      'shadow-md': values.displayType !== CTA_TYPES.BAR,
                    },
                  )}
                  ref={editorRef}
                >
                  <AddonName />
                  {isTemplate && <TemplateFields />}
                  <TypeSelector />
                  <TabsSection editorRef={editorRef} saveCTA={() => saveAndPublishComp.current.saveAddon()} />
                </section>
                <Preview saveCTA={() => saveAndPublishComp.current.saveAddon()} />
              </div>
            </Form>
          </DashboardPage>
        )}
      </Formik>
    </AddonEditorContext.Provider>
  );
}

const tabs = ['CTA', 'Settings'];

function TabsSection({ editorRef, saveCTA }) {
  const { selectedSite } = useSelectedSite();
  const [selectedTab, setSelectedTab] = useState(0);
  const { errors, touched, values, setFieldValue } = useFormikContext();

  // store selected site initially if nothing set yet
  useEffect(() => {
    if (!values.audience.site) {
      setFieldValue('audience.site', selectedSite);
    }
  }, [setFieldValue, selectedSite, values.audience.site]);

  const hasTabError = (header) => {
    if (header === 'CTA') {
      return errors.heading || errors.buttonTitle || errors.subtext || errors.action;
    } else if (header === 'Settings') {
      let errorCount = numberOfKeys(errors?.theme) + numberOfKeys(errors?.audience) + numberOfKeys(errors?.behavior);
      // don't count untouched input fields as errors
      if (!touched?.audience?.referrers && errors?.audience?.referrers) {
        errorCount -= 1;
      }
      if (!touched?.audience?.pages && errors?.audience?.pages) {
        errorCount -= 1;
      }
      return errorCount > 0;
    }
  };

  const goToTab = (name) => {
    const tabNumber = tabs.findIndex((t) => t === name);
    if (tabNumber > -1) {
      setSelectedTab(tabNumber);
      editorRef.current.scrollTo(0, 0);
    }
  };

  return (
    <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
      <TabList tabs={tabs} hasTabError={hasTabError} />

      <Tab.Panels>
        <Tab.Panel>
          <Content />
          <Action saveCTA={saveCTA} />
          <div className="mb-20 mt-10 flex w-full justify-end">
            <Button size="lg" onClick={() => goToTab('Settings')}>
              Settings <ArrowIcon className="ml-2.5 h-3.5 w-3.5 fill-current" />
            </Button>
          </div>
        </Tab.Panel>

        <Tab.Panel>
          <Audience />
          <Appearance />
          <Behavior />
          <div className="mt-10 w-full pl-10">
            <Button size="lg" onClick={() => goToTab('CTA')}>
              <ArrowIcon className="mr-2.5 h-3.5 w-3.5 rotate-180 fill-current" />
              CTA
            </Button>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
