import { useAuthContext } from '/src/hooks';

export function useScriptUrl() {
  const { currentAccount } = useAuthContext();

  const scriptUrl = currentAccount?.scriptUrl?.replace(/https?:/, '') ?? null;
  const code = `&lt;script type=&quot;text/javascript&quot; src=&quot;${scriptUrl}&quot; async=&quot;async&quot; &gt;&lt;/script&gt;`;

  return [code];
}
