import { assign, setup, enqueueActions } from 'xstate5';

import { balanceWeights, prepareVariantsForEdit, generateVariantEditorPatches } from '../utils';
import { variantMachine, initialVariantContext, controlVariant } from './variant-editor-v2';

import { DEVICE_WIDTHS, VARIANT_STATUSES } from '/src/features/ab-testing/constants';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { findDeviceWidth, findDeviceID } from '/src/features/ab-testing/utils';

export const initialPayload = {
  matchingUrl: '',
  url: undefined,
  devices: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE],
  variants: [controlVariant],
  goalId: null, // for existing goals
  createSnapshots: true,
  type: null,
  autoReweight: null,
  patchStrategy: 'async',
};

const emptyPayload = {
  matchingUrl: '',
  url: undefined,
  devices: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE].sort(),
  variants: [controlVariant],
  goalId: null, // for existing goals
  createSnapshots: true,
  type: null,
  autoReweight: null,
  patchStrategy: 'async',
  ...initialPayload,
};

function getActiveVariants(event) {
  return event.output.variants.filter((v) => !v.isRetired);
}

export const addVariantMachine = setup({
  actors: {
    loadAbTest: null,
    createVariants: null,
    setTrafficSplit: null,
  },
}).createMachine({
  initial: 'loading',
  context: {
    abTest: null,
    payload: {
      ...emptyPayload,
    },
    variants: [],
    nextState: 'variants',
    title: 'Set traffic split',
  },
  states: {
    loading: {
      invoke: {
        id: 'loadAbTest',
        src: 'loadAbTest',
        onDone: [
          {
            target: 'variants',
            guard: ({ context }) => context.nextState === 'variants',
            actions: assign({
              abTest: ({ event }) => event.output,
              payload: ({ event }) => {
                return {
                  id: event.output.id,
                  autoReweight: event.output.autoReweight,
                  devices: event.output.devices,
                  url: event.output.pageUrl,
                  matchingUrl: event.output.matchingUrl,
                  variants: prepareVariantsForEdit(getActiveVariants(event), { createBlank: false }),
                };
              },
              variants: ({ context, event }) =>
                prepareVariantsForEdit(getActiveVariants(event), {
                  createBlank: context.nextState === 'variants',
                  nextPosition: event.output.variants.length,
                }),
            }),
          },
          {
            target: 'weights',
            guard: ({ context }) => context.nextState === 'weights',
            actions: assign({
              abTest: ({ event }) => event.output,
              payload: ({ event }) => {
                return {
                  id: event.output.id,
                  autoReweight: event.output.autoReweight,
                  devices: event.output.devices,
                  url: event.output.pageUrl,
                  matchingUrl: event.output.matchingUrl,
                  variants: prepareVariantsForEdit(getActiveVariants(event), { createBlank: false }),
                };
              },
              variants: ({ event }) => prepareVariantsForEdit(getActiveVariants(event), { createBlank: false }),
            }),
          },
        ],
        onError: [{ target: 'error' }],
      },
    },
    variants: {
      entry: assign({
        nextText: 'Next',
      }),
      invoke: {
        id: 'variantMachine',
        src: variantMachine,
        input: ({ context }) => {
          const device = context.payload.devices.sort(
            (a, b) => parseInt(DEVICE_WIDTHS[b]) - parseInt(DEVICE_WIDTHS[a]),
          )[0];
          const payload = context.variants;
          const selectedVariant = payload.at(-1);

          return {
            ...initialVariantContext,
            payload,
            selectedVariant,
            nextText: undefined,
            device,
            matchingUrl: context.payload.matchingUrl,
            url: context.payload.url || context.payload.matchingUrl,
            editor: {
              ...initialVariantContext.editor,
              device,
              deviceId: findDeviceID(device),
              screenWidth: findDeviceWidth(device),
            },
            variants: context.variants,
          };
        },
        onDone: [
          {
            target: 'createVariants',
            actions: assign({
              nextState: 'weights',
              variants: ({ event }) => {
                return event.output.variants || [];
              },
            }),
            guard: ({ event }) => {
              return (
                event.output.reason === 'NEXT' && event.output.variants.some((v) => v.status === VARIANT_STATUSES.DIRTY)
              );
            },
          },
          {
            target: 'weights',
            actions: assign({
              nextState: 'weights',
            }),
            guard: ({ event }) => {
              return event.output.reason === 'NEXT';
            },
          },
          {
            target: 'cancelled',
            guard: ({ event }) => {
              return event.output.reason === 'CANCEL';
            },
          },
        ],
      },
      on: {
        NEXT: [{ target: 'weights', guard: ({ context }) => context.abTest.autoWeight === false }, { target: 'done' }],
      },
    },
    weights: {
      on: {
        BACK: 'variants',
        NEXT: [
          {
            target: 'syncWeights',
            guard: ({ context }) =>
              context.payload.autoReweight === false || context.payload.autoReweight !== context.abTest.autoReweight,
          },
          { target: 'done' },
        ],
        SET_REWEIGHT: {
          actions: assign({
            payload: ({ context, event }) => ({
              ...context.payload,
              autoReweight: event.value,
              // clear variants.weight if value is true
              variants: event.value
                ? context.payload.variants.map((variant) => ({
                    ...variant,
                    weight: undefined,
                  }))
                : context.payload.variants,
            }),
          }),
        },
        ADJUST_WEIGHTS: {
          actions: enqueueActions(({ context, event, enqueue }) => {
            const newVariants = balanceWeights(event.value);

            enqueue.assign({
              payload: {
                ...context.payload,
                variants: newVariants.variants.map((variant) => ({ ...variant, status: VARIANT_STATUSES.DIRTY })),
              },
            });
          }),
        },
      },
    },
    createVariants: {
      invoke: {
        id: 'createVariants',
        src: 'createVariants',
        input: ({ context }) => {
          return {
            id: context.abTest.id,
            variants: generateVariantEditorPatches({
              variants: context.variants.filter((v) => v.status === VARIANT_STATUSES.DIRTY),
            }),
          };
        },
        onDone: [{ target: 'loading', actions: assign({ nextState: 'weights' }) }],
        onError: { target: 'error' },
      },
    },
    syncWeights: {
      invoke: {
        id: 'setTrafficSplit',
        src: 'setTrafficSplit',
        input: ({ context }) => {
          return {
            id: context.abTest.id,
            autoReweight: context.payload.autoReweight,
            variants: context.payload.variants.map((v) => ({
              id: v.id,
              weight: typeof v.weight === 'number' ? v.weight : 0,
            })),
          };
        },
        onDone: { target: 'done' },
        onError: { target: 'error' },
      },
    },
    done: {
      type: 'final',
    },
    error: {
      type: 'final',
    },
    cancelled: {
      type: 'final',
    },
  },
});
