import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useQueryParams } from '/src/hooks';
import { getParam, getInitialValue } from '/src/utils/location';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { SHARED_URLS_ORDER, SHARED_URL_STATUSES } from '/src/features/team-and-sharing/constants';

const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
};
const { SET, RESET } = FILTER_ACTIONS;

const FilterContext = createContext();

// keep this as a function to delay evaluation while the script loads
const initialState = (status) => {
  const appendStatus = status === SHARED_URL_STATUSES.ENABLED ? 'Active' : 'Inactive';
  return {
    page: parseInt(getParam(`page${appendStatus}`)) || 1,
    order:
      getInitialValue(`order${appendStatus}`, [...Object.values(SHARED_URLS_ORDER)]) || SHARED_URLS_ORDER.CREATED_AT,
    sort:
      getInitialValue(`sort${appendStatus}`, [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || SORT_ORDER_TYPES.DESC,
  };
};

export function AccountUsersSharingProvider({ status, children }) {
  function filterReducer(state = initialState(status), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case RESET:
        return {
          ...initialState(),
        };
      default:
        return state;
    }
  }

  const [filterSharing, filterSharingDispatch] = useReducer(filterReducer, initialState());
  const [filterSharingState, setFilterSharingState] = useState({
    filterSharing,
    filterSharingDispatch,
  });
  if (!isEqual(filterSharing, filterSharingState.filterSharing)) {
    setFilterSharingState({ filterSharing, filterSharingDispatch });
  }
  return <FilterContext.Provider value={filterSharingState}>{children}</FilterContext.Provider>;
}

export function useFilterSharing(status) {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filterSharing, filterSharingDispatch } = useContext(FilterContext);
  const appendStatus = status === SHARED_URL_STATUSES.ENABLED ? 'Active' : 'Inactive';
  const { order, sort, page } = filterSharing;

  const setPage = useCallback(
    (page) => {
      queryParamsSet(`page${appendStatus}`, page);
      filterSharingDispatch({
        type: SET,
        payload: {
          page,
        },
      });
      window.scrollTo(0, 0);
    },
    [filterSharingDispatch, queryParamsSet, appendStatus],
  );

  const setSort = useCallback(
    (sort) => {
      queryParamsSet(`sort${appendStatus}`, sort);
      page !== 1 && setPage(1);
      filterSharingDispatch({
        type: SET,
        payload: {
          sort,
        },
      });
    },
    [appendStatus, page, queryParamsSet, setPage, filterSharingDispatch],
  );

  const setOrder = useCallback(
    (order) => {
      queryParamsSet(`order${appendStatus}`, order);
      page !== 1 && setPage(1);
      filterSharingDispatch({
        type: SET,
        payload: {
          order,
        },
      });
    },
    [appendStatus, page, queryParamsSet, setPage, filterSharingDispatch],
  );

  function reset() {
    removeAll([`order${appendStatus}`, `sort${appendStatus}`, 'page']);
    setSort(initialState().sort);
    setOrder(initialState().order);
    filterSharingDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  return {
    page,
    order: { field: order, sort },
    setSort,
    setOrder,
    setPage,
    reset,
  };
}
