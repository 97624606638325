export function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
}

export function isBrightColor(hex) {
  const hsl = hexToHSL(hex);

  const l = hsl.match(/([0-9.]+)%\)/)[1];

  if (l < 50) return false;
  return true;
}

function anyColorToHex(str) {
  const c = document.createElement('canvas').getContext('2d');
  c.fillStyle = str;
  return c.fillStyle;
}

function convertToRgb(color) {
  // if already RGB
  const match = color.match(/rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/);
  if (match) {
    return [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])];
  }

  // Otherwise convert color to hex, unless already hex
  const hex = /^#[0-9a-fA-F]{6}$/.test(color) ? color : anyColorToHex(color);

  const array = hex.substring(1).match(/.{1,2}/g);
  const rgb = [parseInt(array[0], 16), parseInt(array[1], 16), parseInt(array[2], 16)];
  return rgb;
}

export function addOpacity(color, opacity) {
  if (opacity < 0.0 || opacity > 1.0) {
    return color;
  }
  const rgb = convertToRgb(color);
  return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
}
