import { useState, useReducer, createContext } from 'react';
import { produce } from 'immer';
import isEqual from 'react-fast-compare';

export const SELECTION_ACTIONS = {
  REMOVE: 'REMOVE',
  SET: 'SET',
  ADD: 'ADD',
  RESET: 'RESET',
  TOGGLE: 'TOGGLE',
  INIT_GROUP: 'INIT_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  TOGGLE_GROUP: 'TOGGLE_GROUP',
  TOGGLE_ENTITY_IN_GROUP: 'TOGGLE_ENTITY_IN_GROUP',
  LIMIT: 'LIMIT',
};

const { REMOVE, SET, ADD, RESET, TOGGLE, INIT_GROUP, UPDATE_GROUP, TOGGLE_GROUP, TOGGLE_ENTITY_IN_GROUP, LIMIT } =
  SELECTION_ACTIONS;

export const SelectionContext = createContext();
const initialSelectionState = {
  listSelection: [],
  groupSelection: [],
};

export function SelectionProvider({ children }) {
  const [selection, selectionDispatch] = useReducer(selectionReducer, initialSelectionState);
  const [selectionState, setSelection] = useState({ selection, selectionDispatch });

  if (!isEqual(selection, selectionState.selection)) {
    setSelection({ selectionDispatch, selection });
  }

  return <SelectionContext.Provider value={selectionState}>{children}</SelectionContext.Provider>;
}

function selectionReducer(state, action) {
  switch (action.type) {
    case REMOVE:
      return produce(state, (draft) => {
        if (state.listSelection.length) {
          action.payload.forEach((x) => {
            const index = draft.listSelection.findIndex((y) => y.id === x.id);
            if (index > -1) {
              draft.listSelection.splice(index, 1);
            }
          });
        } else {
          state.groupSelection.forEach((group, groupIndex) => {
            action.payload.forEach((x) => {
              const entityIndex = group.entities.findIndex((entity) => entity.id === x.id);
              if (entityIndex > -1) {
                draft.groupSelection[groupIndex].entities[entityIndex].selected = false;
              }
            });
          });
        }
      });
    case LIMIT: {
      const limit = action.payload;
      return produce(state, (draft) => {
        if (state.listSelection.length) {
          draft.listSelection.splice(Math.min(limit, state.listSelection.length));
        } else {
          let count = 0;
          state.groupSelection.forEach((group, groupIndex) => {
            if (count >= limit) {
              draft.groupSelection.splice(groupIndex);
            } else if (group.entities.length + count < limit) {
              count += group.entities.length;
            } else {
              const numToKeep = Math.min(limit - count, state.groupSelection[groupIndex].entities.length);
              draft.groupSelection[groupIndex].entities.splice(numToKeep);
              count += numToKeep;
            }
          });
        }
      });
    }
    case TOGGLE:
      return produce(state, (draft) => {
        const index = state.listSelection.findIndex((s) => s.id === action.payload.id);
        if (index > -1) {
          draft.listSelection.splice(index, 1);
        } else {
          draft.listSelection.push(action.payload);
        }
      });
    case SET:
      return produce(state, (draft) => {
        draft.listSelection = [...action.payload];
      });
    case ADD:
      return produce(state, (draft) => {
        action.payload.forEach((x) => {
          const index = draft.listSelection.findIndex((y) => y.id === x.id);
          if (index === -1) {
            draft.listSelection.push(x);
          }
        });
      });
    case INIT_GROUP:
      return produce(state, (draft) => {
        draft.groupSelection.push({
          groupId: action.payload.groupId,
          entities: action.payload.entities.map((x) => ({ ...x, selected: false })),
        });
      });
    case UPDATE_GROUP:
      return produce(state, (draft) => {
        const index = state.groupSelection.findIndex((x) => x.groupId === action.payload.groupId);
        // push new group and copy selected values from old
        draft.groupSelection.push({
          groupId: action.payload.groupId,
          entities: action.payload.entities.map((x) => ({
            ...x,
            selected: !!state.groupSelection[index].entities.find((y) => y.id === x.id && y.selected),
          })),
        });
        // delete old group
        draft.groupSelection.splice(index, 1);
      });
    case TOGGLE_GROUP:
      return produce(state, (draft) => {
        const index = state.groupSelection.findIndex((x) => x.groupId === action.payload);
        // partially or not selected, so select fully
        if (state.groupSelection[index].entities.some((x) => x.selected === false)) {
          draft.groupSelection[index].entities = state.groupSelection[index].entities.map((x) => ({
            ...x,
            selected: true,
          }));
        }
        // fully selected, so deselect (remove)
        else {
          draft.groupSelection.splice(index, 1);
        }
      });
    case TOGGLE_ENTITY_IN_GROUP:
      return produce(state, (draft) => {
        const index = state.groupSelection.findIndex((x) => x.groupId === action.payload.groupId);
        const indexInGroup = state.groupSelection[index].entities.findIndex((x) => x.id === action.payload.entity.id);
        draft.groupSelection[index].entities[indexInGroup].selected =
          !state.groupSelection[index].entities[indexInGroup].selected;
      });
    case RESET:
      return initialSelectionState;
  }
}
