export function TooltipSiteAccessContent() {
  return (
    <div className="w-[280px] px-2 py-3 text-left">
      <h5 className="mb-1.5 text-md font-semibold text-white">What does this mean?</h5>
      <p className="text-xs font-normal text-cadet-blue-500">
        When you select a site to share, your Team Member can view all of the features and data under the site.
      </p>
    </div>
  );
}
