import { PlayerEvent } from '../PlayerEvent';
import { ReactComponent as GoalConversionIcon } from '@crazyegginc/hatch/dist/images/icon-trophy-filled.svg';
import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';

export function GoalConversionEvent({ timestamp, active, ...rest }) {
  return (
    <PlayerEvent
      icon={GoalConversionIcon}
      title="Goal conversion"
      timestamp={timestamp}
      active={active}
      subText={
        rest.amount && rest.currency && rest.amount >= 0.01 ? (
          <DisplayCurrency
            value={{ displayWorth: rest.amount, displayCurrency: rest.currency, isConverted: false }}
            precision={1}
            textOnly={true}
          />
        ) : undefined
      }
      {...rest}
    />
  );
}
