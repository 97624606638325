import { useCallback, useEffect } from 'react';
import { Button } from '@crazyegginc/hatch';
import { useNavigate } from 'react-router-dom';
import { useActor } from '@xstate/react';

import { DEVICE_TYPES } from '/src/features/_global/constants';
import { abTestTypes } from '/src/features/ab-testing/constants';
import { useSite } from '/src/hooks';

import AbTestPNG from '@crazyegginc/hatch/dist/images/illustration-ab-test.png';
import AbTestWebP from '@crazyegginc/hatch/dist/images/illustration-ab-test.webp';
import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

import { WebP } from '/src/components/WebP';

export function AbTestSuccessPage({ siteId, ids = [], service }) {
  const navigate = useNavigate();
  const { selectedSite, selectSite, sites } = useSite();
  const [state] = useActor(service);
  const isSplit = state.context.payload.type === abTestTypes.SPLIT;

  useEffect(() => {
    if (sites && sites.length && selectedSite?.id !== siteId) {
      const site = sites.find((site) => site.id === siteId);
      selectSite(site);
    }
  }, [selectSite, selectedSite, siteId, sites]);

  const navigateToDashboard = useCallback(() => {
    if (selectedSite?.id === siteId) {
      navigate(
        {
          pathname: `/ab-tests`,
          search: `?site=${selectedSite.name}`,
        },
        { replace: true },
      );
    } else {
      navigate(`/ab-tests`, { replace: true });
    }
  }, [navigate, selectedSite, siteId]);

  const navigateToSnapshotBulkCreation = useCallback(() => {
    if (!isSplit) return;
    const payload = state.context.payload;
    const urls = [payload.url, ...payload.variants.filter((v) => v.type === 'variant').map((v) => v.redirectUrl)];
    const name = payload.name;
    const devices = payload.devices.map((d) => (d === DEVICE_TYPES.PHONE ? DEVICE_TYPES.MOBILE : d));
    const searchParams = new URLSearchParams();
    if (selectedSite.id === siteId) {
      searchParams.append('site', selectedSite.name);
    }
    searchParams.append('abTest', 'true');
    urls.forEach((url) => {
      searchParams.append('siteUrl', url);
    });
    searchParams.append('name', `${name.replace(payload.url, '[URL]')} - [TYPE]`);
    devices.forEach((device) => {
      searchParams.append('device', device);
    });

    navigate({
      pathname: `/snapshots/new/bulk`,
      search: `?${searchParams.toString()}`,
    });
  }, [navigate, selectedSite, siteId, state.context.payload, isSplit]);

  const createdSeveral = ids.length > 1;

  return (
    <div className="flex min-h-screen w-full flex-col items-center pt-[82px]">
      <WebP
        className="mx-auto mb-5 h-[200px] w-auto"
        webp={AbTestWebP}
        fallback={AbTestPNG}
        height="200"
        width="234"
        alt="AB Test"
      />

      <h1 className="text-header-0 m-0 mb-10">Success!</h1>

      <div className="mb-10 flex items-center space-x-3.75">
        <SuccessIcon className="h-5 w-5 fill-current text-lima-500" aria-label="success" />
        <span className="text-header-4">{createdSeveral ? `${ids.length} A/B Tests Created` : 'A/B Test Created'}</span>
      </div>

      <p className="text-body-2 m-0 mb-8 max-w-[454px] text-center text-base">
        Your {createdSeveral ? 'tests' : 'test'} will be live within a few minutes.
      </p>

      <div className="flex space-x-4">
        <Button onClick={navigateToDashboard}>Return to Dashboard</Button>
        {isSplit ? (
          <Button variant="secondary" onClick={navigateToSnapshotBulkCreation}>
            Set up Snapshots
          </Button>
        ) : null}
      </div>
    </div>
  );
}
