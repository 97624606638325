import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Divider } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useSelectedSite, useNotifications } from '/src/hooks';
import { useFilter } from '../../recordings-filter-context';
import { decomposeFilter } from './filter-functions';
import { playlistsMostRecentQuery } from '/src/features/recordings/queries';
import { markPlaylistAsUsedMutation } from '/src/features/recordings/mutations';
import { PlaylistTiles } from './PlaylistTiles';
import { PlaylistsSelector } from './PlaylistsSelector';

export function Playlists() {
  const { sessionInfo, currentUser } = useAuthContext();

  const { selectedSite } = useSelectedSite();
  const siteId = selectedSite?.id;
  const notifications = useNotifications();
  const { mutate: markPlaylistUsedMutate } = useMutation(markPlaylistAsUsedMutation);
  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  const { data: dataRecent } = useQuery({
    ...playlistsMostRecentQuery({ site: siteId }),
    enabled: Boolean(siteId),
  });

  const { playlists, selectPlaylist, setFilters, clearFilters, playlistsListQuery, recordingsCriteriaDefinitionQuery } =
    useFilter();
  const { data, fetching } = playlistsListQuery;

  // Apply playlist when playlists.selected is present
  useEffect(() => {
    if (!playlists.selected || fetching) return;
    const playlist = data?.playlistsList.find((x) => x.id === playlists.selected);
    if (!playlist) return;
    let { conditions } = decomposeFilter({
      filtersParam: playlist.filter,
      definitions: recordingsCriteriaDefinitionQuery.data?.definitions,
      currentUser,
    });
    if (!isImpersonated) {
      markPlaylistUsedMutate({ site: siteId, playlist: playlist.id });
    }
    if (conditions) {
      setFilters({ filters: JSON.parse(playlist.filter), playlist, conditions, resetPage: true });
    } else {
      clearFilters();
      notifications.error({
        content: 'Failed to load playlist as it contains invalid filter parameters!',
        skipHoneybadger: true,
      });
    }
  }, [
    recordingsCriteriaDefinitionQuery,
    data,
    setFilters,
    clearFilters,
    markPlaylistUsedMutate,
    siteId,
    notifications,
    playlists.selected,
    fetching,
    isImpersonated,
    currentUser,
  ]);

  if (!dataRecent) return null;

  return (
    <div className="flex w-full flex-col">
      <div className="-mx-4 mb-10">
        <div className="mb-[11px] flex items-center justify-between px-4">
          <h3 className="text-header-3">Most recent playlists</h3>
          <PlaylistsSelector
            playlists={data?.playlistsList}
            fetching={fetching}
            activePlaylist={playlists.active}
            selectPlaylist={selectPlaylist}
          />
        </div>
        <PlaylistTiles playlists={dataRecent?.playlistsMostRecent} selectPlaylist={selectPlaylist} />
        <Divider className="mx-4 mt-[26px] !w-auto" dashed />
      </div>
    </div>
  );
}
