import { useContext, useCallback } from 'react';

import { VisitorIdContext } from '/src/features/visitor-panel/visitor-id-context';

const SELECT_VISITOR = 'SELECT_VISITOR';
const UNSELECT_VISITOR = 'UNSELECT_VISITOR';

export function useVisitorId() {
  const { visitor, visitorDispatch } = useContext(VisitorIdContext);
  return {
    selectedVisitor: visitor?.selectedVisitor ?? null,
    panelOpen: visitor?.panelOpen ?? false,

    // actions
    selectVisitor: useCallback(
      ({ identifier = null, visitorId = null }) => {
        visitorDispatch({
          type: SELECT_VISITOR,
          payload: {
            identifier,
            visitorId,
          },
        });
      },
      [visitorDispatch],
    ),

    unselectVisitor: useCallback(() => {
      visitorDispatch({
        type: UNSELECT_VISITOR,
      });
    }, [visitorDispatch]),
  };
}
