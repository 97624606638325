import { BROWSER_TYPES, OS_TYPES } from '/src/features/_global/constants';

const { CHROME, EDGE, INTERNET_EXPLORER, FIREFOX, OPERA, SAFARI } = BROWSER_TYPES;
const { ANDROID, IOS, LINUX, MACOS, WINDOWS, WINDOWS_PHONE } = OS_TYPES;

export function DeviceDescription({ browser, os = null, screenDimensions = null }) {
  const getBrowser = (browser) => {
    switch (browser) {
      case CHROME:
        return 'Chrome';
      case EDGE:
        return 'Edge';
      case INTERNET_EXPLORER:
        return 'Internet Explorer';
      case FIREFOX:
        return 'Firefox';
      case OPERA:
        return 'Opera';
      case SAFARI:
        return 'Safari';
      default:
        return 'Other Device';
    }
  };

  const getOs = (os) => {
    switch (os) {
      case ANDROID:
        return 'Android';
      case IOS:
        return 'iOS';
      case LINUX:
        return 'Linux';
      case MACOS:
        return 'Mac';
      case WINDOWS:
        return 'Windows';
      case WINDOWS_PHONE:
        return 'Windows Phone';
      default:
        return 'Other OS';
    }
  };

  return (
    <span className="flex-1 truncate">
      {getBrowser(browser)} &bull; {getOs(os)} {screenDimensions && <>&bull; {screenDimensions}</>}
    </span>
  );
}
