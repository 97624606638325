import { capitalize } from '@crazyegginc/hatch';

import { useMutation, useModal, useSelectedSite, useNotifications } from '/src/hooks';
import { convertErrorTypeToConstant } from '/src/utils/string';
import { ChangeStatusModal } from '../modals/ChangeStatusModal';

import { getErrorHelper } from '/src/features/errors/utils';
import { updateErrorMutation } from '/src/features/errors/mutations';

export function ErrorHelper({ status, summary, fingerprintMd5 }) {
  const helperSummary = getErrorHelper(convertErrorTypeToConstant(summary));
  const modal = useModal();

  const { selectedSite } = useSelectedSite();
  const doUpdateError = useMutation(updateErrorMutation);
  const notifications = useNotifications();

  if (!helperSummary) return null;

  return (
    <div className="mt-3 flex w-full flex-wrap border-t border-dotted border-t-cadet-blue-500 px-5 pb-2 pt-4">
      <span className="mb-2 text-base leading-normal">{helperSummary.message}</span>
      {status !== helperSummary.recommendation && (
        <span className="w-full text-base">
          <span className="font-bold">Recommended action:</span>
          <button
            disabled={status === helperSummary.recommendation}
            type="button"
            className="ml-1 text-base font-semibold text-dodger-blue-500 hover:underline focus-visible:outline-black"
            onClick={() => {
              modal.show(
                <ChangeStatusModal
                  newStatus={helperSummary.recommendation}
                  onSubmit={(values, actions) => {
                    doUpdateError.mutate(
                      {
                        siteId: selectedSite?.id,
                        status: helperSummary.recommendation,
                        note: values.comment,
                        fingerprintMd5,
                      },
                      {
                        onError: (error) =>
                          notifications.error({
                            content: 'Failed to update error status.',
                            timeout: 3000,
                            context: { error },
                          }),
                        onSuccess: () => close(),
                        onSettled: () => actions.setSubmitting(false),
                      },
                    );
                  }}
                />,
              );
            }}
          >
            Mark as {capitalize(helperSummary.recommendation)}
          </button>
        </span>
      )}
    </div>
  );
}
