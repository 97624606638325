import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Input, Button } from '@crazyegginc/hatch';

import { getVariantColor } from '/src/services/web-editor';
import { Modal } from '/src/contexts/modal';
import { copyToClipboard } from '/src/utils';

function customGetVariantColor(variant) {
  if (variant.type === 'CONTROL') {
    return 'black';
  }
  return getVariantColor(variant);
}

export function SharePreviewModal({ variants }) {
  const [copied, setCopied] = useState(null);

  return (
    <Modal>
      <Modal.Title>Share A/B Test preview</Modal.Title>
      <div className="grid grid-cols-[auto,1fr] items-center gap-4">
        {variants.map((v) => {
          const id = `view_variant_url_${v.id}`;
          const isCopied = copied === id;
          if (!v.viewVariantUrl) return null;
          return (
            <Fragment key={`view_variant_url_${v.id}`}>
              <label
                style={{ color: customGetVariantColor(v) }}
                className="text-body-1 flex-shrink-0 text-xs"
                htmlFor={id}
              >
                {v.variantName.replace('#', '')}
              </label>
              <Input
                id={id}
                value={v.viewVariantUrl}
                className={classNames('!h-[50px]', {
                  '!pr-[82px]': isCopied,
                  '!pr-[70px]': !isCopied,
                })}
                rightIcon={
                  <Button
                    disabled={isCopied}
                    onClick={() => {
                      copyToClipboard(v.viewVariantUrl);
                      setTimeout(() => {
                        setCopied(null);
                      }, 1000);
                      setCopied(id);
                    }}
                    variant="secondary"
                    className={classNames('!h-[30px] !px-[7px]', {
                      '!cursor-default select-none !bg-lima-500 !text-white': isCopied,
                    })}
                  >
                    {isCopied ? 'copied' : 'copy'}
                  </Button>
                }
              />
            </Fragment>
          );
        })}
      </div>
    </Modal>
  );
}
