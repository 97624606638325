const purgeIgnoreWhitelist = ['ce_seen_surveys'];

export function selectivelyClearLocalStorage() {
  for (let key of Object.keys(localStorage)) {
    if (!key.match(/^keep:/) && !purgeIgnoreWhitelist.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}
//this is more aggressive than `selectivelyClearLocalStorage`.
// It also clears `keep:` prefixed entries, but ignores
// entries in the whitelist
export function purgeLocalStorage() {
  if (!window.localStorage) return;
  for (let [key] of Object.entries(window.localStorage)) {
    if (!purgeIgnoreWhitelist.includes(key)) {
      window.localStorage.removeItem(key);
    }
  }
}
