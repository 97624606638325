import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';

function ListMessage(roleType) {
  switch (roleType.toUpperCase()) {
    case ACCOUNT_USER_ROLES.OWNER:
      return [
        'Admin',
        'Can create Snapshots, Tests and enable Recordings. Can add or edit team members, and also make changes to billing/payment info.',
      ];
    case ACCOUNT_USER_ROLES.MANAGER:
      return [
        'Member',
        'Can create Snapshots, Tests, and enable Recordings. Cannot access team members or view payment information.',
      ];
    case ACCOUNT_USER_ROLES.READ_ONLY:
      return [
        'Read only',
        'Can view Snapshots, Tests and Recordings. Cannot access team members or view payment information.',
      ];
    default:
      return ['N/A', 'N/A'];
  }
}

function ListItem({ roleType }) {
  return (
    <li className="border-b border-dashed border-mako-500 px-5 py-6 last:border-none">
      <h5 className="mb-1.5 text-md font-semibold text-white">{`${ListMessage(roleType)[0]}:`}</h5>
      <p className="text-xs font-normal text-cadet-blue-500">{ListMessage(roleType)[1]}</p>
    </li>
  );
}

export function TooltipRoleContent(role) {
  function contentType() {
    switch (role) {
      case ACCOUNT_USER_ROLES.OWNER:
        return <ListItem roleType={ACCOUNT_USER_ROLES.OWNER} />;
      case ACCOUNT_USER_ROLES.MANAGER:
        return <ListItem roleType={ACCOUNT_USER_ROLES.MANAGER} />;
      case ACCOUNT_USER_ROLES.READ_ONLY:
        return <ListItem roleType={ACCOUNT_USER_ROLES.READ_ONLY} />;
      default:
        return (
          <>
            <ListItem roleType={ACCOUNT_USER_ROLES.OWNER} />
            <ListItem roleType={ACCOUNT_USER_ROLES.MANAGER} />
            <ListItem roleType={ACCOUNT_USER_ROLES.READ_ONLY} />
          </>
        );
    }
  }
  return <ul className="w-[360px] text-left">{contentType()}</ul>;
}
