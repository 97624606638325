import { useFormikContext } from 'formik';
import { Checkbox } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { useEditorContext } from '../../editor-context';
import { ADDONS_CAPABILITIES } from '/src/features/addons/constants';

import { ReactComponent as AccessibilityIcon } from '@crazyegginc/hatch/dist/images/icon-accessibility-badge.svg';

export function ColorAccessibility() {
  const { addonsCapabilities, spelledType, readonly } = useEditorContext();
  const canSelectAccessibility = addonsCapabilities[ADDONS_CAPABILITIES.COLOR_ACCESSIBILITY];
  const { values, handleChange } = useFormikContext();

  if (!canSelectAccessibility) {
    return (
      <div className="flex items-center rounded bg-white-lilac-500 py-2 pl-2.5 pr-3.5">
        <div className="flex-shrink-0  pr-1.25 pt-0.5">
          <AccessibilityIcon className="h-6 w-6 fill-current text-[#DDB36D]" />
        </div>

        <div className="text-info-body leading-none">
          We automatically adjust your {spelledType} colors for minimum contrast -{' '}
          <a
            className="text-link text-2xs font-normal italic"
            href={SupportLinks.survey.minimumContrast}
            target="_blank"
            rel="noopener noreferrer"
          >
            it&apos;s just one of the many things we do to meet AAA WCAG.
          </a>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-body-1 mb-1.5 block">Color accessibility</div>
      <Checkbox
        label="Automate Accessibility"
        id="adjustColors"
        name="theme.adjustColors"
        checked={values.theme.adjustColors}
        onChange={handleChange}
        disabled={readonly}
      />
      <div className="ml-[30px] mt-2.5 flex items-center rounded bg-white-lilac-500 py-2 pl-2.5 pr-3.5">
        <div className="flex-shrink-0  pr-1.25 pt-0.5">
          <AccessibilityIcon className="h-6 w-6 fill-current text-[#DDB36D]" />
        </div>

        {values.theme.adjustColors ? (
          <div className="text-info-body leading-none">
            We automatically adjust your {spelledType} colors for minimum contrast{' '}
            <a
              className="text-link text-2xs font-normal italic"
              href={SupportLinks.survey.minimumContrast}
              target="_blank"
              rel="noopener noreferrer"
            >
              to meet AAA WCAG standards.
            </a>
          </div>
        ) : (
          <div className="text-info-body leading-none">
            Some jurisdictions may have legal requirements around accessibility, please check your color selections.
          </div>
        )}
      </div>
    </div>
  );
}
