import { useEffect, useRef, useMemo, useCallback, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Checkbox, SkeletonLine, Panel, Button } from '@crazyegginc/hatch';

import { recordingsGroupsQuery, recordingsInGroupQuery } from '/src/features/recordings/queries';
import { NoMatchesWall } from '../../../_global/paywalls/NoMatchesWall';
import { RecordingsTable } from './RecordingsTable';
import { Loader } from './Loader';
import { AsyncImg } from '/src/components/AsyncImg';
import { PaginationControls } from '/src/components/PaginationControls';
import { useSelectedSite, useSelection, usePrevious, usePermissions } from '/src/hooks';
import { formatFullDateTime } from '/src/utils/date';
import { inflect } from '/src/utils/string';
import { isProduction } from '/src/utils';
import { hudMetric } from '/src/utils/metrics';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { FEATURES, LOGICALS } from '/src/features/_global/constants';
import { RECORDINGS_PER_PAGE } from '../../constants';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-cross-circle-filled.svg';
import { ReactComponent as HeatmapIcon } from '@crazyegginc/hatch/dist/images/icon-heat-filled.svg';
import Placeholder from '/src/features/recordings/assets/placeholder-img.png';

const bottomMargin = 15;
const groupHeader = 110;
const groupFooter = 61;
const tableHeader = 56;
const tableRow = 67;
const heatmapRow = 35;

const closedGroupHeight = bottomMargin + groupHeader;

export function RecordingsGroups({ filtersHook, site }) {
  const isProd = isProduction();
  const { selectedSite } = useSelectedSite();
  const permissions = usePermissions();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const parentRef = useRef(null);

  const { queryParams, clearFilters, initialLoadComplete } = filtersHook;
  let { data, hasNextPage, fetchNextPage, isFetching, isInitialLoading } = useInfiniteQuery({
    ...recordingsGroupsQuery({
      filters: JSON.stringify(queryParams.filters),
      groupBy: queryParams.groupBy,
      includeFirst: queryParams.groupURL,
      limit: RECORDINGS_PER_PAGE,
      site: selectedSite?.id,
    }),
    enabled: Boolean(
      selectedSite?.id &&
        initialLoadComplete &&
        [GROUP_BY_TYPES.FIRST, GROUP_BY_TYPES.LAST].includes(queryParams.groupBy),
    ),
  });
  if (!initialLoadComplete) {
    isInitialLoading = true;
    isFetching = true;
  }

  const canEditRecordings = permissions.can('edit', FEATURES.RECORDINGS).allowed;
  const canViewHUDSnapshot = permissions.can('viewHUDSnapshot', FEATURES.RECORDINGS).allowed;

  const {
    data: recordingsData,
    isLoading: recordingsLoading,
    isFetching: recordingsFetching,
  } = useQuery({
    ...recordingsInGroupQuery({
      filters: JSON.stringify(queryParams.filters),
      group: queryParams.group,
      groupBy: queryParams.groupBy,
      limit: 10,
      field: queryParams.order.field,
      sort: queryParams.order.sort,
      page: queryParams.page,
      site: site?.id,
    }),
    enabled: Boolean(
      site?.id &&
        initialLoadComplete &&
        [GROUP_BY_TYPES.FIRST, GROUP_BY_TYPES.LAST].includes(queryParams.groupBy) &&
        queryParams.group,
    ),
  });

  const groups = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.recordingsGroups?.groups ?? [])], []) ?? [],
    [data],
  );
  const recordings = recordingsLoading ? [] : (recordingsData?.recordingsInGroup?.recordings ?? []);

  const getCount = () => {
    if (isInitialLoading) return 1;
    if (hasNextPage) return groups.length + 1;
    return groups.length;
  };

  const scrollMargin = parentRef.current?.offsetTop;

  const estimateSize = useCallback(
    (index) => {
      if (index > groups.length - 1 || isInitialLoading) return closedGroupHeight;
      if (queryParams.group === groups[index].id) {
        let h = closedGroupHeight + tableHeader + groupFooter + (recordings.length || 1) * tableRow;
        if (canViewHUDSnapshot) {
          h += heatmapRow;
        }
        return h;
      } else {
        return closedGroupHeight;
      }
    },
    [queryParams.group, groups, recordings.length, isInitialLoading, canViewHUDSnapshot],
  );

  const virtualizer = useWindowVirtualizer({
    count: getCount(),
    estimateSize,
    scrollMargin,
    overscan: 5,
  });

  useLayoutEffect(() => {
    // need to reset measurment, whenever estimateSize changes
    virtualizer.measure();
  }, [estimateSize, virtualizer]);

  const virtualItems = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();
    if (!lastItem) return;
    if (lastItem.index === groups.length && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, groups.length, isFetching, fetchNextPage, virtualItems]);

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      if (queryParams.groupBy === GROUP_BY_TYPES.FIRST) {
        window.CE2.timing.start('recordings_dashboard_group_first');
        setPerfMetricStarted(true);
      } else if (queryParams.groupBy === GROUP_BY_TYPES.LAST) {
        window.CE2.timing.start('recordings_dashboard_group_last');
        setPerfMetricStarted(true);
      }
    }
  }, [isProd, queryParams.groupBy]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && data) {
      try {
        if (queryParams.groupBy === GROUP_BY_TYPES.FIRST) {
          window.CE2.timing.stop('recordings_dashboard_group_first');
        } else if (queryParams.groupBy === GROUP_BY_TYPES.LAST) {
          window.CE2.timing.stop('recordings_dashboard_group_last');
        }
        setPerfMetricStarted(false);
      } catch {
        // there was no starting point (this is an edge case)
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, isProd, queryParams.groupBy]);

  if (!isFetching && groups.length === 0) {
    return (
      <div className="mt-5 flex w-full items-center justify-center" ref={parentRef}>
        <NoMatchesWall
          reset={clearFilters}
          text={
            <>
              matches, try adjusting the date range or clearing the filter.
              <br />
              You can also try adjusting the targeting rules to ensure you are recording the most important pages
            </>
          }
          actionText="filter"
        />
      </div>
    );
  }

  return (
    <>
      {isInitialLoading && <Loader />}
      <div ref={parentRef} className="relative w-full" style={{ height: `${totalSize}px` }}>
        {virtualItems.map((item) => {
          const style = {
            minHeight: '125px',
            paddingBottom: `${bottomMargin}px`,
            transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
          };

          if (item.index > groups.length - 1 || isInitialLoading) {
            return (
              <div key="skeleton" className="absolute left-0 top-0 w-full" style={style}>
                <RecordingsGroupSkeleton />
              </div>
            );
          }

          const group = groups[item.index];
          const open = queryParams.group === group.id;
          return (
            <div key={group.id} className="absolute left-0 top-0 w-full" style={style} data-testid="recordingsGroup">
              <RecordingsGroup
                group={group}
                filtersHook={filtersHook}
                recordings={open ? recordings : []}
                open={open}
                fetching={recordingsFetching}
                loading={recordingsLoading}
                site={site}
                innerHeight={estimateSize(item.index) - closedGroupHeight}
                selectable={canEditRecordings}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

function RecordingsGroup({ group, filtersHook, recordings, open, fetching, loading, site, innerHeight, selectable }) {
  const { queryParams, selectGroup, setPage, setPageOnCountChange } = filtersHook;
  const hasUnwatched = group.unwatchedCount !== 0;
  const groupRef = useRef(null);

  const { toggleRecordingsGroup, toggleRecordingsEntity, groupSelection } = useSelection();
  const selectionForGroup = groupSelection.find((x) => x.groupId === group.id);
  const everyChecked = selectionForGroup?.entities.every((x) => x.selected === true);
  const someChecked = selectionForGroup?.entities.some((x) => x.selected === true);
  const permissions = usePermissions();
  const canViewHUDSnapshot = permissions.can('viewHUDSnapshot', FEATURES.RECORDINGS).allowed;

  const prevPage = usePrevious(queryParams.page);
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if ((open && prevOpen === false) || (open && queryParams.page !== prevPage && prevPage)) {
      setTimeout(() => groupRef.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' }), 10);
    }
  }, [open, prevOpen, prevPage, queryParams.page]);

  useEffect(() => {
    // set page when total count in group changes (due to delete)
    if (!fetching && open) {
      setPageOnCountChange(group.recordingsCount);
    }
  }, [setPageOnCountChange, group.recordingsCount, fetching, open]);

  const handleSelectionInGroup = useCallback(
    (entity) => {
      toggleRecordingsEntity({ entity, groupId: group.id, queryParams });
    },
    [toggleRecordingsEntity, group.id, queryParams],
  );

  const handleGroupOpenClose = () => {
    if (open) {
      selectGroup(undefined);
    } else {
      selectGroup(group.id);
    }
  };

  const groupSelectionForTable = useMemo(
    () => recordings.filter((x) => selectionForGroup?.entities.find((y) => y.id === x.id)?.selected ?? false),
    [recordings, selectionForGroup],
  );

  return (
    <Panel className="!m-0 !p-0">
      <button
        type="button"
        className="flex h-[110px] w-full items-center px-[35px] focus:outline-none"
        onClick={handleGroupOpenClose}
        ref={groupRef}
        data-testid="recordingsHeader"
      >
        {selectable && (
          <div className="mr-5">
            <Checkbox
              checked={everyChecked}
              indeterminate={!everyChecked && someChecked}
              onChange={() => toggleRecordingsGroup({ groupId: group.id, queryParams })}
              onClick={(e) => e.stopPropagation()}
              title="Toggle whole group selected"
              size="lg"
            />
          </div>
        )}
        <Thumbnail src={group.thumbnail} />
        <div className="ml-5 mr-2.5 flex min-w-0 flex-1 flex-col justify-center text-left">
          <div
            data-testid="group_id"
            className={classNames('text-body-1 mb-1.25 truncate underline', {
              'text-dodger-blue-500': hasUnwatched,
              'text-black-pearl-500': !hasUnwatched,
            })}
          >
            {group.id}
          </div>
          <span className="text-body-4 italic">Latest: {formatFullDateTime(group.latestRecording)}</span>
        </div>
        <div className="text-body-3 flex-shrink-0">
          {group.unwatchedCount ? `${group.unwatchedCount.toLocaleString('en-US')} unwatched` : ''}
        </div>
        <div className="text-body-1 ml-5 flex h-10 w-[175px] flex-shrink-0 items-center justify-between rounded bg-solitude-500 px-[15px] text-dodger-blue-500">
          {group.recordingsCount
            ? `${group.recordingsCount.toLocaleString('en-US')} ${inflect('recording', group.recordingsCount)}`
            : open
              ? 'Close group'
              : 'Open group'}
          <ExpandIcon
            className={classNames('h-2.5 w-2.5 fill-current transition-transform duration-300 ease-out', {
              'rotate-180': !open,
            })}
          />
        </div>
      </button>
      {
        <div
          style={{ maxHeight: open ? `${innerHeight}px` : '0', height: `${innerHeight}px` }}
          className={classNames('w-full overflow-hidden', {
            'transition-all duration-700 ease-in-out': open && !loading,
          })}
        >
          {open && (
            <>
              {canViewHUDSnapshot && (
                <div className="flex h-[35px] justify-end border-t border-dashed border-mystic-500 bg-off-white-500 pr-[21px]">
                  <ViewHUDSnapshot url={group.url} filtersHook={filtersHook} />
                </div>
              )}
              {recordings.length > 0 || loading ? (
                <>
                  <RecordingsTable
                    site={site}
                    filtersHook={filtersHook}
                    recordings={recordings}
                    fetching={loading}
                    useVirtualization={false}
                    nested={true}
                    selectAllOption={false}
                    groupSelectionForTable={groupSelectionForTable}
                    customSelectionHandler={handleSelectionInGroup}
                  />
                  <div className="flex h-[61px] w-full items-center justify-end pr-[35px]">
                    <PaginationControls
                      currentPage={queryParams.page}
                      perPage={RECORDINGS_PER_PAGE}
                      total={group.recordingsCount}
                      setPage={(page) => setPage(page)}
                    />
                  </div>
                </>
              ) : (
                <div className="flex h-4/5 items-center justify-center">
                  <ErrorIcon className="mr-4 h-6 w-6 fill-current text-carnation-500" />
                  <div className="text-body-2">
                    <div className="text-body-1">Hmm, we’re having a little trouble here…</div>
                    <br />
                    Please refresh the page and try again.
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      }
    </Panel>
  );
}

function Thumbnail({ src }) {
  return (
    <div className="h-[74px] w-[110px] flex-shrink-0 overflow-hidden rounded border border-mystic-500">
      <AsyncImg
        src={src}
        alt="group thumbnail"
        width="108px"
        height="72px"
        loadingComp={<SkeletonLine className="!h-full !w-full !rounded" />}
        errorComp={<img src={Placeholder} alt="default group thumbnail" width="108" height="72" />}
      />
    </div>
  );
}

function RecordingsGroupSkeleton() {
  return (
    <Panel className="!m-0 !p-0">
      <div className="flex h-[110px] w-full items-center px-[35px]">
        <div className="mr-5">
          <Checkbox size="lg" onChange={() => {}} />
        </div>
        <div className="h-[72px] w-[108px] rounded bg-[#e2e2e2]" />
        <div className="ml-5 mr-2.5 flex min-w-0 flex-1 flex-col justify-center">
          <div className="mb-1.25">
            <SkeletonLine width="130px" />
          </div>
          <span>
            <SkeletonLine width="235px" />
          </span>
        </div>
        <SkeletonLine width="250px" />
      </div>
    </Panel>
  );
}

function ViewHUDSnapshot({ url, filtersHook }) {
  let { filters } = filtersHook.queryParams;
  const { filtersVersion } = filtersHook;
  const visitedPageFilter = {
    criteria: 'visited_page',
    comparison: 'matches',
    multiple_values: 'and',
    values: [url],
  };

  if (filters && filters.conditions && filters.conditions.length > 0) {
    filters = JSON.parse(JSON.stringify(filters));
    filters.conditions.push(visitedPageFilter);
  } else {
    filters = {
      version: filtersVersion,
      operator: LOGICALS.AND,
      conditions: [visitedPageFilter],
    };
  }

  return (
    <Button
      onClick={() => {
        hudMetric('adat_filter', 'loading_started');
        window.HudLauncher.open(url, {
          adat: filters,
        });
      }}
      variant="ghost-primary"
    >
      <HeatmapIcon className="mr-2 h-4 w-4 fill-current" />
      View Heatmap
    </Button>
  );
}
