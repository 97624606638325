import { DeleteRecordingsModal } from '/src/features/recordings/components/dashboard/modals/DeleteRecordingsModal';
import { TagRecordingsModal } from '/src/features/recordings/components/dashboard/modals/TagRecordingsModal';
import { UntagRecordingsModal } from '/src/features/recordings/components/dashboard/modals/UntagRecordingsModal';

import { usePermissions, useMutation, useSelectedSite, useModal } from '/src/hooks';
import {
  deleteRecordingsMutation,
  watchRecordingsMutation,
  unwatchRecordingsMutation,
  addTagsToRecordingsMutation,
  removeTagsFromRecordingsMutation,
} from '/src/features/recordings/mutations';
import { ActionIcon } from '/src/components/bulk-action/ActionIcon';

import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as WatchedIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as UnwatchedIcon } from '@crazyegginc/hatch/dist/images/icon-unwatched-outline.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as UntagIcon } from '@crazyegginc/hatch/dist/images/icon-untag-outline.svg';

export function RecordingsBulkActions({ onActionStart, onSuccess, onError, selectedIds }) {
  const { selectedSite } = useSelectedSite();
  const modal = useModal();
  const permissions = usePermissions();

  const deleteRecordings = useMutation(deleteRecordingsMutation);
  const addTagsToRecordings = useMutation(addTagsToRecordingsMutation);
  const removeTagsFromRecordings = useMutation(removeTagsFromRecordingsMutation);
  const watchRecordings = useMutation(watchRecordingsMutation);
  const unwatchRecordings = useMutation(unwatchRecordingsMutation);
  const selectedNum = selectedIds.length;

  const canDelete = permissions.can('edit', FEATURES.RECORDINGS).allowed;
  const canTag = permissions.can('edit', FEATURES.RECORDINGS).allowed;
  const canWatchUnwatch = permissions.can('edit', FEATURES.RECORDINGS).allowed;

  return (
    <>
      {canDelete && (
        <ActionIcon
          img={RemoveIcon}
          alt="Delete selected"
          tooltip="Delete"
          onClick={() => {
            modal.show(
              <DeleteRecordingsModal
                onConfirm={() => {
                  onActionStart();
                  deleteRecordings.mutate(
                    { recordingIds: [...selectedIds], site: selectedSite.id },
                    {
                      onError: (error) => onError({ error, name: 'Delete recordings' }),
                      onSuccess: () => onSuccess({ deselectAll: true }),
                    },
                  );
                }}
                numToDelete={selectedNum}
              />,
            );
          }}
        />
      )}
      {canTag && (
        <>
          <ActionIcon
            img={TagIcon}
            alt="Tag selected"
            tooltip="Add tag"
            onClick={() => {
              modal.show(
                <TagRecordingsModal
                  onConfirm={(tags) => {
                    onActionStart();
                    addTagsToRecordings.mutate(
                      { recordingIds: [...selectedIds], tags },
                      {
                        onError: (error) => onError({ error, name: 'Tag recordings' }),
                        onSuccess: () => onSuccess(),
                      },
                    );
                  }}
                />,
              );
            }}
          />
          <ActionIcon
            img={UntagIcon}
            alt="Untag selected"
            tooltip="Remove tag"
            onClick={() => {
              modal.show(
                <UntagRecordingsModal
                  onConfirm={(tags) => {
                    onActionStart();
                    removeTagsFromRecordings.mutate(
                      { recordingIds: [...selectedIds], tags },
                      {
                        onError: (error) => onError({ error, name: 'Untag recordings' }),
                        onSuccess: () => onSuccess(),
                      },
                    );
                  }}
                />,
              );
            }}
          />
        </>
      )}
      {canWatchUnwatch && (
        <>
          <ActionIcon
            img={WatchedIcon}
            alt="Mark selected as watched"
            tooltip="Mark as watched"
            onClick={() => {
              onActionStart();
              watchRecordings.mutate(
                { recordingIds: [...selectedIds] },
                {
                  onError: (error) => onError({ error, name: 'Mark recordings as watched' }),
                  onSuccess: () => onSuccess({ updateRecordingsGroups: true }),
                },
              );
            }}
          />
          <ActionIcon
            img={UnwatchedIcon}
            alt="Mark selected as unwatched"
            tooltip="Mark as unwatched"
            onClick={() => {
              onActionStart();
              unwatchRecordings.mutate(
                { recordingIds: [...selectedIds] },
                {
                  onError: (error) => onError({ error, name: 'Mark recordings as unwatched' }),
                  onSuccess: () => onSuccess({ updateRecordingsGroups: true }),
                },
              );
            }}
          />
        </>
      )}
    </>
  );
}
