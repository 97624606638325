/* eslint-disable jsx-a11y/no-autofocus */
import { Formik, Form, Field } from 'formik';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { useMutation, useModal } from '/src/hooks';
import { addonEditMutation } from '/src/features/addons/mutations';
import { Modal } from '/src/contexts/modal';
import { ADDON_TYPES } from '/src/features/addons/constants';

export function RenameAddonModal({ addon }) {
  const modal = useModal();

  const addonEdit = useMutation(addonEditMutation);

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Formik
        initialValues={{ name: addon.name }}
        onSubmit={(values) => {
          addonEdit.mutate(
            {
              addon: { name: values.name, id: addon.id, siteId: addon.siteId },
            },
            {
              onSuccess: () => modal.close(),
            },
          );
        }}
      >
        {({ values }) => (
          <Form>
            <Modal.Title>Rename your {addon.type === ADDON_TYPES.SURVEY ? 'Survey' : 'CTA'}</Modal.Title>

            <Field name="name">
              {({ field }) => (
                <Input
                  {...field}
                  autoFocus
                  aria-label={`new ${addon.type === ADDON_TYPES.SURVEY ? 'survey' : 'CTA'} name`}
                />
              )}
            </Field>

            <Modal.Actions>
              <Button type="submit" disabled={addonEdit.isLoading || !values.name}>
                {addonEdit.isLoading && (
                  <span className="mr-2.5">
                    <Spinner />
                  </span>
                )}
                {addonEdit.isLoading ? 'Renaming...' : 'Rename'}
              </Button>
              <Modal.Cancel />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
