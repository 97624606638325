import { LIVE_EVENT_NAMES, LIVE_EVENT_TYPES } from './constants';

import { VisitEvent } from '/src/features/live-activity/components/events/VisitEvent';
import { DailyVisitEvent } from '/src/features/live-activity/components/events/DailyVisitEvent';
import { DailyVisitCounterEvent } from '/src/features/live-activity/components/events/DailyVisitCounterEvent';
import { AddToCartEvent } from '/src/features/live-activity/components/events/AddToCartEvent';
import { CheckoutCompleteEvent } from '/src/features/live-activity/components/events/CheckoutCompleteEvent';
import { ClickEvent } from '/src/features/live-activity/components/events/ClickEvent';
import { ErrorEvent } from '/src/features/live-activity/components/events/ErrorEvent';
import { TagEvent } from '/src/features/live-activity/components/events/TagEvent';
import { FormEvent } from '/src/features/live-activity/components/events/FormEvent';
import { SignupEvent } from '/src/features/live-activity/components/events/SignupEvent';
import { LoginEvent } from '/src/features/live-activity/components/events/LoginEvent';
import { SurveyResponseEvent } from '/src/features/live-activity/components/events/SurveyResponseEvent';
import { GoalConversionEvent } from '/src/features/live-activity/components/events/GoalConversionEvent';

export const liveEvents = [
  {
    name: LIVE_EVENT_NAMES.VISIT,
    type: LIVE_EVENT_TYPES.VISIT,
    printName: 'Advanced visit',
    CardComponent: VisitEvent,
    tooltip:
      'Advanced Visits are tracked every time someone visits your site as a part of an active Snapshot or Recording. To track more Advanced Visits create a new Snapshot or upgrade your plan to track more Recordings.',
  },
  {
    name: LIVE_EVENT_NAMES.BASIC_VISIT,
    type: LIVE_EVENT_TYPES.DAILY_VISIT,
    printName: 'Basic visit',
    CardComponent: DailyVisitEvent,
    tooltip:
      'Basic Visits are tracked the first time someone visits your site for the day when they are not a part of an active Snapshot or Recording. To track more detailed information create a new Snapshot or upgrade your plan to track more Recordings.',
  },
  {
    name: LIVE_EVENT_NAMES.BASIC_VISIT,
    type: LIVE_EVENT_TYPES.DAILY_VISIT_COUNTER,
    printName: 'Basic visit',
    CardComponent: DailyVisitCounterEvent,
    tooltip:
      'Basic Visits are tracked the first time someone visits your site for the day when they are not a part of an active Snapshot or Recording. To track more detailed information create a new Snapshot or upgrade your plan to track more Recordings.',
  },
  {
    name: LIVE_EVENT_NAMES.CLICK,
    type: LIVE_EVENT_TYPES.CLICK,
    printName: 'Click',
    CardComponent: ClickEvent,
  },
  {
    name: LIVE_EVENT_NAMES.ERROR,
    type: LIVE_EVENT_TYPES.ERROR,
    printName: 'Error',
    CardComponent: ErrorEvent,
  },
  {
    name: LIVE_EVENT_NAMES.TAG,
    type: LIVE_EVENT_TYPES.TAG,
    printName: 'Tag event',
    CardComponent: TagEvent,
  },
  {
    name: LIVE_EVENT_NAMES.ECOMMERCE_ADD_TO_CART,
    type: LIVE_EVENT_TYPES.ECOMMERCE,
    sub_type: 1,
    printName: 'Add to cart',
    CardComponent: AddToCartEvent,
  },
  {
    name: LIVE_EVENT_NAMES.ECOMMERCE_CHECKOUT_COMPLETE,
    type: LIVE_EVENT_TYPES.ECOMMERCE,
    sub_type: 3,
    printName: 'Checkout complete',
    CardComponent: CheckoutCompleteEvent,
  },
  {
    name: LIVE_EVENT_NAMES.FORM_SUBMIT,
    type: LIVE_EVENT_TYPES.FORM,
    sub_type: 1,
    printName: 'Complete form',
    CardComponent: FormEvent,
  },
  {
    name: LIVE_EVENT_NAMES.FORM_RESUBMIT,
    type: LIVE_EVENT_TYPES.FORM,
    sub_type: 2,
    printName: 'Resubmit form',
    CardComponent: FormEvent,
  },
  {
    name: LIVE_EVENT_NAMES.FORM_ABANDON,
    type: LIVE_EVENT_TYPES.FORM,
    sub_type: 3,
    printName: 'Abandon form',
    CardComponent: FormEvent,
  },
  {
    name: LIVE_EVENT_NAMES.FORM_SIGNUP,
    type: LIVE_EVENT_TYPES.FORM,
    sub_type: 4,
    printName: 'Signup',
    CardComponent: SignupEvent,
  },
  {
    name: LIVE_EVENT_NAMES.FORM_LOGIN,
    type: LIVE_EVENT_TYPES.FORM,
    sub_type: 5,
    printName: 'Login',
    CardComponent: LoginEvent,
  },
  {
    name: LIVE_EVENT_NAMES.SURVEY_RESPONSE,
    type: LIVE_EVENT_TYPES.SURVEY_RESPONSE,
    printName: 'Survey response',
    CardComponent: SurveyResponseEvent,
  },
  {
    name: LIVE_EVENT_NAMES.GOAL_CONVERSION,
    type: LIVE_EVENT_TYPES.GOAL_CONVERSION,
    printName: 'Goal conversion',
    CardComponent: GoalConversionEvent,
  },
];
