export const WHATS_NEW_FEATURES = {
  AB_TESTING: 'AB_TESTING',
  ACCOUNT: 'ACCOUNT',
  CTAS: 'CTAS',
  EDITOR: 'EDITOR',
  ERRORS_TRACKING: 'ERRORS_TRACKING',
  HELP: 'HELP',
  INSTALLATION: 'INSTALLATION',
  OPTIONS: 'OPTIONS',
  RECORDINGS: 'RECORDINGS',
  SNAPSHOTS: 'SNAPSHOTS',
  SURVEYS: 'SURVEYS',
  TRAFFIC_ANALYSIS: 'TRAFFIC_ANALYSIS',
  LIVE_ACTIVITY: 'LIVE_ACTIVITY',
  GOALS: 'GOALS',
};

export const WHATS_NEW_TYPES = {
  BETA: 'BETA',
  FIX: 'FIX',
  NEW: 'NEW',
  IMPROVEMENT: 'IMPROVEMENT',
};
