import { useRef, useMemo, useState, useCallback } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button, ReactTable, Toggle } from '@crazyegginc/hatch';

import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { useSelectedSite, useQueryParams } from '/src/hooks';

import { SelectedValues } from './SelectedValues';
import { db } from '../db';
import { initialSelection, getEmptyText } from '../utils';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function TopTrafficSourcesTab({ initDone, addToComparison, fetching }) {
  const { selectedSite } = useSelectedSite();
  const tableRef = useRef(null);
  const { get: getParam, set: setParam } = useQueryParams();
  const [includeInternalReferrer, setIncludeInternalReferrer] = useState(getParam('internalReferrer', false));

  const setToggle = useCallback(
    (newValue) => {
      setIncludeInternalReferrer(newValue);
      setParam('internalReferrer', newValue);
    },
    [setParam],
  );

  const getTopSources = async () => {
    if (!initDone) return [];

    // sc22016
    if ((await db.traffic.count()) === 0) {
      return [];
    }
    const regexp = new RegExp(selectedSite.name);
    let result = await db.traffic.orderBy('total');
    if (!includeInternalReferrer) {
      result = result.filter((x) => !regexp.test(x.referrerUrl));
    }

    result = result.reverse().limit(10).toArray();
    return result;
  };

  const topSourcesResult = useLiveQuery(() => getTopSources(), [initDone, includeInternalReferrer]);
  const topSources = useMemo(() => topSourcesResult ?? [], [topSourcesResult]);

  const columns = useMemo(() => {
    function createSelectionObj(row) {
      const params = initialSelection();
      const includeEmpty = Object.keys(params).every((key) => row.original[key] === '');
      Object.keys(params).forEach((key) => {
        if (row.original[key] !== '' || includeEmpty) {
          params[key].push({ value: row.original[key], label: includeEmpty ? getEmptyText(key) : null });
        }
      });
      return params;
    }

    return [
      {
        header: 'Parameters',
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => {
          const params = createSelectionObj(row);
          return <SelectedValues options={{}} selection={params} checkFilteredOut={false} />;
        },
      },
      {
        header: 'Total sessions',
        accessorKey: 'total',
        meta: {
          align: 'center',
        },
        size: 30,
        cell: ({ row }) => {
          return <div className="w-full max-w-[75px] text-right">{row.original.total.toLocaleString()}</div>;
        },
      },
      {
        id: 'actions',
        meta: {
          align: 'center',
        },
        size: 30,
        cell: ({ row }) => {
          const params = createSelectionObj(row);
          return (
            <Button variant="secondary" size="sm" onClick={() => addToComparison(params)}>
              <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
              Analyze
            </Button>
          );
        },
      },
    ];
  }, [addToComparison]);

  if (initDone && topSources.length === 0) {
    return (
      <div className="flex w-full items-center justify-center">
        <NoMatchesWall text="traffic with the selected date range" />
      </div>
    );
  }

  return (
    <>
      <div className="mb-5">
        <Toggle
          labelClasses="!mt-px !font-semibold"
          enabled={includeInternalReferrer}
          setEnabled={setToggle}
          label={'Include internal referrers'}
          displayLabel={true}
        />
      </div>
      <ReactTable
        fetching={!initDone || fetching}
        ref={{ tableRef }}
        rowPadding={true}
        columns={columns}
        data={topSources}
        enableSorting={false}
        useVirtualization={false}
      />
    </>
  );
}
