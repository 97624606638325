import { useQuery } from '@tanstack/react-query';

import { adminAnnouncementQuery } from '/src/features/_global/queries';

import { AnnouncementBanner } from '/src/components/banners/AnnouncementBanner';

export function AdminAnnouncement() {
  const { data } = useQuery(adminAnnouncementQuery());

  if (!data?.announcement || data.announcement.enabled === false) return null;

  return <AnnouncementBanner severity={data.announcement.severity} content={data.announcement.htmlContent} />;
}
