import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';

import { SupportLinks } from '/src/support';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';

import InactivePNG from '@crazyegginc/hatch/dist/images/illustration-inactive.png';
import InactiveWebP from '@crazyegginc/hatch/dist/images/illustration-inactive.webp';

export function SubscriptionCancelledPaywall() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image webp={InactiveWebP} fallback={InactivePNG} width="128" />
      <Paywall.Title>Your Crazy Egg subscription is inactive</Paywall.Title>

      {canManageBilling ? (
        <>
          <Paywall.Body>Choose a plan and restart your subscription to access your reports.</Paywall.Body>
          <Button component={Link} to={MANAGE_SUBSCRIPTION_PATH}>
            Choose a plan
          </Button>
        </>
      ) : (
        <>
          <Paywall.Body>
            To continue using Crazy Egg, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to reactivate
            this subscription.
            <div className="mt-5">
              If you need help, email us at{' '}
              <a className="text-link" href={`mailto:${SupportLinks.general.email}`}>
                {SupportLinks.general.email}
              </a>
              .
            </div>
          </Paywall.Body>
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
            Contact account owner
          </Button>
        </>
      )}
    </Paywall>
  );
}
