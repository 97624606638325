import classNames from 'classnames';
import { IconButton } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';

import { HeaderInfoBox } from '/src/components/info-box/HeaderInfoBox';
import { ReactComponent as BalloonIcon } from '@crazyegginc/hatch/dist/images/icon-logo-ce-balloon.svg';

export function DashHeader({
  title = '',
  titleComponent = null,
  actionButton = null,
  search = null,
  className,
  feature = null,
}) {
  const { isSharing } = useAuthContext();

  return (
    <div
      className={classNames('flex flex-col border-b border-mystic-500 bg-white px-10', { [className]: !!className })}
      role="banner"
    >
      <div className="flex h-[85px] items-center">
        {isSharing && (
          <IconButton
            component="a"
            className="group mr-5 h-16 w-16 rounded bg-alice-blue-500 transition hover:bg-lima-500"
            href="https://crazyegg.com/"
            icon={<BalloonIcon className="h-11 w-6 fill-current text-lima-500 group-hover:text-white" />}
            label="link to crazy egg homepage"
          />
        )}
        <h1 className="text-header-1 my-4 flex-grow truncate leading-none" title={title}>
          {titleComponent || title}
        </h1>
        {feature && <HeaderInfoBox feature={feature} />}
        {search && search}
        {actionButton}
      </div>
    </div>
  );
}
