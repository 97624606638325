import { useFormikContext } from 'formik';
import { Select } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';

export function AutoPositionSelector() {
  const { readonly, spelledType } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const autoPositionOptions = [
    {
      value: 0,
      label: `Auto-position the ${spelledType} to avoid other elements on the page, selecting the best possible position.`,
    },
    {
      value: 1,
      label: `Auto-position the ${spelledType} to avoid other elements on the page, but don't show the ${spelledType} if an ideal position is not found.`,
    },
    {
      value: 2,
      label: `Always show the ${spelledType} on the selected position, do not try to auto-position.`,
    },
  ];

  return (
    <div className="w-80">
      <Select
        label="Auto positioning"
        labelClassName="font-semibold !mb-2"
        options={autoPositionOptions}
        value={
          values.autoPosition === false
            ? autoPositionOptions[2].value
            : values.autoPositionFallbackToShow
              ? autoPositionOptions[0].value
              : autoPositionOptions[1].value
        }
        onChange={(value) => {
          if (value === 0) {
            setFieldValue('autoPosition', true);
            setFieldValue('autoPositionFallbackToShow', true);
          } else if (value === 1) {
            setFieldValue('autoPosition', true);
            setFieldValue('autoPositionFallbackToShow', false);
          } else if (value === 2) {
            setFieldValue('autoPosition', false);
            setFieldValue('autoPositionFallbackToShow', true);
          }
        }}
        disabled={readonly}
      />
    </div>
  );
}
