import { useContext } from 'react';

import { VariantEditorWizard } from '/src/features/ab-testing/components/VariantEditorWizard';

// eslint-disable-next-line no-restricted-syntax
export default function AbTestVariantEditorPage({ context }) {
  const { abTestService } = useContext(context);
  const service = abTestService.children.get('variantEditor');

  return <VariantEditorWizard service={service} />;
}
