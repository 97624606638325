import { useState } from 'react';
import { Spinner, Button } from '@crazyegginc/hatch';

import { inflect } from '/src/utils/string';
import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function DeleteSnapshotsModal({ onConfirm, visits, numToDelete }) {
  const [submitting, setSubmitting] = useState(false);
  const modal = useModal();

  return (
    <Modal dialogClassName="!p-6">
      <Modal.Title>
        Delete {numToDelete} {inflect('snapshot', numToDelete)}?
      </Modal.Title>

      <div className="text-body-2">
        {visits
          ? `You've tracked ${visits.toLocaleString()} visits. If you delete ${
              numToDelete > 1 ? 'these snapshots' : 'this snapshot'
            }, you'll lose all of this data.`
          : `If you delete ${numToDelete > 1 ? 'these snapshots' : 'this snapshot'}, you'll lose all the data.`}
      </div>

      <Modal.Actions>
        <Button
          onClick={() => {
            setSubmitting(true);
            onConfirm?.();
          }}
          variant="warning"
        >
          {submitting ? (
            <div className="flex items-center">
              <Spinner className="mr-2.5 h-4 w-4 text-white" />
              Deleting snapshot...
            </div>
          ) : (
            'Yes, delete permanently'
          )}
        </Button>
        <Button onClick={() => modal.close()} variant="cancel">
          No, I still need my data
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
