import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Divider, SkeletonLine } from '@crazyegginc/hatch';

import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { DashboardPage } from '/src/components/Page';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { NoAbTestsWall } from '../components/paywalls/NoAbTestsWall';
import { AtomSearchInput } from '/src/components/search-input';
import { SkeletonCard } from '/src/features/ab-testing/components/ListCard';

import { usePermissions, useSite, useSelectedSite, useQueryParams, useDebounce, useSearchParams } from '/src/hooks';
import { getParam } from '/src/utils/location';
import { FEATURES, ALL } from '/src/features/_global/constants';
import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';
import { getFeatureName } from '/src/features/_global/utils';
import { isProduction } from '/src/utils';

import { CreateAbTestButton } from '../components/CreateAbTestButton';
import { AbTestListCard } from '../components/AbTestListCardV2';
import { AbTestStatusDropdown } from '../components/AbTestStatusDropdown';
import { AbTestSortOrder } from '../components/AbTestSortOrder';
import { abTestListQuery } from '../queries';

import { SnapshotAlerts } from '/src/features/_global/components/SnapshotAlerts';

const isProd = isProduction();

export function computedStatus(status) {
  if (!status) return undefined;
  if (status === ALL) return undefined;
  if (status === AB_TEST_STATUSES.PENDING) return AB_TEST_STATUSES.RUNNING;
  if ([AB_TEST_STATUSES.RUNNING, AB_TEST_STATUSES.STOPPED, AB_TEST_STATUSES.DRAFT].includes(status)) return status;
  return undefined;
}

const filterAtom = atom({ status: computedStatus(getParam('status')) || ALL, search: getParam('search') });

export function AbTestsDashboard() {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.AB_TESTING);
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const {
    sites,
    fetching: fetchingSites,
    selectedSite,
    selectSite,
  } = useSite({ enableAllSites: true, sitesQueryProps: { enabled: canViewDashboard } });

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.AB_TESTING} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.AB_TESTING)} />
      <DashHeader title={getFeatureName(FEATURES.AB_TESTING)} actionButton={<CreateAbTestButton />} />

      <SitePickerBar
        sites={sites}
        loading={fetchingSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        enableAllSites={true}
        addSnapshotOption={false}
        addSiteOption={canEditSites}
        pageDisplayName={`${getFeatureName(FEATURES.AB_TESTING)} dashboard`}
      />
      <SnapshotAlerts page={FEATURES.AB_TESTING} />
      <AbtestDashContent />
    </DashboardPage>
  );
}

function AbtestDashContent() {
  const perfMetricsStarted = useRef(false);
  const { setAll: setAllParams } = useQueryParams();
  const [filters, setFilters] = useAtom(filterAtom);
  const [searchParams] = useSearchParams();
  const { selectedSite } = useSelectedSite();
  const [order, setOrder] = useState(() => {
    const order = searchParams.get('order') || 'CREATED_AT';
    const sort = searchParams.get('sort') || 'DESC';

    return { order, sort };
  });

  const debouncedStatusValue = useDebounce(filters.status, 500);
  const debouncedSearchValue = useDebounce(filters.search, 500);

  useEffect(() => {
    setAllParams({ search: debouncedSearchValue, status: debouncedStatusValue });
  }, [debouncedSearchValue, debouncedStatusValue, setAllParams]);

  const { data, isFetching, error, hasNextPage, fetchNextPage, isInitialLoading } = useInfiniteQuery({
    ...abTestListQuery({
      site: selectedSite?.id,
      search: debouncedSearchValue ? debouncedSearchValue : undefined,
      status: debouncedStatusValue ? computedStatus(debouncedStatusValue) : undefined,
      order: order.order,
      sort: order.sort,
      limit: 10,
    }),
  });

  const abTests = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.abTestList?.list ?? [])], []) ?? [],
    [data],
  );

  const hasTests = abTests.length > 0;
  const hasData = !!data && (hasTests || debouncedSearchValue || computedStatus(debouncedStatusValue));

  useEffect(() => {
    if (window.CE2?.timing && isProd && !perfMetricsStarted.current) {
      window.CE2.timing.start('ab_tests_dashboard_v2');
      perfMetricsStarted.current = true;
    }
  }, []);

  useEffect(() => {
    if (window.CE2?.timing && isProd && perfMetricsStarted.current && (data || error) && !isFetching) {
      window.CE2.timing.stop('ab_tests_dashboard_v2');
      perfMetricsStarted.current = false;
    }
  }, [data, error, isFetching]);

  return (
    <>
      {isInitialLoading || hasData ? (
        <>
          <InstallationBanner />
          <div className="flex w-full min-w-[650px] flex-col items-center space-y-5 self-center px-10 pb-10">
            <div className="w-full min-w-[650px] pt-10">
              <AbTestFilters setOrder={setOrder} />
            </div>
            {isInitialLoading ? (
              <SkeletonCard />
            ) : hasTests ? (
              <VirtualizedItems
                tests={abTests}
                fetching={isFetching}
                hasNextPage={hasNextPage}
                loadNextPage={fetchNextPage}
              />
            ) : (
              <NoMatchesWall
                reset={() =>
                  setFilters({
                    status: null,
                    search: null,
                  })
                }
                text="matches, try clearing the filter"
                actionText="filter"
              />
            )}
          </div>
        </>
      ) : (
        <NoAbTestsWall />
      )}
    </>
  );
}

function VirtualizedItems({ tests, fetching, hasNextPage = false, loadNextPage }) {
  const parentRef = useRef(null);
  const items = useMemo(() => tests ?? [], [tests]);

  const getCount = () => {
    if (hasNextPage) return items.length + 1;
    return items.length;
  };

  const scrollMargin = parentRef.current?.offsetTop;

  const virtualizer = useWindowVirtualizer({
    count: getCount(),
    estimateSize: useCallback((index) => (index === 0 ? 255 : 275), []),
    overscan: 5,
    scrollMargin,
  });
  const virtualItems = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();
    if (!lastItem) return;
    if (lastItem.index === items.length && hasNextPage && !fetching) {
      loadNextPage();
    }
  }, [hasNextPage, fetching, virtualItems, items.length, loadNextPage]);

  return (
    <ul ref={parentRef} className="relative flex w-full flex-col space-y-5" style={{ height: `${totalSize}px` }}>
      {virtualItems.map((item) => {
        const style = {
          height: `${item.size}px`,
          transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
        };

        if (item.index > items.length - 1) {
          return (
            <li key="skeleton" className="absolute left-0 top-0 w-full" style={style}>
              <AbTestSkeleton />
            </li>
          );
        }

        const abTest = items[item.index];

        return (
          <li key={abTest.id} className="absolute left-0 top-0 w-full" style={style} data-testid="abTestCard">
            <AbTestListCard abTest={abTest} />
          </li>
        );
      })}
    </ul>
  );
}

function AbTestSkeleton() {
  return (
    <div className="flex h-[220px] w-full justify-between space-x-6 rounded border border-mystic-500 bg-white p-6 shadow">
      <div className="flex min-w-[140px] flex-col">
        <div className="flex flex-col rounded border border-mystic-500">
          <SkeletonLine width="138px" className="!h-[88px]" />
        </div>
      </div>
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex">
          <SkeletonLine width="30%" />
        </div>

        <Divider className="my-5 !border-t-2 !border-solitude-500" />

        <div className="flex flex-1 justify-between">
          <div className="mt-5 flex flex-1 flex-col space-y-2">
            <SkeletonLine width="30%" />
            <SkeletonLine width="30%" />
            <SkeletonLine width="30%" />
            <SkeletonLine width="30%" />
          </div>
          <div className="mt-5 flex space-x-12">
            <SkeletonLine width="50px" />
            <SkeletonLine width="50px" />
          </div>
        </div>
      </div>
    </div>
  );
}

function AbTestFilters({ setOrder }) {
  return (
    <div className="flex w-full items-center justify-between" data-testid="ab-tests-filters-bar">
      <div />
      <div className="flex space-x-[10px]">
        <AbTestSortOrder onSet={(newValue) => setOrder(newValue)} />
        <AbTestStatusDropdown atom={filterAtom} />
        <AtomSearchInput atom={filterAtom} />
      </div>
    </div>
  );
}
