import { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useMutation, useNotifications } from '/src/hooks';
import { abTestDetailQuery } from '/src/features/ab-testing/queries';
import { abTestUpdateVariantsMutation } from '/src/features/ab-testing/mutations';
import { VariantEditorWizard } from '/src/features/ab-testing/components/VariantEditorWizard';
import { variantMachine, parseExistingVariant } from '/src/features/ab-testing/machines/variant-editor';
import { generateVariantEditorPatches } from '/src/features/ab-testing/utils';
import { DEVICE_WIDTHS, VARIANT_STATUSES } from '/src/features/ab-testing/constants';

// eslint-disable-next-line
export default function AbTestEditVariantPage() {
  const [status, setStatus] = useState('IDLE');
  const location = useLocation();
  const { id, variantId } = useParams();
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { data, isFetching } = useQuery({
    ...abTestDetailQuery({ id: Number(id) }),
  });

  const existingVariants = data?.abTestDetail?.variants ?? [];
  const pageUrl = data?.abTestDetail?.pageUrl;
  const device = data?.abTestDetail?.devices.sort((a, b) => parseInt(DEVICE_WIDTHS[b]) - parseInt(DEVICE_WIDTHS[a]))[0];
  const variants = existingVariants.filter((v) => {
    return v.type === 'CONTROL' || v.id === parseInt(variantId);
  });

  const { mutate: mutateUpdateVariants } = useMutation(abTestUpdateVariantsMutation);

  const [state, send, service] = useMachine(variantMachine, {
    devTools: true,
  });

  useEffect(() => {
    let running = false;

    function handleUpdate(event) {
      if (event.data.reason !== 'CANCEL') {
        doMutate(event);
      } else {
        setStatus('CANCELLED');
      }
    }

    const doMutate = async (e) => {
      if (running) return;
      const variants = generateVariantEditorPatches({
        variants: e.data.variants.filter(
          (variant) => variant.type === 'variant' && variant.status === VARIANT_STATUSES.DIRTY,
        ),
      });
      mutateUpdateVariants(
        {
          id: Number(id),
          variants,
        },
        {
          onMutate: () => {
            running = true;
          },
          onSuccess: () => {
            running = false;
            notifications.success({ content: `Variant updated successfully.`, timeout: 3000 });
            setStatus('DONE');
          },
          onError: (error) => {
            running = false;
            notifications.error({ content: `Variant update failed.`, timeout: 3000, context: { error } });
            setStatus('IDLE');
          },
        },
      );
    };
    service.onDone(handleUpdate);

    return () => {
      service.off(handleUpdate);
    };
  }, [mutateUpdateVariants, id, notifications, navigate, service]);

  useEffect(() => {
    if (pageUrl && pageUrl !== state.context.url) {
      send({
        type: 'SET_VARIANTS',
        payload: variants.map(parseExistingVariant),
        createEmptyVariant: false,
        initialEditorPatch: location?.state?.editorPatch,
      });
      send({
        type: 'SET',
        value: {
          url: pageUrl,
          device,
        },
      });
    }
  }, [pageUrl, state.context.url, send, device, variants, location?.state?.editorPatch]);

  if (['CANCELLED', 'DONE'].includes(status)) {
    return <Navigate to={`/ab-tests/${id}`} replace={true} />;
  }

  if (!state.context.url || !state.context.device || isFetching) {
    return <LoaderBalloonScreen text={true} />;
  }

  return (
    <VariantEditorWizard isEditingVariant={true} service={service} backEnabled={false} nextText="Update variant" />
  );
}
