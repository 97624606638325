import { gql } from '@urql/core';

import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { ssoFeatureKeys } from './queries';

export const saveSsoSettingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({
    emailDomain,
    enabled,
    idpConfiguration,
    organizationName,
    required,
    spConfiguration,
    provisioningRules,
  }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SaveSsoSettings(
          $emailDomain: String!
          $enabled: Boolean!
          $idpConfiguration: SsoIdpConfigurationInput!
          $organizationName: String
          $required: Boolean!
          $spConfiguration: SsoSpConfigurationInput!
          $provisioningRules: [SsoProvisioningRuleInput!]
        ) {
          saveSsoSettings(
            emailDomain: $emailDomain
            enabled: $enabled
            idpConfiguration: $idpConfiguration
            organizationName: $organizationName
            required: $required
            spConfiguration: $spConfiguration
            provisioningRules: $provisioningRules
          ) {
            needsValidation
            validationUrl
            validationToken
            validationTimestamp
            ssoSettings {
              emailDomain
              enabled
              idpConfiguration {
                certificate
                entityId
                targetUrl
              }
              provisioningRules {
                action {
                  role
                  sites
                  specificSites
                  type
                }
                conditions {
                  attribute
                  type
                  values
                }
              }
              spConfiguration {
                acsUrl
                defaultEntityId
                entityId
                metadataUrl
                sloUrl
              }
              organizationName
              required
            }
          }
        }
      `,
      {
        emailDomain,
        enabled,
        idpConfiguration,
        organizationName,
        required,
        spConfiguration,
        provisioningRules,
      },
    ),
  onSettled: () => {
    queryClient.invalidateQueries({ ...ssoFeatureKeys.ssoSettings });
  },
});
