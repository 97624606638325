import { Editor } from '@crazyegginc/page-editor';
import '@crazyegginc/page-editor/dist/style.css';

export function PageEditor() {
  return (
    <div className="max-h-screen grow">
      <Editor />
    </div>
  );
}
