import { useEffect } from 'react';
import classNames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';

import { globalFeatureKeys } from '/src/features/_global/queries';
import { useMutation } from '/src/hooks';
import { trackGettingStartedMutation } from '../mutations';
import { Task } from './Task';
import { TRACK_GETTING_STARTED_EVENTS, USER_DATA_KEY_OPENED } from '../constants';
import { setUserDataMutation } from '/src/features/_global/mutations';

export function TaskList({ tasks, close, hasOpened }) {
  const { mutate: trackGettingStarted } = useMutation(trackGettingStartedMutation);
  const { mutate: mutateSetUserData } = useMutation(setUserDataMutation);
  const queryClient = useQueryClient();

  useEffect(() => {
    trackGettingStarted({
      event: TRACK_GETTING_STARTED_EVENTS.VIEW,
      slugs: tasks.map((t) => t.slug),
    });
  }, [trackGettingStarted, tasks]);

  useEffect(() => {
    if (!hasOpened) {
      mutateSetUserData(
        {
          name: USER_DATA_KEY_OPENED,
          value: '1',
        },
        { onSuccess: () => queryClient.invalidateQueries({ ...globalFeatureKeys.me }) },
      );
    }
  }, [mutateSetUserData, queryClient, hasOpened]);

  return (
    <div
      className={classNames(
        '-mr-3.5 mt-5 w-[334px] flex-1 overflow-auto',
        'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
      )}
    >
      <div className="w-[320px] space-y-1 pb-5">
        {tasks.map((task) => (
          <Task task={task} key={task.slug} close={close} />
        ))}
      </div>
    </div>
  );
}
