import { Modal } from '/src/contexts/modal';

export function NoDataModal() {
  return (
    <Modal dialogClassName="!items-center !px-4 !py-9">
      <Modal.Title>No data to export</Modal.Title>

      <div className="text-body-2 mb-9">There&#39;s currently no data to export for this snapshot</div>
      <Modal.Cancel />
    </Modal>
  );
}
