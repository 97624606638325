import { Fragment, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { UsageBar, CloseButton } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useLockBodyScroll } from '/src/hooks';
import { setUserDataMutation } from '/src/features/_global/mutations';
import { globalFeatureKeys } from '/src/features/_global/queries';
import { CAPABILITY_REASONS } from '/src/features/_global/constants';
import { TaskList } from './TaskList';
import { tasksData } from '../tasksData';
import { TASK_STATUSES, USER_DATA_KEY_CLOSED, USER_DATA_KEY_OPENED } from '../constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

const { QUOTA, SUBSCRIPTION_ISSUE, UPGRADE } = CAPABILITY_REASONS;

export function GettingStarted() {
  const { gettingStarted, currentUser } = useAuthContext();
  const queryClient = useQueryClient();
  const { mutate: mutateSetUserData } = useMutation(setUserDataMutation);

  const hasOpened = currentUser?.userData?.find((d) => d.key === USER_DATA_KEY_OPENED)?.value == '1';

  const tasks = useMemo(() => {
    function checkPermission(task) {
      if (
        task.permission &&
        (task.permission.allowed === true || [QUOTA, SUBSCRIPTION_ISSUE, UPGRADE].includes(task.permission.reason))
      ) {
        return true;
      }
      return false;
    }

    function blockTaskIfDependencyIncomplete(task) {
      const incompleteDependencies = task.dependencies?.reduce((acc, depSlug) => {
        let temp = mergedTasks.find((x) => x.slug === depSlug && x.status === TASK_STATUSES.INCOMPLETE);
        if (temp) {
          acc.push(temp);
        }
        return acc;
      }, []);

      if (incompleteDependencies?.length > 0) {
        let tooltipText;
        if (incompleteDependencies.length > 1) {
          tooltipText = incompleteDependencies.reduce(
            (acc, dep) =>
              checkPermission(dep)
                ? `${acc}<li>you have ${dep.referencedText}</li>`
                : `${acc}<li>someone has ${dep.referencedText}. You don't have permission to do this yourself, so you'll have to wait for someone else on your team.</li>`,
            '<div class="text-left p-1 max-w-[300px]">This task will unlock once:<ul class="list-disc list-outside ml-5">',
          );
          tooltipText += '</ul></div>';
        } else {
          tooltipText = `<div class="max-w-[200px]">${
            checkPermission(incompleteDependencies[0])
              ? `This task will unlock once you have ${incompleteDependencies[0].referencedText}.`
              : `This task will unlock once someone has ${incompleteDependencies[0].referencedText}. You don't have permission to do this yourself, so you'll have to wait for someone else on your team.`
          }</div>`;
        }

        return {
          ...task,
          status: TASK_STATUSES.BLOCKED,
          tooltipText,
        };
      }
      return task;
    }

    const mergedTasks = Object.entries(tasksData).map(([key, value]) => ({
      ...value,
      ...(gettingStarted.find((x) => x.slug === key) ?? {}),
    }));

    return mergedTasks.filter(checkPermission).map(blockTaskIfDependencyIncomplete);
  }, [gettingStarted]);

  const parsedUserData = useMemo(() => {
    let parsed;
    const value = currentUser?.userData?.find((d) => d.key === USER_DATA_KEY_CLOSED)?.value;
    if (!value) return {};
    try {
      parsed = JSON.parse(value);
    } catch {
      return {};
    }
    if (typeof parsed !== 'object') return {};
    return parsed;
  }, [currentUser]);

  let alreadyClosedWithNum = parsedUserData?.[currentUser.guestId] ?? false;
  if (alreadyClosedWithNum) {
    alreadyClosedWithNum = Number(alreadyClosedWithNum);
  }
  const total = tasks.length;
  const noValidTasks = total === 0;
  const completed = tasks.filter((t) => t.status === TASK_STATUSES.COMPLETED).length;
  const completionRate = Math.round((completed / total) * 100);
  const success = total === completed;
  const successDismissed = success && total <= alreadyClosedWithNum;

  const [closed, setClosed] = useState(successDismissed);

  function dismissSuccessState() {
    setClosed(true);
    mutateSetUserData(
      {
        name: USER_DATA_KEY_CLOSED,
        value: JSON.stringify({ ...parsedUserData, [currentUser.guestId]: String(total) }),
      },
      { onSuccess: () => queryClient.invalidateQueries({ ...globalFeatureKeys.me }) },
    );
  }

  if (noValidTasks) return null;
  if (success) {
    if (closed || !hasOpened) return null;
    return (
      <div className="relative mb-[30px] flex h-[85px] w-full items-center justify-center rounded-[5px] bg-white focus:outline-none">
        <CloseButton
          onClick={dismissSuccessState}
          buttonClass="!top-2.5 !right-2.5"
          iconClass="!text-black-pearl-500"
        />
        <div>
          <div className="text-header-4 mb-1.5">You&apos;re all set!</div>
          <UsageBar usage={100} size="lg" warningLimit={null} />
          <div className="mt-1 flex items-center text-xs">
            <div>
              {completed}/{total} Completed
            </div>
            <span className="ml-2.5">🎉</span>
          </div>
        </div>
      </div>
    );
  }

  let header = "Let's get started";
  if (alreadyClosedWithNum) {
    header = 'New tasks added!';
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="mb-[30px] flex h-[85px] w-full items-center justify-center rounded-[5px] bg-white focus:outline-none">
            <div className="text-left">
              <div className="text-header-4 mb-1.5">{header}</div>
              <UsageBar usage={completionRate} size="lg" warningLimit={null} classNameBar="!bg-dodger-blue-300" />
              <div className={classNames('mt-1 flex items-center justify-between text-xs')}>
                <div>
                  {completed}/{total} Completed
                </div>
                <ArrowIcon className="h-3 w-3 rotate-90 fill-current" />
              </div>
            </div>
          </Popover.Button>
          {open && (
            <>
              <LockBodyScroll />
              <Popover.Overlay className="fixed inset-0 z-[99998] bg-mako-500/60" />

              <Transition
                as={Fragment}
                enter="transition duration-300 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Popover.Panel
                  className={classNames(
                    'absolute left-full top-10 z-[99999] translate-x-3',
                    'flex max-h-[480px] w-[360px] flex-col px-5 pt-5',
                    'rounded-[5px] border border-mystic-500 bg-white shadow-md',
                    'after:absolute after:-left-2 after:top-3 after:h-4 after:w-4 after:rotate-45 after:border-b after:border-l after:border-mystic-500 after:bg-white',
                  )}
                  static
                  data-testid="getting-started-panel"
                >
                  {({ close }) => (
                    <>
                      <CloseButton onClick={close} />
                      <div className="shrink-0">
                        <div className="text-header-3 leading-none">Complete setup</div>
                        <div className="text-body-4 mt-5">Find out what&apos;s working and not on your website.</div>

                        <UsageBar usage={completionRate} size="lg" warningLimit={null} classNameBar="!h-1.5 mt-5" />
                        <div className="text-2xs">
                          {completed} / {total} Completed
                        </div>
                      </div>

                      <TaskList tasks={tasks} close={close} hasOpened={hasOpened} />
                    </>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </>
      )}
    </Popover>
  );
}

function LockBodyScroll() {
  useLockBodyScroll();
}
