import { useEffect } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Indicator } from '@crazyegginc/hatch';

import { useFilterSharing } from '../../account-sharing-filter-context';
import { inflect } from '/src/utils/string';
import { AccountUserShareTable } from './AccountUserShareTable';
import { PaginationControls } from '/src/components/PaginationControls';

import { sharedUrlsQuery } from '/src/features/team-and-sharing/queries';
import { SHARED_URL_STATUSES } from '/src/features/team-and-sharing/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function AccountUsersSharingContent({ isOpen, contentStatus, search, setNoData }) {
  const filters = useFilterSharing(contentStatus);
  const { setPage } = useFilterSharing();

  const { data, isFetching, isLoading } = useQuery({
    ...sharedUrlsQuery({
      page: parseInt(filters.page),
      limit: 10,
      searchTerm: search || '',
      order: {
        field: filters.order.field,
        sort: filters.order.sort,
      },
      status: contentStatus,
    }),
  });

  const dataSharedUrls = data?.sharedUrls?.sharedUrls ?? [];
  const meta = data?.sharedUrls?.meta ?? {};

  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [search, setPage]);

  useEffect(() => {
    if (data) {
      setNoData(!dataSharedUrls.length);
    }
  }, [data, setNoData, dataSharedUrls.length]);

  if (dataSharedUrls.length === 0) return null;

  return (
    <>
      <Disclosure.Button className="mb-3.75 flex w-full flex-wrap">
        <div className="flex w-full items-center justify-between pr-1">
          <span>
            <span className="text-header-3">
              {inflect(
                `${contentStatus === SHARED_URL_STATUSES.ENABLED ? 'Manage share link' : 'Inactive share link'}`,
                meta.total,
              )}
            </span>
            <span className="ml-2 text-md text-lynch-500">({meta.total})</span>
          </span>
          <span className="ml-3 flex items-center text-md">
            <Indicator className="mr-1.25 mt-0.5" type="expand" up={isOpen} />
            {!isOpen ? 'close' : 'open'}
          </span>
        </div>
        {contentStatus === SHARED_URL_STATUSES.ENABLED && !isOpen && (
          <div className="text-body-3 mt-2.5 flex w-full items-center rounded bg-mystic-500 bg-opacity-40 px-3.75 py-2.5 leading-none">
            <InfoIcon className="mr-2 h-4 w-4 fill-current" /> View and manage share links sent from your team
          </div>
        )}
      </Disclosure.Button>
      {!isOpen && (
        <Transition
          show={!isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Disclosure.Panel className="mb-10" static>
            <div className="mb-3.75">
              <AccountUserShareTable sharedUrls={dataSharedUrls} fetching={isLoading} shareStatus={contentStatus} />
            </div>
            {!isFetching && dataSharedUrls.length > 0 && (
              <PaginationControls
                currentPage={filters.page}
                perPage={meta.perPage}
                total={meta.total}
                setPage={(page) => filters.setPage(page)}
              />
            )}
          </Disclosure.Panel>
        </Transition>
      )}
    </>
  );
}
