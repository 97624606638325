import { useCallback, useMemo } from 'react';
import { getIn, useFormikContext } from 'formik';
import classNames from 'classnames';
import { Checkbox } from '@crazyegginc/hatch';

import { useSite } from '/src/hooks';

export function SitesSelector({ index, disabled = false }) {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const { sites, loadingSites } = useSite({ dontSelectSite: true });

  const basePath = `provisioningRules.[${index}].action`;

  const action = values.provisioningRules?.[index]?.action;
  const selectedSites = useMemo(() => action?.sites || [], [action?.sites]);

  const actionErrors = getIn(errors, `provisioningRules.[${index}].action`);
  const touchedSites = getIn(touched, `provisioningRules.[${index}].action.sites`);
  const sitesError = typeof actionErrors?.sites === 'string' && touchedSites ? actionErrors.sites : false;

  const toggleAllSites = useCallback(() => {
    const nextValue = !action.specificSites;
    setFieldValue(`${basePath}.sites`, []);
    setFieldValue(`${basePath}.specificSites`, nextValue);
  }, [action.specificSites, basePath, setFieldValue]);

  const selectSite = useCallback(
    (e) => {
      const selectedId = parseInt(e.target.getAttribute('data-id'));
      if (selectedSites.includes(selectedId)) {
        setFieldValue(
          `${basePath}.sites`,
          selectedSites.filter((siteId) => selectedId !== siteId),
        );
      } else {
        setFieldValue(`${basePath}.sites`, [...selectedSites, selectedId]);
      }
    },
    [basePath, selectedSites, setFieldValue],
  );

  return (
    <>
      <div className="flex flex-col">Select which sites to share:</div>
      {loadingSites ? (
        <p>Loading...</p>
      ) : (
        <>
          <Checkbox
            disabled={disabled}
            checked={!action.specificSites}
            onChange={toggleAllSites}
            id={`site.[${index}].all`}
            label="Share all current and future sites"
          />
          <div
            className={classNames('max-h-[125px]', {
              'w-[calc(100%+10px)] overflow-y-auto scrollbar-thin scrollbar-track-alice-blue-500 scrollbar-thumb-dodger-blue-500':
                sites?.length > 5,
            })}
          >
            {sites.map((site) => (
              <Checkbox
                disabled={disabled || !action.specificSites}
                checked={!action.specificSites || selectedSites.includes(site.id)}
                onChange={selectSite}
                id={`site.[${index}].${site.id}`}
                label={site.name}
                key={`Site-${site.id}`}
                data-id={site.id}
              />
            ))}
          </div>
          {sitesError ? <span className="text-body-2 text-carnation-500">{sitesError}</span> : null}
        </>
      )}
    </>
  );
}
