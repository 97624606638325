import { getUnixTime } from 'date-fns';

import { ERROR_STATUSES } from '/src/features/errors/constants';
import { Paywall } from '/src/components/Paywall';

import UfoPNG from '@crazyegginc/hatch/dist/images/illustration-ufo-empty.png';
import UfoWebP from '@crazyegginc/hatch/dist/images/illustration-ufo-empty.webp';
import CoffeePNG from '@crazyegginc/hatch/dist/images/illustration-coffee.png';
import CoffeeWebP from '@crazyegginc/hatch/dist/images/illustration-coffee.webp';

export function NoErrorsFoundWall({ status, convertedDateRange }) {
  const now = getUnixTime(new Date());
  if (status === ERROR_STATUSES.UNRESOLVED && convertedDateRange.startDate < now && convertedDateRange.endDate > now) {
    return (
      <Paywall>
        <Paywall.Image webp={CoffeeWebP} fallback={CoffeePNG} width="185px" height="160px" />
        <Paywall.Title>Nice work, no unresolved errors!</Paywall.Title>

        <Paywall.Body>Time to enjoy a nice cup of coffee.</Paywall.Body>
      </Paywall>
    );
  }

  return (
    <Paywall>
      <Paywall.Image webp={UfoWebP} fallback={UfoPNG} width="185px" height="160px" />
      <Paywall.Title>No {status.toLowerCase()} errors for the date range selected.</Paywall.Title>

      <Paywall.Body>Change the date range or choose a different status.</Paywall.Body>
    </Paywall>
  );
}

NoErrorsFoundWall.displayName = 'NoErrorsFoundWall';
