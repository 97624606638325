import { useEffect } from 'react';

import { useWizard, useAccountNumber } from '/src/hooks';
import { CodeBox } from '/src/components/CodeBox';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

import JoomlaPoster from '../assets/joomla-instructions-poster.jpg';
import JoomlaVideo from '../assets/joomla-instructions.mp4';

export function JoomlaInstructions() {
  const [accountNumber] = useAccountNumber();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/joomla/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Joomla',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Joomla!</WizardHeader>
      <WizardSubHeader>
        Follow the instructions below for a completely code-free install. Once you&#39;re prompted for your account
        number, enter <strong className="text-body-1">{accountNumber}</strong>.
      </WizardSubHeader>

      <div className="text-header-4 mb-2.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>Log in to your Joomla account, and open your Control Panel.</WizardList.Item>
        <WizardList.Item>
          Go to <strong className="text-body-1">Extensions &gt; Manage &gt; Install &gt; Install from URL</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Enter the Crazy Egg Joomla extension URL:{' '}
          <a
            href="https://github.com/CrazyEggInc/crazyegg-joomla-plugin/raw/master/crazyegg-joomla-plugin.zip"
            className="text-link mt-2.5"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://github.com/CrazyEggInc/crazyegg-joomla-plugin/raw/master/crazyegg-joomla-plugin.zip
          </a>
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Check and Install</strong>. Go to{' '}
          <strong className="text-body-1">Extensions &gt; Plugins</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Type <strong className="text-body-1">Crazy Egg</strong> into the search field. Find the Crazy Egg plugin in
          the list and open it.
        </WizardList.Item>
        <WizardList.Item>
          Change the plugin&#39;s status to <strong className="text-body-1">Enabled</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Enter your Crazy Egg account number, <strong className="text-body-1">{accountNumber}</strong> into the Account
          Number field.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Save</strong>.
        </WizardList.Item>
      </WizardList>

      <div className="my-5 rounded bg-white-lilac-500 px-6 py-5">
        <strong className="text-body-1">Note:</strong> If you are using a cache plugin on your site, you will need to
        clear it after you have installed your Crazy Egg code.
      </div>

      <CodeBox loading={false} code={accountNumber} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={JoomlaVideo} poster={JoomlaPoster} label="Joomla installation video" />
      </div>

      <a href={SupportLinks.install.joomla} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Joomla
      </a>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Joomla.
      </div>
    </div>
  );
}
