import * as yup from 'yup';

import { DEVICE_TYPES } from '/src/features/_global/constants';
import { REFERRER_VALUES } from '/src/features/addons/constants';
import { superLenientUrlRegex, searchParamRegex } from '/src/utils/regex';

export const audienceSchema = yup.object().shape({
  device: yup
    .array()
    .of(yup.string().oneOf([DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.MOBILE]))
    .min(1, 'Please select at least one device.'),
  site: yup.mixed().required('Please select a site.'),
  allPages: yup.boolean(),
  pages: yup
    .array()
    .when(['allPages', 'site'], (allPages, site, schema) => {
      return allPages === false
        ? yup.array().of(
            yup
              .string()
              .required('Please provide a URL.')
              .test('is valid url', 'Please provide a valid URL.', (value) => {
                if (!value) return false;
                return value.match(superLenientUrlRegex);
              })
              .matches(
                site.name,
                'The page does not match the site selected. Please select the correct site or fix the URL.',
              ),
          )
        : schema;
    })
    .min(1, 'Please provide at least one URL.'),
  referrerOption: yup.string().oneOf([...Object.values(REFERRER_VALUES)]),
  referrers: yup
    .array()
    .when('referrerOption', {
      is: (value) => value === REFERRER_VALUES.REFERRER,
      then: yup.array().of(
        yup
          .string()
          .required('Please provide a referrer.')
          .test(
            'is valid url or search param',
            'Please provide a valid referrer. See the accepted formats.',
            (value) => {
              if (!value) return false;
              return value.match(superLenientUrlRegex) || value.match(searchParamRegex);
            },
          ),
      ),
    })
    .min(1, 'Please provide at least one URL.'),
});

export const templateSchema = yup.object().shape({
  description: yup.string().required('Please provide a description for the template.'),
  category: yup.string(),
  thumbnail: yup.mixed(),
});
