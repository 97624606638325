import { Fragment } from 'react';
import classNames from 'classnames';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { Button, Tooltip } from '@crazyegginc/hatch';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

import { ConditionTypeInput } from './ConditionTypeInput';

export function RenderFieldValuesArray({ ruleIndex, conditionIndex, label = 'Add a value', ...props }) {
  const { errors, touched } = useFormikContext();
  const conditionErrors = getIn(errors, `provisioningRules.[${ruleIndex}].conditions.[${conditionIndex}]`);
  const conditionTouched = getIn(touched, `provisioningRules.[${ruleIndex}].conditions.[${conditionIndex}]`);

  function renderValueError(index) {
    return getIn(conditionTouched, `values.[${index}]`) ? getIn(conditionErrors, `values.[${index}]`) : null;
  }

  const canRemove = props.condition.values.length > 1;
  return (
    <FieldArray name={`${props.conditionKey}.values`}>
      {({ push, remove }) => (
        <div className="flex flex-row items-end gap-x-1.5">
          <div className="flex flex-col gap-y-1.5">
            {(props.condition.values || ['']).map((value, index) => (
              <Fragment key={`${props.conditionKey}.values-${index}`}>
                <div className="flex">
                  <ConditionTypeInput
                    {...props}
                    placeholder={label}
                    name={`${props.conditionKey}.values.[${index}]`}
                    className={classNames('!w-auto', { '!rounded-r-none': !props.disabled })}
                    error={renderValueError(index)}
                    onDragStart={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    draggable
                  />
                  {!props.disabled ? (
                    <Tooltip
                      tooltipContent="Remove this value"
                      placement="top"
                      show={props.condition.values.length > 1}
                    >
                      <Button
                        className={classNames(
                          'group !rounded-l-none border !border-l-0 border-mystic-500 !bg-white !px-2 !text-white',
                          {
                            'hover:border-carnation-500 hover:!bg-carnation-500': canRemove,
                          },
                        )}
                        onClick={() => remove(index)}
                        disabled={!canRemove}
                      >
                        <DeleteIcon
                          aria-label="remove this value"
                          className={classNames('h-3 w-3 fill-current', {
                            '!text-carnation-500 group-hover:!text-white': canRemove,
                            '!text-cadet-blue-500': !canRemove,
                          })}
                        />
                      </Button>
                    </Tooltip>
                  ) : null}
                </div>
              </Fragment>
            ))}
          </div>
          {!props.disabled ? (
            <Button
              onClick={() => push('')}
              className={classNames('!h-[35px] !w-[35px] !justify-center !p-0', {
                'mb-5': !!renderValueError(props.condition.values.length - 1),
              })}
            >
              <PlusIcon className="h-2.5 w-2.5 fill-current text-white" />
            </Button>
          ) : null}
        </div>
      )}
    </FieldArray>
  );
}
