import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';

import { usePermissions } from '/src/hooks';
import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { updateQuestionIds } from '/src/features/addons/surveys/common-functions';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { generateCTAEditorFormat } from '/src/features/addons/ctas/editor/editor-functions';
import { addonTemplateDetailQuery } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function UtilizeTemplate() {
  const { id } = useParams();
  const permissions = usePermissions();

  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;

  const { data, isFetching } = useQuery({
    ...addonTemplateDetailQuery({ id: parseInt(id) }),
    enabled: Boolean(id && (canCreateSurvey || canCreateCTA)),
  });

  const template = data?.addonTemplateDetail;
  const canCreate =
    (template?.type === ADDON_TYPES.SURVEY && canCreateSurvey) || (template?.type === ADDON_TYPES.CTA && canCreateCTA);

  useEffect(() => {
    if (window.Metrics && template && canCreate) {
      if (template.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Editor - Template used');
      } else if (template.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Editor - Template used');
      }
    }
  }, [template, canCreate]);

  if (!template && isFetching) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }

  if (!id || (template && !canCreate) || (!isFetching && !template)) {
    return <Navigate replace to="/addons" />;
  }

  const { type, content } = data.addonTemplateDetail;

  switch (type) {
    case ADDON_TYPES.SURVEY: {
      const surveyData = updateQuestionIds(generateSurveyEditorFormat({ content, addonTemplateId: id }));

      return <SurveyEditor initData={surveyData} />;
    }
    case ADDON_TYPES.CTA: {
      const ctaData = generateCTAEditorFormat({ content, addonTemplateId: id });
      return <CTAEditor initData={ctaData} />;
    }
    default:
      return <Navigate replace to="/addons" />;
  }
}
