import { v4 as uuid } from 'uuid';

export function getExternalPreviewLink(userId, siteId, token) {
  return `https://surveys.crazyegg.com/preview/${userId}/${siteId}#ce-survey-${token}`;
}

export function getExternalSurveyLink(id, userId) {
  if (id && userId) {
    return `https://surveys.crazyegg.com/s/${userId}/${id}`;
  }
  return undefined;
}

export function getPreviewShareLink(url, token, type) {
  return `${url}#ce-${type.toLowerCase()}-${token}`;
}

export function updateQuestionIds(data) {
  const surveyData = structuredClone(data);
  // change questions ids and update them in logic references too
  const mapping = {};
  surveyData.questions = surveyData.questions.map((q) => {
    const newId = uuid();
    mapping[q.id] = newId;
    return { ...q, id: newId };
  });
  surveyData.questions = surveyData.questions.map((q) => {
    return {
      ...q,
      logic: {
        ...q.logic,
        statements: q.logic.statements.map((s) => {
          return {
            ...s,
            actions: s.actions.map((a) => {
              return {
                ...a,
                editorAction: a.editorAction?.startsWith('id-')
                  ? `id-${mapping[a.editorAction.replace('id-', '')]}`
                  : a.editorAction,
              };
            }),
          };
        }),
      },
    };
  });

  return surveyData;
}
