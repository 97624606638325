import { assign, setup } from 'xstate5';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { normalizeUrl } from '/src/utils/url';
import { VARIANT_STATUSES, abTestTypes } from '/src/features/ab-testing/constants';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { DEVICE_WIDTHS } from '../constants';
import { isNumber } from '/src/utils';
import { findDeviceWidth, findDeviceID } from '/src/features/ab-testing/utils';

import {
  editPage,
  showPage,
  showPreview,
  // refreshPage,
  changeInteractiveMode,
  changeMoveSetting,
  undo,
  redo,
  getVariantColorName,
  changePopupsState,
} from '/src/services/web-editor';

export const createEmptyVariant = (position, editorPatch = {}) => ({
  id: uuid(),
  type: 'VARIANT',
  position,
  status: Object.keys(editorPatch).length > 0 ? VARIANT_STATUSES.DIRTY : VARIANT_STATUSES.PRISTINE,
  editorPatch,
});

export const parseExistingVariant = (variant, type = abTestTypes.PATCH) => {
  if (type === abTestTypes.SPLIT) {
    return {
      id: variant.id,
      type: variant.type.toUpperCase() === 'CONTROL' ? 'CONTROL' : 'VARIANT',
      position: variant.position,
      status:
        variant.type.toUpperCase() === 'CONTROL'
          ? 'STATIC'
          : isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0
            ? VARIANT_STATUSES.DIRTY
            : VARIANT_STATUSES.PRISTINE,
      redirectUrl: variant.redirectUrl,
      isRetired: variant.isRetired,
      weight: variant.weight,
    };
  }
  return {
    id: variant.id,
    type: variant.type.toUpperCase() === 'CONTROL' ? 'CONTROL' : 'VARIANT',
    position: variant.position,
    status:
      variant.type.toUpperCase() === 'CONTROL'
        ? 'STATIC'
        : isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0
          ? VARIANT_STATUSES.DIRTY
          : VARIANT_STATUSES.PRISTINE,
    editorPatch: typeof variant.editorPatch === 'string' ? JSON.parse(variant.editorPatch) : variant.editorPatch,
    isRetired: variant.isRetired,
    weight: variant.weight,
  };
};

export const controlVariant = {
  id: uuid(),
  type: 'CONTROL',
  position: 0,
  status: 'STATIC',
};

export const initialVariantContext = {
  url: null,
  device: null,
  payload: [controlVariant, createEmptyVariant(1)],
  selectedVariant: controlVariant,
  editor: {
    changes: [],
    interactive: false,
    device: DEVICE_TYPES.DESKTOP,
    deviceId: DEVICE_TYPES.DESKTOP,
    screenWidth: parseInt(DEVICE_WIDTHS[DEVICE_TYPES.DESKTOP], 10),
    undoEnabled: false,
    redoEnabled: false,
    moveSetting: 'reorder',
    loaded: false,
    hidePopups: false,
  },
  draft: null,
};

yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => !!value[f]),
  });
});

export const editorPatchSchema = yup.object().when('type', {
  is: 'VARIANT',
  then: yup
    .object()
    .shape({
      default: yup.array().min(1),
      style: yup.string(),
      script: yup.string(),
    })
    .required()
    .atLeastOneOf(['default', 'style', 'script']),
  otherwise: yup.object().nullable(true),
});

export const variantsSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string().required(),
      type: yup.string().oneOf(['control', 'variant', 'CONTROL', 'VARIANT']),
      position: yup.number().required(),
      status: yup.string().when(['id', 'type'], (id, type, schema) => {
        if (type.toUpperCase() === 'CONTROL') {
          return schema.oneOf([VARIANT_STATUSES.DIRTY, VARIANT_STATUSES.STATIC]).required();
        }
        if (isNumber(id)) {
          return schema.oneOf([VARIANT_STATUSES.DIRTY, VARIANT_STATUSES.PRISTINE, VARIANT_STATUSES.DELETED]).required();
        } else if (isNaN(id)) {
          return schema.oneOf([VARIANT_STATUSES.DIRTY]);
        }
      }),
      editorPatch: editorPatchSchema,
    }),
  )
  .min(1)
  .test('at-least-one-non-deleted-variant', 'You must have at least one active variant.', (value) => {
    return value.findIndex((v) => v.status !== VARIANT_STATUSES.DELETED) >= 0;
  })
  .required();

export const variantMachine = setup({
  guards: {
    payloadValid: ({ context }) => {
      try {
        // exclude pre-existing variants from the validation
        const variants = context.payload.filter((variant) => !['STATIC', 'DELETED'].includes(variant.status));
        variantsSchema.validateSync(variants);
        return true;
      } catch {
        return false;
      }
    },
  },
}).createMachine({
  id: 'variantEditor',
  initial: 'active',
  context: ({ input }) => ({
    ...initialVariantContext,
    ...input,
  }),
  output: ({ context }) => ({
    reason: context.reason || 'NO_REASON',
    variants: context.payload || [],
  }),
  states: {
    active: {
      on: {
        SET: {
          actions: assign({
            url: ({ event }) => event.value.url,
            matchingUrl: ({ event }) => event.value.matchingUrl || event.value.url,
            device: ({ event }) => event.value.device,
          }),
        },
        SET_URL: {
          actions: assign({
            url: ({ event }) => normalizeUrl(event.url, { appendSlash: false }),
          }),
        },
        BACK: {
          target: 'done',
          actions: assign({
            reason: 'BACK',
          }),
        },
        CANCEL: {
          target: 'done',
          actions: assign({
            reason: 'CANCEL',
          }),
        },
        NEXT: [
          // {
          //   target: 'done',
          //   guard: ({ context, check }) => context.abTest. check('payloadValid'),
          //   actions: assign({
          //     reason: 'NEXT',
          //   }),
          // },
          {
            target: 'done',
            guard: 'payloadValid',
            actions: assign({
              reason: 'NEXT',
            }),
          },
        ],
        ADD_VARIANT: {
          target: 'active',
          actions: [
            assign({
              payload: ({ context }) => [...context.payload, { ...createEmptyVariant(context.payload.length) }],
            }),
            // send(({ context }) => ({ type: 'SELECT_VARIANT', variant: context.payload.at(-1) })),
          ],
        },
        REMOVE_VARIANT: {
          target: 'active',
          actions: [
            // actions.pure(({ context, event }              //   if (context.selectedVariant.id === event.value) {
            //     const currentIdx = context.payload.findIndex((variant) => event.value == variant.id);
            //     return send((context) => ({ type: 'SELECT_VARIANT', variant: context.payload.at(currentIdx - 1) }));
            //   }
            // }),
            assign({
              payload: ({ context, event }) => {
                // if the variant has been saved, mark it for deletion
                const variantIdx = context.payload.findIndex((v) => v.id === event.value);
                const variant = context.payload[variantIdx];

                if (isNumber(variant.id)) {
                  return [
                    ...context.payload.slice(0, variantIdx),
                    {
                      ...variant,
                      status: 'DELETED',
                    },
                    ...context.payload.slice(variantIdx + 1),
                  ];
                } else {
                  return [...context.payload.filter((variant) => variant.id !== event.value)];
                }
              },
            }),
          ],
        },
        SELECT_VARIANT: {
          target: 'active',
          actions: [
            assign({
              selectedVariant: ({ event }) => {
                return {
                  ...event.variant,
                };
              },
              editor: ({ context, event }) => ({
                ...context.editor,
                loaded: false,
                undoEnabled: (event.variant?.editorPatch?.default ?? []).length > 0,
                redoEnabled: false,
              }),
            }),
            ({ context }) => {
              if (
                context.selectedVariant.type.toUpperCase() === 'VARIANT' &&
                context.selectedVariant.status !== 'STATIC'
              ) {
                editPage(
                  context.selectedVariant.id,
                  context.selectedVariant.editorPatch,
                  getVariantColorName(context.selectedVariant),
                  context.editor.moveSetting,
                  context.editor.screenWidth,
                );
                changeInteractiveMode(false);
              } else if (
                context.selectedVariant.type.toUpperCase() === 'VARIANT' &&
                context.selectedVariant.status === 'STATIC'
              ) {
                showPreview({
                  editorPatch: context.selectedVariant.editorPatch,
                  editionId: context.selectedVariant.id,
                  editorColor: getVariantColorName(context.selectedVariant),
                  screenWidth: context.editor.screenWidth,
                });
                changeInteractiveMode(false);
              } else if (context.selectedVariant.type.toUpperCase() === 'CONTROL') {
                showPage();
              }
            },
          ],
        },
        LOAD_VARIANT: {
          guard: ({ context }) => !context.editor.loaded,
          actions: [
            assign({
              editor: ({ context }) => ({
                ...context.editor,
                loaded: true,
                interactive: context.selectedVariant.type.toUpperCase() === 'CONTROL',
              }),
            }),
            ({ context }) => {
              if (
                context.selectedVariant.type.toUpperCase() === 'VARIANT' &&
                context.selectedVariant.status !== 'STATIC'
              ) {
                editPage(
                  context.selectedVariant.id,
                  context.selectedVariant.editorPatch,
                  getVariantColorName(context.selectedVariant),
                  context.editor.moveSetting,
                  context.editor.screenWidth,
                );
                changeInteractiveMode(false);
              } else if (
                context.selectedVariant.type.toUpperCase() === 'VARIANT' &&
                context.selectedVariant.status === 'STATIC'
              ) {
                showPreview({
                  editorPatch: context.selectedVariant.editorPatch,
                  editionId: context.selectedVariant.id,
                  editorColor: getVariantColorName(context.selectedVariant),
                  screenWidth: context.editor.screenWidth,
                });
                changeInteractiveMode(false);
              } else if (
                context.selectedVariant.type.toUpperCase() === 'CONTROL' ||
                context.selectedVariant.status === 'STATIC'
              ) {
                showPage();
              }
            },
          ],
        },
        SET_EDITOR: {
          target: 'active',
          actions: [
            assign({
              editor: ({ context, event }) => ({
                ...context.editor,
                [event.key]: event.value,
              }),
            }),
          ],
        },
        UNDO_CHANGE: {
          target: 'active',
          guard: () => {
            return true;
          },
          actions: [() => undo(), assign({ editor: ({ context }) => ({ ...context.editor, redoEnabled: true }) })],
        },
        REDO_CHANGE: {
          target: 'active',
          guard: () => {
            return true;
          },
          actions: [() => redo(), assign({ editor: ({ context }) => ({ ...context.editor, undoEnabled: true }) })],
        },
        TOGGLE_INTERACTIVE: {
          actions: [
            assign({
              editor: ({ context }) => ({
                ...context.editor,
                interactive: !context.editor.interactive,
              }),
            }),
            ({ context }) => {
              changeInteractiveMode(context.editor.interactive);
            },
          ],
        },
        CHANGE_POPUPS_STATE: {
          actions: [
            assign({
              editor: ({ context }) => ({
                ...context.editor,
                hidePopups: !context.editor.hidePopups,
              }),
            }),
            ({ context }) => {
              changePopupsState(context.editor.hidePopups);
            },
          ],
        },
        SYNC_MUTATIONS: {
          actions: [
            assign({
              editor: ({ context, event }) => ({
                ...context.editor,
                editorPatch: event.editorPatch,
                changes: event.editorPatch?.default ?? [],
                undoEnabled: (event.editorPatch?.default ?? []).length > 0,
                redoEnabled: false, // latest changes override redo state
              }),
              payload: ({ context, event }) =>
                context.payload.map((variant) => {
                  if (variant.id !== context.selectedVariant.id) return variant;

                  return {
                    ...variant,
                    status: VARIANT_STATUSES.DIRTY,
                    editorPatch: event.editorPatch,
                  };
                }),
              selectedVariant: ({ context, event }) => ({
                ...context.selectedVariant,
                status: VARIANT_STATUSES.DIRTY,
                editorPatch: event.editorPatch,
              }),
            }),
          ],
        },
        SET_MOVE_SETTING: {
          actions: [
            ({ event }) => changeMoveSetting(event.value),
            assign({
              editor: ({ context, event }) => ({
                ...context.editor,
                moveSetting: event.value,
              }),
            }),
          ],
        },
        SET_DEVICE: {
          actions: [
            assign({
              editor: ({ context, event }) => ({
                ...context.editor,
                device: event.value,
                deviceId: findDeviceID(event.value),
                loaded: false,
                screenWidth: findDeviceWidth(event.value),
              }),
            }),
          ],
        },
        SET_SCREEN_WIDTH: {
          actions: [
            assign({
              editor: ({ context, event }) => ({
                ...context.editor,
                deviceId: event.id,
                screenWidth: event.value,
              }),
            }),
          ],
        },
        SET_VARIANTS: {
          actions: [
            assign({
              payload: ({ event }) =>
                [
                  ...event.payload,
                  event?.createEmptyVariant === true
                    ? createEmptyVariant(event.payload.length, event.initialEditorPatch)
                    : null,
                ].filter(Boolean),
            }),
            // send(({ context }) => ({ type: 'SELECT_VARIANT', variant: context.payload.at(-1) })),
          ],
        },
      },
    },
    done: {
      type: 'final',
      data: ({ context }) => ({
        variants: [...context.payload],
        url: context.url,
        reason: context.reason,
      }),
    },
  },
});
