import { useFormikContext } from 'formik';
import { RG } from '@crazyegginc/hatch';
import { SURVEY_DISPLAY_TYPES } from '/src/features/addons/constants';

import { useEditorContext } from '../../editor-context';
import { WebP } from '../../../../components/WebP';
import { TypeCard } from '../../common/editor/TypeCard';

import PopupPNG from '@crazyegginc/hatch/dist/images/illustration-survey-popup.png';
import PopupWebP from '@crazyegginc/hatch/dist/images/illustration-survey-popup.webp';
import ExternalPNG from '@crazyegginc/hatch/dist/images/illustration-survey-external.png';
import ExternalWebP from '@crazyegginc/hatch/dist/images/illustration-survey-external.webp';

export function TypeSelector() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  const disabled = readonly;

  return (
    <div className="mb-10 ml-10">
      <RG
        value={values.displayType}
        onChange={(newValue) => setFieldValue('displayType', newValue)}
        disabled={disabled}
      >
        <RG.Label className="mb-1 block !text-lg">Type</RG.Label>
        <div className="flex w-full space-x-2.5">
          <TypeCard
            type={SURVEY_DISPLAY_TYPES.POPUP}
            icon={<WebP webp={PopupWebP} fallback={PopupPNG} width="66px" height="54px" />}
            label="Popup"
            disabled={disabled}
          />
          <TypeCard
            type={SURVEY_DISPLAY_TYPES.EXTERNAL}
            icon={<WebP webp={ExternalWebP} fallback={ExternalPNG} width="70px" height="54px" />}
            label="External link"
            disabled={disabled}
          />
        </div>
      </RG>
    </div>
  );
}
