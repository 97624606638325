import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { useMutation } from '/src/hooks';
import { setUserDataMutation } from '/src/features/_global/mutations';

import { userDataQuery } from '/src/features/_global/queries';
import {
  DEFAULT_DAYS_VALUE,
  DEFAULT_VISITS_VALUE,
  DEFAULT_DAYS_STRING,
  DEFAULT_VISITS_STRING,
} from '/src/features/snapshots/constants';

export function SaveDefaultEndOption() {
  const { values } = useFormikContext();
  const setUserData = useMutation(setUserDataMutation);
  const [saved, setSaved] = useState(false);

  const { data } = useQuery(userDataQuery());

  const defaultDays = Number(
    data?.me?.userData?.find((x) => x.key === DEFAULT_DAYS_STRING)?.value ?? DEFAULT_DAYS_VALUE,
  );
  const defaultVisits = Number(
    data?.me?.userData?.find((x) => x.key === DEFAULT_VISITS_STRING)?.value ?? DEFAULT_VISITS_VALUE,
  );

  const currentDays = +values.ends.days;
  const currentVisits = +values.ends.visits;

  function saveDefaults() {
    if (defaultDays !== currentDays) {
      setUserData.mutate(
        {
          name: DEFAULT_DAYS_STRING,
          value: '' + currentDays,
        },
        {
          onSuccess: () => {
            setSaved(true);
            setTimeout(() => setSaved(false), 5000);
          },
        },
      );
    }
    if (defaultVisits !== currentVisits) {
      setUserData.mutate(
        {
          name: DEFAULT_VISITS_STRING,
          value: '' + currentVisits,
        },
        {
          onSuccess: () => {
            setSaved(true);
            setTimeout(() => setSaved(false), 5000);
          },
        },
      );
    }
  }

  if (!data) return null;

  return (
    <>
      {saved ? (
        <div className="mt-1.5">
          <span className="text-body-2 text-lima-500">Saved as default!</span>
        </div>
      ) : (
        <>
          {defaultDays !== currentDays || defaultVisits !== currentVisits ? (
            <div className="mt-1.5">
              <button className="text-link" onClick={saveDefaults}>
                Save as default
              </button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
