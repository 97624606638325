import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { RG, Checkbox, RadioGroupOption } from '@crazyegginc/hatch';

import { ContainedCheckBoxWithIcon } from '/src/components/ContainedCheckBoxWithIcon';

import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as TabletIcon } from '@crazyegginc/hatch/dist/images/icon-tablet-outline.svg';
import { ReactComponent as MobileIcon } from '@crazyegginc/hatch/dist/images/icon-mobile-outline.svg';

import { ReactComponent as DesktopTrafficIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-desktop.svg';
import { ReactComponent as TabletTrafficIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-tablet.svg';
import { ReactComponent as MobileTrafficIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-mobile.svg';

import { DEVICE_TYPES } from '/src/features/_global/constants';

const { DESKTOP, TABLET, MOBILE } = DEVICE_TYPES;

export function DeviceTracking({ isEditing }) {
  const { values, handleChange } = useFormikContext();
  const isPageCameraSnapshot = values.pageCameraSnapshot;

  return (
    <div className="mt-10">
      <div className="flex">
        <div className="text-header-2 mr-6">Device tracking</div>
        <div className="flex items-center">
          <Checkbox
            label="Use custom tracking to consolidate traffic into one Snapshot."
            id="device-tracking-checkbox"
            name="deviceTracking.custom"
            checked={values.deviceTracking.custom}
            onChange={handleChange}
            disabled={isEditing || isPageCameraSnapshot}
          />
        </div>
      </div>
      {values.deviceTracking.custom ? <CustomSourceSelector isEditing={isEditing} /> : <BasicDeviceSelector />}
    </div>
  );
}

function CustomSourceSelector({ isEditing }) {
  const formik = useFormikContext();

  const selectedOption = formik.values.deviceTracking.customTracking.screenshot;
  const trafficError = formik.errors?.deviceTracking?.customTracking?.traffic;
  const isPageCameraSnapshot = formik.values.pageCameraSnapshot;

  const disabled = isEditing || isPageCameraSnapshot;

  return (
    <>
      <div className="mt-5">
        <RG
          value={selectedOption}
          onChange={(newValue) => formik.setFieldValue('deviceTracking.customTracking.screenshot', newValue, true)}
          disabled={isEditing}
        >
          <RG.Label className="text-body-2 mb-2.5 block">Take a screenshot of the:</RG.Label>
          <div className="flex space-x-3">
            <ContainedRadioOptionWithIcon
              value={DESKTOP}
              Icon={DesktopIcon}
              label="Desktop view"
              selectedOption={selectedOption}
              disabled={disabled}
            />
            <ContainedRadioOptionWithIcon
              value={TABLET}
              Icon={TabletIcon}
              label="Tablet view"
              selectedOption={selectedOption}
              disabled={disabled}
            />
            <ContainedRadioOptionWithIcon
              value={MOBILE}
              Icon={MobileIcon}
              label="Mobile view"
              selectedOption={selectedOption}
              disabled={disabled}
            />
          </div>
        </RG>
      </div>
      <div className="mt-5">
        <div className="text-body-2 mb-2.5 block">Track traffic from:</div>
        <div className="flex flex-wrap space-x-3">
          <ContainedCheckBoxWithIcon
            Icon={DesktopTrafficIcon}
            handleChange={formik.handleChange}
            name="deviceTracking.customTracking.traffic"
            value={DESKTOP}
            label="Desktop visitors"
            checked={formik.values.deviceTracking.customTracking.traffic.includes(DESKTOP)}
            error={!!trafficError}
          />
          <ContainedCheckBoxWithIcon
            Icon={TabletTrafficIcon}
            handleChange={formik.handleChange}
            name="deviceTracking.customTracking.traffic"
            value={TABLET}
            label="Tablet visitors"
            checked={formik.values.deviceTracking.customTracking.traffic.includes(TABLET)}
            error={!!trafficError}
          />
          <ContainedCheckBoxWithIcon
            Icon={MobileTrafficIcon}
            handleChange={formik.handleChange}
            name="deviceTracking.customTracking.traffic"
            value={MOBILE}
            label="Mobile visitors"
            checked={formik.values.deviceTracking.customTracking.traffic.includes(MOBILE)}
            error={!!trafficError}
          />
        </div>
        {trafficError && (
          <div className="relative h-5">
            <div className="text-body-4 absolute whitespace-nowrap text-carnation-500">{trafficError}</div>
          </div>
        )}
        {!trafficError && (
          <div className="text-body-5 mt-2.5 leading-normal">
            We will capture a Snapshot by taking a screenshot of your webpage just as a {selectedOption.toLowerCase()}{' '}
            visitor sees it. We will then track{' '}
            {formik.values.deviceTracking.customTracking.traffic.length < 3 ? 'only ' : ''}
            {formik.values.deviceTracking.customTracking.traffic.map(
              (x, index, array) =>
                `${index === 0 ? '' : index === array.length - 1 ? ' and ' : ', '}${x.toLowerCase()}`,
            )}{' '}
            traffic.
          </div>
        )}
      </div>
    </>
  );
}

function ContainedRadioOptionWithIcon({ selectedOption, value, Icon, label, disabled }) {
  return (
    <div
      className={classNames('flex h-12 w-48 items-center rounded border px-2.5', {
        'border-mystic-500': selectedOption !== value,
        'border-dodger-blue-500': selectedOption === value && !disabled,
        'border-lynch-500': selectedOption === value && disabled,
      })}
    >
      <RadioGroupOption
        labelClass="!text-xs"
        option={{
          value: value,
          label: (
            <div
              className={classNames('flex items-center', {
                'text-dodger-blue-500': selectedOption === value && !disabled,
                'text-lynch-500': selectedOption !== value || disabled,
              })}
            >
              <Icon
                className={classNames('mr-3 h-6 w-6 fill-current', {
                  'text-dodger-blue-500': selectedOption === value && !disabled,
                  'text-lynch-500': selectedOption === value && disabled,
                  'text-cadet-blue-500': selectedOption !== value,
                })}
              />
              {label}
            </div>
          ),
        }}
        disabled={disabled}
      />
    </div>
  );
}

function BasicDeviceSelector() {
  const formik = useFormikContext();

  const deviceError = formik.errors?.deviceTracking?.devices;
  return (
    <>
      <div className="mt-5 flex flex-wrap space-x-3">
        <ContainedCheckBoxWithIcon
          Icon={DesktopIcon}
          handleChange={formik.handleChange}
          name="deviceTracking.devices"
          value={DESKTOP}
          label="Desktop"
          checked={formik.values.deviceTracking.devices.includes(DESKTOP)}
          error={!!deviceError}
        />
        <ContainedCheckBoxWithIcon
          Icon={TabletIcon}
          handleChange={formik.handleChange}
          name="deviceTracking.devices"
          value={TABLET}
          label="Tablet"
          checked={formik.values.deviceTracking.devices.includes(TABLET)}
          error={!!deviceError}
        />
        <ContainedCheckBoxWithIcon
          Icon={MobileIcon}
          handleChange={formik.handleChange}
          name="deviceTracking.devices"
          value={MOBILE}
          label="Mobile"
          checked={formik.values.deviceTracking.devices.includes(MOBILE)}
          error={!!deviceError}
        />
      </div>
      {deviceError && (
        <div className="relative h-5">
          <div className="text-body-4 absolute whitespace-nowrap text-carnation-500">{deviceError}</div>
        </div>
      )}
      <div className="text-body-5 mt-2.5 leading-normal">
        Separate snapshots will be created for each device selected. Settings below apply to each snapshot.
      </div>
    </>
  );
}
