import { gql } from '@urql/core';

import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const trackGettingStartedMutation = ({ client }) => ({
  mutationFn: ({ event, slugs }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation TrackGettingStarted($event: TrackGettingStartedEvents!, $slugs: [String]) {
          trackGettingStarted(event: $event, slugs: $slugs)
        }
      `,
      {
        event,
        slugs,
      },
    ),
});
