import { Outlet } from 'react-router-dom';

import { WizardProvider } from '/src/components/wizard/legacy/wizard-context';
import { WizardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { WizardLogo } from '/src/components/wizard/legacy/wizard-ui';

export function InstallationWizard() {
  return (
    <WizardProvider>
      <WizardPage>
        <SEO title="Install Script" />

        <WizardLogo />
        <Outlet />
      </WizardPage>
    </WizardProvider>
  );
}
