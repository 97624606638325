import React from 'react';
import { Provider as JotaiProvider } from 'jotai';

import { ClientProvider } from '../graphql/client';
import { NotificationProvider } from './notifications';

export function AppProvider({ client, children }) {
  return (
    <JotaiProvider>
      <ClientProvider initialClient={client}>
        <NotificationProvider>{children}</NotificationProvider>
      </ClientProvider>
    </JotaiProvider>
  );
}
