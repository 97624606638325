/* 
  see below link on role names and explanations
  https://www.notion.so/crazyegg/Capabilities-Query-Permissions-Quotas-formerly-referred-to-as-me-query-8b592f00a23945cb83b97b144dd086a4?pvs=4#c13eaf56ab4b4eb891f1c4a251ad9486
*/
export const ACCOUNT_USER_ROLES = {
  ACCOUNT_HOLDER: 'ACCOUNT_HOLDER',
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  READ_ONLY: 'READ_ONLY',
};

export const START_URLS_SHARING_TIMESTAMP = 1643688000; // 01 FEBRUARY 2022 04:00AM GMT

export const SHARABLE_RESOURCE_TYPES = {
  SNAPSHOT: 'SNAPSHOT',
  RECORDING: 'RECORDING',
  ABTEST: 'ABTEST',
  ERROR: 'ERROR',
  SURVEY: 'SURVEY',
  CTA: 'CTA',
  GOAL: 'GOAL',
};

export const SHAREABLE_ROUTES = [
  /\/recordings\/(.*)\/player/g,
  /\/errors\/(.*)/g,
  /\/addons\/(.*)/g,
  /\/ab-tests\/(.*)/g,
  /\/goals\/(.*)/g,
];

export const SHARED_URLS_ORDER = {
  SHARED_BY_USER_NAME: 'SHARED_BY_USER_NAME',
  CREATED_AT: 'CREATED_AT',
  ITEM_TYPE: 'ITEM_TYPE',
};

export const SHARED_URL_STATUSES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export const ACCOUNT_USERS_ORDER = {
  LAST_ACTIVE: 'LAST_ACTIVE',
  EMAIL: 'EMAIL',
};
