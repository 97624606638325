import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { useNeedsUpgradeToAccessFeature, useModal } from '/src/hooks';
import { LockedRecordingModal } from '/src/features/recordings/components/dashboard/modals/LockedRecordingModal';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as BlockIcon } from '@crazyegginc/hatch/dist/images/icon-block.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-circle-filled.svg';
import { ReactComponent as LockIcon } from '@crazyegginc/hatch/dist/images/icon-lock-filled.svg';

export function PlayButton({
  hashedId,
  valid,
  virtual,
  deletedAt,
  canUpgradeToView,
  watched,
  sizeClass = 'h-[35px] w-[35px]',
}) {
  const modal = useModal();

  const needsUpgradeToAccess = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);
  if (needsUpgradeToAccess) {
    return (
      <button
        type="button"
        className="flex-shrink-0 focus-visible:outline-black"
        onClick={() => modal.show(<RecordingsUpgradeModal />)}
      >
        <PlayIcon
          className={classNames('fill-current', sizeClass, {
            'text-dodger-blue-500 hover:text-dodger-blue-700': !watched,
            'text-cadet-blue-500 hover:text-lynch-500': watched,
          })}
          aria-label="play recording of this error"
        />
      </button>
    );
  }

  let tooltipText;
  if (deletedAt) tooltipText = 'The associated video was deleted.';
  else if (hashedId === null || virtual) tooltipText = 'This session was not recorded.';
  else if (!valid)
    tooltipText = "The visitor didn't perform any recordable actions, such as clicking, so there is no video to show.";

  if (tooltipText) {
    return (
      <div className="relative flex-shrink-0">
        <Tooltip tooltipContent={<div className="max-w-[160px]">{tooltipText}</div>}>
          <PlayIcon
            className={classNames('fill-current text-cadet-blue-500', sizeClass)}
            aria-label="no recording for this error"
          />
          <div className={classNames('absolute left-0 top-0 rounded-full p-1', sizeClass)}>
            <BlockIcon className="h-full w-full fill-current text-mystic-500" />
          </div>
        </Tooltip>
      </div>
    );
  }
  if (canUpgradeToView) {
    return (
      <button
        onClick={() => modal.show(<LockedRecordingModal />)}
        type="button"
        className="focus-visible:outline-black"
      >
        <LockIcon
          className={classNames('flex-shrink-0 fill-current text-cadet-blue-500', sizeClass)}
          aria-label="play recording of this error"
        />
      </button>
    );
  }
  return (
    <Link
      className="flex-shrink-0 focus-visible:outline-black"
      to={{
        pathname: `/recordings/${hashedId}/player`,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlayIcon
        className={classNames('fill-current', sizeClass, {
          'text-dodger-blue-500 hover:text-dodger-blue-700': !watched,
          'text-cadet-blue-500 hover:text-lynch-500': watched,
        })}
        aria-label="play recording of this error"
      />
    </Link>
  );
}
