import { createContext, useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Honeybadger from '@honeybadger-io/js';
import crazyAi from '@crazyegginc/crazy-ai';

import { isE2E } from '/src/utils';

export const CrazyAIContext = createContext();
export const CrazyAIQueryContext = createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: false,
      staleTime: 30 * 60 * 1000,
      queryFn: () => new Promise(() => {}),
    },
  },
});

export function CrazyAIProvider({ children }) {
  const e2e = isE2E();
  const [conn, setConn] = useState(null);

  useEffect(() => {
    let canceled = false;
    let c;
    async function connect() {
      c = await crazyAi.connection({
        version: 'v2',
        env: 'prod',
        // When running locally, add a token here to connect to the crazy-ai prod instance. See https://github.com/CrazyEggInc/crazy-ai#v2-api
        //jwtToken: '',
        onError: (error) => {
          Honeybadger.notify('CrazyAI error', {
            context: {
              error,
            },
            name: 'CrazyAI error - new',
          });
        },
      });
      if (!canceled && c) {
        setConn(c);
      } else {
        c?.close();
      }
    }
    if (!conn && !canceled && !e2e) {
      connect();
    }

    return () => {
      canceled = true;
    };
  }, [conn, e2e]);

  return (
    <CrazyAIContext.Provider value={{ ai: conn }}>
      <QueryClientProvider client={queryClient} context={CrazyAIQueryContext}>
        {children}
      </QueryClientProvider>
    </CrazyAIContext.Provider>
  );
}
