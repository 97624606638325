import { Button } from '@crazyegginc/hatch';
import { channelsURL } from '/src/utils';

import { ReactComponent as NotifyIcon } from '@crazyegginc/hatch/dist/images/icon-megaphone-filled.svg';

export function ChannelsLink({ feature, featureID }) {
  return (
    <Button
      variant="secondary"
      component="a"
      href={channelsURL(feature, featureID)}
      target="_blank"
      rel="noopener noreferrer"
      leftIcon={<NotifyIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />}
    >
      Get Notified
    </Button>
  );
}
