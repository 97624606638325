import { useState, useEffect } from 'react';
import { Button, Input } from '@crazyegginc/hatch';

import { useAuthContext, useSelectedSite } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { copyToClipboard } from '/src/utils';
import { getPreviewShareLink, getExternalPreviewLink } from '/src/features/addons/surveys/common-functions';

export function SharePreviewModal({ url, token, type, external }) {
  const [copied, setCopied] = useState(false);
  const { currentAccount } = useAuthContext();
  const { selectedSite } = useSelectedSite();

  const previewUrl = external
    ? getExternalPreviewLink(currentAccount.id, selectedSite.id, token)
    : getPreviewShareLink(url, token, type);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const copyUrl = () => {
    copyToClipboard(previewUrl);
    setCopied(true);
  };

  return (
    <Modal dialogClassName="!w-[535px]">
      <Modal.Title>Share this preview</Modal.Title>

      <div className="relative">
        <Input className="!h-[50px] !pr-24" value={previewUrl} readOnly />
        <Button variant="secondary" className="absolute right-2 top-1/2 -translate-y-1/2" onClick={() => copyUrl()}>
          {copied ? 'copied' : 'copy'}
        </Button>
      </div>
    </Modal>
  );
}
