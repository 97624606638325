import { CloseButton } from '@crazyegginc/hatch';

import { useWizard, useLockBodyScroll } from '/src/hooks';

export function WizardModal({ children }) {
  const wizard = useWizard();
  useLockBodyScroll();

  return (
    <div
      className="fixed left-0 top-0 z-[99] flex min-h-screen w-screen flex-col items-center overflow-hidden bg-white transition-all duration-500 ease-linear"
      data-testid="wizard_modal"
    >
      <div className="max-h-screen w-screen animate-scalein overflow-y-auto px-[15%] pb-[100px] pt-[50px] last:mb-[100px]">
        <CloseButton
          onClick={wizard.closeModal}
          label="Close"
          iconClass="!w-5 !h-5"
          buttonClass="!w-6 !h-6 !top-11 !right-11"
        />
        {children}
      </div>
    </div>
  );
}
