import classNames from 'classnames';
import { Checkbox } from '@crazyegginc/hatch';

export function ContainedCheckBoxWithIcon({
  Icon,
  handleChange,
  name,
  value,
  checked,
  disabled = false,
  error,
  label,
}) {
  return (
    <div
      className={classNames('flex h-12 w-48 items-center rounded border px-2.5', {
        'border-mystic-500': !error && !checked,
        'border-dodger-blue-500': !error && checked,
        'border-carnation-500': error,
      })}
    >
      <Checkbox
        name={name}
        value={value}
        onChange={handleChange}
        checked={checked}
        id={value}
        disabled={disabled}
        label={
          <div
            className={classNames('flex items-center text-xs', {
              'text-dodger-blue-500': checked,
              'text-lynch-500': !checked,
            })}
          >
            {
              <Icon
                className={classNames('mr-3 h-6 w-6  fill-current', {
                  'text-dodger-blue-500': checked,
                  'text-cadet-blue-500': !checked,
                })}
              />
            }
            {label}
          </div>
        }
      />
    </div>
  );
}
