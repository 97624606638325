import React from 'react';

import { RenderNetworkError } from './RenderNetworkError';
import { RenderSuccess } from './RenderSuccess';
import { RenderError } from './RenderError';

export function RenderResult({ data, error, originalUrl, origin }) {
  if (error) {
    return <RenderNetworkError error={error} originalUrl={originalUrl} />;
  }

  if (!data || !originalUrl) {
    return null;
  }

  const installPath = !origin ? '/install' : origin.replace('/check-script', '');

  if (data?.installed || !data?.error?.name) {
    return <RenderSuccess data={data} originalUrl={originalUrl} finalUrl={data.finalUrl} installPath={installPath} />;
  }

  if (data?.error?.name) {
    return <RenderError data={data} installPath={installPath} />;
  }
}
