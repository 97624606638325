import { Link } from 'react-router-dom';

import { useAuthContext } from '/src/hooks';
import { formatDate } from '/src/utils/date';
import { abbreviatedIntervalType } from '/src/utils/string';
import { SUBSCRIPTION_STATES, PLAN_PLATFORMS, PLAN_TYPES, INTERVAL_TYPES } from '/src/features/_global/constants';

const { CANCELING, CANCELED, EXPIRING, EXPIRED } = SUBSCRIPTION_STATES;

function IntervalInfo({ isMonthly, validTwoMonthsFree, id }) {
  if (isMonthly) {
    return (
      validTwoMonthsFree && (
        <>
          <p>Get two months free each year by choosing annual billing.</p>
          <Link to={`/subscriptions/${id}/edit/plans/yearly`} className="text-link">
            Change to annual billing
          </Link>
        </>
      )
    );
  }

  return <p>Annual subscription</p>;
}

function IsMonthlyOrYearly(name, intervalType) {
  if (intervalType === INTERVAL_TYPES.MONTHS && !name.match(/monthly/i)) {
    return '(Monthly)';
  }
  if (intervalType === INTERVAL_TYPES.YEARS && !name.match(/yearly/i)) {
    return '(Yearly)';
  }
  return '';
}

function ActiveDetail({ currentPlanTitle }) {
  const { subscription, currentUser } = useAuthContext();
  const {
    id,
    renewOn,
    intervalType,
    nextPaymentPlan,
    nextAmount,
    nextIntervalType,
    paymentPlan: { id: paymentPlanId },
  } = currentUser.subscriptions.snapshots;

  const isShopifyUser = subscription.plan.platform === PLAN_PLATFORMS.SHOPIFY;
  const isTrial = subscription.plan.type === PLAN_TYPES.TRIAL;
  const isMonthly = intervalType === INTERVAL_TYPES.MONTHS;

  const noTwoMonthsFreePlans = [197, 190, 208, 210];
  const isTwoMonthsFree = !noTwoMonthsFreePlans.includes(paymentPlanId);

  const validTwoMonthsFree =
    isTwoMonthsFree && !isShopifyUser && ![PLAN_TYPES.FREEMIUM, PLAN_TYPES.ZARAZ].includes(subscription.plan.type);

  return (
    <>
      <div className="text-body-4">
        {!isTrial && <IntervalInfo isMonthly={isMonthly} validTwoMonthsFree={validTwoMonthsFree} id={id} />}
      </div>
      <div className="text-body-4">
        {!isShopifyUser && nextPaymentPlan !== null && (
          <>
            Your plan will change from{' '}
            <strong>
              {currentPlanTitle} {IsMonthlyOrYearly(currentPlanTitle, intervalType)}
            </strong>{' '}
            to{' '}
            <strong>
              {nextPaymentPlan.name} {IsMonthlyOrYearly(nextPaymentPlan.name, nextIntervalType)}
            </strong>{' '}
            for ${nextAmount.toLocaleString()}/{abbreviatedIntervalType(nextIntervalType)} on{' '}
            {renewOn ? formatDate(renewOn) : 'N/A'}.
          </>
        )}
        {[PLAN_TYPES.FREEMIUM, PLAN_TYPES.ZARAZ].includes(subscription.plan.type) && !nextPaymentPlan && (
          <p>Upgrade your plan to access more Crazy Egg features! </p>
        )}
      </div>
    </>
  );
}

function InactiveDetail() {
  const { currentUser, subscription } = useAuthContext();

  const {
    paymentPlan: { id },
  } = currentUser.subscriptions.snapshots;

  const isInExpiredGrace = subscription.state === EXPIRING && ![181, 197].includes(id);

  if (isInExpiredGrace) {
    return (
      <p className="text-error">
        Your Account will be deactivated on {formatDate(subscription.endDate)} due to a problem with payment.
        <br />
        <Link to="/account/billing?billingModal=true" className="text-link">
          Please update your payment info
        </Link>
        .
      </p>
    );
  }

  if ([CANCELING, CANCELED].includes(subscription.state)) {
    return (
      <p className="text-error">
        Subscription canceled.{' '}
        {subscription.state === CANCELING
          ? `Your subscription will not auto-renew once it expires on ${formatDate(subscription.endDate)}.`
          : ''}
      </p>
    );
  }

  if ([EXPIRING, EXPIRED].includes(subscription.state)) {
    return (
      <p className="text-error">
        Your subscription {subscription.state === EXPIRED ? `expired` : `expires`} on {formatDate(subscription.endDate)}
        .
      </p>
    );
  }
}

export function PlanSubscriptionInfo({ currentPlanTitle }) {
  const { subscription } = useAuthContext();

  return (
    <>
      {[CANCELING, CANCELED, EXPIRING, EXPIRED].includes(subscription.state) ? (
        <InactiveDetail />
      ) : (
        <ActiveDetail currentPlanTitle={currentPlanTitle} />
      )}
    </>
  );
}
