import { useEffect, useState, useCallback } from 'react';
import { Input, CloseButton } from '@crazyegginc/hatch';
import { useAtom } from 'jotai';
import classNames from 'classnames';

import { useDebounce, usePrevious } from '/src/hooks';

import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';

export function SearchInput({
  search,
  initialValue,
  placeholder,
  effectOnSearch,
  className = '',
  wrapperClassName = '',
  inputSize = 'lg',
  ariaLabel,
}) {
  const [searchValue, setSearchValue] = useState(initialValue || '');
  const debouncedValue = useDebounce(searchValue, 500);
  const prevDebouncedValue = usePrevious(debouncedValue);

  const clearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  useEffect(() => {
    if ((prevDebouncedValue && prevDebouncedValue !== debouncedValue) || debouncedValue !== '') {
      const searchValue = debouncedValue.trim();
      effectOnSearch?.(searchValue);
      search(searchValue);
    }
  }, [debouncedValue, prevDebouncedValue, search, effectOnSearch]);

  useEffect(() => {
    window.addEventListener('filters:reset', () => clearInput());
    return () => window.removeEventListener('filters:reset', () => clearInput());
  }, [clearInput]);

  return (
    <div className={classNames('w-[240px]', wrapperClassName)}>
      <Input
        aria-label={ariaLabel}
        value={searchValue}
        size={inputSize}
        className={className}
        leftIcon={<SearchIcon className="h-4 w-4 fill-current text-dodger-blue-500" />}
        rightIcon={
          searchValue.length > 0 ? (
            <CloseButton
              buttonClass="!top-0 !-right-1 !mr-0 !my-0 !relative"
              iconClass="!text-dodger-blue-500"
              label="clear search"
              onClick={() => {
                clearInput();
              }}
            />
          ) : null
        }
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
}

export function AtomSearchInput({ atom, placeholder = 'Search for URL or Name' }) {
  const [filters, setFilters] = useAtom(atom);

  const searchValue = filters.search || '';

  const clearInput = useCallback(() => {
    setFilters({ ...filters, search: null });
  }, [filters, setFilters]);

  useEffect(() => {
    window.addEventListener('filters:reset', () => clearInput());
    return () => window.removeEventListener('filters:reset', () => clearInput());
  }, [clearInput]);

  return (
    <div className="w-[240px]">
      <Input
        value={searchValue}
        size="lg"
        className="!border-solitude-500 !bg-solitude-500 !font-semibold !placeholder-dodger-blue-500/50"
        leftIcon={<SearchIcon className="h-4 w-4 fill-current text-dodger-blue-500" />}
        rightIcon={
          searchValue.length > 0 ? (
            <CloseButton
              buttonClass="!top-0 !-right-1 !mr-0 !my-0 !relative"
              iconClass="!text-dodger-blue-500"
              label="clear search"
              onClick={() => {
                clearInput();
              }}
            />
          ) : null
        }
        onChange={(e) => {
          const value = e.target.value;
          setFilters({ ...filters, search: value.trim() === '' ? null : value });
        }}
        placeholder={placeholder}
      />
    </div>
  );
}
