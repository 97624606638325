import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ThumbPlaceholder from '/src/assets/images/thumb_placer.jpg';

export function LazyImg({
  placeholder = ThumbPlaceholder,
  Placeholder = null,
  src,
  alt = null,
  width = 0,
  height = 0,
  className = '',
  ...props
}) {
  const [hasError, setHasError] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    let observer;
    const didCancel = false;

    if (imageRef?.current && imgSrc === null) {
      if (typeof IntersectionObserver !== 'undefined' && !observer) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              // when image is visible in the viewport
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImgSrc(src);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          },
        );
        observer.observe(imageRef.current);
      } else {
        // old browser
        setImgSrc(src);
      }
    }

    return () => observer?.disconnect?.();
  }, [imgSrc, Placeholder, src]);

  if ((hasError || !src) && typeof Placeholder === 'function') {
    return (
      <Placeholder
        width={width}
        height={height}
        aria-label={alt}
        {...props}
        className={classNames('select-none rounded border-0 outline-none', className)}
      />
    );
  }

  return (
    <img
      className={classNames('select-none rounded border-0 outline-none', className)}
      src={imgSrc || placeholder}
      alt={alt}
      ref={imageRef}
      width={width}
      height={height}
      onError={() => {
        setHasError(true);
        setImgSrc(placeholder);
      }}
      {...props}
    />
  );
}
