import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RG, RadioGroup, RadioGroupOption } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { SectionHeader, SectionPanel, SectionContent } from '../../common/basic-ui';
import { ColorAccessibility } from '../../common/editor/ColorAccessibility';
import { ColorSelector_v2 } from '../../common/editor/ColorSelector_v2';
import { PopupPositionSelector } from '../../common/editor/PopupPositionSelector';
import { ClosableBehavior } from '../../common/editor/ClosableBehavior';
import { ButtonPositionSelector } from './ButtonPositionSelector';
import { AutoPositionSelector } from '../../common/editor/AutoPositionSelector';
import { ThemeSelector } from '../../common/editor/ThemeSelector';
import { RemoveBranding } from '../../common/editor/RemoveBranding';
import { CTA_TYPES } from '/src/features/addons/constants';

import { ReactComponent as AppearanceIcon } from '@crazyegginc/hatch/dist/images/icon-appearance.svg';

const darkerOptions = [
  '#0A1C2E',
  '#225B3F',
  '#0A5C6A',
  '#122B6E',
  '#461E73',
  '#83244F',
  '#671111',
  '#663110',
  '#114379',
];

const lighterOptions = [
  '#FFFFFF',
  '#34D495',
  '#00CFF3',
  '#78ACFF',
  '#B197F8',
  '#FF7CB8',
  '#FF8080',
  '#FF8A41',
  '#FFEA00',
];

export function Appearance() {
  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <AppearanceIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
          Appearance
        </div>
      </SectionHeader>
      <SectionContent>
        <AppearanceTypes />
      </SectionContent>
    </SectionPanel>
  );
}

function AppearanceTypes() {
  const { values } = useFormikContext();

  switch (values.displayType) {
    case CTA_TYPES.BUTTON:
      return <ButtonAppearance />;
    case CTA_TYPES.BAR:
      return <BarAppearance />;
    case CTA_TYPES.POPUP:
      return <PopupAppearance />;
    default:
      return null;
  }
}

function ButtonAppearance() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const currentOptionList = values.theme.dark ? lighterOptions : darkerOptions;
    const newOptionList = values.theme.dark ? darkerOptions : lighterOptions;
    const index = currentOptionList.findIndex((color) => color === values.theme.accent);
    if (index !== -1) {
      setFieldValue('theme.accent', newOptionList[index]);
    }
  }, [values.theme.dark, values.theme.accent, setFieldValue]);

  return (
    <>
      <div className="flex items-center space-x-8">
        <ThemeSelector />
        <ColorSelector_v2
          defaultOptions={values.theme.dark ? darkerOptions : lighterOptions}
          label="Button color"
          fieldPath="theme.accent"
        />
      </div>
      <ColorAccessibility />
      <RG
        value={values.button.vertical}
        onChange={(newValue) => setFieldValue('button.vertical', newValue)}
        className="!self-start"
        disabled={readonly}
      >
        <RG.Label className="text-body-1 mb-2 block">Button orientation</RG.Label>
        <div className="flex h-9 items-center justify-center space-x-2.5 rounded-full bg-white-lilac-500 px-2.5">
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: true, label: 'Vertical' }}
            disabled={readonly}
          />
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: false, label: 'Horizontal' }}
            disabled={readonly}
          />
        </div>
      </RG>
      <ButtonPositionSelector />
      <AutoPositionSelector />
    </>
  );
}

function BarAppearance() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const oneOptionList = values.theme.dark ? lighterOptions : darkerOptions;
    const secondOptionList = values.theme.dark ? darkerOptions : lighterOptions;
    const barIndex = oneOptionList.findIndex((color) => color === values.theme.bar);
    const buttonIndex = secondOptionList.findIndex((color) => color === values.theme.button);
    if (barIndex !== -1) {
      setFieldValue('theme.bar', secondOptionList[barIndex]);
    }
    if (buttonIndex !== -1) {
      setFieldValue('theme.button', oneOptionList[buttonIndex]);
    }
  }, [values.theme.dark, values.theme.bar, values.theme.button, setFieldValue]);

  return (
    <>
      <div className="flex items-center space-x-8">
        <ThemeSelector />
        <ColorSelector_v2
          defaultOptions={values.theme.dark ? darkerOptions : lighterOptions}
          label="Bar color"
          fieldPath="theme.bar"
        />
        <ColorSelector_v2
          defaultOptions={values.theme.dark ? lighterOptions : darkerOptions}
          label="Button color"
          fieldPath="theme.button"
          canEditSiteColors={false}
        />
      </div>
      <ColorAccessibility />
      <RG
        value={values.bar.position}
        onChange={(newValue) => setFieldValue('bar.position', newValue)}
        className="!self-start"
        disabled={readonly}
      >
        <RG.Label className="text-body-1 mb-2 block">Position on page</RG.Label>
        <div className="flex h-9 items-center justify-center space-x-2.5 rounded-full bg-white-lilac-500 px-2.5">
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: 'top', label: 'Top' }}
            disabled={readonly}
          />
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: 'bottom', label: 'Bottom' }}
            disabled={readonly}
          />
        </div>
      </RG>
      <RadioGroup
        size="sm"
        options={[
          { value: true, label: 'Make space on my page for my Crazy Egg Bar so it does not cover anything' },
          { value: false, label: "Don't move anything on my page, place my Crazy Egg Bar on top of my content" },
        ]}
        value={values.bar.padding}
        onChange={async (value) => await setFieldValue('bar.padding', value)}
        disabled={readonly}
      />
      {values.bar.position === 'top' && (
        <RadioGroup
          label="Scrolling"
          groupLabelClass="!mb-2"
          optionWrapperClass="!h-auto !items-start mb-1"
          size="sm"
          options={[
            {
              value: 'partial',
              label: (
                <div className="mt-0.5 min-w-[500px] leading-tight">
                  Always show my Crazy Egg Bar even when someone scrolls the page on Desktop/Tablet. Hide my Crazy Egg
                  Bar on Mobile if the visitor scrolls down.
                </div>
              ),
            },
            {
              value: true,
              label: (
                <div className="mt-0.5 min-w-[500px] leading-tight">
                  Always show my Crazy Egg Bar even when someone scrolls the page on both Desktop/Tablet and Mobile.
                </div>
              ),
            },
            {
              value: false,
              label: (
                <div className="mt-0.5 min-w-[500px] leading-tight">
                  Let my Crazy Egg Bar scroll off the screen in Desktop/Tablet and Mobile if the visitor scrolls down.
                </div>
              ),
            },
          ]}
          value={values.bar.fixed}
          onChange={async (value) => await setFieldValue('bar.fixed', value)}
          disabled={readonly}
        />
      )}
      <div>
        <div className="text-body-1 mb-px block">Close behavior</div>
        <ClosableBehavior label="Let visitors close my Bar." />
      </div>
    </>
  );
}

function PopupAppearance() {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const currentOptionList = values.theme.dark ? lighterOptions : darkerOptions;
    const newOptionList = values.theme.dark ? darkerOptions : lighterOptions;
    const index = currentOptionList.findIndex((color) => color === values.theme.accent);
    if (index !== -1) {
      setFieldValue('theme.accent', newOptionList[index]);
    }
  }, [values.theme.dark, values.theme.accent, setFieldValue]);

  return (
    <>
      <div className="flex items-center space-x-8">
        <ThemeSelector />
        <ColorSelector_v2
          defaultOptions={values.theme.dark ? darkerOptions : lighterOptions}
          fieldPath="theme.accent"
        />
      </div>
      <ColorAccessibility />
      <PopupPositionSelector fieldPath="popup.position" />
      <div>
        <div className="text-body-1 mb-px block">Close behavior</div>
        <ClosableBehavior label="Let visitors close my Popup." />
      </div>
      <RemoveBranding />
    </>
  );
}
