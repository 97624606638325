import { gql } from '@urql/core';
import { produce } from 'immer';

import { addonsFeatureKeys } from './queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';
import { ADDON_TYPES } from '/src/features/addons/constants';

export const addonEnabledMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, siteId, status }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonEnabled($id: String!, $siteId: Int!, $status: Boolean!) {
          addonEnabled(id: $id, siteId: $siteId, status: $status)
        }
      `,
      {
        id,
        siteId,
        status,
      },
    ),
  onMutate: async (variables) => {
    await Promise.all([
      queryClient.cancelQueries({ queryKey: addonsFeatureKeys.addonList._def }),
      queryClient.cancelQueries({ ...addonsFeatureKeys.addonDetail({ id: variables.id }) }),
    ]);

    const previousStatus =
      queryClient.getQueryData(addonsFeatureKeys.addonDetail({ id: variables.id }).queryKey)?.addonDetail.active ??
      queryClient
        .getQueriesData({ queryKey: addonsFeatureKeys.addonList._def })
        .flatMap(([, data]) => data?.pages.flatMap((page) => page.addonList.list))
        .filter(Boolean)
        .find((addon) => addon.id === variables.id)?.active ??
      false;

    queryClient.setQueryData(addonsFeatureKeys.addonDetail({ id: variables.id }).queryKey, (old) =>
      old
        ? produce(old, (draft) => {
            draft.addonDetail.active = variables.status;
          })
        : null,
    );

    queryClient.setQueriesData({ queryKey: addonsFeatureKeys.addonList._def }, (old) =>
      old
        ? produce(old, (draft) => {
            for (let i = 0; i < old.pages.length; i++) {
              const j = old.pages[i].addonList.list.findIndex((addon) => addon.id === variables.id);
              if (j > -1) {
                draft.pages[i].addonList.list[j].active = variables.status;
              }
            }
          })
        : null,
    );

    return { previousStatus };
  },
  onError: (_, variables, context) => {
    queryClient.setQueryData(addonsFeatureKeys.addonDetail({ id: variables.id }).queryKey, (old) =>
      old
        ? produce(old, (draft) => {
            draft.addonDetail.active = context.previousStatus;
          })
        : null,
    );

    queryClient.setQueriesData({ queryKey: addonsFeatureKeys.addonList._def }, (old) =>
      old
        ? produce(old, (draft) => {
            for (let i = 0; i < old.pages.length; i++) {
              const j = old.pages[i].addonList.list.findIndex((addon) => addon.id === variables.id);
              if (j > -1) {
                draft.pages[i].addonList.list[j].active = context.previousStatus;
              }
            }
          })
        : null,
    );
  },
  onSuccess: (_, variables) => {
    if (variables.status) {
      queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
    }
  },
  onSettled: (_, __, variables) => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonList._def });
    queryClient.invalidateQueries({ ...addonsFeatureKeys.addonDetail({ id: variables.id }) });
  },
});

export const addonCreateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ siteId, addon }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonCreate($siteId: Int!, $addon: AddonCreateParams!) {
          addonCreate(siteId: $siteId, addon: $addon) {
            id
            previewToken
          }
        }
      `,
      {
        siteId,
        addon,
      },
    ),
  onSuccess: (_, variables) => {
    if (window.Metrics) {
      if (variables.addon.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Created');
      } else if (variables.addon.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Created');
      }
    }
    queryClient.resetQueries({ queryKey: addonsFeatureKeys.addonList._def });
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const addonEditMutation = ({ client, queryClient }) => ({
  mutationFn: ({ addon }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonEdit($addon: AddonEditParams!) {
          addonEdit(addon: $addon) {
            id
            previewToken
            status
          }
        }
      `,
      {
        addon,
      },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonList._def });
    if (data.addonEdit.status === 'SUCCESS') {
      queryClient.invalidateQueries({ ...addonsFeatureKeys.addonDetail({ id: data.addonEdit.id }) });
      queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.surveyResultSummary._def });
      queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.surveyResponseList._def });
    }
  },
});

export const addonDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, siteId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonDelete($id: String!, $siteId: Int!) {
          addonDelete(id: $id, siteId: $siteId)
        }
      `,
      {
        id,
        siteId,
      },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ ...addonsFeatureKeys.addonDetail({ id: data.addonDelete.id }) });
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
    return queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonList._def });
  },
});

export const surveyResponseDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ responseGroupIds, surveyId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SurveyResponseDelete($responseGroupIds: [ID!], $surveyId: ID!) {
          surveyResponseDelete(responseGroupIds: $responseGroupIds, surveyId: $surveyId)
        }
      `,
      {
        responseGroupIds,
        surveyId,
      },
    ),
  onSuccess: (_, variables) => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.surveyResponses._def });
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonList._def });
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.surveyResponseList._def });
    queryClient.invalidateQueries({ ...addonsFeatureKeys.addonDetail({ id: variables.surveyId }) });
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.surveyResultSummary._def });
  },
});
export const addonTemplateCreateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ template, thumbnail }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonTemplateCreate($template: AddonTemplateCreateParams!, $thumbnail: Upload) {
          addonTemplateCreate(template: $template, thumbnail: $thumbnail) {
            __typename
            content
            createdAt
            formatVersion
            id
            name
            type
          }
        }
      `,
      {
        template,
        thumbnail,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonTemplatesList.queryKey });
  },
});

export const addonTemplateEditMutation = ({ client, queryClient }) => ({
  mutationFn: ({ template, thumbnail }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonTemplateEdit($template: AddonTemplateEditParams!, $thumbnail: Upload) {
          addonTemplateEdit(template: $template, thumbnail: $thumbnail) {
            __typename
            content
            createdAt
            formatVersion
            id
            name
            type
          }
        }
      `,
      {
        template,
        thumbnail,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonTemplatesList.queryKey });
  },
});

export const addonTemplateDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddonTemplateDelete($id: Int!) {
          addonTemplateDelete(id: $id) {
            id
          }
        }
      `,
      {
        id,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: addonsFeatureKeys.addonTemplatesList.queryKey });
  },
});

export const imageUploadMutation = ({ client }) => ({
  mutationFn: ({ image, siteId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ImageUpload($image: Upload, $siteId: Int!) {
          imageUpload(image: $image, siteId: $siteId) {
            filename
            id
            publicUrl
          }
        }
      `,
      {
        image,
        siteId,
      },
    ),
});

export const imageDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, siteId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ImageDelete($id: ID!, $siteId: Int!) {
          imageDelete(id: $id, siteId: $siteId)
        }
      `,
      {
        id,
        siteId,
      },
    ),
  onSuccess: (_, variables) => {
    queryClient.invalidateQueries({ ...addonsFeatureKeys.imageDetail({ id: variables.id, siteId: variables.siteId }) });
  },
});
