import { useState } from 'react';
import { Button, TextArea } from '@crazyegginc/hatch';
import { Modal } from '/src/contexts/modal';

import { useModal, useMutation, usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

import { abTestUpdateVariantDescriptionMutation } from '/src/features/ab-testing/mutations';

export function VariantDescriptionModal({ abTest, variant, onSave = null }) {
  const modal = useModal();
  const [status, setStatus] = useState('idle');
  const [description, setDescription] = useState(variant.description || '');
  const permissions = usePermissions();
  const canEdit = permissions.can('edit', FEATURES.AB_TESTING).allowed;

  const { mutateAsync: updateVariantDescription } = useMutation(abTestUpdateVariantDescriptionMutation);

  const hasDescription = typeof variant.description === 'string' && variant.description.trim() !== '';

  return (
    <Modal dialogClassName="w-[640px]">
      <Modal.Title>{variant.variantName.replace('#', '')} description</Modal.Title>

      <TextArea
        rows={3}
        placeholder="Add a description for your variant..."
        readOnly={!canEdit}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      ></TextArea>

      <Modal.Actions>
        <Button
          disabled={!canEdit || status === 'saving'}
          onClick={async () => {
            try {
              setStatus('saving');
              await updateVariantDescription({ id: abTest.id, variantId: variant.id, description });
              onSave?.();
              modal.close();
            } finally {
              setStatus('idle');
            }
          }}
        >
          {status === 'saving' ? 'Saving... ' : hasDescription ? 'Save changes' : 'Save description'}
        </Button>
        <Button variant="cancel" onClick={() => modal.close()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
