import { StyledPopoverPanel, PopoverItem, Tooltip } from '@crazyegginc/hatch';

import {
  SURVEY_QUESTION_TYPES,
  TEXT_ENTRY_SUBTYPES,
  MULTI_CHOICE_SUBTYPES,
  RATING_SUBTYPES,
} from '/src/features/addons/constants';

import { ReactComponent as MultiChoiceIcon } from '@crazyegginc/hatch/dist/images/icon-multi-choice.svg';
import { ReactComponent as TextIcon } from '@crazyegginc/hatch/dist/images/icon-text.svg';
import { ReactComponent as StarIcon } from '@crazyegginc/hatch/dist/images/icon-star-outline.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function QuestionSelector({ onSelection, align = 'left' }) {
  const handleSelection = (type, subtype, close) => {
    onSelection?.(type, subtype);
    close();
  };

  return (
    <StyledPopoverPanel align={align} className="top-full !w-[305px]" data-testid="question selector">
      {({ close }) => (
        <>
          <section className="mt-4 w-full">
            <SectionHeader icon={MultiChoiceIcon} text="Multiple Choice" />
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.MULTI_CHOICE, MULTI_CHOICE_SUBTYPES.SINGLE, close)}
              className="flex items-center justify-between"
            >
              Single-Select (choose only one)
              <Info text="Visitors can choose only one of the given choices via radio buttons or a dropdown menu." />
            </PopoverItem>
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.MULTI_CHOICE, MULTI_CHOICE_SUBTYPES.MULTI, close)}
              className="flex items-center justify-between"
            >
              Multi-Select (choose one or more)
              <Info text="Visitors can choose one or more of the given choices via checkboxes or a dropdown menu with checkboxes." />
            </PopoverItem>
          </section>
          <section className="mt-5 w-full">
            <SectionHeader icon={TextIcon} text="Text" />
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.TEXT_ENTRY, TEXT_ENTRY_SUBTYPES.SHORT, close)}
            >
              Short answer
            </PopoverItem>
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.TEXT_ENTRY, TEXT_ENTRY_SUBTYPES.LONG, close)}
            >
              Long answer
            </PopoverItem>
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.TEXT_ENTRY, TEXT_ENTRY_SUBTYPES.EMAIL, close)}
            >
              Email
            </PopoverItem>
          </section>
          <section className="mt-5 w-full">
            <SectionHeader icon={StarIcon} text="Rating" />
            <PopoverItem onClick={() => handleSelection(SURVEY_QUESTION_TYPES.RATING, RATING_SUBTYPES.STARS, close)}>
              Stars
            </PopoverItem>
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.RATING, RATING_SUBTYPES.NUMERICAL, close)}
            >
              Numeric
            </PopoverItem>
            <PopoverItem onClick={() => handleSelection(SURVEY_QUESTION_TYPES.RATING, RATING_SUBTYPES.SMILEY, close)}>
              Emoji
            </PopoverItem>
            <PopoverItem
              onClick={() => handleSelection(SURVEY_QUESTION_TYPES.RATING, RATING_SUBTYPES.NPS, close)}
              className="flex items-center justify-between"
            >
              NPS (Net Promoter Score)
              <Info text="NPS asks participants how likely they are to recommend your product/service as an indicator of loyalty and satisfaction with your brand." />
            </PopoverItem>
          </section>
        </>
      )}
    </StyledPopoverPanel>
  );
}

function SectionHeader({ icon: Icon, text }) {
  return (
    <div className="mb-2.5 flex items-center border-b border-mystic-500 pb-2.5">
      <Icon className="mx-2 h-[18px] w-[18px] fill-current text-dodger-blue-500" />
      <h3 className="text-body-2 m-0 leading-none">{text}</h3>
    </div>
  );
}

function Info({ text }) {
  return (
    <Tooltip tooltipContent={<div className="max-w-xs">{text}</div>} placement="right">
      <InfoIcon aria-label="option info" className="h-3 w-3 fill-current text-cadet-blue-500" />
    </Tooltip>
  );
}
