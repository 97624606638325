import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { superLenientUrlRegex } from '/src/utils/regex';

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('You must provide a Snapshot URL.')
    .matches(superLenientUrlRegex, 'Your URL appears to be invalid.'),
});

export function AddSnapshotNewSiteModal() {
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <Modal>
      <Modal.Title>Add Snapshots for another site</Modal.Title>
      <div className="text-body-4">Enter a domain below you’d like to create snapshots for</div>
      <Formik
        initialValues={{ url: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          navigate({
            pathname: '/snapshots/new/single',
            search: `?url=${values.url}`,
          });
          modal.close();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <div className="mt-4 w-full">
              <Input
                size="xl"
                name="url"
                type="text"
                placeholder="Enter URL (example: www.example.com)"
                onChange={handleChange}
                onBlur={(e) => setTimeout(() => handleBlur(e), 200)}
                value={values.url}
                error={touched.url && errors.url ? errors.url : null}
                autoFocus // eslint-disable-line
              />
            </div>

            <Modal.Actions>
              <Button type="submit">Create snapshots</Button>
              <Modal.Cancel />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
