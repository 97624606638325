import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Input, capitalizeFirstLetter } from '@crazyegginc/hatch';
import { useEditorContext } from '../../editor-context';

export function AddonName() {
  const { isTemplate, readonly, spelledType } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  return (
    <div
      className={classNames('ml-10', {
        'mb-10': !isTemplate,
        'mb-5': isTemplate,
      })}
    >
      <Input
        label={isTemplate ? 'Template Name' : `${capitalizeFirstLetter(spelledType)} Name`}
        id="name"
        name="name"
        value={values.name}
        error={errors.name && touched.name ? errors.name : null}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => {
          if (e.target.value.match(/New (Survey|CTA|Template) [\d-.: ]+/)) {
            e.target.select();
          }
        }}
        size="lg"
        labelClassName="!text-lg"
        autoFocus={true} //eslint-disable-line
        disabled={readonly}
      />
    </div>
  );
}
