import { useFormikContext, getIn } from 'formik';
import { RG, RadioGroupOption } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { AutoPositionSelector } from './AutoPositionSelector';

export function PopupPositionSelector({ fieldPath }) {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  const value = getIn(values, fieldPath);

  return (
    <div className="flex space-x-7 self-start">
      <RG
        value={value}
        onChange={(newValue) => setFieldValue(fieldPath, newValue)}
        className="!self-start"
        disabled={readonly}
      >
        <RG.Label className="text-body-1 mb-2 block">Position on page</RG.Label>
        <div className="flex h-9 items-center justify-center space-x-2.5 rounded-full bg-white-lilac-500 px-2.5">
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: 'bottomLeft', label: 'Left' }}
            disabled={readonly}
          />
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: 'bottomRight', label: 'Right' }}
            disabled={readonly}
          />
        </div>
      </RG>
      <AutoPositionSelector />
    </div>
  );
}
