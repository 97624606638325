import { useCallback, useState } from 'react';
import { Button } from '@crazyegginc/hatch';
import { ReactComponent as CopyIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';

import { copyToClipboard } from '/src/utils';

export function ClipboardButton({ value }) {
  const [clicked, setClicked] = useState(false);

  const copyValue = useCallback(() => {
    copyToClipboard(value);
    setTimeout(() => {
      setClicked(false);
    }, 1500);
    setClicked(true);
  }, [value]);

  return (
    <Button variant="cancel" onClick={copyValue} className="absolute right-[2px] top-[2px] !px-2.5">
      <CopyIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
      {clicked ? <span className="absolute left-1.25 top-7 text-[8px] uppercase">copied</span> : null}
    </Button>
  );
}
