/* eslint-disable no-console */
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BroadcastChannel } from 'broadcast-channel';
import { datadogRum } from '@datadog/browser-rum';
import { nanoid } from 'nanoid';
import ReactGA from 'react-ga4';
// import { inspect } from '@xstate/inspect';
import structuredClone from '@ungap/structured-clone';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { configure as configureHoneybadger } from '/src/utils/honeybadger';
import { newClient } from '/src/graphql/urql';
import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';
import { extendTypekitFontFace } from '/src/utils';

import pkg from '../package.json';

const BROADCAST_CHANNEL_NAME = 'ce:shell:app';

window.ceSessionId = nanoid();

console.stderror = console.error.bind(console);
console.errors = [];
console.error = function () {
  console.errors.push(Array.from(arguments));
  console.stderror.apply(console, arguments);
};

window.onerror = function () {
  console.errors.push(Array.from(arguments));
};

if (!('structuredClone' in globalThis)) {
  globalThis.structuredClone = structuredClone;
}

if (
  import.meta.env.DEV &&
  (window.location.search.includes('inspect') ||
    window.localStorage.getItem('inspect') === 'debug' ||
    window.localStorage.getItem('keep:inspect') === 'debug')
) {
  console.log('[DEBUG] Inspecting XState machine');
  // inspect({
  //   iframe: false,
  // });
}

window.version = pkg.version;

import App from './app';

window.accountChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

extendTypekitFontFace();

import '@crazyegginc/hatch/dist/styles.css';
import '/src/app.scss';

function mountMetrics() {
  if (window.ENVIRONMENT === 'production') {
    const scriptTag = document.createElement('script');
    scriptTag.src = window.METRICS_SCRIPT_URL;
    document.body.appendChild(scriptTag);

    datadogRum.init({
      applicationId: '3ce8a10f-e6ee-4a7a-9f92-e0a7bda36711',
      clientToken: 'pub6606bba45203e618f8a4baaa3d16d091',
      site: 'datadoghq.com',
      // categorization
      service: 'ce-shell',
      env: 'prod',
      version: pkg.version,
      // sessions tracking
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
      // backend tracing
      allowedTracingOrigins: ['https://core.crazyegg.com', 'https://api.crazyegg.com'],
      tracingSampleRate: 100,
      trackViewsManually: true,
    });
    // session replay
    // datadogRum.startSessionReplayRecording();

    ReactGA.initialize('G-683MNBBN19');
  }
}

function setUpReactRemoveChildWorkaround() {
  // Note: caused by rogue extensions in the wild. This slows down rendering a bit but is an unfortunate necessity
  // This affected around 100 users per day.
  // https://github.com/facebook/react/issues/11538#issuecomment-417504600
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
}

function mount(client) {
  configureHoneybadger();
  setUpReactRemoveChildWorkaround();

  const root = createRoot(document.getElementById(ROOT_ELEMENT_ID));

  return root.render(
    <Suspense fallback={<LoaderBalloonScreen />}>
      <App client={client} />
    </Suspense>,
  );
}

// export the client created later
export let client = null;

function initClient() {
  // Backwards compatibility:
  // redirect any url with `demo`query param in the URL to www.crazyegg.com/overview
  if (window.location.href.includes('demo=')) {
    return (window.location.href = `${window.MARKETING_BASE_URL}/overview`);
  }

  mountMetrics();
  client = newClient();
  mount(client);
}

if (window.CORE_INITIALIZED) {
  initClient();
} else {
  window.addEventListener('core:loaded', () => {
    window.BUNDLE_OUTDATED = window.SHELL_VERSION !== window.version;
    initClient();
  });
}

// use interval timer to wait for Typekit
// to be available
const fontLoader = setInterval(() => {
  if (window.Typekit) {
    window.Typekit.load({ async: true });
    clearInterval(fontLoader);
  }
}, 100);

// we need this since we removed our service worker and some users still have
// it installed
navigator?.serviceWorker?.getRegistrations?.().then?.(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});
