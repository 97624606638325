import { isProduction } from '/src/utils';
import {
  SNAPSHOT_ADDON_50,
  SNAPSHOT_ADDON_100,
  SNAPSHOT_ADDON_200,
  SNAPSHOT_ADDON_UNLIMITED,
} from '/src/features/account/constants';

export function addCETag(tag) {
  const isProd = isProduction();

  if (window.CE2?.addTag && isProd) {
    window.CE2.addTag(tag);
  }
}

export function snapshotAddonListDetails(addon) {
  let detail;
  switch (addon) {
    case SNAPSHOT_ADDON_50:
      detail = { costPerMonth: 50, snapshots: 50, type: addon };
      break;
    case SNAPSHOT_ADDON_100:
      detail = { costPerMonth: 75, snapshots: 100, type: addon };
      break;
    case SNAPSHOT_ADDON_200:
      detail = { costPerMonth: 100, snapshots: 200, type: addon };
      break;
    case SNAPSHOT_ADDON_UNLIMITED:
      detail = { costPerMonth: 250, snapshots: 'unlimited', type: addon };
      break;
    default:
      detail = { costPerMonth: 0, snapshots: 0, type: false };
      break;
  }

  return detail;
}
