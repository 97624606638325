import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fromUnixTime, startOfDay, addDays, getUnixTime } from 'date-fns';
import { capitalize, Divider, Button } from '@crazyegginc/hatch';

import { useAuthContext, useSelectedSite } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { copyToClipboard } from '/src/utils';
import { errorQuery } from '/src/features/errors/queries';
import { formatFullDateTime } from '/src/utils/date';
import { getVisitorDisplayName } from '/src/utils/visitor';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function ErrorDetailsModal({ errorId, siteId }) {
  const { selectedSite } = useSelectedSite();
  const { isSharing } = useAuthContext();
  const [copyClicked, setCopyClicked] = useState(false);

  const { data, isLoading } = useQuery({
    ...errorQuery({
      id: errorId,
      siteId,
    }),
    enabled: Boolean(errorId && siteId),
  });

  const error = data?.error;

  useEffect(() => {
    window.dispatchEvent(new Event('player:pause'));
  }, []);

  useEffect(() => {
    if (copyClicked) {
      setTimeout(() => {
        setCopyClicked(false);
      }, 3000);
    }
  }, [copyClicked]);

  if (isLoading || !error) return null;

  function copyLink() {
    if (error) {
      let dataToCopy = {
        url: error.url,
        'error message': error.errorMessage,
        visitor: getVisitorDisplayName(error.identifier, error.visitorId),
        country: error.country?.name ?? '-',
        timestamp: `${formatFullDateTime(error.createdAt)} UTC`,
        browser: getBrowserName(error.browser),
        'operating system': getOSName(error.os),
        device: capitalize(error.device),
        viewport: error.screenWidth && error.screenHeight ? `${error.screenWidth} x ${error.screenHeight}` : '-',
        'app version': error.appVersion ?? '-',
        'user agent': error.userAgent ?? '-',
        'stack trace': error.stackTrace ?? '',
      };
      if (!isSharing) {
        dataToCopy = {
          ...dataToCopy,
          'view more details here': `${window.location.origin}/errors/${error.fingerprintMd5}?site=${
            selectedSite.name
          }&eid=${error.id}&date=${getErrorDateRange(error.createdAt)}`,
        };
      }
      copyToClipboard(JSON.stringify(dataToCopy));
      setCopyClicked(true);
    }
  }

  return (
    <Modal dialogClassName="text-white !w-[640px]" dark>
      <Modal.Title>
        <div className="flex items-center">
          <ErrorIcon className="mr-2.5 h-5 w-5 fill-current" /> Error
        </div>
      </Modal.Title>

      <Divider className="!border-t-charade-500" />

      <div className="mt-5 flex items-end justify-between px-7">
        <div className="text-header-5 text-white">Session metadata</div>
        <Button
          theme="dark"
          variant="secondary"
          size="sm"
          onClick={() => copyLink()}
          className="!flex !justify-center !px-5"
          disabled={copyClicked}
        >
          {copyClicked ? 'Copied' : 'Copy'}
        </Button>
      </div>

      <div className="px-7">
        <div className="mt-2.5 h-80 overflow-y-auto rounded border border-charade-500 bg-woodsmoke-500 px-4 pt-3.5">
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">URL</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{error.url}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Error message</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{error.errorMessage}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Visitor</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">
              {getVisitorDisplayName(error.identifier, error.visitorId)}
            </div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Country</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{error.country?.name ?? '-'}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Timestamp</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{`${formatFullDateTime(
              error.createdAt,
            )} UTC`}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Browser</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{getBrowserName(error.browser)}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Operating system</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{getOSName(error.os)}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Device</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{capitalize(error.device)}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Viewport</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">
              {error.screenWidth && error.screenHeight ? `${error.screenWidth} x ${error.screenHeight}` : '-'}
            </div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">App version</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{error.appVersion ?? '-'}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">User agent</div>
            <div className="text-body-2 break-all py-0.5 text-cadet-blue-500">{error.userAgent ?? '-'}</div>
          </div>
          <div className="flex">
            <div className="text-body-1 w-36 flex-none py-0.5 pr-4 text-right text-white">Stack trace</div>
            <div className="text-body-2 whitespace-pre-wrap pb-3.5 pt-0.5 text-cadet-blue-500">
              {error.stackTrace ?? ''}
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-center">
          {!isSharing && (
            <Button
              component={Link}
              theme="dark"
              size="lg"
              to={{
                pathname: `/errors/${error.fingerprintMd5}`,
                search: `site=${selectedSite.name}&eid=${error.id}&date=${getErrorDateRange(error.createdAt)}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {}}
            >
              View more details
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

function getBrowserName(browser) {
  let result = '-';
  if (browser.name) {
    result = capitalize(browser.name).replace('_', ' ');
    if (browser.version) {
      result += ` (version: ${browser.version})`;
    }
  }
  return result;
}

function getOSName(os) {
  return capitalize(os).replace('_', ' ');
}

function getErrorDateRange(unixDate) {
  const errorDay = startOfDay(fromUnixTime(unixDate));
  const startDay = getUnixTime(addDays(errorDay, -15));
  const endDay = getUnixTime(addDays(errorDay, 15));

  return JSON.stringify({
    start_date: startDay,
    end_date: endDay,
  });
}
