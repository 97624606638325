/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Button } from '@crazyegginc/hatch';

import { WebP } from '/src/components/WebP';
import { ConsoleErrors } from '/src/components/ConsoleErrors';

import CrashBalloonPNG from '@crazyegginc/hatch/dist/images/illustration-balloon-error.png';
import CrashBalloonWebP from '@crazyegginc/hatch/dist/images/illustration-balloon-error.webp';

export function AuthErrorPage() {
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (viewErrors && !console.errors.length) {
      setViewErrors(false);
    }
  }, [viewErrors]);

  useEffect(() => {
    window.Mocky?.stop();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (console.errors.length !== errors.length) {
        setErrors(console.errors);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [errors.length]);

  return (
    <div className="flex h-[100dvh] w-screen flex-col items-center justify-center">
      <div className="flex max-w-[600px] flex-col">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="mx-8 mb-8 flex flex-col justify-center md:mb-0">
            <WebP webp={CrashBalloonWebP} fallback={CrashBalloonPNG} width="160px" alt="Crashing balloon" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <h1 className="text-header-0 mb-3 font-normal">Bother!</h1>
            <h2 className="text-header-1">Something went wrong in-flight.</h2>
            <div className="text-body-2">Head on back to the dashboard and let&#39;s try that again.</div>
            <div className="!mt-5 flex space-x-2.5">
              <Button
                className="!mt-0"
                variant="secondary"
                onClick={() => {
                  // clear current errors list
                  console.errors = [];
                  window.location.reload();
                }}
                size="lg"
              >
                Try again
              </Button>
            </div>
            {window.ceSessionId ? <div className="text-body-3 mt-5">Session ID: {window.ceSessionId}</div> : null}
          </div>
        </div>
        {errors.length ? (
          <div className="mt-4 flex w-full rounded bg-white-lilac-500">
            <div className="flex-1 px-3 py-2">
              <h3 className="text-header-6 mb-0.5 text-lynch-500">Need some help?</h3>
              <p className="text-caption">
                Before you contact support, please click the &quot;See errors&quot; button and take a screenshot of this
                entire page to accompany your request.
              </p>
            </div>
            <div className="flex w-[140px] items-center px-2 pl-3 pr-4">
              <Button onClick={() => setViewErrors((v) => !v)} className="w-full justify-center" size="lg">
                {viewErrors ? 'Hide' : 'See'} errors
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      {viewErrors ? <ConsoleErrors onClose={() => setViewErrors(false)} /> : null}
    </div>
  );
}
