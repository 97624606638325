import { createContext, useState, useReducer } from 'react';
import isEqual from 'react-fast-compare';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { WizardModal } from './WizardModal';
import { WizardFooter } from './WizardFooter';

export const WIZARD_ACTIONS = {
  SET: 'SET',
  SET_DIRTY: 'SET_DIRTY',
};

export const WizardContext = createContext();

const initialState = {
  back: null,
  next: null,
  backEnabled: false,
  nextText: 'Next',
  nextEnabled: false,
  currentStep: 1,
  totalSteps: 1,
  title: null,
  data: {},
  celebrate: false,
  modal: null,
  cancelConfirm: false,
  returnLocation: null,
};

function wizardReducer(state, action) {
  switch (action.type) {
    case WIZARD_ACTIONS.SET:
      return {
        ...state,
        ...action.payload,
      };
    case WIZARD_ACTIONS.SET_DIRTY:
      return {
        ...state,
        data: {
          ...state.data,
          dirty: action.payload,
        },
      };
    default:
      return state;
  }
}

export function WizardProvider({ children }) {
  const { currentUser } = useAuthContext();
  const [wizard, wizardDispatch] = useReducer(wizardReducer, initialState);
  const [wizardState, setWizardState] = useState({ wizard, wizardDispatch });

  if (!isEqual(wizard, wizardState.wizard)) {
    setWizardState({ wizard, wizardDispatch });
  }

  // we don't need to check `sharedResource` here since
  // they don't have access to wizards
  if (!currentUser) {
    return <LoaderBalloonScreen />;
  }

  return (
    <WizardContext.Provider value={wizardState}>
      {children}
      <WizardFooter />

      {wizardState.wizard.modal && <WizardModal>{wizardState.wizard.modal.component}</WizardModal>}
    </WizardContext.Provider>
  );
}
