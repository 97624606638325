import { useMemo, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom, atom } from 'jotai';
import { IconButton, ReactTable, Tooltip } from '@crazyegginc/hatch';

import {
  useSearchParams,
  useMutation,
  useModal,
  usePermissions,
  useNeedsUpgradeToAccessFeature,
  useSite,
  useNotifications,
} from '/src/hooks';
import { formatDate } from '/src/utils/date';
import { GoalTriggerSummary, generateRecordingsFilterUrlForGoal } from '/src/features/goals/utils';
import { getFeatureName } from '/src/features/_global/utils';

// ICONS & ILLUSTRATIONS
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as ABTestIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';

// COMPONENTS
import { SEO } from '/src/components/SEO';
import { SitePickerBar } from '/src/components/site-picker';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { GoalsDashHeader } from '/src/features/goals/components/DashHeader';
import { NoGoalsWall } from '../paywalls/NoGoalsWall';
import { DeleteModal } from '../components/modals/DeleteModal';
import { WebP } from '/src/components/WebP';
import { AtomSearchInput } from '/src/components/search-input';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { DashboardPage } from '/src/components/Page';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';
import { ABTestingUpgradeModal } from '/src/features/ab-testing/components/modals/ABTestingUpgradeModal';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';

import { goalListQuery } from '/src/features/goals/queries';
import { goalDeleteMutation } from '/src/features/goals/mutations';

import { FEATURES, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { GOAL_TRIGGERS_METADATA } from '../constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

import { camelToSnake, snakeToCamel } from '/src/utils/string';

const filterAtom = atom({ search: '' });

// HEADER CONTENT :START
// NOTE: PLACE FOR FILTER AND I THINK THE TITLE IS CONNECTED TO THE FILTER
function GoalsActions() {
  const [params] = useSearchParams();

  return (
    <div className="mb[15px] mb-[15px] flex w-full items-end justify-between">
      <h2 className="text-header-3">All goals</h2>

      <AtomSearchInput
        atom={filterAtom}
        initialValue={params.search}
        placeholder="Search for goal or URL"
        className="!bg-solitude-500 !placeholder-dodger-blue-500/50"
        ariaLabel="search for goal or URL"
      />
    </div>
  );
}

// GOAL TABLE CONTENT :START
// NOTE: MAKE SURE CREATED COLUMN IS SORTABLE AND UPDATE RECORDING AND A/B TEST ARIA LABEL IN THE ICONS
const modalDeleteOptions = {
  overlaycolor: 'rgba(59, 66, 70, 0.65)',
};

function GoalActionsRow({ goal, siteId }) {
  const { mutate: mutateDeleteGoal } = useMutation(goalDeleteMutation);
  const navigate = useNavigate();
  const modal = useModal();
  const notifications = useNotifications();
  const permissions = usePermissions();
  const canShare = permissions.can('share', goal).allowed;
  const canEdit = permissions.can('edit', goal).allowed;
  const canDelete = permissions.can('delete', goal).allowed;

  return (
    <div
      className={classNames(
        'invisible mr-2.5 flex w-full max-w-[85px] items-center justify-between space-x-1.5 px-1.25 group-hover:visible',
      )}
    >
      {canEdit && (
        <Tooltip tooltipContent="Edit">
          <IconButton
            onClick={() => {
              navigate(`/goals/${goal.id}/edit`);
            }}
            icon={<EditIcon aria-label="edit goals" className="h-4 w-4 fill-current text-malibu-500" />}
          />
        </Tooltip>
      )}

      {canShare && (
        <Tooltip tooltipContent="Share">
          <IconButton
            icon={<ShareIcon aria-label="delete goals" className="h-4 w-4 fill-current text-malibu-500" />}
            onClick={() => {
              window.SharingModal.show({ entity: { id: goal.id, siteId, type: SHARABLE_RESOURCE_TYPES.GOAL } });
            }}
          />
        </Tooltip>
      )}

      {canDelete && (
        <Tooltip tooltipContent="Delete">
          <IconButton
            icon={<DeleteIcon aria-label="delete goals" className="h-4 w-4 fill-current text-malibu-500" />}
            onClick={() =>
              modal.show(
                <DeleteModal
                  goal={goal}
                  abTests={goal.abTests}
                  onConfirm={(id) => {
                    mutateDeleteGoal(
                      {
                        id: Number(id),
                      },
                      {
                        onSuccess: () => {
                          notifications.success({ content: 'Goal deleted successfully.', timeout: 3000 });
                        },
                        onError: (error) => {
                          notifications.error({ content: 'Goal delete failed.', timeout: 3000, context: { error } });
                        },
                      },
                    );
                  }}
                />,
                modalDeleteOptions,
              )
            }
          />
        </Tooltip>
      )}
    </div>
  );
}

function GoalsTable({ goals, fetching, hasNextPage, loadNextPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { sites, selectedSite } = useSite({ dontSelectSite: true });
  const needsUpgradeToAccessRecordings = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);
  const needsUpgradeToAccessABTesting = useNeedsUpgradeToAccessFeature(FEATURES.AB_TESTING);
  const modal = useModal();

  const getSiteName = useCallback(
    (cellRowSiteId) => {
      if (!selectedSite?.name) {
        return sites.find((x) => cellRowSiteId === x.id)?.name ?? null;
      }

      return selectedSite?.name;
    },
    [selectedSite?.name, sites],
  );

  const columns = useMemo(
    () => [
      {
        header: () => {
          return <div className="pl-2.5">Goal</div>;
        },
        accessorKey: 'name',
        size: 340,
        meta: {
          align: 'center',
          justify: 'left',
          padding: '0px 8px',
        },
        cell: function FingerprintCell({ row }) {
          return (
            <div className="flex min-w-0 pr-5">
              <div className="mr-4 flex w-[45px] items-center">
                <WebP
                  className="w-[45px]"
                  webp={
                    GOAL_TRIGGERS_METADATA[row.original.icon.toUpperCase()]?.webp ?? GOAL_TRIGGERS_METADATA.OTHER.webp
                  }
                  fallback={
                    GOAL_TRIGGERS_METADATA[row.original.icon.toUpperCase()]?.png ?? GOAL_TRIGGERS_METADATA.OTHER.png
                  }
                  width="45"
                  alt="option type"
                />
              </div>
              <div className="flex w-full flex-col overflow-hidden">
                <div className="flex flex-1 items-center overflow-hidden">
                  {row.original.permissions.canView ? (
                    <Link to={`/goals/${row.original.id}`} className="text-link mb-1 truncate">
                      {row.original.name}
                    </Link>
                  ) : (
                    <span className="text-link mb-1 truncate">{row.original.name}</span>
                  )}
                  <p className="text-caption mb-0.5 font-light">{row.original.desc}</p>
                  <div className="flex items-center truncate">
                    <p className="text-caption mr-0.5 inline font-light">
                      {GOAL_TRIGGERS_METADATA[row.original.trigger]?.description}
                    </p>
                    <span className="truncate">
                      <Tooltip tooltipContent={<div className="w-[390px] px-0.5 py-1">{row.original.targetUrl}</div>}>
                        <span
                          className="truncate text-2xs text-dodger-blue-500 underline"
                          style={{ display: 'inherit' }}
                        >
                          {row.original.targetUrl}
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
                <GoalTriggerSummary goal={row.original} />
              </div>
            </div>
          );
        },
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        size: 90,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function GoalsCreatedAtCell({ row }) {
          return <div>{formatDate(row.original.createdAt)}</div>;
        },
      },
      {
        header: function GoalsConversionsHeader() {
          return (
            <div className="flex items-center">
              <div className="mr-1.5 leading-none">Conversions</div>
              <Tooltip
                tooltipContent={
                  <div className="w-[335px] px-2 py-2.5">
                    <h5 className="mb-2.5 text-xs font-bold text-white">
                      Does this look different to your A/B test conversions?
                    </h5>
                    <p className="text-xs font-light leading-tight tracking-wide text-white">
                      Conversions for A/B tests are only counted if a visitor has landed on your test pages before
                      performing a goal, whereas all conversions are counted in your goals dashboard.
                    </p>
                  </div>
                }
              >
                <InfoIcon
                  className="h-3 w-3 -translate-y-px transform fill-current text-dodger-blue-300"
                  aria-label="Info on conversion"
                />
              </Tooltip>
            </div>
          );
        },
        accessorKey: 'conversions',
        size: 110,
        meta: {
          align: 'center',
        },
        cell: function GoalsConversionsCell({ row }) {
          return row.original.conversionsCount || 0;
        },
      },
      {
        header: 'Recordings',
        size: 80,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => {
          return needsUpgradeToAccessRecordings ? (
            <button type="button" onClick={() => modal.show(<RecordingsUpgradeModal />)}>
              <RecordingIcon
                className="h-4 w-4 fill-current text-malibu-500"
                aria-label={`link to recordings related to ${row.original.name}`}
              />
            </button>
          ) : (
            <Link
              to={{
                pathname: '/recordings',
                search: `filters=${generateRecordingsFilterUrlForGoal(row.original)}&site=${getSiteName(
                  row.original.siteId,
                )}`,
              }}
              className="flex items-center"
            >
              <RecordingIcon
                className="h-4 w-4 fill-current text-malibu-500"
                aria-label={`link to recordings related to ${row.original.name}`}
              />
            </Link>
          );
        },
      },
      {
        header: 'a/b test',
        accessorKey: 'abTests',
        size: 80,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => {
          return needsUpgradeToAccessABTesting ? (
            <button type="button" className="mr-2 " onClick={() => modal.show(<ABTestingUpgradeModal />)}>
              <ABTestIcon
                className="h-4 w-4 fill-current text-malibu-500"
                aria-label={`link to A/B Tests related to ${row.original.name}`}
              />
            </button>
          ) : (
            <>
              <Link
                to={{
                  pathname: `/goals/${row.original.id}`,
                  hash: 'ab-tests',
                }}
                className="mr-2 flex items-center"
              >
                <ABTestIcon
                  className="h-4 w-4 fill-current text-malibu-500"
                  aria-label={`link to A/B Tests related to ${row.original.name}`}
                />
              </Link>
              {row.original.abTestsCountActive || 0}
            </>
          );
        },
      },
      {
        id: 'Actions',
        minSize: 80,
        maxSize: 80,
        meta: {
          align: 'center',
          padding: '0',
        },
        cell: function ActionsCell({ row }) {
          return <GoalActionsRow goal={row.original} siteId={row.original.siteId} />;
        },
      },
    ],
    [getSiteName, modal, needsUpgradeToAccessRecordings, needsUpgradeToAccessABTesting],
  );

  const tableRef = useRef(null);

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(searchParams.get('order') ?? 'CREATED_AT'),
      desc: (searchParams.get('sort') ?? SORT_ORDER_TYPES.DESC) === SORT_ORDER_TYPES.DESC,
    };
  }, [searchParams]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        setSearchParams({
          order: camelToSnake(sorting[0].id).toUpperCase(),
          sort: sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC,
        });
      }
    },
    [setSearchParams],
  );

  return (
    <ReactTable
      columns={columns}
      data={goals}
      fetching={fetching}
      ref={{ tableRef }}
      enableSorting={true}
      sorting={tableSortData}
      onFetchData={onFetchData}
      rowHeight={90}
      dynamicRowHeight={true}
      hasNextPage={hasNextPage}
      isNextPageLoading={fetching}
      loadNextPage={loadNextPage}
      useVirtualization={true}
      rowPadding={true}
      rowCustomClassname={() => 'py-3.75'}
    />
  );
}

// CONTENT FOR NOGOALWALL, GOALSACTIONS, GOALSTABLE COMPONENTS :START
// NOTE: AT THE MOMENT USING SITES LENGTH TO SHOW THE CONTENT
function GoalsDashContent() {
  const [searchParams] = useSearchParams();
  const permissions = usePermissions();
  const { sites, loadingSites, selectedSite, reloadSites } = useSite({ enableAllSites: true });

  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const [filter, setFilter] = useAtom(filterAtom);

  const navigate = useNavigate();
  const {
    data: goalsData,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...goalListQuery({
      siteId: selectedSite?.id ?? undefined,
      search: filter.search && filter.search.length > 0 ? filter.search : undefined,
      order: searchParams.get('order'),
      sort: searchParams.get('sort'),
    }),
    enabled: Boolean(selectedSite),
  });

  const isLoading = !goalsData && !error;

  const goals = useMemo(
    () => (goalsData?.pages ?? []).reduce((acc, page) => [...acc, ...(page?.goalList?.list ?? [])], []) ?? [],
    [goalsData],
  );

  if (!loadingSites && !sites.length && canEditSites) {
    return (
      <AddSiteModal
        customTitle="Add a site before creating Goals"
        onExtraSteps={() => {
          reloadSites();
        }}
        onCancel={() => navigate('/snapshots', { replace: true })}
      />
    );
  }

  if (!isLoading && goals.length === 0 && !filter.search) {
    return <NoGoalsWall />;
  }

  return (
    <>
      <InstallationBanner />
      <div className="mt-10 flex w-full flex-col px-10">
        <GoalsActions />
        <div className="flex w-full justify-center">
          {!isLoading && goals.length === 0 && filter.search.length > 0 ? (
            <NoMatchesWall text="matches for this search" reset={() => setFilter({ search: '' })} actionText="search" />
          ) : (
            <GoalsTable goals={goals} fetching={isLoading} hasNextPage={hasNextPage} loadNextPage={fetchNextPage} />
          )}
        </div>
      </div>
    </>
  );
}

// ALL COMPONENTS :START
// eslint-disable-next-line no-restricted-syntax
export default function GoalsDashboard() {
  const { sites, loadingSites, selectedSite, selectSite } = useSite({ enableAllSites: true });
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.GOALS);
  const canEditSites =
    permissions.can('navigate', FEATURES.SITE_SETTINGS).allowed &&
    permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.GOALS} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.GOALS)} />
      <GoalsDashHeader />
      <SitePickerBar
        sites={sites}
        loading={loadingSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        enableAllSites={true}
        addSiteOption={canEditSites}
        pageDisplayName={`${getFeatureName(FEATURES.GOALS)} dashboard`}
      />
      <GoalsDashContent />
    </DashboardPage>
  );
}
