import { Modal } from '/src/contexts/modal';

export function PrivacyExtensionModal() {
  return (
    <Modal disableOutsideClick={true} dismissable={false} dialogClassName="!p-6 !max-w-[400px]" dark>
      <Modal.Title>Whoops!</Modal.Title>

      <p className="text-body-2 leading-5 text-white">
        We have detected a compatibility issue with your browser. Please run the compatibility check to identify and
        resolve the issue preventing you from watching the recordings.
      </p>
    </Modal>
  );
}
