import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';

import LockedPNG from '@crazyegginc/hatch/dist/images/illustration-locked.png';
import LockedWebP from '@crazyegginc/hatch/dist/images/illustration-locked.webp';

export function SsoValidationModal({ onConfirm, onDismiss }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!px-5 !py-8 !items-center" disableOutsideClick={true}>
      <div className="flex flex-col">
        <WebP
          className="mx-auto mb-4 w-[150px]"
          webp={LockedWebP}
          fallback={LockedPNG}
          width="150"
          alt="Validation required"
        />
        <h2 className="text-header-3 mb-5">We need to validate your configuration</h2>
      </div>
      <div className="flex flex-col">
        <span className="text-body-2 text-center">
          Before we can store your changes, we need to validate that the provided configuration will work. Clicking on
          continue will redirect you to your Identity Provider and you will complete a Single Sign-On flow. When it
          completes, your settings will be stored.
        </span>
      </div>

      <Modal.Actions>
        <Button
          onClick={() => {
            onConfirm?.();
          }}
        >
          Continue
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            onDismiss?.();
            modal.close();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
