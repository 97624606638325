import { useCallback } from 'react';

import { Drawer } from '/src/components/Drawer';
import { VisitorProfile } from './VisitorProfile';

import { useVisitorId, useNeedsUpgradeToAccessFeature } from '/src/hooks';
import { VisitorPanelUpgradePaywall } from './paywalls/VisitorPanelUpgradePaywall';
import { FEATURES } from '/src/features/_global/constants';

export function VisitorPanel({ currentRecordingHash = null, navigateToAnotherRecording = null }) {
  const { unselectVisitor, selectedVisitor } = useVisitorId();
  const shouldShow = selectedVisitor !== null;
  const needsUpgradeToAccess = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);

  const navigateAndCloseVisitorPanel = useCallback(
    (recording) => {
      navigateToAnotherRecording(recording);
      unselectVisitor();
    },
    [navigateToAnotherRecording, unselectVisitor],
  );

  if (!selectedVisitor) return null;

  return (
    <div
      className="fixed inset-0 z-[99999] h-screen w-screen cursor-default overflow-hidden bg-mako-500 bg-opacity-20"
      onMouseDown={() => unselectVisitor()}
      role="button"
      aria-label="closable region"
      tabIndex={0}
    >
      <Drawer opened={shouldShow}>
        {needsUpgradeToAccess ? (
          <VisitorPanelUpgradePaywall />
        ) : (
          <VisitorProfile
            currentRecordingHash={currentRecordingHash}
            navigateToAnotherRecording={navigateToAnotherRecording ? navigateAndCloseVisitorPanel : undefined}
          />
        )}
      </Drawer>
    </div>
  );
}
