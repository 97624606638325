import { forwardRef } from 'react';
import classNames from 'classnames';
import { Toggle as HatchToggle, Input as HatchInput } from '@crazyegginc/hatch';

export const Input = forwardRef(({ ...props }, ref) => {
  return <HatchInput ref={ref} size="lg" labelClassName="font-semibold" {...props} />;
});

export function Toggle({ ...props }) {
  return <HatchToggle labelClasses="!ml-1.5 !text-lynch-500" displayLabel={true} {...props} />;
}

export function SectionPanel({ children, className, ...rest }) {
  return (
    <section
      className={classNames('ml-10 mt-5 flex flex-col rounded-[5px] border border-mystic-500 shadow-md', className)}
      data-testid="section-panel"
      {...rest}
    >
      {children}
    </section>
  );
}

export function SectionHeader({ children, className }) {
  return (
    <div
      className={classNames(
        'flex h-11 w-full items-center justify-between rounded-t-[5px] border-b border-mystic-500 bg-white-lilac-500 px-5',
        className,
      )}
      data-testid="section-header"
    >
      {children}
    </div>
  );
}

export function SectionContent({ children, className }) {
  return <div className={classNames('mx-5 mb-7 mt-5 flex flex-col space-y-5', className)}>{children}</div>;
}
