import { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import * as yup from 'yup';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Indicator, Divider, Panel, Checkbox, Button, IconButton } from '@crazyegginc/hatch';

import { useAuthContext, useHasFeatureFlag } from '/src/hooks';
import { useFunnelContext } from '../funnel-context';
import { SearchableGoalSelector } from '/src/components/SearchableGoalSelector';
import { MAX_STEPS, STEP_TYPES } from '../constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-move-dots.svg';

const err = 'Please select a Goal.';
const validationSchema = yup.object().shape({
  steps: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().oneOf([...Object.values(STEP_TYPES)]),
        id: yup.number().when('type', {
          is: STEP_TYPES.VISIT,
          then: yup.number().nullable(true),
          otherwise: yup.number(err).typeError(err).integer(err).positive(err),
        }),
      }),
    )
    .min(2, 'Please add at least ${min} steps.'),
});

export function ManageSteps() {
  const { steps, setSteps } = useFunnelContext();

  return (
    <DndProvider backend={HTML5Backend}>
      <Formik
        initialValues={{
          steps,
        }}
        onSubmit={(values) => {
          setSteps([...values.steps]);
        }}
        validationSchema={validationSchema}
      >
        <Disclosure as="div" className="mb-5" defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-header-4 flex w-full items-center text-dodger-blue-500 focus-visible:outline-black">
                Manage steps <Indicator className="ml-2" type="expand" up={open} />
                <Divider className="ml-4 w-auto flex-1" dashed />
              </Disclosure.Button>

              <Disclosure.Panel>
                <Form>
                  <Panel>
                    <FieldArray name={'steps'}>{(actions) => <ManageStepsContent actions={actions} />}</FieldArray>
                  </Panel>
                </Form>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Formik>
    </DndProvider>
  );
}

function ManageStepsContent({ actions }) {
  const { currentAccount } = useAuthContext();
  const isSupportAccount = currentAccount?.ownerEmail === 'support@crazyegg.com';
  const hasPageViewFlag = useHasFeatureFlag('page-view');
  const { values, errors } = useFormikContext();

  const includeVisitors = values.steps[0]?.type === STEP_TYPES.VISIT ? true : false;

  return (
    <>
      {hasPageViewFlag || isSupportAccount ? (
        <div className="mb-4 flex items-center">
          <div className="mr-2.5 w-4 font-mono text-cadet-blue-500">{includeVisitors ? '1.' : ''}</div>
          <Checkbox
            name="visitStep"
            id="visitStep"
            label="Visitors"
            checked={includeVisitors}
            onChange={(e) => {
              if (e.target.checked) {
                actions.unshift({ id: null, type: STEP_TYPES.VISIT });
              } else {
                actions.remove(0);
              }
            }}
            disabled={!includeVisitors && values.steps.length >= MAX_STEPS}
          />
        </div>
      ) : null}

      {values.steps.map((step, i) => (
        <GoalSelector key={`${step.type}-${step.id ?? i}`} step={step} i={i} actions={actions} />
      ))}

      {errors.steps && typeof errors.steps === 'string' ? <div className="text-error">{errors.steps}</div> : null}

      {values.steps.length < MAX_STEPS ? (
        <Button
          className="ml-8"
          variant="secondary"
          onClick={() => {
            actions.push({ id: null, type: STEP_TYPES.GOAL });
          }}
        >
          <PlusIcon className="mr-2 h-3 w-3 fill-current" />
          Add Goal
        </Button>
      ) : null}

      <div className="mt-10 flex items-center space-x-2.5">
        <Button type="submit">Apply</Button>
      </div>
    </>
  );
}

function GoalSelector({ step, i, actions }) {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const goalOnSelect = useCallback((goal) => setFieldValue(`steps[${i}].id`, goal?.id ?? null), [setFieldValue, i]);

  const canReorder = true;

  const ref = useRef(null);
  const dragRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'funnelstep',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop: () => canReorder,
    hover(item, monitor) {
      if (!ref.current || !monitor.canDrop()) {
        return;
      }
      const dragIndex = item.i;
      const hoverIndex = i;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      actions.move(dragIndex, hoverIndex);
      item.i = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'funnelstep',
    item: () => {
      return { id: `${step.type}-${step.id ?? i}`, i };
    },
    canDrag: () => canReorder,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  drag(dragRef);

  if (i === 0 && step.type === STEP_TYPES.VISIT) return null;

  const selectedGoalsList = values.steps
    .filter((x, index) => x.type === STEP_TYPES.GOAL && i !== index)
    .map((x) => x.id);

  return (
    <div
      className={classNames('group -ml-5 mb-5 flex w-full max-w-xl items-center', { 'opacity-0': isDragging })}
      ref={ref}
    >
      <div className="relative flex h-10 items-center">
        <div
          className={classNames('invisible mb-0.5 mr-1 group-hover:visible', {
            'cursor-[grab] text-cadet-blue-500': canReorder,
            'cursor-not-allowed text-cadet-blue-500/50': !canReorder,
          })}
          ref={dragRef}
        >
          <MoveIcon className="h-4 w-4 fill-current" />
        </div>
        <div className="ml-0 w-4 font-mono text-cadet-blue-500">{i + 1}.</div>
      </div>
      {step.type === STEP_TYPES.GOAL ? (
        <div className="ml-2.5 flex grow items-center">
          <div className="text-body-2 grow">
            <SearchableGoalSelector
              value={step.id}
              onSelect={goalOnSelect}
              showNoGoalOption={false}
              filterIds={selectedGoalsList}
              displayIfNoGoals="Please create Goals to be able to select one."
              error={touched?.steps?.[i] && errors?.steps?.[i] ? errors?.steps?.[i].id : null}
            />
          </div>
          <IconButton
            icon={<CrossIcon className="h-3 w-3 fill-current" />}
            className="ml-5 shrink-0 text-cadet-blue-500 hover:text-carnation-500"
            onClick={() => {
              actions.remove(i);
            }}
            label="delete goal"
          />
        </div>
      ) : null}
    </div>
  );
}
