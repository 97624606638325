import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { usePermissions, useAuthContext } from '/src/hooks';

import { Paywall } from '/src/components/Paywall';

import { FEATURES } from '/src/features/_global/constants';

import LivePNG from '@crazyegginc/hatch/dist/images/illustration-live-dash.png';

export function LiveNotInstalled() {
  const permissions = usePermissions();
  const { currentAccount } = useAuthContext();

  const canViewInstall = permissions.can('view', FEATURES.INSTALLATION).allowed;

  return (
    <Paywall>
      <div className="mb-6 mt-12">
        <img src={LivePNG} width="175px" height="150px" alt="Live dashboard events" />
      </div>
      <Paywall.Title>Your website events, live!</Paywall.Title>

      <Paywall.Body>
        View key events happening on your website in
        <br /> real-time. Dive deeper into visitor information,
        <br /> errors and snapshots as they happen.
        {!canViewInstall ? (
          <p className="mt-5">
            To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to install
            Crazy Egg on this site.
          </p>
        ) : null}
      </Paywall.Body>

      {canViewInstall ? (
        <Button component={Link} to="/install/choose-method">
          Install Crazy Egg
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
