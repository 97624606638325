export function clamp(num, min, max = Infinity) {
  return Math.max(min, Math.min(max, num));
}

// this is only used in the recordings player, it is very opinionated now and
// shouldn't be used as a generic timeseries cluster method
export function timeSeriesClusterize(
  input,
  config = {
    minDistance: 100,
    maxDistance: 1000,
    minTimeframe: 0,
    minRelevance: 0,
    timeField: 'timestamp',
    tagField: null,
    tagValues: [],
    singleClusterTags: [],
  },
) {
  const _clusters = [];
  const inputLength = input.length;

  const Cluster = (minValue, maxValue, indexes, tags) => {
    return {
      maxValue,
      minValue,
      range: (maxValue - minValue) / config.maxDistance,
      relevance: indexes.length,
      indexes,
      tags: [...tags],
    };
  };

  const addCluster = (cluster) => {
    const clusterTimeframe = cluster.maxValue - cluster.minValue;
    if (clusterTimeframe < 0) {
      throw 'Cluster timeframe cannot be negative';
    }

    if (clusterTimeframe >= config.minTimeframe && cluster.relevance >= config.minRelevance) {
      _clusters.push(cluster);
    }
  };

  const inSameCluster = (data, previousData) => {
    if (
      (config.singleClusterTags.includes(data[config.tagField]) && data.backgrounded_time > 2000) ||
      (config.singleClusterTags.includes(previousData[config.tagField]) && previousData.backgrounded_time > 2000)
    ) {
      return false;
    }
    return Math.abs(data[config.timeField] - previousData[config.timeField]) <= config.maxDistance;
  };

  let tagsInCluster = new Set();
  let idxInCluster = [];
  let minValue = null;
  let maxValue = null;

  for (const [idx, item] of input.entries()) {
    if (idx === 0) {
      minValue = item[config.timeField];
      // insert the first item into first cluster without checks
      idxInCluster.push(idx);
      if (config.tagField && item[config.tagField] && config.tagValues.includes(item[config.tagField])) {
        tagsInCluster.add(item[config.tagField]);
      }
    } else {
      maxValue = input[idx - 1][config.timeField];

      if (inSameCluster(input[idxInCluster[0]], item)) {
        // push to the current cluster
        idxInCluster.push(idx);
        if (config.tagField && item[config.tagField] && config.tagValues.includes(item[config.tagField])) {
          tagsInCluster.add(item[config.tagField]);
        }
        maxValue = item[config.timeField];

        if (idx === inputLength - 1) {
          addCluster(Cluster(minValue, maxValue, idxInCluster, tagsInCluster));
        }
      } else {
        // current item is in new cluster
        maxValue = input[idx - 1][config.timeField];

        addCluster(Cluster(minValue, maxValue, idxInCluster, tagsInCluster));

        // clear data for new cluser
        minValue = item[config.timeField];
        maxValue = null;
        tagsInCluster.clear();

        if (config.tagField && item[config.tagField] && config.tagValues.includes(item[config.tagField])) {
          tagsInCluster.add(item[config.tagField]);
        }

        idxInCluster = [idx];
      }
    }
  }

  return _clusters;
}

export function round(num, precision) {
  return Number(`${Math.round(+`${num}e+${precision}`)}e-${precision}`);
}
