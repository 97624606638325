import { useEffect, memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { assign, actions, send, createMachine } from 'xstate';
import { atomWithMachine } from 'jotai-xstate';
import { Provider, atom, useAtom, createStore } from 'jotai';
import Honeybadger from '@honeybadger-io/js';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';
import { fromUnixTime, differenceInMilliseconds, subMilliseconds } from 'date-fns';

import { AuthErrorPage } from '/src/features/_global/pages/AuthErrorPage';
import { NoAccountsErrorPage } from '/src/features/_global/pages/NoAccountsErrorPage';
import { decodedToken, goToLogin } from '/src/utils/auth';
import { clearCookies } from '/src/utils/cookies';
import { selectivelyClearLocalStorage } from '/src/utils/storage';
import { identifyUser, clearContext as clearHoneybadgerCtx } from '/src/utils/honeybadger';
import { getShareTokenPayload, getShareCodeFromUrl } from '/src/utils';
import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';
import {
  meQuery,
  sessionInfoQuery,
  sharedResourceQuery,
  capabilitiesQuery,
  subscriptionQuery,
} from '/src/features/_global/queries';
import { gettingStartedQuery } from '/src/features/getting-started/queries';
import { AppConfig } from '../AppConfig';

// states
const INITIALIZE_SHARING = 'INITIALIZE_SHARING';
const INITIALIZE_APP = 'INITIALIZE_APP';
const FETCH_TOKEN = 'FETCH_TOKEN';
const FETCH_SHARED_RESOURCE = 'FETCH_SHARED_RESOURCE';
const AUTHENTICATED = 'AUTHENTICATED';
const APP_AUTHENTICATED = 'APP_AUTHENTICATED';
const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA';
export const ERROR = 'ERROR';
export const PRESENT_SHARED_RESOURCE = 'PRESENT_SHARED_RESOURCE';
export const REDIRECT_TO_SHARED_RESOURCE = 'REDIRECT_TO_SHARED_RESOURCE';
export const SHARED_RESOURCE_NOT_FOUND = 'SHARED_RESOURCE_NOT_FOUND';
const HANDLE_SHARED_RESOURCE = 'HANDLE_SHARED_RESOURCE';

// internal actions
const INITIALIZED = 'INITIALIZED';
// const TOKEN = 'TOKEN';
const RECEIVED_SHARED_RESOURCE = 'RECEIVED_SHARED_RESOURCE';
const RETRY_FETCH_TOKEN = 'RETRY_FETCH_TOKEN';

// exposed actions
export const REFETCH_TOKEN = 'REFETCH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT';
export const RECEIVED_INITIAL_DATA = 'RECEIVED_INITIAL_DATA';
export const ERRORED_INITIAL_DATA = 'ERRORED_INITIAL_DATA';
export const REFETCH_USER_DATA = 'REFETCH_USER_DATA';

const initialAuthAtom = atom(INITIALIZE_APP); // alternatively can be `INITIALIZE_SHARING`

async function invokeFetchAuthToken() {
  try {
    const response = await fetch(`${AppConfig.authBaseURL()}/authenticate`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      redirect: 'follow',
    });
    if (response.redirected) {
      return window.location.replace(response.url);
    }
    const token = await response.json();
    if (token.access_token) {
      window.dispatchEvent(new CustomEvent('auth:received_token', { detail: { token } }));
    }
    return token;
  } catch {
    throw new Error('User auth fetch failed');
  }
}

async function performAccountSwitch(context, event) {
  const rawNewToken = await fetch(`${AppConfig.authBaseURL()}/switch?account_holder_id=${event.accountId}`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  });
  return await rawNewToken.json();
}

export async function signOut() {
  return window.location.replace(`${AppConfig.authBaseURL()}/logout`);
}

function handleAccountMessage({ message }) {
  if (message === 'logout') {
    goToLogin();
  } else if (message === 'accountSwitch') {
    window.location.reload();
  }
}

const initialAuthContext = {
  token: null,
  connected: false,
  isSharing: false,
  sessionInfo: null,
  currentUser: null,
  currentAccount: null,
  sharedResource: null,
  refetchAttempts: 0,
  sharingCode: null,
  msUntilTokenExpiration: null,
  permittedAccounts: [],
};

const createAuthMachine = (initial) => {
  return createMachine(
    {
      id: 'auth',
      // https://xstate.js.org/docs/guides/actions.html
      predictableActionArguments: true,
      preserveActionOrder: true,
      initial,
      context: initialAuthContext,
      states: {
        // initial states
        [INITIALIZE_APP]: {
          on: {
            [INITIALIZED]: {
              actions: [
                assign({
                  isSharing: false,
                }),
              ],
              target: FETCH_TOKEN,
            },
          },
        },
        [INITIALIZE_SHARING]: {
          on: {
            [INITIALIZED]: {
              actions: [
                assign({
                  isSharing: true,
                }),
              ],
              target: FETCH_SHARED_RESOURCE,
            },
          },
        },
        // loading states
        [FETCH_TOKEN]: {
          invoke: {
            id: 'fetch-auth-token',
            src: invokeFetchAuthToken,
            onDone: [
              {
                target: AUTHENTICATED,
                cond: 'isValidJwt',
                actions: [
                  assign({
                    refetchAttempts: 0,
                    token: (context, event) => event.data?.access_token,
                    tokenPayload: (context, event) =>
                      event.data?.access_token ? decodedToken(event.data.access_token) : null,
                    permittedAccounts: (context, event) => event.data?.permitted_accounts ?? [],
                  }),
                  assign({
                    msUntilTokenExpiration: (context) => {
                      const localTime = new Date();
                      const serverTime = fromUnixTime(context.tokenPayload.iat);
                      const clockSkewMs = differenceInMilliseconds(localTime, serverTime);
                      const adjustedLocalTime = subMilliseconds(localTime, clockSkewMs);
                      return differenceInMilliseconds(fromUnixTime(context.tokenPayload.exp), adjustedLocalTime);
                    },
                  }),
                  (context, event) => {
                    window.localStorage.setItem(AUTH_TOKEN_KEY, event.data?.access_token);
                  },
                ],
              },
              { target: UNAUTHENTICATED },
            ],
            onError: [
              {
                target: RETRY_FETCH_TOKEN,
                cond: 'threeRefetchAttempts',
              },
              {
                target: ERROR,
              },
            ],
          },
        },
        [RETRY_FETCH_TOKEN]: {
          after: {
            REFETCH_DELAY: {
              actions: [
                assign({
                  refetchAttempts: (context) => context.refetchAttempts + 1,
                }),
              ],
              target: FETCH_TOKEN,
            },
          },
        },
        RETRY_FETCH_INITIAL_DATA: {
          after: {
            REFETCH_DELAY: {
              actions: [
                assign({
                  refetchAttempts: (context) => context.refetchAttempts + 1,
                }),
              ],
              target: FETCH_INITIAL_DATA,
            },
          },
        },
        [FETCH_SHARED_RESOURCE]: {
          entry: [
            assign({
              sharingCode: getShareCodeFromUrl() || getShareTokenPayload()?.shared_item?.code,
            }),
            actions.pure((context) => {
              if (!context.sharingCode || context.sharingCode === '') {
                return send('SHARED_RESOURCE_NOT_FOUND');
              }
            }),
          ],
          on: {
            [RECEIVED_SHARED_RESOURCE]: {
              target: HANDLE_SHARED_RESOURCE,
              actions: [
                assign({
                  sharedResource: (context, event) => event.data?.sharedResource,
                }),
              ],
            },
            [SHARED_RESOURCE_NOT_FOUND]: {
              target: SHARED_RESOURCE_NOT_FOUND,
            },
          },
        },
        [HANDLE_SHARED_RESOURCE]: {
          always: [
            {
              target: PRESENT_SHARED_RESOURCE,
              cond: 'hasPresentableSharedResource',
            },
            {
              target: REDIRECT_TO_SHARED_RESOURCE,
              cond: 'hasRedirectableSharedResource',
            },
            { target: SHARED_RESOURCE_NOT_FOUND },
          ],
        },
        [PRESENT_SHARED_RESOURCE]: {
          on: {},
          entry: [
            assign({
              token: (ctx) => ctx.sharedResource.resource.authToken,
            }),
            (ctx) => {
              window.shr = ctx.sharedResource.resource.authToken;
            },
          ],
        },
        [REDIRECT_TO_SHARED_RESOURCE]: {
          type: 'final',
          entry: [
            (context) => {
              const searchParams = new URLSearchParams(window.location.search);
              if (Array.from(searchParams.keys()).length > 0) {
                window.location.href = `${context.sharedResource.resource.url}?${searchParams.toString()}`;
              } else {
                window.location.href = context.sharedResource.resource.url;
              }
            },
          ],
        },
        [SHARED_RESOURCE_NOT_FOUND]: {
          type: 'final',
        },
        [FETCH_INITIAL_DATA]: {
          on: {
            [RECEIVED_INITIAL_DATA]: [
              {
                actions: [
                  assign({
                    refetchAttempts: 0,
                    currentUser: (context, event) => ({
                      ...event.data.me,
                      accounts: event.data.sessionInfo.isImpersonated
                        ? event.data.me.accounts
                        : event.data.me.accounts.filter((acc) => context.permittedAccounts.includes(acc.id)),
                    }),
                    sessionInfo: (context, event) => event.data.sessionInfo,
                    currentAccount: (context, event) => event.data.me.accounts.find((account) => account.isCurrent),
                    capabilities: (context, event) => event.data.capabilities,
                    subscription: (context, event) => event.data.subscription,
                    gettingStarted: (context, event) => event.data.gettingStarted,
                  }),
                ],
                target: AUTHENTICATED,
                cond: 'hasValidInitialData',
              },
              {
                // the payload wasn't valid, assume error
                target: 'RETRY_FETCH_INITIAL_DATA',
                cond: 'threeRefetchAttempts',
              },
              {
                target: 'ERROR',
              },
            ],
            [ERRORED_INITIAL_DATA]: [
              {
                target: 'RETRY_FETCH_INITIAL_DATA',
                cond: 'threeRefetchAttempts',
              },
              {
                target: 'ERROR',
              },
            ],
            NO_ACCOUNTS: 'ACCOUNT_ERROR',
          },
        },
        // final states
        [AUTHENTICATED]: {
          always: [
            {
              target: FETCH_INITIAL_DATA,
              cond: 'sessionNotLoaded',
            },
            {
              target: APP_AUTHENTICATED,
            },
          ],
        },
        [APP_AUTHENTICATED]: {
          entry: [
            (context) => {
              identifyUser({
                userId: context.currentUser.userId,
                guestId: context.currentUser.guestId,
                email: context.currentUser.email,
                currentAccount: context.currentAccount,
              });

              if (window.CE2?.identify) {
                try {
                  window.CE2.identify(context.currentUser.email);
                } catch {
                  Honeybadger.notify('User script failed to identify', { context: { script: { ...window.CE2 } } });
                }
              } else {
                window.CE_READY = function () {
                  try {
                    window.CE2.identify(context.currentUser.email);
                  } catch {
                    Honeybadger.notify('User script missing identify function', {
                      context: { script: { ...window.CE2 } },
                    });
                  }
                };
              }
            },
          ],
          invoke: {
            src: () => (sendBack) => {
              function refetchAuth() {
                sendBack({ type: REFETCH_TOKEN });
              }
              window.addEventListener('auth:refetch_token', refetchAuth);
              return () => window.removeEventListener('auth:refetch_token', refetchAuth);
            },
          },
          on: {
            ONLINE: {
              target: FETCH_TOKEN,
            },
            [REFETCH_TOKEN]: {
              target: FETCH_TOKEN,
            },
            [REFETCH_USER_DATA]: {
              target: FETCH_INITIAL_DATA,
            },
            [LOGOUT]: {
              target: UNAUTHENTICATED,
            },
            [SWITCH_ACCOUNT]: {
              target: SWITCH_ACCOUNT,
            },
            [RECEIVED_INITIAL_DATA]: {
              actions: [
                assign({
                  currentUser: (context, event) => ({
                    ...event.data.me,
                    accounts: event.data.sessionInfo.isImpersonated
                      ? event.data.me.accounts
                      : event.data.me.accounts.filter((acc) => context.permittedAccounts.includes(acc.id)),
                  }),
                  sessionInfo: (context, event) => event.data.sessionInfo,
                  currentAccount: (context, event) => event.data.me.accounts.find((account) => account.isCurrent),
                  capabilities: (context, event) => event.data.capabilities,
                  subscription: (context, event) => event.data.subscription,
                  gettingStarted: (context, event) => event.data.gettingStarted,
                }),
              ],
              cond: 'hasValidInitialData',
            },
          },
          after: {
            TOKEN_EXPIRATION: {
              target: FETCH_TOKEN,
            },
          },
        },
        [SWITCH_ACCOUNT]: {
          invoke: {
            id: 'account-switch',
            src: performAccountSwitch,
            onDone: {
              // target: FETCH_INITIAL_DATA,
              actions: [
                assign({
                  token: (context, event) => event?.data?.access_token || event?.data?.token,
                  permittedAccounts: (context, event) => event?.data?.permitted_accounts ?? [],
                }),
                (ctx) => {
                  window.localStorage.setItem(AUTH_TOKEN_KEY, ctx.token);
                  // ensure Urql cache is cleared
                  window.dispatchEvent(new Event('account:refetch'));
                  window.accountChannel?.postMessage?.({
                    message: 'accountSwitch',
                  });
                  // wait 200ms and then do full page reloads.
                  // TODO: replace this with Apollo
                  window.location.reload();
                },
              ],
            },
          },
        },
        [UNAUTHENTICATED]: {
          type: 'final',
          entry: [
            assign(initialAuthContext),
            () => {
              clearHoneybadgerCtx();
              clearCookies();
              selectivelyClearLocalStorage();
              window.sessionStorage.clear();
              window.accountChannel?.postMessage?.({
                message: 'logout',
              });
              signOut();
            },
          ],
        },
        ACCOUNT_ERROR: {
          type: 'final',
        },
        [ERROR]: {
          type: 'final',
          entry: [
            assign({
              refetchAttempts: 0,
            }),
            // we could do some Honeybadger reporting here
          ],
        },
      },
    },
    {
      delays: {
        TOKEN_EXPIRATION: (context) => {
          // fetch 5s before the token expires
          if (context.msUntilTokenExpiration <= 5000) {
            // immediately trigger expiration
            return 0;
          }
          return context.msUntilTokenExpiration - 5000;
        },
        REFETCH_DELAY: (ctx) => {
          return ctx.refetchAttempts * 1000;
        },
      },
      guards: {
        isValidJwt: (context, event) => {
          try {
            if (event.data.access_token) {
              const tokenPayload = decodedToken(event.data.access_token);

              if (event.data.permitted_accounts.includes(tokenPayload.sub)) {
                return true;
              }
            }
            return false;
          } catch {
            return false;
          }
        },
        threeRefetchAttempts: (context) => context.refetchAttempts < 3,
        sessionNotLoaded: (context) => !context.currentUser,
        hasPresentableSharedResource: (context) => {
          return context.sharedResource.type === 'RESOURCE';
        },
        hasRedirectableSharedResource: (context) => {
          return context.sharedResource.type === 'REDIRECT';
        },
        hasValidInitialData: (context, e) => {
          return (
            e?.data?.sessionInfo &&
            e?.data?.me &&
            e?.data?.capabilities &&
            e?.data.subscription &&
            e?.data?.gettingStarted &&
            e?.data?.me?.accounts &&
            e.data.me.accounts.length > 0 &&
            e.data.me.accounts.some((account) => account.isCurrent)
          );
        },
      },
    },
  );
};

// this is the only way you should interact with the authMachine in any way from
// anywhere in the app.
export const authAtom = atomWithMachine((get) => createAuthMachine(get(initialAuthAtom)), {
  devTools: import.meta.env.DEV,
});

// The Jotai provider in this component is an isolated tree,
// it also provides the initial values to the contained machine.
// It is wrapped in a Suspense component because there are
// async behaviours contained inside.
// NOTE: please be sure that there is only ever one AuthProvider in the tree at any given moment.
export const AuthProvider = memo(({ children }) => {
  // determine if this is a sharing session
  const isSharedSession = window.location.hostname.startsWith('share.');
  const store = createStore();
  store.set(initialAuthAtom, isSharedSession ? INITIALIZE_SHARING : INITIALIZE_APP);

  return (
    <Provider store={store}>
      <AuthFlow>{children}</AuthFlow>
    </Provider>
  );
});

function AdminAuthFlow() {
  useEffect(() => {
    // remove anything that might hinder impesonation sessions
    window.sessionStorage.clear();
    window.localStorage.clear();

    // get redirectTo from query params and include any other params in the destinatin url
    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirectTo');
    const destination = new URL(redirectTo || '/', window.location.origin);
    params.forEach((value, key) => {
      if (key === 'redirectTo') return;
      destination.searchParams.set(key, value);
    });
    window.location.replace(destination.toString());
  }, []);

  return <LoaderBalloonScreen text={`Preparing impersonation...`} />;
}

function UserAuthFlow({ children }) {
  // ensure the machine starts up
  const [state, send] = useAtom(authAtom);

  useEffect(() => {
    if (window.location.search.indexOf('debug=auth') >= 0) {
      Honeybadger.notify('Auth debug', {
        context: {
          value: state.value,
          state: state.context,
        },
      });
    }
  }, [state]);

  const enabled = [FETCH_INITIAL_DATA, APP_AUTHENTICATED].includes(state.value);

  const meResult = useQuery({
    ...meQuery(),
    enabled,
  });
  const capabilitiesResult = useQuery({
    ...capabilitiesQuery(),
    enabled,
  });
  const subscriptionResult = useQuery({
    ...subscriptionQuery(),
    enabled,
  });
  const sessionInfoResult = useQuery({
    ...sessionInfoQuery(),
    enabled,
  });
  const gettingStartedResult = useQuery({
    ...gettingStartedQuery(),
    enabled,
  });

  const sharedResourceResult = useQuery({
    ...sharedResourceQuery({ sharingCode: state.context.sharingCode }),
    enabled: Boolean(state.value === FETCH_SHARED_RESOURCE && state.context.sharingCode),
  });

  useEffect(() => {
    if (state.value === FETCH_INITIAL_DATA && meResult?.data?.me?.accounts?.length === 0) {
      send('NO_ACCOUNTS');
    }
  }, [meResult?.data?.me?.accounts?.length, state.value, send]);

  useEffect(() => {
    if (state.value === INITIALIZE_APP || state.value === INITIALIZE_SHARING) {
      send({
        type: INITIALIZED,
      });
    }
  }, [send, state.value]);

  useEffect(() => {
    if (
      state.value === FETCH_INITIAL_DATA &&
      (meResult.error ||
        sessionInfoResult.error ||
        capabilitiesResult.error ||
        subscriptionResult.error ||
        gettingStartedResult.error)
    ) {
      const error = {
        ...meResult.error,
        ...sessionInfoResult.error,
        ...capabilitiesResult.error,
        ...subscriptionResult.error,
        ...gettingStartedResult.error,
      };
      const message = error?.graphQLErrors?.[0]?.originalError?.message;
      if (message && !['User not found'].some((msg) => message.indexOf(msg) > -1)) {
        Honeybadger.notify('Initial data fetch error', {
          context: {
            local_time: new Date().getTime(),
            current_token: window.localStorage.getItem(AUTH_TOKEN_KEY),
            server_response: JSON.stringify(error),
            message,
            meError: meResult?.error ?? null,
            capabilitiesError: capabilitiesResult.error ?? null,
            subscriptionError: subscriptionResult.error ?? null,
            sessionInfoError: sessionInfoResult?.error ?? null,
            gettingStartedError: gettingStartedResult?.error ?? null,
            pathname: window.location.pathname,
          },
        });
      }
      send({
        type: ERRORED_INITIAL_DATA,
        error,
      });
    }
  }, [
    meResult.error,
    sessionInfoResult.error,
    capabilitiesResult.error,
    subscriptionResult.error,
    gettingStartedResult.error,
    state.value,
    send,
  ]);

  useEffect(() => {
    if (
      [FETCH_INITIAL_DATA, APP_AUTHENTICATED].includes(state.value) &&
      meResult.data &&
      sessionInfoResult.data &&
      capabilitiesResult.data &&
      subscriptionResult.data &&
      gettingStartedResult.data
    ) {
      send({
        type: RECEIVED_INITIAL_DATA,
        data: {
          ...meResult.data,
          ...sessionInfoResult.data,
          ...capabilitiesResult.data,
          ...subscriptionResult.data,
          ...gettingStartedResult.data,
        },
      });
    }
  }, [
    meResult.data,
    sessionInfoResult.data,
    capabilitiesResult.data,
    subscriptionResult.data,
    gettingStartedResult.data,
    state.value,
    send,
  ]);

  useEffect(() => {
    if (state.value === FETCH_SHARED_RESOURCE && sharedResourceResult?.data) {
      send({
        type: RECEIVED_SHARED_RESOURCE,
        data: sharedResourceResult.data,
      });
    } else if (state.value === FETCH_SHARED_RESOURCE && sharedResourceResult?.error) {
      send({
        type: SHARED_RESOURCE_NOT_FOUND,
      });
    }
  }, [send, sharedResourceResult?.data, sharedResourceResult?.error, state.value]);

  useEffect(() => {
    window.accountChannel?.addEventListener?.('message', handleAccountMessage);

    return () => window.accountChannel?.removeEventListener?.('message', handleAccountMessage);
  }, []);

  useEffect(() => {
    function sendOnline() {
      send('ONLINE');
    }

    function sendOffline() {
      send('OFFLINE');
    }

    function handleTokenError() {
      send(REFETCH_TOKEN);
    }

    window.addEventListener('offline', sendOffline);
    window.addEventListener('online', sendOnline);
    window.addEventListener('gql:tokenError', handleTokenError);

    return () => {
      window.removeEventListener('offline', sendOffline);
      window.removeEventListener('online', sendOnline);
      window.removeEventListener('gql:tokenError', handleTokenError);
    };
  }, [send]);

  // we can declare global type of listeners here
  // but please keep them to a minimum 😅

  if (
    [
      SWITCH_ACCOUNT,
      FETCH_INITIAL_DATA,
      FETCH_SHARED_RESOURCE,
      'RETRY_FETCH_INITIAL_DATA',
      'RETRY_FETCH_TOKEN',
    ].includes(state.value)
  ) {
    return (
      <>
        {state.context.refetchAttempts > 0 && state.context.refetchAttempts <= 3 ? (
          <LoaderBalloonScreen text={`Retry ${state.context.refetchAttempts} of 3...`} />
        ) : state.context.refetchAttempts > 0 ? (
          <LoaderBalloonScreen text={false} />
        ) : (
          <LoaderBalloonScreen text={true} />
        )}
      </>
    );
  }

  if (state.value === 'ACCOUNT_ERROR') {
    return <NoAccountsErrorPage />;
  }

  if (state.value === ERROR) {
    return <AuthErrorPage />;
  }

  if (!state.context?.currentUser && !state.context.isSharing) {
    return <LoaderBalloonScreen text={true} />;
  }

  return children;
}

function AuthFlow({ children }) {
  const { pathname } = window.location;

  if (pathname === '/admin/impersonate') {
    return <AdminAuthFlow />;
  }

  return <UserAuthFlow>{children}</UserAuthFlow>;
}

export function refetchAuthToken() {
  return new Promise((resolve) => {
    // dispatch the global event and wait for the new token
    window.addEventListener(
      'auth:received_token',
      (e) => {
        resolve(e.detail.token);
      },
      { once: true },
    );

    window.dispatchEvent(new CustomEvent('auth:refetch_token'));
  });
}
