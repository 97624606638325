import { Button } from '@crazyegginc/hatch';

import { AppConfig } from '/src/AppConfig';
import { useAuthContext } from '/src/hooks';
import { WebP } from '/src/components/WebP';

import BalloonPNG from '@crazyegginc/hatch/dist/images/illustration-girl-balloon.png';
import BalloonWebP from '@crazyegginc/hatch/dist/images/illustration-girl-balloon.webp';

export function NotFoundPage() {
  const { isSharing } = useAuthContext();

  function navigateDashboard() {
    window.location.replace('/');
  }

  function gotoLogin() {
    window.location.href = `${AppConfig.authBaseURL()}/login`;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex flex-col items-start justify-center">
        <h1 className="text-header-0 mb-5 font-normal">Whoops!</h1>
        <h2 className="text-header-1 mb-2">
          {isSharing ? (
            <span>The shared link is no longer available.</span>
          ) : (
            <span>The page you are looking for could not be found.</span>
          )}
        </h2>
        <div className="text-body-2">Float on back to {!isSharing ? 'the dashboard and' : ''} calmer skies.</div>
        {isSharing ? (
          <Button className="!mt-5" onClick={() => gotoLogin()} size="lg">
            Take me to the login
          </Button>
        ) : (
          <Button className="!mt-5" onClick={() => navigateDashboard()} size="lg">
            Take me back to the dashboard
          </Button>
        )}
      </div>
      <div className="flex flex-col items-start justify-center">
        <WebP webp={BalloonWebP} fallback={BalloonPNG} width="336px" height="400px" />
      </div>
    </div>
  );
}
