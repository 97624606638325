import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Tooltip, Select } from '@crazyegginc/hatch';

import { Input } from '../../../common/basic-ui';
import { SurveyLogic } from './SurveyLogic';
import {
  SURVEY_QUESTION_TYPES,
  MULTI_CHOICE_SUBTYPES,
  ADDONS_CAPABILITIES,
  RANDOMIZE_OPTIONS,
} from '/src/features/addons/constants';
import { maxInputBeforeTextArea } from './MultiChoices';

import {
  DEFAULT_NEXT_TITLE,
  DEFAULT_SKIP_TITLE,
  DEFAULT_DROPDOWN_SELECT_TEXT,
  DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT,
  getDefaultErrorMessage,
} from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as TriangleIcon } from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg';

const options = [
  { label: 'Do not randomize', value: RANDOMIZE_OPTIONS.NONE },
  { label: 'Randomize all choices', value: RANDOMIZE_OPTIONS.ALL },
  { label: 'Randomize all choices except first', value: RANDOMIZE_OPTIONS.ALL_BUT_FIRST },
  { label: 'Randomize all choices except last', value: RANDOMIZE_OPTIONS.ALL_BUT_LAST },
];

export function CustomizeOptions({ index }) {
  const { readonly, addonsCapabilities } = useEditorContext();
  const { values, errors, setFieldValue } = useFormikContext();
  const [open, setOpen] = useState(false);
  const isDropdownCustomSetting = addonsCapabilities[ADDONS_CAPABILITIES.LABEL_MULTIPLE_CHOICE];
  const q = values.questions[index];

  const hasErrorInCustomizeSection =
    errors?.questions?.[index]?.logic ||
    errors?.questions?.[index]?.nextButton ||
    errors?.questions?.[index]?.skipButton ||
    errors?.questions?.[index]?.requiredErrorMessage ||
    errors?.questions?.[index]?.randomize ||
    errors?.questions?.[index]?.dropdownSelect;

  const isLongMultiChoice = q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE && q.options.length > maxInputBeforeTextArea;

  useEffect(() => {
    if (hasErrorInCustomizeSection && !open) setOpen(true);
  }, [hasErrorInCustomizeSection, open]);

  return (
    <>
      <button className="text-body-1 flex items-center !self-start" type="button" onClick={() => setOpen((x) => !x)}>
        <TriangleIcon className={classNames('mr-2 h-2 w-2 fill-current', { '-rotate-90': !open })} />
        Customize settings
      </button>
      <div className={classNames('space-y-5 pl-5', { hidden: !open })}>
        {q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE && (
          <Select
            label="Randomize Choice Order"
            labelClassName="font-semibold !mb-1"
            options={options}
            value={values.questions[index].randomize}
            onChange={(value) => setFieldValue(`questions[${index}].randomize`, value)}
            disabled={readonly}
          />
        )}

        <div className="flex items-start">
          <NextButton index={index} />
          <SkipButtonOrErrorMessage index={index} />
        </div>
        {isLongMultiChoice && isDropdownCustomSetting && (
          <div className="flex items-start">
            <DropdownText index={index} />
            {q.subtype === MULTI_CHOICE_SUBTYPES.MULTI && <MultipleItemsText index={index} />}
          </div>
        )}

        <SurveyLogic index={index} />
      </div>
    </>
  );
}

function NextButton({ index }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  const q = values.questions[index];

  return (
    <div className="relative mr-2.5 w-1 flex-grow">
      <Input
        label={
          <div className="flex items-center space-x-2">
            <span>Next button</span>
            {!q.optional &&
              (q.type === SURVEY_QUESTION_TYPES.RATING ||
                (q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE &&
                  q.subtype === MULTI_CHOICE_SUBTYPES.SINGLE &&
                  q.options.length <= 5)) && (
                <>
                  <span className="text-body-3 text-xs">- Where is this?</span>
                  <Tooltip
                    tooltipContent={
                      <div className="max-w-xs">
                        To meet WCAG AAA accessibility standards your survey is fully navigable via the keyboard. If a
                        visitor starts using their keyboard to navigate your survey the Next button will automatically
                        appear.
                      </div>
                    }
                  >
                    <InfoIcon
                      aria-label="information on next button"
                      className="h-3 w-3 fill-current text-dodger-blue-300"
                    />
                  </Tooltip>
                </>
              )}
          </div>
        }
        id={`questions[${index}].nextButton.title`}
        name={`questions[${index}].nextButton.title`}
        value={values.questions[index].nextButton.title}
        error={
          errors.questions?.[index]?.nextButton?.title && touched.questions?.[index]?.nextButton?.title
            ? errors.questions[index].nextButton.title
            : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => {
          if (e.target.value === DEFAULT_NEXT_TITLE) {
            e.target.select();
          }
        }}
        disabled={readonly}
      />
    </div>
  );
}

function SkipButtonOrErrorMessage({ index }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  const q = values.questions[index];

  return (
    <div className="ml-2.5 w-1 flex-grow">
      {q.optional ? (
        <Input
          label="Skip button"
          id={`questions[${index}].skipButton.title`}
          name={`questions[${index}].skipButton.title`}
          value={values.questions[index].skipButton.title}
          error={
            errors.questions?.[index]?.skipButton?.title && touched.questions?.[index]?.skipButton?.title
              ? errors.questions[index].skipButton.title
              : null
          }
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={(e) => {
            if (e.target.value === DEFAULT_SKIP_TITLE) {
              e.target.select();
            }
          }}
          disabled={readonly}
        />
      ) : (
        <>
          {q.type !== SURVEY_QUESTION_TYPES.STATIC_DISPLAY && (
            <Input
              label={
                <div className="flex items-center space-x-2">
                  <span>Error message</span>
                  <span className="text-body-3 text-xs">- Where is this?</span>
                  <Tooltip
                    tooltipContent={
                      <div className="max-w-xs">
                        This text appears when a visitor uses the Next button without answering this question. If you
                        don&apos;t see the Next button, click your survey and press the “tab” key on your keyboard.
                        Alternatively you can make this question optional by clicking the “Required” toggle above.
                      </div>
                    }
                  >
                    <InfoIcon
                      aria-label="information on error message"
                      className="h-3 w-3 fill-current text-dodger-blue-300"
                    />
                  </Tooltip>
                </div>
              }
              id={`questions[${index}].requiredErrorMessage`}
              name={`questions[${index}].requiredErrorMessage`}
              value={values.questions[index].requiredErrorMessage}
              error={errors.questions?.[index]?.requiredErrorMessage}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={(e) => {
                if (e.target.value === getDefaultErrorMessage(q.type, q.subtype)) {
                  e.target.select();
                }
              }}
              disabled={readonly}
            />
          )}
        </>
      )}
    </div>
  );
}

function DropdownText({ index }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  return (
    <div className="relative w-1/2 pr-2.5">
      <Input
        label={
          <div className="flex items-center space-x-2">
            <span>Select answer</span>
          </div>
        }
        id={`questions[${index}].dropdownSelect.defaultText`}
        name={`questions[${index}].dropdownSelect.defaultText`}
        value={values.questions[index].dropdownSelect.defaultText}
        error={
          errors.questions?.[index]?.dropdownSelect?.defaultText &&
          touched.questions?.[index]?.dropdownSelect?.defaultText
            ? errors.questions[index].dropdownSelect.defaultText
            : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => {
          if (e.target.value === DEFAULT_DROPDOWN_SELECT_TEXT) {
            e.target.select();
          }
        }}
        disabled={readonly}
      />
    </div>
  );
}

function MultipleItemsText({ index }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  return (
    <div className="relative w-1/2 pl-2.5">
      <Input
        label={
          <div className="flex items-center space-x-2">
            <span>Multiple items selected</span>
          </div>
        }
        id={`questions[${index}].dropdownSelect.multipleItemsText`}
        name={`questions[${index}].dropdownSelect.multipleItemsText`}
        value={values.questions[index].dropdownSelect.multipleItemsText}
        error={
          errors.questions?.[index]?.dropdownSelect?.multipleItemsText &&
          touched.questions?.[index]?.dropdownSelect?.multipleItemsText
            ? errors.questions[index].dropdownSelect.multipleItemsText
            : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => {
          if (e.target.value === DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT) {
            e.target.select();
          }
        }}
        disabled={readonly}
      />
    </div>
  );
}
