import { VariantEditorWizard, NullEditorWizard } from '/src/features/ab-testing/components/VariantEditorWizard-v2';
import { useSelector } from '@xstate5/react';

// eslint-disable-next-line no-restricted-syntax
export default function AbTestVariantEditorPage({ actorRef }) {
  const children = useSelector(actorRef, (snapshot) => snapshot.children);
  const nextText = useSelector(actorRef, (snapshot) => snapshot.context.nextText);

  if (children && children.variantMachine) {
    return (
      <VariantEditorWizard
        actorRef={children.variantMachine}
        enableDraft={false}
        nextText={nextText ? nextText : 'Next'}
        backEnabled={false}
      />
    );
  }

  return <NullEditorWizard />;
}
