import { useLocation, Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import { WizardHeader } from '../../../../components/wizard/legacy/wizard-ui';
import { WebP } from '/src/components/WebP';
import SinglePNG from '@crazyegginc/hatch/dist/images/illustration-snapshot-single.png';
import SingleWebP from '@crazyegginc/hatch/dist/images/illustration-snapshot-single.webp';
import MultiPNG from '@crazyegginc/hatch/dist/images/illustration-snapshots.png';
import MultiWebP from '@crazyegginc/hatch/dist/images/illustration-snapshots.webp';

import CartPNG from '@crazyegginc/hatch/dist/images/illustration-snapshot-cart.png';
import CartWebP from '@crazyegginc/hatch/dist/images/illustration-snapshot-cart.webp';
import LoginPNG from '@crazyegginc/hatch/dist/images/illustration-snapshot-login.png';
import LoginWebP from '@crazyegginc/hatch/dist/images/illustration-snapshot-login.webp';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

import { generateInitialSnapshotSchema } from './Wizard';

export function SnapshotSelection() {
  const formik = useFormikContext();
  const location = useLocation();
  const isSingle = location.pathname.indexOf('/single') > -1;
  const isBulk = location.pathname.indexOf('/bulk') > -1;
  const isCheckout = location.pathname.indexOf('/checkout') > -1;
  const isLogin = location.pathname.indexOf('/login') > -1;
  const isPageCameraSnapshot = formik.values.pageCameraSnapshot;

  function setUpSingle() {
    if (formik.values.snapshots.length > 1) {
      formik.setFieldValue('snapshots', [formik.values.snapshots[0]]);
    }
  }

  function setUpBulk() {
    if (formik.values.snapshots.length < 2) {
      formik.setFieldValue('snapshots', [formik.values.snapshots[0], generateInitialSnapshotSchema()]);
    }
  }

  return (
    <section>
      <WizardHeader>{isSingle ? `Let’s make a Snapshot!` : `Let’s make some Snapshots!`}</WizardHeader>
      {!isPageCameraSnapshot ? (
        <>
          <h2 className="text-header-2 mb-2.5">I want to create:</h2>
          <div className="mb-2.5 flex justify-between">
            <SelectionBox
              target="/snapshots/new/bulk"
              onClick={setUpBulk}
              image={<WebP webp={MultiWebP} fallback={MultiPNG} width="129px" height="110px" alt="Multi snapshot" />}
              title="Snapshots for multiple pages"
              selected={isBulk}
            />
            <SelectionBox
              target="/snapshots/new/single"
              onClick={setUpSingle}
              image={<WebP webp={SingleWebP} fallback={SinglePNG} width="125px" height="90px" alt="Single snapshot" />}
              title="Snapshot for single page"
              selected={isSingle}
            />
          </div>
          <div className="mb-10 flex justify-between">
            <SelectionBox
              target="/snapshots/new/checkout"
              onClick={() => {}}
              image={<WebP webp={CartWebP} fallback={CartPNG} width="111px" height="80px" alt="Shopping cart" />}
              title="Snapshot for SHOPPING CART"
              selected={isCheckout}
              className="!h-[120px] !flex-row !px-7 !pb-1.25"
            />
            <SelectionBox
              target="/snapshots/new/login"
              onClick={() => {}}
              image={<WebP webp={LoginWebP} fallback={LoginPNG} width="111px" height="80px" alt="Login page" />}
              title="Snapshot for PAGES BEHIND A LOGIN"
              selected={isLogin}
              className="!h-[120px] !flex-row !px-7 !pb-1.25"
            />
          </div>
        </>
      ) : null}
    </section>
  );
}

function SelectionBox({ target, onClick, image, title, selected, className }) {
  return (
    <Link to={target} replace>
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          'relative mt-2.5 flex h-[170px] w-[350px] flex-col items-center justify-between overflow-hidden rounded p-1.25 pb-4',
          'hover:scale-[1.02] hover:shadow-md',
          className,
          {
            'border-2 border-dodger-blue-500': selected,
            'border border-mystic-500': !selected,
          },
        )}
      >
        <div className="flex shrink-0 grow items-center justify-center">{image}</div>
        <div className="text-body-1 text-left">{title}</div>
        {selected && <TickIcon className="absolute right-2.5 top-2.5 h-5 w-5 fill-current text-dodger-blue-500" />}
      </button>
    </Link>
  );
}
