import { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';

import { VariantWeightsPanel } from '/src/features/ab-testing/components/VariantWeightsPanel';

export function AbTestWeightsPage({ context }) {
  const { abTestService } = useContext(context);
  const [state] = useActor(abTestService);
  const { send } = abTestService;

  useEffect(() => {
    if (state.context.payload.autoReweight === null) {
      send({ type: 'SET_REWEIGHT', value: true }); // set initial value in draft state
    }
  }, [send, state.context.payload.autoReweight]);

  return (
    <div className="mx-auto flex w-[710px] flex-col space-y-10">
      <h1 className="text-header-0 m-0 mt-14 text-center">How do you want to split your traffic?</h1>

      <h2 className="text-header-2 mb-10">Automatically split the traffic or manually set the traffic split.</h2>

      <div className="space-y-5">
        <VariantWeightsPanel
          abTest={state.context.payload}
          onSelect={(value) => send({ type: 'SET_REWEIGHT', value })}
          onChange={(value) => send({ type: 'ADJUST_WEIGHTS', value })}
        />
      </div>
    </div>
  );
}
