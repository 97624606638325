import { useCallback } from 'react';

import { useSearchParams } from './useSearchParams';
import { getStorageKey } from '/src/utils/location';

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const get = useCallback(
    (key, fallback) => {
      const value = searchParams.get(key);
      const storedValue = window.localStorage.getItem(`shell:${key}`);

      return value || storedValue || fallback;
    },
    [searchParams],
  );

  // TODO: we can optimize this to not update the url if the value is already set
  // but doing so breaks a bunch of tests.
  const set = useCallback(
    (key, value, persist = false, keyPersistPrefix) => {
      if (persist && value) {
        window.localStorage.setItem(getStorageKey(key, keyPersistPrefix), value);
      } else if (persist && !value) {
        window.localStorage.removeItem(getStorageKey(key, keyPersistPrefix));
      }
      setSearchParams(
        (x) => {
          if (!value) {
            x.delete(key);
          } else {
            x.set(key, value);
          }
          return x;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const setAll = useCallback(
    (state, opts) => {
      /*
        Usage:
        setAll({ elapsedTime: 0, persist: true });
        Results in a URL with:
        ?elapsedTime=0&persist=true
      */
      setSearchParams(
        (x) => {
          for (const [key, value] of Object.entries(state)) {
            const shouldPersist = (opts?.persistKeys ?? []).includes(key) || (opts?.persist ?? false);
            if (value === undefined || value === null) {
              x.delete(key);
              if (shouldPersist) {
                localStorage.removeItem(getStorageKey(key, opts?.keyPersistPrefix));
              }
            } else {
              x.set(key, value);
              if (shouldPersist) {
                localStorage.setItem(getStorageKey(key, opts?.keyPersistPrefix), value);
              }
            }
          }
          return x;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const remove = useCallback(
    (key, persist = false, keyPersistPrefix) => {
      setSearchParams(
        (x) => {
          x.delete(key);
          if (persist) {
            window.localStorage.removeItem(getStorageKey(key, keyPersistPrefix));
          }
          return x;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const removeAll = useCallback(
    (keys, persist, keyPersistPrefix) => {
      setSearchParams(
        (x) => {
          for (const key of keys) {
            x.delete(key);
            if (persist) {
              window.localStorage.removeItem(getStorageKey(key, keyPersistPrefix));
            }
          }
          return x;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  function getAll() {
    return Object.fromEntries(searchParams);
  }

  return {
    get,
    getAll,
    set,
    setAll,
    remove,
    removeAll,
  };
}
