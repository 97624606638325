import { Button, Panel } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';

import { OptionsHeader } from '../../options/components/OptionsHeader';
import { AuditLogContent } from './AuditLogContent';
import { SupportLinks } from '/src/support';
import { FEATURES } from '/src/features/_global/constants';

export function AuditLogDashboard() {
  const permissions = usePermissions();
  const canViewAuditLog = permissions.can('view', FEATURES.AUDIT_LOG).allowed;

  if (!canViewAuditLog) {
    return (
      <>
        <OptionsHeader>Audit Log</OptionsHeader>
        <Panel className="py-[30px]">
          <div className="text-body-2 mb-7 leading-snug">
            Crazy Egg Audit Log provides you with a security-relevant chronological record of changes made in your
            account. Find out which team members viewed data, deleted data, and updated settings. Available for
            enterprise plans only.
          </div>
          <Button onClick={() => window.open(SupportLinks.general.newSupportRequest, '_blank')} className="self-start">
            Contact sales
          </Button>
        </Panel>
      </>
    );
  }

  return <AuditLogContent />;
}
