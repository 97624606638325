/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Popover, Tooltip, StyledPopoverPanel, Toggle, IconButton } from '@crazyegginc/hatch';
import { ReactComponent as EyeIcon } from '@crazyegginc/hatch/dist/images/icon-unwatched-outline.svg';

import { CommonActions } from '../CommonActions';

const columnVisibilityId = 'column-visibility';

export function ActionsRow({ survey, refetch }) {
  return (
    <div className="mb-3.75 flex items-center justify-between">
      <div className="flex items-center space-x-2.5">
        <CommonActions
          survey={survey}
          refresh={() => {
            refetch({ requestPolicy: 'network-only' });
          }}
        />
        <div id={columnVisibilityId}></div>
      </div>
    </div>
  );
}

// this is passed in to the table and rendered back with a portal
export function ColumnVisibility({ allColumns, columnVisibility }) {
  const [infoShown, setInfoShown] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const numOfHiddenColumns = Object.values(columnVisibility).reduce((acc, curr) => (curr === false ? acc + 1 : acc), 0);

  useEffect(() => {
    if (!infoShown && numOfHiddenColumns > 0) {
      setInfoShown(true);
      setShowInfo(true);
    }
  }, [infoShown, numOfHiddenColumns]);

  const targetDiv = document.querySelector(`#${columnVisibilityId}`);

  if (!targetDiv) {
    return null;
  }

  return createPortal(
    <Popover className="relative">
      {({ open }) => (
        <>
          <Tooltip tooltipContent="Column visibility" show={!showInfo}>
            <Tooltip
              tooltipContent="Some columns are hidden. You can always re-enable them here."
              show={showInfo}
              onDismiss={() => setShowInfo(false)}
              hover={false}
            >
              <Popover.Button
                as={IconButton}
                className={classNames('h-[35px] w-[35px] rounded hover:bg-solitude-500', {
                  'bg-solitude-500': open,
                })}
                iconClass="!text-dodger-blue-500"
                icon={EyeIcon}
                label="Column visibility"
              />
            </Tooltip>
          </Tooltip>

          <StyledPopoverPanel align="center" className="w-80 space-y-2.5 !px-6 !py-7">
            {allColumns
              .filter((c) => c.getCanHide())
              .map((column) => {
                return (
                  <div key={column.id} className="flex items-center justify-between space-x-2.5">
                    <label
                      htmlFor={column.id}
                      className="text-body-2 truncate"
                      dangerouslySetInnerHTML={{ __html: column.columnDef.header }}
                    />
                    <Toggle
                      id={column.id}
                      enabled={column.getIsVisible()}
                      setEnabled={() => {
                        setInfoShown(true);
                        column.toggleVisibility();
                      }}
                      label={column.columnDef.header}
                      size="sm"
                    />
                  </div>
                );
              })}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>,
    targetDiv,
  );
}
