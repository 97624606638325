import classNames from 'classnames';
import { Button, Checkbox } from '@crazyegginc/hatch';

import { ReactComponent as PauseIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';

export function PausePlayButton({ paused, setPaused, pauseOnHover, setPauseOnHover, waitingToShow }) {
  return (
    <div className="mb-5">
      <div
        className={classNames('flex items-center', {
          'rounded-[5px] border border-mystic-500': !paused,
          'rounded-t-[5px] border-2 border-regal-blue-500': paused,
        })}
      >
        <div className="border-r border-mystic-500 p-3.75">
          <Button size="lg" onClick={() => setPaused((x) => !x)}>
            {paused ? (
              <PlayIcon className="h-3 w-3 fill-current" aria-label="Resume activity stream" />
            ) : (
              <PauseIcon className="h-3 w-3 fill-current" aria-label="Pause activity stream" />
            )}
          </Button>
        </div>
        <div className="flex w-full items-center justify-center">
          <div>
            <Checkbox
              checked={pauseOnHover}
              onChange={() => setPauseOnHover((x) => !x)}
              label="Hover on activity to pause"
              id="hover-on-pause"
            />
          </div>
        </div>
      </div>
      {paused && (
        <div className="text-body-4 w-full rounded-b-[5px] bg-regal-blue-500 px-3.75 py-2 text-white">
          <div className="font-semibold">Activity stream paused</div>
          <div>
            {waitingToShow > 0 ? (
              <>
                Waiting to show {waitingToShow} {waitingToShow > 1 ? 'activities' : 'activity'}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
