import { Modal } from '/src/contexts/modal';
import { VideoPlayer } from '../VideoPlayer';

export function VideoTutorial({ title, description, videoSrc, videoPoster, videoLabel }) {
  return (
    <Modal dialogClassName="!w-[600px] !px-0 !pt-10">
      <VideoPlayer
        src={videoSrc}
        poster={videoPoster}
        label={videoLabel}
        controls={true}
        muted={false}
        loop={false}
        autoplay={false}
      />
      <div className="mt-7 flex w-full flex-col items-center px-7">
        <h2 className="text-header-2">{title}</h2>
        <p className="text-body-2 mt-5 text-center leading-normal">{description}</p>
      </div>
    </Modal>
  );
}
