import { useEffect, useRef, useCallback } from 'react';
import { startOfDay, endOfDay, subYears, fromUnixTime, getUnixTime } from 'date-fns';
import { Popover, Button, DateRangePicker } from '@crazyegginc/hatch';

import {
  useMutation,
  useUrqlClient,
  useAuthContext,
  useSelectedSite,
  useNotifications,
  usePermissions,
} from '/src/hooks';
import { markPlaylistAsUsedMutation } from '/src/features/recordings/mutations';

import { FilterPane } from './FilterPane';
import { CriteriaSelector } from './CriteriaSelector';
import { GroupBySelector } from './GroupBySelector';

import { getParam } from '/src/utils/location';
import { useFilter } from '../../recordings-filter-context';
import { decomposeFilter } from './filter-functions';

import { FEATURES, SPECIAL_DATE_RANGES } from '/src/features/_global/constants';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';

import { ReactComponent as FilterIcon } from '@crazyegginc/hatch/dist/images/icon-filter-filled.svg';

const groupByValues = [
  {
    value: GROUP_BY_TYPES.FIRST,
    text: 'Group by starting page',
  },
  {
    value: GROUP_BY_TYPES.LAST,
    text: 'Group by last page',
  },
  {
    value: GROUP_BY_TYPES.LIST_VIEW,
    text: 'List view',
  },
];

export function Filters() {
  const { sessionInfo, currentUser } = useAuthContext();

  const { client } = useUrqlClient();
  const permissions = usePermissions();
  const { selectedSite } = useSelectedSite();
  const notifications = useNotifications();
  const { mutate: markPlaylistUsedMutate } = useMutation(markPlaylistAsUsedMutation);
  const filtersHook = useFilter();
  const paneRef = useRef();
  const isImpersonated = sessionInfo?.isImpersonated ?? false;
  const canViewGroups = permissions.can('viewGroups', FEATURES.RECORDINGS).allowed;

  const {
    initialLoadComplete,
    filters,
    selectPlaylist,
    setFilters,
    setInitialLoadComplete,
    filtersVersion,
    addCriteria,
    recordingsCriteriaDefinitionQuery,
    playlistsListQuery,
    queryParams,
    setGroupBy,
    setDateRange,
  } = filtersHook;
  const { data: recordingsCriteriaDefinition, fetching: fetchingDefinition } = recordingsCriteriaDefinitionQuery;
  const { data: playlistsListData, fetching: fetchingPlaylists } = playlistsListQuery;

  useEffect(() => {
    // populate state if filters URL param received
    if (
      recordingsCriteriaDefinition &&
      !fetchingDefinition &&
      playlistsListData &&
      !fetchingPlaylists &&
      !initialLoadComplete
    ) {
      const filtersParam = getParam('filters');
      if (filtersParam) {
        let { conditions, playlist } = decomposeFilter({
          filtersParam,
          definitions: recordingsCriteriaDefinition.definitions,
          playlists: playlistsListData.playlistsList,
          filtersVersion,
          client,
          currentUser,
        });
        if (conditions) {
          if (playlist) {
            selectPlaylist(playlist.id);
            setFilters({ filters: JSON.parse(playlist.filter), playlist, conditions });
            if (!isImpersonated) {
              markPlaylistUsedMutate({ site: selectedSite?.id, playlist: playlist.id });
            }
          } else {
            setFilters({ filters: JSON.parse(filtersParam), conditions });
          }
        } else {
          notifications.error({ content: 'Invalid filter specified in the URL!', skipHoneybadger: true });
        }
      }
      setInitialLoadComplete();
    }
  }, [
    fetchingDefinition,
    recordingsCriteriaDefinition,
    notifications,
    selectPlaylist,
    setFilters,
    setInitialLoadComplete,
    fetchingPlaylists,
    playlistsListData,
    initialLoadComplete,
    markPlaylistUsedMutate,
    selectedSite?.id,
    client,
    filtersVersion,
    isImpersonated,
    currentUser,
  ]);

  let dateRangeValue;
  const dateRangeIndex = filters.conditions.findIndex((condition) => condition.criteria === 'date_range');
  if (dateRangeIndex >= 0) {
    dateRangeValue = filters.conditions[dateRangeIndex].values[0][0].value;
  }

  // set the default date range if there isnt one already applied
  useEffect(() => {
    if (!dateRangeValue && initialLoadComplete && recordingsCriteriaDefinition) {
      setDateRange({ special: SPECIAL_DATE_RANGES.LAST_30_DAYS });
    }
  }, [dateRangeValue, setDateRange, initialLoadComplete, recordingsCriteriaDefinition]);

  const setDateRangeWrapper = useCallback(
    (value) => {
      setDateRange({
        start_date: getUnixTime(value.start_date),
        end_date: getUnixTime(value.end_date),
      });
    },
    [setDateRange],
  );

  if (!initialLoadComplete || !recordingsCriteriaDefinition || !dateRangeValue) return null;

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex w-full items-center justify-between">
        {canViewGroups ? (
          <GroupBySelector groupBy={queryParams.groupBy} setGroupBy={setGroupBy} values={groupByValues} />
        ) : (
          <div />
        )}
        <div className="flex items-center gap-x-2.5">
          <DateRangePicker
            lowerBoundary={startOfDay(subYears(new Date(), 2))}
            upperBoundary={endOfDay(new Date())}
            startDate={dateRangeValue?.start_date && fromUnixTime(dateRangeValue.start_date)}
            endDate={dateRangeValue?.end_date && fromUnixTime(dateRangeValue.end_date)}
            special={dateRangeValue?.special}
            setDateRange={setDateRangeWrapper}
            setCommonDateRange={setDateRange}
            showCommonRanges={true}
            size="lg"
          />
          {!filters.pane.show && (
            <FilterButton
              addCriteria={addCriteria}
              filters={filters}
              definitions={recordingsCriteriaDefinition.definitions}
            />
          )}
        </div>
      </div>
      {filters.pane.show && <FilterPane filtersHook={filtersHook} ref={paneRef} />}
    </div>
  );
}

function FilterButton({ addCriteria, filters, definitions }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as={Button} size="lg" className={open ? 'bg-dodger-blue-700' : ''}>
            <FilterIcon className="mr-2.5 h-4 w-4 fill-current text-white" aria-label="filter" />
            Filter
          </Popover.Button>
          <CriteriaSelector
            addCriteria={addCriteria}
            conditions={filters.conditions}
            definitions={definitions}
            align="right"
          />
        </>
      )}
    </Popover>
  );
}
