import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { DeviceIcon, ReactTable, Tag, Tooltip } from '@crazyegginc/hatch';
import { useSelector } from '@xstate5/react';

import { useOutsideClickNotify, useSelectedSite } from '/src/hooks';
import { recordingsInGroupQuery, recordingsListQuery } from '/src/features/recordings/queries';
import { PlayerLink } from './PlayerLink';

import { formatShortDateTime } from '/src/utils/date';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';

import { ReactComponent as DurationIcon } from '@crazyegginc/hatch/dist/images/icon-clock-outline.svg';
import { ReactComponent as PagesIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';

const GROUP_BY_LIMIT = 10;

export function Playlist({
  actorRef,
  navigateToAnotherRecording,
  queryParams,
  shown = false,
  toggle = null,
  loaded = false,
}) {
  const currentHash = useSelector(actorRef, (state) => state.context.recordingHash);
  const contextPlaylist = useSelector(actorRef, (state) => state.context.playlist);

  const recordingsInGroupRef = useRef([]);
  const playlistRef = useRef(null);
  const playlistParentRef = useRef(null);

  const isListView = queryParams.groupBy === GROUP_BY_TYPES.LIST_VIEW;

  const [page, setPage] = useState(queryParams.page);
  const { selectedSite } = useSelectedSite();

  useOutsideClickNotify(playlistParentRef, toggle, true);

  const { data: recordingsGroup, isFetching: recordingsGroupFetching } = useQuery({
    ...recordingsInGroupQuery({
      filters: JSON.stringify(queryParams.filters),
      group: queryParams.group,
      groupBy: queryParams.groupBy,
      limit: GROUP_BY_LIMIT,
      field: queryParams.order.field,
      sort: queryParams.order.sort,
      page,
      site: selectedSite?.id,
    }),
    enabled: loaded && Boolean(selectedSite?.id && !isListView && queryParams.group),
    keepPreviousData: contextPlaylist.length > 0,
  });

  const {
    data: recordingsList,
    isFetching: recordingsListFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...recordingsListQuery({
      filters: JSON.stringify(queryParams.filters),
      site: selectedSite?.id,
      field: queryParams.order.field,
      sort: queryParams.order.sort,
      limit: 50,
    }),
    enabled: loaded && Boolean(selectedSite?.id && isListView),
  });

  const columns = useMemo(
    () => [
      {
        header: 'Play',
        accessorKey: 'visitorId',
        size: 49,
        meta: {
          align: 'center',
        },
        cell: function RecordingsVisitorCell({ row }) {
          return (
            <div className="box-content h-12 w-12 p-2.5">
              <PlayerLink
                recording={
                  row.original.hashedId === currentHash && row.original.viewedAt === null
                    ? {
                        ...row.original,
                        viewedAt: Date.now(),
                      }
                    : row.original
                }
                isWatching={row.original.hashedId === currentHash}
                redirect={true}
                flipColorOnHover={true}
                navigateToAnotherRecording={navigateToAnotherRecording}
                togglePlaylist={toggle}
                onClick={(recording) => {
                  actorRef.send({ type: 'CHANGE_RECORDING', hashedId: recording.hashedId });
                }}
              />
            </div>
          );
        },
      },
      {
        header: 'Date',
        accessorKey: 'recordedAt',
        size: 67,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function RecordingsDateCell({ row }) {
          return <span className="text-xs font-normal">{formatShortDateTime(row.original.recordedAt)}</span>;
        },
      },
      {
        header: function RecordingsDurationHeaderCell() {
          return <DurationIcon className="h-4 w-4 fill-current text-white" />;
        },
        accessorKey: 'duration',
        size: 40,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDurationCell({ row }) {
          return (
            <span className="text-xs font-normal">
              {Math.floor(row.original.duration / 60) + ':' + String(row.original.duration % 60).padStart(2, '0')}
            </span>
          );
        },
      },
      {
        header: function RecordingsPageHeaderCell() {
          return <PagesIcon className="h-4 w-4 fill-current text-white" />;
        },
        accessorKey: 'visitedPagesCount',
        size: 33,
        meta: {
          align: 'center',
        },
        cell: function RecordingPageCell({ row }) {
          return <span className="text-xs font-normal">{row.original.visitedPagesCount}</span>;
        },
      },
      {
        header: function RecordingsTagsHeaderCell() {
          return (
            <span className="flex items-center">
              <TagIcon className="mr-1.5 h-4 w-4 fill-current text-white" /> Tags
            </span>
          );
        },
        accessorKey: 'tags',
        size: 60,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function RecordingsTagsCell({ row }) {
          if (!row?.original?.tags?.length) return null;

          if (row.original.tags.length > 1) {
            const restTags = row.original.tags.slice(1);
            const totalNumRestTags = restTags.length.toString();

            return (
              <span className="flex w-full cursor-pointer items-center">
                <Tag tag={row.original.tags[0]} />
                <div className="flex-shrink-0">
                  <Tooltip
                    darkTheme={false}
                    placement="right"
                    arrowSkiddingPercent={20}
                    tooltipContent={restTags.map((tag, index) => {
                      return (
                        <span key={index}>
                          <Tag tag={tag} />
                        </span>
                      );
                    })}
                  >
                    <Tag tag={`+ ${totalNumRestTags}`} />
                  </Tooltip>
                </div>
              </span>
            );
          } else {
            return <Tag tag={row.original.tags[0]} />;
          }
        },
      },
      {
        header: function RecordingsDeviceHeaderCell() {
          return <DesktopIcon className="h-4 w-4 fill-current text-white" />;
        },
        accessorKey: 'device',
        size: 33,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDeviceCell({ row }) {
          return (
            <DeviceIcon
              device={row.original.device.toUpperCase()}
              tooltip={false}
              className={classNames('group-hover:!text-white', {
                '!text-white': row.original.hashedId === currentHash,
              })}
            />
          );
        },
      },
    ],
    [actorRef, currentHash, navigateToAnotherRecording, toggle],
  );

  const getNextPageAvailable = useCallback(() => {
    if (recordingsGroup) {
      return recordingsGroup?.recordingsInGroup?.recordings.length === GROUP_BY_LIMIT;
    }
    if (recordingsList) {
      return hasNextPage;
    }
  }, [recordingsGroup, recordingsList, hasNextPage]);

  const loadNextPage = useCallback(() => {
    if (recordingsGroup && !recordingsGroupFetching) {
      setPage(recordingsGroup.recordingsInGroup.page + 1);
    }
    if (recordingsList && !recordingsListFetching) {
      fetchNextPage();
    }
  }, [recordingsList, recordingsListFetching, recordingsGroup, recordingsGroupFetching, fetchNextPage]);

  const playlist = useMemo(() => {
    const recordingsInGroup = recordingsGroup?.recordingsInGroup?.recordings;
    if (recordingsInGroup) {
      const recordingsSet = new Set(recordingsInGroupRef.current.map((rec) => rec.id));
      const newRecordings = recordingsInGroup.filter((rec) => !recordingsSet.has(rec.id));

      recordingsInGroupRef.current = [...recordingsInGroupRef.current, ...newRecordings];

      return recordingsInGroupRef.current;
    }
    if (recordingsList) {
      return recordingsList.pages.reduce((acc, page) => {
        const newPage = page.recordingsList?.recordings ?? [];
        acc.push(...newPage);

        return acc;
      }, []);
    }
    return null;
  }, [recordingsList, recordingsGroup?.recordingsInGroup?.recordings]);

  useEffect(() => {
    if (playlist && playlist.length > 1) {
      const currentIndex = playlist.findIndex((v) => v.hashedId === currentHash);
      const nextRecording = playlist?.[currentIndex + 1];

      if ((currentIndex === -1 || !nextRecording) && getNextPageAvailable()) {
        loadNextPage();
      }
    }
    actorRef.send({ type: 'SET_PLAYLIST', playlist: playlist ?? [] });
  }, [currentHash, actorRef, playlist, getNextPageAvailable, loadNextPage]);

  if (!shown || !playlist || playlist?.length === 0) return null;

  return (
    <div className="absolute left-14 top-11 z-[60] flex w-[480px] flex-col overflow-auto shadow-lg">
      <div
        ref={playlistParentRef}
        className="max-h-[500px] !overflow-y-auto !overflow-x-hidden rounded bg-woodsmoke-500 pb-1.5 scrollbar-thin scrollbar-track-mako-500 scrollbar-thumb-woodsmoke-500"
      >
        {contextPlaylist && columns ? (
          <ReactTable
            fetching={false}
            ref={{ tableRef: playlistRef, parentRef: playlistParentRef }}
            rowPadding={false}
            rowHeight={55}
            headerHeight={40}
            columns={columns}
            data={contextPlaylist}
            useVirtualization={true}
            enableSorting={false}
            dark={true}
            hasNextPage={getNextPageAvailable()}
            loadNextPage={loadNextPage}
            rowCustomBackground={(row) =>
              classNames('hover:bg-dodger-blue-500', {
                'bg-dodger-blue-500': row && row.original.hashedId === currentHash,
                'even:bg-woodsmoke-500/94 odd:bg-charade-500/50': row && row.original.hashedId !== currentHash,
              })
            }
            rowCustomClassname={(row) =>
              classNames('hover:text-white', {
                'text-white': row && row.original.hashedId === currentHash,
                'text-cadet-blue-500': row && row.original.hashedId !== currentHash,
              })
            }
          />
        ) : null}
      </div>
    </div>
  );
}
