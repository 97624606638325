import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { DashboardPage } from '/src/components/Page';
import { TabHeader, Tab } from '/src/components/headers/tab-header';
import { SEO } from '/src/components/SEO';
import { usePermissions } from '/src/hooks';

import { FEATURES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function AccountPage() {
  const permissions = usePermissions();
  const canVisitBilling = permissions.can('navigate', FEATURES.BILLING).allowed;
  const canVisitSSO = permissions.can('navigate', FEATURES.SSO).allowed;

  return (
    <DashboardPage>
      <SEO title="Account" />
      <TabHeader title="Account">
        <Tab to="/account/profile">Profile</Tab>
        {canVisitBilling ? <Tab to="/account/plan">Plan</Tab> : null}
        {canVisitBilling ? <Tab to="/account/billing">Billing</Tab> : null}
        {canVisitSSO ? <Tab to="/account/sso">Single Sign-on</Tab> : null}
      </TabHeader>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </DashboardPage>
  );
}
