import classNames from 'classnames';
import { Button } from '@crazyegginc/hatch';

import { useScrollToAndFocus } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as GuideIcon } from '@crazyegginc/hatch/dist/images/icon-guides-outline.svg';

export function CreateWithPageCamera({ isLogin = false }) {
  const canInstall = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const isInstalled = !!document.documentElement.getAttribute('data-page-camera');
  const ref = useScrollToAndFocus({ offset: -120 });

  function PageCameraMessage({ isLogin, installed }) {
    return (
      <p ref={ref} className={classNames('text-body-2', { 'mt-2': !installed })}>
        {isLogin
          ? 'Pages behind a login, require your visitors to log in. To create a snapshot for these pages you will need to use our Page Camera extension.'
          : 'When creating a snapshot for your shopping cart, be sure to add the typical number of items customers purchase into the cart, for the most realistic layout of your cart.'}
      </p>
    );
  }

  if (isInstalled) {
    return (
      <>
        <h2 className="text-header-2 mb-5">Use Page Camera to create this Snapshot</h2>
        <PageCameraMessage isLogin={isLogin} installed={isInstalled} />
        <div className="mt-[30px] flex items-center space-x-3.75">
          <div className="flex h-[35px] items-center rounded border border-mystic-500 px-3.75">
            <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
            <div className="text-body-1">Page Camera is Installed</div>
          </div>
          <UsingPageCameraButton isLogin={isLogin} />
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="text-header-2 mb-5">Install Page Camera:</h2>
      <p className="text-body-2">
        Page Camera is a browser extension that allows you to take snapshots of non-public pages. By taking the snapshot
        yourself, you can track pages that are accessible only to logged-in users or pages that can only be reached from
        within your internal network.
      </p>
      <PageCameraMessage isLogin={isLogin} installed={isInstalled} />
      <div className="mt-[30px] flex items-center space-x-3.75">
        <Button
          component="a"
          href={window.PAGE_CAMERA_URL}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!canInstall}
          className={!canInstall ? 'pointer-events-none' : ''}
        >
          Install Page Camera
        </Button>
        <UsingPageCameraButton isLogin={isLogin} />
      </div>
    </>
  );
}

function UsingPageCameraButton({ isLogin }) {
  return (
    <Button
      variant="ghost-primary"
      className="!px-0"
      component="a"
      href={isLogin ? SupportLinks.snapshots.usingPageCamera : SupportLinks.snapshots.usingPageCameraForCheckout}
      target="_blank"
      rel="noopener noreferrer"
    >
      <GuideIcon className="mr-2.5 h-4 w-4 fill-current" />
      Using Page Camera
    </Button>
  );
}
