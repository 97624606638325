import { gql } from '@urql/core';

import { teamAndSharingFeatureKeys } from './queries';
import { globalFeatureKeys } from '/src/features/_global/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';

export const resendInvitationEmailMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ResendInvitationEmail($id: String!) {
          resendInvitationEmail(id: $id)
        }
      `,
      {
        id,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
  },
});

/* 
  NOTE: ID for this is a string,
  if invitation still pending prefix with: "i_",
  if invitation not pending prefix with: "ua_"
*/
export const deleteAccountUsersMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteAccountUsers($ids: [String!]!) {
          deleteAccountUsers(ids: $ids)
        }
      `,
      {
        ids,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: globalFeatureKeys.teamMembers._def });
    return queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
  },
});

export const updateAccountUsersMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids, role, sites, specificSites }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateAccountUsers(
          $ids: [String!]!
          $role: AccountUsersRole
          $sites: [String]
          $specificSites: Boolean
        ) {
          updateAccountUsers(ids: $ids, role: $role, sites: $sites, specificSites: $specificSites) {
            id
            role
            sites
            specificSites
          }
        }
      `,
      {
        ids,
        role,
        sites,
        specificSites,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['sites'] });
    return queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
  },
});

export const createAccountUsersMutation = ({ client, queryClient }) => ({
  mutationFn: ({ emails, role, sites, specificSites }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation CreateAccountUsers(
          $emails: [String!]!
          $role: AccountUsersRole!
          $sites: [String!]!
          $specificSites: Boolean!
        ) {
          createAccountUsers(emails: $emails, role: $role, sites: $sites, specificSites: $specificSites) {
            id
          }
        }
      `,
      {
        emails,
        role,
        sites,
        specificSites,
      },
    ),
  onSuccess: () => {
    window.Metrics?.used('Invited team member');
    queryClient.invalidateQueries({ queryKey: ['sites'] });
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
    return queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
  },
});

export const regenerateSharedUrlListMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation RegenerateSharedUrlList($ids: [Int!]!) {
          regenerateSharedUrlList(ids: $ids) {
            fullPath
          }
        }
      `,
      {
        ids,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.sharedUrls._def });
  },
});

export const updateSharedUrlListMutation = ({ client, queryClient }) => ({
  mutationFn: ({ sharedUrls }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateSharedUrlList($sharedUrls: [SharedUrlUpdateParams!]!) {
          updateSharedUrlList(sharedUrls: $sharedUrls) {
            createdAt
            fullPath
            id
            itemId
            itemType
            lastViewedAt
            note
            sharedByUserEmail
            sharedByUserId
            sharedByUserName
            status
            title
            totalViews
          }
        }
      `,
      {
        sharedUrls,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.sharedUrls._def });
  },
});
