import { useState } from 'react';
import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { FilterTagsSelect } from '/src/features/recordings/components/recordings-filter/FilterSelect';

export function UntagRecordingsModal({ onConfirm }) {
  const [selectedTags, setSelectedTags] = useState();
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[530px]" data-testid="tagModal">
      <Modal.Title>Remove tag</Modal.Title>

      <div className="text-body-2">
        <div className="mb-1.25">Select a tag to remove from these sessions</div>
        <FilterTagsSelect
          placeholder="Search tags"
          value={selectedTags}
          onChange={(value) => setSelectedTags(value.length ? value.map((o) => o.value ?? o) : undefined)}
          label="tags"
          menuPosition="fixed"
          menuPortalTarget={document.body}
          containerStyle={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}
        />
      </div>

      <Modal.Actions>
        <Button
          onClick={() => {
            onConfirm?.(selectedTags);
            modal.close();
          }}
          variant="warning"
          disabled={!selectedTags}
        >
          Remove
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
