import { gql } from '@urql/core';

import { accountFeatureKeys } from './queries';
import { globalFeatureKeys } from '/src/features/_global/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const updateUserProfileMutation = ({ client, queryClient }) => ({
  mutationFn: ({ login, name, language }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateUserProfile($login: String!, $name: String!, $language: String!) {
          updateUserProfile(login: $login, name: $name, language: $language) {
            name
            email
            language
          }
        }
      `,
      {
        login,
        name,
        language,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...globalFeatureKeys.me });
  },
});

export const deleteAccountMutation = ({ client }) => ({
  mutationFn: ({ delete: deleteString, userId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteAccount($delete: String!, $userId: Int!) {
          deleteAccount(delete: $delete, userId: $userId)
        }
      `,
      {
        delete: deleteString,
        userId,
      },
    ),
});

export const setUserNotificationsMutation = ({ client, queryClient }) => ({
  mutationFn: ({
    accountLimits,
    dailyUpdate,
    endOfTest,
    monthlyExport,
    newsletter,
    receipt,
    snapshotRecommendation,
    trialEnded,
    weeklyExport,
    weeklyUpdate,
  }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SetUserNotifications(
          $accountLimits: Boolean
          $dailyUpdate: Boolean
          $endOfTest: Boolean
          $monthlyExport: Boolean
          $newsletter: Boolean
          $receipt: Boolean
          $snapshotRecommendation: Boolean
          $trialEnded: Boolean
          $weeklyExport: Boolean
          $weeklyUpdate: Boolean
        ) {
          setUserNotifications(
            accountLimits: $accountLimits
            dailyUpdate: $dailyUpdate
            endOfTest: $endOfTest
            monthlyExport: $monthlyExport
            newsletter: $newsletter
            receipt: $receipt
            snapshotRecommendation: $snapshotRecommendation
            trialEnded: $trialEnded
            weeklyExport: $weeklyExport
            weeklyUpdate: $weeklyUpdate
          )
        }
      `,
      {
        accountLimits,
        dailyUpdate,
        endOfTest,
        monthlyExport,
        newsletter,
        receipt,
        snapshotRecommendation,
        trialEnded,
        weeklyExport,
        weeklyUpdate,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...globalFeatureKeys.me });
  },
});

export const sendAddonDetailEmailMutation = ({ client }) => ({
  mutationFn: ({ email }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SendAddonDetailEmail($email: SimpleEmailParams!) {
          sendAddonDetailEmail(email: $email) {
            success
            email {
              subject
              from
              to
              message
              userId
            }
          }
        }
      `,
      {
        email,
      },
    ),
});

export const updateCompanyInfoMutation = ({ client, queryClient }) => ({
  mutationFn: ({ address, city, country, stateOrProvince, zipOrPostalCode, companyId, companyName, companyVat }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateCompanyInfo(
          $address: String
          $city: String
          $country: String
          $stateOrProvince: String
          $zipOrPostalCode: String
          $companyId: String
          $companyName: String
          $companyVat: String
        ) {
          updateCompanyInfo(
            address: $address
            city: $city
            country: $country
            stateOrProvince: $stateOrProvince
            zipOrPostalCode: $zipOrPostalCode
            companyId: $companyId
            companyName: $companyName
            companyVat: $companyVat
          )
        }
      `,
      {
        address,
        city,
        country,
        stateOrProvince,
        zipOrPostalCode,
        companyId,
        companyName,
        companyVat,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...accountFeatureKeys.billingDetails });
  },
});

export const createOrUpdateCCMutation = ({ client, queryClient }) => ({
  mutationFn: ({
    address,
    city,
    country,
    stateOrProvince,
    zipOrPostalCode,
    firstName,
    lastName,
    cardNumber,
    cardExpirationYear,
    cardExpirationMonth,
    cardSecurityCode,
  }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation CreateOrUpdateCreditCard(
          $address: String!
          $city: String!
          $country: String!
          $stateOrProvince: String
          $zipOrPostalCode: String
          $firstName: String!
          $lastName: String!
          $cardNumber: String!
          $cardExpirationYear: String!
          $cardExpirationMonth: String!
          $cardSecurityCode: String!
        ) {
          createOrUpdateCreditCard(
            address: $address
            city: $city
            country: $country
            stateOrProvince: $stateOrProvince
            zipOrPostalCode: $zipOrPostalCode
            firstName: $firstName
            lastName: $lastName
            cardNumber: $cardNumber
            cardExpirationYear: $cardExpirationYear
            cardExpirationMonth: $cardExpirationMonth
            cardSecurityCode: $cardSecurityCode
          )
        }
      `,
      {
        address,
        city,
        country,
        stateOrProvince,
        zipOrPostalCode,
        firstName,
        lastName,
        cardNumber,
        cardExpirationYear,
        cardExpirationMonth,
        cardSecurityCode,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ ...accountFeatureKeys.paymentTransactionList._def });
    queryClient.invalidateQueries({ ...globalFeatureKeys.me });
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
    queryClient.invalidateQueries({ ...globalFeatureKeys.subscription });
    return queryClient.invalidateQueries({ ...accountFeatureKeys.billingDetails });
  },
});
