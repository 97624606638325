import { createContext, useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useNotifications } from '/src/hooks';
import { abTestWizardMachine } from '/src/features/ab-testing/machines/wizard';
import { contextToArgs } from '/src/features/ab-testing/utils';

import { AbTestWizard } from '../components/wizard';
import { abTestCreateMutation, abTestPublishMutation } from '/src/features/ab-testing/mutations';
import { WizardLoading } from '/src/features/ab-testing/components/wizard/WizardLoading';

export const AbTestWizardContext = createContext({});

const machine = abTestWizardMachine({ initial: 'setup' });

// eslint-disable-next-line
export default function NewAbTestPage() {
  const [state, , service] = useMachine(machine, {
    devTools: true,
  });

  const isSaving = state.matches('syncDraft') || state.matches('savingDraft');

  return (
    <>
      {isSaving ? (
        <WizardLoading
          nextText={state.context.nextText}
          balloonText="Saving your draft..."
          title={state.context.title}
          step={state.context.step}
          totalSteps={4}
        />
      ) : (
        <AbTestWizardContext.Provider value={{ abTestService: service }}>
          <AbTestWizard context={AbTestWizardContext} />
        </AbTestWizardContext.Provider>
      )}
      <StateHander service={service} />
    </>
  );
}

function StateHander({ service }) {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { mutate: mutateCreateAbTest } = useMutation(abTestCreateMutation);
  const { mutate: mutatePublishTest } = useMutation(abTestPublishMutation);

  const { success: notifySuccess, error: notifyError } = notifications;

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.matches('publishTest')) {
        mutatePublishTest(
          {
            id: state.context.id,
          },
          {
            onSuccess: (data) => {
              notifySuccess({ content: `A/B test published successfully.`, timeout: 3000 });
              service.send({ type: 'SUCCESS', value: data.abTestPublish });
            },
            onError: (error) => {
              notifyError({ content: `Failed to publish your A/B test.`, timeout: 3000 });
              service.send({ type: 'ERROR', error });
            },
          },
        );
      }
      if (state.matches('createTest')) {
        mutateCreateAbTest(
          {
            args: contextToArgs(state.context),
          },
          {
            onSuccess: (data) => {
              service.send({ type: 'CREATE_SUCCEEDED', value: data.abTestCreate });
            },
            onError: (error) => {
              notifyError({ content: `Failed to publish your A/B test.`, timeout: 3000 });
              service.send({ type: 'CREATE_FAILED', value: error });
            },
          },
        );
      }
      if (state.matches('savingDraft')) {
        mutateCreateAbTest(
          {
            args: contextToArgs(state.context),
          },
          {
            onSuccess: (data) => {
              notifySuccess({ content: `Draft saved successfully.`, timeout: 3000 });
              service.send({ type: 'CREATE_SUCCEEDED', value: data.abTestCreate });
            },
            onError: (error) => {
              notifyError({ content: `Failed to save your draft.`, timeout: 3000 });
              service.send({ type: 'CREATE_FAILED', value: error });
            },
          },
        );
      }
      if (state.matches('cancelled')) {
        navigate(state.context.previousPath, { replace: true });
      }
    });

    return subscription.unsubscribe;
  }, [mutateCreateAbTest, mutatePublishTest, notifyError, notifySuccess, service, navigate]);

  return null;
}
