import React from 'react';
import { Button } from '@crazyegginc/hatch';
import { Link } from 'react-router-dom';

import { ErrorItem } from './ErrorItem';
import { SupportLinks } from '/src/support';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

const DEFAULT_INSTALL_PATH = '/install';

export function RenderError({ data: { finalUrl, error, consoleErrors = null }, installPath = DEFAULT_INSTALL_PATH }) {
  switch (error.name) {
    case 'PERMISSION_REQUIRED':
      return (
        <ErrorItem
          title="Permission Required"
          description={
            <span>
              You do not have access to run the script check on this site{' '}
              <strong className="text-body-1">{finalUrl}</strong>. Check that the URL is correct, or contact the account
              owner for access to this site.
            </span>
          }
          consoleErrors={consoleErrors}
        />
      );
    case 'TRACKING_SCRIPT_CODE_MALFORMED':
      return (
        <ErrorItem
          title="Tracking Script Code Malformed"
          description="The tracking script installed on your website may have a syntax error. For example, an extra semicolon, quote, or bracket may be missing."
          suggestion={
            <span>
              Visit this{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.scriptCheck.malformedScript}
              >
                help article
              </a>{' '}
              to learn more.
            </span>
          }
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            component="a"
            className="mt-5"
            target="_blank"
            rel="noopener noreferrer"
            href={SupportLinks.scriptCheck.malformedScript}
          >
            View Help Article
          </Button>
        </ErrorItem>
      );
    case 'TRACKING_SCRIPT_CODE_COMMENTED':
      return (
        <ErrorItem
          title="Tracking Script Code Commented Out"
          description={<span>Uncomment the script section of your Tracking Script to re-enable it.</span>}
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            className="mt-5"
            component={Link}
            to={{ pathname: installPath }}
            state={{ requestUrl: finalUrl }}
          >
            {installPath === DEFAULT_INSTALL_PATH ? 'Open Installation Guide' : 'View Install Instructions'}
          </Button>
        </ErrorItem>
      );
    case 'INCORRECT_TRACKING_SCRIPT_INSTALLED':
      return (
        <ErrorItem
          title="Incorrect Tracking Script Installed"
          description="The tracking script installed on your website does NOT match the tracking script assigned your account. This is a simple issue to fix! Follow the instructions below to replace the mismatching script."
          suggestion={
            <span>
              <Link className="text-link" to={{ pathname: installPath }} state={{ requestUrl: finalUrl }}>
                Reinstall
              </Link>{' '}
              your Tracking Script. Then, re-check its installation.
            </span>
          }
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            className="mt-5"
            component={Link}
            to={{ pathname: installPath }}
            state={{ requestUrl: finalUrl }}
          >
            {installPath === DEFAULT_INSTALL_PATH ? 'Open Installation Guide' : 'View Install Instructions'}
          </Button>
        </ErrorItem>
      );
    case 'INACTIVE_ACCOUNT':
      return (
        <ErrorItem
          title="Inactive Account"
          description={
            <span>
              Your subscription is no longer active, please{' '}
              <Link className="text-link" to={MANAGE_SUBSCRIPTION_PATH}>
                choose a plan
              </Link>{' '}
              to reactivate your account.
            </span>
          }
        >
          <Button size="xl" className="mt-5" target="_blank" component={Link} to={MANAGE_SUBSCRIPTION_PATH}>
            Choose a Plan
          </Button>
        </ErrorItem>
      );
    case 'MISSING_TRACKING_SCRIPT':
      return (
        <ErrorItem
          title="Missing Tracking Script"
          description={`The tracking script was not detected on ${finalUrl}. If your site requires cookie acceptance, it could block our verification process.`}
          suggestion={
            <span>
              <a className="text-link" target="_blank" rel="noopener noreferrer" href={SupportLinks.scriptCheck.manual}>
                Verify manually
              </a>{' '}
              that the script is installed. Or{' '}
              <Link className="text-link" to={{ pathname: installPath }} state={{ requestUrl: finalUrl }}>
                install
              </Link>{' '}
              the tracking script.
            </span>
          }
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            className="mt-5"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={SupportLinks.scriptCheck.manual}
          >
            Check for Script Manually
          </Button>
        </ErrorItem>
      );
    case 'SELF_HOSTED_TRACKING_SCRIPT':
      return (
        <ErrorItem
          title="Self-Hosted Tracking Script"
          description={
            <span>
              It looks like you are self-hosting the Tracking Script. Only our Enterprise plans support self-hosting,
              please{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.general.newSupportRequest}
              >
                contact support
              </a>{' '}
              to learn more.
            </span>
          }
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            className="mt-5"
            component={Link}
            to={{ pathname: installPath }}
            state={{ requestUrl: finalUrl }}
          >
            {installPath === DEFAULT_INSTALL_PATH ? 'Open Installation Guide' : 'View Install Instructions'}
          </Button>
        </ErrorItem>
      );
    case 'CONTENT_SECURITY_POLICY':
      return (
        <ErrorItem
          title="Content Security Policy"
          description="This site implements a Content Security Policy that is blocking the tracking script from loading."
          suggestion={
            <span>
              Visit this{' '}
              <a
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SupportLinks.scriptCheck.contentSecurityPolicy}
              >
                help article
              </a>{' '}
              to learn more.
            </span>
          }
          consoleErrors={consoleErrors}
        >
          <Button
            size="xl"
            component="a"
            className="mt-5"
            target="_blank"
            rel="noopener noreferrer"
            href={SupportLinks.scriptCheck.contentSecurityPolicy}
          >
            View Help Article
          </Button>
        </ErrorItem>
      );
  }
}
