import { Tag } from '@crazyegginc/hatch';

import { Identity } from '../EventCard';

export function TagEvent({ liveEvent, identifyEvents }) {
  const { tags } = liveEvent.attributes;

  return (
    <div>
      <div className="flex items-center">
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <div className="flex items-center">
          got tagged with{' '}
          {tags.map((tag) => (
            <Tag tag={tag} key={tag} tagClass="!my-0 !ml-2" />
          ))}
        </div>
      </div>
    </div>
  );
}
