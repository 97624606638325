import { Button } from '@crazyegginc/hatch';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function PaginationControls({ currentPage, perPage, total, setPage }) {
  const startItem = (currentPage - 1) * perPage + 1;
  const endItem = Math.min(Math.max(currentPage * perPage, 1), total);
  const totalPages = Math.ceil(total / perPage);

  const showButtons = totalPages > 1;

  function renderPages() {
    const mustRender = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
    const pages = Array.from(Array(totalPages).keys());
    const renderItems = [];
    let dotsBefore = false;
    let dotsAfter = false;
    for (const page of pages) {
      if (mustRender.includes(page + 1)) {
        renderItems.push({ type: 'page', value: page + 1 });
      } else if (page + 1 < currentPage && !dotsBefore) {
        dotsBefore = true;
        renderItems.push({ type: 'dots' });
      } else if (page + 1 > currentPage && !dotsAfter) {
        dotsAfter = true;
        renderItems.push({ type: 'dots' });
      }
    }
    return renderItems;
  }

  return (
    <div className="flex items-center justify-end" data-testid="pagination-container">
      <div className="text-body-5 mr-4">
        <strong className="font-semibold">{`${startItem.toLocaleString()} - ${endItem.toLocaleString()} `}</strong>
        <span>of</span>
        <strong className="font-semibold">{` ${total.toLocaleString()}`}</strong>
      </div>

      {showButtons && (
        <div className="flex space-x-1.25">
          <Button
            variant="secondary"
            className={classNames('!px-3', {
              '!text-cadet-blue-500': currentPage === 1,
            })}
            onClick={() => setPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowIcon className="mr-2.5 h-2.5 w-2.5 -rotate-90 fill-current" />
            Prev
          </Button>

          {renderPages().map((renderItem, idx) =>
            renderItem.type === 'dots' ? (
              <span
                className="text-body-5 !ml-[15px] !mr-[10px] text-lg leading-[35px] tracking-widest"
                key={`PaginatorItem-${idx}`}
              >
                ...
              </span>
            ) : (
              <Button
                variant="secondary"
                className={classNames('min-w-[35px] !justify-center !px-3', {
                  '!text-cadet-blue-500': currentPage === renderItem.value,
                })}
                key={`PaginatorItem-${idx}`}
                onClick={() => setPage(renderItem.value)}
                disabled={currentPage === renderItem.value}
                data-testid={`paginator_page_${renderItem.value}`}
              >
                {renderItem.value}
              </Button>
            ),
          )}

          <Button
            variant="secondary"
            className={classNames('!px-3', {
              '!text-cadet-blue-500': currentPage >= totalPages,
            })}
            onClick={() => setPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            Next
            <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
          </Button>
        </div>
      )}
    </div>
  );
}
