import { useState } from 'react';
import { Select } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { DATE_RANGE_OPTIONS } from '../constants';

import { HomeDashboardContent } from '../components/HomeDashboardContent';
import { Settings } from '../components/Settings';
import { SECTIONS } from '../constants';

import { usePermissions } from '/src/hooks';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { FEATURES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function HomeDashboard() {
  const [dateRange, setDateRange] = useState(DATE_RANGE_OPTIONS[1].value);
  const [sections, setSections] = useState([...SECTIONS.map((s) => ({ ...s, enabled: true }))]);
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.GOALS);

  if (!canViewDashboard) {
    return <DashboardPaywall reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title="Home" />
      <DashHeader
        titleComponent="Home"
        actionButton={
          <div className="flex items-center space-x-5">
            <Settings sections={sections} setSections={setSections} />
            <div className="w-52">
              <Select options={DATE_RANGE_OPTIONS} value={dateRange} onChange={setDateRange} />
            </div>
          </div>
        }
      />

      <HomeDashboardContent dateRange={dateRange} sections={sections} />
    </DashboardPage>
  );
}
