import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useLocation, Navigate } from 'react-router-dom';

import { useSite, usePermissions } from '/src/hooks';

import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { generateCTAEditorFormat } from '/src/features/addons/ctas/editor/editor-functions';
import { addonDetailQuery, SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function EditAddon() {
  const { id } = useParams();
  const location = useLocation();
  const permissions = usePermissions();

  const { goalId } = location.state ?? {};

  const { data, isFetching } = useQuery({
    ...addonDetailQuery({ id }),
    enabled: Boolean(id),
  });

  const { sites, loadingSites } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: true,
  });

  const addon = data?.addonDetail;
  const canEdit = addon && permissions.can('edit', addon).allowed;

  useEffect(() => {
    if (window.Metrics && addon && canEdit) {
      if (addon.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Editor - Edit');
      } else if (addon.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Editor - Edit');
      }
    }
  }, [addon, canEdit]);

  if ((!data && isFetching) || loadingSites) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }

  if (!id || (!addon && !isFetching) || (addon && !canEdit)) {
    return <Navigate replace to="/addons" />;
  }

  const { type, name, content, siteId, draftContent, draftName, publishedAt, goal } = addon;
  const site = sites.find((s) => s.id === siteId);

  switch (type) {
    case ADDON_TYPES.SURVEY: {
      const surveyData = generateSurveyEditorFormat({
        name: draftName || name,
        content: draftContent || content,
        site,
      });
      const publishedQuestionIds = generateSurveyEditorFormat({ content, site }).questions.map((q) => q.id);
      return (
        <SurveyEditor
          id={id}
          initData={surveyData}
          publishedEdit={!!publishedAt}
          publishedQuestionIds={publishedQuestionIds}
        />
      );
    }
    case ADDON_TYPES.CTA: {
      const ctaData = generateCTAEditorFormat({
        name: draftName || name,
        content: draftContent || content,
        site,
        goalId: goalId && !publishedAt ? goalId : goal?.id,
      });
      return <CTAEditor id={id} initData={ctaData} publishedEdit={!!publishedAt} />;
    }
    default:
      return <Navigate replace to="/addons" />;
  }
}
