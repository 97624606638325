import { SupportLinks } from '/src/support';
import { DoughnutChart } from './DoughnutChart';
import { ReactComponent as HelpIcon } from '@crazyegginc/hatch/dist/images/icon-help-circle-outline.svg';

export function NPS({ question }) {
  const totalCount = question.results?.reduce((acc, curr) => acc + curr.count, 0);

  const promoters = question.results?.find((r) => r.value === 'promoters')?.count ?? 0;
  const passives = question.results?.find((r) => r.value === 'passives')?.count ?? 0;
  const detractors = question.results?.find((r) => r.value === 'detractors')?.count ?? 0;
  const promotersPercentage = (promoters / totalCount) * 100 || 0;
  const detractorsPercentage = (detractors / totalCount) * 100 || 0;

  const finalScore = Math.ceil(promotersPercentage - detractorsPercentage);

  const hasData = totalCount > 0;

  const options = {
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: finalScore > 0 ? `+${finalScore}%` : 0,
            color: '#0A1C2E',
            font: {
              size: 40,
            },
          },
          {
            text: ' ',
            font: {
              size: 3,
            },
          },
          {
            text: 'NPS Score',
            color: '#68768D',
            font: {
              size: 12,
              weight: 600,
            },
          },
        ],
      },
    },
  };

  if (!hasData) {
    options.plugins = {
      ...options.plugins,
      tooltip: {
        enabled: false,
      },
    };
  }

  return (
    <div className="flex items-center justify-center space-x-16">
      <DoughnutChart
        data={{
          labels: ['Promoters', 'Passives', 'Detractors'],
          datasets: hasData
            ? [
                {
                  label: 'NPS Score',
                  data: [promoters, passives, detractors],
                  backgroundColor: ['#7FC2F2', '#FFD155', '#FF8FA7'],
                  borderColor: ['#7FC2F2', '#FFD155', '#FF8FA7'],
                  borderWidth: 1,
                },
              ]
            : [
                {
                  label: 'NPS Score',
                  data: [1],
                  backgroundColor: ['#e1e6ef'],
                  borderColor: ['#e1e6ef'],
                  borderWidth: 1,
                },
              ],
        }}
        options={options}
      />

      <div className="w-[260px] rounded bg-white-lilac-500">
        <div className="flex items-center border-b border-dashed border-mystic-500 py-2.5 pl-5 pr-16">
          <div className="flex flex-1 items-center space-x-4">
            <div className="h-[9px] w-[9px] rounded-full bg-malibu-500" />
            <span className="text-header-5">Promoters</span>
          </div>
          <span className="text-body-5">{promoters}</span>
        </div>

        <div className="flex items-center border-b border-dashed border-mystic-500 py-2.5 pl-5 pr-16">
          <div className="flex flex-1 items-center space-x-4">
            <div className="h-[9px] w-[9px] rounded-full bg-dandelion-500" />
            <span className="text-header-5">Passives</span>
          </div>
          <span className="text-body-5">{passives}</span>
        </div>

        <div className="flex items-center py-2.5 pl-5 pr-16">
          <div className="flex flex-1 items-center space-x-4">
            <div className="h-[9px] w-[9px] rounded-full bg-pink-salmon-500" />
            <span className="text-header-5">Detractors</span>
          </div>
          <span className="text-body-5">{detractors}</span>
        </div>

        <a
          className="text-link flex items-center border-t-5 border-white py-2 pl-[17px] text-2xs"
          href={SupportLinks.survey.nps}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <HelpIcon className="mb-[2px] mr-2 h-3 w-3 fill-current text-dodger-blue-500" />
          Learn more about NPS
        </a>
      </div>
    </div>
  );
}
