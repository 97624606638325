import classNames from 'classnames';

import { SupportLinks } from '/src/support';

export function Panel({ children }) {
  return (
    <div className="mb-[50px] box-border flex flex-col rounded border border-mystic-500 bg-white px-[35px] py-[30px] shadow-md">
      {children}
    </div>
  );
}

export function Label({ children, htmlFor, ...rest }) {
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className="text-header-5 flex min-h-[40px] w-[140px] items-center justify-end self-start text-right leading-tight"
    >
      {children}
    </label>
  );
}

export function IntroText({ className }) {
  return (
    <p className={classNames('text-body-2', className)}>
      Single sign-on (SSO) allows anyone with an email from an approved domain to access Crazy Egg securely through an
      Identity Provider without the need for passwords.
      <br />
      Available for enterprise plans starting at <strong>$499/month</strong>.{' '}
      <a className="text-link" target="_blank" rel="noopener noreferrer" href={SupportLinks.general.newSupportRequest}>
        Contact us
      </a>{' '}
      to upgrade your plan or to add the SSO feature as an add-on to your existing plan.{' '}
      <a href={SupportLinks.sso} className="text-link" rel="noopener noreferrer">
        Learn more.
      </a>
    </p>
  );
}
