import { Fragment } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

export function TabList({ tabs, hasTabError }) {
  return (
    <Tab.List className="mb-10 ml-10 flex min-w-[600px] items-center border-b border-mystic-500">
      {tabs.map((header) => {
        const hasErrors = hasTabError(header);
        return (
          <Fragment key={header}>
            <Tab
              className={({ selected }) =>
                classNames(
                  'text-header-4 relative border-b-2 px-2.5 pb-3 focus:outline-none focus-visible:outline-black',
                  'flex items-center',
                  {
                    'border-dodger-blue-500 text-dodger-blue-500': selected && !hasErrors,
                    'border-b-carnation-500': selected && hasErrors,
                    'text-dodger-blue-500': !hasErrors,
                    'text-carnation-500': hasErrors,
                    'border-transparent': !selected,
                  },
                )
              }
            >
              {header}
              {hasErrors && (
                <WarningIcon
                  className="absolute -right-1 -top-1 h-3.5 w-3.5 fill-current text-carnation-500"
                  aria-label="contains error"
                />
              )}
            </Tab>
            <ExpandIcon className="mx-1 mb-3 h-3 w-3 rotate-90 fill-current text-cadet-blue-500 last:hidden" />
          </Fragment>
        );
      })}
    </Tab.List>
  );
}
