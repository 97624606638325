import { useContext, useCallback, useMemo } from 'react';

import { ModalContext } from '/src/contexts/modal';
import { MODAL_ACTIONS } from '/src/features/_global/constants';

export function useModal() {
  const { modalDispatch } = useContext(ModalContext);

  const show = useCallback(
    (component) => {
      modalDispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: {
          component: { ...component },
        },
      });
    },
    [modalDispatch],
  );

  const close = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTIONS.CLOSE,
    });
  }, [modalDispatch]);

  return useMemo(() => ({ show, close }), [show, close]);
}
