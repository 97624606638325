import { Button } from '@crazyegginc/hatch';

import { usePermissions, useAuthContext } from '/src/hooks';
import { Link } from 'react-router-dom';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';
import { SupportLinks } from '/src/support';
import { FEATURES, MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import LockedPNG from '@crazyegginc/hatch/dist/images/illustration-locked.png';
import LockedWebP from '@crazyegginc/hatch/dist/images/illustration-locked.webp';

export function LockedRecordingModal({ feature = 'recording' }) {
  const { currentAccount, capabilities } = useAuthContext();
  const permissions = usePermissions();

  const storageDuration = capabilities.recordings.quotas.storageDuration.limit;
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Modal dialogClassName="!py-5" overlayClassName="!z-[99999]">
      <div className="text-body-2 flex flex-col items-center py-5 text-center">
        <WebP webp={LockedWebP} fallback={LockedPNG} width="183px" height="121px" />
        <div className="text-header-2 mt-5">Sorry this {feature.toLowerCase()} is locked!</div>
        <div className="mb-3 mt-4 px-10 text-center">
          Your current plan only allows {storageDuration} months’ storage.
        </div>
        {canManageSubscription ? (
          <>
            <div className="mb-10 px-10">
              To expand your storage and access earlier {feature.toLowerCase()}s, you’ll need to upgrade your plan.
            </div>
            <Button component={Link} to={MANAGE_SUBSCRIPTION_PATH}>
              Choose a plan
            </Button>
          </>
        ) : (
          <>
            <div className="px-9">
              To expand your storage and access earlier {feature.toLowerCase()}s, contact your Account Owner (
              {currentAccount.ownerEmail}) to upgrade your plan.
            </div>
            <div className="mb-10 mt-3 px-10">
              If you need help, email us at{' '}
              <a className="text-link" href={`mailto:${SupportLinks.general.email}`}>
                {SupportLinks.general.email}
              </a>
              .
            </div>
            <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
              Contact Account Owner
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}
