import { useMemo } from 'react';

import { mergeDeep } from '/src/utils/object';
import { round } from '/src/utils/math';
import { addOpacity } from '/src/utils/color';

import { ScrollingLineChart } from '/src/components/ScrollingLineChart';
import { charDateLabel } from '../utils';
import { STEP_COLORS } from '../constants';

const commonChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest',
    axis: 'x',
  },
  scales: {
    x: {
      ticks: {
        padding: 10,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      suggestedMax: 10,
      ticks: {
        padding: 10,
      },
      border: {
        dash: [5, 5],
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      padding: { x: 12, y: 6 },
      displayColors: false,
      callbacks: {
        title: () => '',
        label: (context) => `${context.parsed.y.toLocaleString()}`,
      },
    },
  },
};

const rateChartOptions = mergeDeep({}, commonChartOptions, {
  scales: {
    y: {
      ticks: {
        padding: 10,
        callback: (value) => {
          return `${value}%`;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => `${round(context.parsed.y, 1)}%`,
      },
    },
  },
});

export function StepCharts({ stepsData, timeUnitResults, timeUnit }) {
  const chartLabels = useMemo(() => {
    if (!timeUnitResults.length) return null;
    return timeUnitResults.map((x) => {
      if (timeUnit === 1) return charDateLabel(x.startAt);
      return `${charDateLabel(x.startAt)} - ${charDateLabel(x.endAt)}`;
    });
  }, [timeUnitResults, timeUnit]);

  return (
    <div
      key={`${timeUnit}-${timeUnitResults.length}`}
      className="mt-6 min-w-[900px] space-y-5 rounded border border-mystic-500 bg-white p-8 shadow"
    >
      {stepsData.map((step, i) => (
        <StepChart
          key={`${step.name}-${i}`}
          index={i}
          chartLabels={chartLabels}
          steps={stepsData}
          timeUnitResults={timeUnitResults}
          timeUnit={timeUnit}
        />
      ))}
    </div>
  );
}

function StepChart({ index, chartLabels, steps, timeUnitResults, timeUnit }) {
  const colors = useMemo(() => STEP_COLORS.slice(0 - steps.length), [steps.length]);

  const data = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: steps[index].name,
          data: timeUnitResults.map((x) => x.steps[index]),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: '#fff',
          pointHoverBorderColor: '#fff',
          borderColor: colors[index].hex,
          pointBackgroundColor: colors[index].hex,
          pointHoverBackgroundColor: colors[index].hex,
          backgroundColor: addOpacity(colors[index].hex, 0.05),
        },
      ],
    }),
    [chartLabels, index, timeUnitResults, steps, colors],
  );
  const rateData = useMemo(() => {
    if (index === 0) return null;
    return {
      labels: chartLabels,
      datasets: [
        {
          label: `${steps[index].name}/${steps[index - 1].name}`,
          data: timeUnitResults.map((x) => x.conversionRates[index - 1]),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: '#fff',
          pointHoverBorderColor: '#fff',
          borderColor: colors[index].hex,
          pointBackgroundColor: colors[index].hex,
          pointHoverBackgroundColor: colors[index].hex,
          backgroundColor: addOpacity(colors[index].hex, 0.05),
        },
      ],
    };
  }, [chartLabels, index, timeUnitResults, steps, colors]);

  const height = timeUnit === 1 ? 200 : 300;

  return (
    <div className="flex items-center space-x-10">
      <div className="w-1/2">
        <div className="mb-2.5 flex w-full justify-center text-base font-semibold">{steps[index].name}</div>
        <ScrollingLineChart
          data={data}
          options={commonChartOptions}
          id={`volume-${index}`}
          height={height}
          pixelsPerDatapoint={30}
        />
      </div>
      {index > 0 ? (
        <div className="w-1/2">
          <div className="mb-2.5 flex w-full justify-center text-base font-semibold">{`${steps[index].name} / ${
            steps[index - 1].name
          }`}</div>
          <ScrollingLineChart
            data={rateData}
            options={rateChartOptions}
            id={`rate-${index}`}
            height={height}
            pixelsPerDatapoint={30}
          />
        </div>
      ) : null}
    </div>
  );
}
