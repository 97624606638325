import { Link } from 'react-router-dom';

import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as GoalsIllustrationIcon } from '@crazyegginc/hatch/dist/images/illustration-target-goals.svg';

export function GoalPromo() {
  const permissions = usePermissions();
  const canViewGoals = permissions.can('view', FEATURES.GOALS).allowed;

  if (!canViewGoals) return <div />;

  return (
    <Link
      to="/goals"
      className="group relative mr-5 flex h-[76px] w-[370px] flex-shrink-0 items-center rounded bg-gradient-to-tl from-[#025ad5] to-[#00a1dd] py-2.5 pl-5 pr-3.5 text-lg font-semibold shadow hover:from-[#337bdd] hover:to-[#33b3e3]"
    >
      <GoalsIllustrationIcon className="h-14 w-14 flex-shrink-0" />
      <div className="flex h-full flex-1 pl-6">
        <div className="mr-3 flex w-[200px] flex-col">
          <span className="mb-1 self-start rounded-[3px] bg-white pb-0.5 pl-1 pr-1.5 pt-1 text-2xs font-semibold uppercase leading-none text-black-pearl-500">
            new feature
          </span>
          <div className="text-md leading-none text-white">Track your most important conversions with Goals</div>
        </div>
        <ArrowIcon className="w-5 rotate-90 fill-white transition ease-out group-hover:translate-x-5" />
      </div>
    </Link>
  );
}
