import { useNavigate } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { getSurveyQueryParams } from '/src/utils/url';

export function DeletedAddonModal({ onConfirm, type }) {
  const modal = useModal();
  const navigate = useNavigate();

  return (
    <Modal dialogClassName="!p-6" dismissable={false} disableOutsideClick={true}>
      <Modal.Title>This {type} was deleted</Modal.Title>

      <Modal.Actions>
        <Button
          variant="primary"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Save as new
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            modal.close();
            navigate({ pathname: '/addons', search: getSurveyQueryParams({ type: `${type}S` }) });
          }}
        >
          Discard changes and return to the dashboard
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
