export const SNAPSHOT_ADDON_50 = 'SNAPSHOT_ADDON_50';
export const SNAPSHOT_ADDON_100 = 'SNAPSHOT_ADDON_100';
export const SNAPSHOT_ADDON_200 = 'SNAPSHOT_ADDON_200';
export const SNAPSHOT_ADDON_UNLIMITED = 'SNAPSHOT_ADDON_UNLIMITED';

export const PAYMENT_TYPES = {
  USER_CHARGE: 'USER_CHARGE',
  AUTOMATED_CHARGE: 'AUTOMATED_CHARGE',
  REFUND: 'REFUND',
  ONE_TIME_CHARGE: 'ONE_TIME_CHARGE',
  MANUAL_CHARGE: 'MANUAL_CHARGE',
};

export const PAYMENT_TYPE_NAMES = {
  [PAYMENT_TYPES.USER_CHARGE]: 'User Initiated',
  [PAYMENT_TYPES.AUTOMATED_CHARGE]: 'Automated',
  [PAYMENT_TYPES.REFUND]: 'Refund',
  [PAYMENT_TYPES.ONE_TIME_CHARGE]: 'One Time Charge',
  [PAYMENT_TYPES.MANUAL_CHARGE]: 'Manual',
};

export const CC_TYPES = {
  AMEX: 'AMEX',
  DINERS_CLUB: 'DINERS_CLUB',
  DISCOVER: 'DISCOVER',
  JCB: 'JCB',
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  OTHER: 'OTHER',
};

export const NO_COUNTRY = '-';
export const NO_MONTH = '--';
export const NO_YEAR = '----';
