import { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Popover, StyledPopoverPanel, Indicator, PopoverItem } from '@crazyegginc/hatch';

import { useMutation, useModal, useNotifications } from '/src/hooks';
import { goalDeleteMutation } from '/src/features/goals/mutations';
import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

import { ReactComponent as PencilIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { DeleteModal } from './modals/DeleteModal';

export function ActionButton({ goal = null, redirectOnDelete = null, abTests = [] }) {
  const notifications = useNotifications();
  const modal = useModal();
  const navigate = useNavigate();

  const { isLoading: deletingGoal, mutate: mutateDeleteGoal } = useMutation(goalDeleteMutation);
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  if (!goal) return null;

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              className={classNames(
                'flex h-[35px] w-[70px] items-center justify-center rounded px-4 text-dodger-blue-300',
                {
                  'border border-mystic-500 bg-white shadow-md': open,
                },
              )}
            >
              <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
              <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                ref={setTooltipElement}
                // onMouseDown={(e) => e.stopPropagation()}
                className="w-60"
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {() => (
                  <>
                    <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />

                    {goal.permissions.canEdit ? (
                      <Item
                        onClick={() => {
                          close();
                          navigate(`/goals/${goal.id}/edit`);
                        }}
                        icon={PencilIcon}
                      >
                        Edit Goal
                      </Item>
                    ) : null}

                    <Item
                      disabled={!goal.permissions.canShare}
                      onClick={() => {
                        window.SharingModal.show({
                          entity: {
                            id: parseInt(goal.id),
                            siteId: parseInt(goal.siteId),
                            type: SHARABLE_RESOURCE_TYPES.GOAL,
                          },
                        });
                      }}
                      icon={ShareIcon}
                    >
                      Share Goal
                    </Item>

                    <Item
                      disabled={!goal.permissions.canDelete}
                      onClick={() => {
                        modal.show(
                          <DeleteModal
                            goal={goal}
                            abTests={abTests}
                            isDeleting={deletingGoal}
                            onConfirm={() => {
                              mutateDeleteGoal(
                                { id: goal.id },
                                {
                                  onSuccess: () => {
                                    modal.close();
                                    notifications.success({ content: `Goal deleted successfully.`, timeout: 3000 });
                                    if (redirectOnDelete) {
                                      navigate(redirectOnDelete, { replace: true });
                                    }
                                  },
                                  onError: (error) => {
                                    notifications.error({
                                      content: `Goal delete failed.`,
                                      timeout: 3000,
                                      context: { error },
                                    });
                                  },
                                },
                              );
                            }}
                          />,
                          { width: '450px' },
                        );
                      }}
                      icon={TrashIcon}
                    >
                      Delete Goal
                    </Item>
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </>
      )}
    </Popover>
  );
}

function Item({ children, icon: Icon, className, disabled, ...props }) {
  return (
    <PopoverItem
      className={classNames('!flex !select-none !items-center !py-2', className, {
        'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
        'group ': !disabled,
      })}
      disabled={disabled}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames('mr-5 h-4 w-4 fill-current group-hover:text-dodger-blue-500', {
            'text-cadet-blue-500': disabled,
            'text-dodger-blue-300': !disabled,
          })}
        />
      )}
      {children}
    </PopoverItem>
  );
}
