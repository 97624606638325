import { ACCOUNT_USER_ROLES } from './constants';

export function isAccountOwner(role) {
  return role === ACCOUNT_USER_ROLES.ACCOUNT_HOLDER;
}

export function userRoleTextAndClassName(role) {
  switch (role) {
    case ACCOUNT_USER_ROLES.ACCOUNT_HOLDER:
      return ['Account Owner', 'bg-dodger-blue-500'];
    case ACCOUNT_USER_ROLES.OWNER:
      return ['Admin', 'bg-dodger-blue-300'];
    case ACCOUNT_USER_ROLES.MANAGER:
      return ['Member', 'bg-lavender-500'];
    case ACCOUNT_USER_ROLES.READ_ONLY:
      return ['Read only', 'bg-california-500'];
    default:
      return ['', 'bg-black-pearl-500'];
  }
}
