import { inflect } from '/src/utils/string';
import { Dot } from './Dot';

export function MetaRow({ totalViews, responseCount = undefined, optional, showAnswerRate }) {
  const answerRate = totalViews > 0 ? Math.round((responseCount / totalViews) * 100) : 0;

  return (
    <div className="text-body-5 question-meta flex items-center space-x-2.5" data-testid="metarow">
      {responseCount != undefined && (
        <>
          <div>
            <span>
              {responseCount} {inflect('Response', responseCount)}
            </span>{' '}
          </div>
          <Dot />
        </>
      )}
      <span>{optional ? 'Not Required' : 'Required'}</span>

      {showAnswerRate ? (
        <>
          <Dot /> <span>{answerRate}% answer rate</span>
        </>
      ) : null}
    </div>
  );
}
