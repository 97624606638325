import useResizeObserver from 'use-resize-observer';

export function useScrollBarVisible() {
  useResizeObserver({ ref: document.documentElement });

  return {
    horizontalScrollBarVisible: document.documentElement.clientWidth < document.documentElement.scrollWidth,
    verticalScrollBarVisible: document.documentElement.clientHeight < document.documentElement.scrollHeight,
  };
}
