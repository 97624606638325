import { ScrollToTop } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { TADashboardHeader } from '/src/features/traffic-analysis/components/TADashboardHeader';
import { TADashboardContent } from '/src/features/traffic-analysis/components/TADashboardContent';

import { usePermissions } from '/src/hooks';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

// eslint-disable-next-line no-restricted-syntax
export default function TrafficAnalysisDashboard() {
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.TRAFFIC_ANALYSIS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.TRAFFIC_ANALYSIS} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.TRAFFIC_ANALYSIS)} />
      <TADashboardHeader />
      <TADashboardContent />
      <ScrollToTop />
    </DashboardPage>
  );
}
