import { useFormikContext } from 'formik';
import { RG, RadioGroupOption, Tooltip, Button } from '@crazyegginc/hatch';

import { UrlTrackingModal } from '../modals/UrlTrackingModal';
import { WildcardModal } from '../modals/WildcardModal';
import { RegexModal } from '../modals/RegexModal';
import { NameTrackingModal } from '../modals/NameTrackingModal';
// import { GoogleOptimizeModal } from '../modals/GoogleOptimizeModal';

import { useWizard } from '/src/hooks';
import { ScrollTo } from '/src/utils/scroll';
import { SNAPSHOT_TRACKING_OPTIONS } from '/src/features/snapshots/constants';

export function TrackingOptions({ isEditing }) {
  const formik = useFormikContext();
  const wizard = useWizard();

  const urlInputLabel = '#your-page';

  const isBulk = formik.values.snapshots.length > 1;

  const nameValue = formik.values.snapshots?.[0].name;
  const siteUrlValue = formik.values.snapshots?.[0].siteUrl;
  const siteUrlTouched = formik.touched.snapshots?.[0]?.siteUrl;
  const siteUrlError = formik.errors.snapshots?.[0]?.siteUrl;
  const snapshotUrlValid = siteUrlTouched && !siteUrlError;
  const isPageCameraSnapshot = formik.values.pageCameraSnapshot;
  const changeTrackingDisabled = !isPageCameraSnapshot && !isEditing && !snapshotUrlValid;

  const editUrlTracking = !!formik.values.trackingOptions.urlTracking;
  const editWildcard = !!formik.values.trackingOptions.wildcard;
  const editRegex = !!formik.values.trackingOptions.regex;
  const editNameTracking = !!formik.values.trackingOptions.named;
  // const editGoogleOptimize = !!formik.values.trackingOptions.googleOptimize;

  const configuredTrackingOptions =
    Object.keys(formik.values.trackingOptions).filter((option) => formik.values.trackingOptions[option] !== null)
      .length - 1;

  function showUrlTrackingModal(formik) {
    wizard.showModal(<UrlTrackingModal formik={formik} />);
  }

  function showWildcardModal(formik) {
    wizard.showModal(<WildcardModal formik={formik} />);
  }

  function showRegexModal(formik) {
    wizard.showModal(<RegexModal formik={formik} />);
  }

  function showNameTrackingModal(formik) {
    wizard.showModal(<NameTrackingModal formik={formik} />);
  }

  // function showGoogleOptimizeModal(formik) {
  //   wizard.showModal(<GoogleOptimizeModal formik={formik} />);
  // }

  return (
    <section className="mt-7">
      <h2 className="text-header-2 mb-4">Tracking Options</h2>

      {configuredTrackingOptions > 1 && (
        <div className="text-body-2 mb-2 mt-2 w-full rounded border border-carnation-500 p-3">
          You have configured more than one tracking option. Only the selected option will be applied.
        </div>
      )}

      <RG
        value={formik.values.trackingOptions.option}
        onChange={(newValue) => formik.setFieldValue('trackingOptions.option', newValue, true)}
      >
        <RadioGroupOption option={{ value: SNAPSHOT_TRACKING_OPTIONS.DEFAULT, label: 'Default tracking' }} />

        <div className="ml-[34px] flex items-center">
          <div className="text-body-5 mr-1.5 leading-normal">
            Default settings that will work for the majority of sites.{' '}
          </div>
          {isBulk && (
            <Tooltip
              tooltipContent={
                <div className="max-w-xs">
                  You are creating multiple Snapshots at once - only Default Tracking is available. To access custom
                  tracking options such as Custom URL Tracking Rules and Wildcards, you&#39;ll need to create one
                  Snapshot at a time.
                </div>
              }
            >
              <div className="text-link cursor-pointer hover:no-underline">Why is this the only option available?</div>
            </Tooltip>
          )}
        </div>

        <div className="mt-4">
          <RadioGroupOption
            option={{ value: SNAPSHOT_TRACKING_OPTIONS.URL, label: 'Custom URL Tracking Rules' }}
            disabled={isBulk}
          />
        </div>
        <div className="ml-[34px]">
          <div className="text-body-5 leading-normal">
            Adjust the default settings if you need more specific tracking.
          </div>
          {formik.values.trackingOptions.option === SNAPSHOT_TRACKING_OPTIONS.URL && (
            <div className="mt-2.5">
              <div className="mb-2.5">
                <Button
                  variant="secondary"
                  disabled={changeTrackingDisabled}
                  onClick={() => showUrlTrackingModal(formik)}
                >
                  {editUrlTracking ? 'Edit' : 'Customize'} my URL Tracking Rules
                </Button>
              </div>
              {changeTrackingDisabled && (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a {!siteUrlValue.length ? <strong>URL</strong> : <strong>valid URL</strong>} above
                  to set up Custom URL Tracking Rules.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-4">
          <RadioGroupOption
            option={{ value: SNAPSHOT_TRACKING_OPTIONS.WILDCARD, label: 'Use a Wildcard' }}
            disabled={isBulk}
          />
        </div>
        <div className="ml-[34px]">
          <div className="text-body-5 leading-normal">
            Use a wildcard to combine data for multiple pages into one Snapshot. This is useful to track multiple pages
            that share the same layout. For example, if your site has product pages that all start with
            http://example.com/products/, you can track all of them by entering http://www.example.com/products/*.
          </div>

          {formik.values.trackingOptions.option === SNAPSHOT_TRACKING_OPTIONS.WILDCARD && (
            <div className="mt-2.5">
              <div className="mb-2.5">
                <Button variant="secondary" disabled={changeTrackingDisabled} onClick={() => showWildcardModal(formik)}>
                  {editWildcard ? 'Edit' : 'Set up a'} Wildcard
                </Button>
              </div>
              {changeTrackingDisabled && (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a {!siteUrlValue?.length ? <strong>URL</strong> : <strong>valid URL</strong>}{' '}
                  above to set up Wildcard tracking.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-4">
          <RadioGroupOption
            option={{ value: SNAPSHOT_TRACKING_OPTIONS.REGEX, label: 'Use a Regular Expression' }}
            disabled={isBulk}
          />
        </div>
        <div className="ml-[34px]">
          <div className="text-body-5 leading-normal">
            Regular expressions let you combine data for multiple pages into one Snapshot. They offer more control over
            URL-matching than wildcards but require more knowledge to use properly.
          </div>

          {formik.values.trackingOptions.option === SNAPSHOT_TRACKING_OPTIONS.REGEX && (
            <div className="mt-2.5">
              <div className="mb-2.5">
                <Button variant="secondary" disabled={changeTrackingDisabled} onClick={() => showRegexModal(formik)}>
                  {editRegex ? 'Edit' : 'Set up a'} Regular Expression
                </Button>
              </div>
              {changeTrackingDisabled && (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a {!siteUrlValue?.length ? <strong>URL</strong> : <strong>valid URL</strong>}{' '}
                  above to set up Regular Expression tracking.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-4">
          <RadioGroupOption
            option={{ value: SNAPSHOT_TRACKING_OPTIONS.NAMED, label: 'Track by Name' }}
            disabled={isBulk}
          />
        </div>
        <div className="ml-[34px]">
          <div className="text-body-5 leading-normal">
            Track the same URL with multiple Snapshots. It is useful for running A/B tests, tracking the different steps
            of a multi-stage sign-up process, or just about any situation where you want to track pages that have
            different content but the same address.
          </div>

          {formik.values.trackingOptions.option === SNAPSHOT_TRACKING_OPTIONS.NAMED && (
            <div className="mt-2.5">
              <div className="mb-2.5">
                <Button
                  variant="secondary"
                  disabled={changeTrackingDisabled}
                  onClick={() => showNameTrackingModal(formik)}
                >
                  {editNameTracking ? 'Edit' : 'Set up'} Track by Name Tracking
                </Button>
              </div>
              {changeTrackingDisabled ? (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a {!siteUrlValue?.length ? <strong>URL</strong> : <strong>valid URL</strong>}{' '}
                  above to set up Name Tracking.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              ) : !nameValue?.length ? (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a <strong>Snapshot Name</strong> above to set up Name Tracking.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* <div className="mt-4">
          <RadioGroupOption option={{ value: GOOGLE_OPTIMIZE, label: 'Track by Google Optimize' }} disabled={isBulk} />
        </div>
        <div className="ml-[34px]">
          <div className="text-body-5 leading-normal">
            If you are running an experiment with Google Optimize, simply enter the tracking ID, experiment ID, and
            variant ID and you&#39;ll be able to create snapshots for each variant.
          </div>

          {formik.values.trackingOptions.option === GOOGLE_OPTIMIZE && (
            <div className="mt-2.5">
              <div className="mb-2.5">
                <Button
                  variant="secondary"
                  disabled={changeTrackingDisabled}
                  onClick={() => showGoogleOptimizeModal(formik)}
                >
                  {editGoogleOptimize ? 'Edit' : 'Set up'} Google Optimize Tracking
                </Button>
              </div>
              {changeTrackingDisabled && (
                <div className="text-body-5 leading-normal">
                  <span role="img" aria-label="point up">
                    👆
                  </span>{' '}
                  You need to provide a {!siteUrlValue?.length ? <strong>URL</strong> : <strong>valid URL</strong>}{' '}
                  above to set up Google Optimize tracking.
                  <ScrollTo selector={urlInputLabel} delay={1000} />
                </div>
              )}
            </div>
          )}
        </div> */}
      </RG>
    </section>
  );
}
