import { Paywall } from '/src/components/Paywall';

import NoSelectionPNG from '@crazyegginc/hatch/dist/images/illustration-button.png';
import NoSelectionWebP from '@crazyegginc/hatch/dist/images/illustration-button.webp';

export function NoAuditTrailSelection({ noSelectedActions, noSelectedUsers }) {
  const text = ['actions', 'users'];

  function selectOptionText() {
    if (noSelectedActions && noSelectedUsers) return `${text[0]} and ${text[1]}`;
    if (noSelectedActions) return `${text[0]}`;
    if (noSelectedUsers) return `${text[1]}`;
  }

  return (
    <Paywall>
      <Paywall.Image webp={NoSelectionWebP} fallback={NoSelectionPNG} width="128" />
      <Paywall.Title>Please select one or more options, in {selectOptionText()} dropdown.</Paywall.Title>
    </Paywall>
  );
}
