import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function DeleteResponseModal({ onConfirm }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Modal.Title>Delete this response?</Modal.Title>

      <div className="text-body-2">
        Are you sure you want to delete this response? <br />
        This cannot be undone.
      </div>

      <Modal.Actions>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm?.(() => modal.close());
          }}
        >
          Yes, delete response
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
