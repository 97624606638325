import { useState, useEffect } from 'react';

export function useDebounce(value, delay, deps = []) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // cancel the timeout if the value changes
    // timeout gets cleared and restarted
    return () => {
      clearTimeout(handler);
    };
    }, [value, delay, ...deps]); //eslint-disable-line

  return debouncedValue;
}
