import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';
import { useAtom } from 'jotai';

import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';

export function AbTestStatusDropdown({ atom }) {
  const [filters, setFilters] = useAtom(atom);

  const getHumanizedStatus = (status) => {
    switch (status) {
      case null:
      case 'ALL':
        return 'show all';
      case AB_TEST_STATUSES.STOPPED:
        return 'ended';
      default:
        return status?.toLowerCase?.() ?? null;
    }
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('capitalize', { '!border-dodger-blue-500': open })}
          >
            {getHumanizedStatus(filters.status)}
            <Indicator type="dropdown" className=" ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="center">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: null });
                    close();
                  }}
                >
                  Show All
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.DRAFT });
                    close();
                  }}
                >
                  Drafts
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.RUNNING });
                    close();
                  }}
                >
                  Running
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.STOPPED });
                    close();
                  }}
                >
                  Ended
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
