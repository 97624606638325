import { useEffect } from 'react';

import { useWizard } from '/src/hooks';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

import ShopifyPoster from '../assets/shopify-instructions-poster.jpg';
import ShopifyVideo from '../assets/shopify-instructions.mp4';

export function ShopifyInstructions() {
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/shopify/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Shopify',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Shopify!</WizardHeader>
      <WizardSubHeader>
        You&#39;ll need to install the Crazy Egg app found within the Shopify App Store.
      </WizardSubHeader>

      <div className="text-header-4 mb-2.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>Log into your Shopify account.</WizardList.Item>
        <WizardList.Item>
          Visit{' '}
          <a
            className="text-link mt-2.5"
            target="_blank"
            rel="noreferrer noopener"
            href="https://apps.shopify.com/crazy-egg"
          >
            apps.shopify.com/crazy-egg
          </a>
          .
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Add App</strong>.
        </WizardList.Item>
        <WizardList.Item>
          You&#39;ll be redirected to the Shopify installation screen. Review the setup information, and click{' '}
          <strong className="text-body-1">Install app</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click <strong className="text-body-1">Connect existing account</strong>.
        </WizardList.Item>
        <WizardList.Item>Enter your Crazy Egg username and password.</WizardList.Item>
        <WizardList.Item>
          Go to <strong className="text-body-1">Themes</strong> under Online Store.
        </WizardList.Item>
        <WizardList.Item>Click Customize.</WizardList.Item>
        <WizardList.Item>
          Select <strong className="text-body-1">Theme Settings</strong> in the bottom left corner.
        </WizardList.Item>
        <WizardList.Item>
          Select <strong className="text-body-1">App Embeds</strong>.
        </WizardList.Item>
        <WizardList.Item>Search for Crazy Egg (if needed).</WizardList.Item>
        <WizardList.Item>
          <strong className="text-body-1">Enable</strong> the Embedded App.
        </WizardList.Item>
        <WizardList.Item>
          Click <strong className="text-body-1">Save</strong> in the top right corner.
        </WizardList.Item>
      </WizardList>

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={ShopifyVideo} poster={ShopifyPoster} label="WordPress installation video" />
      </div>

      <a href={SupportLinks.install.shopify} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Shopify
      </a>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Shopify.
      </div>
    </div>
  );
}
