export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function numberOfKeys(obj) {
  if (!isObject(obj)) return 0;
  if (Object.keys(obj).length === 0) return 0;
  return JSON.stringify(obj).match(/[^\\]":/g).length;
}

export function updateObject(obj, keys, value) {
  Object.keys(obj).forEach((k) => {
    if (isObject(obj[k])) {
      updateObject(obj[k], keys, value);
    } else {
      if (keys.includes(k)) {
        obj[k] = value;
      }
    }
  });
}
