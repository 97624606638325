import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';

export function EditModal({ goal, onConfirm = null, onCancel = null, onCreate = null }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[380px]">
      <Modal.Title>Edit this Goal?</Modal.Title>
      <div className="mb-6 flex w-full justify-between">
        <p className="mt-0 text-sm leading-5 text-lynch-500">
          If you edit this goal, you will also change it for {goal.abTestsCountActive}
          <br />
          other {inflect('test', goal.abTestsCount)}. This cannot be undone. We recommend that you{' '}
          <span
            className="text-link"
            onClick={() => {
              onCreate?.();
              modal.close();
            }}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onCreate?.();
                modal.close();
              }
            }}
            tabIndex={0}
          >
            create a new goal
          </span>
          .
        </p>
      </div>
      <div className="flex">
        <Button
          onClick={() => {
            onConfirm?.(goal);
            modal.close();
          }}
        >
          Edit Goal
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            onCancel?.();
            modal.close();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
