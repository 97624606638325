import classNames from 'classnames';
import { Input } from '@crazyegginc/hatch';

import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

export function FilterText({
  width = '200px',
  value,
  placeholder = '',
  onChange,
  chainingText,
  label,
  error,
  removable,
  onRemove,
}) {
  return (
    <>
      {chainingText && (
        <div className="text-body-4 m-1.25 mr-2.5 flex h-[35px] items-center justify-center">{chainingText}</div>
      )}
      <div className="relative m-1.25 ml-0" style={{ width }}>
        <Input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          aria-label={label}
          error={error}
        />
        {removable && (
          <button
            onClick={onRemove}
            className={classNames(
              'absolute right-0 top-0 flex h-[35px] w-[35px] items-center justify-center border-l border-mystic-500',
              'text-dodger-blue-300 hover:text-dodger-blue-500 focus:text-dodger-blue-500 focus-visible:outline-black',
            )}
          >
            <CrossIcon className="h-2.5 w-2.5 fill-current" aria-label="delete condition value" />
          </button>
        )}
      </div>
    </>
  );
}
