import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

export function ABTestingPaywallText({ children }) {
  return children;
}

function ABTestingPaywallTitle() {
  return 'Quickly try out new ideas with A/B Testing';
}

function ABTestingPaywallBody() {
  return (
    <>
      <p className="mb-2">Got new copywriting and page designs you want to validate before launching?</p>
      <p className="leading-tight">
        Run an A/B Test to compare your new ideas against current pages, and see which performs better!
      </p>
    </>
  );
}

function ABTestingPaywallList() {
  return (
    <div className="mt-4 flex justify-center px-10">
      <ul className="space-y-2">
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          Easily create tests with URL redirects or our Visual Page Editor
          <br />
          (no coding required).
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          Analyze conversion rates, statistical significance, and total conversion value across multiple Goals.
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          Automatically generate Heatmap Snapshots for each page variant.
        </li>
      </ul>
    </div>
  );
}

ABTestingPaywallText.Title = ABTestingPaywallTitle;
ABTestingPaywallText.Body = ABTestingPaywallBody;
ABTestingPaywallText.List = ABTestingPaywallList;
