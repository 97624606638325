import { useAuthContext } from '/src/hooks';
import { LANGUAGES } from '/src/features/_global/constants';

export function useUserLanguage() {
  const { currentUser } = useAuthContext();

  const langCode = currentUser?.language ?? 'en';

  return LANGUAGES[langCode].name;
}
