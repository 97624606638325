import { useState } from 'react';
import { useFormikContext, getIn } from 'formik';
import { TextArea } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
const defaultHeight = 1.5 * 14 * 1.5 + 22; // see TextArea in hatch
const extendedHeight = 2.5 * 14 * 1.5 + 22;

import { DEFAULT_ADDITIONAL_INFO } from '../../ctas/editor/CTAEditor';
import { MarkdownTooltip } from '/src/features/addons/common/editor/MarkdownTooltip';

export function AdditionalInformation({ fieldPath, labelExtra = 'Optional', markdownSupport = true, markdownType }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  const [textAreaHeight, setTextAreaHeight] = useState(defaultHeight);

  const error = getIn(errors, fieldPath);
  const value = getIn(values, fieldPath);
  const isTouched = getIn(touched, fieldPath);

  function selectTextOnFocus(e) {
    if (e.target.value === DEFAULT_ADDITIONAL_INFO) {
      e.target.select();
    }
  }

  return (
    <div>
      <TextArea
        label={
          <div className="mb-1">
            <span className="text-body-1">Additional Information</span>
            <span className="text-body-5 ml-2.5">{labelExtra}</span>
          </div>
        }
        rows={1.5}
        id={fieldPath}
        name={fieldPath}
        value={value}
        error={error && isTouched ? error : null}
        onChange={handleChange}
        onBlur={(e) => {
          setTextAreaHeight(defaultHeight);
          handleBlur(e);
        }}
        onFocus={(e) => {
          setTextAreaHeight(extendedHeight);
          selectTextOnFocus(e);
        }}
        className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded"
        style={{ height: `${textAreaHeight}px` }}
        disabled={readonly}
      />
      {markdownSupport && (
        <div className="text-caption mb-[-19px] mt-1.25 flex h-3.5 items-center justify-end space-x-2.5">
          <MarkdownTooltip markdownType={markdownType} />
        </div>
      )}
    </div>
  );
}
