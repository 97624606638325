import { useState, useEffect, useCallback } from 'react';
import { getUnixTime } from 'date-fns';

import { usePermissions, useOutsideClickNotify, usePrevious } from '/src/hooks';
import { useMocky, mockyNotificationsConfig } from './mocky';

import { FEATURES } from '/src/features/_global/constants';

const storageObj = 'keep:shell:mockyNavbar';

export function useMockyNavSidebar({ commentsRef }) {
  const permissions = usePermissions();
  const [mockyReady, setMockyReady] = useState(false);
  const [isCommentsOpen, setCommentsOpen] = useState(false);
  const [newNotificationExist, setNewNotificationExist] = useState(false);
  const prevIsCommetsOpen = usePrevious(isCommentsOpen);
  useMocky(
    mockyNotificationsConfig,
    useCallback(() => setMockyReady(true), []),
    useCallback(() => setMockyReady(true), []),
    permissions.can('navigate', FEATURES.COMMENTING).allowed,
  );

  useEffect(() => {
    function onSidebarOpen() {
      setCommentsOpen(true);
    }
    function onSidebarClose() {
      setCommentsOpen(false);
    }
    if (mockyReady) {
      window.Mocky?.on('sidebarOpen', onSidebarOpen);
      window.Mocky?.on('sidebarClose', onSidebarClose);
      return () => {
        window.Mocky?.off('sidebarOpen', onSidebarOpen);
        window.Mocky?.off('sidebarClose', onSidebarClose);
      };
    }
  }, [mockyReady]);

  useEffect(() => {
    let timer;
    let interval = 5000;
    const pollFunction = async () => {
      if (window.Mocky && window.Mocky.getNotificationsCount) {
        if (interval === 5000) {
          interval = 30000;
          clearTimeout(timer);
          timer = setInterval(() => pollFunction(), interval);
        }
        const params = {
          since: localStorage.getItem(storageObj),
        };
        setNewNotificationExist((await window.Mocky.getNotificationsCount(params)) > 0);
      }
    };

    if (!timer && !isCommentsOpen) {
      timer = setInterval(() => pollFunction(), interval);
    }
    if (isCommentsOpen && !prevIsCommetsOpen) {
      localStorage.setItem(storageObj, getUnixTime(Date.now()));
      pollFunction();
      clearInterval(timer);
    }
    if (!isCommentsOpen && prevIsCommetsOpen) {
      if (prevIsCommetsOpen) {
        localStorage.setItem(storageObj, getUnixTime(Date.now()));
      }
    }

    return () => clearInterval(timer);
  }, [isCommentsOpen, prevIsCommetsOpen]);

  useOutsideClickNotify([{ current: document.querySelector('mocky-comment') }, commentsRef], () => {
    window.Mocky?.closeNotifications();
  });

  return { isCommentsOpen, newNotificationExist };
}
