import { Link } from 'react-router-dom';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';

export function CreateTemplateButton() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as={Button} size="xl" className={open ? '!border-dodger-blue-500' : ''}>
            Create New Template
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="center" className="!w-[180px]">
            <>
              <PopoverItem
                component={Link}
                to={{ pathname: '/addons/templates/surveys/new' }}
                className="group flex items-center"
              >
                <SurveyIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                Create Survey
              </PopoverItem>
              <PopoverItem
                component={Link}
                to={{ pathname: '/addons/templates/ctas/new' }}
                className="group flex items-center"
              >
                <ClickIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                Create CTA
              </PopoverItem>
            </>
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
