import { ScrollToTop } from '@crazyegginc/hatch';

import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { ErrorsDashboardContent } from '../components/dashboard/ErrorsDashboardContent';

import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

// eslint-disable-next-line no-restricted-syntax
export default function ErrorsDashboard() {
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.ERRORS_TRACKING);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.ERRORS_TRACKING} reason={reason} />;
  }

  return (
    <>
      <SEO title={getFeatureName(FEATURES.ERRORS_TRACKING)} />
      <DashHeader title={getFeatureName(FEATURES.ERRORS_TRACKING)} feature={FEATURES.ERRORS_TRACKING} />
      <ErrorsDashboardContent />
      <ScrollToTop />
    </>
  );
}
