import { useFormikContext } from 'formik';
import { RadioGroup, Input } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { useDebounce, useSpecificCompatibilityCheck } from '/src/hooks';
import { ElementSelector } from '/src/components/ElementSelector';
import { SNAPSHOT_BLOCKING_POPUP_OPTIONS } from '/src/features/snapshots/constants';

const compatibilityFeatures = ['SNAPSHOT_CREATION'];

export function BlockingElements() {
  const formik = useFormikContext();

  useSpecificCompatibilityCheck({
    features: compatibilityFeatures,
    enabled: formik.values.blocking.option === SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_ELEMENTS,
  });

  const url = useDebounce(formik.values.blocking.url, 500);
  const urlToLoad = url ? (url.match(/^https?:\/\//) ? url : `http://${url}`) : null;

  function onSelect(e) {
    formik.setValues(
      (values) => ({
        ...values,
        blocking: {
          ...values.blocking,
          elements: values.blocking.elements === '' ? e.payload : `${values.blocking.elements}, ${e.payload}`,
        },
      }),
      true,
    );
  }

  return (
    <section className="mb-0.5 mt-10">
      <h2 className="text-header-2 mb-4">Blocking Pop-Ups</h2>

      <RadioGroup
        options={[
          { value: SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_POPUPS, label: 'Omit pop-ups' },
          { value: SNAPSHOT_BLOCKING_POPUP_OPTIONS.DEFAULT, label: "Don't block anything" },
          { value: SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_ELEMENTS, label: 'Omit specific elements' },
        ]}
        value={formik.values.blocking.option}
        onChange={(newValue) => formik.setFieldValue('blocking.option', newValue)}
        optionWrapperClass="mb-2.5"
      />

      {formik.values.blocking.option === SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_ELEMENTS && (
        <>
          <div className="text-body-5 mt-2.5 leading-normal">
            To exclude unwanted elements from the Snapshot -- enter the CSS selector value below. When entering more
            than one value, separate them using a comma (,). For more information, see{' '}
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href={SupportLinks.snapshots.blockPopUpsOmitSpecificElements}
            >
              Blocking Popups and Modals
            </a>
            .
          </div>
          <div className="mt-2.5 w-full">
            <Input
              placeholder="#popup, #signup"
              name={'blocking.elements'}
              value={formik.values.blocking.elements}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.blocking?.elements && formik.errors?.blocking?.elements
                  ? formik.errors.blocking.elements
                  : null
              }
            />
          </div>
          <div className="text-body-2 mt-5 text-lynch-500">
            Or, enter the URL of the page, find and click on the element that you want to omit. We&apos;ll add it to the
            list of CSS selectors for you.
          </div>
          <div className="mt-2.5 w-full">
            <Input
              placeholder="e.g. example.com"
              name={'blocking.url'}
              value={formik.values.blocking.url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched?.blocking?.url && formik.errors?.blocking?.url ? formik.errors.blocking.url : null}
            />
          </div>
          {formik.values.blocking.url && urlToLoad && !formik.errors?.blocking?.url && (
            <div className="mt-5 h-[380px] w-full overflow-hidden rounded border border-mystic-500">
              <ElementSelector
                url={urlToLoad}
                editorPath="inspect"
                event-name="page-interaction"
                onSelect={onSelect}
                className="h-full w-full"
              />
            </div>
          )}
        </>
      )}
    </section>
  );
}
