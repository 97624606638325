import { superLenientUrlRegex } from '/src/utils/regex';

export function normalizeUrl(url, { appendSlash } = { appendSlash: true }) {
  let urlParts;

  try {
    urlParts = new URL(url);
  } catch {
    //
  }

  let result = '';

  if (!url.match(/^https?:\/\//) && url.length > 0 && !('http://'.includes(url) || 'https://'.includes(url))) {
    result += 'https://';
  }

  result += url;

  if (urlParts && urlParts.pathname.includes('.')) {
    // the url has an extension.
  } else if (appendSlash && !result.endsWith('/')) {
    result += '/';
  }

  return result;
}

export function normalizeWithHttps(url) {
  if (!url.match(/^https?:\/\//)) {
    return `https://${url}`;
  }
  return url;
}

export function escapeRegExp(val, escapeForwardSlashes = false) {
  if (escapeForwardSlashes) {
    return val.replace(/([-.*+?^${}()|[\]/\\])/g, '\\$1');
  } else {
    return val.replace(/([-.*+?^${}()|[\]\\])/g, '\\$1');
  }
}

export function regexForUrl(url) {
  const uri = new URL(url);
  let result = '^https?://((www|m)\\.)?' + escapeRegExp(uri.host.replace(/^(www|m)\./, ''));

  result += escapeRegExp(uri.pathname, false);

  if (uri.search) {
    if (uri.hash) {
      result += '\\?[^#]+';
    } else {
      result += '\\?.+';
    }
  }

  if (uri.hash) {
    result += escapeRegExp(uri.hash);
  }

  result += '$';

  return result;
}

export function wildcardToRegex(wildcard) {
  // replace wildcard chars with temporary stand-ins
  // '*' --> '[asterisk]' -> https://app.clubhouse.io/crazyegg/story/9144
  let result = wildcard.replace(/(^|[^\\])\*/g, '$1[asterisk]');

  // escape regex characters
  result = escapeRegExp(result);

  // replace stand-in characters with intended regex patterns
  result = `^${result.replace(/\\\[asterisk\\\]/g, '.*').replace(/\\\//g, '/')}$`;
  return result;
}

export function isFile(input) {
  const commonFileExtensions = ['.exe', '.pdf', '.csv', '.json', '.xml', '.txt', '.doc', '.docx'];
  try {
    const url = new URL(input);
    return commonFileExtensions.some((extension) => url.pathname.endsWith(extension));
  } catch {
    return false;
  }
}

export function isValidUrl(input) {
  if (input === null || input.trim() === '') return false;
  if (typeof input !== 'string') return false;
  return superLenientUrlRegex.test(input);
}

export const playerStateParams = ['speed', 'skip', 'autoplay'];
export const sessionParameters = ['sharing', 'mode'];
export const playerPlaylistParams = ['group', 'page', 'order', 'sort', 'filters', 'site', 'autoplay', 'skipflag'];
export const surveyParams = ['site', 'query'];
export const errorParams = ['date'];

export function getListOfQueryParams(params, additionalParams = {}) {
  const searchParams = Object.fromEntries(new URLSearchParams(location.search.slice(1)).entries());
  const response = new URLSearchParams();
  params.forEach((param) => {
    if (searchParams?.[param]) {
      response.append(param, searchParams[param]);
    }
  });
  Object.keys(additionalParams).forEach((paramKey) => {
    response.append(paramKey, additionalParams[paramKey]);
  });
  return response.toString();
}

export function getSessionQueryParameters(additionalParams = {}) {
  return getListOfQueryParams([...sessionParameters, ...playerStateParams], additionalParams);
}

export function getPlayerPlaylistQueryParams(additionalParams = {}) {
  return getListOfQueryParams([...sessionParameters, ...playerPlaylistParams, ...playerStateParams], additionalParams);
}

export function getErrorsQueryParams(additionalParams = {}) {
  return getListOfQueryParams(errorParams, additionalParams);
}

export function getSurveyQueryParams(additionalParams = {}) {
  return getListOfQueryParams(surveyParams, additionalParams);
}

export function getQueryParams(params = {}) {
  const response = new URLSearchParams();
  Object.keys(params).forEach((paramKey) => {
    response.append(paramKey, params[paramKey]);
  });
  return response.toString();
}

// ensures the destination has a protocol set
export function ensureUrlHasProtocol(destination, comparison) {
  if (destination.startsWith('http')) return destination;
  if (comparison && comparison.startsWith('https://')) {
    return `https://${destination}`;
  }
  return `http://${destination}`;
}

export function displayUrl(input) {
  // remove the protocol and www. and trailing slashes
  return input.replace('https://', '').replace('http://', '').replace('www.', '').replace(/\/$/, '');
}

export function removeProtocol(url) {
  return url.replace(/^https?:\/\//, '');
}

export function ensureProtocol(url) {
  return url.match(/^https?:\/\//) ? url : `http://${url}`;
}

export function displaySiteUrl(url, siteName = '') {
  const URLobj = new URL(ensureProtocol(url));
  const { hostname, pathname } = URLobj;

  return hostname === siteName ? (pathname === '/' ? 'Homepage' : pathname) : `${hostname}${pathname}`;
}

export function generateWildcardUrl(input, fallbackDomain = 'https://example.com') {
  const trimmedInput = input.trim();

  if (!trimmedInput) return `${fallbackDomain}/*`;

  if (trimmedInput.indexOf('*') >= 0) return trimmedInput;

  const url = trimmedInput.endsWith('/') ? trimmedInput : `${trimmedInput}/`;

  try {
    const { host, pathname, protocol } = new URL(url.startsWith('http') ? url : 'https://' + url);
    const processedPath = pathname.replace(/^\/.+?\//, '/*/').replace(/\/$/, '');
    const returnUrl = processedPath.indexOf('*') >= 0 ? `${host}${processedPath}` : `${host}/*`;
    if (url.includes(protocol)) {
      return `${protocol}//${returnUrl}`;
    } else {
      return returnUrl;
    }
  } catch {
    return `${fallbackDomain}/*`;
  }
}

export function hasWildcard(string) {
  return (string ?? '').indexOf('*') > -1;
}
