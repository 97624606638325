import { useNavigate } from 'react-router-dom';
import { Popover, StyledPopoverPanel, PopoverItem, IconButton } from '@crazyegginc/hatch';

import { useModal, useMutation, usePermissions } from '/src/hooks';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';

import { addonTemplateDeleteMutation } from '/src/features/addons/mutations';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as MenuIcon } from '@crazyegginc/hatch/dist/images/icon-menu-dots.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function TemplateMenu({ template }) {
  const modal = useModal();
  const deleteTemplate = useMutation(addonTemplateDeleteMutation);
  const permissions = usePermissions();
  const canManageTemplates = permissions.can('manageTemplates', FEATURES.ADDONS).allowed;
  const navigate = useNavigate();

  if (!canManageTemplates) return null;

  return (
    <Popover className="relative" data-testid="question-menu">
      {() => (
        <>
          <Popover.Button
            as={IconButton}
            className="text-cadet-blue-500 hover:text-dodger-blue-500"
            icon={<MenuIcon className="h-4 w-4 fill-current" />}
          />
          <StyledPopoverPanel align="right" className="!w-32">
            {({ close }) => (
              <>
                <PopoverItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    close();
                    navigate(`/addons/templates/${template.id}/edit`);
                  }}
                  className="group flex items-center"
                >
                  <EditIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                  Edit
                </PopoverItem>
                <PopoverItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    modal.show(
                      <DeleteConfirmationModal
                        entity="Template"
                        text="Are you sure you want to delete this template?"
                        onDelete={() => {
                          deleteTemplate.mutate({ id: template.id });
                          modal.close();
                        }}
                      />,
                    );
                    close();
                  }}
                  className="group flex items-center"
                >
                  <DeleteIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-carnation-500" />
                  Delete
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
