import { capitalize } from '@crazyegginc/hatch';

import { FEATURES } from '/src/features/_global/constants';

export function getFeatureName(feature) {
  switch (feature) {
    case FEATURES.ADDONS:
      return 'Add-Ons';
    case FEATURES.CTAS:
      return 'CTAs';
    case FEATURES.AB_TESTING:
      return 'A/B Testing';
    case FEATURES.WHATS_NEW:
      return "What's new";
    default:
      return feature
        .split('_')
        .map((x) => capitalize(x))
        .join(' ');
  }
}

export function isValidVersion(version) {
  // Extended regular expression to match a valid version string with optional tags
  const regex = /^\d+(\.\d+)*(-[a-zA-Z0-9-_]+)?$/;
  return regex.test(version);
}

export function normalizeVersion(version) {
  // Extract the numeric part and the tag part of the version
  const [numericPart, tagPart] = version.split('-', 2);
  const segments = numericPart.split('.');
  const maxSegments = 3;
  const missingSegments = maxSegments - segments.length;

  for (let i = 0; i < missingSegments; i++) {
    segments.push('0');
  }

  // Reconstruct the version string with the tag part if present
  return tagPart ? `${segments.join('.')}-${tagPart}` : segments.join('.');
}

export function compareVersions(version1, version2) {
  // Validate both version strings first
  if (!isValidVersion(version1) || !isValidVersion(version2)) {
    throw new Error('Invalid version format.');
  }

  // Normalize both versions to have the same number of segments.
  const normalizedVersion1 = normalizeVersion(version1);
  const normalizedVersion2 = normalizeVersion(version2);

  // Compare the normalized versions.
  return normalizedVersion1.localeCompare(normalizedVersion2, undefined, { numeric: true, sensitivity: 'base' });
}

// Examples
// console.log(compareVersions('1', '1.0')); // 0, considered equal
// console.log(compareVersions('1.2', '1.2.0')); // 0, considered equal
// console.log(compareVersions('1.2.3', '1.2.10')); // -1, '1.2.3' < '1.2.10'
// console.log(compareVersions('11.5.169', '11.5.169-ga')); // -1
// console.log(compareVersions('11.5.169-ga', '11.5.169-ga-test')); // -1
// console.log(compareVersions('11.5.169-ga-test', '11.5.169-ga-test')); // 0
// This should throw an error
// try {
//   console.log(compareVersions('1..2', '1.2.0')); // Invalid version format
// } catch (e) {
//   console.error(e.message);
// }

export function versionGt(version1, version2) {
  return compareVersions(version1, version2) > 0;
}

export function versionGte(version1, version2) {
  return compareVersions(version1, version2) >= 0;
}

export function versionEq(version1, version2) {
  return compareVersions(version1, version2) === 0;
}

export function versionLt(version1, version2) {
  return compareVersions(version1, version2) < 0;
}

export function versionLte(version1, version2) {
  return compareVersions(version1, version2) < 0;
}
