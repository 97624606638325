import { useRef, useState, useEffect, useCallback, createRef } from 'react';
import classNames from 'classnames';
import { Tooltip, Tag } from '@crazyegginc/hatch';

import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';

export function TableTagList({ tags, onTagClick, className }) {
  const containerRef = useRef();
  const [tagRefs, setTagRefs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tagClicked, setTagClicked] = useState(false);
  const [numberOverflowing, setNumberOverflowing] = useState(0);

  const Component = onTagClick ? 'button' : 'div';

  const setContainerRef = useCallback((node) => {
    setLoaded(true);
    containerRef.current = node;
  }, []);

  useEffect(() => {
    // used for scrolling and closing tooltip
    if (tagClicked) {
      document.querySelector('div[data-testid="filtersPane-edit"]').scrollIntoView({ block: 'center' });
      setTagClicked(false);
    }
  }, [tagClicked]);

  useEffect(() => {
    setTagRefs((tagRefs) =>
      Array(tags.length)
        .fill()
        .map((_, i) => tagRefs[i] || createRef()),
    );
  }, [tags.length, loaded]);

  useEffect(() => {
    setNumberOverflowing(getNumberOverflowing(tagRefs, containerRef));
  }, [tagRefs]);

  if (!tags?.length) return null;
  return (
    <div
      className={classNames(
        'relative flex max-h-[50px] min-w-0 flex-1 flex-wrap items-center overflow-hidden px-0.5',
        className,
      )}
      ref={setContainerRef}
    >
      {tags.map((tag, index) => {
        return (
          <Component
            type="button"
            ref={tagRefs[index]}
            key={tag}
            className="my-[2.5px] mr-1.25 min-w-0 focus-visible:outline-black"
            style={{ outlineOffset: '0' }}
            onClick={
              onTagClick
                ? () => {
                    onTagClick(tag);
                    setTagClicked(true);
                  }
                : null
            }
          >
            <Tag tag={tag} tagClass="!m-0" showTooltip />
          </Component>
        );
      })}
      {numberOverflowing > 0 && !tagClicked && (
        <div
          className="absolute bottom-0 right-0 pl-[15px] hover:cursor-pointer"
          style={{ filter: 'drop-shadow(-8px 0px 3px rgba(255, 255, 255, 0.8))' }}
        >
          <Tooltip
            placement="left"
            arrowSkiddingPercent="15"
            interactive
            darkTheme={false}
            tooltipContent={
              <div className="text-body-2 flex flex-col items-start px-1.5 pb-1 pt-2">
                <div className="mb-2.5 flex items-center">
                  <TagIcon className="text-cadet-blue-900 mr-1 h-3.5 w-3.5 flex-shrink-0 fill-current" />
                  Tags
                </div>
                {tags.map((tag) => {
                  return (
                    <Component
                      type="button"
                      key={tag}
                      onClick={
                        onTagClick
                          ? () => {
                              onTagClick(tag);
                              setTagClicked(true);
                            }
                          : null
                      }
                      className="mb-1.25 focus-visible:outline-black"
                    >
                      <Tag tag={tag} tagClass="!m-0" />
                    </Component>
                  );
                })}
              </div>
            }
          >
            <Tag tag={`+${numberOverflowing}`} />
          </Tooltip>
        </div>
      )}
    </div>
  );
}

function getNumberOverflowing(tagRefs, containerRef) {
  return tagRefs.reduce((acc, tagRef) => {
    if (tagRef.current?.getBoundingClientRect().bottom > containerRef.current?.getBoundingClientRect().bottom) {
      acc += 1;
    }
    return acc;
  }, 0);
}
