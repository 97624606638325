import { useSelector } from '@xstate5/react';

import { VariantWeightsPanel } from '/src/features/ab-testing/components/VariantWeightsPanel';

export function AbTestWeightsPage({ actorRef }) {
  const payload = useSelector(actorRef, (snapshot) => snapshot.context.payload);

  return (
    <div className="mx-auto flex w-[710px] flex-col space-y-10">
      <h1 className="text-header-0 m-0 mt-14 text-center">How do you want to split your traffic?</h1>

      <h2 className="text-header-2 mb-10">Automatically split the traffic or manually set the traffic split.</h2>

      <div className="space-y-5">
        <VariantWeightsPanel
          abTest={payload}
          onSelect={(value) => actorRef.send({ type: 'SET_REWEIGHT', value })}
          onChange={(value) => actorRef.send({ type: 'ADJUST_WEIGHTS', value })}
        />
      </div>
    </div>
  );
}
