import { useRef, useState, useEffect } from 'react';
import { Button } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { copyToClipboard } from '/src/utils';

import { ReactComponent as LinkIcon } from '@crazyegginc/hatch/dist/images/icon-link-outline.svg';

export function CopyURLModal({ newShareUrl }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const urlRef = useRef(null);

  const copyUrl = () => {
    const urlText = urlRef?.current?.value;
    copyToClipboard(urlText);
    setCopied(true);
  };

  return (
    <Modal>
      <Modal.Title>Your new URL share link</Modal.Title>
      <Modal.Actions className="!mt-0">
        <div className="relative w-full">
          <input
            ref={urlRef}
            className="h-[50px] w-full truncate rounded border border-mystic-500 bg-transparent pl-5 text-sm"
            disabled
            value={newShareUrl}
            style={{ paddingRight: '160px' }}
          />

          <div className="absolute right-2.5 top-1/2 -translate-y-1/2 transform">
            <Button
              onClick={() => copyUrl()}
              className="!text-xs"
              variant="secondary"
              leftIcon={<LinkIcon className="mr-2 h-3 w-3 fill-current" />}
            >
              {copied ? 'Link copied!' : 'Copy share link'}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}
