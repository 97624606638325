import { Toggle } from '@crazyegginc/hatch';

import { addonEnabledMutation } from '/src/features/addons/mutations';
import { usePermissions, useMutation } from '/src/hooks';

export function AddonActiveToggle({ addon = null, showLoadingState = true }) {
  const addonEnabled = useMutation(addonEnabledMutation);

  const permissions = usePermissions();
  const canToggle = permissions.can('toggle', addon).allowed;
  const disabled = !canToggle;

  return (
    <Toggle
      labelClasses="!mt-px"
      disabled={addonEnabled.isLoading || disabled}
      visuallyDisable={false}
      enabled={addon.active}
      setEnabled={(newValue) => {
        addonEnabled.mutate({
          id: addon.id,
          siteId: addon.siteId,
          status: newValue,
        });
      }}
      label={addonEnabled.isLoading && showLoadingState ? 'Saving...' : addon.active ? 'On' : 'Off'}
      displayLabel={true}
    />
  );
}
