import { createContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import { useAuthContext, useSelectedSite } from '/src/hooks';
import { newGoalMachine } from '/src/features/goals/machines/wizard';

export const GoalWizardContext = createContext({});

import { GoalsWizard } from '/src/features/goals/components/GoalsWizard';

// eslint-disable-next-line
export default function NewGoalPage({ flow, onDone }) {
  const { selectedSite } = useSelectedSite();
  const location = useLocation();
  const navigate = useNavigate();
  const ctaId = useRef(location.state?.ctaId);

  const { currentUser } = useAuthContext();
  const { defaultCurrency } = currentUser.settings;

  const [, , service] = useMachine(newGoalMachine, {
    devTools: true,
    context: {
      ctaId: ctaId.current,
      defaultCurrency: defaultCurrency || 'USD',
      siteId: selectedSite?.id,
    },
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.value === 'cancelled') {
        if (state.context.ctaId) {
          return navigate(`/addons/${state.context.ctaId}/edit`, { replace: true });
        }
        return navigate('/goals', { replace: true });
      }
      if (state.value === 'completed') {
        if (state.context.ctaId) {
          return navigate(`/addons/${state.context.ctaId}/edit`, {
            state: { goalId: state.context.goalId },
            replace: true,
          });
        }
        return navigate('/goals/new/success', { replace: true });
      }
    });

    return subscription.unsubscribe;
  }, [navigate, service]);

  return (
    <GoalWizardContext.Provider value={{ goalService: service }}>
      <GoalsWizard service={service} />
    </GoalWizardContext.Provider>
  );
}
