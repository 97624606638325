import { useFormikContext } from 'formik';

import { SectionHeader, SectionPanel, SectionContent } from '../../common/basic-ui';
import { Heading } from './Heading';
import { AdditionalInformation } from '../../common/editor/AdditionalInformation';
import { ImageUpload } from '../../common/editor/ImageUpload';
import { ButtonText } from './ButtonText';
import { BarButtonSelector } from './BarButtonSelector';
import { CTA_TYPES, ADDONS_CAPABILITIES, MARKDOWN_TYPES } from '/src/features/addons/constants';

import { useEditorContext } from '../../editor-context';

import { ReactComponent as ContentIcon } from '@crazyegginc/hatch/dist/images/icon-content.svg';

export function Content() {
  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <ContentIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
          Content
        </div>
      </SectionHeader>
      <SectionContent>
        <ContentTypes />
      </SectionContent>
    </SectionPanel>
  );
}

function ContentTypes() {
  const { values } = useFormikContext();

  switch (values.displayType) {
    case CTA_TYPES.BUTTON:
      return <ButtonContent />;
    case CTA_TYPES.BAR:
      return <BarContent />;
    case CTA_TYPES.POPUP:
      return <PopupContent />;
    default:
      return null;
  }
}

function ButtonContent() {
  const { addonsCapabilities } = useEditorContext();
  const hasTooltipMarkdownSupport = addonsCapabilities[ADDONS_CAPABILITIES.BUTTON_CTA_TOOLTIP_MARKDOWN];

  return (
    <>
      <ButtonText />
      <AdditionalInformation
        fieldPath="subtext"
        labelExtra="Optional - Shows as tooltip on hover"
        markdownSupport={hasTooltipMarkdownSupport ? true : false}
        markdownType={MARKDOWN_TYPES.INLINE_NO_IMG}
      />
    </>
  );
}

function BarContent() {
  const { values } = useFormikContext();
  return (
    <>
      <Heading />
      <AdditionalInformation fieldPath="subtext" markdownType={MARKDOWN_TYPES.INLINE} />
      <div className="flex items-center space-x-9">
        <BarButtonSelector />
        {values.displayType === CTA_TYPES.BAR && !values.bar.showButton ? null : <ButtonText />}
      </div>
    </>
  );
}

function PopupContent() {
  const { addonsCapabilities, isTemplate } = useEditorContext();
  const hasImageSupport = addonsCapabilities[ADDONS_CAPABILITIES.POPUP_IMAGE];

  return (
    <>
      <Heading />
      {hasImageSupport && !isTemplate && <ImageUpload fieldPath="popup.image" />}
      <AdditionalInformation fieldPath="subtext" />
      <ButtonText />
    </>
  );
}
