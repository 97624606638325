import fp from '@crazyegginc/fingerprint';

import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';
import { getPageName } from '../../live-functions';
import { MainTextRow, SubTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function ClickEvent({ liveEvent, visitEvents, identifyEvents }) {
  const { rage, dead, fingerprint, visit_id } = liveEvent.attributes;

  let type = '';
  if (rage) {
    type = 'rage';
  } else if (dead) {
    type = 'dead';
  }

  let url;
  if (visitEvents[visit_id]?.attributes.url) {
    url = getPageName(visitEvents[visit_id]?.attributes.url);
  }

  const name = fingerprint.name || Object.keys(fp.TAG_TYPES).find((tag) => fp.TAG_TYPES[tag] === fingerprint.type);

  return (
    <div className="flex flex-col">
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <div className="max-w-lg">
          <div className="flex items-center">
            <span className="shrink-0 whitespace-pre">{type} clicked </span>
            <TruncateWithTooltip text={name} className="text-body-1 mr-8" />
          </div>
        </div>
      </MainTextRow>
      {url && (
        <SubTextRow>
          <TruncateWithTooltip text={`on ${url}`} className="max-w-lg" />
        </SubTextRow>
      )}
    </div>
  );
}
