import { Formik, Form } from 'formik';
import { Button, TextArea, Spinner, capitalize } from '@crazyegginc/hatch';

import { ERROR_STATUSES } from '/src/features/errors/constants';
import { Modal } from '/src/contexts/modal';

export function ChangeStatusModal({ newStatus, onSubmit }) {
  let action1, action2;
  switch (newStatus) {
    case ERROR_STATUSES.RESOLVED:
      action1 = 'Resolving';
      action2 = 'Resolve';
      break;
    case ERROR_STATUSES.UNRESOLVED:
      action1 = 'Unresolving';
      action2 = 'Unresolve';
      break;
    case ERROR_STATUSES.MUTED:
      action1 = 'Muting';
      action2 = 'Mute';
      break;
  }

  return (
    <Modal data-testid="status-change-modal" dialogClassName="!w-[360px] !p-5">
      <Modal.Title>Change status to {capitalize(newStatus)}</Modal.Title>

      <Formik initialValues={{ comment: '' }} onSubmit={onSubmit}>
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <TextArea
              name="comment"
              label="Add comment (optional):"
              id="status_comment"
              placeholder="Type here..."
              value={values.comment}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus // eslint-disable-line
              rows={4}
            />

            <Modal.Actions className="!mt-5">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <div className="flex items-center">
                    <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                    {action1} error...
                  </div>
                ) : (
                  `${action2} error`
                )}
              </Button>
              <Modal.Cancel disabled={isSubmitting} />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
