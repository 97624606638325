import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { useEditorContext } from '../../editor-context';
import { SectionHeader, SectionPanel, SectionContent } from '../../common/basic-ui';
import { ColorAccessibility } from '../../common/editor/ColorAccessibility';
import { ColorSelector_v2 } from '../../common/editor/ColorSelector_v2';
import { PopupPositionSelector } from '../../common/editor/PopupPositionSelector';
import { ThemeSelector } from '../../common/editor/ThemeSelector';
import { ClosableBehavior } from '../../common/editor/ClosableBehavior';
import { RemoveBranding } from '../../common/editor/RemoveBranding';
import { SURVEY_DISPLAY_TYPES, ADDONS_CAPABILITIES } from '/src/features/addons/constants';

import { ReactComponent as AppearanceIcon } from '@crazyegginc/hatch/dist/images/icon-appearance.svg';

const lighterOptions = [
  '#0A1C2E',
  '#319E74',
  '#00A0BC',
  '#4B7BF8',
  '#906FE4',
  '#F55CA1',
  '#E94E4E',
  '#E77100',
  '#114379',
];
const darkerOptions = [
  '#FFFFFF',
  '#27B980',
  '#00B8D8',
  '#4A90FF',
  '#9B7FE9',
  '#FF7CB7',
  '#FD6868',
  '#FF8A42',
  '#FFEA00',
];

export function Appearance() {
  const { addonsCapabilities } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const isExternal = values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL;
  const isClosable = addonsCapabilities[ADDONS_CAPABILITIES.CLOSABLE];

  useEffect(() => {
    const currentOptionList = values.theme.dark ? lighterOptions : darkerOptions;
    const newOptionList = values.theme.dark ? darkerOptions : lighterOptions;
    const index = currentOptionList.findIndex((color) => color === values.theme.accent);
    if (index !== -1) {
      setFieldValue('theme.accent', newOptionList[index]);
    }
  }, [values.theme.dark, values.theme.accent, setFieldValue]);

  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <AppearanceIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
          Appearance
        </div>
      </SectionHeader>
      <SectionContent>
        <div className="flex items-center space-x-7">
          <ThemeSelector />
          <ColorSelector_v2
            defaultOptions={values.theme.dark ? darkerOptions : lighterOptions}
            fieldPath="theme.accent"
          />
        </div>
        {!isExternal && <PopupPositionSelector fieldPath="position" />}
        <ColorAccessibility />
        {!isExternal && isClosable && (
          <div>
            <div className="text-body-1 mb-px block">Close behavior</div>
            <ClosableBehavior label="Allow visitors to close my survey." />
          </div>
        )}
        <RemoveBranding />
      </SectionContent>
    </SectionPanel>
  );
}
