import { ReactComponent as AnguishedEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-anguished.svg';
import { ReactComponent as SadEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-sad.svg';
import { ReactComponent as NeutralEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-neutral.svg';
import { ReactComponent as HappyEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-happy.svg';
import { ReactComponent as GrinningEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-grinning.svg';

export function EmojiFaces(rating) {
  const Icons = [AnguishedEmojiIcon, SadEmojiIcon, NeutralEmojiIcon, HappyEmojiIcon, GrinningEmojiIcon];
  const items = [];
  for (let i = 0; i < rating; i++) {
    items.push(Icons[i]);
  }
  return (
    <div className="mr-2 flex items-center space-x-2">
      {items.map((Icon) => (
        <Icon key={Icon} className="h-4 w-4 opacity-50 last:opacity-100" />
      ))}
    </div>
  );
}
