import { useState } from 'react';
import { Disclosure } from '@headlessui/react';

import { AccountUsersSharingProvider } from '../../account-sharing-filter-context';
import { AccountUsersSharingContent } from './AccountUsersSharingContent';
import { BulkAction } from '/src/components/bulk-action/BulkAction';
import { NoSharedUrls } from './paywalls/NoSharedUrls';
import { NoMatchesWall } from '../../../_global/paywalls/NoMatchesWall';

import { SHARED_URL_STATUSES } from '/src/features/team-and-sharing/constants';
import { BULK_ACTIONABLE_ITEMS } from '/src/features/_global/constants';

export function AccountUsersSharing({ search, setSearch }) {
  const [activeNoData, setActiveNoData] = useState(false);
  const [inactiveNoData, setInactiveNoData] = useState(false);

  function clearSearch() {
    window.dispatchEvent(new Event('filters:reset'));
    setSearch('');
  }

  return (
    <>
      {activeNoData && inactiveNoData ? (
        search ? (
          <NoMatchesWall reset={() => clearSearch()} text="matches, try clearing the search" actionText="search" />
        ) : (
          <NoSharedUrls />
        )
      ) : null}
      <>
        <AccountUsersSharingProvider status={SHARED_URL_STATUSES.ENABLED}>
          <Disclosure>
            {({ open }) => (
              <AccountUsersSharingContent
                isOpen={open}
                contentStatus={SHARED_URL_STATUSES.ENABLED}
                search={search}
                setNoData={setActiveNoData}
              />
            )}
          </Disclosure>
        </AccountUsersSharingProvider>
        <AccountUsersSharingProvider status={SHARED_URL_STATUSES.DISABLED}>
          <Disclosure>
            {({ open }) => (
              <AccountUsersSharingContent
                isOpen={open}
                contentStatus={SHARED_URL_STATUSES.DISABLED}
                search={search}
                setNoData={setInactiveNoData}
              />
            )}
          </Disclosure>
        </AccountUsersSharingProvider>
        <BulkAction feature={BULK_ACTIONABLE_ITEMS.SHARED_URLS} />
      </>
    </>
  );
}
