import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';
import { CTAsPaywallText } from '/src/features/addons/paywalls/CTAsPaywallText';

import AddonPNG from '@crazyegginc/hatch/dist/images/illustration-addons.png';
import AddonWebP from '@crazyegginc/hatch/dist/images/illustration-addons.webp';

import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

export function NoCTAsWall({ mini }) {
  return (
    <Paywall>
      <Paywall.Image
        webp={AddonWebP}
        fallback={AddonPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>
        <CTAsPaywallText.Title />
      </Paywall.Title>

      <Paywall.Body>
        <CTAsPaywallText.Body />
        <CTAsPaywallText.List />
      </Paywall.Body>
      <NewAddonButton size="base" disableIfNoPermission={true} type={FEATURES.CTAS} />
    </Paywall>
  );
}
