import { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import { useEditorContext } from '../../editor-context';
import { useAuthContext, useNavBarDimensions } from '/src/hooks';
import { ADDON_PREVIEW_ID, CTA_TYPES, ADDONS_CAPABILITIES } from '/src/features/addons/constants';
import { PreviewOnSite } from './PreviewOnSite';
export const BAR_PREVIEW_HEIGHT = 165;

export function Preview({ saveCTA }) {
  const { values, errors } = useFormikContext();
  const { handleAddOnsCapabilities } = useEditorContext();
  const { navBarWidth } = useNavBarDimensions();
  const [ctaCreated, setCtaCreated] = useState(false);
  const [ctaAvailable, setCtaAvailable] = useState(false);

  const isValidForPreview =
    !errors.heading && !errors.theme && !errors.subtext && !errors.buttonTitle && !errors.action;
  const isBar = values.displayType === CTA_TYPES.BAR;
  const previousDisplay = useRef();
  const previewRef = useRef();

  const { currentAccount, currentUser } = useAuthContext();
  const { scriptVersion } = currentAccount;
  const { features: featureFlags } = currentUser;

  useEffect(() => {
    // we transition the whole preview section's opacity on type change to avoid flickering
    if (previousDisplay.current && previousDisplay.current !== values.displayType) {
      Object.assign(previewRef.current.style, {
        opacity: 0,
        transition: '',
      });
      setTimeout(
        () =>
          Object.assign(previewRef.current.style, {
            opacity: 1,
            transition: 'opacity 700ms 300ms',
          }),
        10,
      );
    }
    previousDisplay.current = values.displayType;
  }, [values.displayType]);

  useEffect(() => {
    if (!ctaCreated) {
      const { theme, bar, popup, button, closable, displayType, heading, subtext, buttonTitle, action } = values;
      window.CE2.CTA?.createCTA(1, {
        target: `#${ADDON_PREVIEW_ID}`,
        data: {
          theme,
          bar,
          popup,
          button,
          closable,
          displayType,
          heading,
          subtext,
          buttonTitle,
          action,
        },
        preview: true,
        userData: { scriptVersion, featureFlags },
      });
      setCtaCreated(true);
      setTimeout(() => setCtaAvailable(true), 200);
    }
  }, [ctaCreated, values, scriptVersion, featureFlags]);

  useEffect(() => {
    if (ctaAvailable) {
      window.CE2.CTA?.update(1, {
        data: {
          closable: values.closable,
          displayType: values.displayType,
          heading: values.heading,
          subtext: values.subtext,
          buttonTitle: values.buttonTitle,
          action: values.action,
        },
      });
    }
  }, [
    ctaAvailable,
    values.closable,
    values.displayType,
    values.heading,
    values.subtext,
    values.buttonTitle,
    values.action,
  ]);

  useEffect(() => {
    if (ctaAvailable) {
      window.CE2.CTA?.update(1, {
        data: {
          theme: values.theme,
        },
      });
    }
  }, [ctaAvailable, values.theme]);

  useEffect(() => {
    if (ctaAvailable) {
      window.CE2.CTA?.update(1, {
        data: {
          bar: values.bar,
          popup: values.popup,
          button: values.button,
        },
      });
    }
  }, [ctaAvailable, values.bar, values.popup, values.button]);

  useEffect(() => {
    if (ctaAvailable) {
      const capabilities = {
        [ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE]: window.CE2.CTA?.hasCapability(
          1,
          ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE,
        ),
        [ADDONS_CAPABILITIES.CLOSABLE_OPTIONS]: window.CE2.CTA?.hasCapability(1, ADDONS_CAPABILITIES.CLOSABLE_OPTIONS),
        [ADDONS_CAPABILITIES.POPUP_IMAGE]: window.CE2.CTA?.hasCapability(1, ADDONS_CAPABILITIES.POPUP_IMAGE),
        [ADDONS_CAPABILITIES.CUSTOM_BRANDING]: window.CE2.CTA?.hasCapability(1, ADDONS_CAPABILITIES.CUSTOM_BRANDING),
        [ADDONS_CAPABILITIES.COLOR_ACCESSIBILITY]: window.CE2.CTA?.hasCapability(
          1,
          ADDONS_CAPABILITIES.COLOR_ACCESSIBILITY,
        ),
        [ADDONS_CAPABILITIES.BUTTON_CTA_TOOLTIP_MARKDOWN]: window.CE2.CTA?.hasCapability(
          1,
          ADDONS_CAPABILITIES.BUTTON_CTA_TOOLTIP_MARKDOWN,
        ),
      };

      handleAddOnsCapabilities(capabilities);
    }
  }, [ctaAvailable, handleAddOnsCapabilities]);

  return (
    <section
      ref={previewRef}
      className={classNames('flex flex-col', {
        'fixed bottom-0 right-0 border-t border-mystic-500 px-10': isBar,
        'relative w-[400px] shrink-0 items-center justify-start pt-8': !isBar,
      })}
      style={{
        height: isBar ? `${BAR_PREVIEW_HEIGHT}px` : 'auto',
        left: isBar ? `${navBarWidth}px` : 'unset',
        backgroundColor: '#F9FAFD',
        backgroundImage: 'radial-gradient(rgb(239, 242, 247) 2px, rgb(249, 250, 253) 2px)',
        backgroundSize: '20px 20px',
      }}
    >
      {isBar ? (
        <>
          <div className="mt-6 flex items-center justify-between">
            <Header />
            <PreviewOnSite saveCTA={saveCTA} isValidForPreview={isValidForPreview} />
          </div>
          <PreviewSlot isValidForPreview={isValidForPreview} />
        </>
      ) : (
        <>
          <Header className="ml-10 mt-8 !self-start" />
          <PreviewSlot isValidForPreview={isValidForPreview} />
          <PreviewOnSite saveCTA={saveCTA} isValidForPreview={isValidForPreview} />
        </>
      )}
    </section>
  );
}

function Header({ className }) {
  return <h2 className={classNames('text-header-3', className)}>Preview</h2>;
}

function PreviewSlot({ isValidForPreview }) {
  const { values } = useFormikContext();

  return (
    <div
      id={ADDON_PREVIEW_ID}
      className={classNames('relative mt-3', {
        'w-full': values.displayType === CTA_TYPES.BAR,
        'h-[300px] w-[310px] border border-mystic-500': values.displayType === CTA_TYPES.BUTTON,
        'bg-white/50': values.displayType === CTA_TYPES.BUTTON && values.theme.dark,
        'bg-[#28292A]/85': values.displayType === CTA_TYPES.BUTTON && !values.theme.dark,
      })}
    >
      {!isValidForPreview ? (
        <div
          className={classNames(
            'rounded border border-mystic-500 bg-white px-5 py-5',
            'text-error text-center text-sm',
            '',
            {
              'absolute w-[250px] -translate-x-1/2': values.displayType === CTA_TYPES.POPUP,
              'absolute left-1/2 top-1/2 w-[250px] -translate-x-1/2 -translate-y-1/2':
                values.displayType === CTA_TYPES.BUTTON,
              'absolute -top-5 left-1/2 w-[450px] -translate-x-1/2': values.displayType === CTA_TYPES.BAR,
            },
          )}
        >
          Please fix any errors in the editor and then you&apos;ll be able to see the preview here.
        </div>
      ) : null}
    </div>
  );
}
