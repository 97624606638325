import { createContext, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import isEqual from 'react-fast-compare';
import { Banner } from '@crazyegginc/hatch';
import { useNotifications } from '/src/hooks';
import { NOTIFICATION_ACTIONS } from '/src/features/_global/constants';

const { ADD, REMOVE, REMOVE_ALL } = NOTIFICATION_ACTIONS;

export const NotificationContext = createContext();

const initialState = [];

function notificationReducer(state, action) {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: action.payload.id,
          title: action.payload.title,
          content: action.payload.content,
          type: action.payload.type,
        },
      ];

    case REMOVE:
      return state.filter((t) => t.id !== action.payload.id);

    case REMOVE_ALL:
      return initialState;

    default:
      return state;
  }
}

function NotificationCenter({ notifications }) {
  const notificationCenter = useNotifications();

  if (notifications.length === 0) return null;

  if (!notifications || notifications.length === 0) {
    return null;
  }

  return (
    <div className="fixed right-0 top-0 z-[99999] flex max-h-screen flex-col overflow-y-auto p-3.75">
      {notifications.map((notification) => (
        <Banner
          key={notification.id}
          title={notification.title}
          type={notification.type}
          onClose={() => notificationCenter.remove(notification.id)}
          className="!w-[445px]"
        >
          {notification.content}
        </Banner>
      ))}
    </div>
  );
}

export function NotificationProvider({ children }) {
  const [notifications, notificationDispatch] = useReducer(notificationReducer, initialState);
  const [notificationState, setNotifications] = useState({ notifications, notificationDispatch });

  if (!isEqual(notifications, notificationState.notifications)) {
    setNotifications({ notifications, notificationDispatch });
  }

  return (
    <NotificationContext.Provider value={notificationState}>
      {children}

      {createPortal(<NotificationCenter notifications={notifications} />, document.body)}
    </NotificationContext.Provider>
  );
}
