import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button, Input, Spinner, TextArea } from '@crazyegginc/hatch';

import { useMutation, useModal, useNotifications } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { sendAddonDetailEmailMutation } from '/src/features/account/mutations';

const validationSchema = yup.object().shape({
  message: yup.string().required('Please provide additional details.'),
});

export function EnterprisePlanModal({ email }) {
  const modal = useModal();
  const addonDetailEmail = useMutation(sendAddonDetailEmailMutation);
  const notifications = useNotifications();

  return (
    <Modal dialogClassName="!p-0">
      <div className="px-7 pt-7">
        <h2 className="text-header-4 mb-3">Let us help you change plans</h2>
        <p className="text-body-2 mb-5 text-[#3B4246]">
          Give us an idea of what you’re looking for and we’ll help you find the right plan for you.
        </p>
      </div>
      <Formik
        initialValues={{
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          addonDetailEmail.mutate(
            {
              email: {
                fromEmail: email,
                fromName: email,
                message: values.message,
                isCorporate: true,
              },
            },
            {
              onError: (error) =>
                notifications.error({ content: `Failed to send email.`, timeout: 3000, context: { error } }),
              onSuccess: () =>
                notifications.success({
                  title: 'Nice work!',
                  content: `Your request has been sent, we'll email once complete.`,
                  timeout: 3000,
                }),
              onSettled: () => modal.close(),
            },
          );
        }}
      >
        {({ touched, values, errors, handleChange, handleBlur, dirty }) => (
          <Form>
            <div className="border-b border-mystic-500 px-7 pb-7">
              <TextArea
                rows={4}
                name="message"
                id="enterprise_plan_message"
                placeholder="Please provide additional details for the number of pageviews, snapshots, and recordings you would like."
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.message ? errors.message : null}
              />
            </div>
            <div className="p-7">
              <p className="text-body-2 mb-3">
                We&lsquo;ll send an email to this address to finalize the plan & pricing.
              </p>
              <Input name="enterprisePlanEmail" id="enterprise-email-input" value={email} autoComplete="off" disabled />
            </div>
            <div className="flex space-x-1 px-7 pb-7">
              <Button
                leftIcon={addonDetailEmail.isLoading ? <Spinner className="mr-1 h-3 w-3" /> : null}
                disabled={!dirty || addonDetailEmail.isLoading}
                type="submit"
              >
                Send email
              </Button>
              <Modal.Cancel />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
