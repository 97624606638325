import { useQuery } from '@tanstack/react-query';
import Linkify from 'linkify-react';
import classNames from 'classnames';
import { Panel, capitalize, SkeletonLine } from '@crazyegginc/hatch';

import { formatDate, formatDateTime } from '/src/utils/date';
import { uniqueErrorNotesQuery } from '/src/features/errors/queries';
import { ERROR_STATUSES } from '/src/features/errors/constants';
import { AddNote } from './AddNote';

import { ReactComponent as CELogoIcon } from '@crazyegginc/hatch/dist/images/icon-logo-ce-balloon.svg';

export function ErrorHistory({ uniqueErrorId, siteId, uniqueErrorData }) {
  const { data, isLoading } = useQuery({
    ...uniqueErrorNotesQuery({
      fingerprintMd5: uniqueErrorId,
      siteId: siteId,
    }),
    enabled: Boolean(uniqueErrorId),
  });

  const notes = data?.uniqueErrorNotes;
  const statusChanges = notes?.filter((item) => item.id);
  const firstOccurrence = notes && notes[notes.length - 1];

  if (!data && !isLoading) {
    return null;
  }

  return (
    <div className="mb-10 w-full">
      <div className="text-header-3 mb-[15px] flex items-end justify-between">
        Error history <AddNote fingerprintMd5={uniqueErrorData?.fingerprintMd5} />
      </div>
      <Panel className="!max-h-[300px] !overflow-y-auto !p-0 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded hover:scrollbar-thumb-lynch-500">
        {isLoading ? (
          <SkeletonLine width="55%" />
        ) : (
          <div className="relative px-5 py-3.75">
            {statusChanges?.map((item) => (
              <div key={item.id} className="relative z-10 mt-[25px] flex first:mt-0">
                <div className="mr-2.5 flex h-[50px] w-10 items-center justify-center bg-white">
                  {item.guestId ? (
                    <img src={item.guestAvatarUrl} alt="Account avatar" className={'h-10 w-10 rounded-full'} />
                  ) : (
                    <CELogoIcon className="h-10 w-10 fill-current text-lima-500" />
                  )}
                </div>
                <div className={classNames('text-body-2', { 'mt-3.75': item.status, 'flex-1': !item.status })}>
                  {item.status && (
                    <div>
                      <span className="text-body-1">{item.guestId ? item.guestName : 'Crazy Egg'}</span> changed status
                      to
                      <EmojiStatus status={item.status} />
                      <span className="text-caption ml-2.5">{formatDate(item.createdAt)}</span>
                    </div>
                  )}

                  <Note item={item} />
                </div>
              </div>
            ))}

            {firstOccurrence && (
              <div className="relative z-10 mt-[25px] flex first:mt-0">
                <div className="my-0.5 mr-2.5 flex h-2.5 w-10 items-center justify-center bg-white">
                  <div className="h-2.5 w-2.5 rounded-full border-2 border-mystic-500 bg-white" />
                </div>
                <div className="text-body-2">
                  <div>
                    <span className="text-body-1">First occurrence of this error detected</span>
                    <span className="text-caption ml-2.5">{formatDateTime(firstOccurrence.createdAt)}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="absolute left-[39px] top-0 h-full w-0.5 py-5">
              <div className="h-full bg-mystic-500" />
            </div>
          </div>
        )}
      </Panel>
    </div>
  );
}

function EmojiStatus({ status }) {
  return (
    <>
      <span role="presentation" aria-hidden="true">
        {status === ERROR_STATUSES.RESOLVED && <> ✅ </>}
        {status === ERROR_STATUSES.UNRESOLVED && <> 🚫 </>}
        {status === ERROR_STATUSES.MUTED && <> 🤫 </>}
      </span>
      <span className="text-body-1">{capitalize(status)}</span>
    </>
  );
}

const linkifyOptions = {
  className: 'text-link text-xs',
  target: '_blank',
  rel: 'noopener noreferrer',
};

function Note({ item }) {
  return (
    <>
      {(item.note || !item.guestId) && (
        <>
          {!item.status && (
            <span className="text-caption">
              <span className="text-body-1">{item.guestId ? item.guestName : 'Crazy Egg'}</span>{' '}
              {formatDate(item.createdAt)}
            </span>
          )}

          <div className="rounded bg-white-lilac-500 p-3.75 text-xs">
            <Linkify options={linkifyOptions}>
              {item.note ??
                'We changed the status of this back to Unresolved because we automatically detected this error happened again.'}
            </Linkify>
          </div>
        </>
      )}
    </>
  );
}
