import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button, Input, Tooltip, Checkbox, Select, Divider, Spinner } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';

import { TooltipSiteAccessContent } from '../TooltipSiteAccessContent';
import { DeleteUserModal } from './DeleteUserModal';
import { TooltipRoleContent } from '../TooltipRoleContent';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';

import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

const validationSchema = yup.object().shape({
  role: yup.string().required('Role is required!'),
  sites: yup.array().min(1, 'Please choose a site.').of(yup.string()),
});

export function EditUserModal({
  selectedUserNames,
  numToUpdate,
  user,
  siteList,
  selectedIds,
  onDelete = null,
  onUpdate = null,
}) {
  const modal = useModal();
  const isMoreThanOne = numToUpdate > 1;
  const isFromBulkAction = isMoreThanOne || Array.isArray(user);
  const userIds = user.id ? [user.id] : [...selectedIds];

  return (
    <Modal dialogClassName="!w-[640px]" overlayClassName="!z-[50]">
      <Modal.Title>{`Edit ${isMoreThanOne ? numToUpdate : ''} ${inflect('team member', numToUpdate)} ?`}</Modal.Title>
      <Formik
        initialValues={{
          role: isFromBulkAction ? (isMoreThanOne ? '' : user[0].role.toUpperCase()) : user?.role,
          sites: isFromBulkAction ? (isMoreThanOne ? [] : user[0].sites) : user?.specificSites ? user?.sites : siteList,
          specificSites: isFromBulkAction ? (isMoreThanOne ? true : user[0].specificSites) : user?.specificSites,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formValues = {
            ids: userIds,
            role: values.role,
            sites: values.sites,
            specificSites: siteList.length !== values.sites.length,
          };
          onUpdate?.(formValues);
          modal.close();
        }}
      >
        {({ touched, dirty, handleChange, values, setFieldValue, setFieldTouched, errors, isSubmitting }) => {
          return (
            <Form className="w-full">
              <div className="flex-center mb-2.5 flex">
                <span className="text-header-6 flex w-32 items-center justify-end pr-3.75">Name</span>
                <span className="flex-1">
                  <Input disabled value={isMoreThanOne ? selectedUserNames : user?.email || selectedUserNames} />
                </span>
              </div>
              <div className="flex-center flex">
                <span className="text-header-6 flex h-[35px] w-32 items-center justify-end pr-3.75">
                  {isFromBulkAction ? (
                    <>
                      <span className="mr-2.5 leading-none">Access level</span>
                      <Tooltip
                        arrowSkiddingPercent={5}
                        placement="bottom"
                        className="p-0"
                        tooltipContent={TooltipRoleContent(values.role)}
                      >
                        <InfoIcon
                          className="h-4 w-4 fill-current text-cadet-blue-500"
                          aria-label="Info on access level"
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <span className="leading-none">Email</span>
                  )}
                </span>
                <span className="flex-1">
                  {isFromBulkAction ? (
                    <>
                      <Select
                        name="role"
                        options={[
                          {
                            value: ACCOUNT_USER_ROLES.OWNER,
                            label: 'Admin',
                          },
                          {
                            value: ACCOUNT_USER_ROLES.MANAGER,
                            label: 'Member',
                          },
                          {
                            value: ACCOUNT_USER_ROLES.READ_ONLY,
                            label: 'Read only',
                          },
                        ]}
                        placeholder="Select access level"
                        value={values.role}
                        onChange={async (value) => {
                          if (value === ACCOUNT_USER_ROLES.OWNER) {
                            await setFieldValue('sites', siteList);
                            setFieldTouched('sites', true);
                            await setFieldValue('specificSites', false);
                          } else {
                            await setFieldValue('specificSites', true);
                          }
                          await setFieldValue('role', value);
                        }}
                      />
                      {errors.role && touched.role && <div className="text-error mt-1">{errors.role}</div>}
                    </>
                  ) : (
                    <Input disabled value={user?.email || selectedUserNames} />
                  )}
                </span>
              </div>
              {values.role !== ACCOUNT_USER_ROLES.OWNER ? (
                <>
                  <Divider className="my-6" dashed />

                  <div className="flex max-h-52 flex-row overflow-auto">
                    <span className="flex flex-1 flex-col overflow-x-hidden pl-32">
                      <span className="mb-4 flex items-center pl-1 ">
                        <h6 className="text-header-6 mr-2.5 leading-none">Allow access to the following sites</h6>
                        <Tooltip
                          arrowSkiddingPercent={5}
                          placement="bottom"
                          tooltipContent={<TooltipSiteAccessContent />}
                        >
                          <InfoIcon aria-label="delete user" className="h-4 w-4 fill-current text-cadet-blue-500" />
                        </Tooltip>
                      </span>
                      <div role="group" aria-labelledby="checkbox-group">
                        <Checkbox
                          id="allSites"
                          label="All current and future sites"
                          onChange={() => {
                            if (
                              (siteList.length !== values.sites.length || siteList.length === values.sites.length) &&
                              values.specificSites
                            ) {
                              setFieldValue('sites', siteList);
                              setFieldTouched('sites', true);
                              setFieldValue('specificSites', false);
                            } else {
                              setFieldValue('sites', []);
                              setFieldValue('specificSites', true);
                            }
                          }}
                          checked={siteList.length === values.sites.length && !values.specificSites}
                        />
                        <div className="ml-6" role="group" aria-labelledby="checkbox-group">
                          {siteList.map((site, index) => {
                            return (
                              <Checkbox
                                name="sites"
                                key={`Site-${site}`}
                                id={`site.[${index}]`}
                                value={site}
                                label={site}
                                checked={values.sites.includes(site)}
                                onChange={handleChange}
                                labelClass="truncate"
                              />
                            );
                          })}
                        </div>
                      </div>
                      {errors.sites && touched.sites && <div className="text-error mt-1">{errors.sites}</div>}
                    </span>
                  </div>
                </>
              ) : null}

              <Divider className="!-mx-7 mt-5 !w-auto" />

              <Modal.Actions className="justify-between">
                <Modal.Actions className="!mt-0">
                  <Button
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    leftIcon={isSubmitting ? <Spinner className="mr-1 h-3 w-3 text-dodger-blue-500" /> : null}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                  <Modal.Cancel disabled={isSubmitting} />
                </Modal.Actions>
                {!isMoreThanOne && (
                  <Button
                    leftIcon={DeleteIcon}
                    variant="ghost-warning"
                    onClick={() => modal.show(<DeleteUserModal onDelete={onDelete} />)}
                  >
                    Delete member
                  </Button>
                )}
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
