import { useState, createContext, useReducer, useContext } from 'react';
import isEqual from 'react-fast-compare';

import { useModal, useSelection, useNotifications, useMutation } from '/src/hooks';
import { useFilter } from './snapshots-filter-context';

import { inflect } from '/src/utils/string';
import { DeleteSnapshotsModal } from '/src/features/snapshots/components/dashboard/modals/DeleteSnapshotsModal';
import { SnapshotsExportModal } from '/src/features/snapshots/components/dashboard/modals/SnapshotsExportModal';
import { NoDataModal } from '/src/features/snapshots/components/dashboard/modals/NoDataModal';

import {
  duplicateSnapshotsMutation,
  deleteSnapshotMutation,
  refreshSnapshotsMutation,
  pauseSnapshotsMutation,
  resumeSnapshotsMutation,
  moveSnapshotsMutation,
  unfileSnapshotsMutation,
} from '/src/features/snapshots/mutations';

import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';
import { SNAPSHOT_ACTIONS } from '/src/features/snapshots/constants.js';

const { DUPLICATED, DELETED, PAUSED, RESUMED, REFRESHED, EXPORTED, MOVED, UNFILED, SHARED, SHOW_ERROR, CLEAR } =
  SNAPSHOT_ACTIONS;

const SnapshotActionContext = createContext();

const initialActionState = null;

function snapshotActionReducer(state = initialActionState, action) {
  switch (action.type) {
    case DUPLICATED:
      return {
        ids: [...action.payload.ids],
        action: DUPLICATED,
      };
    case DELETED:
      return {
        ids: [...action.payload.ids],
        action: DELETED,
      };
    case PAUSED:
      return {
        ids: [...action.payload.ids],
        action: PAUSED,
      };
    case RESUMED:
      return {
        ids: [...action.payload.ids],
        action: RESUMED,
      };
    case REFRESHED:
      return {
        ids: [...action.payload.ids],
        action: REFRESHED,
      };
    case EXPORTED:
      return {
        ids: [...action.payload.ids],
        action: EXPORTED,
      };
    case MOVED:
      return {
        ids: [...action.payload.ids],
        action: MOVED,
      };
    case SHARED:
      return {
        ids: [...action.payload.ids],
        action: SHARED,
      };
    case SHOW_ERROR:
      return {
        ids: [...action.payload.ids],
        action: SHOW_ERROR,
      };
    case CLEAR:
      return initialActionState;
    default:
      return state;
  }
}

export function SnapshotActionProvider({ children }) {
  const [action, actionsDispatch] = useReducer(snapshotActionReducer, initialActionState);
  const [actionState, setAction] = useState({ action, actionsDispatch });

  if (!isEqual(action, actionState.action)) {
    setAction({ action, actionsDispatch });
  }

  return <SnapshotActionContext.Provider value={actionState}>{children}</SnapshotActionContext.Provider>;
}

export function useSnapshotActions() {
  const { clearSelection } = useSelection();
  const filters = useFilter();
  const modal = useModal();
  const notifications = useNotifications();
  const context = useContext(SnapshotActionContext);

  const duplicateSnapshots = useMutation(duplicateSnapshotsMutation);
  const deleteSnapshots = useMutation(deleteSnapshotMutation);
  const pauseSnapshots = useMutation(pauseSnapshotsMutation);
  const resumeSnapshots = useMutation(resumeSnapshotsMutation);
  const refreshSnapshots = useMutation(refreshSnapshotsMutation);
  const moveSnapshots = useMutation(moveSnapshotsMutation);
  const unfileSnapshots = useMutation(unfileSnapshotsMutation);

  if (!context) return;

  const { action, actionsDispatch } = context;

  function renderNotification(title, content, timeout = 8000, status = 'success') {
    notifications.add({
      title,
      content,
      timeout,
      type: status,
    });
  }

  function duplicate(id, options = {}) {
    const ids = [id].map((id) => parseInt(id));
    clearSelection();
    duplicateSnapshots.mutate(
      { ids },
      {
        onError: (error) => {
          notifications.error({
            title: 'Error',
            content: 'Failed to duplicate Snapshot.',
            context: { error },
          });
          options.onError?.();
        },
        onSuccess: (data) => {
          const newIds = data.duplicateSnapshots.affected;
          actionsDispatch({
            type: DUPLICATED,
            payload: {
              ids: newIds,
            },
          });
          options.onSuccess?.();
        },
        onSettled: () => {
          filters.reset();
          options.onSettled?.();
        },
      },
    );
  }

  function remove(id, options = {}) {
    const ids = [id].map((id) => parseInt(id));
    modal.show(
      <DeleteSnapshotsModal
        onConfirm={() => {
          clearSelection();
          deleteSnapshots.mutate(
            { ids },
            {
              onError: (error) => {
                notifications.error({
                  title: 'Error',
                  content: 'Failed to delete Snapshot.',
                  context: { error },
                });
                options.onError?.();
              },
              onSuccess: (data) => {
                const newIds = data.deleteSnapshots.affected;
                actionsDispatch({
                  type: DELETED,
                  payload: {
                    ids: newIds,
                  },
                });
                renderNotification(
                  `${newIds.length} ${inflect('Snapshot', newIds.length)} deleted`,
                  `Your ${newIds.length > 1 ? 'Snapshots have' : 'Snapshot has'} been deleted successfully.`,
                );
                options.onSuccess?.();
              },
              onSettled: () => {
                modal.close();
                options.onSettled?.();
              },
            },
          );
        }}
        visits={options.visits}
        numToDelete={ids.length}
      />,
    );
  }

  function pause(id, options = {}) {
    const ids = [id].map((id) => parseInt(id));
    clearSelection();
    pauseSnapshots.mutate(
      {
        ids,
      },
      {
        onSuccess: (data) => {
          const newIds = data.pauseSnapshots.affected;
          actionsDispatch({
            type: PAUSED,
            payload: {
              ids: newIds,
            },
          });
          options.onSuccess?.();
        },
        onSettled: () => {
          options.onSettled?.();
        },
      },
    );
  }

  function resume(id, options = {}) {
    const ids = [id].map((id) => parseInt(id));
    clearSelection();
    resumeSnapshots.mutate(
      {
        ids,
      },
      {
        onSuccess: (data) => {
          const newIds = data.resumeSnapshotsNew.affected;
          actionsDispatch({
            type: RESUMED,
            payload: {
              ids: newIds,
            },
          });
          if (newIds.length === 0) {
            renderNotification(
              'We could not resume your Snapshot',
              `Looks like there is an active Snapshot tracking this same url.`,
              8000,
              'error',
            );
          }
          options.onSuccess?.();
        },
        onSettled: () => {
          options.onSettled?.();
        },
      },
    );
  }

  function refresh(id, options = {}) {
    const ids = [id].map((id) => parseInt(id));
    clearSelection();
    refreshSnapshots.mutate(
      {
        ids,
      },
      {
        onSuccess: (data) => {
          const newIds = data.refreshSnapshots.affected;
          actionsDispatch({
            type: REFRESHED,
            payload: {
              ids: newIds,
            },
          });
          newIds.length > 1 &&
            renderNotification(
              'Your images are updating',
              `We're in the process of updating ${newIds.length} snapshot images.`,
            );
          options.onSuccess?.();
        },
        onSettled: () => {
          options.onSettled?.();
        },
      },
    );
  }

  function exportItem(snapshot, options = {}) {
    if (snapshot.hasData) {
      const ids = [parseInt(snapshot.id)];
      modal.show(
        <SnapshotsExportModal
          ids={ids}
          onSuccess={(data) => {
            if (!data?.exportSnapshots?.affected) return;
            actionsDispatch({
              type: EXPORTED,
              payload: {
                ids: data.exportSnapshots.affected,
              },
            });
            renderNotification('Success!', `Exporting ${ids.length} ${inflect('Snapshot', ids.length)}...`);
            options.onSuccess?.();
          }}
        />,
      );
    } else {
      modal.show(<NoDataModal />);
    }
  }

  function move(id, destinationFolderId, options = {}) {
    clearSelection();
    moveSnapshots.mutate(
      {
        ids: [id],
        toFolderId: destinationFolderId,
      },
      {
        onSuccess: (data) => {
          const newIds = data.moveSnapshots.affected;
          actionsDispatch({
            type: MOVED,
            payload: {
              ids: newIds,
            },
          });
          renderNotification(
            'Success!',
            `${newIds.length} ${newIds.length > 1 ? 'Snapshots have' : 'Snapshot has'} been moved.`,
          );
          options.onSuccess?.();
        },
        onSettled: () => {
          options.onSettled?.();
        },
      },
    );
  }

  function unfile(id, options = {}) {
    clearSelection();
    unfileSnapshots.mutate(
      {
        ids: [id],
      },
      {
        onSuccess: (data) => {
          const newIds = data.unfileSnapshots.affected;
          actionsDispatch({
            type: UNFILED,
            payload: {
              ids: newIds,
            },
          });
          renderNotification(
            'Success!',
            `${newIds.length} ${newIds.length > 1 ? 'Snapshots have' : 'Snapshot has'} been unfiled.`,
          );
          options.onSuccess?.();
        },
        onSettled: () => {
          options.onSettled?.();
        },
      },
    );
  }

  function share(id) {
    window.SharingModal.show({ entity: { id: parseInt(id), type: SHARABLE_RESOURCE_TYPES.SNAPSHOT } });
  }

  function clear() {
    actionsDispatch({
      type: CLEAR,
    });
  }

  function showError(id) {
    actionsDispatch({
      type: SHOW_ERROR,
      payload: {
        ids: [id],
      },
    });
  }

  return {
    lastAction: { ...action },
    duplicate,
    remove,
    pause,
    resume,
    refresh,
    exportItem,
    move,
    unfile,
    share,
    clear,
    // using showError here to avoid adding another one of these
    showError,
  };
}
