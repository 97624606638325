import { useState, useEffect } from 'react';

import { msToHumanDuration } from '/src/utils/date';

import { ReactComponent as ClockIcon } from '@crazyegginc/hatch/dist/images/icon-clock-partial-outline.svg';

export function SessionTimeCounter({ start }) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    start && setTimeout(() => setTime(time + 1), 1000);
  }, [start, time]);

  const formattedTime = msToHumanDuration(time * 1000);

  if (!start) return null;

  return (
    <div className="flex items-center">
      <ClockIcon className="mb-px mr-2.5 h-4 w-4 fill-current text-dodger-blue-500" />
      <span className="text-body-5">Your Live session has been running for {formattedTime}.</span>
    </div>
  );
}
