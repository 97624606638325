import { memo, useCallback, useRef, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useResizeObserver from 'use-resize-observer';
import { Tooltip, SkeletonLine } from '@crazyegginc/hatch';
import { useSelector } from '@xstate5/react';

import { useMutation, useAuthContext, useNotifications, usePermissions, useModal } from '/src/hooks';
import { DeleteModal } from './modals/DeleteModal';
import { FilterPill } from '../recordings-filter/FilterPill';
import { Playlist } from './Playlist';

import { deleteRecordingsMutation } from '/src/features/recordings/mutations';
import { getPlayerPlaylistQueryParams } from '/src/utils/url';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';

import { ReactComponent as ArrowExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';
import { ReactComponent as CeLogoIcon } from '@crazyegginc/hatch/dist/images/icon-logo-ce-balloon.svg';

export function PlayerHeader({
  actorRef,
  filters,
  queryParams,
  navigateToAnotherRecording,
  group,
  isError,
  loaded = false,
}) {
  const currentHash = useSelector(actorRef, (state) => state.context.recordingHash);
  const recording = useSelector(actorRef, (state) => state.context.recording);
  const playlist = useSelector(actorRef, (state) => state.context.playlist);
  const isDevToolsOpen = useSelector(actorRef, (state) => state.context.showDevtools);
  const devToolsEventsCount = useSelector(actorRef, (state) => state.context.devToolsEventsCount);
  const nextRecording = useSelector(actorRef, (state) => state.context.nextRecording);

  const { isSharing } = useAuthContext();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const modal = useModal();
  const notifications = useNotifications();

  const hasPlaylist = playlist.length > 1;
  const [playlistShown, setPlaylistShown] = useState(false);

  const deleteRecordings = useMutation(deleteRecordingsMutation);

  const deleteRecording = useCallback(() => {
    deleteRecordings.mutate(
      { recordingIds: [recording.id], site: recording.siteId },
      {
        onError: (error) =>
          notifications.error({ title: 'Error', content: 'Failed to delete Recording!', context: { error } }),
        onSuccess: () => {
          if (nextRecording) {
            return window.location.replace(
              `/recordings/${nextRecording.hashedId}/player?${getPlayerPlaylistQueryParams()}`,
            );
          } else {
            return navigate(
              {
                pathname: '/recordings',
                search: `?${getPlayerPlaylistQueryParams()}`,
              },
              { replace: true },
            );
          }
        },
      },
    );
  }, [recording?.id, recording?.siteId, deleteRecordings, navigate, nextRecording, notifications]);

  const canDelete = permissions.can('delete', recording).allowed;
  const canShare = permissions.can('share', recording).allowed;

  function playListDefaultText() {
    const identifier = recording?.identifier ?? recording?.visitorId ?? null;
    const recordingText = recording?.identifier ? identifier : `Visitor #${identifier}`;
    if (!identifier) return 'Loading...';

    if (group) {
      return `${queryParams.groupBy === GROUP_BY_TYPES.FIRST ? 'First page' : 'Last page'}: ${group}`;
    }
    return recordingText;
  }

  const handlePlaylistToggle = useCallback(
    (e) => {
      e.stopPropagation();
      if (!hasPlaylist) return;

      setPlaylistShown((shown) => !shown);
    },
    [setPlaylistShown, hasPlaylist],
  );

  return (
    <header className="flex w-full items-center pb-2.5 pl-5 pr-5 pt-2.5 gradient-black">
      {!isSharing ? (
        <button
          className="invisible hidden h-8 w-4 md:visible md:flex"
          onClick={() => {
            if (!isSharing) {
              // clear the player state and navigate to dashboard
              navigateToAnotherRecording(null);
            }
          }}
          data-testid="dashboardLinkButton"
        >
          <CeLogoIcon className="h-8 w-8 fill-current text-white" aria-label="crazy egg logo" />
          <span className="sr-only">back to dashboard</span>
        </button>
      ) : (
        <CeLogoIcon className="h-8 w-8 fill-current text-white" aria-label="crazy egg logo" />
      )}

      {isError ? null : (
        <>
          <div className="invisible ml-5 mr-3.5 hidden md:visible md:block">
            <ArrowExpandIcon className="h-3 w-3 rotate-90 fill-current text-cadet-blue-500" />
          </div>

          <div className="relative m-0 flex items-center text-sm font-bold text-white">
            <span className="font-normal text-cadet-blue-500">Playing:</span>
            {loaded ? (
              <>
                <button
                  className={classNames('ml-2.5 flex items-center outline-none focus:outline-none', {
                    'cursor-pointer': hasPlaylist,
                    'cursor-default': !hasPlaylist,
                  })}
                  onMouseDown={(e) => {
                    window.Mocky?.turnOff();
                    handlePlaylistToggle(e);
                  }}
                >
                  {filters.conditions?.length && recording?.siteId ? (
                    <PlaylistText filters={filters} recording={recording} />
                  ) : (
                    playListDefaultText()
                  )}
                  {hasPlaylist ? <PlayIcon className="ml-2 h-2 w-2 rotate-90 fill-current text-white" /> : null}
                </button>
                {!isSharing ? (
                  <Playlist
                    actorRef={actorRef}
                    currentHash={currentHash}
                    queryParams={queryParams}
                    shown={playlistShown}
                    toggle={handlePlaylistToggle}
                    navigateToAnotherRecording={navigateToAnotherRecording}
                    loaded={loaded}
                  />
                ) : null}
              </>
            ) : (
              <SkeletonLine className="ml-2 w-[150px] bg-mako-500" />
            )}
          </div>
        </>
      )}

      <div className="flex h-10 flex-1 justify-end">
        {isError ? null : (
          <div className="flex" id="actionButtons">
            <button
              className={classNames(
                'group invisible relative z-0 mr-1.5 hidden shrink-0 items-center justify-center rounded-full border-2 bg-mako-500 px-2.5 text-xs font-semibold text-white shadow-lg focus:outline-none md:visible md:flex',
                { 'border-dodger-blue-500': isDevToolsOpen },
                { 'border-mako-500 hover:border-dodger-blue-500': !isDevToolsOpen },
              )}
              onClick={() => {
                actorRef.send({ type: 'TOGGLE_DEVTOOLS' });
              }}
            >
              <CodeIcon aria-label="dev tools" className="mr-1.25 h-5 w-5 fill-current text-white" />
              Dev tools
              {devToolsEventsCount > 0 ? (
                <span className="absolute -right-1.5 -top-1.25 h-4 rounded-full bg-radical-red-500 py-0.5 pl-1.25 pr-1.5 leading-none">
                  {devToolsEventsCount}
                </span>
              ) : null}
            </button>
            {canShare ? (
              <Tooltip placement="bottom" tooltipContent="Share" containerStyle={{ zIndex: 1000001 }}>
                <button
                  onClick={() => {
                    actorRef.send({ type: 'SHARE' });
                  }}
                  className="group relative mr-1.5 flex h-10 w-10 items-center justify-center rounded-full border-2 border-mako-500 bg-mako-500 shadow-lg hover:border-dodger-blue-500 focus:rounded-full focus:border-dodger-blue-500 focus:outline-none focus:ring"
                  data-testid="share_button"
                >
                  <ShareIcon aria-label="share" className="h-4 w-4 fill-current text-white" />
                </button>
              </Tooltip>
            ) : null}
            {canDelete ? (
              <Tooltip placement="bottom" tooltipContent="Delete" containerStyle={{ zIndex: 1000001 }}>
                <button
                  className="group relative flex h-10 w-10 items-center justify-center rounded-full border-2 border-mako-500 bg-mako-500 shadow-lg hover:border-dodger-blue-500 focus:rounded-full focus:border-dodger-blue-500 focus:outline-none focus:ring"
                  data-testid="trash_button"
                  onClick={() => {
                    actorRef.send({ type: 'DELETE' });
                    modal.show(<DeleteModal onConfirm={() => deleteRecording()} />);
                  }}
                >
                  <TrashIcon aria-label="delete" className="h-4 w-4 fill-current text-white" />
                </button>
              </Tooltip>
            ) : null}
          </div>
        )}
      </div>
    </header>
  );
}

const PlaylistText = memo(function PlaylistText({ filters, recording }) {
  const ref = useRef();
  useResizeObserver({ ref });

  /* Pretty awful hack here to avoid remounting FilterPill when it's wrapped by Tooltip and the show prop changes. 
     A more proper solution would be to fix Tooltip to not cause a remount. 
  */
  return (
    <div className="relative flex min-w-0 max-w-[350px]">
      <span className="max-w-full truncate whitespace-pre" ref={ref}>
        {filters.conditions.map((condition) => (
          <Fragment key={condition.criteria}>
            <FilterPill condition={condition} siteId={recording?.siteId} textOnly={true} />
            {'; '}
          </Fragment>
        ))}
      </span>
      <div className="absolute left-0 top-0 h-5 w-[350px] opacity-0">
        <Tooltip
          placement="bottom"
          tooltipContent={
            <div className="flex flex-col items-start p-2">
              {ref.current?.innerText
                ?.split('; ')
                .map((textString, index) => <span key={`PlaylistCondition-${index}`}>{textString}</span>) ?? null}
            </div>
          }
          show={ref.current?.clientWidth < ref.current?.scrollWidth}
        >
          &nbsp;
        </Tooltip>
      </div>
    </div>
  );
});
