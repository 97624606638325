import { useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Tooltip, DeviceIcon, ReactTable, LoaderBalloon } from '@crazyegginc/hatch';

import { inflect } from '/src/utils/string';
import { useLockBodyScroll, useSelectedSite, useVisitorId } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { visitorsRecordingsDetailsQuery } from '../queries';

import { formatFullDateTime, formatShortDateTime } from '/src/utils/date';
import { getVisitorDisplayName } from '/src/utils/visitor';

import { Gravatar } from '/src/components/gravatar';
import { PlayerLink } from '../../recordings/components/player/PlayerLink';
import { InfoPage } from './InfoPage';

import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as PagesIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as DurationIcon } from '@crazyegginc/hatch/dist/images/icon-clock-outline.svg';

export function VisitorProfile({ currentRecordingHash = null, navigateToAnotherRecording = null }) {
  useLockBodyScroll();
  const { selectedVisitor } = useVisitorId();
  const tableRef = useRef(null);
  const parentRef = useRef(null);

  const { selectedSite } = useSelectedSite();
  const identifier = selectedVisitor.identifier || selectedVisitor.visitorId;

  const { data: visitorData, isFetching: loadingVisitorData } = useQuery({
    ...visitorsRecordingsDetailsQuery({
      siteId: selectedSite?.id && selectedSite.id !== 0 ? selectedSite.id : null,
      terms: [identifier],
    }),
  });

  const columns = useMemo(
    () => [
      {
        header: 'Play',
        accessorKey: 'visitorId',
        meta: {
          align: 'center',
          padding: '0',
        },
        size: 40,
        cell: function RecordingsVisitorCell({ row }) {
          return (
            <PlayerLink
              isWatching={row.original.hashedId === currentRecordingHash}
              recording={row.original}
              redirect={true}
              navigateToAnotherRecording={navigateToAnotherRecording}
            />
          );
        },
      },
      {
        header: 'Date',
        accessorKey: 'recordedAt',
        size: 67,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function RecordingsDateCell({ row }) {
          return (
            <Tooltip tooltipContent={formatFullDateTime(row.original.recordedAt)} container={`#ce_drawer`}>
              {formatShortDateTime(row.original.recordedAt)}
            </Tooltip>
          );
        },
      },
      {
        header: function RecordingsDurationHeaderCell() {
          return <IconHeader title="Duration" Icon={DurationIcon} />;
        },
        accessorKey: 'duration',
        size: 49,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDurationCell({ row }) {
          return Math.floor(row.original.duration / 60) + ':' + String(row.original.duration % 60).padStart(2, '0');
        },
      },
      {
        header: function RecordingsPageHeaderCell() {
          return <IconHeader title="Pages" Icon={PagesIcon} />;
        },
        accessorKey: 'visitedPagesCount',
        size: 33,
        meta: {
          align: 'center',
        },
      },
      {
        header: function RecordingsDeviceHeaderCell() {
          return <IconHeader title="Device" Icon={DesktopIcon} />;
        },
        accessorKey: 'device',
        size: 33,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDeviceCell({ row }) {
          return <DeviceIcon device={row.original.device.toUpperCase()} tooltipProps={{ container: '#ce_drawer' }} />;
        },
      },
    ],
    [navigateToAnotherRecording, currentRecordingHash],
  );

  const isLoading = loadingVisitorData;

  const recordingsCount = (visitorData?.visitorsRecordingsDetails?.recordings ?? []).length;

  return (
    <div className="h-full overflow-y-auto">
      <header className="absolute left-0 top-0 z-[999999] h-[110px] w-full bg-solitude-500 px-5 py-4">
        <div className="mt-[50px]">
          <h4 className="text-header-3 m-0 max-w-[315px] truncate font-bold">
            {getVisitorDisplayName(selectedVisitor.identifier, selectedVisitor.visitorId)}
          </h4>
        </div>
        <div className="absolute bottom-[-20px] right-5 z-10 h-20 w-20 rounded-full bg-white p-3 shadow-md">
          <Gravatar
            email={identifier}
            custom={recordingsCount === 0 && 'https://ce-extension-assets.s3.amazonaws.com/face-inactive-lrg.png'}
            size={56}
          />
        </div>
      </header>
      <div className="absolute top-[110px] flex h-[calc(100%-110px)] w-full flex-col overflow-y-auto" ref={parentRef}>
        {!(selectedVisitor?.identifier ?? false) && (
          <div className="bg-alice-blue-500 px-5 py-2 text-xs leading-4 text-lynch-500">
            Want to find specific visitors? Install our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={SupportLinks.sessions.visitorIdentifier}
              className="text-link text-xs"
            >
              Visitor ID script
            </a>
            !
          </div>
        )}
        {!isLoading ? (
          (visitorData?.visitorsRecordingsDetails?.recordings ?? []).length > 0 ? (
            <div className="flex-1 px-7 py-[30px]">
              <p className="text-body-1 mb-3.5">
                {recordingsCount} {inflect('recording', recordingsCount)} for this visitor:
              </p>
              <ReactTable
                fetching={loadingVisitorData}
                ref={{ tableRef, parentRef }}
                rowPadding={false}
                rowHeight={55}
                rowCustomBackground={(row) =>
                  classNames({
                    'bg-white-lilac-500 hover:bg-white': row && row.original.viewedAt,
                    '!bg-dodger-blue-500': row && row.original.hashedId === currentRecordingHash,
                  })
                }
                rowCustomClassname={(row) =>
                  classNames({
                    '!text-lynch-500': row && row.original.permissions.canUpgradeToView,
                    '!text-white': row && row.original.hashedId === currentRecordingHash,
                  })
                }
                headerHeight={40}
                columns={columns}
                data={visitorData.visitorsRecordingsDetails.recordings}
                useVirtualization={true}
                enableSorting={false}
              />
            </div>
          ) : (
            <InfoPage
              isActiveSession={true}
              style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', flex: 1 }}
            />
          )
        ) : (
          <div className="mt-7">
            <LoaderBalloon />
          </div>
        )}
      </div>
    </div>
  );
}

function IconHeader({ title, Icon }) {
  return (
    <Tooltip tooltipContent={title} container={`#ce_drawer`}>
      <Icon aria-label={title} className="text-cadet-blue-900 h-3.5 w-3.5 flex-shrink-0 fill-current" />
    </Tooltip>
  );
}
