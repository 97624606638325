import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';

import PopupPNG from '@crazyegginc/hatch/dist/images/illustration-popup.png';
import PopupWebP from '@crazyegginc/hatch/dist/images/illustration-popup.webp';

export function EditorPopupsModal({ service }) {
  const { send } = service;
  const modal = useModal();

  return (
    <Modal
      dialogClassName="!items-center !text-center !p-9 !pb-6 !w-[640px] !h-[375px]"
      disableOutsideClick={true}
      dismissable={true}
    >
      <WebP webp={PopupWebP} fallback={PopupPNG} height="105" width="120" alt="popup" className="!mb-4" />
      <Modal.Title className="!mb-0 !font-bold">We&apos;ve detected popups blocking some of your content</Modal.Title>
      <div className="text-body-2 mb-4 mt-6 leading-normal">
        You can close pop-ups in our interactive mode, just like you do in a regular browser. <br /> If you&apos;d
        prefer not to see any pop-ups, you can also hide them all.
      </div>
      <Modal.Actions>
        <Button
          onClick={() => {
            send({ type: 'TOGGLE_INTERACTIVE' });
            modal.close();
          }}
        >
          Take me to interactive mode
        </Button>
        <Button
          onClick={() => {
            send({ type: 'CHANGE_POPUPS_STATE' });
            modal.close();
          }}
          variant="secondary"
        >
          Hide all my pop-ups
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
