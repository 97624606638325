import { MainTextRow, SubTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function AddToCartEvent({ liveEvent, identifyEvents }) {
  const { quantity, product_name, price, currency } = liveEvent.attributes;

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <span>
          added{' '}
          {quantity && product_name ? (
            <>
              <span className="text-body-1">{quantity}</span> x <span className="text-body-1">{product_name}</span>
            </>
          ) : (
            'something'
          )}{' '}
          to cart
        </span>
      </MainTextRow>
      {price && (
        <SubTextRow>
          <>
            `${price} ${currency}`
          </>
        </SubTextRow>
      )}
    </div>
  );
}
