import { useActor } from '@xstate/react';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@crazyegginc/hatch';

import { TriggerForm } from './TriggerForm';

import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

export function TriggerCard({ service, triggersCount, trigger, index }) {
  const [state] = useActor(service);
  const { send } = service;

  const valid = !state.context.errors?.triggers?.[index];

  return (
    <>
      {index > 0 ? (
        <div className="my-10 flex items-center">
          <hr className="flex-1 border-t border-dashed border-mystic-500" />
          <span className="text-header-2 mx-3.75 text-lynch-500">OR</span>
          <hr className="flex-1 border-t border-dashed border-mystic-500" />
        </div>
      ) : null}
      <div key={`trigger.${index}`} className="rounded border border-mystic-500">
        <div
          className={classNames('group flex items-center space-x-4 py-5 pl-[25px] pr-7 hover:bg-off-white-500', {
            'border-b border-dashed border-b-mystic-500': trigger.expanded,
          })}
        >
          <div className="flex w-full justify-between">
            <div className="flex items-center space-x-3">
              <h3>{trigger.name && trigger.name.length > 0 ? trigger.name : `Goal trigger ${index + 1}`}</h3>
              {!valid && !trigger.expanded ? (
                <Tooltip
                  tooltipContent={
                    <div className="w-40 text-left">This trigger does not have all the required fields</div>
                  }
                >
                  <WarningIcon
                    className="mb-[2px] h-[16px] w-[16px] fill-current text-radical-red-500"
                    aria-label="error"
                  />
                </Tooltip>
              ) : null}
            </div>
            <div className="flex items-center">
              {triggersCount > 1 ? (
                <Tooltip tooltipContent="Delete trigger">
                  <IconButton
                    onClick={() => {
                      send({ type: 'DELETE_TRIGGER', index });
                    }}
                    className="hidden text-cadet-blue-500 hover:text-carnation-500 group-hover:block"
                    icon={<TrashIcon className="h-3.5 w-3.5 fill-current" />}
                  />
                </Tooltip>
              ) : null}
              <button className="ml-5 text-dodger-blue-500" onClick={() => send({ type: 'TOGGLE_TRIGGER', index })}>
                <ExpandIcon
                  aria-label={trigger.expanded ? 'collapse' : 'expand'}
                  className={classNames('h-4 w-4 fill-current', { 'rotate-180': !trigger.expanded })}
                />
              </button>
            </div>
          </div>
        </div>

        {trigger.expanded ? <TriggerForm service={service} trigger={trigger} triggerIndex={index} /> : null}
      </div>
    </>
  );
}
