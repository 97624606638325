import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { Gravatar } from '/src/components/gravatar';
import { Paywall } from '/src/components/Paywall';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';

import { useAuthContext, usePermissions, useMetric, useLockBodyScroll, useVisitorId } from '/src/hooks';
import { getVisitorDisplayName } from '/src/utils/visitor';

import RecordingsPNG from '@crazyegginc/hatch/dist/images/illustration-recordings-1.png';
import RecordingsWebP from '@crazyegginc/hatch/dist/images/illustration-recordings-1.webp';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function VisitorPanelUpgradePaywall() {
  useLockBodyScroll();

  const { selectedVisitor } = useVisitorId();
  const identifier = selectedVisitor.identifier || selectedVisitor.visitorId;

  const { currentAccount } = useAuthContext();
  const trackMetric = useMetric();

  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <div className="h-full overflow-y-auto">
      <header className="absolute left-0 top-0 z-[999999] h-[110px] w-full bg-solitude-500 px-5 py-4">
        <div className="mt-[50px]">
          <h4 className="text-header-3 m-0 max-w-[315px] truncate font-bold">
            {getVisitorDisplayName(selectedVisitor.identifier, selectedVisitor.visitorId)}
          </h4>
        </div>
        <div className="absolute bottom-[-20px] right-5 z-10 h-20 w-20 rounded-full bg-white p-3 shadow-md">
          <Gravatar email={identifier} size={56} />
        </div>
      </header>

      <div className="absolute top-[110px] flex h-[calc(100%-110px)] w-full flex-col overflow-y-auto py-20">
        <Paywall>
          <Paywall.Image webp={RecordingsWebP} png={RecordingsPNG} width="285px" height="150px" />
          <Paywall.Title>See clearly with Recordings</Paywall.Title>

          <div className="text-body-2 mb-10 mt-4 max-w-[380px] text-center">
            Watch recordings of visitors as they fill out Survey responses, interact with the CTAs, and navigate through
            your site.
            <div className="mt-6 flex justify-center text-left leading-tight">
              <div className="space-y-3">
                <div className="flex items-center">
                  <TickIcon className="mr-2.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
                  See all the recordings linked to a specific visitor.
                </div>
                <div className="flex items-center">
                  <TickIcon className="mr-2.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
                  <span>
                    Associate the visitors with a custom{' '}
                    <a
                      className="text-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://support.crazyegg.com/hc/en-us/articles/1500001716641-Visitor-Identifier"
                    >
                      Visitor Identifier
                    </a>
                    .
                  </span>
                </div>
              </div>
            </div>
            {!canManageBilling ? (
              <p className="mt-5">
                To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to
                upgrade the plan.
              </p>
            ) : null}
          </div>

          {canManageBilling ? (
            <Button
              component={Link}
              to={MANAGE_SUBSCRIPTION_PATH}
              onMouseDown={() => trackMetric('upgrade_cta_click', 'visitor_profile_upsell_paywall')}
            >
              Get Recordings
              <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
            </Button>
          ) : (
            <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
              Contact account owner
            </Button>
          )}
        </Paywall>
      </div>
    </div>
  );
}
