import { MainTextRow } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function LoginEvent({ liveEvent, identifyEvents }) {
  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        logged in!
      </MainTextRow>
    </div>
  );
}
