import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useInfiniteQuery } from '@tanstack/react-query';
import classNames from 'classnames';
// TODO: remove the `default` check when Vite improves experimental support for deps optimization
import useScrollbarSizeImport from 'react-scrollbar-size';
const useScrollbarSize = useScrollbarSizeImport.default ? useScrollbarSizeImport.default : useScrollbarSizeImport;
import { Tooltip, Checkbox, SurveyAllResponsesTable, capitalize, DeviceIcon, BrowserIcon } from '@crazyegginc/hatch';

import {
  useAuthContext,
  useSelectedSite,
  usePermissions,
  useSelection,
  useNavBarDimensions,
  useScrollBarVisible,
  useOutsideClickNotify,
  useVisitorId,
} from '/src/hooks';
import { NoMatchesWall } from '/src/features/_global/paywalls/NoMatchesWall';
import { VisitorIdProvider } from '/src/features/visitor-panel/visitor-id-context';
import { VisitorPanel } from '/src/features/visitor-panel/components/VisitorPanel';
import { useSurveyResponseFilter } from '/src/features/addons/survey-response-filter-context';
import { BulkAction } from '/src/components/bulk-action/BulkAction';
import { surveyResponseListQuery } from '/src/features/addons/queries';
import { SelectionProvider } from '/src/contexts/selection';
import { formatShortDateTime, formatFullDateTime } from '/src/utils/date';
import { snakeToCamel, camelToSnake } from '/src/utils/string';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { ActionsRow, ColumnVisibility } from './ActionsRow';
import { EmojiFaces } from '../EmojiFaces';
import { sentimentMeta } from '../summary/TextEntry';
import { processUnsafeText } from '../../../common/common-functions';
import {
  SURVEY_QUESTION_TYPES,
  TEXT_ENTRY_SUBTYPES,
  RATING_SUBTYPES,
  ALL_RESPONSES_TABLE_ORDER,
} from '/src/features/addons/constants';
import { BULK_ACTIONABLE_ITEMS, SORT_ORDER_TYPES, ROOT_ELEMENT_ID } from '/src/features/_global/constants';

import { TableActionsCell } from './TableActionsCell';
import { ColumnMenu } from './ColumnMenu';
import { PlayButton } from '/src/components/PlayButton';
import { isProduction } from '/src/utils';
import { CountryFlag } from '/src/components/CountryFlag';
import { TableTagList } from '/src/components/TableTagList';
import { displaySiteUrl, removeProtocol } from '/src/utils/url';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as BrowserHeaderIcon } from '@crazyegginc/hatch/dist/images/icon-browser.svg';
import { ReactComponent as LocationIcon } from '@crazyegginc/hatch/dist/images/icon-pin-filled.svg';

export function AllResponsesTab({ survey, convertedDateRange, resetDateRange }) {
  return (
    <SelectionProvider>
      <VisitorIdProvider>
        <VisitorPanel />
        <AllResponsesTabContent
          survey={survey}
          convertedDateRange={convertedDateRange}
          resetDateRange={resetDateRange}
        />
      </VisitorIdProvider>
    </SelectionProvider>
  );
}

function AllResponsesTabContent({ survey, convertedDateRange, resetDateRange }) {
  const { selectedSite } = useSelectedSite();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const isProd = isProduction();
  const { setSort, setOrderField, order, setHiddenColumns, initialColumnVisibility } = useSurveyResponseFilter();
  const { selectVisitor } = useVisitorId();
  const tableRef = useRef(null);
  const { navBarWidth } = useNavBarDimensions();
  const { verticalScrollBarVisible } = useScrollBarVisible();
  const { width: scrollBarWidth } = useScrollbarSize();
  const usedWidth = (navBarWidth ?? 0) + 80 + (verticalScrollBarVisible ? scrollBarWidth : 0);
  const { isSharing } = useAuthContext();

  const permissions = usePermissions();
  const selectable = permissions.can('anyBulkAction', survey).allowed;
  const canDelete = permissions.can('delete', survey).allowed;

  const { toggleSelection, setSelection, clearSelection, currentSelection } = useSelection();

  const questions = useMemo(() => {
    let parsedContent = {};
    try {
      parsedContent = JSON.parse(survey.content);
    } catch {
      //noop
    }
    return parsedContent.questions.filter((question) => question.type !== SURVEY_QUESTION_TYPES.STATIC_DISPLAY);
  }, [survey]);

  const {
    data,
    error,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isInitialLoading,
    isRefetching,
    refetch: refetchResponses,
  } = useInfiniteQuery({
    ...surveyResponseListQuery({
      surveyId: survey.id,
      order: order,
      startDate: convertedDateRange.startDate,
      endDate: convertedDateRange.endDate,
    }),
  });

  const responses = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.surveyResponseList?.responses ?? [])], []) ?? [],
    [data],
  );

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('survey_results_all_responses');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && (data || error) && !isFetching) {
      try {
        window.CE2.timing.stop('survey_results_all_responses');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, error, isProd, isFetching]);

  const columns = useMemo(() => {
    const cols = [
      {
        header: 'VISITOR',
        enableSorting: false,
        size: 240,
        meta: {
          sticky: true,
          rightBorder: true,
        },
        cell: ({ row }) => {
          return (
            <div className="relative min-w-0">
              <div className="text-body-1 truncate">
                {getVisitorDisplayName(row.original.identifier, row.original.visitorId)}
              </div>
              {!isSharing && (
                <button
                  type="button"
                  className={classNames(
                    'absolute bottom-[-18px] left-0 flex items-center text-xs text-dodger-blue-500 hover:underline focus:outline-none',
                    'invisible group-hover:visible',
                  )}
                  data-testid="visitor_id_link"
                  onClick={() => selectVisitor(row.original)}
                >
                  View profile <ExpandIcon className="ml-1 h-2 w-2 rotate-90 fill-current" />
                </button>
              )}
            </div>
          );
        },
      },
      {
        header: 'DATE',
        accessorKey: 'createdAt',
        size: 85,
        cell: ({ row }) => {
          const date = formatShortDateTime(row.original.createdAt).split(',');
          return (
            <Tooltip tooltipContent={formatFullDateTime(row.original.createdAt)}>
              <div className="flex flex-col items-center">
                <div>{date[0].trim()}</div>
                <div className="text-body-4">{date[1].trim()}</div>
              </div>
            </Tooltip>
          );
        },
      },
    ];

    questions.forEach((q) =>
      cols.push({
        header: processUnsafeText(q.title),
        accessorKey: q.id,
        size: q.type === SURVEY_QUESTION_TYPES.TEXT_ENTRY ? 400 : 320,
        enableHiding: true,
        meta: {
          showMenu: true,
        },
        cell: ({ row }) => {
          return (
            <ResponseContent
              response={row.original.questions.find((x) => x.questionId === q.id)}
              question={q}
              tableRef={tableRef}
            />
          );
        },
      }),
    );

    cols.push(
      ...[
        {
          header: () => null,
          id: 'EmptyFiller',
          enableSorting: false,
          meta: {
            filler: true,
          },
          size: 0,
          cell: () => null,
        },
        {
          header: () => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TagIcon
                  className="text-cadet-blue-900 mr-1 h-3.5 w-3.5 flex-shrink-0 fill-current"
                  aria-label="tags"
                />
                Tags
              </div>
            );
          },
          accessorKey: 'tags',
          size: 170,
          enableSorting: false,
          cell: ({ row }) => {
            return row.original.recording ? (
              <TableTagList tags={row.original.recording.tags} className="!max-h-[25px]" />
            ) : null;
          },
        },
        {
          header: 'URL',
          accessorKey: 'surveyUrl',
          size: 170,
          cell: ({ row }) => {
            const { surveyUrl } = row.original;
            return surveyUrl ? (
              <Tooltip tooltipContent={<div className="max-w-md break-all">{removeProtocol(surveyUrl)}</div>}>
                <div className="truncate">{displaySiteUrl(surveyUrl, selectedSite?.name)}</div>
              </Tooltip>
            ) : null;
          },
        },
        {
          header: () => {
            return <IconHeader title="Device" Icon={DesktopIcon} />;
          },
          accessorKey: 'device',
          size: 30,
          enableSorting: false,
          meta: {
            headerStyle: { padding: 0, justifyContent: 'center' },
            cellStyle: { padding: 0, alignItems: 'center' },
          },
          cell: ({ row }) => {
            return row.original.recording?.device ? (
              <DeviceIcon device={row.original.recording.device.toUpperCase()} />
            ) : null;
          },
        },
        {
          header: () => {
            return <IconHeader title="Browser" Icon={BrowserHeaderIcon} />;
          },
          accessorKey: 'browser',
          size: 30,
          enableSorting: false,
          meta: {
            headerStyle: { padding: 0, justifyContent: 'center' },
            cellStyle: { padding: 0, alignItems: 'center' },
          },
          cell: ({ row }) => {
            return row.original.recording?.browser ? <BrowserIcon browser={row.original.recording.browser} /> : null;
          },
        },
        {
          header: () => {
            return <IconHeader title="Location" Icon={LocationIcon} />;
          },
          accessorKey: 'country',
          size: 30,
          enableSorting: false,
          meta: {
            headerStyle: { padding: 0, justifyContent: 'center' },
            cellStyle: { padding: 0, alignItems: 'center' },
          },
          cell: ({ row }) => {
            return row.original.recording?.country ? <CountryFlag country={row.original.recording.country} /> : null;
          },
        },
      ],
    );

    if (!isSharing) {
      cols.push({
        header: 'PLAY',
        accessorKey: 'hashedId',
        enableSorting: false,
        size: 65,
        meta: {
          headerStyle: { justifyContent: 'center' },
          cellStyle: { alignItems: 'center' },
        },
        cell: ({ row }) => {
          return (
            <PlayButton
              hashedId={row.original.recording?.hashedId ?? null}
              valid={row.original.recording?.valid}
              virtual={row.original.recording?.virtual}
              deletedAt={row.original.recordingDeletedAt}
              canUpgradeToView={row.original.permissions.canUpgradeToView}
              watched={row.original.recording?.viewedAt}
            />
          );
        },
      });
    }

    if (canDelete) {
      cols.push({
        id: 'Actions',
        size: 71,
        enableSorting: false,
        meta: {
          cellStyle: { paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: '15px' },
        },
        cell: ({ row }) => {
          return <TableActionsCell response={row.original} survey={survey} />;
        },
      });
    }

    if (selectable) {
      cols.unshift({
        id: 'selection',
        size: 56,
        enableSorting: false,
        meta: {
          sticky: true,
        },
        header: () => (
          <div className="pl-3.75">
            <Checkbox
              checked={currentSelection.length && currentSelection.length === responses.length ? true : false}
              onChange={() => {
                currentSelection.length === responses.length ? clearSelection() : setSelection([...responses]);
              }}
              indeterminate={currentSelection.length > 0 && currentSelection.length < responses.length}
              title="Toggle all rows selected"
              size="lg"
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="pl-3.75">
            <Checkbox
              checked={currentSelection.findIndex((x) => x.id === row.original.id) > -1}
              onChange={() => toggleSelection(row.original)}
              size="lg"
            />
          </div>
        ),
      });
    }

    return cols;
  }, [
    selectable,
    currentSelection,
    clearSelection,
    setSelection,
    toggleSelection,
    responses,
    survey,
    selectVisitor,
    questions,
    selectedSite,
    isSharing,
    canDelete,
  ]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        if ([ALL_RESPONSES_TABLE_ORDER.CREATED_AT, ALL_RESPONSES_TABLE_ORDER.SURVEY_URL].includes(nextField)) {
          setOrderField({ field: nextField, questionId: null });
        } else {
          setOrderField({ field: ALL_RESPONSES_TABLE_ORDER.QUESTION_ID, questionId: sorting[0].id });
        }
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrderField, setSort],
  );

  const getTableSortData = () => {
    if ([ALL_RESPONSES_TABLE_ORDER.CREATED_AT, ALL_RESPONSES_TABLE_ORDER.SURVEY_URL].includes(order.field)) {
      return {
        id: snakeToCamel(order.field),
        desc: order.sort === SORT_ORDER_TYPES.DESC,
      };
    } else {
      return {
        id: order.questionId,
        desc: order.sort === SORT_ORDER_TYPES.DESC,
      };
    }
  };

  if (!(isInitialLoading || isRefetching) && survey.responseCount > 0 && responses.length === 0) {
    return <NoMatchesWall reset={resetDateRange} text="matches for this date range" actionText="date range" />;
  }

  return (
    <div className="flex h-full flex-col">
      <ActionsRow survey={survey} refetch={refetchResponses} />
      <div
        style={{
          maxWidth: `calc(100vw - ${usedWidth}px)`,
          height: `calc(100vh - 120px)`,
        }}
      >
        <SurveyAllResponsesTable
          fetching={isInitialLoading || isRefetching}
          ref={{ tableRef }}
          columns={columns}
          data={responses}
          onFetchData={onFetchData}
          enableSorting={true}
          sorting={getTableSortData()}
          hasNextPage={hasNextPage}
          isNextPageLoading={isFetching}
          loadNextPage={fetchNextPage}
          ColumnMenu={ColumnMenu}
          VisibilityMenu={ColumnVisibility}
          initialColumnVisibility={initialColumnVisibility}
          onVisibilityChange={setHiddenColumns}
        />
      </div>
      <BulkAction feature={BULK_ACTIONABLE_ITEMS.SURVEY_RESPONSES} survey={survey} />
    </div>
  );
}

function IconHeader({ title, Icon }) {
  return (
    <Tooltip tooltipContent={title}>
      <Icon aria-label={title} className="text-cadet-blue-900 h-3.5 w-3.5 flex-shrink-0 fill-current" />
    </Tooltip>
  );
}

function ResponseContent({ response, question, tableRef }) {
  if (!response) return '';
  switch (question.type) {
    case SURVEY_QUESTION_TYPES.RATING:
      switch (question.subtype) {
        case RATING_SUBTYPES.NPS:
          return <NPS response={response} />;
        case RATING_SUBTYPES.STARS:
          return <Stars response={response} />;
        case RATING_SUBTYPES.SMILEY:
          return <SmileyFaces response={response} />;
        case RATING_SUBTYPES.NUMERICAL:
          return <Numerical response={response} question={question} />;
        default:
          return null;
      }
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      return <MultiChoice response={response} tableRef={tableRef} />;
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY:
      return <TextEntry response={response} tableRef={tableRef} question={question} />;
    default:
      return null;
  }
}

function TextEntry({ response, tableRef, question }) {
  if (question.subtype === TEXT_ENTRY_SUBTYPES.EMAIL) {
    if (response.textResponse) {
      return (
        <a className="text-body-2 text-dodger-blue-500 hover:underline" href={`mailto:${response.textResponse}`}>
          {response.textResponse}
        </a>
      );
    }
    return null;
  }

  return (
    <ExpandingCell
      expandingText={processUnsafeText(response.textResponse)}
      additionalText={
        <div className="flex items-center space-x-2.5">
          <div
            className="h-[7px] w-[7px] rounded-full"
            style={{ background: sentimentMeta[response.sentiment].color }}
          />
          <span className="text-body-4">{capitalize(response.sentiment)}</span>
        </div>
      }
      tableRef={tableRef}
    />
  );
}

function MultiChoice({ response, tableRef }) {
  const text = processUnsafeText(response.textResponse?.split('\n').join(', '));
  return <ExpandingCell expandingText={text} tableRef={tableRef} clampSize={2} />;
}

function ExpandingCell({ expandingText, additionalText = null, tableRef, clampSize = 1 }) {
  const [open, setOpen] = useState(false);
  const expandedTextRef = useRef();
  const clampedTextRef = useRef();
  useOutsideClickNotify(expandedTextRef, close, open);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  function close() {
    setOpen(false);
  }

  useEffect(() => {
    const r = tableRef.current;
    r.addEventListener('scroll', close);
    return () => r.removeEventListener('scroll', close);
  }, [tableRef]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      useMemo(
        () => ({
          name: 'widthModifier',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width + 30}px`;
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${state.elements.reference.offsetWidth + 30}px`;
          },
        }),
        [],
      ),
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ reference }) => {
              return [0, -reference.height - 15];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <>
      <button
        type="button"
        disabled={clampedTextRef.current?.scrollHeight <= clampedTextRef.current?.clientHeight}
        className="text-body-2 select-text text-left leading-tight"
        onClick={() => setOpen(true)}
        ref={setReferenceElement}
      >
        <p
          className={classNames({ 'line-clamp-2': clampSize === 2, 'line-clamp-1': clampSize === 1 })}
          ref={clampedTextRef}
          dangerouslySetInnerHTML={{ __html: expandingText }}
        />
      </button>
      {additionalText}
      {open &&
        createPortal(
          <div
            className="absolute left-0 top-0 w-full border-2 border-dodger-blue-500 bg-white shadow"
            ref={setPopperElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            <p
              ref={expandedTextRef}
              className={classNames(
                'text-body-2 max-h-60 overflow-y-auto p-3.75 leading-tight',
                'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
              )}
              dangerouslySetInnerHTML={{ __html: expandingText }}
            />
          </div>,
          document.querySelector(`#${ROOT_ELEMENT_ID}`),
        )}
    </>
  );
}

function Numerical({ response, question }) {
  return (
    <div className="text-body-2">
      <span className="text-body-1">Rating:</span> {response.integerResponse}/{question.max.value}
    </div>
  );
}

function SmileyFaces({ response }) {
  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Rating:</span> {response.integerResponse}/5
      </div>
      <div className="mt-1 flex items-center whitespace-pre">{EmojiFaces(response.integerResponse)}</div>
    </>
  );
}

function Stars({ response }) {
  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Rating:</span> {response.integerResponse}/5
      </div>
      <div className="flex items-center whitespace-pre">{`⭐️ `.repeat(response.integerResponse)}</div>
    </>
  );
}

function NPS({ response }) {
  let result;
  if (response.integerResponse >= 9) {
    result = ['Promoter', 'bg-malibu-500'];
  } else if (response.integerResponse < 7) {
    result = ['Detractor', 'bg-pink-salmon-500'];
  } else {
    result = ['Passive', 'bg-dandelion-500'];
  }

  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Score:</span> {response.integerResponse}/10
      </div>
      <div className="flex items-center space-x-2.5">
        <div className={classNames('h-[7px] w-[7px] rounded-full', result[1])} />
        <span className="text-body-4">{result[0]}</span>
      </div>
    </>
  );
}
