import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Input } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { superLenientUrlRegex } from '/src/utils/regex';

const validationSchema = yup.object().shape({
  url: yup.string().required('Please enter a URL.').matches(superLenientUrlRegex, 'Your URL appears to be invalid.'),
});

export function ProvideEditorPageModal({ onConfirm = null, onDismiss }) {
  return (
    <Modal dialogClassName="!w-[640px] !p-0 !bg-[#222326]" dark onDismiss={onDismiss}>
      <div className="flex h-14 items-center bg-black px-7">
        <h2 className="text-header-4 text-white">Provide editor page</h2>
      </div>

      <div className="text-body-2 p-7 text-cadet-blue-500">
        <div className="leading-normal">
          As you’re testing multiple pages, you’ll need to select a page to make your edits to in our WYSIWYG editor.
          Any changes you apply to this page will be carried across all of the URLs you have selected for your test.
        </div>

        <Divider dashed className="-mx-7 my-5 !w-auto !border-t-cadet-blue-500/70" />
        <Formik
          validateOnMount={true}
          initialValues={{ url: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onConfirm(values.url);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form className="w-full">
              <Input
                theme="dark"
                size="xl"
                id="url-input"
                name="url"
                placeholder="e.g. https://www.yoursite.com/page"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url && errors.url ? errors.url : null}
              />

              <Modal.Actions>
                <Button theme="dark" disabled={isSubmitting} type="submit">
                  Edit this page
                </Button>
              </Modal.Actions>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
