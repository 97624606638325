import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

export function ErrorsPaywallText({ children }) {
  return children;
}

function ErrorsPaywallTitle() {
  return 'Are Javascript Errors frustrating your visitors?';
}

function ErrorsPaywallBody() {
  return (
    <>
      <p className="leading-tight">
        Our automatic error detection tells you which visitors are getting errors, and which errors are most important
        to fix.
      </p>
    </>
  );
}

function ErrorsPaywallList() {
  return (
    <div className="mt-2 flex flex-col justify-center pl-6 pr-4">
      <ul className="space-y-2">
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>Watch Recordings of your errors to reproduce how and why they occur.</p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>
            Get metrics on the number of pages and visitors affected, impact on bounce rates and rage clicks,
            timestamps, and more.
          </p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>Easily share error reports and Recordings with your teams.</p>
        </li>
      </ul>
    </div>
  );
}

ErrorsPaywallText.Title = ErrorsPaywallTitle;
ErrorsPaywallText.Body = ErrorsPaywallBody;
ErrorsPaywallText.List = ErrorsPaywallList;
