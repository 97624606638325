import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Slider } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { ADDONS_CAPABILITIES } from '/src/features/addons/constants';

export function Sampling() {
  const { readonly, addonsCapabilities } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const hasCapability = addonsCapabilities[ADDONS_CAPABILITIES.SAMPLING];

  useEffect(() => {
    if (hasCapability && locationState?.goToSampling) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      setTimeout(() => navigate('.', { replace: true }), 1000);
    }
  }, [hasCapability, navigate, locationState]);

  if (!hasCapability) {
    return null;
  }

  return (
    <div className="w-full max-w-2xl" ref={ref}>
      <div className="text-body-1 mb-2" id="sampling">
        What percentage of these visitors should see this survey?
      </div>
      <p className="text-body-2">
        If you want to limit the number of responses you expect to receive, adjust the percentage of the visitors who
        will see your survey. Eg. 50% means the survey will be shown to half of the visitors who also meet the above
        conditions.
      </p>
      <div className="mt-3.75 flex w-full items-start">
        <span className="text-body-1 flex w-16 shrink-0 grow-0 justify-center">{`${values.sampling}%`}</span>
        <div className="mr-8 grow">
          <Slider
            disabled={readonly}
            min={1}
            max={100}
            value={values.sampling}
            onChange={(newValue) => setFieldValue('sampling', newValue)}
            tooltipLabel={(v) => `${v}%`}
            aria-labelledby="sampling"
          />
          <div className="flex w-full items-center justify-between">
            <span className="text-body-4">0%</span>
            <span className="text-body-4 pl-1.5">50%</span>
            <span className="text-body-4">100%</span>
          </div>
        </div>
      </div>
    </div>
  );
}
