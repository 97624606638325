import { inflect } from '/src/utils/string';

export function DailyVisitCounterEvent({ liveEvent }) {
  const { count } = liveEvent.attributes;

  return (
    <div>
      {count} additional Basic {inflect('Visitor', count)} visited
    </div>
  );
}
