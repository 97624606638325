import { Link, useLocation } from 'react-router-dom';

export function LinkWithParams({ to, disabled = false, className = null, excluded = [], children }) {
  const location = useLocation();

  const search = new URLSearchParams(location.search);
  for (const key of excluded) {
    search.delete(key);
  }

  if (disabled) {
    return <span className={className}>{children}</span>;
  }
  return (
    <Link
      className={className}
      to={{
        pathname: to,
        search: search.toString(),
      }}
    >
      {children}
    </Link>
  );
}
