import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { CrazyAIContext, CrazyAIQueryContext } from '/src/contexts/CrazyAI';

const queryKeys = createQueryKeys('crazyAI', {
  crazyai: ({ fnName, type, params, extraQueryKeyParams }) => [{ fnName, type, params, extraQueryKeyParams }],
});

export function useCrazyAI({ fnName = 'ask', type, params, context, extraQueryKeyParams }) {
  const queryClient = useQueryClient({ context: CrazyAIQueryContext });
  const queryKey = useMemo(
    () => queryKeys.crazyai({ fnName, type, params, extraQueryKeyParams }).queryKey,
    [fnName, type, params, extraQueryKeyParams],
  );
  const { ai } = useContext(CrazyAIContext);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  const refetch = useCallback(() => {
    queryClient.removeQueries({ queryKey });
    setRetry((x) => x + 1);
  }, [queryKey, queryClient]);

  useEffect(() => {
    let canceled = false;

    async function run() {
      if (ai && !queryClient.getQueryData(queryKey)) {
        setError(null);
        try {
          await ai[fnName](type, params, context, {
            onChunk: (p) => {
              if (!canceled) {
                const oldData = queryClient.getQueryData(queryKey) ?? { text: '' };
                const newData = { ...oldData, text: oldData.text + p.message };
                queryClient.setQueryData(queryKey, newData);
              }
            },
            onError: (e) => {
              if (!canceled) {
                setError(e);
              }
            },
            onResponse: (p) => {
              if (!canceled) {
                const oldData = queryClient.getQueryData(queryKey);
                queryClient.setQueryData(queryKey, { ...oldData, queryId: p.query_id, ready: true });
              }
            },
          });
        } catch (e) {
          setError(e);
        }
      }
    }
    run();

    return () => {
      canceled = true;
      if (!queryClient.getQueryData(queryKey)?.ready) {
        queryClient.removeQueries({ queryKey });
      }
    };
  }, [ai, queryKey, fnName, type, params, context, retry, queryClient]);

  const result = useQuery({ queryKey, context: CrazyAIQueryContext });

  const id = result.data?.queryId;

  const positiveFeedback = useCallback(
    () =>
      new Promise((resolve) => {
        if (!id || !ai) resolve({ error: 'No connection or no query.' });
        ai.feedback(id, 'explicit_positive_feedback', {
          onResponse: () => {
            const oldData = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, { ...oldData, feedback: 'positive' });
            resolve({ data: 'ok' });
          },
          onError: (error) => resolve({ error }),
        });
      }),
    [id, ai, queryKey, queryClient],
  );

  const negativeFeedback = useCallback(
    () =>
      new Promise((resolve) => {
        if (!id || !ai) resolve({ error: 'No connection or no query.' });
        ai.feedback(id, 'explicit_negative_feedback', {
          onResponse: () => {
            const oldData = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, { ...oldData, feedback: 'negative' });
            resolve({ data: 'ok' });
          },
          onError: (error) => resolve({ error }),
        });
      }),
    [id, ai, queryKey, queryClient],
  );

  if (error) return { error, refetch };

  return { ...result, refetch, positiveFeedback, negativeFeedback };
}
