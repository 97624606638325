import { gql } from '@urql/core';

export const LIVE_EVENTS_SUBSCRIPTION = gql`
  subscription LiveEvents($siteId: Int!) {
    liveEvents(siteId: $siteId) {
      attributes
      clientTimestamp
      event
      eventId
      siteId
      snapshotId
      timestamp
      userId
      version
      visitorId
    }
  }
`;
