import { Link } from 'react-router-dom';
import { SkeletonLine } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';
import { TemplateMenu } from './TemplateMenu';
import { getSessionQueryParameters } from '/src/utils/url';
import { FEATURES } from '/src/features/_global/constants';

const fixedColors = {
  Survey: '#0086E6',
  CTA: '#1798AA',
};

function getBackgroundColor(stringInput) {
  if (fixedColors[stringInput]) return fixedColors[stringInput];
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export function TemplateCard({ template }) {
  const permissions = usePermissions();
  const canManageTemplates = permissions.can('manageTemplates', FEATURES.ADDONS).allowed;

  return (
    <div className="max-h-[400px] min-w-[320px] max-w-[460px] overflow-hidden rounded border border-mystic-500 shadow-md hover:border-dodger-blue-500">
      <div className="flex h-full flex-col justify-between">
        <Link
          type="button"
          className="relative flex h-full flex-col justify-between text-left"
          to={{ pathname: `/addons/templates/${template.id}/utilize`, search: getSessionQueryParameters() }}
        >
          {canManageTemplates && (
            <div className="absolute right-1 top-1">
              <TemplateMenu template={template} />
            </div>
          )}
          <div
            className="flex h-48 shrink-0 grow-0 items-center justify-center"
            style={{
              backgroundColor: '#F9FAFD',
              backgroundImage: 'radial-gradient(rgb(239, 242, 247) 2px, rgb(249, 250, 253) 2px)',
              backgroundSize: '20px 20px',
            }}
          >
            {template.thumbnail && (
              <img
                src={template.thumbnail}
                alt="template thumbnail"
                className="max-h-[80%] max-w-[80%] object-contain drop-shadow"
              />
            )}
          </div>
          <div className="flex grow flex-col justify-between border-t border-mystic-500 bg-white-lilac-500 px-5 py-4">
            <div>
              <div className="text-header-3 line-clamp-2 font-bold">{template.name}</div>
              <div className="mt-1.25 line-clamp-3 text-base leading-normal text-black-pearl-500">
                {template.description}
              </div>
            </div>
            <div className="mt-2.5 flex flex-wrap items-start space-x-1.25">
              {template.category?.split(',').map((cat) => (
                <div
                  key={cat}
                  className="text-tag flex h-5 min-w-0 flex-grow-0 items-center rounded-full px-1.5 text-white"
                  style={{ backgroundColor: getBackgroundColor(cat.trim()) }}
                >
                  {cat.trim()}
                </div>
              ))}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export function TemplateSkeleton() {
  return (
    <div className="h-[292px] min-w-[300px] max-w-[460px] shrink-0 overflow-hidden rounded border border-mystic-500 pt-5 shadow-md">
      <div className="flex h-full flex-col justify-between">
        <div>
          <div className="flex grow-0 items-center space-x-3.5 px-5">
            <SkeletonLine className="!h-[50px] !w-[50px] rounded-full" />
            <SkeletonLine className="!w-2/3" />
          </div>
          <div className="my-6 space-y-2.5 px-5 text-base leading-normal text-black-pearl-500">
            <SkeletonLine className="!w-full" />
            <SkeletonLine className="!w-full" />
            <SkeletonLine className="!w-full" />
            <SkeletonLine className="!w-2/3" />
          </div>
        </div>
        <div className="flex items-center justify-between border-t border-mystic-500 bg-white-lilac-500 p-5">
          <SkeletonLine className="!w-1/3" />
          <SkeletonLine className="!w-1/3" />
        </div>
      </div>
    </div>
  );
}
