import { Panel } from '@crazyegginc/hatch';

import { OptionsHeader } from '../components/OptionsHeader';
import { CheckScript } from '/src/components/check-script/CheckScript';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsCheckInstallationScreen() {
  return (
    <>
      <OptionsHeader>Check Crazy Egg Installation</OptionsHeader>
      <Panel className="mb-[50px]">
        <CheckScript />
      </Panel>
    </>
  );
}
