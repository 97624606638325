import { useEffect, useContext, useReducer, createContext, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'react-fast-compare';
import { produce } from 'immer';

import { recordingsCriteriaDefinitionQuery, playlistsListQuery } from '/src/features/recordings/queries';
import { useSelectedSite, usePrevious, useQueryParams, usePermissions, useAuthContext } from '/src/hooks';
import { getParam, getInitialValue } from '/src/utils/location';
import {
  getFieldsWithInitialValues,
  combineFilterWithDateRange,
} from './components/recordings-filter/filter-functions';
import { FEATURES, SPECIAL_DATE_RANGES, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import {
  RECORDINGS_PER_PAGE,
  GROUP_BY_TYPES,
  PLAYLIST_TYPES,
  RECORDINGS_TABLE_ORDER,
  RECORDINGS_FILTER_ACTIONS,
} from './constants';

const {
  ADD_CRITERIA,
  REMOVE_CRITERIA,
  CHANGE_VALUE,
  CHANGE_COMPARISON,
  CHAIN_CONDITION,
  REMOVE_CONDITION,
  EDIT_FILTERS,
  APPLY_FILTERS,
  CANCEL_EDIT,
  SET_ERRORS,
  DISMISS_DUPLICATE_TOOLTIP,
  APPLY_CLICKED_TAG,
  SELECT_PLAYLIST,
  INITIAL_LOAD_COMPLETE,
  REFRESH_DEFINITION,
  SET_ORDER_FIELD,
  SET_ORDER_SORT,
  SET_FILTERS_VERSION,
  SET_DEFINITIONS,
  SET_GROUP_BY,
  SELECT_GROUP,
  SET_PAGE,
  SET_DATE_RANGE,
  RESET,
} = RECORDINGS_FILTER_ACTIONS;

export const FilterContext = createContext();
const versionOverride = Number(window.localStorage.getItem('shell:filtersVersion') ?? undefined);

export const DEFAULT_DATE_RANGE = SPECIAL_DATE_RANGES.LAST_30_DAYS;
const DEFAULT_PAGE = 1;
const DEFAULT_SORT = SORT_ORDER_TYPES.DESC;
const DEFAULT_ORDER = RECORDINGS_TABLE_ORDER.RECORDED_AT;

const filtersInitialState = () => ({
  pane: {
    show: false,
    edit: false,
  },
  conditions: [],
  appliedConditions: null,
});

const playlistsInitialState = () => ({
  selected: null,
  active: null,
  dropped: null,
  data: null,
});

export function FilterProvider({ children }) {
  const permissions = usePermissions();
  const { currentUser } = useAuthContext();
  const canViewGroups = permissions.can('viewGroups', FEATURES.RECORDINGS).allowed;

  const DEFAULT_GROUPBY = canViewGroups ? GROUP_BY_TYPES.FIRST : GROUP_BY_TYPES.LIST_VIEW;

  const initialState = useCallback(
    () => ({
      queryParams: {
        order: {
          field: getInitialValue('order', [...Object.values(RECORDINGS_TABLE_ORDER)]) || DEFAULT_ORDER,
          sort: getInitialValue('sort', [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || DEFAULT_SORT,
        },
        filters: null,
        groupBy: getInitialValue('groupBy', [...Object.values(GROUP_BY_TYPES)]) || DEFAULT_GROUPBY,
        group: getParam('group'),
        groupURL: getParam('group'),
        page: parseInt(getParam('page'), 10) || DEFAULT_PAGE,
      },
      filters: filtersInitialState(),
      playlists: playlistsInitialState(),
      filtersVersion: Number.isInteger(versionOverride) ? versionOverride : null,
      recordingsCriteriaDefinitionQuery: {},
      initialLoadComplete: false,
    }),
    [DEFAULT_GROUPBY],
  );

  function reducer(state, action) {
    switch (action.type) {
      case ADD_CRITERIA:
        return produce(state, (draft) => {
          const conditionIndex = state.filters.conditions.findIndex(
            (condition) => condition.criteria === action.payload,
          );
          if (conditionIndex !== -1) {
            draft.filters.conditions[conditionIndex].showDuplicateTooltip = true;
          } else {
            const criteria = action.payload;
            const definition = state.recordingsCriteriaDefinitionQuery.data.definitions.find(
              (x) => x.criteria === criteria,
            );
            const comparison = definition.allowed_comparisons?.[0] ?? null;
            const fields = getFieldsWithInitialValues(definition, currentUser.settings);
            draft.filters.conditions.push({ criteria, definition, comparison, values: [fields] });
          }
          draft.filters.pane.show = true;
          draft.filters.pane.edit = true;
        });

      case SET_DATE_RANGE:
        return produce(state, (draft) => {
          const criteria = 'date_range';
          let conditionIndex = state.filters.conditions.findIndex((condition) => condition.criteria === criteria);
          if (conditionIndex === -1) {
            const definition = state.recordingsCriteriaDefinitionQuery.data.definitions.find(
              (x) => x.criteria === criteria,
            );
            const comparison = definition.allowed_comparisons?.[0] ?? null;
            const fields = getFieldsWithInitialValues(definition, currentUser.settings);
            draft.filters.conditions.push({ criteria, definition, comparison, values: [fields] });
            conditionIndex = draft.filters.conditions.length - 1;
          }
          draft.filters.conditions[conditionIndex].values[0][0].value = action.payload;
          // reset error on field value change
          draft.filters.conditions[conditionIndex].values[0][0].error = '';

          draft.filters.appliedConditions = state.filters.appliedConditions
            ? [
                ...state.filters.appliedConditions.filter((condition) => condition.criteria !== criteria),
                { ...draft.filters.conditions[conditionIndex] },
              ]
            : [{ ...draft.filters.conditions[conditionIndex] }];

          const dateRangeApplied = state.filters.appliedConditions?.find((c) => c.criteria === criteria)?.values[0][0]
            .value.special;

          draft.queryParams.filters = combineFilterWithDateRange(
            state.queryParams.filters,
            action.payload,
            state.filtersVersion,
          );
          draft.queryParams.groupURL = draft.queryParams.group;
          if (dateRangeApplied && dateRangeApplied !== DEFAULT_DATE_RANGE) {
            draft.playlists = playlistsInitialState();
          }
        });

      case APPLY_CLICKED_TAG:
        return produce(state, (draft) => {
          draft.filters.conditions = state.filters.conditions.filter(
            (condition) => condition.criteria === 'date_range',
          );
          const criteria = 'tags';
          const definition = state.recordingsCriteriaDefinitionQuery.data.definitions.find(
            (x) => x.criteria === criteria,
          );
          const comparison = definition.allowed_comparisons?.[0] ?? null;
          let fields = getFieldsWithInitialValues(definition, currentUser.settings);
          fields[0].value = [action.payload];
          draft.filters.conditions.push({ criteria, definition, comparison, values: [fields] });
          draft.filters.pane.show = true;
          draft.filters.pane.edit = true;
          if (state.playlists.active) {
            draft.playlists.active = null;
            draft.playlists.dropped = state.playlists.active;
          }
        });

      case REMOVE_CRITERIA:
        return produce(state, (draft) => {
          draft.filters.conditions = state.filters.conditions.filter(
            (condition) => condition.criteria !== action.payload,
          );
          draft.filters.pane.show = draft.filters.conditions.some((condition) => condition.criteria !== 'date_range');
        });

      case CHANGE_VALUE:
        return produce(state, (draft) => {
          const conditionIndex = state.filters.conditions.findIndex(
            (condition) => condition.criteria === action.payload.target.criteria,
          );
          const groupIndex = action.payload.target.fieldGroup;
          const valueIndex = action.payload.target.fieldIndex;
          draft.filters.conditions[conditionIndex].values[groupIndex][valueIndex].value = action.payload.value;

          // reset error on field value change
          draft.filters.conditions[conditionIndex].values[groupIndex][valueIndex].error = '';
        });

      case SET_ERRORS:
        return produce(state, (draft) => {
          action.payload.map((error) => {
            const conditionIndex = state.filters.conditions.findIndex(
              (condition) => condition.criteria === error.target.criteria,
            );
            const groupIndex = error.target.fieldGroup;
            const valueIndex = error.target.fieldIndex;
            draft.filters.conditions[conditionIndex].values[groupIndex][valueIndex].error = error.error;
          });
        });

      case CHANGE_COMPARISON:
        return produce(state, (draft) => {
          const conditionIndex = state.filters.conditions.findIndex(
            (condition) => condition.criteria === action.payload.criteria,
          );
          draft.filters.conditions[conditionIndex].comparison = action.payload.value;
        });

      case CHAIN_CONDITION:
        return produce(state, (draft) => {
          const conditionIndex = state.filters.conditions.findIndex(
            (condition) => condition.criteria === action.payload.criteria,
          );
          const definition = state.recordingsCriteriaDefinitionQuery.data.definitions.find(
            (x) => x.criteria === action.payload.criteria,
          );
          const fields = getFieldsWithInitialValues(definition, currentUser.settings);
          draft.filters.conditions[conditionIndex].values.push(fields);
          if (action.payload.multiple_values) {
            draft.filters.conditions[conditionIndex].multiple_values = action.payload.multiple_values;
          }
        });

      case REMOVE_CONDITION:
        return produce(state, (draft) => {
          const { index, criteria } = action.payload;
          const conditionIndex = state.filters.conditions.findIndex((condition) => condition.criteria === criteria);
          draft.filters.conditions[conditionIndex].values.splice(index, 1);

          const valueCount = state.filters.conditions[conditionIndex].values.length;
          if (valueCount === 2 && state.filters.conditions[conditionIndex].multiple_values) {
            delete draft.filters.conditions[conditionIndex].multiple_values;
          }
        });

      case EDIT_FILTERS:
        return produce(state, (draft) => {
          draft.filters.pane.edit = true;
          if (action.payload) {
            draft.playlists.active = null;
            draft.playlists.dropped = state.playlists.active;
          }
        });

      case APPLY_FILTERS:
        return produce(state, (draft) => {
          if (action.payload.conditions) {
            draft.filters.appliedConditions = action.payload.conditions;
            draft.filters.conditions = action.payload.conditions;
          } else {
            draft.filters.appliedConditions = [...draft.filters.conditions];
          }
          draft.filters.pane.edit = false;

          if (state.playlists.selected) {
            draft.playlists.active = state.playlists.selected;
            draft.playlists.selected = null;
            draft.playlists.data = action.payload.playlist;
          }
          draft.playlists.dropped = null;

          // only show pane if there are conditions other than date_range
          if (draft.filters.appliedConditions.some((condition) => condition.criteria !== 'date_range')) {
            draft.filters.pane.show = true;
          } else {
            draft.filters.pane.show = false;
            draft.playlists.active = null;
          }

          draft.queryParams.filters = action.payload.filters;
          draft.queryParams.groupURL = draft.queryParams.group;
        });

      case CANCEL_EDIT:
        return produce(state, (draft) => {
          draft.filters.conditions = [...draft.filters.appliedConditions];
          // only show pane if there are conditions other than date_range
          if (draft.filters.conditions.some((condition) => condition.criteria !== 'date_range')) {
            draft.filters.pane.show = true;
          } else {
            draft.filters.pane.show = false;
          }
          draft.filters.pane.edit = false;
          if (state.playlists.dropped) {
            draft.playlists.active = state.playlists.dropped;
            draft.playlists.dropped = null;
          }
        });

      case DISMISS_DUPLICATE_TOOLTIP:
        return produce(state, (draft) => {
          const conditionIndex = state.filters.conditions.findIndex(
            (condition) => condition.criteria === action.payload,
          );
          delete draft.filters.conditions[conditionIndex].showDuplicateTooltip;
        });

      case SELECT_PLAYLIST:
        return produce(state, (draft) => {
          draft.playlists.selected = action.payload;
        });

      case INITIAL_LOAD_COMPLETE:
        return produce(state, (draft) => {
          draft.initialLoadComplete = true;
        });

      case REFRESH_DEFINITION:
        return produce(state, (draft) => {
          draft.filters.conditions = state.filters.conditions.map((condition) => ({
            ...condition,
            definition: action.payload.find((def) => def.criteria === condition.criteria),
          }));
        });

      case SET_ORDER_FIELD:
        return produce(state, (draft) => {
          draft.queryParams.order.field = action.payload;
        });

      case SET_ORDER_SORT:
        return produce(state, (draft) => {
          draft.queryParams.order.sort = action.payload;
        });

      case SET_GROUP_BY:
        return produce(state, (draft) => {
          if (action.payload !== state.queryParams.groupBy) {
            draft.queryParams.groupBy = action.payload;
          }
        });

      case SET_FILTERS_VERSION:
        return produce(state, (draft) => {
          draft.filtersVersion = action.payload;
        });

      case SET_DEFINITIONS:
        return produce(state, (draft) => {
          draft.recordingsCriteriaDefinitionQuery = action.payload;
        });

      case SELECT_GROUP:
        return produce(state, (draft) => {
          draft.queryParams.group = action.payload;
        });

      case SET_PAGE:
        return produce(state, (draft) => {
          draft.queryParams.page = action.payload;
        });

      case RESET:
        return produce(state, (draft) => {
          draft.filters = filtersInitialState();
          draft.playlists = playlistsInitialState();
          draft.queryParams = initialState().queryParams;
          draft.queryParams.order = state.queryParams.order;
        });
    }
  }
  const { setAll: queryParamsSetAll } = useQueryParams();
  const [state, dispatch] = useReducer(reducer, initialState());
  const { selectedSite } = useSelectedSite();

  const [contextValue, setContextValue] = useState({ state, dispatch });

  if (!isEqual(state, contextValue.state)) {
    setContextValue({ state, dispatch });
  }

  useEffect(() => {
    const page = state.queryParams.page === DEFAULT_PAGE ? null : state.queryParams.page;
    const sort = state.queryParams.order.sort === DEFAULT_SORT ? null : state.queryParams.order.sort;
    const order = state.queryParams.order.field === DEFAULT_ORDER ? null : state.queryParams.order.field;
    const group = state.queryParams.group;

    let filters;
    if (state.playlists.active) {
      if (state.playlists.data.type === PLAYLIST_TYPES.RECOMMENDED) {
        filters = JSON.stringify(state.playlists.data.name);
      } else {
        filters = state.playlists.data.id;
      }
    } else if (state.queryParams.filters) {
      filters = JSON.stringify(state.queryParams.filters);
    }

    if (filters) {
      queryParamsSetAll({
        filters,
        page,
        sort,
        order,
        group,
        site: selectedSite?.name,
      });
    }
  }, [state, queryParamsSetAll, DEFAULT_GROUPBY, selectedSite?.name]);

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
}

function useRecordingsCriteriaDefinitionQuery() {
  const { state, dispatch } = useContext(FilterContext);

  const {
    data: queryData,
    isFetching: fetching,
    error,
  } = useQuery({
    ...recordingsCriteriaDefinitionQuery({ version: state.filtersVersion }),
    enabled: Boolean(state.recordingsCriteriaDefinitionQuery?.data?.version !== state.filtersVersion),
    staleTime: 60 * 60 * 1000,
  });

  const data = queryData ? JSON.parse(queryData?.recordingsCriteriaDefinition ?? null) : null;

  useEffect(() => {
    if (data && state.recordingsCriteriaDefinitionQuery?.data?.version !== state.filtersVersion) {
      dispatch({ type: SET_DEFINITIONS, payload: { data, fetching } });
    }
  }, [data, dispatch, fetching, state.filtersVersion, state.recordingsCriteriaDefinitionQuery?.data?.version]);

  useEffect(() => {
    // reset filtersVersion in case the override value was incorrect
    if (error) {
      dispatch({ type: SET_FILTERS_VERSION, payload: null });
    }
    // set filtersVersion to the received version initially
    else if (data && !fetching && !state.filtersVersion) {
      dispatch({ type: SET_FILTERS_VERSION, payload: data.version });
    }
  }, [dispatch, error, data, fetching, state.filtersVersion]);
}

function usePlaylistsListQuery() {
  const { selectedSite } = useSelectedSite();
  const { state, dispatch } = useContext(FilterContext);

  const { data, isFetching: fetching } = useQuery({
    ...playlistsListQuery({ site: selectedSite?.id, version: state.filtersVersion }),
    enabled: Boolean(selectedSite?.id && state.filtersVersion),
  });

  // update filterVersion if a greater version found in the playlists
  useEffect(() => {
    if (!data) return;
    let max = 0;
    data.playlistsList.forEach((playlist) => {
      let filter;
      try {
        filter = JSON.parse(playlist.filter);
      } finally {
        if (filter) {
          max = Math.max(max, filter.version);
        }
      }
    });
    if (max > state.filtersVersion) {
      dispatch({ type: SET_FILTERS_VERSION, payload: max });
    }
  }, [data, state.filtersVersion, dispatch, fetching]);

  return { data, fetching };
}

export function useFilter() {
  const { removeAll: queryParamsRemoveAll, set: queryParamsSet } = useQueryParams();
  const { state, dispatch } = useContext(FilterContext);

  const playlistsListQuery = usePlaylistsListQuery();
  useRecordingsCriteriaDefinitionQuery();

  // refresh definitions in state if version changes
  const prevDefVersion = usePrevious(state.recordingsCriteriaDefinitionQuery.data?.version);
  useEffect(() => {
    if (
      state.recordingsCriteriaDefinitionQuery.data &&
      prevDefVersion !== state.recordingsCriteriaDefinitionQuery.data.version
    ) {
      dispatch({ type: REFRESH_DEFINITION, payload: state.recordingsCriteriaDefinitionQuery.data.definitions });
    }
  }, [dispatch, state.recordingsCriteriaDefinitionQuery.data, prevDefVersion]);

  const setPage = useCallback(
    (page) => {
      dispatch({ type: SET_PAGE, payload: page });
    },
    [dispatch],
  );

  const setSort = useCallback(
    (sort) => {
      setPage(1);
      dispatch({ type: SET_ORDER_SORT, payload: sort });
    },
    [dispatch, setPage],
  );

  const setOrder = useCallback(
    (order) => {
      setPage(1);
      dispatch({ type: SET_ORDER_FIELD, payload: order });
    },
    [dispatch, setPage],
  );

  function setGroupBy(groupBy) {
    setPage(1);
    queryParamsSet('groupBy', groupBy, true);
    selectGroup(null);
    if (
      [GROUP_BY_TYPES.LAST, GROUP_BY_TYPES.LIST_VIEW].includes(groupBy) &&
      state.queryParams.order.field === RECORDINGS_TABLE_ORDER.LAST_PAGE
    ) {
      setOrder(RECORDINGS_TABLE_ORDER.FIRST_PAGE);
    } else if (
      groupBy === GROUP_BY_TYPES.FIRST &&
      state.queryParams.order.field === RECORDINGS_TABLE_ORDER.FIRST_PAGE
    ) {
      setOrder(RECORDINGS_TABLE_ORDER.LAST_PAGE);
    }
    dispatch({ type: SET_GROUP_BY, payload: groupBy });
  }

  const setPageOnCountChange = useCallback(
    (newCount) => {
      const newMaxPage = Math.max(Math.ceil(newCount / RECORDINGS_PER_PAGE), 1);
      if (state.queryParams.page > newMaxPage) {
        setPage(newMaxPage);
      }
    },
    [setPage, state.queryParams.page],
  );

  function selectGroup(group) {
    setPage(1);
    dispatch({ type: SELECT_GROUP, payload: group });
  }

  const setDateRange = useCallback(
    (value) => {
      dispatch({ type: SET_DATE_RANGE, payload: value });
    },
    [dispatch],
  );

  const setFilters = useCallback(
    ({ filters, playlist, conditions, resetPage }) => {
      if (resetPage) {
        setPage(1);
      }
      dispatch({ type: APPLY_FILTERS, payload: { conditions, filters, playlist } });
    },
    [dispatch, setPage],
  );

  const clearFilters = useCallback(() => {
    queryParamsRemoveAll(['filters', 'group', 'page']);
    dispatch({ type: RESET });
  }, [queryParamsRemoveAll, dispatch]);

  const selectPlaylist = useCallback(
    (playlistId) => {
      dispatch({ type: SELECT_PLAYLIST, payload: playlistId });
    },
    [dispatch],
  );

  const setInitialLoadComplete = useCallback(() => {
    dispatch({ type: INITIAL_LOAD_COMPLETE });
  }, [dispatch]);

  const addCriteria = useCallback(
    (criteria) => {
      dispatch({ type: ADD_CRITERIA, payload: criteria });
    },
    [dispatch],
  );

  const applyClickedTag = useCallback(
    (tag) => {
      dispatch({ type: APPLY_CLICKED_TAG, payload: tag });
    },
    [dispatch],
  );

  const setErrors = useCallback(
    (errors) => {
      dispatch({ type: SET_ERRORS, payload: errors });
    },
    [dispatch],
  );

  const cancelEdit = useCallback(() => {
    dispatch({ type: CANCEL_EDIT });
  }, [dispatch]);

  const dismissDuplicateTooltip = useCallback(
    (criteria) => {
      dispatch({ type: DISMISS_DUPLICATE_TOOLTIP, payload: criteria });
    },
    [dispatch],
  );

  const changeComparison = useCallback(
    (criteria, value) => {
      dispatch({ type: CHANGE_COMPARISON, payload: { criteria, value } });
    },
    [dispatch],
  );

  const changeValue = useCallback(
    (target, value) => {
      dispatch({ type: CHANGE_VALUE, payload: { target, value } });
    },
    [dispatch],
  );

  const chainCondition = useCallback(
    (criteria, multiple_values) => {
      dispatch({ type: CHAIN_CONDITION, payload: { criteria, multiple_values } });
    },
    [dispatch],
  );

  const removeCondition = useCallback(
    (criteria, index) => {
      dispatch({ type: REMOVE_CONDITION, payload: { criteria, index } });
    },
    [dispatch],
  );

  const removeCriteria = useCallback(
    (criteria) => {
      dispatch({ type: REMOVE_CRITERIA, payload: criteria });
    },
    [dispatch],
  );

  const editFilters = useCallback(
    (recommendedPlaylistActive) => {
      dispatch({ type: EDIT_FILTERS, payload: recommendedPlaylistActive });
    },
    [dispatch],
  );

  return {
    ...state,
    playlistsListQuery,
    setSort,
    setOrder,
    setGroupBy,
    setDateRange,
    setFilters,
    clearFilters,
    selectPlaylist,
    setInitialLoadComplete,
    addCriteria,
    applyClickedTag,
    setErrors,
    cancelEdit,
    dismissDuplicateTooltip,
    changeComparison,
    changeValue,
    chainCondition,
    removeCondition,
    removeCriteria,
    editFilters,
    selectGroup,
    setPage,
    setPageOnCountChange,
  };
}
