import { Link } from 'react-router-dom';

import { useAuthContext } from '/src/hooks';

export function ZarazInstallPrompt() {
  const { currentUser, capabilities } = useAuthContext();

  if (!currentUser.flags.includes('zaraz-upgraded')) return null;
  if (capabilities.account.quotas.monthlyPageViews.current ?? 0 > 0) return null;

  return (
    <div>
      Unfortunately Zaraz doesn&apos;t yet support this feature. To use all of Crazy Egg&apos;s premium features,
      including Snapshots, Surveys, and A/B Tests, you&apos;ll need to do a{' '}
      <Link to="/install/manually" className="text-link hover:underline">
        standard script install
      </Link>{' '}
      instead of using your Zaraz installation.
    </div>
  );
}

ZarazInstallPrompt.displayName = 'ZarazInstallPrompt';
