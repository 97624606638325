/* eslint-disable no-console */
import { useEffect, useState, useMemo } from 'react';
import { Button } from '@crazyegginc/hatch';
import { datadogRum } from '@datadog/browser-rum';
import { useNavigate, useRouteError } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';

import { isDevelopment } from '/src/utils';
import { useGeneralCompatibilityCheck } from '/src/hooks';
import { copyToClipboard } from '/src/utils';
import { WebP } from '/src/components/WebP';
import { ConsoleErrors, RenderError } from '/src/components/ConsoleErrors';
import { testErrors } from '/src/test-utils/errors';

import { IconButton } from '@crazyegginc/hatch';
import CrashBalloonPNG from '@crazyegginc/hatch/dist/images/illustration-balloon-error.png';
import CrashBalloonWebP from '@crazyegginc/hatch/dist/images/illustration-balloon-error.webp';
import { ReactComponent as CopyIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';

export function GeneralErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  useGeneralCompatibilityCheck();
  const isTestErrorScreen = window.location.pathname === '/error';

  function navigateDashboard() {
    return navigate('/', { replace: true });
  }

  useEffect(() => {
    if (viewErrors && !errors.length) {
      setViewErrors(false);
    }
  }, [viewErrors, errors.length]);

  useEffect(() => {
    window.Mocky?.stop();
  }, []);

  useEffect(() => {
    logError(error, { route: window.location.pathname });
  }, [error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (console.errors.length > 0 && console.errors.length !== errors.length) {
        setErrors(console.errors);
      } else if (!console.errors.length && isDevelopment()) {
        setErrors(testErrors);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [errors.length, isTestErrorScreen]);

  const latestError = useMemo(() => {
    return errors.at(-1);
  }, [errors]);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <div className="flex max-w-[600px] flex-col">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="mx-8 mb-8 flex flex-col justify-center md:mb-0">
            <WebP webp={CrashBalloonWebP} fallback={CrashBalloonPNG} width="160px" alt="Crashing balloon" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <h1 className="text-header-0 mb-3 font-normal">Bother!</h1>
            <h2 className="text-header-1">Something went wrong in-flight.</h2>
            <div className="text-body-2">Head on back to the dashboard and let&#39;s try that again.</div>
            <div className="!mt-5 flex space-x-2.5">
              <Button
                className="!mt-0"
                variant="secondary"
                onClick={() => {
                  // clear current errors list
                  console.errors = [];
                  navigate('.', { replace: true });
                }}
                size="lg"
              >
                Try again
              </Button>
              <Button onClick={() => navigateDashboard()} size="lg" variant="ghost-primary">
                Back to dashboard
              </Button>
            </div>
            {window.ceSessionId ? (
              <div className="text-body-3 mt-5 flex items-center font-mono">
                Session ID:
                <div className="ml-2 flex items-center">
                  {window.ceSessionId}
                  <IconButton
                    icon={<CopyIcon className="h-3.5 w-3.5 fill-current" />}
                    className="mb-0.5 ml-0.5 h-full shrink-0 text-malibu-500 hover:text-dodger-blue-500 active:text-dodger-blue-700"
                    onClick={() => copyToClipboard(window.ceSessionId)}
                    label="copy session id"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {latestError ? (
          <div className="mt-4 flex w-full flex-col rounded bg-white-lilac-500">
            <div className="flex-1 px-3 py-2">
              <p className="text-caption w-full break-words font-mono">
                <RenderError error={latestError} />
              </p>
            </div>
            <div className="flex w-[180px] items-center px-2 pb-2 pl-3 pr-4">
              <Button onClick={() => setViewErrors((v) => !v)} className="w-full justify-center" size="lg">
                {errors.length > 1 && viewErrors ? 'Hide' : 'See'} more errors
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      {errors.length > 1 && viewErrors ? <ConsoleErrors errors={errors} onClose={() => setViewErrors(false)} /> : null}
    </div>
  );
}

function logError(error, context) {
  if (!isDevelopment()) {
    Honeybadger.notify(error, { context, tags: 'user_facing' });
    datadogRum.addError(error, { context, tags: 'user_facing' });
  }
}
