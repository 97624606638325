import { Button } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

import { abTestStopMutation } from '/src/features/ab-testing/mutations';

export function StopTestModal({ abTest }) {
  const modal = useModal();
  const notifications = useNotifications();
  const { mutate: mutateStopAbTest, isLoading } = useMutation(abTestStopMutation);

  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>
      <div className="text-body-2 leading-normal">This test can not be restarted once you end the test.</div>

      <Modal.Actions>
        <Button
          disabled={isLoading}
          variant="warning"
          onClick={async () => {
            await mutateStopAbTest(
              { id: abTest.id },
              {
                onSuccess: () => {
                  modal.close();
                  notifications.success({
                    content: `A/B test ended successfully.`,
                    timeout: 3000,
                  });
                },
                onError: (error) => {
                  notifications.error({
                    content: `A/B test failed to end.`,
                    timeout: 3000,
                    context: { error },
                  });
                },
              },
            );
          }}
        >
          {isLoading ? 'Ending...' : 'End this test'}
        </Button>
        <Modal.Cancel disabled={isLoading} />
      </Modal.Actions>
    </Modal>
  );
}
