import { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Tooltip, Checkbox, DeviceIcon, BrowserIcon, ReactTable } from '@crazyegginc/hatch';

import { usePermissions, useNotifications, useSelection, useMutation, useVisitorId } from '/src/hooks';

import { deleteRecordingsMutation } from '/src/features/recordings/mutations';

import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';
import { formatShortDateTime, formatFullDateTime } from '/src/utils/date';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { CountryFlag } from '/src/components/CountryFlag';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { TableTagList } from '/src/components/TableTagList';
import { PlayerLink } from '../player/PlayerLink';

import { PERMISSION_CRITERIAS, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';
import { SupportLinks } from '/src/support';

import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as DurationIcon } from '@crazyegginc/hatch/dist/images/icon-clock-outline.svg';
import { ReactComponent as PagesIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as BrowserHeaderIcon } from '@crazyegginc/hatch/dist/images/icon-browser.svg';
import { ReactComponent as LocationIcon } from '@crazyegginc/hatch/dist/images/icon-pin-filled.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as CartCurrencyIcon } from '@crazyegginc/hatch/dist/images/icon-cart-currency-outline.svg';
import { ReactComponent as CurrencyIcon } from '@crazyegginc/hatch/dist/images/icon-currency-outline.svg';

import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function RecordingsTable({
  site,
  filtersHook,
  recordings,
  fetching,
  isNextPageLoading,
  hasNextPage = null,
  loadNextPage = null,
  useVirtualization = true,
  nested = false,
  selectAllOption = true,
  groupSelectionForTable = null,
  customSelectionHandler = null,
}) {
  const tableRef = useRef(null);
  const { selectVisitor } = useVisitorId();
  const { toggleSelection, setSelection, clearSelection, currentSelection } = useSelection();
  const { queryParams, applyClickedTag, setOrder, setSort } = filtersHook;
  const permissions = usePermissions();

  const selectable = permissions.can('edit', recordings, PERMISSION_CRITERIAS.SOME).allowed;

  const selection = groupSelectionForTable ?? currentSelection;

  const openVisitorPanel = useCallback(
    (visitor) => {
      selectVisitor(visitor);
    },
    [selectVisitor],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        header: 'Play',
        accessorKey: 'id',
        enableSorting: false,
        size: 55,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: function RecordingsPlayCell({ row }) {
          return <PlayerLink recording={row.original} size="lg" link={true} />;
        },
      },
      {
        header: 'Date',
        accessorKey: 'recordedAt',
        size: 80,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function RecordingsDateCell({ row }) {
          return (
            <Tooltip tooltipContent={formatFullDateTime(row.original.recordedAt)}>
              {formatShortDateTime(row.original.recordedAt)}
            </Tooltip>
          );
        },
      },
      {
        header: function RecordingsDurationHeaderCell() {
          return <IconHeader title="Duration" Icon={DurationIcon} />;
        },
        accessorKey: 'duration',
        size: 55,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDurationCell({ row }) {
          return Math.floor(row.original.duration / 60) + ':' + String(row.original.duration % 60).padStart(2, '0');
        },
      },
      {
        header: function RecordingsPageHeaderCell() {
          return <IconHeader title="Pages" Icon={PagesIcon} />;
        },
        accessorKey: 'visitedPagesCount',
        size: 25,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => row.original.visitedPagesCount,
      },
      {
        header: queryParams.groupBy === GROUP_BY_TYPES.FIRST ? 'Last page' : 'Start page',
        accessorKey: queryParams.groupBy === GROUP_BY_TYPES.FIRST ? 'lastPageUrl' : 'firstPageUrl',
        meta: {
          align: 'center',
          justify: 'left',
        },
        size: 130,
        cell: function RecordingsStartPageCell({ row }) {
          return (
            <Tooltip
              tooltipContent={
                <div className="max-w-md break-all">
                  {queryParams.groupBy === GROUP_BY_TYPES.FIRST ? row.original.lastPageUrl : row.original.firstPageUrl}
                </div>
              }
            >
              <div className="truncate">
                {queryParams.groupBy === GROUP_BY_TYPES.FIRST ? row.original.lastPageName : row.original.firstPageName}
              </div>
            </Tooltip>
          );
        },
      },
      {
        header: 'Visitor profile',
        accessorKey: 'visitorId',
        meta: {
          align: 'center',
          justify: 'left',
        },
        size: 130,
        cell: function RecordingsVisitorCell({ row }) {
          const visitorId = getVisitorDisplayName(row.original.identifier, row.original.visitorId);

          return (
            <div className="relative w-full min-w-0">
              <div className="truncate">{visitorId}</div>
              <button
                type="button"
                className={classNames(
                  'absolute bottom-[-18px] left-0 flex items-center text-xs text-dodger-blue-500 hover:underline focus-visible:outline-black',
                  'invisible group-hover:visible',
                )}
                data-testid="visitor_id_link"
                onClick={() => openVisitorPanel(row.original)}
              >
                View profile <ExpandIcon className="ml-1 h-2 w-2 rotate-90 fill-current" />
              </button>
            </div>
          );
        },
      },
      {
        header: function RecordingsTagsHeaderCell() {
          return (
            <Tooltip
              interactive={true}
              tooltipContent={
                <div style={{ width: '120px' }}>
                  Learn more about our auto generated tags{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SupportLinks.recordings.autoGeneratedTags}
                    className="text-link"
                  >
                    here
                  </a>
                  .
                </div>
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TagIcon
                  className="text-cadet-blue-900 mr-1 h-3.5 w-3.5 flex-shrink-0 fill-current"
                  aria-label="tags"
                />
                Tags
              </div>
            </Tooltip>
          );
        },
        accessorKey: 'tags',
        size: 130,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function RecordingsTagsCell({ row }) {
          return <TableTagList tags={row.original.tags} onTagClick={applyClickedTag} />;
        },
      },
      {
        header: function RecordingsCurrencyHeaderCell() {
          return <IconHeader title="Final Value" Icon={CurrencyIcon} />;
        },
        accessorKey: 'finalValue',
        size: 45,
        meta: {
          align: 'center',
        },
        cell: function RecordingsCurrencyCell({ row }) {
          return (
            <div className="flex flex-col">
              <span className="text-body-2">
                <DisplayCurrency value={row.original.finalValue} />
              </span>
            </div>
          );
        },
      },
      {
        header: function RecordingsCurrencyHeaderCell() {
          return <IconHeader title="Max Value" Icon={CartCurrencyIcon} />;
        },
        accessorKey: 'maxValue',
        size: 45,
        meta: {
          align: 'center',
        },
        cell: function RecordingsCurrencyCell({ row }) {
          return (
            <div className="flex flex-col">
              <span className="text-body-2">
                <DisplayCurrency value={row.original.maxValue} />
              </span>
            </div>
          );
        },
      },
      {
        header: function RecordingsDeviceHeaderCell() {
          return <IconHeader title="Device" Icon={DesktopIcon} />;
        },
        accessorKey: 'device',
        size: 20,
        meta: {
          align: 'center',
        },
        cell: function RecordingsDeviceCell({ row }) {
          return <DeviceIcon device={row.original.device.toUpperCase()} />;
        },
      },
      {
        header: function RecordingsBrowserHeaderCell() {
          return <IconHeader title="Browser" Icon={BrowserHeaderIcon} />;
        },
        accessorKey: 'browser',
        size: 20,
        meta: {
          align: 'center',
        },
        cell: function RecordingsBrowserCell({ row }) {
          return <BrowserIcon browser={row.original.browser} />;
        },
      },
      {
        header: function RecordingsLocationHeaderCell() {
          return <IconHeader title="Location" Icon={LocationIcon} />;
        },
        accessorKey: 'country',
        sortDescFirst: false,
        size: 20,
        meta: {
          align: 'center',
        },
        cell: function RecordingsLocationCell({ row }) {
          return <CountryFlag country={row.original.country} />;
        },
      },
      {
        id: 'Actions',
        size: 40,
        meta: {
          align: 'center',
          padding: '0',
        },
        cell: function ActionsCell({ row }) {
          return <RecordingsActions recording={row.original} siteId={site.id} />;
        },
      },
    ];

    if (selectable) {
      cols.unshift({
        id: 'selection',
        meta: {
          align: 'center',
          justify: 'left',
        },
        size: 54,
        header: () =>
          selectAllOption ? (
            <div className="pl-[30px]">
              <Checkbox
                checked={selection.length && selection.length === recordings.length ? true : false}
                onChange={() => {
                  selection.length === recordings.length ? clearSelection() : setSelection([...recordings]);
                }}
                indeterminate={selection.length > 0 && selection.length < recordings.length}
                title="Toggle all rows selected"
                size="lg"
              />
            </div>
          ) : null,
        cell: ({ row }) => (
          <div className="pl-[30px]">
            <Checkbox
              checked={selection.findIndex((x) => x.id === row.original.id) > -1}
              onChange={() =>
                customSelectionHandler ? customSelectionHandler(row.original) : toggleSelection(row.original)
              }
              size="lg"
            />
          </div>
        ),
      });
    }

    return cols.filter(Boolean);
  }, [
    site.id,
    applyClickedTag,
    queryParams.groupBy,
    openVisitorPanel,
    selectable,
    customSelectionHandler,
    selection,
    toggleSelection,
    selectAllOption,
    recordings,
    clearSelection,
    setSelection,
  ]);

  const tableSortData = useMemo(() => {
    // add 'Url' for firstPageUrl, lastPageUrl
    let id = snakeToCamel(queryParams.order.field);
    if (['firstPage', 'lastPage'].includes(id)) {
      id += 'Url';
    }
    return {
      id,
      desc: queryParams.order.sort === SORT_ORDER_TYPES.DESC,
    };
  }, [queryParams.order.field, queryParams.order.sort]);

  const onFetchData = useCallback(
    ({ sorting = null }) => {
      if (sorting) {
        // remove 'Url' from firstPageUrl, lastPageUrl
        const nextField = camelToSnake(sorting[0].id.replace(/Url$/, '')).toUpperCase();
        setOrder(nextField);

        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      fetching={fetching}
      ref={{ tableRef }}
      rowPadding={!selectable}
      columns={columns}
      data={recordings}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      useVirtualization={useVirtualization}
      rowCustomBackground={(row) =>
        classNames({
          'bg-white-lilac-500 hover:bg-white': row && row.original.viewedAt,
        })
      }
      rowCustomClassname={(row) =>
        classNames({
          '!text-lynch-500': row && row.original.permissions.canUpgradeToView,
        })
      }
      hasNextPage={hasNextPage}
      isNextPageLoading={isNextPageLoading}
      loadNextPage={loadNextPage}
      nested={nested}
    />
  );
}

function RecordingsActions({ recording, siteId }) {
  const { removeFromSelection } = useSelection();
  const [state, setState] = useState({ clicked: false, inProgress: false });
  const deleteRecordings = useMutation(deleteRecordingsMutation);
  const notifications = useNotifications();
  const permissions = usePermissions();

  const canShare = permissions.can('share', recording).allowed;
  const canDelete = permissions.can('delete', recording).allowed;

  useEffect(() => {
    setState({ clicked: false, inProgress: false });
  }, [recording]);

  return (
    <>
      {!state.clicked && !state.inProgress && (
        <div
          className={classNames(
            'mr-2.5 flex w-full max-w-[65px] justify-between px-1.25',
            'invisible group-hover:visible',
          )}
        >
          {canShare ? (
            <Tooltip tooltipContent="Share">
              <button
                type="button"
                className="focus:outline-none"
                onClick={() => {
                  window.SharingModal.show({
                    entity: { id: parseInt(recording.id), type: SHARABLE_RESOURCE_TYPES.RECORDING },
                  });
                }}
              >
                <ShareIcon aria-label="share recording" className="h-4 w-4 fill-current text-cadet-blue-500" />
              </button>
            </Tooltip>
          ) : (
            <div />
          )}
          {canDelete ? (
            <Tooltip tooltipContent="Delete">
              <button
                type="button"
                className="focus:outline-none"
                onClick={() => {
                  setState((x) => ({ ...x, clicked: true }));
                }}
                style={{ marginLeft: '5px' }}
              >
                <DeleteIcon aria-label="delete recording" className="h-4 w-4 fill-current text-cadet-blue-500" />
              </button>
            </Tooltip>
          ) : (
            <div />
          )}
        </div>
      )}
      {state.clicked && (
        <button
          type="button"
          className="relative z-[1] flex h-full w-full flex-col items-center justify-center bg-carnation-500 text-xs text-white focus:outline-none"
          onMouseLeave={() => {
            setState((x) => ({ ...x, clicked: false }));
          }}
          onClick={() => {
            setState({ clicked: false, inProgress: true });

            setTimeout(() => {
              deleteRecordings.mutate(
                { recordingIds: [recording.id], site: siteId },
                {
                  onError: (error) => {
                    setState((x) => ({ ...x, inProgress: false }));
                    notifications.error({
                      title: 'Error',
                      content: 'Failed to delete Recording!',
                      context: { error },
                    });
                  },
                  onSuccess: () => {
                    removeFromSelection([{ id: recording.id }]);
                  },
                },
              );
            }, 300);
          }}
        >
          <DeleteIcon aria-label="confirm delete" className="h-4 w-4 fill-current text-white" />
          Delete?
        </button>
      )}
      {state.inProgress && (
        <div className="relative z-[1] flex h-full w-full flex-col items-center justify-center bg-carnation-500 text-xs text-white">
          <TickIcon className="h-4 w-4 fill-current text-white" />
          Deleting
        </div>
      )}
    </>
  );
}

function IconHeader({ title, Icon }) {
  return (
    <Tooltip tooltipContent={title}>
      <Icon aria-label={title} className="text-cadet-blue-900 h-3.5 w-3.5 flex-shrink-0 fill-current" />
    </Tooltip>
  );
}
