import { FieldArray, useFormikContext } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button } from '@crazyegginc/hatch';
import { v4 as uuid } from 'uuid';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

import { ProvisioningActionRow } from './rules/ProvisioningActionRow';
import { RenderRuleBlock } from './rules/RenderRuleBlock';
import { emptyRule } from '../pages/sso';
import { Panel } from './SsoUi';

export function SsoRulesForm() {
  const { values, setFieldTouched } = useFormikContext();

  const rules = values.provisioningRules || [];

  if (!rules) return null;

  return (
    <Panel>
      <p className="text-body-2 mb-3">
        The rules below do not affect existing team members within your Crazy Egg account or existing invitations. The
        rules only affect team members the first time they access Crazy Egg through your SSO provider.
      </p>
      <p className="text-body-2 mb-3">
        If you remove a Team Member within Crazy Egg they will no longer have access to your Crazy Egg account unless
        you send them a new invitation.
      </p>

      <p className="text-body-2 mb-3">
        At any time you can override the following rules by manually inviting a Team Member to Crazy Egg with the
        desired level of access.
      </p>

      <p className="text-info-body mb-7 flex max-w-[664px] rounded bg-white-lilac-500 px-3.5 py-2.5">
        <InfoIcon className="mr-2.5 h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
        The rules in this section are conditions and when a specific condition is met the corresponding action will be
        applied when provisioning users. The order is important as we only apply the first matched rule. Rules can be
        added, removed, and re-ordered.
      </p>

      <FieldArray name="provisioningRules">
        {({ insert: insertRule, remove: removeRule, move: moveRule }) => (
          <DndProvider backend={HTML5Backend}>
            {values.provisioningRules.map((rule, ruleIndex) => {
              // skip rendering the last rule in this form, it will be rendered manually
              if (ruleIndex === (values.provisioningRules?.length ?? 0) - 1) return null;
              return (
                <RenderRuleBlock
                  id={rule.id}
                  key={rule.id}
                  rule={rule}
                  ruleIndex={ruleIndex}
                  removeRule={removeRule}
                  moveRule={moveRule}
                />
              );
            })}
            <Button
              className="!self-start"
              onClick={() => {
                insertRule((values.provisioningRules?.length ?? 0) - 1, { ...emptyRule(uuid()) });
                setFieldTouched(`provisioningRules.[${values.provisioningRules.length - 1}]`);
              }}
            >
              Add another rule
            </Button>
            <div className="mb-4 mt-4 flex items-center gap-x-1.5 rounded bg-white-lilac-500 p-4 leading-[35px]">
              <div className="font-lg flex h-10 w-10 flex-shrink-0 items-center justify-center self-start rounded-full bg-dodger-blue-500 p-2 font-bold text-white">
                {values.provisioningRules.length}
              </div>

              <div className="pl-12">
                <ProvisioningActionRow index={values.provisioningRules.length - 1} />
              </div>
            </div>
          </DndProvider>
        )}
      </FieldArray>
    </Panel>
  );
}
