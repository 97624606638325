import React from 'react';
import { IconWithTooltip } from './IconWithTooltip';

export function WarningItem({ header, description, howtoFix, extraDescription, ctaButton }) {
  return (
    <div className="text-body-2 relative min-h-[30px] py-1">
      <IconWithTooltip type="warning" />
      <div className="text-header-4">{header}</div>
      <p className="leading-snug">{description}</p>
      {extraDescription && extraDescription}
      <div className="pt-2.5">
        {howtoFix ? (
          <>
            <strong className="text-body-1">How to fix it:</strong>&nbsp;{howtoFix}
          </>
        ) : null}
        {ctaButton && ctaButton}
      </div>
    </div>
  );
}
