import { useFormikContext, FieldArray } from 'formik';
import classNames from 'classnames';

import { checkLastInputEmpty, returnURLObjectValue, haveErrors } from '/src/features/options/utils';
import { Button, IconButton, Input } from '@crazyegginc/hatch';

import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function SiteSettingsModalListofInputs({
  listData,
  type = 'page',
  placeholder = 'e.g. mysite.com/page',
  buttonName = 'page',
  showSingleDelete = false,
  onRowAdded = null,
  onRowRemoved = null,
  buttonDisabled = null,
  isRecordingSessionEnabled,
  children,
}) {
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext();

  const isTypeCSS = type === 'css';
  const haveErrorMessages = errors?.[listData] ?? [];

  return (
    <>
      <FieldArray name={`${listData}`}>
        {(actions) => (
          <>
            {values[listData].map((page, i) => {
              return (
                <div className="relative mb-1.5 flex items-start" key={`${type}-${i}`}>
                  <Input
                    aria-label={`${type} ${i}`}
                    id={`${listData}[${i}]`}
                    name={`${listData}[${i}]`}
                    value={!isTypeCSS ? values[listData][i].u.replace(/^\*:\/\//, '') : values[listData][i].selector}
                    onChange={(e) =>
                      returnURLObjectValue({
                        isTypeCSS,
                        setFieldValue,
                        data: listData,
                        index: i,
                        value: e.target.value,
                      })
                    }
                    error={haveErrors({ isTypeCSS, touched, errors, data: listData, index: i })}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    disabled={!isRecordingSessionEnabled}
                  />

                  {(values[listData]?.length > 1 || showSingleDelete) && (
                    <div className="ml-4 flex h-10 w-5 flex-shrink-0 items-center">
                      <IconButton
                        icon={<CrossIcon className="h-3 w-3 fill-current" />}
                        className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                        onClick={() => {
                          actions.remove(i);
                          onRowRemoved?.(page);
                        }}
                        label={`delete ${type} ${i}`}
                        disabled={!isRecordingSessionEnabled}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {typeof errors?.[listData] === 'string' && <div className="text-error !mt-0.5">{errors[listData]}</div>}
            <div
              className={classNames('flex items-start justify-between space-x-5 pt-[9px]', {
                'mr-9': !isTypeCSS,
              })}
            >
              <Button
                variant="secondary"
                size={!isTypeCSS ? 'sm' : 'base'}
                onClick={() => {
                  if (!isTypeCSS) {
                    const data = { u: '' };
                    actions.push(data);
                    onRowAdded?.(data);
                  } else {
                    const data = { selector: '' };
                    actions.push(data);
                    onRowAdded?.(data);
                  }
                }}
                className="!flex-shrink-0"
                disabled={
                  buttonDisabled !== null
                    ? buttonDisabled || haveErrorMessages.length > 0 || !isRecordingSessionEnabled
                    : haveErrorMessages.length > 0 ||
                      checkLastInputEmpty(values?.[listData], isTypeCSS) ||
                      !isRecordingSessionEnabled
                }
              >
                <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                Add {buttonName}
              </Button>

              {children}
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
}
