/* eslint-disable no-restricted-syntax */
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useAuthContext } from '/src/hooks';
import { showPreview } from '/src/services/web-editor';

export default function AbTestPreview() {
  const navigate = useNavigate();

  const { currentUser } = useAuthContext();

  const { explicitAgent } = currentUser.settings;

  const frameSrc = useMemo(() => {
    if (!window.EDITOR_PREVIEW_DATA) return null;
    return `${window.WEB_EDITOR_URL}/pages/${encodeURIComponent(
      window.EDITOR_PREVIEW_DATA.url.replace(/%/g, '%25'),
    )}/blank?device=${window.EDITOR_PREVIEW_DATA.deviceType}&name=test-editor&explicit_agent=${explicitAgent}`;
  }, [explicitAgent]);

  if (!window.EDITOR_PREVIEW_DATA) {
    navigate('/ab-tests', { replace: true });

    return null;
  }

  return (
    <div className="wysiwyg absolute inset-0">
      <iframe
        id="test-editor-frame"
        title="Test Editor"
        src={frameSrc}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        scrolling="no"
        frameBorder="0"
        onLoad={() => {
          if (window.EDITOR_PREVIEW_DATA) {
            showPreview(window.EDITOR_PREVIEW_DATA);
          }
        }}
      />
    </div>
  );
}
