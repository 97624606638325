import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

import { mergeDeep } from '/src/utils/object';

export function ScrollingLineChart({ data, options, plugins, id, title, height, pixelsPerDatapoint = 23 }) {
  // in case of scrollbars on the "proper" chart, we need to account for that on the "yAxis only" chart
  const [yAxisHeight, setYaxisHeight] = useState(0);
  const [yAxisWidth, setYAxisWidth] = useState(0);
  const [yAxisContentHeight, setYAxisContentHeight] = useState(0);

  useEffect(() => {
    setYaxisHeight(ChartJS.getChart(id).scales.x.bottom);
    setYAxisWidth(ChartJS.getChart(id).scales.x.left);
    setYAxisContentHeight(ChartJS.getChart(id).scales.y.top + ChartJS.getChart(id).scales.y.height + 5);
  }, [id, height]);

  const yAxisChartData = useMemo(() => {
    return mergeDeep({}, data, {
      datasets: data.datasets.map((set) => ({
        ...set,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointBorderColor: 'rgba(255,255,255,0)',
        pointHoverBorderColor: 'rgba(255,255,255,0)',
        borderColor: 'rgba(255,255,255,0)',
        pointBackgroundColor: 'rgba(255,255,255,0)',
        pointHoverBackgroundColor: 'rgba(255,255,255,0)',
        backgroundColor: 'rgba(255,255,255,0)',
      })),
    });
  }, [data]);

  const yAxisChartOption = useMemo(() => {
    return mergeDeep({}, options, {
      scales: {
        x: {
          ticks: {
            color: 'rgba(255,255,255,0)',
          },
        },
        y: {
          backgroundColor: 'white',
          grid: {
            color: 'rgba(255,255,255,0)',
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    });
  }, [options]);

  const chartOption = useMemo(() => {
    return mergeDeep({}, options, {
      scales: {
        y: {
          ticks: {
            color: 'rgba(255,255,255,0)',
          },
        },
      },
    });
  }, [options]);

  const width = pixelsPerDatapoint * data.datasets[0].data.length;

  return (
    <div>
      {title ? <div className="mb-2.5 text-base font-semibold">{title}</div> : null}
      <div className="relative w-full overflow-hidden" style={{ height: `${height}px` }}>
        <div
          className={classNames(
            'absolute h-full w-full overflow-x-auto bg-white',
            'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
          )}
        >
          <div className="h-full" style={{ width: `max(${width}px, 100%)` }}>
            <Line options={chartOption} data={data} plugins={plugins} id={id} />
          </div>
        </div>
        <div
          className="pointer-events-none absolute left-0 top-0 bg-white"
          style={{ width: `${yAxisWidth}px`, height: `${yAxisContentHeight}px` }}
        />
        <div
          className="pointer-events-none absolute bg-transparent"
          style={{
            height: `${yAxisHeight}px`,
            width: `max(${width}px, 100%)`,
            left: '0px',
          }}
        >
          <Line options={yAxisChartOption} data={yAxisChartData} id={`y-${id}`} />
        </div>
      </div>
    </div>
  );
}
