import { useMemo, useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { recordingsListQuery } from '/src/features/recordings/queries';
import { NoMatchesWall } from '../../../_global/paywalls/NoMatchesWall';
import { RecordingsTable } from './RecordingsTable';
import { Loader } from './Loader';
import { useSelectedSite } from '/src/hooks';
import { isProduction } from '/src/utils';

export function RecordingsList({ filtersHook, site }) {
  const isProd = isProduction();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const { selectedSite } = useSelectedSite();
  const { clearFilters, initialLoadComplete, queryParams } = filtersHook;

  let { data, hasNextPage, fetchNextPage, isFetching, isInitialLoading } = useInfiniteQuery({
    ...recordingsListQuery({
      filters: JSON.stringify(queryParams.filters),
      site: selectedSite?.id,
      field: queryParams.order.field,
      sort: queryParams.order.sort,
      limit: 50,
    }),
    enabled: Boolean(selectedSite?.id && initialLoadComplete),
  });
  if (!initialLoadComplete) {
    isInitialLoading = true;
    isFetching = true;
  }

  const recordings = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.recordingsList?.recordings ?? [])], []) ?? [],
    [data],
  );

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('recordings_dashboard_list');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && data) {
      try {
        window.CE2.timing.stop('recordings_dashboard_list');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, isProd]);

  if (!isFetching && recordings.length === 0) {
    return (
      <div className="mt-5 flex w-full items-center justify-center">
        <NoMatchesWall
          reset={clearFilters}
          text={
            <>
              matches, try adjusting the date range or clearing the filter.
              <br />
              You can also try adjusting the targeting rules to ensure you are recording the most important pages
            </>
          }
          actionText="filter"
        />
      </div>
    );
  }

  return (
    <>
      {isInitialLoading && <Loader />}
      <RecordingsTable
        site={site}
        filtersHook={filtersHook}
        recordings={recordings}
        fetching={isInitialLoading}
        hasNextPage={hasNextPage}
        loadNextPage={fetchNextPage}
        isNextPageLoading={isFetching}
      />
    </>
  );
}
