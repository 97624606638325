import ReactCountryFlag from 'react-country-flag';
import { DeviceIcon } from '@crazyegginc/hatch';

import { DEVICE_TYPES } from '/src/features/_global/constants';
import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';
import { getPageName } from '../../live-functions';
import { MainTextRow, SubTextRow, Bull } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function VisitEvent({ liveEvent, identifyEvents }) {
  const { url, device, country, referrer, utm } = liveEvent.attributes;
  const page = getPageName(url);

  let deviceText;
  switch (device) {
    case 1:
      deviceText = DEVICE_TYPES.DESKTOP;
      break;
    case 2:
      deviceText = DEVICE_TYPES.MOBILE;
      break;
    case 3:
      deviceText = DEVICE_TYPES.TABLET;
      break;
  }

  let source;
  if (!referrer && !utm) {
    source = 'direct visit';
  } else if (referrer && !utm) {
    source = referrer;
  } else if (utm) {
    let names = '';
    let values = '';
    for (const param of ['source', 'medium', 'campaign', 'term', 'content']) {
      if (utm[param] && (names.match(/ \+ /g) || []).length < 2) {
        names += ` + UTM ${param}`;
        values += ` + ${utm[param]}`;
      }
    }
    source = `${names.replace(/^ + /, '')}: ${values.replace(/^ + /, '')}`;
    if (referrer) {
      source += ` on ${referrer}`;
    }
  }

  return (
    <div className="flex flex-col">
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <div className="max-w-lg">
          <div className="flex items-center">
            <span className="shrink-0 whitespace-pre">visited {page === 'homepage' ? '' : 'page'} </span>
            <TruncateWithTooltip text={page} className="text-body-1 mr-8" />
          </div>
        </div>
      </MainTextRow>
      <SubTextRow>
        <DeviceIcon device={deviceText} className="!h-4 !w-4" tooltip={true} />
        {country && (
          <>
            <Bull />
            <div className="flex items-center">
              <ReactCountryFlag
                countryCode={country.code}
                svg
                alt={country.name}
                style={{
                  margin: '0px 5px 0px 0px',
                  height: '14px',
                  width: '18px',
                  border: '1px solid #e1e6ef',
                }}
              />
              {country.name}
            </div>
            <Bull />
            <TruncateWithTooltip text={`via ${source}`} className="max-w-sm" />
          </>
        )}
      </SubTextRow>
    </div>
  );
}
