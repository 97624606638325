import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions, useMetric } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';
import { CTAsPaywallText } from '/src/features/addons/paywalls/CTAsPaywallText';

import AddonsPNG from '@crazyegginc/hatch/dist/images/illustration-addons.png';
import AddonsWebP from '@crazyegginc/hatch/dist/images/illustration-addons.webp';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function CTAsUpgradePaywall({ mini }) {
  const { currentAccount } = useAuthContext();
  const trackMetric = useMetric();

  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={AddonsWebP}
        png={AddonsPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>
        <CTAsPaywallText.Title />
      </Paywall.Title>

      <Paywall.Body>
        <CTAsPaywallText.Body />
        {mini ? null : <CTAsPaywallText.List />}
        {!canManageBilling ? (
          <p className="mt-5">
            To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
            the plan.
          </p>
        ) : null}
      </Paywall.Body>

      {canManageBilling ? (
        <Button
          component={Link}
          to={MANAGE_SUBSCRIPTION_PATH}
          onMouseDown={() => trackMetric('upgrade_cta_click', 'ctas_upsell_paywall')}
          className="mb-5"
        >
          Get CTAs
          <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
