/* eslint-disable jsx-a11y/no-autofocus */
import { Formik, Form, Field } from 'formik';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { useModal, useMutation } from '/src/hooks';
import { goalEditMutation } from '/src/features/goals/mutations';
import { Modal } from '/src/contexts/modal';

export function RenameModal({ goal }) {
  const modal = useModal();

  const goalEdit = useMutation(goalEditMutation);

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Formik
        initialValues={{ name: goal.name }}
        onSubmit={(values) => {
          goalEdit.mutate(
            {
              params: {
                name: values.name,
                autoName: goal.autoName || values.name,
                id: goal.id,
                purpose: goal.purpose,
                triggers: goal.triggers.map((trigger) => ({
                  autoName: trigger.autoName,
                  filter: trigger.filter,
                  id: trigger.id,
                  name: trigger.name,
                  uiMode: trigger.uiMode,
                })),
              },
            },
            {
              onSuccess: () => modal.close(),
            },
          );
        }}
      >
        {({ values }) => (
          <Form>
            <Modal.Title>Rename your Goal</Modal.Title>

            <Field name="name">{({ field }) => <Input {...field} autoFocus aria-label={`new name`} />}</Field>

            <Modal.Actions>
              <Button type="submit" disabled={goalEdit.isLoading || !values.name}>
                {goalEdit.isLoading && (
                  <span className="mr-2.5">
                    <Spinner />
                  </span>
                )}
                {goalEdit.isLoading ? 'Renaming...' : 'Rename'}
              </Button>
              <Modal.Cancel />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
