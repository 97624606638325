import { useState, useEffect } from 'react';
import { Button } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { copyToClipboard } from '/src/utils';
import { getExternalSurveyLink } from '/src/features/addons/surveys/common-functions';

import { ReactComponent as LinkIcon } from '@crazyegginc/hatch/dist/images/icon-link-outline.svg';

export function ExternalShareModal({ surveyId, userId }) {
  const [copied, setCopied] = useState(false);

  const url = getExternalSurveyLink(surveyId, userId);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const copyUrl = () => {
    copyToClipboard(url);
    setCopied(true);
  };

  return (
    <Modal dialogClassName="!w-[535px]">
      <Modal.Title>Your Survey will be ready in 60 seconds.</Modal.Title>

      <p className="text-body-2">
        Share your survey link with anyone you want to take the survey. Once a person completes the survey, the
        responses will be available to view in your survey dashboard.
      </p>

      <div className="mt-6 flex max-w-full items-center space-x-2.5">
        <div className="text-header-4 flex h-10 min-w-0 items-center rounded bg-mystic-500/50 px-3.75">
          <LinkIcon className="mr-2 h-4 w-4 shrink-0 fill-current text-cadet-blue-500" />
          <span className="mr-1.25 shrink-0 text-lynch-500">Survey link</span>
          <a href={url} className="text-link truncate" target="_blank" rel="noreferrer">
            {url}
          </a>
        </div>
        <Button variant="secondary" size="lg" onClick={() => copyUrl()}>
          {copied ? 'copied' : 'Copy'}
        </Button>
      </div>
    </Modal>
  );
}
