import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input, Divider } from '@crazyegginc/hatch';

import { regexForUrl, normalizeUrl } from '/src/utils/url';
import { useWizard } from '/src/hooks';
import { SupportLinks } from '/src/support';

const validationSchema = yup.object().shape({
  regex: yup
    .string()
    .required('Please provide a regular expression.')
    .test(
      'is-valid-regexp',
      'Oops, looks like the regular expression is not valid. Please see the examples for reference.',
      (value) => {
        try {
          new RegExp(value, 'i');
        } catch {
          return false;
        }
        return true;
      },
    )
    .notOneOf(
      [yup.ref('testUrl')],
      'Oops, looks like your regular expression is the same as your Snapshot URL. Please see the examples for more information on how to create your regular expression.',
    ),
  testUrl: yup
    .string()
    .required('Please provide a test URL.')
    .when('regex', (regex, schema) =>
      schema.test(
        'match',
        'Oops, looks like the test URL doesn’t match the regular expression pattern. Please double check and try again.',
        (testUrl) => {
          if (!testUrl || !regex) return true;
          try {
            const compiled = new RegExp(regex, 'i');
            if (testUrl.match(compiled) || decodeURI(testUrl).match(compiled)) {
              return true;
            }
            return false;
          } catch {
            return false;
          }
        },
      ),
    ),
});

export function RegexModal({ onSuccess, formik }) {
  const wizard = useWizard();

  const snapshot = formik.values.snapshots[0];
  const defaultRegex = regexForUrl(normalizeUrl(snapshot.siteUrl));

  const initialRegex = formik.values.trackingOptions.regex?.regex ?? defaultRegex;

  function openInfoModal() {
    window.open('/snapshots/new/regex-syntax', '_blank', 'width=700,height=700,left=200');
  }

  return (
    <>
      <h1 className="text-header-1 mb-8 mt-0">Set-up a Regular Expression</h1>
      <Divider className="my-2.5" />

      <div className="text-body-2 my-3.5">
        Regular expressions let you combine data for multiple pages into one snapshot. They offer more control over
        URL-matching than wildcards but require more knowledge to use properly.
      </div>

      <div className="relative mb-12 w-[calc(100%-170px)] rounded-lg border border-mystic-500 bg-white-lilac-500 px-4 py-5">
        <ul className="text-body-4 mx-8 my-2.5 list-disc">
          <li className="py-1.25 pl-2.5">
            Begin your regular expression with <code className="text-body-1 border border-mystic-500 px-0.5">^</code>{' '}
            and end it with <code className="text-body-1 border border-mystic-500 px-0.5">$</code> to ensure that you
            are matching against the whole URL.
          </li>
          <li className="py-1.25 pl-2.5">
            Escape the periods in your domain name and URL with a backslash, as periods have special meaning in regular
            expressions eg: ^http://www<strong>\</strong>.example<strong>\</strong>.com/file<strong>\</strong>.html$
          </li>
        </ul>
      </div>

      <Formik
        initialValues={{
          regex: initialRegex,
          testUrl: formik.values.trackingOptions.regex?.testUrl ?? normalizeUrl(snapshot.siteUrl),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          formik.setFieldValue('trackingOptions.regex', values, true);
          wizard.closeModal();
          onSuccess?.();
        }}
      >
        {({ values, errors, handleChange, handleBlur, isValid }) => (
          <Form>
            <div
              className={classNames('relative my-4 flex w-full flex-col rounded border border-mystic-500 p-6', {
                'bg-[#FDEEEE]': !isValid,
                'bg-[#F3F8E5]': isValid,
              })}
            >
              <Input
                name="regex"
                id="regex"
                label="Enter your regular expression:"
                value={values.regex}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={!errors.regex}
                placeholder="^http://www\.example\.com/$"
              />
              <div className="text-body-2 my-3.5 flex items-center space-x-1.25">
                <button type="button" className="text-link" onClick={openInfoModal}>
                  See more regular expression examples
                </button>
                <span>or</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link"
                  href={SupportLinks.snapshots.wildcardRegex}
                >
                  learn more
                </a>
              </div>

              <Input
                name="testUrl"
                id="testUrl"
                label="Enter a URL to test whether your regular expression matches:"
                value={values.testUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={!errors.testUrl}
                placeholder="http://www.example.com/info"
              />
            </div>

            {isValid ? (
              <div className="text-body-2 mt-3.5">Awesome! Your regular expression matches your test URL.</div>
            ) : (
              <div className="relative h-5">
                <div className="text-body-4 absolute whitespace-nowrap text-carnation-500">
                  {errors.regex || errors.testUrl}
                </div>
              </div>
            )}

            <Divider className="my-2.5" />

            <div className="mt-5 flex space-x-2.5">
              <Button variant="secondary" disabled={!isValid} type="submit">
                Save &amp; Continue
              </Button>
              <Button variant="cancel" onClick={wizard.closeModal}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
