import { forwardRef } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

export const ActionIcon = forwardRef(({ img: Icon, alt, onClick, tooltip, iconClassName = '' }, ref) => {
  return (
    <div className="ml-6 first:ml-0">
      <Tooltip tooltipContent={tooltip ?? ''} offset="25" show={!!tooltip}>
        <button
          className="group flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full p-2 hover:bg-wedgewood-500 focus-visible:outline-white"
          type="button"
          onClick={onClick}
          ref={ref}
        >
          <Icon
            className={classNames(
              'h-5 w-5 flex-shrink-0 fill-current text-white brightness-75 group-hover:brightness-100',
              iconClassName,
            )}
            aria-label={alt}
          />
        </button>
      </Tooltip>
    </div>
  );
});
