import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const ssoFeatureKeys = createQueryKeys('ssoFeature', {
  ssoSettings: null,
});

export const ssoSettingsQuery = () => ({
  ...ssoFeatureKeys.ssoSettings,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query SsoSettings {
        ssoSettings {
          lastValidatedAt
          emailDomain
          enabled
          idpConfiguration {
            certificate
            entityId
            targetUrl
          }
          provisioningRules {
            action {
              role
              sites
              specificSites
              type
            }
            conditions {
              attribute
              type
              values
            }
          }
          spConfiguration {
            acsUrl
            defaultEntityId
            entityId
            metadataUrl
            sloUrl
          }
          organizationName
          required
        }
      }
    `,
  },
});
