import { useRef, useEffect, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select, ScrollToTop } from '@crazyegginc/hatch';

import { isProduction } from '/src/utils';

import { useModal, useSelectedSite, useSelection, useAuthContext } from '/src/hooks';
import { useFilter } from '../../snapshots-filter-context';

import { ExportsListModal } from './modals/ExportsListModal';
import { SnapshotTable } from './SnapshotTable';
import { PaginationControls } from '/src/components/PaginationControls';
import { SearchInput } from '/src/components/search-input';
import { FoldersDropdown } from './FoldersDropdown';
import { SnapshotStatusDropdown } from './SnapshotStatusDropdown';
import { BulkAction } from '/src/components/bulk-action/BulkAction';
import { GoalPromo } from '/src/components/promos/GoalPromo';
import { NoMatchesWall } from '../../../_global/paywalls/NoMatchesWall';
import { snapshotsQuery } from '/src/features/snapshots/queries';
import { FirstSnapshotWall } from '../../paywalls/FirstSnapshotWall';

import { PER_PAGE_OPTIONS, SNAPSHOT_FILTER_STATUSES } from '/src/features/snapshots/constants';
import { BULK_ACTIONABLE_ITEMS, ALL } from '/src/features/_global/constants';

const isProd = isProduction();

export function SnapshotDashboardContent({ sites, setPollExports }) {
  const modal = useModal();
  const { capabilities } = useAuthContext();
  const perfMetricsStarted = useRef(false);
  const filters = useFilter();
  const { selectedSite } = useSelectedSite();
  const isExportStatusInURL = window.location.href.split('#')[1] === 'export-status';
  const { clearSelection } = useSelection();

  const { setFolder } = filters;

  const siteHasSnapshots = selectedSite !== ALL && (selectedSite?.snapshotsCount ?? 0) > 0;
  const accountHasSnapshots = capabilities.snapshots.quotas.totalSnapshots.current > 0;
  const hasSnapshots = siteHasSnapshots || (selectedSite === ALL && accountHasSnapshots);

  useEffect(() => {
    // clear selection when selectedSite changes
    clearSelection();
  }, [clearSelection, selectedSite, setFolder]);

  const { data, isFetching, isLoading, error } = useQuery({
    ...snapshotsQuery({
      page: parseInt(filters.filter.page),
      perPage: filters.filter.perPage,
      query: filters.filter.search || undefined,
      status: filters.filter.status === SNAPSHOT_FILTER_STATUSES.ALL ? undefined : filters.filter.status || undefined,
      folderId: filters.filter.folder === null ? null : filters.filter.folder?.id,
      siteId: selectedSite?.id ?? undefined,
      field: filters.order.field,
      sort: filters.order.sort,
    }),
    // for "All sites" the selected site is "ALL"
    enabled: Boolean(hasSnapshots && selectedSite),
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd && !perfMetricsStarted.current) {
      window.CE2.timing.start('snapshots_dashboard');
      perfMetricsStarted.current = true;
    }
  }, []);

  useEffect(() => {
    if (window.CE2?.timing && isProd && perfMetricsStarted.current && (data || error) && !isFetching) {
      window.CE2.timing.stop('snapshots_dashboard');
      perfMetricsStarted.current = false;
    }
  }, [data, error, isFetching]);

  useEffect(() => {
    if (isExportStatusInURL) {
      modal.show(<ExportsListModal isExportStatusInURL={isExportStatusInURL} />);
    }
  }, [modal, isExportStatusInURL]);

  if (!selectedSite) return null;

  const snapshots = data?.snapshots?.list ?? [];
  const meta = data?.snapshots?.meta ?? {};

  if (!hasSnapshots) {
    return <FirstSnapshotWall />;
  }

  return (
    <>
      <div className="flex flex-col p-10">
        <SnapshotActions
          meta={meta}
          snapshotCountForSelectedSite={sites.find((site) => site.id === selectedSite.id)?.snapshotsCount}
        />
        <RenderDashboardContent
          fetching={isLoading}
          selectedSiteName={selectedSite.name}
          listOfSites={sites}
          snapshots={snapshots}
          meta={meta}
          setPollExports={setPollExports}
        />
      </div>
      <ScrollToTop />
      <BulkAction
        feature={BULK_ACTIONABLE_ITEMS.SNAPSHOTS}
        filter={filters.filter}
        sites={sites}
        setPollExports={setPollExports}
      />
    </>
  );
}

function SnapshotActions({ meta, snapshotCountForSelectedSite }) {
  const filters = useFilter();
  const { setFolder } = filters;
  const { clearSelection } = useSelection();

  return (
    <div className="flex w-full items-center justify-between pb-[15px]" data-testid="snapshot-actions-bar">
      <GoalPromo />
      <div className="flex space-x-[10px]">
        <SnapshotStatusDropdown
          counts={meta?.counts}
          onSelect={(status) => {
            filters.setStatus(status);
            clearSelection();
          }}
          selected={filters.filter.status}
        />
        <FoldersDropdown
          totalCount={snapshotCountForSelectedSite}
          selected={filters.filter.folder}
          folderId={filters.filter.folderId}
          onSelect={useCallback(
            (folder) => {
              setFolder(folder);
              clearSelection();
            },
            [clearSelection, setFolder],
          )}
        />
        <SearchInput
          search={filters.search}
          initialValue={filters.filter.search}
          placeholder="Search for URL or Name"
          effectOnSearch={clearSelection}
          className="!border-solitude-500 !bg-solitude-500 !font-semibold !placeholder-dodger-blue-500/50"
          ariaLabel="search for url or name"
        />
      </div>
    </div>
  );
}

function RenderDashboardContent({ fetching, selectedSiteName, listOfSites, snapshots, meta, setPollExports }) {
  const filters = useFilter();

  if (!fetching && snapshots.length === 0) {
    return (
      <div className="mt-5 flex w-full items-center justify-center">
        <NoMatchesWall reset={filters.reset} text="matches for this search" actionText="search" />
      </div>
    );
  }

  return (
    <>
      <SnapshotTable
        selectedSiteName={selectedSiteName}
        listOfSites={listOfSites}
        snapshots={snapshots}
        fetching={fetching}
        meta={meta}
        setPollExports={setPollExports}
      />
      {!fetching && snapshots.length > 0 && (
        <div className="mb-10 mt-5 flex w-full items-center justify-between">
          <div className="text-body-2 flex items-center space-x-1.5">
            <span>Show</span>
            <div className="w-30">
              <Select
                options={PER_PAGE_OPTIONS.map((x) => ({ value: x }))}
                value={filters.filter.perPage}
                onChange={filters.setPerPage}
              />
            </div>
            <span>Per page</span>
          </div>
          <PaginationControls
            currentPage={filters.filter.page}
            perPage={meta.perPage}
            total={meta.total}
            setPage={(page) => filters.setPage(page)}
          />
        </div>
      )}
    </>
  );
}
