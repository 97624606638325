import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { RG } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { BOTTOM, TOP, LEFT, RIGHT, CENTER } from '/src/features/addons/constants';

import { ReactComponent as PositionBgIcon } from '@crazyegginc/hatch/dist/images/illustration-cta-position.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';

export function ButtonPositionSelector() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const [edge, position] = values.button.position.split('-');

    if (values.button.vertical && ![LEFT, RIGHT].includes(edge)) {
      const opposite = {
        [TOP]: LEFT,
        [BOTTOM]: RIGHT,
        [CENTER]: CENTER,
        [LEFT]: TOP,
        [RIGHT]: BOTTOM,
      };
      const newEdge = opposite[edge];
      let newPosition = opposite[position];
      setFieldValue('button.position', `${newEdge}-${newPosition}`);
    } else if (!values.button.vertical && ![TOP, BOTTOM].includes(edge)) {
      const opposite = {
        [LEFT]: TOP,
        [RIGHT]: BOTTOM,
        [CENTER]: CENTER,
        [TOP]: LEFT,
        [BOTTOM]: RIGHT,
      };
      const newEdge = opposite[edge];
      let newPosition = opposite[position];
      setFieldValue('button.position', `${newEdge}-${newPosition}`);
    }
  }, [setFieldValue, values.button.vertical, values.button.position]);

  return (
    <RG
      value={values.button.position}
      onChange={(newValue) => setFieldValue('button.position', newValue)}
      className="!self-start"
      disabled={readonly}
    >
      <RG.Label className="text-body-1 mb-2 block">Position on page</RG.Label>
      <div className="relative">
        <PositionBgIcon />
        {values.button.vertical ? (
          <>
            <div className="absolute left-0 top-2.5 flex h-[115px] flex-col justify-between">
              <Option value={`${LEFT}-${TOP}`} />
              <Option value={`${LEFT}-${CENTER}`} />
              <Option value={`${LEFT}-${BOTTOM}`} />
            </div>
            <div className="absolute right-0 top-2.5 flex h-[115px] flex-col justify-between">
              <Option value={`${RIGHT}-${TOP}`} />
              <Option value={`${RIGHT}-${CENTER}`} />
              <Option value={`${RIGHT}-${BOTTOM}`} />
            </div>
          </>
        ) : (
          <>
            <div className="absolute left-0 top-2.5 flex w-full justify-between">
              <Option value={`${TOP}-${LEFT}`} />
              <Option value={`${TOP}-${CENTER}`} />
              <Option value={`${TOP}-${RIGHT}`} />
            </div>
            <div className="absolute bottom-0 left-0 flex w-full justify-between">
              <Option value={`${BOTTOM}-${LEFT}`} />
              <Option value={`${BOTTOM}-${CENTER}`} />
              <Option value={`${BOTTOM}-${RIGHT}`} />
            </div>
          </>
        )}
      </div>
    </RG>
  );
}

function Option({ value }) {
  const { readonly } = useEditorContext();
  const { values } = useFormikContext();

  const disabled = readonly;

  return (
    <RG.Option
      value={value}
      disabled={disabled}
      className={classNames('cursor-pointer', {
        'h-1/3 w-12': values.button.vertical,
        'h-[34px] w-1/3': !values.button.vertical,
      })}
      aria-label={value}
    >
      {({ active, checked }) => (
        <div
          className={classNames(
            'flex h-full w-full items-center justify-center bg-off-white-500 hover:border-2 hover:border-dodger-blue-500',
            {
              'border-2 border-dodger-blue-500': checked,
              'border border-mystic-500': !checked,
            },
          )}
        >
          <div
            className={classNames('flex h-6 w-6 shrink-0 items-center justify-center rounded-full', {
              'bg-solitude-500': active,
            })}
          >
            <div
              data-testid="radiobutton"
              className={classNames('flex h-[18px] w-[18px] items-center justify-center rounded-full border', {
                'border-dodger-blue-500 bg-dodger-blue-500': checked && !disabled,
                'border-lynch-500 bg-lynch-500': checked && disabled,
                'border-cadet-blue-500 bg-white': !checked,
                'cursor-pointer': !disabled,
                'cursor-not-allowed': disabled,
              })}
            >
              <Transition
                show={checked}
                enter="transition ease-in duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <TickIcon className={classNames('h-2.5 w-2.5 fill-current text-white')} />
              </Transition>
            </div>
          </div>
        </div>
      )}
    </RG.Option>
  );
}
