import { useState, useCallback } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { ScrollToTop, Button } from '@crazyegginc/hatch';

import { useSite, usePermissions, useModal, useQueryParams } from '/src/hooks';
import { AccountUsersProvider } from '../account-users-filter-context';
import { SelectionProvider } from '/src/contexts/selection';

import { SEO } from '/src/components/SEO';
import { DashboardPage } from '/src/components/Page';
import { DashHeader } from '/src/components/headers/DashHeader';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { InviteUserModal } from '../components/team/modals/InviteUserModal';
import { SearchInput } from '/src/components/search-input';

import { AccountUsersTeam } from '../components/team/AccountUsersTeam';
import { AccountUsersSharing } from '../components/sharing/AccountUsersSharing';
import { AuditLogDashboard } from '../../audit-log/components/AuditLogDashboard';

import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

function SearchInputComponent({ placeholderText, ariaLabel, setSearch, search }) {
  const { set: queryParamsSet } = useQueryParams();

  const doSearch = useCallback(
    (value) => {
      setSearch(value);
      queryParamsSet('search', value);
    },
    [queryParamsSet, setSearch],
  );

  return (
    <SearchInput
      search={doSearch}
      inputSize="xl"
      initialValue={search}
      placeholder={placeholderText}
      ariaLabel={ariaLabel}
    />
  );
}

// eslint-disable-next-line no-restricted-syntax
export default function AccountUsersPage() {
  const modal = useModal();
  const { set: queryParamsSet, get: queryParamsGet, removeAll } = useQueryParams();
  const [search, setSearch] = useState(queryParamsGet('search'));
  const initialTab = queryParamsGet('tab') ?? 0;

  const permissions = usePermissions();
  const canNavigateAuditTrail = permissions.can('navigate', FEATURES.AUDIT_LOG).allowed;

  const { sites, loadingSites } = useSite({ dontSelectSite: true });

  const siteList = sites.map((site) => site.name);

  const titlePage = 'Team & Sharing';
  const tabs = ['Manage team', 'Manage sharing'];
  if (canNavigateAuditTrail) {
    tabs.push('Audit Log');
  }

  return (
    <DashboardPage>
      <SEO title={titlePage} />

      <Tab.Group
        defaultIndex={initialTab}
        onChange={(index) => {
          // clear search input on tab change
          window.dispatchEvent(new Event('filters:reset'));
          removeAll([
            'orderActive',
            'sortActive',
            'orderInactive',
            'sortInactive',
            'pageActive',
            'pageInactive',
            'page',
            'sort',
            'order',
            'search',
          ]);
          setSearch('');
          queryParamsSet('tab', index);
        }}
      >
        {({ selectedIndex }) => (
          <>
            <DashHeader
              className="!border-0"
              title={titlePage}
              actionButton={
                <div className="flex items-center space-x-2.5">
                  {selectedIndex !== 2 && (
                    <span>
                      <SearchInputComponent
                        placeholderText={selectedIndex === 0 ? 'Search members' : 'Search share links'}
                        ariaLabel={selectedIndex === 0 ? 'searchMembers' : 'searchShareLinks'}
                        search={search}
                        setSearch={setSearch}
                      />
                    </span>
                  )}
                  {selectedIndex === 0 ? (
                    <Button
                      size="xl"
                      onClick={() => {
                        siteList.length === 0
                          ? modal.show(
                              <AddSiteModal
                                numSites={siteList.length}
                                customTitle="Add a site before adding team members"
                                onExtraSteps={() => modal.show(<InviteUserModal />)}
                              />,
                            )
                          : modal.show(<InviteUserModal />);
                      }}
                    >
                      <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                      Invite members
                    </Button>
                  ) : null}
                </div>
              }
            />

            <Tab.List className="flex border-b border-mystic-500 bg-white px-10">
              {tabs.map((tabTitle) => (
                <Tab
                  key={tabTitle}
                  className={({ selected }) =>
                    classNames(
                      'text-header-4 border-b-2 px-5 pb-3 text-dodger-blue-500 focus:outline-none focus-visible:outline-black',
                      'flex items-center',
                      {
                        'border-dodger-blue-500': selected,
                        'border-transparent': !selected,
                      },
                    )
                  }
                >
                  {tabTitle}
                </Tab>
              ))}
            </Tab.List>

            <div className="my-10 px-10">
              <Tab.Panels>
                <Tab.Panel>
                  <SelectionProvider>
                    <AccountUsersProvider>
                      {loadingSites || !siteList ? null : <AccountUsersTeam siteList={siteList} search={search} />}
                    </AccountUsersProvider>
                  </SelectionProvider>
                </Tab.Panel>
                <Tab.Panel>
                  <SelectionProvider>
                    <AccountUsersSharing search={search} setSearch={setSearch} />
                  </SelectionProvider>
                </Tab.Panel>
                {canNavigateAuditTrail && (
                  <Tab.Panel>
                    <AuditLogDashboard />
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </div>
          </>
        )}
      </Tab.Group>

      <ScrollToTop />
    </DashboardPage>
  );
}
