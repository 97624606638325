/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-element-interactions */
import { createContext, useReducer, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { CloseButton, Button } from '@crazyegginc/hatch';

import { useLockBodyScroll, useModal } from '/src/hooks';
import { MODAL_ACTIONS } from '/src/features/_global/constants';

export const ModalContext = createContext();

const initialState = { component: null, options: {} };

function modalReducer(state, action) {
  switch (action.type) {
    case MODAL_ACTIONS.SHOW:
      return {
        component: { ...action.payload.component },
      };

    case MODAL_ACTIONS.CLOSE:
      return initialState;

    default:
      return state;
  }
}

function ModalCenter({ component }) {
  if (!component) {
    return null;
  }

  return <>{component}</>;
}

export function ModalProvider({ children }) {
  const [modal, modalDispatch] = useReducer(modalReducer, initialState);
  const [modalState] = useState({
    modal,
    modalDispatch,
  });

  return (
    <ModalContext.Provider value={modalState}>
      {children}

      {createPortal(<ModalCenter component={modal.component} />, document.body)}
    </ModalContext.Provider>
  );
}

export function Modal({
  children,
  overlayClassName,
  dialogClassName,
  disableOutsideClick = false,
  dismissable = true,
  dark = false,
  onDismiss = null,
  ...props
}) {
  useLockBodyScroll();
  const modal = useModal();

  return (
    <div
      className={classNames('fixed inset-0 z-[99998] bg-mako-500/60', overlayClassName, {
        'dark ': dark,
      })}
      data-testid="overlay"
      onMouseDown={(e) => {
        e.stopPropagation();
        if (disableOutsideClick) return;
        modal.close();
      }}
    >
      <div
        role="dialog"
        className={classNames(
          'absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col overflow-auto',
          'z-[99999] max-h-[80%] w-[520px] rounded p-7',
          'bg-white dark:bg-woodsmoke-500/90',
          dialogClassName,
        )}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        {dismissable && (
          <CloseButton
            label="close modal"
            onClick={() => {
              onDismiss?.();
              modal.close();
            }}
            theme={dark ? 'dark' : 'light'}
          />
        )}
        {children}
      </div>
    </div>
  );
}

function ModalTitle({ children, className }) {
  return <h2 className={classNames('text-header-3 mb-7 dark:text-white', className)}>{children}</h2>;
}

function ModalActions({ children, className }) {
  return <div className={classNames('mt-7 flex items-center space-x-2.5', className)}>{children}</div>;
}

function ModalCancelButton({ onClick, children, ...props }) {
  const modal = useModal();
  return (
    <Button
      variant="cancel"
      onClick={() => {
        onClick?.();
        modal.close();
      }}
      className="disabled:bg-transparent"
      {...props}
    >
      {children ? children : 'Cancel'}
    </Button>
  );
}

Modal.Title = ModalTitle;
Modal.Actions = ModalActions;
Modal.Cancel = ModalCancelButton;
