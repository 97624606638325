import { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { isBefore, isAfter, addHours, fromUnixTime, formatDistanceToNow } from 'date-fns';

import { usePermissions, useSelectedSite } from '/src/hooks';
import { formatDate } from '/src/utils/date';
import { FEATURES } from '/src/features/_global/constants';
import { DashboardBanner } from '/src/components/DashboardBanner';
import { InstallSuccessAlert } from '/src/components/alerts/InstallSuccessAlert';
import { SupportLinks } from '/src/support';

export function InstallationBanner() {
  const storageItemName = 'ce.dashboards.dismissedInstallAlert';
  const { selectedSite } = useSelectedSite();
  const permissions = usePermissions();
  const canViewInstall = permissions.can('view', FEATURES.INSTALLATION).allowed;
  const [hasDismissedAlerts, setHasDismissedAlerts] = useState(false);

  function dismissAlerts() {
    const array = getArrayFromStorage(storageItemName);
    window.sessionStorage.setItem(storageItemName, JSON.stringify([...array, selectedSite.name]));
    setHasDismissedAlerts(true);
  }

  useLayoutEffect(() => {
    if (getArrayFromStorage(storageItemName).includes(selectedSite?.name)) {
      setHasDismissedAlerts(true);
    } else {
      setHasDismissedAlerts(false);
    }
  }, [selectedSite?.name]);

  if (!canViewInstall || !selectedSite?.name) return null;

  let text;

  // temp code
  return null;
  /* eslint-disable */
  if (selectedSite.installed && selectedSite.lastData) {
    const lastDataTimeStamp = fromUnixTime(selectedSite.lastData);
    if (isBefore(lastDataTimeStamp, addHours(new Date(), -12))) {
      if (isAfter(lastDataTimeStamp, addHours(new Date(), -24))) {
        text = ` for ${formatDistanceToNow(lastDataTimeStamp)}`;
      } else if (isAfter(lastDataTimeStamp, addHours(new Date(), -48))) {
        text = ' since yesterday';
      } else {
        text = ` since ${formatDate(selectedSite.lastData)}`;
      }
      text += '. There could be an installation issue.';
    }
  } else {
    text = '. Please install Crazy Egg.';
  }

  // TODO: don't forget to re-enable e2e tests when this banner is re-enabled
  if (text) {
    if (hasDismissedAlerts) return null;

    return (
      <DashboardBanner title="Check your Crazy Egg installation!" data-testid="install-banner" onClose={dismissAlerts}>
        <div className="flex justify-between">
          We haven&apos;t received any data for {selectedSite.name}
          {text}
          <div className="ml-3.75 flex items-center">
            <Link className="text-link !text-white" to="/install/check-script">
              Check installation
            </Link>
            <div className="mx-2.5 h-1 w-1 rounded-full bg-white" />
            <Link className="text-link !text-white" to="/install">
              Install script
            </Link>
            <div className="mx-2.5 h-1 w-1 rounded-full bg-white" />
            <a
              className="text-link !text-white"
              href={SupportLinks.installation}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </div>
        </div>
      </DashboardBanner>
    );
  }

  return <InstallSuccessAlert selectedSite={selectedSite} />;
}

function getArrayFromStorage(itemName) {
  try {
    const value = JSON.parse(window.sessionStorage.getItem(itemName));
    if (Array.isArray(value)) {
      return value;
    }
  } catch {
    //noop
  }
  return [];
}
