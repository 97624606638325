import { ConditionTypeSelect } from '../selects/ConditionTypeSelect';
import { DeleteRowButton } from './DeleteRowButton';
import { RenderFieldValuesArray } from './RenderFieldValuesArray';

export function EmailConditionTypeRow({ ...props }) {
  return (
    <div className="mb-4 flex flex-col gap-y-1.5 border-b border-dashed border-mystic-500 pb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-1.5">
          {props.conditionIndex > 0 ? 'And' : 'If'} <ConditionTypeSelect {...props} /> is ANY of the following:{' '}
        </div>
        {!props.disabled ? <DeleteRowButton onRemove={props.onRemove} /> : null}
      </div>
      <RenderFieldValuesArray {...props} label="Enter an email" />
    </div>
  );
}
