import { setup, assign, sendTo, enqueueActions } from 'xstate5';
import Honeybadger from '@honeybadger-io/js';
import { getParam, setParam } from '/src/utils/location';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';
import { clamp } from '/src/utils/math';
import { getDevToolEvents } from '/src/features/recordings/components/player/DevTools';

const resettedState = {
  playbackStarted: false,
  ticks: 0,
  devToolsOpen: false,
  recording: null,
  events: [],
  rawEvents: [],
  recordedEvents: [],
  groupedEvents: [],
  devToolsEvents: [],
  devToolsEventsCount: 0,
  seeking: false,
  tabs: [],
  active_tab: 0,
  loading: true,
};

export const playerMachine = setup({
  actions: {
    readHash: assign({
      recordingHash: () => {
        return window.location.pathname.split('/')[2];
      },
    }),
    readState: assign({
      ticks: () => parseInt(getParam('s', 0)) * 1000,
      speed: () => parseFloat(getParam('speed', 1)),
      skipPauses: () => getParam('skip', '0') === '1',
      autoPlay: () => getParam('autoplay', '0') === '1',
      autoStart: () => getParam('autostart', '1') === '1',
    }),
    loadRecording: () => {},
    watchRecording: () => {},
    setCurrentViewedAt: assign({
      playlist: ({ context }) => {
        const { playlist, recordingHash } = context;

        const currentIndex = playlist.findIndex((recording) => recording.hashedId === recordingHash);

        if (currentIndex !== -1) {
          playlist[currentIndex] = {
            ...playlist[currentIndex],
            viewedAt: Math.floor(Date.now() / 1000),
          };
        }

        return [...playlist];
      },
      recording: ({ context }) => {
        return { ...context.recording, viewedAt: Math.floor(Date.now() / 1000) };
      },
    }),
  },
}).createMachine(
  {
    id: 'player',
    initial: 'initialize',
    context: ({ input }) => ({
      playbackStarted: false,
      recordingHash: null,
      autoPlay: false,
      speed: 1,
      skipPauses: false,
      ticks: 0,
      fullScreen: false,
      showVisitorSidebar: true,
      devToolsOpen: false,
      recording: null,
      events: [],
      rawEvents: [],
      recordedEvents: [],
      groupedEvents: [],
      devToolsEvents: [],
      devToolsEventsCount: 0,
      playlist: [],
      seeking: false,
      tabs: [],
      active_tab: 0,
      explicitAgent: input?.explicitAgent ?? false,
      loading: true,
    }),
    states: {
      initialize: {
        always: {
          target: 'initialized.loading',
          actions: ['readHash', 'readState'],
        },
      },
      initialized: {
        initial: 'loading',
        states: {
          active: {
            initial: 'loading',
            invoke: {
              id: 'player',
              src: 'playerLogic',
              input: ({ context }) => ({
                recording: context.recording,
                ticks: context.ticks,
                speed: context.speed,
                skipPauses: context.skipPauses,
                explicitAgent: context.explicitAgent,
              }),
              systemId: 'player',
            },
            states: {
              loading: {
                on: {
                  READY: {
                    actions: [
                      assign(({ event }) => ({
                        duration: event.payload.duration,
                        rawEvents: event.payload.rawEvents,
                        events: event.payload.events,
                        groupedEvents: event.payload.groupedEvents,
                        recordedEvents: event.payload.events.filter(
                          (e) => e.timestamp >= 0 && e.timestamp <= event.payload.duration,
                        ),
                        devToolsEvents: getDevToolEvents(event.payload.rawEvents).filter(
                          (e) => e.timestamp >= 0 && e.timestamp <= event.payload.duration,
                        ),
                      })),
                      assign(({ context }) => ({
                        devToolsEventsCount: context.devToolsEvents.reduce(
                          (sum, event) => sum + event.events.length,
                          0,
                        ),
                      })),
                    ],
                    target: 'ready',
                  },
                },
              },
              ready: {
                always: [
                  {
                    target: 'playing',
                    guard: ({ context }) => context.autoPlay && context.playbackStarted,
                  },
                  {
                    target: 'playing',
                    guard: ({ context }) => context.autoStart && !context.playbackStarted,
                  },
                ],
                on: {
                  PLAY: 'playing',
                  TOGGLE_PLAYING: 'playing',
                },
              },
              playing: {
                entry: [
                  assign({ isPlaying: true, lastAction: 'play', playbackStarted: true }),
                  sendTo('player', { type: 'PLAY' }),
                ],
                on: {
                  PAUSE: 'paused',
                  DONE: 'done',
                  TICK: {
                    actions: [
                      assign({
                        ticks: ({ event }) => {
                          return event.ticks;
                        },
                      }),
                      enqueueActions(({ context, enqueue }) => {
                        if (!context.recording.viewedAt && context.ticks >= 200) {
                          enqueue('setCurrentViewedAt');
                          enqueue('watchRecording');
                        }
                      }),
                    ],
                  },
                  TOGGLE_PLAYING: 'paused',
                },
                after: {
                  500: {
                    actions: [assign({ lastAction: null })],
                  },
                },
              },
              paused: {
                entry: [assign({ isPlaying: false, lastAction: 'pause' }), sendTo('player', { type: 'PAUSE' })],
                on: {
                  PLAY: 'playing',
                  TOGGLE_PLAYING: 'playing',
                },
                after: {
                  500: {
                    actions: [assign({ lastAction: null })],
                  },
                },
              },
              done: {
                entry: [
                  assign({
                    ticks: ({ context }) => context.duration,
                    isPlaying: false,
                    lastAction: null,
                  }),
                  enqueueActions(({ context, enqueue }) => {
                    if (!context.recording.viewedAt) {
                      enqueue('setCurrentViewedAt');
                      enqueue('watchRecording');
                    }
                  }),
                  sendTo('player', { type: 'DONE' }),
                ],
                on: {
                  REPLAY: {
                    actions: [
                      assign({
                        ticks: 0,
                      }),
                      sendTo('player', { type: 'SEEK', ticks: 0 }),
                    ],
                    target: 'playing',
                  },
                  TOGGLE_PLAYING: {
                    target: 'playing',
                    actions: [
                      assign({
                        ticks: 0,
                      }),
                      sendTo('player', { type: 'SEEK', ticks: 0 }),
                    ],
                  },
                  SEEK: [
                    {
                      target: 'paused',
                      actions: [
                        assign({
                          ticks: ({ context, event }) => clamp(event.ticks, 0, context.duration),
                        }),
                        sendTo('player', ({ context }) => ({ type: 'SEEK', ticks: context.ticks })),
                      ],
                      guard: ({ context, event }) => clamp(event.ticks, 0, context.duration) < context.duration,
                    },
                    {
                      actions: [
                        assign({
                          ticks: ({ context }) => context.duration,
                        }),
                        sendTo('player', ({ context }) => ({ type: 'SEEK', ticks: context.duration })),
                      ],
                    },
                  ],
                },
              },
            },
            on: {
              DRAG: {
                actions: [
                  assign({
                    dragging: true,
                  }),
                  enqueueActions(({ context, enqueue }) => {
                    if (context.isPlaying) {
                      enqueue.sendTo('player', { type: 'PAUSE' });
                    }
                  }),
                ],
              },
              SEEK: [
                {
                  target: 'active.done',
                  actions: [
                    assign({
                      ticks: ({ context }) => context.duration,
                    }),
                    sendTo('player', ({ context }) => ({ type: 'SEEK', ticks: context.duration })),
                  ],
                  guard: ({ context, event }) => clamp(event.ticks, 0, context.duration) >= context.duration,
                },
                {
                  actions: [
                    assign({
                      ticks: ({ context, event }) => clamp(event.ticks, 0, context.duration),
                    }),
                    sendTo('player', ({ context }) => ({ type: 'SEEK', ticks: context.ticks })),
                  ],
                },
              ],
              DROP: {
                actions: [
                  assign({
                    dragging: false,
                  }),
                  enqueueActions(({ context, enqueue }) => {
                    if (context.isPlaying) {
                      enqueue.sendTo('player', { type: 'PLAY' });
                    }
                  }),
                ],
              },
              SET_SPEED: {
                actions: [
                  assign({
                    speed: ({ event }) => event.speed,
                  }),
                  sendTo('player', ({ event }) => ({ type: 'SET_SPEED', speed: event.speed })),
                  ({ event }) => setParam('speed', event.speed),
                ],
              },
              TOGGLE_SKIP_PAUSES: {
                actions: [
                  assign({
                    skipPauses: ({ context }) => !context.skipPauses,
                  }),
                  sendTo('player', ({ context }) => ({ type: 'SET_SKIP_PAUSES', skipPauses: context.skipPauses })),
                  ({ context }) => setParam('skip', context.skipPauses ? '1' : '0'),
                ],
              },
              TOGGLE_AUTO_PLAY: {
                actions: [
                  assign({
                    autoPlay: ({ context }) => !context.autoPlay,
                  }),
                  ({ context }) => setParam('autoplay', context.autoPlay ? '1' : '0'),
                ],
              },
              SET_TABS: {
                actions: [
                  assign({
                    active_tab: ({ event }) => event.active_tab,
                    tabs: ({ event }) => event.tabs,
                  }),
                ],
              },
              SET_PLAYLIST: {
                actions: [
                  assign({
                    playlist: ({ event }) => event.playlist,
                    nextRecording: ({ context, event }) => getNextRecording(event.playlist, context.recordingHash),
                  }),
                ],
              },
              TOGGLE_DEVTOOLS: {
                actions: [
                  assign({
                    showDevtools: ({ context }) => !context.showDevtools,
                  }),
                  ({ context }) => {
                    if (context.showDevtools) {
                      window.Mocky?.turnOff();
                      if (window.Metrics) {
                        window.Metrics.used('viewed dev tools');
                      }
                    }
                  },
                ],
              },
              SHARE: {
                actions: [
                  ({ context }) => {
                    window.Mocky?.turnOff();
                    window.SharingModal.show({
                      entity: { id: parseInt(context.recording.id), type: SHARABLE_RESOURCE_TYPES.RECORDING },
                      dark: true,
                    });
                  },
                  sendTo('player', { type: 'PAUSE' }),
                ],
              },
              DELETE: {
                actions: [
                  () => {
                    window.Mocky?.turnOff();
                  },
                  sendTo('player', { type: 'PAUSE' }),
                ],
              },
              TOGGLE_FULL_SCREEN: {
                actions: [
                  assign({
                    fullScreen: ({ context }) => !context.fullScreen,
                  }),
                ],
              },
              TOGGLE_VISITOR_SIDEBAR: {
                actions: [
                  assign({
                    showVisitorSidebar: ({ context }) => !context.showVisitorSidebar,
                  }),
                ],
              },
              PLAYER_LOADING: {
                actions: [
                  assign({
                    loading: ({ event }) => event.percentage !== 100,
                  }),
                ],
              },
            },
          },
          loading: {
            id: 'loadRecording',
            entry: ['readHash'],
            invoke: {
              id: 'getRecording',
              src: 'loadRecording',
              onDone: [
                {
                  target: 'error.notFound',
                  // https://app.shortcut.com/crazyegg/story/24729/ignore-v1-recordings-player
                  guard: ({ event }) => event.output?.version === 1,
                },
                {
                  target: 'active.loading',
                  actions: assign({
                    recording: ({ event }) => event.output,
                    nextRecording: ({ context }) => {
                      if (context.playlist.length) {
                        return getNextRecording(context.playlist, context.recordingHash);
                      }
                    },
                  }),
                },
              ],
              onError: [
                {
                  target: 'error.notFound',
                  guard: ({ event }) =>
                    event.error === 'recording not found' ||
                    event.error.match(/Subscription issue with the account of this resource/),
                },
              ],
            },
          },
          error: {
            id: 'error',
            entry: () => {
              window.Mocky?.stop();
            },
            initial: 'general',
            states: {
              general: {
                type: 'final',
              },
              notFound: {
                type: 'final',
              },
              noStates: {
                type: 'final',
              },
            },
            on: {
              DELETE: 'deleting',
              NEXT: 'loading',
            },
          },
          deleting: {
            on: {
              SUCCESS: [
                {
                  target: 'loading',
                  guard: 'hasNextRecording',
                },
              ],
            },
          },
        },
        on: {
          CHANGE_RECORDING: {
            target: '#loadRecording',
            actions: [assign(resettedState)],
          },

          PLAYER_ERROR: [
            {
              // no-op
              guard: ({ event }) => ['asset', 'page-state'].includes(event.error.resource),
            },
            {
              target: '#error.noStates',
              guard: ({ event }) => event.error.resource === 'no-page-states',
            },
            {
              target: '#error.general',
              actions: [
                ({ event }) => {
                  Honeybadger.notify('Unknown player error', {
                    context: event.error,
                  });

                  try {
                    window.CE2?.notifyError?.(event.error, 'unknown-player-error');
                  } catch {
                    console.error('Could not report error');
                  }
                },
              ],
            },
          ],
        },
      },
    },
  },
  {
    guards: {
      hasNextRecording: (ctx) => {
        if (
          ctx.recording &&
          ctx.playlist.length > 0 &&
          ctx.playlist.findIndex((r) => r.id === ctx.recording.id) < ctx.playlist.length - 1
        ) {
          return true;
        }
      },
    },
  },
);

function getNextRecording(playlist, currentHash) {
  if (playlist.length === 0) return null;

  const currentIndex = playlist.findIndex((recording) => recording.hashedId === currentHash);

  if (currentIndex >= 0 && currentIndex < playlist.length) {
    const nextRecording = playlist?.[currentIndex + 1];

    if (nextRecording?.hashedId && nextRecording.hashedId !== currentHash) {
      return nextRecording;
    }
  }

  return null;
}
