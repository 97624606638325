import { useRef, useState, useMemo, Fragment, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Button, Tooltip, ReactTable, IconButton } from '@crazyegginc/hatch';

import { useQueryParams } from '/src/hooks';
import { camelToSnake } from '/src/utils/string';
import { hudMetric } from '/src/utils/metrics';

import { SORT_ORDER_TYPES, LOGICALS } from '/src/features/_global/constants';
import { RECORDINGS_FILTER_VERSION } from '/src/features/recordings/constants';
import { trafficAnalysisUrlsQuery } from '/src/features/traffic-analysis/queries';

import { getOptionDisplayValue, printParamCombination } from '../utils';

import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as MenuIcon } from '@crazyegginc/hatch/dist/images/icon-menu-dots.svg';
import { ReactComponent as HeatmapIcon } from '@crazyegginc/hatch/dist/images/icon-heat-filled.svg';
import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

export function TATable({ comparisons, removeFromComparison, convertedDateRange, selectedSite, resetComparison }) {
  const { setAll: queryParamsSetAll, get: queryParamsGet } = useQueryParams();
  const [order, setOrder] = useState({
    filterIndex: parseInt(queryParamsGet('order')) || 0,
    sort: [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC].includes(queryParamsGet('sort'))
      ? queryParamsGet('sort')
      : SORT_ORDER_TYPES.DESC,
  });

  const filters = useMemo(
    () =>
      comparisons.map((x) => {
        const transformed = {};
        Object.keys(x).forEach((key) => {
          if (x[key].length > 0) {
            transformed[key] = x[key].map((y) => y.value);
          }
        });
        return transformed;
      }),
    [comparisons],
  );

  const { data, isLoading } = useQuery({
    ...trafficAnalysisUrlsQuery({
      siteId: selectedSite?.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
      filters: filters,
      order: order,
    }),
    enabled: Boolean(selectedSite?.id && filters.length),
  });

  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current?.scrollIntoView();
  }, [filters]);

  const columns = useMemo(() => {
    function generateFilterUrl(comparison, page) {
      const conditions = [
        {
          criteria: 'date_range',
          value: {
            start_date: convertedDateRange.startDate,
            end_date: convertedDateRange.endDate,
          },
        },
        ...Object.keys(comparison)
          .filter((key) => comparison[key].length > 0)
          .map((key) => ({
            criteria: key === 'referrerUrl' ? 'referrer' : camelToSnake(key),
            comparison: key === 'referrerUrl' ? 'matches' : 'is',
            values: comparison[key].map((x) =>
              x.value === '' ? '---' : key === 'referrerUrl' ? `//${x.value}` : x.value,
            ),
          })),
      ];

      if (page !== 'All') {
        conditions.push({
          criteria: 'landing_page',
          comparison: 'is',
          multiple_values: 'or',
          values: [page],
        });
      }

      return {
        version: RECORDINGS_FILTER_VERSION,
        operator: LOGICALS.AND,
        conditions,
      };
    }

    const regex = new RegExp(`^${selectedSite?.name}`);
    const cols = [
      {
        header: 'Landing Page',
        accessorKey: 'url',
        enableSorting: false,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => {
          return <div className="line-clamp-2">{row.original.url.replace(regex, '')}</div>;
        },
      },
      {
        header: () => <div className="pl-4">Total recorded sessions</div>,
        accessorKey: 'total',
        enableSorting: false,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => {
          return (
            <div className="flex w-full max-w-[265px] items-center justify-between px-4">
              <div className="pr-4">{row.original.total.toLocaleString()} sessions</div>
              <div className="flex shrink-0 items-center">
                <Tooltip tooltipContent="View Recordings">
                  <IconButton
                    component={Link}
                    to={{
                      pathname: '/recordings',
                      search: `?filters=${JSON.stringify(generateFilterUrl({}, row.original.url))}`,
                    }}
                    icon={<RecordingIcon className="h-4 w-4 fill-current text-malibu-500" />}
                    label="link to recordings"
                  />
                </Tooltip>

                {row.original.url !== 'All' ? (
                  <div className="ml-1.5">
                    <Tooltip tooltipContent="View Heatmap">
                      <IconButton
                        onClick={() => {
                          hudMetric('adat_filter', 'loading_started');
                          window.HudLauncher.open(`http://${row.original.url}`, {
                            adat: generateFilterUrl({}, row.original.url),
                          });
                        }}
                        icon={<HeatmapIcon className="h-4 w-4 fill-current text-malibu-500" />}
                        label="link to heatmap"
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="ml-1.5 w-6" />
                )}
              </div>
            </div>
          );
        },
      },
    ];

    comparisons.forEach((comparison, index) => {
      let headerString = '';
      if (Object.values(comparison).every((v) => v.length === 1 && v[0].value === '')) {
        headerString = 'Direct traffic';
      } else {
        const UTMparams = Object.values(comparison).filter((y) => y.length > 0);
        UTMparams.forEach((param, i) => {
          if (i !== 0) {
            headerString += ' + ';
          }
          param.forEach((value, j) => {
            if (j === param.length - 1) {
              headerString += `${getOptionDisplayValue(value)}`;
            } else {
              headerString += `${getOptionDisplayValue(value)}, `;
            }
          });
        });
      }
      cols.push({
        header: ({ column }) => {
          return (
            <div
              className={classNames('min-w-0 pl-4')}
              style={{ maxWidth: column.getIsSorted() ? 'calc(100% - 50px)' : 'calc(100% - 40px)' }}
            >
              <Tooltip tooltipContent={<div className="max-w-xs">{printParamCombination(comparison)}</div>}>
                <div className="truncate">{headerString}</div>
              </Tooltip>
              <TableColumnMenu
                items={[
                  {
                    label: 'Remove',
                    onClick: (e) => {
                      e.stopPropagation();
                      removeFromComparison(index);
                    },
                  },
                ]}
              />
            </div>
          );
        },
        accessorKey: index.toString(),
        sortDescFirst: true,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => {
          return (
            <div className="flex w-full max-w-[265px] items-center justify-between px-4">
              <div className="pr-4">{row.original.filtersTotals[index].toLocaleString()} sessions</div>
              {row.original.filtersTotals[index] !== 0 ? (
                <div className="flex shrink-0 items-center">
                  <Tooltip tooltipContent="View Recordings">
                    <IconButton
                      component={Link}
                      to={{
                        pathname: '/recordings',
                        search: `?filters=${JSON.stringify(generateFilterUrl(comparison, row.original.url))}`,
                      }}
                      icon={<RecordingIcon className="h-4 w-4 fill-current text-malibu-500" />}
                      label="link to recordings"
                    />
                  </Tooltip>

                  {row.original.url !== 'All' ? (
                    <div className="ml-1.5">
                      <Tooltip tooltipContent="View Heatmap">
                        <IconButton
                          onClick={() => {
                            hudMetric('adat_filter', 'loading_started');
                            window.HudLauncher.open(`http://${row.original.url}`, {
                              adat: generateFilterUrl(comparison, row.original.url),
                            });
                          }}
                          icon={<HeatmapIcon className="h-4 w-4 fill-current text-malibu-500" />}
                          label="link to heatmap"
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="ml-1.5 w-6" />
                  )}
                </div>
              ) : (
                <div className="flex items-center">
                  <RecordingIcon className="h-4 w-4 fill-current text-cadet-blue-500" aria-label="link to recordings" />
                  <HeatmapIcon
                    className="ml-3.5 mr-1 h-4 w-4 fill-current text-cadet-blue-500"
                    aria-label="link to heatmap"
                  />
                </div>
              )}
            </div>
          );
        },
      });
    });

    return cols;
  }, [comparisons, removeFromComparison, convertedDateRange, selectedSite?.name]);

  const onFetchData = useCallback(
    ({ sorting = null }) => {
      if (sorting) {
        setOrder({ filterIndex: +sorting[0].id, sort: sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC });
        queryParamsSetAll({
          order: sorting[0].id,
          sort: sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC,
        });
      }
    },
    [setOrder, queryParamsSetAll],
  );

  const tableSortData = useMemo(() => {
    return {
      id: order.filterIndex.toString(),
      desc: order.sort === SORT_ORDER_TYPES.DESC,
    };
  }, [order.filterIndex, order.sort]);

  if (filters.length === 0) return null;

  return (
    <div className="mt-14">
      <div className="mb-3.5 flex items-center">
        <div className="text-header-3 mr-3.75">Compare traffic</div>
        <Button variant="secondary" onClick={resetComparison}>
          <CloseIcon className="mr-2.5 h-3 w-3 fill-current" />
          Clear all
        </Button>
      </div>
      <ReactTable
        fetching={isLoading}
        ref={{ tableRef }}
        rowPadding={true}
        columns={columns}
        data={data?.trafficAnalysisUrls ?? []}
        onFetchData={onFetchData}
        sorting={tableSortData}
        useVirtualization={true}
        rowCustomClassname={(row) =>
          classNames('divide-x divide-mystic-500', {
            'border-solid': row?.original?.url === 'All',
          })
        }
      />
    </div>
  );
}

function TableColumnMenu({ items }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [menuElement, setMenuElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, menuElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper }) => {
              return [-popper.width / 2 + 20, 10];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Menu as="div" className="absolute right-5 top-1/2 -translate-y-1/2">
      {({ open }) => (
        <>
          <div ref={setReferenceElement}>
            {/* dummy div to stop table header from sorting when closing menu */}
            {/* eslint-disable-next-line */}
            <div onClick={(e) => e.stopPropagation()}>
              <Menu.Button
                as={Button}
                variant="secondary"
                size="lg"
                className={classNames('ml-3.75 !h-[25px] !w-5 !justify-center !px-0', {
                  '!border-dodger-blue-500': open,
                })}
              >
                <MenuIcon className="h-4 w-4 fill-current" />
              </Menu.Button>
            </div>
          </div>
          {open &&
            createPortal(
              <div ref={setMenuElement} style={styles.popper} {...attributes.popper}>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Menu.Items
                    className="w-40 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    static
                  >
                    <div className="px-2 py-2 ">
                      {items.map((item) => (
                        <Menu.Item key={item.label}>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? 'text-body-1 bg-solitude-500' : 'text-body-2 bg-white'
                              } flex w-full items-center rounded px-2 py-2`}
                              onClick={item.onClick}
                            >
                              {item.label}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </div>,
              document.querySelector('body'),
            )}
        </>
      )}
    </Menu>
  );
}
