import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useQueryParams } from '/src/hooks';
import { getParam, deleteParam } from '/src/utils/location';
import { PER_PAGE_OPTIONS, SNAPSHOT_FILTER_STATUSES, SNAPSHOTS_TABLE_ORDER } from '/src/features/snapshots/constants';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';

const RUNNING = 'RUNNING';
const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
};
const { SET, RESET } = FILTER_ACTIONS;

const FilterContext = createContext();

const getInitialValue = (name, value, possibleValues) => {
  if (possibleValues.includes(value)) {
    return value;
  } else {
    deleteParam(name);
    return null;
  }
};

const defaultState = {
  page: 1,
  order: SNAPSHOTS_TABLE_ORDER.STARTS_AT,
  sort: SORT_ORDER_TYPES.DESC,
  search: undefined,
  status: SNAPSHOT_FILTER_STATUSES.ALL,
  folderId: undefined,
};

// keep this as a function to delay evaluation while the script loads
const initialState = () => {
  return {
    page: parseInt(getParam('page')) || defaultState.page,
    order: getInitialValue('order', getParam('order'), [...Object.values(SNAPSHOTS_TABLE_ORDER)]) || defaultState.order,
    sort: getInitialValue('sort', getParam('sort'), [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || defaultState.sort,
    search: getParam('search') || defaultState.search,
    status:
      getInitialValue(
        'status',
        getParam('status')?.toUpperCase()?.replace(RUNNING, SNAPSHOT_FILTER_STATUSES.NOT_COMPLETED),
        [...Object.values(SNAPSHOT_FILTER_STATUSES)],
      ) || defaultState.status,
    folderId: getParam('folderId') || defaultState.folderId,
    perPage: getInitialValue('perPage', parseInt(getParam('perPage', null, null, 'snapshot')), PER_PAGE_OPTIONS) || 25,
  };
};

export function FilterProvider({ children }) {
  function filterReducer(state = initialState(), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case RESET:
        return {
          ...state,
          ...defaultState,
        };
      default:
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, initialState());
  const [filterState, setFilterState] = useState({ filter, filterDispatch });
  if (!isEqual(filter, filterState.filter)) {
    setFilterState({ filter, filterDispatch });
  }
  return <FilterContext.Provider value={filterState}>{children}</FilterContext.Provider>;
}

export function useFilter() {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filter, filterDispatch } = useContext(FilterContext);

  function set(key, value) {
    filterDispatch({
      type: SET,
      payload: {
        [key]: value,
      },
    });
  }

  const setPage = useCallback(
    (page) => {
      queryParamsSet('page', page);
      filterDispatch({
        type: SET,
        payload: {
          page,
        },
      });
      window.scrollTo(0, 0);
    },
    [filterDispatch, queryParamsSet],
  );

  const setFolder = useCallback(
    (folder) => {
      setPage(1);
      let folderId;
      if (folder === null) {
        folderId = 'unfiled';
      } else {
        folderId = folder?.id;
      }
      queryParamsSet('folderId', folderId);

      filterDispatch({
        type: SET,
        payload: {
          folder,
          folderId,
        },
      });
    },
    [filterDispatch, setPage, queryParamsSet],
  );

  function setStatus(status) {
    if (status === SNAPSHOT_FILTER_STATUSES.NOT_COMPLETED) {
      queryParamsSet('status', RUNNING);
    } else {
      queryParamsSet('status', status, true);
    }
    setPage(1);
    filterDispatch({
      type: SET,
      payload: {
        status,
      },
    });
  }

  const search = useCallback(
    (value) => {
      setPage(1);
      queryParamsSet('search', value);
      filterDispatch({
        type: SET,
        payload: {
          search: value,
        },
      });
    },
    [queryParamsSet, filterDispatch, setPage],
  );

  function setPerPage(perPage) {
    filter.page !== 1 && setPage(1);
    queryParamsSet('perPage', perPage, true, 'snapshot');
    filterDispatch({
      type: SET,
      payload: {
        perPage,
      },
    });
  }

  const setSort = useCallback(
    (sort) => {
      queryParamsSet('sort', sort);
      filter.page !== 1 && setPage(1);
      filterDispatch({
        type: SET,
        payload: {
          sort,
        },
      });
    },
    [filter.page, setPage, queryParamsSet, filterDispatch],
  );

  const setOrder = useCallback(
    (order) => {
      queryParamsSet('order', order);
      filter.page !== 1 && setPage(1);
      filterDispatch({
        type: SET,
        payload: {
          order,
        },
      });
    },
    [filter.page, setPage, queryParamsSet, filterDispatch],
  );

  function reset() {
    removeAll(['order', 'sort', 'status', 'page', 'folderId']);
    filterDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  const { order, sort, ...filterObj } = filter;

  return {
    filter: filterObj,
    order: { field: order, sort },
    set,
    reset,
    search,
    setFolder,
    setStatus,
    setSort,
    setOrder,
    setPage,
    setPerPage,
  };
}
