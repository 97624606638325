import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Section } from '../Section';

import { ReactComponent as SuggestionIcon } from '@crazyegginc/hatch/dist/images/icon-lightbulb-on-outline.svg';
import { ReactComponent as SnapshotIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function SuggestionsSection() {
  return (
    <Section className="col-end-4 row-span-2 pl-10">
      <Section.Header icon={SuggestionIcon} color="bg-dandelion-500">
        <span>Suggestions</span>
      </Section.Header>
      <Section.Body className="mb-5 !grow-0">
        <div>
          <div
            className={classNames(
              'mr-3 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-lynch-500',
            )}
          >
            <SnapshotIcon className="h-3 w-3 fill-current text-white" />
          </div>
          One of your top landing pages <span className="rounded-sm bg-white-lilac-500 px-1">/pricing</span> does not
          have a snapshot. <Link className="text-link">Let&apos;s create one!</Link>
        </div>
      </Section.Body>
      <Section.Body className="mb-5 !grow-0">
        <div>
          <div
            className={classNames(
              'mr-3 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-wedgewood-500',
            )}
          >
            <SurveyIcon className="h-3 w-3 fill-current text-white" />
          </div>
          You have a lot of visitors from Direct traffic. How did they find your site?{' '}
          <Link className="text-link">Let&apos;s create a survey and ask them!</Link>
        </div>
      </Section.Body>
      <Section.Body className="mb-5 !grow-0">
        <div>
          <div
            className={classNames(
              'mr-3 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-carnation-500',
            )}
          >
            <WarningIcon className="h-3 w-3 fill-current text-white" />
          </div>
          We haven&apos;t received any events for this site for the last 18 hours. This is unusal based on its
          historical traffic pattern.
          <br /> Let&apos;s <Link className="text-link">check the installation</Link> of Crazy Egg on your site.
        </div>
      </Section.Body>
    </Section>
  );
}
