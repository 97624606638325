import { msToHumanDuration } from '/src/utils/date';
import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';
import { MainTextRow, SubTextRow, Bull } from '/src/features/live-activity/components/ui';
import { Identity } from '../EventCard';

export function FormEvent({ liveEvent, identifyEvents }) {
  const { form_name, form_id, form_action, time_spent, sub_event_type } = liveEvent.attributes;

  const form = (typeof form_name === 'string' ? form_name : '') || form_id;

  let action;
  if (sub_event_type === 1) action = 'completed';
  else if (sub_event_type === 2) action = 'resubmitted';
  else if (sub_event_type === 3) action = 'abandoned';

  return (
    <div>
      <MainTextRow>
        <Identity id={liveEvent.visitorId} identifyEvents={identifyEvents} />
        <div className="max-w-lg">
          <div className="flex items-center">
            <span className="shrink-0 whitespace-pre">
              {action} {form ? '' : 'a'} form{' '}
            </span>
            <TruncateWithTooltip text={form} className="text-body-1 mr-8" />
          </div>
        </div>
      </MainTextRow>
      <SubTextRow>
        {form_action && <TruncateWithTooltip text={`to ${form_action}`} className="max-w-lg" />}
        {form_action && time_spent && <Bull />}
        {time_spent && <>after {msToHumanDuration(time_spent)}</>}
      </SubTextRow>
    </div>
  );
}
