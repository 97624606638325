import React from 'react';

import { AuthProvider } from '/src/machines/auth';
import { AppProvider } from '/src/contexts/app';
import { CrazyAIProvider } from '/src/contexts/CrazyAI';
import { Router } from './Router';

// eslint-disable-next-line no-restricted-syntax
export default function App({ client }) {
  return (
    <React.StrictMode>
      <CrazyAIProvider>
        <AppProvider client={client}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </AppProvider>
      </CrazyAIProvider>
    </React.StrictMode>
  );
}
