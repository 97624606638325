export const SupportLinks = {
  general: {
    helpCenter: 'https://support.crazyegg.com/hc/en-us',
    email: 'support@crazyegg.com',
    newSupportRequest: 'https://auth.app.crazyegg.com/zendesk/signin?return_to=%2Frequests%2Fnew',
    profileSetting: 'https://auth.app.crazyegg.com/v2/settings',
  },
  abTests: {
    multiArmBanditMethod:
      'https://support.crazyegg.com/hc/en-us/articles/360056916433-Crazy-Egg-A-B-Tests-Guide-to-Multi-Armed-Bandit-Testing',
  },
  install: {
    manual: 'https://help.crazyegg.com/article/43-crazy-egg-manual-installation',
    drupal7: 'https://help.crazyegg.com/article/44-drupal-7-crazy-egg-installation',
    drupal8: 'https://help.crazyegg.com/article/45-drupal-8-crazy-egg-installation',
    gtm: 'https://help.crazyegg.com/article/49-google-tag-manager-crazy-egg-installation',
    joomla: 'https://help.crazyegg.com/article/48-joomla-crazy-egg-installation',
    magento1: 'https://help.crazyegg.com/article/46-magento-1-crazy-egg-installation',
    magento2: 'https://help.crazyegg.com/article/47-magento-2-crazy-egg-installation',
    shopify: 'https://help.crazyegg.com/article/187-shopify-installation-guide',
    squarespace: 'https://help.crazyegg.com/article/52-squarespace-crazy-egg-installation',
    wix: 'https://help.crazyegg.com/article/53-wix-crazy-egg-installation',
    wordpress: 'https://help.crazyegg.com/article/50-wordpress-crazy-egg-installation',
  },
  scriptCheck: {
    manual:
      'https://support.crazyegg.com/hc/en-us/articles/360054296114-Manually-Check-your-Tracking-Script-Installation',
    malformedScript: 'https://help.crazyegg.com/article/361-error-malformed-script',
    missingScript: 'https://help.crazyegg.com/article/362-error-missing-tracking-script',
    contentSecurityPolicy: 'https://help.crazyegg.com/article/363-error-content-security-policy',
    duplicateScript: 'https://help.crazyegg.com/article/302-error-duplicate-tracking-script',
    cacheScript: 'https://help.crazyegg.com/article/364-warning-cache-script',
    multipleSnapshots: 'https://help.crazyegg.com/article/56-multiple-snapshots-for-same-page-error',
    trackByName: 'https://help.crazyegg.com/article/365-error-track-by-name',
    noData: 'https://support.crazyegg.com/hc/en-us/requests/new?ticket_form_id=360006373893',
  },
  sessions: {
    visitorIdentifier: 'https://support.crazyegg.com/hc/en-us/articles/1500001716641-Visitor-Identifier',
  },
  errors: {
    appVersionTracking: 'https://support.crazyegg.com/hc/en-us/articles/360063398213-Errors-App-Version-Tracking',
    crossBrowserTracking: 'https://support.crazyegg.com/hc/en-us/articles/1500003127422-Errors-Cross-Browser-Tracking',
  },
  recordings: {
    autoGeneratedTags: 'https://support.crazyegg.com/hc/en-us/articles/1500000314802-Auto-Generated-Tags',
    monthlyRecordingsSampling:
      'https://support.crazyegg.com/hc/en-us/articles/360056085553-How-to-Customize-Your-User-Recordings-Sampling-Rate',
  },
  sso: 'https://support.crazyegg.com/hc/en-us/articles/4402334175891-Single-Sign-On',
  script: {
    ce2Functions: 'https://support.crazyegg.com/hc/en-us/articles/360054584474-Custom-User-Variables',
  },
  snapshots: {
    trackByName: 'https://help.crazyegg.com/articles/46-track-by-name',
    wildcardRegex:
      'https://support.crazyegg.com/hc/en-us/articles/360054584434-Advanced-Tracking-Wildcard-or-Regular-Expression',
    blockPopUpsOmitSpecificElements:
      'https://support.crazyegg.com/hc/en-us/articles/360056085493-Blocking-Modals-or-Pop-ups',
    usingPageCamera: 'https://support.crazyegg.com/hc/en-us/articles/360055618733-Using-Page-Camera',
    usingPageCameraForCheckout: 'https://support.crazyegg.com/hc/en-us/articles/8219376022547',
    recurringSnapshot: 'https://support.crazyegg.com/hc/en-us/articles/8485490028307',
    error: 'https://support.crazyegg.com/hc/en-us/requests/new?ticket_form_id=360005127774',
  },
  installation: 'https://support.crazyegg.com/hc/en-us/categories/360005656013-Installation-Information',
  survey: {
    minimumContrast: 'https://support.crazyegg.com/hc/en-us/articles/4458220493203',
    advancedTrigger: 'https://support.crazyegg.com/hc/en-us/articles/7409561627539',
    zeroViews: 'https://support.crazyegg.com/hc/en-us/articles/22012480127251',
    nps: 'https://support.crazyegg.com/hc/en-us/articles/4576733437075-Interpreting-NPS-results',
  },
  siteSettings: {
    sessionsTriggeredByJS:
      'https://support.crazyegg.com/hc/en-us/articles/20874136744339-Track-by-Name-for-Video-Recordings',
    maskingElement:
      'https://support.crazyegg.com/hc/en-us/articles/360056409073-User-Privacy-Masking-Elements-in-Recordings',
  },
};
