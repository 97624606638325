import { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Popover, StyledPopoverPanel, PopoverItem, Spinner, Indicator, Tooltip } from '@crazyegginc/hatch';

import { AppConfig } from '/src/AppConfig';
import { useSnapshotActions } from '../../snapshot-actions-context';
import { usePermissions } from '/src/hooks';
import { snapshotExportsQuery, foldersQuery } from '/src/features/snapshots/queries';
import { getSessionQueryParameters } from '/src/utils/url';
import { FEATURES, ROOT_ELEMENT_ID } from '/src/features/_global/constants';
import { SNAPSHOT_EXPORT_STATUSES, SNAPSHOT_STATUSES } from '/src/features/snapshots/constants';

import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as TriangleIcon } from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg';
import { ReactComponent as ChartIcon } from '@crazyegginc/hatch/dist/images/icon-chart-filled.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as RefreshIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-images.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as PauseIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as ExportIcon } from '@crazyegginc/hatch/dist/images/icon-download-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as FolderIcon } from '@crazyegginc/hatch/dist/images/icon-folder-outline.svg';

export function TableRowActionsDropdown({ entityId, entity, setPollExports }) {
  const permissions = usePermissions();
  const actions = useSnapshotActions();
  const location = useLocation();
  const [mutationLoading, setMutationLoading] = useState(false);

  const { data: exportsData, isFetching: fetchingExports } = useQuery(snapshotExportsQuery());
  const { data: foldersData, isLoading: fetchingFolders } = useQuery(foldersQuery({ siteId: entity.siteId ?? null }));

  const isExporting =
    exportsData?.snapshotExports?.find(
      (snapshotExport) =>
        snapshotExport.id === parseInt(entityId) && snapshotExport.status === SNAPSHOT_EXPORT_STATUSES.EXPORTING,
    ) ?? false;

  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  const folders = foldersData?.folders?.list ?? [];

  const canEditSnapshots = permissions.can('edit', FEATURES.SNAPSHOTS).allowed;

  const canView = permissions.can('view', entity).allowed;
  const canEdit = permissions.can('edit', entity).allowed;
  const canRefresh = permissions.can('refresh', entity).allowed;
  const canDuplicate = permissions.can('duplicate', entity).allowed;
  const canResume = permissions.can('resume', entity).allowed;
  const canPause = permissions.can('pause', entity).allowed;
  const canExport = permissions.can('export', entity).allowed;

  const isError = entity.snapshotStatus === SNAPSHOT_STATUSES.ERROR;
  const isAbTestSnapshot = entity.isAbTestVariant;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              className={classNames(
                'flex h-10 items-center rounded px-4 text-dodger-blue-300 focus:outline-none focus-visible:outline-black',
                {
                  'border border-mystic-500 bg-white shadow-md': open,
                },
              )}
            >
              <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
              <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                ref={setTooltipElement}
                onMouseDown={(e) => e.stopPropagation()}
                className="w-60"
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {({ close }) => (
                  <>
                    <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />
                    {mutationLoading && (
                      <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-mystic-500/30">
                        <Spinner className="mr-5 h-7 w-7 text-lynch-500" />
                      </div>
                    )}
                    {canView && !isError && (
                      <Item
                        component="a"
                        href={`${AppConfig.legacyCoreBaseURL()}/snapshots/${entityId}?${getSessionQueryParameters()}`}
                        target="_blank"
                        icon={ChartIcon}
                      >
                        View results
                      </Item>
                    )}
                    {canEditSnapshots && (
                      <>
                        <Tooltip
                          show={!canEdit && isAbTestSnapshot}
                          tooltipContent="This snapshot is linked to an A/B test so it can not be edited."
                        >
                          <Item
                            component={!canEdit ? 'button' : Link}
                            to={{
                              pathname: `/snapshots/edit/${entityId}`,
                            }}
                            state={{
                              snapshot: {
                                ...entity,
                              },
                              returnLocation: `${location.pathname}${location.search}`,
                            }}
                            icon={EditIcon}
                            disabled={!canEdit}
                          >
                            Edit this Snapshot
                          </Item>
                        </Tooltip>
                        {canRefresh && (
                          <Item
                            onClick={() => {
                              setMutationLoading(true);
                              actions.refresh(entityId, {
                                onSettled: () => {
                                  setMutationLoading(false);
                                  close();
                                },
                              });
                            }}
                            icon={RefreshIcon}
                          >
                            Refresh this Snapshot
                          </Item>
                        )}
                        {(canDuplicate || isAbTestSnapshot) && (
                          <Tooltip
                            show={!canDuplicate && isAbTestSnapshot}
                            tooltipContent="This snapshot is linked to an A/B test so it can not be duplicated."
                          >
                            <Item
                              onClick={() => {
                                setMutationLoading(true);
                                actions.duplicate(entityId, {
                                  onSuccess: () => {
                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                  },
                                  onSettled: () => {
                                    setMutationLoading(false);
                                    close();
                                  },
                                });
                              }}
                              disabled={isAbTestSnapshot}
                              icon={DuplicateIcon}
                            >
                              Duplicate this Snapshot
                            </Item>
                          </Tooltip>
                        )}
                        <Item
                          onClick={() => {
                            actions.share(entityId);
                            close();
                          }}
                          disabled={!canView || isError}
                          icon={ShareIcon}
                        >
                          Share this Snapshot
                        </Item>
                        {entity.status === 'stopped' && (canResume || isAbTestSnapshot) && (
                          <Tooltip
                            show={!canResume && isAbTestSnapshot}
                            tooltipContent="This snapshot is linked to an A/B test so it can not be resumed."
                          >
                            <Item
                              onClick={() => {
                                setMutationLoading(true);
                                actions.resume(entityId, {
                                  onSettled: () => {
                                    setMutationLoading(false);
                                    close();
                                  },
                                });
                              }}
                              disabled={isAbTestSnapshot}
                              icon={PlayIcon}
                            >
                              Resume this Snapshot
                            </Item>
                          </Tooltip>
                        )}
                        {entity.status === 'active' && (canPause || isAbTestSnapshot) && (
                          <Tooltip
                            show={!canPause && isAbTestSnapshot}
                            tooltipContent="This snapshot is linked to an A/B test so it can not be paused."
                          >
                            <Item
                              onClick={() => {
                                setMutationLoading(true);
                                actions.pause(entityId, {
                                  onSettled: () => {
                                    setMutationLoading(false);
                                    close();
                                  },
                                });
                              }}
                              disabled={isAbTestSnapshot}
                              icon={PauseIcon}
                            >
                              Pause this Snapshot
                            </Item>
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Item
                      onClick={() => {
                        actions.exportItem(entity, {
                          onSuccess: () => setPollExports(true),
                        });
                        close();
                      }}
                      disabled={!canExport || isError || fetchingExports || isExporting}
                    >
                      {fetchingExports ? (
                        <>
                          <Spinner className="mr-5 h-4 w-4 text-cadet-blue-500" /> Loading...
                        </>
                      ) : (
                        <>
                          <ExportIcon
                            className={classNames(
                              'mr-5 h-4 w-4 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500',
                              {
                                '!text-cadet-blue-500': !canExport || isError || fetchingExports || isExporting,
                              },
                            )}
                          />
                          {isExporting ? 'Exporting...' : 'Export this Snapshot'}
                        </>
                      )}
                    </Item>

                    {canEditSnapshots && (
                      <>
                        <Item
                          onClick={() => {
                            actions.remove(entityId, { visits: entity.totalVisits, onSettled: () => close() });
                          }}
                          icon={DeleteIcon}
                        >
                          Delete this Snapshot
                        </Item>
                        <Item component="div" icon={FolderIcon}>
                          <div className="flex-1">{fetchingFolders ? 'Loading...' : 'Move this Snapshot'}</div>
                          <TriangleIcon className="h-2 w-2 -rotate-90 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                          <div
                            className={classNames(
                              'absolute -top-2.5 right-full hidden flex-col group-hover:flex',
                              'rounded border border-mystic-500 bg-white px-4 py-2.5 shadow-md',
                              'min-w-[155px] max-w-[255px]',
                            )}
                          >
                            {folders.map((folder, idx) => (
                              <Item
                                className="group-2"
                                key={`ActionDropdownFolder-${idx}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setMutationLoading(true);
                                  actions.move(Number(entityId), folder.id, {
                                    onSettled: () => {
                                      setMutationLoading(false);
                                      close();
                                    },
                                  });
                                }}
                                disabled={entity.folderId === folder.id}
                              >
                                <FolderIcon
                                  className={classNames('mr-2.5 h-4 w-4 flex-shrink-0 fill-current', {
                                    'text-cadet-blue-500': entity.folderId === folder.id,
                                    'group-2-hover:text-dodger-blue-500 text-dodger-blue-300':
                                      entity.folderId !== folder.id,
                                  })}
                                />
                                <div
                                  before={`${folder.title} (${folder.snapshotsCount})`}
                                  className={classNames(
                                    'w-max',
                                    'before:invisible before:block before:h-0 before:overflow-hidden before:font-semibold before:content-[attr(before)]',
                                  )}
                                  style={{ overflowWrap: 'anywhere' }}
                                >
                                  {folder.title} ({folder.snapshotsCount})
                                </div>
                              </Item>
                            ))}

                            <Item
                              onClick={(e) => {
                                e.stopPropagation();
                                setMutationLoading(true);
                                actions.unfile(Number(entityId), {
                                  onSettled: () => {
                                    setMutationLoading(false);
                                    close();
                                  },
                                });
                                close();
                              }}
                              disabled={entity.folderId === null}
                            >
                              <div className="ml-[26px]">Unfiled</div>
                            </Item>
                          </div>
                        </Item>
                      </>
                    )}
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </>
      )}
    </Popover>
  );
}

function Item({ children, icon: Icon, className, disabled, ...props }) {
  return (
    <PopoverItem
      className={classNames('!flex !select-none !items-center !py-2', className, {
        'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
        'group ': !disabled,
      })}
      disabled={disabled}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames('mr-5 h-4 w-4 fill-current group-hover:text-dodger-blue-500', {
            'text-cadet-blue-500': disabled,
            'text-dodger-blue-300': !disabled,
          })}
        />
      )}
      {children}
    </PopoverItem>
  );
}
