import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { WizardHeader } from '/src/components/wizard/legacy/wizard-ui';
import { SnapshotSettings } from './SnapshotSettings';
import { useWizard } from '/src/hooks';
import { SNAPSHOT_TRACKING_OPTIONS } from '/src/features/snapshots/constants';

export function BulkSnapshotSettings() {
  const location = useLocation();
  const { set: wizardSet, setCancelConfirm: wizardSetCancelConfirm } = useWizard();
  const formik = useFormikContext();

  const navigate = useNavigate();

  const navigationEnabled = formik.isValid;

  useEffect(() => {
    if (formik.values.snapshots.length < 2) {
      navigate('/snapshots/new/bulk', { replace: true });
    }
  }, [formik.values.snapshots.length, navigate]);

  const trackingOption = formik.values.trackingOptions.option;
  const { setFieldValue } = formik;

  useEffect(() => {
    // make sure for Bulk, the tracking option is set to DEFAULT
    if (trackingOption !== SNAPSHOT_TRACKING_OPTIONS.DEFAULT) {
      setFieldValue('trackingOptions.option', SNAPSHOT_TRACKING_OPTIONS.DEFAULT);
    }
  }, [trackingOption, setFieldValue]);

  useEffect(() => {
    wizardSet({
      title: 'Snapshot Settings',
      back: `/snapshots/new/bulk${location.search}`,
      backEnabled: true,
      next: `/snapshots/new/review${location.search}`,
      nextEnabled: navigationEnabled,
      nextText: 'Review',
      currentStep: 2,
      totalSteps: 3,
      loading: false,
    });
    wizardSetCancelConfirm(true);
  }, [wizardSet, wizardSetCancelConfirm, navigationEnabled, location.search]);

  useEffect(() => {
    document.querySelector('main')?.scrollIntoView?.({
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <WizardHeader>Great, let’s define how and when your Snapshots will run!</WizardHeader>

      <SnapshotSettings />
    </>
  );
}
