import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useActor } from '@xstate/react';
import { Input, Checkbox } from '@crazyegginc/hatch';

import { ContainedCheckBoxWithIcon } from '/src/components/ContainedCheckBoxWithIcon';
import { GoalCard, SkeletonCard, GoalRequired } from '/src/features/goals/components/GoalCard';
import { abTestTypes } from '/src/features/ab-testing/constants';
import { goalDetailQuery } from '/src/features/goals/queries';
import { inflect } from '/src/utils/string';

import { DEVICE_TYPES } from '/src/features/_global/constants';

import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as TabletIcon } from '@crazyegginc/hatch/dist/images/icon-tablet-outline.svg';
import { ReactComponent as MobileIcon } from '@crazyegginc/hatch/dist/images/icon-mobile-outline.svg';

export function AbTestReviewPage({ context }) {
  const { abTestService } = useContext(context);
  const [state] = useActor(abTestService);
  const { send } = abTestService;

  const isCreating = state.matches('publishTest');

  const values = state.context.payload;

  const {
    data: goal,
    error: goalError,
    isFetching: isFetchingGoal,
  } = useQuery({
    ...goalDetailQuery({ id: values.goalId }),
  });

  useEffect(() => {
    if (values.goalId && goalError && goalError.message === '[GraphQL] Goal not found') {
      send('RESET_GOAL');
    }
  }, [goalError, send, values.goalId]);

  const variantCount = values.variants.filter((variant) => variant.type.toUpperCase() === 'VARIANT').length; // include the control variant in the count

  return (
    <div className="mx-auto flex w-[710px] flex-col space-y-10">
      <h1 className="text-header-0 m-0 mt-14 text-center">Let&apos;s review your test</h1>

      <span className="text-body-2 text-center">
        Nearly finished! Review your work below to be sure everything is tip-top before we create your A/B test.
      </span>

      <div className="space-y-5">
        <h2 className="text-header-2">Summary</h2>
        <div className="flex space-x-[18px]">
          <div className="text-header-6 flex flex-col space-y-2.5">
            <h3 className="w-[84px]">Testing page:</h3>
            <h3 className="w-[84px]">Variants:</h3>
            <h3 className="w-[84px]">Audience:</h3>
          </div>
          <div className="text-body-5 flex flex-col space-y-2.5">
            <a href={values.matchingUrl} target="_blank" rel="noreferrer noopener" className="text-link">
              {values.matchingUrl}
            </a>

            <div className="flex items-center space-x-4">
              <span>
                {variantCount} {inflect('variant', variantCount)}
              </span>
              <button onClick={() => send({ type: 'NAVIGATE', value: 'variants' })} className="text-link">
                Change variants
              </button>
            </div>

            <DeviceSelector
              selected={values.devices}
              onChange={(e) => {
                send({
                  type: 'CHANGE',
                  key: 'devices',
                  value: values.devices.includes(e.target.value)
                    ? [...values.devices.filter((device) => device !== e.target.value)]
                    : [...values.devices, e.target.value],
                });
              }}
            />
            {state.context.errors?.devices ? (
              <div className="text-error whitespace-nowrap">{state.context.errors?.devices}</div>
            ) : null}
          </div>
        </div>
      </div>

      <hr className="my-10 w-full border-t border-dashed border-t-mystic-500" />

      <div className="space-y-5">
        <h2 className="text-header-2">Give your test a name</h2>
        <Input
          className="!h-[50px]"
          value={values.name}
          onChange={(e) => {
            send({ type: 'CHANGE', key: 'name', value: e.target.value });
          }}
          disabled={isCreating}
          aria-label="test name"
          // eslint-disable-next-line
          autoFocus={true}
          error={state.context.errors?.name}
        />
      </div>

      <hr className="my-10 w-full border-t border-dashed border-t-mystic-500" />

      <div className="space-y-5">
        <div className="flex space-x-[18px]">
          <h2 className="text-header-2">Traffic split</h2>
          <button className="text-link" onClick={() => send('BACK')}>
            Change traffic split
          </button>
        </div>

        <div>
          <div className="text-body-2 flex h-10 items-center rounded border border-mystic-500 pl-3.5">
            {state.context.payload.autoReweight ? 'Multi-arm bandit - Powered by AI' : 'Manual'}
          </div>

          {!state.context.payload.autoReweight ? (
            <div className="mt-3.75 flex flex-col divide-y divide-mystic-500 rounded border border-mystic-500">
              {state.context.payload.variants.map((variant, index) => (
                <div
                  className="flex min-h-[65px] items-center justify-between px-5 py-3.5"
                  key={`ReviewVariant-${index}`}
                >
                  <div className="flex flex-col">
                    {variant.type.toUpperCase() === 'CONTROL' ? (
                      <>
                        <span className="text-body-2">Control</span>
                        {state.context.payload.type === abTestTypes.SPLIT ? (
                          <span className="text-body-4">
                            {state.context.payload.url || state.context.payload.pageUrl}
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <span className="text-body-2">
                          {variant.variantName ? variant.variantName : `Variant #${index}`}
                        </span>
                        {state.context.payload.type === abTestTypes.SPLIT ? (
                          <span className="text-body-4">{variant.redirectUrl}</span>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    {String(variant?.weight ?? '')}
                    <span className="ml-1.25">&#37;</span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <hr className="my-10 w-full border-t border-dashed border-t-mystic-500" />

      <div className="space-y-5">
        <div className="flex space-x-[18px]">
          <h2 className="text-header-2">Goal for this test</h2>
          <button className="text-link" onClick={() => send('CHANGE_GOAL')}>
            Change goal
          </button>
        </div>
        {isFetchingGoal ? (
          <SkeletonCard />
        ) : goal && !goalError ? (
          <GoalCard goal={goal?.goalDetail} />
        ) : (
          <GoalRequired onClick={() => send('CHANGE_GOAL')} />
        )}
      </div>

      <hr className="my-10 w-full border-t border-dashed border-t-mystic-500" />

      <div className="space-y-5">
        <h2 className="text-header-2">Set-up Snapshots</h2>
        {state.context.payload.type === abTestTypes.PATCH ? (
          <Checkbox
            name="createSnapshots"
            checked={values.createSnapshots}
            onChange={() => send({ type: 'CHANGE', key: 'createSnapshots', value: !values.createSnapshots })}
            label="Create a Snapshot for each variant of this test"
            id="createSnapshots"
            disabled={isCreating}
          />
        ) : (
          <span className="text-body-2">
            To set-up Snapshots for variants of a URL redirect test, you can follow the link on the next screen after
            creating your test. If your pages are not internet accessible, you can use our Page Camera tool once the
            test has launched.
          </span>
        )}
      </div>
    </div>
  );
}

function DeviceSelector({ selected = [], onChange }) {
  return (
    <div className="flex flex-wrap space-x-3">
      <ContainedCheckBoxWithIcon
        Icon={DesktopIcon}
        handleChange={onChange}
        name="audience"
        value={DEVICE_TYPES.DESKTOP}
        label="Desktop"
        checked={selected.includes(DEVICE_TYPES.DESKTOP)}
      />
      <ContainedCheckBoxWithIcon
        Icon={TabletIcon}
        handleChange={onChange}
        name="audience"
        value={DEVICE_TYPES.TABLET}
        label="Tablet"
        checked={selected.includes(DEVICE_TYPES.TABLET)}
      />
      <ContainedCheckBoxWithIcon
        Icon={MobileIcon}
        handleChange={onChange}
        name="audience"
        value={DEVICE_TYPES.PHONE}
        label="Mobile"
        checked={selected.includes(DEVICE_TYPES.PHONE)}
      />
    </div>
  );
}
