import { useRef, memo, useLayoutEffect, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { ReactComponent as FavIcon } from '@crazyegginc/hatch/dist/images/icon-globe-outline.svg';

const MIN_WIDTH = 64;
const PADDING_LEFT = 10;
const NUM_TAB_WIDTH = 40;

function Tab({ title, activeTab, ordinal, isInvisibleTabsExist, visibleTabsLength, index }) {
  const isActive = activeTab === ordinal;

  return (
    <li
      className={classNames(
        'group relative flex h-[30px] max-w-[230px] flex-1 items-center overflow-hidden rounded-t pl-1.5 text-xs',
        {
          'bg-woodsmoke-500 text-mystic-500': !isActive,
          'bg-[#3C424D] text-white-lilac-500': isActive,
        },
      )}
    >
      <FavIcon className="mr-1.5 h-4 w-4 fill-current" />
      <div className="flex-1 overflow-hidden whitespace-nowrap">{title}</div>
      <div
        className={classNames('absolute bottom-0 right-0 top-0 z-20 my-auto h-[15px] w-[1px] bg-[#3C424D]', {
          hidden:
            activeTab === ordinal ||
            index === visibleTabsLength - 1 ||
            (visibleTabsLength === 2 && !isInvisibleTabsExist),
        })}
      ></div>
      <div
        className={classNames('absolute right-0 top-0 h-full w-2/6 bg-gradient-to-l', {
          'from-woodsmoke-500/100 via-woodsmoke-500/80 to-woodsmoke-500/0': !isActive,
          'from-[#3C424D]/100 via-[#3C424D]/80 to-[#3C424D]/0': isActive,
        })}
      ></div>
      <div
        className={classNames('absolute right-0 top-0 h-full w-6 bg-gradient-to-l opacity-70', {
          'from-woodsmoke-500/100 via-woodsmoke-500/80 to-woodsmoke-500/0': !isActive,
          'from-[#3C424D]/100 via-[#3C424D]/80 to-[#3C424D]/0': isActive,
        })}
      ></div>
    </li>
  );
}

function InvisibleTabs({ invisibleTabs, activeTab }) {
  const selectedInvisibleTab = invisibleTabs.filter((tab) => tab.ordinal === activeTab && !tab.closed)[0] ?? false;

  return (
    <li
      className={classNames(
        'absolute right-0 flex h-[30px] w-[40px] flex-1 items-center justify-center rounded-t px-1.5 text-xs',
        {
          'bg-woodsmoke-500 text-mystic-500': !selectedInvisibleTab,
          'bg-[#3C424D] text-white-lilac-500': selectedInvisibleTab,
        },
      )}
    >
      <Tooltip show={selectedInvisibleTab} tooltipContent={selectedInvisibleTab?.title ?? ''}>
        <div className="truncate">+{invisibleTabs.length}</div>
      </Tooltip>
      <div
        className={classNames('absolute -left-[1px] bottom-0 top-0 z-50 my-auto h-[15px] w-[1px] bg-woodsmoke-500', {
          hidden: !selectedInvisibleTab,
        })}
      ></div>
    </li>
  );
}

/*
  Most of the time these are the same as width and height of getBoundingClientRect(),
  when there aren't any transforms applied to the element. In case of transforms,
  the offsetWidth and offsetHeight returns the element's layout width and height,
  while getBoundingClientRect() returns the rendering width and height.
  As an example, if the element has width: 100px; and transform: scale(0.5);
  the getBoundingClientRect() will return 50 as the width,
  while offsetWidth will return 100.
*/

function TabsComponent({ active_tab, tabs }) {
  const tabWrapperRef = useRef(null);
  const [content, setContent] = useState(() => ({ visibleTabs: [], invisibleTabs: [] }));
  const updateContentStates = useCallback(() => {
    const tabsContainer = Math.floor(
      tabWrapperRef.current.getBoundingClientRect().width - (PADDING_LEFT + NUM_TAB_WIDTH),
    );

    const singleTabWidth = Math.floor(tabsContainer / tabs.length);

    if (singleTabWidth < MIN_WIDTH) {
      const invisibleTabsStart = Math.floor(tabsContainer / MIN_WIDTH);
      setContent((state) => ({
        ...state,
        visibleTabs: tabs.slice(0, invisibleTabsStart),
        invisibleTabs: tabs.slice(invisibleTabsStart),
      }));
    } else {
      setContent((state) => ({
        ...state,
        visibleTabs: tabs,
        invisibleTabs: [],
      }));
    }
  }, [tabs]);

  useEffect(() => {
    if (tabWrapperRef.current) {
      updateContentStates();
    }
  }, [updateContentStates]);

  useLayoutEffect(() => {
    const onResize = () => {
      updateContentStates();
    };

    if (tabWrapperRef.current) {
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tabWrapperRef, updateContentStates]);

  const isInvisibleTabsExist = content.invisibleTabs.length >= 1;
  const noClosedTabs = content.visibleTabs.filter((tab) => !tab.closed);

  return (
    <ul
      className={classNames(
        'relative flex h-[30px] w-full flex-wrap overflow-hidden md:h-[45px] md:pt-3.75 lg:pl-2.5',
        {
          'pr-[40px]': isInvisibleTabsExist,
          'lg:pr-2.5': !isInvisibleTabsExist,
        },
      )}
      ref={tabWrapperRef}
    >
      {noClosedTabs.map((tab, idx) => {
        return (
          <Tab
            title={tab.title}
            activeTab={active_tab}
            ordinal={tab.ordinal}
            key={`${tab.title}${idx}`}
            visibleTabsLength={noClosedTabs.length}
            isInvisibleTabsExist={isInvisibleTabsExist}
            index={idx}
          />
        );
      })}
      {isInvisibleTabsExist && <InvisibleTabs invisibleTabs={content.invisibleTabs} activeTab={active_tab} />}
    </ul>
  );
}

export const Tabs = memo(TabsComponent);
