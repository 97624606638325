import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Popover, StyledPopoverPanel, PopoverItem, Spinner, Indicator } from '@crazyegginc/hatch';

import { CodeBox } from '/src/components/CodeBox';
import { useWizard, useAuthContext, useSite, useSelectedSite } from '/src/hooks';
import { useScriptUrl } from '/src/features/installation/utils';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

export function ManualInstructions() {
  const [code] = useScriptUrl();
  const { set: wizardSet } = useWizard();
  const { sessionInfo } = useAuthContext();
  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/manually/check-script',
      nextEnabled: true,
      title: 'Manual installation',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, do-it-yourselfer!</WizardHeader>

      <WizardSubHeader>
        To install Crazy Egg, place this script at the top of the &lt;head&gt; section just after any &lt;meta&gt; tags.
      </WizardSubHeader>

      <CodeBox loading={false} code={code} />

      <a className="text-link mt2.5" target="_blank" rel="noopener noreferrer" href={SupportLinks.install.manual}>
        How do I manually install this tracking script onto my site?
      </a>

      {isImpersonated ? <AdminInstructions /> : null}

      <div className="mt-8">
        Click <strong className="text-body-1">Next</strong> once you have installed the tracking script.
      </div>
    </div>
  );
}

function AdminInstructions() {
  const { selectedSite } = useSelectedSite();
  const [site, setSite] = useState(selectedSite);
  const { currentAccount } = useAuthContext();

  const splitUserId = `${currentAccount.paddedId.slice(0, 4)}/${currentAccount.paddedId.slice(4)}`;

  let advancedCode =
    `&lt;link rel=&quot;preload&quot; href=&quot;//script.crazyegg.com/pages/scripts/%USER%.js&quot; as=&quot;script&quot; fetchPriority=&quot;high&quot;/&gt;
&lt;link rel=&quot;preload&quot; href=&quot;//script.crazyegg.com/pages/data-scripts/%USER%/site/%SITE%.json?t=1&quot; as=&quot;fetch&quot; fetchPriority=&quot;high&quot; crossorigin /&gt;
&lt;link rel=&quot;preload&quot; href=&quot;//script.crazyegg.com/pages/data-scripts/%USER%/sampling/%SITE%.json?t=1&quot; as=&quot;fetch&quot; fetchPriority=&quot;high&quot; crossorigin /&gt;
&lt;link rel=&quot;preload&quot; href=&quot;//script.crazyegg.com/pages/abtests-scripts/%USER%/%SITE%.css&quot; as=&quot;style&quot; fetchPriority=&quot;high&quot; /&gt;
&lt;link href=&quot;//script.crazyegg.com/pages/abtests-scripts/%USER%/%SITE%.css&quot; rel=&quot;stylesheet&quot;&gt;
&lt;script type=&quot;text/javascript&quot; src=&quot;//script.crazyegg.com/pages/scripts/%USER%.js&quot; async=&quot;async&quot; fetchPriority=&quot;high&quot;&gt;&lt;/script&gt;`
      .replaceAll('%USER%', splitUserId)
      .replaceAll('%SITE%', site?.name ?? '%SITE%');

  return (
    <div className="mt-10 border border-mystic-500 p-3">
      <p className="text-header-4 mb-3">Admin section</p>
      <SiteSelector site={site} setSite={setSite} />
      {site && <CodeBox loading={false} code={advancedCode} />}
    </div>
  );
}

function SiteSelector({ site, setSite }) {
  const { sites, loadingSites } = useSite({ dontSelectSite: true });

  const disabled = loadingSites;

  return (
    <div>
      <div className="text-body-2 mb-0.5 block">Show install instruction for site:</div>
      <div className="w-80">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  'relative h-[35px] w-full cursor-default rounded border border-mystic-500 pl-3 pr-9 text-left',
                  'text-body-4 leading-none',
                  'focus:border-dodger-blue-500 focus:outline-none',
                  {
                    '!border-dodger-blue-500': open,
                    'cursor-not-allowed bg-white-lilac-500 text-lynch-500': disabled,
                    'bg-white text-black-pearl-500': !disabled,
                  },
                )}
                disabled={disabled}
                aria-label="site selector"
              >
                {loadingSites ? (
                  <div className="flex items-center space-x-2.5">
                    <Spinner />
                    <span className="text-lynch-500">loading sites...</span>
                  </div>
                ) : (
                  <span>{site?.name ?? 'Select the site'}</span>
                )}
                <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" />
              </Popover.Button>
              <StyledPopoverPanel align="right" className="!max-h-[280px] !w-full !overflow-y-auto">
                {({ close }) => (
                  <>
                    {sites.length ? (
                      sites.map((site) => (
                        <PopoverItem
                          key={site.id}
                          onClick={() => {
                            setSite(site);
                            close();
                          }}
                        >
                          {site.name}
                        </PopoverItem>
                      ))
                    ) : (
                      <div className="text-body-5 relative inline-block w-full max-w-full rounded px-2.5 py-1.25 text-left leading-[1.15]">
                        You don’t have any sites.
                      </div>
                    )}
                  </>
                )}
              </StyledPopoverPanel>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}
