import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import readOnlyRangesExtension from 'codemirror-readonly-ranges';
import { Select, Button, Tooltip } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { SectionHeader, SectionPanel, SectionContent, Input } from '../../common/basic-ui';
import { returnURLTypeValue, urlTypeLabel } from '../../common/common-functions';
import { CTA_TYPES, TARGET_OPTIONS, EXECUTE_JS, URL_TYPES, ADDONS_CAPABILITIES } from '/src/features/addons/constants';
import { SearchableGoalSelector } from '/src/components/SearchableGoalSelector';
import { JSCodeEditor } from '../../../../components/JSCodeEditor';
import { normalizeUrl } from '/src/utils/url';

import { ReactComponent as CursorIcon } from '@crazyegginc/hatch/dist/images/icon-cursor-outline.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

const getReadOnlyRanges = (targetState) => {
  return [
    {
      from: undefined,
      to: targetState.doc.line(2).to,
    },
    {
      from: targetState.doc.line(targetState.doc.lines - 1).from,
      to: undefined,
    },
  ];
};
const extensionList = [readOnlyRangesExtension(getReadOnlyRanges)];

const customTheme = {
  '.cm-line:nth-of-type(-n+2), .cm-line:nth-last-of-type(-n+2)': {
    backgroundColor: '#e1e6ef',
  },
  '.cm-line:nth-of-type(1) > .ͼm': {
    color: '#e1e6ef',
  },
};

export function Action({ saveCTA }) {
  const { readonly, isTemplate, publishedEdit, addonsCapabilities } = useEditorContext();
  const { values, errors, handleBlur, setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const codeOnChange = useCallback((newValue) => setFieldValue('action.code', newValue), [setFieldValue]);
  const goalOnSelect = useCallback((goal) => setFieldValue('goalId', goal?.id ?? null), [setFieldValue]);
  const haveURLTypes = addonsCapabilities[ADDONS_CAPABILITIES.MULTIPLE_URLS_TYPE];

  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <CursorIcon className="mr-1.25 h-4 w-4 fill-current text-cadet-blue-500" />
          Action
        </div>
      </SectionHeader>
      <SectionContent>
        <div className="w-full max-w-lg">
          <Select
            label={`When the ${
              values.displayType === CTA_TYPES.BAR && !values.bar.showButton ? 'bar' : 'button'
            } is clicked:`}
            labelClassName="font-semibold !mb-1"
            options={[
              {
                label: 'Open a URL in a new tab',
                value: TARGET_OPTIONS.NEW_TAB,
              },
              { label: 'Open a URL in a new window', value: TARGET_OPTIONS.NEW_WINDOW },
              { label: 'Open a URL in the same window', value: TARGET_OPTIONS.SAME_WINDOW },
              ...(haveURLTypes
                ? [
                    { label: 'Send an email', value: URL_TYPES.MAILTO },
                    { label: 'Make a call', value: URL_TYPES.PHONE },
                  ]
                : []),
              { label: 'Execute JavaScript', value: EXECUTE_JS },
            ]}
            value={values.action.action}
            onChange={(value) => setFieldValue('action.action', value)}
            disabled={readonly}
          />
        </div>
        {[...Object.values(URL_TYPES), ...Object.values(TARGET_OPTIONS)].includes(values.action.action) && (
          <Input
            label={`Enter ${urlTypeLabel(values.action.action)}:`}
            id="actionUrl"
            name="action.url"
            value={values.action.url}
            error={errors?.action?.url}
            onChange={(e) => {
              if (haveURLTypes) {
                const config = {
                  selectedType: values.action.action,
                  value: e.target.value,
                  fieldValueKeyType: 'action.action',
                  fieldValueKeyURL: 'action.url',
                  setFieldValue,
                  isSurvey: false,
                };
                returnURLTypeValue(config);
              } else {
                setFieldValue('action.url', normalizeUrl(e.target.value, { appendSlash: false }));
              }
            }}
            onBlur={handleBlur}
            disabled={readonly}
          />
        )}

        {values.action.action === EXECUTE_JS && (
          <div>
            <div className="text-body-1 mb-1">JavaScript to execute</div>
            <JSCodeEditor
              value={values.action.code}
              onChange={codeOnChange}
              name="action.code"
              className="h-40"
              extensions={extensionList}
              theme={customTheme}
            />
            {errors?.action?.code ? <div className="text-error">{errors.action.code}</div> : null}
          </div>
        )}

        {!isTemplate && (
          <>
            <div className="flex w-full max-w-lg flex-col">
              <div className="text-body-1 mb-1 flex items-center">
                <span className="mr-1">What&apos;s your goal for this CTA?</span>
                <Tooltip
                  tooltipContent={
                    <div className="max-w-xs">
                      Track conversions and analyze the success of your CTA by adding a goal.
                    </div>
                  }
                >
                  <InfoIcon aria-label="option info" className="h-3 w-3 fill-current text-dodger-blue-300" />
                </Tooltip>
                <span className="text-body-4 ml-2.5">Optional</span>
              </div>

              <CantEditGoalTooltip show={publishedEdit}>
                <div className="mb-5">
                  <SearchableGoalSelector
                    value={values.goalId}
                    onSelect={goalOnSelect}
                    displayIfNoGoals={null}
                    disabled={readonly || publishedEdit}
                  />
                </div>
              </CantEditGoalTooltip>

              <div className="!self-start">
                <CantEditGoalTooltip show={publishedEdit}>
                  <Button
                    className="!self-start leading-none"
                    variant="secondary"
                    onClick={async () => {
                      const { id: ctaId } = await saveCTA();
                      if (ctaId) {
                        navigate('/goals/new', { state: { ctaId }, replace: true });
                      }
                    }}
                    disabled={readonly || publishedEdit}
                  >
                    <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                    Create new goal
                  </Button>
                </CantEditGoalTooltip>
              </div>
            </div>
          </>
        )}
      </SectionContent>
    </SectionPanel>
  );
}

function CantEditGoalTooltip({ children, show }) {
  return (
    <Tooltip
      show={show}
      tooltipContent={
        <div className="max-w-md">
          You can not add or modify a Goal for a CTA that has been published. To add or change the Goal, create a new
          CTA and unpublish the current one.
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
