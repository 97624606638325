import { useState } from 'react';

import { SitePickerBar } from '/src/components/site-picker/index';
import { SEO } from '/src/components/SEO';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { DashboardPage } from '/src/components/Page';
import { SelectionProvider } from '/src/contexts/selection';
import { FilterProvider } from '../snapshots-filter-context';
import { SnapshotActionProvider } from '../snapshot-actions-context';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { useSite, usePermissions } from '/src/hooks';
import { getFeatureName } from '../../_global/utils';
import { FEATURES } from '/src/features/_global/constants';
import { SnapshotAlerts } from '/src/features/_global/components/SnapshotAlerts';

import { SnapshotDashboardContent } from '/src/features/snapshots/components/dashboard/SnapshotDashboardContent';
import { SnapshotDashHeader } from '/src/features/snapshots/components/dashboard/SnapshotDashHeader';
import { ZarazInstallPrompt } from '../components/dashboard/ZarazInstallPrompt';

import { SNAPSHOTS_SITES_QUERY } from '/src/features/snapshots/queries';

export function SnapshotDashboard() {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.SNAPSHOTS);
  const canCreate = permissions.can('create', FEATURES.SNAPSHOTS).allowed;
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const { selectedSite, selectSite, sites, loadingSites } = useSite({
    sitesQuery: SNAPSHOTS_SITES_QUERY,
    enableAllSites: true,
    sitesQueryProps: {
      enabled: canViewDashboard,
    },
  });

  const [pollExports, setPollExports] = useState(false);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.SNAPSHOTS} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.SNAPSHOTS)} />
      <FilterProvider>
        <SelectionProvider>
          <SnapshotDashHeader pollExports={pollExports} setPollExports={setPollExports} />
          <SitePickerBar
            sites={sites}
            loading={loadingSites}
            selectedSite={selectedSite}
            selectSite={selectSite}
            enableAllSites={true}
            addSnapshotOption={canCreate && canEditSites}
            addSiteOption={canEditSites}
            pageDisplayName={`${getFeatureName(FEATURES.SNAPSHOTS)} dashboard`}
          />

          <>
            <SnapshotAlerts />
            <InstallationBanner />
            <ZarazInstallPrompt />
          </>

          <SnapshotActionProvider>
            <SnapshotDashboardContent sites={sites} setPollExports={setPollExports} />
          </SnapshotActionProvider>
        </SelectionProvider>
      </FilterProvider>
    </DashboardPage>
  );
}
