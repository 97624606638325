import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup, Input, Tooltip, InfoBox } from '@crazyegginc/hatch';

import { SectionHeader, SectionPanel, SectionContent } from '../basic-ui';
import {
  REPEAT_VALUES,
  SURVEY_DISPLAY_TYPES,
  AUDIENCE_MODES,
  ADDON_TYPES,
  BEHAVIOR_TRIGGERS,
} from '/src/features/addons/constants';

import { useEditorContext } from '../../editor-context';
import { validForPreview, showFeedbackButtonInPreview } from '../../surveys/editor/editor-functions';

import { ReactComponent as BehaviorIcon } from '@crazyegginc/hatch/dist/images/icon-clock-partial-outline.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function Behavior() {
  const { readonly, spelledType, type } = useEditorContext();
  const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange } = useFormikContext();

  useEffect(() => {
    if (values.behavior.show === BEHAVIOR_TRIGGERS.FEEDBACK) {
      setFieldValue(
        'behavior.feedback.cta_bg',
        window.CE2.Survey.getThemeColor(values.theme.accent, values.theme.dark, 'cta-bg'),
      );
      setFieldValue(
        'behavior.feedback.cta_bg_hover',
        window.CE2.Survey.getThemeColor(values.theme.accent, values.theme.dark, 'cta-bg-hover'),
      );
      setFieldValue(
        'behavior.feedback.cta_bg_active',
        window.CE2.Survey.getThemeColor(values.theme.accent, values.theme.dark, 'cta-bg-active'),
      );
      setFieldValue(
        'behavior.feedback.cta_text',
        window.CE2.Survey.getThemeColor(values.theme.accent, values.theme.dark, 'cta-text'),
      );
      setFieldValue(
        'behavior.feedback.focus',
        window.CE2.Survey.getThemeColor(values.theme.accent, values.theme.dark, 'focus'),
      );
    }
  }, [values.behavior.show, setFieldValue, values.theme.accent, values.theme.dark]);

  if (values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL) {
    return null;
  }

  const options = [
    { value: BEHAVIOR_TRIGGERS.IMMEDIATE, label: 'As soon as the page loads' },
    {
      value: BEHAVIOR_TRIGGERS.TIME_DELAY,
      label: (
        <div className="flex items-center">
          <div className="mr-1.25 w-11">
            <Input
              name={'behavior.timeDelaySeconds'}
              size="xs"
              value={values.behavior.timeDelaySeconds}
              onChange={handleChange}
              onBlur={handleBlur}
              className="!px-2"
              error={errors.behavior?.timeDelaySeconds ? true : false}
              disabled={readonly}
              aria-label="seconds"
            />
          </div>
          seconds after the page loads
        </div>
      ),
    },
    {
      value: BEHAVIOR_TRIGGERS.SCROLL_PAST_FOLD,
      label: (
        <div className="flex items-center space-x-2.5">
          <span>When a visitor scrolls past the fold</span>
          <Tooltip
            tooltipContent={
              <div className="max-w-xs">
                The fold in a website is the point on the web page that rests at the bottom of someone’s browser. In
                other words, to see anything below the fold, they would have to scroll down.
              </div>
            }
          >
            <InfoIcon aria-label="information on fold" className="h-3 w-3 fill-current text-dodger-blue-300" />
          </Tooltip>
        </div>
      ),
    },
    {
      value: BEHAVIOR_TRIGGERS.EXIT_INTENT,
      label: 'When a visitor is about to leave the page',
    },
    {
      value: BEHAVIOR_TRIGGERS.NUM_PAGES,
      label: (
        <div className="flex items-center">
          After
          <div className="mx-1.25 w-11">
            <Input
              name={'behavior.numPages'}
              size="xs"
              value={values.behavior.numPages}
              onChange={handleChange}
              onBlur={handleBlur}
              className="!px-2"
              error={errors.behavior?.numPages ? true : false}
              disabled={readonly}
              aria-label="pages"
            />
          </div>
          pages
        </div>
      ),
    },
  ];

  if (type === ADDON_TYPES.SURVEY) {
    options.push({
      value: BEHAVIOR_TRIGGERS.FEEDBACK,
      label: 'When a visitor clicks on my button',
    });
  }

  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <BehaviorIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
          Behavior
        </div>
      </SectionHeader>
      <SectionContent>
        {values.mode === AUDIENCE_MODES.SIMPLE ? (
          <div className="self-start">
            <div>
              <RadioGroup
                label={`When does this ${spelledType} launch`}
                groupLabelClass="!mb-2"
                size="sm"
                options={options}
                value={values.behavior.show}
                onChange={async (value) => {
                  await setFieldValue('behavior.show', value, true);
                  if (value === BEHAVIOR_TRIGGERS.FEEDBACK && !touched.behavior?.repeat) {
                    setFieldValue('behavior.repeat', REPEAT_VALUES.ALWAYS);
                  }
                }}
                disabled={readonly}
              />
            </div>
            {errors.behavior?.timeDelaySeconds && (
              <div className="text-error !mt-0.5">{errors.behavior.timeDelaySeconds}</div>
            )}
            {errors.behavior?.numPages && <div className="text-error !mt-0.5">{errors.behavior.numPages}</div>}

            {values.behavior.show === BEHAVIOR_TRIGGERS.FEEDBACK && (
              <div className="ml-[34px] w-80">
                <Input
                  name={'behavior.feedback.text'}
                  id="feedback-text"
                  label="Button text:"
                  labelClassName="!font-semibold !mt-1"
                  placeholder="e.g. Launch survey"
                  value={values.behavior.feedback.text}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={() => {
                    showFeedbackButtonInPreview(values.behavior.feedback, validForPreview(errors));
                  }}
                  error={errors.behavior?.feedback?.text}
                  disabled={readonly}
                />
              </div>
            )}
          </div>
        ) : null}

        <div className="self-start">
          <RadioGroup
            label={`Visitors will see this ${spelledType}`}
            groupLabelClass="!mb-2"
            size="sm"
            options={[
              {
                value: REPEAT_VALUES.SUBMIT,
                label: type === ADDON_TYPES.SURVEY ? 'Until they submit a response' : 'Until they click on my CTA',
              },
              { value: REPEAT_VALUES.NUMBER, label: 'Only once' },
              { value: REPEAT_VALUES.ALWAYS, label: 'Always' },
            ]}
            value={values.behavior.repeat}
            onChange={(value) => {
              setFieldValue('behavior.repeat', value);
              setFieldTouched('behavior.repeat', true);
            }}
            disabled={readonly}
          />
        </div>

        {type === ADDON_TYPES.SURVEY && (
          <div className="max-w-md">
            {values.behavior.repeat === REPEAT_VALUES.ALWAYS ? (
              <InfoBox
                Icon={<WarningIcon className="h-3.5 w-3.5 fill-current text-dandelion-500" />}
                body={
                  <>
                    We will show this survey to every visitor matching your criteria every time they visit, even if they
                    have seen it many times or completed the survey already. Be cautious using this option as it may
                    frustrate repeat visitors.
                  </>
                }
              />
            ) : (
              <InfoBox
                body={
                  values.behavior.repeat === REPEAT_VALUES.SUBMIT ? (
                    <>
                      A visitor will continue to see this survey until they submit an answer. Once a visitor answers
                      this survey we won&apos;t show them another survey until it&apos;s been at least 72 hours and they
                      have visited your site at least three separate times since.
                    </>
                  ) : (
                    <>
                      Once a visitor sees this survey we won&apos;t show them another survey until it&apos;s been at
                      least 72 hours and they have visited your site at least three separate times since.
                    </>
                  )
                }
              />
            )}
          </div>
        )}
      </SectionContent>
    </SectionPanel>
  );
}
