import classNames from 'classnames';
import { useActor } from '@xstate/react';
import { Select } from '@crazyegginc/hatch';

import { WebP } from '/src/components/WebP';
import { SelectLabel } from '/src/components/SelectLabel';

import { ClickTrigger } from './triggers/Click';
import { UrlVisitTrigger } from './triggers/UrlVisit';
import { FormSubmissionTrigger } from './triggers/FormSubmission';
import { CodeTrigger } from './triggers/Code';
import { PixelTrigger } from './triggers/Pixel';

import { GOAL_TRIGGER_TYPES, GOAL_TRIGGERS_METADATA } from '../constants';

export function TriggerForm({ service, trigger, triggerIndex }) {
  return (
    <div className="p-6">
      {!trigger?.trigger ? (
        <>
          <h2 className="text-header-2 mb-5 mt-4">Choose an option below to track your goal:</h2>
          <p className="text-body-2 mb-3.75">This will let you know how well your goal is performing.</p>
          <SelectorRow service={service} triggerIndex={triggerIndex} />
        </>
      ) : (
        <>
          <h2 className="text-header-2 mb-5 mt-4 leading-none">Your goal is triggered when someone:</h2>
          <SelectionField service={service} triggerIndex={triggerIndex} />
          <RenderTriggerFormContent service={service} triggerIndex={triggerIndex} />
        </>
      )}
    </div>
  );
}

function RenderTriggerFormContent({ service, triggerIndex }) {
  const [state] = useActor(service);

  const { payload: values } = state.context;

  switch (values.triggers[triggerIndex].trigger) {
    case GOAL_TRIGGER_TYPES.CLICK:
      return <ClickTrigger service={service} triggerIndex={triggerIndex} />;
    case GOAL_TRIGGER_TYPES.URL_VISIT:
      return <UrlVisitTrigger service={service} triggerIndex={triggerIndex} />;
    case GOAL_TRIGGER_TYPES.FORM_SUBMISSION:
      return <FormSubmissionTrigger service={service} triggerIndex={triggerIndex} />;
    case GOAL_TRIGGER_TYPES.PIXEL_EVENT:
      return <PixelTrigger service={service} triggerIndex={triggerIndex} />;
    case GOAL_TRIGGER_TYPES.CODE:
      return <CodeTrigger service={service} triggerIndex={triggerIndex} />;
  }
}

function SelectorRow({ service, triggerIndex }) {
  const { send } = service;

  return (
    <div className="flex flex-wrap justify-center gap-x-3.75 gap-y-5">
      {Object.values(GOAL_TRIGGER_TYPES).map((trigger) => (
        <Card
          image={GOAL_TRIGGERS_METADATA[trigger].webp}
          fallbackImage={GOAL_TRIGGERS_METADATA[trigger].png}
          imgWidth={'90px'}
          imgHeight={'70px'}
          title={GOAL_TRIGGERS_METADATA[trigger].label}
          text={undefined}
          key={`Trigger.${triggerIndex}.${trigger}`}
          isSelected={false}
          onClick={() => send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'trigger', value: trigger })}
        />
      ))}
    </div>
  );
}

function SelectionField({ service, triggerIndex }) {
  const [state] = useActor(service);
  const { send } = service;

  const fieldOptions = Object.values(GOAL_TRIGGER_TYPES).map((trigger) => ({
    value: trigger,
    label: (
      <SelectLabel
        text={GOAL_TRIGGERS_METADATA[trigger].selectLabel}
        webp={GOAL_TRIGGERS_METADATA[trigger].webp}
        png={GOAL_TRIGGERS_METADATA[trigger].png}
      />
    ),
  }));

  return (
    <div className="mb-10">
      <Select
        placeholder="Choose..."
        options={fieldOptions}
        value={state.context.payload.triggers[triggerIndex].trigger}
        onChange={(value) => send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'trigger', value })}
      />
    </div>
  );
}

function Card({ image, fallbackImage, imgWidth, imgHeight, title, text, isSelected, ...rest }) {
  return (
    <button
      className={classNames('flex h-[160px] w-[125px] flex-col items-center rounded px-3 py-2', {
        'border-2 border-dodger-blue-500': isSelected,
        'border border-mystic-500': !isSelected,
      })}
      {...rest}
    >
      {image ? (
        <div className="flex h-[70px] flex-shrink-0 items-center justify-center">
          <WebP webp={image} fallback={fallbackImage} width={imgWidth} height={imgHeight} />
        </div>
      ) : null}
      <span className="text-header-6 mt-2.5">{title}</span>
      <p className="text-caption mt-1.25">{text}</p>
    </button>
  );
}
