import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';
import { SupportLinks } from '/src/support';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

import ExpiredPNG from '@crazyegginc/hatch/dist/images/illustration-cc-expired.png';
import ExpiredWebP from '@crazyegginc/hatch/dist/images/illustration-cc-expired.webp';

export function TrialExpiredPaywall() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image webp={ExpiredWebP} fallback={ExpiredPNG} width="103" />
      <Paywall.Title>Your free trial has ended</Paywall.Title>
      {canManageBilling ? (
        <>
          <Paywall.Body>
            Don&#39;t worry, all of your data will be saved, but you&#39;ll need to choose a plan before you can access
            it. You can continue using Crazy Egg by upgrading to a plan that&#39;s right for you.
          </Paywall.Body>
          <Button component={Link} to={MANAGE_SUBSCRIPTION_PATH}>
            Choose a plan
          </Button>
        </>
      ) : (
        <>
          <Paywall.Body>
            To continue using Crazy Egg, contact your Account Owner &#40;
            {currentAccount.ownerEmail}&#41; to choose a plan that&#39;s right for you.
            <div className="mt-5">
              If you need help, email us at{' '}
              <a className="text-link" href={`mailto:${SupportLinks.general.email}`}>
                {SupportLinks.general.email}
              </a>
              .
            </div>
          </Paywall.Body>
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
            Contact account owner
          </Button>
        </>
      )}
    </Paywall>
  );
}
