import { useState, useRef } from 'react';
import classNames from 'classnames';
import { CloseButton, Input, Spinner, Tooltip } from '@crazyegginc/hatch';

import { escapeRegexSpecialChars } from '/src/utils/regex';
import { getOptionDisplayValue } from '../utils';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';

const maxSelectableOptions = 3;

export function UTMSelect({ name, options, onChange, values, loading, disabled }) {
  return (
    <div className="w-40 flex-grow">
      <div className="text-header-5">{name}</div>
      <ListSelect
        options={options}
        onChange={onChange}
        values={values}
        name={name}
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
}

function ListSelect({ options, onChange, values, name, loading, disabled }) {
  const [focused, setFocused] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const ref = useRef();

  const liClassName = name.replace(/\s/g, '');

  function searchResultHighlighter(searchPhrase) {
    const regexp = new RegExp(`(${escapeRegexSpecialChars(searchPhrase)})`, 'i');
    const results = document.querySelectorAll('.' + liClassName);
    for (const result of results) {
      const newHtml = result.textContent.replace(regexp, (full, group1) => `<b>${group1}</b>`);
      result.innerHTML = newHtml;
    }
  }

  if (searchInput.length > 0) {
    const regexp = new RegExp(escapeRegexSpecialChars(searchInput), 'i');
    options = options.filter((x) => getOptionDisplayValue(x).match(regexp));
  }

  const toggleSelection = (option) => {
    if (!option) return;
    if (values.map((x) => x.value).includes(option.value)) {
      onChange?.(values.filter((x) => x.value !== option.value));
    } else {
      onChange?.([...values, option]);
    }
    setSearchInput('');
    searchResultHighlighter('');
  };

  const handleFocus = (index) => {
    if (index >= 0 && index < options.length) {
      setFocused(index);
    }
  };

  const clearFocus = () => {
    setFocused(null);
  };

  const handleScroll = (index) => {
    const scrollValue = 32 * index;
    const height = ref.current?.getBoundingClientRect().height - 60;
    const bottom = height + ref.current.scrollTop;
    const top = ref.current.scrollTop + 35;
    if (scrollValue > bottom) ref.current.scroll(0, 32 * index - height);
    if (scrollValue < top) ref.current.scroll(0, 32 * index - 35);
  };

  const focusNext = () => {
    let index;
    if (focused === null && options.length > 0) {
      index = 0;
    } else if (focused !== null && options.length - 1 === focused) {
      index = 0;
    } else if (focused !== null && options.length - 1 > focused) {
      index = focused + 1;
    }
    if (index >= 0) {
      handleFocus(index);
      handleScroll(index);
    }
  };

  const focusPrevious = () => {
    let index;
    if (focused === null && options.length > 0) {
      index = options.length - 1;
    } else if (focused !== null && 0 === focused) {
      index = options.length - 1;
    } else if (focused !== null && focused > 0) {
      index = focused - 1;
    }
    if (index >= 0) {
      handleFocus(index);
      handleScroll(index);
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;

    switch (key) {
      case 'ArrowDown':
        e.preventDefault();
        focusNext();
        break;
      case 'ArrowUp':
        e.preventDefault();
        focusPrevious();
        break;
      case ' ':
      case 'Enter':
        e.preventDefault();
        toggleSelection(options[focused]);
    }
  };

  const hasSelection = values.length > 0;

  return (
    <>
      <div>
        <SearchInput
          value={searchInput}
          onChange={setSearchInput}
          onKeyUp={searchResultHighlighter}
          placeholder={`Search ${name.split(' ').pop().toLowerCase()}`}
        />
        <Tooltip
          tooltipContent={`You can only select a maximum of ${maxSelectableOptions} items`}
          show={values.length >= maxSelectableOptions}
        >
          <div className="relative">
            <ul
              className={classNames(
                'mt-1 flex h-48 w-full flex-col rounded border bg-white py-1.5 focus:outline-none',
                '!overflow-y-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded focus-within:!overflow-y-auto hover:!overflow-y-auto',
                {
                  'border-mystic-500': !hasSelection,
                  'border-dodger-blue-500': hasSelection,
                  'pointer-events-none': loading,
                },
              )}
              onKeyDown={handleKeyDown}
              onMouseOut={clearFocus}
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  clearFocus;
                }
              }}
              onFocus={() => {
                if (focused === null) focusNext();
              }}
              tabIndex={0}
              role="listbox"
              ref={ref}
            >
              {options.length > 0 ? (
                options.map((option, index) => {
                  const isFocused = index === focused;
                  const isSelected = values.map((x) => x.value).includes(option.value);

                  // can't add more if max is reached, but should be able to deselect
                  const itemDisabled = !isSelected && values.length >= maxSelectableOptions;

                  return (
                    <ListItem
                      key={option.value}
                      option={option}
                      index={index}
                      isFocused={isFocused}
                      isSelected={isSelected}
                      toggleSelection={toggleSelection}
                      handleFocus={handleFocus}
                      liClassName={liClassName}
                      listDisabled={disabled}
                      itemDisabled={itemDisabled}
                    />
                  );
                })
              ) : (
                <li className="text-body-4 flex h-8 w-full min-w-0 items-center justify-center px-3.5 italic">
                  No result
                </li>
              )}
            </ul>
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white-lilac-500 bg-opacity-60">
                <Spinner className="h-7 w-7 text-cadet-blue-500" />
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    </>
  );
}

function SearchInput({ value, onChange, onKeyUp, placeholder }) {
  return (
    <div className="relative mt-2.5">
      <Input
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        onKeyUp={(e) => onKeyUp(e.target.value)}
        className="!px-9"
        leftIcon={<SearchIcon className="h-4 w-4 -translate-x-1 fill-current text-cadet-blue-500" />}
        rightIcon={
          value.length > 0 ? (
            <CloseButton
              buttonClass="!top-0 !-right-1 !mr-0 !my-0 !relative"
              label="clear search"
              onClick={() => {
                onChange('');
                onKeyUp('');
              }}
            />
          ) : null
        }
      />
    </div>
  );
}

function ListItem({
  option,
  index,
  isFocused,
  isSelected,
  toggleSelection,
  handleFocus,
  liClassName,
  listDisabled,
  itemDisabled,
}) {
  const disabled = listDisabled || itemDisabled;
  return (
    <li key={option.value}>
      <button
        role="menuitem"
        tabIndex={-1}
        onMouseOver={() => handleFocus(index)}
        onFocus={() => {}}
        onClick={() => toggleSelection(option)}
        disabled={disabled}
        className={classNames(
          'text-body-4 relative flex h-8 w-full min-w-0 items-center pl-5 pr-3.5 text-left text-black-pearl-500 focus:outline-none',
          {
            'bg-solitude-500': isFocused,
            'font-semibold': isSelected,
            'cursor-pointer': !disabled,
            'cursor-not-allowed': disabled,
          },
        )}
      >
        {isSelected && (
          <span className="absolute left-0 top-0 flex h-8 items-center pl-1.5">
            <TickIcon className="h-2.5 w-2.5 fill-current text-dodger-blue-500" />
          </span>
        )}
        <span className={`truncate ${liClassName}`}>{getOptionDisplayValue(option)}</span>
      </button>
    </li>
  );
}
