import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { IconButton, Popover, StyledPopoverPanel, PopoverItem } from '@crazyegginc/hatch';

import { generateInitialQuestionSchema } from '../SurveyEditor';
import { QuestionSelector } from './QuestionSelector';

import { useModal } from '/src/hooks';
import { DeleteQuestionModal } from '../../modals/DeleteQuestionModal';

import { ReactComponent as MenuIcon } from '@crazyegginc/hatch/dist/images/icon-menu-dots.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function QuestionMenu({ index, questionActions }) {
  const { values } = useFormikContext();
  const modal = useModal();

  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button
            as={IconButton}
            className="text-cadet-blue-500 hover:text-dodger-blue-500"
            icon={<MenuIcon className="h-4 w-4 rotate-90 fill-current" />}
            label="question menu"
          />
          <StyledPopoverPanel align="right" className="!w-56" data-testid="question-menu-panel">
            {({ close }) => (
              <>
                <Popover className="relative">
                  <Popover.Button as={PopoverItem} className="group flex items-center">
                    <PlusIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                    Add question before
                  </Popover.Button>
                  <QuestionSelector
                    align="right"
                    onSelection={(type, subtype) => {
                      questionActions.insert(index, generateInitialQuestionSchema(type, subtype));
                      close();
                    }}
                  />
                </Popover>
                <PopoverItem
                  onClick={() => {
                    questionActions.insert(
                      index,
                      Object.assign({}, JSON.parse(JSON.stringify(values.questions[index])), { id: uuid() }),
                    );
                    close();
                  }}
                  className="group flex items-center"
                >
                  <DuplicateIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                  Duplicate
                </PopoverItem>
                <PopoverItem
                  onClick={() => {
                    modal.show(
                      <DeleteQuestionModal
                        questionNumber={index + 1}
                        onConfirm={() => questionActions.remove(index)}
                      />,
                    );
                    close();
                  }}
                  className="group flex items-center"
                >
                  <DeleteIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-carnation-500" />
                  Delete
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
