import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import flatten from 'flat';
import { Button, SkeletonLine } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useNotifications, usePermissions, useModal } from '/src/hooks';
import { ssoSettingsQuery } from '/src/features/sso/queries';
import { saveSsoSettingsMutation } from '/src/features/sso/mutations';
import { FormPatchTouched, PersistFormik, ResetFormik } from '/src/utils/form';
import { SsoValidationModal } from '../components/modals/SsoValidationModal';
import { SsoRehydrateModal } from '../components/modals/SsoRehydrateModal';
import { Panel } from '../components/SsoUi';
import { UpgradePrompt } from '../components/messages/UpgradePrompt';
import { NotAllowedMessage } from '../components/messages/NotAllowedMessage';
import { GeneralErrorMessage } from '../components/messages/GeneralErrorMessage';
import { SsoSettingsForm } from '../components/SettingsForm';
import { SsoRulesForm } from '../components/RulesForm';
import { SsoEnabled } from '../components/SsoEnabled';

import { snakeToCamel } from '/src/utils/string';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';
import { FEATURES } from '/src/features/_global/constants';
import { PROVISIONING_CONDITION_TYPES, PROVISIONING_ACTION_TYPES } from '/src/features/sso/constants';

const mustUpgradeError = '[GraphQL] SSO is not allowed for your current plan type. Please upgrade.';
const notAllowedError = '[GraphQL] User is not authorized';

export const provisioningOptions = [
  {
    value: PROVISIONING_CONDITION_TYPES.ATTRIBUTE,
    label: 'User attribute',
  },
  {
    value: PROVISIONING_CONDITION_TYPES.EMAIL,
    label: 'Email',
  },
];

export const actionOptions = [
  {
    value: PROVISIONING_ACTION_TYPES.CREATE,
    label: 'Create as a new team member',
  },
  {
    value: PROVISIONING_ACTION_TYPES.EXISTING,
    label: 'Only allow existing team members / deny others',
  },
];

export const roleOptions = [
  {
    value: ACCOUNT_USER_ROLES.OWNER,
    label: 'Owner',
  },
  {
    value: ACCOUNT_USER_ROLES.MANAGER,
    label: 'Manager',
  },
  {
    value: ACCOUNT_USER_ROLES.READ_ONLY,
    label: 'Read-only',
  },
];

export const emptyCondition = { attribute: null, type: null, values: [''] };
export const defaultCondition = { attribute: null, type: PROVISIONING_CONDITION_TYPES.ANYONE, values: null };
export const emptyAction = {
  type: PROVISIONING_ACTION_TYPES.CREATE,
  role: ACCOUNT_USER_ROLES.MANAGER,
  specificSites: true,
  sites: [],
};

export const emptyRule = (id) => ({
  id,
  conditions: [{ ...emptyCondition }],
  action: { ...emptyAction },
});

export const defaultRule = {
  conditions: [{ ...defaultCondition }],
  action: { ...emptyAction },
};

const SsoSchema = Yup.object().shape({
  enabled: Yup.boolean().required('Enabled is a required field'),
  required: Yup.boolean().required('Required is a required field'),
  emailDomain: Yup.string(),
  organizationName: Yup.string(),
  spConfiguration: Yup.object()
    .shape({
      entityId: Yup.string().required('SP Entity ID a required field'),
    })
    .required('SP configuration is required'),
  idpConfiguration: Yup.object()
    .shape({
      entityId: Yup.string().required('IdP Entity ID is a required field'),
      targetUrl: Yup.string().required('IdP SSO URL is a required field'),
      certificate: Yup.string().required('You must provide a X.509 Certificate'),
    })
    .required('IdP configuration is required'),
  provisioningRules: Yup.array()
    .of(
      Yup.object().shape({
        action: Yup.object().shape({
          type: Yup.string().oneOf([...Object.values(PROVISIONING_ACTION_TYPES)]),
          specificSites: Yup.boolean()
            .when('type', {
              is: PROVISIONING_ACTION_TYPES.EXISTING,
              then: Yup.boolean().nullable(true),
            })
            .when('type', {
              is: PROVISIONING_ACTION_TYPES.CREATE,
              then: Yup.boolean().required(),
            }),
          sites: Yup.array()
            .of(Yup.number())
            .nullable(true)
            .when('type', {
              is: PROVISIONING_ACTION_TYPES.EXISTING,
              then: Yup.array().nullable(true),
            })
            .when(['type', 'specificSites'], {
              is: (type, specificSites) => type !== PROVISIONING_ACTION_TYPES.EXISTING && specificSites,
              then: Yup.array().of(Yup.number()).min(1, 'You must select at least one site to share'),
            }),
          role: Yup.string()
            .when('type', {
              is: PROVISIONING_ACTION_TYPES.EXISTING,
              then: Yup.string().nullable(true),
            })
            .when('type', {
              is: PROVISIONING_ACTION_TYPES.CREATE,
              then: Yup.string().oneOf([
                ACCOUNT_USER_ROLES.READ_ONLY,
                ACCOUNT_USER_ROLES.MANAGER,
                ACCOUNT_USER_ROLES.OWNER,
              ]),
            }),
        }),
        conditions: Yup.array()
          .of(
            Yup.object().shape({
              attribute: Yup.string()
                .nullable(true)
                .when('type', {
                  is: PROVISIONING_CONDITION_TYPES.ATTRIBUTE,
                  then: Yup.string().nullable(true).required('Attribute name is required'),
                }),
              type: Yup.string()
                .nullable(true)
                .oneOf([...Object.values(PROVISIONING_CONDITION_TYPES)], 'Cannot submit an empty condition'),
              values: Yup.array()
                .of(Yup.string().required('Must enter a value'))
                .nullable(true)
                .when('type', {
                  is: PROVISIONING_CONDITION_TYPES.EMAIL,
                  then: Yup.array().of(
                    Yup.string().email('Must be a valid email address').required('You must enter an email address'),
                  ),
                }),
            }),
          )
          .min(1, 'You must add at least one condition per rule')
          .required(),
      }),
    )
    .required(),
});

// eslint-disable-next-line no-restricted-syntax
export default function OptionsSsoSettingsPage() {
  const permissions = usePermissions();
  const canManageSSO = permissions.can('edit', FEATURES.SSO).allowed;

  return <div className="px-10">{!canManageSSO ? <UpgradePrompt /> : <SSOContent />}</div>;
}

function SSOContent() {
  const { currentUser } = useAuthContext();

  const [shouldPersist, setShouldPersist] = useState(false);
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const [shouldRehydrate, setShouldRehydrate] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const notifications = useNotifications();
  const modal = useModal();
  const lastValidatedAt = parseInt(window.localStorage.getItem('Sso-LastValidated')) || false;
  const previousChangeAuthor = parseInt(window.localStorage.getItem('Sso-ChangeAuthor')) || false;

  const {
    data,
    isInitialLoading,
    error,
    refetch: refetchSettings,
  } = useQuery({
    ...ssoSettingsQuery(),
  });

  const { data: saveData, mutate: mutateSaveSsoSettings } = useMutation(saveSsoSettingsMutation);

  const settings = data?.ssoSettings ?? null;

  useEffect(() => {
    if (
      settings &&
      window.localStorage.getItem('Sso-Form') &&
      lastValidatedAt &&
      settings.lastValidatedAt &&
      lastValidatedAt > settings.lastValidatedAt &&
      previousChangeAuthor &&
      currentUser.userId === previousChangeAuthor
    ) {
      setHasSavedChanges(true);
    }
  }, [currentUser.userId, lastValidatedAt, previousChangeAuthor, settings]);

  useEffect(() => {
    if (saveData?.saveSsoSettings?.needsValidation) {
      modal.show(
        <SsoValidationModal
          onConfirm={() => {
            setShouldPersist(true);
            setTimeout(async () => {
              if (!saveData?.saveSsoSettings?.validationUrl) return;

              try {
                let validationForm = document.createElement('form');
                validationForm.method = 'POST';
                validationForm.action = saveData.saveSsoSettings.validationUrl;

                let tokenInput = document.createElement('input');
                tokenInput.name = 'validation_token';
                tokenInput.value = saveData.saveSsoSettings.validationToken;

                validationForm.appendChild(tokenInput);
                document.body.appendChild(validationForm);
                validationForm.submit();

                modal.close();
              } catch (error) {
                notifications.error({
                  content: 'Failed to perform SSO validation. Please check your internet connection and try again.',
                  timeout: 3000,
                  context: { error },
                });
              }
            }, 1000);
          }}
          onDismiss={() => refetchSettings()}
        />,
      );
    }
  }, [
    modal,
    notifications,
    refetchSettings,
    saveData?.saveSsoSettings?.needsValidation,
    saveData?.saveSsoSettings.validationToken,
    saveData?.saveSsoSettings.validationUrl,
  ]);

  useEffect(() => {
    if (hasSavedChanges && !shouldRehydrate) {
      modal.show(
        <SsoRehydrateModal
          onConfirm={() => setShouldRehydrate(true)}
          onDismiss={() => {
            setHasSavedChanges(false);
            window.localStorage.removeItem('Sso-Form');
            window.localStorage.removeItem('Sso-LastValidated');
            window.localStorage.removeItem('Sso-ChangeAuthor');
          }}
        />,
      );
    }
  }, [hasSavedChanges, modal, shouldRehydrate]);

  if (isInitialLoading) {
    return (
      <Panel>
        <div className="flex flex-col gap-y-6">
          <SkeletonLine width="100%" />
          <SkeletonLine width="20%" />
          <SkeletonLine width="20%" />
          <SkeletonLine width="60%" />
        </div>
      </Panel>
    );
  }

  if (!data && error) {
    if (error.message === mustUpgradeError) {
      return <UpgradePrompt />;
    }

    if (error.message === notAllowedError) {
      return <NotAllowedMessage />;
    }

    return <GeneralErrorMessage />;
  }

  return (
    <>
      <Formik
        initialValues={{
          ...getInitialValues(settings),
        }}
        validationSchema={SsoSchema}
        onSubmit={async (values, helpers) => {
          const payload = {
            ...values,
            spConfiguration: {
              entityId: values.spConfiguration.entityId,
            },
            provisioningRules: values.provisioningRules.map(
              // eslint-disable-next-line no-unused-vars
              ({ action: { id, __typename: _action_typename, ...action }, conditions }) => ({
                action,
                conditions: [
                  // eslint-disable-next-line no-unused-vars
                  ...conditions.map(({ __typename: _condition_typename, ...condition }) => ({ ...condition })),
                ],
              }),
            ),
          };
          helpers.setSubmitting(true);
          mutateSaveSsoSettings(payload, {
            onError: (error) => {
              const fieldErrors = parseErrors(error.graphQLErrors);
              helpers.setErrors(fieldErrors);
            },
            onSuccess: (data) => {
              if (data?.saveSsoSettings?.ssoSettings) {
                notifications.success({
                  content: 'SSO settings saved successfully',
                  timeout: 3000,
                });
              }
              // set new values received from the backend
              helpers.resetForm({
                values: {
                  ...getInitialValues(data.saveSsoSettings.ssoSettings),
                },
              });
            },
            onSettled: () => helpers.setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, resetForm }) => (
          <Form>
            <FormPatchTouched showErrorNotification={true} />
            <PersistFormik
              name="Sso-Form"
              store={shouldPersist}
              rehydrate={shouldRehydrate}
              additionalKeyValues={{
                'Sso-LastValidated': saveData?.saveSsoSettings?.validationTimestamp,
                'Sso-ChangeAuthor': currentUser.userId,
              }}
            />
            {shouldReset ? (
              <ResetFormik
                onComplete={() => {
                  resetForm();
                  setShouldReset(false);
                }}
              />
            ) : null}

            <SsoSettingsForm settings={settings} />

            <h2 id="provisioning" className="text-header-3 mb-[15px]">
              Provisioning Rules
            </h2>
            <SsoRulesForm />

            <SsoEnabled settings={settings} />
            <div className="flex gap-x-3">
              <fieldset className="flex items-center gap-x-2.5">
                <Button type="submit" disabled={!dirty || isSubmitting}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button variant="cancel" disabled={!dirty || isSubmitting} onClick={resetForm}>
                  Cancel
                </Button>
              </fieldset>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function getInitialValues(values) {
  return {
    enabled: values.enabled || false,
    required: values.required || false,
    spConfiguration: {
      metadataUrl: values?.spConfiguration?.metadataUrl ?? '',
      acsUrl: values?.spConfiguration?.acsUrl ?? '',
      defaultEntityId: values?.spConfiguration?.defaultEntityId ?? '',
      entityId: values?.spConfiguration?.entityId ?? '',
      sloUrl: values?.spConfiguration?.sloUrl ?? '',
    },
    idpConfiguration: {
      certificate: values?.idpConfiguration?.certificate ?? '',
      entityId: values?.idpConfiguration?.entityId ?? '',
      targetUrl: values?.idpConfiguration?.targetUrl ?? '',
    },
    emailDomain: values?.emailDomain ?? '',
    organizationName: values?.organizationName ?? '',
    provisioningRules: values?.provisioningRules
      ? // eslint-disable-next-line no-unused-vars
        values.provisioningRules.map(({ __typename, ...rule }) => ({
          id: uuid(),
          ...rule,
        }))
      : [defaultRule],
  };
}

function parseErrors(graphQLErrors) {
  const graphQLError = graphQLErrors.find((gqlError) => gqlError.path.includes('saveSsoSettings'));
  const fieldErrors = {};
  for (const error of graphQLError.originalError.errors) {
    const fieldName = error.field
      .map((part) => {
        if (typeof part === 'number') {
          return String(part);
        }
        return snakeToCamel(part, false);
      })
      .join('.');
    fieldErrors[fieldName] = error.message;
  }
  return flatten.unflatten(fieldErrors);
}
