import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const installationFeatureKeys = createQueryKeys('installationFeature', {
  techRecommendation: ({ url }) => [{ url }],
});

export const techRecommendationQuery = ({ url }) => ({
  ...installationFeatureKeys.techRecommendation({ url }),
  meta: {
    query: gql`
      query RecommendedTechnology($url: String) {
        techRecommendation(url: $url) {
          analysisThreshold
          commonTechnologies
          found
          foundTechnologies
          issue
          method
          missingAnalysis
          name
          siteNames
        }
      }
    `,
  },
});
