import { Field, useFormikContext } from 'formik';
import { Select } from '@crazyegginc/hatch';

import { actionOptions } from '../../pages/sso';
import { PROVISIONING_ACTION_TYPES } from '/src/features/sso/constants';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';

export function ProvisioningActionSelect({ index, disabled = false }) {
  const { values, setFieldValue } = useFormikContext();
  const rule = values.provisioningRules?.[index];
  const action = rule?.action;

  const actionBase = `provisioningRules.[${index}].action`;

  return (
    <Field name={`${actionBase}.type`}>
      {() => (
        <div className="no-wrap-select-ul">
          <Select
            disabled={disabled}
            placeholder="Choose an action"
            options={actionOptions}
            value={actionOptions.find((option) => option.value === action.type)?.value}
            onChange={(value) => {
              setFieldValue(`${actionBase}.type`, value);
              // reset nested fields
              setFieldValue(`${actionBase}.sites`, value === PROVISIONING_ACTION_TYPES.CREATE ? [] : null);
              setFieldValue(`${actionBase}.specificSites`, value === PROVISIONING_ACTION_TYPES.CREATE ? true : null);
              setFieldValue(
                `${actionBase}.role`,
                value === PROVISIONING_ACTION_TYPES.CREATE ? ACCOUNT_USER_ROLES.MANAGER : null,
              );
            }}
          />
        </div>
      )}
    </Field>
  );
}
