import Honeybadger from '@honeybadger-io/js';
import {
  generateAudienceAddonFormat,
  generateBehaviorAddonFormat,
  generateAudienceEditorFormat,
  generateBehaviorEditorFormat,
} from '../../common/editor/common-editor-functions';
import { CTA_TYPES, ADDON_TYPES } from '/src/features/addons/constants';

// editor data format -> CTA data format
export function generateCTADataFormat({ values, isTemplate, id, publishedEdit, publishDraft }) {
  let content = {
    action: values.action,
    autoPosition: values.autoPosition,
    autoPositionFallbackToShow: values.autoPositionFallbackToShow,
    buttonTitle: values.buttonTitle,
    closable: values.closable,
    displayType: values.displayType,
    heading: values.heading,
    mode: values.mode,
    subtext: values.subtext,
    theme: values.theme,
    audience: generateAudienceAddonFormat(values.audience),
    behavior: generateBehaviorAddonFormat(values.behavior),
  };

  if (values.displayType === CTA_TYPES.BUTTON) {
    content.button = values.button;
  }
  if (values.displayType === CTA_TYPES.POPUP) {
    content.popup = values.popup;
  }
  if (values.displayType === CTA_TYPES.BAR) {
    content.bar = values.bar;
  }
  content = JSON.stringify(content);

  if (isTemplate) {
    if (id) {
      // AddonTemplateEditParams
      return {
        category: values.category,
        content,
        description: values.description,
        id: parseInt(id),
        name: values.name,
      };
    } else {
      // AddonTemplateCreateParams
      return {
        category: values.category,
        content,
        description: values.description,
        name: values.name,
        type: ADDON_TYPES.CTA,
      };
    }
  } else {
    if (id) {
      // AddonEditParams
      if (publishedEdit) {
        if (publishDraft) {
          return {
            id,
            name: values.name,
            content,
            draftName: null,
            draftContent: null,
            siteId: values.audience.site.id,
          };
        }
        return {
          id,
          draftName: values.name,
          draftContent: content,
          siteId: values.audience.site.id,
        };
      }
      return {
        id,
        content,
        goalId: values.goalId,
        name: values.name,
        siteId: values.audience.site.id,
      };
    } else {
      // AddonCreateParams
      return {
        active: false,
        addonTemplateId: values.addonTemplateId !== undefined ? parseInt(values.addonTemplateId) : null,
        suggestionId: values.suggestionId !== undefined ? parseInt(values.suggestionId) : null,
        content,
        goalId: values.goalId,
        name: values.name,
        type: ADDON_TYPES.CTA,
      };
    }
  }
}

// addon data format -> editor data format
export function generateCTAEditorFormat({
  name,
  content,
  description,
  category,
  addonTemplateId,
  suggestionId,
  site,
  goalId,
}) {
  let parsedContent = {};

  try {
    parsedContent = JSON.parse(content);
  } catch (error) {
    Honeybadger.notify('Failed to parse saved CTA content', {
      context: { error, content },
      name: 'Edit CTA error',
    });
  }

  const result = {
    ...parsedContent,
  };

  if (name) {
    result.name = name;
  }
  if (description) {
    result.description = description;
  }
  if (category) {
    result.category = category;
  }
  if (addonTemplateId != null) {
    result.addonTemplateId = addonTemplateId;
  }
  if (suggestionId != null) {
    result.suggestionId = suggestionId;
  }

  result.audience = generateAudienceEditorFormat(parsedContent.audience, site);
  result.behavior = generateBehaviorEditorFormat(parsedContent.behavior);

  if (goalId != null) {
    result.goalId = goalId;
  }

  return result;
}
