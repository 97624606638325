import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const trafficAnalysisFeatureKeys = createQueryKeys('trafficAnalysisFeature', {
  trafficAnalysis: ({ siteId, startAt, endAt, limit }) => [
    {
      siteId,
      startAt,
      endAt,
      limit,
    },
  ],
  trafficAnalysisUrls: ({ siteId, startAt, endAt, filters, order }) => [
    {
      siteId,
      startAt,
      endAt,
      filters,
      order,
    },
  ],
});

export const trafficAnalysisQuery = ({ siteId, startAt, endAt, limit }) => ({
  ...trafficAnalysisFeatureKeys.trafficAnalysisUrls({ siteId, startAt, endAt, limit }),
  meta: {
    query: gql`
      query TrafficAnalysis($siteId: Int!, $startAt: Int!, $endAt: Int!, $limit: Int) {
        trafficAnalysis(siteId: $siteId, startAt: $startAt, endAt: $endAt, limit: $limit) {
          referrerUrl
          utmSource
          utmCampaign
          utmMedium
          utmTerm
          utmContent
          total
        }
      }
    `,
  },
});

export const trafficAnalysisUrlsQuery = ({ siteId, startAt, endAt, filters, order }) => ({
  ...trafficAnalysisFeatureKeys.trafficAnalysisUrls({ siteId, startAt, endAt, filters, order }),
  meta: {
    query: gql`
      query TrafficAnalysisUrls(
        $siteId: Int!
        $startAt: Int!
        $endAt: Int!
        $order: TrafficAnalysisUrlsOrder
        $filters: [TrafficAnalysisUrlsQuery!]
      ) {
        trafficAnalysisUrls(siteId: $siteId, startAt: $startAt, endAt: $endAt, order: $order, filters: $filters) {
          filtersTotals
          total
          url
        }
      }
    `,
  },
});
