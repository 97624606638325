import { useState, useLayoutEffect, useEffect, Children, createRef, useMemo } from 'react';
import { PlaylistTile } from '@crazyegginc/hatch';

import { usePrevious } from '/src/hooks';

const onHoverTransition = 'transform 0.2s ease-out';
const tileBreakPoint = 1440;

function getNumberOfTiles() {
  return window.innerWidth < tileBreakPoint ? 3 : 4;
}

export function PlaylistTiles({ playlists, selectPlaylist }) {
  const [numOfTiles, setNumOfTiles] = useState(getNumberOfTiles());
  const [list, setList] = useState(playlists.slice(0, numOfTiles));
  const prevPlaylists = usePrevious(playlists);

  useEffect(() => {
    // handle resize
    function updateNumberOfTiles() {
      setNumOfTiles(getNumberOfTiles());
    }
    window.addEventListener('resize', updateNumberOfTiles);
    return () => window.removeEventListener('resize', updateNumberOfTiles);
  }, []);

  useEffect(() => {
    // adjust list when numOfTiles goes 3->4
    if (list.length < numOfTiles) {
      setList(playlists.slice(0, numOfTiles));
    }
  }, [playlists, numOfTiles, list.length]);

  useEffect(() => {
    // new playlists received
    if (playlists !== prevPlaylists && prevPlaylists) {
      if (!prevPlaylists?.includes(playlists[0])) {
        // new element
        setList(playlists.slice(0, numOfTiles + 1));
      } else {
        // just shuffling
        setList(playlists.slice(0, numOfTiles));
      }
    }
  }, [playlists, prevPlaylists, numOfTiles]);

  useEffect(() => {
    //remove tile that exited to the right after animation
    let timer;
    if (list.length > numOfTiles) {
      timer = setTimeout(() => {
        setList((x) => x.slice(0, numOfTiles));
      }, 800);
    }
    return () => clearTimeout(timer);
  }, [list.length, numOfTiles]);

  const tileList = useMemo(() => {
    return list.map((item) => (
      <PlaylistTile
        key={item.id}
        name={item.name}
        onClick={() => selectPlaylist(item.id)}
        ref={createRef()}
        numOfTiles={numOfTiles}
      />
    ));
  }, [list, numOfTiles, selectPlaylist]);

  return <AnimateTiles>{tileList}</AnimateTiles>;
}

function AnimateTiles({ children }) {
  const [boundingBox, setBoundingBox] = useState({});
  const prevBoundingBox = usePrevious(boundingBox);
  const [animate, setAnimate] = useState(false);

  useLayoutEffect(() => {
    function calcBoxes(resize) {
      const newBoundingBoxes = {};

      Children.forEach(children, (child) => {
        newBoundingBoxes[child.key] = child.ref.current?.getBoundingClientRect();
      });

      setBoundingBox(newBoundingBoxes);
      if (!resize) setAnimate(true);
    }
    calcBoxes(false);

    window.addEventListener('resize', () => calcBoxes(true));
    return () => window.removeEventListener('resize', () => calcBoxes(true));
  }, [children]);

  useLayoutEffect(() => {
    if (!animate) return;
    const hasPrevBoundingBox = prevBoundingBox && Object.keys(prevBoundingBox).length;

    if (hasPrevBoundingBox) {
      Children.forEach(children, (child) => {
        const domNode = child.ref.current;
        const firstBox = prevBoundingBox[child.key];
        const lastBox = boundingBox[child.key];

        if (firstBox && lastBox) {
          const changeInX = firstBox.left - lastBox.left;
          if (changeInX) {
            requestAnimationFrame(() => {
              domNode.style.transform = `translateX(${changeInX}px)`;
              domNode.style.transition = 'transform 0s';

              const transitionTime = 500;
              requestAnimationFrame(() => {
                domNode.style.transform = '';
                domNode.style.transition = `transform ${transitionTime}ms`;

                // wait for animation to finish, then reset transition to default
                let start;
                function resetTransition(timestamp) {
                  if (start === undefined) start = timestamp;
                  const elapsed = timestamp - start;
                  if (elapsed < transitionTime) {
                    requestAnimationFrame(resetTransition);
                  } else {
                    domNode.style.transition = onHoverTransition;
                  }
                }
                requestAnimationFrame(resetTransition);
              });
            });
          }
        } else {
          requestAnimationFrame(() => {
            domNode.style.opacity = 0;
            domNode.style.transition = 'opacity 0s';

            requestAnimationFrame(() => {
              domNode.style.opacity = 1;
              domNode.style.transition = 'opacity 0.5s 0.2s, ' + onHoverTransition;
            });
          });
        }
      });
    }
  }, [boundingBox, prevBoundingBox, children, animate]);

  useEffect(() => {
    setAnimate(false);
  }, [animate]);

  return (
    <div className="flex h-[128px] items-center overflow-hidden pl-4" data-testid="playlistTiles">
      {children}
    </div>
  );
}
