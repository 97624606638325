import { useRef, useState } from 'react';
import classNames from 'classnames';
import { CloseButton, Button, RadioGroup, Input, Tooltip, Spinner } from '@crazyegginc/hatch';

import { useMutation, useOutsideClickNotify, useSelectedSite } from '/src/hooks';
import { savePlaylistMutation } from '/src/features/recordings/mutations';
import { composeFilter } from './filter-functions';
import { PLAYLIST_TYPES } from '/src/features/recordings/constants';

const maxNameLength = 35;

const radioOptions = [
  { value: 'current', label: 'Save changes to current playlist' },
  { value: 'new', label: 'Create a new playlist' },
];

export function PlaylistSaveDialog({ open, onClose, buttonRef, filtersHook }) {
  const { selectedSite } = useSelectedSite();
  const dialogRef = useRef();
  const [name, setName] = useState('');
  const [selectedRadio, setSelectedRadio] = useState(radioOptions[0].value);
  const [saving, setSaving] = useState(false);
  const savePlaylist = useMutation(savePlaylistMutation);

  const { filters, playlists, selectPlaylist, filtersVersion, playlistsListQuery } = filtersHook;

  const playlist = playlistsListQuery.data?.playlistsList.find((x) => x.id === playlists.active);

  useOutsideClickNotify([dialogRef, buttonRef], () => closeDialog(), open && name.length < maxNameLength);

  if (!open) return null;

  function closeDialog() {
    setName('');
    onClose();
  }

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxNameLength) {
      setName(value);
    }
  };

  const handleSave = () => {
    if (saving) return;
    setSaving(true);

    if (
      !playlists.active ||
      (playlists.active && selectedRadio === 'new') ||
      (playlists.active && playlist?.type === PLAYLIST_TYPES.RECOMMENDED)
    ) {
      // new playlist
      const params = {
        filter: JSON.stringify(composeFilter(filters, filtersVersion)),
        name,
      };
      const start = performance.now();
      savePlaylist.mutate(
        { site: selectedSite?.id, playlist: params },
        {
          onSuccess: (data) => selectPlaylist(data.savePlaylist.id),
          onSettled: () => {
            const end = performance.now();
            setTimeout(
              () => {
                setSaving(false);
                closeDialog();
              },
              Math.max(1200 - (end - start), 0),
            );
          },
        },
      );
    } else {
      // update current playlist
      const params = {
        filter: JSON.stringify(composeFilter(filters, filtersVersion)),
        name: playlist.name,
        id: playlist.id,
      };
      const start = performance.now();
      savePlaylist.mutate(
        { site: selectedSite?.id, playlist: params },
        {
          onSettled: () => {
            const end = performance.now();
            setTimeout(
              () => {
                setSaving(false);
                closeDialog();
              },
              Math.max(1200 - (end - start), 0),
            );
          },
        },
      );
    }
  };

  let buttonText;

  if (!playlists.active) {
    if (!saving) {
      buttonText = 'Save playlist';
    } else {
      buttonText = 'Saving playlist...';
    }
  } else {
    if (selectedRadio === 'current' && playlist?.type !== PLAYLIST_TYPES.RECOMMENDED) {
      if (!saving) {
        buttonText = 'Update current playlist';
      } else {
        buttonText = 'Updating playlist...';
      }
    } else {
      if (!saving) {
        buttonText = 'Save new playlist';
      } else {
        buttonText = 'Saving playlist...';
      }
    }
  }

  return (
    <>
      <span
        className="fixed inset-0 z-[9998] block"
        style={{
          background: 'rgba(6,20,33,0.1)',
        }}
      />
      <div
        className={classNames(
          'flex w-[350px] animate-scalein flex-col rounded-md border border-mystic-500 bg-white p-6 shadow-md',
          'absolute right-0 top-[35px] z-[9999]',
        )}
        ref={dialogRef}
        data-testid="savePlaylistDialog"
      >
        <CloseButton onClick={() => closeDialog()} label="close save playlist dialog" />
        <div className="text-header-3 mb-8">{playlists.active ? 'Save playlist' : 'Save a new playlist'}</div>

        {playlists.active && playlist?.type === PLAYLIST_TYPES.USER && (
          <div className="mb-5">
            <RadioGroup value={selectedRadio} onChange={setSelectedRadio} options={radioOptions} />
          </div>
        )}

        {(!playlists.active ||
          (playlists.active && selectedRadio === 'new') ||
          (playlists.active && playlist?.type === PLAYLIST_TYPES.RECOMMENDED)) && (
          <Tooltip
            tooltipContent={
              <div className="m-2 w-[180px] text-left">Please keep playlist name under {maxNameLength} characters</div>
            }
            placement="bottom"
            skiddingPercent="10"
            arrowSkiddingPercent="15"
            hover={false}
            show={name.length >= maxNameLength}
            offset={6}
            containerStyle={{ zIndex: 10000 }}
          >
            <Input
              type="text"
              value={name}
              label="Give your playlist a name"
              id="new-playlist"
              onChange={handleNameChange}
              aria-label="playlist name"
              // eslint-disable-next-line
              autoFocus={true}
              onKeyPress={(e) => {
                if (e.key !== 'Enter' || name.length >= maxNameLength || name.length === 0) {
                  return;
                }
                handleSave();
              }}
            />
          </Tooltip>
        )}

        <div className="mt-[30px] flex">
          <Button
            disabled={
              ((!playlists.active || (playlists.active && selectedRadio === 'new')) &&
                (name.length === 0 || name.length >= maxNameLength)) ||
              saving
            }
            onClick={handleSave}
          >
            {saving && (
              <div className="mr-2.5">
                <Spinner />
              </div>
            )}
            {buttonText}
          </Button>
          <Button variant="cancel" className="!ml-2.5" onClick={() => closeDialog()}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}
