import { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { LoaderBalloon } from '@crazyegginc/hatch';

import { useMutation } from '/src/hooks';
import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';

import { Event } from '/src/features/whats-new/components/Event';
import { changeLogQuery } from '/src/features/whats-new/queries';
import { viewChangelogEventsMutation } from '/src/features/whats-new/mutations';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

function Changelog() {
  const [synced, setSynced] = useState(false);
  const { data, fetching } = useQuery(changeLogQuery({ newOnly: true }));

  const viewEvents = useMutation(viewChangelogEventsMutation);

  const unseenEvents = useMemo(() => (data?.changelog ?? []).filter((event) => event.seenAt === null), [data]);

  useEffect(() => {
    if (!synced && unseenEvents.length > 0) {
      const ids = unseenEvents.map((event) => parseInt(event.id));
      viewEvents.mutate(
        { ids },
        {
          onSuccess: () => {
            window.sessionStorage.setItem('unseenEvents', JSON.stringify(ids));
            setSynced(true);
          },
        },
      );
    }
  }, [synced, unseenEvents, viewEvents]);

  return (
    <DashboardPage>
      <div className="flex min-h-screen flex-col">
        <SEO title={getFeatureName(FEATURES.WHATS_NEW)} />
        <DashHeader title={getFeatureName(FEATURES.WHATS_NEW)} />
        {fetching ? (
          <div className="box-content flex w-full flex-1 items-center justify-center">
            <LoaderBalloon />
          </div>
        ) : (
          <div className="mt-12 flex justify-center">
            <div className="ml-36 mr-16 flex flex-col items-start xl:ml-64 xl:mr-64">
              {data?.changelog?.map((event) => (
                <Event key={`Event${event.id}`} event={event} />
              ))}
            </div>
          </div>
        )}
      </div>
    </DashboardPage>
  );
}

export default Changelog; // eslint-disable-line
