import { gql } from '@urql/core';

import { globalFeatureKeys } from '/src/features/_global/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const setUserDataMutation = ({ client, queryClient }) => ({
  mutationFn: ({ name, value }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SetUserData($name: String!, $value: String) {
          setUserData(name: $name, value: $value)
        }
      `,
      {
        name,
        value,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ ...globalFeatureKeys.userData });
  },
});

export const setSiteColors = ({ client, queryClient }) => ({
  mutationFn: ({ siteId, colors }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SetSiteColors($siteId: Int!, $colors: [String]!) {
          setSiteColors(siteId: $siteId, colors: $colors) {
            id
            name
            metadata
          }
        }
      `,
      {
        siteId,
        colors,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ queryKey: ['sites'] });
  },
});
