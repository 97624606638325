import { useState, useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { startOfDay, endOfDay, subYears, getUnixTime } from 'date-fns';
import { ScrollToTop, DateRangePicker, Button } from '@crazyegginc/hatch';

import { useAuthContext, useQueryParams } from '/src/hooks';
import { getInitialDate, getConvertedDateRange } from '/src/utils/date';

import { ActionSelector } from './ActionSelector';
import { UserSelector } from './UserSelector';
import { AuditLogTable } from './AuditLogTable';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { NoAuditTrailSelection } from '../paywalls/NoAuditTrailSelection';

import { SORT_ORDER_TYPES, SPECIAL_DATE_RANGES, ALL, NONE } from '/src/features/_global/constants';
import { defaultActionsToInclude } from '../constants';
import { teamMembersQuery } from '/src/features/_global/queries';
import { auditableActionListQuery } from '/src/features/audit-log/queries';

import { ReactComponent as DownloadIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-download.svg';

const actionsParam = 'actions';
const usersParam = 'users';

export function AuditLogContent() {
  const { set: queryParamsSet, get: queryParamsGet, remove: queryParamsRemove } = useQueryParams();
  const [sort, setSort] = useState(SORT_ORDER_TYPES.DESC);
  const [dateRange, setDateRange] = useState(
    getInitialDate(queryParamsGet('date')) ?? { special: SPECIAL_DATE_RANGES.LAST_30_DAYS },
  );

  const [initialSelectedUsers] = useState(() => queryParamsGet(usersParam));
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [initialSelectedAction] = useState(() => queryParamsGet(actionsParam));
  const [selectedActions, setSelectedActions] = useState(null);

  const { data: teamMembersData, isFetching: fetchingTeamMember } = useQuery({
    ...teamMembersQuery({}),
  });

  const listOfUsers = useMemo(() => {
    return teamMembersData?.teamMembers ?? [];
  }, [teamMembersData?.teamMembers]);

  const convertedDateRange = getConvertedDateRange(dateRange);
  const noSelectedActions = selectedActions?.length === 0;
  const noSelectedUsers = selectedUsers?.length === 0;
  const isBothSelected = !noSelectedActions && !noSelectedUsers;

  const { data, isLoading, hasNextPage, fetchNextPage, isInitialLoading } = useInfiniteQuery({
    ...auditableActionListQuery({
      filter: {
        actionsToInclude: selectedActions,
        startAt: convertedDateRange.startDate,
        endAt: convertedDateRange.endDate,
        sort: sort,
        users: selectedUsers,
      },
    }),
    enabled: selectedUsers !== null,
  });

  const auditTrail = useMemo(
    () =>
      (data?.pages ?? []).reduce((acc, page) => [...acc, ...(page?.auditableActionList?.auditableActions ?? [])], []) ??
      [],
    [data],
  );

  const listOfAuditTrail = auditTrail.filter((trail) => trail !== null);

  const setDateRangeWrapper = useCallback(
    (value) => {
      setDateRange(value);
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({ start_date: getUnixTime(value.start_date), end_date: getUnixTime(value.end_date) }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
    },
    [setDateRange, queryParamsSet],
  );

  useEffect(() => {
    if (initialSelectedAction === undefined) {
      setSelectedActions(defaultActionsToInclude);
    } else if (initialSelectedAction === NONE) {
      return [];
    } else {
      try {
        const parsed = JSON.parse(initialSelectedAction);

        if (Array.isArray(parsed) && parsed.every((x) => defaultActionsToInclude.includes(x))) {
          setSelectedActions(parsed);
        } else {
          queryParamsRemove(actionsParam);
          setSelectedActions(defaultActionsToInclude);
        }
      } catch {
        queryParamsRemove(actionsParam);
        setSelectedActions(defaultActionsToInclude);
      }
    }
  }, [queryParamsRemove, initialSelectedAction]);

  useEffect(() => {
    if (!fetchingTeamMember) {
      if (initialSelectedUsers === undefined) {
        setSelectedUsers(listOfUsers.map((user) => user.id));
      } else if (initialSelectedUsers === NONE) {
        setSelectedUsers([]);
      } else {
        try {
          setSelectedUsers(JSON.parse(initialSelectedUsers));
        } catch {
          queryParamsSet(usersParam, undefined);
          setSelectedUsers(listOfUsers.map((user) => user.id));
        }
      }
    }
  }, [queryParamsSet, initialSelectedUsers, listOfUsers, fetchingTeamMember]);

  const selectUserParams = useCallback(
    (selected) => {
      if (selected === ALL || listOfUsers.length === selected.length) {
        queryParamsSet(usersParam, undefined);
        setSelectedUsers(listOfUsers.map((user) => user.id));
      } else if (selected === NONE || selected.length === 0) {
        queryParamsSet(usersParam, NONE);
        setSelectedUsers([]);
      } else {
        queryParamsSet(usersParam, JSON.stringify(selected));
        setSelectedUsers(selected);
      }
    },
    [queryParamsSet, listOfUsers],
  );

  const selectActionParams = useCallback(
    (selected) => {
      if (selected === ALL) {
        queryParamsSet(actionsParam, undefined);
        setSelectedActions(defaultActionsToInclude);
      } else if (selected === NONE || selected.length === 0) {
        queryParamsSet(actionsParam, NONE);
        setSelectedActions([]);
      } else {
        queryParamsSet(actionsParam, JSON.stringify(selected));
        setSelectedActions(selected);
      }
    },
    [queryParamsSet],
  );

  return (
    <>
      <div className="mb-4 flex w-full justify-between">
        <span className="flex space-x-2.5">
          <ActionSelector selectedActions={selectedActions} setActionParams={selectActionParams} />
          <UserSelector userList={listOfUsers} selectedUsers={selectedUsers} setUsersParams={selectUserParams} />
        </span>

        <div className="flex flex-shrink-0 items-center space-x-2.5">
          <DownloadLink
            actions={selectedActions}
            sort={sort}
            dateRange={dateRange}
            disabled={listOfAuditTrail.length === 0}
          />
          <DateRangePicker
            lowerBoundary={startOfDay(subYears(new Date(), 2))}
            upperBoundary={endOfDay(new Date())}
            startDate={dateRange?.start_date}
            endDate={dateRange?.end_date}
            special={dateRange?.special}
            setDateRange={setDateRangeWrapper}
            setCommonDateRange={setDateRangeWrapper}
            showCommonRanges={true}
            maxRange={31}
            size="lg"
          />
        </div>
      </div>

      {listOfAuditTrail.length === 0 && !fetchingTeamMember && !isInitialLoading ? (
        <NoMatchesWall text="matches for this search" />
      ) : isBothSelected || fetchingTeamMember || isInitialLoading ? (
        <>
          <AuditLogTable
            setSort={setSort}
            sort={sort}
            listOfAuditTrail={listOfAuditTrail}
            fetching={isLoading}
            hasNextPage={hasNextPage}
            loadNextPage={fetchNextPage}
          />

          <ScrollToTop />
        </>
      ) : (
        <NoAuditTrailSelection noSelectedActions={noSelectedActions} noSelectedUsers={noSelectedUsers} />
      )}
    </>
  );
}

function DownloadLink({ actions, sort, dateRange, disabled }) {
  const { token } = useAuthContext();
  const dates = getConvertedDateRange(dateRange);
  const actionsParams = actions?.reduce((prev, curr) => `${prev}&actions[]=${curr}`, ``);

  return (
    <Button
      variant="secondary"
      component="a"
      size="lg"
      href={`${window.CORE_API_URL}/export/audit_trail.csv?sort=${sort}&startDate=${dates.startDate}&endDate=${dates.endDate}${actionsParams}&token=${token}`}
      download={true}
      disabled={disabled}
      className={classNames({ 'pointer-events-none': disabled })}
    >
      <DownloadIcon className="mr-2.5 h-4 w-4 fill-current" />
      Download as CSV
    </Button>
  );
}
