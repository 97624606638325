/* eslint-disable no-console */
import { useEffect, useState, useCallback, memo } from 'react';
import Honeybadger from '@honeybadger-io/js';

import { newClient } from '/src/graphql/urql';
import {
  PAGE_CAMERA_SNAPSHOTS,
  PAGE_CAMERA_URL_UPLOAD_POLICY,
  PAGE_CAMERA_SNAPSHOT_UPLOAD_POLICY,
  PAGE_CAMERA_IS_LOGGED_IN,
} from '/src/features/snapshots/queries';
import { PAGE_CAMERA_SET_SCREENSHOT_STATUS } from '/src/features/snapshots/mutations';
import { useNotifications } from '/src/hooks';

let client;
let notifications;

function sendResponse(receipt, result) {
  window.postMessage({ type: 'page_camera_webapi', receipt, result }, '*');
}

async function snapshots() {
  const result = await makeQuery(PAGE_CAMERA_SNAPSHOTS);
  return result?.pageCameraSnapshots;
}

async function getUploadPolicy(params) {
  if (params.snapshot?.id) {
    const result = await makeQuery(PAGE_CAMERA_SNAPSHOT_UPLOAD_POLICY, { snapshotId: params.snapshot.id });
    return result?.pageCameraGetUploadPolicy;
  } else {
    const result = await makeQuery(PAGE_CAMERA_URL_UPLOAD_POLICY, {
      sourceUrl: params.snapshot.url,
      device: params.snapshot.device || 'desktop',
    });
    return result?.pageCameraGetUploadPolicy;
  }
}

async function setScreenshotStatus(params) {
  return makeMutation(PAGE_CAMERA_SET_SCREENSHOT_STATUS, {
    snapshotId: params.snapshotId,
    recapture: params.recapture,
  });
}

async function isLoggedIn({ skipSnapshotCount }) {
  const result = await makeQuery(PAGE_CAMERA_IS_LOGGED_IN);
  if (result) {
    return {
      ...result?.pageCameraIsLoggedIn,
      overNumberOfSnapshots: skipSnapshotCount ? null : result?.pageCameraIsLoggedIn?.overNumberOfSnapshots,
    };
  } else {
    return { loggedIn: false };
  }
}

function makeQuery(query, variables) {
  return client
    .query(query, variables)
    .toPromise()
    .then(({ data, error }) => {
      if (error) {
        notifications.error({
          content: `Page Camera API request failed`,
          timeout: 3000,
          context: { error },
          skipHoneybadger: true,
        });

        Honeybadger.notify('Page Camera API query failed', {
          context: {
            graphQLErrors: error.graphQLErrors,
            networkError: error.networkError,
            query,
            variables,
            currentPath: window.location.pathname,
          },
        });

        return null;
      }

      return data;
    });
}

function makeMutation(mutation, variables) {
  return client
    .mutation(mutation, variables)
    .toPromise()
    .then(({ data, error }) => {
      if (error) {
        Honeybadger.notify('Failed to set Snapshot screenshot status', {
          context: { error, variables, currentPath: window.location.pathname },
        });
        notifications.error({ content: `Unable to make api request`, timeout: 3000, context: { error } });
        return null;
      }

      return data;
    });
}

function PageCameraApiComponent({ skipSnapshotCount = false, snapshotId = null, onSuccess = null, onUpload = null }) {
  client = newClient();
  notifications = useNotifications();
  const [ready, setReady] = useState(false);

  const onMessage = useCallback(
    async (event) => {
      if (event.source != window || event.data?.type !== 'page_camera_extension') {
        return;
      }

      const { request, params, receipt } = event.data;

      switch (request) {
        case 'snapshots': {
          console.log('[PageCamera] Retrieving snapshots...');
          const data = await snapshots();
          sendResponse(receipt, data);
          break;
        }
        case 'getUploadPolicy':
          console.log('[PageCamera] Getting upload policy');
          sendResponse(receipt, await getUploadPolicy(params));
          break;
        case 'isLoggedIn':
          console.log('[PageCamera] Checking auth...');
          sendResponse(receipt, await isLoggedIn({ skipSnapshotCount }));
          break;
        case 'setScreenshotStatus':
          console.log('[PageCamera] Setting screenshot status');
          setScreenshotStatus(params).then((result) => {
            sendResponse(receipt, result);
            if (result) {
              setTimeout(() => {
                console.log('[PageCamera] Screenshot status updated!');
                onSuccess?.([snapshotId]);
              }, 250);
            }
          });
          break;
        case 'setUploadStatus':
          console.log('[PageCamera] Setting upload status');
          onUpload?.(params);
          break;
      }
    },
    [onSuccess, onUpload, snapshotId, skipSnapshotCount],
  );

  useEffect(() => {
    const pageCameraUploadStatus = window.sessionStorage.getItem('pageCamera.uploadStatus');
    const didUpload = pageCameraUploadStatus === 'done';

    const pageCameraScreenshotStatus = window.sessionStorage.getItem('pageCamera.updatedScreenshotStatus');
    const didUpdateStatus = pageCameraScreenshotStatus === 'true';

    let checkInterval;

    const removeInterval = () => {
      clearInterval(checkInterval);
      checkInterval = null;
    };

    if (onSuccess && didUpdateStatus) {
      console.log('[PageCamera] Skipping set status');
      onSuccess([snapshotId]);
    } else if (onUpload && didUpload) {
      console.log('[PageCamera] Skipping upload');
      onUpload({ status: 'done' });
    } else {
      window.addEventListener('message', onMessage);
      console.log('[PageCamera] Listener is ready');

      checkInterval = setInterval(() => {
        const pageCameraUploadStatus = window.sessionStorage.getItem('pageCamera.uploadStatus');
        const didUpload = pageCameraUploadStatus === 'done';

        const pageCameraScreenshotStatus = window.sessionStorage.getItem('pageCamera.updatedScreenshotStatus');
        const didUpdateStatus = pageCameraScreenshotStatus === 'true';

        if (onSuccess && didUpdateStatus) {
          console.log('[PageCamera] Already updated status');
          removeInterval();
          onSuccess([snapshotId]);
        }

        if (onUpload && didUpload) {
          console.log('[PageCamera] Already uploaded');
          removeInterval();
          onUpload({ status: 'done' });
        }
      }, 5000);
    }

    setReady(true);

    return () => {
      window.removeEventListener('message', onMessage);
      console.log('[PageCamera] Listener is removed');
      clearInterval(checkInterval);
      checkInterval = null;
    };
  }, [setReady, onMessage, onUpload, onSuccess, snapshotId]);

  if (!ready) {
    return null;
  }

  console.log('[PageCamera] mounting Page Camera API');

  return <div className="page_camera_api"></div>;
}

export const PageCameraApi = memo(PageCameraApiComponent);
