import { useMutation, useModal } from '/src/hooks';
import { inflect } from '/src/utils/string';

import { RegenerateUrlModal } from '/src/features/team-and-sharing/components/sharing/modals/RegenerateUrlModal';
import { UpdateUrlStatusModal } from '/src/features/team-and-sharing/components/sharing/modals/UpdateUrlStatusModal';
import { ActionIcon } from '/src/components/bulk-action/ActionIcon';

import { regenerateSharedUrlListMutation, updateSharedUrlListMutation } from '/src/features/team-and-sharing/mutations';
import { SHARED_URL_STATUSES } from '/src/features/team-and-sharing/constants';

import { ReactComponent as PausedIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as GenerateNewUrlIcon } from '@crazyegginc/hatch/dist/images/icon-switch-outline.svg';

export function SharedUrlsBulkActions({ onActionStart, onSuccess, onError, selected, selectedIds }) {
  const modal = useModal();
  const regenerateSharedUrlList = useMutation(regenerateSharedUrlListMutation);
  const updateSharedUrlList = useMutation(updateSharedUrlListMutation);
  const selectedNum = selectedIds.length;
  const isSomeSelectedEnabled =
    selectedNum > 1 && selected.some((select) => select?.status === SHARED_URL_STATUSES.ENABLED);
  const isSomeSelectedDisabled =
    selectedNum > 1 && selected.some((select) => select?.status === SHARED_URL_STATUSES.DISABLED);

  const selectedFilterProps = (status) => {
    return selected.map((select) => ({
      id: select.id,
      note: select.note,
      status,
    }));
  };

  return (
    <>
      <ActionIcon
        img={GenerateNewUrlIcon}
        alt="Generate new url"
        tooltip="Generate new url"
        onClick={() => {
          modal.show(
            <RegenerateUrlModal
              numToGenerate={selectedNum}
              onRegenerate={() => {
                onActionStart();
                regenerateSharedUrlList.mutate(
                  { ids: [...selectedIds] },
                  {
                    onError: (error) => onError({ error, name: 'Generate new sharing URLs' }),
                    onSuccess: () =>
                      onSuccess({
                        deselectAll: true,
                        message: `${selectedNum} ${inflect('share link', selectedNum)} ${
                          selectedNum > 1 ? 'have' : 'has'
                        } been regenerated.`,
                      }),
                    onSettled: () => modal.close(),
                  },
                );
              }}
            />,
          );
        }}
      />

      {((isSomeSelectedEnabled && isSomeSelectedDisabled) || selected[0]?.status === SHARED_URL_STATUSES.DISABLED) && (
        <ActionIcon
          img={PlayIcon}
          alt="Resume link sharing"
          tooltip="Resume link sharing"
          iconClassName="pl-1"
          onClick={() => {
            modal.show(
              <UpdateUrlStatusModal
                status={SHARED_URL_STATUSES.DISABLED}
                numToUpdate={selectedNum}
                onStatusUpdate={() => {
                  onActionStart();
                  updateSharedUrlList.mutate(
                    { sharedUrls: selectedFilterProps(SHARED_URL_STATUSES.ENABLED) },
                    {
                      onError: (error) => onError({ error, name: 'Resume sharing URLs' }),
                      onSuccess: () =>
                        onSuccess({
                          deselectAll: true,
                          message: `${selectedNum} ${inflect('Share link', selectedNum)} ${
                            selectedNum > 1 ? 'have' : 'has'
                          } been resumed.`,
                        }),
                      onSettled: () => modal.close(),
                    },
                  );
                }}
              />,
            );
          }}
        />
      )}

      {((isSomeSelectedEnabled && isSomeSelectedDisabled) || selected[0]?.status === SHARED_URL_STATUSES.ENABLED) && (
        <ActionIcon
          img={PausedIcon}
          alt="Pause link sharing"
          tooltip="Pause link sharing"
          iconClassName="px-0.5"
          onClick={() => {
            modal.show(
              <UpdateUrlStatusModal
                status={SHARED_URL_STATUSES.ENABLED}
                numToUpdate={selectedNum}
                onStatusUpdate={() => {
                  onActionStart();
                  updateSharedUrlList.mutate(
                    { sharedUrls: selectedFilterProps(SHARED_URL_STATUSES.DISABLED) },
                    {
                      onError: (error) => onError({ error, name: 'Pause sharing URLs' }),
                      onSuccess: () =>
                        onSuccess({
                          deselectAll: true,
                          message: `${selectedNum} ${inflect('Share link', selectedNum)} ${
                            selectedNum > 1 ? 'have' : 'has'
                          } been paused.`,
                        }),
                      onSettled: () => modal.close(),
                    },
                  );
                }}
              />,
            );
          }}
        />
      )}
    </>
  );
}
