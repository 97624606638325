import classNames from 'classnames';

import { SupportLinks } from '/src/support';
import { WebP } from '../../../components/WebP';

import GirlJumpingPNG from '@crazyegginc/hatch/dist/images/illustration-jump.png';
import GirlJumpingWebP from '@crazyegginc/hatch/dist/images/illustration-jump.webp';
import MagnifyingGlassIcon from '@crazyegginc/hatch/dist/images/icon-magnifying-glass-color.svg';

export function InfoPage({ isActiveSession, style = undefined }) {
  return (
    <div
      className={classNames('px-14 text-center', {
        'pt-[200px]': isActiveSession,
        'mt-80 h-[calc(100%-78px)] pt-12': !isActiveSession,
      })}
      style={{ ...style }}
    >
      {isActiveSession ? (
        <>
          {/* FOR NO RESULT PAGE */}
          <div className="mx-auto mb-4 w-[75px]">
            <img className="block w-full border-0" src={MagnifyingGlassIcon} alt="No result" />
          </div>
          <h3 className="mb-7 text-xl">No matches found</h3>
          <p className="mb-10 text-base leading-6">
            To search for visitors, you need to{' '}
            <a
              className="text-header-link cursor-pointer no-underline hover:underline"
              href={SupportLinks.sessions.visitorIdentifier}
              alt="Add visitor ID script to your site"
              draggable={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              add our Visitor ID script
            </a>{' '}
            to your site.
          </p>
          <a
            className="inline-block cursor-pointer rounded-md border bg-dodger-blue-500 px-4 py-3 text-center text-sm font-semibold leading-4 text-white no-underline"
            href={SupportLinks.sessions.visitorIdentifier}
            alt="Start getting to know your visitors"
            draggable={false}
            target="_blank"
            rel="noopener noreferrer"
          >
            Start tracking visitors
          </a>
        </>
      ) : (
        <>
          {/* FOR NO ACTIVE SESSION PAGE */}
          <WebP
            className="mx-auto mb-4 w-[75px]"
            webp={GirlJumpingWebP}
            fallback={GirlJumpingPNG}
            width="75"
            alt="Start getting to know your visitors"
          />
          <h3 className="mb-7 text-xl">Get to know your visitor</h3>
          <p className="text-base leading-6">
            Identify visitors on any page and watch recordings of them using your site.
          </p>
          <p className="mb-10 text-base leading-6">
            Just add our{' '}
            <a
              className="text-header-link cursor-pointer no-underline hover:underline"
              href={SupportLinks.script.ce2Functions}
              alt="Start getting to know your visitors"
              draggable={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visitor ID
            </a>{' '}
            script to your site and away you go!
          </p>
          <button
            className="inline-block cursor-pointer rounded-md border bg-dodger-blue-500 px-4 py-3 text-center text-sm font-semibold leading-4 text-white no-underline"
            alt="Start getting to know your visitors"
            onClick={() => {
              window.open(SupportLinks.script.ce2Functions, '_blank');
            }}
          >
            Try it out!
          </button>
        </>
      )}
    </div>
  );
}
