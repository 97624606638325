import { useContext, useCallback } from 'react';
import { useUrqlClient } from '/src/hooks';

import { SELECTION_ACTIONS, SelectionContext } from '/src/contexts/selection';
import { useSelectedSite, useLoader } from '/src/hooks';
import { RECORDINGS_IDS_IN_GROUP_QUERY } from '/src/features/recordings/queries';
const { REMOVE, SET, ADD, RESET, TOGGLE, INIT_GROUP, UPDATE_GROUP, TOGGLE_GROUP, TOGGLE_ENTITY_IN_GROUP, LIMIT } =
  SELECTION_ACTIONS;

export function useSelection() {
  const { client } = useUrqlClient();
  const { selectedSite } = useSelectedSite();
  const { selection, selectionDispatch } = useContext(SelectionContext);
  const { showLoader, hideLoader } = useLoader();

  const removeFromSelection = useCallback(
    (selection) => {
      selectionDispatch({
        type: REMOVE,
        payload: selection,
      });
    },
    [selectionDispatch],
  );

  const limitSelection = useCallback(
    (limit) => {
      selectionDispatch({
        type: LIMIT,
        payload: limit,
      });
    },
    [selectionDispatch],
  );

  const setSelection = useCallback(
    (selection) => {
      selectionDispatch({
        type: SET,
        payload: [...selection],
      });
    },
    [selectionDispatch],
  );

  const addToSelection = useCallback(
    (selection) => {
      selectionDispatch({
        type: ADD,
        payload: [...selection],
      });
    },
    [selectionDispatch],
  );

  const toggleSelection = useCallback(
    (entity) => {
      selectionDispatch({
        type: TOGGLE,
        payload: entity,
      });
    },
    [selectionDispatch],
  );

  const clearSelection = useCallback(() => {
    setTimeout(() => {
      selectionDispatch({
        type: RESET,
      });
    }, 0);
  }, [selectionDispatch]);

  const initGroup = useCallback(
    (groupInfo) => {
      selectionDispatch({ type: INIT_GROUP, payload: groupInfo });
    },
    [selectionDispatch],
  );

  const updateGroup = useCallback(
    (groupInfo) => {
      selectionDispatch({ type: UPDATE_GROUP, payload: groupInfo });
    },
    [selectionDispatch],
  );

  const toggleRecordingsGroup = ({ groupId, queryParams }) => {
    const selectionForGroup = selection.groupSelection.find((x) => x.groupId === groupId);
    const variables = {
      filters: JSON.stringify(queryParams.filters),
      group: groupId,
      groupBy: queryParams.groupBy,
      site: selectedSite.id,
    };
    if (!selectionForGroup) {
      showLoader();
      client
        .query(RECORDINGS_IDS_IN_GROUP_QUERY, variables)
        .toPromise()
        .then(({ data }) => {
          if (selectionForGroup) {
            updateGroup({
              groupId: groupId,
              entities: [
                ...data.recordingsIdsInGroup.map(({ id, viewedAt, permissions }) => ({ id, viewedAt, permissions })),
              ],
            });
            selectionDispatch({ type: TOGGLE_GROUP, payload: groupId });
          } else {
            initGroup({
              groupId: groupId,
              entities: [
                ...data.recordingsIdsInGroup.map(({ id, viewedAt, permissions }) => ({ id, viewedAt, permissions })),
              ],
            });
            selectionDispatch({ type: TOGGLE_GROUP, payload: groupId });
          }
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      selectionDispatch({ type: TOGGLE_GROUP, payload: groupId });
    }
  };

  const toggleRecordingsEntity = useCallback(
    ({ entity, groupId, queryParams }) => {
      const selectionForGroup = selection.groupSelection.find((x) => x.groupId === groupId);
      const variables = {
        filters: JSON.stringify(queryParams.filters),
        group: groupId,
        groupBy: queryParams.groupBy,
        site: selectedSite.id,
      };
      if (!selectionForGroup) {
        showLoader();
        client
          .query(RECORDINGS_IDS_IN_GROUP_QUERY, variables)
          .toPromise()
          .then(({ data }) => {
            if (selectionForGroup) {
              updateGroup({
                groupId: groupId,
                entities: [
                  ...data.recordingsIdsInGroup.map(({ id, viewedAt, permissions }) => ({ id, viewedAt, permissions })),
                ],
              });
              selectionDispatch({ type: TOGGLE_ENTITY_IN_GROUP, payload: { groupId, entity } });
            } else {
              initGroup({
                groupId: groupId,
                entities: [
                  ...data.recordingsIdsInGroup.map(({ id, viewedAt, permissions }) => ({ id, viewedAt, permissions })),
                ],
              });
              selectionDispatch({ type: TOGGLE_ENTITY_IN_GROUP, payload: { groupId, entity } });
            }
          })
          .finally(() => {
            hideLoader();
          });
      } else {
        selectionDispatch({ type: TOGGLE_ENTITY_IN_GROUP, payload: { groupId, entity } });
      }
    },
    [
      client,
      initGroup,
      updateGroup,
      selectedSite?.id,
      selectionDispatch,
      selection.groupSelection,
      showLoader,
      hideLoader,
    ],
  );

  const updateSelectionInfoInAllRecordingsGroups = (queryParams) => {
    selection.groupSelection.forEach((group) => {
      const variables = {
        filters: JSON.stringify(queryParams.filters),
        group: group.groupId,
        groupBy: queryParams.groupBy,
        site: selectedSite.id,
      };
      client
        .query(RECORDINGS_IDS_IN_GROUP_QUERY, variables)
        .toPromise()
        .then(({ data }) => {
          updateGroup({
            groupId: group.groupId,
            entities: [
              ...data.recordingsIdsInGroup.map(({ id, viewedAt, permissions }) => ({ id, viewedAt, permissions })),
            ],
          });
        });
    });
  };

  const getSelectedIdsFromGroups = () => {
    let selected = [];
    selection.groupSelection.forEach((x) => {
      selected.push(...x.entities.map((y) => (y.selected === true ? y.id : null)).filter(Boolean));
    });
    return selected;
  };

  const getSelectedIdsFromList = () => {
    return [...selection.listSelection.map((x) => x.id)];
  };

  const getSelectedIds = () => {
    return selection.listSelection.length ? getSelectedIdsFromList() : getSelectedIdsFromGroups();
  };

  const getSelectedEntitiesFromGroups = () => {
    let selected = [];
    selection.groupSelection.forEach((x) => {
      selected.push(...x.entities.filter((y) => y.selected === true));
    });
    return selected;
  };

  const getSelectedEntities = () => {
    return selection.listSelection.length ? selection.listSelection : getSelectedEntitiesFromGroups();
  };

  return {
    currentSelection: selection.listSelection,
    groupSelection: selection.groupSelection,
    removeFromSelection,
    limitSelection,
    setSelection,
    addToSelection,
    toggleSelection,
    clearSelection,
    toggleRecordingsGroup,
    toggleRecordingsEntity,
    updateSelectionInfoInAllRecordingsGroups,
    getSelectedIds,
    getSelectedEntities,
  };
}
