import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as CeLogoIcon } from '@crazyegginc/hatch/dist/images/logo-crazy-egg-color.svg';
import { ReactComponent as BulbIcon } from '@crazyegginc/hatch/dist/images/icon-lightbulb-on-outline.svg';

export function WizardLogo({ subElement = null, to = null }) {
  if (to) {
    return (
      <Link to={to} className="group absolute left-6 top-6">
        <CeLogoIcon className="w-28" aria-label="logo link to dashboard" />
        {subElement}
      </Link>
    );
  }
  return (
    <div className="group absolute left-6 top-6">
      <CeLogoIcon className="w-28" />
      {subElement}
    </div>
  );
}

export function WizardHeader({ children, className }) {
  return (
    <div className="flex w-full justify-center text-center">
      <h1 className={classNames('text-header-0 mb-10 mt-0', className)}>{children}</h1>
    </div>
  );
}

export function WizardSubHeader({ children, className }) {
  return (
    <div className={classNames('text-header-3 mb-10 w-full text-center leading-normal', className)}>{children}</div>
  );
}

export function WizardList({ children }) {
  return <ol className="text-body-2 ml-[25px] list-outside list-decimal">{children}</ol>;
}

function WizardListItem({ children }) {
  return (
    <li className="mb-3 font-mono leading-normal">
      <span className="text-body-2 font-sans">{children}</span>
    </li>
  );
}

WizardList.Item = WizardListItem;

export function LightBulbIcon() {
  return <BulbIcon className="mr-3 h-6 w-6 fill-current text-dandelion-500" />;
}
