import { Link } from 'react-router-dom';

import { PlayerWall } from '../RenderPlayerWall';
import { WebP } from '/src/components/WebP';

import RecordingErrorPNG from '@crazyegginc/hatch/dist/images/illustration-camera-recordings.png';
import RecordingErrorWebP from '@crazyegginc/hatch/dist/images/illustration-camera-recordings.webp';

function PlayerNoRecordingsImage() {
  return <WebP webp={RecordingErrorWebP} fallback={RecordingErrorPNG} width="185px" height="150px" />;
}

// eslint-disable-next-line no-restricted-syntax
export default function PlayerNoRecordings() {
  return (
    <PlayerWall
      title="No recordings yet!"
      primaryText="Go to dashboard"
      primaryComponent={Link}
      primaryProps={{ to: '/recordings' }}
      image={PlayerNoRecordingsImage}
    >
      You&#39;ve activated Crazy Egg recordings, but you don&#39;t have any ready to watch yet. As recordings are
      collected they&#39;ll appear in your recordings dashboard.
    </PlayerWall>
  );
}
