import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { GOALS_DASHBOARD_PATH } from '/src/features/goals/constants';

import GoalTargetPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
import GoalTargetWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';
import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

import { WebP } from '../../../components/WebP';

export function NewGoalSuccessPage() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center pt-[82px]">
      <WebP
        className="mx-auto mb-5 h-[200px] w-auto"
        webp={GoalTargetPNG}
        fallback={GoalTargetWebP}
        height="200"
        width="234"
        alt="AB Test"
      />

      <h1 className="text-header-0 m-0 mb-10">Success!</h1>

      <div className="mb-10 flex items-center space-x-3.75">
        <SuccessIcon className="h-5 w-5 fill-current text-lima-500" aria-label="success" />
        <span className="text-header-4">Goal Created</span>
      </div>

      <p className="text-body-2 m-0 mb-4 max-w-[454px] text-center text-base">
        We&apos;ll start tracking your goal immediately.
      </p>

      <p className="text-body-2 m-0 mb-8 max-w-[454px] text-center text-base">
        You&apos;ll be able to monitor your conversions from your Goals
        <br />
        dashboard, or use your goals in your A/B tests.
      </p>

      <Button component={Link} to={GOALS_DASHBOARD_PATH}>
        Return to Dashboard
      </Button>
    </div>
  );
}
