import SpinnerGif from '@crazyegginc/hatch/dist/animations/spinner.gif';

export function Loader() {
  return (
    <div className="-mt-[50px] flex h-[50px] w-full items-center justify-center">
      <img src={SpinnerGif} alt="" role="presentation" className="mr-4 h-4 w-4" />
      <span className="text-xs font-semibold italic text-lynch-500">Loading results &hellip;</span>
    </div>
  );
}
