import { Field, getIn, useFormikContext } from 'formik';
import { Select } from '@crazyegginc/hatch';
import { provisioningOptions } from '../../pages/sso';

export function ConditionTypeSelect({ disabled, conditionKey, condition }) {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const conditionError = getIn(errors, conditionKey);
  const touchedCondition = getIn(touched, conditionKey);
  const typeError = typeof conditionError?.type === 'string' && touchedCondition ? conditionError.type : null;

  return (
    <Field name={`${conditionKey}.type`}>
      {() => (
        <div className="no-wrap-select-ul">
          <Select
            disabled={disabled}
            placeholder="Choose a condition"
            options={provisioningOptions}
            value={provisioningOptions.find((option) => option.value === condition.type)?.value}
            onChange={(value) => {
              setFieldValue(`${conditionKey}.type`, value);
              // clear the list of values
              setFieldValue(`${conditionKey}.values`, ['']);
              // reset the condition's touched state
              setFieldTouched(conditionKey, false);
            }}
          />
          {typeError ? <span className="text-body-2 text-carnation-500">{typeError}</span> : null}
        </div>
      )}
    </Field>
  );
}
