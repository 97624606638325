import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { getNameForParam, getOptionDisplayValue } from '../utils';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

export function SelectedValues({ options, selection, checkFilteredOut = true }) {
  const selected = Object.keys(selection).filter((x) => selection[x].length > 0);
  if (
    selected.length === Object.keys(selection).length &&
    Object.values(selection).every((x) => x.length === 1 && x[0].value === '')
  ) {
    return (
      <div className="flex flex-wrap items-center">
        <div className="mb-1 flex h-6 min-w-0 cursor-default items-center rounded bg-[#039783] px-1 text-sm font-semibold text-white">
          Direct traffic
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap items-center">
      {selected.map((utmParam) => (
        <SelectedValuesForParam
          key={utmParam}
          options={options}
          selection={selection}
          utmParam={utmParam}
          checkFilteredOut={checkFilteredOut}
        />
      ))}
    </div>
  );
}

function SelectedValuesForParam({ options, selection, utmParam, checkFilteredOut = true }) {
  const isFilteredOut = (paramValue) => {
    if (!checkFilteredOut) return false;
    if (options[utmParam].findIndex((option) => option.value === paramValue.value) === -1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        className={classNames(
          'mb-1 flex min-w-0 cursor-default items-center rounded px-1 py-0.5 text-sm font-semibold text-white',
          {
            'bg-[#FF6C72]': utmParam === 'utmSource',
            'bg-[#56C7D7]': utmParam === 'utmMedium',
            'bg-[#5686D7]': utmParam === 'utmCampaign',
            'bg-[#D7568D]': utmParam === 'utmTerm',
            'bg-[#836DF0]': utmParam === 'utmContent',
            'bg-[#00C19F]': utmParam === 'referrerUrl',
          },
        )}
      >
        {getNameForParam(utmParam)}:
        <div
          className={classNames('ml-1 flex min-w-0 items-center rounded-sm px-1', {
            'bg-[#C25761]': utmParam === 'utmSource',
            'bg-[#439CAD]': utmParam === 'utmMedium',
            'bg-[#436BAD]': utmParam === 'utmCampaign',
            'bg-[#A44775]': utmParam === 'utmTerm',
            'bg-[#6558BF]': utmParam === 'utmContent',
            'bg-[#039783]': utmParam === 'referrerUrl',
          })}
        >
          {selection[utmParam].map((paramValue, i) => {
            const filteredOut = isFilteredOut(paramValue);
            return (
              <div key={paramValue.value} className="flex">
                <span className="whitespace-pre">{i > 0 ? ', ' : ''}</span>
                <div className={classNames('relative', { 'border border-dandelion-500 px-px': filteredOut })}>
                  {getOptionDisplayValue(paramValue)}
                  {filteredOut && (
                    <div className="absolute -right-2.5 -top-2.5 flex h-3.5 w-3.5 items-center justify-center overflow-visible rounded-full bg-black-pearl-500">
                      <div className="absolute">
                        <Tooltip
                          tooltipContent={
                            <div className="max-w-xs">
                              We don&apos;t have any recorded sessions with this parameter in combination with your
                              other selected parameters. You can remove this parameter, change your other selections, or
                              you can just keep it selected - it won&apos;t affect your results.
                            </div>
                          }
                          show={filteredOut}
                        >
                          <WarningIcon className="h-4 w-4 fill-current text-dandelion-500 " />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PlusIcon className="mx-2 mb-1 h-2 w-2 fill-current text-black-pearl-500 last:hidden" />
    </>
  );
}
