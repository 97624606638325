import React from 'react';
import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

import { VerticalLine } from './VerticalLine';
import { RenderInfos } from './RenderInfos';
import { RenderWarnings } from './RenderWarnings';

export function RenderSuccess({ data, originalUrl, finalUrl }) {
  const { warnings, infos, consoleErrors } = data;

  const hasExtra = (warnings || infos) && ((warnings || []).length > 0 || (infos || []).length > 0);

  return (
    <div className="mt-10 flex h-full w-full items-stretch rounded-md bg-alice-blue-500 px-[30px] pb-10 pt-[30px]">
      <div className="relative mr-[25px] w-10 flex-shrink-0">
        <SuccessIcon className="h-10 w-10 fill-current text-lima-500" aria-label="success" />
        {hasExtra && <VerticalLine />}
      </div>
      <div className="flex h-full w-full flex-col items-start">
        <h3 className="text-header-4">Crazy Egg Installed</h3>
        <p className="text-body-2 leading-snug">
          Crazy Egg is correctly installed on <strong className="text-body-1">{finalUrl}</strong>
        </p>
        <RenderWarnings warnings={warnings} finalUrl={finalUrl} />
        <RenderInfos infos={infos} consoleErrors={consoleErrors} finalUrl={finalUrl} originalUrl={originalUrl} />
      </div>
    </div>
  );
}
