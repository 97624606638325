import { Formik, Form, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import { Button, Input } from '@crazyegginc/hatch';

import { useNotifications, useMutation, useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { normalizeUrl } from '/src/utils/url';
import { superLenientUrlRegex } from '/src/utils/regex';
import { abTestVariantTypes } from '/src/features/ab-testing/constants';
import { abTestAddVariantsMutation } from '/src/features/ab-testing/mutations';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

const formValidation = yup.object().shape({
  newVariants: yup
    .array()
    .of(
      yup.object().shape({
        redirectUrl: yup
          .string()
          .matches(superLenientUrlRegex, 'Your URL appears to be invalid.')
          .required('Please enter a valid URL'),
      }),
    )
    .min(1, 'Please add at least one variant'),
});

export function AddSplitVariant({ abTest }) {
  const modal = useModal();
  const notifications = useNotifications();

  const { mutate: mutateCreateVariants, isLoading } = useMutation(abTestAddVariantsMutation);

  return (
    <Modal dialogClassName="!w-[660px] !p-[30px] flex-col scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded">
      <Modal.Title>Add variant</Modal.Title>

      <h3 className="text-header-6 mb-2.5">Current variants:</h3>

      <div className="border-box flex w-full flex-shrink-0 flex-col divide-y divide-mystic-500 overflow-hidden rounded border border-mystic-500">
        {abTest.variants.map((variant) => (
          <div className="flex w-full" key={`variant-${variant.id}`}>
            <div className="border-box text-header-6 w-[96px] bg-mystic-500 bg-opacity-50 px-3.75 py-2.5 text-lynch-500">
              {variant.variantName}
            </div>
            <div className="border-box text-body-2 flex flex-1 items-center justify-between space-x-2 overflow-hidden border-l border-mystic-500 bg-white-lilac-500 pl-5 pr-0.5">
              {variant.type === 'CONTROL' ? (
                <span className="truncate py-2.5" title={abTest.pageUrl}>
                  {abTest.pageUrl}
                </span>
              ) : (
                <span className="truncate py-2.5" title={abTest.redirectUrl}>
                  {variant.redirectUrl}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <h3 className="text-header-6 mb-2.5 mt-5">Add new variant:</h3>

      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        initialValues={{ newVariants: [{ redirectUrl: '' }] }}
        validationSchema={formValidation}
        onSubmit={async (values, helpers) => {
          const payload = values.newVariants.filter((variant) => variant.redirectUrl.trim() !== '');

          mutateCreateVariants(
            {
              id: Number(abTest.id),
              variants: payload.map((variant) => ({
                redirectUrl: normalizeUrl(variant.redirectUrl, { appendSlash: false }),
                type: abTestVariantTypes.VARIANT,
                weight: abTest.autoReweight ? undefined : 0,
              })),
            },
            {
              onSuccess: () => {
                notifications.success({ content: `Variants created successfully.`, timeout: 3000 });
                modal.close();
              },
              onError: (error) => {
                notifications.error({ content: `Variant creation failed.`, timeout: 3000, context: { error } });
                helpers.resetForm({ values: { newVariants: payload } });
              },
            },
          );
        }}
      >
        {({ values, isSubmitting, errors, isValid, dirty }) => (
          <Form>
            <div className="flex flex-shrink-0 flex-col space-y-2.5">
              <FieldArray name="newVariants">
                {({ push }) => (
                  <div className="flex flex-col space-y-2.5">
                    {values.newVariants.map((variant, index) => (
                      <Field name={`newVariants.${index}.redirectUrl`} key={`new-variant-${index}`}>
                        {({ field }) => (
                          <div className="flex items-start space-x-1">
                            {/* eslint-disable-next-line */}
                            <Input error={errors?.newVariants?.[index]?.redirectUrl} {...field} autoFocus />
                            {values.newVariants.length - 1 === index ? (
                              <Button onClick={() => push({ redirectUrl: '' })}>
                                <PlusIcon className="h-3 w-3 fill-current" />
                              </Button>
                            ) : null}
                          </div>
                        )}
                      </Field>
                    ))}
                  </div>
                )}
              </FieldArray>
            </div>

            <Modal.Actions>
              <Button type="submit" disabled={!dirty || !isValid || isSubmitting || isLoading}>
                {isLoading ? `Adding variant...` : `Add variant`}
              </Button>
              <Modal.Cancel />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
