import ReactCountryFlag from 'react-country-flag';
import { Tooltip } from '@crazyegginc/hatch';

export function CountryFlag({ country }) {
  if (country && country.code && country.name) {
    return (
      <div className="pb-[3px]">
        <Tooltip tooltipContent={country.name}>
          <ReactCountryFlag
            countryCode={country.code}
            svg
            alt={country.name}
            style={{
              width: '18px',
              height: '14px',
              border: '1px solid #e1e6ef',
            }}
          />
        </Tooltip>
      </div>
    );
  }
  return <span className="text-lynch-500">-</span>;
}
