import { Button } from '@crazyegginc/hatch';
import { useNavigate } from 'react-router-dom';

import { inflect } from '/src/utils/string';
import { Modal } from '/src/contexts/modal';
import { useModal, useMutation, useNotifications } from '/src/hooks';

import { abTestDeleteMutation } from '/src/features/ab-testing/mutations';

export function DeleteTestModal({ abTest, redirectOnDelete = null }) {
  const modal = useModal();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { mutate: mutateDeleteAbTest, isLoading } = useMutation(abTestDeleteMutation);

  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>
      <div className="text-body-2 leading-normal">
        You&#39;ve created {abTest.variantsCount} {inflect('variant', abTest.variantsCount)} for this test. If you
        delete this test, you&#39;ll lose the {inflect('variant', abTest.variantsCount)}.
      </div>

      <Modal.Actions>
        <Button
          disabled={isLoading}
          variant="warning"
          onClick={async () => {
            await mutateDeleteAbTest(
              { id: abTest.id },
              {
                onSuccess: () => {
                  modal.close();
                  notifications.success({ content: `A/B test deleted successfully.`, timeout: 3000 });
                  if (redirectOnDelete) {
                    navigate(redirectOnDelete, { replace: true });
                  }
                },
                onError: (error) => {
                  notifications.error({
                    content: `A/B test failed to delete.`,
                    timeout: 3000,
                    context: { error },
                  });
                },
              },
            );
          }}
        >
          {isLoading ? 'Deleting...' : 'Delete permanently'}
        </Button>
        <Modal.Cancel disabled={isLoading}>Keep this test</Modal.Cancel>
      </Modal.Actions>
    </Modal>
  );
}
