import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Button, Popover, StyledPopoverPanel, PopoverItem } from '@crazyegginc/hatch';

import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';

import { ReactComponent as EyeIcon } from '@crazyegginc/hatch/dist/images/icon-unwatched-outline.svg';
import { ReactComponent as SortIcon } from '@crazyegginc/hatch/dist/images/icon-sort.svg';
import { ReactComponent as MenuIcon } from '@crazyegginc/hatch/dist/images/icon-menu-dots.svg';

export function ColumnMenu({ column }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: () => {
              return [0, 5];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <div className={classNames({ 'invisible group-hover:visible': !open })}>
          <div ref={setReferenceElement}>
            <Popover.Button
              as={Button}
              variant="secondary"
              size="lg"
              className={classNames('ml-3.75 !h-[25px] !w-5 !justify-center !px-0', {
                '!border-dodger-blue-500': open,
              })}
            >
              <MenuIcon className="h-3.5 w-3.5 fill-current" aria-label="column menu" />
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                className="w-48"
                ref={setTooltipElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {({ close }) => (
                  <>
                    <PopoverItem
                      onClick={() => {
                        column.toggleVisibility();
                        close();
                      }}
                      className="group-2 !flex !items-start"
                    >
                      <EyeIcon className="group-2-hover:text-dodger-blue-500 mr-3.75 h-4 w-4 fill-current text-cadet-blue-500" />
                      <span>Hide column</span>
                    </PopoverItem>
                    <PopoverItem
                      onClick={() => {
                        column.toggleSorting(false);
                        close();
                      }}
                      className="group-2 !flex !items-start"
                    >
                      <SortIcon className="group-2-hover:text-dodger-blue-500 mr-3.75 h-4 w-4 scale-y-[-1] fill-current text-cadet-blue-500" />
                      Sort ascending
                    </PopoverItem>
                    <PopoverItem
                      onClick={() => {
                        column.toggleSorting(true);
                        close();
                      }}
                      className="group-2 !flex !items-start"
                    >
                      <SortIcon className="group-2-hover:text-dodger-blue-500 mr-3.75 h-4 w-4 fill-current text-cadet-blue-500" />
                      Sort descencing
                    </PopoverItem>
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </div>
      )}
    </Popover>
  );
}
