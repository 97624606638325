import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';

const validationSchema = yup.object().shape({
  delete: yup.string().required('required').matches('DELETE', 'must match'),
});

export function DeleteConfirmationModal({ onDelete = null, text, entity, onCancel }) {
  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>

      <Formik
        initialValues={{ delete: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await onDelete?.(values.delete.toLowerCase());
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, isSubmitting }) => (
          <Form>
            <div className="text-body-2 leading-normal">
              <p>{text}</p>
              <p className="text-body-1 mb-5">This cannot be undone.</p>
              <Input
                name="delete"
                id="delete-confirm-input"
                label="Type DELETE below to confirm:"
                placeholder="Type DELETE to continue"
                onFocus={() => setFieldTouched('delete', true, true)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.delete}
                autoComplete="off"
              />
              <span className="text-caption">case sensitive</span>
            </div>

            <Modal.Actions>
              <Button
                variant="warning"
                disabled={!!errors.delete || !touched.delete || isSubmitting}
                type="submit"
                className="!flex !items-center !justify-center !space-x-2.5"
              >
                {isSubmitting ? (
                  <>
                    <Spinner />
                    <span>Deleting {entity}...</span>
                  </>
                ) : (
                  `Delete ${entity}`
                )}
              </Button>
              <Modal.Cancel disabled={isSubmitting} onClick={onCancel} />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
