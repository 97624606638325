import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';

const validationSchema = yup.object().shape({
  track: yup
    .string()
    .required('Please type TRACK, all in uppercase.')
    .matches('TRACK', 'Please type TRACK, all in uppercase.'),
});

export function EmailTrackModal({ onTrack = null }) {
  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>

      <Formik
        initialValues={{ track: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await onTrack?.(values.track.toLowerCase());
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <div className="text-body-2 leading-normal">
              <p className="mb-6">
                Please confirm below that you wish to track your visitors email
                <br /> address and use it as their visitor ID.
              </p>

              <Input
                name="track"
                id="track-confirm-input"
                label="Type TRACK below to confirm:"
                placeholder="Type TRACK to continue"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.track}
                error={touched.track && errors.track ? errors.track : null}
                autoComplete="off"
                autoFocus // eslint-disable-line
              />
              <span className="text-caption">case sensitive</span>
            </div>

            <Modal.Actions>
              <Button
                disabled={isSubmitting}
                type="submit"
                className="!flex !items-center !justify-center !space-x-2.5"
              >
                {isSubmitting ? (
                  <>
                    <Spinner />
                    <span>Updating...</span>
                  </>
                ) : (
                  'Track email addresses'
                )}
              </Button>
              <Modal.Cancel disabled={isSubmitting} />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
