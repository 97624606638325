import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@crazyegginc/hatch';

import { BillingPaymentInfo } from '../components/BillingPaymentInfo';
import { BillingCompanyInfo } from '../components/BillingCompanyInfo';
import { BillingHistory } from '../components/BillingHistory';

import { billingDetailsQuery } from '../queries';
import { countriesQuery } from '../../_global/queries';

// eslint-disable-next-line no-restricted-syntax
export default function AccountBilling() {
  const { data, isFetching } = useQuery(billingDetailsQuery());
  const { data: dataCountries, isFetching: isFetchingCountries } = useQuery(countriesQuery());

  if ((isFetching && !data) || (isFetchingCountries && !dataCountries)) {
    return (
      <div className="mt-16 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );
  }

  return (
    <div className="px-10">
      <BillingPaymentInfo data={data.billingDetails} countries={dataCountries.countries} />
      <BillingCompanyInfo data={data.billingDetails} countries={dataCountries.countries} />
      <BillingHistory />
    </div>
  );
}
