import { forwardRef } from 'react';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@crazyegginc/hatch';

export const ActionIconButton = forwardRef(({ tooltip, disabled, className, ...rest }, ref) => {
  return (
    <Tooltip tooltipContent={tooltip}>
      <IconButton
        ref={ref}
        className={classNames('h-[35px] w-[35px] rounded hover:bg-solitude-500', {
          'pointer-events-none': disabled,
          [className]: !!className,
        })}
        iconClass={classNames({
          '!text-dodger-blue-500': !disabled,
          '!text-cadet-blue-500': disabled,
        })}
        {...rest}
      />
    </Tooltip>
  );
});
