import { useMemo, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Button, ReactTable, Tooltip } from '@crazyegginc/hatch';

import { usePermissions, useNotifications, useModal, useMutation, useSite } from '/src/hooks';
import { OptionsHeader } from '../components/OptionsHeader';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';
import { deleteSiteAndAssociatedDataMutation } from '/src/features/options/mutations';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsSitesScreen() {
  const permissions = usePermissions();
  const modal = useModal();
  const notifications = useNotifications();
  const { mutateAsync: deleteSiteMutateAsync } = useMutation(deleteSiteAndAssociatedDataMutation);

  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const tableRef = useRef(null);

  const { sites, loadingSites, selectedSite, resetSite } = useSite({ dontSelectSite: true });

  const removeSite = useCallback(
    (site) => {
      if (!canEditSites) return;

      modal.show(
        <DeleteConfirmationModal
          text={
            <>
              If you delete <strong className="text-body-1">{site.name}</strong>, all your data will be gone forever.
            </>
          }
          entity="site"
          onDelete={() => {
            try {
              return deleteSiteMutateAsync(
                {
                  siteId: site.id,
                },
                {
                  onError: (error) =>
                    notifications.error({ content: 'Deleting site failed.', timeout: 3000, context: { error } }),
                  onSuccess: () => {
                    notifications.success({ content: 'Site deleted successfully.', timeout: 3000 });
                    if (selectedSite?.id === site.id) {
                      resetSite();
                    }
                    modal.close();
                  },
                },
              );
            } catch {
              //noop
            }
          }}
        />,
      );
    },
    [canEditSites, modal, notifications, deleteSiteMutateAsync, resetSite, selectedSite],
  );

  function addNewSite() {
    if (!canEditSites) return;

    modal.show(<AddSiteModal />);
  }

  const columns = useMemo(
    () => [
      {
        header: 'URL',
        accessorKey: 'name',
        cell: ({ row }) => (
          <a className="text-link" target="_blank" rel="noopener noreferrer" href={`http://${row.original.name}`}>
            {row.original.name}
          </a>
        ),
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Actions',
        minSize: 60,
        maxSize: 60,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => (
          <div>
            <Tooltip tooltipContent={canEditSites ? 'Delete site' : "You don't have permission for this action."}>
              <button
                type="button"
                onClick={() => removeSite(row.original)}
                className={classNames('flex-shrink-0 p-0.5 focus-visible:outline-black', {
                  'text-dodger-blue-300 hover:text-carnation-500': canEditSites,
                  'cursor-not-allowed text-cadet-blue-500': !canEditSites,
                })}
                disabled={!canEditSites}
              >
                <RemoveIcon className="h-4 w-4 fill-current" aria-label="delete" />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    [removeSite, canEditSites],
  );

  return (
    <>
      <OptionsHeader>Sites (Domains)</OptionsHeader>
      <ReactTable
        fetching={loadingSites}
        ref={{ tableRef }}
        enableSorting={false}
        columns={columns}
        data={sites}
        Footer={
          canEditSites && (
            <Button variant="secondary" onClick={addNewSite}>
              Add New Site
            </Button>
          )
        }
        rowPadding={true}
      />
    </>
  );
}
