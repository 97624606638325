import ReactCountryFlag from 'react-country-flag';
import { DeviceIcon } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';
import { getPageName } from '../../live-functions';
import { MainTextRow, SubTextRow, Bull } from '/src/features/live-activity/components/ui';

export function DailyVisitEvent({ liveEvent }) {
  const { path, device, country, referrer } = liveEvent.attributes;
  const page = getPageName(path);
  const { sessionInfo } = useAuthContext();

  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  let deviceText;
  switch (device) {
    case 1:
      deviceText = DEVICE_TYPES.DESKTOP;
      break;
    case 2:
      deviceText = DEVICE_TYPES.MOBILE;
      break;
    case 3:
      deviceText = DEVICE_TYPES.TABLET;
      break;
  }

  return (
    <div>
      <MainTextRow>
        Basic Visitor
        <div className="max-w-lg">
          <div className="flex items-center">
            <span className="shrink-0 whitespace-pre"> visited {page === 'homepage' ? '' : 'page'} </span>
            <TruncateWithTooltip text={page} className="text-body-1 mr-8" />
          </div>
        </div>
      </MainTextRow>
      <SubTextRow>
        <DeviceIcon device={deviceText} className="!h-4 !w-4" tooltip={true} />
        {country && (
          <>
            <Bull />
            <div className="flex items-center">
              <ReactCountryFlag
                countryCode={country.code}
                svg
                alt={country.name}
                style={{
                  margin: '0px 5px 0px 0px',
                  height: '14px',
                  width: '18px',
                  border: '1px solid #e1e6ef',
                }}
              />
              {country.name}
            </div>
          </>
        )}
        {referrer && (
          <>
            <Bull />
            <TruncateWithTooltip text={`via ${referrer}`} className="max-w-sm" />
          </>
        )}
        {isImpersonated && (
          <>
            <Bull />
            {liveEvent.visitorId}
          </>
        )}
      </SubTextRow>
    </div>
  );
}
