/*
  This file contains a patched version of react-router-dom's useSearchParams hook.
  See issues below. 
  Be mindful of it when upgrading react-router-dom. 
*/
import { useCallback, useRef, useMemo } from 'react';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

// This is just copied from react-router-dom
function getSearchParamsForLocation(locationSearch, defaultSearchParams) {
  let searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    // Use `defaultSearchParams.forEach(...)` here instead of iterating of
    // `defaultSearchParams.keys()` to work-around a bug in Firefox related to
    // web extensions. Relevant Bugzilla tickets:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}

// Make setSearchParams keep hash
// https://github.com/remix-run/react-router/issues/8393
function useSearchParamsOrigPatched(defaultInit) {
  let defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  let hasSetSearchParamsRef = useRef(false);

  let location = useLocation();
  let searchParams = useMemo(
    () =>
      // Only merge in the defaults if we haven't yet called setSearchParams.
      // Once we call that we want those to take precedence, otherwise you can't
      // remove a param with setSearchParams({}) if it has an initial value
      getSearchParamsForLocation(
        location.search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current,
      ),
    [location.search],
  );

  let navigate = useNavigate();
  let setSearchParams = useCallback(
    (nextInit, navigateOptions) => {
      const newSearchParams = createSearchParams(typeof nextInit === 'function' ? nextInit(searchParams) : nextInit);
      hasSetSearchParamsRef.current = true;
      navigate({ search: '?' + newSearchParams, hash: location.hash }, navigateOptions);
    },
    [navigate, searchParams, location.hash],
  );

  return [searchParams, setSearchParams];
}

// Make the setter a stable ref
// https://github.com/remix-run/react-router/issues/9991
export function useSearchParams() {
  const [searchParams, setSearchParams] = useSearchParamsOrigPatched();
  // Store a reference to the setter
  const setSearchParamsRef = useRef(setSearchParams);
  // Update the reference when the setter changes
  setSearchParamsRef.current = setSearchParams;

  // Return a stable setter (which has no dep on searchParams)
  const setSearchParamsStable = useCallback((...args) => setSearchParamsRef.current(...args), []);
  return [searchParams, setSearchParamsStable];
}
