import { Divider } from '@crazyegginc/hatch';

// eslint-disable-next-line no-restricted-syntax
export default function WildcardSyntaxModal() {
  return (
    <div className="text-body-2 flex w-full flex-col p-7">
      <h1 className="text-header-2 m-0 mt-1.25">Wildcard Pattern Syntax</h1>

      <Divider />

      <p className="my-2.5">
        Use the <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">*</code>{' '}
        (asterisk) character in your wildcard pattern to match multiple URLs.
      </p>

      <br />

      <p className="my-2.5">
        For example, <strong>http://www.example.com/</strong>
        <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">*</code>
      </p>
      <table className="mb-2.5 w-full border-collapse">
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Will match these:
        </th>
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Won&#39;t match these:
        </th>
        <tr>
          <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
            <code>http://www.example.com/</code>
            <br />
            <code>http://www.example.com/index.html</code>
            <br />
            <code>http://www.example.com/form?q=1</code>
          </td>
          <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
            <code>http://example.com/</code>
            <br />
            <code>https://www.example.com/</code>
            <br />
            <code>http://www.example.org/form?q=1</code>
          </td>
        </tr>
      </table>

      <p className="my-2.5">
        For example, <strong>http</strong>
        <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">*</code>
        <strong>://</strong>
        <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">*</code>
        <strong>example.com/</strong>
      </p>
      <table className="mb-2.5 w-full border-collapse">
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Will match these:
        </th>
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Won&#39;t match these:
        </th>
        <tr>
          <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
            <code>http://www.example.com/</code>
            <br />
            <code>http://www.example.com/</code>
            <br />
            <code>https://store.example.com/</code>
          </td>
          <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
            <code>http://example.com/index.html</code>
            <br />
            <code>http://www.example.com/search?q=1</code>
            <br />
            <code>https://www.example.com/info/</code>
          </td>
        </tr>
      </table>

      <p className="my-2.5">
        For example, <strong>http://mysite.com/search?q=</strong>
        <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">*</code>
      </p>
      <table className="mb-2.5 w-full border-collapse">
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Will match these:
        </th>
        <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
          Won&#39;t match these:
        </th>
        <tr>
          <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
            <code>http://mysite.com/search?q=red</code>
            <br />
            <code>http://mysite.com/search?q=g%20b</code>
            <br />
            <code>http://mysite.com/search?q= </code>
          </td>
          <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
            <code>http://mysite.com/home</code>
            <br />
            <code>http://mysite.com/search</code>
            <br />
            <code>http://www.mysite.com/search?q=blue</code>
          </td>
        </tr>
      </table>
    </div>
  );
}
