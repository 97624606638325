import { useContext, useMemo, useState, Fragment } from 'react';
import classNames from 'classnames';
import { useActor } from '@xstate/react';
import { Button, Input, Spinner, IconButton, Checkbox, InfoBox } from '@crazyegginc/hatch';

import { hasWildcard } from '/src/utils/url';
import { foobarGen } from '/src/utils/string';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { abTestTypes, VARIANT_STATUSES } from '/src/features/ab-testing/constants';
const { SPLIT, PATCH } = abTestTypes;

import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';
import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/illustration-desktop.svg';
import { ReactComponent as TabletIcon } from '@crazyegginc/hatch/dist/images/illustration-tablet.svg';
import { ReactComponent as MobileIcon } from '@crazyegginc/hatch/dist/images/illustration-mobile.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as PageEditorIcon } from '@crazyegginc/hatch/dist/images/illustration-page-editor-variant.svg';
import { ReactComponent as UrlRedirectIcon } from '@crazyegginc/hatch/dist/images/illustration-url-based-variant.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

function getDeviceImage(device) {
  switch (device) {
    case DEVICE_TYPES.DESKTOP:
      return <DesktopIcon className="h-12 w-[57px]" />;
    case DEVICE_TYPES.TABLET:
      return <TabletIcon className=" h-[43px] w-[62px]" />;
    case DEVICE_TYPES.PHONE:
      return <MobileIcon className=" h-[53px] w-[34px]" />;
    default:
      return null;
  }
}

function getDeviceLabel(device) {
  switch (device) {
    case DEVICE_TYPES.DESKTOP:
      return 'Desktop visitors';
    case DEVICE_TYPES.TABLET:
      return 'Tablet visitors';
    case DEVICE_TYPES.PHONE:
      return 'Mobile visitors';
    default:
      return null;
  }
}

export function PageSetup({ context }) {
  const { abTestService } = useContext(context);
  const [state] = useActor(abTestService);
  const { send } = abTestService;

  const loading = state.matches('setup.verifyPage');
  const pending = state.matches('setup.queueVerifyPage');
  const urlValid = state.matches('setup.selectDevice');

  const { type, variants, matchingUrl, devices } = state.context.payload;

  const splitVariants = useMemo(() => {
    if (type === PATCH) {
      return [];
    }
    return variants.filter((variant) => {
      return variant && variant.type.toUpperCase() === 'VARIANT';
    });
  }, [variants, type]);

  const [touched, setTouched] = useState({});

  return (
    <div className="mx-auto flex w-[740px] flex-col space-y-10">
      <h1 className="text-header-0 m-0 mt-14 text-center">Let&#39;s set up your A/B test!</h1>

      <div>
        <h2 className="text-header-2 mb-5">What type of test would you like to run?</h2>

        <div className="flex space-x-5">
          <SelectionBox
            label="Page editor"
            image={<PageEditorIcon />}
            isSelected={type === PATCH}
            onClick={() => {
              if (type !== PATCH) {
                send({ type: 'SET_TYPE', value: PATCH });
              }
            }}
            description={
              state.matches('setup.selectType') ? 'Make edits directly to your page with our visual editor.' : undefined
            }
          />
          <SelectionBox
            label="URL redirect"
            image={<UrlRedirectIcon />}
            isSelected={type === SPLIT}
            onClick={() => {
              if (type !== SPLIT) {
                send({ type: 'SET_TYPE', key: 'type', value: SPLIT });
              }
            }}
            description={
              state.matches('setup.selectType')
                ? "Provide us with the URLs of the pages you'd like to test."
                : undefined
            }
          />
        </div>
      </div>

      {type ? (
        <div>
          <h2 className="text-header-2">What is the URL for the control?</h2>
          <p className="text-body-5 mb-2 leading-tight">
            Visitors to this page will be redirected to one of your variants.
          </p>
          <div className="mb-2">
            <InfoBox
              body={
                <span className="leading-tight">
                  If you want to test a common element or template across multiple pages, use wildcards (*). ( e.g.
                  https://example.com/products/* ) <br />
                  You can even have several wildcards. ( e.g. https://example.com/products/*/subcategory/*/?id=* )
                </span>
              }
            />
          </div>
          <Input
            id="matchingUrl"
            name="matchingUrl"
            aria-label="matching url"
            placeholder="e.g. https://www.yoursite.com/products/*/info"
            error={touched.matchingUrl ? state.context.errors?.matchingUrl : null}
            className="!h-[50px]"
            onChange={(e) => {
              setTouched((x) => ({ ...x, matchingUrl: true }));
              send({ type: 'CHANGE', key: 'matchingUrl', value: e.target.value.trim() });
            }}
            value={matchingUrl}
            leftIcon={<SearchIcon className="h-4 w-4 fill-current text-cadet-blue-500" />}
            rightIcon={
              (loading || pending) && !matchingUrl ? (
                <Spinner className="h-4 w-4 text-cadet-blue-500" aria-label="pending" />
              ) : urlValid ? (
                <SuccessIcon className="h-4 w-4 fill-current text-lima-500" aria-label="success" />
              ) : touched.matchingUrl && state.context.errors?.matchingUrl ? (
                <ErrorIcon className="h-4 w-4 fill-current text-carnation-500" aria-label="success" />
              ) : null
            }
          />
          {hasWildcard(matchingUrl) && !state.context.errors?.matchingUrl ? (
            <div className="text-body-4 mt-2">
              For example, this will match URLs like:{' '}
              <span className="inline-block bg-white-lilac-500 px-0.5">{matchingUrl.replace(/\*/g, foobarGen(0))}</span>
            </div>
          ) : null}
        </div>
      ) : null}

      {type === SPLIT && matchingUrl && matchingUrl !== '' ? (
        <div>
          <h2 className="text-header-2">What are the URLs for the variants?</h2>
          <p className="text-body-5 mb-5 leading-tight">
            Visitors in your test will be redirected to one of these URLs. Visitors are who designated as the “control”
            group will not be redirected - they will stay on the original URL they visited.
          </p>

          <div className="flex flex-col space-y-3">
            {variants.map((variant, index) => {
              if (!variant || variant.type.toUpperCase() !== 'VARIANT' || variant.status === VARIANT_STATUSES.DELETED)
                return null;
              return (
                <Fragment key={`test-variant-urls-${index}`}>
                  <div className="flex items-center" data-testid={`variant position ${variant.position}`}>
                    <Input
                      value={variant.redirectUrl}
                      className="!h-[50px]"
                      onChange={(e) => {
                        setTouched((x) => ({ ...x, [`variant${index}`]: true }));
                        send({ type: 'CHANGE_SPLIT_VARIANT', index, value: e.target.value.trim() });
                      }}
                      error={touched[`variant${index}`] && state.context.errors?.variants?.[index]?.redirectUrl}
                      placeholder={
                        hasWildcard(matchingUrl)
                          ? 'e.g. https://www.yoursite.com/products-b/*/info'
                          : 'e.g. https://www.yoursite.com/products-b'
                      }
                    />
                    {splitVariants.length > 1 && (
                      <IconButton
                        icon={<CrossIcon className="h-3 w-3 fill-current" />}
                        className="ml-4 flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                        label="delete variant"
                        onClick={() => {
                          send({ type: 'DELETE_SPLIT_VARIANT', position: variant.position });
                        }}
                      />
                    )}
                  </div>
                  {hasWildcard(matchingUrl) &&
                  hasWildcard(variant.redirectUrl) &&
                  !state.context.errors?.variants?.[index]?.redirectUrl ? (
                    <div className="text-body-4 !mt-1">
                      This will redirect the above example URL to:{' '}
                      <span className="inline-block bg-white-lilac-500 px-0.5">
                        {variant.redirectUrl.replace(/\*/g, foobarGen(0))}
                      </span>
                    </div>
                  ) : null}
                </Fragment>
              );
            })}
            <div className="flex items-center justify-start space-x-2">
              <Button
                variant="secondary"
                onClick={() => {
                  send('ADD_SPLIT_VARIANT');
                }}
              >
                <PlusIcon className="mr-2.5 h-[15px] w-[15px] fill-current text-dodger-blue-500" />
                <span>Add Variant</span>
              </Button>
              {typeof state.context.errors?.variants === 'string' ? (
                <p className="text-body-2 text-carnation-500">{state.context.errors.variants}</p>
              ) : null}
            </div>
          </div>
          <div className="mt-3">
            <InfoBox
              body={
                <>
                  Your variants can also contain wildcards, they will be applied in the same order as in your control
                  URL.
                </>
              }
            />
          </div>
        </div>
      ) : null}

      {matchingUrl && matchingUrl !== '' ? (
        <div className="pb-10">
          <h2 className="text-header-2">Who should see this test?</h2>

          <ConditionalDeviceSelector
            selectedDevices={devices}
            selectDevice={(value) => {
              setTouched((x) => ({ ...x, devices: true }));
              send({ type: 'SELECT', value });
            }}
          />
          {touched.devices && state.context.errors?.devices ? (
            <div className="text-error whitespace-nowrap">{state.context.errors?.devices}</div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function ConditionalDeviceSelector({ selectedDevices = [], selectDevice = null }) {
  return (
    <div className="mb-1.5 mt-4 flex space-x-3">
      {[DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.PHONE].map((device) => {
        const isSelected = selectedDevices.includes(device);
        const id = `device-${device}`;
        return (
          <label
            htmlFor={id}
            key={device}
            className={classNames(
              'relative flex h-[138px] w-[170px] cursor-pointer flex-col items-center justify-center rounded',
              {
                'border-2 border-dodger-blue-500': isSelected,
                'border border-mystic-500': !isSelected,
              },
            )}
          >
            <div className="absolute right-2 top-2">
              <Checkbox value={device} onChange={() => selectDevice(device)} checked={isSelected} id={id} size="lg" />
            </div>
            <div className="relative flex h-16 w-16 items-center justify-center">{getDeviceImage(device)}</div>
            <span className="text-body-1 mt-[14px]">{getDeviceLabel(device)}</span>
          </label>
        );
      })}
    </div>
  );
}

function SelectionBox({ onClick, image, label, isSelected, description }) {
  return (
    <div className="flex w-[170px] flex-col space-y-3.75">
      <button
        onClick={onClick}
        className={classNames(
          'group relative flex h-[138px] w-[170px] flex-col items-center justify-center rounded disabled:cursor-not-allowed',
          {
            'border-2 border-dodger-blue-500': isSelected,
            'border border-mystic-500': !isSelected,
          },
        )}
      >
        <div className="relative flex h-16 w-16 items-center justify-center">{image}</div>
        <span className="text-body-1 mt-[14px]">{label}</span>
      </button>
      {description ? <p className="text-body-4 leading-5">{description}</p> : null}
    </div>
  );
}
