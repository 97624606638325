import React from 'react';
import { Button } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { useAuthContext } from '/src/hooks';
import { ErrorItem } from './ErrorItem';

export function RenderNetworkError({ error, originalUrl }) {
  const { sessionInfo } = useAuthContext();

  if ((sessionInfo?.isImpersonated ?? false) === false) {
    return (
      <ErrorItem
        title="Trouble Accessing the Page"
        description={
          <span>
            We ran into difficulty while trying to check the script on{' '}
            <strong className="text-body-1">{originalUrl}</strong>. Double-check the URL and run the check again.
            <br />
            <br />
            Try checking the{' '}
            <a className="text-link" target="_blank" rel="noopener noreferrer" href={SupportLinks.scriptCheck.manual}>
              installation manually
            </a>
            .
          </span>
        }
      >
        <Button
          size="xl"
          className="mt-5"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={SupportLinks.scriptCheck.manual}
        >
          Check for Script Manually
        </Button>
      </ErrorItem>
    );
  } else {
    let errorCode = error?.graphQLErrors?.[0]?.originalError?.data ?? 'UNKNOWN';

    switch (errorCode) {
      case 'TIMEOUT':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Connection Timeout</em> &#8212; The page took too long to load.
              </span>
            }
          />
        );
      case 'DNS':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Host Not Resolved</em> &#8212; We couldn&#39;t resolve the site to an actual server. Please check
                the URL and try again.
              </span>
            }
          />
        );
      case 'RESET':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Connection Reset</em> &#8212; The connection to the server was reset. Please check the URL and try
                again.
              </span>
            }
          />
        );
      case 'REFUSED':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Connection Refused</em> &#8212; The connection to the server was refused when trying to check the
                Tracking Script installation.
              </span>
            }
          />
        );
      case 'BAD_CERT':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Certificate Issue</em> &#8212; The server had an invalid or insecure certificate when trying to
                check the Tracking Script installation.
              </span>
            }
          />
        );
      case 'BLOCKED':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Connection Blocked</em> &#8212; The server blocked the connection. This usually happens when there
                is some bot/DDoS protection service active for the site.
              </span>
            }
          />
        );
      case 'NOT_FOUND':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Page Not Found</em> &#8212; The page was not found. Please check the URL and try again.
              </span>
            }
          />
        );
      case 'HTTP_4XX':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>HTTP Error</em> &#8212; The server returned an error instead of the page. Please check the URL and
                try again. If the error persists, the installation can&#39;t be automatically checked.
              </span>
            }
          />
        );
      case 'HTTP_5XX':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>HTTP Error</em> &#8212; The server returned an error instead of the page. Please check the URL and
                try again. If the error persists, the installation can&#39;t be automatically checked.
              </span>
            }
          />
        );
      case 'INVOCATION':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Execution Error</em> &#8212; An error occurred while we were processing the website. Please try
                again later.
              </span>
            }
          />
        );
      case 'UNKNOWN':
        return (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Unknown Error</em> &#8212; There was an unknown error when trying to access the page and perform the
                checks on it. Please check the URL and try again to see if this error persists.
              </span>
            }
          />
        );
      default:
        return sessionInfo.isImpersonated ? (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Unknown Error</em> &#8212; {JSON.stringify(errorCode)}
              </span>
            }
          />
        ) : (
          <ErrorItem
            title="Trouble Accessing the Page"
            description={
              <span>
                <em>Unknown Error</em> &#8212; There was an unknown error when trying to access the page and perform the
                checks on it. Please check the URL and try again to see if this error persists.
              </span>
            }
          />
        );
    }
  }
}
