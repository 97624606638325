import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const accountFeatureKeys = createQueryKeys('accountFeature', {
  paymentTransactionList: ({ field, sort, limit }) => [
    {
      order: {
        field,
        sort,
      },
      limit,
    },
  ],
  paymentTransaction: ({ id }) => [{ id }],
  billingDetails: null,
});

export const paymentTransactionListQuery = ({ field, sort, limit }) => ({
  ...accountFeatureKeys.paymentTransactionList({ field, sort, limit }),
  getNextPageParam: (lastPage) =>
    lastPage.paymentTransactionList.hasNextPage ? lastPage.paymentTransactionList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query PaymentTransactionList($cursor: String, $order: PaymentTransactionListOrder!, $limit: Int) {
        paymentTransactionList(cursor: $cursor, order: $order, limit: $limit) {
          hasNextPage
          nextPageCursor
          paymentTransactions {
            amount
            cardNumber
            createdAt
            id
            paymentType
            planName
          }
        }
      }
    `,
  },
});

export const paymentTransactionQuery = ({ id }) => ({
  ...accountFeatureKeys.paymentTransaction({ id }),
  meta: {
    query: gql`
      query PaymentTransaction($id: Int!) {
        paymentTransaction(id: $id) {
          amount
          cardNumber
          createdAt
          id
          paymentType
          planName
          companyName
          companyId
          companyVat
          companyAddress {
            address
            city
            country
            stateOrProvince
            zipOrPostalCode
          }
          address {
            address
            city
            country
            stateOrProvince
            zipOrPostalCode
          }
          paypalEmail
          description
          firstName
          lastName
        }
      }
    `,
  },
});

export const billingDetailsQuery = () => ({
  ...accountFeatureKeys.billingDetails,
  meta: {
    query: gql`
      query BillingDetails {
        billingDetails {
          ccAddress {
            address
            city
            country
            stateOrProvince
            zipOrPostalCode
          }
          ccExpiration
          ccFirstName
          ccLastName
          ccNumberSanitized
          ccType
          companyAddress {
            address
            city
            country
            stateOrProvince
            zipOrPostalCode
          }
          companyId
          companyName
          companyVat
        }
      }
    `,
  },
});
