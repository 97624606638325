import { useState } from 'react';
import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { FilterTagsSelect } from '/src/features/recordings/components/recordings-filter/FilterSelect';

export function TagRecordingsModal({ onConfirm }) {
  const [selectedTags, setSelectedTags] = useState();
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[530px]" data-testid="tagModal">
      <Modal.Title>Tag sessions</Modal.Title>

      <div className="text-body-2">
        <div className="mb-1.25">Select or create a tag to add to these sessions</div>
        <FilterTagsSelect
          placeholder="Search or create tags"
          value={selectedTags}
          onChange={(value) => {
            setSelectedTags(value.length ? value.map((o) => o.value ?? o) : undefined);
          }}
          label="tags"
          creatable={true}
          menuPosition="fixed"
          menuPortalTarget={document.body}
          containerStyle={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}
        />
      </div>

      <Modal.Actions>
        <Button
          onClick={() => {
            onConfirm?.(selectedTags);
            modal.close();
          }}
          disabled={!selectedTags}
        >
          Apply
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
