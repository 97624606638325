import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

import { useSearchParams, usePermissions } from '/src/hooks';
import { DashHeader } from '/src/components/headers/DashHeader';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

export function GoalsDashHeader({ subPage = false, actionButton = null }) {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.GOALS).allowed;
  const canCreateGoals = permissions.can('create', FEATURES.GOALS).allowed;
  const canEditGoals = permissions.can('edit', FEATURES.GOALS).allowed;

  const backLink = search.get('back') ? decodeURIComponent(search.get('back')) : '/goals';
  const backText = search.get('back') ? 'Back to Dashboard' : 'Back to all Goals';

  return (
    <DashHeader
      title={getFeatureName(FEATURES.GOALS)}
      actionButton={
        <div className="flex items-center space-x-2.5">
          {canVisitDashboard && subPage && (
            <Link
              to={backLink}
              className="text-button flex h-[45px] items-center rounded border border-solitude-500 bg-solitude-500 px-[15px] text-dodger-blue-500 hover:border-dodger-blue-500 focus:outline-black"
            >
              <ArrowIcon aria-label="back to all goals" className="mr-2 h-4 w-4 rotate-180 transform fill-current" />
              {backText}
            </Link>
          )}
          {canEditGoals ? actionButton : null}
          {canCreateGoals ? (
            <Button
              size="xl"
              onClick={() => {
                navigate('/goals/new');
              }}
              leftIcon={<PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />}
            >
              Create New Goal
            </Button>
          ) : null}
        </div>
      }
    />
  );
}
