import { useFormikContext } from 'formik';
import { RG } from '@crazyegginc/hatch';

import { CTA_TYPES } from '/src/features/addons/constants';
import { useEditorContext } from '../../editor-context';
import { WebP } from '../../../../components/WebP';
import { TypeCard } from '../../common/editor/TypeCard';

import PopupPNG from '@crazyegginc/hatch/dist/images/illustration-cta-popup.png';
import PopupWebP from '@crazyegginc/hatch/dist/images/illustration-cta-popup.webp';
import ButtonPNG from '@crazyegginc/hatch/dist/images/illustration-cta-button.png';
import ButtonWebP from '@crazyegginc/hatch/dist/images/illustration-cta-button.webp';
import BarPNG from '@crazyegginc/hatch/dist/images/illustration-cta-bar.png';
import BarWebP from '@crazyegginc/hatch/dist/images/illustration-cta-bar.webp';

export function TypeSelector() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  const disabled = readonly;

  return (
    <div className="mb-10 ml-10">
      <RG
        value={values.displayType}
        onChange={(newValue) => setFieldValue('displayType', newValue)}
        disabled={disabled}
      >
        <RG.Label className="mb-1 block !text-lg">Type</RG.Label>
        <div className="flex w-full space-x-2.5">
          <TypeCard
            type={CTA_TYPES.BUTTON}
            icon={<WebP webp={ButtonWebP} fallback={ButtonPNG} width="66px" height="54px" />}
            label="Button"
            disabled={disabled}
          />
          <TypeCard
            type={CTA_TYPES.POPUP}
            icon={<WebP webp={PopupWebP} fallback={PopupPNG} width="66px" height="54px" />}
            label="Popup"
            disabled={disabled}
          />
          <TypeCard
            type={CTA_TYPES.BAR}
            icon={<WebP webp={BarWebP} fallback={BarPNG} width="66px" height="54px" />}
            label="Bar"
            disabled={disabled}
          />
        </div>
      </RG>
    </div>
  );
}
