import { useState } from 'react';
import { Button, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { inflect } from '/src/utils/string';

export function DeleteUserModal({ onDelete = null, numToDelete }) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal dialogClassName="!w-[360px]">
      <Modal.Title>{`Delete ${numToDelete > 1 ? numToDelete : ''} ${inflect(
        'team member',
        numToDelete,
      )}?`}</Modal.Title>
      <p className="text-body-5 leading-5">
        If you delete {numToDelete > 1 ? 'these' : 'this'} {inflect('team member', numToDelete)}, they will no longer
        have access to this Crazy Egg account. This cannot be undone.
      </p>
      <Modal.Actions>
        <Button
          variant="warning"
          disabled={submitting}
          leftIcon={submitting ? <Spinner className="mr-1 h-3 w-3" /> : null}
          onClick={() => {
            setSubmitting(true);
            onDelete?.();
          }}
        >
          {submitting ? 'Deleting...' : `Delete ${inflect('member', numToDelete)}`}
        </Button>
        <Modal.Cancel />
      </Modal.Actions>
    </Modal>
  );
}
