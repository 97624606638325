/* eslint-disable no-restricted-syntax */
import { Formik, Form } from 'formik';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@crazyegginc/hatch';

import { useMutation, useNotifications } from '/src/hooks';
import { AbTestGoalSelectPage } from '../components/wizard/new/goal';
import { abTestDetailQuery } from '../queries';
import { abTestChangeGoalMutation } from '../mutations';

const CANCEL_PAGE_PATH = '/ab-tests';
const NEXT_PAGE_PATH = '/ab-tests';

export default function EditGoalPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const notifications = useNotifications();

  const { data, isInitialLoading } = useQuery({
    ...abTestDetailQuery({ id: Number(id) }),
    enabled: !!id,
  });

  const { mutate: mutateSaveGoal } = useMutation(abTestChangeGoalMutation);

  if (!data && isInitialLoading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        siteId: data?.abTestDetail?.siteId,
        goalId: data?.abTestDetail?.goal?.id ?? null,
      }}
      onSubmit={(values) => {
        mutateSaveGoal(
          {
            id: Number(id),
            goalId: values.goalId,
          },
          {
            onSuccess: () => {
              notifications.success({ title: 'Goal changed successfully!', timeout: 3000 });
              navigate(location.state?.previousPath, { replace: true });
            },
          },
        );
      }}
    >
      {({ values, submitForm }) => (
        <Form className="w-full">
          <AbTestGoalSelectPage
            showProgress={false}
            confirmCancel={false}
            backEnabled={false}
            title="Edit goal"
            nextText="Save"
            cancelPagePath={location.state?.previousPath || CANCEL_PAGE_PATH}
            nextPagePath={NEXT_PAGE_PATH}
            nextEnabled={values.goalId && values.goalId !== data?.abTestDetail?.goal?.id}
            onNext={submitForm}
          />
        </Form>
      )}
    </Formik>
  );
}
