import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const snapshotFeatureKeys = createQueryKeys('snapshotFeature', {
  snapshot: ({ id }) => [{ id }],
  snapshots: ({ page, perPage, query, status, folderId, siteId, field, sort }) => [
    {
      filter: {
        page,
        perPage,
        query,
        status,
        folderId,
        siteId,
      },
      order: {
        field,
        sort,
      },
    },
  ],
  folders: ({ siteId }) => [
    {
      filter: {
        siteId,
        type: 'snapshots',
      },
    },
  ],
  snapshotExports: null,
  checkTitle: ({ url }) => [{ url }],
  checkPermission: ({ url }) => [{ url }],
  thumbnails: ({ requests }) => [{ requests }],
  checkSnapshots: ({ params, settings }) => [{ params, settings }],
  checkSnapshotsQuota: ({ newSnapshotsCount }) => [{ newSnapshotsCount }],
});

// TODO...?
export const SNAPSHOTS_SITES_QUERY = gql`
  query SitesForSnapshots {
    sitesForSnapshots {
      __typename
      id
      installed
      lastData
      name
      snapshotsCount
      totalVisits
      lastData
      active
      metadata
    }
  }
`;

export const snapshotsQuery = ({ page, perPage, query, status, folderId, siteId, field, sort }) => ({
  ...snapshotFeatureKeys.snapshots({ page, perPage, query, status, folderId, siteId, field, sort }),
  meta: {
    query: gql`
      query SnapshotsQuery($filter: SnapshotListFilter!, $order: SnapshotListOrder!) {
        snapshots(filter: $filter, order: $order) {
          meta {
            userId
            total
            totalPages
            page
            perPage
            counts {
              total
              running
              completed
            }
          }
          list {
            __typename
            id
            name
            createdAt
            createdByUserName
            totalVisits
            maxVisits
            device
            sourceUrl
            thumbnailUrl
            duration
            totalDays
            status
            snapshotStatus
            isAbTestVariant
            hasData
            manualScreenshot
            folderId
            siteId
            startsAt
            expiresAt
            endedAt
            firstEvent
            reoccurring
            permissions {
              canDuplicate
              canEdit
              canEditExpiration
              canEditStartDate
              canExport
              canPause
              canRefresh
              canResume
              canSetReoccurring
              canUnsetReoccurring
              canView
            }
          }
        }
      }
    `,
  },
});

export const foldersQuery = ({ siteId }) => ({
  ...snapshotFeatureKeys.folders({ siteId }),
  meta: {
    query: gql`
      query UserFolders($filter: FolderListFilter!) {
        folders(filter: $filter) {
          list {
            id
            siteId
            title
            type
            snapshotsCount
          }
        }
      }
    `,
  },
});

export const snapshotExportsQuery = () => ({
  ...snapshotFeatureKeys.snapshotExports,
  staleTime: 0,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query SnapshotExports {
        snapshotExports {
          id
          name
          status
          timestamp
          url
        }
      }
    `,
  },
});

export const singleSnapshotQuery = ({ id }) => ({
  ...snapshotFeatureKeys.snapshot({ id }),
  staleTime: 0,
  meta: {
    query: gql`
      query SingleSnapshot($id: Int!) {
        snapshot(id: $id) {
          __typename
          id
          name
          status
          createdAt
          createdByUserName
          totalVisits
          maxVisits
          siteId
          duration
          totalDays
          thumbnailUrl
          stoppedAt
          deletedAt
          screenshotStatus
          numUnprocessedLogs
          manualScreenshot
          snapshotStatus
          endedAt
          expiresAtOption
          expiresAt
          startsAt
          startsAtOption
          useSamplingRatio
          samplingRatio
          trackingDevices
          trackingOption
          matchingRuleParams
          removeElementsOption
          removeElements
          useWaitAfterLoad
          waitAfterLoad
          sourceUrl
          device
          isAbTestVariant
          permissions {
            canDuplicate
            canEdit
            canEditExpiration
            canEditStartDate
            canExport
            canPause
            canRefresh
            canResume
            canSetReoccurring
            canUnsetReoccurring
            canView
          }
          reoccurring
        }
      }
    `,
  },
});

export const checkTitleQuery = ({ url }) => ({
  ...snapshotFeatureKeys.checkTitle({ url }),
  staleTime: 0,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query CheckUrl($url: String!) {
        checkTitle(url: $url) {
          didRedirect
          domain
          finalUrl
          pageTitle
          isLoginPage
          permissionDenied
          requestedUrl
        }
      }
    `,
  },
});

export const checkPermissionQuery = ({ url }) => ({
  ...snapshotFeatureKeys.checkPermission({ url }),
  staleTime: 0,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query CheckPermission($url: String!) {
        checkPermission(url: $url) {
          domain
          permissionDenied
          requestedUrl
        }
      }
    `,
  },
});

export const thumbnailsQuery = ({ requests }) => ({
  ...snapshotFeatureKeys.thumbnails({ requests }),
  meta: {
    query: gql`
      query Thumbnails($requests: [ThumbnailRequest!]!) {
        thumbnails(requests: $requests) {
          url
          thumbnailUrl
        }
      }
    `,
  },
});

export const checkSnapshotsQuotaQuery = ({ newSnapshotsCount }) => ({
  ...snapshotFeatureKeys.checkSnapshotsQuota({ newSnapshotsCount }),
  staleTime: 0,
  meta: {
    query: gql`
      query CheckSnapshotsQuota($newSnapshotsCount: Int!) {
        checkSnapshotsQuota(newSnapshotsCount: $newSnapshotsCount)
      }
    `,
  },
});

export const SNAPSHOT_NAME_ONLY_QUERY = gql`
  query SingleSnapshotName($id: Int!) {
    snapshot(id: $id) {
      id
      name
    }
  }
`;

export const PAGE_CAMERA_SNAPSHOTS = gql`
  query PageCameraSnapshots {
    pageCameraSnapshots {
      __typename
      id
      name
      sourceUrl
      manualScreenshot
      thumbnailUrl
      createdAt
      totalVisits
      totalClicks
      device
      formatVersion
    }
  }
`;

export const PAGE_CAMERA_SNAPSHOT_UPLOAD_POLICY = gql`
  query PageCameraGetUploadPolicy($snapshotId: Int!) {
    pageCameraGetUploadPolicy(snapshot_id: $snapshotId) {
      formatVersion
      screenshot {
        url
        fields
      }
      elements {
        url
        fields
      }
    }
  }
`;

export const PAGE_CAMERA_URL_UPLOAD_POLICY = gql`
  query PageCameraGetUploadPolicy($sourceUrl: String!, $device: String!) {
    pageCameraGetUploadPolicy(sourceUrl: $sourceUrl, device: $device) {
      screenshot {
        url
        fields
      }
      fingerprints {
        url
        fields
      }
    }
  }
`;

export const PAGE_CAMERA_IS_LOGGED_IN = gql`
  query PageCameraIsLoggedIn {
    pageCameraIsLoggedIn {
      loggedIn
      canEditSnapshot
      overNumberOfSnapshots
    }
  }
`;
