export const AB_TEST_RATIO_CHANGE = 'AB_TEST_RATIO_CHANGE';
export const BILLING_CHANGE = 'BILLING_CHANGE';
export const DELETED_AB_TEST = 'DELETED_AB_TEST';
export const DELETED_RECORDINGS = 'DELETED_RECORDINGS';
export const DELETED_SITE = 'DELETED_SITE';
export const DELETED_SNAPSHOTS = 'DELETED_SNAPSHOTS';
export const DUPLICATED_AB_TEST = 'DUPLICATED_AB_TEST';
export const INTERNAL_URL_VISITED = 'INTERNAL_URL_VISITED';
export const RECORDING_SETTINGS_CHANGE = 'RECORDING_SETTINGS_CHANGE';
export const SNAPSHOT_SETTINGS_CHANGE = 'SNAPSHOT_SETTINGS_CHANGE';
export const SNAPSHOTS_CREATED = 'SNAPSHOTS_CREATED';
export const DELETED_SURVEY = 'DELETED_SURVEY';
export const SURVEY_CREATED = 'SURVEY_CREATED';
export const SURVEY_EDITED = 'SURVEY_EDITED';
export const SURVEY_TOGGLED = 'SURVEY_TOGGLED';
export const SURVEY_VIEW_RESPONSES = 'SURVEY_VIEW_RESPONSES';
export const SNAPSHOTS_VIEWED = 'SNAPSHOTS_VIEWED';
export const INSTALL = 'INSTALL';

export const defaultActionsToInclude = [
  AB_TEST_RATIO_CHANGE,
  BILLING_CHANGE,
  DELETED_AB_TEST,
  DELETED_RECORDINGS,
  DELETED_SITE,
  DELETED_SNAPSHOTS,
  DUPLICATED_AB_TEST,
  INTERNAL_URL_VISITED,
  RECORDING_SETTINGS_CHANGE,
  SNAPSHOT_SETTINGS_CHANGE,
  SNAPSHOTS_VIEWED,
  SNAPSHOTS_CREATED,
  INSTALL,
  DELETED_SURVEY,
  SURVEY_CREATED,
  SURVEY_EDITED,
  SURVEY_TOGGLED,
  SURVEY_VIEW_RESPONSES,
];
