import { Chart as ChartJS } from 'chart.js';

export const SyncedLines = {
  id: 'syncedlines',
  afterDraw: (chart) => {
    if (chart.tooltip?._active?.length) {
      let x = chart.tooltip._active[0].element.x;
      let yAxis = chart.scales.y;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 3;
      ctx.strokeStyle = '#A5ACBC';
      ctx.setLineDash([8, 8]);
      ctx.stroke();
      ctx.restore();
    }
    const index = chart.tooltip?._active?.[0]?.index;
    chart.options.plugins.syncedlines.chartIds.forEach((id) => {
      if (id !== chart.canvas.id) {
        const otherChart = ChartJS.getChart(id);
        const activeElements = otherChart?.tooltip?.getActiveElements();
        const activeIndex = activeElements?.[0]?.index;
        if (chart.tooltip?._active?.length) {
          if (activeIndex !== index) {
            otherChart?.tooltip?.setActiveElements([{ datasetIndex: 0, index: index }], {
              x: (otherChart.chartArea.left + otherChart.chartArea.right) / 2,
              y: (otherChart.chartArea.top + otherChart.chartArea.bottom) / 2,
            });
            otherChart?.setActiveElements([{ datasetIndex: 0, index: index }]);
            otherChart?.update();
          }
        } else {
          if (activeElements?.length !== 0) {
            otherChart?.tooltip?.setActiveElements([], { x: 0, y: 0 });
            otherChart?.setActiveElements([]);
            otherChart?.update();
          }
        }
      }
    });
  },
};
