import { Button } from '@crazyegginc/hatch';
import { usePermissions, useModal, useAuthContext } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';

import { FEATURES } from '/src/features/_global/constants';

import SitePNG from '@crazyegginc/hatch/dist/images/illustration-page-warn.png';
import SiteWebP from '@crazyegginc/hatch/dist/images/illustration-page-warn.webp';

export function FirstSiteWall() {
  const { currentAccount } = useAuthContext();
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const modal = useModal();

  function addNewSite() {
    if (!canEditSites) return;

    modal.show(<AddSiteModal />);
  }

  return (
    <Paywall>
      <Paywall.Image webp={SiteWebP} png={SitePNG} width={160} />
      <Paywall.Title>You don’t have any sites</Paywall.Title>
      <div className="mt-6">
        {canEditSites ? (
          <Button onClick={addNewSite}>Add a Site</Button>
        ) : (
          <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
            Contact account owner
          </Button>
        )}
      </div>
    </Paywall>
  );
}
