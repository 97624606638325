import { Tooltip, IconButton } from '@crazyegginc/hatch';

import { usePermissions, useModal } from '/src/hooks';
import { RenameAddonModal } from '../modals/RenameAddonModal';

import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

export function AddonNameTitle({ fetching, addon }) {
  const modal = useModal();
  const permissions = usePermissions();

  const canRename = permissions.can('rename', addon).allowed;

  return (
    <div className="mr-5 flex items-center space-x-1">
      <span className="truncate">{fetching ? 'Loading...' : (addon?.name ?? '')}</span>
      {!fetching && addon?.name && canRename ? (
        <Tooltip placement="bottom" tooltipContent="Rename Addon">
          <IconButton
            icon={<EditIcon className="h-4 w-4 fill-current" />}
            className="h-full shrink-0 text-malibu-500 hover:text-dodger-blue-500"
            onClick={() => {
              modal.show(<RenameAddonModal addon={addon} />);
            }}
            label="edit addon name"
          />
        </Tooltip>
      ) : null}
    </div>
  );
}
