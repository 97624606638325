import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Link, Navigate, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ReactTable, Spinner, SkeletonLine, Checkbox, Button, DateRangePicker, Tooltip } from '@crazyegginc/hatch';
import { Modal } from '/src/contexts/modal';
import { fromUnixTime, getUnixTime, startOfDay, endOfDay } from 'date-fns';
import { useAtom, atom } from 'jotai';
import { abTestTypes } from '/src/features/ab-testing/constants';

import { LazyImg } from '/src/components/LazyImg';
import { SelectionProvider } from '/src/contexts/selection';
import { formatLongDate } from '/src/utils/date';
import { displayUrl } from '/src/utils/url';
import { CreateAbTestButton } from '../components/CreateAbTestButton';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { DashboardPage } from '/src/components/Page';
import { LinkWithParams } from '/src/components/LinkWithParams';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';

import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { camelToSnake, snakeToCamel } from '/src/utils/string';

import { useModal, useAuthContext, useNotifications, usePermissions, useSelection } from '/src/hooks';
import { SPECIAL_DATE_RANGES } from '/src/features/_global/constants';

import { AbTestDetailModal } from '../components/modals/AbTestDetailModal';
import { NotesModal } from '../components/modals/NotesModal';
import { AbTestActionButton } from '../components/AbTestActionButton';
import { SummaryRow, SummaryCard, SummaryGoalSelector } from '../components/SummaryRow';
import {
  DistributionChart,
  abTestDistributionDatasetAtom,
} from '/src/features/ab-testing/components/DistributionChart';
import { getConvertedDateRange } from '/src/utils/date';

import { AddSplitVariant } from '../components/modals/AddSplitVariant';
import { EditSplitVariant } from '../components/modals/EditSplitVariant';
// import { AbTestRenameModal } from '../components/modals/AbTestRenameModal';

import { formatCurrency, isProduction, isSharedResourceSubscriptionError } from '/src/utils';
import { RateDelta } from '../utils';

import { abTestDetailV2Query } from '../queries';
import { goalSimpleListQuery } from '/src/features/goals/queries';
import { FEATURES } from '/src/features/_global/constants';
import { AB_TEST_STATUSES } from '../constants';

import { ReactComponent as DescriptionIcon } from '@crazyegginc/hatch/dist/images/icon-page-w-text-outline.svg';
import { ReactComponent as NotesIcon } from '@crazyegginc/hatch/dist/images/icon-form.svg';
import { ReactComponent as NotesAddedIcon } from '@crazyegginc/hatch/dist/images/icon-form-tick.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { VariantActionButton } from '../components/VariantActionButton';
import { ReactComponent as EyeIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as ImgPlaceholderIcon } from '@crazyegginc/hatch/dist/images/illustration-thumbnail-placeholder.svg';

const isProd = isProduction();

export const abTestSelectedDatesAtom = atom({
  special: 'default',
  startDate: null,
  endDate: null,
});

function VariantScreenshotModal({ variant }) {
  const modal = useModal();
  const notifications = useNotifications();

  return (
    <Modal dialogClassName="!w-auto max-w-[90vw] max-h-[90vh] pt-10">
      <img
        src={variant.screenshotUrl}
        alt="screenshot"
        onError={() => {
          notifications.info({
            title: 'Screenshot still processing',
            content: `This variant's screenshot is still being processed. Please check back later.`,
            timeout: 3000,
          });
          modal.close();
        }}
      />
    </Modal>
  );
}

// function variantResultSortFunc(variant1, variant2) {
//   return (variant2.results.improvementFromControl || 0) - (variant1.results.improvementFromControl || 0);
// }
//
// function variantPositionSortFunc(variant1, variant2) {
//   const firstVariant = variant1.position || 0;
//   const secondVariant = variant2.position || 0;
//
//   if (firstVariant > secondVariant) return 1;
//   if (firstVariant < secondVariant) return -1;
//   return 0;
// }
//

function getSpecialDateRangeForTest(data) {
  if (data.stoppedAt || data.status === 'STOPPED') {
    return getConvertedDateRange({ special: SPECIAL_DATE_RANGES.LAST_100_DAYS }, false, fromUnixTime(data.stoppedAt));
  }
  return getConvertedDateRange({ special: SPECIAL_DATE_RANGES.LAST_30_DAYS });
}

const enableRetiredVariantsAtom = atom(false);

function AbTestResults() {
  const { currentUser, isSharing } = useAuthContext();
  const defaultCurrency = currentUser?.settings?.defaultCurrency ?? 'USD';
  const { addToSelection, currentSelection } = useSelection();
  const [selectedGoal, setSelectedGoal] = useState(null);
  const modal = useModal();
  const perfMetricsStarted = useRef(false);
  const variantsTableRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const notifications = useNotifications();
  const [selection, setSelection] = useAtom(abTestSelectedDatesAtom);
  const [dataset, setDataset] = useAtom(abTestDistributionDatasetAtom);
  const [searchParams] = useSearchParams();
  const order = searchParams.get('order') || 'NAME';
  const sort = searchParams.get('sort') || 'ASC';
  const [showRetiredVariants] = useAtom(enableRetiredVariantsAtom);

  const permissions = usePermissions();

  const {
    data: resultData,
    isFetching,
    isInitialLoading,
    error,
    refetch,
  } = useQuery({
    ...abTestDetailV2Query({
      id: Number(id),
      goalId: selectedGoal?.id ? Number(selectedGoal.id) : undefined,
      presetDate: selection.special,
      startDate: selection.special ? undefined : selection.startDate,
      endDate: selection.special ? undefined : selection.endDate,
      includeGoals: true,
      order,
      sort,
    }),
    enabled: !!id,
    onSuccess(data) {
      if (!selectedGoal?.id) {
        setSelectedGoal({
          id: data?.abTestDetailV2?.selectedGoal?.id ?? data?.abTestDetailV2?.goal?.id,
          name: data?.abTestDetailV2?.selectedGoal?.name ?? data?.abTestDetailV2?.goal?.name,
        });
      }

      if (!currentSelection || currentSelection.length === 0) {
        const variants = data.abTestDetailV2.variants.filter((v) => {
          if (!showRetiredVariants) {
            return !v.isRetired;
          }
          return true;
        });

        setTimeout(() => {
          addToSelection(variants);
        }, 100);
      }
    },
    keepPreviousData: true,
    cacheTime: 0,
    networkMode: 'always',
  });

  const { data: goalsData, isFetching: isFetchingGoals } = useQuery({
    ...goalSimpleListQuery({
      siteId: isSharing ? -1 : Number(resultData?.abTestDetailV2?.siteId),
    }),
    enabled: isSharing || !!resultData?.abTestDetailV2?.siteId,
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd && !perfMetricsStarted.current) {
      window.CE2.timing.start('ab_test_detail_v2');
      perfMetricsStarted.current = true;
    }
  }, []);

  useEffect(() => {
    if (window.CE2?.timing && isProd && perfMetricsStarted.current && (resultData || error) && !isFetching) {
      window.CE2.timing.stop('ab_test_detail_v2');
      perfMetricsStarted.current = false;
    }
  }, [error, isFetching, resultData]);

  useEffect(() => {
    if (error && error?.graphQLErrors?.find((error) => error.message === 'Not found')) {
      notifications.error({
        title: 'A/B Test Not Found',
        content: `Couldn't find the A/B Test you were looking for`,
        timeout: 3000,
        skipHoneybadger: true,
      });
      navigate(
        {
          pathname: '/ab-tests',
          search: location.search,
        },
        { replace: true },
      );
    }
  }, [error, navigate, location.search, notifications]);

  const setTestDateRange = useCallback(
    (value) => {
      // we need to do a comparison to avoid the DateRangePicker
      // triggering an infinite rerender, since it calls the `setDateRange`
      // function from inside a useEffect
      if (
        (value.start_date && value.start_date !== selection.startDate) ||
        (value.end_date && value.end_date !== selection.endDate)
      ) {
        setSelection({
          startDate: getUnixTime(value.start_date),
          endDate: getUnixTime(value.end_date),
        });
      }
    },
    [selection.endDate, selection.startDate, setSelection],
  );

  const data = resultData?.abTestDetailV2 ?? null;

  const setSpecialRange = useCallback(
    ({ special }) => {
      if (special && special !== selection.special) {
        const { startDate, endDate } =
          special === 'default' ? getSpecialDateRangeForTest(data) : getConvertedDateRange({ special });
        setSelection({ special, startDate, endDate });
      }
    },
    [selection.special, setSelection, data],
  );

  const testDefaultDateRange = useMemo(() => {
    if (!data) return { startDate: null, endDate: null };
    return getSpecialDateRangeForTest(data);
  }, [data]);

  // useEffect(() => {
  //   setSpecialRange({ special: SPECIAL_DATE_RANGES.LAST_30_DAYS });
  // }, [id]); // eslint-disable-line

  const backTo = new URLSearchParams(window.location.search).get('back');
  const isBackToGoal = backTo && backTo.startsWith('/goals/');
  const canGoBackToDash = isBackToGoal
    ? permissions.can('navigate', FEATURES.GOALS).allowed
    : permissions.can('navigate', FEATURES.AB_TESTING).allowed;

  const addNewVariant = useCallback(
    (editorPatch) => {
      if (data.type === abTestTypes.SPLIT) {
        modal.show(<AddSplitVariant abTest={data} />);
      } else {
        navigate(
          {
            pathname: `/ab-tests/${id}/variants/new`,
          },
          {
            replace: true,
            state: { editorPatch },
          },
        );
      }
    },
    [data, navigate, id, modal],
  );

  const editVariant = useCallback(
    (abTest, variant) => {
      if (abTest.type === abTestTypes.SPLIT) {
        modal.show(<EditSplitVariant abTest={abTest} variant={variant} />);
      } else {
        navigate(`/ab-tests/${abTest.id}/variants/${variant.id}/edit`);
      }
    },
    [modal, navigate],
  );

  const isDraft = data?.status === AB_TEST_STATUSES.DRAFT;
  const hasNotes = typeof data?.notes === 'string' && data?.notes?.trim?.() !== '';

  const variantsTableWidth = useMemo(() => variantsTableRef.current?.clientWidth ?? null, []);

  if (isDraft) {
    return <Navigate to={`/ab-tests/${id}/edit`} replace={true} />;
  }

  if (error && isSharedResourceSubscriptionError(error)) {
    return <Navigate replace to="/not-found" />;
  }

  const goalsList = goalsData?.goalSimpleList || data?.conversionGoals;

  return (
    <DashboardPage>
      <DashHeader
        titleComponent={
          <Breadcrumb>
            {!isSharing && (
              <BreadcrumbItem active={false} to="/ab-tests">
                A/B Testing
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active={true}>
              <div className="mr-5 flex items-center space-x-1">
                {data?.name ? (
                  <span className="truncate">{data.name}</span>
                ) : isInitialLoading ? (
                  <SkeletonLine width="300px" height="16px" />
                ) : null}
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex flex-shrink-0 items-center space-x-2">
            {canGoBackToDash ? (
              <LinkWithParams className="text-link flex items-center" to={backTo || '/ab-tests'} excluded={['back']}>
                <ArrowIcon className="mr-2.5 h-3 w-3 shrink-0 rotate-180 fill-current" /> Go back to{' '}
                {isBackToGoal ? 'goal' : 'tests'}
              </LinkWithParams>
            ) : null}
            <AbTestActionButton
              entity={data}
              redirectOnDelete="/ab-tests"
              refetch={refetch}
              addNewVariant={addNewVariant}
              editVariant={editVariant}
            />
            <CreateAbTestButton />
          </div>
        }
      />

      <div
        className="box-content p-10"
        style={{ minWidth: variantsTableWidth ? `${variantsTableWidth + 2}px` : undefined }}
      >
        <SEO title="A/B Testing Result" />
        {(isFetching && !data) || isInitialLoading ? (
          <div className="mt-16 flex w-full items-center justify-center">
            <Spinner />
            <div className="ml-2.5">Loading...</div>
          </div>
        ) : !data ? (
          <div className="mt-16 flex w-full items-center justify-center">
            <span>Failed to load data</span>
          </div>
        ) : (
          <>
            <div className="mb-[15px] flex items-center justify-between">
              <span className="text-body-5">
                Test published: {formatLongDate(data?.publishedAt)}
                {data?.publishedBy?.name ? ` by ${data.publishedBy.name}` : ''}
              </span>
              <div className="flex items-center space-x-5">
                <Button
                  variant="cancel"
                  onClick={() => modal.show(<AbTestDetailModal data={data} />)}
                  className="!px-0 !text-dodger-blue-500"
                >
                  <InfoIcon className="mr-1.5 h-4 w-4 fill-current" />
                  Test details
                </Button>

                <Button
                  variant="cancel"
                  onClick={() => modal.show(<NotesModal abTest={data} onSave={() => refetch?.()} />)}
                  className="!px-0 !text-dodger-blue-500"
                >
                  {hasNotes ? (
                    <NotesAddedIcon className="mr-1.5 h-4 w-4 fill-current" />
                  ) : (
                    <NotesIcon className="mr-1.5 h-4 w-4 fill-current" />
                  )}
                  Notes
                </Button>

                {selection.special === 'default' && !data ? null : (
                  <DateRangePicker
                    startDate={
                      selection.special === 'default'
                        ? fromUnixTime(testDefaultDateRange.startDate)
                        : fromUnixTime(selection.startDate)
                    }
                    endDate={
                      selection.special === 'default'
                        ? fromUnixTime(testDefaultDateRange.endDate)
                        : fromUnixTime(selection.endDate)
                    }
                    lowerBoundary={startOfDay(fromUnixTime(data.createdAt))}
                    upperBoundary={data.stoppedAt ? endOfDay(fromUnixTime(data.stoppedAt)) : endOfDay(new Date())}
                    setCommonDateRange={setSpecialRange}
                    size="lg"
                    special={selection.special === 'default' ? null : selection.special}
                    tooltipOptions={{}}
                    setDateRange={setTestDateRange}
                    showCommonRanges={true}
                    relativeTo={data.stoppedAt}
                    maxRange={365}
                  />
                )}
              </div>
            </div>

            <SummaryRow>
              <SummaryGoalSelector
                goals={goalsList}
                selected={selectedGoal}
                onClick={(goal) => {
                  if (goal.id) {
                    setSelectedGoal({
                      id: Number(goal.id),
                      name: goal.name,
                    });
                  }
                }}
                loading={(!isSharing && !goalsData) || isFetchingGoals}
              />
              <SummaryCard
                title="Unique visitors"
                value={data?.totalVisitors?.toLocaleString?.() ?? 0}
                onClick={() => setDataset('visitors')}
                selected={dataset === 'visitors'}
              />
              <SummaryCard
                title="Conversions"
                value={data?.totalConversions?.toLocaleString?.() ?? 0}
                onClick={() => setDataset('conversions')}
                selected={dataset === 'conversions'}
              />
              <SummaryCard
                title="Conversion rate"
                value={`${data?.avgConversionRate?.toLocaleString?.() ?? 0}%`}
                unit="avg"
                onClick={() => setDataset('conversion_rate')}
                selected={dataset === 'conversion_rate'}
              />
              {/* <SummaryCard title="Avg session worth" value="$6" onClick={() => setDataset('avg_session_worth')} selected={dataset === 'avg_session_worth'} /> */}
              <SummaryCard
                title="Total goal value"
                value={formatCurrency((data?.totalValue ?? 0) / 10000, defaultCurrency)}
                onClick={() => setDataset('value')}
                selected={dataset === 'value'}
              />
            </SummaryRow>

            <DistributionChart
              abTest={data}
              goal={selectedGoal}
              counts={{ totalConversions: data?.totalConversions, totalVisitors: data?.totalVisitors }}
              isFetching={isFetching}
            />
            <VariantsTable
              abTest={data}
              variantsTableRef={variantsTableRef}
              refetch={refetch}
              editVariant={editVariant}
              addNewVariant={addNewVariant}
              enableRetiredVariantsAtom={enableRetiredVariantsAtom}
            />
          </>
        )}
      </div>
    </DashboardPage>
  );
}

export function AbTestsResult() {
  return (
    <SelectionProvider>
      <AbTestResults />
    </SelectionProvider>
  );
}

function VariantsTable({
  abTest,
  variantsTableRef,
  refetch = null,
  editVariant = null,
  addNewVariant = null,
  enableRetiredVariantsAtom,
}) {
  const modal = useModal();
  const { variants } = abTest;
  const { currentSelection, removeFromSelection, addToSelection, clearSelection, toggleSelection } = useSelection();
  const hasRetiredVariants = useMemo(() => variants.some((v) => v.isRetired), [variants]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useAuthContext();
  const defaultCurrency = currentUser?.settings?.defaultCurrency ?? 'USD';
  const [showRetiredVariants, setShowRetiredVariants] = useAtom(enableRetiredVariantsAtom);

  const parsedVariants = useMemo(() => {
    if (!showRetiredVariants) {
      return variants.filter((v) => !v.isRetired);
    }
    return variants;
  }, [variants, showRetiredVariants]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        accessorKey: 'variantName',
        meta: {
          align: 'center',
          justify: 'left',
          padding: '0',
        },
        header: () => (
          <div className="flex items-center space-x-5">
            <div>
              <Checkbox
                checked={currentSelection.length && currentSelection.length === parsedVariants.length ? true : false}
                onChange={() => {
                  currentSelection.length === parsedVariants.total
                    ? clearSelection()
                    : parsedVariants.every((x) => currentSelection.find((y) => x.id === y.id))
                      ? removeFromSelection([...parsedVariants])
                      : addToSelection([...parsedVariants]);
                }}
                indeterminate={currentSelection.length > 0 && currentSelection.length < parsedVariants.total}
                title="Toggle all rows selected"
                size="lg"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <span className="font-bold">Variants</span>
            {hasRetiredVariants ? (
              <div className="absolute right-28">
                <Checkbox
                  id="showRetiredVariants"
                  label={<span className="text-body-2 !normal-case">Show retired variants</span>}
                  value={showRetiredVariants}
                  checked={showRetiredVariants}
                  onChange={() =>
                    setShowRetiredVariants((currentValue) => {
                      // if currentSelection contains any retired variants and we toggle this off, remove them
                      const newValue = !currentValue;

                      if (!newValue) {
                        const retiredVariants = currentSelection.filter((v) => v.isRetired);
                        removeFromSelection([...retiredVariants]);
                      }

                      return newValue;
                    })
                  }
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            ) : null}
          </div>
        ),
        cell({ row }) {
          const selectedIndex = currentSelection.findIndex((x) => x.id === row.original.id);
          return (
            <div className="flex h-full w-full items-center">
              <VariantColor index={selectedIndex} />
              <div className="mx-[14px]">
                <Checkbox
                  checked={selectedIndex > -1}
                  onChange={() => toggleSelection({ ...row.original })}
                  size="lg"
                />
              </div>

              <div
                className={classNames(
                  'group relative mr-6 h-10 w-[65px] overflow-hidden rounded border border-[#E1E6EF] bg-mystic-500 flex-shrink-0',
                  {
                    'cursor-pointer': !!row.original?.screenshotUrl,
                  },
                )}
              >
                <LazyImg
                  className="mr-5 !h-10 w-[65px] rounded !border !border-mystic-500 object-cover"
                  alt="variant thumbnail"
                  Placeholder={ImgPlaceholderIcon}
                  src={row.original?.screenshotUrl ?? null}
                  width="65px"
                  height="40px"
                  onClick={() => {
                    if (!row.original?.screenshotUrl) return;
                    modal.show(<VariantScreenshotModal variant={row.original} />);
                  }}
                />
                {row.original?.screenshotUrl ? (
                  <div className="pointer-events-none absolute bottom-0 right-0 hidden h-10 w-16 items-center justify-center rounded bg-regal-blue-700 bg-opacity-60 border border-[#E1E6EF] group-hover:flex">
                    <EyeIcon className="h-4 w-4 fill-current text-white" />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col truncate">
                <div className="flex items-center space-x-2.5">
                  <span
                    className={classNames({
                      'text-body-1': !row.original.isRetired,
                      'text-body-3 font-semibold': row.original.isRetired,
                    })}
                  >
                    {row.original.variantName}
                    {row.original.isRetired ? ` (retired)` : ''}
                  </span>
                  {typeof row.original.description === 'string' && row.original.description.trim() !== '' ? (
                    <Tooltip tooltipContent={<div className="max-w-[174px] text-left">{row.original.description}</div>}>
                      <DescriptionIcon className="h-[14px] w-[14px] fill-current text-cadet-blue-500" />
                    </Tooltip>
                  ) : null}
                </div>
                <a
                  className="text-body-2 text-link truncate"
                  href={row.original.viewVariantUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={displayUrl(row.original.redirectUrl || abTest.pageUrl)}
                >
                  {displayUrl(row.original.redirectUrl || abTest.pageUrl)}
                </a>
              </div>
            </div>
          );
        },
        size: 300,
      },
      {
        id: 'visitors',
        accessorKey: 'results.visitors',
        meta: {
          align: 'center',
          justify: 'center',
        },
        header: () => (
          <div>
            <div className="text-center font-bold">
              Unique
              <br />
              Visitors
            </div>
          </div>
        ),
        cell({ row }) {
          return <span>{(row.original.results.visitors || 0).toLocaleString()}</span>;
        },
      },
      {
        id: 'conversions',
        header: () => (
          <div>
            <div className="text-center font-bold">Conversions</div>
          </div>
        ),
        accessorKey: 'results.conversions',
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell({ row }) {
          return <span>{(row.original.results.conversions || 0).toLocaleString()}</span>;
        },
      },
      {
        id: 'conversionRate',
        accessorKey: 'results.conversion_rate',
        meta: {
          align: 'center',
          justify: 'center',
        },
        header: () => (
          <div>
            <div className="text-center font-bold">
              Conversion
              <br />
              Rate
            </div>
          </div>
        ),
        cell({ row }) {
          return (
            <div className="flex flex-col items-center">
              <span>{(row.original.results.conversionRate || 0).toFixed(2).toLocaleString()}%</span>
              <RateDelta value={row.original.results.improvementFromControl} />
            </div>
          );
        },
      },
      {
        id: 'significance',
        accessorKey: 'significance',
        meta: {
          align: 'center',
          justify: 'center',
        },
        header: () => (
          <div>
            <Tooltip
              tooltipContent={
                <div className="max-w-[250px] text-left">
                  Statistical significance shows how likely your A/B test results are real, not random. 95% confidence
                  or higher is considered significant.
                </div>
              }
            >
              <div className="border-b border-dashed border-cadet-blue-500">
                <div className="text-center font-bold">
                  Statistical
                  <br />
                  Significance
                </div>
              </div>
            </Tooltip>
          </div>
        ),
        cell({ row }) {
          if (row.original.significance && row.original.significance < 1) {
            return <span>&lt;1%</span>;
          }
          if (row.original.significance) {
            const finalValue = Math.round(row.original.significance);
            const isSignificant = finalValue >= 95;
            return (
              <span
                className={classNames({
                  'font-semibold text-lima-500': isSignificant,
                })}
              >
                {finalValue}%
              </span>
            );
          }
          return <span>0%</span>;
        },
      },
      // {
      //   id: 'avg_value',
      //   header: () => <span className="text-center">Avg Session<br />Worth (USD)</span>,
      //   meta: {
      //     align: 'center',
      //     justify: 'center',
      //     headerCellClassnames: 'border-l border-mystic-500',
      //     rowCellClassnames: 'border-l border-mystic-500',
      //   },
      //   cell() {
      //     return <span>a</span>
      //   }
      // },
      {
        id: 'value',
        accessorKey: 'results.total_value',
        meta: {
          align: 'center',
          justify: 'center',
          headerCellClassnames: 'border-l border-mystic-500',
          rowCellClassnames: 'border-l border-mystic-500',
        },
        header: () => (
          <div>
            <div className="text-center font-bold">
              Total Goal
              <br />
              Value ({defaultCurrency})
            </div>
          </div>
        ),
        cell({ row }) {
          if (abTest.selectedGoal.hasValue) {
            return <span>{formatCurrency(row.original.results.value / 10000, defaultCurrency)}</span>;
          }
          return (
            <Link to={`/goals/${abTest.selectedGoal.id}/edit-value`} className="text-link">
              Set Goal value
            </Link>
          );
        },
      },
      {
        id: 'actions',
        enableSorting: false,
        minSize: 60,
        maxSize: 60,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell({ row }) {
          return (
            <VariantActionButton
              abTest={abTest}
              entity={row.original}
              refetch={refetch}
              editVariant={editVariant}
              addNewVariant={addNewVariant}
            />
          );
        },
      },
    ];
  }, [
    refetch,
    abTest,
    currentSelection,
    removeFromSelection,
    addToSelection,
    toggleSelection,
    clearSelection,
    parsedVariants,
    showRetiredVariants,
    hasRetiredVariants,
    addNewVariant,
    defaultCurrency,
    editVariant,
    modal,
    setShowRetiredVariants,
  ]);

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(searchParams.get('order') || 'NAME'),
      desc: (searchParams.get('sort') || SORT_ORDER_TYPES.ASC) === SORT_ORDER_TYPES.DESC,
    };
  }, [searchParams]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        setSearchParams({
          order: camelToSnake(sorting[0].id).toUpperCase(),
          sort: sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC,
        });
      }
    },
    [setSearchParams],
  );

  return (
    <div className="sharp-table-edges">
      <ReactTable
        fetching={false}
        columns={columns}
        data={parsedVariants}
        ref={{ tableRef: variantsTableRef }}
        rowCustomClassname={() =>
          classNames({
            'after:!border-solid': true,
          })
        }
        enableSorting={true}
        sorting={tableSortData}
        onFetchData={onFetchData}
      />
    </div>
  );
}

function VariantColor({ index }) {
  if (index === -1) {
    return <div className="absolute bottom-px top-0 w-[5px] pb-px">&nbsp;</div>;
  }

  const colorMatrix = [
    'bg-dodger-blue-300',
    'bg-lavender-500',
    'bg-lima-500',
    'bg-pink-salmon-500',
    'bg-dandelion-500',
    'bg-dodger-blue-500',
    'bg-wisteria-500',
    'bg-[#8EDEC5]',
    'bg-[#FFAF8F]',
    'bg-[#FF7679]',
  ];

  const color = colorMatrix[index] ?? 'bg-[#A5ACBC]';

  return <div className={classNames('absolute bottom-px top-0 w-[5px] pb-px', color)}>&nbsp;</div>;
}
