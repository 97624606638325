const lineColors = ['#0086e6', '#7acfbd', '#ba89f5'];

export function LineChart({ index, value, maxValue }) {
  const fillColor = lineColors[index % 3];

  return (
    <div className="min-w-11 relative h-2 w-full overflow-hidden rounded-full bg-[#E2E8ED]">
      <div
        className="absolute left-0 top-0 h-2 rounded-full"
        style={{ width: `${Math.round((value / maxValue) * 100)}%`, background: fillColor }}
        aria-valuemin="0"
        aria-valuemax={maxValue}
        aria-valuenow={value}
      />
    </div>
  );
}
