/* eslint-disable jsx-a11y/no-autofocus */
import { Formik, Form, Field } from 'formik';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { useMutation, useModal } from '/src/hooks';
import { abTestRenameMutation } from '/src/features/ab-testing/mutations';
import { Modal } from '/src/contexts/modal';

export function AbTestRenameModal({ abTest, onSuccess = null }) {
  const modal = useModal();

  const abTestRename = useMutation(abTestRenameMutation);

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Formik
        initialValues={{ name: abTest.name }}
        onSubmit={(values) => {
          abTestRename.mutate(
            {
              name: values.name,
              id: abTest.id,
            },
            {
              onSuccess: () => {
                modal.close();
                onSuccess?.();
              },
            },
          );
        }}
      >
        {({ values }) => (
          <Form>
            <Modal.Title>Rename your A/B Test</Modal.Title>

            <Field name="name">{({ field }) => <Input {...field} autoFocus aria-label={`new name`} />}</Field>

            <Modal.Actions>
              <Button type="submit" disabled={abTestRename.isLoading || !values.name}>
                {abTestRename.isLoading && (
                  <span className="mr-2.5">
                    <Spinner />
                  </span>
                )}
                {abTestRename.isLoading ? 'Renaming...' : 'Rename'}
              </Button>
              <Modal.Cancel />
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
