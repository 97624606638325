import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { ADDON_TYPES } from '/src/features/addons/constants';
import { ABTEST } from '/src/features/ab-testing/constants';

function featureDescription(type) {
  if (type === ADDON_TYPES.SURVEY) {
    return 'Although you can interact with your survey it will not record any responses in preview mode.';
  }
  if (type === ABTEST) {
    return 'Note: no views or conversions are tracked during Preview mode.';
  }
}

export function PreviewLoadingModal({ target, type, spelledType }) {
  return (
    <Modal dialogClassName="!w-[640px]">
      <Modal.Title>
        Your {capitalizeFirstLetter(spelledType)} Preview is loading in a new {target}
      </Modal.Title>
      <p className="text-body-2 mb-5">
        Your {capitalizeFirstLetter(spelledType)} Preview is an excellent way to see how your {spelledType} looks and
        feels on your website.
        <br />
        {featureDescription(type)} You can also share the preview URL with other team members to get feedback.
      </p>
      <p className="text-body-2">If the preview does not load, it could be due to the following:</p>
      <ul className="text-body-2 ml-5 mt-3 list-outside list-disc space-y-2">
        {type === ABTEST && (
          <li>
            Your IP address is on the Block list, preventing the tracking script from triggering.{' '}
            <Link to="/options/advanced-tracking" className="text-link">
              Remove your IP address
            </Link>{' '}
            if you need to view the variants.
          </li>
        )}
        <li>
          A consent modal that you need to accept on your website. Try clicking accept to see if your preview shows. If
          you already rejected the modal reload and try again. Some consent modals only show once so you may need to
          quit and re-open your browser to see it again.
        </li>
        <li>
          The Crazy Egg tracking script is being blocked by a plugin or browser setting. (1) Check your browser privacy
          settings and ensure it is not set to the strictest setting (The Do Not Track setting should be disabled). (2)
          Try disabling extensions/plugins that block advertisements or site trackers.
        </li>
        <li>
          Your website redirected from the original page you entered to a new one. One way this can happen is if you
          tried to preview the {spelledType} on a page that requires a login, but you were not logged in yet. In that
          case log into your website and then try again or enter a different URL.
        </li>
      </ul>
    </Modal>
  );
}
