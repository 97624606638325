import { WarningModal } from '../modals/WarningModal';
import { inflect } from '/src/utils/string';

import { useMutation, usePermissions, useModal } from '/src/hooks';
import { surveyResponseDeleteMutation } from '/src/features/addons/mutations';
import { addTagsToRecordingsMutation, removeTagsFromRecordingsMutation } from '/src/features/recordings/mutations';

import { ActionIcon } from '/src/components/bulk-action/ActionIcon';
import { TagRecordingsModal } from '/src/features/recordings/components/dashboard/modals/TagRecordingsModal';
import { UntagRecordingsModal } from '/src/features/recordings/components/dashboard/modals/UntagRecordingsModal';

import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as UntagIcon } from '@crazyegginc/hatch/dist/images/icon-untag-outline.svg';

export function SurveyResponsesBulkActions({
  onActionStart,
  onSuccess,
  onError,
  selectedIds,
  currentSelection,
  survey,
}) {
  const modal = useModal();
  const deleteResponses = useMutation(surveyResponseDeleteMutation);
  const addTagsToRecordings = useMutation(addTagsToRecordingsMutation);
  const removeTagsFromRecordings = useMutation(removeTagsFromRecordingsMutation);
  const selectedNum = selectedIds.length;

  const taggableIds = currentSelection.filter((x) => x.recording).map((x) => parseInt(x.recording.id));

  const permissions = usePermissions();
  const canTag = permissions.can('edit', FEATURES.RECORDINGS).allowed;
  const canDelete = permissions.can('delete', survey).allowed;

  return (
    <>
      {canDelete && (
        <ActionIcon
          img={RemoveIcon}
          alt="Delete selected"
          tooltip="Delete"
          onClick={() => {
            modal.show(
              <WarningModal
                onConfirm={() => {
                  onActionStart();
                  deleteResponses.mutate(
                    {
                      surveyId: survey.id,
                      responseGroupIds: [...selectedIds],
                    },
                    {
                      onError: (error) => onError({ error, name: 'Delete survey responses' }),
                      onSuccess: () => onSuccess({ deselectAll: true }),
                    },
                  );
                }}
                title={`Delete ${selectedNum} ${inflect('response', selectedNum)}?`}
                body={`If you delete ${
                  selectedNum > 1 ? 'these responses' : 'this response'
                }, you will no longer have access to ${selectedNum > 1 ? 'them' : 'it'}. This can't be undone.`}
                button={`Yes, delete ${selectedNum > 1 ? 'responses' : 'response'}`}
              />,
            );
          }}
        />
      )}
      {canTag && (
        <>
          <ActionIcon
            img={TagIcon}
            alt="Tag selected"
            tooltip="Add tag"
            onClick={() => {
              modal.show(
                <TagRecordingsModal
                  onConfirm={(tags) => {
                    onActionStart();
                    addTagsToRecordings.mutate(
                      { recordingIds: [...taggableIds], tags },
                      {
                        onError: (error) => onError({ error, name: 'Tag session' }),
                        onSuccess: () => onSuccess(),
                      },
                    );
                  }}
                />,
              );
            }}
          />
          <ActionIcon
            img={UntagIcon}
            alt="Untag selected"
            tooltip="Remove tag"
            onClick={() => {
              modal.show(
                <UntagRecordingsModal
                  onConfirm={(tags) => {
                    onActionStart();
                    removeTagsFromRecordings.mutate(
                      { recordingIds: [...taggableIds], tags },
                      {
                        onError: (error) => onError({ error, name: 'Untag session' }),
                        onSuccess: () => onSuccess(),
                      },
                    );
                  }}
                />,
              );
            }}
          />
        </>
      )}
    </>
  );
}
