import { FEATURES, PLAN_TYPES, PLAN_PLATFORMS, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';

export const INSTALLATION_PERMISSIONS = {
  [FEATURES.INSTALLATION]: ({ capabilities, subscription }) => ({
    navigate: capabilities?.installation.permissions.nav,
    view:
      subscription?.plan.type !== PLAN_TYPES.ZARAZ
        ? capabilities?.installation.permissions.view
        : PERMISSION_NOT_ALLOWED,
    viewZaraz:
      subscription?.plan.platform === PLAN_PLATFORMS.ZARAZ
        ? capabilities?.installation.permissions.view
        : PERMISSION_NOT_ALLOWED,
  }),
};
