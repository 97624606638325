import { FEATURES, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';

export const COMMENTING_PERMISSIONS = {
  [FEATURES.COMMENTING]: ({ capabilities, isSharing }) => {
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.commenting.permissions.nav,
          edit: capabilities.commenting.permissions.edit,
        };
  },
};
