import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input, Divider } from '@crazyegginc/hatch';

import { normalizeUrl, wildcardToRegex } from '/src/utils/url';
import { useWizard } from '/src/hooks';
import { SupportLinks } from '/src/support';

const validationSchema = yup.object().shape({
  wildcard: yup
    .string()
    .required('Please provide a wildcard pattern.')
    .matches(/\*/, 'Oops, looks like your wildcard is not valid. Please see the wildcard examples for reference.'),
  testUrl: yup
    .string()
    .required('Please provide a test URL.')
    .when('wildcard', (wildcard, schema) => {
      try {
        const regex = new RegExp(wildcardToRegex(wildcard), 'i');
        return schema.matches(
          regex,
          'Oops, looks like the test URL doesn’t match the wildcard pattern. Please double check and try again.',
        );
      } catch {
        return schema;
      }
    }),
});

export function WildcardModal({ onSuccess, formik }) {
  const wizard = useWizard();

  const snapshot = formik.values.snapshots[0];
  const siteUrl = snapshot.siteUrl;
  const defaultPattern = `${normalizeUrl(siteUrl)}*`;

  const initialPattern = formik.values.trackingOptions.wildcard?.wildcard ?? defaultPattern;

  function openInfoModal() {
    window.open('/snapshots/new/wildcard-syntax', '_blank', 'width=700,height=700,left=200');
  }

  return (
    <>
      <h1 className="text-header-1 mb-8 mt-0">Set-up a Wildcard</h1>
      <Divider className="my-2.5" />

      <div className="text-body-2 my-3.5">
        Use a wildcard to combine data for multiple pages into one Snapshot. This is useful to track multiple pages that
        share the same layout.
      </div>

      <div className="text-body-2 my-3.5">
        <a target="_blank" rel="noopener noreferrer" className="text-link" href={SupportLinks.snapshots.wildcardRegex}>
          Learn more about creating WildCard Expressions.
        </a>
      </div>

      <Formik
        initialValues={{
          wildcard: initialPattern,
          testUrl: formik.values.trackingOptions.wildcard?.testUrl ?? normalizeUrl(siteUrl),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          formik.setFieldValue('trackingOptions.wildcard', values, true);
          wizard.closeModal();
          onSuccess?.();
        }}
      >
        {({ values, errors, handleChange, handleBlur, isValid }) => (
          <Form>
            <div
              className={classNames('relative my-4 flex w-full flex-col rounded border border-mystic-500 p-6', {
                'bg-[#FDEEEE]': !isValid,
                'bg-[#F3F8E5]': isValid,
              })}
            >
              <Input
                name="wildcard"
                id="wildcard"
                label="Enter a wildcard pattern:"
                value={values.wildcard}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={!errors.wildcard}
              />

              <div className="flex py-3.5">
                <button type="button" className="text-link" onClick={openInfoModal}>
                  See examples of wildcard patterns
                </button>
              </div>

              <Input
                name="testUrl"
                id="testUrl"
                label="Enter a URL to test if it matches your wildcard pattern:"
                value={values.testUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={!errors.testUrl}
              />
            </div>

            {isValid ? (
              <div className="text-body-2 mt-3.5">Awesome! Your wildcard pattern matches the test URL.</div>
            ) : (
              <div className="text-body-4 text-carnation-500">{errors.wildcard || errors.testUrl}</div>
            )}

            <Divider className="my-2.5" />

            <div className="mt-5 flex space-x-2.5">
              <Button variant="secondary" disabled={!isValid} type="submit">
                Save &amp; Continue
              </Button>
              <Button variant="cancel" onClick={wizard.closeModal}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
