import hash from 'object-hash';

const GRAVATAR_AVATAR_URL = 'https://www.gravatar.com/avatar';

const FACE_IMAGES_COUNT = 24;

const SMALL = 'SMALL';
const LARGE = 'LARGE';
export const SIZES = [SMALL, LARGE];

export function generateGravatarUrl(input, size = 80, fallback = 'identicon') {
  const parsedInput = input.trim().toLowerCase();
  const hashedEmail = hash.MD5(parsedInput);
  return `${GRAVATAR_AVATAR_URL}/${hashedEmail}?s=${size}&d=${fallback}`;
}

function hashCode(input) {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function selectLocalFallback(input, size = SMALL) {
  const imageIndex = hashCode(input) % FACE_IMAGES_COUNT;
  const imageUrl = `https://ce-extension-assets.s3.amazonaws.com/faces/face-${Math.abs(imageIndex) + 1}${
    size === LARGE ? '-lrg' : ''
  }.png`;
  return encodeURI(imageUrl);
}
