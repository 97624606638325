import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

import SnapshotsPNG from '@crazyegginc/hatch/dist/images/illustration-snapshots.png';
import SnapshotsWebP from '@crazyegginc/hatch/dist/images/illustration-snapshots.webp';

export function FirstSnapshotWall({ mini }) {
  const { currentAccount } = useAuthContext();

  const permissions = usePermissions();
  const canCreate = permissions.can('create', FEATURES.SNAPSHOTS).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={SnapshotsWebP}
        png={SnapshotsPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Create your first Snapshot!</Paywall.Title>

      <Paywall.Body>Set up a snapshot to learn more about how your visitors interact with your site.</Paywall.Body>
      {canCreate ? (
        <Button component={Link} to={`/snapshots/new`} className="mb-5">
          Create a Snapshot
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
