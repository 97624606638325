/* eslint-disable no-useless-escape */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

export const lenientUrlRegex =
  /^(https?:\/\/)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](\/.*?)?$/gi;

export const mailtoRegex = /^mailto:/i;
export const telRegex = /^tel:/i;

// same as lenientUrlRegex but also doesn't enforce TLDs
export const superLenientUrlRegex = /^(https?:\/\/)?(?:[a-z0-9-]{1,63}\.)*[a-z0-9-]{1,63}(\/.*?)?$/gi;

// same as superLenientUrlRegex but also doesn't enforce having a path slash
export const megaSuperLenientUrlRegex = /^(https?:\/\/)?(?:[a-z0-9-]{1,63}\.)*[a-z0-9-]{1,63}((\/|\?|\#|\,).*?)?$/gi;

export const haveHTTPSRegex = /^(?:https?:\/\/|s?:\/\/)/gi;

export const noAsteriskRegex = /^[^*]*$/;

export const hasProtocolRegex =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](\/.*?)?$/gi;

export const extensionRegex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

export const escapeRegexSpecialChars = (input) => {
  if (typeof input !== 'string') return input;
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const searchParamRegex = /^\?([\w-]+(=[\w.\-:%+]*)?(&[\w-]+(=[\w.\-:%+]*)?)*)?$/;
