import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'react-fast-compare';
import flatten from 'flat';

import { useDebounce, useNotifications } from '/src/hooks';

export function PersistFormik({ name, rehydrate, store, additionalKeyValues }) {
  const previousValues = useRef(null);
  const { values, setValues } = useFormikContext();

  const debouncedValues = useDebounce(values, 500);

  useEffect(() => {
    if (rehydrate && !previousValues.current) {
      const storedState = window.localStorage.getItem(name);
      if (storedState) {
        const rehydratedState = JSON.parse(storedState);
        setValues(rehydratedState);
        previousValues.current = rehydratedState;
      }
    }
  }, [name, rehydrate, setValues]);

  useEffect(() => {
    if (store && !isEqual(debouncedValues, previousValues.current)) {
      const dehydratedState = JSON.stringify(debouncedValues);
      window.localStorage.setItem(name, dehydratedState);
      previousValues.current = dehydratedState;
      if (additionalKeyValues) {
        for (const [key, value] of Object.entries(additionalKeyValues)) {
          window.localStorage.setItem(key, value);
        }
      }
    }
  }, [name, store, additionalKeyValues, debouncedValues]);

  return null;
}

export function ResetFormik({ onComplete }) {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    resetForm();
    onComplete?.();
  }, [onComplete, resetForm]);

  return null;
}

export function FormPatchTouched({ showErrorNotification = false }) {
  const notifications = useNotifications();
  const { errors, setFieldTouched, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorPaths = Object.keys(flatten(errors));
      for (const path of errorPaths) {
        setFieldTouched(path, true, false);
      }
      if (errorPaths.length > 0 && showErrorNotification) {
        notifications.error({
          content: 'Errors prevented form submission. Please correct them and try again.',
          timeout: 3000,
          skipHoneybadger: true,
        });
      }
    }
  }, [errors, isSubmitting, isValidating, notifications, setFieldTouched, showErrorNotification]);

  return null;
}
