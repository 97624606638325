import { EditUserModal } from '/src/features/team-and-sharing/components/team/modals/EditUserModal';
import { DeleteUserModal } from '/src/features/team-and-sharing/components/team/modals/DeleteUserModal';

import { useMutation, useModal } from '/src/hooks';
import { inflect } from '/src/utils/string';

import { deleteAccountUsersMutation, updateAccountUsersMutation } from '/src/features/team-and-sharing/mutations';
import { ActionIcon } from '/src/components/bulk-action/ActionIcon';

import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function AccountUsersBulkActions({ onActionStart, onSuccess, onError, selected, selectedIds, siteList }) {
  const modal = useModal();
  const updateAccountUsers = useMutation(updateAccountUsersMutation);
  const deleteAccountUsers = useMutation(deleteAccountUsersMutation);
  const selectedNum = selectedIds.length;
  const selectedUserNames = selected.map((user) => user?.name || user.email).join('; ');
  const isOneSelectedAdminRole = selectedNum === 1 && selected[0].role === 'owner';

  return (
    <>
      {!isOneSelectedAdminRole ? (
        <ActionIcon
          img={EditIcon}
          alt="Edit selection"
          tooltip="Edit"
          onClick={() => {
            modal.show(
              <EditUserModal
                user={selected}
                selectedIds={selectedIds}
                numToUpdate={selectedNum}
                selectedUserNames={selectedUserNames}
                siteList={siteList}
                onUpdate={(values) => {
                  onActionStart();
                  updateAccountUsers.mutate(values, {
                    onError: (error) => onError({ error, name: 'Update team members' }),
                    onSuccess: (data) => {
                      const numUserUpdated = data.updateAccountUsers.length;
                      onSuccess({
                        deselectAll: true,
                        message: `${numUserUpdated} ${inflect('Team member', numUserUpdated)} ${
                          numUserUpdated > 1 ? 'have' : 'has'
                        } been updated.`,
                      });
                    },
                  });
                }}
                onDelete={() => {
                  onActionStart();
                  deleteAccountUsers.mutate(
                    { ids: [...selectedIds] },
                    {
                      onError: (error) => onError({ error, name: 'Delete team members' }),
                      onSuccess: (data) => {
                        const numUserDeleted = data.deleteAccountUsers.length;
                        onSuccess({
                          deselectAll: true,
                          message: `${numUserDeleted} ${inflect('Team member', numUserDeleted)} ${
                            numUserDeleted > 1 ? 'have' : 'has'
                          } been deleted.`,
                        });
                      },
                      onSettled: () => modal.close(),
                    },
                  );
                }}
              />,
            );
          }}
        />
      ) : null}
      <ActionIcon
        img={RemoveIcon}
        alt="Delete selection"
        tooltip="Delete"
        onClick={() => {
          modal.show(
            <DeleteUserModal
              onDelete={() => {
                onActionStart();
                deleteAccountUsers.mutate(
                  { ids: [...selectedIds] },
                  {
                    onError: (error) => onError({ error, name: 'Delete team members' }),
                    onSuccess: (data) => {
                      const numUserDeleted = data.deleteAccountUsers.length;
                      onSuccess({
                        deselectAll: true,
                        message: `${numUserDeleted} ${inflect('Team member', numUserDeleted)} ${
                          numUserDeleted > 1 ? 'have' : 'has'
                        } been deleted.`,
                      });
                    },
                    onSettled: () => modal.close(),
                  },
                );
              }}
              numToDelete={selectedNum}
            />,
          );
        }}
      />
    </>
  );
}
