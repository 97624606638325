import { Button } from '@crazyegginc/hatch';

import { Paywall } from '/src/components/Paywall';

import SearchPNG from '@crazyegginc/hatch/dist/images/illustration-search-bg.png';
import SearchWebP from '@crazyegginc/hatch/dist/images/illustration-search-bg.webp';

export function NoTeamMembers({ actionButton, headerText, text, actionText }) {
  return (
    <Paywall>
      <Paywall.Image webp={SearchWebP} fallback={SearchPNG} width="176" />
      <Paywall.Title>{headerText}</Paywall.Title>
      <Paywall.Body>{text}.</Paywall.Body>

      <Button variant="secondary" onClick={actionButton}>
        {actionText}
      </Button>
    </Paywall>
  );
}
