import { useEffect, useState } from 'react';
import { Checkbox, Button } from '@crazyegginc/hatch';

import { useMutation, useWizard } from '/src/hooks';
import { initializeSegmentIntegrationMutation } from '/src/features/installation/mutations';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

export function SegmentInstructions() {
  const [installed, setInstalled] = useState(false);

  const { set: wizardSet } = useWizard();

  const initSegment = useMutation(initializeSegmentIntegrationMutation);

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      nextEnabled: false,
      title: 'Integrate Crazy Egg with Segment',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  function connectSegment() {
    initSegment.mutate(
      {
        returnUrl: `${window.location.href}/check-script`,
      },
      {
        onSuccess: (data) => {
          if (data?.initializeSegmentIntegration?.redirectUrl) {
            window.open(data.initializeSegmentIntegration.redirectUrl, '_self');
          }
        },
      },
    );
  }

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Segment!</WizardHeader>
      <WizardSubHeader>One click, no code &#8212; easy!</WizardSubHeader>

      <p className="leading-tight">
        If you&#39;re a Segment customer, you know how easy it is to get new integrations up and running.
        <br />
        Crazy Egg + Segment works the same way &#8212; one click and no need to touch any code at all.
      </p>
      <div className="text-body-1 mt-6">
        Click the button below to connect your account and get Crazy Egg live on your site.
      </div>

      <div className="mt-10 flex w-full items-center justify-center space-x-5 rounded bg-white-lilac-500 py-5">
        <div>
          <Checkbox
            name="already_set"
            checked={installed}
            onChange={() => setInstalled(!installed)}
            label="I've set up Segment for my website"
            id="segment_installed"
          />
        </div>
        <Button disabled={!installed || initSegment.isLoading} onClick={connectSegment}>
          {initSegment.isLoading ? 'Loading...' : 'Connect Segment'}
        </Button>
      </div>

      {initSegment.error && <span className="text-error">{initSegment.error.graphQLErrors[0].message}</span>}
    </div>
  );
}
