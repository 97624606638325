import { useMemo, useState } from 'react';
import { useActor } from '@xstate/react';
import { Input, RadioGroup } from '@crazyegginc/hatch';

import { GOAL_TRACKING_OPTIONS, ELEMENT_SELECTOR_TYPES } from '/src/features/goals/constants';

import { useDebounce } from '/src/hooks';
import { TriggerElementSelector } from './TriggerElementSelector';
import { normalizeUrl, generateWildcardUrl } from '/src/utils/url';

export function FormSubmissionTrigger({ service, triggerIndex }) {
  const [state] = useActor(service);
  const isEditing = window.location.pathname.includes('edit');
  const { send } = service;
  const { selectedSite } = state.context;
  const errors = state.context.errors?.triggers?.[triggerIndex];
  const values = state.context.payload.triggers[triggerIndex];
  const [touched, setTouched] = useState({ targetUrl: isEditing && !!values.targetUrl });
  const debouncedTargetUrl = useDebounce(values.targetUrl, 500);
  const debouncedWildcardUrl = useDebounce(values.wildcardUrl, 500);
  const debouncedTargetUrlError = useDebounce(touched.targetUrl ? errors?.targetUrl : null, 500, [debouncedTargetUrl]);
  const debouncedWildcardUrlError = useDebounce(touched.wildcardUrl ? errors?.wildcardUrl : null, 500, [
    debouncedWildcardUrl,
  ]);

  const suggestedWildcard = useMemo(() => {
    return generateWildcardUrl(debouncedTargetUrl, selectedSite?.name);
  }, [debouncedTargetUrl, selectedSite?.name]);

  return (
    <>
      <h2 className="text-header-2 mb-5 mt-10">Your form is on this page:</h2>

      <div className="pb-5">
        <Input
          placeholder="Search URL"
          value={values.targetUrl}
          onChange={(e) => {
            setTouched((x) => ({ ...x, targetUrl: true }));
            send({
              type: 'SET_TRIGGER',
              index: triggerIndex,
              key: 'targetUrl',
              value: normalizeUrl(e.target.value.trim(), { appendSlash: false }),
            });
          }}
          error={touched.targetUrl ? debouncedTargetUrlError : null}
        />
        <span className="text-body-4">Example: You can track if someone has filled out the newsletter form.</span>
      </div>

      <div className="mb-10 mt-5">
        <RadioGroup
          options={[
            { value: GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE, label: 'Track this form only on this page.' },
            { value: GOAL_TRACKING_OPTIONS.ANY_PAGE, label: 'Include this form from any page across my website.' },
            { value: GOAL_TRACKING_OPTIONS.WILDCARD, label: 'Include this form for any pages that match a wildcard.' },
          ]}
          value={values.trackingOption}
          onChange={(value) => {
            send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'trackingOption', value });
            if (value !== GOAL_TRACKING_OPTIONS.WILDCARD && values.wildcardUrl) {
              send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'wildcardUrl', value: undefined });
            }
          }}
        />
        {values.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD ? (
          <Input
            className="mt-2.5"
            placeholder={`Enter a wildcard pattern: ${suggestedWildcard}`}
            value={values.wildcardUrl}
            error={touched.wildcardUrl ? debouncedWildcardUrlError : null}
            onChange={(e) => {
              setTouched((x) => ({ ...x, wildcardUrl: true }));
              send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'wildcardUrl', value: e.target.value });
            }}
          />
        ) : null}
      </div>

      {values.trackingOption && debouncedTargetUrl && !debouncedTargetUrlError ? (
        <>
          <h2 className="text-header-2 mb-5 mt-10">Click on the form that you want to track</h2>

          <RadioGroup
            options={[
              { value: ELEMENT_SELECTOR_TYPES.SPECIFIC, label: 'Track only the element I click' },
              // https://app.shortcut.com/crazyegg/story/27554/broad-element-matching-for-goal-triggers
              // { value: 'BROAD', label: 'Track all similar elements' },
              { value: ELEMENT_SELECTOR_TYPES.MANUAL, label: 'Advanced: Track via CSS selector' },
            ]}
            value={values.elementSelectorType}
            onChange={(value) => send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'elementSelectorType', value })}
          />

          <div className="text-body-4 mb-2.5 mt-10">
            The element you select will be outlined in red. To cancel a selection, click on the element again.
          </div>

          <TriggerElementSelector
            service={service}
            trigger={values}
            url={debouncedTargetUrl}
            triggerIndex={triggerIndex}
            mode="forms"
            device={state.context.payload.device}
            isManual={values.elementSelectorType === ELEMENT_SELECTOR_TYPES.MANUAL}
            errors={errors}
            placeholder="form#contact"
          />
        </>
      ) : null}
    </>
  );
}
