import { useEffect } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';

import { SupportLinks } from '/src/support';
import { useWizard, useAccountNumber } from '/src/hooks';
import { CodeBox } from '/src/components/CodeBox';
import { VideoPlayer } from '/src/components/VideoPlayer';
import { WizardHeader, WizardSubHeader, WizardList, LightBulbIcon } from '/src/components/wizard/legacy/wizard-ui';

import Drupal7Poster from '../assets/drupal7-instructions-poster.jpg';
import Drupal7Video from '../assets/drupal7-instructions.mp4';
import Drupal8Poster from '../assets/drupal8-instructions-poster.jpg';
import Drupal8Video from '../assets/drupal8-instructions.mp4';

function Drupal7({ loading, accountNumber }) {
  return (
    <>
      <div className="text-header-4 mb-2.5">Instructions for Drupal 7:</div>
      <WizardList>
        <WizardList.Item>
          Go to your Drupal administration page. Click on{' '}
          <strong className="text-body-1">Modules &gt; + Install new module</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste this URL,{' '}
          <a href={`https://ftp.drupal.org/files/projects/crazyegg-7.x-1.1.tar.gz`}>
            https://ftp.drupal.org/files/projects/crazyegg-7.x-1.1.tar.gz
          </a>{' '}
          into the <strong className="text-body-1">Install from a URL</strong> text field. Click on{' '}
          <strong className="text-body-1">Install</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Administration pages &gt; Modules</strong>. Find Crazy Egg and make
          sure it is selected. Click on <strong className="text-body-1">Save configuration</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Configuration &gt; Crazy Egg</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste your account number, <strong className="text-body-1">{accountNumber}</strong> into the{' '}
          <strong className="text-body-1">Crazy Egg Account ID</strong> input field.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Save configuration</strong>.
        </WizardList.Item>
      </WizardList>

      <div className="my-5 rounded bg-white-lilac-500 px-6 py-5">
        <strong className="text-body-1">Note:</strong> If you are using a cache plugin on your site, you will need to
        clear it after you have installed your Crazy Egg code.
      </div>

      <CodeBox loading={loading} code={accountNumber} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={Drupal7Video} poster={Drupal7Poster} label="Drupal 7 installation video" />
      </div>

      <a href={SupportLinks.install.drupal7} className="text-link mt-2.5 block" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Drupal 7
      </a>
    </>
  );
}

function Drupal8({ accountNumber }) {
  return (
    <>
      <div className="text-header-4 mb-2.5">Instructions for Drupal 8, 9, or 10:</div>
      <WizardList>
        <WizardList.Item>
          Go to your Drupal administration page. Click on{' '}
          <strong className="text-body-1">Extend &gt; + Install new module</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste this URL,{' '}
          <a href={`https://ftp.drupal.org/files/projects/crazyegg-8.x-1.4.tar.gz`}>
            https://ftp.drupal.org/files/projects/crazyegg-8.x-1.4.tar.gz
          </a>{' '}
          into the <strong className="text-body-1">Install from a URL</strong> text field. Click on{' '}
          <strong className="text-body-1">Install</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Extend</strong>. Find Crazy Egg and make sure it is selected. Click
          on <strong className="text-body-1">Install</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Go to <strong className="text-body-1">Administration &gt; Configuration &gt; System &gt; Crazy Egg</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Copy and paste your account number, <strong className="text-body-1">{accountNumber}</strong> into the{' '}
          <strong className="text-body-1">Crazy Egg Account ID</strong> input field.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Save configuration</strong>.
        </WizardList.Item>
      </WizardList>

      <div className="my-5 rounded bg-white-lilac-500 px-6 py-5">
        <strong className="text-body-1">Note:</strong> If you are using a cache plugin on your site, you will need to
        clear it after you have installed your Crazy Egg code.
      </div>

      <CodeBox loading={false} code={accountNumber} />

      <div className="mb-2.5 mt-5 flex items-center">
        <LightBulbIcon />
        Need a hand? Watch this video to see these steps in action:
      </div>

      <div className="border border-mystic-500">
        <VideoPlayer src={Drupal8Video} poster={Drupal8Poster} label="Drupal 8 installation video" />
      </div>

      <a href={SupportLinks.install.drupal8} className="text-link mt-2.5 block" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Drupal 8, 9 or 10
      </a>
    </>
  );
}

export function DrupalInstructions() {
  const [accountNumber] = useAccountNumber();

  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/drupal/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Drupal',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Drupal!</WizardHeader>
      <WizardSubHeader>
        Follow the instructions below for a completely code-free install. Once you&#39;re prompted for your account
        number, enter <strong className="text-body-1">{accountNumber}</strong>.
      </WizardSubHeader>

      <Tab.Group>
        <Tab.List className="mb-6 flex border-b border-mystic-500">
          {['Drupal 8, 9 or 10', 'Drupal 7'].map((header) => (
            <Tab
              key={header}
              className={({ selected }) =>
                classNames('text-header-5 border-b-2 px-5 pb-3 focus:outline-none focus-visible:outline-black', {
                  'border-dodger-blue-500': selected,
                  'border-transparent': !selected,
                })
              }
            >
              {header}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Drupal8 accountNumber={accountNumber} />
          </Tab.Panel>

          <Tab.Panel>
            <Drupal7 accountNumber={accountNumber} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Drupal.
      </div>
    </div>
  );
}
