import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Legend, defaults, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { atom, useAtom } from 'jotai';
import { fromUnixTime, startOfDay, format, eachDayOfInterval, isSameDay, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { useAuthContext } from '/src/hooks';
import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';
import { goalSelectedDatesAtom } from '/src/features/goals/store';
import { MouseLine } from '/src/chartjs-plugins/MouseLine';
import { goalDistributionQuery } from '/src/features/goals/queries';
import { MetricCards, capitalize } from '@crazyegginc/hatch';

defaults.font.family = 'Proxima Nova, Lucida Grande, Tahoma, Verdana, Arial, sans-serif';

ChartJS.register(MouseLine, Legend, LinearScale, CategoryScale, PointElement, LineElement);
export const goalDistributionDatasetAtom = atom('conversions');

const MAX_INTERVALS = 100;

function sliceIntoChunks(arr, chunkSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

function getDatasetKey(dataset) {
  switch (dataset) {
    case 'conversions':
      return 'conversionsCount';
    case 'visitors':
      return 'uniqueConversionsCount';
    case 'value':
      return 'totalValue';
  }
}

function getYAxisLabel(dataset, currency) {
  if (dataset === 'value') {
    return `Value (${currency})`;
  }
  return capitalize(dataset);
}

export function DistributionChart({ title, goal, counts, isFetching = true }) {
  const { currentUser } = useAuthContext();
  const defaultCurrency = currentUser?.settings?.defaultCurrency ?? 'USD';

  const { id } = useParams();

  const [dataset] = useAtom(goalDistributionDatasetAtom);
  const [selection] = useAtom(goalSelectedDatesAtom);

  const { data } = useQuery({
    ...goalDistributionQuery({
      id: Number(id),
      presetDate: selection.special,
      startDate: selection.startDate,
      endDate: selection.endDate,
    }),
  });

  const days = useMemo(() => {
    if (!selection?.startDate || !selection?.endDate) return [];
    return eachDayOfInterval({
      start: fromUnixTime(selection.startDate),
      end: fromUnixTime(selection.endDate),
    });
  }, [selection.startDate, selection.endDate]);

  const daysIntervals = useMemo(() => {
    if (days && days.length > MAX_INTERVALS) {
      const daysPerChunk = Math.ceil(days.length / MAX_INTERVALS);
      return sliceIntoChunks(days, daysPerChunk);
    }
    return null;
  }, [days]);

  const chartLabels = useMemo(() => {
    if (!days && !daysIntervals) return null;
    // const data = distributionData?.data?.goalDistribution ?? [];
    // return data.map((item) => format(fromUnixTime(item.date), `MMM dd`));
    if (daysIntervals) {
      return daysIntervals.map((days) => `${format(days[0], 'MMM dd')} ${format(days[days.length - 1], 'MMM dd')}`);
    }
    return days.map((day) => format(day, 'MMM dd'));
  }, [days, daysIntervals]);

  const chartValues = useMemo(() => {
    const datasetMapping = getDatasetKey(dataset);
    const distData = data?.goalDistribution ?? [];

    if (!days || !goal) return null;

    const dataStartDate = startOfDay(fromUnixTime(goal.createdAt));

    return days.map((date) => {
      const day = distData.find((item) => isSameDay(utcToZonedTime(fromUnixTime(item.date)), date));
      const dataPoint = day?.[datasetMapping] ?? (isBefore(date, dataStartDate) ? null : 0);

      if (datasetMapping === getDatasetKey('value')) {
        return dataPoint / 10000;
      }

      return dataPoint;
    });
  }, [days, goal, data, dataset]);

  const distributionData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: capitalize(dataset),
          data: chartValues,
          fill: true,
          backgroundColor: (ctx) => {
            const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 350);
            gradient.addColorStop(0, 'rgba(153, 207, 245, 0.15)');
            gradient.addColorStop(1, 'rgba(153, 207, 245, 0)');
            return gradient;
          },
          borderColor: 'rgb(153, 207, 245)',
          pointBackgroundColor: 'rgb(153,207,245)',
          pointBorderColor: 'rgb(255,255,255)',
          pointBorderWidth: 2,
          pointRadius: 5,
        },
      ],
    }),
    [chartLabels, chartValues, dataset],
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      spanGaps: true,
      scales: {
        y: {
          suggestedMin: 0,
          suggestedMax: 60,
          ticks: {
            color: 'rgb(104, 118, 141)',
            stepSize: 10,
            padding: 10,
          },
          grid: {
            color: 'rgb(225, 230, 239)',
            lineWidth: 1,
          },
          border: {
            display: false,
            dash: [6, 3],
          },
        },
        x: {
          ticks: {
            color: 'rgb(104, 118, 141)',
            padding: 15,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: 'rgba(10, 11, 14, 0.9)',
          titleAlign: 'center',
          titleColor: 'rgb(165, 172, 188)',
          displayColors: false,
          bodyAlign: 'center',
          xAlign: 'center',
          yAlign: 'bottom',
          callbacks: {
            title: function (c) {
              return c[0].label;
            },
          },
        },
        legend: {
          labels: false,
        },
        mouseline: {
          enabled: true,
        },
      },
    }),
    [],
  );

  if (!goal) return null;

  return (
    <div className="w-full">
      <div className="mb-3.75 flex items-end justify-between">
        <h3 className="text-header-3 capitalize">{title}</h3>
      </div>
      <DistributionChartCards counts={counts} isFetching={isFetching} goal={goal} />
      <div
        className={classNames('flex w-full flex-col border border-mystic-500 bg-white', {
          rounded: true,
        })}
        style={{
          boxShadow: '0 4px 6px 0 rgba(7,31,49,0.07)',
        }}
      >
        {dataset === 'value' && !goal?.hasValue ? (
          <div className="flex flex-row p-8">
            <div className="flex h-[300px] w-5 w-full flex-col items-center justify-center">
              <span className="text-body-5">A value for this Goal has not been set or detected.</span>
              <Link to={`/goals/${id}/edit-value`} className="text-link mt-2">
                Set Goal value
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-row p-8">
            <div className="flex h-[300px] w-5 items-center justify-center">
              <span className="text-caption -rotate-90 transform whitespace-nowrap">
                {getYAxisLabel(dataset, defaultCurrency)}
              </span>
            </div>
            {/* 
            NOTE: No need skeleton loader since GRAPHJS will show only the grids
          */}
            <div className="relative flex h-[300px] min-w-[500px] flex-1">
              <Line data={distributionData} options={options} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function DistributionChartCards({ counts, isFetching, goal }) {
  const [dataset, setDataset] = useAtom(goalDistributionDatasetAtom);

  return (
    <>
      <MetricCards className="!mb-5 !justify-start">
        <MetricCards.Card
          title="Total Conversions"
          value={counts?.conversionsCount}
          fetching={isFetching}
          className={classNames({
            '[&>button]:border-dodger-blue-500': dataset === 'conversions',
            'hover:[&>button]:border-dodger-blue-300': dataset !== 'conversions',
          })}
          onClick={() => setDataset('conversions')}
        />
        <MetricCards.Card
          title="Visitors converting"
          value={counts?.uniqueConversionsCount}
          fetching={isFetching}
          className={classNames({
            '[&>button]:border-dodger-blue-500': dataset === 'visitors',
            'hover:[&>button]:border-dodger-blue-300': dataset !== 'visitors',
          })}
          onClick={() => setDataset('visitors')}
        />
        <MetricCards.Card
          title="Goal value"
          value={
            !goal?.hasValue ? (
              <span>-</span>
            ) : (
              <DisplayCurrency
                value={{
                  displayWorth: goal.totalValue.displayWorth,
                  displayCurrency: goal.totalValue.displayCurrency,
                  isConverted: false,
                }}
                precision={10000}
                textOnly={true}
                appendCurrency={false}
                trimEmptyCents={true}
              />
            )
          }
          fetching={isFetching}
          className={classNames({
            '[&>button]:border-dodger-blue-500': dataset === 'value',
            'hover:[&>button]:border-dodger-blue-300': dataset !== 'value',
          })}
          onClick={() => setDataset('value')}
          style={{ width: goal.hasValue && goal.totalValue.displayWorth ? 'unset' : undefined }}
        />
      </MetricCards>
    </>
  );
}
