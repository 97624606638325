import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { NoAbTestsWall } from '/src/features/ab-testing/components/paywalls/NoAbTestsWall';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';

import { usePermissions, useSelectedSite } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

import { abTestListQuery } from '/src/features/ab-testing/queries';

import { ReactComponent as AbTestingIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';

export function ABTestingSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={AbTestingIcon} color="bg-cornflower-500" to="/ab-tests">
        <span>A/B Testing</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.AB_TESTING);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.AB_TESTING} reason={reason} mini={true} />;
  }

  return <ABTestContent data={data} loading={loading} />;
}

function ABTestContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();

  const { data: abTestList, isLoading } = useInfiniteQuery({
    ...abTestListQuery({
      site: selectedSite?.id,
      status: undefined,
    }),
    enabled: Boolean(selectedSite?.id),
  });
  const abTests = useMemo(
    () => abTestList?.pages.reduce((acc, page) => [...acc, ...(page.abTestList?.list ?? [])], []) ?? [],
    [abTestList],
  );

  if (!isLoading && abTests.length === 0) {
    return <NoAbTestsWall mini={true} />;
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-between gap-x-3">
        <TrendMetric text="Active" value={data?.abTests.active} below={true} loading={loading} />
        <TrendMetric text="Control winning" value="1" below={true} loading={loading} />
        <TrendMetric text="Variant winning" value="3" below={true} loading={loading} />
      </div>
      <div className="rounded bg-white-lilac-500 px-4 py-2">
        <div className="text-body-1 mb-2">Most recent</div>
        {isLoading ? (
          <>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-4/5" />
            </div>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-3/5" />
            </div>
            <div className="mb-0 flex h-5 items-center">
              <SkeletonLine className="!w-2/3" />
            </div>
          </>
        ) : (
          <>
            {abTests.map((t) => (
              <div key={t.id} className="mb-1 grow truncate last:mb-0">
                <Link className="text-link" to={`/ab-tests/${t.id}`}>
                  {t.name}
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to="/ab-tests" />
    </>
  );
}
