import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { FEATURES } from '/src/features/_global/constants';
import { ADDON_STATUSES } from '../constants';
import { capitalizeFirstLetter } from '/src/utils/string';

const SHOW_ALL = 'Show All';

function humanizeType(status, type) {
  switch (status) {
    case ADDON_STATUSES.ACTIVE:
      return 'Active (on)';
    case ADDON_STATUSES.INACTIVE:
      return 'Inactive (off)';
    case null:
    case undefined:
    case '': {
      if (type === FEATURES.CTAS) {
        return 'All CTAs';
      }
      if (type === FEATURES.SURVEYS) {
        return 'All Surveys';
      }
      return SHOW_ALL;
    }
    default:
      return capitalizeFirstLetter(status.toLowerCase());
  }
}

export function AddonStatusFilter({ type, status, setStatus }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('w-32', { '!border-dodger-blue-500': open })}
            data-testid="status-filter"
          >
            {humanizeType(status)}
            <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" />
          </Popover.Button>

          <StyledPopoverPanel align="center" data-testid="status-filter-dropdown">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setStatus(null);
                    close();
                  }}
                >
                  {humanizeType(null, type)}
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setStatus(ADDON_STATUSES.DRAFT);
                    close();
                  }}
                >
                  {humanizeType(ADDON_STATUSES.DRAFT)}
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setStatus(ADDON_STATUSES.ACTIVE);
                    close();
                  }}
                >
                  {humanizeType(ADDON_STATUSES.ACTIVE)}
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setStatus(ADDON_STATUSES.INACTIVE);
                    close();
                  }}
                >
                  {humanizeType(ADDON_STATUSES.INACTIVE)}
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
