import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Tooltip } from '@crazyegginc/hatch';

import {
  useMutation,
  usePermissions,
  useAuthContext,
  useNotifications,
  useModal,
  useNeedsUpgradeToAccessFeature,
} from '/src/hooks';
import { useSurveyResponseFilter } from '/src/features/addons/survey-response-filter-context';
import { surveyResponseDeleteMutation } from '/src/features/addons/mutations';
import { DeleteResponseModal } from '/src/features/addons/surveys/modals/DeleteResponseModal';
import { CommonActions } from '../CommonActions';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function ActionsRow({ survey, response, refetchResponse, total }) {
  const { isSharing } = useAuthContext();

  return (
    <div className="flex items-center justify-between">
      <CommonActions survey={survey} refresh={() => refetchResponse()} />
      <div className="mb-3.75 flex items-center space-x-2.5">
        {!isSharing && <PlayRecording response={response} />}
        <DeleteResponse survey={survey} response={response} refetchResponse={refetchResponse} total={total} />
      </div>
    </div>
  );
}

function DeleteResponse({ survey, response, refetchResponse, total }) {
  const { index, updateIndex } = useSurveyResponseFilter();
  const modal = useModal();
  const deleteResponse = useMutation(surveyResponseDeleteMutation);
  const notifications = useNotifications();
  const permissions = usePermissions();
  const canDelete = permissions.can('delete', survey).allowed;

  return (
    <Tooltip tooltipContent="Delete response">
      <Button
        variant="secondary"
        size="lg"
        disabled={!canDelete}
        onClick={async () => {
          modal.show(
            <DeleteResponseModal
              onConfirm={(close) => {
                deleteResponse.mutate(
                  {
                    surveyId: survey.id,
                    responseGroupIds: [response.id],
                  },
                  {
                    onError: (error) => {
                      notifications.error({
                        content: 'Failed to delete response.',
                        timeout: 3000,
                        context: { error },
                      });
                    },
                    onSuccess: () => {
                      if (total === index) {
                        updateIndex(index - 1, total - 1);
                      }
                      close();
                      refetchResponse();
                    },
                  },
                );
              }}
              text="Are you sure you want to delete this response?"
              entity="survey response"
            />,
          );
        }}
      >
        <DeleteIcon className="h-4 w-4 fill-current" aria-label="Delete response" />
      </Button>
    </Tooltip>
  );
}

function PlayRecording({ response }) {
  const { recording, recordingDeletedAt } = response;
  const { hashedId, valid, virtual, duration } = recording ?? {};
  const modal = useModal();
  const needsUpgradeToAccess = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);

  let tooltipText;
  const props = {};
  if (needsUpgradeToAccess) {
    tooltipText = 'Play recording';
    props.onClick = () => modal.show(<RecordingsUpgradeModal />);
  } else if (recordingDeletedAt) {
    tooltipText = 'The associated video was deleted.';
    props.disabled = true;
  } else if (virtual || !recording) {
    tooltipText = 'This session was not recorded.';
    props.disabled = true;
  } else if (!valid) {
    tooltipText = "The visitor didn't perform any recordable actions, such as clicking, so there is no video to show.";
    props.disabled = true;
  } else {
    tooltipText = 'Play recording';
    props.component = Link;
    props.to = {
      pathname: `/recordings/${hashedId}/player`,
    };
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  return (
    <Tooltip tooltipContent={tooltipText}>
      <Button variant="secondary" size="lg" {...props}>
        <PlayIcon
          className={classNames('h-3 w-3 fill-current', {
            'mr-2': duration != null,
          })}
          aria-label="Play recording"
        />
        {duration != null ? `${Math.floor(duration / 60) + ':' + String(duration % 60).padStart(2, '0')}s` : null}
      </Button>
    </Tooltip>
  );
}
