export const getOptionDisplayValue = (option) => {
  return option.label ?? option.value;
};

export const initialSelection = () => ({
  utmSource: [],
  utmMedium: [],
  utmCampaign: [],
  utmTerm: [],
  utmContent: [],
  referrerUrl: [],
});

export const getNameForParam = (utmParam) => {
  switch (utmParam) {
    case 'referrerUrl':
      return 'Referrer';
    case 'utmCampaign':
      return 'Campaign name';
    default:
      return `Campaign ${utmParam.replace('utm', '')}`;
  }
};

export const getEmptyText = (param) => {
  return param === 'referrerUrl' ? 'No referrer set' : `No ${param.replace('utm', '').toLowerCase()} set`;
};

export const printParamCombination = (selection) => {
  let text = '';
  Object.keys(selection)
    .filter((x) => selection[x].length > 0)
    .forEach((x, i) => {
      if (i > 0) text += ' + ';
      text += getNameForParam(x) + ': ';
      selection[x].forEach((y, index) => (text += `${index > 0 ? ', ' : ''}${getOptionDisplayValue(y)}`));
    });
  return text;
};
