import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

export function GoalsPaywallText({ children }) {
  return children;
}

function GoalsPaywallTitle() {
  return 'Measure conversion events that you want to drive';
}

function GoalsPaywallBody() {
  return (
    <>
      <p className="leading-tight">
        Configure on-site actions that you find valuable: link clicks, page views, form submissions, ad conversion
        pixels, or a javascript code snippet.
      </p>
    </>
  );
}

function GoalsPaywallList() {
  return (
    <div className="mt-2 flex flex-col justify-center px-10">
      <p className="mb-4">We’ll use these to help you:</p>
      <ul className="space-y-2">
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>
            Analyze conversion rates and conversion value for <strong>A/B Testing</strong>
          </p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>
            Drive more on-site conversions with smart <strong>CTAs</strong>
          </p>
        </li>
        <li className="flex text-left">
          <TickIcon className="mr-2.5 mt-0.5 h-4 w-4 shrink-0 fill-current text-lima-500" />
          <p>
            Filter <strong>Heatmap Snapshots</strong> based on conversion events
          </p>
        </li>
      </ul>
    </div>
  );
}

GoalsPaywallText.Title = GoalsPaywallTitle;
GoalsPaywallText.Body = GoalsPaywallBody;
GoalsPaywallText.List = GoalsPaywallList;
