import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { Divider } from '@crazyegginc/hatch';

import { StatusSelector } from '/src/features/errors/components/report/StatusSelector';
import { SupportLinks } from '/src/support';

export function CrossOriginError({ data }) {
  return (
    <div className="flex flex-col p-10">
      <div className="mb-6 w-full rounded bg-mystic-500/40 px-5 pb-9 pt-3">
        <div className="flex w-full items-center">
          <div className="flex-grow">
            <div className="text-header-3 leading-tight">CrossOrigin Error</div>
            <div className="text-body-3">
              Last seen {formatDistanceToNow(fromUnixTime(data.lastSeen), { addSuffix: true })}
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center">
            <span className="text-body-5 mr-3.75 font-semibold">Status:</span>
            <StatusSelector status={data.status} fingerprintMd5={data.fingerprintMd5} />
          </div>
        </div>

        <Divider dashed className="!mb-9 !mt-4" />

        <div className="pl-4">
          <div className="text-header-3 mb-8">Why can&apos;t I see any details?</div>
          <div className="text-body-2 leading-normal">
            This error occurred on a 3rd-party script (a Javascript file not hosted on your site). By default Crazy Egg
            can not receive the error name, error message or stack trace for 3rd-party scripts due to browser
            restrictions. <br />
            However, with some{' '}
            <a
              href={SupportLinks.errors.crossBrowserTracking}
              rel="noopener noreferrer"
              target="_blank"
              className="text-link"
            >
              configuration on your end
            </a>{' '}
            we can capture all the details for all Javascript files.
          </div>
        </div>
      </div>
    </div>
  );
}
