import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Panel, Button, Divider, Indicator } from '@crazyegginc/hatch';

import { AppConfig } from '../../../AppConfig';
import { OptionsHeader } from '../components/OptionsHeader';
import { CodeBox } from '../../../components/CodeBox';
import { apiQuery } from '/src/features/options/queries';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsApiScreen() {
  const [showDocs, setShowDocs] = useState(false);
  const { data: apiData, isLoading } = useQuery({
    ...apiQuery(),
  });

  const apiKey = apiData?.api?.key ?? null;
  const apiSecret = apiData?.api?.secret ?? null;
  const apiSignature = apiData?.api?.signature ?? null;

  return (
    <>
      <OptionsHeader>API (Snapshots only)</OptionsHeader>
      <Panel>
        <div className="text-body-2 mb-5 leading-snug">
          Crazy Egg&#39;s Application Programming Interface (API) can be used by software developers to automate the
          process of creating and updating snapshots and retrieving results securely from other websites and software.
          This API is only available to use with the Snapshots feature.
        </div>
        <section className="mb-5">
          <h3 className="text-header-5">API key</h3>
          <div className="w-[700px]">
            <CodeBox code={apiKey} loading={isLoading} />
          </div>
        </section>

        <section className="mb-10">
          <h3 className="text-header-5">API Secret</h3>
          <div className="w-[700px]">
            <CodeBox code={apiSecret} loading={isLoading} />
          </div>
        </section>

        <Button onClick={() => setShowDocs((x) => !x)}>
          {showDocs ? 'Close' : 'Read'} API documentation <Indicator type="expand" up={showDocs} className="ml-2.5" />
        </Button>

        {showDocs && <ApiDocs apiKeyText={apiKey} apiSecretText={apiSecret} apiSignatureText={apiSignature} />}
      </Panel>
    </>
  );
}

function ApiDocs({ apiKeyText, apiSecretText, apiSignatureText }) {
  return (
    <section className="mt-5 break-words" style={{ overflowWrap: 'anywhere' }}>
      <h3 className="text-header-4 mb-5">Using the API</h3>
      <div className="text-body-2 mb-5 leading-snug">
        The Crazy Egg API is designed to be used by other developers that want an easy way to create and manage
        snapshots, as well as view their results from within other applications.
      </div>

      <div className="text-body-1 mb-1.25">All API calls should go through the URL:</div>
      <CodeBox code={`${AppConfig.legacyCoreBaseURL()}/api/v2`} canCopy={false} />

      <div className="text-body-1 mb-1.25 mt-5">To check if the API is up and working, you can check:</div>
      <CodeBox code={`curl ${AppConfig.legacyCoreBaseURL()}/api/v2/status.json`} canCopy={false} />
      <div className="text-body-1 mb-1.25 mt-5">And you should see the following result:</div>
      <CodeBox code={`{"msg":"OK"}`} canCopy={false} />
      <div className="text-body-2">Only JSON is supported as a return file format.</div>

      <Divider className="my-10" />

      <h4 className="text-header-5 mb-5">Request signing</h4>
      <div className="text-body-2 mb-5 leading-snug">
        All non-status checks require that you digitally &#34;sign&#34; your request. The scheme works as follows:
      </div>
      <List>
        <List.Item>For each request variable, concatenate the name and value into a string</List.Item>
        <List.Item>
          Order each of the resulting strings alphabetically and concatenate them together into a single string
        </List.Item>
        <List.Item>Generate a HMAC-SHA256 hex-digest of the final result</List.Item>
      </List>

      <div className="text-body-2 mb-1.25 leading-snug">
        The resulting hex digest is sent as a parameter called &#34;signed&#34;. To test your signing implementation,
        you can use the following:
      </div>
      <CodeBox
        code={`curl "${AppConfig.legacyCoreBaseURL()}/api/v2/authenticate.json?test=value&api_key=${apiKeyText}&signed=${apiSignatureText}"`}
        canCopy={false}
      />
      <div className="text-body-2 mb-1.25">If all went well, you should see a message saying</div>
      <CodeBox code={`{"msg":"OK"}`} canCopy={false} />
      <div className="text-body-2 mb-1.25 leading-snug">
        Try changing something about it. Add a new parameter, or change &#34;value&#34; to &#34;value2&#34;. Anything
        that would make the signature invalid, you will get a message saying
      </div>
      <CodeBox code={`{"error":"Bad signature"}`} canCopy={false} />

      <div className="text-body-2 mb-1.25 mt-5">The above example was generated in the following way:</div>

      <List>
        <List.Item>
          <div className="text-body-2 mb-1.25">Combine the query parameters into strings:</div>
          <CodeBox code={`testvalue\napi_key${apiKeyText}`} canCopy={false} />
        </List.Item>
        <List.Item>
          <div className="text-body-2 mb-1.25">Sort the strings alphabetically and concatenate them:</div>
          <CodeBox code={`api_key${apiKeyText}testvalue`} canCopy={false} />
        </List.Item>
        <List.Item>
          <div className="text-body-2 mb-1.25">Find the HMAC-SHA256 hex-digest:</div>
          <div className="text-body-1 mb-1.25">PHP</div>
          <CodeBox
            code={`$key = &ldquo;${apiSecretText}&rdquo;;\n$content = &ldquo;api_key${apiKeyText}testvalue&rdquo;;\n$result = hash_hmac(&ldquo;sha256&rdquo;, $content, $key);`}
            canCopy={false}
          />
          <div className="text-body-1 mb-1.25">Python</div>
          <CodeBox
            code={`import hashlib\nimport hmac\n\nkey = b&ldquo;${apiSecretText}&rdquo;;\ncontent = b&ldquo;api_key${apiKeyText}testvalue&rdquo;;\nresult = hmac.HMAC(key, content, hashlib.sha256).hexdigest()`}
            canCopy={false}
          />
          <div className="text-body-1 mb-1.25">Ruby</div>
          <CodeBox
            code={`requiree &lsquo;openssl&rsquo;\n\nkey = &ldquo;${apiSecretText}&rdquo;;\ncontent = &ldquo;api_key${apiKeyText}testvalue&rdquo;;\ndigest = OpenSSL::Digest.new(&lsquo;sha256&rsquo;)\nresult = OpenSSL::HMAC.hexdigest(digest, key, content)`}
            canCopy={false}
          />
          <div className="text-body-1 mb-1.25">Result</div>
          <CodeBox code={apiSignatureText} canCopy={false} />
        </List.Item>
      </List>

      <div className="text-body-2">
        Again, you can always use the authenticate method with any combination of parameters and values to test your
        implementation.
      </div>

      <Divider className="my-10" />

      <h4 className="text-header-5 mb-5">API methods</h4>

      <div className="text-body-2 mb-5 leading-snug">
        Here is a list of API methods and their use. All methods require signing (see above) unless otherwise noted.
      </div>

      <InlineCode>GET /api/v2/status.json</InlineCode>
      <div className="text-body-2 mb-5 mt-1">Check that the API service is up. Does not require signing.</div>

      <InlineCode>GET /api/v2/authenticate.json</InlineCode>
      <div className="text-body-2 mb-5 mt-1">A route to check your signing implementation. See above for details.</div>

      <InlineCode>POST /api/v2/snapshots.json</InlineCode>
      <div className="text-body-2 mb-1.25 mt-1">
        Create a new snapshot. All parameters should be sent as standard HTTP POST form fields, in the standard format
        for nested parameters. Here is an example of form fields that you would post to create a simple new snapshot:
      </div>
      <CodeBox
        code={`snapshot[source_url]=http://www.example.com\nsnapshot[name]=Example snapshot\nsnapshot[max_visits]=1000\nsnapshot[expires_at]=1412368414\napi_key=${apiKeyText}`}
        canCopy={false}
      />

      <div className="text-body-1 mb-1.25">Here are all the properties of a snapshot that can be set:</div>

      <List ordered={false}>
        <List.Item>
          <span className="text-body-1">snapshot[source_url]:</span> The page URL to be tracked
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[name]:</span> The title to give the snapshot
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[max_visits]:</span> The maximum number of times a page can be viewed
          before the snapshot no longer tracks. Sets a cut-off
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[expires_at]:</span> The date/time that the snapshot expires and stops
          tracking. All time values should be in{' '}
          <a
            href="https://en.wikipedia.org/wiki/Unix_time"
            target="_blank"
            rel="noreferrer"
            className="text-link text-sm"
          >
            Unix timestamp format
          </a>
          .
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[starts_at]:</span> The date/time that the snapshot starts tracking.
          Used to schedule future snapshots.
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[description]:</span> A short description of the snapshot. Only used to
          view snapshots, and does not impact the tracking code.
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[url_matching_rules]:</span> (Optional) Advanced rules for matching a
          page with a snapshot. See below for more information on how to use this field.
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[sampling_ratio]:</span> (Optional) Only available on premium plans. Use
          this to track only a fraction of your visitors. For example, to record only every 100th visitor, set this
          parameter to 100. The default behavior is to track all visitors.
        </List.Item>
        <List.Item>
          <span className="text-body-1">snapshot[device]:</span> (Optional) Only available on plus plans or higher. This
          parameter specifies which device type should be emulated while capturing the snapshot. Valid values are
          desktop, phone, or tablet. The default is desktop. <strong className="text-body-1">NOTE:</strong> be sure to
          set the corresponding device tracking options in <strong className="text-body-1">url_matching_rules</strong>.
          (See below.)
        </List.Item>
      </List>

      <div className="mt-2.5">
        <InlineCode>GET /api/v2/snapshots.json</InlineCode>
        <div className="text-body-2 mb-5 mt-1">
          Get a list of all snapshots. Will include all the information above, plus information on the status of the
          processing and heatmap generation.
        </div>
      </div>

      <InlineCode>
        GET /api/v2/snapshot/<span className="text-lima-500">id</span>.json
      </InlineCode>
      <div className="text-body-2 mb-5 mt-1">
        Get information about a specific snapshot, by id.
        <br />
        <strong className="text-body-1">NOTE:</strong> do not forget to include id and its value in the list of
        parameters when generating a signature.
      </div>

      <InlineCode>
        PUT /api/v2/snapshot/<span className="text-lima-500">id</span>.json
      </InlineCode>
      <div className="text-body-2 mb-5 mt-1">
        Same format as creating a new snapshot, but updates an existing one.
        <br />
        <strong className="text-body-1">NOTE:</strong> do not forget to include id and its value in the list of
        parameters when generating a signature.
      </div>

      <InlineCode>
        PUT /api/v2/snapshot/<span className="text-lima-500">id</span>/stop.json
      </InlineCode>
      <div className="text-body-2 mb-5 mt-1">
        Stop a currently running snapshot by id.
        <br />
        <strong className="text-body-1">NOTE:</strong> do not forget to include id and its value in the list of
        parameters when generating a signature.
      </div>

      <InlineCode>
        PUT /api/v2/snapshot/<span className="text-lima-500">id</span>/restart.json
      </InlineCode>
      <div className="text-body-2 mb-5 mt-1">
        Start a currently stopped snapshot.
        <br />
        <strong className="text-body-1">NOTE:</strong> do not forget to include id and its value in the list of
        parameters when generating a signature.
      </div>

      <div className="text-body-2 mb-5">
        Any API call that references a snapshot that is either not controlled by the user, or does not exist will return
        a 404 status code. Any API call that fails will return a 500 status code with an error message describing the
        failure. View a {`snapshot's`} results by requesting{' '}
        <InlineCode>
          GET /api/v2/snapshot/<span className="text-lima-500">id</span>.json
        </InlineCode>{' '}
        and retrieving the images referred to by the <span className="text-body-1">screenshot_url</span>,{' '}
        <span className="text-body-1">thumbnail_url</span> and <span className="text-body-1">heatmap_url</span> values.
      </div>

      <Divider className="my-10" />

      <h4 className="text-header-5 mb-5">Advanced</h4>

      <div className="text-body-2 mb-1.25 leading-snug">
        All snapshots that are created through the API are set to use {`"named tracking"`}, unless otherwise set in the{' '}
        <span className="text-body-1">url_matching_rules</span> field. Named tracking requires that you must set a
        global Javascript variable called <span className="text-body-1">CE_SNAPSHOT_NAME</span> on your page. The value
        of <span className="text-body-1">CE_SNAPSHOT_NAME</span> must be set to the{' '}
        <span className="text-body-1">name</span> (not the <span className="text-body-1">source_url</span>) of the
        snapshot you wish to track.
        <br />
        The <span className="text-body-1">url_matching_rules</span> field controls how the tracking script matches a
        given page with a snapshot for tracking. The options can seem a bit arcane, but are very powerful. The field
        itself should be a JSON-encoded string in the following format:
      </div>

      <CodeBox
        code={`{\n&nbsp;&nbsp;&ldquo;u&rdquo;:&ldquo;http://example.com&rdquo;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//(required) Source URL or regular expression.\n&nbsp;&nbsp;&ldquo;o&rdquo;:&ldquo;hw/?#e&rdquo;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//(optional) URL-matching options.\n&nbsp;&nbsp;&ldquo;d&rdquo;: [&ldquo;desktop&rdquo;, &ldquo;phone&rdquo;, &ldquo;tablet&rdquo;]&nbsp;&nbsp;&nbsp;&nbsp;//(optional) Device type(s). Only available to users on a plus plan or higher.\n}`}
        canCopy={false}
      />

      <div className="text-body-2 mb-1.25 leading-snug">
        The required <InlineCode>u</InlineCode> field must be set to a string. Its value should be the URL that you want
        to track. (It can be a regular expression if the optional <InlineCode>o</InlineCode> field is set to{' '}
        <InlineCode>&ldquo;e&rdquo;</InlineCode>. See below.)
      </div>

      <div className="text-body-2 mb-1.25 leading-snug">
        If none of the optional fields are supplied, then &ldquo;standard&rdquo; matching will apply. Standard matching
        allows for certain small discrepancies between the URL specified by <InlineCode>u</InlineCode> and the URL of
        the actual page. For instance, standard matching considers{' '}
        <span className="text-body-1">http://example.com/</span> and{' '}
        <span className="text-body-1">https://www.example.com/index.html</span> to be equivalent, even though there are
        numerous small differences between those two URLs.
      </div>

      <div className="text-body-2 mb-1.25 leading-snug">
        The optional <InlineCode>o</InlineCode> field describes how to interpret the <InlineCode>u</InlineCode> field.
        Its value must be a string made up of a specific set of characters (order doesn&lsquo;t matter). Each character
        in the string activates a specific corresponding option.
      </div>

      <List ordered={false}>
        <List.Item>
          <span className="text-body-1 font-mono">h:</span> Match only the protocol set in <InlineCode>u</InlineCode>.
          So restrict to only https if <InlineCode>u</InlineCode> is https://example.com and only http if{' '}
          <InlineCode>u</InlineCode> is http://example.com
        </List.Item>
        <List.Item>
          <span className="text-body-1 font-mono">w:</span> Match only the specified sub-domain. So match only
          www.example.com if <InlineCode>u</InlineCode> is http://www.example.com, and match only http://example.com if{' '}
          <InlineCode>u</InlineCode> is http://example.com. Note that this applies to all sub-domains, not just the www.
          sub-domain.
        </List.Item>
        <List.Item>
          <span className="text-body-1 font-mono">/:</span> Match only the specified path. Otherwise will match any
          style of &ldquo;index&rdquo; page (/, /index.html, /index.asp, etc)
        </List.Item>
        <List.Item>
          <span className="text-body-1 font-mono">?:</span> Match only the querystring provided. Order of query
          paramters don&lsquo;t matter; the tracking script will parse them and make its descision based on the presense
          and values of the various parameters, not the exact string arrangement of the parameters.
        </List.Item>
        <List.Item>
          <span className="text-body-1 font-mono">#:</span> Match only pages with the same URL fragment (anything after
          a #.) This can be useful for #! AJAX-loaded pages.
        </List.Item>
        <List.Item>
          <span className="text-body-1 font-mono">e:</span> Treat <InlineCode>u</InlineCode> as if it were a{' '}
          <a
            href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions"
            target="_blank"
            rel="noopener noreferrer"
            className="text-link text-sm"
          >
            regular expression
          </a>
          . The snapshot will track any page whose URL matches <InlineCode>u</InlineCode>.
        </List.Item>
      </List>

      <Divider className="my-10" />

      <h4 className="text-header-5 mb-5">Examples</h4>

      <div className="text-body-2 mb-1.25 leading-snug">
        Below are some examples of url_matching_rules and values that will and won&lsquo;t match the settings.
      </div>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://www.example.com"}`}</InlineCode>
      </p>
      <table className="mb-5 w-full border-collapse">
        <thead>
          <tr>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Will match these:
            </th>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Won&#39;t match these:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
              <code>http://www.example.com/</code>
              <br />
              <code>https://www.example.com</code>
              <br />
              <code>http://example.com/</code>
              <br />
              <code>https://example.com/index.html</code>
              <br />
              <code>https://example.com#fragment</code>
            </td>
            <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
              <code>http://www.otherexample.com</code>
              <br />
              <code>https://example.com/some_other_path</code>
              <br />
              <code>http://www.example.com/?query=string</code>
            </td>
          </tr>
        </tbody>
      </table>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://www.example.com/?key=value"}`}</InlineCode>
      </p>
      <table className="mb-5 w-full border-collapse">
        <thead>
          <tr>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Will match these:
            </th>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Won&#39;t match these:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
              <code>http://www.example.com/?key=value</code>
              <br />
              <code>http://www.example.com/?key=value&otherkey=othervalue</code>
              <br />
              <code>http://example.com/?key=value</code>
              <br />
              <code>https://example.com/index.html?key=value</code>
              <br />
              <code>https://example.com/?key=value#fragment</code>
            </td>
            <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
              <code>http://www.example.com</code>
              <br />
              <code>http://www.example.com/?otherkey=othervalue</code>
              <br />
              <code>https://example.com/some_other_path?key=value</code>
            </td>
          </tr>
        </tbody>
      </table>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://www.example.com", "o": "hw"}`}</InlineCode>
      </p>
      <table className="mb-5 w-full border-collapse">
        <thead>
          <tr>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Will match these:
            </th>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Won&#39;t match these:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
              <code>http://www.example.com</code>
              <br />
              <code>http://www.example.com#fragment</code>
            </td>
            <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
              <code>https://www.example.com</code>
              <br />
              <code>http://example.com</code>
              <br />
              <code>http://www.example.com/?query=string</code>
            </td>
          </tr>
        </tbody>
      </table>

      <p className="my-2.5">
        <InlineCode>{`{"u": "^https?://(www\\.)?example.com/categories/.+$", "o": "e"}`}</InlineCode>
      </p>
      <table className="mb-5 w-full border-collapse">
        <thead>
          <tr>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Will match these:
            </th>
            <th className="text-body-2 w-1/2 border border-mystic-500 bg-white-lilac-500 px-2.5 py-1.25 text-left">
              Won&#39;t match these:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/2 border border-mystic-500 bg-[#F3F8E5] p-2.5 text-left">
              <code>http://www.example.com/categories/foo</code>
              <br />
              <code>https://www.example.com/categories/bar?query=string#fragment</code>
              <br />
              <code>https://example.com/categories/a</code>
            </td>
            <td className="w-1/2 border border-mystic-500 bg-[#FEF7F6] p-2.5 text-left">
              <code>http://www.example.com/categories</code>
              <br />
              <code>http://example.com/foobar</code>
              <br />
              <code>http://www.example.com</code>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="text-body-2 mb-1.25 leading-snug">
        The optional <InlineCode>d</InlineCode> field specifies which device type(s) should be tracked by the snapshot.
        The value of <InlineCode>d</InlineCode> must be an array containing no more than three strings. Each string in{' '}
        <InlineCode>d</InlineCode> must be one of <u>desktop</u>, <u>phone</u>, or <u>tablet</u>. If the{' '}
        <InlineCode>d</InlineCode> field is not provided, then all device types are tracked. This field is only
        available for users on a plus plan or higher.
      </div>

      <div className="text-body-1 mb-1.25 leading-snug">Examples</div>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://example.com/", "d": ["desktop"]}`}</InlineCode>
      </p>
      <div className="text-body-2 mb-5">Will only track visitors using a desktop/laptop.</div>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://example.com/", "d": ["desktop", "tablet"]}`}</InlineCode>
      </p>
      <div className="text-body-2 mb-5">Will track visitors using either a desktop/laptop or tablet.</div>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://example.com/", "d": ["phone"]}`}</InlineCode>
      </p>
      <div className="text-body-2 mb-5">Will only track visitors using a mobile phone.</div>

      <p className="my-2.5">
        <InlineCode>{`{"u": "http://example.com/"}`}</InlineCode>
      </p>
      <div className="text-body-2 mb-5">Will track any visitor, regardless of device type.</div>
    </section>
  );
}

function InlineCode({ children }) {
  return <code className="rounded-sm border border-mystic-500 bg-white-lilac-500 px-px text-center">{children}</code>;
}

function List({ children, ordered = true }) {
  const Comp = ordered ? 'ol' : 'ul';
  return (
    <Comp
      className={classNames('text-body-2 ml-[25px] list-outside', { 'list-decimal': ordered, 'list-disc': !ordered })}
    >
      {children}
    </Comp>
  );
}

function ListItem({ children }) {
  return (
    <li className="mb-3 font-mono text-lima-500">
      <div className="text-body-2 font-sans">{children}</div>
    </li>
  );
}

List.Item = ListItem;
