import { useState, forwardRef, useRef } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'react-fast-compare';
import { produce } from 'immer';
import { Popover, CloseButton, Button, Tooltip, SkeletonLine, Panel, Indicator } from '@crazyegginc/hatch';

import { useMutation, usePermissions, useSelectedSite, useAuthContext } from '/src/hooks';
import { FilterCondition } from './FilterCondition';
import { CriteriaSelector } from './CriteriaSelector';
import { FilterPill } from './FilterPill';
import { PlaylistSaveDialog } from './PlaylistSaveDialog';
import { PlaylistDeleteDialog } from './PlaylistDeleteDialog';
import { getValidationErrors, composeFilter, decomposeFilter, hasError } from './filter-functions';
import { updatePlaylistNameMutation } from '/src/features/recordings/mutations';
import { recordingsCountQuery } from '/src/features/recordings/queries';
import { PLAYLIST_TYPES } from '/src/features/recordings/constants';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as SavePlaylistIcon } from '@crazyegginc/hatch/dist/images/icon-playlist-add-outline.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export const FilterPane = forwardRef(({ filtersHook }, ref) => {
  const { filters } = filtersHook;

  if (filters.pane.edit) {
    return <FilterEditPane filtersHook={filtersHook} ref={ref} />;
  } else {
    return <FilterSummaryPane filtersHook={filtersHook} ref={ref} />;
  }
});

export const FilterEditPane = forwardRef(({ filtersHook }, ref) => {
  const permissions = usePermissions();
  const canSavePlaylist = permissions.can('edit', FEATURES.RECORDINGS).allowed;

  const {
    filters,
    playlists,
    filtersVersion,
    setFilters,
    setErrors,
    cancelEdit,
    clearFilters,
    addCriteria,
    recordingsCriteriaDefinitionQuery,
    playlistsListQuery,
  } = filtersHook;
  const { data: recordingsCriteriaDefinition } = recordingsCriteriaDefinitionQuery;
  const playlist = playlistsListQuery.data?.playlistsList.find((x) => x.id === playlists.active);

  const handleApply = () => {
    let errors = getValidationErrors(filters);
    if (errors) {
      setErrors(errors);
    } else {
      setFilters({ filters: composeFilter(filters, filtersVersion), playlist, resetPage: true });
    }
  };

  const handleCancel = () => {
    if (filters.appliedConditions) {
      cancelEdit();
    } else {
      clearFilters();
    }
  };

  return (
    /* minWidth matching recordings table */
    <Panel className="!mb-[50px] min-w-[739px] !p-0" ref={ref} data-testid="filtersPane-edit">
      <EditableHeader canEditName={canSavePlaylist} clearFilters={clearFilters} playlist={playlist} />
      <div className="w-full px-[25px]">
        {filters.conditions.map((condition) => (
          <FilterCondition key={condition.criteria} condition={condition} filtersHook={filtersHook} />
        ))}
      </div>
      <div className="ml-[25px] flex">
        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="mr-2.5 mt-2.5">
                <Popover.Button
                  as={Button}
                  variant="secondary"
                  className={classNames('!relative !w-[175px]', { '!border-dodger-blue-500': open })}
                >
                  <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                  Add condition
                  <Indicator type="dropdown" className="absolute right-2" />
                </Popover.Button>
              </div>
              <CriteriaSelector
                addCriteria={addCriteria}
                conditions={filters.conditions}
                definitions={recordingsCriteriaDefinition.definitions}
                align="left"
              />
            </>
          )}
        </Popover>
      </div>
      <div className="mt-5 flex w-[175px] justify-between pb-[25px] pl-[25px]">
        <Button size="lg" disabled={hasError(filters)} onClick={handleApply}>
          Apply
        </Button>
        <Button size="lg" variant="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </Panel>
  );
});

export const FilterSummaryPane = forwardRef(({ filtersHook }, ref) => {
  const { currentUser } = useAuthContext();
  const { selectedSite } = useSelectedSite();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const saveButtonRef = useRef();
  const deleteButtonRef = useRef();
  const permissions = usePermissions();
  const canManagePlaylist = permissions.can('edit', FEATURES.RECORDINGS).allowed;

  const {
    filters,
    queryParams,
    playlists,
    clearFilters,
    editFilters,
    recordingsCriteriaDefinitionQuery,
    playlistsListQuery,
  } = filtersHook;
  const { data: recordingsCriteriaDefinition } = recordingsCriteriaDefinitionQuery;

  const dateFilterOnly = produce(queryParams.filters, (draft) => {
    draft.conditions = draft.conditions.filter((c) => c.criteria === 'date_range');
  });

  const { data: dataFiltered, isFetching: isFetchingFiltered } = useQuery({
    ...recordingsCountQuery({ filters: JSON.stringify(queryParams.filters), site: selectedSite?.id }),
    enabled: Boolean(selectedSite?.id),
  });
  const { data: dataTotal, isFetching: isFetchingTotal } = useQuery({
    ...recordingsCountQuery({ filters: JSON.stringify(dateFilterOnly), site: selectedSite?.id }),
    enabled: Boolean(selectedSite?.id),
  });
  const playlist = playlistsListQuery.data?.playlistsList.find((x) => x.id === playlists.active);

  const checkUnsavedChanges = () => {
    if (playlist && !playlists.selected) {
      const { conditions } = decomposeFilter({
        filtersParam: playlist.filter,
        definitions: recordingsCriteriaDefinition.definitions,
        currentUser,
      });
      if (!isEqual(filters.conditions, conditions)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Panel className="!mb-[50px] min-w-[739px] !p-0" ref={ref} data-testid="filtersPane-summary">
      <div className="relative mb-2.5 flex w-full items-center border-b border-dashed border-mystic-500 pl-[25px] pr-[45px] pt-5">
        <Title>
          {playlist?.name ?? 'Recordings that match the following:'}
          {checkUnsavedChanges() && (
            <div className="text-caption ml-[15px] flex h-5 items-center rounded bg-white-lilac-500 px-1.25 font-semibold">
              Unsaved edits
            </div>
          )}
        </Title>
        {!isFetchingFiltered && !isFetchingTotal ? (
          <div className="text-body-5 mb-2.5 font-semibold">
            {dataFiltered?.recordingsCount != null &&
              dataTotal?.recordingsCount != null &&
              `${dataFiltered?.recordingsCount} of ${dataTotal?.recordingsCount}`}
            {dataFiltered?.recordingsCount != null &&
              dataTotal?.recordingsCount == null &&
              `${dataFiltered?.recordingsCount} matches`}
            {dataFiltered?.recordingsCount == null &&
              dataTotal?.recordingsCount != null &&
              `Filtering from a total of ${dataTotal?.recordingsCount}`}
          </div>
        ) : (
          <SkeletonLine width="80px" />
        )}
        <FilterCloseButton onClick={clearFilters} />
      </div>
      <div className="flex w-full p-[25px] pt-[15px]">
        <div className="flex flex-1 flex-wrap">
          {filters.conditions.map((condition) =>
            condition.criteria === 'date_range' ? null : ( // sc20550
              <FilterPill key={condition.criteria} condition={condition} siteId={selectedSite?.id} />
            ),
          )}
        </div>
        <div className="flex">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => editFilters(playlist && playlist.type === PLAYLIST_TYPES.RECOMMENDED)}
          >
            <EditIcon className="mr-2 h-3.5 w-3.5 fill-current text-dodger-blue-500" />
            <span>Edit</span>
          </Button>

          {canManagePlaylist &&
            (!playlist ||
              playlist?.type === PLAYLIST_TYPES.USER ||
              (playlist?.type === PLAYLIST_TYPES.RECOMMENDED && checkUnsavedChanges())) && (
              <div className="relative">
                <div className="ml-1.25" ref={saveButtonRef}>
                  <Button variant="secondary" size="sm" onClick={() => setSaveDialogOpen((x) => !x)}>
                    <SavePlaylistIcon className="mr-2 h-3.5 w-3.5 fill-current text-dodger-blue-500" />
                    <span>Save playlist</span>
                  </Button>
                </div>
                <PlaylistSaveDialog
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  buttonRef={saveButtonRef}
                  filtersHook={filtersHook}
                />
              </div>
            )}

          {canManagePlaylist && playlist && playlist.type === PLAYLIST_TYPES.USER && (
            <div className="relative">
              <div className="ml-1.25" ref={deleteButtonRef}>
                <Button variant="secondary" size="sm" onClick={() => setDeleteDialogOpen((x) => !x)}>
                  <DeleteIcon className="h-3.5 w-3.5 fill-current text-dodger-blue-500" aria-label="Delete playlist" />
                </Button>
              </div>
              <PlaylistDeleteDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                buttonRef={deleteButtonRef}
                filtersHook={filtersHook}
              />
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
});

const maxNameLength = 35;
function EditableHeader({ canEditName, clearFilters, playlist }) {
  const { selectedSite } = useSelectedSite();
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(playlist?.name);
  const updatePlaylistName = useMutation(updatePlaylistNameMutation);

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxNameLength || e.nativeEvent.inputType.match(/delete/)) {
      setName(value);
    }
  };

  const handleApply = () => {
    if (name.length >= maxNameLength || name.length === 0) return;
    if (name !== playlist.name) {
      updatePlaylistName.mutate(
        { site: selectedSite.id, playlist: playlist.id, name },
        {
          onError: () => setName(playlist.name),
          onSuccess: (data) => setName(data.updatePlaylistName),
          onSettled: () => setEditing(false),
        },
      );
    }
  };

  if (!playlist) {
    return (
      <div className="relative mb-2.5 flex w-full items-center pl-[25px] pr-[45px] pt-5">
        <Title className="text-sm">Recordings that match the following:</Title>
        <FilterCloseButton onClick={clearFilters} />
      </div>
    );
  }

  return (
    <>
      <div className="relative mb-2.5 flex w-full items-center border-b border-dashed border-mystic-500 pl-[25px] pr-[45px] pt-5">
        {editing ? (
          <div className="flex-1">
            <Tooltip
              tooltipContent={
                <div className="max-w-[180px] p-1.5 text-left">
                  Please keep playlist name under {maxNameLength} characters
                </div>
              }
              placement="bottom"
              skiddingPercent="10"
              arrowSkiddingPercent="15"
              hover={false}
              show={name.length >= maxNameLength}
              offset={6}
            >
              <input
                className="mb-2.5 h-[25px] w-full flex-1 text-lg outline-none"
                type="text"
                value={name}
                onChange={handleNameChange}
                onKeyPress={(e) => {
                  if (e.key !== 'Enter') {
                    return;
                  }
                  handleApply();
                }}
                onBlur={handleApply}
                aria-label="playlist name"
                // eslint-disable-next-line
                autoFocus={true}
              />
            </Tooltip>
          </div>
        ) : (
          <Title>
            {name}
            {canEditName && (
              <button onClick={() => setEditing(true)} className="ml-2.5 focus-visible:outline-black">
                <EditIcon className="h-4 w-4 fill-current text-dodger-blue-300" aria-label="Edit playlist name" />
              </button>
            )}
          </Title>
        )}
        <FilterCloseButton onClick={clearFilters} />
      </div>
      <div className="relative flex w-full items-center px-[25px] pt-[15px]">
        <Title className="text-sm">Recordings that match the following:</Title>
      </div>
    </>
  );
}

function FilterCloseButton({ onClick }) {
  return <CloseButton onClick={onClick} label="remove filters" iconClass="!w-4 !h-4" buttonClass="!w-5 !h-5 !top-6" />;
}

const Title = ({ children, className }) => (
  <div className={classNames('text-header-3 mb-2.5 flex h-[25px] flex-1 items-center', className)}>{children}</div>
);
