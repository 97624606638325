import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function RemoveDraft({ onConfirm = null, onCancel = null }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!w-[370px]">
      <Modal.Title>Remove stored draft?</Modal.Title>
      <div className="mb-6 flex w-full justify-between">
        <p className="mt-0 flex flex-col space-y-2.5 text-sm leading-5 text-lynch-500">
          <span>Creating a new draft will replace the exising one.</span>
          <span>Once your draft saves, this cannot be undone.</span>
        </p>
      </div>
      <div className="flex">
        <Button
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Continue
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            onCancel?.();
            modal.close();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
