import { useCallback } from 'react';
import classNames from 'classnames';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import LoaderGif from '@crazyegginc/hatch/dist/animations/loader-circle.gif';

export function useLoader() {
  const { show, close } = useModal();

  const showLoader = useCallback(() => {
    show(<LoaderModal />);
  }, [show]);

  const hideLoader = useCallback(() => {
    close();
  }, [close]);

  return { showLoader, hideLoader };
}

function LoaderModal() {
  return (
    <Modal
      disableOutsideClick={true}
      dismissable={false}
      dialogClassName={classNames(
        '!rounded-[10px] !h-[100px] !w-[100px]',
        '!opacity-40 !bg-black !bg-no-repeat !bg-center',
      )}
      overlayClassName="!bg-black-pearl-500/10"
      style={{ backgroundImage: `url(${LoaderGif})`, backgroundSize: '48px 48px' }}
    />
  );
}
