import { createClient, fetchExchange } from '@urql/core';
import { v4 as uuid } from 'uuid';

import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';
import { checkSharingSession, getSharingToken } from '/src/utils';

export const urqlOptions = (baseUrl) => {
  return {
    url: `${baseUrl || window.CORE_API_URL}/gql`,
    exchanges: [fetchExchange],
    fetchOptions: () => {
      const currentToken = checkSharingSession() ? getSharingToken() : window.localStorage.getItem(AUTH_TOKEN_KEY);
      return currentToken
        ? {
            headers: {
              authorization: `Bearer ${currentToken}`,
              'x-request-id': uuid(),
            },
          }
        : {};
    },
  };
};

export const newClient = (options = urqlOptions()) => {
  return createClient(options);
};
