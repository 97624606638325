import classNames from 'classnames';
import { WHATS_NEW_TYPES } from '../constants';

export function Dot({ type, children }) {
  if (!type) {
    return <DotContainer className="bg-cadet-blue-500">{children}</DotContainer>;
  }

  switch (type.toUpperCase()) {
    case WHATS_NEW_TYPES.FIX:
      return <DotContainer className="bg-dandelion-500">{children}</DotContainer>;
    case WHATS_NEW_TYPES.NEW:
      return <DotContainer className="bg-dodger-blue-500">{children}</DotContainer>;
    case WHATS_NEW_TYPES.IMPROVEMENT:
      return <DotContainer className="bg-lavender-500">{children}</DotContainer>;
    default:
      return <DotContainer className="bg-cadet-blue-500">{children}</DotContainer>;
  }
}

function DotContainer({ children, className = null }) {
  return (
    <div
      className={classNames(
        'relative z-10 mt-9 box-border h-6 w-6 flex-shrink-0 rounded-full border-4 border-white-lilac-500',
        className,
      )}
    >
      {children}
    </div>
  );
}
