import { Doughnut } from 'react-chartjs-2';

import { mergeDeep } from '/src/utils/object';

export function DoughnutChart({ data, options = {} }) {
  return (
    <div className="relative flex h-[182px] w-[182px] items-center justify-center">
      <Doughnut
        width="182px"
        height="182px"
        options={mergeDeep(
          {
            maintainAspectRatio: false,
            cutout: 60,
            plugins: {
              legend: {
                display: false,
                position: 'right',
              },
            },
          },
          { ...options },
        )}
        data={data}
      />
    </div>
  );
}
