import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { SkeletonLine } from '@crazyegginc/hatch';

import { authAtom, LOGOUT, SWITCH_ACCOUNT } from '/src/machines/auth';
import { useSite, usePermissions } from '/src/hooks';
import { SharedAccountRow } from './SharedAccountRow';
import { FEATURES, PLAN_TYPES, ALL } from '/src/features/_global/constants';

import { ReactComponent as LogoutIcon } from '@crazyegginc/hatch/dist/images/icon-signout-outline.svg';
import { ReactComponent as TriangleIcon } from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg';

export function AccountSelector({ expanded = true }) {
  const [state, send] = useAtom(authAtom);
  const { currentUser, currentAccount, subscription } = state.context;
  const { sites, selectedSite, loadingSites } = useSite({ dontSelectSite: true });
  const [switchingAccount] = useState(false);
  const permissions = usePermissions();

  const canVisitTeamAndSharing = permissions.can('navigate', FEATURES.TEAM_AND_SHARING).allowed;
  const canManageAccount = permissions.can('navigate', FEATURES.BILLING).allowed;
  const canVisitBilling =
    permissions.can('navigate', FEATURES.BILLING).allowed && subscription.plan.type !== PLAN_TYPES.FREEMIUM;

  const ownedAccounts = currentUser.accounts.filter((acc) => acc.isOwner);
  const sharedAccounts = currentUser.accounts.filter((acc) => !acc.isOwner);
  const isAccountOwner = currentAccount.id === currentUser.guestId && currentAccount.isOwner;

  async function switchAccount(accountId) {
    send({
      type: SWITCH_ACCOUNT,
      accountId,
    });
  }

  function nameDisplay() {
    if (isAccountOwner) return 'My Account';

    if ((sites?.length > 1 && selectedSite === ALL) || sites?.length <= 0) {
      return currentAccount.name || currentAccount.ownerName || currentAccount.ownerEmail;
    }

    if (sites?.length === 1) {
      return sites[0].name;
    }

    return selectedSite?.name ?? currentAccount.name;
  }

  return (
    <>
      <div
        className="group relative flex h-[60px] w-full items-center hover:bg-white hover:bg-opacity-[0.15]"
        data-testid="account-selector-menu"
      >
        <span
          className={classNames('box-border flex w-full items-center justify-center bg-opacity-80', {
            'px-6 py-4': expanded,
            'relative p-3.5': !expanded,
          })}
        >
          {currentAccount && (
            <img
              src={currentAccount.ownerAvatarUrl}
              alt={isAccountOwner ? 'Your avatar. Visit gravatar.com to change it' : 'Account avatar'}
              className={classNames('h-6 w-6 flex-shrink-0 rounded-full', {
                'mr-2': expanded,
              })}
            />
          )}
          {expanded ? (
            switchingAccount || loadingSites ? (
              <SkeletonLine background="#004573" width="100%" />
            ) : (
              <span
                className="w-full select-none truncate text-left text-sm font-semibold text-solitude-500"
                data-testid="account_name"
              >
                {nameDisplay()}
              </span>
            )
          ) : null}

          <TriangleIcon
            aria-label="expand"
            className={classNames(' -rotate-90 fill-current text-solitude-500', {
              'right-2 h-3 w-3': expanded,
              'absolute right-1 h-2 w-2': !expanded,
            })}
          />
        </span>
        <div
          className="absolute bottom-[3px] left-[calc(100%-5px)] z-[9999] hidden max-h-[90vh] w-[250px] flex-col items-start overflow-y-auto overflow-x-hidden rounded border border-mystic-500 bg-white shadow-lg group-hover:flex"
          data-testid="account_menu"
        >
          {currentUser.accounts.length > 1 ? (
            <span className="text-body-4 mx-3.5 my-2 italic">Switch Accounts:</span>
          ) : null}
          <div className="w-full">
            {ownedAccounts.map((account, idx) => {
              if (account.id === currentUser.guestId) {
                return (
                  <button
                    className={classNames('box-border flex w-full p-3', {
                      'border-t border-dashed border-mystic-500': currentUser.accounts.length > 1,
                      'bg-zumthor-500': account.id === currentAccount.id,
                      'hover:bg-alice-blue-500': account.id !== currentAccount.id,
                    })}
                    onClick={() => {
                      if (currentAccount.id !== parseInt(account.id)) {
                        switchAccount(parseInt(account.id));
                      }
                    }}
                    key={`MyAccount-${idx}`}
                  >
                    <img
                      src={account.ownerAvatarUrl}
                      alt="Your avatar. Visit gravatar.com to change it"
                      className="h-[30px] w-[30px] rounded-full"
                    />
                    <div className="ml-2 flex flex-col items-start leading-tight">
                      <h3 className="text-sm font-bold text-mako-500">My Account</h3>
                      <span className="text-2xs text-mako-500">{account.ownerEmail}</span>

                      {account.isCurrent && (
                        <div className="mt-2 flex flex-col items-start">
                          <Link to="/account/profile" className="text-link mb-0.5 text-xs">
                            {canManageAccount ? 'Manage account' : 'View profile'}
                          </Link>
                          {canVisitTeamAndSharing ? (
                            <Link to="/account/team" className="text-link mb-0.5 text-xs">
                              Manage team &amp; sharing
                            </Link>
                          ) : null}
                          {canVisitBilling ? (
                            <Link to="/account/billing" className="text-link text-xs">
                              Billing details
                            </Link>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </button>
                );
              } else {
                return (
                  <SharedAccountRow
                    account={account}
                    key={`OwnedAccount-${idx}`}
                    onClick={() => {
                      if (currentAccount.id !== parseInt(account.id)) {
                        switchAccount(parseInt(account.id));
                      }
                    }}
                  />
                );
              }
            })}

            {sharedAccounts.map((account, idx) => (
              <SharedAccountRow
                account={account}
                key={`SharedAccount-${idx}`}
                onClick={() => {
                  if (currentAccount.id !== parseInt(account.id)) {
                    switchAccount(parseInt(account.id));
                  }
                }}
              />
            ))}
          </div>
          <button
            className="box-border flex w-full items-center border-t border-dashed border-mystic-500 px-5 py-3 hover:bg-alice-blue-500"
            onClick={() => {
              send(LOGOUT);
            }}
          >
            <LogoutIcon aria-label="logout" className="h-4 w-4 fill-current text-dodger-blue-300" />
            <span className="text-header-6 ml-3.5">Log Out</span>
          </button>
        </div>
      </div>
    </>
  );
}
