import DOMPurify from 'dompurify';
import { EmojiConvertor } from 'emoji-js';
import { mailtoRegex, telRegex } from '/src/utils/regex';
import { URL_TYPES } from '/src/features/addons/constants';
import { normalizeUrl } from '/src/utils/url';

const emojiCDN = 'https://cdn.jsdelivr.net/npm/emoji-datasource-google@7.0.2/img/google/64/';

const emoji = new EmojiConvertor();
emoji.use_sheet = false;
emoji.avoid_ms_emoji = false;
emoji.allow_native = true;
emoji.include_text = true;
emoji.wrap_native = true;

emoji.img_set = 'google';
emoji.img_sets.google.path = emojiCDN;

export function processUnsafeText(input) {
  if (!input) return input;
  const parsedResponse = emoji.replace_colons(input);
  const safeHtml = DOMPurify.sanitize(parsedResponse);

  return safeHtml;
}

const emoji2colons = new EmojiConvertor();
emoji2colons.colons_mode = true;

export function replaceEmoji(input) {
  if (!input) return input;
  return emoji2colons.replace_unified(input);
}

const emoji2img = new EmojiConvertor();
emoji2img.use_sheet = false;
emoji2img.replace_mode = 'css';
emoji2img.img_set = 'google';
emoji2img.include_text = true;
emoji2img.img_sets.google.path = emojiCDN;

export function replaceEmojiWithImage(input) {
  if (!input) return input;
  return emoji2img.replace_colons(replaceEmoji(input));
}

export function replaceImageWithColons(input) {
  if (!input) return input;
  return input.replaceAll(/<span class="emoji[^>]*>(:[^:]*:)<\/span>/g, '$1');
}

const getURLTypeValue = (type, value, isSurvey) => {
  let result = { urlType: type, urlValue: value };

  if (
    value.match(mailtoRegex) ||
    (type === URL_TYPES.PHONE && value.match(mailtoRegex)) ||
    (type === URL_TYPES.MAILTO && !value.match(telRegex))
  ) {
    result.urlType = isSurvey ? 'open-mail' : URL_TYPES.MAILTO;
    result.urlValue = value.replace('mailto:', '');
  }

  if (
    value.match(telRegex) ||
    (type === URL_TYPES.PHONE && !value.match(mailtoRegex)) ||
    (type === URL_TYPES.MAILTO && value.match(telRegex))
  ) {
    result.urlType = isSurvey ? 'open-tel' : URL_TYPES.PHONE;
    result.urlValue = value.replace(/tel:|-|\s*/g, '');
  }
  return result;
};

export const returnURLTypeValue = ({
  selectedType,
  value,
  fieldValueKeyType,
  fieldValueKeyURL,
  setFieldValue,
  isSurvey,
}) => {
  const result = getURLTypeValue(selectedType, value, isSurvey);
  const isNotMailandPhone = result.urlValue !== 'open-mail' && result.urlValue !== 'open-tel';

  if (selectedType !== result.urlType) {
    setFieldValue(fieldValueKeyType, result.urlType);
  }

  setFieldValue(
    fieldValueKeyURL,
    result.urlType === URL_TYPES.URL || (isSurvey && !isNotMailandPhone)
      ? normalizeUrl(result.urlValue, { appendSlash: false })
      : result.urlValue,
  );
};

export const urlTypeLabel = (type) => {
  switch (type) {
    case URL_TYPES.MAILTO:
      return 'email address';
    case URL_TYPES.PHONE:
      return 'phone number';
    default:
      return 'URL';
  }
};
