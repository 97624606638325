//import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { CloseButton, Input } from '@crazyegginc/hatch';

import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';

const parserSchema = yup.object().shape({
  url: yup
    .string()
    .required('Please provide a URL.')
    .test('is-valid-url', 'Please provide a valid URL.', (value) => {
      if (value === undefined) return true;
      let url = value.match(/^https?:\/\//) ? value : `http://${value}`;
      try {
        new URL(url);
      } catch {
        return false;
      }
      return true;
    }),
});

export function URLParser({ onClick }) {
  return (
    <div className="mt-5 flex w-full items-start">
      <Formik
        initialValues={{ url: '' }}
        validationSchema={parserSchema}
        onSubmit={(values, actions) => {
          const returnValues = {
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            utm_term: '',
            utm_content: '',
          };
          let url = values.url.match(/^https?:\/\//) ? values.url : `http://${values.url}`;
          url = new URL(url);
          let params = new URLSearchParams(url.search);
          Object.keys(returnValues).forEach((key) => {
            returnValues[key] = params.get(key);
          });
          onClick?.(returnValues);
          actions.resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, resetForm }) => (
          <div className="relative w-full">
            <Form>
              <Input
                name="url"
                type="text"
                placeholder="OR paste campaign URL for quick select (e.g. example.url?utm_source=google)"
                error={touched.url && errors.url ? errors.url : null}
                onChange={handleChange}
                value={values.url}
                className="!pr-9"
                leftIcon={<SearchIcon className="h-4 w-4 fill-current text-cadet-blue-500" />}
                rightIcon={
                  values.url.length > 0 ? (
                    <CloseButton
                      buttonClass="!top-0 !-right-1 !mr-0 !my-0 !relative"
                      type="button"
                      label="clear input"
                      onClick={() => {
                        resetForm();
                      }}
                    />
                  ) : null
                }
              />
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
