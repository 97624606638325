import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { RG } from '@crazyegginc/hatch';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

export function TypeCard({ type, icon, label, disabled }) {
  const { values } = useFormikContext();

  const selected = values.displayType === type;

  return (
    <RG.Option
      value={type}
      className={classNames(
        'relative flex h-[110px] min-w-[190px] max-w-[230px] grow flex-col items-center justify-center rounded border',
        {
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
          'border-lynch-500': disabled && selected,
          'border-mystic-500': !selected,
          'border-dodger-blue-500': !disabled && selected,
        },
      )}
    >
      {icon}
      <span className={classNames('text-body-4', { 'font-semibold text-dodger-blue-500': selected })}>{label}</span>
      {selected ? (
        <TickIcon
          className={classNames('absolute left-3.5 top-3.5 h-3.5 w-3.5 fill-current', {
            'text-dodger-blue-500': !disabled,
            'text-lynch-500': disabled,
          })}
        />
      ) : (
        <div className="absolute left-3.5 top-3.5 h-3.5 w-3.5 rounded-full border border-cadet-blue-500" />
      )}
    </RG.Option>
  );
}
