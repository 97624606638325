import { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import { siteQuery } from '/src/features/_global/queries';

import { changeInteractiveMode } from '/src/services/web-editor';
import { Button, RadioGroup, Input, Tooltip, Toggle } from '@crazyegginc/hatch';
import { useMutation, useNotifications, useModal } from '/src/hooks';
import { setRecordingSettings, setSiteRecording } from '/src/features/options/mutations';
import {
  noDuplicationInValidation,
  getInitialValues,
  returnArrayOfURLsForSubmission,
  pageTargetingEnabledType,
} from '/src/features/options/utils';
import { PAGE_TARGETING_ENABLED_TYPE } from '/src/features/options/constants';
import { ElementSelector } from '/src/components/ElementSelector';
import { superLenientUrlRegex } from '/src/utils/regex';
import { isValidCssSelector } from '/src/utils';
import { SupportLinks } from '/src/support';
import { Modal } from '/src/contexts/modal';

import { SiteSettingsModalListofInputs } from '/src/features/options/components/modals/SiteSettingsModalListofInputs';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';
import { ReactComponent as DesktopIcon } from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg';
import { ReactComponent as TabletIcon } from '@crazyegginc/hatch/dist/images/icon-tablet-outline.svg';
import { ReactComponent as MobileIcon } from '@crazyegginc/hatch/dist/images/icon-mobile-outline.svg';
import { ReactComponent as InteractiveIcon } from '@crazyegginc/hatch/dist/images/icon-interactive-outline.svg';
import { ReactComponent as ReloadIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';

import { normalizeUrl } from '/src/utils/url';
import { DEVICE_TYPES } from '/src/features/_global/constants';

const { DESKTOP, TABLET, PHONE } = DEVICE_TYPES;
const { RECORD_ALL, RECORD_BY_JS, RECORD_SPECIFIC } = PAGE_TARGETING_ENABLED_TYPE;

export function SiteSettingsModal({ site }) {
  const { mutate: mutateUpdateSiteRecording } = useMutation(setSiteRecording);
  const { mutate: mutateRecordingSettings, isLoading } = useMutation(setRecordingSettings);
  const notifications = useNotifications();
  const modal = useModal();

  const { data } = useQuery({
    ...siteQuery({
      id: site.id,
    }),
  });

  const isRecordingSessionEnabled = data?.site?.sessionRecording ?? false;

  const validationSchema = yup.object().shape({
    pageTargetingEnabledType: yup.string().oneOf([RECORD_ALL, RECORD_BY_JS, RECORD_SPECIFIC]),
    urlMatchingRules: yup.array().when('pageTargetingEnabledType', {
      is: (value) => value === RECORD_SPECIFIC,
      then: yup
        .array()
        .of(
          yup.object().shape({
            u: yup
              .string()
              .required('Please provide a URL.')
              .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
              .matches(
                new RegExp(`${site.name.replace(/\./g, '\\.')}`, 'i'),
                `The provided URL must be on ${site.name}.`,
              ),
          }),
        )
        .test({
          name: 'is-duplicate',
          test: function (value) {
            const isDuplicate = noDuplicationInValidation(value, 'u');

            return !isDuplicate
              ? true
              : this.createError({
                  message: 'Please provide a unique URL.',
                });
          },
        }),
    }),
    blockedUrlMatchingRules: yup
      .array()
      .of(
        yup.object().shape({
          u: yup
            .string()
            .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
            .matches(
              new RegExp(`${site.name.replace(/\./g, '\\.')}`, 'i'),
              `The provided URL must be on ${site.name}.`,
            ),
        }),
      )
      .test({
        name: 'is-url-duplicate',
        test: function (value) {
          const isDuplicate = noDuplicationInValidation(value, 'u');

          return !isDuplicate
            ? true
            : this.createError({
                message: 'Please provide a unique URL.',
              });
        },
      }),
    maskElements: yup
      .array()
      .of(
        yup.object().shape({
          selector: yup
            .string()
            .max(10000, 'Your CSS selector exceeds the maximum length of 10,000 characters.')
            .test('css-selector', 'Please enter a valid CSS selector.', (value) => isValidCssSelector(value)),
        }),
      )
      .test({
        name: 'is-css-duplicate',
        test: function (value) {
          const isDuplicate = noDuplicationInValidation(value, 'selector');

          return !isDuplicate
            ? true
            : this.createError({
                message: 'Please provide a unique CSS selector.',
              });
        },
      }),
  });

  const initialValues = {
    pageTargetingEnabledType: pageTargetingEnabledType(
      site?.recordingSettings?.pageTargetingEnabled,
      site?.recordingSettings?.urlMatchingRules,
    ),
    endSessionOnBlockedUrl: getInitialValues('bool', site?.recordingSettings?.endSessionOnBlockedUrl),
    urlMatchingRules: getInitialValues('url', site?.recordingSettings?.urlMatchingRules),
    blockedUrlMatchingRules: getInitialValues('url', site?.recordingSettings?.blockedUrlMatchingRules),
    maskElements: getInitialValues('css', site?.recordingSettings?.maskElements),
  };

  return (
    <Modal dialogClassName="!p-0 w-[640px] relative" disableOutsideClick={true}>
      <div className="border-b border-mystic-500 px-[30px] pb-6 pt-[30px]">
        <h4 className="text-header-3">Recordings Settings</h4>
      </div>
      <div className="h-[50vh] max-h-[440px] overflow-y-auto pb-[84px] scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded">
        <div className="border-b border-dashed border-mystic-500 px-[30px] py-5">
          <Toggle
            labelClasses="!font-semibold !text-md"
            enabled={isRecordingSessionEnabled}
            setEnabled={() => {
              mutateUpdateSiteRecording({
                siteId: site.id,
                enable: !isRecordingSessionEnabled,
              });
            }}
            label={`Record visitors to ${site.name}`}
            displayLabel={true}
          />
          <p className="text-body-2 ml-[51px] mt-1 text-lynch-500">
            Crazy Egg will {!isRecordingSessionEnabled && 'NOT'} record visitors to your website.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const {
              urlMatchingRules,
              endSessionOnBlockedUrl,
              blockedUrlMatchingRules,
              pageTargetingEnabledType,
              maskElements,
            } = values;
            mutateRecordingSettings(
              {
                siteId: site.id,
                pageTargetingEnabled:
                  pageTargetingEnabledType === RECORD_SPECIFIC || pageTargetingEnabledType === RECORD_BY_JS
                    ? true
                    : false,
                endSessionOnBlockedUrl: endSessionOnBlockedUrl,
                urlMatchingRules:
                  urlMatchingRules.length > 0 &&
                  urlMatchingRules[0].u !== '' &&
                  pageTargetingEnabledType !== RECORD_BY_JS
                    ? `{"rules": ${JSON.stringify(returnArrayOfURLsForSubmission(urlMatchingRules))}}`
                    : `{"rules" : []}`,
                blockedUrlMatchingRules:
                  blockedUrlMatchingRules.length > 0 && blockedUrlMatchingRules[0].u
                    ? `{"rules": ${JSON.stringify(returnArrayOfURLsForSubmission(blockedUrlMatchingRules))}}`
                    : null,
                maskElements: maskElements[0]?.selector
                  ? JSON.stringify(maskElements.filter((el) => el.selector.trim() !== ''))
                  : null,
              },
              {
                onError: (error) => {
                  notifications.error({
                    content: `Setting update failed for ${site.name}.`,
                    timeout: 3000,
                    context: { error },
                  });
                },
                onSuccess: () => {
                  notifications.success({ content: `Setting updated successfully for ${site.name}.`, timeout: 3000 });
                  modal.close();
                },
              },
            );
          }}
        >
          {() => {
            return (
              <Form>
                <SiteSettingsSection title="What pages should we record?" opacity={isRecordingSessionEnabled}>
                  <SiteSettingSectionRecordPage
                    siteName={site.name}
                    isRecordingSessionEnabled={isRecordingSessionEnabled}
                  />
                </SiteSettingsSection>
                <SiteSettingsSection title="What should we ignore?" opacity={isRecordingSessionEnabled}>
                  <SiteSettingsSectionIgnorePage isRecordingSessionEnabled={isRecordingSessionEnabled} />
                  <SiteSettingsSectionCode />
                  <SiteSettingsSectionMaskedElementsExplainer />
                  <SiteSettingsSectionMaskedElements isRecordingSessionEnabled={isRecordingSessionEnabled} />
                </SiteSettingsSection>

                <div className="absolute bottom-0 flex w-full items-center space-x-2.5 border-t border-mystic-500 bg-white px-[30px] py-6">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? `Saving...` : `Save`}
                  </Button>
                  <Modal.Cancel />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

function SiteSettingsSection({ title, opacity = true, children }) {
  return (
    <div
      className={classNames('px-[30px] py-5  first:border-b first:border-dashed first:border-mystic-500', {
        'opacity-50': !opacity,
      })}
    >
      <h4 className="text-header-5 mb-5">{title}</h4>
      <div>{children}</div>
    </div>
  );
}

function SiteSettingSectionRecordPage({ siteName, isRecordingSessionEnabled }) {
  const { values, setFieldValue } = useFormikContext();
  const { urlMatchingRules, pageTargetingEnabledType } = values;

  return (
    <div>
      <RadioGroup
        size="sm"
        options={[
          {
            value: RECORD_ALL,
            label: `Record sessions when visitors land anywhere on ${siteName}`,
          },
          { value: RECORD_SPECIFIC, label: 'Only start recording sessions once visitors land on a specific page' },
          { value: RECORD_BY_JS, label: 'Start recording sessions when triggered via Javascript' },
        ]}
        value={pageTargetingEnabledType}
        onChange={(value) => {
          urlMatchingRules.length < 1 && value === RECORD_SPECIFIC
            ? setFieldValue('urlMatchingRules', [{ u: '' }])
            : null;
          setFieldValue('pageTargetingEnabledType', value);
        }}
        disabled={!isRecordingSessionEnabled}
      />
      {pageTargetingEnabledType === RECORD_SPECIFIC && (
        <div className="pt-4">
          <SiteSettingsModalListofInputs
            listData="urlMatchingRules"
            type="record"
            isRecordingSessionEnabled={isRecordingSessionEnabled}
          />
        </div>
      )}
      {pageTargetingEnabledType === RECORD_BY_JS && <SiteSettingsSectionCode section="record" />}
    </div>
  );
}

function SiteSettingsSectionIgnorePage({ isRecordingSessionEnabled }) {
  const { values, setFieldValue } = useFormikContext();
  const { blockedUrlMatchingRules, endSessionOnBlockedUrl } = values;

  return (
    <div className="mb-5">
      <p className="text-body-1"> What pages should we ignore?</p>
      <p className="text-body-4 mb-[15px] leading-tight">Choose which pages Crazy Egg shouldn’t record.</p>
      {blockedUrlMatchingRules.length > 0 && (
        <div className="mb-[15px]">
          <RadioGroup
            size="sm"
            options={[
              {
                value: false,
                label: `Skip these pages from recording`,
              },
              { value: true, label: 'End recording session when visitor reaches these pages' },
            ]}
            value={endSessionOnBlockedUrl}
            onChange={(value) => {
              setFieldValue('endSessionOnBlockedUrl', value);
            }}
            disabled={!isRecordingSessionEnabled}
          />
        </div>
      )}
      <SiteSettingsModalListofInputs
        listData="blockedUrlMatchingRules"
        type="ignore"
        showSingleDelete={true}
        isRecordingSessionEnabled={isRecordingSessionEnabled}
      />
    </div>
  );
}

function SiteSettingsSectionCode({ section }) {
  const isSectionRecord = section === 'record';

  return (
    <div className={classNames({ 'pb-[30px]': !isSectionRecord, 'pt-2': isSectionRecord })}>
      <p className="text-body-4 mb-2.5">
        You can also {isSectionRecord ? 'start' : 'stop'} a recording by calling{' '}
        <code className="rounded-[3px] border border-mystic-500 bg-white-lilac-500 px-1.25 text-center font-mono text-2xs">
          {isSectionRecord ? 'CE2.startRecording()' : 'CE2.stopRecording()'}
        </code>{' '}
        on your website like so:
      </p>
      <code className="mb-2.5 block whitespace-pre rounded bg-white-lilac-500 p-[15px] font-mono text-2xs text-black-pearl-500">
        {`(window.CE_API || (window.CE_API=[])).push(function(){\n${' '}${' '}${
          isSectionRecord ? 'CE2.startRecording()' : 'CE2.stopRecording()'
        };\n});`}
      </code>
      <a
        href={SupportLinks.siteSettings.sessionsTriggeredByJS}
        target="_blank"
        rel="noopener noreferrer"
        className="text-link text-xs font-normal"
      >
        Learn more about recording sessions triggered by JavaScript
      </a>
    </div>
  );
}

function SiteSettingsSectionMaskedElementsExplainer() {
  return (
    <div className="mb-5">
      <p className="text-body-1 mb-2.5">Which elements should we ignore?</p>
      <p className="text-body-2">For privacy reasons, Crazy Egg automatically masks the following elements:</p>
      <ul className="text-body-2 my-2.5 ml-5 list-disc">
        <li>Email, password, numeric, and text input fields</li>
        <li>All slider and dial controls</li>
      </ul>
      <p className="text-body-2">
        If you want to mask additional elements, you can add them below by clicking “Add element”, or enter the URL of a
        page with the element you&apos;d like to mask, then find and click on the element to add it to the mask list.{' '}
        <a
          href={SupportLinks.siteSettings.maskingElement}
          target="_blank"
          rel="noopener noreferrer"
          className="text-link font-normal"
        >
          Learn more about masking elements
        </a>
        .
      </p>
    </div>
  );
}

function SiteSettingsSectionMaskedElements({ isRecordingSessionEnabled }) {
  const { values, setFieldValue } = useFormikContext();
  const [selectedDevice, selectDevice] = useState(DESKTOP);
  const [isInteractive, setIsInteractive] = useState(false);
  const [pageUrl, setPageUrl] = useState('');
  const [count, setCount] = useState(0);
  const [normalizedUrl, setNormalizedUrl] = useState('');
  const [urlDirty, setUrlDirty] = useState(false);

  const validUrl = useMemo(() => normalizedUrl.trim() !== '', [normalizedUrl]);
  const validDevice = [DESKTOP, TABLET, PHONE].includes(selectedDevice);

  const maskElementsLength = useMemo(() => values.maskElements.length, [values]);
  const lastInputEmpty = useMemo(() => (values.maskElements.at(-1)?.selector ?? '') === '', [values]);

  const onElementSelected = useCallback(
    (event) => {
      let element = null;

      if (event.eventType === 'clickSelected') {
        element = event.payload;
      }

      if (element !== null && lastInputEmpty && !values.maskElements.some((el) => el.selector === element)) {
        const targetIndex = Math.max(maskElementsLength - 1, 0);
        setFieldValue(`maskElements.${targetIndex}.selector`, element);
      } else if (element !== null && !lastInputEmpty && !values.maskElements.some((el) => el.selector === element)) {
        const targetIndex = Math.max(maskElementsLength, 0);
        setFieldValue(`maskElements.${targetIndex}`, { selector: element });
      } else if (element === null && !lastInputEmpty) {
        const targetIndex = Math.max(maskElementsLength - 1, 0);
        setFieldValue(`maskElements.${targetIndex}.selector`, '');
      }
    },
    [lastInputEmpty, maskElementsLength, setFieldValue, values.maskElements],
  );

  return (
    <div className="pb-5">
      <p className="text-body-1 mb-2.5">Mask these elements:</p>
      <div className="flex flex-col">
        <SiteSettingsModalListofInputs
          listData="maskElements"
          type="css"
          buttonName="element"
          placeholder="e.g. input[type=hidden]"
          showSingleDelete={true}
          buttonDisabled={false}
          isRecordingSessionEnabled={isRecordingSessionEnabled}
        >
          <div className="flex flex-1 pl-2.5">
            <div className="flex-1">
              <Input
                placeholder="e.g. example.com"
                name={'blocking.url'}
                value={pageUrl}
                className="rounded-br-none rounded-tr-none"
                onChange={(e) => {
                  setPageUrl(e.target.value.trim());
                  if (urlDirty) {
                    setUrlDirty(false);
                  }
                }}
                disabled={!isRecordingSessionEnabled}
                error={isRecordingSessionEnabled && !validUrl && urlDirty ? 'URL is required.' : null}
              />
            </div>
            <Button
              variant="secondary"
              className="w-[180px] rounded-bl-none rounded-tl-none pl-2.5 text-[13px]"
              onClick={() => {
                setUrlDirty(true);
                const urlInput = document.querySelector('[name="blocking.url"]').value;
                setNormalizedUrl(normalizeUrl(urlInput || '', { appendSlash: false }));
              }}
              disabled={!isRecordingSessionEnabled}
            >
              <SearchIcon className="mr-1.25 h-4 w-4 fill-current" />
              Find element on page
            </Button>
          </div>
        </SiteSettingsModalListofInputs>
      </div>
      {validUrl && validDevice && isRecordingSessionEnabled ? (
        <div className="mt-5 flex flex-col">
          <div className="flex flex-col rounded border border-mystic-500">
            <div className="border-b border-mystic-500 px-5 py-2.5">
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center space-x-1">
                  <span className="text-body-4 mr-[6px]">Device view:</span>
                  <div className="flex items-center space-x-0.5">
                    <Button
                      className={classNames(
                        'relative flex w-10 justify-center !rounded-br-none !rounded-tr-none !px-0',
                        {
                          '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                            selectedDevice === DESKTOP,
                        },
                      )}
                      onClick={() => selectDevice(DESKTOP)}
                      variant="secondary"
                      disabled={selectedDevice === DESKTOP}
                    >
                      <DesktopIcon className="h-4 w-4 fill-current" aria-label="desktop" />
                    </Button>
                    <Button
                      className={classNames('relative flex w-10 justify-center !rounded-none !px-0', {
                        '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                          selectedDevice === TABLET,
                      })}
                      onClick={() => selectDevice(TABLET)}
                      variant="secondary"
                      disabled={selectedDevice === TABLET}
                    >
                      <TabletIcon className="h-4 w-4 fill-current" aria-label="tablet" />
                    </Button>
                    <Button
                      className={classNames(
                        'relative flex w-10 justify-center !rounded-bl-none !rounded-tl-none !px-0',
                        {
                          '!cursor-default !border !border-dodger-blue-500 !bg-dodger-blue-500 text-white':
                            selectedDevice === PHONE,
                        },
                      )}
                      onClick={() => selectDevice(PHONE)}
                      variant="secondary"
                      disabled={selectedDevice === PHONE}
                    >
                      <MobileIcon className="h-4 w-4 fill-current" aria-label="mobile" />
                    </Button>
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <Tooltip tooltipContent="Interactive mode">
                    <Button
                      onClick={() => {
                        setIsInteractive((interactive) => {
                          const newValue = !interactive;
                          changeInteractiveMode(newValue, 'element-selector-frame');
                          return newValue;
                        });
                      }}
                      className={classNames('relative flex w-10 justify-center !px-0', {
                        '!border !border-dodger-blue-500 !bg-dodger-blue-500 text-white': isInteractive,
                      })}
                      variant="secondary"
                    >
                      <InteractiveIcon className="h-4 w-4 fill-current" />
                    </Button>
                  </Tooltip>
                  <Tooltip tooltipContent="Reload the page">
                    <Button
                      onClick={() => {
                        setCount((count) => count + 1);
                      }}
                      className="relative flex w-10 justify-center !px-0"
                      variant="secondary"
                    >
                      <ReloadIcon className="h-3 w-3 fill-current" />
                    </Button>
                  </Tooltip>
                  {/* <Tooltip tooltipContent={`${state.context.hidePopups ? 'Show' : 'Hide'} pop-ups`}> */}
                  {/*   <Button */}
                  {/*     onClick={() => { */}
                  {/*       send({ type: 'CHANGE_POPUPS_STATE' }); */}
                  {/*     }} */}
                  {/*     className="group !flex !w-[35px] !items-center !justify-center !p-0" */}
                  {/*     variant="cancel" */}
                  {/*   > */}
                  {/*     {state.context.hidePopups ? ( */}
                  {/*       <PopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" /> */}
                  {/*     ) : ( */}
                  {/*       <HidePopupIcon className="h-4 w-4 fill-current text-dodger-blue-500" /> */}
                  {/*     )} */}
                  {/*   </Button> */}
                  {/* </Tooltip> */}
                </div>
              </div>
            </div>

            <ElementSelector
              key={`${normalizedUrl}:${selectedDevice}:${count}`}
              url={normalizedUrl}
              device={selectedDevice.toLowerCase()}
              editorPath="basic"
              event-name="page-interaction"
              onSelect={onElementSelected}
              className="aspect-[4/3] w-full"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
