import { getChecks } from '@crazyegginc/compatibility-checker';

import { isDevelopment, isE2E } from '/src/utils';

const dummyResponse = {
  passed: true,
  videoApiIframe: { result: 'ok' },
  snapshotEventsApi: { result: 'ok' },
  filesApi: { result: 'ok' },
};

export async function recordingsPlayerCheck() {
  if (isDevelopment() || isE2E()) {
    return dummyResponse;
  }

  const report = await getChecks(['ALL']);

  const [videoApiIframe, snapshotEventsApi, filesApi] = await Promise.all([
    report.categories.browserChecks.find((c) => c.name === 'Video API Iframe').check(),
    report.categories.urlChecks.find((c) => c.name === 'Snapshot Events API').check(),
    report.categories.urlChecks.find((c) => c.name === 'Files').check(),
  ]);

  return {
    passed: videoApiIframe.result === 'ok' && snapshotEventsApi.result === 'ok' && filesApi.result === 'ok',
    videoApiIframe,
    snapshotEventsApi,
    filesApi,
  };
}
