import { RECORDINGS_FILTER_VERSION } from '/src/features/recordings/constants';
import { ERROR_STATUSES, NETWORK_ERROR, UNKNOWN_RESOURCE_ERROR } from './constants';
import { LOGICALS } from '/src/features/_global/constants';

export function generateErrorFilterUrl(error, convertedDateRange) {
  return JSON.stringify({
    version: RECORDINGS_FILTER_VERSION,
    operator: LOGICALS.AND,
    conditions: [
      {
        criteria: 'error',
        multiple_values: 'or',
        values: [error],
      },
      {
        criteria: 'date_range',
        value: {
          start_date: convertedDateRange.startDate,
          end_date: convertedDateRange.endDate,
        },
      },
    ],
  });
}

const ErrorHelper = {
  NETWORK_ERROR: {
    DEFAULT: {
      recommendation: ERROR_STATUSES.RESOLVED,
      message: `Many times network errors such as these are temporary or due to internet connectivity issues from the person visiting your site. However, if you notice a large volume of network errors this can indicate an issue with your servers. If it's a small number you can safely ignore this or resolve it now and see if it returns. If you are uncertain you should share it with your engineering team.`,
    },
  },
  UNKNOWN_RESOURCE_ERROR: {
    DEFAULT: {
      recommendation: ERROR_STATUSES.MUTED,
      message: `This error often triggers when an image, script, or CSS file fails to load. You should investigate and fix the failing resource.`,
    },
  },
};

export function getErrorHelper([mainType, subType]) {
  let summary;

  switch (mainType) {
    case NETWORK_ERROR:
      switch (subType) {
        default:
          summary = ErrorHelper[mainType].DEFAULT;
      }
      break;
    case UNKNOWN_RESOURCE_ERROR:
      switch (subType) {
        default:
          summary = ErrorHelper[mainType].DEFAULT;
      }
      break;
    default:
      return null;
  }

  return summary;
}
