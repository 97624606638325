import { useState, useEffect, useRef } from 'react';
import { Tag, capitalize, SkeletonLine } from '@crazyegginc/hatch';

import { formatDate } from '/src/utils/date';
import { useUrqlClient } from '/src/hooks';
import { UNIQUE_ERROR_QUERY } from '/src/features/errors/queries';
import { SNAPSHOT_NAME_ONLY_QUERY } from '/src/features/snapshots/queries';
import { ADDON_NAME } from '/src/features/addons/queries';
import { capitalizeFirstLetter } from '/src/utils/string';
import { GOAL_NAME_ONLY } from '/src/features/goals/queries';

const empty_values = ['blank', 'empty', 'direct', '---'];
const empty_utm_value = '---';

export function FilterPill({ condition, siteId, textOnly = false }) {
  let text;
  let tags;

  switch (condition.definition.pill_function) {
    case 'snapshot_pill':
      return <SnapshotPill condition={condition} textOnly={textOnly} />;
    case 'error_pill':
      return <ErrorPill condition={condition} siteId={siteId} textOnly={textOnly} />;
    case 'goal_pill':
      return <GoalPill condition={condition} textOnly={textOnly} />;
    case 'cta_pill':
      return <CtaPill condition={condition} textOnly={textOnly} />;
    case 'default':
      text = condition.definition.pill_criteria + (condition.comparison ? ` ${condition.comparison}` : '');
      text += getValues(condition);
      break;
    case 'skip_name':
      text = getValues(condition);
      break;
    case 'duration':
      if (condition.comparison === 'between') {
        text = `between ${condition.values[0][0].value}${
          condition.values[0][1].value === condition.values[0][3].value ? '' : ' ' + condition.values[0][1].value
        } and ${condition.values[0][2].value} ${condition.values[0][3].value}`;
      } else {
        text = `${condition.comparison === 'at least' ? '≥' : '≤'} ${condition.values[0][0].value} ${
          condition.values[0][1].value
        }`;
      }
      break;
    case 'num_pages_viewed':
      if (condition.comparison === 'between') {
        text = `between ${condition.values[0][0].value} and ${condition.values[0][1].value}`;
      } else {
        text = `${condition.comparison === 'at least' ? '≥' : '≤'} ${condition.values[0][0].value}`;
      }
      text += ' pages viewed';
      break;
    case 'screen_width':
      if (condition.comparison === 'between') {
        text = `screen width between ${condition.values[0][0].value} and ${condition.values[0][1].value}`;
      } else {
        text = `screen width ${condition.comparison === 'at least' ? '≥' : '≤'} ${condition.values[0][0].value}`;
      }
      text += ' pixels';
      break;
    case 'screen_height':
      if (condition.comparison === 'between') {
        text = `screen height between ${condition.values[0][0].value} and ${condition.values[0][1].value}`;
      } else {
        text = `screen height ${condition.comparison === 'at least' ? '≥' : '≤'} ${condition.values[0][0].value}`;
      }
      text += ' pixels';
      break;
    case 'currency':
      if (condition.comparison === 'between') {
        text = `${condition.definition.name} between ${condition.values[0][0].value} and ${condition.values[0][1].value} ${condition.values[0][2].value}`;
      } else {
        text = `${condition.definition.name} ${condition.comparison === 'at least' ? '≥' : '≤'} ${
          condition.values[0][0].value
        } ${condition.values[0][2].value}`;
      }
      break;
    case 'tags':
      {
        const field = condition.values[0][0];
        const tagStyle = '!mt-[2.5px] !mr-0 !mb-[2.5px] !ml-1.25';
        const includeTags = field.value?.filter((tag) => !tag.match(/^NOT:/)) ?? [];
        const excludeTags =
          field.value?.filter((tag) => tag.match(/^NOT:/)).map((tag) => tag.replace('NOT:', '')) ?? [];

        text = condition.comparison === 'none of' ? 'Does not have ' : 'Has ';
        text += includeTags.length > 1 ? 'tags' : 'tag';

        if (textOnly) {
          text += ' ';

          includeTags.slice(0, -1).forEach((tag) => {
            text += `"${capitalize(tag)}" `;
          });

          if (includeTags.length > 1) {
            text += condition.comparison === 'any of' ? 'or ' : 'and ';
          }

          text += `"${capitalize(includeTags[includeTags.length - 1])}"`;

          if (excludeTags.length !== 0) {
            text += excludeTags.length > 1 ? ' but none of ' : ' but not ';
            excludeTags.slice(0, -1).forEach((tag) => {
              text += `"${capitalize(tag)}" `;
            });
            if (excludeTags.length > 1) {
              text += 'or ';
            }
            text += `"${capitalize(excludeTags[excludeTags.length - 1])}"`;
          }
        } else {
          tags = (
            <>
              {includeTags.slice(0, -1).map((tag) => (
                <Tag key={tag} tag={tag} tagClass={tagStyle} />
              ))}
              {includeTags.length > 1 && (
                <span className={tagStyle}>{condition.comparison === 'any of' ? 'or' : 'and'}</span>
              )}
              {<Tag tag={includeTags[includeTags.length - 1]} tagClass={tagStyle} />}
              {excludeTags.length !== 0 && (
                <>
                  <span className={tagStyle}>{excludeTags.length > 1 ? 'but none of' : 'but not'}</span>
                  {excludeTags.slice(0, -1).map((tag) => (
                    <Tag key={tag} tag={tag} tagClass={tagStyle} />
                  ))}
                  {excludeTags.length > 1 && <span className={tagStyle}>or</span>}
                  <Tag tag={excludeTags[excludeTags.length - 1]} tagClass={tagStyle} />
                </>
              )}
            </>
          );
        }
      }
      break;
    case 'date_range':
      {
        const field = condition.values[0][0];
        if (field.value.special) {
          text = field.value.special;
        } else {
          text = `${formatDate(condition.values[0][0].value.start_date)} to ${formatDate(
            condition.values[0][0].value.end_date,
          )}`;
        }
      }
      break;
    case 'referrer_pill':
      {
        text = '';
        let criteriaAdded = false;
        condition.values.map((group, groupIndex) => {
          group.map((field) => {
            if (empty_values.includes(field.value)) {
              if (condition.comparison === 'matches') {
                text += ' ' + 'direct traffic';
              } else if (condition.comparison === 'does not match') {
                text += ' ' + 'not direct visit';
              }
            } else {
              if (criteriaAdded) {
                text += ' ' + field.value;
              } else {
                text +=
                  ' ' +
                  condition.definition.pill_criteria +
                  (condition.comparison ? ` ${condition.comparison}` : '') +
                  ' ' +
                  field.value;
                criteriaAdded = true;
              }
            }
            if (groupIndex === condition.values.length - 2) {
              text += ' ' + condition.definition.multiple_values_type;
            } else if (groupIndex !== condition.values.length - 1) {
              text += ',';
            }
          });
        });
        text = capitalizeFirstLetter(text.trimLeft());
      }
      break;
  }

  if (textOnly) {
    return text;
  }

  return (
    <Pill>
      {text}
      {tags && tags}
    </Pill>
  );
}

function SnapshotPill({ condition, textOnly = false }) {
  const { client } = useUrqlClient();
  const [state, setState] = useState({ loading: true, text: condition.definition.pill_criteria + ' ' });

  useEffect(() => {
    let names = '';
    let didCancel = false;
    const length = condition.values.length;

    async function updateNames() {
      await getSnapshotNames();
      if (!didCancel) {
        setState((x) => ({ loading: false, text: x.text + names }));
      }
    }

    async function getSnapshotNames() {
      return Promise.all(
        condition.values.map(async (x, index) => {
          const { data } = await client.query(SNAPSHOT_NAME_ONLY_QUERY, { id: +x[0].value }).toPromise();
          let value = x[0].value;
          if (data && data.snapshot) {
            value = `"${data.snapshot.name}"`;
          }
          if (index === length - 2) {
            names += `${value} or `;
          } else if (index === length - 1) {
            names += `${value}`;
          } else {
            names += `${value}, `;
          }
          return;
        }),
      );
    }

    updateNames();

    return () => {
      didCancel = true;
    };
  }, [client, condition.values]);

  if (textOnly) {
    if (state.text) {
      return state.text;
    } else {
      return 'Loading...';
    }
  }

  if (state.loading) {
    return <SkeletonLine width="150px" />;
  }
  return <Pill>{state.text}</Pill>;
}

function ErrorPill({ condition, siteId, textOnly = false }) {
  const { client } = useUrqlClient();
  const [state, setState] = useState({ loading: true, text: condition.definition.pill_criteria + ' ' });

  useEffect(() => {
    let names = '';
    let didCancel = false;
    const length = condition.values.length;

    async function updateNames() {
      await getErrorNames();
      if (!didCancel) {
        setState((x) => ({ loading: false, text: x.text + names }));
      }
    }

    async function getErrorNames() {
      return Promise.all(
        condition.values.map(async (x, index) => {
          const { data } = await client
            .query(UNIQUE_ERROR_QUERY, { fingerprintMd5: x[0].value, siteId: siteId })
            .toPromise();
          let value = x[0].value;
          if (data && data.uniqueError) {
            value = `"${data.uniqueError.fingerprint}"`;
          }
          if (index === length - 2) {
            names += `${value} or `;
          } else if (index === length - 1) {
            names += `${value}`;
          } else {
            names += `${value}, `;
          }
          return;
        }),
      );
    }

    updateNames();

    return () => {
      didCancel = true;
    };
  }, [client, condition.values, siteId]);

  if (textOnly) {
    if (state.text) {
      return state.text;
    } else {
      return 'Loading...';
    }
  }

  if (state.loading) {
    return <SkeletonLine width="150px" />;
  }
  return <Pill>{state.text}</Pill>;
}

function GoalPill({ condition, textOnly = false }) {
  const tags = useRef(null);
  const goalNames = useRef({});
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState('');

  const field = condition.values[0][0];
  const connectorStyle = '!mt-[2.5px] !mr-0 !mb-[2.5px] !ml-1.25';
  const tagStyle = '!mt-[2.5px] !mr-0 !mb-[2.5px] !ml-1.25 !bg-[#6565E0] !text-white';

  const { client } = useUrqlClient();

  useEffect(() => {
    async function prepareGoals() {
      setLoading(true);
      await getGoalNames();

      if (textOnly) {
        let text = condition.comparison === 'did not do' ? 'Did not do ' : 'Did ';
        text += field.value.length > 1 ? 'Goals' : 'Goal';

        field.value.slice(0, -1).forEach((goalId) => {
          text += ` "${goalNames.current[goalId]}"`;
        });
        if (field.value.length > 1) {
          text += condition.comparison === 'did any of' ? ' or' : ' and';
        }
        text += ` "${goalNames.current[field.value[field.value.length - 1]]}"`;
        setTextValue(text);
      } else {
        let text = condition.comparison === 'did not do' ? 'Did not do ' : 'Did ';
        text += field.value.length > 1 ? 'Goals' : 'Goal';

        tags.current = (
          <>
            {field.value.slice(0, -1).map((goalId, index) => (
              <Tag key={`Goal:${goalId}:${index}`} tag={goalNames.current[goalId]} tagClass={tagStyle} />
            ))}
            {field.value.length > 1 && (
              <span className={connectorStyle}>{condition.comparison === 'did any of' ? 'or' : 'and'}</span>
            )}
            {<Tag tag={goalNames.current[field.value[field.value.length - 1]]} tagClass={tagStyle} />}
          </>
        );

        setTextValue(text);
        setLoading(false);
      }
    }

    async function getGoalNames() {
      return Promise.all(
        condition.values[0]?.[0]?.value?.map?.(async (goal) => {
          const { data } = await client.query(GOAL_NAME_ONLY, { id: +goal }).toPromise();
          goalNames.current[goal] = data?.goalDetail?.name;
        }),
      );
    }

    prepareGoals();
  }, [client, condition.values, field.value, condition.comparison, textOnly]);

  if (textOnly) {
    if (textValue !== '') {
      return textValue;
    } else {
      return 'Loading...';
    }
  }

  if (loading) {
    return <SkeletonLine width="150px" />;
  }

  return (
    <Pill>
      {textValue}
      {tags.current && tags.current}
    </Pill>
  );
}

function CtaPill({ condition, textOnly = false }) {
  const tags = useRef(null);
  const ctaNames = useRef({});
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState('');

  const field = condition.values[0][0];
  const connectorStyle = '!mt-[2.5px] !mr-0 !mb-[2.5px] !ml-1.25';
  const tagStyle = '!mt-[2.5px] !mr-0 !mb-[2.5px] !ml-1.25 !bg-[#1698A9] !text-white';

  const { client } = useUrqlClient();

  useEffect(() => {
    async function prepareCTAs() {
      setLoading(true);
      await getNames();

      let text = condition.definition.pill_criteria + ' ';
      if (textOnly) {
        field.value.slice(0, -1).forEach((id) => {
          text += ` "${ctaNames.current[id]}"`;
        });
        if (field.value.length > 1) {
          text += condition.comparison === 'any of' ? ' or' : ' and';
        }
        text += ` "${ctaNames.current[field.value[field.value.length - 1]]}"`;
        setTextValue(text);
      } else {
        tags.current = (
          <>
            {field.value.slice(0, -1).map((id, index) => (
              <Tag key={`${id}:${index}`} tag={ctaNames.current[id]} tagClass={tagStyle} />
            ))}
            {field.value.length > 1 && (
              <span className={connectorStyle}>{condition.comparison === 'any of' ? 'or' : 'and'}</span>
            )}
            {<Tag tag={ctaNames.current[field.value[field.value.length - 1]]} tagClass={tagStyle} />}
          </>
        );

        setTextValue(text);
        setLoading(false);
      }
    }

    async function getNames() {
      return Promise.all(
        condition.values[0]?.[0]?.value?.map?.(async (id) => {
          const { data } = await client.query(ADDON_NAME, { id }).toPromise();
          ctaNames.current[id] = data?.addonDetail?.name;
        }),
      );
    }

    prepareCTAs();
  }, [client, condition.values, field.value, condition.comparison, textOnly, condition.definition.pill_criteria]);

  if (textOnly) {
    if (textValue !== '') {
      return textValue;
    } else {
      return 'Loading...';
    }
  }

  if (loading) {
    return <SkeletonLine width="150px" />;
  }

  return (
    <Pill>
      {textValue}
      {tags.current && tags.current}
    </Pill>
  );
}

function transformIfEmpty(value, condition) {
  if (value === empty_utm_value && condition.criteria.match(/^utm_/)) {
    return 'empty';
  }
  return value;
}

function getValues(condition) {
  let values = '';
  condition.values.map((group, groupIndex) => {
    group.map((field, fieldIndex) => {
      // don't include fields that were not shown due to specific comparison
      const fieldDef = condition.definition.fields[fieldIndex];
      if (fieldDef.show_only_for_specific_comparisons) {
        if (!fieldDef.show_only_for_specific_comparisons_list.includes(condition.comparison)) return;
      }

      if (Array.isArray(field.value)) {
        if (field.value.length > 1) {
          values +=
            ' ' +
            field.value
              .slice(0, -1)
              .map((x) => transformIfEmpty(x, condition))
              .join(', ') +
            ' or ' +
            transformIfEmpty(field.value[field.value.length - 1], condition);
        } else {
          values += ' ' + transformIfEmpty(field.value[0], condition);
        }
      } else {
        values += ' ' + transformIfEmpty(field.value, condition);
      }

      if (condition.definition.use_multiple_values) {
        if (groupIndex === condition.values.length - 2) {
          values += ' ' + (condition.multiple_values ?? condition.definition.multiple_values_type);
        } else if (groupIndex !== condition.values.length - 1) {
          values += ',';
        }
      }
    });
  });

  return values;
}

const Pill = ({ children }) => (
  <div className="text-body-2 mb-1.25 mr-1.25 flex min-h-[30px] flex-wrap items-center rounded-full border border-malibu-500 px-3">
    {children}
  </div>
);
