import { Spinner, capitalize } from '@crazyegginc/hatch';

import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { FEATURES } from '/src/features/_global/constants';

function humanize(type) {
  switch (type) {
    case FEATURES.ADDONS:
      return 'Add-Ons';
    case FEATURES.CTAS:
      return 'CTAs';
    default:
      return capitalize(type);
  }
}

export function AddonLoading({ type }) {
  const title = humanize(type);
  return (
    <>
      <SEO title={title} />
      <DashHeader title={title} />
      <div className="mt-16 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    </>
  );
}
