import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@crazyegginc/hatch';

import { useSelectedSite, useNotifications, useModal, useMutation } from '/src/hooks';
import { useAddonMutations } from '../../common/mutation-functions';
import { addonEnabledMutation } from '/src/features/addons/mutations';
import { LaunchModal } from '../modals/LaunchModal';
import { useEditorContext } from '../../editor-context';
import { hasAtLeastOneQuestion, generateSurveyDataFormat } from '../../surveys/editor/editor-functions';
import { generateCTADataFormat } from '../../ctas/editor/editor-functions';
import { getSurveyQueryParams } from '/src/utils/url';

import { SURVEY_DISPLAY_TYPES, ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as SaveIcon } from '@crazyegginc/hatch/dist/images/icon-save.svg';
import { ReactComponent as PublishIcon } from '@crazyegginc/hatch/dist/images/icon-rocket.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

const minSavingTimeInterval = 20 * 1000;

const SAVE = 'save';
const PUBLISH = 'publish';

export const SaveAndPublish = forwardRef((_, ref) => {
  const { isTemplate, publishedEdit, id, type, spelledType } = useEditorContext();
  const { isValid, values, isSubmitting, submitForm, setSubmitting } = useFormikContext();
  const [intervalForAutoSavePassed, setIntervalForAutoSavePassed] = useState(false);
  const [savedValues, setSavedValues] = useState(values);
  const [currentActionType, setCurrentActionType] = useState();
  const notifications = useNotifications();
  const enableAddonMutation = useMutation(addonEnabledMutation);
  const { editAddon } = useAddonMutations();
  const navigate = useNavigate();
  const modal = useModal();
  const { selectedSite } = useSelectedSite();
  const isExternal = values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL;

  const canSaveOrPublish = type === ADDON_TYPES.CTA ? isValid : isValid && hasAtLeastOneQuestion(values);

  const intervalRef = useRef();
  const startInterval = () => {
    setIntervalForAutoSavePassed(false);
    clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => setIntervalForAutoSavePassed(true), minSavingTimeInterval);
  };

  useEffect(() => {
    startInterval();
  }, []);

  useEffect(() => {
    async function autoSave() {
      setSubmitting(true);
      setCurrentActionType(SAVE);
      setSavedValues(values);
      await submitForm();
      startInterval();
      setCurrentActionType();
    }
    // no auto-saving for templates
    if (!isTemplate) {
      if (canSaveOrPublish && intervalForAutoSavePassed && savedValues !== values && !isSubmitting) {
        autoSave();
      }
    }
  }, [
    canSaveOrPublish,
    intervalForAutoSavePassed,
    savedValues,
    values,
    submitForm,
    setSubmitting,
    isSubmitting,
    isTemplate,
  ]);

  useImperativeHandle(ref, () => ({
    async saveAddon() {
      return await handleClick(SAVE);
    },
  }));

  async function handleClick(actionType) {
    setCurrentActionType(actionType);
    if (type === ADDON_TYPES.SURVEY && !hasAtLeastOneQuestion(values)) {
      notifications.error({
        title: `Failed to ${actionType} ${spelledType}`,
        content: `You need to add at least one question to be able to ${actionType} this ${spelledType}.`,
        timeout: 4000,
        skipHoneybadger: true,
      });
      modal.close();
      return;
    }
    setSubmitting(true);
    setSavedValues(values);
    startInterval();

    if (!isValid) {
      notifications.error({
        title: `Failed to ${actionType} ${spelledType}`,
        content: `Fix any errors in the editor to be able to ${actionType} this ${spelledType}.`,
        timeout: 4000,
        skipHoneybadger: true,
      });
    }
    let returnValue;
    await submitForm().then(async (result) => {
      setSubmitting(true);
      if (result) {
        if (isTemplate) {
          modal.close();
          navigate({ pathname: '/addons/templates', search: getSurveyQueryParams({ type: `${type}S` }) });
        } else {
          const { id, previewToken } = result;
          returnValue = { id, previewToken };
          if (id && actionType === PUBLISH) {
            if (publishedEdit) {
              const addon =
                type === ADDON_TYPES.CTA
                  ? generateCTADataFormat({ values, id, publishedEdit, publishDraft: true })
                  : generateSurveyDataFormat({ values, id, publishedEdit, publishDraft: true });
              returnValue = await editAddon(addon, 'Failed to publish add-on.');
            }
            try {
              await enableAddonMutation.mutateAsync(
                {
                  id,
                  siteId: selectedSite.id,
                  status: true,
                },
                {
                  onError: (error) => {
                    modal.close();
                    notifications.error({
                      content: 'Failed to publish add-on.',
                      timeout: 3000,
                      context: { error, id },
                    });
                  },
                  onSuccess: () => {
                    modal.close();
                    if (isExternal) {
                      navigate(
                        { pathname: '/addons', search: getSurveyQueryParams({ type: `${type}S` }) },
                        { state: { externalPublishSuccess: true, publishedId: id } },
                      );
                    } else {
                      if (publishedEdit) {
                        navigate(
                          { pathname: '/addons', search: getSurveyQueryParams({ type: `${type}S` }) },
                          { state: { draftPublishSuccess: true, type: spelledType } },
                        );
                      } else {
                        navigate(
                          { pathname: '/addons', search: getSurveyQueryParams({ type: `${type}S` }) },
                          { state: { publishSuccess: true, type: spelledType } },
                        );
                      }
                    }
                  },
                },
              );
            } catch {
              //noop
            }

            setCurrentActionType();
          } else {
            modal.close();
          }
        }
      } else {
        modal.close();
      }
    });
    setSubmitting(false);
    return returnValue;
  }

  async function discardEdits() {
    setSubmitting(true);
    const addon = {
      id,
      draftName: null,
      draftContent: null,
      siteId: values.audience.site.id,
    };
    await editAddon(addon, 'Failed to discard changes.');
    setSubmitting(false);
    navigate({ pathname: '/addons', search: getSurveyQueryParams({ type: `${type}S` }) });
  }

  return (
    <div className="flex items-center space-x-2.5">
      {publishedEdit && (
        <Button
          variant="secondary"
          size="xl"
          className="flex items-center leading-none"
          disabled={isSubmitting}
          onClick={discardEdits}
        >
          <DeleteIcon className="mr-2 h-3 w-3 shrink-0 fill-current" />
          Discard changes
        </Button>
      )}
      {!isTemplate && (
        <Button
          variant="secondary"
          size="xl"
          className="flex items-center leading-none"
          disabled={isSubmitting}
          onClick={() => handleClick(SAVE)}
        >
          {isSubmitting && currentActionType === SAVE ? (
            <>
              <Spinner className="mr-2 h-3 w-3" />
              Saving...
            </>
          ) : (
            <>
              <SaveIcon className="mr-2 h-3 w-3 shrink-0 fill-current" />
              {publishedEdit ? 'Save draft' : 'Save'}
            </>
          )}
        </Button>
      )}
      <Button
        size="xl"
        className="flex items-center leading-none"
        disabled={isSubmitting}
        onClick={() => {
          modal.show(
            <LaunchModal
              onConfirm={() => handleClick(PUBLISH)}
              isTemplate={isTemplate}
              publishedEdit={publishedEdit}
              type={spelledType}
            />,
          );
        }}
      >
        {isSubmitting && currentActionType === PUBLISH ? (
          <>
            <Spinner className="mr-2 h-3 w-3 shrink-0" />
            Publishing...
          </>
        ) : (
          <>
            <PublishIcon className="mr-2 h-3 w-3 shrink-0 fill-current" />
            Publish {isTemplate ? 'Template' : publishedEdit ? 'changes' : spelledType}
          </>
        )}
      </Button>
    </div>
  );
});
