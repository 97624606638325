import { useEffect } from 'react';

const pattern = new RegExp(/\n-/g);

// Change the \n- bullet points pattern to proper html list items
// Mainly used for AI generated content
export function useBulletPoints({ id, data }) {
  useEffect(() => {
    const node = document.querySelector(`[id="${id}"]`);
    if (node) {
      const content = node.textContent;
      const html = node.innerHTML;

      if (html.indexOf('<ul ') === -1 && content.search(pattern) > -1) {
        const newHtml = content.replace(pattern, replacer) + '</li></ul>';
        node.innerHTML = newHtml;
      }
    }
  }, [id, data]);
}

function replacer(match, offset, string) {
  if (string.search(pattern) === offset) {
    // first replacement
    return '<ul class="list-disc ml-5 list-outside"><li>';
  } else {
    return '</li><li>';
  }
}
