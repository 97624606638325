import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Checkbox, Select } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { CLOSE_OPTIONS, ADDONS_CAPABILITIES } from '../../constants';

const options = [
  {
    value: CLOSE_OPTIONS.EVERY_TIME,
    label: 'The next time they visit a page where this triggers',
  },
  {
    value: CLOSE_OPTIONS.SESSION,
    label: 'The next time they leave and come back to my site',
  },
  {
    value: CLOSE_OPTIONS.DAYS_1,
    label: 'After 1 day',
  },
  {
    value: CLOSE_OPTIONS.DAYS_3,
    label: 'After 3 days',
  },
  {
    value: CLOSE_OPTIONS.DAYS_7,
    label: 'After 7 days',
  },
  {
    value: CLOSE_OPTIONS.FOREVER,
    label: 'Never',
  },
];

export function ClosableBehavior({ label }) {
  const { readonly, addonsCapabilities } = useEditorContext();
  const { values, setFieldValue, handleChange } = useFormikContext();
  const [lastValue, setLastValue] = useState(values.closable);
  const checked = !!values.closable;
  const hasCapability = addonsCapabilities[ADDONS_CAPABILITIES.CLOSABLE_OPTIONS];

  useEffect(() => {
    // change default value initially if has support for new options
    if (values.closable === true && hasCapability) {
      setFieldValue('closable', CLOSE_OPTIONS.DAYS_1);
      setLastValue(CLOSE_OPTIONS.DAYS_1);
    }
  }, [values.closable, hasCapability, setFieldValue]);

  return (
    <div className="-ml-1.25 flex min-h-[35px] items-center">
      <div className="shrink-0">
        <Checkbox
          label={label}
          id="closable"
          name="closable"
          checked={checked}
          onChange={(e) => {
            if (hasCapability) {
              if (checked) {
                setFieldValue('closable', false);
              } else {
                setFieldValue('closable', lastValue || CLOSE_OPTIONS.DAYS_1);
                if (!lastValue) {
                  setLastValue(CLOSE_OPTIONS.DAYS_1);
                }
              }
            } else {
              handleChange(e);
            }
          }}
          disabled={readonly}
        />
      </div>
      {values.closable !== false && hasCapability ? (
        <>
          <span className="text-body-2 ml-5 mr-2 shrink-0">Show it again: </span>
          <div className="w-96">
            <Select
              aria-label="close options"
              options={options}
              value={lastValue}
              onChange={(value) => {
                setFieldValue('closable', value);
                setLastValue(value);
              }}
              disabled={readonly}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
