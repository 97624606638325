export const PROVISIONING_ACTION_TYPES = {
  CREATE: 'CREATE',
  EXISTING: 'EXISTING',
};

export const PROVISIONING_CONDITION_TYPES = {
  ANYONE: 'ANYONE',
  ATTRIBUTE: 'ATTRIBUTE',
  EMAIL: 'EMAIL',
};
