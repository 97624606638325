import { useLocation, Navigate } from 'react-router-dom';

import { ErrorReport } from '/src/features/errors/components/report/ErrorReport';

import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function ErrorReportPage() {
  const location = useLocation();
  const permissions = usePermissions();
  const canViewReport = permissions.can('view', FEATURES.ERRORS_TRACKING).allowed;

  if (!canViewReport) {
    return <Navigate to="/errors" replace={true} />;
  }

  return <ErrorReport key={location.state?.reMount ? location.key : null} />;
}
