import { useState } from 'react';
import classNames from 'classnames';
import Honeybadger from '@honeybadger-io/js';
import { Spinner, Button } from '@crazyegginc/hatch';

import { useNavBarDimensions, useSelection } from '/src/hooks';
import { RecordingsBulkActions } from './RecordingsBulkActions';
import { SnapshotsBulkActions } from './SnapshotsBulkActions';
import { AccountUsersBulkActions } from './AccountUsersBulkActions';
import { SharedUrlsBulkActions } from './SharedUrlsBulkActions';
import { SurveyResponsesBulkActions } from './SurveyResponsesBulkActions';
import { inflect } from '/src/utils/string';
import { BULK_ACTIONABLE_ITEMS } from '/src/features/_global/constants';

import { ReactComponent as SuccessIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

const minimumWorkingTime = 1200;
const successBannerWithDefaultMessageTimeout = 1500;
const successBannerWithCustomMessageTimeout = 5500;
const MAX_BULK_ITEMS = 250;

const { RECORDINGS, ACCOUNT_USERS, SHARED_URLS, SURVEY_RESPONSES, SNAPSHOTS } = BULK_ACTIONABLE_ITEMS;

export function BulkAction({ feature, queryParams, filter, sites, setPollExports, siteList, survey, ...props }) {
  const {
    currentSelection,
    getSelectedIds,
    clearSelection,
    limitSelection,
    updateSelectionInfoInAllRecordingsGroups,
    getSelectedEntities,
  } = useSelection();
  const [state, setState] = useState({ working: false, success: false, error: false });

  function getFeatureText(feature) {
    switch (feature) {
      case RECORDINGS:
        return 'recording';
      case ACCOUNT_USERS:
        return 'team member';
      case SHARED_URLS:
        return 'share link';
      case SURVEY_RESPONSES:
        return 'response';
      case SNAPSHOTS:
        return 'snapshot';
    }
  }

  function renderBulkActionIcons(feature) {
    switch (feature) {
      case RECORDINGS:
        return (
          <RecordingsBulkActions
            onActionStart={onActionStart}
            onSuccess={onSuccess}
            onError={onError}
            selectedIds={selectedIds}
          />
        );
      case ACCOUNT_USERS:
        return (
          <AccountUsersBulkActions
            onActionStart={onActionStart}
            onSuccess={onSuccess}
            onError={onError}
            selectedIds={selectedIds}
            selected={getSelectedEntities()}
            siteList={siteList}
          />
        );
      case SHARED_URLS:
        return (
          <SharedUrlsBulkActions
            onActionStart={onActionStart}
            onSuccess={onSuccess}
            onError={onError}
            selectedIds={selectedIds}
            selected={getSelectedEntities()}
          />
        );
      case SURVEY_RESPONSES:
        return (
          <SurveyResponsesBulkActions
            onActionStart={onActionStart}
            onSuccess={onSuccess}
            onError={onError}
            selectedIds={selectedIds}
            currentSelection={currentSelection}
            survey={survey}
          />
        );
      case SNAPSHOTS:
        return (
          <SnapshotsBulkActions
            onActionStart={onActionStart}
            onSuccess={onSuccess}
            onError={onError}
            currentSelection={currentSelection}
            selectedIds={selectedIds}
            filter={filter}
            sites={sites}
            setPollExports={setPollExports}
            {...props}
          />
        );
    }
  }

  const selectText = getFeatureText(feature);

  const selectedIds = getSelectedIds();
  const selectedNum = selectedIds.length;

  if (!selectedNum && !state.working && !state.success && !state.error) {
    return null;
  }

  function onActionStart() {
    setState((x) => ({ ...x, working: true, start: performance.now() }));
  }

  function onSuccess(options = {}) {
    const end = performance.now();
    setTimeout(
      () => {
        setState((x) => ({ ...x, working: false, success: true, message: options.message }));
        if (options.deselectAll) {
          clearSelection();
        }
        if (options.updateRecordingsGroups) {
          updateSelectionInfoInAllRecordingsGroups(queryParams);
        }
        setTimeout(
          () => {
            setState((x) => ({ ...x, success: false, message: null }));
          },
          options.message ? successBannerWithCustomMessageTimeout : successBannerWithDefaultMessageTimeout,
        );
      },
      Math.max(minimumWorkingTime - (end - state.start), 0),
    );
  }

  function onError(options = {}) {
    const end = performance.now();
    setTimeout(
      () => {
        setState((x) => ({ ...x, working: false, error: true, message: options.message }));
        clearSelection();
        setTimeout(() => {
          setState((x) => ({ ...x, error: false, message: null }));
        }, 5000);
        if (options.error) {
          Honeybadger.notify('Bulk action failed', {
            context: { error: options.error },
            name: options.name ?? 'Bulk action failed',
          });
        }
      },
      Math.max(minimumWorkingTime - (end - state.start), 0),
    );
  }

  return (
    <>
      <Bar animate>
        <div className="flex grow text-white brightness-90">
          {selectedNum.toLocaleString()} {inflect(selectText, selectedNum)} selected
        </div>
        {selectedNum > MAX_BULK_ITEMS ? (
          <div className="flex shrink-0 grow items-center justify-center">
            <div className="relative mr-2.5 h-5 w-5 rounded-full bg-white">
              <ErrorIcon className="absolute -left-0.5 -top-0.5 h-6 w-6 fill-current text-carnation-500" />
            </div>
            <span className="mr-4">You can only perform bulk actions on {MAX_BULK_ITEMS} items at a time.</span>
            <Button variant="primary" size="sm" onClick={() => limitSelection(MAX_BULK_ITEMS)}>
              Select the first {MAX_BULK_ITEMS}
            </Button>
          </div>
        ) : (
          <div className="flex grow justify-center">{renderBulkActionIcons(feature)}</div>
        )}
        <div className="flex grow justify-end">
          <button
            className="flex items-center px-2.5 text-white brightness-90 hover:brightness-100 focus-visible:outline-white"
            type="button"
            onClick={clearSelection}
          >
            Deselect all
            <CloseIcon className="ml-3 h-3 w-3 fill-current" />
          </button>
        </div>
      </Bar>
      {state.working && (
        <Bar>
          <div className="flex items-center">
            <Spinner className="mr-4 h-4 w-4 text-cadet-blue-500" />
            Working on it...
          </div>
        </Bar>
      )}
      {state.success && (
        <Bar data-testid="action_success_response">
          <div className="flex items-center">
            <div className="relative mr-4 h-5 w-5 rounded-full bg-white">
              <SuccessIcon className="absolute -left-0.5 -top-0.5 h-6 w-6 fill-current text-lima-500" />
            </div>
            <div>
              <span className="font-semibold">All done!</span>
              <br />
              {state.message ?? 'Updated successfully.'}
            </div>
          </div>
        </Bar>
      )}
      {state.error && (
        <Bar data-testid="action_error_response">
          <div className="flex items-center">
            <div className="relative mr-4 h-5 w-5 rounded-full bg-white">
              <ErrorIcon className="absolute -left-0.5 -top-0.5 h-6 w-6 fill-current text-carnation-500" />
            </div>
            <div>
              <span className="font-semibold">Hmm, we’re having a little trouble here…</span>
              <br />
              {state.message ?? 'Please refresh the page and try again.'}
            </div>
          </div>
        </Bar>
      )}
    </>
  );
}

function Bar({ children, animate, ...props }) {
  const { navBarWidth } = useNavBarDimensions();

  return (
    <div
      {...props}
      style={{ left: `${navBarWidth}px` }}
      className={classNames(
        'fixed left-[200px] right-0 z-10 flex h-[70px] items-center justify-between space-x-3 bg-regal-blue-700 px-[60px] text-sm text-mystic-500',
        {
          'bottom-[-80px] animate-slideup': animate,
          'bottom-0': !animate,
        },
      )}
    >
      {children}
    </div>
  );
}
