import { useState, useEffect } from 'react';
import { Tooltip } from '@crazyegginc/hatch';

import { usePermissions, useMutation, useSelection, useNotifications } from '/src/hooks';
import { surveyResponseDeleteMutation } from '/src/features/addons/mutations';

import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';

export function TableActionsCell({ response, survey }) {
  const { removeFromSelection } = useSelection();
  const [state, setState] = useState({ clicked: false, inProgress: false });
  const deleteResponse = useMutation(surveyResponseDeleteMutation);
  const notifications = useNotifications();
  const permissions = usePermissions();

  const canDelete = permissions.can('delete', survey).allowed;

  useEffect(() => {
    setState({ clicked: false, inProgress: false });
  }, [response]);

  return (
    <>
      {!state.clicked && !state.inProgress && (
        <div className="invisible flex w-full items-center gap-2.5 px-3.75 leading-[0] group-hover:visible">
          {canDelete ? (
            <Tooltip tooltipContent="Delete">
              <button
                type="button"
                className="focus:outline-none"
                onClick={() => {
                  setState((x) => ({ ...x, clicked: true }));
                }}
              >
                <DeleteIcon aria-label="delete response" className="h-4 w-4 fill-current text-cadet-blue-500" />
              </button>
            </Tooltip>
          ) : (
            <div />
          )}
        </div>
      )}
      {state.clicked && (
        <button
          type="button"
          className="relative z-[1] flex h-[65px] w-[65px] flex-col items-center justify-center bg-carnation-500 text-xs text-white focus:outline-none"
          onMouseLeave={() => {
            setState((x) => ({ ...x, clicked: false }));
          }}
          onClick={() => {
            setState({ clicked: false, inProgress: true });

            setTimeout(() => {
              deleteResponse.mutate(
                {
                  surveyId: survey.id,
                  responseGroupIds: [response.id],
                },
                {
                  onError: (error) => {
                    setState((x) => ({ ...x, inProgress: false }));
                    notifications.error({
                      title: 'Error',
                      content: 'Failed to delete Survey response!',
                      context: { error },
                    });
                  },
                  onSuccess: () => removeFromSelection([{ id: response.id }]),
                },
              );
            }, 300);
          }}
        >
          <DeleteIcon aria-label="confirm delete" className="h-4 w-4 fill-current text-white" />
          Delete?
        </button>
      )}
      {state.inProgress && (
        <div className="relative z-[1] flex h-[65px] w-[65px] flex-col items-center justify-center bg-carnation-500 text-xs text-white">
          <TickIcon className="h-4 w-4 fill-current text-white" />
          Deleting
        </div>
      )}
    </>
  );
}
