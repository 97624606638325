import { gql } from '@urql/core';

import { goalsFeatureKeys } from './queries';
import { abTestsFeatureKeys } from '/src/features/ab-testing/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';

export const goalCreateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ params }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation GoalCreate($params: GoalCreateParams!) {
          goalCreate(params: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  onSuccess: () => {
    window.Metrics?.used('Goal: Created');
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalListMinimal._def });
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const goalEditMutation = ({ client, queryClient }) => ({
  mutationFn: ({ params }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation GoalEdit($params: GoalEditParams!) {
          goalEdit(params: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalListMinimal._def });
    queryClient.invalidateQueries({
      predicate: (query) => {
        if (query.queryKey.includes('goalDetail') && query.queryKey.at(-1)?.id === data.goalEdit.id) {
          return true;
        }
        return false;
      },
    });
  },
});

export const goalDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation GoalDelete($id: Int!) {
          goalDelete(id: $id) {
            id
          }
        }
      `,
      { id },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalListMinimal._def });
    queryClient.invalidateQueries({ ...goalsFeatureKeys.goalDetail({ id: data.goalDelete.id }), refetchType: 'none' });
    queryClient.invalidateQueries({ ...goalsFeatureKeys.goalCounts({ id: data.goalDelete.id }), refetchType: 'none' });
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
  },
});
