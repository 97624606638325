import RocketPNG from '@crazyegginc/hatch/dist/images/illustration-rocket.png';
import RocketWebP from '@crazyegginc/hatch/dist/images/illustration-rocket.webp';

import { Paywall } from '/src/components/Paywall';

export function NoRecordingsWall() {
  return (
    <Paywall>
      <Paywall.Image webp={RocketWebP} png={RocketPNG} width="176px" height="189px" />
      <Paywall.Title>We have lift off!</Paywall.Title>

      <Paywall.Body>
        You&#39;ve turned on Crazy Egg recordings, but you don&#39;t have any ready to watch yet.
        <br />
        As recordings are collected they&#39;ll appear here.
      </Paywall.Body>
    </Paywall>
  );
}
