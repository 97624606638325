import { useSite } from '/src/hooks';
import { SiteSettingsTable } from '../components/SiteSettingsTable';
import { FirstSiteWall } from '../paywalls/FirstSiteWall';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsSiteSettings() {
  const { sites, loadingSites, selectedSite, resetSite } = useSite({
    dontSelectSite: true,
  });

  if (sites.length === 0 && !loadingSites) {
    return <FirstSiteWall />;
  }

  return (
    <SiteSettingsTable sites={sites} loadingSites={loadingSites} selectedSite={selectedSite} resetSite={resetSite} />
  );
}
