import { Button, Divider } from '@crazyegginc/hatch';

import { CodeBox } from '/src/components/CodeBox';
import { useWizard } from '/src/hooks';
import { WizardList } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

export function NameTrackingModal({ onSuccess, formik }) {
  const wizard = useWizard();

  const snapshot = formik.values.snapshots[0];
  const code = snapshot.name
    ? `&lt;!-- Track a specific Crazy Egg snapshot by name --&gt;
&lt;script type=&quot;text/javascript&quot;&gt;
&nbsp;&nbsp;&nbsp;&nbsp;var CE_SNAPSHOT_NAME = &quot;${snapshot.name}&quot;;
&lt;/script&gt;`
    : null;

  function save() {
    if (!snapshot.name) return;
    formik.setFieldValue('trackingOptions.named', { named: snapshot.name }, true);

    wizard.closeModal();
    onSuccess?.();
  }

  return (
    <>
      <h1 className="text-header-1 mb-8 mt-0">Set-up Track by Name Tracking</h1>

      <Divider className="my-2.5" />

      <div className="text-body-2 mt-3.5">
        Track by Name lets you track the same URL with multiple snapshots. It is useful for running A/B tests, tracking
        the different steps of a multiple sign-up process, or just about any situation where you want to track pages
        that have different content but the same address.
        <br />
        <a target="_blank" rel="noopener noreferrer" href={SupportLinks.snapshots.trackByName} className="text-link">
          Learn more about Track by Name
        </a>
      </div>

      <div className="text-body-1 mb-2.5 mt-3.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>Copy the Track by Name code.</WizardList.Item>
        <WizardList.Item>
          Place the Track by Name code on the page before the standard Crazy Egg tracking script.
        </WizardList.Item>
      </WizardList>

      {code && <CodeBox code={code} />}

      <Divider className="my-2.5" />

      <div className="mt-5 flex space-x-2.5">
        <Button variant="secondary" onClick={save}>
          Save &amp; Continue
        </Button>
        <Button variant="cancel" onClick={wizard.closeModal}>
          Cancel
        </Button>
      </div>
    </>
  );
}
