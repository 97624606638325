import { Link } from 'react-router-dom';
import { Button, Select } from '@crazyegginc/hatch';

import { useAuthContext, useModal, useMetric, useHasFeatureAccess } from '/src/hooks';
import { addCETag, snapshotAddonListDetails } from '../utils';

import { SnapshotAddonModal } from '../components/modals/SnapshotAddonModal';
import { SectionHeader } from '../components/accountUI';

import { MANAGE_SUBSCRIPTION_PATH, SUBSCRIPTION_STATES, PLAN_TYPES, FEATURES } from '/src/features/_global/constants';
import {
  SNAPSHOT_ADDON_50,
  SNAPSHOT_ADDON_100,
  SNAPSHOT_ADDON_200,
  SNAPSHOT_ADDON_UNLIMITED,
} from '/src/features/account/constants';

const snapshotAddonList = [SNAPSHOT_ADDON_50, SNAPSHOT_ADDON_100, SNAPSHOT_ADDON_200, SNAPSHOT_ADDON_UNLIMITED];
const { CANCELING, CANCELED, EXPIRING, EXPIRED } = SUBSCRIPTION_STATES;

export function PlanAddons({ snapshotAddon, setSnapshotAddon }) {
  const modal = useModal();
  const trackMetric = useMetric();
  const { subscription, currentUser } = useAuthContext();
  const isTrial = subscription.plan.type === PLAN_TYPES.TRIAL;

  const isInGrace = [CANCELING, EXPIRING].includes(subscription.state) && !isTrial;
  const isClosed = [CANCELED, EXPIRED].includes(subscription.state);

  if (!useHasFeatureAccess(FEATURES.SNAPSHOTS) || isClosed || isInGrace) {
    return null;
  }

  return (
    <>
      <SectionHeader className="mt-10">Plan Add-Ons</SectionHeader>
      <div className="flex w-full space-x-5">
        <section className="flex w-1/2 justify-between rounded-md bg-white-lilac-500 px-[35px] py-[30px]">
          <div className="flex flex-col">
            <h4 className="text-header-5 mb-2">Add Snapshots to your plan:</h4>
            {<SnapshotAddOnOptions snapshotAddon={snapshotAddon} setSnapshotAddon={setSnapshotAddon} />}

            <Button
              disabled={!snapshotAddon.type}
              size="sm"
              onClick={() => {
                addCETag('snapshots_addon');
                modal.show(<SnapshotAddonModal planDetail={snapshotAddon} email={currentUser.email} />);
              }}
              className="mt-auto self-start"
            >
              Add to plan
            </Button>
          </div>
          <div className="w-[140px]">
            <div className="mb-0.5 flex w-full justify-end">
              <span className=" self-start text-md">$</span>
              <span className="self-start text-[32px] leading-none">{snapshotAddon.costPerMonth}</span>
              <span className="ml-0.5 self-end text-base"> / month</span>
            </div>
            <p className="w-full pr-5 text-right text-xs text-lynch-500">
              ${(snapshotAddon.costPerMonth * 12).toLocaleString()} / year
            </p>
          </div>
        </section>
        <section className="relative flex w-1/2 flex-col justify-center rounded-md bg-riptide-500 px-[35px] py-[35px]">
          <h4 className="text-header-5 mb-3">Need more Pageviews or Recordings?</h4>
          <p className="text-body-2 mb-6">Upgrade your plan to get more Pageviews and Recordings each month.</p>
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="flex items-center justify-center self-start rounded bg-black-pearl-500 px-3 py-2 text-xs font-semibold leading-none text-white"
            onClick={() => {
              trackMetric('upgrade_cta_click', 'plan_more_pageviews_recordings');
              addCETag('pv_rec_addon');
            }}
          >
            View and compare plans
          </Link>
        </section>
      </div>
    </>
  );
}

function SnapshotAddOnOptions({ snapshotAddon, setSnapshotAddon }) {
  return (
    <Select
      name="planAddOn"
      options={snapshotAddonList.map((addon) => {
        const addonDetail = snapshotAddonListDetails(addon);
        const isAddonUnlimited = addon === SNAPSHOT_ADDON_UNLIMITED;

        return {
          value: addon,
          label: `${isAddonUnlimited ? 'Unlimited' : addonDetail.snapshots} ${
            !isAddonUnlimited ? 'extra' : ''
          } Snapshots`,
          disabled: snapshotAddon.type === addon,
        };
      })}
      placeholder={`${snapshotAddon.type === SNAPSHOT_ADDON_UNLIMITED ? 'Unlimited' : snapshotAddon.snapshots} ${
        !snapshotAddon.type !== SNAPSHOT_ADDON_UNLIMITED ? 'extra' : ''
      } Snapshots`}
      onChange={async (value) => {
        const addonDetail = snapshotAddonListDetails(value);

        await setSnapshotAddon({
          costPerMonth: addonDetail.costPerMonth,
          snapshots: addonDetail.snapshots,
          type: addonDetail.type ? value : false,
        });
      }}
      style={{
        background: '#e5f2fc',
        borderColor: '#e5f2fc',
        cursor: 'pointer',
        color: '#0086e6',
        fontWeight: 'bold',
      }}
    />
  );
}
