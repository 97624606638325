import { useMemo } from 'react';
import { Select } from '@crazyegginc/hatch';

import { CURRENCIES } from '/src/features/_global/constants';

const popularCurrencies = [...Object.values(CURRENCIES)];

export function CurrencySelector({ value, onSelect = null, unitOnly = false, selectFirst = false, ...rest }) {
  const currenciesOptions = useMemo(() => {
    const currencyNames = new Intl.DisplayNames(['en'], { type: 'currency' });
    return [
      ...popularCurrencies.map((currency) => ({
        value: currency,
        label: `${currency}${!unitOnly ? ` | ${currencyNames.of(currency)}` : ''}`,
      })),
      { value: '', label: '----------------------', disabled: true },
      ...Intl.supportedValuesOf('currency')
        .map((currency) => {
          if (popularCurrencies.includes(currency)) {
            return null;
          }
          return {
            value: currency,
            label: `${currency}${!unitOnly ? `| ${currencyNames.of(currency)}` : ''}`,
          };
        })
        .filter(Boolean),
    ];
  }, [unitOnly]);

  return (
    <Select
      options={currenciesOptions}
      value={selectFirst && !value ? currenciesOptions[0].value : value}
      placeholder="Select a currency"
      onChange={(value) => {
        onSelect(value);
      }}
      {...rest}
    />
  );
}
