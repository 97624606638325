import { useState, useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Button, CloseButton, Popover, TextArea, Spinner, StyledPopoverPanel, capitalize } from '@crazyegginc/hatch';

import { useMutation, useSelectedSite, useNotifications, usePermissions } from '/src/hooks';
import { ERROR_STATUSES } from '/src/features/errors/constants';
import { FEATURES } from '/src/features/_global/constants';
import { updateErrorMutation } from '/src/features/errors/mutations';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as DashIcon } from '@crazyegginc/hatch/dist/images/icon-dash-circle-filled.svg';

export function StatusSelector({ status, fingerprintMd5 }) {
  const buttonRef = useRef();
  const { selectedSite } = useSelectedSite();
  const notifications = useNotifications();
  const permissions = usePermissions();
  const [referenceElement, setReferenceElement] = useState(null);
  const [boxElement, setBoxElement] = useState(null);
  const canChangeStatus = permissions.can('edit', FEATURES.ERRORS_TRACKING).allowed;
  const doUpdateError = useMutation(updateErrorMutation);

  const statusButtons = [
    {
      status: ERROR_STATUSES.UNRESOLVED,
      text: capitalize(ERROR_STATUSES.UNRESOLVED),
      inProgressText: 'Unresolving',
      actionText: 'Unresolve',
      color: 'text-radical-red-500',
    },
    {
      status: ERROR_STATUSES.RESOLVED,
      text: capitalize(ERROR_STATUSES.RESOLVED),
      inProgressText: 'Resolving',
      actionText: 'Resolve',
      color: 'text-lima-500',
    },
    {
      status: ERROR_STATUSES.MUTED,
      text: capitalize(ERROR_STATUSES.MUTED),
      inProgressText: 'Muting',
      actionText: 'Mute',
      color: 'text-dandelion-500',
    },
  ];

  function ButtonIcon(status, buttonColor, isActive, canChangeStatus) {
    let Icon, hoverColor;
    switch (status) {
      case ERROR_STATUSES.UNRESOLVED:
        Icon = WarningIcon;
        hoverColor = 'group-hover:!text-radical-red-500';
        break;
      case ERROR_STATUSES.RESOLVED:
        Icon = TickIcon;
        hoverColor = 'group-hover:!text-lima-500';
        break;
      case ERROR_STATUSES.MUTED:
        Icon = DashIcon;
        hoverColor = 'group-hover:!text-dandelion-500';
        break;
      default:
        return null;
    }

    return (
      <Icon
        className={classNames(`mb-0.5 mr-2 h-4 w-4 fill-current`, {
          [`${buttonColor}`]: isActive,
          [`${hoverColor}`]: canChangeStatus,
        })}
      />
    );
  }

  const { styles, attributes } = usePopper(referenceElement, boxElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-reference.width + popper.width - 10, 8];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover.Group className="flex space-x-1 rounded-md bg-mystic-500 p-1.25" ref={setReferenceElement}>
      {statusButtons.map((button, index) => {
        return (
          <div key={`${button.text}+${index}`}>
            <Popover className="relative">
              {({ open }) => {
                const isActive = button.status === status || open;
                return (
                  <>
                    <Popover.Button
                      ref={buttonRef}
                      as={Button}
                      disabled={!canChangeStatus}
                      variant="tertiary"
                      className={classNames('group', {
                        ' !bg-white text-black-pearl-500 !shadow-md': button.status === status || open,
                        'pointer-events-none': open || button.status === status,
                      })}
                    >
                      {ButtonIcon(button.status, button.color, isActive, canChangeStatus)}
                      {button.text}
                    </Popover.Button>

                    {open && (
                      <StyledPopoverPanel
                        ref={setBoxElement}
                        onMouseDown={(e) => e.stopPropagation()}
                        align="left"
                        className="w-[360px] !p-5"
                        style={{ ...styles.popper }}
                        {...attributes.popper}
                      >
                        {({ close }) => {
                          return (
                            <div>
                              <CloseButton label="close" onClick={() => close()} />
                              <h3 className="text-header-3 mb-6">Change status to {button.text}</h3>
                              <Formik
                                initialValues={{ note: '' }}
                                onSubmit={(values, actions) => {
                                  doUpdateError.mutate(
                                    {
                                      siteId: selectedSite?.id,
                                      status: button.status,
                                      note: values.note,
                                      fingerprintMd5,
                                    },
                                    {
                                      onError: (error) =>
                                        notifications.error({
                                          content: 'Failed to update error status.',
                                          timeout: 3000,
                                          context: { error },
                                        }),
                                      onSuccess: () => close(),
                                      onSettled: () => actions.setSubmitting(false),
                                    },
                                  );
                                }}
                              >
                                {({ values, handleChange, handleBlur, isSubmitting }) => (
                                  <Form>
                                    <TextArea
                                      name="note"
                                      label="Add note (optional):"
                                      id="status_note"
                                      placeholder="Type here..."
                                      value={values.note}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      autoFocus // eslint-disable-line
                                      rows={4}
                                      className="mb-5"
                                    />

                                    <Button type="submit" disabled={isSubmitting}>
                                      {isSubmitting ? (
                                        <div className="flex items-center">
                                          <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                                          {button.inProgressText} error...
                                        </div>
                                      ) : (
                                        `${button.actionText} error`
                                      )}
                                    </Button>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          );
                        }}
                      </StyledPopoverPanel>
                    )}
                  </>
                );
              }}
            </Popover>
          </div>
        );
      })}
    </Popover.Group>
  );
}
