import { FieldArray, getIn, useFormikContext } from 'formik';
import { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, CloseButton, Tooltip } from '@crazyegginc/hatch';

import { ProvisioningActionRow } from './ProvisioningActionRow';
import { RenderConditionRow } from './RenderConditionRow';
import { emptyCondition } from '../../pages/sso';

export function RenderRuleBlock({ id, rule, ruleIndex, removeRule, moveRule }) {
  const { touched, errors, setFieldTouched } = useFormikContext();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'ruleBlock',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = ruleIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRule(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const canDrag = useCallback(() => {
    return true;
  }, []);

  const [{ isDragging }, drag] = useDrag({
    type: 'ruleBlock',
    item: () => {
      return { id, index: ruleIndex };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag,
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const ruleErrors = getIn(errors, `provisioningRules.[${ruleIndex}]`);
  const touchedCondition = getIn(touched, `provisioningRules.[${ruleIndex}]`);
  const conditionsError =
    typeof ruleErrors?.conditions === 'string' && touchedCondition ? ruleErrors.conditions : false;

  return (
    <div
      ref={ref}
      className="relative mb-4 flex rounded bg-white-lilac-500 p-4"
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className="font-lg flex h-10 w-10 flex-shrink-0 items-center justify-center self-start rounded-full bg-dodger-blue-500 p-2 font-bold text-white">
        {ruleIndex + 1}
      </div>

      <div className="absolute right-5 top-5 -my-0.5 -mr-0.5 h-4 w-4">
        <Tooltip tooltipContent="Remove this provisioning rule" placement="left">
          <CloseButton
            buttonClass="!right-0 !top-0"
            label="remove provisioning rule"
            onClick={() => removeRule(ruleIndex)}
          />
        </Tooltip>
      </div>

      <div className="flex flex-col gap-y-8 pl-12">
        <FieldArray name={`provisioningRules.[${ruleIndex}].conditions`}>
          {({ push: pushCondition, remove: removeCondition }) => (
            <div className="flex flex-col">
              {rule.conditions.map((condition, conditionIndex) => (
                <RenderConditionRow
                  ruleIndex={ruleIndex}
                  conditionIndex={conditionIndex}
                  condition={condition}
                  action={rule.action}
                  conditionKey={`provisioningRules.[${ruleIndex}].conditions.[${conditionIndex}]`}
                  key={`rule-${ruleIndex}-condition-${conditionIndex}`}
                  onRemove={() => removeCondition(conditionIndex)}
                >
                  Condition type: {condition.type}
                </RenderConditionRow>
              ))}
              <div className="flex items-center gap-x-1.5 self-start">
                <Button
                  className="self-start"
                  onClick={() => {
                    pushCondition({ ...emptyCondition });
                    setFieldTouched(`provisioningRules.[${ruleIndex}].conditions.[${rule.conditions.length}]`, true);
                  }}
                >
                  Add another condition
                </Button>
                {conditionsError ? <span className="text-body-2 text-carnation-500">{conditionsError}</span> : null}
              </div>
            </div>
          )}
        </FieldArray>

        <ProvisioningActionRow index={ruleIndex} />
      </div>
    </div>
  );
}
