import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';

import { FEATURES, PLAN_TYPES } from '/src/features/_global/constants';

import { ReactComponent as TeamIcon } from '@crazyegginc/hatch/dist/images/icon-team-outline.svg';

export function SharedAccountRow({ account, onClick = null }) {
  const { currentUser, currentAccount, subscription } = useAuthContext();

  const permissions = usePermissions();

  if (!account) {
    return null;
  }

  const sites = account?.sites ?? [];

  const hasSites = sites.length > 0;
  const displaySites = sites.slice(0, 2);
  const hasMoreSites = sites.length > 2;
  const remainingSites = sites.length - 2;

  const canVisitTeamAndSharing = permissions.can('navigate', FEATURES.TEAM_AND_SHARING).allowed;
  const canManageAccount = permissions.can('navigate', FEATURES.BILLING).allowed;
  const canVisitBilling =
    permissions.can('navigate', FEATURES.BILLING).allowed && subscription.plan.type !== PLAN_TYPES.FREEMIUM;

  const isActiveAccount = account.isCurrent;

  return (
    <button
      onClick={() => {
        onClick?.();
      }}
      className={classNames('box-border flex min-h-[58px] w-full items-center p-3 outline-none', {
        'border-t border-dashed border-mystic-500': currentUser.accounts.length > 1,
        'bg-zumthor-500': account.id === currentAccount.id,
        'hover:bg-alice-blue-500': account.id !== currentAccount.id,
      })}
    >
      <div>
        <Tooltip
          placement="bottom"
          tooltipContent={
            <div className="flex flex-col items-start">
              <span className="font-sm text-white">Shared with you by</span>
              <a href={`mailto:${account.ownerEmail}`}>{account.ownerEmail}</a>
            </div>
          }
        >
          {account.ownerAvatarUrl ? (
            <img
              className="h-[30px] w-[30px] rounded-full fill-current text-dodger-blue-300"
              src={account.ownerAvatarUrl}
              alt="Shared account avatar"
            />
          ) : (
            <TeamIcon className="h-[30px] w-[30px] fill-current text-dodger-blue-300" alt="Shared account avatar" />
          )}
        </Tooltip>
      </div>
      <div className="ml-2 flex flex-col items-start">
        <div className="flex flex-col items-start">
          {hasSites ? (
            <>
              {displaySites.map((site, idx) => (
                <span className="text-body-4 text-black-pearl-500" key={`Account-${account.id}_Site${idx}`}>
                  {site}
                </span>
              ))}
              {hasMoreSites && <span className="text-body-4 italic text-[#7786A0]">&amp; {remainingSites} more</span>}
            </>
          ) : (
            <span className="text-body-4">{account.name || account.ownerName}</span>
          )}
        </div>

        {isActiveAccount && (
          <div className="flex flex-col items-start">
            <Link to="/account/profile" className="text-link mb-0.5 text-xs">
              {canManageAccount ? 'Manage account' : 'View profile'}
            </Link>
            {canVisitTeamAndSharing ? (
              <Link to="/account/team" className="text-link mb-0.5">
                Manage team
              </Link>
            ) : null}
            {canVisitBilling ? (
              <Link to="/account/billing" className="text-link">
                Billing details
              </Link>
            ) : null}
          </div>
        )}
      </div>
    </button>
  );
}
