import { Button } from '@crazyegginc/hatch';

export const PlayerPaywalls = {
  GeneralError: 'PlayerGeneralError',
  RecordingNotFound: 'PlayerRecordingNotFound',
  NoRecordings: 'PlayerNoRecordings',
};

export function PlayerWall({
  title,
  primaryComponent,
  primaryAction,
  primaryProps,
  primaryText,
  secondaryComponent,
  secondaryAction,
  secondaryProps,
  secondaryText,
  image: Image,
  children,
}) {
  return (
    <div className="z-[11] flex h-full w-full items-center justify-center bg-mako-500">
      <div className="flex min-h-[400px] w-[640px] flex-col items-center rounded bg-woodsmoke-500 p-[30px] text-white">
        <div className="mb-[30px] mt-5">{Image ? <Image /> : null}</div>
        <h2 className="text-header-2 mb-6 text-white">{title}</h2>
        <p className="text-body-2 mb-6 px-[33px] text-center leading-5 text-white">{children}</p>
        <div className="flex flex-col items-center gap-y-1.5">
          {primaryAction || primaryComponent ? (
            <Button
              component={primaryComponent ? primaryComponent : undefined}
              onClick={primaryAction}
              {...primaryProps}
            >
              {primaryText}
            </Button>
          ) : null}
          {secondaryAction || secondaryComponent ? (
            <Button
              variant="cancel"
              component={secondaryComponent ? secondaryComponent : undefined}
              onClick={secondaryAction}
              {...secondaryProps}
              className="flex justify-center !text-cadet-blue-500 hover:!bg-transparent"
            >
              {secondaryText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const walls = import.meta.glob(`./walls/*.jsx`, { eager: true });

export function RenderPlayerWall({ actorRef, wall, navigateToAnotherRecording = null }) {
  const WallComponent = walls[`./walls/${wall}.jsx`];

  return <WallComponent.default actorRef={actorRef} navigateToAnotherRecording={navigateToAnotherRecording} />;
}
