import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

export function ConfirmLeaveWizardModal({ onConfirm = null }) {
  const modal = useModal();

  return (
    <Modal>
      <Modal.Title>Are you sure?</Modal.Title>

      <div className="text-body-2">
        If you cancel and return to the dashboard, your changes will be lost. Do you want to return to the dashboard
        anyway?
      </div>

      <Modal.Actions>
        <Button
          variant="warning"
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Yes, cancel and return to dashboard
        </Button>
        <Button variant="cancel" onClick={modal.close}>
          Actually, I want to stay
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
