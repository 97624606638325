import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Popover, StyledPopoverPanel, PopoverItem, Button, Indicator, Divider, Tooltip } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';
import { getSurveyQueryParams } from '/src/utils/url';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';
import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';

export function NewAddonButton({ showTemplates = true, size = 'xl', className, disableIfNoPermission = false, type }) {
  const permissions = usePermissions();
  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;

  const canCreate = (canCreateSurvey && type === FEATURES.SURVEYS) || (canCreateCTA && type === FEATURES.CTAS);
  const disabled = !canCreate && disableIfNoPermission;

  if (!canCreate && !disableIfNoPermission) {
    return null;
  }

  const typeText = (type ? getFeatureName(type) : getFeatureName(FEATURES.ADDONS)).replace(/s$/, '');

  if (!showTemplates && type) {
    return (
      <Button
        component={Link}
        to={{ pathname: `/addons/${type.toLowerCase()}/new` }}
        size={size}
        className={classNames('leading-none', className)}
        disabled={disabled}
      >
        <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
        Create New {typeText}
      </Button>
    );
  }

  return (
    <Tooltip tooltipContent={`You don't have permission to create a ${typeText}.`} show={disabled}>
      <Popover className="relative">
        <>
          <Popover.Button as={Button} size={size} className={classNames('leading-none', className)} disabled={disabled}>
            <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
            Create New {typeText}
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>
          <StyledPopoverPanel align="center" className="!w-52">
            <>
              <>
                <PopoverItem
                  component={Link}
                  to={{ pathname: `/addons/templates`, search: getSurveyQueryParams({ type: type }) }}
                  className="group flex items-center"
                >
                  <DuplicateIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                  Browse templates
                </PopoverItem>
                <Divider className="-mx-4 my-2 !w-auto" />
                <div className="text-caption mb-1.25 ml-2.5 font-semibold">Create from scratch:</div>
              </>
              {canCreate && type === FEATURES.SURVEYS && (
                <PopoverItem
                  component={Link}
                  to={{ pathname: '/addons/surveys/new', search: getSurveyQueryParams() }}
                  className="group flex items-center"
                >
                  <SurveyIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                  Create Survey
                </PopoverItem>
              )}
              {canCreate && type === FEATURES.CTAS && (
                <PopoverItem
                  component={Link}
                  to={{ pathname: '/addons/ctas/new', search: getSurveyQueryParams() }}
                  className="group flex items-center"
                >
                  <ClickIcon className="mr-2.5 h-3.5 w-3.5 fill-current text-dodger-blue-300 group-hover:text-dodger-blue-500" />
                  Create CTA
                </PopoverItem>
              )}
            </>
          </StyledPopoverPanel>
        </>
      </Popover>
    </Tooltip>
  );
}
