import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator, capitalize } from '@crazyegginc/hatch';

import { CTA_TYPES } from '/src/features/addons/constants';

export function TemplateCTATypeSelector({ onSelect, selected, disabled }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={`flex !w-[180px] justify-between ${open ? '!border-dodger-blue-500' : ''}`}
            disabled={disabled}
          >
            {(selected && `CTAs: ${capitalize(selected)}`) || 'All CTAs'}
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="center" className="!w-[180px]">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(undefined);
                    close();
                  }}
                >
                  All CTAs
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(CTA_TYPES.BAR);
                    close();
                  }}
                >
                  Bar
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(CTA_TYPES.BUTTON);
                    close();
                  }}
                >
                  Button
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    onSelect(CTA_TYPES.POPUP);
                    close();
                  }}
                >
                  Popup
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
