import { useNotifications, useMutation } from '/src/hooks';
import {
  addonCreateMutation,
  addonEditMutation,
  addonTemplateCreateMutation,
  addonTemplateEditMutation,
} from '/src/features/addons/mutations';
import { timeoutPromise } from '/src/utils';

export function useAddonMutations() {
  const createAddonMutation = useMutation(addonCreateMutation);
  const editAddonMutation = useMutation(addonEditMutation);
  const createTemplateMutation = useMutation(addonTemplateCreateMutation);
  const editTemplateMutation = useMutation(addonTemplateEditMutation);
  const notifications = useNotifications();

  async function editTemplate(template, thumbnail) {
    let returnValue = template.id;
    try {
      await Promise.all([
        editTemplateMutation.mutateAsync(
          {
            template,
            thumbnail,
          },
          {
            onError: (error) => {
              returnValue = false;
              notifications.error({
                content: 'Failed to save template.',
                timeout: 3000,
                context: { error, template },
              });
            },
          },
        ),
        timeoutPromise(1500),
      ]);
    } catch {
      //noop
    }
    return returnValue;
  }

  async function createTemplate(template, thumbnail) {
    let returnValue;
    try {
      await Promise.all([
        createTemplateMutation.mutateAsync(
          {
            template,
            thumbnail,
          },
          {
            onError: (error) => {
              returnValue = false;
              notifications.error({
                content: 'Failed to create template.',
                timeout: 3000,
                context: { error, template },
              });
            },
            onSuccess: (data) => {
              returnValue = data.addonTemplateCreate?.id;
            },
          },
        ),
        timeoutPromise(1500),
      ]);
    } catch {
      //noop
    }
    return returnValue;
  }

  async function editAddon(addon, errorMsg = 'Failed to save add-on.') {
    let returnValue;
    try {
      await Promise.all([
        editAddonMutation.mutateAsync(
          {
            addon,
          },
          {
            onError: (error) => {
              returnValue = false;
              notifications.error({
                content: errorMsg,
                timeout: 3000,
                context: { error, addon },
              });
            },
            onSuccess: (data) => {
              const { id, previewToken, status } = data.addonEdit;
              returnValue = { id, previewToken, status };
            },
          },
        ),
        timeoutPromise(1500),
      ]);
    } catch {
      //noop
    }
    return returnValue;
  }

  async function createAddon(addon, siteId) {
    let returnValue;
    try {
      await Promise.all([
        createAddonMutation.mutateAsync(
          {
            siteId,
            addon,
          },
          {
            onError: (error) => {
              returnValue = false;
              notifications.error({
                content: 'Failed to create add-on.',
                context: { error, addon },
                timeout: 3000,
              });
            },
            onSuccess: (data) => {
              const { id, previewToken } = data.addonCreate;
              returnValue = { id, previewToken };
            },
          },
        ),
        timeoutPromise(1500),
      ]);
    } catch {
      //noop
    }
    return returnValue;
  }

  return { createAddon, editAddon, createTemplate, editTemplate };
}
