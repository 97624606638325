import { Link } from 'react-router-dom';
import { useSelector } from '@xstate5/react';

import { usePermissions } from '/src/hooks';
import { PlayerWall } from '../RenderPlayerWall';
import { WebP } from '/src/components/WebP';
import { FEATURES } from '/src/features/_global/constants';

import UfoPNG from '@crazyegginc/hatch/dist/images/illustration-ufo-empty.png';
import UfoWebP from '@crazyegginc/hatch/dist/images/illustration-ufo-empty.webp';

function PlayerRecordingNotFoundImage() {
  return <WebP webp={UfoWebP} fallback={UfoPNG} width="185px" height="160px" />;
}

// eslint-disable-next-line no-restricted-syntax
export default function PlayerRecordingNotFound({ actorRef, navigateToAnotherRecording = null }) {
  const nextRecording = useSelector(actorRef, (state) => state.context.nextRecording);
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.RECORDINGS).allowed;

  return (
    <PlayerWall
      title="Oops, recording not found!"
      primaryText="Go to dashboard"
      primaryComponent={canVisitDashboard ? Link : null}
      primaryProps={{ to: '/recordings' }}
      secondaryText="Play next recording"
      secondaryAction={
        !!nextRecording && navigateToAnotherRecording ? () => navigateToAnotherRecording(nextRecording) : undefined
      }
      image={PlayerRecordingNotFoundImage}
    >
      We couldn&#39;t find a recording for this URL
      {canVisitDashboard ? ", please visit the recordings dashboard to search for sessions you'd like to watch" : ''}.
    </PlayerWall>
  );
}

PlayerRecordingNotFound.displayName = 'PlayerRecordingNotFound';
