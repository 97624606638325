import { useEffect, useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { Button, Input, Checkbox, Divider, Spinner } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useModal } from '/src/hooks';
import { snapshotExportsQuery } from '/src/features/snapshots/queries';
import { exportSnapshotsMutation } from '/src/features/snapshots/mutations';
import { Modal } from '/src/contexts/modal';

import { SNAPSHOT_REPORT_TYPES } from '../../../constants';
const { HEATMAP, SCROLLMAP, CONFETTI, OVERLAY, LIST } = SNAPSHOT_REPORT_TYPES;

const validationSchema = yup.object().shape({
  views: yup.array().min(1, 'Please select one or more reports to export.'),
  sendEmail: yup.boolean(),
  email: yup.string().when('sendEmail', {
    is: true,
    then: yup.string().required('Please provide an email address.').email('Please provide a valid email address.'),
  }),
});

export function SnapshotsExportModal({ ids: snapshotIds, onSuccess = null, onConfirm = null }) {
  const { currentUser } = useAuthContext();

  const onSuccessRef = useRef(onSuccess);
  const onConfirmRef = useRef(onConfirm);
  const modal = useModal();

  const {
    data: exportsData,
    isFetching: fetchingExports,
    isLoading: loadingExports,
    refetch: refetchExports,
  } = useQuery({ ...snapshotExportsQuery(), enabled: false });

  const { data: exportData, isLoading, error, mutate } = useMutation(exportSnapshotsMutation);

  useEffect(() => {
    if (exportsData && !fetchingExports && !error && exportData) {
      onSuccessRef?.current?.(exportData);
      modal.close();
    }
  }, [exportData, exportsData, fetchingExports, error, modal]);

  const exportSelection = useCallback(
    (values) => {
      const params = {
        date: format(new Date(), 'yyyy-MM-dd'),
        destinationEmail: values.sendEmail ? values.email : undefined,
        snapshotIds,
        views: values.views,
      };
      if (onConfirmRef.current) {
        onConfirmRef.current(params);
      } else {
        mutate(params, { onSuccess: () => refetchExports() });
      }
    },
    [mutate, refetchExports, snapshotIds],
  );

  if (snapshotIds.length === 0) {
    modal.close();
    return;
  }

  return (
    <Modal>
      <Modal.Title>{snapshotIds.length > 1 ? `Export ${snapshotIds.length} Snapshots` : `Export Snapshot`}</Modal.Title>

      <Formik
        initialValues={{
          views: [HEATMAP, SCROLLMAP, CONFETTI, OVERLAY, LIST],
          sendEmail: true,
          email: currentUser.email,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          exportSelection(values);
        }}
      >
        {({ values, errors, handleChange, handleBlur, isValid }) => (
          <Form>
            <div className="flex flex-col">
              <div className="text-body-1 mb-3.5">Which reports would you like to export?</div>

              <Field name="views" type="checkbox" value={HEATMAP}>
                {({ field }) => <Checkbox {...field} label="Heatmap" id="Heatmap" />}
              </Field>
              <Field name="views" type="checkbox" value={SCROLLMAP}>
                {({ field }) => <Checkbox {...field} label="Scrollmap" id="Scrollmap" />}
              </Field>
              <Field name="views" type="checkbox" value={CONFETTI}>
                {({ field }) => <Checkbox {...field} label="Confetti" id="Confetti" />}
              </Field>
              <Field name="views" type="checkbox" value={OVERLAY}>
                {({ field }) => <Checkbox {...field} label="Overlay" id="Overlay" />}
              </Field>
              <Field name="views" type="checkbox" value={LIST}>
                {({ field }) => <Checkbox {...field} label="List" id="List" />}
              </Field>
              {errors.views && <div className="text-body-4 text-carnation-500">{errors.views}</div>}
            </div>

            <Divider className="-mx-7 my-6 !w-auto" dashed />

            <div className="flex flex-col">
              <Checkbox
                name="sendEmail"
                checked={values.sendEmail}
                onChange={handleChange}
                label="Send an email to this address when export finishes"
                id="sendEmail"
              />
              <div className="mt-1.5 w-full">
                <Input
                  name="email"
                  placeholder="e.g. user@email.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  data-testid="exportEmailInput"
                  error={errors.email}
                  disabled={!values.sendEmail}
                  validationIcon={!values.sendEmail ? null : !errors.email}
                />
              </div>
              <div className="mt-6">
                <Button size="lg" type="submit" disabled={isLoading || !isValid || loadingExports}>
                  {isLoading || loadingExports ? (
                    <div className="flex items-center">
                      <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                      Exporting...
                    </div>
                  ) : (
                    <>{snapshotIds.length > 1 ? 'Export Snapshots' : 'Export Snapshot'}</>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
