import { useEffect } from 'react';

export function useOutsideClickNotify(refs, handler, isActive = true) {
  useEffect(() => {
    const listener = (event) => {
      // do nothing if the click notifier is not active
      if (!isActive) {
        return;
      }

      // Do nothing if clicking ref's element or descendent elements
      const arr = Array.isArray(refs) ? refs : [refs];
      let contains = false;
      for (let ref of arr) {
        if (!ref.current || ref.current.contains(event.target)) {
          contains = true;
        }
      }
      if (contains) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler, isActive]);
}
