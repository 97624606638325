import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

import { msToHHMMSS } from '/src/utils/date';
import { PLAYER_EVENTS } from '/src/features/recordings/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as ArrowExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

const { PAGE, ERROR_EVENT, TAB_SWITCH } = PLAYER_EVENTS;

export function PlayerEvent({
  icon: Icon = null,
  active = false,
  title = null,
  text = null,
  subText = null,
  timestamp = null,
  children,
  onClick = null,
  isErrorEvent = false,
}) {
  const eventRef = useRef(null);

  useEffect(() => {
    if (
      eventRef?.current &&
      active &&
      !(document.querySelector('aside section#sidebar-events')?.matches?.(':hover') ?? null)
    ) {
      eventRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [eventRef, active]);

  const formattedTimestamp = msToHHMMSS(timestamp);
  const [minutes, seconds] = formattedTimestamp.split(':');

  return (
    <li className="group relative z-10">
      <span
        className={classNames(
          'absolute -top-px left-0 right-0 z-20 h-px',
          'pointer-events-none transition duration-150 ease-in-out group-hover:opacity-100',
          'border-b border-dodger-blue-500',
          {
            'opacity-0': !active,
          },
        )}
      />
      <button
        className={classNames(
          'group relative flex w-full items-center pl-12 pr-6 text-sm transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white focus:bg-dodger-blue-500 focus:text-white focus:outline-none',
          {
            'text-white': !active && !isErrorEvent,
            'text-carnation-500': !active && isErrorEvent,
            'bg-dodger-blue-500 text-white': active,
            'py-3': !subText,
            'py-[5px]': !!subText,
          },
        )}
        onClick={onClick}
        ref={eventRef}
      >
        <div className="mr-2.5 h-5 w-5 flex-shrink-0">
          <Icon
            aria-label="event"
            className={classNames('h-5 w-5 fill-current group-hover:text-white group-focus:opacity-100', {
              'text-white': !active && !isErrorEvent,
              'text-carnation-500': !active && isErrorEvent,
            })}
          />
        </div>
        <div className="flex flex-1 flex-col items-start truncate">
          <div className="flex w-full items-center">
            {title || text ? (
              <span className="flex-1 truncate pr-2 text-left">
                {title ? (
                  <strong>
                    {title}
                    {text ? ': ' : null}
                  </strong>
                ) : null}
                {text ? text : null}
              </span>
            ) : null}
          </div>
          {subText ? <span className="text-caption -mt-1 text-mystic-500">{subText}</span> : null}
        </div>
        {timestamp ? (
          <time className="w-10 flex-shrink-0 truncate text-right" dateTime={`PT${minutes}M${seconds}S`}>
            {formattedTimestamp}
          </time>
        ) : (
          children
        )}
      </button>
      <span
        className={classNames(
          'pointer-events-none absolute bottom-0 left-0 right-0 z-0 mx-auto h-px w-11/12',
          'border-t border-dashed border-mako-500 group-hover:opacity-0',
          {
            'opacity-0': active,
          },
        )}
      />
    </li>
  );
}

export function DropdownEvent({ icon: Icon = null, title, text, active, timestamp, children, onClick = null }) {
  const eventRef = useRef(null);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (
      eventRef?.current &&
      active &&
      !(document.querySelector('aside section#sidebar-events')?.matches?.(':hover') ?? null)
    ) {
      eventRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [eventRef, active]);

  const formattedTimestamp = msToHHMMSS(timestamp);
  const [minutes, seconds] = formattedTimestamp.split(':');

  function toggleOpened() {
    setOpened(!opened);
  }

  return (
    <li className="group relative z-10 overflow-hidden">
      <span className="group flex flex-row flex-wrap transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white">
        <button
          className={classNames(
            'group relative flex py-3 pl-6 text-sm transition duration-150 ease-in-out focus:outline-none',
            {
              'text-cadet-blue-500': !active,
              'bg-dodger-blue-500 text-white': active && !opened,
              'text-white': opened,
            },
          )}
          onClick={toggleOpened}
          ref={eventRef}
          aria-expanded={opened}
        >
          <ArrowExpandIcon
            aria-label={opened ? 'expand' : 'closed'}
            className={classNames(
              'mr-3 h-3  w-3 self-center fill-current transition duration-150 ease-in-out group-hover:text-white',
              {
                'rotate-0': opened,
                'rotate-180': !opened,
                'text-white': active,
                'text-cadet-blue-500': !active || opened,
              },
            )}
          />
        </button>
        <button
          className={classNames(
            'group flex flex-1 items-center justify-between py-3 pr-6 text-sm transition duration-150 ease-in-out focus:outline-none group-hover:text-white',
            {
              'text-cadet-blue-500': !active,
              'bg-dodger-blue-500 text-white': active && !opened,
              'text-white': opened,
            },
          )}
          onClick={onClick}
          ref={eventRef}
        >
          <Icon
            aria-label="event"
            className={classNames('mr-2.5 h-5 w-5 fill-current group-hover:text-white group-focus:text-white', {
              'text-cadet-blue-500': !active,
              'text-white': active,
            })}
          />
          {title || text ? (
            <span className="flex-1 truncate pr-2 text-left">
              {title ? (
                <strong>
                  {title}
                  {text ? ': ' : null}
                </strong>
              ) : null}
              {text ? text : null}
            </span>
          ) : null}
          <time className="w-10 truncate text-right" dateTime={`PT${minutes}M${seconds}S`}>
            {formattedTimestamp}
          </time>
        </button>
      </span>
      {opened ? (
        <div className="flex w-full px-3.5">
          <ul className="w-full border-t border-dashed border-mako-500">{children}</ul>
        </div>
      ) : null}
      {title !== 'Recording ended' && (
        <span
          className={classNames(
            'absolute bottom-0 left-0 right-0 z-0 mx-auto h-px w-11/12',
            'pointer-events-none group-hover:opacity-0',
            'border-t border-dashed border-mako-500',
            {
              'opacity-0': active || opened,
            },
          )}
        ></span>
      )}
    </li>
  );
}

export function DropdownRow({ tooltipText = null, icon: Icon = null, title, duration, active = false }) {
  const formattedDuration = duration && parseFloat(duration).toFixed(2);

  return (
    <li className="border-b border-dashed border-mako-500">
      <span
        className={classNames(
          'group relative flex w-full items-center bg-mako-500 py-3 pl-4 pr-2.5 text-sm transition duration-150 ease-in-out',
          {
            'text-cadet-blue-500': !active,
            'bg-dodger-blue-500 text-white': active,
          },
        )}
      >
        {tooltipText || Icon ? (
          <div className="ml-[18px] mr-2.5 flex w-5 items-center justify-center">
            {tooltipText ? (
              <Tooltip placement="left" style={{ maxWidth: '140px' }} tooltipContent={tooltipText}>
                <InfoIcon className="h-3 w-3 fill-current text-cadet-blue-500" aria-label="hover for more info" />
              </Tooltip>
            ) : null}
            {Icon ? (
              <Icon
                className={classNames('h-4 w-4 fill-current', {
                  'text-cadet-blue-500': !active,
                  'text-white': active,
                })}
              />
            ) : null}
          </div>
        ) : null}

        {title || duration ? (
          <span className="flex-1 truncate pr-2 text-left">
            {title ? (
              <strong>
                {title}
                {duration ? ': ' : null}
              </strong>
            ) : null}
            {duration ? <time dateTime={`PT${formattedDuration}S`}>{formattedDuration}s</time> : null}
          </span>
        ) : null}
      </span>
    </li>
  );
}

export function TooltipEvent({ icon: Icon, title, event, onClick }) {
  function renderContent() {
    switch (event.type) {
      case PAGE:
      case TAB_SWITCH:
        return (
          <button
            className="flex w-full items-start border-b border-dashed border-white border-opacity-10 p-2.5 text-xs text-picasso-500 transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white focus:bg-dodger-blue-500 focus:outline-none"
            onMouseDown={(e) => {
              e.stopPropagation();
              onClick?.(event.timestamp, { isTimestamp: true });
            }}
          >
            <Icon aria-label="event" className="mr-1.5 mt-px h-3 w-2.5 fill-current" />
            <span className="line-clamp-2 flex-1 text-left">
              <strong>{title}:</strong> {event?.url && new URL(event.url).pathname}
            </span>
          </button>
        );
      case ERROR_EVENT:
        return (
          <button
            className="flex w-full items-center border-b border-dashed border-white border-opacity-10 p-2.5 text-xs font-semibold text-carnation-500 transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white focus:bg-dodger-blue-500 focus:text-white focus:outline-none"
            onMouseDown={(e) => {
              e.stopPropagation();
              onClick?.(event.timestamp, { isTimestamp: true });
            }}
          >
            <Icon aria-label="event" className="mr-1.5 h-3 w-3 fill-current" />
            {title}
          </button>
        );
      default:
        return (
          <button
            className="flex w-full items-center border-b border-dashed border-white border-opacity-10 p-2.5 text-xs font-semibold text-white transition duration-150 ease-in-out hover:bg-dodger-blue-500 focus:bg-dodger-blue-500 focus:outline-none"
            onMouseDown={(e) => {
              e.stopPropagation();
              onClick?.(event.timestamp, { isTimestamp: true });
            }}
          >
            <Icon aria-label="event" className="mr-1.5 h-3 w-3 fill-current" />
            {title}
          </button>
        );
    }
  }

  return <li className="flex w-full items-center overflow-hidden">{renderContent()}</li>;
}
