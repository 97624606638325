import { useQuery } from '@tanstack/react-query';
import { endOfHour, getUnixTime } from 'date-fns';
import { usePermissions, useSite } from '/src/hooks';

import { SitePickerBar } from '/src/components/site-picker/index';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { FEATURES } from '/src/features/_global/constants';

import { dashboardQuery } from '../queries';

export function HomeDashboardContent({ dateRange, sections }) {
  const { sites, selectedSite, selectSite, loadingSites } = useSite();
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const convertedDateRange = {
    start: getUnixTime(endOfHour(new Date())) - dateRange * 24 * 60 * 60,
    end: getUnixTime(endOfHour(new Date())),
  };

  const { data, isLoading } = useQuery({
    ...dashboardQuery({
      siteId: selectedSite?.id,
      startAt: convertedDateRange.start,
      endAt: convertedDateRange.end,
      previousStartAt: convertedDateRange.start - (convertedDateRange.end - convertedDateRange.start) - 1,
      previousEndAt: convertedDateRange.start - 1,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const dashboardData = data?.dashboard;

  return (
    <>
      <SitePickerBar
        sites={sites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        loading={loadingSites}
        addSiteOption={canEditSites}
        pageDisplayName="Home dashboard"
      />
      <>
        <InstallationBanner />
        <div className="flex flex-col p-10">
          <div className="grid grid-flow-row-dense grid-cols-3 gap-5">
            {sections
              .filter((s) => s.enabled)
              .map(({ Component, name }) => (
                <Component key={name} dateRange={convertedDateRange} data={dashboardData} loading={isLoading} />
              ))}
          </div>
        </div>
      </>
    </>
  );
}
