import { useMemo, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Tooltip, capitalize } from '@crazyegginc/hatch';

import { useNotifications } from '/src/hooks';
import { LazyImg } from '/src/components/LazyImg';
import { deserializeTrigger } from '/src/features/goals/machines/wizard';
import { copyToClipboard, pickFields } from '/src/utils';
import { SupportLinks } from '/src/support';
import { RECORDINGS_FILTER_VERSION } from '/src/features/recordings/constants';
import { DEVICE_TYPES, SPECIAL_DATE_RANGES, LOGICALS } from '/src/features/_global/constants';
import { PIXEL_API_LIST } from './constants';

function humanizePixelApi(api) {
  switch (api) {
    case PIXEL_API_LIST.TIKTOK:
      return 'TikTok';
    default:
      return capitalize(api);
  }
}

export function triggersFromDisplayData(displayData, device = DEVICE_TYPES.DESKTOP) {
  const parsed = JSON.parse(displayData);

  if (typeof parsed.triggers === 'undefined') {
    return null;
  }

  const triggers = parsed.triggers || parsed.values;

  const humanizedTriggers = triggers.map((trigger, triggerIndex) => {
    return trigger.segments
      .map((segment, index) => {
        if (typeof segment === 'string') {
          const isLink = segment.match(/https?:\/\//);
          return (
            <span
              key={`${parsed.id}_${triggerIndex}_${index}`}
              className={classNames({
                'shrink-0': !isLink,
                'min-w-0 truncate': isLink,
              })}
            >
              {segment}
            </span>
          );
        }
        if (segment && segment?.href) {
          return (
            <div className="relative min-w-0" key={`Trigger-${trigger.index}-Segment-${index}`}>
              <GoalLinkURL url={segment.href} message={segment.message} />
            </div>
          );
        }
        if (segment && segment?.onHover) {
          if (segment.onHover.type === 'editor') {
            return (
              <span className="shrink-0" key={`Trigger-${trigger.index}-Segment-${index}`}>
                <Tooltip
                  interactive={true}
                  darkTheme={false}
                  placement="bottom"
                  tooltipContent={
                    <LazyImg
                      className="h-[88px] w-[138px] !rounded-none"
                      alt="goal thumbnail"
                      src={segment.onHover.arguments?.[0]?.[`thumbnail_for_${device.toLowerCase()}`] ?? null}
                      width="138px"
                      height="88px"
                    />
                  }
                >
                  <span className="cursor-pointer text-2xs font-normal underline decoration-cadet-blue-500 decoration-dashed underline-offset-2">
                    {segment.message}
                  </span>
                </Tooltip>
              </span>
            );
          } else if (segment.onHover.type === 'scriptInstructions') {
            return (
              <span className="shrink-0" key={`Trigger-${trigger.index}-Segment-${index}`}>
                <Tooltip
                  interactive={true}
                  darkTheme={true}
                  placement="bottom"
                  tooltipContent={
                    <div className="w-40">
                      Read our{' '}
                      <a
                        href={SupportLinks.script.ce2Functions}
                        className="text-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        help article
                      </a>{' '}
                      on setting up code goals.
                    </div>
                  }
                >
                  <span className="cursor-pointer text-2xs font-normal underline decoration-cadet-blue-500 decoration-dashed underline-offset-2">
                    {segment.message}
                  </span>
                </Tooltip>
              </span>
            );
          } else if (segment.onHover.type === 'scriptInstructionsCopy') {
            return <CopyCodeLink segment={segment} key={`Trigger-${trigger.index}-Segment-${index}`} />;
          } else if (segment.onHover.type === 'pixel_event') {
            return (
              <span className="shrink-0" key={`Trigger-${trigger.index}-Segment-${index}`}>
                <Tooltip
                  interactive={true}
                  darkTheme={true}
                  placement="bottom"
                  tooltipContent={
                    <div>
                      <span className="block font-semibold">
                        {humanizePixelApi(segment.onHover.arguments?.[0]?.api)} event:
                      </span>
                      <span className="font-normal">{segment.onHover.arguments?.[0]?.event || 'Any event'}</span>
                    </div>
                  }
                >
                  <span className="cursor-pointer text-2xs font-normal underline decoration-cadet-blue-500 decoration-dashed underline-offset-2">
                    {segment.message}
                  </span>
                </Tooltip>
              </span>
            );
          }
          return (
            <span
              key={`${parsed.id}_${triggerIndex}_${index}`}
              className="text-link shrink-0 cursor-pointer text-2xs font-normal"
            >
              {segment?.message}
            </span>
          );
        }
        return (
          <span key={`${parsed.id}_${triggerIndex}_${index}`} className="shrink-0">
            {segment?.message}
          </span>
        );
      })
      .filter(Boolean);
  });

  return humanizedTriggers;
}

export function textOnlyTriggersFromDisplayData(displayData) {
  const parsed = JSON.parse(displayData);

  if (typeof parsed.triggers === 'undefined') {
    return null;
  }

  const triggers = parsed.triggers || parsed.values;

  const noDisplayList = ['|', 'Copy script'];

  const humanizedTriggers = triggers.map((trigger, triggerIndex) => {
    return trigger.segments.map((segment, index) => {
      const msg = typeof segment === 'string' ? segment : segment?.message;
      if (!msg || noDisplayList.includes(msg)) {
        return null;
      }
      return (
        <span key={`${parsed.id}_${triggerIndex}_${index}`} className="leading-snug">
          {msg}
        </span>
      );
    });
  });

  return humanizedTriggers;
}

export function GoalTriggerSummary({
  goal,
  textOnly = false,
  centered,
  expandBehavior = 'click',
  showMaxTriggers = 2,
}) {
  const [expanded, setExpanded] = useState(false);
  if (!goal?.displayData) return null;

  const triggers = textOnly
    ? textOnlyTriggersFromDisplayData(goal.displayData)
    : triggersFromDisplayData(goal.displayData);

  if (triggers === null) {
    return null;
  }

  const truncatedTriggers = triggers.slice(0, showMaxTriggers ?? undefined);
  const leftOverTriggers = showMaxTriggers ? triggers.slice(showMaxTriggers) : [];
  const displayedTriggers = expanded ? triggers : truncatedTriggers;

  return (
    <div
      className={classNames('text-caption', {
        'w-full text-center': centered,
        'flex flex-col': !centered,
      })}
    >
      <span className="block">Goal reached when someone:</span>
      {displayedTriggers.length === 1 ? (
        <span className={'flex justify-center space-x-1 self-start'}>{displayedTriggers[0]}</span>
      ) : (
        <ul className="inline-block max-w-full list-outside list-disc pl-5 text-left ">
          {displayedTriggers.map((trigger, index) => (
            <li key={`Goal-${goal.id}_trigger_${index}`}>
              <div className="flex items-center space-x-1 overflow-hidden">
                {trigger}
                {displayedTriggers.length > index + 1 ? <span className="!ml-0 shrink-0">, or</span> : null}
              </div>
            </li>
          ))}
        </ul>
      )}
      {leftOverTriggers.length > 0 && expandBehavior === 'click' ? (
        <div
          className={classNames({
            'ml-5': displayedTriggers.length > 1,
            'self-start': !centered,
            'flex w-full items-center justify-center': centered,
          })}
        >
          <button type="button" className="font-semibold text-dodger-blue-500" onClick={() => setExpanded((x) => !x)}>
            {expanded ? 'Hide triggers' : `Show ${leftOverTriggers.length} more triggers`}
          </button>
        </div>
      ) : null}
      {leftOverTriggers.length > 0 && expandBehavior === 'info' ? (
        <div
          className={classNames({
            'ml-5': displayedTriggers.length > 1,
            'self-start': !centered,
            'flex w-full items-center justify-center': centered,
          })}
        >
          +{leftOverTriggers.length} more triggers
        </div>
      ) : null}
    </div>
  );
}

export function GoalLinkURL({ url, message }) {
  const ref = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    setIsTruncated(ref.current?.clientWidth < ref.current?.scrollWidth);

    const getTruncated = () => {
      setIsTruncated(ref.current?.clientWidth < ref.current?.scrollWidth);
    };
    window.addEventListener('resize', getTruncated);

    return () => window.removeEventListener('resize', getTruncated);
  }, [message]);

  return (
    <Tooltip
      show={isTruncated}
      style={{ maxWidth: '230px' }}
      tooltipContent={<div className="break-words">{message}</div>}
    >
      <a
        href={url}
        className="text-link external-link relative flex flex-1 items-center text-2xs font-normal"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="relative min-w-0 truncate" ref={ref}>
          {message}
        </span>
      </a>
    </Tooltip>
  );
}

export function parseTriggers(triggers) {
  return triggers.map((trigger, index) => {
    const { __typename, ...payload } = trigger; // eslint-disable-line
    return {
      ...payload,
      ...deserializeTrigger(trigger.filter),
      name: trigger?.name ?? '',
      expanded: index === 0,
      elementValue: trigger.elementValue
        ? {
            ...trigger.elementValue,
            syncSelectors: true,
          }
        : null,
    };
  });
}

export function generateCodeGoalScript(code, escaped = true) {
  if (escaped) {
    return `&lt;script type="text/javascript"&gt;\n\t(window.CE_API || (window.CE_API=[])).push(function(){\n\t\tCE2.converted("${code}");\n\t});\n&lt;/script&gt;`;
  }
  return `<script type="text/javascript">\n\t(window.CE_API || (window.CE_API=[])).push(function(){\n\t\tCE2.converted("${code}");\n\t});\n</script>`;
}

function CopyCodeLink({ segment }) {
  const notifications = useNotifications();
  const script = useMemo(
    () => generateCodeGoalScript(segment.onClick.arguments[0], false),
    [segment.onClick.arguments],
  );

  return (
    <Tooltip
      interactive={false}
      darkTheme={true}
      placement="bottom"
      tooltipContent={<div className="w-40">Click to copy the script for this trigger</div>}
    >
      <button
        onClick={
          segment.onClick
            ? async () => {
                await copyToClipboard(script);
                notifications.success({ content: 'Script copied to clipboard', timeout: 2000 });
              }
            : null
        }
        className="text-link cursor-pointer text-2xs font-normal"
      >
        {segment.message}
      </button>
    </Tooltip>
  );
}

export function generateRecordingsFilterUrlForGoal(data, selected = { special: SPECIAL_DATE_RANGES.LAST_30_DAYS }) {
  return JSON.stringify({
    version: RECORDINGS_FILTER_VERSION,
    operator: LOGICALS.AND,
    conditions: [
      {
        criteria: 'goal',
        comparison: 'did all of',
        values: [data.id],
      },
      {
        criteria: 'date_range',
        value: selected.special
          ? {
              special: selected.special,
            }
          : {
              start_date: selected.startDate,
              end_date: selected.endDate,
            },
      },
    ],
  });
}

const basicFields = ['autoName', 'devices', 'expanded', 'filter', 'name', 'syncSelectors', 'trigger', 'uiMode'];
const elementFields = [
  'phoneSelector',
  'tabletSelector',
  'desktopSelector',
  'targetUrl',
  'trackingOption',
  'elementSelectorType',
  'wildcardUrl',
];

const getWhitelistedFields = (type) => {
  switch (type) {
    case 'CODE':
      return [...basicFields, 'code'];
    case 'PIXEL_EVENT':
      return [...basicFields, 'pixelApi', 'pixelCustomEvent', 'pixelEvent', 'pixelId'];
    case 'CLICK':
    case 'FORM_SUBMISSION':
      return [...basicFields, ...elementFields];
    case 'URL_VISIT':
      return [...basicFields, 'targetUrl'];
    default:
      return basicFields;
  }
};

export function contextToArgs(context) {
  const { payload } = context;

  const triggers = payload.triggers.map((t) => pickFields(t, getWhitelistedFields(t.trigger)));

  return {
    ...payload,
    triggers,
  };
}
