import classNames from 'classnames';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as PauseIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';

import { msToHHMMSS } from '/src/utils/date';

export function PlayerControls({
  togglePlaying,
  playerReady,
  loading,
  isPlaying,
  isDone,
  seeking,
  elapsedTime,
  playbackTime,
}) {
  const formattedElapsedTime = msToHHMMSS(seeking ? seeking : elapsedTime);
  const formattedPlaybackTime = playbackTime && msToHHMMSS(playbackTime);

  return (
    <div
      className="mb-4 flex items-center justify-center md:mb-0 md:justify-start md:pl-4 xl:pl-8"
      data-testid="player_controls"
    >
      <button
        className={classNames('invisible mr-6 hidden transition-all md:visible md:inline-block', {
          'cursor-not-allowed opacity-20': !playerReady || loading || isDone,
        })}
        onClick={togglePlaying}
        disabled={!playerReady || loading || isDone}
      >
        {isPlaying ? (
          <>
            <PauseIcon className="mx-auto h-4 w-4 fill-current text-white" />
            <span className="sr-only">pause video</span>
          </>
        ) : (
          <>
            <PlayIcon className="mx-auto h-4 w-4 fill-current text-white" />
            <span className="sr-only">play video</span>
          </>
        )}
      </button>
      <span className="text-sm text-white" aria-label="current timeline">
        {formattedElapsedTime}
      </span>
      <span className="mx-2 text-sm text-white" role="presentation">
        /
      </span>
      {formattedPlaybackTime ? (
        <time className="text-sm text-white" dateTime="PT01M08S" aria-label="video total duration">
          {formattedPlaybackTime}
        </time>
      ) : (
        <span className="text-sm text-white">-</span>
      )}
    </div>
  );
}
