import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { Paywall } from '/src/components/Paywall';

import YellowWarningPNG from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.png';
import YellowWarningWebP from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.webp';

export function ErrorNotFoundWall() {
  return (
    <Paywall>
      <Paywall.Image webp={YellowWarningWebP} fallback={YellowWarningPNG} width="204px" height="162px" />
      <Paywall.Title>Oops, we could not find that error!</Paywall.Title>

      <Button className="mt-5" component={Link} to="/errors">
        Take me back to the dashboard
      </Button>
    </Paywall>
  );
}
