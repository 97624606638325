import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 1);
  }, [pathname]);

  return null;
}

export function ScrollTo({ selector, delay = 0, onComplete = null }) {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(selector)?.scrollIntoView?.();
      onComplete?.();
    }, delay);
  }, [selector, delay, onComplete]);

  return null;
}
