import { useState } from 'react';
import { Button, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { WebP } from '../../../../components/WebP';

import RocketPNG from '@crazyegginc/hatch/dist/images/illustration-rocket.png';
import RocketWebP from '@crazyegginc/hatch/dist/images/illustration-rocket.webp';

export function LaunchModal({ onConfirm, isTemplate, publishedEdit, type }) {
  const [publishing, setPublishing] = useState(false);

  return (
    <Modal
      dialogClassName="!w-[640px] !items-center"
      dismissable={publishing ? false : true}
      disableOutsideClick={publishing ? true : false}
    >
      <WebP webp={RocketWebP} fallback={RocketPNG} width="144px" height="128px" />

      <div className="text-header-2 mt-5">
        Ready to publish your {isTemplate ? 'Template' : publishedEdit ? 'changes' : type}?
      </div>
      <div className="text-body-2 mt-6 text-center">
        {isTemplate ? <>Ready to publish this new template? Customers will have access to it right away!</> : null}
      </div>

      <div className="mt-7 flex flex-col items-center space-y-2.5">
        <Button
          disabled={publishing}
          onClick={async () => {
            setPublishing(true);
            await onConfirm?.();
            setPublishing(false);
          }}
        >
          {publishing ? (
            <>
              <Spinner className="mr-2 h-3 w-3" />
              {isTemplate ? 'Publishing' : 'Launching...'}
            </>
          ) : (
            <>{isTemplate ? 'Publish Template' : `Launch my ${type}!`}</>
          )}
        </Button>
        <Modal.Cancel disabled={publishing}>Continue editing</Modal.Cancel>
      </div>
    </Modal>
  );
}
