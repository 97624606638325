import { useState, useEffect, useId } from 'react';
import { useFormikContext, getIn } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { Button, IconButton, Spinner, SkeletonLine, Input, Tooltip } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';
import { useNotifications, useMutation } from '/src/hooks';
import { imageUploadMutation, imageDeleteMutation } from '/src/features/addons/mutations';
import { imageDetailQuery } from '/src/features/addons/queries';
import { DEFAULT_IMAGE_ALT } from '/src/features/addons/surveys/editor/SurveyEditor';

import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function ImageUpload({ fieldPath }) {
  const notifications = useNotifications();
  const { values, errors, setFieldValue, handleChange, handleBlur } = useFormikContext();
  const { readonly } = useEditorContext();
  const [fileName, setFileName] = useState();
  const id = useId();
  const uploadImage = useMutation(imageUploadMutation);
  const deleteImage = useMutation(imageDeleteMutation);

  const value = getIn(values, fieldPath);
  const error = getIn(errors, fieldPath);

  const [tooltipVisible, setTooltipVisible] = useState(
    value?.id && value?.alt && value?.alt !== DEFAULT_IMAGE_ALT ? false : true,
  );

  const { data, isInitialLoading } = useQuery({
    ...imageDetailQuery({ id: value?.id, siteId: values.audience.site?.id }),
    enabled: Boolean(value?.id && values.audience.site?.id && !fileName),
  });

  useEffect(() => {
    if (!fileName && data?.imageDetail.filename) {
      setFileName(data.imageDetail.filename);
    }
  }, [fileName, data]);

  function handleUpload(e) {
    const file = e.currentTarget.files[0];

    // validate type
    if (!file.type.match(/^image\//)) {
      notifications.error({
        title: 'Image upload failed',
        content: 'Please select a valid image file to upload.',
        timeout: 5000,
        skipHoneybadger: true,
      });
      return;
    }

    uploadImage.mutate(
      { image: file, siteId: values.audience.site.id },
      {
        onError: (error) =>
          notifications.error({
            content: 'Failed to upload image.',
            timeout: 3000,
            context: { error },
          }),
        onSuccess: (data) => {
          const { filename, id, publicUrl } = data.imageUpload;
          setFieldValue(fieldPath, {
            id,
            publicUrl,
            alt: DEFAULT_IMAGE_ALT,
          });
          setFileName(filename);
        },
      },
    );
  }

  async function handleDelete() {
    await setFieldValue(fieldPath, undefined);
    // sc-43616
    /*saveAddon();
    deleteImage.mutate(
      { id: value.id, siteId: values.audience.site.id },
      {
        onError: (error) =>
          notifications.error({
            content: 'Failed to delete image from the server.',
            timeout: 3000,
            context: { error },
          }),
        onSettled: () => {
          setFileName(undefined);
          setTooltipVisible(true);
        },
      },
    );*/
    setFileName(undefined);
    setTooltipVisible(true);
  }

  function selectTextOnFocus(e) {
    if (e.target.value === DEFAULT_IMAGE_ALT) {
      e.target.select();
    }
  }

  return (
    <div>
      <div className="text-body-1 mb-1 flex w-full">
        <span className="w-1/2">Image</span>
        {fileName ? (
          <div className="mr-5 flex w-1/2 items-center">
            <span className="mr-3">Description</span>
            <Tooltip
              tooltipContent={
                <div className="max-w-xs">
                  For maximum accessibility please provide
                  <br /> a description of this image.
                </div>
              }
            >
              <InfoIcon aria-label="information on fold" className="h-3 w-3 fill-current text-dodger-blue-300" />
            </Tooltip>
          </div>
        ) : null}
      </div>
      {isInitialLoading ? (
        <SkeletonLine />
      ) : (
        <>
          {fileName ? (
            <div className="flex items-start">
              <div className="w-1/2 truncate rounded bg-white-lilac-500 py-2 pl-2.5 pr-3.5 font-mono text-xs">
                {fileName}
              </div>
              <div className="ml-5 w-1/2">
                <Tooltip
                  hover={false}
                  show={tooltipVisible}
                  onDismiss={() => setTooltipVisible(false)}
                  tooltipContent={
                    <div className="max-w-xs">
                      For maximum accessibility please provide
                      <br /> a description of this image.
                    </div>
                  }
                >
                  <Input
                    aria-label="image description"
                    id={`${fieldPath}.alt`}
                    name={`${fieldPath}.alt`}
                    value={value?.alt}
                    error={error?.alt}
                    onChange={(e) => {
                      handleChange(e);
                      if (tooltipVisible) {
                        setTooltipVisible(false);
                      }
                    }}
                    onBlur={handleBlur}
                    onFocus={selectTextOnFocus}
                    disabled={readonly}
                    autoFocus={true} //eslint-disable-line
                  />
                </Tooltip>
              </div>
              <div className="ml-4 flex h-[35px] shrink-0 items-center">
                <IconButton
                  icon={
                    deleteImage.isLoading ? (
                      <Spinner className="mr-1.5 h-4 w-4 text-lynch-500" />
                    ) : (
                      <CrossIcon className="mr-2.5 h-3 w-3 fill-current" />
                    )
                  }
                  className="shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                  onClick={handleDelete}
                  label="delete image"
                  disabled={deleteImage.isLoading}
                />
              </div>
            </div>
          ) : (
            <>
              <Button
                variant="secondary"
                component="label"
                className="!flex !w-[125px] cursor-pointer !justify-center"
                htmlFor={id}
                disabled={uploadImage.isLoading || readonly}
              >
                {uploadImage.isLoading ? (
                  <div className="flex items-center">
                    <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                    Uploading...
                  </div>
                ) : (
                  'Upload image'
                )}
              </Button>
              <input accept="image/*" hidden id={id} type="file" onChange={handleUpload} />
            </>
          )}
        </>
      )}
    </div>
  );
}
