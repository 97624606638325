import { useState } from 'react';
import { Button, Spinner } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';

export function InviteCancelModal({ onCancelInvite = null }) {
  const [isCancelling, setIsCancelling] = useState(false);

  return (
    <Modal dialogClassName="!w-[360px]">
      <Modal.Title>Cancel Invite?</Modal.Title>

      <p className="text-body-5 leading-5">
        If you cancel this team member&apos;s invite, they will no longer have access to this Crazy Egg account.
      </p>

      <Modal.Actions>
        <Button
          variant="warning"
          disabled={isCancelling}
          onClick={() => {
            setIsCancelling(true);
            onCancelInvite?.();
          }}
        >
          {isCancelling ? (
            <>
              <Spinner className="mr-2 h-3 w-3" />
              <span>Canceling...</span>
            </>
          ) : (
            'Cancel invite'
          )}
        </Button>
        <Modal.Cancel disabled={isCancelling}>Don&apos;t cancel invite</Modal.Cancel>
      </Modal.Actions>
    </Modal>
  );
}
