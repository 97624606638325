import { Button } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { Panel, IntroText } from '../SsoUi';

export function UpgradePrompt() {
  return (
    <Panel>
      <IntroText className="mb-7" />
      <Button onClick={() => window.open(SupportLinks.general.newSupportRequest, '_blank')} className="self-start">
        Contact sales
      </Button>
    </Panel>
  );
}
