import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { recordingFragment } from '/src/features/recordings/queries';

export const addonsFeatureKeys = createQueryKeys('addonsFeature', {
  addonList: ({ filter, siteId, minimal }) => [
    minimal,
    {
      filter,
      siteId,
    },
  ],
  addonDetail: ({ id }) => [{ id }],
  surveyResultSummary: ({ id, startDate, endDate }) => [{ id, startDate, endDate }],
  surveyResponses: ({ id, index, startDate, endDate }) => [{ id, index, startDate, endDate }],
  surveyResponseList: ({ surveyId, order, limit, startDate, endDate }) => [
    { surveyId, order, limit, startDate, endDate },
  ],
  ctaResult: ({ id, startAt, endAt }) => [{ id, startAt, endAt }],
  addonTemplatesList: null,
  addonTemplateDetail: ({ id }) => [{ id }],
  imageDetail: ({ id, siteId }) => [{ id, siteId }],
  imageList: ({ filter, siteId }) => [{ filter, siteId }],
  addonSuggestionsList: ({ limit, siteId, type }) => [{ limit, siteId, type }],
  addonSuggestionDetail: ({ id }) => [{ id }],
});

export const SITES_FOR_ADDONS = gql`
  query SitesForAddons {
    sitesForAddons {
      __typename
      id
      name
      installed
      lastData
      ctas {
        count
      }
      surveys {
        count
      }
      lastData
      active
      metadata
    }
  }
`;

export const ADDON_NAME = gql`
  query AddonDetail($id: String!) {
    addonDetail(id: $id) {
      __typename
      id
      name
    }
  }
`;

const ADDON_LIST_MINIMAL = gql`
  query AddonList($siteId: Int, $cursor: String, $filter: AddonFilter!) {
    addonList(siteId: $siteId, cursor: $cursor, filter: $filter) {
      list {
        __typename
        id
        name
        publishedAt
      }
    }
  }
`;

const ADDON_LIST = gql`
  query AddonList($siteId: Int, $cursor: String, $filter: AddonFilter!) {
    addonList(siteId: $siteId, cursor: $cursor, filter: $filter) {
      list {
        __typename
        active
        content
        createdAt
        createdBy
        draftContent
        draftName
        formatVersion
        hasData
        id
        name
        permissions {
          canViewResults
          canViewSettings
          canEdit
          canRename
          canDelete
          canToggle
          canExport
          canShare
        }
        publishedAt
        totalViews
        responseCount
        siteId
        type
        totalConversions
      }
      hasNextPage
      nextPageCursor
    }
  }
`;

export const addonListQuery = ({ filter, siteId, minimal = false }) => ({
  ...addonsFeatureKeys.addonList({ filter, siteId, minimal }),
  getNextPageParam: (lastPage) => (lastPage.addonList.hasNextPage ? lastPage.addonList.nextPageCursor : undefined),
  meta: {
    query: minimal ? ADDON_LIST_MINIMAL : ADDON_LIST,
  },
});

export const addonDetailQuery = ({ id }) => ({
  ...addonsFeatureKeys.addonDetail({ id }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AddonDetail($id: String!) {
        addonDetail(id: $id) {
          __typename
          active
          content
          createdAt
          createdBy
          draftContent
          draftName
          formatVersion
          goal {
            createdAt
            deletedAt
            displayData
            icon
            id
            name
            purpose
          }
          hasData
          id
          name
          permissions {
            canViewResults
            canViewSettings
            canEdit
            canRename
            canDelete
            canToggle
            canExport
            canShare
          }
          publishedAt
          responseCount
          siteId
          totalViews
          type
        }
      }
    `,
  },
});

export const surveyResultSummaryQuery = ({ id, startDate, endDate }) => ({
  ...addonsFeatureKeys.surveyResultSummary({ id, startDate, endDate }),
  meta: {
    query: gql`
      query SurveyResultSummary($id: ID!, $startDate: Int, $endDate: Int) {
        surveyResultSummary(id: $id, startDate: $startDate, endDate: $endDate) {
          id
          permissions {
            canViewResults
            canViewSettings
            canEdit
            canRename
            canDelete
            canToggle
            canExport
            canShare
          }
          questions {
            id
            keyPhrases {
              count
              value
            }
            lastResponses {
              textResponse
            }
            position
            responseCount
            results {
              count
              value
            }
            totalViews
          }
          responseCount
          totalViews
        }
      }
    `,
  },
});

export const surveyResponsesQuery = ({ id, index, startDate, endDate }) => ({
  ...addonsFeatureKeys.surveyResponses({ id, index, startDate, endDate }),
  meta: {
    query: gql`
      query SurveyResponses($id: ID!, $index: Int!, $startDate: Int, $endDate: Int) {
        surveyResponses(id: $id, index: $index, startDate: $startDate, endDate: $endDate) {
          index
          total
          response {
            createdAt
            formatVersion
            id
            identifier
            language
            questions {
              integerResponse
              questionId
              questionPosition
              questionText
              questionType
              sentiment
              textResponse
            }
            recording {
              ...Recording_recording
              valid
              virtual
            }
            recordingDeletedAt
            surveyUrl
            userId
            visitorId
          }
        }
      }
      ${recordingFragment}
    `,
  },
});

export const surveyResponseListQuery = ({ surveyId, order, limit, startDate, endDate }) => ({
  ...addonsFeatureKeys.surveyResponseList({ surveyId, order, limit, startDate, endDate }),
  getNextPageParam: (lastPage) =>
    lastPage.surveyResponseList.hasNextPage ? lastPage.surveyResponseList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query SurveyResponseList(
        $surveyId: ID!
        $cursor: String
        $order: SurveyResponsesListOrder!
        $limit: Int
        $startDate: Int
        $endDate: Int
      ) {
        surveyResponseList(
          surveyId: $surveyId
          cursor: $cursor
          order: $order
          limit: $limit
          startDate: $startDate
          endDate: $endDate
        ) {
          hasNextPage
          nextPageCursor
          responses {
            createdAt
            formatVersion
            id
            identifier
            language
            permissions {
              canUpgradeToView
            }
            questions {
              integerResponse
              questionId
              questionPosition
              questionText
              questionType
              sentiment
              textResponse
            }
            recording {
              ...Recording_recording
              valid
              virtual
            }
            recordingDeletedAt
            sessionId
            siteId
            surveyId
            surveyUrl
            userId
            visitorId
          }
        }
      }
      ${recordingFragment}
    `,
  },
});

export const ctaResultQuery = ({ id, startAt, endAt }) => ({
  ...addonsFeatureKeys.ctaResult({ id, startAt, endAt }),
  meta: {
    query: gql`
      query CtaResult($id: ID!, $startAt: Int!, $endAt: Int!) {
        ctaResult(id: $id, startAt: $startAt, endAt: $endAt) {
          id
          totalClicks
          totalConversions
          totalViews
          results {
            clicks
            conversions
            date
            views
          }
        }
      }
    `,
  },
});

export const addonTemplatesListQuery = () => ({
  ...addonsFeatureKeys.addonTemplatesList,
  meta: {
    query: gql`
      query AddonTemplatesList {
        addonTemplatesList {
          canManage
          list {
            __typename
            category
            content
            createdAt
            description
            formatVersion
            id
            name
            permissions {
              canManage
              canView
            }
            thumbnail
            type
          }
        }
      }
    `,
  },
});

export const addonTemplateDetailQuery = ({ id }) => ({
  ...addonsFeatureKeys.addonTemplateDetail({ id }),
  meta: {
    query: gql`
      query AddonTemplateDetail($id: Int!) {
        addonTemplateDetail(id: $id) {
          __typename
          category
          content
          createdAt
          description
          formatVersion
          id
          name
          permissions {
            canManage
            canView
          }
          type
        }
      }
    `,
  },
});

export const imageDetailQuery = ({ id, siteId }) => ({
  ...addonsFeatureKeys.imageDetail({ id, siteId }),
  meta: {
    query: gql`
      query ImageDetail($id: ID, $siteId: Int) {
        imageDetail(id: $id, siteId: $siteId) {
          __typename
          filename
          id
          publicUrl
        }
      }
    `,
  },
});

export const imageListQuery = ({ filter, siteId }) => ({
  ...addonsFeatureKeys.imageList({ filter, siteId }),
  getNextPageParam: (lastPage) => (lastPage.imageList.hasNextPage ? lastPage.imageList.nextPageCursor : undefined),
  meta: {
    query: gql`
      query Imagelist($filter: ImageFilter!, $siteId: Int) {
        imageList(filter: $filter, siteId: $siteId) {
          hasNextPage
          nextPageCursor
          list {
            filename
            id
            publicUrl
          }
        }
      }
    `,
  },
});

export const addonSuggestionsListQuery = ({ siteId, limit, type }) => ({
  ...addonsFeatureKeys.addonSuggestionsList({ siteId, limit, type }),
  meta: {
    query: gql`
      query AddonSuggestionsList($siteId: Int!, $limit: Int, $type: AddonSuggestionType!) {
        addonSuggestionsList(siteId: $siteId, limit: $limit, type: $type) {
          data
          id
          title
          type
        }
      }
    `,
  },
});

export const addonSuggestionDetailQuery = ({ id }) => ({
  ...addonsFeatureKeys.addonSuggestionDetail({ id }),
  meta: {
    query: gql`
      query AddonSuggestionDetail($id: Int!) {
        addonSuggestionDetail(id: $id) {
          data
          id
          title
          type
        }
      }
    `,
  },
});
