import { Button } from '@crazyegginc/hatch';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function Navigator({ index, total, updateIndex }) {
  return (
    <div className="mb-3.75 flex items-center justify-end space-x-2.5">
      <span className="text-body-1 text-lynch-500">
        Response {index} of {total}
      </span>
      <div className="flex items-center space-x-0.5">
        <Button variant="secondary" size="lg" onClick={() => updateIndex(index - 1, total)} disabled={total < 2}>
          <ExpandIcon className="h-3 w-3 -rotate-90 fill-current" aria-label="Previous response" />
        </Button>
        <Button variant="secondary" size="lg" onClick={() => updateIndex(index + 1, total)} disabled={total < 2}>
          <ExpandIcon className="h-3 w-3 rotate-90 fill-current" aria-label="Next response" />
        </Button>
      </div>
    </div>
  );
}
