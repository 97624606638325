/* eslint-disable jsx-a11y/media-has-caption */
import { useRef } from 'react';

export function VideoPlayer({
  format = 'video/mp4',
  poster,
  src,
  label = null,
  controls = false,
  muted = true,
  loop = true,
  autoplay = true,
}) {
  const videoRef = useRef(null);

  function startVideoPlayback() {
    const video = videoRef.current;
    if (!video) return;
    // this is required for React to work correctly with videos
    // adding these attributes to the element directly will
    // throw errors in testing
    video.muted = muted;
    video.loop = loop;
    if (autoplay) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch(() => {
          // try to restart playback again later
          setTimeout(() => {
            startVideoPlayback();
          }, 500);
        });
      }
    }
  }

  return (
    <video
      className="w-full"
      ref={videoRef}
      poster={poster}
      aria-label={label}
      onCanPlay={() => startVideoPlayback()}
      controls={controls}
    >
      <source type={format} src={src} />
      Sorry, your browser does not support this video format.
    </video>
  );
}
