import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const gettingStartedFeatureKeys = createQueryKeys('gettingStartedFeature', {
  gettingStarted: null,
});

export const gettingStartedQuery = () => ({
  ...gettingStartedFeatureKeys.gettingStarted,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query GettingStarted {
        gettingStarted {
          slug
          permission {
            allowed
            reason
          }
          status
          dependencies
        }
      }
    `,
  },
});
