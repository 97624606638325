import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { getParam, getInitialValue } from '/src/utils/location';
import { usePermissions, useQueryParams } from '/src//hooks';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_STATUSES } from './constants';

const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
};
const { SET, RESET } = FILTER_ACTIONS;

const FilterContext = createContext();

const initialState = (types) => {
  return {
    type: getInitialValue('type', types),
    search: getParam('search') || '',
    status: getInitialValue('status', Object.values(ADDON_STATUSES)),
  };
};

export function AddonsFilterProvider({ children }) {
  const permissions = usePermissions();
  const canVisitSurveys = permissions.can('navigate', FEATURES.SURVEYS).allowed;
  const canVisitCTAs = permissions.can('navigate', FEATURES.CTAS).allowed;

  const allowedTypes = [];
  if (canVisitSurveys) {
    allowedTypes.push(FEATURES.SURVEYS);
  }
  if (canVisitCTAs) {
    allowedTypes.push(FEATURES.CTAS);
  }
  function filterReducer(state = initialState(allowedTypes), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case RESET:
        return {
          ...initialState(allowedTypes),
          status: null,
        };
      default:
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, initialState(allowedTypes));
  const [filterState, setFilterState] = useState({ filter, filterDispatch });
  if (!isEqual(filter, filterState.filter)) {
    setFilterState({ filter, filterDispatch });
  }
  return <FilterContext.Provider value={filterState}>{children}</FilterContext.Provider>;
}

export function useFilter() {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filter, filterDispatch } = useContext(FilterContext);

  const setTypeFilter = useCallback(
    (type) => {
      queryParamsSet('type', type);
      filterDispatch({
        type: SET,
        payload: {
          type,
        },
      });
    },
    [filterDispatch, queryParamsSet],
  );

  const setSearch = useCallback(
    (search) => {
      queryParamsSet('search', search);
      filterDispatch({
        type: SET,
        payload: {
          search,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  const setStatus = useCallback(
    (status) => {
      queryParamsSet('status', status);
      filterDispatch({
        type: SET,
        payload: {
          status,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  function reset() {
    removeAll(['search', 'status']);
    filterDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  return {
    ...filter,
    setTypeFilter,
    setSearch,
    setStatus,
    reset,
  };
}
