import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { authAtom } from '/src/machines/auth';

export function SharedSessionWrapper() {
  const [state] = useAtom(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.value === 'SHARED_RESOURCE_NOT_FOUND') {
      navigate('/not-found');
    }
  }, [state.value, navigate]);

  return <Outlet />;
}
