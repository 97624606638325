import classNames from 'classnames';
import { capitalizeFirstLetter } from '@crazyegginc/hatch';

import { ANY_VALUE, VISITOR_TYPES } from '../constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function AppliedFilters({ filters }) {
  const filtersApplied = Object.keys(filters).filter((x) => filters[x] !== ANY_VALUE && x !== 'date').length > 0;
  if (!filtersApplied) return null;
  return (
    <>
      <div className="text-header-5">Applied filters:</div>
      <div className="flex flex-wrap items-center">
        {Object.keys(filters)
          .filter(
            (x) => filters[x] !== ANY_VALUE && x !== 'date' && !(x === 'visitor' && filters[x] === VISITOR_TYPES.ALL),
          )
          .map((utmParam, i) => (
            <SelectedFilterParams key={i} selection={filters} utmParam={utmParam} />
          ))}
      </div>
    </>
  );
}

function SelectedFilterParams({ selection, utmParam }) {
  return (
    <>
      <div
        className={classNames(
          'mb-1 flex h-6 min-w-0 cursor-default items-center rounded px-1 text-sm font-semibold text-white',
          {
            'bg-[#FF6C72]': utmParam === 'utmSource',
            'bg-[#56C7D7]': utmParam === 'utmMedium',
            'bg-[#5686D7]': utmParam === 'utmCampaign',
            'bg-[#D7568D]': utmParam === 'utmTerm',
            'bg-[#836DF0]': utmParam === 'utmContent',
            'bg-[#00C19F]': utmParam === 'referrerUrl',
            'bg-[#00b34d]': utmParam === 'landingPage',
            'bg-[#c25700]': utmParam === 'abTestName',
            'bg-[#b87400]': utmParam === 'abTestVariationName',
            'bg-[#4286bd]': utmParam === 'visitor',
          },
        )}
      >
        {getNameForParam(utmParam)}:
        <div
          className={classNames('ml-1 flex h-5 min-w-0 items-center rounded-sm px-1', {
            'bg-[#C25761]': utmParam === 'utmSource',
            'bg-[#439CAD]': utmParam === 'utmMedium',
            'bg-[#436BAD]': utmParam === 'utmCampaign',
            'bg-[#A44775]': utmParam === 'utmTerm',
            'bg-[#6558BF]': utmParam === 'utmContent',
            'bg-[#039783]': utmParam === 'referrerUrl',
            'bg-[#008a3c]': utmParam === 'landingPage',
            'bg-[#a83800]': utmParam === 'abTestName',
            'bg-[#996100]': utmParam === 'abTestVariationName',
            'bg-[#0056b8]': utmParam === 'visitor',
          })}
        >
          <div className="flex">
            <div className={classNames('relative')}>{getParamValue(utmParam, selection[utmParam]) || 'Not set'}</div>
          </div>
        </div>
      </div>
      <PlusIcon className="mx-2 mb-1 h-2 w-2 fill-current text-black-pearl-500 last:hidden" />
    </>
  );
}

export const getNameForParam = (utmParam) => {
  switch (utmParam) {
    case 'visitor':
      return 'Visitor';
    case 'referrerUrl':
      return 'Referrer';
    case 'utmCampaign':
      return 'Campaign name';
    case 'landingPage':
      return 'Landing Page';
    case 'abTestName':
      return 'A/B Test Name';
    case 'abTestVariationName':
      return 'A/B Test Variation Name';
    default:
      return `Campaign ${utmParam.replace('utm', '')}`;
  }
};

function getParamValue(param, value) {
  switch (param) {
    case 'visitor':
      return capitalizeFirstLetter(value.toLowerCase());
    default:
      return value;
  }
}
