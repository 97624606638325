import { getIn, useFormikContext } from 'formik';
import { Tooltip } from '@crazyegginc/hatch';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

import { PROVISIONING_CONDITION_TYPES, PROVISIONING_ACTION_TYPES } from '/src/features/sso/constants';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';
import { ProvisioningActionSelect } from '../selects/ProvisioningActionSelect';
import { ProvisioningRoleSelect } from '../selects/ProvisioningRoleSelect';
import { SitesSelector } from './SitesSelector';

export function ProvisioningActionRow({ index }) {
  const { values } = useFormikContext();
  const action = getIn(values, `provisioningRules.[${index}].action`);
  const conditions = getIn(values, `provisioningRules.[${index}].conditions`);
  const hasFallbackCondition = !!conditions.find((condition) => condition.type === PROVISIONING_CONDITION_TYPES.ANYONE);

  return (
    <div className="text-body-2 flex flex-col items-start gap-x-1.5 gap-y-1.5 leading-[35px]">
      <div className="flex items-center gap-x-1.5 leading-none">
        {hasFallbackCondition ? 'For all other cases' : 'Then'}
        {action.type === PROVISIONING_ACTION_TYPES.CREATE ? `, if the user doesn't exist yet` : null}
        <ProvisioningActionSelect index={index} />
        {action.type === PROVISIONING_ACTION_TYPES.CREATE ? 'with the following access:' : null}
        {hasFallbackCondition ? (
          <div className="flex h-10 items-center">
            <Tooltip
              placement="right"
              tooltipContent={
                <div className="max-w-[150px] items-center">
                  This is the catch-all rule, this rule cannot be re-ordered or removed
                </div>
              }
            >
              <InfoIcon className="mr-2.5 h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
            </Tooltip>
          </div>
        ) : null}
      </div>
      {action.type === PROVISIONING_ACTION_TYPES.CREATE ? (
        <>
          <div className="flex items-center gap-x-1.5">
            Permissions <ProvisioningRoleSelect index={index} />
          </div>
          {action.role !== ACCOUNT_USER_ROLES.OWNER ? (
            <div>
              <SitesSelector index={index} />
            </div>
          ) : null}
        </>
      ) : (
        <div className="text-info-body mb-7 flex max-w-[664px] rounded bg-white-lilac-500 px-3.5 py-2.5">
          <InfoIcon className="mr-2.5 h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
          <div className="leading-normal">
            Existing team members who match this rule will continue to have access to your account. You can remove any
            existing team members from Crazy Egg in the <a href={`/account/team`}>Team Member</a> settings. Anyone who
            is not a team member yet and matches this rule will be denied access.
          </div>
        </div>
      )}
    </div>
  );
}
