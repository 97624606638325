import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { getSurveyQueryParams } from '/src/utils/url';
import { getFeatureName } from '/src/features/_global/utils';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

export function BackToDashboardButton({ type }) {
  const typeText = type ? getFeatureName(type) : getFeatureName(FEATURES.ADDONS);

  return (
    <Button
      component={Link}
      variant="ghost-primary"
      to={{
        pathname: `/addons`,
        search: getSurveyQueryParams({ type }),
      }}
      size="xl"
      className="text-center leading-none"
    >
      <ArrowIcon className="mr-2 h-3 w-3 shrink-0 rotate-180 fill-current" />
      Back to all {typeText}
    </Button>
  );
}
