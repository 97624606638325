import { memo, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useSelector } from '@xstate5/react';

import { msToHHMMSS } from '/src/utils/date';
import { useModal } from '/src/hooks';

import { WebP } from '/src/components/WebP';
import { ErrorDetailsModal } from './modals/ErrorDetailsModal';
import { PLAYER_EVENTS } from '/src/features/recordings/constants';

import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';
import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-page-outline.svg';
import { ReactComponent as ArrowExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

import ConsolePNG from '@crazyegginc/hatch/dist/images/illustration-console.png';
import ConsoleWebP from '@crazyegginc/hatch/dist/images/illustration-console.webp';

const { PAGE, VISIT, ERROR_EVENT } = PLAYER_EVENTS;

export function generateEventTags(event) {
  switch (event.type) {
    case PAGE:
      return [PAGE];
    case VISIT:
      return [VISIT];
    case ERROR_EVENT:
      return [ERROR_EVENT, 'bug'];
  }
}

export function getDevToolEvents(events) {
  let parsedDevToolsEvents = [];
  for (const [idx, event] of events.entries()) {
    if (event.type === VISIT || event.type === PAGE) {
      parsedDevToolsEvents.push({ ...event, events: [] });
    } else {
      const endTimestamp = events?.[idx + 1]?.timestamp ?? events[idx].timestamp + 1000;
      if (event.type === ERROR_EVENT) {
        parsedDevToolsEvents[parsedDevToolsEvents.length - 1]?.events?.push?.({
          ...event,
          endTimestamp,
          tags: generateEventTags(event),
        });
      }
    }
  }
  return parsedDevToolsEvents.filter((e) => e.events.length !== 0);
}

function DevToolsComponent({ actorRef, onSeek = null, siteId }) {
  const [isOpen, setIsOpen] = useState({});
  const modal = useModal();

  const devToolsEvents = useSelector(actorRef, (state) => state.context.devToolsEvents);

  const toggleOpen = useCallback(
    (index) => {
      setIsOpen({ ...isOpen, [index]: !isOpen[index] });
    },
    [isOpen],
  );

  return (
    <section className="invisible absolute bottom-0 left-0 z-10 hidden w-full bg-woodsmoke-500 pb-20 shadow-2xl md:visible md:block">
      <div className="h-80">
        {/* HEAD */}
        <div className="mb-3.5 flex justify-between border-b border-white border-opacity-5 px-2.5">
          {/* LEFT SIDE FOR CONSOLE AND NETWORK TABS */}
          <ul role="tablist">
            <li>
              <button
                role="tab"
                aria-controls="console-log"
                className="group pointer-events-none flex items-center border-b-4 border-dodger-blue-500 py-5 pl-3 pr-3.5 text-base font-semibold leading-none text-white"
              >
                <CodeIcon aria-label="console" className="mr-1.25 h-5 w-5 fill-current text-white" />
                Console
              </button>
            </li>
          </ul>

          {/* RIGHT SIDE FOR LIST OF FILTER, SEARCH BAR AND CLOSE BUTTON */}
          <div className="flex items-center">
            <button
              className="group ml-auto flex h-10 w-9 items-center justify-center rounded"
              onClick={() => actorRef.send({ type: 'TOGGLE_DEVTOOLS' })}
            >
              <CloseIcon
                aria-label="close search"
                className="h-4 w-4 fill-current text-cadet-blue-500 group-hover:text-white"
              />
            </button>
          </div>
        </div>

        {/* BODY */}
        <div className="relative z-0 pr-1" aria-live="polite" role="region">
          {devToolsEvents.length > 0 ? (
            <div
              className="relative z-0 h-60 overflow-y-scroll pb-6 pl-4 pr-1 scrollbar scrollbar-thumb-mako-500 scrollbar-track-woodsmoke-500"
              role="tabpanel"
              id="console-log"
            >
              <ul>
                {devToolsEvents.map((page, index) => (
                  <li className="mb-1.25 rounded bg-charade-500" key={`${page.timestamp}:${page.url}`}>
                    <button
                      className={classNames(
                        'group flex w-full items-center rounded-t px-3 py-2.5 text-xs font-bold leading-none !outline-none transition duration-150 ease-in-out',
                        {
                          'rounded-b text-picasso-500 hover:bg-dodger-blue-500 hover:text-white ': isOpen[index],
                          'bg-dodger-blue-500 text-white': !isOpen[index],
                        },
                      )}
                      onClick={() => {
                        toggleOpen(index);
                      }}
                    >
                      <ArrowExpandIcon
                        aria-label="expand"
                        className={classNames(
                          'mr-3.5 h-3 w-3 self-center fill-current transition duration-150 ease-in-out',
                          {
                            'rotate-180 text-picasso-500 group-hover:text-white': isOpen[index],
                            'rotate-0 text-white': !isOpen[index],
                          },
                        )}
                      />
                      <PageIcon
                        aria-label="page"
                        className={classNames('mr-2.5 h-5 w-5 fill-current', {
                          'text-picasso-500 group-hover:text-white': isOpen[index],
                          ' text-white': !isOpen[index],
                        })}
                      />
                      <span className="truncate">{page.url}</span>
                    </button>
                    {!isOpen[index] && (
                      <ul>
                        {page.events.map((event) => {
                          const formattedTimestamp = msToHHMMSS(event.timestamp);
                          const [minutes, seconds] = formattedTimestamp.split(':');
                          return (
                            <li
                              className="flex border-t border-dashed border-mako-500 py-3 pl-10 pr-6 text-xs leading-none text-cadet-blue-500"
                              key={`${event.timestamp}:${event.name}`}
                            >
                              <button
                                className="group flex w-full items-center justify-between transition duration-150 ease-in-out"
                                onClick={() => {
                                  onSeek?.(event.timestamp, { isTimestamp: true });
                                  modal.show(<ErrorDetailsModal errorId={event.uuid} siteId={siteId} />);
                                }}
                              >
                                <div className="flex flex-1 items-center leading-none">
                                  <ErrorIcon
                                    aria-label="error"
                                    className="mr-2.5 h-4 w-4 fill-current text-carnation-500 transition duration-150 ease-in-out group-hover:text-white"
                                  />
                                  <span className="truncate transition duration-150 ease-in-out group-hover:text-white">
                                    {event.name}
                                  </span>
                                </div>
                                <time
                                  className="w-10 truncate text-right transition duration-150 ease-in-out group-hover:text-white"
                                  dateTime={`PT${minutes}M${seconds}S`}
                                >
                                  {formattedTimestamp}
                                </time>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="flex items-center justify-center pt-5">
              <div className="mr-16">
                <WebP webp={ConsoleWebP} fallback={ConsolePNG} width="212px" height="195px" />
              </div>
              <div>
                <h3 className="text-header-2 mb-4 text-white">No results</h3>
                <p className="text-body-2 mb-3.5 w-96 pr-11 text-cadet-blue-500">
                  No errors were found on any pages in this recording.
                  <br />
                  Any Javascript errors we find will appear in this Developer Console.
                </p>
              </div>
            </div>
          )}
          <div className="absolute bottom-0 z-10 h-8 w-full bg-gradient-to-t from-woodsmoke-500 opacity-30"></div>
        </div>
      </div>
    </section>
  );
}

export const DevTools = memo(DevToolsComponent);
