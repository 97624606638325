import { useState } from 'react';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { useMutation, useNotifications, useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { SNAPSHOT_ADDON_UNLIMITED } from '/src/features/account/constants';
import { sendAddonDetailEmailMutation } from '/src/features/account/mutations';

export function SnapshotAddonModal({ planDetail, email }) {
  const modal = useModal();
  const addonDetailEmail = useMutation(sendAddonDetailEmailMutation);
  const notifications = useNotifications();
  const [sending, setSending] = useState(false);

  return (
    <Modal dialogClassName="!w-[470px] !p-0">
      <div className="border-b border-mystic-500 p-7">
        <h2 className="text-header-3 mb-4">Confirm Plan Add-on</h2>
        <p className="text-body-2 mb-6">
          Your request to add{' '}
          <strong>{`${planDetail.snapshots} ${
            planDetail.type !== SNAPSHOT_ADDON_UNLIMITED ? 'extra' : ''
          } Snapshots`}</strong>{' '}
          add-on will be sent to our team. We’ll be in touch via email to confirm the final amount.
        </p>
        <div className="flex justify-between rounded-md border border-dashed border-cadet-blue-500 bg-white-lilac-500 py-6 pl-5 pr-6">
          <div className="flex flex-col">
            <span className="mb-0.5 text-xs text-lynch-500">Plan Add-on</span>
            <span className="text-base font-semibold text-black-pearl-500">{`${planDetail.snapshots} ${
              planDetail.type !== SNAPSHOT_ADDON_UNLIMITED ? 'extra' : ''
            } Snapshots`}</span>
          </div>
          <div className="w-[140px]">
            <div className="mb-0.5 flex w-full justify-end">
              <span className=" self-start text-md">$</span>
              <span className="self-start text-[32px] leading-none">{planDetail.costPerMonth}</span>
              <span className="ml-0.5 self-end text-base"> / month</span>
            </div>
            <p className="w-full pr-5 text-right text-xs text-lynch-500">
              ${(planDetail.costPerMonth * 12).toLocaleString()} / year
            </p>
          </div>
        </div>
      </div>
      <div className="p-7">
        <p className="text-body-2 mb-3">We’ll send an email to this address to complete this request.</p>
        <Input name="sendEmail" id="send-email-input" value={email} autoComplete="off" disabled />
        <div className="flex space-x-1 pt-6">
          <Button
            leftIcon={sending ? <Spinner className="mr-1 h-3 w-3" /> : null}
            onClick={() => {
              setSending(true);
              addonDetailEmail.mutate(
                {
                  email: {
                    fromEmail: email,
                    fromName: email,
                    message: `Snapshots Addon for ${planDetail.snapshots}, price ${planDetail.costPerMonth} per month.`,
                  },
                },
                {
                  onError: (error) =>
                    notifications.error({ content: `Failed to send email.`, timeout: 3000, context: { error } }),
                  onSuccess: () =>
                    notifications.success({
                      title: 'Nice work!',
                      content: `Your request has been sent, we'll email once complete.`,
                      timeout: 3000,
                    }),
                  onSettled: () => {
                    setSending(false);
                    modal.close();
                  },
                },
              );
            }}
          >
            {sending ? 'Sending...' : 'Confirm Plan Add-on'}
          </Button>
          <Button variant="cancel" disabled={sending} onClick={() => modal.close()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
