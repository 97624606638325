import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { produce } from 'immer';
import { getUnixTime } from 'date-fns';

import { getInitialDate } from '/src/utils/date';
import { useQueryParams } from '/src/hooks';
import { getParam, getInitialValue } from '/src/utils/location';
import { SPECIAL_DATE_RANGES, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { ERROR_STATUSES, UNIQUE_ERRORS_ORDER, ERRORS_FILTER_ACTIONS } from '/src/features/errors/constants';

const { SET_ORDER_FIELD, SET_ORDER_SORT, SET_SEARCH, SET_DATE_RANGE, SET_STATUS } = ERRORS_FILTER_ACTIONS;

const FilterContext = createContext();

const initialState = () => ({
  queryParams: {
    order: {
      field: getInitialValue('order', [...Object.values(UNIQUE_ERRORS_ORDER)]) || UNIQUE_ERRORS_ORDER.LAST_SEEN,
      sort: getInitialValue('sort', [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || SORT_ORDER_TYPES.DESC,
    },
    filters: null,
    search: getParam('search') || undefined,
    dateRange: getInitialDate(getParam('date')) ?? { special: SPECIAL_DATE_RANGES.LAST_30_DAYS },
    status:
      getInitialValue('status', [ERROR_STATUSES.UNRESOLVED, ERROR_STATUSES.RESOLVED, ERROR_STATUSES.MUTED]) ||
      ERROR_STATUSES.UNRESOLVED,
  },
});

export function ErrorsFilterProvider({ children }) {
  function reducer(state, action) {
    switch (action.type) {
      case SET_ORDER_FIELD:
        return produce(state, (draft) => {
          draft.queryParams.order.field = action.payload;
        });

      case SET_ORDER_SORT:
        return produce(state, (draft) => {
          draft.queryParams.order.sort = action.payload;
        });

      case SET_SEARCH:
        return produce(state, (draft) => {
          draft.queryParams.search = action.payload;
        });

      case SET_DATE_RANGE:
        return produce(state, (draft) => {
          draft.queryParams.dateRange = action.payload;
        });

      case SET_STATUS:
        return produce(state, (draft) => {
          draft.queryParams.status = action.payload;
        });
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState());

  const [contextValue, setContextValue] = useState({ state, dispatch });

  if (!isEqual(state, contextValue.state)) {
    setContextValue({ state, dispatch });
  }

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
}

export function useErrorsFilter() {
  const { state, dispatch } = useContext(FilterContext);
  const { set: queryParamsSet } = useQueryParams();

  const setSort = useCallback(
    (sort) => {
      queryParamsSet('sort', sort);
      dispatch({ type: SET_ORDER_SORT, payload: sort });
    },
    [dispatch, queryParamsSet],
  );

  const setOrder = useCallback(
    (order) => {
      queryParamsSet('order', order);
      dispatch({ type: SET_ORDER_FIELD, payload: order });
    },
    [dispatch, queryParamsSet],
  );

  const setSearch = useCallback(
    (search) => {
      queryParamsSet('search', search);
      dispatch({ type: SET_SEARCH, payload: search });
    },
    [queryParamsSet, dispatch],
  );

  const setDateRange = useCallback(
    (value) => {
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({
            start_date: getUnixTime(value.start_date),
            end_date: getUnixTime(value.end_date),
          }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
      dispatch({ type: SET_DATE_RANGE, payload: value });
    },
    [queryParamsSet, dispatch],
  );

  const setStatus = useCallback(
    (status) => {
      queryParamsSet('status', status);
      dispatch({ type: SET_STATUS, payload: status });
    },
    [queryParamsSet, dispatch],
  );

  return {
    ...state,
    setSort,
    setOrder,
    setSearch,
    setDateRange,
    setStatus,
  };
}
