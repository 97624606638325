import { useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { CheckScript } from '/src/components/check-script/CheckScript';
import { useWizard } from '/src/hooks';
import { WizardHeader } from '/src/components/wizard/legacy/wizard-ui';

export function CheckScriptPage() {
  const params = useParams();
  const location = useLocation();
  const { set: wizardSet, setNextEnabled: wizardSetNextEnabled } = useWizard();

  const method = params?.page ?? 'your';

  const humanizeMethod = (method) => {
    switch (method) {
      case 'manually':
        return 'manual';
      default:
        return 'your';
    }
  };

  useEffect(() => {
    wizardSet({
      back: location.pathname.replace('/check-script', ''),
      backEnabled: true,
      next: '/',
      nextEnabled: false,
      title: `Check ${humanizeMethod(method)} installation`,
      currentStep: 3,
      totalSteps: 3,
    });
  }, [wizardSet, method, location.pathname]);

  const onSuccess = useCallback(() => wizardSetNextEnabled(true), [wizardSetNextEnabled]);
  const onError = useCallback(() => wizardSetNextEnabled(false), [wizardSetNextEnabled]);

  return (
    <div className="flex flex-col items-start justify-start">
      <WizardHeader>Alright, let&#39;s check your installation!</WizardHeader>
      <CheckScript onSuccess={onSuccess} onError={onError} origin={location.pathname} />
    </div>
  );
}
