import React from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Button, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { DEVICE_TYPES } from '/src/features/_global/constants';

const { DESKTOP, TABLET, PHONE } = DEVICE_TYPES;

export const devices = [DESKTOP, TABLET, PHONE];

function humanizeString(input) {
  switch (input) {
    case DESKTOP:
      return 'Desktop site';
    case PHONE:
      return 'Mobile site';
    case TABLET:
      return 'Tablet site';
  }
}

export function DeviceSelect({ value, ...props }) {
  return (
    <Listbox {...props}>
      {({ open }) => (
        <>
          <div className="relative flex-shrink-0">
            <Listbox.Button as={Button} variant="secondary" size="xl" className={open ? '!border-dodger-blue-500' : ''}>
              {humanizeString(value)}
              <Indicator type="dropdown" className="ml-2.5" />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute left-1/2 z-10 mt-1 max-h-56 w-[135px] -translate-x-1/2 overflow-auto rounded bg-white px-4 py-2.5 shadow-md focus:outline-none"
              >
                {devices.map((device) => (
                  <PopoverItem
                    component={Listbox.Option}
                    key={device}
                    value={device}
                    className="!block !cursor-pointer select-none"
                  >
                    {humanizeString(device)}
                  </PopoverItem>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
