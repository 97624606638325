import { VisitorSection } from './components/sections/Visitors';
import { GoalsSection } from './components/sections/Goals';
import { ABTestingSection } from './components/sections/ABTesting';
import { SurveysSection } from './components/sections/Surveys';
import { CTAsSection } from './components/sections/CTAs';
import { RecordingsSection } from './components/sections/Recordings';
import { SnapshotsSection } from './components/sections/Snapshots';
import { WhatsNewSection } from './components/sections/WhatsNew';
import { SuggestionsSection } from './components/sections/Suggestions';
import { LiveSection } from './components/sections/Live';

export const DATE_RANGE_OPTIONS = [
  { value: 1, label: 'Last 24 hours' },
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
];

export const SECTIONS = [
  { name: "What's new", Component: WhatsNewSection },
  { name: 'Suggestions', Component: SuggestionsSection },
  { name: 'Visitors', Component: VisitorSection },
  { name: 'Goals', Component: GoalsSection },
  { name: 'A/B Testing', Component: ABTestingSection },
  { name: 'Surveys', Component: SurveysSection },
  { name: 'CTAs', Component: CTAsSection },
  { name: 'Recordings', Component: RecordingsSection },
  { name: 'Snapshots', Component: SnapshotsSection },
  { name: 'Live Activity', Component: LiveSection },
];
