import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const teamAndSharingFeatureKeys = createQueryKeys('teamAndSharingFeature', {
  accountUsers: ({ order }) => [{ order }],
  sharedUrls: ({ limit, order, page, status, searchTerm }) => [{ limit, order, page, status, searchTerm }],
});

export const accountUsersQuery = ({ order }) => ({
  ...teamAndSharingFeatureKeys.accountUsers({ order }),
  meta: {
    query: gql`
      query AccountUsers($order: AccountUsersOrder!) {
        accountUsers(order: $order) {
          id
          name
          lastActive
          invitedDate
          sites
          email
          role
          invitationPending
          specificSites
          invitationUrl
          secondFactorEnabled
        }
      }
    `,
  },
});

export const sharedUrlsQuery = ({ limit, order, page, status, searchTerm }) => ({
  ...teamAndSharingFeatureKeys.sharedUrls({ limit, order, page, status, searchTerm }),
  staleTime: 0,
  meta: {
    query: gql`
      query SharedUrls(
        $limit: Int!
        $order: SharedUrlsOrder!
        $page: Int!
        $status: SharedUrlsStatus!
        $searchTerm: String
      ) {
        sharedUrls(limit: $limit, order: $order, page: $page, status: $status, searchTerm: $searchTerm) {
          meta {
            total
            totalPages
            page
            perPage
          }
          sharedUrls {
            createdAt
            fullPath
            id
            itemId
            itemType
            lastViewedAt
            note
            sharedByUserEmail
            sharedByUserId
            sharedByUserName
            status
            title
            totalViews
          }
        }
      }
    `,
  },
});
