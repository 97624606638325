import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const whatsNewFeatureKeys = createQueryKeys('whatsNewFeature', {
  changelog: ({ newOnly }) => [{ newOnly }],
});

export const changeLogQuery = ({ newOnly }) => ({
  ...whatsNewFeatureKeys.changelog({ newOnly }),
  meta: {
    query: gql`
      query Changelog($newOnly: Boolean!) {
        changelog(newOnly: $newOnly) {
          id
          title
          contentHtml
          category
          tags
          version
          featureFlag
          publishedAt
          attachments {
            id
            assetUrl
            assetType
            assetSource
          }
          seenAt
          reaction
        }
      }
    `,
  },
});
