import { useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { accountUsersQuery } from '/src/features/team-and-sharing/queries';

import { escapeRegexSpecialChars } from '/src/utils/regex';
import { useModal, useSelection } from '/src/hooks';
import { useFilter } from '../../account-users-filter-context';
import { BulkAction } from '/src/components/bulk-action/BulkAction';
import { BULK_ACTIONABLE_ITEMS } from '/src/features/_global/constants';

import { AccountUsersTable } from './AccountUsersTable';
import { NoTeamMembers } from './paywalls/NoTeamMembers';
import { InviteUserModal } from './modals/InviteUserModal';

export function AccountUsersTeam({ siteList, search }) {
  const filters = useFilter();
  const modal = useModal();
  const { clearSelection } = useSelection();

  useEffect(() => {
    clearSelection();
  }, [clearSelection]);

  const { data, isFetching, isLoading } = useQuery({
    ...accountUsersQuery({
      order: {
        field: filters.order.field,
        sort: filters.order.sort,
      },
    }),
  });

  const filterUsers = useMemo(() => {
    const users = data?.accountUsers ?? [];
    const regexp = new RegExp(`${escapeRegexSpecialChars(search) || ''}`, 'gi');

    return search ? users.filter((user) => user.name?.match(regexp) || user.email?.match(regexp)) : users;
  }, [search, data?.accountUsers]);

  if (!isFetching && filterUsers.length === 0) {
    const isFilterSearch = search?.length > 0;
    return (
      <div className="mt-5 flex w-full items-center justify-center">
        <NoTeamMembers
          actionButton={isFilterSearch ? filters.reset : () => modal.show(<InviteUserModal />)}
          headerText={isFilterSearch ? 'No matches found' : 'No team members'}
          text={
            isFilterSearch
              ? 'We could not find any matches for this search'
              : 'There are no team members in your account, click the button below to invite your team members'
          }
          actionText={isFilterSearch ? 'Clear search' : 'Invite members'}
        />
      </div>
    );
  }

  return (
    <>
      <AccountUsersTable users={filterUsers} fetching={isLoading} siteList={siteList} />
      <BulkAction feature={BULK_ACTIONABLE_ITEMS.ACCOUNT_USERS} siteList={siteList} />
    </>
  );
}
