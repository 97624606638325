import { useLayoutEffect } from 'react';

export function useLockBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // re-enable scrolling when the component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []);
}
