import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageCameraApi } from '/src/features/snapshots/components/creation-flow/PageCameraApi';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';
import { pageCameraSetScreenshotMutation } from '/src/features/snapshots/mutations';
import { useMutation, useNotifications } from '/src/hooks';

import { getParam } from '/src/utils/location';
import { isNumber } from '/src/utils';

export function RecapturePage() {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const {
    isSuccess,
    isError,
    isIdle,
    mutateAsync: mutateSetScreenshotStatus,
  } = useMutation(pageCameraSetScreenshotMutation);

  const isNewFlow = useMemo(() => {
    const isComplete = getParam('complete') === 'true';
    const isPreUpload = getParam('preUpload') === 'true';
    const snapshotId = parseInt(getParam('snapshot'));

    if (isComplete && isPreUpload && isNumber(snapshotId) && snapshotId > 0) {
      return true;
    }
    return false;
  }, []);

  const snapshotId = useMemo(() => {
    return parseInt(getParam('snapshot'));
  }, []);

  useEffect(() => {
    if (isNewFlow && snapshotId && isIdle) {
      mutateSetScreenshotStatus({ snapshotId, recapture: true });
    }
  }, [isNewFlow, snapshotId, isIdle, mutateSetScreenshotStatus]);

  useEffect(() => {
    if (isSuccess) {
      notifications.success({
        content: `Snapshot recapture queued successfully!`,
        timeout: 3000,
      });
      navigate('/snapshots', { replace: true });
    } else if (isError) {
      notifications.error({
        content: `Failed to process your Snapshot recapture.`,
        timeout: 3000,
      });
      navigate('/snapshots', { replace: true });
    }
  }, [isSuccess, isError, navigate, notifications]);

  if (!isNewFlow) {
    return <PageCameraApi />;
  }
  return <LoaderBalloonScreen text="Updating your Snapshot..." />;
}
