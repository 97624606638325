import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { runChecks } from '@crazyegginc/compatibility-checker';

import { usePrevious, useAuthContext } from '/src/hooks';
import { isDevelopment, isE2E } from '/src/utils';

export function useGeneralCompatibilityCheck() {
  const location = useLocation();
  const { pathname } = location;
  const prevPathname = usePrevious(pathname);

  const [running, setRunning] = useState(false);
  const { currentUser } = useAuthContext();
  const isDev = isDevelopment();
  const isE2Etest = isE2E();

  useEffect(() => {
    async function checkCompatibility() {
      setRunning(true);
      const features = ['SHELL'];

      if (pathname.match(/recordings\/.+\/player/)) {
        features.push('PLAYER');
      }

      await runChecks({
        features,
        enableServerErrorNotification: true,
        enableClientErrorNotification: true,
        userData: { user_email: currentUser?.email, user_id: currentUser?.id },
      });

      setRunning(false);
    }

    if (!isDev && !isE2Etest && !running && prevPathname !== pathname) {
      checkCompatibility();
    }
  }, [pathname, prevPathname, running, currentUser, isDev, isE2Etest]);
}
