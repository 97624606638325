import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import { useAuthContext } from '/src/hooks';
import { editGoalMachine } from '/src/features/goals/machines/wizard';
import { GoalsWizard } from '/src/features/goals/components/GoalsWizard';

export function Component() {
  const params = useParams();
  const navigate = useNavigate();
  const abTestId = params?.id ?? null;

  const { currentUser } = useAuthContext();
  const { defaultCurrency } = currentUser.settings;

  const [, , service] = useMachine(editGoalMachine, {
    devTools: true,
    context: {
      nextText: 'Save goal',
      goalId: Number(params.goalId),
      previousPath: abTestId ? `/ab-tests/${abTestId}/goals` : `/goals/${params.goalId}`,
      defaultCurrency: defaultCurrency || 'USD',
    },
  });

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.matches('completed')) {
        navigate(`/goals/${params.goalId}`, { replace: true });
      }

      if (state.matches('cancelled')) {
        navigate(`/goals/${params.goalId}`, { replace: true });
      }
    });

    return subscription.unsubscribe;
  }, [navigate, service, params.goalId]);

  return <GoalsWizard goalId={Number(params.goalId)} service={service} />;
}
