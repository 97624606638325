import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from '/src/hooks';
import { sendMetric } from '/src/utils/metrics';

export function usePageViews() {
  const { sessionInfo, currentUser } = useAuthContext();

  let location = useLocation();

  useEffect(() => {
    if (sessionInfo && sessionInfo.isAuthenticated && currentUser) {
      sendMetric('internal_url_visited', {
        source: 'shell_v2',
        user_id: currentUser.userId,
        guest_id: currentUser.guestId,
        session_id: window.CE2?.tracker?.session?.id,
        url: location.pathname + (location.search ? location.search : ''),
        admin: sessionInfo.isImpersonated,
      });
    }
  }, [location, currentUser, sessionInfo]);
}
