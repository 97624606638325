import { CloseButton, Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { WebP } from '/src/components/WebP';

import LockedPNG from '@crazyegginc/hatch/dist/images/illustration-locked.png';
import LockedWebP from '@crazyegginc/hatch/dist/images/illustration-locked.webp';

export function SsoRehydrateModal({ onConfirm, onDismiss }) {
  const modal = useModal();

  return (
    <Modal dialogClassName="!px-5 !py-8 !items-center" dismissable={false} disableOutsideClick={true}>
      <CloseButton
        label="close modal"
        onClick={() => {
          onDismiss?.();
          modal.close();
        }}
      />
      <div className="flex flex-col">
        <WebP
          className="mx-auto mb-4 w-[150px]"
          webp={LockedWebP}
          fallback={LockedPNG}
          width="150"
          alt="Found saved settings"
        />
        <h2 className="text-header-3 mb-5">We found saved changes</h2>
      </div>
      <div className="flex flex-col">
        <span className="text-body-2">Do you want to restore the saved state or discard the saved settings?</span>
      </div>

      <Modal.Actions>
        <Button
          onClick={() => {
            onConfirm?.();
            modal.close();
          }}
        >
          Restore
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            onDismiss?.();
            modal.close();
          }}
          actionType="soft"
        >
          Discard
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
