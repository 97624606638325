import { useEffect } from 'react';
import { Button } from '@crazyegginc/hatch';

import { SupportLinks } from '/src/support';
import { WebP } from '/src/components/WebP';

import CrashBalloonPNG from '@crazyegginc/hatch/dist/images/illustration-balloon-error.png';
import CrashBalloonWebP from '@crazyegginc/hatch/dist/images/illustration-balloon-error.webp';

export function NoAccountsErrorPage() {
  function navigateToLogin() {
    return window.location.replace(`${window.LEGACY_APP_URL}/login`);
  }

  useEffect(() => {
    window.Mocky?.stop();
  }, []);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <div className="flex max-w-[600px] flex-col">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="mx-8 mb-8 flex flex-col justify-center md:mb-0">
            <WebP webp={CrashBalloonWebP} fallback={CrashBalloonPNG} width="160px" alt="Crashing balloon" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <h1 className="text-header-0 mb-3 font-normal">Bother!</h1>
            <h2 className="text-header-1">We couldn&apos;t find any accounts for you.</h2>
            <div className="text-body-2">You might need to ask your account owner to invite you again.</div>
            <div className="text-body-2">If you believe this is a mistake, please contact support.</div>
            <div className="!mt-5 flex space-x-2.5">
              <Button
                href={SupportLinks.general.newSupportRequest}
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                className="!mt-0"
                variant="secondary"
                size="lg"
              >
                Contact support
              </Button>
              <Button onClick={() => navigateToLogin()} size="lg" variant="ghost-primary">
                Back to login
              </Button>
            </div>
            {window.ceSessionId ? <div className="text-body-3 mt-5">Session ID: {window.ceSessionId}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
