import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';

import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { generateCTAEditorFormat } from '/src/features/addons/ctas/editor/editor-functions';
import { addonTemplateDetailQuery } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function EditAddonTemplate() {
  const { id } = useParams();

  const { data, isFetching } = useQuery({
    ...addonTemplateDetailQuery({ id: parseInt(id) }),
    enabled: Boolean(id),
  });

  const template = data?.addonTemplateDetail;

  if (!template && isFetching) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }

  if (!id || (!template && !isFetching)) {
    return <Navigate replace to="/addons/templates" />;
  }

  const { type, name, content, description, category } = template;

  switch (type) {
    case ADDON_TYPES.SURVEY: {
      const surveyData = generateSurveyEditorFormat({ name, content, description, category });
      return <SurveyEditor id={id} initData={surveyData} isTemplate={true} />;
    }
    case ADDON_TYPES.CTA: {
      const ctaData = generateCTAEditorFormat({ name, content, description, category });
      return <CTAEditor id={id} initData={ctaData} isTemplate={true} />;
    }
    default:
      return <Navigate replace to="/addons/templates" />;
  }
}
