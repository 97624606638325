import { FEATURES, PERMISSION_NOT_ALLOWED, ALLOWED } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

export const ADDONS_PERMISSIONS = {
  [FEATURES.ADDONS]: ({ capabilities }) => ({
    navigate: capabilities?.addons.permissions.nav,
    manageTemplates: capabilities?.addons.permissions.manageTemplates,
    view:
      capabilities?.ctas.permissions.view.allowed || capabilities?.surveys.permissions.view.allowed
        ? ALLOWED
        : capabilities?.surveys.permissions.view,
  }),

  [FEATURES.SURVEYS]: ({ capabilities, isSharing, sharedResource }) => {
    const sharedResourceIsSurvey = sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.SURVEY;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsSurvey ? ALLOWED : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
          create: PERMISSION_NOT_ALLOWED,
          removeBranding: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.surveys.permissions.nav,
          view: capabilities.surveys.permissions.view,
          edit: capabilities.surveys.permissions.edit,
          create: capabilities.surveys.permissions.create,
          removeBranding: capabilities.surveys.permissions.removeBranding,
        };
  },

  [FEATURES.CTAS]: ({ capabilities, isSharing, sharedResource }) => {
    const sharedResourceIsCTA = sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.CTA;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsCTA ? ALLOWED : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
          create: PERMISSION_NOT_ALLOWED,
          removeBranding: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.ctas.permissions.nav,
          view: capabilities.ctas.permissions.view,
          edit: capabilities.ctas.permissions.edit,
          create: capabilities.ctas.permissions.create,
          removeBranding: capabilities.ctas.permissions.removeBranding,
        };
  },

  Addon: ({ entity: addon = null }) => ({
    viewResults: addon?.permissions.canViewResults ? ALLOWED : PERMISSION_NOT_ALLOWED,
    viewSettings: addon?.permissions.canViewSettings ? ALLOWED : PERMISSION_NOT_ALLOWED,
    edit: addon?.permissions.canEdit ? ALLOWED : PERMISSION_NOT_ALLOWED,
    rename: addon?.permissions.canRename ? ALLOWED : PERMISSION_NOT_ALLOWED,
    delete: addon?.permissions.canDelete ? ALLOWED : PERMISSION_NOT_ALLOWED,
    toggle: addon?.permissions.canToggle ? ALLOWED : PERMISSION_NOT_ALLOWED,
    export: addon?.permissions.canExport && addon.type === ADDON_TYPES.SURVEY ? ALLOWED : PERMISSION_NOT_ALLOWED,
    share: addon?.permissions.canShare ? ALLOWED : PERMISSION_NOT_ALLOWED,
    anyBulkAction: addon?.permissions.canDelete ? ALLOWED : PERMISSION_NOT_ALLOWED,
  }),
};
