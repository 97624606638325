import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const visitorPanelFeatureKeys = createQueryKeys('visitorPanel', {
  visitorsRecordingsDetails: ({ terms, siteId }) => [{ terms, siteId }],
});

export const visitorsRecordingsDetailsQuery = ({ terms, siteId }) => ({
  ...visitorPanelFeatureKeys.visitorsRecordingsDetails({ terms, siteId }),
  meta: {
    query: gql`
      query VisitorsRecordingsDetails($terms: [String!]!, $siteId: Int) {
        visitorsRecordingsDetails(terms: $terms, siteId: $siteId) {
          recordings {
            id
            device
            duration
            identifier
            permissions {
              canDelete
              canView
              canWatchUnwatch
              canUpgradeToView
            }
            recordedAt
            lastEventAt
            viewedAt
            visitedPagesCount
            visitorId
            hashedId
            inProgress
            version
          }
          tags {
            count
            name
          }
        }
      }
    `,
  },
});
