import { ADDON_TYPES } from '/src/features/addons/constants';

function ce2MethodType(type) {
  switch (type) {
    case ADDON_TYPES.SURVEY:
      return 'CE2.showSurvey';
    default:
      return 'CE2.showCTA';
  }
}

export function advancedSettingsJSScript(type, id) {
  const ce2Method = ce2MethodType(type);

  return `&lt;script type="text/javascript"&gt;\n\t(window.CE_API || (window.CE_API=[])).push(function(){\n\t\t${ce2Method}("${id}");\n\t});\n&lt;/script&gt;`;
}
