import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { InfoBox, StyledPopoverPanel, PopoverItem } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { SupportLinks } from '/src/support';

import { ReactComponent as CampaignIcon } from '@crazyegginc/hatch/dist/images/icon-campaign-outline.svg';
import { ReactComponent as EventsIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as UserIcon } from '@crazyegginc/hatch/dist/images/icon-user-outline.svg';
import { ReactComponent as PathIcon } from '@crazyegginc/hatch/dist/images/icon-viewed-page-outline.svg';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-outline.svg';
import { ReactComponent as CustomIcon } from '@crazyegginc/hatch/dist/images/icon-wrench-outline.svg';

const VISITOR = 'visitor';
const SESSION = 'session';
const PATH = 'path';
const UTM_CAMPAIGN = 'utm_campaign';
const CUSTOM = 'custom';
const ACTIVITY = 'activity';
// const VISITOR_JOURNEY = 'visitor_journey';

export function CriteriaSelector({ addCriteria, conditions, definitions, align }) {
  const { currentUser } = useAuthContext();
  const categories = useMemo(() => {
    const dat = definitions.reduce(
      (acc, obj) => ({
        ...acc,
        [obj.category]: [...(acc?.[obj.category] ?? []), { ...obj }].sort((a, b) => a.position > b.position),
      }),
      {},
    );
    return dat;
  }, [definitions]);

  if (!definitions) return null;

  const renderCategory = (categoryKey, onClose) => {
    if (!categories[categoryKey]) {
      return null;
    }

    const filteredCategory = categories[categoryKey].filter((categoryItem) => {
      return !categoryItem.feature_flag || currentUser.features.includes(categoryItem.feature_flag);
    });

    if (filteredCategory.length === 0) {
      return null;
    }

    return (
      <>
        {filteredCategory?.map((categoryItem) => {
          const selected = conditions.find((condition) => condition.criteria === categoryItem.criteria) ? true : false;
          return (
            <PopoverItem
              className={classNames('!mb-1.25 last:!mb-0', {
                '!bg-solitude-500 !font-semibold !text-black-pearl-500': selected,
              })}
              onClick={() => {
                addCriteria(categoryItem.criteria);
                onClose();
              }}
              key={`${categoryKey}_${categoryItem.position}`}
            >
              <div>
                {categoryItem.name} <span className="text-2xs">{categoryItem.name_extra}</span>
              </div>
            </PopoverItem>
          );
        })}
      </>
    );
  };

  return (
    <StyledPopoverPanel
      align={align}
      data-testid="criteria-selector"
      className="!grid !w-[470px] !auto-rows-auto !grid-cols-2 gap-x-12 gap-y-[30px] !px-6 !pb-6 !pt-[30px]"
    >
      {({ close }) => (
        <>
          <Category
            categoryKey={VISITOR}
            icon={<UserIcon className="mx-2.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            title="Visitor info"
            render={renderCategory}
            onClose={close}
          />
          <Category
            categoryKey={SESSION}
            icon={<RecordingIcon className="mx-2.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            title="Session info"
            render={renderCategory}
            onClose={close}
          />
          <Category
            categoryKey={PATH}
            icon={<PathIcon className="mx-2.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            title="Path"
            render={renderCategory}
            onClose={close}
          />
          <Category
            categoryKey={UTM_CAMPAIGN}
            icon={<CampaignIcon className="mx-2.5 h-4 w-4 scale-x-[-1] fill-current text-dodger-blue-500" />}
            title="Ad campaign"
            render={renderCategory}
            onClose={close}
          />
          <Category
            categoryKey={ACTIVITY}
            icon={<EventsIcon className="mx-2.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            title="Activity"
            render={renderCategory}
            onClose={close}
          />
          <DropdownCategory
            categoryKey={CUSTOM}
            icon={<CustomIcon className="mx-2.5 h-4 w-4 fill-current text-dodger-blue-500" />}
            title="Custom"
            render={renderCategory}
            onClose={close}
          />
        </>
      )}
    </StyledPopoverPanel>
  );
}

function Category({ categoryKey, icon, title, render, onClose }) {
  const items = render(categoryKey, onClose);
  if (!items) {
    return null;
  }

  return (
    <div>
      <div className="mb-2.5 flex items-center border-b border-mystic-500 pb-2.5">
        {icon}
        <h3 className="text-header-3 m-0 leading-none">{title}</h3>
      </div>
      {items}
    </div>
  );
}

function DropdownCategory({ categoryKey, icon, title, render, onClose }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button type="button" className="w-full focus-visible:outline-black" onClick={() => setOpen(!open)}>
        <div className="mb-2.5 flex items-center border-b border-mystic-500 pb-2.5">
          {icon}
          <h3 className="text-header-3 m-0 leading-none">{title}</h3>
          <div
            className={classNames(
              'ml-2 h-2 w-2 border-b-2 border-l-2 border-dodger-blue-500 transition-transform duration-300 ease-out',
              {
                'translate-y-1/2 rotate-[135deg]': open,
                '-rotate-45': !open,
              },
            )}
          />
        </div>
      </button>
      <div
        className={classNames('overflow-y-hidden transition-all duration-300 ease-out', {
          'max-h-[500px]': open,
          'max-h-0': !open,
        })}
      >
        {render(categoryKey, onClose)}
      </div>
      {categoryKey === CUSTOM && (
        <InfoBox
          header="Create your own filters"
          body={
            <>
              Learn more{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-link text-2xs font-normal italic"
                href={SupportLinks.script.ce2Functions}
              >
                here
              </a>
            </>
          }
        />
      )}
    </div>
  );
}
