import { Field, getIn, useFormikContext } from 'formik';
import { Button, Input, TextArea, Tooltip } from '@crazyegginc/hatch';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

import { ClipboardButton } from '/src/components/ClipboardButton';
import { Panel, Label } from './SsoUi';
import { SupportLinks } from '/src/support';

export function SsoSettingsForm({ settings }) {
  const { values, errors, setFieldValue } = useFormikContext();

  if (!settings) return null;

  return (
    <>
      <h2 className="text-header-3 mb-[15px]">Organization details</h2>
      <Panel>
        <p className="text-body-2 mb-5">
          Single sign-on (SSO) allows anyone with an email from an approved domain to access Crazy Egg securely through
          an Identity Provider.{' '}
          <a href={SupportLinks.sso} className="text-link" rel="noopener noreferrer">
            Learn more
          </a>
        </p>
        <p className="text-body-2 mb-7">Information specific to your organization.</p>

        <fieldset>
          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="organizationName">Organization name</Label>
            <div className="w-[16px]" />
            <div className="max-w-[410px] flex-1">
              <Field name="organizationName">
                {({ field }) => (
                  <Input id="organizationName" {...field} error={getIn(errors, `organizationName`)} size="lg" />
                )}
              </Field>
            </div>
          </div>

          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="emailDomain">Email domain (optional)</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This is your organization’s email domain. It will be used to redirect team members who do not have a
                    Crazy Egg account yet that attempt to log in from crazyegg.com to your Identity Provider. If left
                    blank they will see a message that says “There is no account with that email” and they must first
                    log in via your Identity Provider or you must add them manually.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="emailDomain">
                {({ field }) => <Input id="emailDomain" {...field} error={getIn(errors, `emailDomain`)} size="lg" />}
              </Field>
            </div>
          </div>
        </fieldset>
      </Panel>

      <h2 className="text-header-3 mb-[15px]">Crazy Egg Single Sign-on details</h2>
      <Panel>
        {values.spConfiguration.metadataUrl ? (
          <p className="text-body-2 mb-7">
            You can{' '}
            <a href={values.spConfiguration.metadataUrl} className="text-link" download={true}>
              download our Metadata XML file
            </a>{' '}
            or copy and paste the information below into your Identity Provider.
          </p>
        ) : (
          <p className="text-body-2 mb-7">Paste the information below into your Identity Provider.</p>
        )}

        <fieldset>
          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="spConfiguration.entityId">SP Entity ID</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field may also be called <code className="highlight dark">Service Provider Entity ID</code>,{' '}
                    <code className="highlight dark">Audience URI</code>,{' '}
                    <code className="highlight dark">Identifier</code> or simply{' '}
                    <code className="highlight dark">Entity ID</code>, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="spConfiguration.entityId">
                {({ field }) => (
                  <div className="relative w-full">
                    <Input
                      id="spConfiguration.entityId"
                      {...field}
                      error={getIn(errors, `spConfiguration.entityId`)}
                      className="!pr-10"
                      size="lg"
                    />
                    <ClipboardButton value={field.value} />
                  </div>
                )}
              </Field>
            </div>
            {values.spConfiguration.defaultEntityId !== values.spConfiguration.entityId ? (
              <Button
                variant="cancel"
                onClick={() => setFieldValue('spConfiguration.entityId', values.spConfiguration.defaultEntityId)}
              >
                Reset to default
              </Button>
            ) : null}
          </div>

          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="spConfiguration.acsUrl">ACS URL</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field may also be called <code className="highlight dark">Assertion Consumer Service URL</code>
                    , <code className="highlight dark">Single sign on URL</code> or{' '}
                    <code className="highlight dark">Reply URL</code>, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="spConfiguration.acsUrl">
                {({ field }) => (
                  <div className="relative w-full">
                    <Input
                      disabled={true}
                      id="spConfiguration.acsUrl"
                      {...field}
                      error={getIn(errors, `spConfiguration.acsUrl`)}
                      className="!pr-10"
                      size="lg"
                    />
                    <ClipboardButton value={field.value} />
                  </div>
                )}
              </Field>
            </div>
          </div>

          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="spConfiguration.sloUrl">Logout URL (optional)</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field is only available for providers who support IdP-initiated Single Logout. It may also be
                    called <code className="highlight dark">Single Logout URL</code>,{' '}
                    <code className="highlight dark">SLO URL</code> or{' '}
                    <code className="highlight dark">SLO Service URL</code>, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="spConfiguration.sloUrl">
                {({ field }) => (
                  <div className="relative w-full">
                    <Input
                      disabled={true}
                      id="spConfiguration.sloUrl"
                      {...field}
                      error={getIn(errors, `spConfiguration.sloUrl`)}
                      className="!pr-10"
                      size="lg"
                    />
                    <ClipboardButton value={field.value} />
                  </div>
                )}
              </Field>
            </div>
          </div>

          <div className="mb-1.25 flex gap-x-3">
            <Label htmlFor="spConfiguration.sloUrl">Name ID</Label>
            <div className="w-[16px]" />
            <div className="flex max-w-[410px] flex-1 items-center">
              <p className="text-body-2">
                Select format <code className="highlight">EMAIL</code>, from users&#39; primary email address.
              </p>
            </div>
          </div>

          <div className="flex gap-x-3">
            <Label htmlFor="spConfiguration.sloUrl">Attributes</Label>
            <div className="w-[16px]" />
            <div className="flex max-w-[410px] flex-1 items-center pt-2.5">
              <p className="text-body-2 leading-5">
                Please map at least the users&#39; display name or full name to appropriate fields.
                <br />
                (e.g. <code className="highlight">display_name</code>, <code className="highlight">full_name</code>,{' '}
                <code className="highlight">first_name</code> + <code className="highlight">last_name</code> or{' '}
                <code className="highlight">given_name</code> and <code className="highlight">surname</code>).
                <br />
                Case and separators don&#39;t matter. You can map any extra attributes that you want. They&#39;ll be
                accessible in the provisioning rules.
              </p>
            </div>
          </div>
        </fieldset>
      </Panel>

      <h2 className="text-header-3 mb-[15px]">Your identity provider details</h2>
      <Panel>
        <p className="text-body-2 mb-7">
          You will need to get the following information from your Identity Provider and paste it below.
        </p>

        <fieldset>
          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="idpConfiguration.targetUrl">IdP Single Sign-on URL</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field may also be called <code className="highlight dark">SSO URL</code>,{' '}
                    <code className="highlight dark">Single sign on URL</code>,{' '}
                    <code className="highlight dark">Identity Provider Single Sign-On URL</code> or{' '}
                    <code className="highlight dark">Login URL</code>, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="idpConfiguration.targetUrl">
                {({ field }) => (
                  <Input
                    id="idpConfiguration.targetUrl"
                    {...field}
                    error={getIn(errors, `idpConfiguration.targetUrl`)}
                    size="lg"
                  />
                )}
              </Field>
            </div>
          </div>

          <div className="mb-[15px] flex gap-x-3">
            <Label htmlFor="idpConfiguration.entityId">IdP Entity ID</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field may also be called <code className="highlight dark">Identity Provider Entity ID</code>,{' '}
                    <code className="highlight dark">Identity Provider Issuer</code>,{' '}
                    <code className="highlight dark">Identity Provider Identifier</code> or simply{' '}
                    <code className="highlight dark">Entity ID</code>,<code className="highlight dark">Issuer</code> or{' '}
                    <code className="highlight dark">Identifier</code>, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="idpConfiguration.entityId">
                {({ field }) => (
                  <Input id="idpConfiguration.entityId" {...field} error={getIn(errors, field.name)} size="lg" />
                )}
              </Field>
            </div>
          </div>

          <div className="flex gap-x-3">
            <Label htmlFor="idpConfiguration.certificate">X.509 Certificate</Label>
            <div className="flex h-10 items-center">
              <Tooltip
                placement="right"
                tooltipContent={
                  <div className="max-w-[250px] items-center">
                    This field may also be called <code className="highlight dark">Identity Provider Certificate</code>{' '}
                    or simply <code className="highlight dark">Certificate</code>, and it may have been provided as a
                    downloadable file instead of a text field, depending on your Single Sign-on provider.
                  </div>
                }
              >
                <InfoIcon className="h-4 w-4 flex-shrink-0 fill-current text-cadet-blue-500" />
              </Tooltip>
            </div>
            <div className="max-w-[410px] flex-1">
              <Field name="idpConfiguration.certificate">
                {({ field }) => (
                  <TextArea
                    {...field}
                    id="idpConfiguration.certificate"
                    rows={5}
                    error={getIn(errors, `idpConfiguration.certificate`)}
                  />
                )}
              </Field>
            </div>
          </div>
        </fieldset>
      </Panel>
    </>
  );
}
