import { useCallback } from 'react';

export function useScrollToAndFocus({ enabled = true, focus = true, offset = 0 }) {
  const ref = useCallback(
    (node) => {
      if (node !== null && enabled) {
        window.setTimeout(() => {
          window.scrollTo({
            top: node.getBoundingClientRect().top + window.pageYOffset + offset,
            behavior: 'smooth',
          });
          if (focus) {
            node.focus();
          }
        }, 10);
      }
    },
    [enabled, offset, focus],
  );

  return ref;
}
