import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Divider, Checkbox, Tooltip } from '@crazyegginc/hatch';

import { useFilter } from '../live-filter-context';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { getLiveEventByName, getAllEventNames } from '../live-functions';
import { BASIC_VISITOR } from '../constants';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function LiveFilters({ eventCounts, peopleCounts, identifyEvents, pageCounts }) {
  return (
    <div className="mt-5 w-[310px] rounded-md border border-mystic-500 bg-white p-6">
      <PageFilter pageCounts={pageCounts} />
      <Divider dashed className="-mx-6 my-7 !w-auto" />
      <PeopleFilter peopleCounts={peopleCounts} identifyEvents={identifyEvents} />
      <Divider dashed className="-mx-6 my-7 !w-auto" />
      <EventFilter eventCounts={eventCounts} />
    </div>
  );
}

function PageFilter({ pageCounts }) {
  const { setPageFilter, filter } = useFilter();
  const [showMore, setShowMore] = useState(false);
  const maxToShowWhenNotExpanded = 5;
  const maxToShowWhenExpanded = 10;

  let pagesCountArray = [];
  for (const url in pageCounts) {
    pagesCountArray.push({ url, count: pageCounts[url].count });
  }
  pagesCountArray = pagesCountArray.sort((a, b) => b.count - a.count);

  const numPages = pagesCountArray.length;
  const numShowing = showMore
    ? Math.min(maxToShowWhenExpanded, numPages)
    : Math.min(maxToShowWhenNotExpanded, numPages);

  const filteredPage = !!filter.page && pagesCountArray.find((e) => e.url === filter.page);

  useEffect(() => {
    // clear page filter if no such page in event flow
    if (!!filter.page && !filteredPage) {
      setPageFilter(undefined);
    }
  }, [filteredPage, filter.page, setPageFilter]);

  return (
    <section>
      <div className="flex items-center justify-between">
        <span className="text-header-4">Page Activity</span>
        {numPages > maxToShowWhenNotExpanded && (
          <button
            className="text-sm text-dodger-blue-500 focus-visible:outline-black"
            type="button"
            onClick={() => setShowMore((x) => !x)}
          >
            {showMore ? 'view less' : 'view more'}
          </button>
        )}
      </div>
      <div className="text-body-5 relative mt-1.25">
        {numPages > numShowing ? 'Top ' : ''} {numShowing} of {numPages} pages
        {!!filteredPage && (
          <div className="absolute right-0 top-6 flex">
            <button
              type="button"
              className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
              disabled={false}
              onClick={() => setPageFilter(undefined)}
            >
              Clear
            </button>
          </div>
        )}
        <div className="mt-8 space-y-3">
          {!!filteredPage && (
            <>
              <PageFilterItem item={filteredPage} />
              <Divider />
            </>
          )}
          {pagesCountArray
            .slice(0, numShowing)
            .filter((e) => e.url !== filter.page)
            .map((e) => (
              <PageFilterItem key={e.url} item={e} />
            ))}
        </div>
      </div>
    </section>
  );
}

function PeopleFilter({ peopleCounts, identifyEvents }) {
  const { setPersonFilter, filter } = useFilter();
  const [showMore, setShowMore] = useState(false);
  const maxToShowWhenNotExpanded = 5;
  const maxToShowWhenExpanded = 10;

  let peopleCountArray = [];
  for (const id in peopleCounts) {
    peopleCountArray.push({
      id,
      count: peopleCounts[id],
      name: id === BASIC_VISITOR ? id : getVisitorDisplayName(identifyEvents[id], id),
    });
  }
  peopleCountArray = peopleCountArray.sort((a, b) => b.count - a.count);

  const numPeople = peopleCountArray.length;
  const numShowing = showMore
    ? Math.min(maxToShowWhenExpanded, numPeople)
    : Math.min(maxToShowWhenNotExpanded, numPeople);

  const filteredPerson = !!filter.person && peopleCountArray.find((e) => e.id === filter.person);

  useEffect(() => {
    // clear person filter if no such person in event flow
    if (!!filter.person && !filteredPerson) {
      setPersonFilter(undefined);
    }
  }, [filteredPerson, filter.person, setPersonFilter]);

  return (
    <section>
      <div className="flex items-center justify-between">
        <span className="text-header-4">Visitor Activity</span>
        {numPeople > maxToShowWhenNotExpanded && (
          <button
            className="text-sm text-dodger-blue-500 focus-visible:outline-black"
            type="button"
            onClick={() => setShowMore((x) => !x)}
          >
            {showMore ? 'view less' : 'view more'}
          </button>
        )}
      </div>
      <div className="text-body-5 relative mt-1.25">
        {numPeople > numShowing ? 'Top ' : ''} {numShowing} of {numPeople} people
        {!!filteredPerson && (
          <div className="absolute right-0 top-6 flex">
            <button
              type="button"
              className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
              disabled={false}
              onClick={() => setPersonFilter(undefined)}
            >
              Clear
            </button>
          </div>
        )}
        <div className="mt-8 space-y-3">
          {!!filteredPerson && (
            <>
              <PeopleFilterItem item={filteredPerson} />
              <Divider />
            </>
          )}
          {peopleCountArray
            .slice(0, numShowing)
            .filter((e) => e.id !== filter.person)
            .map((e) => (
              <PeopleFilterItem key={e.id} item={e} />
            ))}
        </div>
      </div>
    </section>
  );
}

function EventFilter({ eventCounts }) {
  const { setEventFilter, filter } = useFilter();
  const [showAll, setShowAll] = useState(false);
  const maxToShowWhenNotExpanded = 5;

  let eventCountArray = [];
  for (const e in eventCounts) {
    eventCountArray.push({ type: e, count: eventCounts[e] });
  }

  eventCountArray = eventCountArray.sort((a, b) => b.count - a.count);

  const numEvents = eventCountArray.length;
  const numShowing = showAll ? numEvents : Math.min(maxToShowWhenNotExpanded, numEvents);

  const allEvents = getAllEventNames();

  return (
    <section>
      <div className="flex items-center justify-between">
        <span className="text-header-4">Event Activity</span>
        {numEvents > maxToShowWhenNotExpanded && (
          <button
            className="text-sm text-dodger-blue-500 focus-visible:outline-black"
            type="button"
            onClick={() => setShowAll((x) => !x)}
          >
            {showAll ? 'view less' : 'view all'}
          </button>
        )}
      </div>
      <div className="text-body-5 mt-1.25">
        {numEvents > numShowing ? 'Top ' : ''} {numShowing} of {numEvents} events
        <div className="mt-5 flex items-center justify-between">
          <button
            type="button"
            className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
            disabled={allEvents.length === filter.events.length}
            onClick={() => setEventFilter([...allEvents])}
          >
            Select all
          </button>
          <button
            type="button"
            className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
            disabled={!filter.events.length}
            onClick={() => setEventFilter([])}
          >
            Select none
          </button>
        </div>
        <div className="-ml-1.25 mt-2">
          {eventCountArray.slice(0, numShowing).map((e) => (
            <EventFilterItem key={e.type} item={e} />
          ))}
        </div>
      </div>
    </section>
  );
}

function PageFilterItem({ item }) {
  const { setPageFilter, filter } = useFilter();

  const selected = filter.page === item.url;

  return (
    <div className="flex w-full min-w-0 items-center justify-between">
      <div className="flex min-w-0 items-center">
        {selected && <TickIcon className="mr-2.5 h-2.5 w-2.5 fill-current text-dodger-blue-500" />}
        <button
          type="button"
          className="mr-7 truncate text-sm font-semibold text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
          onClick={() => {
            if (selected) {
              setPageFilter(undefined);
            } else {
              setPageFilter(item.url);
            }
          }}
        >
          {item.url}
        </button>
      </div>
      <span className="text-body-5">{item.count}</span>
    </div>
  );
}

function PeopleFilterItem({ item }) {
  const { setPersonFilter, filter } = useFilter();

  const selected = filter.person === item.id;
  const isBasicVisitor = item.id === BASIC_VISITOR;

  return (
    <div className="flex w-full min-w-0 items-center justify-between">
      <div className="mr-7 flex min-w-0 items-center">
        {selected && <TickIcon className="mr-2.5 h-2.5 w-2.5 fill-current text-dodger-blue-500" />}
        <button
          type="button"
          className={classNames(
            'truncate text-sm font-semibold text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500',
            {
              'mr-2.5': isBasicVisitor,
            },
          )}
          onClick={() => {
            if (selected) {
              setPersonFilter(undefined);
            } else {
              setPersonFilter(item.id);
            }
          }}
        >
          {item.name}
        </button>
        {isBasicVisitor && (
          <Tooltip
            arrowSkiddingPercent={85}
            tooltipContent={
              <div className="max-w-md">
                Basic Visits are tracked the first time someone visits your site for the day when they are not a part of
                an active Snapshot or Recording. To track more detailed information create a new Snapshot or upgrade
                your plan to track more Recordings.
              </div>
            }
          >
            <InfoIcon className="h-3.5 w-3.5 shrink-0 fill-current text-dodger-blue-300" />
          </Tooltip>
        )}
      </div>
      <span className="text-body-5">{item.count}</span>
    </div>
  );
}

function EventFilterItem({ item }) {
  const { setEventFilter, filter } = useFilter();

  const checked = filter.events.includes(item.type);
  const { printName, tooltip } = getLiveEventByName(item.type);

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center space-x-2.5">
        <Checkbox
          checked={checked}
          onChange={() => {
            if (checked) {
              setEventFilter(filter.events.filter((e) => e !== item.type));
            } else {
              setEventFilter([...filter.events, item.type]);
            }
          }}
          label={printName}
          id={printName}
        />
        {tooltip && (
          <Tooltip arrowSkiddingPercent={85} tooltipContent={<div className="max-w-md">{tooltip}</div>}>
            <InfoIcon className="h-3.5 w-3.5 shrink-0 fill-current text-dodger-blue-300" />
          </Tooltip>
        )}
      </div>
      <span className="text-body-5">{item.count}</span>
    </div>
  );
}
