import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Tooltip } from '@crazyegginc/hatch';

import { usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { AB_TESTS_CREATION_LINK } from '/src/features/ab-testing/constants';
import { FEATURES } from '/src/features/_global/constants';
import { ABTestingPaywallText } from '/src/features/ab-testing/components/paywalls/ABTestingPaywallText';

import ABTestingPNG from '@crazyegginc/hatch/dist/images/illustration-ab-test.png';
import ABTestingWebP from '@crazyegginc/hatch/dist/images/illustration-ab-test.webp';

export function NoAbTestsWall({ mini }) {
  const permissions = usePermissions();
  const canCreate = permissions.can('create', FEATURES.AB_TESTING).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={ABTestingWebP}
        png={ABTestingPNG}
        width={mini ? '80px' : '260px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>
        <ABTestingPaywallText.Title />
      </Paywall.Title>

      <Paywall.Body>
        <ABTestingPaywallText.Body />
        <ABTestingPaywallText.List />
      </Paywall.Body>

      <Tooltip show={!canCreate} tooltipContent="You don't have permission to create an A/B Test.">
        <Button
          component={Link}
          to={AB_TESTS_CREATION_LINK}
          disabled={!canCreate}
          className={classNames('mb-5', { 'pointer-events-none': !canCreate })}
        >
          Create an A/B Test
        </Button>
      </Tooltip>
    </Paywall>
  );
}
