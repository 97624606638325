import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup, DateTimePicker } from '@crazyegginc/hatch';

import { usePermissions, useWizard } from '/src/hooks';

import { formatDateTime } from '/src/utils/date';
import { SNAPSHOT_START_OPTIONS } from '/src/features/snapshots/constants';

export function StartSnapshot({ isEditing, validationErrors }) {
  const formik = useFormikContext();
  const { data: wizardData } = useWizard();
  const { setFieldError, setFieldTouched } = formik;
  const permissions = usePermissions();
  const disabled = isEditing && !permissions.can('editStartDate', wizardData.existingSnapshot);

  useEffect(() => {
    if (validationErrors?.findIndex((error) => error.field === 'startsAt' && error.message === 'date_in_past') > -1) {
      setFieldError('starts.at', 'The start date cannot be in the past');
      setFieldTouched('starts.at', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'startsAt' && error.message === 'invalid_date') > -1
    ) {
      setFieldError(
        'starts.at',
        'You have selected an invalid start date for your Snapshot. Your entry does not appear to be a date.',
      );
      setFieldTouched('starts.at', true, false);
    }
  }, [validationErrors, setFieldError, setFieldTouched]);

  return (
    <section className="flex w-1/2 flex-1 flex-col pr-24">
      <h2 className="text-header-2 mb-4">Start Snapshot</h2>
      <RadioGroup
        options={[
          { value: SNAPSHOT_START_OPTIONS.IMMEDIATELY, label: 'Immediately' },
          { value: SNAPSHOT_START_OPTIONS.SCHEDULED, label: 'Scheduled' },
        ]}
        value={formik.values.starts.option}
        onChange={(newValue) => formik.setFieldValue('starts.option', newValue, true)}
        disabled={disabled}
        optionWrapperClass="mb-2.5"
      />

      {formik.values.starts.option === SNAPSHOT_START_OPTIONS.SCHEDULED && (
        <div className="mt-2">
          <DateTimePicker
            selectedDate={formik.values.starts.at}
            setDate={(newValue) => formik.setFieldValue('starts.at', newValue, true)}
            showTimePicker={true}
            size="sm"
            disabled={disabled}
          />
        </div>
      )}

      {isEditing && wizardData.existingSnapshot ? (
        wizardData.existingSnapshot.snapshotStatus === SNAPSHOT_START_OPTIONS.SCHEDULED ? (
          <div className="text-body-5 mt-3">
            Your Snapshot will not be captured or start collecting data until{' '}
            {<span className="font-semibold">{formatDateTime(wizardData.existingSnapshot.startsAt)}.</span>}
          </div>
        ) : (
          <div className="text-body-5 mt-3">
            This Snapshot started tracking on{' '}
            <span className="font-semibold">
              {formatDateTime(wizardData.existingSnapshot.startsAt || wizardData.existingSnapshot.createdAt)}.
            </span>
          </div>
        )
      ) : (
        <div className="text-body-5 mt-0.5">
          You will not be able to modify the start date after the Snapshot has started.
        </div>
      )}
    </section>
  );
}
