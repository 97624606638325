import { useEffect, useState, useMemo } from 'react';
import { Link, Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { addDays, startOfHour, addHours, fromUnixTime, isFuture, addYears } from 'date-fns';
import { v4 as uuidV4 } from 'uuid';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import { Spinner, Button } from '@crazyegginc/hatch';

import { WizardProvider } from '/src/components/wizard/legacy/wizard-context';
import { WizardPage } from '/src/components/Page';

import { userDataQuery } from '/src/features/_global/queries';
import { singleSnapshotQuery } from '/src/features/snapshots/queries';

import { superLenientUrlRegex } from '/src/utils/regex';
import { getParam } from '/src/utils/location';
import { isFile } from '/src/utils/url';

import { useAuthContext, usePermissions, useWizard } from '/src/hooks';

import { WizardLogo, WizardHeader } from '/src/components/wizard/legacy/wizard-ui';
import { SEO } from '/src/components/SEO';
import { WebP } from '/src/components/WebP';

import YellowWarningPNG from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.png';
import YellowWarningWebP from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.webp';

import { convertKeysToCamelCase } from '/src/utils/string';

import { FEATURES, DEVICE_TYPES } from '/src/features/_global/constants';
import {
  SNAPSHOT_TRACKING_OPTIONS,
  SNAPSHOT_BLOCKING_POPUP_OPTIONS,
  SNAPSHOT_START_OPTIONS,
  SNAPSHOT_END_OPTIONS,
  SNAPSHOT_SAMPLING_RATIO_OPTIONS,
  SNAPSHOT_CAPTURE_TIMER_OPTIONS,
  DEFAULT_DAYS_VALUE,
  DEFAULT_VISITS_VALUE,
  DEFAULT_DAYS_STRING,
  DEFAULT_VISITS_STRING,
  MAX_SNAPSHOT_YEARS,
} from '/src/features/snapshots/constants';

const { DESKTOP, TABLET, PHONE, MOBILE } = DEVICE_TYPES;

export const generateInitialSnapshotSchema = (param) => ({
  name: param?.name ?? '',
  siteUrl: param?.siteUrl ?? '',
  uuid: uuidV4(),
  validatedUrl: param?.validatedUrl ?? '',
});

const generateValidationSchema = (maxVisits, isEditing) =>
  yup.object().shape({
    snapshots: yup.array().of(
      yup
        .object()
        .shape({
          id: yup.string(),
          name: yup
            .string()
            .required('Please provide a name for your Snapshot.')
            .max(140, 'Your Snapshot name is too long.'),
          siteUrl: yup
            .string()
            .required('Please provide a Snapshot URL.')
            .matches(superLenientUrlRegex, 'Your URL appears to be invalid.')
            .test('extension', 'The URL should point to a web page, but it seems to point to a file.', (value) => {
              if (!value) return true;
              let url = value.match(/^https?:\/\//) ? value : `http://${value}`;
              return !isFile(url);
            }),
          validatedUrl: yup.string().oneOf([yup.ref('siteUrl')], 'must match with siteUrl'),
        })
        .test('unique', 'Please provide a unique URL.', function (value) {
          if (!value || !value['siteUrl']) {
            return true;
          }

          if (this.parent.filter((v) => v !== value).some((v) => v['siteUrl'] === value['siteUrl'])) {
            return this.createError({
              path: `${this.path}.siteUrl`,
            });
          }

          return true;
        }),
    ),
    pageCameraSnapshot: yup.boolean(),
    preUpload: yup.boolean(),
    deviceTracking: yup.object().shape({
      custom: yup.boolean(),
      devices: yup
        .array()
        .of(yup.string().oneOf([DESKTOP, TABLET, MOBILE]))
        .when('custom', { is: false, then: yup.array().min(1, 'Please select at least one device.') }),
      customTracking: yup.object().when('custom', (custom, schema) =>
        schema.shape({
          screenshot: yup.string().oneOf([DESKTOP, TABLET, MOBILE], 'Must be one of desktop, tablet or mobile.'),
          traffic: !custom
            ? yup.array().of(yup.string().oneOf([DESKTOP, TABLET, MOBILE]))
            : yup
                .array()
                .of(yup.string().oneOf([DESKTOP, TABLET, MOBILE]))
                .min(1, 'Please select at least one traffic source.')
                .test({
                  name: 'is-screenshot-included',
                  test: function (value) {
                    return value.includes(this.parent.screenshot)
                      ? true
                      : this.createError({
                          message: `You have selected a ${this.parent.screenshot.toLowerCase()} screenshot, but you are not tracking traffic from ${this.parent.screenshot.toLowerCase()} visitors.`,
                          path: `deviceTracking.customTracking.traffic`,
                        });
                  },
                }),
        }),
      ),
    }),
    starts: yup.object().shape({
      option: yup.string().oneOf([...Object.values(SNAPSHOT_START_OPTIONS)]),
      at: yup.mixed().when('option', {
        is: (value) => value === SNAPSHOT_START_OPTIONS.SCHEDULED && !isEditing,
        then: yup.date().min(new Date(), 'Please select a date in the future.'),
      }),
    }),
    ends: yup.object().when('starts', (starts, schema) =>
      schema.shape({
        option: yup.string().oneOf([...Object.values(SNAPSHOT_END_OPTIONS)]),
        days: yup.number().when('option', {
          is: (value) => value === SNAPSHOT_END_OPTIONS.DEFAULT,
          then: yup
            .number()
            .oneOf([7, 30, 60, 90])
            .test('is-in-future', 'The end date has to be in the future.', (value) => {
              if (starts.option === SNAPSHOT_START_OPTIONS.SCHEDULED || isEditing) {
                return isFuture(addDays(starts.at, value));
              }
              return true;
            }),
        }),
        atChanged: yup.boolean(),
        at: yup.mixed().when('option', {
          is: (value) => value === SNAPSHOT_END_OPTIONS.CUSTOM,
          then: yup
            .date()
            .min(starts.at, 'The end date has to be after the starting date.')
            .max(
              addYears(starts.at, MAX_SNAPSHOT_YEARS),
              `Snapshots are limited to a maximum of ${MAX_SNAPSHOT_YEARS} years.`,
            )
            .test('is-in-future', 'The end date has to be in the future.', (value, context) => {
              if (context.parent.atChanged) {
                return isFuture(value);
              }
              return true;
            }),
        }),
        visits: yup
          .number()
          .required('Please provide a valid number for visits.')
          .typeError('Please provide a valid number for visits.')
          .integer('Please provide a valid number for visits.')
          .positive('Please provide a valid number for visits.')
          .min(1, 'Visits should be at least ${min}. Please provide a larger number.')
          .max(maxVisits, 'Visits cannot exceed ${max}. Please provide a lower number.'),
      }),
    ),
    blocking: yup.object().shape({
      option: yup.string().oneOf([...Object.values(SNAPSHOT_BLOCKING_POPUP_OPTIONS)]),
      elements: yup.string().when('option', {
        is: (value) => value === SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_ELEMENTS,
        then: yup.string().required('Please provide a list of elements or select another option.'),
      }),
      url: yup.string().when('option', {
        is: (value) => value === SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_ELEMENTS,
        then: yup.string().matches(superLenientUrlRegex, 'The URL appears to be invalid.'),
      }),
    }),
    samplingRatio: yup.object().shape({
      option: yup.string().oneOf([...Object.values(SNAPSHOT_SAMPLING_RATIO_OPTIONS)]),
      ratio: yup.mixed().when('option', {
        is: (value) => value === SNAPSHOT_SAMPLING_RATIO_OPTIONS.CUSTOM,
        then: yup
          .number()
          .required('Please provide a valid number for visits.')
          .typeError('Please provide a valid number for visits.')
          .integer('Please provide a valid number for visits.')
          .positive('Please provide a valid number for visits.')
          .min(2, 'Visits should be at least ${min}. Please provide a larger number.')
          .max(50000, 'Visits cannot exceed ${max}. Please provide a lower number.'),
      }),
    }),
    captureTimer: yup.object().shape({
      option: yup.string().oneOf([...Object.values(SNAPSHOT_CAPTURE_TIMER_OPTIONS)]),
      seconds: yup.mixed().when('option', {
        is: (value) => value === SNAPSHOT_CAPTURE_TIMER_OPTIONS.CUSTOM,
        then: yup
          .number()
          .required('Please provide a valid number for the delay timer.')
          .typeError('Please provide a valid number for the delay timer.')
          .integer('Please provide a valid number for the delay timer.')
          .positive('Please provide a valid number for the delay timer.')
          .min(0, 'Your delay timer should be at least ${min}. Please provide a larger number.')
          .max(60, 'Your delay timer cannot exceed ${max}. Please provide a lower number.'),
      }),
    }),
    trackingOptions: yup.object().shape({
      option: yup.string().oneOf([...Object.values(SNAPSHOT_TRACKING_OPTIONS)]),
      urlTracking: yup
        .object()
        .nullable()
        .when('option', {
          is: (value) => value === SNAPSHOT_TRACKING_OPTIONS.URL,
          then: yup.object().shape({
            strictProtocol: yup.boolean().required('required'),
            strictHost: yup.boolean().required('required'),
            strictPath: yup.boolean().required('required'),
            strictQuery: yup.boolean().required('required'),
            strictHash: yup.boolean().required('required'),
          }),
        }),
      wildcard: yup
        .object()
        .nullable()
        .when('option', {
          is: (value) => value === SNAPSHOT_TRACKING_OPTIONS.WILDCARD,
          then: yup.object().shape({
            wildcard: yup.string().required('required'),
            testUrl: yup.string(),
          }),
        }),
      regex: yup
        .object()
        .nullable()
        .when('option', {
          is: (value) => value === SNAPSHOT_TRACKING_OPTIONS.REGEX,
          then: yup.object().shape({
            regex: yup.string().required('required'),
            testUrl: yup.string(),
          }),
        }),
      named: yup
        .object()
        .nullable()
        .when('option', {
          is: (value) => value === SNAPSHOT_TRACKING_OPTIONS.NAMED,
          then: yup.object().shape({
            named: yup.string().required('required'),
          }),
        }),
      googleOptimize: yup
        .object()
        .nullable()
        .when('option', {
          is: (value) => value === SNAPSHOT_TRACKING_OPTIONS.GOOGLE_OPTIMIZE,
          then: yup.object().shape({
            trackingId: yup.string().required('required'),
            experimentId: yup.string().required('required'),
            variantId: yup.string().required('required'),
          }),
        }),
    }),
    recurringSnapshot: yup.boolean(),
  });

function convertToDevice(device) {
  return device.toUpperCase().replace(PHONE, MOBILE);
}

export function SnapshotsWizard() {
  return (
    <WizardProvider>
      <SnapshotWizardContent />
    </WizardProvider>
  );
}

function SnapshotWizardContent() {
  const { capabilities } = useAuthContext();

  const params = useParams();
  const { set: wizardSet, setData: wizardSetData, setReturnLocation: wizardSetReturnLocation } = useWizard();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = usePermissions();
  const maxVisits = capabilities.snapshots.quotas.visitsPerSnapshot.limit;

  const canCreateMore = permissions.can('createMore', FEATURES.SNAPSHOTS).allowed;

  const isPathNew = location.pathname.endsWith('/new') || location.pathname.endsWith('/new/');
  const urlSearchParams = new URLSearchParams(location.search.slice(1));
  const [searchParams] = useState(Object.fromEntries(urlSearchParams.entries()));
  const isPageCameraSnapshot = searchParams?.pageCamera === 'true';
  const isPreUpload = searchParams?.preUpload === 'true';
  const isAbTestSnapshot = searchParams?.abTest === 'true';
  const [initialSiteUrls] = useState(urlSearchParams.getAll('siteUrl'));
  const [initialDevices] = useState(urlSearchParams.getAll('device'));

  const returnLocation = location?.state?.returnLocation;
  useEffect(() => {
    if (returnLocation) {
      wizardSetReturnLocation(returnLocation);
    }
  }, [wizardSetReturnLocation, returnLocation]);

  useEffect(() => {
    if (isPathNew) {
      // set default state
      wizardSet({
        title: 'Create new Snapshots',
        currentStep: 1,
        totalSteps: 2,
      });
    }
  }, [isPathNew, wizardSet]);

  const existingId = parseInt(params?.snapshotId) ?? null;
  const isEditing = location.pathname.indexOf('/snapshots/edit/') === 0;

  const { data: userData, fetching: userDataFetching } = useQuery(userDataQuery());

  const defaultDays = Number(
    userData?.me?.userData?.find?.((x) => x.key === DEFAULT_DAYS_STRING)?.value ?? DEFAULT_DAYS_VALUE,
  );
  const defaultVisits = Number(
    userData?.me?.userData?.find?.((x) => x.key === DEFAULT_VISITS_STRING)?.value ?? DEFAULT_VISITS_VALUE,
  );

  const { data, fetching } = useQuery({
    ...singleSnapshotQuery({ id: existingId }),
    enabled: Boolean(isEditing && existingId),
  });

  const snapshot = data?.snapshot;
  const canEditThisSnapshot = permissions.can('edit', snapshot).allowed;

  useEffect(() => {
    if (isPageCameraSnapshot || isPreUpload) {
      wizardSetData({
        pageCameraSnapshot: isPageCameraSnapshot,
        preUpload: isPageCameraSnapshot && isPreUpload,
      });
    }
  }, [wizardSetData, isPageCameraSnapshot, isPreUpload]);

  useEffect(() => {
    if (isEditing && snapshot)
      wizardSetData({
        existingSnapshot: {
          ...snapshot,
        },
      });
  }, [snapshot, isEditing, wizardSetData, isPageCameraSnapshot, isPreUpload]);

  useEffect(() => {
    if (isEditing && !fetching && snapshot === null) {
      // redirect to the dashboard gracefully if the provided snapshot id in the url does not exist
      navigate('/snapshots', { replace: true });
    } else if (!isEditing && !canCreateMore) {
      navigate('/snapshots', { state: { cannotCreateMoreSnapshots: true }, replace: true });
    }
  }, [navigate, isEditing, existingId, canCreateMore, fetching, snapshot]);

  const initialValues = useMemo(() => {
    if (isEditing && !snapshot) return null;
    return isEditing
      ? {
          snapshots: [
            generateInitialSnapshotSchema({
              siteUrl: snapshot.sourceUrl,
              validatedUrl: snapshot.sourceUrl,
              name: snapshot.name,
            }),
          ],
          deviceTracking: {
            custom: true,
            devices: [],
            customTracking: {
              screenshot: convertToDevice(snapshot.device),
              traffic: snapshot.trackingDevices.map((device) => convertToDevice(device)),
            },
          },
          starts: {
            option: snapshot.startsAtOption,
            at: snapshot.startsAt ? fromUnixTime(snapshot.startsAt) : fromUnixTime(snapshot.createdAt),
          },
          ends: {
            option: snapshot.expiresAtOption,
            days: defaultDays,
            atChanged: false,
            at: snapshot.expiresAt ? fromUnixTime(snapshot.expiresAt) : null,
            visits: snapshot.maxVisits,
          },
          blocking: {
            option: snapshot.removeElementsOption,
            elements: snapshot.removeElements ?? '',
            url: '',
          },
          samplingRatio: {
            option: snapshot.useSamplingRatio
              ? SNAPSHOT_SAMPLING_RATIO_OPTIONS.CUSTOM
              : SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT,
            ratio: snapshot.samplingRatio ?? 20,
          },
          captureTimer: {
            option: snapshot.useWaitAfterLoad
              ? SNAPSHOT_CAPTURE_TIMER_OPTIONS.CUSTOM
              : SNAPSHOT_CAPTURE_TIMER_OPTIONS.DEFAULT,
            seconds: snapshot.waitAfterLoad ?? 31,
          },
          trackingOptions: {
            option: snapshot.trackingOption,
            urlTracking:
              snapshot.trackingOption === SNAPSHOT_TRACKING_OPTIONS.URL
                ? {
                    ...convertKeysToCamelCase(JSON.parse(snapshot.matchingRuleParams)),
                  }
                : null,
            wildcard:
              snapshot.trackingOption === SNAPSHOT_TRACKING_OPTIONS.WILDCARD
                ? {
                    ...convertKeysToCamelCase(JSON.parse(snapshot.matchingRuleParams)),
                  }
                : null,
            regex:
              snapshot.trackingOption === SNAPSHOT_TRACKING_OPTIONS.REGEX
                ? {
                    ...convertKeysToCamelCase(JSON.parse(snapshot.matchingRuleParams)),
                  }
                : null,
            named:
              snapshot.trackingOption === SNAPSHOT_TRACKING_OPTIONS.NAMED
                ? {
                    ...convertKeysToCamelCase(JSON.parse(snapshot.matchingRuleParams)),
                  }
                : null,
            googleOptimize:
              snapshot.trackingOption === SNAPSHOT_TRACKING_OPTIONS.GOOGLE_OPTIMIZE
                ? {
                    trackingId: JSON.parse(snapshot.matchingRuleParams).google_optimize.split('.')[0],
                    experimentId: JSON.parse(snapshot.matchingRuleParams).google_optimize.split('.')[1],
                    variantId: JSON.parse(snapshot.matchingRuleParams).google_optimize.split('.')[2],
                  }
                : null,
          },
          recurringSnapshot: snapshot.reoccurring,
        }
      : {
          snapshots:
            isAbTestSnapshot && initialSiteUrls.length > 0
              ? [
                  ...initialSiteUrls.map((siteUrl, index) => {
                    return generateInitialSnapshotSchema({
                      siteUrl,
                      validatedUrl: siteUrl,
                      name: getParam('name')
                        .replace(/test (\d{2}-\d{2}-\d{4})/, '- $1')
                        .replace('[URL]', siteUrl)
                        .replace('[TYPE]', index === 0 ? 'Control' : `Variant ${index}`),
                    });
                  }),
                ]
              : [
                  generateInitialSnapshotSchema({
                    siteUrl: isPageCameraSnapshot ? searchParams.url : (getParam('url') ?? ''),
                    validatedUrl: null,
                    name: isPageCameraSnapshot ? searchParams.title : '',
                  }),
                ],
          pageCameraSnapshot: isPageCameraSnapshot,
          deviceTracking: {
            custom: isPageCameraSnapshot ? true : false,
            devices: isAbTestSnapshot && initialDevices.length > 0 ? initialDevices : [DESKTOP, TABLET, MOBILE],
            customTracking: {
              screenshot: isPageCameraSnapshot ? convertToDevice(searchParams.device) : DESKTOP,
              traffic: isPageCameraSnapshot ? [convertToDevice(searchParams.device)] : [DESKTOP, TABLET, MOBILE],
            },
          },
          starts: {
            option: SNAPSHOT_START_OPTIONS.IMMEDIATELY,
            at: startOfHour(addHours(new Date(), 1)),
          },
          ends: {
            option: SNAPSHOT_END_OPTIONS.DEFAULT,
            days: defaultDays,
            atChanged: false,
            at: addDays(startOfHour(addHours(new Date(), 1)), defaultDays),
            visits: defaultVisits,
          },
          blocking: {
            option: SNAPSHOT_BLOCKING_POPUP_OPTIONS.REMOVE_POPUPS,
            elements: '',
            url: '',
          },
          samplingRatio: {
            option: SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT,
            ratio: 20,
          },
          captureTimer: {
            option: SNAPSHOT_CAPTURE_TIMER_OPTIONS.DEFAULT,
            seconds: 31,
          },
          trackingOptions: {
            option: SNAPSHOT_TRACKING_OPTIONS.DEFAULT,
          },
          recurringSnapshot: false,
        };
  }, [
    defaultDays,
    defaultVisits,
    initialDevices,
    initialSiteUrls,
    isAbTestSnapshot,
    isEditing,
    isPageCameraSnapshot,
    searchParams.device,
    searchParams.title,
    searchParams.url,
    snapshot,
  ]);

  if (!isEditing && !canCreateMore) {
    return null;
  }

  // Loading
  if (fetching || userDataFetching || (isEditing && !snapshot)) {
    return (
      <WizardPage>
        <SEO title="Edit Snapshot" />
        <WizardLogo />
        <WizardHeader>{isEditing ? 'Let’s edit your Snapshot!' : 'Let’s make some Snapshots!'}</WizardHeader>

        <div className="mt-16 flex w-full items-center justify-center">
          <Spinner />
          <div className="ml-2.5">Loading...</div>
        </div>
      </WizardPage>
    );
  }

  // Can't edit error
  if (isEditing && !canEditThisSnapshot) {
    return (
      <WizardPage>
        <SEO title="Edit Snapshot" />
        <WizardLogo />
        <div className="mt-32 flex w-full flex-col items-center justify-center">
          <WebP webp={YellowWarningWebP} fallback={YellowWarningPNG} width="204px" height="162px" />

          <div className="text-header-2 mb-8 mt-8">This Snapshot can not be edited.</div>
          <Button component={Link} to="/snapshots">
            Take me back to the dashboard
          </Button>
        </div>
      </WizardPage>
    );
  }

  return (
    <WizardPage>
      <Formik initialValues={initialValues} validationSchema={generateValidationSchema(maxVisits, isEditing)}>
        <Form>
          <FieldArray name="snapshots">
            {(snapshotFieldArray) => (
              <>
                <SEO title={isEditing ? 'Edit Snapshot' : 'Create Snapshot'} />
                <WizardLogo />
                <Outlet context={snapshotFieldArray} />
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </WizardPage>
  );
}
