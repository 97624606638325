import { useEffect } from 'react';

import { CodeBox } from '/src/components/CodeBox';
import { useScriptUrl } from '/src/features/installation/utils';
import { useWizard } from '/src/hooks';
import { WizardHeader, WizardSubHeader, WizardList } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

export function WixInstructions() {
  const [code] = useScriptUrl();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/wix/check-script',
      nextEnabled: true,
      title: 'Integrate Crazy Egg with Wix',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s use Wix!</WizardHeader>
      <WizardSubHeader>Follow the instructions below for a completely code-free install.</WizardSubHeader>

      <div className="text-header-4 mb-2.5">Instructions:</div>
      <WizardList>
        <WizardList.Item>This feature is only available for sites with a connected domain.</WizardList.Item>
        <WizardList.Item>
          Within your Wix dashboard, click on <strong className="text-body-1">Settings</strong> in the left navigation.
        </WizardList.Item>
        <WizardList.Item>
          Scroll down to the <strong className="text-body-1">Advanced Settings</strong> section and click on{' '}
          <strong className="text-body-1">Custom Code</strong>.
        </WizardList.Item>
        <WizardList.Item>
          In the <strong className="text-body-1">top right-hand corner</strong>, click{' '}
          <strong className="text-body-1">&lsquo;+ Add Custom Code&rsquo;</strong>.
        </WizardList.Item>
        <WizardList.Item>Copy and paste the Crazy Egg tracking script below into the input field.</WizardList.Item>
        <WizardList.Item>
          Select <strong className="text-body-1">Load code on each new page</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Select <strong className="text-body-1">Place Code in Head</strong>.
        </WizardList.Item>
        <WizardList.Item>
          Click on <strong className="text-body-1">Apply</strong>.
        </WizardList.Item>
      </WizardList>

      <CodeBox loading={false} code={code} />

      <a href={SupportLinks.install.wix} className="text-link mt-2.5" target="_blank" rel="noreferrer">
        Learn more about integrating Crazy Egg with Wix
      </a>

      <div className="mt-5">
        Click on <strong className="text-body-1">Next</strong> once you have integrated Crazy Egg with Wix.
      </div>
    </div>
  );
}
