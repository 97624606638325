import classNames from 'classnames';

import { Modal } from '/src/contexts/modal';

export function CookieModal({ feature, domain, dark = true, className = null }) {
  return (
    <Modal
      dialogClassName={classNames('!w-[80%] !max-w-[450px]', className)}
      dismissable={false}
      disableOutsideClick={true}
      dark={dark}
    >
      <Modal.Title>Cookies not enabled</Modal.Title>
      <p
        className={classNames('text-sm leading-5', {
          'text-white': dark,
        })}
      >
        For the <strong>{feature}</strong> to work, you will need to enable cookies for our <strong>{feature}</strong>{' '}
        domain.
      </p>
      <p
        className={classNames('mt-5 text-sm leading-5', {
          'text-white': dark,
        })}
      >
        Please visit the cookies settings page in your browser. Select the option to <strong>allow</strong> cookies for{' '}
        <strong>{domain}</strong> and then reload this page.
      </p>
    </Modal>
  );
}
