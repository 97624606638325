import { useEffect, useMemo, useCallback } from 'react';
import { useAtom } from 'jotai';

import { isE2E } from '/src/utils';
import { authAtom } from '/src/machines/auth';
import { getMutations, provideInteragentJWT, provideAuthJWT } from '/src/services/web-editor';
import { VARIANT_STATUSES } from '/src/features/ab-testing/constants';
import { useModal, useAuthContext } from '/src/hooks';
import { CookieModal } from '/src/features/ab-testing/components/modals/CookieModal';
import { EditorPopupsModal } from '/src/features/ab-testing/components/modals/EditorPopupsModal';

function safeEncodeUrlForPath(url) {
  /* Copied from web-editor Shell integration */

  // By encoding any % symbol before encoding the URL we avoid the
  // (incorrect) decoding the browser does when reading the URL on the
  // web-editor.
  //
  // Without this fix, the following flow happens
  //
  // 1. Shell (decoded)     : "/foo%20bar"
  // 2. Shell (encoded)     : "%2Ffoo%2520bar"
  // 3. WebEditor (encoded) : "%2Ffoo%20bar"
  // 4. WebEditor (decoded) : "/foo bar" Which is not what we want
  //
  // As you can see we loose the %25 character escape between step 2 and 3,
  // after the URL is placed on the iframe's src attribute as part of the
  // path.
  //
  // To avoid this we double encode the % symbol.
  //
  // 1. Shell (decoded)     : "/foo%20bar"
  // 2. Shell (escape %)     : "/foo%2520bar"
  // 3. Shell (encoded)     : "%2Ffoo%252520bar"
  // 4. WebEditor (encoded) : "%2Ffoo%2520bar"
  // 5. WebEditor (decoded) : "/foo%20bar"
  //
  // Which is the same as initial input.
  return encodeURIComponent(url.replace(/%/g, '%25'));
}

export function VariantEditor({
  variant = null,
  service = null,
  debug = false,
  url,
  device = 'desktop',
  onChange = null,
  onUndoRedoChange = null,
  onLoaded = null,
  ...props
}) {
  const modal = useModal();
  const [state] = useAtom(authAtom);

  const { currentUser } = useAuthContext();

  const { explicitAgent } = currentUser.settings;

  const showCookieModal = useCallback(() => {
    modal.show(<CookieModal feature="Editor" domain="crazyeggeditor.com" dark={false} />);
  }, [modal]);

  const showPopupsModal = useCallback(() => {
    modal.show(<EditorPopupsModal service={service} />);
  }, [modal, service]);

  const handleMessage = useCallback(
    async (msg) => {
      let data;
      try {
        data = JSON.parse(msg.data);
        if (debug) {
          console.log('EDITOR says:', data); // eslint-disable-line
        }
      } catch {
        // noop
      }

      if (data?.name === 'test-editor-events' && data?.query?.eventType === 'interagentJWT') {
        // get the interagent JWT
        await provideInteragentJWT('test-editor-frame', data);
      }

      if (data?.name === 'test-editor-events' && data?.query?.eventType === 'authJWT') {
        // get the interagent JWT
        await provideAuthJWT('test-editor-frame', data, state.context.token);
      }

      if (data?.response?.editorPatch) {
        // got a new editor patch
        onChange?.(data?.response?.editorPatch);
      }

      if (
        data?.response &&
        (typeof data.response.undoEnabled !== 'undefined' || typeof data.response.redoEnabled !== 'undefined')
      ) {
        // got a new undo/redo state
        onUndoRedoChange?.(data?.response);
      }

      if (data?.name === 'test-editor-events' && data?.query?.eventType === 'statusChanged') {
        let status;
        switch (data.query.payload.status) {
          case 'loaded':
            status = VARIANT_STATUSES.PRISTINE;
            onLoaded?.();
            break;
          case 'dirty':
            status = VARIANT_STATUSES.DIRTY;
            break;
          case 'enableCookies':
            showCookieModal();
            break;
          default:
            status = data.query.payload.status;
            break;
        }

        if (status === VARIANT_STATUSES.DIRTY) {
          // set the undo enabled to true
          // setHasUndo(true);
          await getMutations(variant.id);
          // const variantSnapshot = {
          //   ...variant,
          //   status,
          //   editorPatch: mutations,
          // };
          // onChange?.(variantSnapshot);
        }
      }

      if (data?.name === 'test-editor-events' && data?.query?.eventType === 'showPopupsModal') {
        showPopupsModal();
      }
    },
    [debug, onChange, onLoaded, onUndoRedoChange, showCookieModal, variant.id, state.context.token, showPopupsModal],
  );

  // this hook should run when the variants change to avoid stale data
  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const frameSrc = useMemo(
    () =>
      `${window.WEB_EDITOR_URL}/pages/${safeEncodeUrlForPath(
        url,
      )}/blank?device=${device}&name=test-editor&explicit_agent=${explicitAgent}`,
    [device, url, explicitAgent],
  );

  if (!window.WEB_EDITOR_URL) {
    console.warn('[web-editor] URL config not found');
    return null;
  }

  if (isE2E()) {
    return (
      <iframe
        name="test-editor-frame"
        id="test-editor-frame"
        title="Test Editor"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        scrolling="no"
        frameBorder="0"
        {...props}
      />
    );
  }

  return (
    <iframe
      id="test-editor-frame"
      title="Test Editor"
      src={frameSrc}
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      scrolling="no"
      frameBorder="0"
      {...props}
    />
  );
}
