import { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { usePermissions, useAuthContext, useMutation, useModal } from '/src/hooks';
import { addonDeleteMutation } from '/src/features/addons/mutations';
import { getSessionQueryParameters } from '/src/utils/url';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';
import { RenameAddonModal } from './modals/RenameAddonModal';

import { ADDON_TYPES } from '/src/features/addons/constants';
import { FEATURES, ROOT_ELEMENT_ID } from '/src/features/_global/constants';

import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as ChartIcon } from '@crazyegginc/hatch/dist/images/icon-chart-filled.svg';
import { ReactComponent as DownloadIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-download.svg';
import { ReactComponent as ShareLinkIcon } from '@crazyegginc/hatch/dist/images/icon-link-outline.svg';
import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';

export function RowActions({ addon }) {
  const permissions = usePermissions();
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;
  const canCreate = addon.type === ADDON_TYPES.CTA ? canCreateCTA : canCreateSurvey;

  const canViewResults = permissions.can('viewResults', addon).allowed;
  const canViewSettings = permissions.can('viewSettings', addon).allowed;
  const canEdit = permissions.can('edit', addon).allowed;
  const canDelete = permissions.can('delete', addon).allowed;
  const canExport = permissions.can('export', addon).allowed;
  const canShare = permissions.can('share', addon).allowed;
  const canRename = permissions.can('rename', addon).allowed;

  const canDoSomething =
    canCreate || canViewResults || canViewSettings || canEdit || canDelete || canExport || canShare || canRename;

  const disableActions = !canDoSomething;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              className={classNames('flex h-10 items-center rounded px-4', {
                'border border-mystic-500 bg-white shadow-md': open,
                'cursor-not-allowed disabled:text-cadet-blue-500': disableActions,
                'text-dodger-blue-300': !disableActions,
              })}
              disabled={disableActions}
            >
              <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
              <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </div>

          {open
            ? createPortal(
                <StyledPopoverPanel
                  ref={setTooltipElement}
                  onMouseDown={(e) => e.stopPropagation()}
                  className="w-[250px]"
                  style={{ ...styles.popper }}
                  {...attributes.popper}
                >
                  {() => (
                    <>
                      <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />
                      <DropdownActions addon={addon} />
                    </>
                  )}
                </StyledPopoverPanel>,
                document.querySelector(`#${ROOT_ELEMENT_ID}`),
              )
            : null}
        </>
      )}
    </Popover>
  );
}

export function PopoverActions({ addon, onDelete = null }) {
  const permissions = usePermissions();
  const { isSharing } = useAuthContext();
  const [referenceElement, setReferenceElement] = useState(null);
  const [boxElement, setBoxElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, boxElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;
  const canCreate = addon.type === ADDON_TYPES.CTA ? canCreateCTA : canCreateSurvey;

  const canViewSettings = permissions.can('viewSettings', addon).allowed;
  const canEdit = permissions.can('edit', addon).allowed;
  const canDelete = permissions.can('delete', addon).allowed;
  const canExport = permissions.can('export', addon).allowed;
  const canShare = permissions.can('share', addon).allowed;
  const canRename = permissions.can('rename', addon).allowed;

  const canDoSomething = canCreate || canViewSettings || canEdit || canDelete || canExport || canShare || canRename;

  const disableActions = !canDoSomething;

  if (disableActions && isSharing) return null;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              'flex h-[35px] w-[70px] items-center justify-center rounded px-4 text-dodger-blue-300',
              {
                'border border-mystic-500 bg-white shadow-md': open,
              },
            )}
            disabled={disableActions}
          >
            <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
            <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
          </Popover.Button>
          {open ? (
            <StyledPopoverPanel
              ref={setBoxElement}
              align="center"
              static
              className="w-[260px] !pr-1"
              data-testid="actions-dropdown"
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />

              <DropdownActions addon={addon} onDelete={onDelete} hideViewResults={true} />
            </StyledPopoverPanel>
          ) : null}
        </>
      )}
    </Popover>
  );
}

function DropdownActions({ addon, onDelete = null, hideViewResults = false }) {
  const modal = useModal();
  const deleteAddon = useMutation(addonDeleteMutation);
  const permissions = usePermissions();

  const isDraft = !addon.publishedAt;

  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;
  const canCreate = addon.type === ADDON_TYPES.CTA ? canCreateCTA : canCreateSurvey;

  const canViewResults = permissions.can('viewResults', addon).allowed;
  const canViewSettings = permissions.can('viewSettings', addon).allowed;
  const canEdit = permissions.can('edit', addon).allowed;
  const canDelete = permissions.can('delete', addon).allowed;
  const canExport = permissions.can('export', addon).allowed;
  const canShare = permissions.can('share', addon).allowed;
  const canRename = permissions.can('rename', addon).allowed;

  const hasDraftEdits = addon.draftContent || addon.drafName;
  const humanizedType = addon.type === ADDON_TYPES.SURVEY ? 'Survey' : 'CTA';

  return (
    <>
      {canViewResults && !hideViewResults && !isDraft && (
        <Item
          component={Link}
          to={{ pathname: `/addons/${addon.id}`, search: getSessionQueryParameters() }}
          icon={ChartIcon}
        >
          View results
        </Item>
      )}

      {canViewSettings && !isDraft && (
        <Item
          component={Link}
          to={{ pathname: `/addons/${addon.id}/view`, search: getSessionQueryParameters() }}
          icon={CogIcon}
        >
          View settings
        </Item>
      )}

      {canEdit && (
        <Item
          component={Link}
          to={{ pathname: `/addons/${addon.id}/edit`, search: getSessionQueryParameters() }}
          icon={EditIcon}
        >
          {hasDraftEdits ? `Continue editing ${humanizedType}` : `Edit this ${humanizedType}`}
        </Item>
      )}

      {canShare && (
        <Item
          onClick={() => {
            window.SharingModal.show({ entity: { id: addon.id, siteId: addon.siteId, type: addon.type } });
          }}
          icon={ShareLinkIcon}
        >
          Get share link
        </Item>
      )}

      {canCreate && (
        <Item
          component={Link}
          to={{ pathname: `/addons/${addon.id}/copy`, search: getSessionQueryParameters() }}
          icon={DuplicateIcon}
        >
          Copy this {humanizedType}
        </Item>
      )}

      {canRename && (
        <Item
          onClick={() => {
            modal.show(<RenameAddonModal addon={addon} />);
          }}
          icon={EditIcon}
        >
          Rename this {humanizedType}
        </Item>
      )}

      {canDelete && (
        <Item
          icon={TrashIcon}
          iconClassName="group-hover:!text-carnation-500"
          onClick={() => {
            modal.show(
              <DeleteConfirmationModal
                onDelete={async () => {
                  try {
                    const data = await deleteAddon.mutateAsync({
                      id: addon.id,
                      siteId: addon.siteId,
                    });
                    if (data) {
                      modal.close();
                      onDelete?.();
                    }
                  } catch {
                    //noop
                  }
                }}
                text={
                  <>
                    Are you sure you want to delete the {humanizedType}{' '}
                    <span className="text-body-1">{addon.name}</span>?{' '}
                    {!isDraft && addon.type === ADDON_TYPES.SURVEY
                      ? 'Deleting this survey will also delete all collected responses for it.'
                      : ''}
                  </>
                }
                entity={humanizedType}
              />,
            );
          }}
        >
          Delete this {humanizedType}
        </Item>
      )}

      {canExport && <DownloadLink survey={addon} />}
    </>
  );
}

function DownloadLink({ survey }) {
  const { token } = useAuthContext();

  return (
    <Item
      component="a"
      href={`${window.CORE_API_URL}/export/survey.csv?site_id=${survey.siteId}&survey_id=${survey.id}&token=${token}`}
      icon={DownloadIcon}
      download={true}
    >
      Download results as CSV
    </Item>
  );
}

function Item({ children, icon: Icon, className, iconClassName, disabled, ...props }) {
  return (
    <PopoverItem
      className={classNames('!flex !select-none !items-center !py-2', className, {
        'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
        'group ': !disabled,
      })}
      disabled={disabled}
      data-testid="action-item"
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames('mr-5 h-4 w-4 fill-current group-hover:text-dodger-blue-500', iconClassName, {
            'text-cadet-blue-500': disabled,
            'text-dodger-blue-300': !disabled,
          })}
        />
      )}
      {children}
    </PopoverItem>
  );
}
