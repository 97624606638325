import { FEATURES, ALLOWED, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

export const GOALS_PERMISSIONS = {
  [FEATURES.GOALS]: ({ capabilities, isSharing, sharedResource }) => {
    const sharedResourceIsGoal = sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.GOAL;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsGoal ? ALLOWED : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
          create: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.goals.permissions.nav,
          view: capabilities.goals.permissions.view,
          edit: capabilities.goals.permissions.edit,
          create: capabilities.goals.permissions.create,
        };
  },
  Goal: ({ entity: goal = null }) => ({
    view: goal?.permissions?.canView ? ALLOWED : PERMISSION_NOT_ALLOWED,
    share: goal?.permissions?.canShare ? ALLOWED : PERMISSION_NOT_ALLOWED,
    edit: goal?.permissions?.canEdit ? ALLOWED : PERMISSION_NOT_ALLOWED,
    delete: goal?.permissions?.canDelete ? ALLOWED : PERMISSION_NOT_ALLOWED,
  }),
};
