import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { Spinner, LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useMutation, useSite, useNotifications, useModal } from '/src/hooks';
import { newGoalMachine } from '/src/features/goals/machines/wizard';
import Wizard from '/src/components/wizard/Wizard';

import { abTestDetailQuery } from '../queries';
import { goalDeleteMutation } from '/src/features/goals/mutations';
import { abTestChangeGoalMutation } from '../mutations';
import { goalListQuery } from '/src/features/goals/queries';

import { GoalSelector } from '/src/features/goals/components/GoalSelector';
import { GoalsWizard } from '/src/features/goals/components/GoalsWizard';
import { ConfirmLeaveWizardModal } from '/src/components/wizard/ConfirmLeaveWizardModal';

export const abTestGoalSchema = yup.object().shape({
  goalId: yup.number().required(),
});

export function AbTestGoalSelectorPage({ newGoal = false }) {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { id } = useParams();
  const modal = useModal();
  const [selected, setSelected] = useState(null);

  const { data: abTestData, isFetching: fetchingAbTest } = useQuery({
    ...abTestDetailQuery({ id: Number(id) }),
    enabled: !!id,
  });

  const {
    data: goalsData,
    isFetching: fetchingGoals,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...goalListQuery({
      siteId: abTestData?.abTestDetail?.siteId,
    }),
    enabled: !!abTestData?.abTestDetail?.siteId,
  });

  const { mutate: mutateSetNewGoal, isLoading: updatingGoal } = useMutation(abTestChangeGoalMutation);
  const { mutate: mutateDeleteGoal, isLoading: deletingGoal } = useMutation(goalDeleteMutation);

  const showConfirmModal = useCallback(
    ({ onConfirm = null }) => {
      modal.show(<ConfirmLeaveWizardModal onConfirm={onConfirm} />);
    },
    [modal],
  );

  const backToDashboard = useCallback(() => {
    navigate('..', { replace: true });
  }, [navigate]);

  const goals = useMemo(
    () => (goalsData?.pages ?? []).reduce((acc, page) => [...acc, ...(page?.goalList?.list ?? [])], []) ?? [],
    [goalsData],
  );

  if (!abTestData || !goalsData) return <LoaderBalloonScreen text={true} />;

  if (newGoal || (goalsData && goals.length === 0)) {
    // immediately navigate to create the first goal
    return (
      <NewGoalFromSelector
        onDone={(goalId) => {
          mutateSetNewGoal(
            { id: Number(id), goalId: Number(goalId) },
            {
              onSuccess: () => {
                notifications.success({ content: `Goal for A/B test successfully changed.`, timeout: 3000 });
                navigate(`/ab-tests/${id}`, { replace: true });
              },
              onError: (error) => {
                notifications.error({
                  content: `Failed to change the Goal for your A/B test.`,
                  timeout: 3000,
                  context: { error },
                });
              },
            },
          );
        }}
      />
    );
  }

  const existingGoalId = abTestData?.abTestDetail?.goal?.id;

  return (
    <Wizard>
      <Wizard.Header>
        <Wizard.CloseButton onClick={() => showConfirmModal({ onConfirm: backToDashboard })} />
      </Wizard.Header>
      <Wizard.Content dontMask={true}>
        <div className="mx-auto flex w-[740px] flex-col space-y-10">
          <h1 className="text-header-0 m-0 mt-14 text-center">What&#39;s your goal for this test?</h1>

          {(fetchingAbTest || fetchingGoals) && !goals.length ? (
            <div className="mt-16 flex w-full items-center justify-center">
              <Spinner />
              <div className="ml-2.5">Loading...</div>
            </div>
          ) : (
            <GoalSelector
              loadNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              fetching={fetchingGoals}
              selected={selected?.id ?? existingGoalId}
              onSelect={(value) => setSelected(value)}
              goals={goals}
              onCreate={() => {
                navigate(`/ab-tests/${id}/goals/new`);
              }}
              onEdit={(goal) => {
                navigate(`/ab-tests/${id}/goals/${goal.id}/edit`);
              }}
              deleting={deletingGoal}
              onDelete={(goalId) => {
                mutateDeleteGoal(
                  { id: Number(goalId) },
                  {
                    onSuccess: () => {
                      notifications.success({ content: 'Goal deleted successfully.', timeout: 3000 });
                      modal.close();
                    },
                    onError: (error) => {
                      notifications.error({ content: 'Goal delete failed.', timeout: 3000, context: { error } });
                    },
                  },
                );
              }}
            />
          )}
        </div>
      </Wizard.Content>
      <Wizard.SimpleFooter
        showMeta={false}
        nextText={updatingGoal ? 'Updating test goal...' : 'Set new goal'}
        nextEnabled={!!selected?.id && !updatingGoal && selected.id !== existingGoalId}
        onNext={() => {
          mutateSetNewGoal(
            { id: Number(id), goalId: Number(selected?.id) },
            {
              onSuccess: () => {
                notifications.success({ content: `Goal for A/B test successfully changed.`, timeout: 3000 });
                navigate(`/ab-tests/${id}`, { replace: true });
              },
              onError: (error) => {
                notifications.error({
                  content: `Failed to change the Goal for your A/B test.`,
                  timeout: 3000,
                  context: { error },
                });
              },
            },
          );
        }}
        onCancel={backToDashboard}
      />
    </Wizard>
  );
}

function NewGoalFromSelector({ onDone }) {
  const mutationCalled = useRef(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, send, service] = useMachine(newGoalMachine, { devTools: true });
  const { selectedSite } = useSite();

  useEffect(() => {
    if (state.value === 'initialize' && selectedSite) {
      send({ type: 'INITIAL_DATA', selectedSite });
    }
  }, [selectedSite, send, state.value]);

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.value === 'cancelled') {
        return navigate(`/ab-tests/${id}`, { replace: true });
      }
      if (state.value === 'completed' && !mutationCalled.current) {
        mutationCalled.current = true;
        await onDone?.(state.context.goalId);
        return navigate(`/ab-tests/${id}`, { replace: true });
      }
    });

    return subscription.unsubscribe;
  }, [id, navigate, onDone, service]);

  return <GoalsWizard service={service} skipNotifications={true} />;
}
