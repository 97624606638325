import { useCallback, useMemo, useState, useEffect } from 'react';
import { useFormikContext, getIn } from 'formik';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { RG, Tooltip, Spinner } from '@crazyegginc/hatch';

import { useDebounce, useSite, useMutation } from '/src/hooks';
import { isBrightColor } from '/src/utils/color';
import { useEditorContext } from '../../editor-context';
import { setSiteColors } from '/src/features/_global/mutations';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

const MAX_SHOWN_SITE_COLORS = 8;

export function ColorSelector_v2({ defaultOptions, label = 'Accent color', fieldPath, canEditSiteColors = true }) {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const { selectedSite, sites } = useSite({ dontSelectSite: true });
  const { mutate: mutateSetSiteColors, isLoading } = useMutation(setSiteColors);

  const value = getIn(values, fieldPath);

  const [customColor, setCustomColor] = useState('');
  const debouncedColor = useDebounce(customColor, 400);
  const [editMode, setEditMode] = useState(false);
  const [deletedColors, setDeletedColors] = useState([]);

  const siteColors = useMemo(() => {
    const site = sites.find((s) => s.id === selectedSite.id);
    if (!site) return [];
    return JSON.parse(site.metadata).colors || [];
  }, [sites, selectedSite]);

  useEffect(() => {
    if (debouncedColor) {
      setFieldValue(fieldPath, debouncedColor);
    }
  }, [fieldPath, debouncedColor, setFieldValue]);

  function doneManagingColors() {
    if (!deletedColors.length) {
      setEditMode(false);
      return;
    }

    mutateSetSiteColors(
      {
        siteId: selectedSite.id,
        colors: siteColors.filter((c) => !deletedColors.includes(c)),
      },
      {
        onSettled: () => {
          setEditMode(false);
          setDeletedColors([]);
        },
      },
    );
  }

  const colorInputHandlers = useCallback(
    (node) => {
      if (node) {
        node.onchange = (e) => {
          const newColor = e.target.value;
          if (newColor && siteColors[0] !== newColor) {
            const filteredColors = siteColors.filter((c) => c !== newColor);
            mutateSetSiteColors({ siteId: selectedSite.id, colors: [newColor, ...filteredColors] });
          }
        };
        node.oninput = (e) => setCustomColor(e.target.value);
      }
    },
    [mutateSetSiteColors, siteColors, selectedSite.id],
  );

  return (
    <div className="self-start">
      <RG value={value} onChange={(newValue) => setFieldValue(fieldPath, newValue)} disabled={readonly}>
        <RG.Label className="text-body-1 mb-2 block">{label}</RG.Label>
        <div className="flex h-9 items-center space-x-2.5 rounded-full bg-white-lilac-500 px-4">
          <div className="relative h-6 w-9 rounded" style={{ background: value }}>
            <input
              ref={colorInputHandlers}
              type="color"
              value={customColor}
              className={classNames('absolute left-0 top-0 h-6 w-9 rounded opacity-0', {
                'cursor-pointer': !readonly,
                'cursor-not-allowed': readonly,
              })}
              onFocus={() => {
                if (debouncedColor) {
                  setFieldValue(fieldPath, debouncedColor);
                }
              }}
              disabled={readonly}
            />
          </div>
          <div className="text-body-2">{value.toUpperCase()}</div>
        </div>
        <div className="flex h-9 items-center space-x-1.25">
          {defaultOptions.map((option) => (
            <ColorSwatchItem option={option} key={option} />
          ))}
        </div>
        {siteColors.length > 0 ? (
          <>
            <div className="text-body-4 -mb-2">Site colors:</div>
            {editMode ? (
              <>
                <div className="flex h-9 items-center space-x-1.25">
                  {siteColors
                    .filter((c) => !deletedColors.includes(c))
                    .slice(0, MAX_SHOWN_SITE_COLORS)
                    .map((color) => {
                      const isBright = isBrightColor(color);
                      return (
                        <Tooltip key={color} tooltipContent={`Delete ${color.toUpperCase()}`} placement="bottom">
                          <button
                            type="button"
                            onClick={() => setDeletedColors((x) => [color, ...x])}
                            className={classNames(
                              'flex h-6 w-6 items-center justify-center rounded-full border-2 border-transparent',
                              'cursor-pointer',
                              {
                                'hover:border-black/40': isBright,
                                'hover:border-dodger-blue-300/90': !isBright,
                              },
                            )}
                            style={{ background: color }}
                          >
                            <CrossIcon
                              className={classNames(' h-2.5 w-2.5 fill-current', {
                                'text-white': !isBright,
                                'text-black-pearl-500': isBright,
                              })}
                              aria-label="delete color"
                            />
                          </button>
                        </Tooltip>
                      );
                    })}
                  <Tooltip tooltipContent="Done managing colors" placement="bottom">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={doneManagingColors}
                      className={classNames(
                        'relative flex h-6 w-6 items-center justify-center rounded-full',
                        !isLoading && 'text-black-pearl-500 hover:bg-mystic-500 hover:text-dodger-blue-500',
                      )}
                    >
                      {isLoading ? (
                        <Spinner className="h-4 w-4 text-cadet-blue-500" />
                      ) : (
                        <TickIcon className="h-3 w-3 fill-current" aria-label="done managing colors" />
                      )}
                    </button>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div className="flex h-9 items-center space-x-1.25">
                {siteColors.slice(0, MAX_SHOWN_SITE_COLORS).map((option) => (
                  <ColorSwatchItem option={option} key={option} />
                ))}
                {readonly || !canEditSiteColors ? null : (
                  <Tooltip tooltipContent="Manage site colors" placement="bottom">
                    <button
                      type="button"
                      onClick={() => setEditMode(true)}
                      className={classNames(
                        'relative flex h-6 w-6 items-center justify-center rounded-full',
                        'text-black-pearl-500 hover:bg-solitude-500 hover:text-dodger-blue-500',
                      )}
                    >
                      <EditIcon className="h-3 w-3 fill-current" aria-label="manage site colors" />
                    </button>
                  </Tooltip>
                )}
              </div>
            )}
          </>
        ) : null}
      </RG>
    </div>
  );
}

function ColorSwatchItem({ option }) {
  const { readonly } = useEditorContext();

  return (
    <Tooltip tooltipContent={option.toUpperCase()} placement="bottom">
      <RG.Option key={option} value={option} className="focus:outline-none" disabled={readonly} aria-label={option}>
        {({ active, checked }) => {
          const isBright = isBrightColor(option);
          return (
            <div
              className={classNames(
                'flex h-6 w-6 items-center justify-center rounded-full border-2 border-transparent',
                readonly
                  ? 'cursor-not-allowed'
                  : {
                      'cursor-pointer': true,
                      'hover:border-black/40': isBright,
                      'border-black/40': active && isBright,
                      'hover:border-dodger-blue-300/90': !isBright,
                      'border-dodger-blue-300/90': active && !isBright,
                    },
                option.toUpperCase() === '#FFFFFF' && '!border-mystic-500 hover:!border-black/40',
              )}
              style={{ background: option }}
            >
              <Transition
                show={checked}
                enter="transition ease-in duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <TickIcon
                  className={classNames(' h-2.5 w-2.5 fill-current', {
                    'text-white': !isBright,
                    'text-black-pearl-500': isBright,
                  })}
                />
              </Transition>
            </div>
          );
        }}
      </RG.Option>
    </Tooltip>
  );
}
