import { gql } from '@urql/core';
import { produce } from 'immer';

import { errorsFeatureKeys } from './queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const updateErrorMutation = ({ client, queryClient }) => ({
  mutationFn: ({ siteId, status, note, fingerprintMd5 }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateError($errorStatus: ErrorStatusParams!, $site: Int!) {
          updateError(errorStatus: $errorStatus, site: $site) {
            fingerprintMd5
            status
          }
        }
      `,
      {
        site: siteId,
        errorStatus: {
          status,
          note,
          fingerprintMd5,
        },
      },
    ),
  onSuccess: (data, variables) => {
    queryClient.setQueriesData(
      {
        ...errorsFeatureKeys.uniqueError({
          fingerprintMd5: data.updateError.fingerprintMd5,
          siteId: variables.siteId,
        }),
      },
      (oldData) =>
        oldData
          ? produce(oldData, (draft) => {
              draft.uniqueError.status = data.updateError.status;
            })
          : oldData,
    );
    queryClient.invalidateQueries({ queryKey: errorsFeatureKeys.uniqueErrorListStats._def });
    queryClient.invalidateQueries({ queryKey: errorsFeatureKeys.uniqueErrorList._def });
    queryClient.invalidateQueries({
      ...errorsFeatureKeys.uniqueErrorNotes({
        fingerprintMd5: data.updateError.fingerprintMd5,
        siteId: variables.siteId,
      }),
    });
    queryClient.invalidateQueries({
      ...errorsFeatureKeys.uniqueError({
        fingerprintMd5: data.updateError.fingerprintMd5,
        siteId: variables.siteId,
      }),
    });
  },
});
