import { PlayerEvent } from '../PlayerEvent';
import { useModal } from '/src/hooks';
import { ErrorDetailsModal } from '../modals/ErrorDetailsModal';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function ErrorEvent({ timestamp, active, fingerprint, errorId, siteId, onClick, ...rest }) {
  const modal = useModal();

  return (
    <PlayerEvent
      icon={ErrorIcon}
      title="Error"
      text={fingerprint}
      timestamp={timestamp}
      active={active}
      isErrorEvent={true}
      onClick={() => {
        onClick?.();
        modal.show(<ErrorDetailsModal errorId={errorId} siteId={siteId} />);
      }}
      {...rest}
    />
  );
}
