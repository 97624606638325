import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { FEATURES } from '/src/features/_global/constants';
import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';

import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions, useSelectedSite } from '/src/hooks';
import { NoCTAsWall } from '/src/features/addons/paywalls/NoCTAsWall';
import { addonListQuery } from '/src/features/addons/queries';
import { ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as CTAsIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';

export function CTAsSection({ data, loading }) {
  return (
    <Section className="col-span-1" to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }}>
      <Section.Header icon={CTAsIcon} color="bg-riptide-500">
        <span>CTAs</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.CTAS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.CTAS} reason={reason} mini={true} />;
  }

  return <CTAContent data={data} loading={loading} />;
}

function CTAContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();

  const { data: addonsData, isLoading } = useInfiniteQuery({
    ...addonListQuery({
      siteId: selectedSite?.id,
      filter: {
        limit: 1,
        query: undefined,
        type: ADDON_TYPES.CTA,
      },
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const ctas = useMemo(
    () => addonsData?.pages.reduce((acc, page) => [...acc, ...(page.addonList?.list ?? [])], []) ?? [],
    [addonsData],
  );

  if (!isLoading && ctas.length === 0) {
    return (
      <div className="mb-5">
        <NoCTAsWall mini={true} />
      </div>
    );
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-between gap-x-3">
        <TrendMetric text="Active" value={data?.addons.activeCtas} loading={loading} />
        <TrendMetric
          text="Total clicks"
          value={data?.addons.totalClicks.current}
          oldValue={data?.addons.totalClicks.previous}
          loading={loading}
        />
      </div>

      <div className="rounded bg-white-lilac-500 px-4 py-2">
        <div className="text-body-1 mb-2">Most clicked</div>
        {loading ? (
          <>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-4/5" />
            </div>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-3/5" />
            </div>
            <div className="mb-0 flex h-5 items-center">
              <SkeletonLine className="!w-2/3" />
            </div>
          </>
        ) : (
          <>
            {data?.addons.topCtas.map((c) => (
              <div key={c.id} className="mb-1 flex last:mb-0">
                <div key={c.id} className="grow truncate">
                  <Link className="text-link" to={`/addons/${c.id}`}>
                    {c.name}
                  </Link>
                </div>
                <div className="ml-4 flex w-12 shrink-0 items-center justify-end text-right">
                  {c.clicks.toLocaleString()}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }} />
    </>
  );
}
