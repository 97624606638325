export const ERROR_STATUSES = {
  UNRESOLVED: 'UNRESOLVED',
  RESOLVED: 'RESOLVED',
  MUTED: 'MUTED',
};

export const NETWORK_ERROR = 'NETWORK_ERROR';
export const UNKNOWN_RESOURCE_ERROR = 'UNKNOWN_RESOURCE_ERROR';

export const UNIQUE_ERRORS_ORDER = {
  BOUNCE_RATIO: 'BOUNCE_RATIO',
  COUNT: 'COUNT',
  FINGERPRINT: 'FINGERPRINT',
  LAST_SEEN: 'LAST_SEEN',
  PAGES_AFFECTED: 'PAGES_AFFECTED',
  RAGE_CLICK_RATIO: 'RAGE_CLICK_RATIO',
  VISITORS_AFFECTED_RATIO: 'VISITORS_AFFECTED_RATIO',
};

export const ERRORS_FILTER_ACTIONS = {
  SET_ORDER_FIELD: 'SET_ORDER_FIELD',
  SET_ORDER_SORT: 'SET_ORDER_SORT',
  SET_SEARCH: 'SET_SEARCH',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_STATUS: 'SET_STATUS',
};
