import { useEffect, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

export function useNavBarDimensions() {
  const initialNavBarHeight = useRef();
  const { width: navBarWidth, height: navBarHeight } = useResizeObserver({ ref: document.querySelector('#navbar') });

  useEffect(() => {
    initialNavBarHeight.current = document.querySelector('#navbar')?.getBoundingClientRect().height;
  }, []);

  return { navBarWidth, navBarHeight, initialNavBarHeight: initialNavBarHeight.current };
}
