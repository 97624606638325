import { Suspense } from 'react';
import { LoaderBalloon, LoaderBalloonScreen } from '@crazyegginc/hatch';
import { Outlet } from 'react-router-dom';

import { DARK_PATHS } from '/src/features/_global/constants';
import { ModalProvider } from '/src/contexts/modal';
import { ScrollToTopOnNavigate } from '/src/utils/scroll';
import { OutdatedBundleHandler } from '/src/components/OutdatedBundleHandler';
import { useGeneralCompatibilityCheck, usePageViews, useSharingModal, useAuthContext } from '/src/hooks';

import { SEO } from './SEO';
import { NavBar } from '/src/components/layout/NavBar';
import { SessionBanner } from '/src/components/banners/SessionBanner';
import { DashHeader } from './headers/DashHeader';
import { AdminAnnouncement } from '/src/components/AdminAnnouncement';

export function Root({ navBar = true }) {
  const { isSharing, currentUser, sharedResource } = useAuthContext();
  useGeneralCompatibilityCheck();
  usePageViews();
  useSharingModal();

  const isDarkLoader = DARK_PATHS.some((route) => window.location.pathname.match(route));

  // ensure we never show the navbar in shared sessions
  // also never if the currentUser isn't available/loaded yet
  // since the navbar is extremely permission specific.
  const showNavbar = navBar && !isSharing && !!currentUser;

  return (
    <ModalProvider>
      {currentUser || sharedResource ? (
        <div
          className="flex min-h-screen flex-col"
          style={!isDarkLoader ? { maxWidth: 'max(100vw, 1350px)', minWidth: 'max(100%, 1350px)' } : null}
        >
          <SEO />
          <SessionBanner />
          <AdminAnnouncement />
          <div className="flex h-full w-full">
            {showNavbar ? <NavBar /> : null}
            <Suspense fallback={<AppLayoutSuspenseLoader />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      ) : (
        <LoaderBalloonScreen dark={isDarkLoader} />
      )}
      <OutdatedBundleHandler />
      <ScrollToTopOnNavigate />
    </ModalProvider>
  );
}

function AppLayoutSuspenseLoader() {
  return (
    <div className="flex h-screen w-full flex-col">
      <DashHeader />
      <div className="flex h-full w-full items-center justify-center">
        <LoaderBalloon />
      </div>
    </div>
  );
}
