import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const errorsFeatureKeys = createQueryKeys('errorsFeature', {
  uniqueErrorListStats: ({ siteId, startAt, endAt, status, search }) => [
    {
      siteId,
      startAt,
      endAt,
      status,
      search,
    },
  ],
  uniqueErrorList: ({ siteId, limit, field, sort, startAt, endAt, status, search }) => [
    {
      siteId,
      limit,
      order: {
        field,
        sort,
      },
      startAt,
      endAt,
      status,
      search,
    },
  ],
  uniqueError: ({ fingerprintMd5, siteId }) => [{ fingerprintMd5, siteId }],
  uniqueErrorNotes: ({ fingerprintMd5, siteId }) => [{ fingerprintMd5, siteId }],
  errorList: ({ fingerprintMd5, limit, page, siteId, field, sort, startAt, endAt }) => [
    {
      fingerprintMd5,
      limit,
      page,
      siteId,
      order: {
        field,
        sort,
      },
      startAt,
      endAt,
    },
  ],
  errorListTotal: ({ fingerprintMd5, siteId, startAt, endAt }) => [
    {
      fingerprintMd5,
      siteId,
      startAt,
      endAt,
    },
  ],
  uniqueErrorStats: ({ fingerprintMd5, siteId, startAt, endAt }) => [
    {
      fingerprintMd5,
      siteId,
      startAt,
      endAt,
    },
  ],
  error: ({ id, siteId }) => [{ id, siteId }],
});

export const uniqueErrorListStatsQuery = ({ siteId, startAt, endAt, status, search }) => ({
  ...errorsFeatureKeys.uniqueErrorListStats({ siteId, startAt, endAt, status, search }),
  meta: {
    query: gql`
      query UniqueErrorListStats(
        $siteId: Int!
        $startAt: Int!
        $endAt: Int!
        $status: ErrorStatusEnum!
        $search: String
      ) {
        uniqueErrorListStats(siteId: $siteId, startAt: $startAt, endAt: $endAt, status: $status, search: $search) {
          browsersAffected
          count
          pagesAffected
          usersAffected
        }
      }
    `,
  },
});

export const uniqueErrorListQuery = ({ siteId, limit, field, sort, startAt, endAt, status, search }) => ({
  ...errorsFeatureKeys.uniqueErrorList({
    siteId,
    limit,
    field,
    sort,
    startAt,
    endAt,
    status,
    search,
  }),
  getNextPageParam: (lastPage) =>
    lastPage.uniqueErrorList.hasNextPage ? lastPage.uniqueErrorList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query UniqueErrorList(
        $cursor: String
        $order: UniqueErrorsOrder!
        $siteId: Int!
        $limit: Int!
        $startAt: Int!
        $endAt: Int!
        $status: ErrorStatusEnum!
        $search: String
      ) {
        uniqueErrorList(
          cursor: $cursor
          order: $order
          siteId: $siteId
          limit: $limit
          startAt: $startAt
          endAt: $endAt
          status: $status
          search: $search
        ) {
          hasNextPage
          nextPageCursor
          uniqueErrors {
            bounceRatio
            count
            fingerprint
            fingerprintMd5
            lastErrorMessage
            lastSeen
            pagesAffected
            rageClickRatio
            visitorsAffectedRatio
          }
        }
      }
    `,
  },
});

export const UNIQUE_ERROR_QUERY = gql`
  query UniqueError($fingerprintMd5: String!, $siteId: Int!) {
    uniqueError(fingerprintMd5: $fingerprintMd5, siteId: $siteId) {
      __typename
      errorName
      fingerprint
      fingerprintMd5
      lastErrorMessage
      lastSeen
      status
    }
  }
`;

export const uniqueErrorQuery = ({ fingerprintMd5, siteId }) => ({
  ...errorsFeatureKeys.uniqueError({ fingerprintMd5, siteId }),
  useErrorBoundary: false,
  meta: {
    query: UNIQUE_ERROR_QUERY,
  },
});

export const uniqueErrorNotesQuery = ({ fingerprintMd5, siteId }) => ({
  ...errorsFeatureKeys.uniqueErrorNotes({ fingerprintMd5, siteId }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query UniqueErrorNotes($fingerprintMd5: String!, $siteId: Int!) {
        uniqueErrorNotes(fingerprintMd5: $fingerprintMd5, siteId: $siteId) {
          code
          createdAt
          fingerprintMd5
          guestAvatarUrl
          guestId
          guestName
          id
          note
          status
        }
      }
    `,
  },
});

export const errorListQuery = ({ fingerprintMd5, limit, page, siteId, field, sort, startAt, endAt }) => ({
  ...errorsFeatureKeys.errorList({ fingerprintMd5, limit, page, siteId, field, sort, startAt, endAt }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query ErrorList(
        $fingerprintMd5: String!
        $limit: Int!
        $page: Int!
        $siteId: Int!
        $order: ErrorsOrder!
        $startAt: Int!
        $endAt: Int!
      ) {
        errorList(
          fingerprintMd5: $fingerprintMd5
          limit: $limit
          page: $page
          siteId: $siteId
          order: $order
          startAt: $startAt
          endAt: $endAt
        ) {
          page
          errors {
            appVersion
            browser {
              name
            }
            createdAt
            deletedAt
            device
            errorMessage
            hashedId
            id
            identifier
            os
            permissions {
              canView
              canUpgradeToView
            }
            sessionViewedAt
            url
            valid
            visitorId
          }
        }
      }
    `,
  },
});

export const errorListTotalQuery = ({ fingerprintMd5, siteId, startAt, endAt }) => ({
  ...errorsFeatureKeys.errorListTotal({ fingerprintMd5, siteId, startAt, endAt }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query ErrorListTotal($fingerprintMd5: String!, $siteId: Int!, $startAt: Int!, $endAt: Int!) {
        errorListTotal(fingerprintMd5: $fingerprintMd5, siteId: $siteId, startAt: $startAt, endAt: $endAt)
      }
    `,
  },
});

export const uniqueErrorStatsQuery = ({ fingerprintMd5, siteId, startAt, endAt }) => ({
  ...errorsFeatureKeys.uniqueErrorStats({ fingerprintMd5, siteId, startAt, endAt }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query UniqueErrorStats($fingerprintMd5: String!, $siteId: Int!, $startAt: Int!, $endAt: Int!) {
        uniqueErrorStats(fingerprintMd5: $fingerprintMd5, siteId: $siteId, startAt: $startAt, endAt: $endAt) {
          __typename
          browsersAffected
          count
          fingerprintMd5
          pagesAffected
          usersAffected
        }
      }
    `,
  },
});

export const errorQuery = ({ id, siteId }) => ({
  ...errorsFeatureKeys.error({ id, siteId }),
  meta: {
    query: gql`
      query Error($id: String!, $siteId: Int!) {
        error(id: $id, siteId: $siteId) {
          appVersion
          browser {
            name
            version
          }
          columnNumber
          country {
            name
            code
          }
          createdAt
          deletedAt
          device
          errorMessage
          errorName
          fileName
          fingerprint
          fingerprintMd5
          hashedId
          id
          identifier
          ipAddress
          lineNumber
          os
          permissions {
            canView
            canUpgradeToView
          }
          referrer
          screenHeight
          screenWidth
          scriptVersion
          stackTrace
          tags
          url
          userAgent
          valid
          visitorId
        }
      }
    `,
  },
});
