import { getIn, useFormikContext } from 'formik';

import { ConditionTypeInput } from './ConditionTypeInput';
import { ConditionTypeSelect } from '../selects/ConditionTypeSelect';
import { DeleteRowButton } from './DeleteRowButton';
import { RenderFieldValuesArray } from './RenderFieldValuesArray';

export function AttributeConditionTypeRow({ ...props }) {
  const { errors, touched } = useFormikContext();
  const attributeError = getIn(errors, `${props.conditionKey}.attribute`);
  const attributeTouched = getIn(touched, `${props.conditionKey}.attribute`);
  const renderAttributeError =
    attributeError && typeof attributeError === 'string' && attributeTouched ? attributeError : null;

  return (
    <div className="mb-4 flex min-w-[720px] flex-col items-start gap-y-1.5 border-b border-dashed border-mystic-500 pb-4">
      <div className="flex w-full justify-between">
        <div className="flex gap-x-1.5">
          <span className="leading-[35px]">{props.conditionIndex > 0 ? 'And' : 'If'} </span>
          <ConditionTypeSelect {...props} />
          <ConditionTypeInput
            {...props}
            name={`${props.conditionKey}.attribute`}
            placeholder="Attribute name"
            className="!w-auto"
            onDragStart={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            draggable
            error={renderAttributeError}
          />
          <span className="leading-[35px]">is ANY of the following:</span>
        </div>
        {!props.disabled ? <DeleteRowButton onRemove={props.onRemove} /> : null}
      </div>
      <RenderFieldValuesArray {...props} />
    </div>
  );
}
