import { PlayerEvent, TooltipEvent } from '../PlayerEvent';
import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';
import { ReactComponent as AddToCartIcon } from '@crazyegginc/hatch/dist/images/icon-cart-add-filled.svg';
import { ReactComponent as CheckoutStartIcon } from '@crazyegginc/hatch/dist/images/icon-cart-filled.svg';
import { ReactComponent as CheckoutCompleteIcon } from '@crazyegginc/hatch/dist/images/icon-cart-complete.svg';

import { PLAYER_ECOMMERCE_SUBEVENTS } from '/src/features/recordings/constants';
const { ECOMMERCE_ADD_TO_CART, ECOMMERCE_CHECKOUT_START, ECOMMERCE_CHECKOUT_COMPLETE } = PLAYER_ECOMMERCE_SUBEVENTS;

function getEcommerceEventDisplayDetails({ sub_event_type, product_name, quantity }) {
  switch (sub_event_type) {
    case ECOMMERCE_ADD_TO_CART:
      return {
        title: 'Add to cart',
        Icon: AddToCartIcon,
        text: product_name && quantity ? `${quantity} x ${product_name}` : '',
      };
    case ECOMMERCE_CHECKOUT_START:
      return { title: 'Checkout start', Icon: CheckoutStartIcon };
    case ECOMMERCE_CHECKOUT_COMPLETE:
      return { title: 'Checkout complete', Icon: CheckoutCompleteIcon };
  }
}

export function EcommerceEvent({ timestamp, active, onClick, ...rest }) {
  const { title, text, Icon } = getEcommerceEventDisplayDetails(rest);

  return (
    <PlayerEvent
      icon={Icon}
      title={title}
      text={text}
      timestamp={timestamp}
      subText={
        rest.price && rest.currency && rest.price >= 0.01 ? (
          <DisplayCurrency
            value={{ displayWorth: rest.price, displayCurrency: rest.currency, isConverted: false }}
            precision={1}
            textOnly={true}
          />
        ) : undefined
      }
      active={active}
      onClick={onClick}
    />
  );
}

export function TooltipEcommerceEvent({ onClick, event }) {
  const { title, Icon } = getEcommerceEventDisplayDetails(event);

  return <TooltipEvent icon={Icon} title={title} event={event} onClick={onClick} />;
}
