import { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { STEP_COLORS } from '../constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      padding: { x: 12, y: 6 },
      displayColors: false,
      callbacks: {
        title: () => '',
        label: (context) => `${context.parsed.y.toLocaleString()}`,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 10,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      ticks: {
        padding: 10,
      },
      border: {
        dash: [5, 5],
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
  },
};

export function FunnelBarChart({ values, stepsData }) {
  const data = useMemo(
    () => ({
      labels: stepsData.map((x) => x.name),
      datasets: [
        {
          label: 'Volume',
          data: values,
          fill: true,
          backgroundColor: [...STEP_COLORS.slice(0 - values.length).map((x) => x.hex)],
        },
      ],
    }),
    [values, stepsData],
  );

  return (
    <div className="h-[250px]">
      <Bar options={options} data={data} />
    </div>
  );
}
