import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MetricCards } from '@crazyegginc/hatch';

import { uniqueErrorListStatsQuery } from '/src/features/errors/queries';

import { useErrorsFilter } from '../../errors-filter-context';
import { getConvertedDateRange } from '/src/utils/date';
import { inflect } from '/src/utils/string';
import { ERROR_STATUSES } from '../../constants';
import { useHasFeatureFlag } from '/src/hooks';
import { SPECIAL_DATE_RANGES } from '/src/features/_global/constants';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-basic.svg';
import { ReactComponent as GeneralBrowserIcon } from '@crazyegginc/hatch/dist/images/icon-browser.svg';
import { ReactComponent as UserIcon } from '@crazyegginc/hatch/dist/images/icon-visitorface-5.svg';
import { ReactComponent as PagesIcon } from '@crazyegginc/hatch/dist/images/icon-pages-outline.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as DashIcon } from '@crazyegginc/hatch/dist/images/icon-dash.svg';

export function ErrorsStats({ site, setPartsLoaded }) {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { queryParams } = useErrorsFilter();

  const convertedDateRange = getConvertedDateRange(
    isHighVolume ? { special: SPECIAL_DATE_RANGES.LAST_30_DAYS } : queryParams.dateRange,
  );

  const { data, isFetching } = useQuery({
    ...uniqueErrorListStatsQuery({
      siteId: site.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
      status: queryParams.status,
      search: isHighVolume ? undefined : queryParams.search,
    }),
  });

  const stats = data?.uniqueErrorListStats;

  useEffect(() => {
    if (data) {
      setPartsLoaded((x) => (x.stats === false ? { ...x, stats: true } : x));
    }
  }, [data, setPartsLoaded]);

  if (stats?.count === 0 && !isFetching) {
    return null;
  }

  return (
    <>
      <div className="text-header-3 mb-3.75">Summary</div>
      <MetricCards>
        {queryParams.status === ERROR_STATUSES.UNRESOLVED && (
          <MetricCards.Card
            fetching={isFetching}
            title={`Unresolved ${inflect('error', stats?.count)}`}
            value={stats?.count}
            icon={ErrorIcon}
            color="bg-carnation-500"
            className="!h-[100px] !w-1/4"
          />
        )}
        {queryParams.status === ERROR_STATUSES.RESOLVED && (
          <MetricCards.Card
            fetching={isFetching}
            title={`Resolved ${inflect('error', stats?.count)}`}
            value={stats?.count}
            icon={TickIcon}
            color="bg-lima-500"
            className="!h-[100px] !w-1/4"
          />
        )}
        {queryParams.status === ERROR_STATUSES.MUTED && (
          <MetricCards.Card
            fetching={isFetching}
            title={`Muted ${inflect('error', stats?.count)}`}
            value={stats?.count}
            icon={DashIcon}
            color="bg-dandelion-500"
            className="!h-[100px] !w-1/4"
          />
        )}
        {!isHighVolume ? (
          <>
            <MetricCards.Card
              fetching={isFetching}
              title={`${inflect('Visitor', stats?.usersAffected)} affected`}
              value={stats?.usersAffected}
              icon={UserIcon}
              color="bg-lavender-500"
              className="!h-[100px] !w-1/4"
            />
            <MetricCards.Card
              fetching={isFetching}
              title={`${inflect('Browser', stats?.browsersAffected)} affected`}
              value={stats?.browsersAffected}
              icon={GeneralBrowserIcon}
              color="bg-dodger-blue-300"
              className="!h-[100px] !w-1/4"
            />
            <MetricCards.Card
              fetching={isFetching}
              title={`${inflect('Page', stats?.pagesAffected)} affected`}
              value={stats?.pagesAffected}
              icon={PagesIcon}
              color="bg-riptide-500"
              className="!h-[100px] !w-1/4"
            />
          </>
        ) : null}
      </MetricCards>
    </>
  );
}
