import { useState } from 'react';

import { useAuthContext } from '/src/hooks';
import { AppConfig } from '../../AppConfig';

import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { DARK_PATHS } from '/src/features/_global/constants';

const isDarkPath = DARK_PATHS.some((route) => window.location.pathname.match(route));

/* this is the blue banner at the top of the dashboard */
export function SessionBanner() {
  const [hidden, setHidden] = useState(false);
  const { sessionInfo } = useAuthContext();

  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  if (hidden || isDarkPath) {
    return null;
  }

  if (isImpersonated) {
    return (
      <div className="text-button flex h-[30px] w-full items-center justify-between bg-dodger-blue-500 px-5">
        <span>&nbsp;</span>
        <span>
          Heads up, you&#39;re currently inspecting a user.{' '}
          <a className="hover:underline" href={`${AppConfig.legacyCoreBaseURL()}/admin`}>
            Go back to admin
          </a>
        </span>
        <button onClick={() => setHidden(true)}>
          <CloseIcon className="h-3 w-3 fill-current text-white" aria-label="close" />
        </button>
      </div>
    );
  }

  return null;
}
