import classNames from 'classnames';
import { Popover, StyledPopoverPanel, Button, Tooltip, Checkbox, Divider, Indicator } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { arraysEqualIgnoreOrder } from '/src/utils';
import { ALL, NONE } from '/src/features/_global/constants';

export function UserSelector({ userList, selectedUsers, setUsersParams }) {
  const { currentAccount } = useAuthContext();

  const userListId = userList.map((user) => user.id);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('flex w-[170px] items-center justify-between', { '!border-dodger-blue-500': open })}
          >
            <span>Users</span>
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="left" className="space-y-1" style={{ width: '300px' }}>
            {() => (
              <>
                <div className="flex items-center justify-between">
                  <button
                    type="button"
                    className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
                    disabled={arraysEqualIgnoreOrder(selectedUsers, userListId)}
                    onClick={() => setUsersParams(ALL)}
                  >
                    Select all
                  </button>
                  <button
                    type="button"
                    className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
                    disabled={selectedUsers.length === 0}
                    onClick={() => setUsersParams(NONE)}
                  >
                    Select none
                  </button>
                </div>

                <Divider className="!-mx-4 !my-2 !w-auto" />
                <div className="max-h-60 !overflow-y-auto rounded scrollbar-thin scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded">
                  {userList.map((user) => {
                    return (
                      <Checkbox
                        key={user.id}
                        label={
                          user.email.length > 32 ? (
                            <Tooltip
                              placement="right"
                              tooltipContent={<div className="max-w-xs break-words text-left">{user.email}</div>}
                            >
                              <div className="cursor-pointer">
                                <div
                                  className={classNames('truncate', {
                                    'font-semibold': user.email === currentAccount.ownerEmail,
                                  })}
                                >
                                  {user.email}
                                </div>
                              </div>
                            </Tooltip>
                          ) : (
                            <span
                              className={classNames({
                                'font-semibold': user.email === currentAccount.ownerEmail,
                              })}
                            >
                              {user.email}
                            </span>
                          )
                        }
                        id={`user-${user.id}`}
                        labelClass="pr-2 truncate"
                        onChange={() =>
                          selectedUsers.includes(user.id)
                            ? setUsersParams(selectedUsers.filter((i) => i !== user.id))
                            : setUsersParams([...selectedUsers, user.id])
                        }
                        checked={selectedUsers.includes(user.id)}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
