import { WebP } from '/src/components/WebP';
import classNames from 'classnames';

export function Paywall({ children }) {
  return <div className="flex flex-col items-center justify-start">{children}</div>;
}

function PaywallTitle({ children, mini }) {
  return <h3 className={mini ? 'text-header-4 text-center' : 'text-header-2'}>{children}</h3>;
}

function PaywallImage({ webp, png, width, height, alt = null, className }) {
  return (
    <div className={classNames('mb-6 mt-12', className)}>
      <WebP webp={webp} fallback={png} width={width} height={height} alt={alt} />
    </div>
  );
}

function PaywallBody({ children }) {
  return <div className="text-body-2 mb-10 mt-4 max-w-[514px] text-center">{children}</div>;
}

Paywall.Title = PaywallTitle;
Paywall.Image = PaywallImage;
Paywall.Body = PaywallBody;
