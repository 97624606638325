import { useState } from 'react';
import { useActor } from '@xstate/react';
import { Input, RadioGroup, Checkbox, Select, InfoBox, capitalize } from '@crazyegginc/hatch';

import { PIXEL_API_LIST, ANY_PIXEL_ID, GOOGLE_PIXEL_EVENTS, ENTER_YOUR_OWN_EVENT } from '../../constants';

export function PixelTrigger({ service, triggerIndex }) {
  const [state] = useActor(service);
  const [touched, setTouched] = useState({});
  const { send } = service;
  const errors = state.context.errors?.triggers?.[triggerIndex];
  const values = state.context.payload.triggers[triggerIndex];

  return (
    <>
      <RadioGroup
        label="Which advertising platform is your pixel for?"
        groupLabelClass="!text-xl !font-semibold !mb-5 !mt-10 !leading-none"
        options={[
          { value: PIXEL_API_LIST.GOOGLE, label: 'Google' },
          { value: PIXEL_API_LIST.TIKTOK, label: 'TikTok' },
          { value: PIXEL_API_LIST.META, label: 'Meta (Facebook + Instagram)' },
        ]}
        value={values.pixelApi}
        onChange={(value) => {
          send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'pixelApi', value });
        }}
      />

      {values.pixelApi ? (
        <>
          <div className="relative mt-10">
            <Input
              id={`trigger-${triggerIndex}-pixelId`}
              label="What is the pixel ID?"
              labelClassName="!text-xl !font-semibold !mb-5 !leading-none"
              placeholder={''}
              value={values.pixelId === ANY_PIXEL_ID ? '' : values.pixelId}
              error={touched.pixelId ? errors?.pixelId : null}
              onChange={(e) => {
                setTouched((x) => ({ ...x, pixelId: true }));
                send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'pixelId', value: e.target.value.trim() });
              }}
              disabled={values.pixelId === ANY_PIXEL_ID}
            />
            <div className="absolute right-0 top-2">
              <Checkbox
                checked={values.pixelId === ANY_PIXEL_ID}
                onChange={() =>
                  send({
                    type: 'SET_TRIGGER',
                    index: triggerIndex,
                    key: 'pixelId',
                    value: values.pixelId === ANY_PIXEL_ID ? '' : ANY_PIXEL_ID,
                  })
                }
                label={`Triggers for any ${capitalize(values.pixelApi)} pixel`}
                id={`trigger-${triggerIndex}-anyPixelId`}
                size="lg"
              />
            </div>
          </div>
          <div className="mb-2.5 mt-10 space-y-4">
            <Select
              label="Which pixel event triggers this goal?"
              labelClassName="!text-xl !font-semibold !mb-5 !leading-none"
              value={values.pixelEvent}
              onChange={(value) => {
                send({
                  type: 'SET_TRIGGER',
                  index: triggerIndex,
                  key: 'pixelEvent',
                  value,
                });
              }}
              options={getPixelEventOptions(values.pixelApi)}
            />
            {values.pixelEvent === ENTER_YOUR_OWN_EVENT ? (
              <Input
                id={`trigger-${triggerIndex}-pixelCustomEvent`}
                label="Enter event:"
                labelClassName=""
                placeholder={'Eg.: AddToCart'}
                value={values.pixelCustomEvent}
                error={touched.pixelCustomEvent ? errors?.pixelCustomEvent : null}
                onChange={(e) => {
                  setTouched((x) => ({ ...x, pixelCustomEvent: true }));
                  send({ type: 'SET_TRIGGER', index: triggerIndex, key: 'pixelCustomEvent', value: e.target.value });
                }}
              />
            ) : null}
          </div>
          {values.pixelEvent === '' ? (
            <div className="mb-2.5">
              <InfoBox
                header="Pages can trigger multiple Pixel events"
                body="Many pages will fire multiple Pixel events. So it’s possible to get multiple conversions for a single visitor."
              />
            </div>
          ) : null}
          <InfoBox
            header="Early pixel execution may affect tracking"
            body="If your pixel executes before Crazy Egg loads, we might not be able to track the pixel in all cases. We are currently working on a solution for this."
          />
        </>
      ) : null}
    </>
  );
}

export function getPixelEventOptions(api) {
  return [
    { value: '', label: 'Any' },
    ...(api === PIXEL_API_LIST.GOOGLE ? GOOGLE_PIXEL_EVENTS : []),
    { value: ENTER_YOUR_OWN_EVENT },
  ];
}
