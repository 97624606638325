import { useFormikContext } from 'formik';
import { RG, RadioGroupOption } from '@crazyegginc/hatch';

import { useEditorContext } from '../../editor-context';

export function BarButtonSelector() {
  const { readonly } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="self-start">
      <RG
        value={values.bar.showButton}
        onChange={(newValue) => setFieldValue('bar.showButton', newValue)}
        disabled={readonly}
      >
        <RG.Label className="text-body-1 mb-2 block whitespace-pre">User clicks button or bar</RG.Label>
        <div className="flex h-9 items-center justify-center space-x-2.5 rounded-full bg-white-lilac-500 px-2.5">
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: true, label: 'Button' }}
            disabled={readonly}
          />
          <RadioGroupOption
            size="sm"
            labelClass="!ml-1.25"
            option={{ value: false, label: 'Bar' }}
            disabled={readonly}
          />
        </div>
      </RG>
    </div>
  );
}
