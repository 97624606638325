export const TASK_STATUSES = {
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  BLOCKED: 'BLOCKED',
};

export const TRACK_GETTING_STARTED_EVENTS = {
  CLICK: 'CLICK',
  VIEW: 'VIEW',
};

export const USER_DATA_KEY_CLOSED = 'getting_started_closed_items';
export const USER_DATA_KEY_OPENED = 'getting_started_opened';
