export const LIVE_EVENT_TYPES = {
  IDENTIFY: 'IDENTIFY',
  VISIT: 'VISIT',
  DAILY_VISIT: 'DAILY_VISIT',
  DAILY_VISIT_COUNTER: 'DAILY_VISIT_COUNTER',
  CLICK: 'CLICK',
  ERROR: 'ERROR',
  TAG: 'TAG',
  ECOMMERCE: 'ECOMMERCE',
  FORM: 'FORM',
  SURVEY_RESPONSE: 'SURVEY_RESPONSE',
  GOAL_CONVERSION: 'GOAL_CONVERSION',
};

export const LIVE_EVENT_NAMES = {
  VISIT: 'visit',
  BASIC_VISIT: 'basic_visit',
  CLICK: 'click',
  ERROR: 'error',
  TAG: 'tag',
  ECOMMERCE_ADD_TO_CART: 'add_to_cart',
  ECOMMERCE_CHECKOUT_COMPLETE: 'checkout_complete',
  FORM_SUBMIT: 'submit',
  FORM_RESUBMIT: 'resubmit',
  FORM_ABANDON: 'abandon',
  FORM_SIGNUP: 'signup',
  FORM_LOGIN: 'login',
  SURVEY_RESPONSE: 'survey_response',
  GOAL_CONVERSION: 'goal_conversion',
};

export const BASIC_VISITOR = 'Basic Visitor';

export const MAX_EVENT_NUMBER = 500;
