import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import classNames from 'classnames';
import { ReactMultiEmailInput } from '@crazyegginc/react-multi-email-input';
import '@crazyegginc/react-multi-email-input/dist/style.css';
import { Formik, Form } from 'formik';
import { Button, Tooltip, Checkbox, Select, Divider, Spinner } from '@crazyegginc/hatch';

import { useSite, useMutation, useNotifications, useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';

import { accountUsersQuery } from '/src/features/team-and-sharing/queries';
import { createAccountUsersMutation } from '/src/features/team-and-sharing/mutations';

import { TooltipRoleContent } from '/src/features/team-and-sharing/components/team/TooltipRoleContent';
import { TooltipSiteAccessContent } from '/src/features/team-and-sharing/components/team/TooltipSiteAccessContent';

import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { ACCOUNT_USER_ROLES, ACCOUNT_USERS_ORDER } from '/src/features/team-and-sharing/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

const validationSchema = yup.object().shape({
  emails: yup.array().min(1, 'Please provide at least one email address.').of(yup.string()),
  role: yup.string().required('Role is required!'),
  sites: yup.array().min(1, 'Please choose a site.').of(yup.string()),
});

export function InviteUserModal() {
  const modal = useModal();
  const createAccountUsers = useMutation(createAccountUsersMutation);
  const notifications = useNotifications();

  const { data, isInitialLoading } = useQuery({
    ...accountUsersQuery({
      order: {
        sort: SORT_ORDER_TYPES.ASC,
        field: ACCOUNT_USERS_ORDER.EMAIL,
      },
    }),
  });

  const { sites } = useSite({
    dontSelectSite: true,
  });

  const siteListInvite = sites.map((site) => site.name);

  const emailList = (data?.accountUsers ?? []).map((user) => user.email.toLowerCase());

  return (
    <Modal dialogClassName="!w-[640px]" overlayClassName="!z-[50]">
      <Modal.Title>Invite new team members</Modal.Title>
      {isInitialLoading ? (
        <div className="my-10 flex w-full items-center justify-center">
          <Spinner />
          <div className="ml-2.5">Loading...</div>
        </div>
      ) : (
        <Formik
          initialValues={{
            emails: [],
            role: '',
            sites: [],
            specificSites: true,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const formValues = {
              emails: values.emails,
              role: values.role,
              sites: values.sites,
              specificSites: values.specificSites,
            };
            createAccountUsers.mutate(formValues, {
              onError: (error) => {
                notifications.error({
                  content: `Failed to send invitation.`,
                  timeout: 3000,
                  context: { error },
                });
              },
              onSuccess: () => notifications.success({ content: `Invitation sent.`, timeout: 3000 }),
              onSettled: () => modal.close(),
            });
          }}
        >
          {({ touched, dirty, handleChange, values, setFieldValue, setFieldTouched, errors, isSubmitting }) => {
            const inputEmailList = values.emails.map((email) => email.toLowerCase());
            const isEmailListExist = emailList.some((email) => inputEmailList.includes(email));

            return (
              <Form className="w-full">
                <div className="flex-center mb-2.5 flex">
                  <span className="text-header-6 flex w-32 justify-end pr-3.75 pt-3.5 leading-none">Email</span>
                  <span className="flex-1">
                    <ReactMultiEmailInput
                      className={classNames(
                        'react-multi-email-placeholder',
                        'flex flex-wrap rounded border border-mystic-500',
                        'pl-3 pr-3.75 pt-2',
                        'text-body-2 min-h-[40px]',
                      )}
                      emails={values.emails}
                      setEmails={(email) => setFieldValue('emails', email)}
                      placeholder="example@email.com"
                      getLabel={(email, index, removeEmail) => {
                        const isEmailExist = emailList.includes(email.toLowerCase());
                        return (
                          <div
                            className={classNames(
                              'flex items-center rounded-full ',
                              'mb-2 mr-1.25 pl-2',
                              'text-body-4 font-bold',
                              {
                                'bg-radical-red-500 text-white': isEmailExist,
                                'bg-mystic-500': !isEmailExist,
                              },
                            )}
                            key={index}
                          >
                            <span>{email}</span>
                            <button
                              type="button"
                              className="group ml-1 h-full rounded-r-full py-1.5 pl-1.5 pr-2 hover:bg-radical-red-500"
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              <CrossIcon
                                className={classNames('h-2 w-2 fill-current  group-hover:text-white', {
                                  'text-white': isEmailExist,
                                  'text-lynch-500': !isEmailExist,
                                })}
                                aria-label="remove email"
                              />
                            </button>
                          </div>
                        );
                      }}
                    />
                    {errors.emails && touched.emails && <div className="text-error mt-1">{errors.emails}</div>}
                    {isEmailListExist && (
                      <div className="text-error mt-1">
                        Please remove the email(s) that have already exist in your team member list
                      </div>
                    )}
                  </span>
                </div>
                <div className="flex-center mb-6 flex">
                  <span className="text-header-6 flex h-[35px] w-32 items-center justify-end pr-3.75">
                    <span className="mr-2.5 leading-none">Access level</span>
                    <Tooltip
                      arrowSkiddingPercent={5}
                      placement="bottom"
                      className="p-0"
                      tooltipContent={TooltipRoleContent(values.role)}
                    >
                      <InfoIcon
                        className="h-4 w-4 fill-current text-cadet-blue-500"
                        aria-label="Info on access level"
                      />
                    </Tooltip>
                  </span>
                  <span className="flex-1">
                    <Select
                      name="role"
                      options={[
                        {
                          value: ACCOUNT_USER_ROLES.OWNER,
                          label: 'Admin',
                        },
                        {
                          value: ACCOUNT_USER_ROLES.MANAGER,
                          label: 'Member',
                        },
                        {
                          value: ACCOUNT_USER_ROLES.READ_ONLY,
                          label: 'Read only',
                        },
                      ]}
                      placeholder="Select access level"
                      value={values.role}
                      onChange={async (value) => {
                        if (value === ACCOUNT_USER_ROLES.OWNER) {
                          await setFieldValue('sites', siteListInvite);
                          setFieldTouched('sites', true);
                          await setFieldValue('specificSites', false);
                        } else {
                          await setFieldValue('specificSites', true);
                        }
                        await setFieldValue('role', value);
                      }}
                    />
                    {errors.role && touched.role && <div className="text-error mt-1">{errors.role}</div>}
                  </span>
                </div>

                {values.role !== ACCOUNT_USER_ROLES.OWNER ? (
                  <>
                    <Divider className="!my-6" dashed />
                    <div className="flex max-h-52 flex-row overflow-auto">
                      <span className="ml-32 flex flex-1 flex-col overflow-x-hidden">
                        <span className="mb-4 flex items-center pl-1">
                          <h6 className="text-header-6 mr-2.5 leading-none">Allow access to the following sites</h6>
                          <Tooltip
                            arrowSkiddingPercent={5}
                            placement="bottom"
                            tooltipContent={<TooltipSiteAccessContent />}
                          >
                            <InfoIcon aria-label="delete user" className="h-4 w-4 fill-current text-cadet-blue-500" />
                          </Tooltip>
                        </span>
                        <div role="group" aria-labelledby="checkbox-group">
                          <Checkbox
                            id="allSites"
                            label="All current and future sites"
                            onChange={async () => {
                              if (
                                (siteListInvite.length !== values.sites.length ||
                                  siteListInvite.length === values.sites.length) &&
                                values.specificSites
                              ) {
                                await setFieldValue('sites', siteListInvite);
                                setFieldTouched('sites', true);
                                await setFieldValue('specificSites', false);
                              } else {
                                await setFieldValue('sites', []);
                                await setFieldValue('specificSites', true);
                              }
                            }}
                            checked={siteListInvite.length === values.sites.length && !values.specificSites}
                          />
                          <div className="ml-6" role="group" aria-labelledby="checkbox-group">
                            {siteListInvite.map((site, index) => (
                              <Checkbox
                                name="sites"
                                key={`Site-${site}`}
                                id={`site.[${index}]`}
                                value={site}
                                label={site}
                                checked={values.sites.includes(site)}
                                onChange={handleChange}
                              />
                            ))}
                          </div>
                        </div>
                        {errors.sites && touched.sites && <div className="text-error mt-1">{errors.sites}</div>}
                      </span>
                    </div>
                  </>
                ) : null}

                <Divider className="!-mx-7 !my-6 !w-auto" />
                <Modal.Actions>
                  <Button
                    disabled={!dirty || isSubmitting || isEmailListExist}
                    type="submit"
                    leftIcon={isSubmitting ? <Spinner className="mr-1 h-3 w-3" /> : null}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                  <Modal.Cancel disabled={isSubmitting} />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}
