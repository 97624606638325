import Wizard from '/src/components/wizard/Wizard';
import { LoaderBalloon } from '@crazyegginc/hatch';

export function WizardLoading({ step, totalSteps, balloonText = 'Loading...', title = '', nextText = 'Next' }) {
  return (
    <div className="flex h-full w-full flex-col">
      <Wizard.Header />

      <div className="flex h-full w-full items-center justify-center">
        <div>
          <LoaderBalloon text={balloonText} />
        </div>
      </div>

      <Wizard.SimpleFooter
        confirmCancel={false}
        currentStep={step}
        totalSteps={totalSteps}
        showMeta={true}
        title={title}
        loading={true}
        nextText={nextText}
        nextLoadingText={nextText}
        nextEnabled={false}
      />
    </div>
  );
}
