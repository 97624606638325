import { useEffect, useState } from 'react';

export function AsyncImg({ src, loadingComp = null, errorComp = null, alt, width = 0, height = 0, ...rest }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    async function handleLoading() {
      try {
        const resolvedSrc = await loadImage();
        setImgSrc(resolvedSrc);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    function loadImage() {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const onResolve = async () => {
          if (img.decode !== undefined) {
            await img.decode();
          }
          resolve(img.src);
        };
        const onReject = () => {
          reject(new Error('An Error occurred while trying to download an image'));
        };
        img.onload = onResolve;
        img.onerror = onReject;
        img.src = src;
      });
    }
    if (loading) {
      handleLoading();
    }
  }, [loading, src]);

  useEffect(() => {
    if (!imgSrc && !error && !loading) setLoading(true);
  }, [imgSrc, error, loading]);

  if (loading && loadingComp) {
    return loadingComp;
  } else if (error && errorComp) {
    return errorComp;
  } else if (imgSrc) {
    return <img src={imgSrc} alt={alt} width={width} height={height} {...rest} />;
  }
  return null;
}
