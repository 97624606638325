import { useCallback } from 'react';
import { useActor } from '@xstate/react';
import { Input, Button } from '@crazyegginc/hatch';

import { useDebounce, useModal, useSite } from '/src/hooks';

import Wizard from '/src/components/wizard/Wizard';
import { TriggerCard } from './TriggerCard';
import { ConfirmLeaveWizardModal } from '/src/components/wizard/ConfirmLeaveWizardModal';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

import { WizardSitePicker } from '/src/components/site-picker/index';

export function GoalTriggerWizard({ service }) {
  const [state, send] = useActor(service);
  const modal = useModal();

  const showConfirmModal = useCallback(
    ({ onConfirm = null }) => {
      modal.show(<ConfirmLeaveWizardModal onConfirm={onConfirm} />);
    },
    [modal],
  );

  const isNextEnabled = useDebounce(() => service?.nextState?.({ type: 'NEXT' })?.changed ?? false, 500);

  return (
    <Wizard>
      <Wizard.Header>
        <Wizard.CloseButton
          onClick={() => showConfirmModal({ onConfirm: () => send('CANCEL') })}
          defaultPath={state.context.previousPath || '/goals'}
        />
      </Wizard.Header>
      <Wizard.Content dontMask={true}>
        <div className="mx-auto max-w-[740px]">
          <Wizard.Title>What&#39;s your goal for your site?</Wizard.Title>

          <div className="mx-auto max-w-[740px]">
            <GoalForm service={service} />
          </div>
        </div>
      </Wizard.Content>
      <Wizard.SimpleFooter
        currentStep={1}
        totalSteps={2}
        showMeta={true}
        title="Goal setup"
        cancelText={state.context.draft ? 'Save draft & close' : 'Cancel'}
        nextEnabled={isNextEnabled}
        onBack={() => send('BACK')}
        onNext={() => send('NEXT')}
        onCancel={() => send('CANCEL')}
        confirmCancel={!state.context.draft}
      />
    </Wizard>
  );
}

function GoalForm({ service }) {
  const [state] = useActor(service);
  const { send } = service;
  const isEditing = service.initialState.value === 'initializeEdit';

  const isAbTestFlow = !!state.context.draft;

  const onSiteSelect = useCallback(
    (site) => {
      send({ type: 'SELECT_SITE', site });
    },
    [send],
  );

  const { sites, selectedSite, selectSite } = useSite({
    onlySelectFromQueryParam: true,
    onSelect: onSiteSelect,
  });

  return (
    <>
      <h2 className="text-header-2 mb-5 mt-10">Let&#39;s start by naming your goal!</h2>
      <Input
        id="name"
        name="name"
        aria-label="name"
        // error={urlErrorMessage}
        className="!h-[50px]"
        onChange={(e) => send({ type: 'CHANGE', key: 'name', value: e.target.value })}
        value={state.context.payload.name}
        // rightIcon={checkUrlLoading ? <Spinner className="h-5 w-5 text-cadet-blue-500" /> : null}
      />

      {!state.matches('goalName') ? (
        <>
          <h2 className="text-header-2 mb-5 mt-10">Your goal is for this site:</h2>
          <WizardSitePicker
            disabled={isEditing || isAbTestFlow}
            sites={sites}
            selectedSite={selectedSite}
            selectSite={(site) => {
              selectSite(site);
            }}
            onSelected={() => {
              setTimeout(() => {
                document.querySelector('#goal-trigger-group').scrollIntoView({ behavior: 'smooth', block: 'start' });
              }, 200);
            }}
          />
        </>
      ) : null}

      {!state.matches('goalName') && !state.matches('goalSite') ? (
        <div id="goal-trigger-group">
          <h2 className="text-header-2 mb-5 mt-10">Great, now let&#39;s set up a trigger for this goal...</h2>
          {state.context.payload.triggers.map((trigger, index) => (
            <TriggerCard
              service={service}
              triggersCount={state.context.payload.triggers.length}
              trigger={trigger}
              index={index}
              actions={{ insertTrigger: () => {}, removeTrigger: () => {} }}
              key={`TriggerCard:${index}`}
            />
          ))}
          <Button
            variant="secondary"
            onClick={() => send({ type: 'ADD_TRIGGER', index: state.context.payload.triggers.length - 1 })}
            className="mt-10"
          >
            <PlusIcon className="mr-2.5 h-[15px] w-[15px] fill-current" />
            Add another trigger
          </Button>
        </div>
      ) : null}
    </>
  );
}
