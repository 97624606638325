import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@crazyegginc/hatch';

import { useMutation, useLoader } from '/src/hooks';
import { trackGettingStartedMutation } from '../mutations';
import { externalLinkTasks } from '../tasksData';
import { TASK_STATUSES, TRACK_GETTING_STARTED_EVENTS } from '../constants';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as LockIcon } from '@crazyegginc/hatch/dist/images/icon-lock-filled.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

const { COMPLETED, INCOMPLETE, BLOCKED } = TASK_STATUSES;

export function Task({ task, close }) {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const { mutate: trackGettingStarted } = useMutation(trackGettingStartedMutation);
  const { Icon: LeftIcon, header, description, url, status, tooltipText, slug } = task;
  const isExternalLink = externalLinkTasks.includes(slug);

  let Component;
  let params = {};
  let RightIcon;
  switch (status) {
    case INCOMPLETE:
      Component = 'button';
      params = {
        onClick: () => {
          if (isExternalLink) {
            showLoader();
          }
          trackGettingStarted(
            {
              event: TRACK_GETTING_STARTED_EVENTS.CLICK,
              slugs: [slug],
            },
            {
              onSettled: () => {
                if (isExternalLink) {
                  hideLoader();
                  close();
                  navigate(url);
                }
              },
            },
          );
          if (!isExternalLink) {
            close();
            navigate(url);
          }
        },
      };
      RightIcon = <ArrowIcon className="h-3 w-3 rotate-90 fill-current text-dodger-blue-500" />;
      break;
    case COMPLETED:
      Component = 'div';
      RightIcon = <TickIcon className="h-5 w-5 fill-current text-lima-500" />;
      break;
    case BLOCKED:
      Component = 'div';
      RightIcon = <LockIcon className="h-4 w-4 fill-current text-cadet-blue-500" />;
      break;
  }

  return (
    <Tooltip
      tooltipContent={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
      show={!!tooltipText && status === BLOCKED}
      containerStyle={{ zIndex: 99999 }}
      placement="right"
      offset={-15}
    >
      <Component
        className={classNames('flex h-[50px] w-full items-center rounded border text-left text-lynch-500', {
          'border-alice-blue-500 bg-alice-blue-500 hover:border-dodger-blue-500 ': status === INCOMPLETE,
          'border-white-lilac-500 bg-white-lilac-500': status === BLOCKED,
          'select-none border-lima-500/10 bg-lima-500/10': status === COMPLETED,
        })}
        {...params}
      >
        <LeftIcon
          className={classNames('mx-3.75 h-5 w-5 shrink-0 fill-current', {
            'text-dodger-blue-500': status === INCOMPLETE,
            'text-lynch-500': [BLOCKED, COMPLETED].includes(status),
          })}
        />

        <div className="grow">
          <div
            className={classNames('text-body-1 leading-4', {
              'text-lynch-500': [BLOCKED, COMPLETED].includes(status),
            })}
          >
            <span className={classNames({ 'line-through': status === COMPLETED })}>{header}</span>
            <span>...</span>
          </div>
          <div className="text-2xs leading-4 text-lynch-500">{description}</div>
        </div>

        <div className="mr-3.75 flex w-5 shrink-0 justify-center">{RightIcon}</div>
      </Component>
    </Tooltip>
  );
}
