import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';

import { useSite } from '/src/hooks';
import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { generateCTAEditorFormat } from '/src/features/addons/ctas/editor/editor-functions';
import { addonDetailQuery, SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function ViewAddon() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isFetching } = useQuery({
    ...addonDetailQuery({ id }),
    enabled: Boolean(id),
  });

  const { sites, loadingSites } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: true,
  });

  useEffect(() => {
    if (!id || (!data?.addonDetail && !isFetching)) {
      navigate('/addons', { replace: true });
    }
  }, [id, navigate, isFetching, data]);

  useEffect(() => {
    if (window.Metrics && data?.addonDetail) {
      if (data.addonDetail.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Editor - View settings');
      } else if (data.addonDetail.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Editor - View settings');
      }
    }
  }, [data]);

  if ((!data && isFetching) || loadingSites) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }
  const { type, name, content, siteId, goal } = data.addonDetail;
  const site = sites.find((s) => s.id === siteId);

  if (type === ADDON_TYPES.SURVEY) {
    const surveyData = generateSurveyEditorFormat({ name, content, site });

    return <SurveyEditor id={id} initData={surveyData} readonly={true} />;
  }

  if (type === ADDON_TYPES.CTA) {
    const ctaData = generateCTAEditorFormat({ name, content, site, goalId: goal?.id });

    return <CTAEditor id={id} initData={ctaData} readonly={true} />;
  }

  return null;
}
