import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { addHours, addDays, isAfter, getUnixTime, addYears } from 'date-fns';
import { RG, Select, RadioGroupOption, Input, DateTimePicker } from '@crazyegginc/hatch';

import { usePermissions, useAuthContext, useWizard } from '/src/hooks';
import { SNAPSHOT_END_OPTIONS, SNAPSHOT_STATUSES, MAX_SNAPSHOT_YEARS } from '/src/features/snapshots/constants';
import { formatDateTime } from '/src/utils/date';

import { SaveDefaultEndOption } from './SaveDefaultEndOption';

export function EndSnapshot({ isEditing, validationErrors }) {
  const permissions = usePermissions();
  const { capabilities } = useAuthContext();

  const formik = useFormikContext();
  const { data: wizardData } = useWizard();

  const snapshotMaxVisits = capabilities.snapshots.quotas.visitsPerSnapshot.limit;
  const disableEndDate = isEditing && !permissions.can('editExpiration', wizardData.existingSnapshot);

  const { setFieldError, setFieldTouched } = formik;

  useEffect(() => {
    if (validationErrors?.findIndex((error) => error.field === 'maxVisits' && error.message === 'at_least') > -1) {
      const value = JSON.parse(
        validationErrors?.find((error) => error.field === 'maxVisits' && error.message === 'at_least').args,
      ).number.toLocaleString();
      setFieldError('ends.visits', `Your maximum visits must be at least ${value}.`);
      setFieldTouched('ends.visits', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'maxVisits' && error.message === 'at_most') > -1
    ) {
      const value = parseInt(
        JSON.parse(validationErrors?.find((error) => error.field === 'maxVisits' && error.message === 'at_most').args)
          .number,
      ).toLocaleString();
      setFieldError('ends.visits', `Your maximum visits cannot exceed ${value}.`);
      setFieldTouched('ends.visits', true, false);
    }

    if (
      validationErrors?.findIndex((error) => error.field === 'expiresAt' && error.message === 'dates_incongruent') > -1
    ) {
      setFieldError(
        'ends.at',
        'You have entered an invalid date range for your Snapshot. The end date has to be after the starting date.',
      );
      setFieldTouched('ends.at', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'expiresAt' && error.message === 'invalid_date') > -1
    ) {
      setFieldError(
        'ends.at',
        'You have selected an invalid end date for your Snapshot. Your entry does not appear to be a date.',
      );
      setFieldTouched('ends.at', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'expiresAt' && error.message === 'date_in_past') > -1
    ) {
      setFieldError('ends.at', 'The end date cannot be in the past.');
      setFieldTouched('ends.at', true, false);
    } else if (
      validationErrors?.findIndex((error) => error.field === 'expiresAt' && error.message === 'date_exceeds') > -1
    ) {
      setFieldError(
        'ends.at',
        `Your end date must be less than ${MAX_SNAPSHOT_YEARS} years from your Snapshot start date.`,
      );
      setFieldTouched('ends.at', true, false);
    }
  }, [validationErrors, setFieldError, setFieldTouched]);

  return (
    <section className="ml-1 flex w-1/2 flex-1 flex-col">
      <h2 className="text-header-2 mb-4">End Snapshot</h2>
      <RG
        value={formik.values.ends.option}
        onChange={async (newValue) => {
          await formik.setFieldValue('ends.option', newValue, true);
          formik.setFieldTouched('ends.option', true);
        }}
        disabled={disableEndDate}
      >
        <RadioGroupOption
          option={{ value: SNAPSHOT_END_OPTIONS.DEFAULT, label: 'Default' }}
          disabled={disableEndDate}
          optionWrapperClass="mb-2.5"
        />
        {formik.values.ends.option === SNAPSHOT_END_OPTIONS.DEFAULT && (
          <div className="mb-2 mt-2">
            <div className="text-body-2 flex flex-wrap items-start pl-8">
              <div className="flex w-full">
                <div className="mr-2 w-28">
                  <Select
                    options={[7, 30, 60, 90].map((days) => ({ value: days, label: `${days} days` }))}
                    value={formik.values.ends.days}
                    onChange={(newValue) => {
                      formik.setFieldTouched('ends.days', true, false);
                      setTimeout(() => formik.setFieldValue('ends.days', newValue, true));
                    }}
                    size="sm"
                    disabled={disableEndDate}
                    error={formik.errors?.ends?.days}
                  />
                </div>
                <div className="flex h-[30px] items-center">from start date</div>
              </div>
              <div className="my-1 flex w-full pl-0.5 text-sm font-semibold uppercase">or</div>
              <div className="flex w-full">
                <div className="mr-2 w-[72px]">
                  <Input
                    name={'ends.visits'}
                    size="sm"
                    value={
                      isNaN(formik.values.ends.visits) || formik.values.ends.visits === ''
                        ? formik.values.ends.visits
                        : (+formik.values.ends.visits)?.toLocaleString('en-US')
                    }
                    onChange={(e) => formik.setFieldValue('ends.visits', e.target.value.replace(',', ''), true)}
                    onBlur={formik.handleBlur}
                    className="!px-2"
                    disabled={disableEndDate}
                  />
                </div>
                <div className="flex h-[30px] items-center">visits</div>
              </div>
            </div>
            {formik.touched?.ends?.visits && formik.errors?.ends?.visits ? (
              <div className="relative h-5">
                <div className="text-body-4 absolute translate-y-0.5 whitespace-nowrap text-carnation-500">
                  {formik.errors.ends.visits}
                </div>
              </div>
            ) : (
              <SaveDefaultEndOption />
            )}
          </div>
        )}
        <RadioGroupOption option={{ value: SNAPSHOT_END_OPTIONS.CUSTOM, label: 'Custom' }} disabled={disableEndDate} />
        {formik.values.ends.option === SNAPSHOT_END_OPTIONS.CUSTOM && (
          <>
            <div className="text-body-2 mt-2 flex flex-wrap items-center pl-8">
              <div className="flex-w-full">
                <DateTimePicker
                  selectedDate={formik.values.ends.at}
                  setDate={(newValue) => {
                    formik.setFieldValue('ends.atChanged', true);
                    setTimeout(() => formik.setFieldValue('ends.at', newValue, true));
                  }}
                  lowerBoundary={
                    isAfter(formik.values.starts.at, addHours(new Date(), 1))
                      ? formik.values.starts.at
                      : addHours(new Date(), 1)
                  }
                  upperBoundary={
                    isAfter(formik.values.starts.at, addHours(new Date(), 1))
                      ? addYears(addHours(formik.values.starts.at, -1), MAX_SNAPSHOT_YEARS)
                      : addYears(new Date(), MAX_SNAPSHOT_YEARS)
                  }
                  showTimePicker={true}
                  size="sm"
                  disabled={disableEndDate}
                />
              </div>
              <div className="my-1 flex w-full pl-0.5 text-sm font-semibold uppercase">or</div>
              <div className="flex w-full items-center">
                <div className="mr-2 w-[72px]">
                  <Input
                    name={'ends.visits'}
                    size="sm"
                    value={
                      isNaN(formik.values.ends.visits) || formik.values.ends.visits === ''
                        ? formik.values.ends.visits
                        : (+formik.values.ends.visits)?.toLocaleString('en-US')
                    }
                    onChange={(e) => formik.setFieldValue('ends.visits', e.target.value.replace(',', ''), true)}
                    onBlur={formik.handleBlur}
                    className="!px-2"
                    disabled={disableEndDate}
                  />
                </div>
                visits
              </div>
            </div>
            {(formik.errors?.ends?.at || (formik.touched?.ends?.visits && formik.errors?.ends?.visits)) && (
              <div className="relative h-5">
                <div className="text-body-4 absolute translate-y-0.5 whitespace-nowrap text-carnation-500">
                  {formik.errors?.ends?.at ?? formik.errors?.ends?.visits}
                </div>
              </div>
            )}
          </>
        )}
      </RG>

      {isEditing && wizardData.existingSnapshot.snapshotStatus === SNAPSHOT_STATUSES.COMPLETED ? (
        <>
          <div className="text-body-5 mt-3">
            This Snapshot ended on{' '}
            <span className="font-semibold">{formatDateTime(wizardData.existingSnapshot.endedAt)}.</span> It tracked{' '}
            <span className="font-semibold">
              {parseInt(wizardData.existingSnapshot.totalVisits).toLocaleString()} visits.
            </span>
          </div>
          {disableEndDate && (
            <div className="text-body-5 mt-3">
              You cannot modify the end date because this Snapshot has reached the maximum of {MAX_SNAPSHOT_YEARS} years
              or {parseInt(snapshotMaxVisits).toLocaleString()} visits.
            </div>
          )}
        </>
      ) : (
        <>
          <div className="text-body-5 mt-3">
            This Snapshot will end on{' '}
            <span className="font-semibold">
              {formik.values.ends.option === SNAPSHOT_END_OPTIONS.CUSTOM &&
                formatDateTime(getUnixTime(formik.values.ends.at))}
              {formik.values.ends.option === SNAPSHOT_END_OPTIONS.DEFAULT &&
                formatDateTime(getUnixTime(addDays(formik.values.starts.at, formik.values.ends.days)))}
            </span>{' '}
            or once the Snapshot has tracked{' '}
            <span className="font-semibold">{parseInt(formik.values.ends.visits).toLocaleString()} visits</span>.
          </div>

          <div className="text-body-5 mt-3">
            Snapshots end at whichever comes first (time or number of visits). Snapshots are limited to a maximum of
            {` ${parseInt(snapshotMaxVisits).toLocaleString()}`} visits or {MAX_SNAPSHOT_YEARS} years.
          </div>
        </>
      )}
    </section>
  );
}
