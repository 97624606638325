import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

export function TruncateWithTooltip({ text, className, tooltipClassName }) {
  const [truncated, setTruncated] = useState(false);

  const truncateRef = useCallback((node) => {
    if (node !== null) {
      setTruncated(node.clientWidth < node.scrollWidth);
    }
  }, []);

  return (
    <Tooltip
      show={truncated}
      tooltipContent={<div className={classNames('max-w-md break-all', tooltipClassName)}>{text}</div>}
    >
      <div className={classNames('truncate', className)} ref={truncateRef}>
        {text}
      </div>
    </Tooltip>
  );
}
