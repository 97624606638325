import React from 'react';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-cross-circle-filled.svg';

import { RenderInfos } from './RenderInfos';
import { VerticalLine } from './VerticalLine';

export function ErrorItem({ title, description, suggestion = null, children, consoleErrors = null }) {
  return (
    <div className="mt-10 flex h-full w-full rounded-md bg-alice-blue-500 px-[30px] pb-10 pt-[30px]">
      <div className="relative mr-[25px] w-10 flex-shrink-0">
        <div className="rounded-full bg-white">
          <ErrorIcon className="h-10 w-10 fill-current text-carnation-500" aria-label="error" />
        </div>
        {consoleErrors?.length && <VerticalLine />}
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-header-4 mb-2.5 leading-none">{title}</h3>
        <p className="text-body-2 leading-snug">{description}</p>
        {suggestion && (
          <div className="text-body-2 mt-2.5">
            <strong className="text-body-1">How to fix it:</strong> {suggestion}
          </div>
        )}
        {children}
        <RenderInfos consoleErrors={consoleErrors} />
      </div>
    </div>
  );
}
