/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';
import { useSelector } from '@xstate5/react';

import { usePermissions } from '/src/hooks';
import { getPlayerPlaylistQueryParams } from '/src/utils/url';

import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as ReplayIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as NextIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

function catchEmAll(e) {
  e.stopPropagation();
  e.preventDefault();
}

export function SelectNextAction({ actorRef, onReplay, onNext }) {
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.RECORDINGS).allowed;

  const recording = useSelector(actorRef, (state) => state.context.recording);
  const nextRecording = useSelector(actorRef, (state) => state.context.nextRecording);
  const autoPlay = useSelector(actorRef, (state) => state.context.autoPlay);

  const navigate = useNavigate();

  useEffect(() => {
    if (autoPlay && nextRecording && nextRecording.id !== recording.id) {
      onNext?.(nextRecording);
    }
  }, [autoPlay, nextRecording, onNext, recording.id]);

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center space-y-6 text-white"
      onMouseDown={(e) => catchEmAll(e)}
      onMouseUp={(e) => catchEmAll(e)}
      onClick={(e) => catchEmAll(e)}
    >
      <span>What do you want to do next?</span>
      <div className="flex space-x-2">
        <Button
          variant="ghost-primary"
          className="border-2 border-transparent !text-white hover:border-white"
          onClick={onReplay}
        >
          <div className="flex items-center space-x-2">
            <ReplayIcon className="h-4 w-4 fill-current text-white" />
            <span>Replay</span>
          </div>
        </Button>
        {nextRecording ? (
          <Button
            variant="ghost-primary"
            className="border-2 border-transparent !text-white hover:border-white"
            onClick={() => {
              onNext?.(nextRecording);
            }}
          >
            <div className="flex items-center space-x-2">
              <span>Play Next</span>
              <NextIcon className="h-4 w-4 fill-current text-white" />
            </div>
          </Button>
        ) : canVisitDashboard ? (
          <Button
            variant="ghost-primary"
            className="border-2 border-transparent !text-white hover:border-white"
            onClick={() => {
              navigate({
                pathname: '/recordings',
                search: `?${getPlayerPlaylistQueryParams()}`,
              });
            }}
          >
            <div className="flex items-center space-x-2">
              <span>View Dashboard</span>
            </div>
          </Button>
        ) : null}
      </div>
    </div>
  );
}
