import { useFormikContext } from 'formik';

import { Input } from '../../../common/basic-ui';
import { AdditionalInformation } from '../../../common/editor/AdditionalInformation';
import { getDefaultTitle } from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';
import { ImageUpload } from '../../../common/editor/ImageUpload';
import { ADDONS_CAPABILITIES } from '/src/features/addons/constants';

export function QuestionTitle({ index, titleLabel = 'Survey Question', autofocus = true }) {
  const { readonly, addonsCapabilities, isTemplate } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  const hasImageSupport = addonsCapabilities[ADDONS_CAPABILITIES.POPUP_IMAGE];

  function selectTextOnFocus(e) {
    if (e.target.value === getDefaultTitle(values.questions[index].subtype)) {
      e.target.select();
    }
  }

  return (
    <>
      <Input
        label={titleLabel}
        id={`questions[${index}].title`}
        name={`questions[${index}].title`}
        value={values.questions[index].title}
        error={
          errors.questions?.[index]?.title && touched.questions?.[index]?.title ? errors.questions[index].title : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={selectTextOnFocus}
        autoFocus={autofocus && index === 0} //eslint-disable-line
        disabled={readonly}
      />
      {hasImageSupport && !isTemplate && <ImageUpload fieldPath={`questions[${index}].image`} />}
      <AdditionalInformation fieldPath={`questions[${index}].subtext`} />
    </>
  );
}
