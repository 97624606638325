import { useState, useRef } from 'react';
import classNames from 'classnames';

import { getParam } from '/src/utils/location';
import { useOutsideClickNotify } from '/src/hooks';

const SPEED_VALUES = { 0: 1.0, 1: 1.5, 2: 2.0, 3: 4.0, 4: 0.5 };
const SORT_SPEED_VALUES = Object.values(SPEED_VALUES).sort().reverse();

export function SpeedButton({ onClick = null }) {
  const [open, setOpen] = useState(false);
  const [speedIndex, setSpeedIndex] = useState(
    parseInt(Object.keys(SPEED_VALUES).find((key) => SPEED_VALUES[key] === parseFloat(getParam('speed', 1)))),
  );
  const buttonRef = useRef();

  useOutsideClickNotify([buttonRef], () => setOpen(false), open);

  function handleRotatingSpeedClick() {
    const newSpeedIndex = parseInt(speedIndex === Object.keys(SPEED_VALUES).length - 1 ? 0 : speedIndex + 1);
    setSpeedIndex(newSpeedIndex);
    onClick?.(SPEED_VALUES[newSpeedIndex]);
  }

  function handleSingleSpeedClick(speedVal) {
    const newSpeedIndex = parseInt(Object.keys(SPEED_VALUES).find((key) => SPEED_VALUES[key] === speedVal));
    setSpeedIndex(newSpeedIndex);
    setOpen(false);
    onClick?.(SPEED_VALUES[newSpeedIndex]);
  }

  return (
    <>
      {/* FOR MOBILE & TABLET */}
      <button
        className="flex h-[30px] items-center justify-center rounded bg-mako-500 p-2.5 text-xs font-semibold text-cadet-blue-500 transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white md:mr-1.5 lg:invisible lg:hidden"
        onClick={handleRotatingSpeedClick}
      >
        {SPEED_VALUES[speedIndex]}x speed
      </button>

      {/* FOR DESKTOP */}
      <div className="relative" ref={buttonRef}>
        <button
          onClick={() => {
            setOpen((x) => !x);
            window.Mocky?.turnOff();
          }}
          className="invisible hidden h-[30px] items-center justify-center rounded bg-mako-500 p-2.5 text-xs font-semibold text-cadet-blue-500 transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white md:mr-1.5 lg:visible lg:flex"
        >
          {SPEED_VALUES[speedIndex]}x speed
        </button>
        <span
          className={classNames(
            'absolute bottom-full left-1/2 z-10 -translate-x-1/2 pb-2 transition duration-150 ease-in-out',
            { 'visible block opacity-100': open, 'invisible hidden opacity-0': !open },
          )}
        >
          <ul className="block w-20 min-w-max overflow-hidden rounded shadow-lg">
            {SORT_SPEED_VALUES.map((speedVal) => {
              if (speedVal === SPEED_VALUES[speedIndex]) {
                return (
                  <li key={speedVal} className="bg-mako-500">
                    <button className="h-11 w-full cursor-not-allowed bg-dodger-blue-500 p-2.5 font-semibold text-white">
                      {speedVal}
                    </button>
                  </li>
                );
              }

              return (
                <li key={speedVal} className="bg-mako-500">
                  <button
                    className={classNames(
                      Object.keys(SPEED_VALUES).find((key) => SPEED_VALUES[key] === speedVal) === speedIndex
                        ? 'cursor-not-allowed bg-dodger-blue-500 '
                        : 'bg-mako-500 text-cadet-blue-500 hover:bg-dodger-blue-500 hover:text-white',
                      ' text-cadet-blue-400 h-11 w-full p-2.5 font-semibold transition duration-150 ease-in-out',
                    )}
                    onClick={() => handleSingleSpeedClick(speedVal)}
                  >
                    {speedVal}
                  </button>
                </li>
              );
            })}
          </ul>
          <span className="relative block h-2 w-full overflow-hidden">
            <span className="absolute -top-full left-0 right-0 mx-auto block h-3 w-3 rotate-45 bg-mako-500" />
          </span>
        </span>
      </div>
    </>
  );
}
