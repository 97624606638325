import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner, Button } from '@crazyegginc/hatch';

import { DashHeader } from '/src/components/headers/DashHeader';
import { LimitWarningTooltip } from './tooltips';
import { DashboardFeatureUsage } from '/src/components/usage';
import { ExportsListModal } from './modals/ExportsListModal';

import { usePermissions, useModal } from '/src/hooks';
import { snapshotExportsQuery } from '/src/features/snapshots/queries';

import { FEATURES } from '/src/features/_global/constants';
import { SNAPSHOT_EXPORT_STATUSES } from '/src/features/snapshots/constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as ExportIcon } from '@crazyegginc/hatch/dist/images/icon-download-filled.svg';

export function SnapshotDashHeader({ pollExports, setPollExports }) {
  const permissions = usePermissions();
  const canCreate = permissions.can('create', FEATURES.SNAPSHOTS).allowed;

  return (
    <DashHeader
      title="Snapshots"
      actionButton={
        <>
          <DashboardFeatureUsage feature={FEATURES.SNAPSHOTS} />
          <SnapshotExportsButton pollExports={pollExports} setPollExports={setPollExports} />
          {canCreate && <CreateSnapshotButton />}
        </>
      }
    />
  );
}

function CreateSnapshotButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = usePermissions();
  const [showWarning, setShowWarning] = useState(location.state?.cannotCreateMoreSnapshots ?? false);

  function clickHandler() {
    const canCreateMore = permissions.can('createMore', FEATURES.SNAPSHOTS).allowed;
    if (!canCreateMore) {
      return setShowWarning(true);
    }
    navigate({
      pathname: '/snapshots/new',
      search: null,
    });
  }

  function clearErrorState() {
    setShowWarning(false);
    navigate(
      {
        pathname: location.pathname,
        state: null,
      },
      {
        replace: true,
      },
    );
  }

  return (
    <LimitWarningTooltip onDismiss={() => clearErrorState()} show={showWarning}>
      <Button size="xl" onClick={clickHandler}>
        <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" /> Create New Snapshot
      </Button>
    </LimitWarningTooltip>
  );
}

function SnapshotExportsButton({ pollExports, setPollExports }) {
  const modal = useModal();
  const { data: exportsData } = useQuery({ ...snapshotExportsQuery(), enabled: pollExports, refetchInterval: 20000 });

  const snapshotExports = exportsData?.snapshotExports ?? [];
  const exportingCount = snapshotExports.filter(
    (snapshotExport) => snapshotExport.status === SNAPSHOT_EXPORT_STATUSES.EXPORTING,
  ).length;

  useEffect(() => {
    if (exportingCount === 0 && snapshotExports.length > 0) {
      setPollExports(false);
    }
  }, [exportingCount, snapshotExports.length, setPollExports]);

  return (
    <Button variant="secondary" size="xl" className="!mr-4" onClick={() => modal.show(<ExportsListModal />)}>
      {pollExports ? (
        <Spinner className="mr-2 h-4 w-4 text-dodger-blue-500" />
      ) : (
        <ExportIcon className="mr-2 h-4 w-4 fill-current text-dodger-blue-500" />
      )}
      Exports
    </Button>
  );
}
