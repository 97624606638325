import { useMachine } from '@xstate5/react';
import { fromPromise } from 'xstate5';
import { useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useMutation, useNotifications } from '/src/hooks';
import { abTestDetailQuery } from '/src/features/ab-testing/queries';
import { abTestAddVariantsMutation, abTestSetTrafficSplitMutation } from '/src/features/ab-testing/mutations';
import { addVariantMachine } from '/src/features/ab-testing/machines/add-variant';

import { AbTestWizard } from '../components/wizard/add-variant';

// eslint-disable-next-line
export default function AbTestAddVariantPage() {
  const { id } = useParams();
  const notifications = useNotifications();

  const { refetch } = useQuery({
    ...abTestDetailQuery({ id: Number(id) }),
    enabled: false,
  });

  const { mutateAsync: mutateCreateVariants } = useMutation(abTestAddVariantsMutation);
  const { mutateAsync: mutateSetTrafficSplit } = useMutation(abTestSetTrafficSplitMutation);

  const [state, , actorRef] = useMachine(
    addVariantMachine.provide({
      actors: {
        loadAbTest: fromPromise(async () => {
          try {
            const res = await refetch();
            return res.data.abTestDetail;
          } catch (err) {
            throw err.message.replace('[GraphQL]', '').trim();
          }
        }),
        createVariants: fromPromise(async ({ input }) => {
          try {
            const res = await mutateCreateVariants({
              id: Number(input.id),
              variants: input.variants,
            });

            if (res?.abTestAddVariants?.id === input.id) {
              notifications.success({ content: `Variant created successfully.`, timeout: 3000 });
            } else {
              notifications.error({
                content: `Variant creation failed.`,
                timeout: 3000,
                context: { error: 'Unknown error', response: res },
              });
            }
          } catch (error) {
            notifications.error({ content: `Variant creation failed.`, timeout: 3000, context: { error } });
          }
        }),
        setTrafficSplit: fromPromise(async ({ input }) => {
          try {
            const res = await mutateSetTrafficSplit({
              id: Number(input.id),
              autoReweight: Boolean(input.autoReweight),
              variants: input.variants.map((variant) => ({ id: variant.id, weight: variant.weight })),
            });

            if (res?.abTestSetTrafficSplit?.id === input.id) {
              notifications.success({ content: `Traffic split set successfully.`, timeout: 3000 });
            } else {
              notifications.error({
                content: `Traffic split update failed.`,
                timeout: 3000,
                context: { error: 'Unknown error', response: res },
              });
            }
          } catch (error) {
            notifications.error({ content: `Traffic split update failed.`, timeout: 3000, context: { error } });
          }
        }),
      },
    }),
  );

  if (state.matches('loading') && state.context.nextState === 'variants') {
    return <LoaderBalloonScreen text={true} />;
  }

  if (state.matches('done')) {
    return <Navigate to={`/ab-tests/${id}`} redirect />;
  }

  return (
    <AbTestWizard actorRef={actorRef} backEnabled={false} nextText="Add variant" defaultPath={`/ab-tests/${id}`} />
  );
}
