import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Button, IconButton, Tooltip, ReactTable, Checkbox, Spinner } from '@crazyegginc/hatch';

import { useMutation, useModal, useSelection, useNotifications, useAuthContext } from '/src/hooks';
import { useFilter } from '../../account-users-filter-context';

import {
  resendInvitationEmailMutation,
  deleteAccountUsersMutation,
  updateAccountUsersMutation,
} from '/src/features/team-and-sharing/mutations';

import { copyToClipboard } from '/src/utils';
import { isAccountOwner, userRoleTextAndClassName } from '../../utils';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { dateDistance } from '/src/utils/date';

import { AccessLevelDropdown } from './AccessLevelDropdown';
import { TooltipRoleContent } from './TooltipRoleContent';
import { DeleteUserModal } from './modals/DeleteUserModal';
import { InviteCancelModal } from './modals/InviteCancelModal';
import { EditUserModal } from './modals/EditUserModal';

import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { ACCOUNT_USER_ROLES } from '/src/features/team-and-sharing/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as TwoFAIcon } from '@crazyegginc/hatch/dist/images/icon-2fa-filled.svg';

export function AccountUsersTable({ users, fetching, siteList }) {
  const tableRef = useRef(null);
  const modal = useModal();
  const filters = useFilter();
  const nonOwnerUsers = users.filter((user) => !isAccountOwner(user.role));
  const { currentUser } = useAuthContext();
  const notifications = useNotifications();

  const { currentSelection, addToSelection, clearSelection, toggleSelection } = useSelection();
  const { mutate: updateAccountUsersMutate } = useMutation(updateAccountUsersMutation);
  const { mutate: deleteAccountUsersMutate } = useMutation(deleteAccountUsersMutation);

  const handleCancelOrDeleteUser = useCallback(
    (user, messageAction, messageErrorAction) => {
      deleteAccountUsersMutate(
        {
          ids: [user.id],
        },
        {
          onError: (error) =>
            notifications.error({ content: `Failed to ${messageErrorAction}.`, timeout: 3000, context: { error } }),
          onSuccess: () => {
            notifications.success({ content: messageAction, timeout: 3000 });
            clearSelection();
          },
          onSettled: () => modal.close(),
        },
      );
    },
    [clearSelection, deleteAccountUsersMutate, notifications, modal],
  );

  const handleUpdateAccountUser = useCallback(
    ({ ids, sites, specificSites, role }) => {
      updateAccountUsersMutate(
        {
          ids,
          sites,
          specificSites,
          role,
        },
        {
          onError: (error) =>
            notifications.error({ content: `Failed to update member.`, timeout: 3000, context: { error } }),
          onSuccess: () => {
            notifications.success({ content: `Member updated.`, timeout: 3000 });
            clearSelection();
          },
        },
      );
    },
    [clearSelection, updateAccountUsersMutate, notifications],
  );

  const columns = useMemo(
    () => [
      {
        header: () => (
          <div>
            <Checkbox
              checked={currentSelection.length && currentSelection.length === nonOwnerUsers.length ? true : false}
              onChange={() => {
                currentSelection.length === nonOwnerUsers.length
                  ? clearSelection()
                  : addToSelection([...nonOwnerUsers]);
              }}
              indeterminate={currentSelection.length > 0 && currentSelection.length < nonOwnerUsers.length}
              title="Toggle all rows selected"
              size="lg"
            />
          </div>
        ),
        cell: ({ row }) => {
          return isAccountOwner(row.original.role) ||
            currentUser.email.toLowerCase() === row.original.email.toLowerCase() ? null : (
            <div>
              <Checkbox
                checked={currentSelection.findIndex((x) => x.id === row.original.id) > -1}
                onChange={() => toggleSelection(row.original)}
                size="lg"
              />
            </div>
          );
        },
        id: 'selection',
        minSize: 30,
        maxSize: 30,
        meta: {
          align: 'center',
        },
      },
      {
        id: 'NameAbbrv',
        size: 50,
        meta: {
          align: 'center',
          padding: '0',
        },
        enableSorting: false,
        cell: function NameAbbrvCell({ row }) {
          return (
            <NameAbbrvComponent
              name={row.original.name}
              role={row.original.role}
              isInvitationPending={row.original.invitationPending}
            />
          );
        },
      },
      {
        header: 'Member',
        accessorKey: 'email',
        size: 125,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function MemberCell({ row }) {
          return (
            <div className="flex min-w-0 flex-col pr-1">
              <span className="text-body-1 mb-0.5 truncate">{row.original.name ? row.original.name : null}</span>
              <span className="text-body-4 truncate">{row.original.email}</span>
            </div>
          );
        },
      },
      {
        header: 'Active',
        accessorKey: 'lastActive',
        size: 180,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function AccountUserActiveCell({ row }) {
          if (!row.original.invitationPending) {
            return (
              <span className="text-body-2 pr-1">
                {row.original.lastActive ? (
                  dateDistance(row.original.lastActive)
                ) : (
                  <div className="flex w-full items-center ">
                    <span className="text-body-5 mr-1">Never</span>
                    {
                      <span className="pt-0.5">
                        <Tooltip tooltipContent="Invitation accepted, but has not logged in yet.">
                          <InfoIcon
                            className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                            aria-label="never active explanation"
                          />
                        </Tooltip>
                      </span>
                    }
                  </div>
                )}
              </span>
            );
          } else {
            return (
              <div className="flex min-w-0 flex-col pr-1">
                <AccountUserPendingInvitationRow
                  userId={row.original.id}
                  time={row.original.invitedDate}
                  invitationUrl={row.original.invitationUrl}
                  onCancelInvite={() =>
                    handleCancelOrDeleteUser(row.original, 'Invitation canceled', `cancel invitation`)
                  }
                />
              </div>
            );
          }
        },
      },
      {
        header: 'Sites',
        accessorKey: 'sites',
        size: 150,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function AccountUserSitesCell({ row }) {
          const listOfSites =
            !row.original.specificSites || row.original.role === ACCOUNT_USER_ROLES.OWNER
              ? siteList.join(', ')
              : row.original.sites.join(', ');

          return (
            <div className="flex w-full pr-3">
              <Tooltip tooltipContent={<div className="max-w-xs break-words text-left">{listOfSites}</div>}>
                {/* NOTE: New DB have specificSites, but old DB does not */}
                <div className="cursor-pointer">
                  {!row.original.specificSites || row.original.role === ACCOUNT_USER_ROLES.OWNER ? (
                    'All sites'
                  ) : (
                    <div className="truncate">{listOfSites}</div>
                  )}
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: 'TwoFAIcon',
        size: 30,
        meta: {
          align: 'center',
          padding: '0',
        },
        enableSorting: false,
        cell: function TwoFAIconCell({ row }) {
          const isTwoFAEnabled = row.original.secondFactorEnabled;
          return (
            <Tooltip
              tooltipContent={`Two-factor authentication ${!isTwoFAEnabled ? 'not' : ''} enabled.`}
              arrowSkiddingPercent={7}
            >
              <TwoFAIcon
                className={classNames('h-5 w-4', {
                  'fill-cadet-blue-500': !isTwoFAEnabled,
                  'fill-lima-500': isTwoFAEnabled,
                })}
              />
            </Tooltip>
          );
        },
      },
      {
        header: function AccountUserAccessLevel() {
          return (
            <div className="flex items-center">
              <div className="mr-1.5 leading-none">Access level</div>
              <Tooltip arrowSkiddingPercent={95} placement="bottom" tooltipContent={<TooltipRoleContent />}>
                <InfoIcon
                  className="h-3 w-3 -translate-y-px fill-current text-dodger-blue-300"
                  aria-label="Info on access level"
                />
              </Tooltip>
            </div>
          );
        },
        accessorKey: 'role',
        size: 130,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function AccountUserAccessLevelCell({ row }) {
          return AccessLevelDropdown(row.original, siteList);
        },
      },
      {
        id: 'Actions',
        size: 80,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function AccountUserActionsCell({ row }) {
          return isAccountOwner(row.original.role) ||
            currentUser.email.toLowerCase() === row.original.email.toLowerCase() ? null : (
            <AccountUserActions
              user={row.original}
              siteList={siteList}
              onUpdate={(values) => handleUpdateAccountUser(values)}
              onDelete={() => handleCancelOrDeleteUser(row.original, 'Member deleted.', 'delete member')}
            />
          );
        },
      },
    ],
    [
      siteList,
      nonOwnerUsers,
      clearSelection,
      addToSelection,
      currentSelection,
      toggleSelection,
      handleCancelOrDeleteUser,
      handleUpdateAccountUser,
      currentUser.email,
    ],
  );

  const tableSortData = useMemo(
    () => ({
      id: snakeToCamel(filters.order.field),
      desc: filters.order.sort === SORT_ORDER_TYPES.DESC,
    }),
    [filters.order.field, filters.order.sort],
  );

  const { setOrder, setSort } = filters;
  const onFetchData = useCallback(
    ({ sorting = null }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setOrder(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      columns={columns}
      data={users}
      ref={{ tableRef }}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      rowPadding={true}
      rowHeight={65}
      fetching={fetching}
      useVirtualization={true}
    />
  );
}

function AccountUserPendingInvitationRow({ userId, time, onCancelInvite, invitationUrl }) {
  const modal = useModal();
  const notifications = useNotifications();
  const resendInvitationEmail = useMutation(resendInvitationEmailMutation);

  return (
    <>
      <span className="text-body-3 mb-0.5 flex pr-2">
        <span className="mr-1.5 leading-none">
          <span className="mr-1">Pending: Invited {dateDistance(time)}</span>
          <span className="-mb-0.5 inline-block">
            <Tooltip
              tooltipContent={<TooltipInvitationURL invitationUrl={invitationUrl} />}
              darkTheme={true}
              placement="right"
              interactive={true}
              arrowSkiddingPercent={20}
            >
              <InfoIcon aria-label="delete user" className="h-3 w-3 fill-current text-cadet-blue-500" />
            </Tooltip>
          </span>
        </span>
      </span>
      <span className="flex text-sm italic">
        <button
          className="mr-1 w-min truncate text-left text-dodger-blue-500"
          disabled={resendInvitationEmail.isLoading}
          onClick={() =>
            resendInvitationEmail.mutate(
              { id: userId },
              {
                onError: (error) =>
                  notifications.error({
                    title: 'Error',
                    content: `Failed to resend invitation.`,
                    timeout: 3000,
                    context: { error },
                  }),
                onSuccess: () =>
                  notifications.success({
                    title: 'Sent!',
                    content: `This team member should receive their invite shortly.`,
                    timeout: 3000,
                  }),
              },
            )
          }
        >
          {resendInvitationEmail.isLoading ? (
            <div className="flex items-center">
              <Spinner className="mr-1 h-2 w-2 text-dodger-blue-500" />
              Resending invite
            </div>
          ) : (
            'Resend invite'
          )}
        </button>
        &bull;
        <button
          className="ml-1 italic text-carnation-500"
          onClick={() => modal.show(<InviteCancelModal onCancelInvite={onCancelInvite} />)}
        >
          Cancel
        </button>
      </span>
    </>
  );
}

function AccountUserActions({ user, onDelete, onUpdate, siteList }) {
  const modal = useModal();

  return (
    <div
      className={classNames(
        'flex w-full max-w-[65px] items-stretch justify-between px-1',
        'invisible group-hover:visible',
      )}
    >
      {user.role !== ACCOUNT_USER_ROLES.OWNER.toLowerCase() && (
        <Tooltip tooltipContent="Edit">
          <IconButton
            icon={<EditIcon className="h-4 w-4 fill-current" />}
            className="h-full text-malibu-500 hover:text-dodger-blue-500"
            onClick={() =>
              modal.show(<EditUserModal user={user} siteList={siteList} onDelete={onDelete} onUpdate={onUpdate} />)
            }
            label="edit user"
          />
        </Tooltip>
      )}
      <Tooltip tooltipContent="Delete">
        <IconButton
          icon={<DeleteIcon className="h-4 w-4 fill-current" />}
          className="h-full text-malibu-500 hover:text-carnation-500"
          onClick={() => modal.show(<DeleteUserModal onDelete={onDelete} numToDelete={1} />)}
          label="delete user"
        />
      </Tooltip>
    </div>
  );
}

function NameAbbrvComponent({ name, role, isInvitationPending }) {
  let bgColor = isInvitationPending ? 'bg-cadet-blue-500' : `${userRoleTextAndClassName(role)[1]}`;
  let fullInitial;
  if (name) {
    let nameArr = name.trim().split(' '); // split the name into array
    // If array length more than 1, just get the first and last
    fullInitial =
      nameArr.length > 1 ? `${nameArr[0].charAt(0)}${nameArr[nameArr.length - 1].charAt(0)}` : nameArr[0].charAt(0);
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-center',
        'text-xs font-semibold text-white',
        'h-[30px] w-[30px] overflow-hidden rounded-full',
        bgColor,
      )}
      style={{ lineHeight: '2' }}
    >
      {name ? fullInitial.toUpperCase() : 'N/A'}
    </div>
  );
}

function TooltipInvitationURL({ invitationUrl }) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    // this is purely for a visual effect
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const urlRef = useRef(null);

  const copyUrl = () => {
    const urlText = urlRef?.current?.innerText;
    copyToClipboard(urlText);
    setCopied(true);
  };

  return (
    <div className="w-80 p-3 text-left">
      <h5 className="text-header-6 text-white">Invitation link</h5>

      <div className="text-body-2 my-2 mb-3 flex items-center rounded border border-charade-500 bg-black py-1 pl-2 pr-1">
        <span className="truncate pr-1 text-cadet-blue-500" ref={urlRef}>
          {invitationUrl}
        </span>
        <Button
          variant="secondary"
          size="xs"
          className={`!text-xs ${copied ? '!bg-lima-500 !text-white' : ''}`}
          onClick={() => copyUrl()}
          theme="dark"
        >
          {copied ? 'Copied' : 'Copy'}
        </Button>
      </div>

      <span className="font-normal leading-none text-cadet-blue-500">
        <p className="mb-1">Need to re-invite this teammate?</p>
        <p>Send them this link so they can collaborate with you on more projects</p>
      </span>
    </div>
  );
}
