import { useEffect } from 'react';
import { fetchNewToken, validToken } from '/src/utils/auth';
import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';

export function useSharingModal(readyCallback, errorCallback, targetReady = true) {
  useEffect(() => {
    const config = {
      host: `${window.CORE_API_URL}/gql`,
      jwtGenerator: async () => {
        if (!validToken(window.localStorage.getItem(AUTH_TOKEN_KEY))) {
          await fetchNewToken();
        }
        return window.localStorage.getItem(AUTH_TOKEN_KEY);
      },
    };
    const alreadyLoaded = document.querySelector(`script[src="${window.SHARING_MODAL_SCRIPT_URL}"]`);
    if (!alreadyLoaded && targetReady) {
      const scriptTag = document.createElement('script');
      scriptTag.onload = () => {
        window.SharingModal.start(config);
        readyCallback?.();
      };
      scriptTag.onerror = () => {
        errorCallback?.();
      };
      scriptTag.src = window.SHARING_MODAL_SCRIPT_URL;
      document.body.appendChild(scriptTag);
    }
  }, [readyCallback, errorCallback, targetReady]);
}
