import { isE2E } from './utils';

(function () {
  if (isE2E()) {
    const script = document.createElement('script');
    script.src = '/c_e2e.js';
    document.body.appendChild(script);
  } else {
    const script = document.createElement('script');
    script.src = '/c.js';
    document.body.appendChild(script);
  }
})();
