import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext, useModal } from '/src/hooks';
import { getPlayerPlaylistQueryParams } from '/src/utils/url';
import { PlayButton } from '/src/features/recordings/components/PlayButton';
import { LockedRecordingModal } from '/src/features/recordings/components/dashboard/modals/LockedRecordingModal';
import { Tooltip } from '@crazyegginc/hatch';

export function PlayerLink({
  recording,
  isWatching = false,
  onClick = null,
  link = false,
  redirect = false,
  size = 'base',
  flipColorOnHover = false,
  navigateToAnotherRecording = null,
  togglePlaylist = null,
}) {
  const { sessionInfo } = useAuthContext();

  const navigate = useNavigate();
  const modal = useModal();

  const isImpersonated = sessionInfo?.isImpersonated ?? false;
  const watched = !!recording.viewedAt;
  const isTooOld = recording.permissions.canUpgradeToView;

  const clickHandler = useCallback(() => {
    if (isTooOld) {
      return modal.show(<LockedRecordingModal />);
    } else if (link) {
      navigate(
        {
          pathname: `/recordings/${recording.hashedId}/player`,
          search: getPlayerPlaylistQueryParams(),
        },
        { replace: redirect },
      );
      onClick?.(recording);
    } else if (redirect && navigateToAnotherRecording) {
      navigateToAnotherRecording(recording);
      onClick?.(recording);
    } else if (redirect) {
      navigate(
        {
          pathname: `/recordings/${recording.hashedId}/player`,
          search: getPlayerPlaylistQueryParams(),
        },
        { replace: redirect },
      );
      onClick?.(recording);
    } else if (onClick) {
      return onClick();
    }
    return null;
  }, [onClick, isTooOld, modal, link, navigate, redirect, recording, navigateToAnotherRecording]);

  const renderPlayButton = useCallback(() => {
    return (
      <PlayButton
        isWatching={isWatching}
        watched={watched}
        isTooOld={isTooOld}
        onClick={(e) => {
          togglePlaylist?.(e);
          clickHandler();
        }}
        size={size}
        flipColorOnHover={flipColorOnHover}
      />
    );
  }, [isWatching, watched, isTooOld, size, flipColorOnHover, clickHandler, togglePlaylist]);

  const renderContent = useCallback(() => {
    return isImpersonated ? (
      <Tooltip
        tooltipContent={
          <div style={{ textAlign: 'left' }}>
            userId: {recording.userId}
            <br />
            recordingId: {recording.id}
            <br />
            sessionId: {recording.sessionId}
            <br />
            downloadId: {recording.userId}-{recording.sessionId}
          </div>
        }
        interactive={true}
        containerStyle={{ zIndex: 999999 }}
      >
        {renderPlayButton()}
      </Tooltip>
    ) : (
      renderPlayButton()
    );
  }, [isImpersonated, recording.id, recording.sessionId, recording.userId, renderPlayButton]);

  if (isTooOld || isWatching) {
    return <div style={{ padding: '10px' }}>{renderContent()}</div>;
  }

  return (
    <div className="flex-shrink-0">
      <div style={{ padding: '10px' }}>{renderContent(true)}</div>
    </div>
  );
}
