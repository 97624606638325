import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const funnelFeatureKeys = createQueryKeys('funnelFeature', {
  funnel: ({ siteId, startAt, endAt, timeUnit, steps, filters }) => [
    {
      siteId,
      startAt,
      endAt,
      timeUnit,
      steps,
      filters,
    },
  ],
});

export const funnelQuery = ({ siteId, startAt, endAt, timeUnit, steps, filters }) => ({
  ...funnelFeatureKeys.funnel({ siteId, startAt, endAt, timeUnit, steps, filters }),
  meta: {
    query: gql`
      query Funnel(
        $siteId: Int!
        $startAt: Int!
        $endAt: Int!
        $timeUnit: Int!
        $steps: [FunnelStep!]!
        $filters: FunnelFilter
      ) {
        funnel(
          siteId: $siteId
          startAt: $startAt
          endAt: $endAt
          timeUnit: $timeUnit
          steps: $steps
          filters: $filters
        ) {
          summary
          timeUnitResults {
            startAt
            endAt
            steps
            conversionRates
          }
        }
      }
    `,
  },
});
